import _ from "lodash";
import React, { PureComponent } from "react";
import Chart from "react-apexcharts";
import baseUtils from "../../../utils/baseUtils";

interface OutputChartProps {
  data: Array<Array<number>>;
  max: number;
  year: "current" | "last";
  yearNum: number;
}

interface OutputChartState {
  series: Array<{ name: string; data: Array<number> }>;
  options: any;
}

class OutputChart extends PureComponent<OutputChartProps, OutputChartState> {
  constructor(props: OutputChartProps) {
    super(props);
    this.state = {
      series: [
        { name: "Book turnover" + (props.year === "last" ? " last year" : ""), data: props.data[0] },
        { name: "Written turnover" + (props.year === "last" ? " last year" : ""), data: props.data[1] }
      ],
      options: {
        colors: ["#E5E6EB", "#0275D8"],
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        },
        yaxis: {
          labels: {
            formatter: (val: number) => {
              if (val >= 1000000) return val / 1000000 + "M €";
              if (val > 1000) return val / 1000 + "K €";
              return val + " €";
            }
          },
          max: props.max
        },
        tooltip: {
          y: { formatter: (val: number) => baseUtils.formatEuro(val) }
        },
        plotOptions: {
          bar: {
            columnWidth: "60",
            borderRadius: 2
          }
        },
        dataLabels: { enabled: false }
      }
    };
  }

  componentDidUpdate(prevProps: Readonly<OutputChartProps>, prevState: Readonly<OutputChartState>, snapshot?: any) {
    if (!_.isEqual(prevProps, this.props)) {
      const { data, max } = this.props;
      const series = _.cloneDeep(this.state.series);
      const options = _.cloneDeep(this.state.options);
      series[0].data = data[0];
      series[1].data = data[1];
      options.yaxis.max = max;
      this.setState({ series, options });
    }
  }

  render() {
    const { yearNum } = this.props;
    const { options, series } = this.state;
    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">{"Turnover (" + yearNum + ")"}</h3>
          </div>
        </div>
        <div className="kt-portlet__body px-0">
          <Chart options={options} series={series} type="bar" height="700" width="100%" />
        </div>
      </div>
    );
  }
}

export default OutputChart;
