export const TIMELINE_SLICE_AMOUNT = -10;

/**
 * Resolves price changes in the timeline of a commodity or packaging.
 * @param pre: State before change
 * @param post: State after change
 * @returns { string } Description of the change
 */
function resolvePriceChanges(pre?: Array<string>, post?: Array<string>) {
  let additionalInformation;
  if (pre && !post) {
    const suppliers = Array.from(new Set(pre!.filter(p => p.includes("Supplier")).map(s => s.split("Supplier: ")[1])));
    const prices = pre!.filter(p => p.includes("Price")).map(s => s.split("Price: ")[1]);
    additionalInformation =
      (prices.length > 1 ? "Prices " : "Price ") +
      " of " +
      (suppliers.length > 1 ? " multiple suppliers " : suppliers[0]) +
      " deleted";
  } else if (!pre && post) {
    const suppliers = Array.from(new Set(post!.filter(p => p.includes("Supplier")).map(s => s.split("Supplier: ")[1])));
    const prices = post!.filter(p => p.includes("Price")).map(s => s.split("Price: ")[1]);
    additionalInformation =
      (prices.length > 1 ? "Prices " : "Price ") +
      " for " +
      (suppliers.length > 1 ? " multiple suppliers " : suppliers[0]) +
      " added";
  }
  return additionalInformation;
}

export default { resolvePriceChanges };
