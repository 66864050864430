import _ from "lodash";
import React, { PureComponent } from "react";
import OutputDetails from "./controlling/OutputDetails";
import OutputHistory from "./controlling/OutputHistory";
import ReadyForProduction from "./controlling/ReadyForProduction";
import { DataContext } from "../../context/dataContext";
import MissingPrintingFiles from "./controlling/MissingPrintingFiles";
import OutputByManufacturer from "./controlling/OutputByManufacturer";
import OutputByProduct from "./controlling/OutputByProduct";
import DateInput from "../common/DateInput";
import dateUtils from "../../utils/dateUtils";
import dashboardUtils from "../../utils/dashboardUtils";
import orderUtils, { DECLINED } from "../../utils/orderUtils";
import invoiceUtils, { I_CANCELED } from "../../utils/invoiceUtils";
import contractUtils from "../../utils/contractUtils";

interface ControllingDashboardProps {
  manufacturer: String;
  context: React.ContextType<typeof DataContext>;
}

interface ControllingDashboardState {
  targetOutput: number;
  targetOutputTurnover: number;
  targetOutputProfit: number;
  outputHistory: Array<any>;
  outputDateStart: Date;
  outputDateEnd: Date;
}

class ControllingDashboard extends PureComponent<ControllingDashboardProps, ControllingDashboardState> {
  constructor(props: ControllingDashboardProps) {
    super(props);
    const currentCW = dateUtils.getCW(new Date());
    const start = dateUtils.getStartOfISOWeek(currentCW - 1, new Date().getFullYear());
    start.setHours(0);
    const end = new Date(start);
    end.setDate(start.getDate() + 6);
    end.setHours(23, 59, 59, 999);
    this.state = {
      targetOutput: +(props.context.general.find(g => g.data === "targetUnits")?.value || 0),
      targetOutputTurnover: +(props.context.general.find(g => g.data === "totalturnover")?.value || 0),
      targetOutputProfit: +(props.context.general.find(g => g.data === "totalmargin")?.value || 0),
      outputHistory: [],
      outputDateStart: start,
      outputDateEnd: end
    };
  }

  componentDidMount() {
    this.createData();
  }

  componentDidUpdate(
    prevProps: Readonly<ControllingDashboardProps>,
    prevState: Readonly<ControllingDashboardState>,
    snapshot?: any
  ) {
    if (!_.isEqual(this.props, prevProps)) {
      this.createData();
    }
  }

  /**
   * Handles setting the output date start.
   * @param e: Event that triggered the change
   */
  handleSetOutputDateStart = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ outputDateStart: new Date(e.target.value) });
  };

  /**
   * Handles setting the output date end. The time is set to make sure it is at the end of the day.
   * @param e: Event that triggered the change
   */
  handleSetOutputDateEnd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const outputDateEnd = new Date(e.target.value);
    outputDateEnd.setHours(23, 59, 59, 999);
    this.setState({ outputDateEnd });
  };

  /**
   * Populate the data for the controlling dashboard.
   */
  createData() {
    const { context, manufacturer } = this.props;
    // Step 1: create an array of date and output
    let output = [];
    for (let i = 0; i < context.orders.length; i++) {
      const order = context.orders[i];
      if (!manufacturer || manufacturer.toString() === order.settings.manufacturer.toString()) {
        if (order.invoices) {
          const percentMargin = dashboardUtils.getPercentMargin(order);
          if (percentMargin < 0) {
            console.warn("Found negative margin for order", order._id.toString());
          }
          for (let j = 0; j < order.invoices.length; j++) {
            const inv = order.invoices[j];
            if (
              orderUtils.isOrder(order) &&
              !contractUtils.isContract(order) &&
              order.state !== DECLINED &&
              inv.dueIn !== -1 &&
              inv.state !== I_CANCELED
            ) {
              const total = invoiceUtils.getSubtotal(inv.positions);
              output.push({
                t: inv.invoiceDate,
                y: 0,
                amount: inv.positions.reduce((sum, p) => (p.type === "position" ? sum + p.amount : sum), 0),
                turnover: total,
                totalTurnover: 0,
                margin: invoiceUtils.calculateAbsoluteMargin(total, percentMargin),
                totalMargin: 0
              });
            }
          }
        }
      }
    }
    // Step 2: filter and sort by date
    const currentYear = new Date(new Date().getFullYear(), 0, 1);
    output = _.filter(output, o => {
      return o.t > currentYear;
    });
    output = _.sortBy(output, "t");
    if (output.length > 0) {
      // Step 3: sum up
      output[0].y = output[0].amount;
      output[0].totalTurnover = output[0].turnover;
      output[0].totalMargin = output[0].margin;
      for (let i = 1; i < output.length; i++) {
        output[i].y += output[i - 1].y + output[i].amount;
        output[i].totalTurnover += output[i - 1].totalTurnover + output[i].turnover;
        output[i].totalMargin += output[i - 1].totalMargin + output[i].margin;
      }
    }
    this.setState({ outputHistory: output });
  }

  render() {
    const { context } = this.props;
    const { outputHistory, outputDateStart, outputDateEnd, targetOutput, targetOutputProfit, targetOutputTurnover } =
      this.state;
    return (
      <div className="row">
        {outputHistory && (
          <>
            <div className="col-12 col-xl-6">
              <OutputHistory
                outputHistory={outputHistory}
                targetOutput={targetOutput}
                targetOutputProfit={targetOutputProfit}
                targetOutputTurnover={targetOutputTurnover}
                year={outputDateStart.getFullYear()}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-3">
              <OutputDetails
                outputHistory={outputHistory}
                targetOutput={targetOutput}
                targetOutputProfit={targetOutputProfit}
                targetOutputTurnover={targetOutputTurnover}
                year={outputDateStart.getFullYear()}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-3">
              <ReadyForProduction orders={context.orders} />
            </div>
            <div className="col-12">
              <div className="kt-portlet">
                <div className="kt-portlet__body kt-portlet__body--fit p-3">
                  <div className="row">
                    <div className="col-md-2 kt-margin-b-20-tablet-and-mobile d-flex">
                      <span className="text-dark mr-2 align-self-center" style={{ whiteSpace: "nowrap" }}>
                        Start date:
                      </span>
                      <DateInput
                        value={outputDateStart}
                        onBlur={this.handleSetOutputDateStart}
                        max={outputDateEnd}
                        name="start"
                      />
                    </div>
                    <div className="col-md-2 kt-margin-b-20-tablet-and-mobile d-flex">
                      <span className="text-dark mr-2 align-self-center" style={{ whiteSpace: "nowrap" }}>
                        End date:
                      </span>
                      <DateInput
                        value={outputDateEnd}
                        onBlur={this.handleSetOutputDateEnd}
                        min={outputDateStart}
                        name="end"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <OutputByManufacturer context={context} startDate={outputDateStart} endDate={outputDateEnd} />
            </div>
            <div className="col-12 col-lg-4">
              <OutputByProduct
                context={context}
                product="capsule"
                startDate={outputDateStart}
                endDate={outputDateEnd}
              />
            </div>
            <div className="col-12 col-lg-4">
              <OutputByProduct context={context} product="tablet" startDate={outputDateStart} endDate={outputDateEnd} />
            </div>
            <div className="col-12 col-lg-4">
              <OutputByProduct context={context} product="powder" startDate={outputDateStart} endDate={outputDateEnd} />
            </div>
            <div className="col-12 col-lg-4">
              <OutputByProduct context={context} product="liquid" startDate={outputDateStart} endDate={outputDateEnd} />
            </div>
            <div className="col-12 col-lg-4">
              <OutputByProduct
                context={context}
                product="softgel"
                startDate={outputDateStart}
                endDate={outputDateEnd}
              />
            </div>
            <div className="col-12 col-lg-4">
              <OutputByProduct context={context} product="custom" startDate={outputDateStart} endDate={outputDateEnd} />
            </div>
            <div className="col-12 col-md-8 col-xl-6">
              <MissingPrintingFiles context={context} />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ControllingDashboard;
