import _ from "lodash";
import React, { PureComponent } from "react";
import { DataContext } from "../../context/dataContext";
import dashboardUtils from "../../utils/dashboardUtils";
import { Period } from "../common/CustomTypes";
import InvestorWelcome from "./investor/InvestorWelcome";
import SalesStats from "./sales/SalesStats";
import OutputChart from "./investor/OutputChart";
import YearToYearDevelopment from "./investor/YearToYearDevelopment";
import CapacityBarometer from "./general/CapacityBarometer";
import OutputDetails from "./controlling/OutputDetails";
import OutputHistory from "./controlling/OutputHistory";
import orderUtils, { DECLINED } from "../../utils/orderUtils";
import invoiceUtils, { I_CANCELED } from "../../utils/invoiceUtils";
import contractUtils from "../../utils/contractUtils";

interface InvestorDashboardProps {
  manufacturer: String;
  context: React.ContextType<typeof DataContext>;
  period: Period;
}

interface InvestorDashboardState {
  targetOutput: number;
  targetOutputTurnover: number;
  targetOutputProfit: number;
  outputHistory: Array<any>;
}

class InvestorDashboard extends PureComponent<InvestorDashboardProps, InvestorDashboardState> {
  constructor(props: InvestorDashboardProps) {
    super(props);
    this.state = {
      targetOutput: +(props.context.general.find(g => g.data === "targetUnits")?.value || 0),
      targetOutputTurnover: +(props.context.general.find(g => g.data === "totalturnover")?.value || 0),
      targetOutputProfit: +(props.context.general.find(g => g.data === "totalmargin")?.value || 0),
      outputHistory: []
    };
  }

  componentDidMount() {
    this.createData();
  }

  componentDidUpdate(
    prevProps: Readonly<InvestorDashboardProps>,
    prevState: Readonly<InvestorDashboardState>,
    snapshot?: any
  ) {
    const { manufacturer, context, period } = this.props;
    if (
      manufacturer !== prevProps.manufacturer ||
      !_.isEqual(context.orders, prevProps.context.orders) ||
      !_.isEqual(period, prevProps.period)
    ) {
      this.createData();
    }
  }

  /**
   * Populate the data for the investor dashboard.
   */
  createData() {
    const { context, period } = this.props;
    // Step 1: create an array of date and output
    let output = [];
    for (let i = 0; i < context.orders.length; i++) {
      const order = context.orders[i];
      if (order.invoices) {
        const percentMargin = dashboardUtils.getPercentMargin(order);
        if (percentMargin < 0) {
          console.warn("Found negative margin for order", order._id.toString());
        }
        for (let j = 0; j < order.invoices.length; j++) {
          const inv = order.invoices[j];
          if (
            orderUtils.isOrder(order) &&
            !contractUtils.isContract(order) &&
            order.state !== DECLINED &&
            inv.state !== I_CANCELED &&
            inv.dueIn !== -1 &&
            period.beginning <= inv.invoiceDate &&
            period.end >= inv.invoiceDate
          ) {
            const total = invoiceUtils.getSubtotal(inv.positions);
            output.push({
              t: inv.invoiceDate,
              y: 0,
              amount: inv.positions.reduce((sum, p) => (p.type === "position" ? sum + p.amount : sum), 0),
              turnover: total,
              totalTurnover: 0,
              margin: invoiceUtils.calculateAbsoluteMargin(total, percentMargin),
              totalMargin: 0
            });
          }
        }
      }
    }
    // Step 2: filter and sort by date
    const relevantYear = new Date(period.beginning.getFullYear(), 0, 1);
    output = _.filter(output, o => {
      return o.t > relevantYear;
    });
    output = _.sortBy(output, "t");
    if (output.length > 0) {
      // Step 3: sum up
      output[0].y = output[0].amount;
      output[0].totalTurnover = output[0].turnover;
      output[0].totalMargin = output[0].margin;
      for (let i = 1; i < output.length; i++) {
        output[i].y += output[i - 1].y + output[i].amount;
        output[i].totalTurnover += output[i - 1].totalTurnover + output[i].turnover;
        output[i].totalMargin += output[i - 1].totalMargin + output[i].margin;
      }
    }
    this.setState({ outputHistory: output });
  }

  /**
   * Calculates the output chart data. Done here to have the maximum of both, this and last year.
   * @returns { { last: { dataOV: Array<number>, dataRP: Array<number> },
   *              current: { dataOV: Array<number>, dataRP: Array<number> }
   *          } }
   */
  calculateOutputChartData = () => {
    const { context, period } = this.props;
    const { orders } = context;
    const dataOVLast = [];
    const dataOV = [];
    const dataRPLast = [];
    const dataRP = [];
    const curYear = period.beginning.getFullYear();
    const lastYear = curYear - 1;
    for (let i = 0; i < 12; i++) {
      const beginningLast = new Date(lastYear, i, 1, 0, 0, 0, 0);
      const beginning = new Date(curYear, i, 1, 0, 0, 0, 0);
      const endLast = new Date(lastYear, i + 1, 0, 23, 59, 59, 999);
      const end = new Date(curYear, i + 1, 0, 23, 59, 59, 999);
      const periodLast = { beginning: beginningLast, end: endLast };
      const period = { beginning, end };
      dataOVLast.push(dashboardUtils.getOutstandingOrderVolume(orders, periodLast));
      dataOV.push(dashboardUtils.getOutstandingOrderVolume(orders, period));
      dataRPLast.push(dashboardUtils.getAlreadyInvoicedVolume(orders, periodLast));
      dataRP.push(dashboardUtils.getAlreadyInvoicedVolume(orders, period));
    }
    return { last: { dataOV: dataOVLast, dataRP: dataRPLast }, current: { dataOV, dataRP } };
  };

  /**
   * Calculates the next greater, full, value for the maximum.
   * @param maxValue: Max value
   * @returns { number } Next greater, full, value
   */
  calculateNextFullMaxValue = (maxValue?: number) => {
    if (!maxValue) return 0;
    const length = Math.ceil(maxValue).toString().length;
    if (length > 2) {
      const exp = Math.pow(10, length - 2);
      let max = Math.ceil(maxValue / exp);
      return max * exp;
    }
    return maxValue;
  };

  render() {
    const { context, period } = this.props;
    const { orders } = context;
    const salesStats = dashboardUtils.getSalesStats(orders, undefined, period);
    const { outputHistory, targetOutput, targetOutputProfit, targetOutputTurnover } = this.state;
    const outputChartData = this.calculateOutputChartData();
    const maxValue = this.calculateNextFullMaxValue(
      _.max(
        outputChartData.last.dataOV
          .concat(outputChartData.last.dataRP)
          .concat(outputChartData.current.dataOV)
          .concat(outputChartData.current.dataRP)
      )
    );
    return (
      <>
        <div className="row">
          <div className="col-12">
            <InvestorWelcome orders={orders} year={period.beginning.getFullYear()} />
          </div>
          <div className="col-12 col-xl-9">
            <SalesStats stats={salesStats} orders={orders} dashboard="investor" year={period.beginning.getFullYear()} />
          </div>
          <div className="col-12 col-lg-6 col-xl-3">
            <CapacityBarometer orders={orders} dashboard="investor" />
          </div>
          <div className="col-12 col-xl-6">
            <OutputHistory
              outputHistory={outputHistory}
              targetOutput={targetOutput}
              targetOutputProfit={targetOutputProfit}
              targetOutputTurnover={targetOutputTurnover}
              year={period.beginning.getFullYear()}
            />
          </div>
          <div className="col-12 col-lg-3">
            <OutputChart
              year="last"
              yearNum={period.beginning.getFullYear() - 1}
              max={maxValue}
              data={[outputChartData.last.dataOV, outputChartData.last.dataRP]}
            />
          </div>
          <div className="col-12 col-lg-3">
            <OutputChart
              year="current"
              yearNum={period.beginning.getFullYear()}
              max={maxValue}
              data={[outputChartData.current.dataOV, outputChartData.current.dataRP]}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-3">
            <OutputDetails
              outputHistory={outputHistory}
              targetOutput={targetOutput}
              targetOutputProfit={targetOutputProfit}
              targetOutputTurnover={targetOutputTurnover}
              onInvestorDashboard={true}
              year={period.beginning.getFullYear()}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-3">
            <YearToYearDevelopment orders={orders} year={period.beginning.getFullYear()} />
          </div>
        </div>
      </>
    );
  }
}

export default InvestorDashboard;
