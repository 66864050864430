import React from "react";
import { toAbsoluteUrl } from "../../_metronic";
import config from "../../config/config.json";
import { CommoditiesDocument } from "../../model/commodities.types";
import { DataContext } from "../../context/dataContext";
import baseUtils from "../../utils/baseUtils";

interface CommodityBaseImageProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
}

const CommodityBaseImage: React.FunctionComponent<CommodityBaseImageProps> = ({
  commodity,
  context
}: CommodityBaseImageProps) => {
  const { colors } = context;
  const relatedColor = baseUtils.getDocFromCollection(colors, commodity.color ? commodity.color : "");
  const mediahubSrc = config.mediahubBase;
  const src =
    commodity.type === "softgel"
      ? toAbsoluteUrl("/media/img/Softgels.jpg")
      : relatedColor && relatedColor.image && commodity.form.toString() === "5ffdc73a95ac35e327854aa0"
      ? mediahubSrc + relatedColor.image
      : commodity.form.toString() === "5ffdc73a95ac35e327854aa0"
      ? mediahubSrc + "files/unknown.png"
      : mediahubSrc + "files/others.png";

  return <img src={src} alt="color" />;
};

export default CommodityBaseImage;
