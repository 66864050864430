import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import slackService from "../../services/slackService";
import userService from "../../services/userService";

interface ErrorPageProps extends RouteComponentProps<ErrorParams, {}, {}> {
  error: string;
  trace: string;
}

interface ErrorParams {
  source: string;
}

interface ErrorPageState {
  usedIndex: number;
}

class ErrorPage extends PureComponent<ErrorPageProps, ErrorPageState> {
  links = [
    "https://media1.giphy.com/media/3o6Ztl7oraKm4ZJ9mw/giphy.gif", // LA Kings waving player
    "https://media.giphy.com/media/LqxA0TsrRyoQ32t1Vy/giphy.gif", // Hysterical screaming guy
    "https://media.giphy.com/media/KHF9fbygB3SRoWt3iC/giphy.gif", // Guy looking shocked
    "https://media.giphy.com/media/dCdTUwSva7GOzPAcf3/giphy.gif", // Hallelujah!
    "https://media.giphy.com/media/f7MGpYEjx86KKzqKjZ/giphy.gif", // Guy raising arms like "don't know"
    "https://media.giphy.com/media/d2lcHJTG5Tscg/giphy.gif", // Guy crying on couch
    "https://media.giphy.com/media/4ZrFRwHGl4HTELW801/giphy.gif", // Oh come on!
    "https://media.giphy.com/media/3oz8xP6SaSkSU9dhcI/giphy.gif", // Guy having an idea and getting bottled
    "https://media.giphy.com/media/WtUcYhTcrtVWwiD7bG/giphy.gif", // Laughing police officer
    "https://media.giphy.com/media/t7pp3YLzz6Tfy/giphy.gif", // Dr. House "mimimi"
    "https://media.giphy.com/media/xT9IgDk29h0Vye0jAs/giphy.gif", // Lucifer eyebrows
    "https://i.kym-cdn.com/entries/icons/original/000/013/113/hahaha-no.gif", // Transformer haha no
    "https://c.tenor.com/NNTV9MjxX4wAAAAC/what-the.gif", // What the hell is even that
    "https://media.giphy.com/media/ukGm72ZLZvYfS/giphy.gif", // Dafuq guy
    "https://media1.giphy.com/media/S3F8kkGTHZ4Y/giphy.gif", // Draco Malfoy looking impressed
    "https://media1.giphy.com/media/13LdqExgczUgCs/giphy.gif", // Neville Longbottom "why is it always me?"
    "https://media1.giphy.com/media/YAlhwn67KT76E/giphy.gif", // Minion "bee doo bee doo"
    "https://media.giphy.com/media/jnhXd7KT8UTk5WIgiV/giphy.gif", // Minion blah blah
    "https://media.giphy.com/media/STfLOU6iRBRunMciZv/giphy.gif", // Austin powers how about no
    "https://media.tenor.com/7p-Jnh69pqsAAAAC/alarm-german.gif" // ALARM
  ];

  constructor(props: ErrorPageProps) {
    super(props);
    this.state = { usedIndex: this.getRandomIndex() };
  }

  async componentDidMount() {
    const { error, trace, match } = this.props;
    const source = match.params.source.replaceAll("\\", "/");
    const user = userService.getUserData();
    if (error || trace) {
      await slackService.sendMessage(
        "#interne-fehlermeldungen",
        `ERROR: ${error ? error : "unknown"}\n
        LOCATION: ${source}\n
        USER: ${user._id.toString()} (${user.prename} ${user.surname})\n
        TRACE: ${trace ? trace : "unknown"}`
      );
    }
  }

  /**
   * Get an random index that is smaller that the links list size.
   * @returns { number } Random index between 0 and links.length - 1
   */
  getRandomIndex = () => {
    return Math.floor(Math.random() * this.links.length);
  };

  render() {
    const { error, trace } = this.props;
    const { usedIndex } = this.state;
    if (!error && !trace) {
      this.props.history.goBack();
    }
    return (
      <div className="kt-grid kt-grid--ver kt-grid--root h-100">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v5 bg-white">
          <div className="row">
            <div className="col-6">
              <div className="kt-error_container w-100">
                <span className="kt-error_title">
                  <h1 className="ml-5" style={{ marginTop: "8rem" }}>
                    Oops!
                  </h1>
                </span>
                <p className="kt-error_subtitle ml-5">Something went wrong here.</p>
                <p className="kt-error_subtitle ml-5">
                  Please report this issue{" "}
                  <a href="https://private-label-factory.slack.com/archives/G015FLK7CM9" target="_blank">
                    here
                  </a>{" "}
                  to help fixing it!
                </p>
                <p className="kt-error_description text-danger ml-5" style={{ fontSize: "1.2rem" }}>
                  Reason: {error ? error : "unknown"}
                </p>
                <p className="kt-error_description ml-5 pb-5" style={{ fontSize: "0.75rem" }}>
                  {trace && "Trace: " + trace}
                </p>
              </div>
            </div>
            <div className="col-6 p-5 d-flex align-self-center justify-content-center">
              <img
                onClick={() => this.setState({ usedIndex: this.getRandomIndex() })}
                src={this.links[usedIndex]}
                className="w-auto h-auto d-block"
                style={{ maxHeight: "600px", maxWidth: "600px", minHeight: "400px", minWidth: "400px" }}
                alt="error"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ErrorPage);
