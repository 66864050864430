import React from "react";
import {
  COLORS,
  BAG_MATERIALS,
  BAG_SHAPES,
  BAG_ZIPPERS,
  BOTTLE_MATERIALS,
  BOTTLE_NECKS,
  BOTTLE_SHAPES,
  BOX_QUALITIES,
  LABEL_QUALITIES,
  LID_MATERIALS,
  LID_SHAPES,
  LIQUIDBOTTLE_NECKS,
  LIQUIDBOTTLELID_NECKS,
  SLEEVE_SIZES,
  SPRAYPUMP_LENGTH,
  SPRAYPUMP_NORMS,
  STICKER_FORMS,
  StickerForms
} from "./PackagingHelper";
import {
  BagData,
  BlisterData,
  BottleData,
  BoxData,
  LabelData,
  LidData,
  PipetteData,
  SprayPumpData,
  SleeveData,
  StickerData,
  SpoonData,
  SilicaGelBagData
} from "./CustomTypes";

/**
 * Packaging Components
 */

function renderOptions(options: Array<{ value: string; label: string }>) {
  return options.map(o => (
    <option key={o.value} value={o.value}>
      {o.label}
    </option>
  ));
}

interface CreatePackagingBaseProps {
  onPackagingDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

interface CreatePackagingBottleProps extends CreatePackagingBaseProps {
  data: BottleData;
}

export const CreatePackagingBottle: React.FunctionComponent<CreatePackagingBottleProps> = ({
  data,
  onPackagingDataChange
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Material</label>
        <div className="col-3">
          <select className="form-control" name="shape" value={data.shape} onChange={onPackagingDataChange}>
            {renderOptions(BOTTLE_SHAPES)}
          </select>
        </div>
        <div className="col-3">
          <select className="form-control" name="material" value={data.material} onChange={onPackagingDataChange}>
            {renderOptions(BOTTLE_MATERIALS)}
          </select>
        </div>
        <div className="col-3">
          <select className="form-control" name="color" value={data.color} onChange={onPackagingDataChange}>
            {renderOptions(COLORS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Volume / Height / Width </label>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.volume.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Volume"
              name="volume"
              onChange={onPackagingDataChange}
              value={data.volume}
            />
            <div className="input-group-append">
              <span className="input-group-text">ml</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.height.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Height"
              name="height"
              onChange={onPackagingDataChange}
              value={data.height}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.width.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Width"
              name="width"
              onChange={onPackagingDataChange}
              value={data.width}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Label Height / Neck </label>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.labelHeight.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="max. Label height"
              name="labelHeight"
              onChange={onPackagingDataChange}
              value={data.labelHeight}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <select className="form-control" name="neck" onChange={onPackagingDataChange} value={data.neck}>
            {renderOptions(BOTTLE_NECKS)}
          </select>
        </div>
      </div>
    </div>
  );
};

export const CreatePackagingLiquidBottle: React.FunctionComponent<CreatePackagingBottleProps> = ({
  data,
  onPackagingDataChange
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Material </label>
        <div className="col-3">
          <select className={"form-control"} name="material" onChange={onPackagingDataChange} value={data.material}>
            {renderOptions(BOTTLE_MATERIALS)}
          </select>
        </div>
        <div className="col-3">
          <select className={"form-control"} name="color" onChange={onPackagingDataChange} value={data.color}>
            {renderOptions(COLORS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Volume / Height / Width</label>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.volume.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Volume"
              name="volume"
              onChange={onPackagingDataChange}
              value={data.volume}
            />
            <div className="input-group-append">
              <span className="input-group-text">ml</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.height.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Height"
              name="height"
              onChange={onPackagingDataChange}
              value={data.height}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.width.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Width"
              name="width"
              onChange={onPackagingDataChange}
              value={data.width}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Label Height / Neck</label>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.labelHeight.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="max. Label height"
              name="labelHeight"
              onChange={onPackagingDataChange}
              value={data.labelHeight}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <select className={"form-control"} name="neck" onChange={onPackagingDataChange} value={data.neck}>
            {renderOptions(LIQUIDBOTTLE_NECKS)}
          </select>
        </div>
      </div>
    </div>
  );
};

interface CreatePackagingSprayPumpProps extends CreatePackagingBaseProps {
  data: SprayPumpData;
}

export const CreatePackagingSprayPump: React.FunctionComponent<CreatePackagingSprayPumpProps> = ({
  data,
  onPackagingDataChange
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Color</label>
        <div className="col-3">
          <select className={"form-control"} name="color" onChange={onPackagingDataChange} value={data.color}>
            {renderOptions(COLORS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Norm</label>
        <div className="col-3">
          <select className={"form-control"} name="norm" onChange={onPackagingDataChange} value={data.norm}>
            {renderOptions(SPRAYPUMP_NORMS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Heigth</label>
        <div className="col-3">
          <select className={"form-control"} name="height" value={data.height} onChange={onPackagingDataChange}>
            {renderOptions(SPRAYPUMP_LENGTH)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Neck</label>
        <div className="col-3">
          <select className={"form-control"} name="neck" value={data.neck} onChange={onPackagingDataChange}>
            {renderOptions(LIQUIDBOTTLE_NECKS)}
          </select>
        </div>
      </div>
    </div>
  );
};

interface CreatePackagingPipetteProps extends CreatePackagingBaseProps {
  data: PipetteData;
}

export const CreatePackagingPipette: React.FunctionComponent<CreatePackagingPipetteProps> = ({
  data,
  onPackagingDataChange
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Height</label>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.height.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Length in bottle"
              name="height"
              onChange={onPackagingDataChange}
              value={data.height}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Color</label>
        <div className="col-3">
          <select className={"form-control"} name="color" onChange={onPackagingDataChange} value={data.color}>
            {renderOptions(COLORS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Neck</label>
        <div className="col-3">
          <select className={"form-control"} name="neck" onChange={onPackagingDataChange} value={data.neck}>
            {renderOptions(LIQUIDBOTTLE_NECKS)}
          </select>
        </div>
      </div>
    </div>
  );
};

interface CreatePackagingLidProps extends CreatePackagingBaseProps {
  data: LidData;
}

export const CreatePackagingLid: React.FunctionComponent<CreatePackagingLidProps> = ({
  data,
  onPackagingDataChange
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Material</label>
        <div className="col-3">
          <select className={"form-control"} name="shape" onChange={onPackagingDataChange} value={data.shape}>
            {renderOptions(LID_SHAPES)}
          </select>
        </div>
        <div className="col-3">
          <select className={"form-control"} name="material" onChange={onPackagingDataChange} value={data.material}>
            {renderOptions(LID_MATERIALS)}
          </select>
        </div>
        <div className="col-3">
          <select className={"form-control"} name="color" onChange={onPackagingDataChange} value={data.color}>
            {renderOptions(COLORS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Size</label>
        <div className="col-3">
          <select className={"form-control"} name="size" onChange={onPackagingDataChange} value={data.size}>
            {renderOptions(LIQUIDBOTTLELID_NECKS)}
          </select>
        </div>
      </div>
    </div>
  );
};

interface CreatePackagingBagProps extends CreatePackagingBaseProps {
  data: BagData;
}

export const CreatePackagingBag: React.FunctionComponent<CreatePackagingBagProps> = ({
  data,
  onPackagingDataChange
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Shape</label>
        <div className="col-3">
          <select className={"form-control"} name="shape" onChange={onPackagingDataChange} value={data.shape}>
            {renderOptions(BAG_SHAPES)}
          </select>
        </div>
        <div className="col-3">
          <select className={"form-control"} name="material" onChange={onPackagingDataChange} value={data.material}>
            {renderOptions(BAG_MATERIALS)}
          </select>
        </div>
        <div className="col-3">
          <select className={"form-control"} name="zipper" onChange={onPackagingDataChange} value={data.zipper}>
            {renderOptions(BAG_ZIPPERS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Volume / Height / Width</label>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.volume.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Volume"
              name="volume"
              onChange={onPackagingDataChange}
              value={data.volume}
            />
            <div className="input-group-append">
              <span className="input-group-text">ml</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.height.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Height"
              name="height"
              onChange={onPackagingDataChange}
              value={data.height}
            />
            <div className="input-group-append">
              <span className="input-group-text">cm</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.width.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Width"
              name="width"
              onChange={onPackagingDataChange}
              value={data.width}
            />
            <div className="input-group-append">
              <span className="input-group-text">cm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Color</label>
        <div className="col-3">
          <div className="input-group">
            <select className={"form-control"} name="color" onChange={onPackagingDataChange} value={data.color}>
              {renderOptions(COLORS)}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

interface CreatePackagingBlisterProps extends CreatePackagingBaseProps {
  data: BlisterData;
}

export const CreatePackagingBlister: React.FunctionComponent<CreatePackagingBlisterProps> = ({
  data,
  onPackagingDataChange
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Depth / Height / Width </label>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.depth.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Depth"
              name="depth"
              onChange={onPackagingDataChange}
              value={data.depth}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.height.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Height"
              name="height"
              onChange={onPackagingDataChange}
              value={data.height}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.width.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Width"
              name="width"
              onChange={onPackagingDataChange}
              value={data.width}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Capsules</label>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.capsules.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Capsules"
              name="capsules"
              onChange={onPackagingDataChange}
              value={data.capsules}
            />
            <div className="input-group-append">
              <span className="input-group-text">pcs</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface CreatePackagingBoxProps extends CreatePackagingBaseProps {
  data: BoxData;
}

export const CreatePackagingBox: React.FunctionComponent<CreatePackagingBoxProps> = ({
  data,
  onPackagingDataChange
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Height / Width / Depth</label>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.height.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Height"
              name="height"
              onChange={onPackagingDataChange}
              value={data.height}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.width.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Width"
              name="width"
              onChange={onPackagingDataChange}
              value={data.width}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.depth.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Depth"
              name="depth"
              onChange={onPackagingDataChange}
              value={data.depth}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Quality</label>
        <div className="col-3">
          <div className="input-group">
            <select className={"form-control"} name="quality" onChange={onPackagingDataChange} value={data.quality}>
              {renderOptions(BOX_QUALITIES)}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

interface CreatePackagingLabelProps extends CreatePackagingBaseProps {
  data: LabelData;
}

export const CreatePackagingLabel: React.FunctionComponent<CreatePackagingLabelProps> = ({
  data,
  onPackagingDataChange
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Height / Width / Quality </label>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.height.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Height"
              name="height"
              onChange={onPackagingDataChange}
              value={data.height}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.width.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Width"
              name="width"
              onChange={onPackagingDataChange}
              value={data.width}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="input-group">
            <select className={"form-control"} name="quality" onChange={onPackagingDataChange} value={data.quality}>
              {renderOptions(LABEL_QUALITIES)}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

interface CreatePackagingSleeveProps extends CreatePackagingBaseProps {
  data: SleeveData;
}

export const CreatePackagingSleeve: React.FunctionComponent<CreatePackagingSleeveProps> = ({
  data,
  onPackagingDataChange
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Size</label>
        <div className="col-3">
          <div className="input-group">
            <select className={"form-control"} name="size" onChange={onPackagingDataChange} value={data.size}>
              {renderOptions(SLEEVE_SIZES)}
            </select>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Printed</label>
        <div className="col-3">
          <div className="custom-control custom-switch custom-switch-lg">
            <input
              type="checkbox"
              className="custom-control-input "
              id="printCheckbox"
              name="print"
              checked={data.print}
              onChange={onPackagingDataChange}
            />
            <label className="custom-control-label align-middle" htmlFor="printCheckbox" />
          </div>
        </div>
      </div>
    </div>
  );
};

interface CreatePackagingStickerProps extends CreatePackagingBaseProps {
  data: StickerData;
}

export const CreatePackagingSticker: React.FunctionComponent<CreatePackagingStickerProps> = ({
  data,
  onPackagingDataChange
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Form / Quality</label>
        <div className="col-3">
          <div className="input-group">
            <select className={"form-control"} name="form" onChange={onPackagingDataChange} value={data.form}>
              {renderOptions(STICKER_FORMS)}
            </select>
          </div>
        </div>
        <div className="col-3">
          <div className="input-group">
            <select className={"form-control"} name="quality" onChange={onPackagingDataChange} value={data.quality}>
              {renderOptions(LABEL_QUALITIES)}
            </select>
          </div>
        </div>
      </div>
      {data.form === StickerForms.ANGULAR ? (
        <div className="form-group row">
          <label className="col-3 col-form-label">Height / Width</label>
          <div className="col-3">
            <div className="input-group">
              <input
                className={"form-control " + (data.height.trim() === "" && "is-invalid")}
                type="number"
                min={0}
                placeholder="Height"
                name="height"
                onChange={onPackagingDataChange}
                value={data.height}
              />
              <div className="input-group-append">
                <span className="input-group-text">mm</span>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="input-group">
              <input
                className={"form-control " + (data.width.trim() === "" && "is-invalid")}
                type="number"
                min={0}
                placeholder="Width"
                name="width"
                onChange={onPackagingDataChange}
                value={data.width}
              />
              <div className="input-group-append">
                <span className="input-group-text">mm</span>
              </div>
            </div>
          </div>
        </div>
      ) : data.form === StickerForms.ROUND ? (
        <div className="form-group row">
          <label className="col-3 col-form-label">Diameter</label>
          <div className="col-3">
            <div className="input-group">
              <input
                className={"form-control " + (data.diameter.trim() === "" && "is-invalid")}
                type="number"
                min={0}
                placeholder="Diameter"
                name="diameter"
                onChange={onPackagingDataChange}
                value={data.diameter}
              />
              <div className="input-group-append">
                <span className="input-group-text">mm</span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

interface CreatePackagingSpoonProps extends CreatePackagingBaseProps {
  data: SpoonData;
}

export const CreatePackagingSpoon: React.FunctionComponent<CreatePackagingSpoonProps> = ({
  data,
  onPackagingDataChange
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Capacity / Color</label>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.capacity.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Capacity"
              name="capacity"
              onChange={onPackagingDataChange}
              value={data.capacity}
            />
            <div className="input-group-append">
              <span className="input-group-text">g</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="input-group">
            <select className={"form-control"} name="color" onChange={onPackagingDataChange} value={data.color}>
              {renderOptions(COLORS)}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

interface CreatePackagingSilicaGelBagProps extends CreatePackagingBaseProps {
  data: SilicaGelBagData;
}

export const CreatePackagingSilicaGelBag: React.FunctionComponent<CreatePackagingSilicaGelBagProps> = ({
  data,
  onPackagingDataChange
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-3 col-form-label">Weight</label>
        <div className="col-3">
          <div className="input-group">
            <input
              className={"form-control " + (data.weight.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Weight"
              name="weight"
              onChange={onPackagingDataChange}
              value={data.weight}
            />
            <div className="input-group-append">
              <span className="input-group-text">g</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
