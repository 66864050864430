import _ from "lodash";
import * as Realm from "realm-web";
import React, { useState } from "react";
import calculationUtils from "../../utils/calculationUtils";
import { SelectedCommoditiesDocument } from "./CustomTypes";
import { CustomActiveSubstance } from "../../model/customTypes.types";

interface SubstanceCalculationModalProps {
  onRecipeSave: (id: Realm.BSON.ObjectId, amount: number) => void;
  commodity: SelectedCommoditiesDocument;
}

export const SubstanceCalculationModal: React.FunctionComponent<SubstanceCalculationModalProps> = React.memo(props => {
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState("0");
  const [substanceAmount, setSubstanceAmount] = useState("0");
  const [unit, setUnit] = useState("mg");
  const { commodity, onRecipeSave } = props;
  const [selectedSubstance, setSelectedSubstance] = useState(
    commodity.activesubstance.length > 0 ? commodity.activesubstance[0] : null
  );

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUnit(e.currentTarget.value);
  };

  const handleSubstanceSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let subStance = commodity.activesubstance.find(
      (c: CustomActiveSubstance) => c._id.toString() === e.currentTarget.value
    )!;
    setSelectedSubstance(subStance);
    setAmount("0");
    setSubstanceAmount("0");
  };

  const handleSubstanceAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let percentagePerMg = selectedSubstance!.value;
    let desiredValue = Number(e.target.value);
    let amount = (desiredValue * 100) / percentagePerMg;
    // Convert substance amount
    let substanceAmount = e.target.value.replaceAll(/^0+/g, "0");
    if (!substanceAmount.includes(".")) substanceAmount = Number(substanceAmount).toString();
    setSubstanceAmount(substanceAmount);
    setAmount(amount.toString());
  };

  /**
   * Adds the commodity to the recipe.
   */
  const handleAddCommodity = () => {
    onRecipeSave(commodity._id, +calculationUtils.convertAmount(amount, unit, "mg"));
    handleClose();
  };

  return (
    <>
      <button
        style={{ marginBottom: 5, border: "none" }}
        type="button"
        className={"btn btn-secondary ml-1 p-0"}
        onClick={handleShow}
      >
        <i className="la la-calculator" />
      </button>
      <div
        className={show ? "modal fade show" : "modal fade d-none"}
        role="dialog"
        style={{
          display: "block",
          margin: "0 auto",
          backgroundColor: "rgba(0,0,0, 0.2)"
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Calculate Active Substance
              </h5>
              <button type="button" className="close" onClick={handleClose}>
                <span>×</span>
                <span className="sr-only">Close</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div>
                  <div className="kt-portlet__body p-0">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="kt-section kt-section--first">
                          <div className="kt-section__body">
                            <br />
                            <h3 className="kt-section__title">Please insert required amount</h3>
                            <div className="form-group mb-0">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <select
                                    className="form-control"
                                    style={{ borderRadius: "10px 0px 0px 10px" }}
                                    name="activesubstance"
                                    id="activesubstance"
                                    value={selectedSubstance!._id.toString()}
                                    disabled={commodity.activesubstance.length === 1}
                                    onChange={handleSubstanceSelect}
                                  >
                                    {commodity.activesubstance.map((activeSub: CustomActiveSubstance) => (
                                      <option key={activeSub._id.toString()} value={activeSub._id.toString()}>
                                        {_.upperFirst(activeSub.name.en)}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <input
                                  className="form-control"
                                  id="substanceAmount"
                                  type="number"
                                  min="0"
                                  value={substanceAmount}
                                  onChange={handleSubstanceAmountChange}
                                  onKeyPress={e => e.key === "Enter" && handleAddCommodity()}
                                />
                                <div className="input-group-append">
                                  <select
                                    className="form-control p-0 pl-2"
                                    name="substanceUnits"
                                    id="substanceUnits"
                                    onChange={handleUnitChange}
                                    value={unit}
                                    style={{ borderRadius: "0px 10px 10px 0px" }}
                                  >
                                    <option value="kg">kg</option>
                                    <option value="g">g</option>
                                    <option value="mg">mg</option>
                                    <option value="ug">&#181;g</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <span className="form-text text-muted">
                              Commodity contains approx.&nbsp;
                              <u>{`${selectedSubstance!.value}% of ${_.upperFirst(selectedSubstance!.name.en)}`}</u>
                            </span>
                          </div>
                          <br />
                          <br />
                          <h3 className="kt-section__title" style={{ float: "right" }}>
                            Required Amount: <b>{calculationUtils.convertAndFormatAmount(amount, unit, 2)}</b>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleClose}>
                Close
              </button>
              <button
                type="button"
                disabled={amount === "" || parseFloat(amount) === 0}
                style={amount === "" || parseFloat(amount) === 0 ? { cursor: "not-allowed" } : {}}
                className="btn btn-primary"
                onClick={handleAddCommodity}
              >
                Update Calculation
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
