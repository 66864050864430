import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { Modal, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { CustomOrder } from "../CustomTypes";
import CompanyWidget from "../../common/CompanyWidget";
import dbGeneralService from "../../../services/dbServices/dbGeneralService";
import orderUtils, { CONTRACT } from "../../../utils/orderUtils";
import dateUtils from "../../../utils/dateUtils";
import userService from "../../../services/userService";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import {
  T_CONVERTEDTOCONTRACT,
  T_ORDERED,
  T_REQUESTAPPROVED,
  T_REQUESTAPPROVEDWITHRAWBIDS
} from "../../../utils/timelineUtils";
import { FILLER_COMMODITIES } from "../../../utils/commodityUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { OrderData } from "../../../model/customTypes.types";
import { Input } from "../../common/Input";
import { DataContext } from "../../../context/dataContext";

interface CreateContractModalProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
  convertExisting?: boolean;
}

interface CreateContractModalState {
  show: boolean;
  saving: boolean;
  orderData: OrderData;
  step: number;
  contractData: Array<ContractData>;
  usedOrderNumbers: Set<string>;
}

interface ContractData {
  _id: BSON.ObjectId;
  date: Date | null;
  value: string;
}

class CreateContractModal extends PureComponent<CreateContractModalProps, CreateContractModalState> {
  constructor(props: CreateContractModalProps) {
    super(props);
    const { orderData, contractData } = this.getDefaultStateValues(props);
    this.state = {
      show: false,
      saving: false,
      orderData,
      step: 1,
      contractData,
      usedOrderNumbers: new Set()
    };
  }

  componentDidUpdate(prevProps: Readonly<CreateContractModalProps>, prevState: Readonly<CreateContractModalState>) {
    // Update on order changes and on show
    if (!_.isEqual(prevProps.order, this.props.order) || (!prevState.show && this.state.show)) {
      const { orderData, contractData } = this.getDefaultStateValues(this.props);
      this.setState({ orderData, contractData });
    }
    if (!this.props.convertExisting && !_.isEqual(prevProps.context.orders, this.props.context.orders)) {
      this.setState({ usedOrderNumbers: this.getAllOrderNumbers() });
    }
  }

  /**
   * Get the default state values
   * @param props the modal properties
   * @returns {{ contractData: Array<ContractData>; orderData: OrderData }} default state values for order data and contract data
   */
  getDefaultStateValues = (
    props: CreateContractModalProps
  ): { contractData: Array<ContractData>; orderData: OrderData } => {
    const { order, convertExisting } = props;
    const orderData = {
      title: order.title,
      subtitle: order.subtitle,
      note: order.note,
      identifier: convertExisting ? order.identifier.toString() : "",
      priority: order.priority,
      targetDate: order.targetDate,
      selectedCalculation: order.calculations[0].id.toString(),
      shelfLife: 24
    };
    const contractData = this.getDefaultContractData(+order.calculations[0].units);
    return { orderData, contractData };
  };

  /**
   * Get all unique order identifiers
   * @returns {Set<string>} a set containing all unique order identifiers
   */
  getAllOrderNumbers = (): Set<string> => {
    const { context } = this.props;
    return new Set(context.orders.map(o => o.identifier.toString()));
  };

  /**
   * Get default contract data
   * @param totalUnits the total units of the currently selected calculation
   * @returns {Array<ContractData>} list of initial calls
   */
  getDefaultContractData = (totalUnits: number): Array<ContractData> => {
    const unitsPerCall = Math.round(totalUnits / 4);
    let calls = [];
    for (let i = 0; i < 4; i++) {
      calls.push({
        _id: new BSON.ObjectId(),
        date: null,
        value: (i === 3 ? totalUnits - 3 * unitsPerCall : unitsPerCall).toString()
      });
    }
    return calls;
  };

  handleShow = () => this.setState({ show: true, step: 1 });
  handleClose = () => !this.state.saving && this.setState({ show: false });

  handleNext = () => {
    const { step } = this.state;
    if (step < 3) this.setState({ step: step + 1 });
  };
  handleBack = () => {
    const { step } = this.state;
    if (step > 1) this.setState({ step: step - 1 });
  };

  handleCreateContract = async () => {
    const { order, convertExisting } = this.props;
    const { orderData, contractData } = this.state;
    const currUser = userService.getUserId();
    const currDate = new Date();
    const { title, subtitle, note, identifier, priority, targetDate, selectedCalculation, shelfLife } = orderData;
    this.setState({ saving: true });
    const contract = contractData.map(c => {
      return {
        _id: c._id,
        date: c.date,
        called: null,
        value: +c.value
      };
    });
    let latestDate = targetDate;
    for (let i = 0; i < contract.length; i++) {
      const c = contract[i];
      if (!latestDate || (c.date && c.date > latestDate)) latestDate = c.date;
    }
    // Get order nr
    const identifierAsNumber = parseInt(identifier, 10);
    const orderNr =
      identifier.length > 0 && identifierAsNumber ? identifierAsNumber : await dbGeneralService.getNextOrderNr();
    const calculations = _.cloneDeep(order.calculations).filter(c => c.id.toString() === selectedCalculation);
    // Handle filler agents, set to delivered and ordered
    calculations[0].prices.forEach(price => {
      if (FILLER_COMMODITIES.includes(price._id.toString())) {
        price.ordered = currDate;
        price.delivered = currDate;
        price.eta = currDate;
        price.userOrdered = currUser;
        price.userDelivered = currUser;
      }
    });
    const timelineEntry = convertExisting
      ? {
          _id: new BSON.ObjectId(),
          type: T_CONVERTEDTOCONTRACT,
          date: currDate,
          person: currUser
        }
      : {
          _id: new BSON.ObjectId(),
          type: T_ORDERED,
          stats: calculations[0].info,
          ordernumber: orderNr,
          date: currDate,
          person: currUser
        };

    const fulfillment = {
      lot: "",
      exp: new Date(0), // set an obviously invalid date
      shelfLife,
      shippingNote: "",
      shippingGroups: []
    };
    const additionalUpdate = {
      $set: {
        title,
        subtitle,
        note,
        identifier: orderNr,
        priority,
        targetDate: latestDate,
        calculations,
        contract,
        fulfillment
      }
    };
    try {
      const result = await dbOrderService.switchState(order._id, CONTRACT, timelineEntry, additionalUpdate);
      if (result && result.modifiedCount) toast.success("Contract successfully placed");
      else toast.error("Contract could not be created");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    }
    this.setState({ saving: false, show: false });
  };

  handleOrderDataChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { order } = this.props;
    const orderData = _.cloneDeep(this.state.orderData);
    const key = e.target.name as keyof OrderData;
    if (key === "targetDate") orderData[key] = (e as React.ChangeEvent<HTMLInputElement>).target.valueAsDate;
    else if (key === "identifier") _.set(orderData, key, e.target.value);
    else _.set(orderData, key, e.target.type === "number" ? +e.target.value : e.target.value);
    if (key === "selectedCalculation") {
      const calc = order.calculations.find(c => c.id.toString() === e.target.value)!;
      this.setState({ orderData, contractData: this.getDefaultContractData(+calc.units) });
    } else this.setState({ orderData });
  };

  handleChangeCallValue = (id: BSON.ObjectId) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const contractData = _.cloneDeep(this.state.contractData);
    const dataSet = contractData.find(c => c._id.toString() === id.toString())!;
    const key = e.target.name as keyof ContractData;
    if (key === "value") dataSet[key] = Number(parseInt(e.target.value) || "0").toString();
    else if (key === "date") dataSet[key] = e.target.valueAsDate;
    this.setState({ contractData });
  };

  handleRemoveCall = (id: BSON.ObjectId) => {
    const { contractData } = this.state;
    this.setState({ contractData: contractData.filter(c => c._id.toString() !== id.toString()) });
  };

  handleAddCall = () => {
    const { order } = this.props;
    const { orderData } = this.state;
    const contractData = _.cloneDeep(this.state.contractData);
    const calc = order.calculations.find(c => c.id.toString() === orderData.selectedCalculation)!;
    const units = calc.units - contractData.reduce((a, b) => a + +b.value, 0);
    contractData.push({ _id: new BSON.ObjectId(), date: null, value: units > 0 ? units.toString() : "0" });
    this.setState({ contractData });
  };

  validateData = () => {
    const { usedOrderNumbers, step, orderData } = this.state;
    const { convertExisting } = this.props;
    const errors: Array<string> = [];
    const warnings: Array<string> = [];
    if (step === 1) {
      if (!orderData.targetDate) errors.push("Please enter a valid target date");
      if (!convertExisting && orderData.identifier && usedOrderNumbers.has(orderData.identifier.toString()))
        warnings.push("Order identifier already exists");
    }
    return [errors, warnings];
  };

  render() {
    const { order, convertExisting, context } = this.props;
    const { show, saving, orderData, step, contractData } = this.state;

    const latestApproval = order.timeline
      .slice()
      .reverse()
      .find(t => t.type === T_REQUESTAPPROVED || t.type === T_REQUESTAPPROVEDWITHRAWBIDS);
    let selectedCalculation = order.calculations.find(c => c.id.toString() === orderData.selectedCalculation)!;
    if (!selectedCalculation) selectedCalculation = order.calculations[0];
    const priceChanges = orderUtils.getCommodityPriceChanges(selectedCalculation);
    const buttonDisabled =
      saving ||
      (step === 1 && !orderData.targetDate) ||
      (step === 2 && contractData.reduce((a, b) => a + +b.value, 0) - +selectedCalculation.units) > 0;
    const convertButtonDisabled =
      convertExisting &&
      (order.calculations[0].prices.some(p => !FILLER_COMMODITIES.includes(p._id.toString()) && p.ordered) ||
        order.calculations[0].packagings.some(p => p.ordered));
    const [errors, warnings] = this.validateData();
    return (
      <>
        {convertExisting ? (
          <ErrorOverlayButton
            errors={convertButtonDisabled ? ["A non-filler commodity or packaging is already ordered"] : []}
            className={"btn btn-sm btn-upper btn-secondary"}
            buttonText={"Convert To Contract"}
            onClick={this.handleShow}
          />
        ) : (
          <>
            <button
              type="button"
              className="btn btn-sm btn-success dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown"
              style={{ marginLeft: 0 }}
            />
            <div className="dropdown-menu dropdown-menu-right">
              <h6 className="dropdown-header pointer" onClick={this.handleShow}>
                Create Contract
              </h6>
            </div>
          </>
        )}
        <Modal show={show} onHide={this.handleClose} size={"lg"} centered name={"CreateContractModal"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand flaticon2-pie-chart mr-2" />
              Contract Settings
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="kt-portlet__body">
              <div className="row">
                <div className="col-xl-12">
                  <div className="kt-section kt-section--first">
                    <div className="kt-section__body">
                      {step === 1 && (
                        <Table>
                          <thead>
                            <tr>
                              <th colSpan={2}>
                                <h3 className="font-weight-bolder">Create Contract for Offer AN-{order.identifier}</h3>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="align-middle font-weight-bold">Title:</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="title"
                                  value={orderData.title}
                                  onChange={this.handleOrderDataChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">AT-Number:</td>
                              <td>
                                <Input
                                  type="number"
                                  min={0}
                                  className="form-control"
                                  name="identifier"
                                  disabled={convertExisting}
                                  value={orderData.identifier}
                                  integerOnly={true}
                                  showEmpty={true}
                                  onChange={this.handleOrderDataChange}
                                />
                                <span className="text-success">Recommended: Leave empty for automated generation.</span>
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">Short description (optional):</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  aria-label="Text input with dropdown button"
                                  name="subtitle"
                                  value={orderData.subtitle}
                                  onChange={this.handleOrderDataChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">Note (optional):</td>
                              <td>
                                <textarea
                                  className="form-control"
                                  rows={5}
                                  name="note"
                                  value={orderData.note}
                                  onChange={this.handleOrderDataChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">Priority:</td>
                              <td>
                                <select
                                  name="priority"
                                  className="form-control"
                                  value={orderData.priority}
                                  onChange={this.handleOrderDataChange}
                                >
                                  <option value="high">High Priority</option>
                                  <option value="medium">Medium Priority</option>
                                  <option value="low">Low Priority</option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">Target Date:</td>
                              <td>
                                <input
                                  type="date"
                                  min={orderUtils.calculateEarliestDeliveryDate(order).toISOString().split("T")[0]}
                                  className="form-control"
                                  name="targetDate"
                                  value={orderData.targetDate ? orderData.targetDate.toISOString().split("T")[0] : ""}
                                  onChange={this.handleOrderDataChange}
                                />
                                <span className="text-danger">
                                  Please note that the target date must be in accordance with all delivery dates of raw
                                  materials and packaging.
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">Product Shelf Life:</td>
                              <td>
                                <div className="input-group">
                                  <Input
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    name="shelfLife"
                                    integerOnly={true}
                                    value={orderData.shelfLife}
                                    onChange={this.handleOrderDataChange}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">months</span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">Calculation:</td>
                              <td>
                                <select
                                  name="selectedCalculation"
                                  className="form-control"
                                  value={orderData.selectedCalculation}
                                  onChange={this.handleOrderDataChange}
                                >
                                  {order.calculations.map(calculation => (
                                    <option key={calculation.id.toString()} value={calculation.id.toString()}>
                                      {calculation.units} Units (
                                      {calculation.info.totalprice.toLocaleString("de-DE", {
                                        style: "currency",
                                        currency: "EUR"
                                      })}
                                      )
                                    </option>
                                  ))}
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">Customer:</td>
                              <td className="align-middle">
                                <div className="kt-widget__info h-100">
                                  <CompanyWidget company={order.createdFor} />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      )}
                      {step === 2 && (
                        <div className="form-group mb-3">
                          <h3 className="kt-section__title">{"Contract Settings"}</h3>
                          {contractData.length > 0 ? (
                            contractData.map(call => (
                              <ContractSettingsRow
                                key={call._id.toString()}
                                callData={call}
                                targetDate={orderData.targetDate!}
                                onContractSettingsChange={this.handleChangeCallValue(call._id)}
                                onRemoveCall={() => this.handleRemoveCall(call._id)}
                              />
                            ))
                          ) : (
                            <div className="text-center">
                              <p className="kt-font-bold kt-font-dark">Order as required.</p>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-5" />
                            <div className="col-5" />
                            <div className="col-2 mt-2 text-right">
                              <button type="button" className="btn btn-success" onClick={this.handleAddCall}>
                                <i className="fa fa-plus" style={{ paddingRight: ".5rem", paddingLeft: ".5rem" }} />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {step === 3 && (
                        <p style={{ fontSize: "1.3rem", color: "#48465b", fontWeight: 600, textAlign: "center" }}>
                          <br />
                          <br />
                          {priceChanges > 0 ? (
                            <i className="fa fa-exclamation text-danger" />
                          ) : (
                            <i className="fa fa-check-square text-success mr-2" />
                          )}
                          {priceChanges < 0
                            ? "Commodity prices decreased"
                            : priceChanges > 0
                            ? "Commodity prices increased"
                            : "Commodity prices as expected"}
                          <br />
                          <span style={{ fontSize: "1rem", fontWeight: 300 }}>
                            Checked {dateUtils.getTimeAgo(latestApproval.date)}
                          </span>
                          <br />
                          <br />
                          <span className={priceChanges > 0 ? "text-danger" : "text-success"}>
                            {priceChanges === 0 ? "Still" : "Now"}{" "}
                            {Math.round(selectedCalculation.info.percentmargin * 100) / 100}% Margin
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={"btn btn-secondary " + (saving && "disabled")}
              disabled={saving}
              onClick={this.handleClose}
            >
              Close
            </button>
            {step > 1 && (
              <button
                className={"btn btn-secondary " + (saving && "disabled")}
                disabled={saving}
                onClick={this.handleBack}
              >
                Back
              </button>
            )}
            <ErrorOverlayButton
              buttonText={
                <>
                  {saving && (
                    <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                      <svg className="button-splash-spinner" viewBox="0 0 50 50">
                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                      </svg>
                    </div>
                  )}
                  {step <= 2 ? "Next" : "Place Contract"}
                </>
              }
              className="btn btn-success"
              disabled={buttonDisabled}
              errors={errors}
              saving={saving}
              warnings={warnings}
              onClick={step <= 2 ? this.handleNext : this.handleCreateContract}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

interface ContractSettingsRowProps {
  callData: ContractData;
  targetDate: Date;
  onContractSettingsChange: (e: any) => void;
  onRemoveCall: () => void;
}

const ContractSettingsRow: React.FunctionComponent<ContractSettingsRowProps> = ({
  callData,
  targetDate,
  onContractSettingsChange,
  onRemoveCall
}) => {
  const { value, date } = callData;
  return (
    <div className="row mb-3">
      <div className="col-5">
        <div className="input-group">
          <input
            className="form-control"
            type="number"
            min={0}
            name="value"
            onChange={onContractSettingsChange}
            value={value}
          />
          <div className={"input-group-append"}>
            <span className="input-group-text">units</span>
          </div>
        </div>
      </div>
      <div className="col-5">
        <div className="input-group">
          <input
            name="date"
            type="date"
            min={targetDate.toISOString().split("T")[0]}
            className="form-control"
            onChange={onContractSettingsChange}
            value={date ? date.toISOString().split("T")[0] : ""}
          />
        </div>
      </div>
      <div className="col-2 text-right">
        <button className="btn btn-danger" onClick={onRemoveCall}>
          <i className="fa fa-trash" style={{ paddingRight: ".5rem", paddingLeft: ".5rem" }} />
        </button>
      </div>
    </div>
  );
};

export default CreateContractModal;
