import _ from "lodash";
import React, { PureComponent } from "react";
import Chart from "react-apexcharts";
import { OutputData } from "../CustomTypes";
import dashboardUtils from "../../../utils/dashboardUtils";

interface OutputTargetProjectionProps {
  targetOutput: number;
  targetOutputTurnover: number;
  targetOutputProfit: number;
  dataType: "units" | "turnover" | "profit";
  outputHistory: Array<OutputData>;
  year: number;
}

interface OutputTargetProjectionState {
  series: Array<{ name: string; data: Array<number> | Array<{ x: string; y: number }> }>;
  options: any;
}

class OutputTargetProjection extends PureComponent<OutputTargetProjectionProps, OutputTargetProjectionState> {
  constructor(props: OutputTargetProjectionProps) {
    super(props);
    this.state = {
      series: [
        {
          name: "Target",
          data: this.generateTarget()
        },
        {
          name: "Actual",
          data: this.generateActual()
        },
        {
          name: "Projection",
          data: dashboardUtils.createProjection(props.outputHistory, props.dataType, props.year)
        }
      ],
      options: {
        chart: {
          type: "area",
          id: "basic-bar-" + props.dataType,
          toolbar: {
            show: false
          }
        },
        xaxis: {
          type: "datetime"
        },
        yaxis: {
          labels: {
            formatter: (v: string) => (+(+v).toFixed(0)).toLocaleString()
          }
        },
        stroke: {
          curve: "straight",
          dashArray: 9
        },
        dataLabels: { enabled: false },
        colors: ["#ff4b4b", "#00d185", "#00d185"],
        fill: { type: "solid", opacity: 0.25, colors: ["#ffffff", "#00d185", "#ffffff"] }
      }
    };
  }

  componentDidUpdate(
    prevProps: Readonly<OutputTargetProjectionProps>,
    prevState: Readonly<OutputTargetProjectionState>,
    snapshot?: any
  ) {
    const { outputHistory, dataType, year } = this.props;
    if (!_.isEqual(prevProps.outputHistory, outputHistory) || prevProps.dataType !== dataType) {
      const series = _.cloneDeep(this.state.series);
      series[0].data = this.generateTarget();
      series[1].data = this.generateActual();
      series[2].data = dashboardUtils.createProjection(outputHistory, dataType, year);
      this.setState({ series });
    }
  }

  /**
   * Generate the data for the target line
   * @returns { Array<{x: string, y: number}> } Array of data points
   */
  generateTarget = () => {
    const { dataType, targetOutput, targetOutputProfit, targetOutputTurnover, year } = this.props;
    const curYear = new Date().getFullYear();
    // Target not shown for the past
    if (year !== curYear) return [];
    const perStep =
      (dataType === "units" ? targetOutput : dataType === "turnover" ? targetOutputTurnover : targetOutputProfit) / 12;
    const target = [];
    for (let i = 0; i <= 12; i++) {
      target.push({ x: new Date(curYear, i, 1, 2).toISOString(), y: i * perStep });
    }
    return target;
  };

  /**
   * Generate the date for the actual line
   * @returns { Array<{x: string, y: number}> } Array of data points
   */
  generateActual = () => {
    const { outputHistory, dataType } = this.props;
    return outputHistory.map(oh => {
      return {
        x: oh.t.toISOString(),
        y: dataType === "units" ? oh.y : dataType === "turnover" ? oh.totalTurnover : oh.totalMargin
      };
    });
  };

  render() {
    const { options, series } = this.state;
    return <Chart series={series} type="area" options={options} heigth="700" width="100%" />;
  }
}
export default OutputTargetProjection;
