import React, { PureComponent } from "react";
import Select from "react-select";
import i18n from "../../translations/i18n";

interface LanguageSelectionDropdownProps {
  additionalWrapperStyles?: React.CSSProperties;
  wrapperClasses?: string;
  labelClasses?: string;
  labelColumnClasses?: string;
  selectClasses?: string;
  selectColumnClasses?: string;
  label?: string;
  labelPosition: "bottom" | "front";
  languageChangeCallback?: () => void;
}

interface LanguageSelectionDropdownState {}

export interface LanguageOption {
  value: string;
  label: string;
}
export const languageOptions: Array<LanguageOption> = [
  { value: "de", label: "German" },
  { value: "en", label: "English" }
];

class LanguageSelectionDropdown extends PureComponent<LanguageSelectionDropdownProps, LanguageSelectionDropdownState> {
  componentDidMount() {
    window.addEventListener("storage", async e => {
      if (e.newValue && ["de", "en"].includes(e.newValue) && e.key === "i18nextLng") {
        await i18n.changeLanguage(e.newValue);
        this.forceUpdate();
        if (this.props.languageChangeCallback) this.props.languageChangeCallback();
      }
    });
  }

  handlePdfLanguageChange = async (pdfLanguage: string) => {
    await i18n.changeLanguage(pdfLanguage);
    this.forceUpdate();
    if (this.props.languageChangeCallback) this.props.languageChangeCallback();
  };

  render() {
    const {
      additionalWrapperStyles,
      labelPosition,
      wrapperClasses,
      labelColumnClasses,
      labelClasses,
      selectColumnClasses,
      selectClasses,
      label
    } = this.props;
    const pdfLanguage = languageOptions.find(l => l.value === i18n.language) || { value: "de", label: "German" };
    const select = (
      <Select
        className={selectClasses ? selectClasses : "select-default " + (labelPosition === "front" ? "col" : "")}
        options={languageOptions}
        isSearchable={false}
        value={pdfLanguage}
        onChange={(value: any) => this.handlePdfLanguageChange(value.value)}
      />
    );
    return labelPosition === "front" ? (
      <div style={additionalWrapperStyles} className={wrapperClasses}>
        <div className={labelColumnClasses}>
          <span className={labelClasses}>{label ? label : "Offer Language"}</span>
        </div>
        {selectColumnClasses ? <div className={selectColumnClasses}>{select}</div> : select}
      </div>
    ) : (
      <div style={additionalWrapperStyles} className={wrapperClasses}>
        {select}
        <span className={labelClasses}>{label ? label : "Offer Language"}</span>
      </div>
    );
  }
}

export default LanguageSelectionDropdown;
