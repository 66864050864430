import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import i18n from "../../../../translations/i18n";
import { useDataContext } from "../../../../context/dataContext";
import { ContentType } from "../../../../model/warehouse/common.types";
import { SenderType } from "../../../../model/warehouse/batch.types";
import { OrdersDocument } from "../../../../model/orders.types";
import { CommodityReservationInformation, CommodityInformation } from "../../../../model/warehouse/customTypes.types";
import { BatchStatus, getBatchStatus } from "../../../../utils/batchUtils";
import BaseListing, { BaseTableHeaderDefinition } from "../../../listings/BaseListing";
import { paginate } from "../../../common/Pagination";
import ReservationRow from "./ReservationRow";

interface OrderReservationBatchSelectionProps {
  selectedOrder: OrdersDocument;
  selectedCommodity: CommodityInformation | undefined;
  orderReservations: Array<CommodityReservationInformation>;
}

const OrderReservationBatchSelection: React.FC<OrderReservationBatchSelectionProps> = ({
  selectedOrder,
  selectedCommodity,
  orderReservations
}) => {
  const dataContext = useDataContext();
  const { batch, reservation } = dataContext;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [showBatches, setShowBatches] = useState<boolean>(false);

  const handleChangePage = useCallback(currentPage => setCurrentPage(currentPage), []);
  const handleChangePageSize = useCallback(pageSize => setPageSize(pageSize), []);
  const handleToggleBatches = useCallback(() => setShowBatches(prevState => !prevState), []);

  const tableHeader: Array<BaseTableHeaderDefinition> = [
    { title: i18n.t("warehouse:supplier"), additionalColumnClasses: "pb-3" },
    { title: i18n.t("warehouse:batch"), additionalColumnClasses: "pb-3" },
    { title: i18n.t("warehouse:bbd"), additionalColumnClasses: "pb-3" },
    { title: i18n.t("warehouse:amount"), additionalColumnClasses: "pb-3" },
    { title: i18n.t("warehouse:warehouse"), additionalColumnClasses: "pb-3" }
  ];

  const filteredBatches = useMemo(() => {
    let filBatches = [];
    if (!selectedCommodity) return [];
    const orderCreatedFor = selectedOrder.createdFor.toString();

    filBatches = batch.filter(b => {
      const batchSender = b.sender.senderId;
      const batchSenderType = b.sender.type;
      const batchStatus = getBatchStatus(b);

      const batchMatchesCommodity =
        b.content.type === ContentType.COMMODITY && b.content.details._id.toString() === selectedCommodity.commodityId;
      const batchHasCorrectSender =
        batchSenderType !== SenderType.CUSTOMER ||
        (batchSenderType === SenderType.CUSTOMER && batchSender === orderCreatedFor); // Exclude Kundenbeistellungen from other customers
      const batchNotBlocked = batchStatus !== BatchStatus.BLOCKED;

      return batchMatchesCommodity && batchHasCorrectSender && batchNotBlocked;
    });

    return _.orderBy(filBatches, b => b.expiry, ["asc"]);
  }, [batch, selectedOrder, selectedCommodity, reservation, orderReservations]);

  return (
    <>
      <h4 className="font-weight-bold text-black">
        {`${filteredBatches.length} ${i18n.t(`warehouse:availableBatches`)} ${
          selectedCommodity ? `- ${selectedCommodity.commodityName}` : ""
        }`}
        <button className="btn btn-text" onClick={handleToggleBatches}>
          <i className={"fa " + (showBatches ? "fa-chevron-up" : "fa-chevron-down")} />
          <span className="kt-hidden-mobile">{showBatches ? i18n.t("common:collapse") : i18n.t("common:expand")}</span>
        </button>
      </h4>
      {showBatches && (
        <>
          {filteredBatches.length > 0 ? (
            <div className="kt-portlet__body px-0 pt-0 overflow-auto" style={{ maxHeight: "40vh" }}>
              <BaseListing
                headerDefinition={tableHeader}
                documents={filteredBatches}
                bodyContent={
                  <>
                    {paginate(filteredBatches, currentPage, pageSize).map(batch => (
                      <ReservationRow key={batch._id.toString()} batch={batch} />
                    ))}
                  </>
                }
                currentPage={currentPage}
                pageSize={pageSize}
                onPageChange={handleChangePage}
                onPageSizeChange={handleChangePageSize}
              />
            </div>
          ) : (
            <div className="text-black">{i18n.t("warehouse:reservationNoBatchesFoundError")}</div>
          )}
        </>
      )}
    </>
  );
};

export default OrderReservationBatchSelection;
