import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { NewsDocument } from "../../model/news.types";
import baseUtils from "../../utils/baseUtils";
import dbNewsService from "../../services/dbServices/dbNewsService";

interface NewsObjectProps {
  newsObject: NewsDocument;
}

interface NewsObjectState {}

class NewsObject extends PureComponent<NewsObjectProps, NewsObjectState> {
  handleDelete = async () => {
    try {
      const result = await dbNewsService.deleteNewsObject(this.props.newsObject._id);
      if (result && result.deletedCount) toast.success("News successfully deleted");
      else toast.error("Deletion failed");
    } catch (e) {
      toast.error("Deletion of news failed: " + e.message);
    }
  };

  render() {
    const { newsObject } = this.props;
    return (
      <div
        className="kt-portlet kt-portlet--height-fluid kt-widget19"
        style={{ background: "rgb(247, 248, 250)", boxShadow: "none" }}
      >
        <div className="kt-portlet__body">
          <div style={{ display: "block" }}>
            <h5 className="kt-font-dark" style={{ display: "inline", float: "left" }}>
              {baseUtils.truncateString(newsObject.header, 25)}
            </h5>
            <button className="btn btn-danger btn-sm p-1 ml-1" onClick={this.handleDelete}>
              <i className="fa fa-trash btn-icon-sm px-1" />
            </button>
            <span className="kt-font-dark" style={{ display: "inline", float: "right" }}>
              {newsObject.date.toLocaleDateString()}
            </span>
          </div>
          <div className="kt-font-dark opacity-5 d-flex" style={{ display: "block" }}>
            {newsObject.picture && (
              <img
                src={newsObject.picture}
                className="text-right"
                style={{
                  width: "80px",
                  height: "80px",
                  display: "inline",
                  borderRadius: "10px",
                  padding: "5px",
                  objectFit: "cover"
                }}
              />
            )}
            <span>{baseUtils.truncateString(newsObject.content, newsObject.picture ? 195 : 300)}</span>
          </div>
          <div className="kt-widget13 mb-2">
            <div className="kt-widget13__item" style={{ display: "block", margin: "0px auto" }} />
            <span className="kt-font-bold kt-font-dark" style={{ fontSize: 12 }}>
              Tags:&nbsp;&nbsp;
            </span>
            {newsObject.tags.map(tag => (
              <span
                key={tag.toString()}
                className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded mr-2"
              >
                <span className="kt-font-bold">{tag}</span>
              </span>
            ))}
          </div>
          <Link
            to={{
              pathname: "/create-news",
              search: "?id=" + newsObject._id.toString()
            }}
          >
            <span
              style={{ float: "right", display: "block", textAlign: "right" }}
              className=" kt-font-dark pointer mt-1"
            >
              <u>Edit article</u>
            </span>
          </Link>
        </div>
      </div>
    );
  }
}

export default NewsObject;
