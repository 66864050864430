import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";
import ExternalManufacturerHelper from "../ExternalManufacturerHelper";
import { ExtendedEMCommodity } from "../../../model/customTypes.types";
import { EMCommoditiesRow } from "../commodities/ExternalManufacturersCommodities";
import EMCommodityRequestModal from "../modals/EMCommodityRequestModal";
import baseUtils, { getComponentState } from "../../../utils/baseUtils";
import { SearchBar } from "../../listings/common/Filters";

interface EmDashboardCommoditiesProps extends RouteComponentProps<{}, {}, {}> {
  context: React.ContextType<typeof ExternalManufacturerContext>;
}

interface EmDashboardCommoditiesState {
  search: string;
  commoditiesShown: number;
  commodities: Array<ExtendedEMCommodity>;
  commodityToOrder: ExtendedEMCommodity | null;
}

class EmDashboardCommodities extends PureComponent<EmDashboardCommoditiesProps, EmDashboardCommoditiesState> {
  constructor(props: EmDashboardCommoditiesProps) {
    super(props);
    this.state = {
      commodityToOrder: null,
      search: "",
      commoditiesShown: 25,
      commodities: []
    };
  }

  componentDidMount() {
    this.setState({ commodities: this.getExtendedCommodities(this.props) });
  }

  componentDidUpdate(
    prevProps: Readonly<EmDashboardCommoditiesProps>,
    prevState: Readonly<EmDashboardCommoditiesState>,
    snapshot?: any
  ) {
    if (!_.isEqual(this.props.context, prevProps.context)) {
      this.setState({ commodities: this.getExtendedCommodities(this.props) });
    }
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });
  handleOrderCommodity = (commodity: ExtendedEMCommodity) => this.setState({ commodityToOrder: commodity });
  handleCloseOrderModal = () => this.setState({ commodityToOrder: null });
  handleShowMore = () => this.setState({ commoditiesShown: this.state.commoditiesShown + 25 });

  /**
   * Extend commodities with data
   * @param props components properties
   * @returns List of extended commodities
   */
  getExtendedCommodities = (props: EmDashboardCommoditiesProps) => {
    const { context } = props;
    const { commodities } = context;
    let extendedCommodities: Array<ExtendedEMCommodity> = [];
    for (let i = 0; i < commodities.length; i++) {
      const commodity = commodities[i];
      extendedCommodities.push(
        ExternalManufacturerHelper.getExtendedCommodity(commodity, context) as ExtendedEMCommodity
      );
    }
    return extendedCommodities;
  };

  getFilteredCommodities = () => {
    const { commodities, search } = this.state;
    let filteredCommodities = commodities.slice();
    if (search.trim() === "") return filteredCommodities;
    return baseUtils.doFuseSearch(filteredCommodities, search, [
      "title.en",
      "title.de",
      "subtitle.en",
      "subtitle.de",
      "category.name.en",
      "category.name.de",
      "form.name.en",
      "form.name.de",
      "composition.name.de",
      "composition.name.en",
      "activesubstance.name.de",
      "activesubstance.name.en",
      "properties.name.de",
      "properties.name.de",
      "country"
    ]) as Array<ExtendedEMCommodity>;
  };

  render() {
    const { context } = this.props;
    const { commodityToOrder, search, commoditiesShown } = this.state;
    const { commodities } = context;
    const filteredCommodities = this.getFilteredCommodities();
    return (
      <>
        <EMCommodityRequestModal commodity={commodityToOrder} onClose={this.handleCloseOrderModal} />
        <div className="kt-portlet kt-portlet--height-fluid" style={{ minHeight: "40vh" }}>
          <div className="kt-portlet__head pr-0">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title kt-font-bolder">Commodities</h3>
            </div>
            <div className="w-100 my-auto">
              <SearchBar
                buttonClasses={"btn btn-light btn-sm float-right"}
                additionalSizeClasses={"col-md-4 float-right"}
                onSearch={this.handleSearch}
                search={search}
              />
            </div>
          </div>
          <div className="kt-portlet__body kt-portlet__body--fit">
            {commodities.length === 0 ? (
              <div className="text-center text-muted my-5">No commodities are currently expected to be ordered</div>
            ) : (
              <div
                className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive"
                style={{ maxHeight: "50vh" }}
              >
                <table className="kt-datatable__table d-table">
                  <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
                    <tr className="kt-datatable__row d-table-row">
                      <th className="kt-datatable__cell d-table-cell" style={{ width: "35%" }}>
                        <span>Commodity</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell" style={{ width: "20%" }}>
                        <span>Category</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell" style={{ width: "15%" }}>
                        <span>Required</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell" style={{ width: "20%" }}>
                        <span>Price Range (kg)</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }} />
                    </tr>
                  </thead>
                  <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                    {filteredCommodities.slice(0, commoditiesShown).map(commodity => {
                      return (
                        <EMCommoditiesRow
                          key={commodity._id.toString()}
                          commodity={commodity}
                          onOrderCommodity={this.handleOrderCommodity}
                          {...this.props}
                          dashboard={true}
                        />
                      );
                    })}
                    <tr>
                      <td colSpan={10}>
                        {filteredCommodities.length > commoditiesShown && (
                          <div className="text-center py-4">
                            <button className="m-0 p-0 button-link text-muted btn-lg" onClick={this.handleShowMore}>
                              Show More
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default EmDashboardCommodities;
