import { GeneralDocument } from "../model/general.types";

/**
 * Retrieve the next free internal code number for the given reference.
 * @param internalCodeReference Code reference whose next free number should be resolved
 * @param general General collection
 * @returns { string } Next free number as string
 */
function getNextInternalCode(internalCodeReference: string, general: Array<GeneralDocument>): string {
  return (+general.find(g => g.data === internalCodeReference)!.value + 1).toString();
}

/**
 * Retrieve the next free organic code value. This should only be used for temporary numbers. See dbGeneralService for
 * guaranteed unique codes.
 * @param general General collection
 * @returns { string } Next free organic code as string
 */
function getNextOrganicCode(general: Array<GeneralDocument>): string {
  return getNextInternalCode("internal_code_60000", general);
}

export default { getNextInternalCode, getNextOrganicCode };
