import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { SupplierSelected } from "./CustomTypes";
import SupplierPricesEntry from "./SupplierPricesEntry";
import CompanyWidget from "../CompanyWidget";
import { PaginationState } from "../CustomTypes";
import Pagination, { paginate } from "../Pagination";
import PersonWidget from "../PersonWidget";
import RatingInfo from "../RatingInfo";
import { DataContext } from "../../../context/dataContext";
import { CapsulesDocument, CapsuleSupplierType } from "../../../model/capsules.types";
import { CommoditiesDocument } from "../../../model/commodities.types";
import { SuppliersDocument } from "../../../model/suppliers.types";
import suppliersUtils from "../../../utils/suppliersUtils";
import { PackagingsDocument } from "../../../model/packagings.types";
import RawbidsPricesEntry from "./RawbidsPricesEntry";
import baseUtils from "../../../utils/baseUtils";
import {
  convertRawbidsPrice,
  getRawbidsSupplierId,
  RawbidsPrice,
  RawbidsPricesResult
} from "../../../utils/rawbidsUtils";
import { ManufacturersDocument } from "../../../model/manufacturers.types";
import manufacturerUtils from "../../../utils/manufacturerUtils";
import ManufacturerWidget from "../ManufacturerWidget";

interface SupplierPricesSelectionProps {
  document?: CapsulesDocument | CommoditiesDocument | PackagingsDocument;
  type: "Capsule" | "Commodity" | "Custom" | "Packaging" | "Service" | "Softgel";
  onSuppliersSelectedChange: (suppliersSelected: Array<SupplierSelected>) => void;
  disabled?: boolean;
  allowManufacturer?: boolean;
}

interface SupplierPricesSelectionState extends PaginationState {
  searchQuery: string;
  providersSelected: Array<SupplierSelected>;
  activeKey: string;
  listingType: { value: CapsuleSupplierType | undefined; label: string };
  allowManufacturer: boolean;
}

class SupplierPricesSelection extends PureComponent<SupplierPricesSelectionProps, SupplierPricesSelectionState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: SupplierPricesSelectionProps) {
    super(props);
    this.state = {
      searchQuery: "",
      pageSize: 5,
      currentPage: 1,
      listingType: { value: CapsuleSupplierType.SUPPLIER, label: "Supplier" },
      providersSelected: [],
      activeKey: "",
      allowManufacturer: !!props.allowManufacturer && props.type === "Capsule" // double check
    };
  }

  componentDidMount() {
    const { allowManufacturer, type } = this.props;
    if (!!allowManufacturer && type !== "Capsule")
      console.error("Manufacturer selection is only supported for capsules");
    this.prepareSuppliers();
  }

  componentDidUpdate(prevProps: Readonly<SupplierPricesSelectionProps>) {
    const { document, allowManufacturer, type } = this.props;
    if (allowManufacturer !== prevProps.allowManufacturer) {
      if (!!allowManufacturer && type !== "Capsule")
        console.error("Manufacturer selection is only supported for capsules");
      this.setState({ allowManufacturer: !!allowManufacturer && type === "Capsule" });
    }
    if (!_.isEqual(document, prevProps.document)) {
      this.prepareSuppliers();
    }
  }

  /**
   * Update the search query after input.
   * @param e Event of the input field
   */
  handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ searchQuery: e.target.value });

  /**
   * Update the active key
   * @param activeKey new active key or empty string if no active key
   */
  handleChangeActiveKey = (activeKey: string) => this.setState({ activeKey });

  /**
   * Handles the addition of a new supplier or manufacturer. A default price is added.
   * @param _id ID of the supplier or manufacturer that should be added
   */
  handleAddProviderClick = (_id: BSON.ObjectId) => {
    const { type, onSuppliersSelectedChange } = this.props;
    const { listingType } = this.state;
    const providersSelected = _.cloneDeep(this.state.providersSelected);
    providersSelected.push({
      _id,
      prices: [suppliersUtils.getDefaultSupplierSelectedPrice(type)],
      type: listingType.value
    });
    this.setState({ providersSelected, activeKey: _id.toString() });
    onSuppliersSelectedChange(providersSelected);
  };

  /**
   * Handles removing a supplier or manufacturer.
   * @param _id ID of the supplier or manufacturer that should be removed
   */
  handleRemoveProviderClick = (_id: BSON.ObjectId) => {
    const { onSuppliersSelectedChange } = this.props;
    const { providersSelected } = this.state;
    const provSel = providersSelected.filter(s => s._id.toString() !== _id.toString());
    this.setState({ providersSelected: provSel });
    onSuppliersSelectedChange(provSel);
  };

  /**
   * Handles the addition of a new price to the given supplier or manufacturer.
   * @param _id ID of the supplier or manufacturer that should have an additional price
   */
  handleAddNewPriceClick = (_id: BSON.ObjectId) => {
    const { type, onSuppliersSelectedChange } = this.props;
    const providersSelected = _.cloneDeep(this.state.providersSelected);
    const provider = providersSelected.find(s => s._id.toString() === _id.toString())!;
    provider.prices.push(suppliersUtils.getDefaultSupplierSelectedPrice(type));
    this.setState({ providersSelected: providersSelected });
    onSuppliersSelectedChange(providersSelected);
  };

  /**
   * Handles the removal of a price from a supplier or manufacturer.
   * @param provider ID of the supplier or manufacturer
   * @param price ID of the price that should be removed
   */
  handleRemovePriceClick = (provider: BSON.ObjectId, price: BSON.ObjectId) => {
    const { onSuppliersSelectedChange } = this.props;
    const providersSelected = _.cloneDeep(this.state.providersSelected);
    const providerSelected = providersSelected.find(s => s._id.toString() === provider.toString())!;
    providerSelected.prices = providerSelected.prices.filter(p => p._id.toString() !== price.toString());
    this.setState({ providersSelected: providersSelected });
    onSuppliersSelectedChange(providersSelected);
  };

  /**
   * Handles the change of values that are stored as numbers.
   * @param provider ID of the supplier or manufacturer that is changed
   * @param price ID of the price that is changed
   * @param e Event that triggered the change
   */
  handleNumberChange = (provider: BSON.ObjectId, price: BSON.ObjectId, e: React.ChangeEvent<HTMLInputElement>) => {
    const { onSuppliersSelectedChange } = this.props;
    const providersSelected = _.cloneDeep(this.state.providersSelected);
    const prices = providersSelected.find(s => s._id.toString() === provider.toString())!.prices;
    const idx = prices.findIndex(p => p._id.toString() === price.toString());
    let val = e.target.value;
    val = val.replaceAll(/^0+/g, "0");
    if (!val.includes(".")) val = Number(val).toString();
    if (idx === -1 || (!val && val !== "0") || Number(val) < 0) return;
    switch (e.target.name) {
      case "totalPrice":
        prices[idx].totalPrice = val;
        break;
      case "purchasePrice":
        prices[idx].purchasePrice = val;
        break;
      case "moq":
        prices[idx].moq = val;
        break;
      case "deliveryTime":
        prices[idx].deliveryTime = val;
        break;
    }
    prices[idx].age = new Date();
    this.setState({ providersSelected: providersSelected });
    onSuppliersSelectedChange(providersSelected);
  };

  /**
   * Handles the change of values that are stored as strung
   * @param provider ID of the supplier or manufacturer that is changed
   * @param price ID of the price that is changed
   * @param e Event that triggered the change
   */
  handleStringChange = (
    provider: BSON.ObjectId,
    price: BSON.ObjectId,
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { onSuppliersSelectedChange } = this.props;
    const providersSelected = _.cloneDeep(this.state.providersSelected);
    const prices = providersSelected.find(s => s._id.toString() === provider.toString())!.prices;
    const idx = prices.findIndex(p => p._id.toString() === price.toString());
    if (idx === -1) return;
    switch (e.target.name) {
      case "incoterm":
        prices[idx].incoterm = e.target.value;
        break;
      case "note":
        prices[idx].note = e.target.value;
        break;
      case "purchaseCurrency":
        prices[idx].purchasePriceCurrency = e.target.value;
        break;
    }
    prices[idx].age = new Date();
    this.setState({ providersSelected: providersSelected });
    onSuppliersSelectedChange(providersSelected);
  };

  handleTypeChange = (entry: { value: CapsuleSupplierType | undefined; label: string }) => {
    this.setState({ listingType: entry });
  };

  handleUpdateRawbidsSupplier = (prices: RawbidsPricesResult) => {
    const { onSuppliersSelectedChange } = this.props;
    const providersSelected = _.cloneDeep(this.state.providersSelected);
    const rbSupplier = providersSelected.find(s => suppliersUtils.isRawbidsSupplier(s));
    if (!rbSupplier) {
      toast.error("Supplier could not be updated");
      return;
    }
    if (prices.hasPrices) {
      const allPrices: Array<[type: string, price: RawbidsPrice]> = [];
      Object.entries(prices.prices).forEach(([type, pricesForType]) => {
        if (pricesForType.length === 0) return;
        pricesForType.forEach(p => allPrices.push([type, p]));
      });
      rbSupplier.prices = allPrices
        .map(([type, p]) => convertRawbidsPrice(type, p))
        .sort((p1, p2) => +p1.moq - +p2.moq);
    } else {
      // if no prices were found, reset saved prices
      rbSupplier.prices = [];
    }

    this.setState({ activeKey: rbSupplier._id.toString(), providersSelected: providersSelected });
    onSuppliersSelectedChange(providersSelected);
  };

  /**
   * Filter the shown providers by the given query. Name, city and ZIP are checked for matches.
   * @returns { Array<SuppliersDocument | ManufacturersDocument> } Filtered providers
   */
  filterProviders = (): Array<SuppliersDocument | ManufacturersDocument> => {
    const { searchQuery, providersSelected, listingType, allowManufacturer } = this.state;
    const { suppliers, manufacturers } = this.context;
    const provSel = providersSelected.map(s => s._id.toString());
    const sqLow = searchQuery.toLowerCase();
    if (!allowManufacturer || listingType.value === CapsuleSupplierType.SUPPLIER) {
      return suppliers.filter(
        s =>
          !s.disabled &&
          (s.name.toLowerCase().includes(sqLow) ||
            s.address[0].city.toLowerCase().includes(sqLow) ||
            s.address[0].zip.toLowerCase().includes(sqLow)) &&
          !provSel.includes(s._id.toString())
      );
    } else {
      return manufacturers.filter(
        m =>
          (m.name.toLowerCase().includes(sqLow) || m.city.toLowerCase().includes(sqLow)) &&
          !provSel.includes(m._id.toString())
      );
    }
  };

  /**
   * Prepares the suppliers and updates the state.
   */
  prepareSuppliers = () => {
    const { suppliers, general } = this.context;
    const { document, type } = this.props;
    const { activeKey } = this.state;
    const suppliersSelected = [];
    const rawbidsSupplierId = getRawbidsSupplierId(general);
    if (document) {
      let rawbidsExists = false;
      for (let i = 0; i < document.suppliers.length; i++) {
        const supplier = document.suppliers[i];
        let supplierSelected: SupplierSelected = { _id: supplier._id, prices: [] };
        if ("type" in supplier && supplier.type !== undefined) {
          supplierSelected = { ...supplierSelected, type: supplier.type };
        }
        if (
          rawbidsSupplierId &&
          !rawbidsExists &&
          "rawbidsData" in document &&
          document.rawbidsData &&
          supplier._id.toString() === rawbidsSupplierId
        ) {
          rawbidsExists = true;
          supplierSelected["rawbids"] = true;
          supplierSelected["material"] = document;
        }
        for (let j = 0; j < supplier.prices.length; j++) {
          const price = supplier.prices[j];
          supplierSelected.prices.push(suppliersUtils.getSupplierSelectedPrice(type, price));
        }
        // rawbids should be first supplier
        if (supplierSelected.rawbids) suppliersSelected.unshift(supplierSelected);
        else suppliersSelected.push(supplierSelected);
      }
      // Add rb supplier if missing
      if (rawbidsSupplierId && !rawbidsExists && "rawbidsData" in document && document.rawbidsData) {
        const rbSupplier = baseUtils.getDocFromCollection(suppliers, rawbidsSupplierId);
        if (rbSupplier) {
          suppliersSelected.unshift({
            _id: rbSupplier._id,
            prices: [],
            rawbids: true,
            material: document
          });
        }
      }
      this.setState({
        providersSelected: suppliersSelected,
        activeKey: activeKey
          ? activeKey
          : document
          ? ""
          : suppliersSelected.length > 0
          ? suppliersSelected[0]._id.toString()
          : ""
      });
    }
  };

  render() {
    const { disabled, type } = this.props;
    const { searchQuery, providersSelected, pageSize, currentPage, activeKey, listingType, allowManufacturer } =
      this.state;
    const { userdata } = this.context;
    const provFiltered = this.filterProviders();
    let provPaginated: Array<ManufacturersDocument | SuppliersDocument>;
    if (!allowManufacturer || listingType.value === CapsuleSupplierType.SUPPLIER) {
      const suppliers: SuppliersDocument[] = provFiltered as SuppliersDocument[];
      provPaginated = paginate(suppliers, currentPage, pageSize);
    } else {
      const manufacturers: ManufacturersDocument[] = provFiltered as ManufacturersDocument[];
      provPaginated = paginate(manufacturers, currentPage, pageSize);
    }

    return (
      <>
        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive overflow-hidden">
          <Table>
            <thead>
              <tr>
                <th style={{ width: allowManufacturer ? "20%" : "30%", minWidth: "200px" }}>Provider</th>
                {allowManufacturer && <th style={{ width: "10%" }}>Type</th>}
                <th style={{ width: "20%" }}>Contact Person</th>
                <th style={{ width: "10%" }}>Rating</th>
                <th style={{ width: "10%" }}>Best Offer</th>
                <th style={{ width: "10%" }}>Fastest</th>
                <th style={{ width: "10%" }}>Last Update</th>
                <th style={{ width: "10%" }} />
              </tr>
            </thead>
            <tbody>
              {providersSelected.length > 0 ? (
                providersSelected.map(s => {
                  if (suppliersUtils.isRawbidsSupplier(s))
                    return (
                      <RawbidsPricesEntry
                        key={s._id.toString()}
                        activeKey={activeKey}
                        onChangeActiveKey={this.handleChangeActiveKey}
                        onAddNewPriceClick={this.handleAddNewPriceClick}
                        onRemovePriceClick={this.handleRemovePriceClick}
                        onNumberChange={this.handleNumberChange}
                        onStringChange={this.handleStringChange}
                        onUpdateRawbidsSupplier={this.handleUpdateRawbidsSupplier}
                        supplierSelected={s}
                        type={type}
                        disabled={disabled}
                        allowManufacturer={allowManufacturer}
                      />
                    );
                  return (
                    <SupplierPricesEntry
                      key={s._id.toString()}
                      activeKey={activeKey}
                      onChangeActiveKey={this.handleChangeActiveKey}
                      onRemoveSupplierClick={this.handleRemoveProviderClick}
                      onAddNewPriceClick={this.handleAddNewPriceClick}
                      onRemovePriceClick={this.handleRemovePriceClick}
                      onNumberChange={this.handleNumberChange}
                      onStringChange={this.handleStringChange}
                      supplierSelected={s}
                      type={type}
                      disabled={disabled}
                      allowManufacturer={allowManufacturer}
                    />
                  );
                })
              ) : (
                <tr style={{ backgroundColor: "#fafafa" }}>
                  <td className="text-center" colSpan={6}>
                    <span className="font-weight-bolder h4">Please add a supplier!</span>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {!disabled && (
          <>
            <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
            <div className="row justify-content-between">
              <div className="col col-md-6">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="la la-search" />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={this.handleSearchQueryChange}
                  />
                </div>
              </div>
              {allowManufacturer && (
                <div className="col col-md-2">
                  <Select
                    className="select-default"
                    options={[
                      { value: CapsuleSupplierType.SUPPLIER, label: "Supplier" },
                      { value: CapsuleSupplierType.MANUFACTURER, label: "Manufacturer" }
                    ]}
                    value={listingType}
                    onChange={(value: any) => this.handleTypeChange(value)}
                  />
                </div>
              )}
            </div>
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive overflow-hidden">
              <Table>
                <thead>
                  <tr>
                    <th style={{ width: "30%", minWidth: "200px" }}>
                      {listingType.value === "supplier" ? "Supplier" : "Manufacturer"}
                    </th>
                    <th style={{ width: "25%" }}>Contact Person</th>
                    <th style={{ width: "35%" }}>Rating</th>
                    <th style={{ width: "10%" }} />
                  </tr>
                </thead>
                <tbody>
                  {provPaginated.map(p => {
                    if (!manufacturerUtils.isManufacturer(p)) {
                      const contact =
                        p.person.length > 0 ? userdata.find(u => u._id.toString() === p.person[0].toString()) : null;
                      return (
                        <tr key={p._id.toString()}>
                          <td className="align-middle">
                            <CompanyWidget company={p} type={"supplier"} />
                          </td>
                          <td className="align-middle">{contact && <PersonWidget person={contact} />}</td>
                          <td className="align-middle">
                            <RatingInfo rating={p.rating} />
                          </td>
                          <td className="align-middle text-right">
                            {!p.disabled && (
                              <button className="btn btn-success" onClick={() => this.handleAddProviderClick(p._id)}>
                                <i className="fa fa-plus pr-0" />
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    } else {
                      const contact = userdata.find(u => u._id.toString() === p.person.toString());
                      return (
                        <tr key={p._id.toString()}>
                          <td className="align-middle">
                            <ManufacturerWidget manufacturer={p} />
                          </td>
                          <td className="align-middle">{contact && <PersonWidget person={contact} />}</td>
                          <td className="align-middle">
                            <span> - </span>
                          </td>
                          <td className="align-middle text-right">
                            <button className="btn btn-success" onClick={() => this.handleAddProviderClick(p._id)}>
                              <i className="fa fa-plus pr-0" />
                            </button>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
              <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <Pagination
                  itemsCount={provFiltered.length}
                  pageSize={pageSize}
                  onPageChange={currentPage => this.setState({ currentPage })}
                  currentPage={currentPage}
                  additionalNavClasses="justify-content-center flex-grow-1"
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default SupplierPricesSelection;
