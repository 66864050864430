import { BSON } from "realm-web";
import { CommodityBatch, CommodityBatchFile, CommoditiesDocument, CommodityTimeline } from "../model/commodities.types";
import { PackagingsDocument } from "../model/packagings.types";

const BATCHKEYS: Array<
  keyof {
    amount?: number;
    expiry?: Date;
    location?: BSON.ObjectId;
    lot?: string;
    price?: number;
    stocked?: Date;
    disabled?: boolean;
    supplier?: BSON.ObjectId | "accumulatedstock" | "custom" | "customer" | "ownstock";
    note?: string;
  }
> = ["amount", "expiry", "location", "lot", "price", "stocked", "supplier", "disabled", "note"];

/**
 * Check stock for differences.
 * @param pre: Document before update
 * @param post: Document after update
 * @param timeline: Timeline entry that contains the diff
 * @param update: Update object for MongoDB
 */
function diffForStock(pre: CommoditiesDocument, post: CommoditiesDocument, timeline: CommodityTimeline, update: any) {
  let stockUpdated = false;
  const stockOld = pre.stock.map(s => s._id);
  for (let batch of post.stock) {
    if (!stockOld.includes(batch._id)) {
      if (!timeline.post.stock) {
        timeline.post.stock = [];
      }
      timeline.post.stock.push({ _id: batch._id, lot: batch.lot, amount: batch.amount, location: batch.location });
      stockUpdated = true;
    } else {
      let changed = false;
      const changePre: any = { _id: batch._id, lot: batch.lot, amount: batch.amount, location: batch.location };
      const changePost: any = { _id: batch._id, lot: batch.lot, amount: batch.amount, location: batch.location };
      // @ts-ignore
      const batchPre = pre.stock.find((s: CommodityBatch | PackagingStock) => s._id === batch._id)!;

      for (let value of BATCHKEYS) {
        if (
          (value === "disabled" && batchPre[value] !== batch[value]) ||
          (value !== "disabled" && batchPre[value].toString() !== batch[value].toString())
        ) {
          changed = true;
          changePre[value] = batchPre[value];
          changePost[value] = batch[value];
        }
      }
      const filesOld = batchPre.files.map((f: CommodityBatchFile) => f.path);
      const filesNew = batch.files.map(f => f.path);
      for (let file of filesNew) {
        if (!filesOld.includes(file)) {
          changed = true;
          if (!changePost.files) {
            changePost.files = [];
          }
          changePost.files.push(file);
        }
      }
      for (let file of filesOld) {
        if (!filesNew.includes(file)) {
          changed = true;
          if (!changePre.files) {
            changePre.files = [];
          }
          changePre.files.push(file);
        }
      }
      if (changed) {
        if (!timeline.pre.stock) {
          timeline.pre.stock = [];
        }
        if (!timeline.post.stock) {
          timeline.post.stock = [];
        }
        timeline.pre.stock.push(changePre);
        timeline.post.stock.push(changePost);
        stockUpdated = true;
      }
    }
  }
  if (stockUpdated) {
    update.stock = post.stock;
  }
}

/**
 * Check specifications for differences.
 * @param pre: Document before update
 * @param post: Document after update
 * @param timeline: Timeline entry that contains the diff
 * @param update: Update object for MongoDB
 */
function diffForSpecifications(
  pre: CommoditiesDocument | PackagingsDocument,
  post: CommoditiesDocument | PackagingsDocument,
  timeline: CommodityTimeline,
  update: any
) {
  // Check all specifications for changes
  let specificationChanged = false;
  const specificationsOld = pre.specifications.map(s => s.path);
  for (let specification of post.specifications) {
    if (!specificationsOld.includes(specification.path)) {
      if (!timeline.post.specifications) {
        timeline.post.specifications = [];
      }
      timeline.post.specifications.push({ id: specification.id, path: specification.path });
      specificationChanged = true;
    }
  }
  const specificationsNew = post.specifications.map(s => s.path);
  for (let specification of pre.specifications) {
    if (!specificationsNew.includes(specification.path)) {
      if (!timeline.pre.specifications) {
        timeline.pre.specifications = [];
      }
      timeline.pre.specifications.push({ id: specification.id, path: specification.path });
      specificationChanged = true;
    }
  }
  if (specificationChanged) {
    update.specifications = post.specifications;
  }
}

export default { diffForSpecifications, diffForStock };
