import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { OrdersDocument } from "../../../model/orders.types";

interface ReadyForProductionProps {
  orders: Array<OrdersDocument>;
}

interface ReadyForProductionState {
  readyOrders: Array<OrdersDocument>;
}

class ReadyForProduction extends Component<ReadyForProductionProps, ReadyForProductionState> {
  constructor(props: ReadyForProductionProps) {
    super(props);
    this.state = { readyOrders: props.orders.filter(o => o.state === "productionqueue" || o.state === "production") };
  }

  componentDidUpdate(
    prevProps: Readonly<ReadyForProductionProps>,
    prevState: Readonly<ReadyForProductionState>,
    snapshot?: any
  ) {
    if (!_.isEqual(this.props.orders, prevProps.orders)) {
      this.setState({
        readyOrders: this.props.orders.filter(o => o.state === "productionqueue" || o.state === "production")
      });
    }
  }

  render() {
    const { readyOrders } = this.state;
    return (
      <div className="kt-portlet  kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">Ready</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-widget1 text-center">
            <h3 className="kt-font-success">
              <b>{readyOrders.reduce((sum, o) => sum + o.calculations[0].units, 0).toLocaleString() + " Units"}</b>
            </h3>
          </div>
          <div className="table-responsive mb-3" style={{ maxHeight: 675, overflow: "scroll" }}>
            <table className="table">
              <thead>
                <tr>
                  <td>Order</td>
                  <td className="kt-align-right">Units</td>
                </tr>
              </thead>
              <tbody>
                {readyOrders.map(order => {
                  return (
                    <tr key={order._id.toString()}>
                      <td>
                        <Link
                          to={"/order/" + order._id.toString()}
                          className="kt-user-card-v2__name kt-link text-dark kt-font-bolder"
                        >
                          {"Order AT-" + order.identifier}
                        </Link>
                      </td>

                      <td className="kt-align-right kt-font-brand kt-font-bold kt-font-dark">
                        {order.calculations[0].units + " Units"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default ReadyForProduction;
