import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { CalculationType, ExtendedCapsule, Preferences } from "../CustomTypes";
import baseUtils from "../../../utils/baseUtils";
import { DataContext } from "../../../context/dataContext";
import { CapsuleSupplierType } from "../../../model/capsules.types";

interface CapsuleCalculationDetailsProps {
  capsule: ExtendedCapsule | null;
  preferences: Preferences;
  calculation: CalculationType;
  onCapsuleCalculationUpdate: (calculationId: BSON.ObjectId, path: string, value: any) => void;
  onCapsuleSupplierChange: (calculationId: BSON.ObjectId, newSupplier: BSON.ObjectId | "ownstock" | "customer") => void;
}

interface CapsuleCalculationDetailsState {}

class CapsuleCalculationDetails extends PureComponent<CapsuleCalculationDetailsProps, CapsuleCalculationDetailsState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  getSupplierList = () => {
    const { preferences } = this.props;
    const { suppliers, manufacturers } = this.context;
    if (!preferences.selectedCapsule) return [{ key: "", value: "undefined" }];
    const capsuleSuppliers = preferences.selectedCapsule?.suppliers;
    let supplierList: Array<{ key: string | BSON.ObjectId; value: string }> = capsuleSuppliers?.map(supp => {
      let supplierName;
      if (supp.type && supp.type === CapsuleSupplierType.MANUFACTURER) {
        supplierName = manufacturers.find(s => s._id.toString() === supp._id.toString())?.name || "Unknown";
      } else {
        supplierName = suppliers.find(s => s._id.toString() === supp._id.toString())?.name || "Unknown";
      }
      return { key: supp._id, value: supplierName };
    });
    supplierList.unshift({ key: "customer", value: "Customer (0€)" });
    return supplierList;
  };

  handleCapsuleSupplierChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { calculation, onCapsuleSupplierChange } = this.props;
    const value = e.currentTarget.value;
    const id = ["customer", "ownstock"].includes(value) ? value : new BSON.ObjectId(value);
    onCapsuleSupplierChange(calculation.id, id as "ownstock" | "customer" | BSON.ObjectId);
  };

  render() {
    const { capsule, preferences, calculation, onCapsuleCalculationUpdate } = this.props;
    if (!calculation.priceDetails) return <></>;
    const matchingCalculation = capsule?.calculations.find(calc => calc.id.toString() === calculation.id.toString());
    const priceDetails = calculation.priceDetails.capsulePrice;
    if (!priceDetails || !matchingCalculation) return <></>;
    const unitPrice = calculation.priceDetails.capsuleUnitPrice;
    const moq = matchingCalculation.supplier.price.moq;
    const totalAmount = matchingCalculation.totalAmount;
    const matchingMOQ = totalAmount >= moq;

    return (
      <table className="kt-datatable__table table-responsive">
        <thead className="kt-datatable__head text-align-left">
          <tr className="kt-datatable__row" style={{ backgroundColor: "#fcfcfc" }}>
            <th className=" kt-datatable__cell">
              <span>
                <b>Capsule</b>
              </span>
            </th>
          </tr>
          <tr className="kt-datatable__row">
            <th className=" kt-datatable__cell">
              <span style={{ width: 150 }}>Title</span>
            </th>
            <th className=" kt-datatable__cell">
              <span style={{ width: 70 }}>Auto</span>
            </th>
            <th className=" kt-datatable__cell">
              <span style={{ width: 60 }}>Amount</span>
            </th>
            <th className=" kt-datatable__cell">
              <span style={{ width: 75 }} />
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 150 }}>Supplier</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>Total</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>MOQ</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>€ / 1000 Caps.</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>€ / Unit</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>Total €</span>
            </th>
          </tr>
        </thead>
        <tbody className="kt-datatable__body ps ps--active-y">
          <tr className="kt-datatable__row">
            <td className="kt-datatable__cell">
              <span style={{ width: 150 }}>
                <b>{baseUtils.buildCapsuleString(capsule as ExtendedCapsule)}</b>
              </span>
            </td>
            <td className=" kt-datatable__cell">
              <span style={{ width: 70 }}>
                <div className="custom-control custom-switch ml-2" style={{ display: "inline" }}>
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={"autoselect_" + calculation.id.toString()}
                    checked={matchingCalculation.auto}
                    onChange={() => onCapsuleCalculationUpdate(calculation.id, "auto", !matchingCalculation.auto)}
                  />
                  <label className="custom-control-label" htmlFor={"autoselect_" + calculation.id.toString()} />
                </div>
              </span>
            </td>
            <td className="kt-datatable__cell">
              <span style={{ width: 60 }}>{preferences.amountPerUnit}</span>
            </td>
            <td className=" kt-datatable__cell">
              <span style={{ width: 75 }} />
            </td>
            <td className="kt-datatable__cell">
              <span style={{ width: 150 }}>
                <select
                  className="form-control"
                  disabled={matchingCalculation.auto}
                  value={matchingCalculation.supplier._id.toString()}
                  onChange={this.handleCapsuleSupplierChange}
                >
                  {this.getSupplierList().map(supp => (
                    <option key={supp.key.toString()} value={supp.key.toString()}>
                      {supp.value}
                    </option>
                  ))}
                </select>
              </span>
            </td>
            <td className="kt-datatable__cell">
              <span style={{ width: 70 }}>{totalAmount}k</span>
            </td>
            <td className=" kt-datatable__cell">
              <span style={{ width: 70 }} className={"text-bold " + (matchingMOQ ? "" : "text-danger")}>
                {moq}k
              </span>
            </td>
            <td className="kt-datatable__cell">
              <span style={{ width: 70 }}>{baseUtils.formatEuro(matchingCalculation.price)}</span>
            </td>
            <td className="kt-datatable__cell">
              <span style={{ width: 70 }}>
                <b>{baseUtils.formatEuro(unitPrice)}</b>
              </span>
            </td>
            <td className="kt-datatable__cell">
              <span style={{ width: 70 }}>{baseUtils.formatEuro(matchingCalculation.totalPrice || 0)}</span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default CapsuleCalculationDetails;
