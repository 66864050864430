import React, { PureComponent } from "react";
import { I_PAYMENTTARGETS } from "../../utils/invoiceUtils";

interface PaymentTargetSelectProps {
  name?: string;
  label?: string;
  labelClasses?: string;
  selectClasses?: string;
  wrapperClasses?: string;
  labelColumnClasses?: string;
  selectColumnClasses?: string;
  value: number | undefined;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  disablePaymentInAdvance?: boolean;
}

interface PaymentTargetSelectState {}

class PaymentTargetSelect extends PureComponent<PaymentTargetSelectProps, PaymentTargetSelectState> {
  render() {
    const {
      name,
      label,
      labelClasses,
      selectClasses,
      wrapperClasses,
      labelColumnClasses,
      selectColumnClasses,
      value,
      onChange,
      disablePaymentInAdvance
    } = this.props;
    const select = (
      <select
        className={selectClasses ? selectClasses : "form-control"}
        value={value !== undefined ? value : ""}
        name={name ? name : "paymentTarget"}
        onChange={onChange}
      >
        <option value={""} disabled hidden>
          Not set
        </option>
        {!disablePaymentInAdvance && <option value={-1}>Payment in advance</option>}
        <option value={0}>Immediately after delivery</option>
        {I_PAYMENTTARGETS.map(x => (
          <option key={x} value={x}>{`${x} days after invoice date`}</option>
        ))}
      </select>
    );
    return (
      <div className={wrapperClasses}>
        <div className={labelColumnClasses}>
          <label className={labelClasses}>{label ? label : "Payment Target"}</label>
        </div>
        {selectColumnClasses ? <div className={selectColumnClasses}>{select}</div> : select}
      </div>
    );
  }
}

export default PaymentTargetSelect;
