import React, { PureComponent } from "react";
import { Stats } from "../../../model/customTypes.types";
import baseUtils from "../../../utils/baseUtils";
import userService from "../../../services/userService";
import { OrdersDocument } from "../../../model/orders.types";
import dashboardUtils from "../../../utils/dashboardUtils";

interface InvestorWelcomeProps {
  orders: Array<OrdersDocument>;
  year: number;
}

class InvestorWelcome extends PureComponent<InvestorWelcomeProps, {}> {
  render() {
    const { orders, year } = this.props;
    const user = userService.getUserData();
    // The written values are calculated for this year while the open values are calculated over everything this is
    // done since the open orders can turn into turnover / margin this year. While completed orders are done in the
    // timeframe they were completed.
    const period = { beginning: new Date(0), end: new Date() };
    const beginning = new Date(year ? year : new Date().getFullYear(), 0, 1, 0, 0, 0, 0);
    const end = new Date(beginning);
    end.setFullYear(beginning.getFullYear() + 1);
    return (
      <div className="col-12 kt-portlet">
        <div className="kt-portlet__body">
          <div className="kt-widget kt-widget--user-profile-3">
            <div className="kt-widget__top overflow-auto">
              <div className="kt-widget__media">
                <img src={process.env.PUBLIC_URL + "/media/logos/SG-lg.gif"} alt="Systematic Growth Logo" />
              </div>
              <div className="kt-widget__content" style={{ display: "block", margin: "auto" }}>
                <div className="kt-widget__head">
                  <span className="kt-widget__username">
                    Hej
                    <br />
                    <span className="kt-font-bolder" style={{ fontSize: "1.5rem" }}>
                      {user.prename + " " + user.surname}
                    </span>
                  </span>
                </div>
              </div>
              <div className="kt-widget__content" style={{ display: "block", margin: "auto" }}>
                <div className="kt-widget__head">
                  <span className="kt-widget__username" style={{ textAlign: "center" }}>
                    <span className="kt-font-bolder text-success" style={{ fontSize: "2rem" }}>
                      {baseUtils.formatEuro(dashboardUtils.getOutstandingOrderVolume(orders, period))}
                    </span>
                    <br />
                    <span style={{ fontSize: "1.5rem" }}>Book turnover</span>
                  </span>
                </div>
              </div>
              <div className="kt-widget__content" style={{ display: "block", margin: "auto" }}>
                <div className="kt-widget__head">
                  <span className="kt-widget__username" style={{ textAlign: "center" }}>
                    <span className="kt-font-bolder text-success" style={{ fontSize: "2rem" }}>
                      {baseUtils.formatEuro(dashboardUtils.getAlreadyInvoicedVolume(orders, { beginning, end }))}
                    </span>
                    <br />
                    <span style={{ fontSize: "1.5rem" }}>Written turnover</span>
                  </span>
                </div>
              </div>
              <div className="kt-widget__content" style={{ display: "block", margin: "auto" }}>
                <div className="kt-widget__head">
                  <span className="kt-widget__username" style={{ textAlign: "center" }}>
                    <span className="kt-font-bolder text-success" style={{ fontSize: "2rem" }}>
                      {baseUtils.formatEuro(dashboardUtils.getOutstandingMargin(orders, period))}
                    </span>
                    <br />
                    <span style={{ fontSize: "1.5rem" }}>Book margin</span>
                  </span>
                </div>
              </div>
              <div className="kt-widget__content" style={{ display: "block", margin: "auto" }}>
                <div className="kt-widget__head">
                  <span className="kt-widget__username" style={{ textAlign: "center" }}>
                    <span className="kt-font-bolder text-success" style={{ fontSize: "2rem" }}>
                      {baseUtils.formatEuro(dashboardUtils.getWrittenProfit(orders, { beginning, end }))}
                    </span>
                    <br />
                    <span style={{ fontSize: "1.5rem" }}>Written margin</span>
                  </span>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvestorWelcome;
