import React from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import countryList from "i18n-iso-countries";
import { toAbsoluteUrl } from "../../_metronic";
import { ExtendedEMCommodity } from "../../model/customTypes.types";
import ExternalManufacturerHelper from "./ExternalManufacturerHelper";

interface EmCommodityWidgetProps {
  commodity: ExtendedEMCommodity;
  small?: boolean;
}

const EmCommodityWidget: React.FunctionComponent<EmCommodityWidgetProps> = ({ commodity, small }) => {
  const renderImage = () => {
    const src = ExternalManufacturerHelper.getCommodityImage(commodity);
    return <img src={src} alt="color" />;
  };
  return (
    <div className="kt-user-card-v2 ">
      <div className="kt-user-card-v2__pic d-none d-xl-block">{renderImage()}</div>
      <div className="kt-user-card-v2__details">
        <span className="kt-user-card-v2__name">
          <Link
            to={"/commodity/" + commodity._id.toString()}
            className="kt-user-card-v2__name kt-link d-inline"
            onClick={e => e.stopPropagation()}
          >
            {commodity.title.en}
          </Link>
          {!small && commodity.country && (
            <OverlayTrigger
              overlay={
                <Tooltip id="button-tooltip product-popover">{countryList.getName(commodity.country, "en")}</Tooltip>
              }
            >
              <img
                style={{
                  width: 14,
                  height: 14,
                  borderRadius: 14,
                  objectFit: "cover"
                }}
                alt={commodity.country}
                src={toAbsoluteUrl("/media/icons/countries/" + commodity.country.toLowerCase() + ".png")}
                className="country-icon ml-2"
              />
            </OverlayTrigger>
          )}
        </span>
        {!small && <span className="kt-user-card-v2__email">{commodity.subtitle.en}</span>}
      </div>
    </div>
  );
};

export default EmCommodityWidget;
