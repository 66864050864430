import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ManufacturerCapabilities from "./ManufacturerCapabilities";
import ManufacturerEmployees from "./ManufacturerEmployees";
import PersonWidget from "../common/PersonWidget";
import { DataContext } from "../../context/dataContext";
import { ManufacturersDocument } from "../../model/manufacturers.types";
import dbManufacturerService from "../../services/dbServices/dbManufacturerService";
import userService from "../../services/userService";
import manufacturerUtils from "../../utils/manufacturerUtils";
import { MANUFACTURERS } from "../../services/dbService";
import { ROLES } from "../../utils/userdataUtils";
import authenticationService from "../../services/authenticationService";
import ManufacturerNotificationSettingsPanel from "./ManufacturerNotificationSettingsPanel";

interface ManufacturerParams {
  id: string;
}

interface ManufacturerDetailsPageProps extends RouteComponentProps<ManufacturerParams, {}, {}> {}

interface ManufacturerDetailsPageState {
  manufacturer?: ManufacturersDocument;
  showAddEmployeeModal: boolean;
  showRemoveEmployeeModal: boolean;
  employeeToRemove?: BSON.ObjectId;
}

class ManufacturerDetailsPage extends PureComponent<ManufacturerDetailsPageProps, ManufacturerDetailsPageState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  _id: string;

  constructor(props: ManufacturerDetailsPageProps, context: React.ContextType<typeof DataContext>) {
    super(props);
    this._id = props.match.params.id;
    this.state = {
      manufacturer: _.cloneDeep(context.manufacturers.find(m => m._id.toString() === this._id)),
      showAddEmployeeModal: false,
      showRemoveEmployeeModal: false
    };
  }

  /**
   * Deletes the referenced capability price.
   * @param typeProduct: Product type
   * @param typePrice: Price type
   * @param key: Index of the price that should be deleted
   */
  handleClickDeleteCapabilityPrice = (
    typeProduct: "liquids" | "powder" | "tablets" | "capsules",
    typePrice: "blending" | "bottling" | "encapsulation",
    key: number
  ) => {
    const manufacturer = _.cloneDeep(this.state.manufacturer);
    if (!manufacturer) return;
    switch (typeProduct) {
      case "liquids":
        switch (typePrice) {
          case "blending":
            manufacturer.liquids!.blending.splice(key, 1);
            break;
          case "bottling":
            manufacturer.liquids!.bottling.splice(key, 1);
        }
        break;
      case "powder":
        switch (typePrice) {
          case "blending":
            manufacturer.powder!.blending.splice(key, 1);
            break;
          case "bottling":
            manufacturer.powder!.bottling.splice(key, 1);
        }
        break;
      case "tablets":
        switch (typePrice) {
          case "blending":
            manufacturer.tablets!.tableting.splice(key, 1);
            break;
          case "bottling":
            manufacturer.tablets!.bottling.splice(key, 1);
        }
        break;
      case "capsules":
        switch (typePrice) {
          case "blending":
            manufacturer.capsules!.blistering.splice(key, 1);
            break;
          case "bottling":
            manufacturer.capsules!.bottling.splice(key, 1);
            break;
          case "encapsulation":
            manufacturer.capsules!.encapsulation.splice(key, 1);
        }
        break;
    }
    this.setState({ manufacturer });
  };

  /**
   * Adds a new capability price for the manufacturer
   * @param typeProduct: Type of the product
   * @param typePrice: Type of the price
   */
  handleClickAddCapabilityPrice = (
    typeProduct: "liquids" | "powder" | "tablets" | "capsules",
    typePrice: "blending" | "bottling" | "encapsulation"
  ) => {
    const manufacturer = _.cloneDeep(this.state.manufacturer);
    if (!manufacturer) return;
    const defaultValue = { amount: "0", price: "999999" };
    switch (typeProduct) {
      case "liquids":
        switch (typePrice) {
          case "blending":
            manufacturer.liquids!.blending.push(defaultValue);
            break;
          case "bottling":
            manufacturer.liquids!.bottling.push(defaultValue);
        }
        break;
      case "powder":
        switch (typePrice) {
          case "blending":
            manufacturer.powder!.blending.push(defaultValue);
            break;
          case "bottling":
            manufacturer.powder!.bottling.push(defaultValue);
        }
        break;
      case "tablets":
        switch (typePrice) {
          case "blending":
            manufacturer.tablets!.tableting.push(defaultValue);
            break;
          case "bottling":
            manufacturer.tablets!.bottling.push(defaultValue);
        }
        break;
      case "capsules":
        switch (typePrice) {
          case "blending":
            manufacturer.capsules!.blistering.push(defaultValue);
            break;
          case "bottling":
            manufacturer.capsules!.bottling.push(defaultValue);
            break;
          case "encapsulation":
            manufacturer.capsules!.encapsulation.push({ size: "???", cost: 999999 });
        }
    }
    this.setState({ manufacturer });
  };

  /**
   * Resets the shown data to the data that is stored inside database.
   */
  handleReset = () => {
    this.setState({ manufacturer: _.cloneDeep(this.context.manufacturers.find(m => m._id.toString() === this._id)) });
  };

  /**
   * Saves the given type of data
   * @param typeData: Determines whether capabilities or employees are saved
   */
  handleSave = async (typeData: "capabilities" | "employees") => {
    const manufacturer = _.cloneDeep(this.state.manufacturer);
    if (!manufacturer) return;
    let res;
    switch (typeData) {
      case "capabilities":
        let capabilities: { liquids?: any; powder?: any; tablets?: any; capsules?: any } = {};
        if (manufacturer.liquids) {
          capabilities.liquids = manufacturer.liquids;
        }
        if (manufacturer.powder) {
          capabilities.powder = manufacturer.powder;
        }
        if (manufacturer.tablets) {
          capabilities.tablets = manufacturer.tablets;
        }
        if (manufacturer.capsules) {
          capabilities.capsules = manufacturer.capsules;
        }
        res = await dbManufacturerService.updateCapabilities(manufacturer._id, capabilities);
        break;
      case "employees":
        res = await dbManufacturerService.updateEmployees(manufacturer._id, manufacturer.employees);
    }
    if (res) {
      await this.context.updateDocumentInContext(MANUFACTURERS, manufacturer._id);
      toast.success(
        <b>
          <i className="fa fa-check mx-2" />
          Manufacturer updated successfully
        </b>
      );
    }
  };

  /**
   * Changes the referenced capability value
   * @param typeProduct: Type of the product
   * @param typePrice: Type of the price
   * @param typeValue: Type of the value that is changed
   * @param key: Index of the product pricing
   * @param value: New value
   */
  handleChangeCapabilityValue = (
    typeProduct: "liquids" | "powder" | "tablets" | "capsules",
    typePrice: "blending" | "bottling" | "encapsulation",
    typeValue: "amount" | "price",
    key: number,
    value: string
  ) => {
    const manufacturer = _.cloneDeep(this.state.manufacturer);
    if (!manufacturer || (!+value && +value !== 0)) return;
    switch (typeProduct) {
      case "liquids":
        switch (typePrice) {
          case "blending":
            switch (typeValue) {
              case "amount":
                manufacturer.liquids!.blending[key].amount = value;
                break;
              case "price":
                manufacturer.liquids!.blending[key].price = value;
                break;
            }
            break;
          case "bottling":
            switch (typeValue) {
              case "amount":
                manufacturer.liquids!.bottling[key].amount = value;
                break;
              case "price":
                manufacturer.liquids!.bottling[key].price = value;
                break;
            }
        }
        break;
      case "powder":
        switch (typePrice) {
          case "blending":
            switch (typeValue) {
              case "amount":
                manufacturer.powder!.blending[key].amount = value;
                break;
              case "price":
                manufacturer.powder!.blending[key].price = value;
                break;
            }
            break;
          case "bottling":
            switch (typeValue) {
              case "amount":
                manufacturer.powder!.bottling[key].amount = value;
                break;
              case "price":
                manufacturer.powder!.bottling[key].price = value;
                break;
            }
            break;
        }
        break;
      case "tablets":
        switch (typePrice) {
          case "blending":
            switch (typeValue) {
              case "amount":
                manufacturer.tablets!.tableting[key].amount = value;
                break;
              case "price":
                manufacturer.tablets!.tableting[key].price = value;
                break;
            }
            break;
          case "bottling":
            switch (typeValue) {
              case "amount":
                manufacturer.tablets!.bottling[key].amount = value;
                break;
              case "price":
                manufacturer.tablets!.bottling[key].price = value;
                break;
            }
            break;
        }
        break;
      case "capsules":
        switch (typePrice) {
          case "blending":
            switch (typeValue) {
              case "amount":
                manufacturer.capsules!.blistering[key].amount = value;
                break;
              case "price":
                manufacturer.capsules!.blistering[key].price = value;
                break;
            }
            break;
          case "bottling":
            switch (typeValue) {
              case "amount":
                manufacturer.capsules!.bottling[key].amount = value;
                break;
              case "price":
                manufacturer.capsules!.bottling[key].price = value;
                break;
            }
            break;
          case "encapsulation":
            switch (typeValue) {
              case "amount":
                manufacturer.capsules!.encapsulation[key].size = value;
                break;
              case "price":
                manufacturer.capsules!.encapsulation[key].cost = +value;
                break;
            }
        }
    }
    this.setState({ manufacturer });
  };

  /**
   * Adds the given employee to the manufacturer.
   * @param _id: ID of the user that should be added
   */
  handleAddEmployee = (_id: BSON.ObjectId) => {
    const manufacturer = _.cloneDeep(this.state.manufacturer);
    if (!manufacturer) return;
    if (!manufacturer.employees) manufacturer.employees = [];
    if (!manufacturer.employees.includes(_id)) {
      manufacturer.employees.push(_id);
    }
    this.setState({ manufacturer });
  };

  /**
   * Removes the given employee from the manufacturer.
   * @param _id: ID of the user that should be removed.
   */
  handleRemoveEmployee = (_id: BSON.ObjectId) => {
    const manufacturer = _.cloneDeep(this.state.manufacturer);
    if (!manufacturer || !manufacturer.employees) return;
    manufacturer.employees = manufacturer.employees.filter(e => e.toString() !== _id.toString());
    this.setState({ manufacturer });
  };

  render() {
    const { history } = this.props;
    const { manufacturer } = this.state;
    const { userdata } = this.context;
    if (!manufacturer) {
      history.replace("/manufacturers");
    } else {
      const responsible = userdata.find(u => u._id.toString() === manufacturer.person.toString())!;
      const canChange =
        userService.isAuthorizedForAction(ROLES.ADMIN) ||
        manufacturerUtils.isResponsibleOfManufacturer(authenticationService.getUserDataID(), manufacturer);
      return (
        <div className="row">
          <div className="col-12">
            <div className="kt-portlet kt-portlet--mobile">
              <div className="kt-portlet__head kt-portlet__head--lg">
                <div className="kt-portlet__head-label">
                  <span className="kt-portlet__head-icon">
                    <i className="kt-font-brand flaticon2-box" />
                  </span>
                  <h3 className="kt-portlet__head-title">{manufacturer.name}</h3>
                </div>
                <div className="kt-portlet__head-toolbar">
                  <div className="kt-portlet__head-wrapper">
                    <a
                      onClick={() => {
                        history.goBack();
                      }}
                      className="btn btn-clean kt-margin-r-10"
                    >
                      <i className="la la-arrow-left" />
                      <span className="kt-hidden-mobile">Back</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="kt-portlet__body kt-portlet__body--fit">
                <div className="row m-4">
                  <div className="col-6 text-dark">
                    <div className="h3">{manufacturer.name}</div>
                    <div className="h5">
                      {manufacturer.city}, {manufacturer.country}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex float-right">
                      <div className="h5 text-dark mr-2 align-self-center">Responsible:</div>
                      <PersonWidget person={responsible} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <ManufacturerCapabilities
              canChange={canChange}
              changeCapabilityValue={this.handleChangeCapabilityValue}
              manufacturer={manufacturer}
              onClickAddCapabilityPrice={this.handleClickAddCapabilityPrice}
              onClickReset={this.handleReset}
              onClickSave={this.handleSave}
              onClickDeleteCapabilityPrice={this.handleClickDeleteCapabilityPrice}
            />
          </div>
          <div className="col-12 col-lg-6">
            <ManufacturerEmployees
              canChange={canChange}
              manufacturer={manufacturer}
              onReset={this.handleReset}
              onSave={this.handleSave}
              onAddEmployee={this.handleAddEmployee}
              onRemoveEmployee={this.handleRemoveEmployee}
            />
          </div>
          <div className="col-12 col-lg-6">
            <ManufacturerNotificationSettingsPanel manufacturer={manufacturer} />
          </div>
        </div>
      );
    }
  }
}
export default withRouter(ManufacturerDetailsPage);
