import _ from "lodash";
import React, { Component } from "react";
import { Accordion } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic";
import { PaginationState } from "../../common/CustomTypes";
import Pagination, { paginate } from "../../common/Pagination";
import PersonWidget from "../../common/PersonWidget";
import { CustomOrder } from "../../order/CustomTypes";
import PayInvoiceModal from "../../order/modals/PayInvoiceModal";
import InvoiceModal from "../../order/modals/InvoiceModal";
import CompanyWidget from "../../common/CompanyWidget";
import config from "../../../config/config.json";
import { DataContext } from "../../../context/dataContext";
import { OrderWidget } from "../../listings/common/BaseListingComponents";
import { CompaniesDocument } from "../../../model/companies.types";
import { Invoice } from "../../../model/orders.types";
import baseUtils from "../../../utils/baseUtils";
import dateUtils from "../../../utils/dateUtils";
import { I_CANCELED, I_PAID, I_PARTLYPAID } from "../../../utils/invoiceUtils";
import orderUtils from "../../../utils/orderUtils";

interface OverdueInvoicesProps {
  activeKey: string;
  context: React.ContextType<typeof DataContext>;
  level: "0" | "1" | "2";
}

interface OverdueInvoicesState extends PaginationState {
  invoices: Array<{ order: CustomOrder; company: CompaniesDocument; invoice: Invoice }>;
  invoiceToPay?: { order: CustomOrder; invoice: Invoice };
}

class OverdueInvoices extends Component<OverdueInvoicesProps, OverdueInvoicesState> {
  constructor(props: OverdueInvoicesProps) {
    super(props);
    this.state = { invoices: [], currentPage: 1, pageSize: 10 };
  }

  componentDidMount() {
    this.collectInvoices();
  }

  componentDidUpdate(
    prevProps: Readonly<OverdueInvoicesProps>,
    prevState: Readonly<OverdueInvoicesState>,
    snapshot?: any
  ) {
    if (prevProps.context.orders !== this.props.context.orders) this.collectInvoices();
  }

  /**
   * Collect all invoices that are needed for the given overdue level
   */
  collectInvoices = () => {
    const { context, level } = this.props;
    const invoices: Array<{ order: CustomOrder; company: CompaniesDocument; invoice: Invoice }> = [];
    for (let i = 0; i < context.orders.length; i++) {
      const o = context.orders[i];
      if (o.invoices) {
        const company = context.companies.find(c => c._id.toString() === o.createdFor.toString())!;
        const creator = context.userdata.find(u => u._id.toString() === o.createdFrom.toString())!;
        // Create a CustomOrder
        const co: any = _.omit(o, ["createdFrom"]);
        co.createdFrom = creator;
        if (level === "0") {
          const inv0 = o.invoices.filter(inv => {
            if ([I_PAID, I_CANCELED].includes(inv.state)) return false;
            const diff = dateUtils.getDaysUntil(orderUtils.getInvoiceDue(inv.dueIn, inv.invoiceDate));
            return diff < 0 && diff >= -7;
          });
          inv0.forEach(i0 => invoices.push({ order: co, company, invoice: i0 }));
        } else if (level === "1") {
          const inv1 = o.invoices.filter(inv => {
            if ([I_PAID, I_CANCELED].includes(inv.state)) return false;
            const diff = dateUtils.getDaysUntil(orderUtils.getInvoiceDue(inv.dueIn, inv.invoiceDate));
            return diff < -7 && diff >= -14;
          });
          inv1.forEach(i1 => invoices.push({ order: co, company, invoice: i1 }));
        } else if (level === "2") {
          const inv2 = o.invoices.filter(inv => {
            if ([I_PAID, I_CANCELED].includes(inv.state)) return false;
            const diff = dateUtils.getDaysUntil(orderUtils.getInvoiceDue(inv.dueIn, inv.invoiceDate));
            return diff < -14;
          });
          inv2.forEach(i2 => invoices.push({ order: co, company, invoice: i2 }));
        }
      }
    }
    this.setState({ invoices: invoices.sort((i1, i2) => +i1.invoice.invoiceNumber - +i2.invoice.invoiceNumber) });
  };

  /**
   * Resolve the description of the overdue level and its amount.
   * @returns { string } Description and amount
   */
  resolveLevelDescription = () => {
    const { level } = this.props;
    const { invoices } = this.state;
    const totalSum = invoices.reduce(
      (sum, i) =>
        sum +
        i.invoice.totalGross +
        i.invoice.reminder.reduce((sumR, r) => sumR + (r.position ? r.position.total : 0), 0),
      0
    );
    switch (level) {
      case "0":
        return "under 7 days (" + baseUtils.formatEuro(totalSum) + ")";
      case "1":
        return "between 7 and 14 days (" + baseUtils.formatEuro(totalSum) + ")";
      case "2":
        return "over 14 days (" + baseUtils.formatEuro(totalSum) + ")";
    }
  };

  render() {
    const { activeKey, context, level } = this.props;
    const { currentPage, invoices, invoiceToPay, pageSize } = this.state;
    const invoicesPaginated = paginate(invoices, currentPage, pageSize);
    return (
      <div className="kt-portlet">
        {invoiceToPay && (
          <PayInvoiceModal
            order={invoiceToPay.order}
            invoice={invoiceToPay.invoice}
            onHide={() => this.setState({ invoiceToPay: undefined })}
            show={!!invoiceToPay}
          />
        )}
        <Accordion.Toggle
          as={"div"}
          className={"kt-portlet__head " + (activeKey === level ? "accordion-selection" : "collapsed")}
          eventKey={level}
        >
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">
              Overdue invoices - {this.resolveLevelDescription()}
            </h3>
          </div>
          <span className="svg-icon svg-icon-rotate svg-icon-2x svg-icon-dark align-self-center">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")} />
          </span>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={level}>
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded overflow-auto m-4">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}>Nr</th>
                    <th style={{ width: "5%" }}>Total</th>
                    <th style={{ width: "5%" }}>State</th>
                    <th style={{ width: "5%" }}>Invoice</th>
                    <th style={{ width: "15%", minWidth: "150px" }}>Order</th>
                    <th style={{ width: "15%", minWidth: "150px" }}>Company</th>
                    <th style={{ width: "15%", minWidth: "150px" }}>Owner</th>
                    <th style={{ width: "5%" }}>Due</th>
                    <th style={{ width: "5%", minWidth: "75px" }}>Overdue since</th>
                    <th style={{ width: "10%", minWidth: "210px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {invoicesPaginated.length > 0 ? (
                    invoicesPaginated.map(i => {
                      const rem = i.invoice.reminder;
                      const due = orderUtils.getInvoiceDue(i.invoice.dueIn, i.invoice.invoiceDate);
                      const dunningFees = rem.reduce((sum, r) => sum + (r.position ? r.position.total : 0), 0);
                      const path = rem.length > 0 ? rem[rem.length - 1].path : i.invoice.path;
                      return (
                        <tr key={i.invoice._id.toString()}>
                          <td className="align-middle font-weight-bold">RE-{i.invoice.invoiceNumber}</td>
                          <td className="align-middle font-weight-bold">
                            <div className="kt-user-card-v2">
                              <div className="kt-user-card-v2__details">
                                <span className="kt-user-card-v2__name">
                                  {baseUtils.formatEuro(i.invoice.totalGross)}
                                </span>
                                <span className="kt-user-card-v2__email">
                                  {dunningFees ? "Dunning fees: " + baseUtils.formatEuro(dunningFees) : ""}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">
                            <span className="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill">
                              {i.invoice.state === I_PARTLYPAID ? "Partially Paid" : "Open"}
                            </span>
                          </td>
                          <td className="align-middle">
                            <span>
                              <div className="kt-widget4__pic kt-widget4__pic--icon pointer">
                                <img
                                  src={toAbsoluteUrl("/media/icons/pdf_icon.png")}
                                  alt="Invoice PDF"
                                  style={{ width: "30px" }}
                                  onClick={() => window.open(config.mediahubBase + path, "_blank")}
                                />
                              </div>
                            </span>
                          </td>
                          <td>
                            <OrderWidget
                              document={i.order}
                              prefix={orderUtils.resolveOrderDescription(i.order)[1] + "-"}
                            />
                          </td>
                          <td>
                            <CompanyWidget company={i.company} />
                          </td>
                          <td>
                            <PersonWidget person={i.order.createdFrom} />
                          </td>
                          <td className="align-middle">{baseUtils.formatDate(due)}</td>
                          <td className="align-middle">{Math.ceil(dateUtils.getDaysBetween(new Date(), due))} days</td>
                          <td className="align-middle">
                            <div className="btn-group">
                              <button
                                className="btn btn-success btn-sm btn-bold my-1"
                                onClick={() => this.setState({ invoiceToPay: { order: i.order, invoice: i.invoice } })}
                              >
                                Pay Invoice
                              </button>
                              <InvoiceModal
                                order={i.order}
                                context={context}
                                invoice={i.invoice}
                                type="reminder"
                                reminderButton={true}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10}>
                        <img
                          src={process.env.PUBLIC_URL + "/media/img/no_results.jpg"}
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: "500px"
                          }}
                          alt="No results found"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <Pagination
                  itemsCount={invoices.length}
                  pageSize={pageSize}
                  onPageChange={currentPage => this.setState({ currentPage })}
                  currentPage={currentPage}
                  additionalNavClasses="justify-content-center flex-grow-1"
                />
              </div>
            </div>
          </div>
        </Accordion.Collapse>
      </div>
    );
  }
}

export default OverdueInvoices;
