import React, { useRef, useState } from "react";
import { Overlay, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { OrdersDocument, pricing, pricingCommodities } from "../../model/orders.types";
import { DataContext } from "../../context/dataContext";
import { OrderWidget, PriorityBadge } from "../listings/common/BaseListingComponents";
import orderUtils, { PRODUCTIONQUEUE } from "../../utils/orderUtils";
import dateUtils from "../../utils/dateUtils";
import { PackagingsDocument } from "../../model/packagings.types";
import baseUtils from "../../utils/baseUtils";
import packagingUtils from "../../utils/packagingUtils";
import { ALLTORSO } from "../configurator/configuratorConstants";
import OrderNotes from "../order/tabPanles/OrderNotes";
import EditTargetDateModal from "../order/modals/EditTargetDateModal";

interface ProductionOrdersListingRowProps {
  order: OrdersDocument;
  context: React.ContextType<typeof DataContext>;
  standardView: boolean;
  onSelectOrder: () => void;
}

const ProductionOrdersListingRow: React.FunctionComponent<ProductionOrdersListingRowProps> = React.memo(
  ({ order, context, standardView, onSelectOrder }) => {
    const rowRef: React.RefObject<any> = useRef(null);
    const hoverClass = "table-hover";
    const planned = order.productionWeek && order.settings.productionMachine;

    const handleMouseEnter = () => {
      if (rowRef && rowRef.current) rowRef.current.classList.remove(hoverClass);
    };
    const handleMouseLeave = () => {
      if (rowRef && rowRef.current) rowRef.current.classList.add(hoverClass);
    };

    /**
     * Take a list of prices and resolve the matching commodity or packaging name
     * @param prices list of prices
     * @param type commodity or packaging
     * @returns {Array<{ name: string; ordered: Date | null; delivered: Date | null; eta: Date | null }>} list of commodities/packaging with
     *          their name, order and delivery date
     */
    const getNamedDocuments = (prices: Array<pricing | pricingCommodities>, type: "commodity" | "packaging") => {
      const { commodities, packagings } = context;
      const getName = (document: any): string => {
        if (!document) return "Unknown " + type;
        if (type === "commodity") return document.title.en;
        else return packagingUtils.resolvePackagingProperties(document);
      };
      return prices.map(p => {
        return {
          name: getName(
            baseUtils.getDocFromCollection(type === "commodity" ? commodities : packagings, p._id.toString())
          ),
          ordered: p.ordered,
          delivered: p.delivered,
          eta: p.eta
        };
      });
    };

    /**
     * Render packaging information cells
     * @param order an order document
     * @returns {JSX.Element} td elements with packaging info
     */
    const renderPackagingInfo = (order: OrdersDocument) => {
      const { packagings } = context;
      const units = order.calculations[0].units;
      const packaging = order.calculations[0].packagings;
      if (packaging.length === 0)
        return (
          <>
            <td className="kt-datatable__cell d-table-cell">
              <span className="kt-font-dark kt-font-bolder">Bulk</span>
            </td>
            {!standardView && (
              <td className="kt-datatable__cell d-table-cell">
                <span className="kt-font-dark">-</span>
              </td>
            )}
          </>
        );
      const fullPackaging: Array<PackagingsDocument> = packaging.map(p =>
        baseUtils.getDocFromCollection(packagings, p._id.toString())
      );
      const torso = fullPackaging.find(p => ALLTORSO.includes(p.packaging_type));
      const torsoPrice = torso ? packaging.find(p => p._id.toString() === torso._id.toString()) : null;
      const otherPackaging = fullPackaging.filter(p => !!torso && p._id.toString() !== torso._id.toString());
      return (
        <>
          <td className="kt-datatable__cell d-table-cell">
            <span className="kt-font-bold kt-font-dark">
              {torso && torsoPrice
                ? `${torsoPrice.amount * units} x ${
                    standardView
                      ? packagingUtils.getShortPackagingInfo(torso)
                      : packagingUtils.resolvePackagingProperties(torso)
                  }`
                : "No torso found"}
            </span>
          </td>
          {!standardView && (
            <td className="kt-datatable__cell d-table-cell">
              {otherPackaging.length > 0 ? (
                otherPackaging.map(p => {
                  const price = p ? packaging.find(price => price._id.toString() === p._id.toString()) : null;
                  if (p && price) {
                    return (
                      <span
                        key={p._id.toString()}
                        className="kt-font-dark"
                      >{`${packagingUtils.resolvePackagingProperties(p)}`}</span>
                    );
                  }
                })
              ) : (
                <span className="kt-font-dark">-</span>
              )}
            </td>
          )}
        </>
      );
    };

    /**
     * Render status information for the order
     * @param order an order document
     * @returns {JSX.Element} table cells with status information about commodities, packaging, etc.
     */
    const renderStatusInformation = (order: OrdersDocument) => {
      const { packagings } = context;
      const calculation = order.calculations[0];
      const commodities = calculation.prices;
      const packaging = calculation.packagings;
      const printingFileReq = orderUtils.getPrintingFileReq(order, packagings);
      const deliveredComm = commodities.reduce((a, b) => (!!b.delivered ? a + 1 : a), 0);
      const deliveredPack = packaging.reduce((a, b) => (!!b.delivered ? a + 1 : a), 0);
      const pfDiff = printingFileReq.required - printingFileReq.uploaded;
      const commDiff = commodities.length - deliveredComm;
      const packDiff = packaging.length - deliveredPack;
      const stateCond = order.state === PRODUCTIONQUEUE;
      const namedCommodities = getNamedDocuments(commodities, "commodity");
      const namedPackaging = getNamedDocuments(packaging, "packaging");

      const commodityPackagingStatus = standardView ? (
        <>
          <td className="kt-datatable__cell d-table-cell">
            <StatusBadge
              documents={namedCommodities}
              ratio={deliveredComm / commodities.length}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          </td>
          <td className="kt-datatable__cell d-table-cell">
            <StatusBadge
              documents={namedPackaging}
              ratio={deliveredPack / packaging.length}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          </td>
        </>
      ) : null;

      // Everything ready
      if (pfDiff <= 0 && commDiff <= 0 && packDiff <= 0 && stateCond) {
        return (
          <>
            {standardView && commodityPackagingStatus}
            <td className="kt-datatable__cell d-table-cell">
              <span>
                {!standardView && (
                  <div className="progress">
                    <div className="progress-bar bg-success" role="progressbar" style={{ width: "100%" }} />
                  </div>
                )}
                {order.settings.productionMachine && order.productionWeek ? (
                  <span className={"kt-font-bold "}>{`Planned for CW ${order.productionWeek.split("-")[0]}`}</span>
                ) : (
                  <span className={"kt-font-bold " + (standardView && "text-success")}>Ready</span>
                )}
              </span>
            </td>
          </>
        );
      }

      // Order not ready
      let progress = pfDiff <= 0 ? 33.33 : ((printingFileReq.uploaded / printingFileReq.required) * 100) / 3;
      progress += commDiff === 0 ? 33.33 : ((deliveredComm / commodities.length) * 100) / 3;
      progress += packDiff === 0 ? 33.33 : ((deliveredPack / packaging.length) * 100) / 3;
      return (
        <>
          {standardView && commodityPackagingStatus}
          <td className="kt-datatable__cell d-table-cell" style={{ minWidth: "180px" }}>
            {!standardView && (
              <div className="progress">
                <div
                  className={"progress-bar " + (progress > 70 ? "bg-warning" : "bg-danger")}
                  role="progressbar"
                  style={{ width: progress + "%" }}
                />
              </div>
            )}
            {pfDiff > 0 && (
              <span style={{ cursor: "text" }}>
                <span className="kt-font-dark kt-font-bold">{pfDiff}</span> printing file(s) missing
              </span>
            )}
            {commDiff > 0 && (
              <span>
                <StatusTooltip documents={namedCommodities} showReady={false}>
                  <span
                    className="kt-font-dark kt-font-bold"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={e => e.stopPropagation()}
                  >
                    {commDiff}
                  </span>
                </StatusTooltip>{" "}
                {commDiff === 1 ? "commodity" : "commodities"} not yet delivered
              </span>
            )}
            {packDiff > 0 && (
              <span>
                <StatusTooltip documents={namedPackaging} showReady={false}>
                  <span
                    className="kt-font-dark kt-font-bold"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={e => e.stopPropagation()}
                  >
                    {packDiff}
                  </span>
                </StatusTooltip>{" "}
                {packDiff === 1 ? "packaging" : "packagings"} not yet delivered
              </span>
            )}
          </td>
        </>
      );
    };

    return (
      <tr
        className={"kt-datatable__row d-table-row " + hoverClass}
        style={planned ? { backgroundColor: "#fafafa" } : {}}
        onClick={onSelectOrder}
        ref={rowRef}
      >
        <td className="kt-datatable__cell d-table-cell" style={{ minWidth: "150px" }}>
          <OrderWidget
            document={order}
            prefix={"Order AT-"}
            popover={<NotePopover order={order} context={context} />}
          />
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <PriorityBadge document={order} />
        </td>
        <td
          className="kt-datatable__cell d-table-cell"
          style={{ minWidth: "120px" }}
          onClick={e => e.stopPropagation()}
        >
          <EditTargetDateModal order={order} targetWeekBadge={true} />
        </td>
        <td className="kt-datatable__cell d-table-cell text-center">
          <span className="kt-font-bold kt-font-dark">{orderUtils.getTotalProductAmount(order)}</span>
          <span className="kt-font-dark">in {order.calculations[0].units} units</span>
        </td>
        {renderPackagingInfo(order)}
        {renderStatusInformation(order)}
      </tr>
    );
  }
);

interface StatusBadgeProps {
  documents: Array<{ name: string; ordered: Date | null; delivered: Date | null; eta: Date | null }>;
  ratio: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const StatusBadge: React.FunctionComponent<StatusBadgeProps> = ({ documents, ratio, onMouseEnter, onMouseLeave }) => {
  return (
    <span>
      <StatusTooltip documents={documents} showReady={true}>
        {documents.length === 0 ? (
          <span>-</span>
        ) : ratio === 1 ? (
          <span
            className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={e => e.stopPropagation()}
          >
            Delivered
          </span>
        ) : (
          <span
            className={
              "kt-badge kt-badge--inline kt-badge--pill " + (ratio <= 0.7 ? "kt-badge--danger" : "kt-badge--warning")
            }
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={e => e.stopPropagation()}
          >
            Pending
          </span>
        )}
      </StatusTooltip>
    </span>
  );
};

interface StatusTooltipProps {
  documents: Array<{ name: string; ordered: Date | null; delivered: Date | null; eta: Date | null }>;
  showReady: boolean;
  children: JSX.Element;
}

const StatusTooltip: React.FunctionComponent<StatusTooltipProps> = ({ documents, showReady, children }) => {
  let filteredDocuments = showReady ? documents : documents.filter(d => !d.ordered || !d.delivered);
  return (
    <OverlayTrigger
      placement="auto"
      overlay={
        <Tooltip id="statusInfo" className={documents.length === 0 ? "d-none" : ""}>
          <span className="text-left" style={{ fontSize: "12px" }}>
            <p>
              <span className="mr-3">
                <i className="fas fa-circle text-danger mr-1" />
                Not ordered
              </span>
              <span className="mr-3">
                <i className="fas fa-circle text-warning mr-1" />
                Not delivered
              </span>
              {showReady && (
                <span className="mr-3">
                  <i className="fas fa-circle text-success mr-1" />
                  Ready
                </span>
              )}
            </p>
            {filteredDocuments.map(doc => (
              <React.Fragment key={doc.name}>
                <div>
                  {doc.ordered && doc.delivered ? (
                    <i className="fas fa-circle text-success mr-1" />
                  ) : doc.ordered ? (
                    <i className="fas fa-circle text-warning mr-1" />
                  ) : (
                    <i className="fas fa-circle text-danger mr-1" />
                  )}
                  <span className="kt-font-dark">{doc.name.trim()}</span>
                  {!doc.delivered && doc.ordered && (
                    <span className="ml-1 kt-font-bold">
                      - ETA:{" "}
                      {doc.eta ? (
                        <span className="text-warning">{`CW ${dateUtils.getCW(
                          doc.eta
                        )} (${doc.eta.getFullYear()})`}</span>
                      ) : (
                        "not set"
                      )}
                    </span>
                  )}
                </div>
              </React.Fragment>
            ))}
          </span>
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

interface NotePopoverProps {
  order: OrdersDocument;
  context: React.ContextType<typeof DataContext>;
}

const NotePopover: React.FunctionComponent<NotePopoverProps> = ({ order, context }) => {
  const overlayRef: React.RefObject<any> = useRef(null);
  const [show, setShow] = useState(false);
  return (
    <>
      <button
        className="btn btn-icon ml-1"
        ref={overlayRef}
        onClick={e => {
          e.stopPropagation();
          setShow(!show);
        }}
        style={{ height: "auto", width: "auto" }}
      >
        <i className="fas fa-pen-square text-muted align-middle" style={{ fontSize: "0.9rem", opacity: "0.5" }} />
      </button>
      <Overlay rootClose show={show} onHide={() => setShow(false)} target={overlayRef.current} placement={"right"}>
        <Popover id={"notes-popover"}>
          <Popover.Title as="h5" className="kt-font-bolder text-dark">
            Notes
          </Popover.Title>
          <Popover.Content>
            <div onClick={e => e.stopPropagation()}>
              <div className="mb-2" style={{ maxHeight: "50vh", overflowY: "auto", overflowX: "hidden" }}>
                <OrderNotes order={order} context={context} alternateText={true} small={true} />
              </div>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

export default ProductionOrdersListingRow;
