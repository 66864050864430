import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import i18n from "../../../translations/i18n";
import CreateStockModal from "../modals/CreateStockModal";
import AssignStorageSpaceModal from "../modals/AssignStorageSpaceModal";
import { selectionFromOneBatch, selectionIsBlocked, WarehouseActionNumber } from "../../../utils/warehouseActionUtils";
import { BaseActionModalProps } from "../../../model/warehouse/common.types";
import { useWarehouseContext, useWarehouseDispatch, WarehouseActionType } from "../../../context/warehouseContext";
import { useDataContext } from "../../../context/dataContext";
import ChangeBatchDataModal from "../modals/ChangeBatchDataModal";
import ChangeBBDModal from "../modals/ChangeBBDModal";
import BookOutModal from "../modals/bookOutModal/BookOutModal";
import CreateReservationModal from "../modals/reservationModal/CreateReservationModal";
import CancelReservationModal from "../modals/CancelReservationModal";
import ReportDamageModal from "../modals/ReportDamageModal";
import { SelectedDeliveryAnnouncementEntryType } from "../../../utils/warehouseUtils";
import BookDeliveryModal from "../modals/BookDeliveryModal";
import ReadCodeModal from "../modals/ReadCodeModal";

interface WarehouseAction {
  number: WarehouseActionNumber;
  label: string;
  disabled?: boolean;
  onClick?: () => void;
}

interface WarehouseActionModal {
  component: JSX.Element;
}

interface WarehouseActionPanelProps {}

const WarehouseActionPanel: React.FC<WarehouseActionPanelProps> = () => {
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { selectedEntries } = warehouseContext;
  const { batch } = dataContext;

  const singleSelection = useMemo(() => selectionFromOneBatch(selectedEntries), [selectedEntries]);
  const selectionBlocked = useMemo(() => selectionIsBlocked(selectedEntries, batch), [selectedEntries, batch]);

  const singleAvisSelection = useMemo(
    () => selectedEntries.filter(sE => sE.type === SelectedDeliveryAnnouncementEntryType.NOTIFICATION).length === 1,
    [selectedEntries]
  );

  const actionsDefinition: Array<Array<WarehouseAction | WarehouseActionModal>> = useMemo(
    () => [
      [
        {
          component: (
            <WarehouseActionModalEntry
              key={WarehouseActionNumber.CREATE_STOCK}
              action={{
                number: WarehouseActionNumber.CREATE_STOCK,
                label: i18n.t("warehouse:createStock"),
                disabled: false
              }}
              ModalComponent={CreateStockModal}
            />
          )
        },
        {
          component: (
            <WarehouseActionModalEntry
              key={WarehouseActionNumber.BOOK_DELIVERY}
              action={{
                number: WarehouseActionNumber.BOOK_DELIVERY,
                label: i18n.t("warehouse:bookDelivery"),
                disabled: !singleAvisSelection
              }}
              ModalComponent={BookDeliveryModal}
            />
          )
        },
        {
          component: (
            <WarehouseActionModalEntry
              key={WarehouseActionNumber.ASSIGN_STORAGE_SPACE}
              action={{
                number: WarehouseActionNumber.ASSIGN_STORAGE_SPACE,
                label: i18n.t("warehouse:assignStorageSpace"),
                disabled: !singleSelection
              }}
              ModalComponent={AssignStorageSpaceModal}
            />
          )
        },
        { number: WarehouseActionNumber.SPLIT_GROUP, label: i18n.t("warehouse:splitGroup"), disabled: true },
        {
          component: (
            <WarehouseActionModalEntry
              key={WarehouseActionNumber.CREATE_RESERVATION}
              action={{
                number: WarehouseActionNumber.CREATE_RESERVATION,
                label: i18n.t("warehouse:createReservation"),
                disabled: selectedEntries.length > 0 && (!singleSelection || selectionBlocked)
              }}
              ModalComponent={CreateReservationModal}
            />
          )
        }
      ],
      [
        {
          component: (
            <WarehouseActionModalEntry
              key={WarehouseActionNumber.CANCEL_RESERVATION}
              action={{
                number: WarehouseActionNumber.CANCEL_RESERVATION,
                label: i18n.t("warehouse:cancelReservation"),
                disabled: true
              }}
              ModalComponent={CancelReservationModal}
            />
          )
        },
        {
          component: (
            <WarehouseActionModalEntry
              key={WarehouseActionNumber.BOOK_OUT}
              ModalComponent={BookOutModal}
              action={{
                number: WarehouseActionNumber.BOOK_OUT,
                label: i18n.t("warehouse:bookOutStock"),
                disabled: selectionBlocked
              }}
            />
          )
        },
        {
          number: WarehouseActionNumber.SEND_TO_PRODUCTION,
          label: i18n.t("warehouse:sendToProduction"),
          disabled: true
        },
        {
          number: WarehouseActionNumber.PRINT_DELIVERY_NOTES,
          label: i18n.t("warehouse:printDeliveryNotes"),
          disabled: true
        },
        { number: WarehouseActionNumber.PRINT_PICK_LIST, label: i18n.t("warehouse:printPickList"), disabled: true }
      ]
    ],
    [singleSelection, singleAvisSelection]
  );
  const otherActionDefinition: Array<Array<WarehouseAction | WarehouseActionModal>> = useMemo(
    () => [
      [
        {
          component: (
            <WarehouseActionModalEntry
              key={WarehouseActionNumber.REPORT_DAMAGE}
              ModalComponent={ReportDamageModal}
              action={{
                number: WarehouseActionNumber.REPORT_DAMAGE,
                label: i18n.t("warehouse:reportDamage"),
                disabled: !singleSelection
              }}
            />
          )
        },
        {
          component: (
            <WarehouseActionModalEntry
              key={WarehouseActionNumber.CHANGE_BATCH_DATA}
              ModalComponent={ChangeBatchDataModal}
              action={{
                number: WarehouseActionNumber.CHANGE_BATCH_DATA,
                label: i18n.t("warehouse:changeInformation"),
                disabled: !singleSelection
              }}
            />
          )
        },
        {
          component: (
            <WarehouseActionModalEntry
              key={WarehouseActionNumber.CHANGE_BDD}
              ModalComponent={ChangeBBDModal}
              action={{
                number: WarehouseActionNumber.CHANGE_BDD,
                label: i18n.t("warehouse:correctBestBefore"),
                disabled: !singleSelection
              }}
            />
          )
        }
      ],
      [
        {
          number: WarehouseActionNumber.RETROFITTING_STORAGE,
          label: i18n.t("warehouse:retrofittingStorage"),
          disabled: true
        },
        { number: WarehouseActionNumber.EXPORT_CSV, label: i18n.t("warehouse:exportCSV"), disabled: true },
        {
          component: (
            <WarehouseActionModalEntry
              key={WarehouseActionNumber.READ_CODE}
              ModalComponent={ReadCodeModal}
              action={{ number: WarehouseActionNumber.READ_CODE, label: i18n.t("warehouse:readCode") }}
            />
          )
        }
      ]
    ],
    [singleSelection]
  );

  return (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title kt-font-bolder">{i18n.t("warehouse:actions")}</h3>
        </div>
        <div className="kt-portlet__head-toolbar" />
      </div>
      <div className="kt-portlet__body">
        <WarehouseActionPanelSelection />
        <div className="navi navi-hover navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon">
          <div className="accordion accordion-light accordion-toggle-arrow" id="accordionGeneralActions">
            <div className="card mb-0">
              <div className="card-header" id="headingGeneralActions">
                <div className="card-title" data-toggle="collapse" data-target="#collapseGeneralActions">
                  <span className="navi-text font-weight-medium font-size-lg text-black">
                    {i18n.t("warehouse:generalActions")}
                  </span>
                </div>
              </div>
              <div id="collapseGeneralActions" className="collapse show" data-parent="#accordionGeneralActions">
                <div className="card-body">
                  <div className="row">
                    {actionsDefinition.map((actions, idx) => (
                      <div key={idx} className="col-6">
                        {actions.map(action => {
                          if ("component" in action) return action.component;
                          else return <WarehouseActionEntry key={action.number} action={action} />;
                        })}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="w-100" />
          <div className="accordion accordion-light accordion-toggle-arrow" id="accordionOtherActions">
            <div className="card mb-0">
              <div className="card-header" id="headingOtherActions">
                <div className="card-title" data-toggle="collapse" data-target="#collapseOtherActions">
                  <span className="navi-text font-weight-medium font-size-lg text-black">
                    {i18n.t("warehouse:otherActions")}
                  </span>
                </div>
              </div>
              <div id="collapseOtherActions" className="collapse show" data-parent="#accordionOtherActions">
                <div className="card-body">
                  <div className="row">
                    {otherActionDefinition.map((actions, idx) => (
                      <div key={idx} className="col-6">
                        {actions.map(action => {
                          if ("component" in action) {
                            return action.component;
                          } else return <WarehouseActionEntry key={action.number} action={action} />;
                        })}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface WarehouseActionEntryProps {
  action: WarehouseAction;
}

const WarehouseActionEntry: React.FC<WarehouseActionEntryProps> = ({ action }) => {
  return (
    <span
      className={"navi-item my-0 pointer " + (!action.onClick && "not-allowed-cursor opacity-50")}
      onClick={action.onClick}
    >
      <span className="navi-link">
        <span className="navi-icon mr-4">
          <span className="label label-box label-rounded label-light font-weight-bold mr-2">{action.number}</span>
        </span>
        <span className="navi-text font-weight-bolder ">{action.label}</span>
      </span>
    </span>
  );
};

interface WarehouseActionModalEntryProps {
  action: Pick<WarehouseAction, "label" | "number" | "disabled">;
  ModalComponent: React.FC<BaseActionModalProps>;
}

const WarehouseActionModalEntry: React.FC<WarehouseActionModalEntryProps> = ({ action, ModalComponent }) => {
  const warehouseContext = useWarehouseContext();
  const dispatch = useWarehouseDispatch();
  const { actionTrigger } = warehouseContext;

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (actionTrigger.actionNumber === action.number) {
      setShow(prevState => !prevState);
    }
  }, [actionTrigger, action.number]);

  const handleShow = useCallback(() => setShow(true), []);

  const handleHide = useCallback(() => {
    setShow(false);
    if (actionTrigger !== null) {
      dispatch({ type: WarehouseActionType.TRIGGER_ACTION, payload: { actionNumber: null } });
    }
  }, [actionTrigger]);

  return (
    <>
      <ModalComponent
        show={show}
        actionTrigger={actionTrigger.actionNumber === action.number ? actionTrigger : undefined}
        onHide={handleHide}
      />
      <span
        className={"navi-item my-0 pointer " + (action.disabled && "not-allowed-cursor opacity-50")}
        onClick={action.disabled ? undefined : handleShow}
      >
        <span className="navi-link">
          <span className="navi-icon mr-4">
            <span className="label label-box label-rounded label-light font-weight-bold mr-2">{action.number}</span>
          </span>
          <span className="navi-text font-weight-bolder ">{action.label}</span>
        </span>
      </span>
    </>
  );
};

interface WarehouseActionPanelSelectionProps {}

const WarehouseActionPanelSelection: React.FC<WarehouseActionPanelSelectionProps> = () => {
  const selectOptions = [
    { value: "1", label: i18n.t("warehouse:sameBatch") },
    { value: "2", label: i18n.t("warehouse:sameLocation") },
    { value: "3", label: i18n.t("warehouse:sameOrder") },
    { value: "4", label: i18n.t("warehouse:free") },
    { value: "5", label: i18n.t("warehouse:blocked") }
  ];

  return (
    <>
      <div className="form-group form-group-xs row ">
        <label className="col-3 align-self-center kt-font-bold text-black">{i18n.t("warehouse:selection")}</label>
        <div className="col-9">
          <span className="not-allowed">
            <Select
              options={selectOptions}
              isMulti
              isDisabled={true}
              closeMenuOnSelect={false}
              classNamePrefix={"multiselect"}
              placeholder={i18n.t("warehouse:manualSelection")}
            />
          </span>
        </div>
      </div>
      <small className="text-muted text-right">{i18n.t("warehouse:batchesSelected", { amount: 0 })}</small>
    </>
  );
};

export default WarehouseActionPanel;
