export const headerBaseDefinition = [
  { title: "Description", size: 18 },
  { title: "Company", size: 15 },
  { title: "Owner", size: 12 },
  { title: "State", size: 7 },
  { title: "Date", size: 6 },
  { title: "Commodities", size: 7 },
  { title: "Invoice", size: 5 },
  { title: "Paid", size: 5 },
  { title: "Units", size: 7 },
  { title: "Volume", size: 5 },
  { title: "Manufacturer", size: 10 }
];

export const headerBaseDefinitionProd = [
  { title: "Description", size: 25 },
  { title: "Company", size: 20 },
  { title: "Owner", size: 15 },
  { title: "State", size: 10 },
  { title: "Date", size: 10 },
  { title: "Commodities", size: 10 },
  { title: "Units", size: 10 }
];

export const headerOrderCommodities = [
  { title: "Description", size: 20 },
  { title: "Company", size: 20 },
  { title: "Created", size: 8 },
  { title: "Priority", size: 7 },
  { title: "Margin", size: 7 },
  { title: "Bound Capital", size: 8 },
  { title: "Ordered", size: 15 },
  { title: "Owner", size: 15 }
];

export const headerOrderCommoditiesProd = [
  { title: "Description", size: 30 },
  { title: "Company", size: 20 },
  { title: "Created", size: 10 },
  { title: "Priority", size: 10 },
  { title: "Ordered", size: 15 },
  { title: "Owner", size: 15 }
];

export const headerOrderPackaging = [
  { title: "Description", size: 20 },
  { title: "Company", size: 20 },
  { title: "Files required", size: 10 },
  { title: "Ordered", size: 10 },
  { title: "Arrived", size: 10 },
  { title: "Ordered", size: 15 },
  { title: "Owner", size: 15 }
];

export const headerOrderProduction = [
  { title: "Description", size: 20 },
  { title: "Company", size: 20 },
  { title: "Created", size: 9 },
  { title: "Priority", size: 9 },
  { title: "Margin", size: 9 },
  { title: "Delivery", size: 9 },
  { title: "Update Delivery", size: 9 },
  { title: "Owner", size: 15 }
];

export const headerOrderProductionProd = [
  { title: "Description", size: 25 },
  { title: "Company", size: 20 },
  { title: "Created", size: 10 },
  { title: "Priority", size: 10 },
  { title: "Delivery", size: 10 },
  { title: "Update Delivery", size: 10 },
  { title: "Owner", size: 15 }
];

export const headerOrderFulfillment = [
  { title: "Description", size: 20 },
  { title: "Company", size: 20 },
  { title: "Created", size: 11.25 },
  { title: "Priority", size: 11.25 },
  { title: "Margin", size: 11.25 },
  { title: "Delivery", size: 11.25 },
  { title: "Owner", size: 15 }
];

export const headerOrderFulfillmentProd = [
  { title: "Description", size: 30 },
  { title: "Company", size: 20 },
  { title: "Created", size: 10 },
  { title: "Priority", size: 10 },
  { title: "Delivery", size: 15 },
  { title: "Owner", size: 15 }
];

export const headerOrderInvoice = [
  { title: "Description", size: 20 },
  { title: "Company", size: 20 },
  { title: "Finished", size: 9 },
  { title: "Turnover", size: 9 },
  { title: "Revenue", size: 9 },
  { title: "Invoiced", size: 9 },
  { title: "Paid", size: 9 },
  { title: "Owner", size: 15 }
];

export const headerOrderInvoiceProd = [
  { title: "Description", size: 35 },
  { title: "Company", size: 30 },
  { title: "Finished", size: 20 },
  { title: "Owner", size: 15 }
];

export const headerOrderDeclined = [
  { title: "Description", size: 20 },
  { title: "Company", size: 20 },
  { title: "Created", size: 15 },
  { title: "Declined", size: 15 },
  { title: "Declined By", size: 15 },
  { title: "Owner", size: 15 }
];
