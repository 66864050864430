import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { ExtendedReservation } from "../../../../../model/warehouse/customTypes.types";
import Tooltip from "../../../../common/Tooltip";
import i18n from "../../../../../translations/i18n";

interface ExtendedReservationRowProps {
  reservation: ExtendedReservation;
  onChangeReservationAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onReservationTakeAll: (reservationId: string) => void;
  onToggleReservationCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ExtendedReservationRow: React.FC<ExtendedReservationRowProps> = React.memo(
  ({ reservation, onChangeReservationAmount, onReservationTakeAll, onToggleReservationCheckbox }) => {
    const handleReservationTakeAll = useCallback(() => onReservationTakeAll(reservation._id.toString()), []);

    const amountLeft = useMemo(
      () =>
        reservation.reservedAmount.value <= +reservation.amountToBookOut
          ? 0
          : reservation.reservedAmount.value - +reservation.amountToBookOut,
      [reservation.reservedAmount, reservation.amountToBookOut]
    );

    const nothingLeft = useMemo(() => amountLeft <= 0, [amountLeft]);

    const checkBoxDisabled = useMemo(
      () => +reservation.amountToBookOut <= 0 || nothingLeft,
      [nothingLeft, reservation.amountToBookOut]
    );

    return (
      <tr className="kt-datatable__row d-table-row border-0">
        <td className="kt-datatable__cell d-table-cell pb-0">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details ">
              <span className="kt-user-card-v2__name text-black ">RV-{reservation.identifier}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell pb-0">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name text-black font-weight-300">
                AT-{reservation.order.identifier}
              </span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell pb-0">
          <div className="input-group input-group-sm">
            <input
              type={"text"}
              disabled
              className="form-control form-control-sm form-control-solid not-allowed-cursor"
              value={reservation.reservedAmount.value}
            />
            <div className="input-group-append">
              <span className="input-group-text input-group-solid">{reservation.reservedAmount.unit}</span>
            </div>
            <div className="input-group-append input-group-solid">
              <Tooltip tooltipText={i18n.t(`warehouse:${nothingLeft ? "nothingLeft" : "useTotalAmount"}`)}>
                <button
                  className={"btn btn-light " + (nothingLeft ? "disabled" : "")}
                  onClick={nothingLeft ? undefined : handleReservationTakeAll}
                >
                  <i className="fas fa-angle-double-right pr-0" />
                </button>
              </Tooltip>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell pb-0">
          <div className="input-group input-group-sm">
            <input
              type={"number"}
              min={0}
              name={reservation._id.toString()}
              className="form-control form-control-sm form-control-solid hide-arrows"
              value={reservation.amountToBookOut}
              onChange={onChangeReservationAmount}
            />
            <div className="input-group-append">
              <span className="input-group-text input-group-solid">{reservation.reservedAmount.unit}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell pb-0">
          <div className="input-group input-group-sm">
            <input
              type={"text"}
              disabled
              className="form-control form-control-sm form-control-solid "
              value={reservation.removeReservation ? "0" : amountLeft}
            />
            <div className="input-group-append">
              <span className="input-group-text input-group-solid">{reservation.reservedAmount.unit}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell pb-0 text-center">
          <label
            className={"checkbox checkbox-single flex-shrink-0 mr-2 " + (checkBoxDisabled ? "checkbox-disabled" : "")}
          >
            <input
              type="checkbox"
              name={reservation._id.toString()}
              disabled={checkBoxDisabled}
              checked={reservation.removeReservation}
              onChange={onToggleReservationCheckbox}
            />
            <span />
          </label>
        </td>
      </tr>
    );
  },
  _.isEqual
);
