import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { DataContext } from "../../context/dataContext";
import dashboardUtils from "../../utils/dashboardUtils";
import { Period } from "../common/CustomTypes";
import baseUtils from "../../utils/baseUtils";
import SalesWelcome from "./sales/SalesWelcome";
import SalesStats from "./sales/SalesStats";
import MissingLabels from "./sales/MissingLabels";
import LatestOrders from "./sales/LatestOrders";
import GeneralVisualization from "./sales/GeneralVisualization";
import orderUtils from "../../utils/orderUtils";

interface SalesDashboardProps {
  context: React.ContextType<typeof DataContext>;
  selectedUser: BSON.ObjectId | string;
  period: Period;
}

interface SalesDashboardState {}

class SalesDashboard extends PureComponent<SalesDashboardProps, SalesDashboardState> {
  constructor(props: SalesDashboardProps) {
    super(props);
  }
  render() {
    const { context, selectedUser, period } = this.props;
    const { orders, companies, userdata, packagings, manufacturers } = context;
    const ordersForOwner = dashboardUtils.getOrdersForOwner(orders, selectedUser);
    const user = baseUtils.getDocFromCollection(userdata, selectedUser);
    const salesStats = dashboardUtils.getSalesStats(ordersForOwner, user, period);
    return (
      <>
        <div className="row">
          <div className="col-12">
            <SalesWelcome stats={salesStats} selectedUser={user} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SalesStats stats={salesStats} selectedUser={user} orders={orders} dashboard="sales" />
          </div>
        </div>
        <div className="row ">
          <div className="col-xl-4">
            <LatestOrders orders={ordersForOwner} companies={companies} />
          </div>
          <div className="col-xl-4">
            <GeneralVisualization orders={ordersForOwner} />
          </div>
          <div className="col-xl-4">
            <MissingLabels
              orders={ordersForOwner.filter(o => orderUtils.isOrder(o) && orderUtils.isActive(o))}
              companies={companies}
              packagings={packagings}
              manufacturers={manufacturers}
            />
          </div>
        </div>
      </>
    );
  }
}

export default SalesDashboard;
