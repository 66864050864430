import React, { PureComponent } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { DataContext } from "../../context/dataContext";
import PackagingWidget from "../common/PackagingWidget";
import baseUtils from "../../utils/baseUtils";
import packagingUtils from "../../utils/packagingUtils";
import { PackagingExtendedExport } from "./CustomTypes";

interface PackagingStockOverviewProps {
  context: React.ContextType<typeof DataContext>;
  packagingsExtended: Array<PackagingExtendedExport>;
}

interface PackagingStockOverviewState {}

class PackagingStockOverview extends PureComponent<PackagingStockOverviewProps, PackagingStockOverviewState> {
  render() {
    const { packagingsExtended, context } = this.props;

    return (
      <Table>
        <thead>
          <tr>
            <th style={{ width: "15%" }}>Packaging</th>
            <th style={{ width: "5%" }}>Color</th>
            <th style={{ width: "5%" }}>Type</th>
            <th style={{ width: "8%" }}>Usage</th>
            <th style={{ width: "8%" }}>Supplier</th>
            <th style={{ width: "8%" }}>Best Price</th>
            <th style={{ width: "8%" }}>Fastest</th>
            <th style={{ width: "8%" }}>Last Update</th>
            <th style={{ width: "8%" }}>Order Req.</th>
            <th style={{ width: "8%" }}>Ordered</th>
            <th style={{ width: "8%" }}>Stock</th>
            <th style={{ width: "8%" }}>Available</th>
          </tr>
        </thead>
        <tbody>
          {packagingsExtended.length > 0 ? (
            packagingsExtended.map(p => {
              return (
                <tr key={p.packaging._id.toString()}>
                  <td className="align-middle font-weight-bold" style={{ opacity: p.packaging.disabled ? "0.4" : "1" }}>
                    <div className="kt-user-card-v2__details align-middle">
                      <PackagingWidget packaging={p.packaging} context={context} />
                    </div>
                  </td>
                  <td className="align-middle " style={{ opacity: p.packaging.disabled ? "0.4" : "1" }}>
                    <div className="align-middle" style={packagingUtils.getBadgeColor(p.packaging)} />
                  </td>
                  <td className="align-middle " style={{ opacity: p.packaging.disabled ? "0.4" : "1" }}>
                    <span className={"font-weight-bolder"}>
                      {p.packaging.packaging_type
                        ? packagingUtils.getPackagingDescription(p.packaging.packaging_type)
                        : "unknown"}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span>
                      <div className="kt-user-card-v2">
                        <div className="kt-user-card-v2__details">
                          <span className="kt-user-card-v2__name">
                            <span style={{ fontSize: 8 }}>
                              {p.usage.usage === 0 ? (
                                <i className="text-secondary fas fa-circle" />
                              ) : p.usage.usage > 0 && p.usage.usage < 10000 ? (
                                <i className="fas fa-circle" style={{ color: "red" }} />
                              ) : p.usage.usage >= 10000 && p.usage.usage < 50000 ? (
                                <React.Fragment>
                                  <i className="fas fa-circle" style={{ color: "#fd7e14" }} />{" "}
                                  <i className="fas fa-circle" style={{ color: "#fd7e14" }} />
                                </React.Fragment>
                              ) : p.usage.usage >= 50000 && p.usage.usage < 100000 ? (
                                <React.Fragment>
                                  <i className="fas fa-circle" style={{ color: "#ffc107" }} />{" "}
                                  <i className="fas fa-circle" style={{ color: "#ffc107" }} />{" "}
                                  <i className="fas fa-circle" style={{ color: "#ffc107" }} />{" "}
                                </React.Fragment>
                              ) : p.usage.usage >= 100000 && p.usage.usage < 500000 ? (
                                <React.Fragment>
                                  <i className="fas fa-circle" style={{ color: "#3faf59" }} />{" "}
                                  <i className="fas fa-circle" style={{ color: "#3faf59" }} />{" "}
                                  <i className="fas fa-circle" style={{ color: "#3faf59" }} />{" "}
                                  <i className="fas fa-circle" style={{ color: "#3faf59" }} />{" "}
                                </React.Fragment>
                              ) : (
                                p.usage.usage >= 500000 && (
                                  <React.Fragment>
                                    <i className="fas fa-circle" style={{ color: "#009e27" }} />{" "}
                                    <i className="fas fa-circle" style={{ color: "#009e27" }} />{" "}
                                    <i className="fas fa-circle" style={{ color: "#009e27" }} />{" "}
                                    <i className="fas fa-circle" style={{ color: "#009e27" }} />{" "}
                                    <i className="fas fa-circle" style={{ color: "#009e27" }} />{" "}
                                  </React.Fragment>
                                )
                              )}
                            </span>
                          </span>
                          <span className="kt-user-card-v2__email">
                            <b>{p.usage.usage + " units"}</b> per year
                          </span>
                        </div>
                      </div>
                    </span>
                  </td>
                  <td className="align-middle " style={{ opacity: p.packaging.disabled ? "0.4" : "1" }}>
                    <span>
                      <div className="kt-user-card-v2">
                        <div className="kt-user-card-v2__details">
                          <div className="kt-widget__media">
                            <span className="kt-user-card-v2__name pointer">
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 100, hide: 400 }}
                                overlay={
                                  <Tooltip id="button-tooltip product-popover">
                                    {p.packaging.suppliers.map(supplier => {
                                      const s = baseUtils.getDocFromCollection(context.suppliers, supplier._id);
                                      return (
                                        <React.Fragment key={supplier._id.toString()}>
                                          <span className="kt-font-dark kt-font-bold" style={{ float: "left" }}>
                                            {s ? s.name : "error"}
                                          </span>
                                          <span className="ml-2">
                                            {supplier.prices.length}
                                            {supplier.prices.length === 1 ? " price" : " prices"}
                                          </span>
                                          <br />
                                        </React.Fragment>
                                      );
                                    })}
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {p.packaging.suppliers.length}{" "}
                                  {p.packaging.suppliers.length === 1 ? "supplier" : "suppliers"}
                                </span>
                              </OverlayTrigger>
                            </span>
                          </div>
                        </div>
                      </div>
                    </span>
                  </td>
                  <td className="align-middle " style={{ opacity: p.packaging.disabled ? "0.4" : "1" }}>
                    <span>
                      <div className="kt-user-card-v2">
                        <div className="kt-user-card-v2__details">
                          <span className="kt-user-card-v2__name">
                            {baseUtils.formatEuro(p.lowestPrice.price || 0)}
                          </span>
                          <span className="kt-user-card-v2__email">
                            {p.lowestPrice &&
                              p.lowestPrice.supplier &&
                              p.lowestPrice.moq !== undefined &&
                              p.lowestPrice.moq + " " + "pcs."}
                          </span>
                        </div>
                      </div>
                    </span>
                  </td>
                  <td className="align-middle " style={{ opacity: p.packaging.disabled ? "0.4" : "1" }}>
                    <span>
                      <div className="kt-user-card-v2">
                        <div className="kt-user-card-v2__details">
                          <span className="kt-user-card-v2__name">
                            {p.fastestPrice ? baseUtils.formatEuro(p.fastestPrice.price.price) : "-"}
                          </span>
                          <span className="kt-user-card-v2__email">
                            {p.fastestPrice &&
                              p.fastestPrice.price.deliverytime &&
                              p.fastestPrice.price.deliverytime + " " + "Days"}
                          </span>
                        </div>
                      </div>
                    </span>
                  </td>
                  <td className="align-middle " style={{ opacity: p.packaging.disabled ? "0.4" : "1" }}>
                    <span>
                      <div className="kt-user-card-v2">
                        <div className="kt-user-card-v2__details">
                          <span
                            className={
                              "kt-user-card-v2__name " +
                              (p.lastUpdate &&
                                p.daysAgo &&
                                (p.daysAgo > 90 && p.daysAgo < 180
                                  ? "text-warning"
                                  : p.lastUpdate && p.daysAgo >= 180
                                  ? "text-danger"
                                  : null))
                            }
                          >
                            {p.lastUpdate ? p.lastUpdate.toLocaleDateString() : "-"}
                          </span>
                        </div>
                      </div>
                    </span>
                  </td>
                  <td className="align-middle " style={{ opacity: p.packaging.disabled ? "0.4" : "1" }}>
                    <span>
                      <div className="kt-user-card-v2">
                        <div className="kt-user-card-v2__details">
                          <span
                            className={"kt-user-card-v2__name " + (p.usage.required.amount ? "text-primary" : null)}
                          >
                            {p.usage.required.amount ? p.usage.required.amount + " " + "pcs." : "-"}
                          </span>
                        </div>
                      </div>
                    </span>
                  </td>
                  <td className="align-middle " style={{ opacity: p.packaging.disabled ? "0.4" : "1" }}>
                    <span>
                      <div className="kt-user-card-v2">
                        <div className="kt-user-card-v2__details">
                          <span className={"kt-user-card-v2__name " + (p.usage.ordered.amount ? "text-primary" : null)}>
                            {p.usage.ordered.amount ? p.usage.ordered.amount + " " + "pcs." : "-"}
                          </span>
                        </div>
                      </div>
                    </span>
                  </td>
                  <td className="align-middle " style={{ opacity: p.packaging.disabled ? "0.4" : "1" }}>
                    <span>
                      <div className="kt-user-card-v2">
                        <div className="kt-user-card-v2__details">
                          <span className={"kt-user-card-v2__name " + (p.totalStock ? "text-primary" : null)}>
                            {p.totalStock ? p.totalStock + " " + "pcs." : "-"}
                          </span>
                        </div>
                      </div>
                    </span>
                  </td>
                  <td className="align-middle " style={{ opacity: p.packaging.disabled ? "0.4" : "0.8" }}>
                    <span>
                      <div className="kt-user-card-v2">
                        <div className="kt-user-card-v2__details">
                          <span
                            className={
                              "kt-user-card-v2__name " +
                              (p.available &&
                                (p.available < 0
                                  ? "text-danger"
                                  : p.available === 0
                                  ? "text-warning"
                                  : p.totalStock
                                  ? "text-primary"
                                  : null))
                            }
                          >
                            {p.available ? p.available + " " + "pcs." : "-"}
                          </span>
                        </div>
                      </div>
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={9}>
                <img
                  src={process.env.PUBLIC_URL + "/media/img/no_results.jpg"}
                  style={{
                    display: "block",
                    margin: "0 auto",
                    height: "500px"
                  }}
                  alt="No results"
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}

export default PackagingStockOverview;
