import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { CustomCall, CustomOrder } from "../CustomTypes";
import { ContractCall, OrdersDocument } from "../../../model/orders.types";
import { ARCHIVE, CREATEINVOICE, FULFILLMENT } from "../../../utils/orderUtils";
import dbService, { ORDERS } from "../../../services/dbService";

interface EditContractSettingsModalProps {
  order: CustomOrder;
  calls: Array<{ call: ContractCall; order: OrdersDocument | undefined }>;
}

interface EditContractSettingsModalState {
  show: boolean;
  calls: Array<{ call: CustomCall; order: OrdersDocument | undefined }>;
}

class EditContractSettingsModal extends PureComponent<EditContractSettingsModalProps, EditContractSettingsModalState> {
  constructor(props: EditContractSettingsModalProps) {
    super(props);
    this.state = {
      show: false,
      calls: this.getCalls(props)
    };
  }

  componentDidUpdate(
    prevProps: Readonly<EditContractSettingsModalProps>,
    prevState: Readonly<EditContractSettingsModalState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.calls, this.props.calls)) {
      this.setState({ calls: this.getCalls(this.props) });
    }
  }

  /**
   * Get calls for contract
   * @param props the props
   * @returns {Array<{ call: CustomCall; order: OrdersDocument | undefined }>} list of calls
   */
  getCalls = (props: EditContractSettingsModalProps) => {
    const calls = _.cloneDeep(props.calls);
    return calls.map(c => {
      return {
        call: {
          ...c.call,
          value: c.call.value.toString()
        },
        order: c.order
      };
    });
  };

  handleCallChange = (id: BSON.ObjectId | string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const calls = _.cloneDeep(this.state.calls);
    const call = calls.find(c => c.call._id.toString() === id.toString());
    const key = e.target.name as keyof CustomCall;
    if (key === "value") {
      // @ts-ignore
      call.call[key] = Number(parseInt(e.target.value) || "0").toString();
    } else if (key === "date") {
      // @ts-ignore
      call.call[key] = e.target.valueAsDate;
    }
    this.setState({ calls });
  };

  handleRemoveCall = (id: BSON.ObjectId | string) => () => {
    let calls = [...this.state.calls];
    calls = calls.filter(c => c.call._id.toString() !== id.toString());
    this.setState({ calls });
  };

  handleAddCall = () => {
    const calls = _.cloneDeep(this.state.calls);
    calls.push({ call: { _id: new BSON.ObjectId(), called: null, date: null, value: "0" }, order: undefined });
    this.setState({ calls });
  };

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });

  handleSave = async () => {
    const { order } = this.props;
    const { calls } = this.state;
    const contract = calls.map(c => {
      return {
        ...c.call,
        value: +c.call.value
      };
    });
    // update target date if there is a date in a contract call that is after the contracts target date
    let latestDate = order.targetDate;
    for (let i = 0; i < contract.length; i++) {
      const c = contract[i];
      if (!latestDate || (c.called && c.called > latestDate)) latestDate = c.called;
      if (!latestDate || (c.date && c.date > latestDate)) latestDate = c.date;
      if ((!latestDate && c.finished) || (c.finished && latestDate && c.finished > latestDate)) latestDate = c.finished;
    }
    try {
      const result = await dbService.updateDocument(ORDERS, order._id, { contract, targetDate: latestDate });
      if (result && result.modifiedCount) {
        toast.success("Contract settings updated successfully");
        this.setState({ show: false });
      } else toast.error("Contract settings could not be updated");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    }
  };

  render() {
    const { show, calls } = this.state;
    return (
      <>
        <button className="btn btn-secondary btn-sm btn-bold" onClick={this.handleShow}>
          <i className="fa fa-cog mr-2" />
          Edit Contract Settings
        </button>
        <Modal show={show} onHide={this.handleClose} size={"xl"} centered name={"createCallModal"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand flaticon2-pie-chart mr-2" />
              Contract Settings
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={"overflow-auto"} style={{ maxHeight: "80vh" }}>
            <div className="kt-portlet__body">
              <div className="row">
                <div
                  className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded"
                  style={{ width: "100%" }}
                >
                  <table className="kt-datatable__table d-table">
                    <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
                      <tr className="kt-datatable__row d-table-row">
                        <th className="kt-datatable__cell d-table-cell text-dark">Amount</th>
                        <th className="kt-datatable__cell d-table-cell text-dark">Planned Call</th>
                        <th className="kt-datatable__cell d-table-cell text-dark">Called</th>
                        <th className="kt-datatable__cell d-table-cell text-dark">Finished</th>
                        <th className="kt-datatable__cell d-table-cell text-dark">Real Amount</th>
                        <th className="kt-datatable__cell d-table-cell text-dark text-right">Action</th>
                      </tr>
                    </thead>
                    <tbody className="kt-datatable__body " style={{ display: "table-row-group" }}>
                      {calls &&
                        calls.map(call => {
                          const called = !!call.call.called || !!call.call.finished || !!call.call.output;
                          const output = call.call.output
                            ? call.call.output + " units"
                            : call.order && [FULFILLMENT, CREATEINVOICE, ARCHIVE].includes(call.order.state)
                            ? call.order.calculations[0].units + " units"
                            : "-";
                          return (
                            <tr key={call.call._id.toString()} className="kt-datatable__row d-table-row">
                              <td className="kt-datatable__cell d-table-cell">
                                <div className="input-group">
                                  <input
                                    className="form-control"
                                    type="number"
                                    min={0}
                                    name="value"
                                    disabled={called}
                                    value={call.call.value}
                                    onChange={this.handleCallChange(call.call._id)}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">units</span>
                                  </div>
                                </div>
                              </td>
                              <td className="kt-datatable__cell d-table-cell">
                                <input
                                  name="date"
                                  type="date"
                                  disabled={called}
                                  className="form-control"
                                  min={new Date().toISOString().split("T")[0]}
                                  value={call.call.date?.toISOString().split("T")[0]}
                                  onChange={this.handleCallChange(call.call._id)}
                                />
                              </td>
                              <td className="kt-datatable__cell d-table-cell">
                                <input
                                  name="date"
                                  type="date"
                                  disabled={true}
                                  className="form-control"
                                  value={call.call.called?.toISOString().split("T")[0]}
                                />
                              </td>
                              <td className="kt-datatable__cell d-table-cell">
                                <input
                                  name="date"
                                  type="date"
                                  disabled={true}
                                  className="form-control"
                                  value={call.call.finished?.toISOString().split("T")[0]}
                                />
                              </td>
                              <td className="kt-datatable__cell d-table-cell ">
                                <span
                                  className={"font-weight-bold " + (output !== "-" && "text-success")}
                                  style={{ fontSize: "1.3rem" }}
                                >
                                  {output}
                                </span>
                              </td>
                              <td className="kt-datatable__cell d-table-cell text-right">
                                <button
                                  className={"btn btn-danger " + ((called || calls.length <= 1) && "disabled")}
                                  onClick={this.handleRemoveCall(call.call._id)}
                                  disabled={called}
                                >
                                  <i className="fa fa-trash p-1" />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      <tr className="kt-datatable__row d-table-row border-bottom-0">
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td className="kt-datatable__cell d-table-cell text-right ">
                          <button className="btn btn-success " onClick={this.handleAddCall}>
                            <i className="fa fa-plus p-1" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <button className="btn btn-success" onClick={this.handleSave}>
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default EditContractSettingsModal;
