import _ from "lodash";
import i18n from "../translations/i18n";
import {
  Batch,
  BatchLocation,
  BatchLocationInformation,
  BatchTimelineType,
  PackagingUnit
} from "../model/warehouse/batch.types";
import { OrdersDocument } from "../model/orders.types";
import { ExtendedBatchTimelineEntry } from "../components/order/CustomTypes";
import { LanguageObject } from "../model/common.types";
import { resolveTranslation } from "./translationUtils";
import baseUtils from "./baseUtils";
import { DEFAULTWEIGHTUNIT } from "./warehouseUtils";

/**
 * Checks the type of the timeline entry and returns the corresponding description as string
 * @param t the extended batch timeline entry including the lot
 * @param batchLocations all batch locations to resolve missing information
 * @param orders all orders to resolve the CO name by id
 * @return { text: string; tooltip: JSX.Element | string } an object containing the basic timeline text and a tooltip or an empty string if no tooltip text is defined
 */
export const resolveWarehouseTimelineText = (
  t: ExtendedBatchTimelineEntry,
  batchLocations: Array<BatchLocation>,
  orders: Array<OrdersDocument>
): { text: string; tooltip: JSX.Element | string } => {
  let text: string;
  let tooltip: string | JSX.Element = "";

  switch (t.type) {
    case BatchTimelineType.BATCHCREATED:
      text = i18n.t("warehouse:batchCreated", { lot: t.lot });
      break;
    case BatchTimelineType.BATCHADDITIONALBOOKIN:
      text = i18n.t("warehouse:batchAdditionalBookIn", { lot: t.lot });
      break;
    case BatchTimelineType.BATCHLOCATIONCHANGE:
      tooltip = resolveLocationChangeTooltipText(batchLocations, t.diff.pre.locations, t.diff.post.locations);
      text = i18n.t("warehouse:batchLocationChange", { lot: t.lot });
      break;
    case BatchTimelineType.BATCHDATACHANGED:
      tooltip = resolveDataChangeTooltipText(t.diff.pre, t.diff.post);
      text = i18n.t("warehouse:batchDataChanged", { lot: t.lot });
      break;
    case BatchTimelineType.BATCHBBDCORRECTED:
      tooltip = resolveBBDChangeTooltipText(t.diff.pre, t.diff.post, t.payload.comment);
      text = i18n.t("warehouse:batchBBDCorrected", { lot: t.lot });
      break;
    case BatchTimelineType.BATCHBOOKEDOUT:
      text = i18n.t("warehouse:batchBookedOut", { lot: t.lot });
      break;
    case BatchTimelineType.BATCHCUSTOMERORDERREMOVED:
      const order = t.payload.customerOrder ? baseUtils.getDocFromCollection(orders, t.payload.customerOrder) : "";
      const orderIdentifier = order ? `AT-${order.identifier}` : "";
      text = i18n.t("warehouse:batchCustomerOrderRemoved", { order: orderIdentifier, lot: t.lot });
      break;
    case BatchTimelineType.BATCHFILEUPLOADED:
      text = i18n.t("warehouse:batchFileUploaded", { fileName: t.payload.file?.title, lot: t.lot });
      break;
    case BatchTimelineType.BATCHDAMAGEREPORTED:
      tooltip = resolveDamageChangeTooltipText(t.diff.pre, t.diff.post);
      text = i18n.t("warehouse:batchDamageReported", { lot: t.lot });
      break;
    default:
      text = `${i18n.t("warehouse:unknownAction")} "${t.type}"`;
  }
  return {
    text,
    tooltip
  };
};

/**
 * Resolves the location change(s) based on the pre- and post-objects
 * @param batchLocations all batch locations for trying to resolve information missing in pre or post locations
 * @param pre a partial batch location (before change)
 * @param post a partial batch location (after change)
 * @returns {string | JSX.Element} the location changes as displayable element or an empty string if no changes were found
 */
export const resolveLocationChangeTooltipText = (
  batchLocations: Array<BatchLocation>,
  pre?: Array<Partial<BatchLocation>>,
  post?: Array<Partial<BatchLocation>>
): string | JSX.Element => {
  if (!pre || !post) return "";
  const getLocationString = (location?: BatchLocationInformation): string => {
    if (!location) return "";
    return `${location.warehouseSnapshot.shortName}-${location.warehouseArea.shortName} ${
      location.storageSpace?.storageSpaceNo ?? i18n.t("warehouse:entrance")
    }`;
  };
  const getPUString = (label?: LanguageObject, quantity?: number | null) => {
    const puLabel = label ? resolveTranslation(label) : i18n.t("warehouse:packagingUnitAbbreviation");
    return quantity ? `${quantity} ${puLabel}` : puLabel;
  };
  const preLocationIds = pre.map(bL => bL._id?.toString() ?? "");
  const postLocationIds = post.map(bL => bL._id?.toString() ?? "");
  const onlyInPre = _.difference(preLocationIds, postLocationIds);
  const onlyInPost = _.difference(postLocationIds, preLocationIds);
  const inPreAndPost = _.intersection(preLocationIds, postLocationIds);
  const preLocations = pre.filter(bL => onlyInPre.includes(bL._id?.toString() || ""));
  const postLocations = post.filter(bL => onlyInPost.includes(bL._id?.toString() || ""));
  const commonLocations = pre.filter(bL => inPreAndPost.includes(bL._id?.toString() || ""));

  let fromLocationString = "";
  const pUMovedToStrings: Array<string> = [];

  // TODO AC-681 add ids, location snapshots and pu snapshots to not having to rely on existing batch locations and cover missing edge cases with new information
  if (inPreAndPost.length === 0 && onlyInPre.length === 1 && onlyInPost.length > 0) {
    // Everything moved to new location(s)
    for (const preLocation of preLocations) {
      const locationFrom = preLocation.location;
      fromLocationString = getLocationString(locationFrom);
    }
    for (const postLocation of postLocations) {
      if (!postLocation.packagingUnits) continue;
      const locationTo = postLocation.location;
      const toLocationString = getLocationString(locationTo);
      for (const pU of postLocation.packagingUnits) {
        const movedPU = getPUString(pU.puSnapshot.label, pU.quantity);
        pUMovedToStrings.push(i18n.t("warehouse:toLocation", { object: movedPU, location: toLocationString }));
      }
    }
  } else if (inPreAndPost.length === 1 && onlyInPre.length === 0 && onlyInPost.length > 0) {
    // Parts(s) moved to new location(s)
    for (const commonLocation of commonLocations) {
      const locationFrom = batchLocations.find(b => b._id.toString() === commonLocation._id?.toString())?.location;
      fromLocationString = getLocationString(locationFrom);
    }
    for (const postLocation of postLocations) {
      if (!postLocation.packagingUnits) continue;
      const locationTo = postLocation.location;
      const toLocationString = getLocationString(locationTo);
      for (const pU of postLocation.packagingUnits) {
        const movedPU = getPUString(pU.puSnapshot.label, pU.quantity);
        pUMovedToStrings.push(i18n.t("warehouse:toLocation", { object: movedPU, location: toLocationString }));
      }
    }
  } else if (inPreAndPost.length > 0 && onlyInPre.length === 1 && onlyInPost.length === 0) {
    // Everything moved to existing location(s)
    const preLocation = preLocations[0];
    const locationFrom = preLocation.location;
    fromLocationString = getLocationString(locationFrom);
    for (const commonLocation of commonLocations) {
      const locationTo = batchLocations.find(b => b._id.toString() === commonLocation._id?.toString())?.location;
      const toLocationString = getLocationString(locationTo);
      if (!commonLocation.packagingUnits) continue;
      for (const pU of commonLocation.packagingUnits) {
        const movedPU = getPUString(pU.puSnapshot?.label, pU.quantity);
        pUMovedToStrings.push(i18n.t("warehouse:toLocation", { object: movedPU, location: toLocationString }));
      }
    }
  } else if (inPreAndPost.length > 0 && onlyInPre.length === 0 && onlyInPost.length === 0) {
    // Part(s) moved to existing location(s)
    for (const preLocation of pre) {
      if (!preLocation.packagingUnits || preLocation.packagingUnits.length === 0) continue; // should not happen
      // find matching postLocation
      const postLocation = post.find(bL => bL._id?.toString() === preLocation._id?.toString());
      if (!postLocation || !postLocation.packagingUnits || postLocation.packagingUnits.length === 0) continue; // should not happen
      for (const prePU of preLocation.packagingUnits) {
        // find matching PU
        const postPU = postLocation.packagingUnits.find(pU => pU._id.toString() === prePU._id.toString());
        if (!postPU || !prePU.quantity) continue;
        // If quantity of PU changed to null in post (moved to remote warehouse) or increased, preLocation is from
        if (!fromLocationString && (!postPU.quantity || prePU.quantity > postPU.quantity)) {
          const locationFrom = batchLocations.find(b => b._id.toString() === preLocation._id?.toString())?.location;
          fromLocationString = getLocationString(locationFrom);
        }
      }
    }
  } else if (inPreAndPost.length > 0 && onlyInPre.length > 0 && onlyInPost.length > 0) {
    // Everything moved to new and existing locations
  } else if (inPreAndPost.length > 0 && onlyInPre.length === 0 && onlyInPost.length > 0) {
    // Part(s) moved to new and existing locations
  }

  if (!fromLocationString && pUMovedToStrings.length === 0) return "";
  return (
    <div className="text-left">
      <div className="mb-2">{i18n.t("warehouse:fromLocation", { location: fromLocationString })}</div>
      <div>
        {pUMovedToStrings.map((pUMovedToString, idx) => {
          return <div key={idx}>{pUMovedToString}</div>;
        })}
      </div>
    </div>
  );
};

/**
 * Resolves the BBD change based on the pre- and post-objects
 * @param pre a partial batch containing the expiry (before change)
 * @param post a partial batch containing the expiry (after change)
 * @param comment optional, the comment given when the BBD change was done
 * @returns {string | JSX.Element} the expiry change as displayable element or an empty string if no change was found
 */
export const resolveBBDChangeTooltipText = (
  pre: Partial<Batch>,
  post: Partial<Batch>,
  comment?: string
): string | JSX.Element => {
  if (!pre.expiry || !post.expiry) return "";
  const preDate = baseUtils.formatDate(pre.expiry);
  const postDate = baseUtils.formatDate(post.expiry);
  const uploadedFiles = post.files ? post.files.map(f => f.title).join(", ") : undefined;
  return (
    <div className="text-left">
      <div>{i18n.t("warehouse:batchBBDCorrectedDetail", { preDate, postDate })}</div>
      {comment && (
        <div>
          {i18n.t("warehouse:comments")}: {comment}
        </div>
      )}
      {uploadedFiles && (
        <div>
          {i18n.t("warehouse:filesUploaded")} {uploadedFiles}
        </div>
      )}
    </div>
  );
};

/**
 * Resolves the batch data change(s) based on the pre- and post-objects
 * @param pre a partial batch containing the state before the change(s)
 * @param post a partial batch containing the state after the change(s)
 * @returns {string | JSX.Element} the batch data change(s) as displayable element or an empty string if no change was found
 */
export const resolveDataChangeTooltipText = (pre: Partial<Batch>, post: Partial<Batch>): string | JSX.Element => {
  const uploadedFiles = post.files ? post.files.map(f => f.title).join(", ") : undefined;
  // TODO AC-681 Add unit to amountPerPU and add puSnapshot.label in diff so it always exists and can be displayed
  let prePUType;
  let postPUType;
  let preAmountPerPU;
  let postAmountPerPU;
  // PU can only be changed if batch is in entrance and has only one PU
  if (pre.locations && pre.locations.length > 0 && post.locations && post.locations.length > 0) {
    const preLocation: Partial<BatchLocation> = pre.locations[0];
    const postLocation: Partial<BatchLocation> = post.locations[0];
    if (
      preLocation.packagingUnits &&
      preLocation.packagingUnits.length > 0 &&
      postLocation.packagingUnits &&
      postLocation.packagingUnits.length > 0
    ) {
      const prePU: Partial<PackagingUnit> = preLocation.packagingUnits[0];
      const postPU: Partial<PackagingUnit> = postLocation.packagingUnits[0];
      if (prePU.puSnapshot?.label && postPU.puSnapshot?.label) {
        prePUType = resolveTranslation(prePU.puSnapshot.label);
        postPUType = resolveTranslation(postPU.puSnapshot.label);
      }
      if (prePU.amountPerPu?.value && postPU.amountPerPu?.value) {
        preAmountPerPU = prePU.amountPerPu.value;
        postAmountPerPU = postPU.amountPerPu.value;
      }
    }
  }
  return (
    <div className="text-left">
      {pre.lot && post.lot && (
        <div>{i18n.t("warehouse:batchDataChangedLOT", { preLOT: pre.lot, postLOT: post.lot })}</div>
      )}
      {uploadedFiles && (
        <div>
          {i18n.t("warehouse:filesUploaded")} {uploadedFiles}
        </div>
      )}
      {pre.stocked && post.stocked && (
        <div>
          {i18n.t("warehouse:batchDataChangedStocked", {
            preDate: baseUtils.formatDate(pre.stocked),
            postDate: baseUtils.formatDate(post.stocked)
          })}
        </div>
      )}
      {prePUType && postPUType && (
        <div>
          {i18n.t("warehouse:batchDataChangedPU", {
            prePU: prePUType,
            postPU: postPUType
          })}
        </div>
      )}
      {preAmountPerPU && postAmountPerPU && (
        <div>
          {i18n.t("warehouse:batchDataChangedAmountPerPU", {
            preAmount: preAmountPerPU,
            preAmountUnit: DEFAULTWEIGHTUNIT,
            postAmount: postAmountPerPU,
            postAmountUnit: DEFAULTWEIGHTUNIT
          })}
        </div>
      )}
    </div>
  );
};

/**
 * Resolves the damage change based on the pre- and post-objects
 * @param pre a partial batch containing the blocking information (before change)
 * @param post a partial batch containing the blocking information (after change)
 * @returns {string | JSX.Element} the blocking information change as displayable element or an empty string if no change was found
 */
export const resolveDamageChangeTooltipText = (pre: Partial<Batch>, post: Partial<Batch>): string | JSX.Element => {
  // TODO AC-659 Adjust tooltip if only PUs are blocked / unblocked
  if (!post.blocked) return "";
  const uploadedFiles = post.blocked.files ? post.blocked.files.map(f => f.title).join(", ") : undefined;
  return (
    <div className="text-left">
      <div>{i18n.t("warehouse:batchDamageReportedDetails", { damage: post.blocked.reason })}</div>
      {uploadedFiles && (
        <div>
          {i18n.t("warehouse:filesUploaded")} {uploadedFiles}
        </div>
      )}
    </div>
  );
};
