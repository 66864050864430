import _ from "lodash";
import { UserdataDocument } from "../model/userdata.types";
import baseUtils from "./baseUtils";
import { CompaniesDocument } from "../model/companies.types";

export interface PersonData {
  title: string;
  prename: string;
  surname: string;
  email: string;
  position: string;
  phone: string;
}

export const ROLES = {
  SUPPLIER: "Supplier",
  CUSTOMER: "Customer",
  ADMIN: "Admin",
  PROCUREMENT: "Procurement",
  PACKAGINGPROCUREMENT: "Packaging Procurement",
  SALES: "Sales",
  PRODUCTION: "Production",
  FINANCE: "Finance",
  DATAMAINTENANCE: "Datamaintenance",
  CONTROLLING: "Controlling",
  INVESTOR: "Investor",
  WAREHOUSE: "Warehouse",
  GELSENKIRCHEN: "Gelsenkirchen",
  BREMEN: "Bremen",
  LEITMERITZ: "Leitmeritz",
  PIRMASENS: "Pirmasens",
  ULM: "Ulm",
  SYSTEM: "System",
  API: "API",
  EXTERNALMANUFACTURER: "External Manufacturer",
  MIXMASTERS: "MixMasters",
  DELIVERYCALENDERONLY: "DeliveryCalenderOnly"
};

/**
 * Filters the given userdata list by the provided query. Prename, surname and position are checked for matches.
 * @param query: Query that is used to filter the userdata
 * @param companies: List of companies used for creating the array
 * @param userdata: List that should be filtered
 * @returns { Array<UserdataDocument> } Filtered userdata document list
 */
function filterUserdata(query: string, companies: Array<CompaniesDocument>, userdata: Array<UserdataDocument>) {
  const q = query.trim();
  if (q !== "") {
    let userdataFuse: Array<{
      userdata: UserdataDocument;
      company: CompaniesDocument | null;
      personName: string | null;
    }> = userdata.map(u => {
      return {
        company: u.company_id ? baseUtils.getDocFromCollection(companies, u.company_id) : null,
        userdata: u,
        personName: u.prename + " " + u.surname
      };
    });
    return baseUtils
      .doFuseSearch(userdataFuse, q, ["personName", "userdata.role", "userdata.position", "company.name"])
      .map(u => u.userdata);
  }
  return userdata;
}

/**
 * Get default user data
 * @param userData data about the person that is merged with default data
 * @param role optional role
 * @returns {Userdata} default user data
 */
function getDefaultUserdata(userData: PersonData, role?: string) {
  const { email, phone } = userData;
  let user = {
    email: [email],
    telephone: [phone],
    img_url: "",
    role: role ? [role] : [],
    password_valid: false,
    notifications: {
      email: [],
      contactMail: email,
      contactPhone: phone,
      sms: [],
      language: "en",
      whatsapp: []
    },
    user_id: "",
    company_id: ""
  };
  return _.merge({ ...user }, _.omit(userData, ["phone", "email"]));
}

export default { filterUserdata, getDefaultUserdata };
