import _ from "lodash";
import React, { PureComponent } from "react";
import MaterialTimeline, { MaterialTimelineContent } from "../../common/MaterialTimeline";
import { DataContext } from "../../../context/dataContext";
import { PackagingsDocument, PackagingTimeline as Timeline } from "../../../model/packagings.types";
import baseUtils from "../../../utils/baseUtils";
import packagingUtils, { ROOTKEYS } from "../../../utils/packagingUtils";
import materialUtils from "../../../utils/materialUtils";

interface PackagingTimelineProps {
  packaging: PackagingsDocument;
  context: React.ContextType<typeof DataContext>;
}

interface PackagingTimelineState {}

class PackagingTimeline extends PureComponent<PackagingTimelineProps, PackagingTimelineState> {
  /**
   * Resolves the description of changes to a packaging.
   * @param t: Timeline entry of a packaging
   * @returns { Array<{ key: string, pre?: Array<string>, post? Array<string> }> } Contains all changed keys with their
   *  pre and post values
   */
  resolveChangeDescription = (t: Timeline) => {
    let changes: Array<{ key: string; pre?: Array<string>; post?: Array<string> }> = [];
    const entriesPre = Object.entries(t.pre);
    const entriesPost = Object.entries(t.post);
    entriesPre.map(([key, value]) => {
      try {
        changes = changes.concat(this.processChanges(key, value, t));
      } catch (e) {
        console.error("ERROR parsing timeline:", e);
      }
    });
    entriesPost.map(([key, value]) => {
      try {
        if (!(key in t.pre)) {
          const changesPost = this.processChanges(key, value, t);
          changesPost.forEach(c => changes.push({ key: c.key, pre: c.pre ? undefined : c.post, post: c.pre }));
        }
      } catch (e) {
        console.error("ERROR parsing timeline:", e);
      }
    });
    return changes;
  };

  /**
   * Resolves a change by matching the key with the correct field of a packaging.
   * @param key: Key that was changed
   * @param value: Values that was set
   * @param t: Timeline entry of a packaging
   * @returns { Array<{ key: string, pre?: Array<string>, post? Array<string> }> } Contains all changed keys with their
   *  pre and post values
   */
  processChanges = (key: string, value: any, t: Timeline) => {
    const { context } = this.props;
    const changes: Array<{ key: string; pre?: Array<string>; post?: Array<string> }> = [];
    // @ts-ignore
    if (ROOTKEYS.includes(key)) {
      switch (key) {
        case "packaging_shape":
        case "lid_shape":
        case "bag_shape":
          const shapePre = packagingUtils.getPackagingDescription(value);
          const shapePost = packagingUtils.getPackagingDescription(t.post.packaging_shape!);
          changes.push({ key: "Shape", pre: [shapePre], post: [shapePost] });
          break;
        case "packaging_material":
        case "lid_material":
        case "bag_material":
          const matType = t.post.packaging_material
            ? t.post.packaging_material
            : t.post.lid_material
            ? t.post.lid_material
            : t.post.bag_material!;
          const matPre = packagingUtils.getPackagingDescription(value);
          const matPost = packagingUtils.getPackagingDescription(matType);
          changes.push({ key: "Material", pre: [matPre], post: [matPost] });
          break;
        case "packaging_color":
        case "lid_color":
        case "bag_color":
          const lidColPre = packagingUtils.getPackagingDescription(value);
          const lidColPost = packagingUtils.getPackagingDescription(t.post[key]!);
          changes.push({ key: "Color", pre: [lidColPre], post: [lidColPost] });
          break;
        case "packaging_volume":
        case "bag_volume":
          changes.push({ key: "Volume", pre: [value + " ml"], post: [t.post[key]! + " ml"] });
          break;
        case "packaging_height":
        case "label_height":
        case "bag_height":
        case "blister_height":
        case "box_height":
          changes.push({ key: "Height", pre: [value + " mm"], post: [t.post[key]! + " mm"] });
          break;
        case "packaging_width":
        case "label_width":
        case "bag_width":
        case "blister_width":
        case "box_width":
          changes.push({ key: "Width", pre: [value + " mm"], post: [t.post[key]! + " mm"] });
          break;
        case "packaging_label_height":
          changes.push({ key: "Label height", pre: [value + " mm"], post: [t.post.packaging_label_height! + " mm"] });
          break;
        case "packaging_neck":
          changes.push({ key: "Neck", pre: [value], post: [t.post.packaging_neck!] });
          break;
        case "label_quality":
        case "box_quality":
          const qualityType = t.post.label_quality ? t.post.label_quality : t.post.box_quality!;
          const labQuaPre = packagingUtils.getPackagingDescription(value);
          const labQuaPost = packagingUtils.getPackagingDescription(qualityType);
          changes.push({ key: "Quality", pre: [labQuaPre], post: [labQuaPost] });
          break;
        case "lid_size":
        case "sleeve_size":
          const sizeType = t.post.lid_size ? t.post.lid_size : t.post.sleeve_size!;
          changes.push({ key: "Size", pre: [value], post: [sizeType] });
          break;
        case "bag_zipper":
          const bagZipPre = packagingUtils.getPackagingDescription(value);
          const bagZipPost = packagingUtils.getPackagingDescription(t.post.bag_zipper!);
          changes.push({ key: "Zipper", pre: [bagZipPre], post: [bagZipPost] });
          break;
        case "blister_capsules":
          changes.push({ key: "Capsules", pre: [value], post: [t.post.blister_capsules!] });
          break;
        case "blister_depth":
          changes.push({ key: "Depth", pre: [value + " mm"], post: [t.post.blister_depth! + " mm"] });
          break;
        case "box_depth":
          changes.push({ key: "Depth", pre: [value + " mm"], post: [t.post.box_depth! + " mm"] });
          break;
        case "sleeve_print":
          changes.push({ key: "Print", pre: [value ? "Yes" : "No"], post: [t.post.sleeve_print ? "Yes" : "No"] });
          break;
        case "note":
          changes.push({
            key: "Note",
            pre: value ? [value] : undefined,
            post: t.post.note ? [t.post.note] : undefined
          });
          break;
        case "article_number":
          changes.push({
            key: "Article number",
            pre: value ? [value] : undefined,
            post: t.post.article_number ? [t.post.article_number] : undefined
          });
          break;
      }
    } else if (key === "prices") {
      const contentPre: Array<string> = [];
      const contentPost: Array<string> = [];
      const ignoredKeys = ["supplier", "id"];
      value.forEach((v: any) => {
        const supplier = baseUtils.getDocFromCollection(context.suppliers, v.supplier).name;
        const keysPre = Object.entries(v);
        const keysPost = t.post.prices
          ? Object.entries(t.post.prices.find(price => price.id.toString() === v.id.toString())!)
          : [];
        keysPre.length > 0 && contentPre.push("Supplier: " + supplier);
        keysPre.forEach(
          ([keyPre, valuePre]) =>
            !ignoredKeys.includes(keyPre) &&
            contentPre.push(_.upperFirst(keyPre) + ": " + valuePre + (keyPre === "price" ? "€" : ""))
        );
        keysPost.length > 0 && contentPost.push("Supplier: " + supplier);
        keysPost.forEach(
          ([keyPost, valuePost]) =>
            !ignoredKeys.includes(keyPost) &&
            contentPost.push(_.upperFirst(keyPost) + ": " + valuePost + (keyPost === "price" ? "€" : ""))
        );
      });
      changes.push({
        key: "Price",
        pre: contentPre.length > 0 ? contentPre : undefined,
        post: contentPost.length > 0 ? contentPost : undefined
      });
    }
    return changes;
  };

  /**
   * Generates a list of all changes that are noted inside the timeline of the packaging.
   * @returns { Array<MaterialTimelineContent> } Changelist
   */
  generateChangeList = () => {
    const { packaging, context } = this.props;

    const changeList: Array<MaterialTimelineContent> = [];
    if (!packaging.timeline || packaging.timeline.length === 0) return changeList;
    const timeline = packaging.timeline.slice().reverse();
    for (let i = 0; i < timeline.length; i++) {
      const t = timeline[i];
      const person = baseUtils.getDocFromCollection(context.userdata, t.person);
      const changes = this.resolveChangeDescription(t);

      if (changes.length === 0 || !person) continue;
      for (let j = 0; j < changes.length; j++) {
        const c = changes[j];
        let icon = "flaticon2-edit";
        let changeDescription = " changed";
        if (c.pre && !c.post) {
          icon = "flaticon2-rubbish-bin-delete-button text-danger";
          changeDescription = " deleted";
        } else if (!c.pre && c.post) {
          icon = "flaticon2-plus text-success";
          changeDescription = " added";
        }
        let additionalInformation;
        if (c.key === "Price") {
          additionalInformation = materialUtils.resolvePriceChanges(c.pre, c.post);
        }
        changeList.push({
          image: person.img_url,
          icon,
          date: t.date,
          user: person.prename + " " + person.surname,
          change: c.key + changeDescription,
          additionalInformation,
          details: c
        });
      }
    }
    return changeList;
  };

  render() {
    return (
      <div className="kt-portlet__body">
        <MaterialTimeline content={this.generateChangeList()} />
      </div>
    );
  }
}

export default PackagingTimeline;
