import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import { withRouter } from "react-router-dom";
import { LayoutContextConsumer } from "../LayoutContext";
import * as builder from "../../ducks/builder";

class SubHeader extends React.Component {
  render() {
    const { subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle } = this.props;
    return (
      <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
        <div className={`kt-container ${subheaderContainerCssClasses}`}>
          <div className="kt-subheader__main">
            {subheaderMobileToggle && (
              <button
                className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                id="kt_subheader_mobile_toggle"
              >
                <span />
              </button>
            )}

            <LayoutContextConsumer>
              {({ subheader: { title } }) => (
                <h3 className="kt-subheader__title kt-font-bolder">{title}&nbsp;&nbsp;</h3>
              )}
            </LayoutContextConsumer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(store.builder.layoutConfig, "subheader.mobile-toggle"),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: "subheader",
    toString: true
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: "subheader_container",
    toString: true
  })
});

export default withRouter(connect(mapStateToProps)(SubHeader));
