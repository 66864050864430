import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { CustomOrder } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import dbService, { ORDERS, UpdateAction } from "../../../services/dbService";
import orderUtils from "../../../utils/orderUtils";
import toastUtils from "../../../utils/toastUtils";
import { OrdersDocument } from "../../../model/orders.types";
import { CompaniesDocument } from "../../../model/companies.types";
import userService from "../../../services/userService";
import { T_TARGETDATEUPDATED } from "../../../utils/timelineUtils";
import { CalendarWeekBadge } from "../../listings/common/BaseListingComponents";
import dateUtils from "../../../utils/dateUtils";
import baseUtils from "../../../utils/baseUtils";
import DelayReasons from "../../common/DelayReasons";
import slackService from "../../../services/slackService";
import { ManufacturersDocument } from "../../../model/manufacturers.types";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";

interface TargetDateTimelineEntry {
  _id: BSON.ObjectId;
  type: string;
  date: Date;
  person: BSON.ObjectId;
  oldDate: Date | null;
  newDate: Date;
  note: string;
  notification?: string;
}

interface EditTargetDateModalProps {
  order: CustomOrder | OrdersDocument;
  targetWeekBadge?: boolean;
  contract?: OrdersDocument;
}

interface EditTargetDateModalState {
  targetDate: Date | null;
  show: boolean;
  showHistory: boolean;
  note: string;
  reason: { value: string; label: string } | "";
  saving: boolean;
}

class EditTargetDateModal extends PureComponent<EditTargetDateModalProps, EditTargetDateModalState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: EditTargetDateModalProps) {
    super(props);
    this.state = {
      show: false,
      targetDate: props.order.targetDate,
      showHistory: false,
      note: "",
      reason: "",
      saving: false
    };
  }

  componentDidUpdate(prevProps: Readonly<EditTargetDateModalProps>) {
    if (!_.isEqual(prevProps.order, this.props.order)) {
      this.setState({ targetDate: this.props.order.targetDate });
    }
  }

  handleShow = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    this.setState({ show: true });
  };
  handleClose = () =>
    this.setState({
      show: false,
      targetDate: this.props.order.targetDate,
      note: "",
      showHistory: false,
      reason: "",
      saving: false
    });
  handleNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({ note: e.target.value });
  handleReason = (reason: { value: string; label: string } | "") => this.setState({ reason });
  handleToggleHistory = () => this.setState({ showHistory: !this.state.showHistory });

  handleChangeTargetDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ targetDate: e.target.valueAsDate });
  };

  handleBlurTargetDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { order } = this.props;
    const min = userService.isAdmin() ? new Date(0) : orderUtils.calculateEarliestDeliveryDate(order);
    const date = e.target.valueAsDate;
    this.setState({ targetDate: date && date >= min ? date : min });
  };

  handleSaveTargetDate = async () => {
    const { order, contract } = this.props;
    const { targetDate, note, reason } = this.state;
    const { companies, updateDocumentInContext } = this.context;
    if (!targetDate) {
      toast.error("No target date set");
      return;
    }
    this.setState({ saving: true });
    try {
      // Update order/call target date
      const orderTimelineEntry: TargetDateTimelineEntry = {
        _id: new BSON.ObjectId(),
        type: T_TARGETDATEUPDATED,
        date: new Date(),
        person: userService.getUserId(),
        oldDate: order.targetDate,
        newDate: targetDate,
        note: note.trim()
      };
      if (reason) orderTimelineEntry.notification = reason.label;
      const updateOrderTargetDateAction: UpdateAction = {
        collection: ORDERS,
        filter: { _id: order._id },
        update: { targetDate: targetDate! },
        push: { timeline: orderTimelineEntry }
      };
      const actions: Array<UpdateAction> = [updateOrderTargetDateAction];
      let contractTimelineEntry: TargetDateTimelineEntry | undefined;

      // Update contract target date as well if new target date of call is behind contract target date
      if (contract && contract.targetDate && targetDate && contract.targetDate < targetDate) {
        contractTimelineEntry = {
          _id: new BSON.ObjectId(),
          type: T_TARGETDATEUPDATED,
          date: new Date(),
          person: userService.getUserId(),
          oldDate: contract.targetDate,
          newDate: targetDate,
          note: note.trim()
        };
        if (reason) contractTimelineEntry.notification = reason.label;
        const updateContractTargetDateAction: UpdateAction = {
          collection: ORDERS,
          filter: { _id: contract._id },
          update: { targetDate: targetDate },
          push: { timeline: contractTimelineEntry }
        };
        actions.push(updateContractTargetDateAction);
      }

      const result = await dbService.transaction(actions);
      await toastUtils.databaseOperationToast(
        result,
        "Target date successfully set",
        "Target date could not be set",
        async () => {
          updateDocumentInContext(ORDERS, order._id);
          if (contract && contract.targetDate && targetDate && contract.targetDate < targetDate)
            updateDocumentInContext(ORDERS, contract._id);
          this.sendNotificationMessages(order, companies, reason, orderTimelineEntry, contract, contractTimelineEntry);
          this.handleClose();
        }
      );
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ saving: false });
    }
  };

  /**
   * Helper function for sending information about the target date change via slack and mail
   * @param order the order for which the target date was changed
   * @param companies all companies for resolving the customer the order was created for
   * @param reason the reason for the target date change
   * @param orderTimelineEntry the timeline entry containing information about the changed date
   * @param contract optional, the related contract if the order is a call of it
   * @param contractTimelineEntry optional, the timeline entry containing information about the changed date in the contract
   */
  sendNotificationMessages = (
    order: CustomOrder | OrdersDocument,
    companies: Array<CompaniesDocument>,
    reason: { value: string; label: string } | "",
    orderTimelineEntry: TargetDateTimelineEntry,
    contract?: OrdersDocument,
    contractTimelineEntry?: TargetDateTimelineEntry
  ) => {
    const user = userService.getUserData();
    const customer =
      "_id" in order.createdFor
        ? order.createdFor
        : (baseUtils.getDocFromCollection(companies, order.createdFor as BSON.ObjectId) as CompaniesDocument);

    const getLinkText = (document: CustomOrder | OrdersDocument, forSlack?: boolean): string => {
      const destination = process.env.REACT_APP_BASE_URL;
      const documentDescription = orderUtils.resolveOrderDescription(document);
      return forSlack
        ? `Target date of ${documentDescription[0]} <${destination}/order/${document._id.toString()}|${
            documentDescription[1]
          }-${document.identifier}>`
        : `Target date of ${documentDescription[0]} <a href="${destination}/order/${document._id.toString()}">${
            documentDescription[1]
          }-${document.identifier}</a>`;
    };
    const getDateChangeText = (timelineEntry: TargetDateTimelineEntry): string => {
      return `Before: ${
        timelineEntry.oldDate
          ? `${baseUtils.formatDate(timelineEntry.oldDate)} (CW ${dateUtils.getCW(timelineEntry.oldDate)})`
          : "-"
      } Now: ${baseUtils.formatDate(timelineEntry.newDate)} (CW ${dateUtils.getCW(timelineEntry.newDate)}).`;
    };

    // Message for order
    const slackOrderLink = getLinkText(order, true);
    const mailOrderLink = getLinkText(order, false);
    const mailOrderTarget = `for ${customer?.name || "Unknown Customer"}`;
    const slackOrderTarget = `for _${customer?.name || "Unknown Customer"}_`;
    let orderMessage = `was updated. ${getDateChangeText(orderTimelineEntry)} Changed By: ${user.prename} ${
      user.surname
    }.`;
    if (reason) orderMessage += ` Reason: ${reason.label}.`;

    // Message if related contract exists and its date was updated too
    let contractSlackMessage = "";
    let contractMailMessage = "";
    if (contract && contractTimelineEntry) {
      const slackContractLink = getLinkText(contract, true);
      const mailContractLink = getLinkText(contract, false);
      const contractMessage = `was also updated. ${getDateChangeText(contractTimelineEntry)}`;
      contractSlackMessage = `\n${slackContractLink} ${contractMessage}`;
      contractMailMessage = `<br>${mailContractLink} ${contractMessage}`;
    }

    const slackMessage = `${slackOrderLink} ${slackOrderTarget} ${orderMessage} ${contractSlackMessage}`;
    const target = "_id" in order.createdFrom ? order.createdFrom._id : order.createdFrom;
    // dev case handled by backend-function
    slackService.sendMessage(target, slackMessage);

    // Check if an email notification should be sent to a specific address
    const manufacturer: ManufacturersDocument =
      "name" in order.settings.manufacturer
        ? order.settings.manufacturer
        : baseUtils.getDocFromCollection(this.context.manufacturers, order.settings.manufacturer);
    if (manufacturer.notificationSettings?.targetWeekChanges) {
      const { mailAddress, isEnabled } = manufacturer.notificationSettings.targetWeekChanges;
      if (isEnabled) {
        const mailSubject = "Change CW: " + (orderUtils.isOrderState(order.state) ? "AT-" : "AN-") + order.identifier;
        const mailText = `${mailOrderLink} ${mailOrderTarget} ${orderMessage} ${contractMailMessage}`;
        // dev case is handled by backend function
        dbService.callFunction("sendEmail", [mailSubject, mailText, "", mailAddress]);
      }
    }
  };

  render() {
    const { order, targetWeekBadge } = this.props;
    const { show, targetDate, showHistory, note, reason, saving } = this.state;
    const history = order.timeline.filter(t => t.type === T_TARGETDATEUPDATED).reverse();
    const isEqual = _.isEqual(targetDate, order.targetDate);

    return (
      <>
        {targetWeekBadge ? (
          <CalendarWeekBadge order={order} onClick={this.handleShow} />
        ) : (
          <i className="fa fa-cog pointer ml-2" onClick={this.handleShow} />
        )}
        {show && (
          <Modal show={show} onHide={this.handleClose} centered name={"editTargetDateModal"}>
            <Modal.Header closeButton>
              <Modal.Title>
                <i className="kt-font-brand fa fa-calendar mr-2" />
                Edit Target Date
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="kt-portlet__body">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="kt-section kt-section--first mb-3">
                      <div className="kt-section__body">
                        <br />
                        <div className="input-group mx-auto" style={{ maxWidth: "300px" }}>
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              {targetDate ? "CW " + dateUtils.getCW(targetDate) : "-"}
                            </span>
                          </div>
                          <input
                            type="date"
                            className="form-control "
                            min={
                              userService.isAdmin()
                                ? undefined
                                : orderUtils.calculateEarliestDeliveryDate(order).toISOString().split("T")[0]
                            }
                            onChange={this.handleChangeTargetDate}
                            onBlur={this.handleBlurTargetDate}
                            value={targetDate ? targetDate.toISOString().split("T")[0] : ""}
                          />
                        </div>
                        <br />
                        <textarea
                          className="form-control mx-auto "
                          name="note"
                          rows={2}
                          value={note}
                          onChange={this.handleNote}
                          placeholder={"Note, e.g. a reason"}
                          style={{ maxWidth: "300px" }}
                        />
                        <br />
                        <div className="mx-auto" style={{ maxWidth: "300px" }}>
                          <DelayReasons reason={reason} onChange={this.handleReason} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {history.length > 0 && (
                  <>
                    <span className="pointer kt-font-hover-dark" onClick={this.handleToggleHistory}>
                      {showHistory ? "Hide history" : "Show history"}
                    </span>
                    {showHistory && (
                      <div
                        className="kt-notes kt-scroll kt-scroll--pull ps ps--active-y pt-2 overflow-auto"
                        style={{ maxHeight: "400px" }}
                      >
                        <div className="kt-notes__items">
                          {history.map(timelineEntry => {
                            const user = baseUtils.getDocFromCollection(this.context.userdata, timelineEntry.person);
                            const userName = user ? user.prename + " " + user.surname : "-";
                            return (
                              <div
                                className="kt-notes__item pb-2"
                                key={
                                  timelineEntry._id?.toString() ||
                                  timelineEntry.id?.toString() ||
                                  timelineEntry.type + timelineEntry.date
                                }
                              >
                                <div className="kt-notes__media">
                                  <img
                                    src={
                                      user && user.img_url
                                        ? user.img_url
                                        : "https://private-label-factory.de/img/mail/placeholder_female.jpg"
                                    }
                                    alt="image"
                                  />
                                </div>
                                <div className="kt-notes__content px-2 pb-0">
                                  <div className="kt-notes__section">
                                    <div className="kt-notes__info">
                                      <span className="kt-notes__title">{userName}</span>
                                      <span className="kt-notes__desc">{baseUtils.formatDate(timelineEntry.date)}</span>
                                    </div>
                                  </div>
                                  <span className="kt-notes__body p-0">
                                    <div className="kt-widget4">
                                      <div className="kt-widget4__item">
                                        <div className="kt-widget4__pic kt-widget4__pic--icon">
                                          <i
                                            className="flaticon2-calendar-2"
                                            style={{ fontSize: "24px", color: "#bfc5d4" }}
                                          />
                                        </div>
                                        <div className="kt-widget4__info">
                                          <span className="kt-widget4__username">
                                            {`${
                                              timelineEntry.oldDate
                                                ? `${baseUtils.formatDate(timelineEntry.oldDate)} (CW ${dateUtils.getCW(
                                                    timelineEntry.oldDate
                                                  )})`
                                                : "Not set"
                                            }`}{" "}
                                            <i className="la la-arrow-right" />{" "}
                                            {`${baseUtils.formatDate(timelineEntry.newDate)} (CW ${dateUtils.getCW(
                                              timelineEntry.newDate
                                            )})`}
                                            {timelineEntry.note && <p>Note: {timelineEntry.note}</p>}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary" onClick={this.handleClose}>
                Close
              </button>
              <ErrorOverlayButton
                buttonText={<b>Set Target Date</b>}
                className="btn btn-success"
                onClick={this.handleSaveTargetDate}
                saving={saving}
                disabled={!targetDate || isEqual}
              />
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

export default EditTargetDateModal;
