import React, { PureComponent } from "react";
import { EmojiProvider, Emoji } from "react-apple-emojis";
import emojiData from "react-apple-emojis/src/data.json";
import { OrdersDocument } from "../../../model/orders.types";
import { Period } from "../../common/CustomTypes";
import dashboardUtils from "../../../utils/dashboardUtils";
import baseUtils from "../../../utils/baseUtils";
import { GeneralDocument } from "../../../model/general.types";

interface OverviewStatsProps {
  orders: Array<OrdersDocument>;
  general: Array<GeneralDocument>;
  periodString: string;
  period: Period;
}

interface OverviewStatsState {}

class OverviewStats extends PureComponent<OverviewStatsProps, OverviewStatsState> {
  /**
   * Get color for the given value
   * @param value the value in percent
   * @returns {string} color string matching the percentage
   */
  getColor = (value: number) => {
    if (value < 25) {
      return "danger";
    } else if (value >= 25 && value < 75) {
      return "warning";
    } else if (value >= 75) {
      return "success";
    } else {
      return "primary";
    }
  };

  render() {
    const { orders, general, period, periodString } = this.props;
    const stats = dashboardUtils.getSalesStats(orders, undefined, period);
    let periodDescription = "This year";
    if (periodString === "thismonth") periodDescription = "This month";
    if (periodString === "lastmonth") periodDescription = "This year";
    if (periodString === "lastyear") periodDescription = "Last year";

    const totalTurnoverGoal = baseUtils.getValueFromGeneral(general, "totalturnover");
    const totalMarginGoal = baseUtils.getValueFromGeneral(general, "totalmargin");
    const marginPercentageGoal = baseUtils.getValueFromGeneral(general, "marginpercentage");
    const deliveryTimeGoal = baseUtils.getValueFromGeneral(general, "deliverytime");

    const totalTurnoverReached =
      stats && stats.WrittenSales && totalTurnoverGoal ? (stats.WrittenSales / +totalTurnoverGoal) * 100 : 0;
    const totalMarginReached =
      stats && stats.TotalMargin && totalMarginGoal ? (stats.TotalMargin / +totalMarginGoal) * 100 : 0;
    const marginPercentageReached =
      stats && stats.marginpercent && marginPercentageGoal ? (stats.marginpercent / +marginPercentageGoal) * 100 : 0;
    const deliveryTimeReached =
      stats && stats.totalTime && deliveryTimeGoal ? (+deliveryTimeGoal / stats.totalTime) * 100 : 0;
    return (
      <div className="kt-portlet">
        <div className="kt-portlet__body  kt-portlet__body--fit">
          <div className="row row-no-padding row-col-separator-lg">
            <div className="col-md-12 col-lg-6 col-xl-3">
              <div className="kt-widget24">
                <div className="kt-widget24__details">
                  <div className="kt-widget24__info">
                    <h4 className="kt-widget24__title">Total Turnover</h4>
                    <span className="kt-widget24__desc">{periodDescription}</span>
                  </div>
                  <span className={"kt-widget24__stats kt-font-" + this.getColor(totalTurnoverReached)}>
                    {stats && stats.WrittenSales ? baseUtils.formatEuro(stats.WrittenSales) : baseUtils.formatEuro(0)}
                    {stats && totalTurnoverGoal && stats.WrittenSales > +totalTurnoverGoal && (
                      <EmojiProvider data={emojiData}>
                        <Emoji className="ml-2" name="party-popper" width="30" />
                      </EmojiProvider>
                    )}
                  </span>
                </div>
                <div className="progress progress--sm">
                  <div
                    className={"progress-bar kt-bg-" + this.getColor(totalTurnoverReached)}
                    role="progressbar"
                    style={{ width: totalTurnoverReached + "%" }}
                  />
                </div>
                <div className="kt-widget24__action">
                  <span className="kt-widget24__change">Goal</span>
                  <span className="kt-widget24__number">
                    {totalTurnoverGoal ? baseUtils.formatEuro(+totalTurnoverGoal) : "loading..."}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 col-xl-3">
              <div className="kt-widget24">
                <div className="kt-widget24__details">
                  <div className="kt-widget24__info">
                    <h4 className="kt-widget24__title">Margin</h4>
                    <span className="kt-widget24__desc">{periodDescription}</span>
                  </div>
                  <span className={"kt-widget24__stats kt-font-" + this.getColor(totalMarginReached)}>
                    {stats && stats.TotalMargin ? baseUtils.formatEuro(stats.TotalMargin) : baseUtils.formatEuro(0)}
                    {stats && totalMarginGoal && stats.TotalMargin > +totalMarginGoal && (
                      <EmojiProvider data={emojiData}>
                        <Emoji className="ml-2" name="party-popper" width="30" />
                      </EmojiProvider>
                    )}
                  </span>
                </div>
                <div className="progress progress--sm">
                  <div
                    className={"progress-bar kt-bg-" + this.getColor(totalMarginReached)}
                    role="progressbar"
                    style={{ width: totalMarginReached + "%" }}
                  />
                </div>
                <div className="kt-widget24__action">
                  <span className="kt-widget24__change">Goal</span>
                  <span className="kt-widget24__number">
                    {totalMarginGoal ? baseUtils.formatEuro(+totalMarginGoal) : "loading..."}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 col-xl-3">
              <div className="kt-widget24">
                <div className="kt-widget24__details">
                  <div className="kt-widget24__info">
                    <h4 className="kt-widget24__title">Margin Percentage</h4>
                    <span className="kt-widget24__desc">{periodDescription}</span>
                  </div>
                  <span className={"kt-widget24__stats kt-font-" + this.getColor(marginPercentageReached)}>
                    {stats && stats.marginpercent ? stats.marginpercent.toFixed(2) + "%" : "0%"}
                  </span>
                </div>
                <div className="progress progress--sm">
                  <div
                    className={"progress-bar kt-bg-" + this.getColor(marginPercentageReached)}
                    role="progressbar"
                    style={{ width: marginPercentageReached + "%" }}
                  />
                </div>
                <div className="kt-widget24__action">
                  <span className="kt-widget24__change">Goal</span>
                  <span className="kt-widget24__number">
                    {marginPercentageGoal ? (+marginPercentageGoal).toFixed(2) + "%" : "loading..."}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 col-xl-3">
              <div className="kt-widget24">
                <div className="kt-widget24__details">
                  <div className="kt-widget24__info">
                    <h4 className="kt-widget24__title">Production Time</h4>
                    <span className="kt-widget24__desc">{periodDescription}</span>
                  </div>
                  <span className={"kt-widget24__stats kt-font-" + this.getColor(deliveryTimeReached)}>
                    {stats && stats.totalTime ? stats.totalTime.toFixed(2) + " Days" : "0 Days"}
                  </span>
                </div>
                <div className="progress progress--sm">
                  <div
                    className={"progress-bar kt-bg-" + this.getColor(deliveryTimeReached)}
                    role="progressbar"
                    style={{ width: deliveryTimeReached + "%" }}
                  />
                </div>
                <div className="kt-widget24__action">
                  <span className="kt-widget24__change">Goal</span>
                  <span className="kt-widget24__number">
                    {deliveryTimeGoal ? (+deliveryTimeGoal).toFixed(0) + " Days" : "loading..."}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OverviewStats;
