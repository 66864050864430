import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { DataContext } from "../../context/dataContext";
import orderUtils, { OFFER, REQUESTAPPROVED, REQUESTPENDING } from "../../utils/orderUtils";
import { paginate } from "../common/Pagination";
import { PaginationState } from "../common/CustomTypes";
import { OrdersDocument } from "../../model/orders.types";
import baseUtils, { getComponentState } from "../../utils/baseUtils";
import PersonWidget from "../common/PersonWidget";
import CompanyWidget from "../common/CompanyWidget";
import dateUtils from "../../utils/dateUtils";
import SplashScreen from "../common/SplashScreen";
import userService from "../../services/userService";
import dbOrderService from "../../services/dbServices/dbOrderService";
import { ORDERS } from "../../services/dbService";
import manufacturerUtils from "../../utils/manufacturerUtils";
import { ManufacturerFilter, MarginFilter, OwnerFilter, PriorityFilter, SearchBar } from "./common/Filters";
import HistoryBackButton from "./common/HistoryBackButton";
import BaseListing from "./BaseListing";
import { CreatedOnBadge, MarginBadge, OrderWidget, PriorityBadge } from "./common/BaseListingComponents";
import { T_REQUESTPENDING, T_REQUESTREVOKED } from "../../utils/timelineUtils";
import accessUtils from "../../utils/accessUtils";

interface OffersProps extends RouteComponentProps<{}, {}, {}> {}

interface OffersState extends PaginationState {
  search: string;
  filterState: string;
  owner: "" | { value: string; label: string };
  priority: "" | { value: string; label: string };
  margin: "" | { value: string; label: string };
  manufacturer: "" | { value: string; label: string };
  manufacturerLocked: boolean;
}

const CONSTRUCTORNAME = "Offers";

class Offers extends PureComponent<OffersProps, OffersState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: OffersProps, context: React.ContextType<typeof DataContext>) {
    super(props, context);
    this.state = this.getDefaultState(context);
  }

  componentDidMount() {
    const state = getComponentState(this.context, CONSTRUCTORNAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }

  handleReset = () => this.setState(this.getDefaultState(this.context));
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value, currentPage: 1 });
  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleFilterState = (filter: string) => () => this.setState({ filterState: filter, currentPage: 1 });
  handleSelectChange = (name: string, entry: "" | { value: string; label: string }) => {
    // @ts-ignore
    this.setState({ [name]: entry ? { value: entry.value, label: entry.label } : "", currentPage: 1 });
  };
  /**
   * Request approval for an offer
   * @param offer the offer document
   */
  handleRequestApproval = (offer: OrdersDocument) => async () => {
    const timelineEntry = {
      type: T_REQUESTPENDING,
      date: new Date(),
      person: userService.getUserId()
    };
    try {
      const result = await dbOrderService.switchState(offer._id, REQUESTPENDING, timelineEntry);
      if (result && result.modifiedCount) {
        toast.success("Successfully requested approval for offer " + offer.title);
        await this.context.updateDocumentInContext(ORDERS, offer._id);
      } else toast.error(`Approval for offer ${offer.title} could not be requested`);
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    }
  };

  /**
   * Revoke approval for an offer
   * @param offer the offer document
   */
  handleRevokeApproval = (offer: OrdersDocument) => async () => {
    const timelineEntry = {
      type: T_REQUESTREVOKED,
      date: new Date(),
      person: userService.getUserId()
    };
    try {
      const result = await dbOrderService.switchState(offer._id, OFFER, timelineEntry);
      if (result && result.modifiedCount) {
        toast.success("Successfully revoked approval for offer " + offer.title);
        await this.context.updateDocumentInContext(ORDERS, offer._id);
      } else toast.error(`Approval for offer ${offer.title} could not be revoked`);
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    }
  };

  /**
   * Get the default state
   * @param context data context
   * @returns {OffersState} state for the component
   */
  getDefaultState = (context: React.ContextType<typeof DataContext>) => {
    const manufacturer = manufacturerUtils.checkCurrentUserManufacturerObject(context.manufacturers);
    return {
      search: "",
      pageSize: 10,
      currentPage: 1,
      filterState: "",
      owner: "",
      priority: "",
      margin: "",
      manufacturer: manufacturer,
      manufacturerLocked: manufacturer !== ""
    } as OffersState;
  };

  /**
   * Get filtered offers
   * @returns {Array<OrdersDocument>} list of offers matching the filter or search
   */
  getFilteredOffers = () => {
    const { orders, companies, userdata } = this.context;
    const { search, filterState, owner, priority, manufacturer, margin } = this.state;
    let offers = orders.filter(o => !orderUtils.isOrder(o));
    offers = orderUtils.filterOrders(
      offers,
      filterState,
      owner ? owner.value : "",
      priority ? priority.value : "",
      margin ? margin.value : "",
      manufacturer ? manufacturer.value : ""
    );
    if (search) {
      offers = orderUtils.filterBySearch(offers, companies, userdata, search) as Array<OrdersDocument>;
    }
    return offers.sort((o1, o2) => o2.createdOn.getTime() - o1.createdOn.getTime());
  };

  render() {
    const { orders, userdata, manufacturers } = this.context;
    const { history } = this.props;
    const {
      pageSize,
      currentPage,
      filterState,
      owner,
      priority,
      margin,
      manufacturer,
      manufacturerLocked,
      search
    } = this.state;
    const offers = this.getFilteredOffers();
    const selectableUsers = userdata.filter(user => user.company_id === "internal");
    const canSeeFinanceData = accessUtils.canSeeFinanceData();
    let headerDefinition = [
      { title: "Description", size: canSeeFinanceData ? 20 : 25 },
      { title: "Company", size: canSeeFinanceData ? 20 : 25 },
      { title: "Created", size: canSeeFinanceData ? 4 : 10 },
      { title: "Priority", size: canSeeFinanceData ? 4 : 10 }
    ];
    if (!canSeeFinanceData) {
      headerDefinition = headerDefinition.concat([
        { title: "Approvement", size: 15 },
        { title: "Owner", size: 15 }
      ]);
    } else {
      headerDefinition = headerDefinition.concat([
        { title: "Margin", size: 4 },
        { title: "Turnover", size: 8 },
        { title: "Margin %", size: 6 },
        { title: "Margin €", size: 8 },
        { title: "Approvement", size: 11 },
        { title: "Owner", size: 15 }
      ]);
    }
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand flaticon2-list-1" />
            </span>
            <h3 className="kt-portlet__head-title">Open Offers</h3>
            <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
              Reset
            </button>
          </div>
          <HistoryBackButton history={history} />
        </div>
        <div className="kt-portlet__body pb-0">
          <div className="kt-form kt-form--label-right  kt-margin-b-10">
            <div className="row align-items-center">
              <SearchBar onSearch={this.handleSearch} search={search} />
              <OwnerFilter owner={owner} selectableUsers={selectableUsers} onFilterChange={this.handleSelectChange} />
              <PriorityFilter priority={priority} onFilterSelect={this.handleSelectChange} />
              <MarginFilter margin={margin} onFilterSelect={this.handleSelectChange} isDisabled={!canSeeFinanceData} />
              <ManufacturerFilter
                manufacturer={manufacturer}
                manufacturerLocked={manufacturerLocked}
                manufacturers={manufacturers}
                onFilterSelect={this.handleSelectChange}
              />
            </div>
          </div>
        </div>
        <div className="btn-group btn-group-md mt-3 mb-2">
          <button
            className={"btn btn-outline-secondary " + (filterState === "" && "active")}
            onClick={this.handleFilterState("")}
          >
            All Offers
          </button>
          <button
            className={"btn btn-outline-secondary " + (filterState === REQUESTPENDING && "active")}
            onClick={this.handleFilterState(REQUESTPENDING)}
          >
            In Approval
          </button>
          <button
            className={"btn btn-outline-secondary " + (filterState === REQUESTAPPROVED && "active")}
            onClick={this.handleFilterState(REQUESTAPPROVED)}
          >
            Request Approved
          </button>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          {orders.length === 0 ? (
            <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
          ) : (
            <BaseListing
              headerDefinition={headerDefinition}
              documents={offers}
              bodyContent={
                <>
                  {paginate(offers, currentPage, pageSize).map(o => (
                    <OffersRow
                      key={o._id.toString()}
                      offer={o}
                      context={this.context}
                      {...this.props}
                      onRequestApproval={this.handleRequestApproval(o)}
                      onRevokeApproval={this.handleRevokeApproval(o)}
                    />
                  ))}
                </>
              }
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
            />
          )}
        </div>
      </div>
    );
  }
}

interface OffersRowProps extends RouteComponentProps {
  offer: OrdersDocument;
  context: React.ContextType<typeof DataContext>;
  onRequestApproval: () => void;
  onRevokeApproval: () => void;
}

const OffersRow: React.FunctionComponent<OffersRowProps> = ({
  history,
  offer,
  context,
  onRequestApproval,
  onRevokeApproval
}) => {
  const { userdata, companies } = context;
  const company = baseUtils.getDocFromCollection(companies, offer.createdFor);
  const owner = baseUtils.getDocFromCollection(userdata, offer.createdFrom);
  const averagePercentMargin = orderUtils.getAverageValue(offer, "percentmargin");
  const averageMargin = orderUtils.getAverageValue(offer, "totalmargin");
  const averageTurnover = orderUtils.getAverageValue(offer, "totalprice");
  const commodityPriceChange =
    offer.settings.type === REQUESTAPPROVED ? orderUtils.getTotalCommodityPriceChanges(offer) : 0;
  const requestPendingEntry =
    offer.settings.type === REQUESTPENDING
      ? offer.timeline
          .slice()
          .reverse()
          .find(t => t.type === T_REQUESTPENDING)
      : null;
  const canSeeFinanceData = accessUtils.canSeeFinanceData();
  const forwardOffer = () => history.push("/order/" + offer._id.toString());

  return (
    <tr className="kt-datatable__row table-hover d-table-row">
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOffer}>
        <OrderWidget document={offer} prefix={"Offer AN-"} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <CompanyWidget company={company} type={"company"} />
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOffer}>
        <CreatedOnBadge document={offer} />
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOffer}>
        <PriorityBadge document={offer} />
      </td>
      {canSeeFinanceData && (
        <>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardOffer}>
            <MarginBadge order={offer} />
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardOffer}>
            <span>
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details">
                  <p className="kt-user-card-v2__name mb-0">
                    {averageTurnover[0] + baseUtils.formatEuro(averageTurnover[1])}
                  </p>
                  <span className="kt-user-card-v2__email">
                    of {offer.calculations.length} {offer.calculations.length === 1 ? "calculation" : "calculations"}
                  </span>
                </div>
              </div>
            </span>
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardOffer}>
            <span>
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details">
                  <p className="kt-user-card-v2__name mb-0">
                    {averagePercentMargin[0] + averagePercentMargin[1].toFixed(2) + "%"}
                  </p>
                  {averagePercentMargin[0] && <span className="kt-user-card-v2__email"> approx.</span>}
                </div>
              </div>
            </span>
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardOffer}>
            <span>
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details">
                  <p className="kt-user-card-v2__name mb-0">
                    {averageMargin[0] + baseUtils.formatEuro(averageMargin[1])}
                  </p>
                  {averageMargin[0] && <span className="kt-user-card-v2__email"> approx.</span>}
                </div>
              </div>
            </span>
          </td>
        </>
      )}
      <td className="kt-datatable__cell d-table-cell">
        <span>
          {offer.state === "offer" ? (
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <p className="kt-user-card-v2__name mb-0">
                  <i className="fa fa-window-close mr-2" style={{ color: "#fd397a" }} />
                  Not Approved
                </p>
                <span className="kt-user-card-v2__email">
                  <button className="btn btn-sm btn-success py-0 px-1" onClick={onRequestApproval}>
                    Request Approval
                  </button>
                </span>
              </div>
            </div>
          ) : offer.state === "request_pending" ? (
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <p className="kt-user-card-v2__name mb-0">
                  <i className="fa fa-pen-square mr-2" style={{ color: "#ffb822" }} />
                  In Approval
                </p>
                <span className="kt-user-card-v2__email">
                  <button className="btn btn-sm btn-warning py-0 px-1" onClick={onRevokeApproval}>
                    Revoke Approval
                  </button>
                </span>
                <div>
                  <span className="kt-user-card-v2__email">
                    {dateUtils.generateTimeDiffString(requestPendingEntry ? requestPendingEntry.date : null)}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <p className="kt-user-card-v2__name mb-0">
                  <i className="fa fa-check-square mr-2" style={{ color: "#0abb87" }} />
                  Approved
                </p>
                <span className="kt-user-card-v2__email">
                  {commodityPriceChange <= 0 ? (
                    <p className="kt-user-card-v2__name mb-0" style={{ color: "#0abb87" }}>
                      <i className="fa fa-caret-down mr-2" style={{ color: "#0abb87" }} />
                      {commodityPriceChange.toFixed(2)}%
                    </p>
                  ) : (
                    <p className="kt-user-card-v2__name mb-0" style={{ color: "#0abb87" }}>
                      <i className="fa fa-exclamation mr-2" style={{ color: "#fd397a" }} />
                      <span style={{ color: "#fd397a" }}>+{commodityPriceChange.toFixed(2)}%</span>
                    </p>
                  )}
                </span>
              </div>
            </div>
          )}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <PersonWidget person={owner} />
        </span>
      </td>
    </tr>
  );
};

export default Offers;
