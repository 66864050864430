import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { toAbsoluteUrl } from "../../../_metronic";
import { CustomOrder } from "../CustomTypes";
import UploadFileModal from "../modals/UploadFileModal";
import CreateDatasheetModal from "../modals/CreateDatasheetModal";
import CreateOrderConfirmationModal from "../modals/CreateOrderConfirmationModal";
import CreateDeliveryNoteModal from "../modals/CreateDeliveryNoteModal";
import CreateManufacturingSheetModal from "../modals/CreateManufacturingSheetModal";
import OrderHelper, { T_CUSTOM, T_SERVICE, T_SOFTGEL } from "../OrderHelper";
import orderUtils, { ARCHIVE, CREATEINVOICE, FULFILLMENT, PRODUCTION } from "../../../utils/orderUtils";
import { DataContext } from "../../../context/dataContext";
import fileUtils from "../../../utils/fileUtils";
import config from "../../../config/config.json";
import baseUtils from "../../../utils/baseUtils";
import packagingUtils from "../../../utils/packagingUtils";
import dateUtils from "../../../utils/dateUtils";
import InvoiceModal from "../modals/InvoiceModal";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import userService from "../../../services/userService";
import {
  T_DATASHEET,
  T_DECLINED,
  T_DELIVERYINFORMATION,
  T_FILE,
  T_INVOICECANCELED,
  T_INVOICECREATED,
  T_INVOICEREMINDER,
  T_MANUFACTURINGPDF,
  T_NONBINDINGOFFERPDF,
  T_OFFERCONFIRMATION,
  T_OFFERPDF,
  T_PRODUCTSPECIFICATION,
  T_REMINDER,
  T_REPORTPDF,
  T_SHIPPINGLABEL
} from "../../../utils/timelineUtils";
import CreateOfferPDFModal from "../modals/CreateOfferPDFModal";
import CreateShippingLabelModal from "../modals/CreateShippingLabelModal";
import CreateNonBindingOfferPDFModal from "../modals/CreateNonBindingOfferPDFModal";
import accessUtils, { ACCESS_AREAS } from "../../../utils/accessUtils";
import { OrderFileTypes } from "../../../model/orders.types";
import CreateQRCodeModal from "../modals/CreateQRCodeModal";

interface OrderFilesProps {
  order: CustomOrder;
  timelineTypes?: Array<string>;
  context: React.ContextType<typeof DataContext>;
  readOnly?: boolean;
}

interface OrderFilesState {
  files: Array<FilesType>;
}

enum FileState {
  PENDING = "pending",
  CONFIRMED = "confirmed"
}

interface FilesType {
  _id?: BSON.ObjectId;
  type: string;
  img: string;
  title: string;
  subtitle?: string;
  path: string;
  date: Date;
  state?: FileState;
  onDelete?: any;
  // only needed for invoice
  timelineEntry?: any;
}

class OrderFiles extends PureComponent<OrderFilesProps, OrderFilesState> {
  constructor(props: OrderFilesProps) {
    super(props);
    this.state = { files: this.getFiles(props) };
  }

  componentDidUpdate(prevProps: Readonly<OrderFilesProps>, prevState: Readonly<OrderFilesState>, snapshot?: any) {
    if (!_.isEqual(prevProps.order, this.props.order)) {
      this.setState({ files: this.getFiles(this.props) });
    }
  }

  /**
   * Get all files to be displayed and set the state accordingly
   * @param props the props of the component
   */
  getFiles = (props: OrderFilesProps) => {
    const { order, timelineTypes, context } = props;
    let files: Array<FilesType> = this.getFilesFromTimeline(props);
    files = files.concat(this.getOrderFiles(props));
    if (!timelineTypes) files = files.concat(this.getOtherFiles(order, context));
    return files.slice().sort((f1, f2) => f2.date.getTime() - f1.date.getTime());
  };

  /**
   * Build the title for a timeline entry
   * @param timelineEntry the timeline entry
   * @returns {string} title for the entry
   */
  getTitleForEntry = (timelineEntry: any): string => {
    const title = timelineEntry.title || timelineEntry.category || "File";
    const type = timelineEntry.fileType || "undefined type";
    const size = this.getFileSize(timelineEntry);
    return `${title}-${type}-${size}`;
  };

  /**
   * Get the file size of a file
   * @param timelineEntry the timeline entry to get the file size for
   * @returns {string} the file size if existing
   */
  getFileSize = (timelineEntry: any): string => {
    return !timelineEntry.fileSize
      ? "undefined size"
      : timelineEntry.fileSize && timelineEntry.fileSize < 1048576
      ? (timelineEntry.fileSize / 1024).toFixed(2) + " KB"
      : timelineEntry.fileSize && timelineEntry.fileSize >= 1048576
      ? (timelineEntry.fileSize / 1024 / 1024).toFixed(2) + " MB"
      : "undefined size";
  };

  /**
   * Get all order files
   * @param props the components properties
   * @returns {Array<FilesType>} list of order files
   */
  getOrderFiles(props: OrderFilesProps): Array<FilesType> {
    const { order } = props;
    let files: Array<FilesType> = [];
    if (!order.files) return files;
    for (let i = 0; i < order.files.length; i++) {
      const file = order.files[i];
      const fileObject: FilesType = {
        _id: file._id,
        type: file.type,
        img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
        title: fileUtils.getDisplayableText(file.type),
        path: file.path,
        date: file.date
      };
      if (file.type === OrderFileTypes.PRODUCTION_REPORT && order.fulfillment?.productionReportApproval?.required) {
        fileObject.state = order.fulfillment?.productionReportApproval?.approvedBy
          ? FileState.CONFIRMED
          : FileState.PENDING;
      }
      files.push(fileObject);
    }
    return files;
  }

  /**
   * Get all specification files for packaging and commodities
   * @param order the order document
   * @param context data context
   * @returns {Array<FilesType>} list of files
   */
  getOtherFiles(order: CustomOrder, context: React.ContextType<typeof DataContext>) {
    let files: Array<FilesType> = [];
    const { commodities, packagings, suppliers } = context;
    const recipe =
      order.calculations && order.calculations[0] && order.calculations[0].prices ? order.calculations[0].prices : [];
    const packaging =
      order.calculations && order.calculations[0] && order.calculations[0].packagings
        ? order.calculations[0].packagings
        : [];
    for (let i = 0; i < recipe.length; i++) {
      const comm = recipe[i];
      const fullCommodity = baseUtils.getDocFromCollection(commodities, comm._id);
      if (fullCommodity)
        for (let j = 0; j < fullCommodity.specifications.length; j++) {
          const spec = fullCommodity.specifications[j];
          if (spec.id.toString() !== comm.supplier.toString()) continue;
          const supplier = baseUtils.getDocFromCollection(suppliers, spec.id);
          if (["specification", "CoA"].includes(spec.type)) {
            files.push({
              type: spec.type,
              img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
              title: `${fileUtils.getDisplayableText(spec.type)} - ${fullCommodity.title.en} - ${
                supplier.name || "unknown"
              }`,
              path: spec.path,
              date: spec.date
            });
          }
        }
    }
    for (let i = 0; i < packaging.length; i++) {
      const pack = packaging[i];
      const fullPackaging = baseUtils.getDocFromCollection(packagings, pack._id);
      if (fullPackaging)
        for (let j = 0; j < fullPackaging.specifications.length; j++) {
          const spec = fullPackaging.specifications[j];
          if (spec.id.toString() !== pack.supplier.toString()) continue;
          const supplier = baseUtils.getDocFromCollection(suppliers, spec.id);
          files.push({
            type: spec.type,
            img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
            title: `${fileUtils.getDisplayableText(spec.type)} - ${packagingUtils.getPackagingType(
              fullPackaging.packaging_type
            )} - ${supplier.name || "unknown"}`,
            path: spec.path,
            date: spec.date
          });
        }
    }
    if (accessUtils.canAccessOneOfAreas([ACCESS_AREAS.FINANCE, ACCESS_AREAS.SALES]) && order.invoices) {
      for (let i = 0; i < order.invoices.length; i++) {
        const invoice = order.invoices[i];
        let due = "";
        if (invoice.dueIn === -1) due = "Payment in Advance";
        else {
          const dueDate = new Date(invoice.invoiceDate);
          dueDate.setDate(dueDate.getDate() + invoice.dueIn);
          due = "Due on: " + baseUtils.formatDate(dueDate);
        }
        files.push({
          type: T_INVOICECREATED,
          img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
          title: `Invoice #${invoice.invoiceNumber}`,
          subtitle: (orderUtils.getInvoicedUnits(invoice) || "").toString() + " Units - " + due,
          path: config.mediahubBase + invoice.path,
          date: invoice.invoiceDate,
          timelineEntry: invoice
        });
        if (invoice.reminder.length > 0) {
          for (let j = 0; j < invoice.reminder.length; j++) {
            const r = invoice.reminder[j];
            files.push({
              type: T_INVOICEREMINDER,
              img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
              title: `Reminder for invoice #${invoice.invoiceNumber}`,
              subtitle:
                r.interest && r.position
                  ? baseUtils.formatEuro(r.position.total) + " reminder fees (40€ + " + r.interest + "% interest)"
                  : "No interest",
              path: config.mediahubBase + r.path,
              date: r.date,
              timelineEntry: invoice
            });
          }
        }
        if (invoice.cancelation) {
          const c = invoice.cancelation;
          files.push({
            type: T_INVOICECANCELED,
            img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
            title: `Cancelation of invoice #${invoice.invoiceNumber}`,
            subtitle: "Cancelation number: #" + c.cancelationNumber,
            path: config.mediahubBase + c.path,
            date: c.date,
            timelineEntry: invoice
          });
        }
      }
    }
    return files;
  }

  /**
   * Get all files from timeline
   * @param props the components props
   * @returns {Array<FilesType>} list of files
   */
  getFilesFromTimeline(props: OrderFilesProps): Array<FilesType> {
    const { order, timelineTypes } = props;
    const files: Array<FilesType> = [];
    const timeline = order.timeline.slice().reverse();
    const canSeeFinanceData = accessUtils.canSeeFinanceData();
    for (let i = 0; i < timeline.length; i++) {
      const timelineEntry = timeline[i];
      const types = timelineTypes
        ? timelineTypes
        : [
            T_REPORTPDF,
            T_MANUFACTURINGPDF,
            T_FILE,
            T_OFFERPDF,
            T_NONBINDINGOFFERPDF,
            T_PRODUCTSPECIFICATION,
            T_OFFERCONFIRMATION,
            T_DATASHEET,
            T_DELIVERYINFORMATION,
            T_REMINDER,
            T_SHIPPINGLABEL
          ];
      if (!types.includes(timelineEntry.type)) continue;

      let fileObject: FilesType;
      switch (timelineEntry.type) {
        case T_REPORTPDF:
          if (!canSeeFinanceData) break;
          fileObject = {
            type: timelineEntry.type,
            img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
            title: "Calculation Report",
            path: config.mediahubBase + timelineEntry.path,
            date: timelineEntry.date
          };
          files.push(fileObject);
          break;
        case T_MANUFACTURINGPDF:
          fileObject = {
            type: timelineEntry.type,
            img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
            title: `Manufacturing Sheet for AT-${timelineEntry.identifier}`,
            path: config.mediahubBase + timelineEntry.path,
            date: timelineEntry.date
          };
          files.push(fileObject);
          break;
        case T_FILE:
          fileObject = {
            type: timelineEntry.fileType,
            img: OrderHelper.getFileTypeImagePath(timelineEntry, timelineEntry.fileType),
            title: this.getTitleForEntry(timelineEntry),
            path: timelineEntry.path,
            date: timelineEntry.date,
            onDelete:
              userService.getUserData()._id.toString() === timelineEntry.person.toString()
                ? () => this.handleDeleteTimelineFile(timelineEntry)
                : undefined
          };
          files.push(fileObject);
          break;
        case T_OFFERPDF:
          if (!canSeeFinanceData) break;
          fileObject = {
            type: timelineEntry.type,
            img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
            title: `Offer`,
            path: config.mediahubBase + timelineEntry.path,
            date: timelineEntry.date
          };
          files.push(fileObject);
          break;
        case T_NONBINDINGOFFERPDF:
          fileObject = {
            type: timelineEntry.type,
            img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
            title: `Non-Binding Offer`,
            path: config.mediahubBase + timelineEntry.path,
            date: timelineEntry.date
          };
          files.push(fileObject);
          break;
        case T_PRODUCTSPECIFICATION:
          fileObject = {
            type: timelineEntry.type,
            img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
            title: `Product Specification`,
            path: config.mediahubBase + timelineEntry.path,
            date: timelineEntry.date
          };
          files.push(fileObject);
          break;
        case T_OFFERCONFIRMATION:
          if (!canSeeFinanceData) break;
          fileObject = {
            type: timelineEntry.type,
            img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
            title: `Order Confirmation for AT-${timelineEntry.identifier}`,
            path: config.mediahubBase + timelineEntry.path,
            date: timelineEntry.date
          };
          files.push(fileObject);
          break;
        case T_DATASHEET:
          fileObject = {
            type: timelineEntry.type,
            img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
            title: `Datasheet for AT-${timelineEntry.identifier}`,
            path: config.mediahubBase + timelineEntry.path,
            date: timelineEntry.date
          };
          files.push(fileObject);
          break;
        case T_DELIVERYINFORMATION:
          fileObject = {
            type: timelineEntry.type,
            img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
            title: `Delivery Information for AT-${timelineEntry.identifier}`,
            path: config.mediahubBase + timelineEntry.path,
            date: timelineEntry.date
          };
          files.push(fileObject);
          break;
        case T_REMINDER:
          if (!canSeeFinanceData) break;
          fileObject = {
            type: timelineEntry.type,
            img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
            title: `Reminder for Invoice ${timelineEntry.invoiceNumber ? "RE-" + timelineEntry.invoiceNumber : ""}`,
            path: config.mediahubBase + timelineEntry.path,
            date: timelineEntry.date
          };
          files.push(fileObject);
          break;
        case T_SHIPPINGLABEL:
          fileObject = {
            type: timelineEntry.type,
            img: toAbsoluteUrl("/media/icons/pdf_icon.png"),
            title: `Shipping Label`,
            path: timelineEntry.path,
            date: timelineEntry.date,
            onDelete: () => this.handleDeleteTimelineFile(timelineEntry)
          };
          files.push(fileObject);
          break;
      }
    }
    return files;
  }

  /**
   * Delete a timeline entry
   * @param timelineEntry the timeline entry to delete
   */
  handleDeleteTimelineFile = async (timelineEntry: any) => {
    const { order } = this.props;
    try {
      const result = await dbOrderService.deleteFromTimeline(order._id, timelineEntry);
      if (result && result.modifiedCount) toast.success("File deleted successfully");
      else toast.error("File could not be deleted");
    } catch (e) {
      console.error(e.message);
      toast.error("An unexpected error occurred: " + e.message);
    }
  };

  render() {
    const { order, timelineTypes, context, readOnly } = this.props;
    const { files } = this.state;
    const canSeeFinanceData = accessUtils.canSeeFinanceData();

    return (
      <>
        {!timelineTypes && !readOnly && (
          <div className="kt-section">
            <div className="kt-section__content kt-section__content--solid " style={{ borderLeft: "none" }}>
              <CreateOfferPDFModal
                order={order}
                context={context}
                disabled={!canSeeFinanceData || order.state === T_DECLINED}
              />
              <CreateNonBindingOfferPDFModal
                order={order}
                context={context}
                disabled={!canSeeFinanceData || order.state === T_DECLINED}
              />
              <CreateDatasheetModal
                order={order}
                context={context}
                disabled={[T_CUSTOM, T_SOFTGEL, T_SERVICE].includes(order.settings.type) || order.state === T_DECLINED}
              />
              <CreateOrderConfirmationModal
                order={order}
                context={context}
                disabled={!canSeeFinanceData || !orderUtils.isOrderState(order.state) || order.state === T_DECLINED}
              />
              <CreateDeliveryNoteModal
                order={order}
                context={context}
                disabled={![PRODUCTION, FULFILLMENT, CREATEINVOICE, ARCHIVE].includes(order.state)}
              />
              <InvoiceModal
                order={order}
                context={context}
                disabled={!canSeeFinanceData || !orderUtils.isOrderState(order.state) || order.state === T_DECLINED}
              />
              <UploadFileModal order={order} />
              <CreateManufacturingSheetModal
                order={order}
                context={context}
                disabled={
                  !orderUtils.isOrderState(order.state) ||
                  order.state === T_DECLINED ||
                  [T_CUSTOM, T_SOFTGEL, T_SERVICE].includes(order.settings.type)
                }
              />
              <CreateQRCodeModal
                order={order}
                disabled={!orderUtils.isOrderState(order.state) || order.state === T_DECLINED}
              />
              <CreateShippingLabelModal
                order={order}
                context={context}
                disabled={
                  !orderUtils.isOrderState(order.state) ||
                  order.settings.type === T_SERVICE ||
                  order.state === T_DECLINED
                }
              />
            </div>
          </div>
        )}
        <div className="kt-notification">
          <div className="kt-widget4">
            {files.map(file => (
              <OrderFilesEntry
                key={file._id ? file._id.toString() : file.type + file.date.toISOString()}
                order={order}
                context={context}
                entry={file}
                readOnly={readOnly}
              />
            ))}
          </div>
        </div>
      </>
    );
  }
}

interface OrderFilesEntryProps {
  order: CustomOrder;
  entry: FilesType;
  context: React.ContextType<typeof DataContext>;
  readOnly?: boolean;
}

interface OrderFilesEntryState {}

class OrderFilesEntry extends PureComponent<OrderFilesEntryProps, OrderFilesEntryState> {
  renderAdditionalButtons = () => {
    const { entry, order, context } = this.props;
    if (entry.type === T_INVOICECREATED) {
      return (
        <>
          <InvoiceModal order={order} context={context} type={"cancelation"} invoice={entry.timelineEntry} />
          <InvoiceModal order={order} context={context} type={"reminder"} invoice={entry.timelineEntry} />
        </>
      );
    }
    return "";
  };

  render() {
    const { entry, readOnly } = this.props;
    return (
      <div className="kt-widget4__item">
        <div className="kt-widget4__pic kt-widget4__pic--icon">
          <img src={entry.img} alt="" />
        </div>
        <div className="kt-widget4__info">
          <div>
            <a href={entry.path} target="_blank" rel="noopener noreferrer" className="kt-widget4__username mr-2">
              {entry.title}
            </a>
            {!readOnly && this.renderAdditionalButtons()}
          </div>
          {entry.subtitle ? <span>{entry.subtitle}</span> : ""}
          <p className="kt-widget4__text">{dateUtils.getTimeAgo(entry.date)}</p>
        </div>
        {entry.onDelete && !readOnly && (
          <button className="btn btn-danger btn-sm p-2" onClick={entry.onDelete}>
            <i className="flaticon2-cross px-1" />
          </button>
        )}
        {entry.state ? (
          entry.state === FileState.PENDING ? (
            <span className="text-warning" style={{ fontSize: "1.05rem" }}>
              <i className="fas fa-hourglass-half" /> Pending
            </span>
          ) : (
            <span className="text-success" style={{ fontSize: "1.05rem" }}>
              <i className="fa fa-check" /> Approved
            </span>
          )
        ) : null}
      </div>
    );
  }
}

export default OrderFiles;
