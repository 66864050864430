import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { OrdersDocument } from "../../../model/orders.types";
import { CompaniesDocument } from "../../../model/companies.types";
import orderUtils, { DECLINED, FULFILLMENT } from "../../../utils/orderUtils";
import dashboardUtils from "../../../utils/dashboardUtils";
import baseUtils from "../../../utils/baseUtils";

const OFFER_TAB = "offer";
const ORDER_TAB = "order";
const FULFILLMENT_TAB = "fulfillment";

interface LatestOrdersProps {
  orders: Array<OrdersDocument>;
  companies: Array<CompaniesDocument>;
}

interface LatestOrdersState {
  tab: string;
}

class LatestOrders extends PureComponent<LatestOrdersProps, LatestOrdersState> {
  constructor(props: LatestOrdersProps) {
    super(props);
    this.state = {
      tab: "offer"
    };
  }

  handleTab = (tab: string) => () => this.setState({ tab: tab });

  render() {
    const { orders, companies } = this.props;
    const { tab } = this.state;

    // filter orders according to tab
    // reverse orders here, since they are passed in ascending sort, but we want descending here
    // slice to display only 25 entries
    const filteredOrders = orders
      .filter(o => {
        if (tab === OFFER_TAB) return !orderUtils.isOrder(o);
        if (tab === ORDER_TAB) return orderUtils.isOrder(o) && o.state !== DECLINED;
        if (tab === FULFILLMENT_TAB) return o.state === FULFILLMENT;
      })
      .reverse()
      .slice(0, 25);

    return (
      <div className="kt-portlet" style={{ height: "500px" }}>
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label w-100">
            <ul className="nav nav-pills nav-fill text-center w-100 my-4 my-xl-auto" role="tablist">
              <li className="nav-item">
                <span
                  className="nav-link active kt-font-bolder pointer"
                  data-toggle="pill"
                  onClick={this.handleTab(OFFER_TAB)}
                >
                  Neue Angebote
                </span>
              </li>
              <li className="nav-item ">
                <span
                  className="nav-link kt-font-bolder pointer"
                  data-toggle="pill"
                  onClick={this.handleTab(ORDER_TAB)}
                >
                  Neue Aufträge
                </span>
              </li>
              <li className="nav-item ">
                <span
                  className="nav-link kt-font-bolder pointer"
                  data-toggle="pill"
                  onClick={this.handleTab(FULFILLMENT_TAB)}
                >
                  Bereit für Versand
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit h-100 overflow-auto m-2 mb-1">
          <div className="kt-widget27__container kt-portlet__space-x mt-3">
            <div className="tab-content">
              <div className="tab-pane active">
                <div className="kt-widget11">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <td>Auftrag</td>
                          <td>Marge</td>
                          <td className="kt-align-right">Umsatz</td>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredOrders.map(order => {
                          const company = baseUtils.getDocFromCollection(companies, order.createdFor);
                          return <LatestOrdersEntry key={order._id.toString()} order={order} company={company} />;
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface LatestOrdersEntryProps {
  order: OrdersDocument;
  company?: CompaniesDocument;
}

const LatestOrdersEntry: React.FunctionComponent<LatestOrdersEntryProps> = ({ order, company }) => {
  const percentMargin = dashboardUtils.getPercentMargin(order);
  const turnover = dashboardUtils.getTotalTurnover(order);
  const showPrefix = !orderUtils.hasFulfillmentPriceInfo(order) && order.calculations.length > 1;
  return (
    <tr>
      <td>
        <Link to={"/order/" + order._id} className="kt-user-card-v2__name kt-link kt-font-bolder">
          {!orderUtils.isOrder(order) ? "Offer AN-" : "Order AT-"}
          {order.identifier}
        </Link>
        {company ? (
          <Link to={"/company/" + company._id.toString()} className="kt-widget11__sub kt-font-dark">
            {company.name}
          </Link>
        ) : (
          <span className="kt-widget11__sub kt-font-dark">Unbekannt</span>
        )}
      </td>
      <td>
        {percentMargin <= 0 ? (
          <span className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill">
            <b>{percentMargin.toFixed(2) + "%"}</b>
          </span>
        ) : percentMargin > 0 && percentMargin < 25 ? (
          <span className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill">
            <b>{percentMargin.toFixed(2) + "%"}</b>
          </span>
        ) : percentMargin >= 25 && percentMargin < 30 ? (
          <span className="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill">
            <b>{percentMargin.toFixed(2) + "%"}</b>
          </span>
        ) : percentMargin >= 30 ? (
          <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill">
            <b>{percentMargin.toFixed(2) + "%"}</b>
          </span>
        ) : null}
      </td>
      <td className="kt-align-right kt-font-brand kt-font-bold kt-font-dark">
        {showPrefix && "∅"}
        {turnover.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR"
        })}
      </td>
    </tr>
  );
};

export default LatestOrders;
