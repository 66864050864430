import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../../context/dataContext";
import ExternalManufacturerOrder from "../orders/ExternalManufacturerOrder";

interface EMOrderParams {
  id: string;
}

interface EMOrderWrapperInternalProps extends RouteComponentProps<EMOrderParams, {}, {}> {}

const EmOrderWrapperInternal: React.FunctionComponent<EMOrderWrapperInternalProps> = props => {
  const context = useContext(DataContext);
  return <ExternalManufacturerOrder context={context} internal={true} {...props} />;
};

export default EmOrderWrapperInternal;
