import React, { PureComponent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BSON } from "realm-web";
import accessUtils, { ACTIONS } from "../../../../utils/accessUtils";
import commodityUtils, { checkOrdersForDifferentManufacturers } from "../../../../utils/commodityUtils";
import {
  I_CIFA,
  I_CIFS,
  I_CIPA,
  I_CIPS,
  I_COUR,
  I_CPT,
  I_DAP,
  I_DDP,
  I_DPU,
  I_EXW,
  I_FAS,
  I_FCA,
  I_FOBA,
  I_FOBS
} from "../../../../utils/suppliersUtils";
import DateInput from "../../../common/DateInput";
import baseUtils from "../../../../utils/baseUtils";
import RelatedOrders from "../../../common/RelatedOrders";
import RelatedExternalOrders from "../../RelatedExternalOrders";
import { CommoditiesDocument, CommodityOrder, CommodityPrice } from "../../../../model/commodities.types";
import { DataContext } from "../../../../context/dataContext";
import { OrdersDocument } from "../../../../model/orders.types";
import { ExternalManufacturerOrdersDocument } from "../../../../model/externalManufacturerOrders.types";
import { getRawbidsSupplierId } from "../../../../utils/rawbidsUtils";
import config from "../../../../config/config.json";
import CustomTooltip from "../../../common/Tooltip";

interface CommodityOrderSettingsProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
  commodityOrder: CommodityOrder;
  edit: boolean;
  availableIncoterms: { [key: string]: Array<string> };
  orders: Array<{ order: OrdersDocument; selected: boolean }>;
  relatedExternalOrders: Array<{ order: ExternalManufacturerOrdersDocument; selected: boolean }>;
  removedOrders: Array<BSON.ObjectId>;
  removedExternalOrders: Array<BSON.ObjectId>;
  orderQuantity: string;
  purchasePrice: string;
  totalPrice: string;
  loadingRawbidsData: boolean;
  resolveMatchingPrice: (commodityOrder: CommodityOrder) => CommodityPrice | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  onBlurQuantity: () => void;
  onRelatedOrder: (order: BSON.ObjectId, remove?: boolean) => void;
  onRelatedExternalOrder: (order: BSON.ObjectId, remove?: boolean) => void;
  onShowCancel: () => void;
  onClickLoadRawbidsOrderData: () => void;
}

class CommodityOrderSettings extends PureComponent<CommodityOrderSettingsProps> {
  handleOnClickOpenRawbids = () => {
    const { commodityOrder } = this.props;
    window.open(`${config.rawbidsBase}order/${commodityOrder.rawbidsOrder?.snapshot._id}`, "_blank");
  };
  /**
   * Calculates the amount of commodity that is needed for the selected orders.
   * @param useOrderQuantity optional, flag to indicate to use order quantity if existing
   * @returns Needed amount of commodity
   */
  calculateRequiredAmount = (useOrderQuantity?: boolean) => {
    const { commodity, orders, relatedExternalOrders, removedOrders, removedExternalOrders } = this.props;
    const selectedOrders = orders.filter(
      o => o.selected && !removedOrders.some(o2 => o.order._id.toString() === o2.toString())
    );
    if (useOrderQuantity && selectedOrders.length !== 1) return 0;
    const selectedExternalOrders = relatedExternalOrders.filter(
      o => o.selected && !removedExternalOrders.some(o2 => o.order._id.toString() === o2.toString())
    );
    return (
      selectedOrders.reduce((sum, o) => {
        const price = o.order.calculations[0].prices.find(p => p._id.toString() === commodity._id.toString())!;
        if (useOrderQuantity) return sum + (price.orderquantity || 0);
        return sum + commodityUtils.calculateUsage(o.order, price, commodity.type, false).raw;
      }, 0) + selectedExternalOrders.reduce((a, b) => a + b.order.amount, 0)
    );
  };

  /**
   * Check the input values for possible conflicts
   */
  checkForWarnings = () => {
    const { commodityOrder, orders, relatedExternalOrders, removedOrders, removedExternalOrders } = this.props;
    const deliveryDate = commodityUtils.resolveDeliveryDate(commodityOrder);
    const selectedOrders = orders.filter(
      o => o.selected && !removedOrders.some(o2 => o.order._id.toString() === o2.toString())
    );
    const selectedExternalOrders = relatedExternalOrders.filter(
      o => o.selected && !removedExternalOrders.some(o2 => o.order._id.toString() === o2.toString())
    );
    const warnings: any = {};
    const setWarning = (type: string, warning: string) => {
      if (warnings[type]) warnings[type] += ", " + warning;
      else warnings[type] = warning;
    };
    for (let order of selectedOrders) {
      let tD = order.order.targetDate;
      if (tD) tD = new Date(tD.getTime() - 1000 * 60 * 60 * 24 * 21); // Remove the 3 weeks buffer
      if (tD && tD < deliveryDate) {
        setWarning(
          "deliveryDate",
          `Delivery date exceeds target date (without buffer) of AT-${order.order.identifier}: ${baseUtils.formatDate(
            tD
          )}`
        );
      }
    }
    for (let externalOrder of selectedExternalOrders) {
      if (externalOrder.order.deliveryDateLimit && externalOrder.order.deliveryDateLimit < deliveryDate) {
        setWarning(
          "deliveryDate",
          `Delivery date exceeds original limit of EMO-${externalOrder.order.identifier}: ${baseUtils.formatDate(
            externalOrder.order.deliveryDateLimit
          )}`
        );
      }
      if (externalOrder.order.actualDeliveryDate && externalOrder.order.actualDeliveryDate < deliveryDate) {
        setWarning(
          "deliveryDate",
          `Delivery date exceeds confirmed delivery of EMO-${externalOrder.order.identifier}: ${baseUtils.formatDate(
            externalOrder.order.actualDeliveryDate
          )}`
        );
      }
      if (
        commodityOrder.orderquantity > 0 &&
        commodityOrder.totalPrice > 0 &&
        externalOrder.order.actualPrice &&
        externalOrder.order.actualPrice < commodityOrder.totalPrice / commodityOrder.orderquantity
      ) {
        setWarning(
          "price",
          `${baseUtils.formatEuro(
            commodityOrder.totalPrice / commodityOrder.orderquantity
          )} exceeds confirmed price of EMO-${externalOrder.order.identifier}: ${baseUtils.formatEuro(
            externalOrder.order.actualPrice
          )}`
        );
      }
    }
    return warnings;
  };

  render() {
    const {
      commodity,
      context,
      commodityOrder,
      availableIncoterms,
      orders,
      relatedExternalOrders,
      removedOrders,
      removedExternalOrders,
      orderQuantity,
      purchasePrice,
      totalPrice,
      edit,
      loadingRawbidsData,
      onChange,
      onBlurQuantity,
      onRelatedOrder,
      onRelatedExternalOrder,
      resolveMatchingPrice,
      onShowCancel,
      onClickLoadRawbidsOrderData
    } = this.props;
    const { currencies, manufacturers, suppliers } = context;
    const comSuppliersString = commodity.suppliers.map(s => s._id.toString());
    const comSuppliers = suppliers.filter(sup => comSuppliersString.includes(sup._id.toString()));
    const matchingPrice = resolveMatchingPrice(commodityOrder);
    const unit = !commodity.type ? "KG" : "TSD";
    const selectedOrders = orders.filter(o => o.selected);
    const selectedExternalOrders = relatedExternalOrders.filter(o => o.selected);
    const canCancel = accessUtils.canPerformAction(ACTIONS.COMMODITYCANCEL);
    const requiredAmount = this.calculateRequiredAmount();
    const requiredOrderQuantity = this.calculateRequiredAmount(true);
    const warehouseRequired = checkOrdersForDifferentManufacturers(
      selectedOrders.map(o => o.order),
      removedOrders.map(rO => rO.toString())
    );
    const warnings = this.checkForWarnings();

    return (
      <>
        <div className="h5 text-dark mb-5">
          Commodity Order Settings
          {edit && (
            <span className="float-right">
              {commodityOrder.rawbidsOrder && (
                <button
                  type="button"
                  className="btn btn-sm btn-dark bg-rawbids mr-2"
                  onClick={this.handleOnClickOpenRawbids}
                >
                  <img
                    style={{
                      width: 12,
                      height: 12,
                      borderRadius: 0,
                      objectFit: "cover"
                    }}
                    alt="R"
                    src={"https://app.rawbids.com/rawbidsFavicon.ico"}
                    className="country-icon mr-1"
                  />
                  Open
                </button>
              )}
              <button
                className={"btn btn-sm btn-secondary" + (canCancel ? "" : " disabled")}
                onClick={() => (canCancel ? onShowCancel() : undefined)}
                disabled={!canCancel}
              >
                Cancel Order
              </button>
            </span>
          )}
        </div>
        {(selectedOrders.length > 0 || selectedExternalOrders.length > 0) && (
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Required</label>
            <div className="col-9 align-self-center">
              <span className="text-dark font-weight-bold">
                {commodityUtils.resolveStockUnit(requiredAmount, commodity.type)}
              </span>
            </div>
          </div>
        )}
        {selectedOrders.length > 0 && requiredOrderQuantity > 0 && requiredOrderQuantity !== requiredAmount && (
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Order Quantity</label>
            <div className="col-9 align-self-center">
              <CustomTooltip tooltipText="Order quantity set during commodity check">
                <span className="text-dark font-weight-bold">
                  {commodityUtils.resolveStockUnit(requiredOrderQuantity, commodity.type)}
                </span>
              </CustomTooltip>
            </div>
          </div>
        )}
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Quantity</label>
          <div className="col-9">
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                value={orderQuantity}
                name="orderquantity"
                min={0}
                disabled={loadingRawbidsData}
                onChange={onChange}
                onBlur={onBlurQuantity}
              />
              <div className="input-group-append">
                <span className="input-group-text">{unit}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Supplier</label>
          <div className="col-9">
            <select
              className="form-control"
              value={commodityOrder.supplier ? commodityOrder.supplier.toString() : ""}
              name="supplier"
              disabled={loadingRawbidsData}
              onChange={onChange}
            >
              <option value="" disabled hidden>
                Please Select
              </option>
              {comSuppliers.map(s => (
                <option key={s._id.toString()} value={s._id.toString()}>
                  {s.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {commodity.rawbidsData && getRawbidsSupplierId(context.general) === commodityOrder.supplier?.toString() && (
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Rawbids Order No.</label>
            <div className="col-8">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">RB-</span>
                </div>
                <input
                  className="form-control"
                  type="text"
                  value={commodityOrder.rawbidsOrder?.snapshot.orderNo ?? ""}
                  name="rawbidsOrder.orderNo"
                  placeholder="2000224"
                  disabled={loadingRawbidsData}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="col-1">
              <button className="btn bg-rawbids" onClick={onClickLoadRawbidsOrderData}>
                <i className="fas fa-sync text-white pr-0" />
              </button>
            </div>
          </div>
        )}
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Incoterm</label>
          <div className="col-9">
            <select
              className="form-control"
              value={commodityOrder.incoterm}
              name="incoterm"
              disabled={loadingRawbidsData}
              onChange={onChange}
            >
              <option value="?" disabled hidden>
                Please Select
              </option>
              {commodityOrder.supplier &&
                availableIncoterms[commodityOrder.supplier.toString()] &&
                availableIncoterms[commodityOrder.supplier.toString()].map(i => (
                  <option value={i} key={i}>
                    {i ? i : "None specified"}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Destination</label>
          <div className="col-9">
            <OverlayTrigger
              show={warehouseRequired ? undefined : false}
              overlay={
                <Tooltip id="warehouseRequiredWarning">
                  <span>
                    Since orders with different manufacturers were selected the commodities have to be send to the
                    warehouse first
                  </span>
                </Tooltip>
              }
            >
              <select
                className={"form-control" + (commodityOrder.orders.length > 0 ? " disabled" : "")}
                value={commodityOrder.destination ? commodityOrder.destination.toString() : ""}
                name="destination"
                onChange={onChange}
                disabled={
                  loadingRawbidsData ||
                  warehouseRequired ||
                  (commodityOrder.orders.length > 0 &&
                    removedOrders.length !== commodityOrder.orders.length &&
                    this.props.commodityOrder &&
                    !!this.props.commodityOrder.destination)
                }
              >
                <option value="" disabled hidden>
                  Please Select
                </option>
                {manufacturers.map(m => (
                  <option
                    key={m._id.toString()}
                    value={m._id.toString()}
                    disabled={
                      !!commodityOrder.warehouseDestination &&
                      commodityOrder.warehouseDestination.toString() === m._id.toString()
                    }
                  >
                    {m.name}
                  </option>
                ))}
              </select>
            </OverlayTrigger>
          </div>
        </div>
        {commodityOrder.orders.length > 0 && removedOrders.length !== commodityOrder.orders.length && (
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">
              Warehouse{!warehouseRequired && " (optional)"}
            </label>
            <div className="col-9">
              <select
                className="form-control"
                value={commodityOrder.warehouseDestination ? commodityOrder.warehouseDestination.toString() : ""}
                name="warehouseDestination"
                onChange={warehouseRequired || Boolean(commodityOrder.arrivedAtWarehouse) ? undefined : onChange}
                disabled={loadingRawbidsData || warehouseRequired || Boolean(commodityOrder.arrivedAtWarehouse)}
              >
                <option value="">Directly to Manufacturer</option>
                {manufacturers.map(m => (
                  <option
                    key={m._id.toString()}
                    value={m._id.toString()}
                    disabled={
                      !!commodityOrder.destination && m._id.toString() === commodityOrder.destination.toString()
                    }
                  >
                    {m.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">
            {[I_FCA, I_CIFA, I_CIFS, I_CIPA, I_CIPS, I_DDP, I_DAP, I_DPU, I_CPT, I_COUR].includes(
              commodityOrder.incoterm
            )
              ? "ETA"
              : "Pickup Date"}
          </label>
          <div className="col-9">
            <OverlayTrigger
              show={!warnings["deliveryDate"] ? false : undefined}
              overlay={
                <Tooltip id="deliveryDateWarning">
                  <span>{warnings["deliveryDate"]}</span>
                </Tooltip>
              }
            >
              <input
                className={"form-control " + (warnings["deliveryDate"] ? "is-invalid" : "")}
                type="date"
                min={new Date().toISOString().split("T")[0]}
                value={commodityUtils.resolveDeliveryDate(commodityOrder).toISOString().split("T")[0]}
                disabled={loadingRawbidsData}
                onChange={onChange}
                name="deliverytime"
              />
            </OverlayTrigger>
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">LOT</label>
          <div className="col-9">
            <input
              className="form-control"
              type="text"
              value={commodityOrder.lot ? commodityOrder.lot : ""}
              disabled={loadingRawbidsData}
              onChange={onChange}
              name="lot"
              placeholder="LOT"
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Est. Batch Expiration</label>
          <div className="col-9">
            <DateInput
              value={commodityOrder.expiry ? commodityOrder.expiry : null}
              disabled={loadingRawbidsData}
              onBlur={onChange}
              name="expiry"
              allowClear={true}
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">
            {commodityOrder.incoterm === I_EXW
              ? "Pickup Company"
              : [I_FCA, I_CIFA, I_CIFS].includes(commodityOrder.incoterm)
              ? "Destination Port"
              : [I_FAS, I_FOBA, I_FOBS].includes(commodityOrder.incoterm)
              ? "Departure Port"
              : "Carrier"}
          </label>
          <div className="col-9">
            <input
              className="form-control"
              type="text"
              value={commodityOrder.carrier ? commodityOrder.carrier : ""}
              disabled={loadingRawbidsData}
              onChange={onChange}
              name="carrier"
              placeholder="Carrier"
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Packages</label>
          <div className="col-9">
            <input
              className="form-control"
              type="text"
              value={commodityOrder.packages ? commodityOrder.packages : ""}
              name="packages"
              disabled={loadingRawbidsData}
              onChange={onChange}
              placeholder="e.g. 4 x 25kg"
            />
          </div>
        </div>
        {[I_FCA, I_FAS, I_FOBA, I_FOBS, I_CIFA, I_CIFS].includes(commodityOrder.incoterm) && (
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Notify</label>
            <div className="col-9">
              <select className="form-control" value={commodityOrder.notify} name="notify" disabled>
                <option value="asap logistics GmbH">asap logistics GmbH</option>
              </select>
            </div>
          </div>
        )}
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Note</label>
          <div className="col-9">
            <textarea
              className="form-control"
              value={commodityOrder.notes ? commodityOrder.notes : ""}
              name="notes"
              disabled={loadingRawbidsData}
              onChange={onChange}
              placeholder="Additional Notes"
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Total Price</label>
          <div className="col-9">
            <div className="input-group">
              <OverlayTrigger
                show={!warnings["price"] ? false : undefined}
                overlay={
                  <Tooltip id="priceWarning">
                    <span>{warnings["price"]}</span>
                  </Tooltip>
                }
              >
                <input
                  className={"form-control " + (warnings["price"] ? "is-invalid" : "")}
                  type="number"
                  value={totalPrice}
                  name="totalPrice"
                  min={0}
                  disabled={loadingRawbidsData}
                  onChange={onChange}
                />
              </OverlayTrigger>
              <div className="input-group-append">
                <span className="input-group-text">€</span>
              </div>
            </div>
            {matchingPrice && (
              <small className="kt-font-dark">
                Reference: {baseUtils.formatEuro(matchingPrice.price)} / {unit} (
                {baseUtils.formatEuro(matchingPrice.price * commodityOrder.orderquantity)} total) - last updated:{" "}
                {matchingPrice.date ? baseUtils.formatDate(matchingPrice.date) : "unknown"}
              </small>
            )}
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Total Purchase Price</label>
          <div className="col-9">
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                value={purchasePrice}
                name="purchasePrice"
                min={0}
                disabled={loadingRawbidsData}
                onChange={onChange}
              />
              <div className="input-group-append">
                <select
                  className="form-control"
                  value={commodityOrder.currency}
                  name="currency"
                  disabled={loadingRawbidsData}
                  onChange={onChange}
                >
                  <option value="" disabled hidden>
                    ?
                  </option>
                  {currencies &&
                    currencies.map(currency => (
                      <option key={currency.code} value={currency.code}>
                        {currency.code}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {matchingPrice && !!matchingPrice.purchasePrice && (
              <small className="kt-font-dark">
                Reference: {baseUtils.formatCurrency(matchingPrice.purchasePrice, matchingPrice.purchaseCurrency)} /{" "}
                {unit} (
                {baseUtils.formatCurrency(
                  matchingPrice.purchasePrice * commodityOrder.orderquantity,
                  matchingPrice.purchaseCurrency
                )}{" "}
                total) - last updated: {matchingPrice.date ? baseUtils.formatDate(matchingPrice.date) : "unknown"}
              </small>
            )}
          </div>
        </div>
        {commodityOrder.orders && commodityOrder.orders.length > 0 && (
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Related Orders</label>
            <div className="col-9">
              <RelatedOrders
                orders={commodityOrder.orders}
                context={context}
                removedOrders={removedOrders}
                onRelatedOrder={onRelatedOrder}
              />
            </div>
          </div>
        )}
        {commodityOrder.relatedEMOrders && commodityOrder.relatedEMOrders.length > 0 && (
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Related Ext. Orders</label>
            <div className="col-9">
              <RelatedExternalOrders
                orders={commodityOrder.relatedEMOrders}
                context={context}
                removedExternalOrders={removedExternalOrders}
                onRelatedExternalOrder={onRelatedExternalOrder}
                edit={edit}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default CommodityOrderSettings;
