import _ from "lodash";
import * as Realm from "realm-web";
import Select from "react-select";
import React, { Component, useContext, useMemo } from "react";
import { CalculationType, CustomCommoditiesDocument, Preferences, SelectedCommoditiesDocument } from "./CustomTypes";
import baseUtils from "../../utils/baseUtils";
import { DataContext } from "../../context/dataContext";
import CommoditySelection from "./CommoditySelection";
import PurchasedSelection from "./PurchasedSelection";
import calculationUtils from "../../utils/calculationUtils";
import { ProductDefinition, ProductTypes } from "./configuratorConstants";
import Tooltip from "../common/Tooltip";

interface RecipeSelectionProps {
  activeType: string;
  fullscreen: boolean;
  preferences: Preferences;
  calculations: Array<CalculationType>;
  commodities: Array<CustomCommoditiesDocument>;
  recipe: Array<SelectedCommoditiesDocument>;
  onProductTypeChange: (entry: string) => void;
  onPreferenceChange: (path: string, item: any) => void;
  onCapsuleChange: (capsuleId: Realm.BSON.ObjectId | string) => void;
  onRecipeAdd: (id: Realm.BSON.ObjectId, index?: number, amount?: number) => void;
  onRecipeSave: (id: Realm.BSON.ObjectId, amount: number) => void;
  onRecipeDelete: (id: Realm.BSON.ObjectId) => void;
  onDraggableItemMove: (oldIndex: number, newIndex: number) => void;
  onRecalculate: () => void;
  onRecalculateSpecific: (calculation: CalculationType) => void;
  onCalculationPropertyChange: (id: Realm.BSON.ObjectId, path: string, value: any, updatePrices?: boolean) => void;
  recipeVolume: { value: number; noDefault: boolean };
}

interface RecipeSelectionState {}

class RecipeSelection extends Component<RecipeSelectionProps, RecipeSelectionState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  shouldComponentUpdate(nextProps: Readonly<RecipeSelectionProps>): boolean {
    return (
      nextProps.activeType !== this.props.activeType ||
      nextProps.fullscreen !== this.props.fullscreen ||
      nextProps.commodities !== this.props.commodities ||
      nextProps.recipe !== this.props.recipe ||
      !_.isEqual(nextProps.preferences, this.props.preferences) ||
      !_.isEqual(nextProps.calculations, this.props.calculations)
    );
  }

  render() {
    const {
      activeType,
      recipe,
      commodities,
      preferences,
      fullscreen,
      calculations,
      onProductTypeChange,
      onPreferenceChange,
      onCapsuleChange,
      onRecipeAdd,
      onRecipeSave,
      onRecipeDelete,
      onDraggableItemMove,
      onRecalculate,
      onRecalculateSpecific,
      onCalculationPropertyChange,
      recipeVolume
    } = this.props;
    return (
      <div>
        <RecipeSelectionToolbar
          preferences={preferences}
          type={activeType}
          calculations={calculations}
          fullscreen={fullscreen}
          onProductTypeChange={onProductTypeChange}
          onPreferenceChange={onPreferenceChange}
          onCapsuleChange={onCapsuleChange}
          onRecalculate={onRecalculate}
          onRecalculateSpecific={onRecalculateSpecific}
          onCalculationPropertyChange={onCalculationPropertyChange}
        />
        {[ProductTypes.CAPSULES, ProductTypes.TABLETS, ProductTypes.POWDER, ProductTypes.LIQUID].includes(
          activeType
        ) ? (
          <CommoditySelection
            activeType={activeType}
            preferences={preferences}
            commodities={commodities}
            recipe={recipe}
            calculations={calculations}
            context={this.context}
            onRecipeAdd={onRecipeAdd}
            onRecipeSave={onRecipeSave}
            onRecipeDelete={onRecipeDelete}
            onDraggableItemMove={onDraggableItemMove}
            recipeVolume={recipeVolume}
          />
        ) : (
          <PurchasedSelection
            activeType={activeType}
            preferences={preferences}
            commodities={commodities}
            recipe={recipe}
            calculations={calculations}
            onRecipeAdd={onRecipeAdd}
            onRecipeDelete={onRecipeDelete}
          />
        )}
      </div>
    );
  }
}

interface RecipeSelectionToolbarProps {
  type: string;
  calculations: Array<CalculationType>;
  preferences: Preferences;
  fullscreen: boolean;
  onProductTypeChange: (entry: string) => void;
  onPreferenceChange: (path: string, item: any) => void;
  onCapsuleChange: (capsuleId: Realm.BSON.ObjectId | string) => void;
  onRecalculate: () => void;
  onRecalculateSpecific: (calculation: CalculationType) => void;
  onCalculationPropertyChange: (id: Realm.BSON.ObjectId, path: string, value: any, updatePrices?: boolean) => void;
}

const RecipeSelectionToolbar: React.FunctionComponent<RecipeSelectionToolbarProps> = ({
  type,
  preferences,
  fullscreen,
  onProductTypeChange,
  onPreferenceChange,
  onRecalculate,
  onRecalculateSpecific,
  calculations,
  onCalculationPropertyChange,
  onCapsuleChange
}) => {
  const context = useContext(DataContext);
  const { capsules, tablets, manufacturers } = context;
  const productType = useMemo(() => ProductDefinition.find(p => p.value.toString() === type)!, [type]);

  const getUnitsDescription = () => {
    switch (type) {
      case ProductTypes.CAPSULES:
      case ProductTypes.TABLETS:
      case ProductTypes.SOFTGEL:
      case ProductTypes.CUSTOM:
        return "tsd.";
      case ProductTypes.POWDER:
        return "kg";
    }
  };

  const filteredManufacturers = useMemo(
    () => calculationUtils.getFilteredManufacturers(manufacturers, type, preferences.selectedCapsule),
    [manufacturers, type, preferences.selectedCapsule]
  );

  const manufacturerOptions = useMemo(
    () =>
      filteredManufacturers.map(manufacturer => {
        return {
          value: manufacturer._id,
          label: manufacturer.name,
          data: manufacturer
        };
      }),
    [filteredManufacturers]
  );

  const fillerOptions = useMemo(
    () =>
      manufacturers
        .filter(
          man =>
            man._id.toString() !== preferences.selectedManufacturer?._id.toString() &&
            Object.keys(man).some(key => key.startsWith(type))
        )
        .map(manufacturer => {
          return {
            value: manufacturer._id,
            label: manufacturer.name,
            data: manufacturer
          };
        }),
    [preferences.selectedManufacturer, manufacturers, type]
  );

  return (
    <div>
      <div
        className={!fullscreen ? "kt-portlet kt-portlet--tabs mt-1" : "kt-portlet kt-portlet--tabs mt-1 d-none"}
        style={{ boxShadow: "none" }}
      >
        <div className="kt-portlet__body pb-1 pl-0 pr-0">
          <div className="form-group row mb-0">
            <div className="col-lg-3">
              <Select
                className="select-default"
                value={{ value: productType.value, label: productType.label }}
                onChange={(value: any) => onProductTypeChange(value.value)}
                options={ProductDefinition.map(def => {
                  return {
                    value: def.value,
                    label: def.label
                  };
                })}
              />
              <span className="form-text text-muted">Product Category</span>
            </div>
            {ProductTypes.CAPSULES === type && (
              <div className="col-lg-3">
                <Select
                  className="select-default"
                  onChange={(value: any) => onCapsuleChange(value.value)}
                  value={
                    preferences.selectedCapsule
                      ? {
                          value: preferences.selectedCapsule._id,
                          label: baseUtils.buildCapsuleString(preferences.selectedCapsule)
                        }
                      : { value: "", label: "" }
                  }
                  options={capsules.map(capsule => {
                    return {
                      value: capsule._id,
                      label: baseUtils.buildCapsuleString(capsule)
                    };
                  })}
                />
                <span className="form-text text-muted">Capsule</span>
              </div>
            )}
            {ProductTypes.TABLETS === type && (
              <div className="col-lg-3">
                <Select
                  className="select-default"
                  onChange={(value: any) =>
                    onPreferenceChange(
                      "selectedTablet",
                      tablets.find(tab => tab._id.toString() === value.value.toString())
                    )
                  }
                  value={
                    preferences.selectedTablet
                      ? {
                          value: preferences.selectedTablet._id,
                          label: baseUtils.buildTabletString(preferences.selectedTablet)
                        }
                      : { value: "", label: "" }
                  }
                  options={tablets.map(tablet => {
                    return {
                      value: tablet._id,
                      label: baseUtils.buildTabletString(tablet)
                    };
                  })}
                />
                <span className="form-text text-muted">Tablet Size</span>
              </div>
            )}
            {[ProductTypes.CAPSULES, ProductTypes.TABLETS, ProductTypes.CUSTOM, ProductTypes.SOFTGEL].includes(
              type
            ) && (
              <div className="col-lg-3">
                <div className="input-group">
                  <input
                    disabled={preferences.bulk}
                    type="number"
                    className="form-control"
                    name="perUnit"
                    value={preferences.amountPerUnit}
                    min={0}
                    onChange={e =>
                      onPreferenceChange("amountPerUnit", Number(parseInt(e.target.value) || "0").toString())
                    }
                    onBlur={onRecalculate}
                  />

                  <div className={"input-group-append"}>
                    <span className="input-group-text">pcs</span>
                  </div>
                </div>
                <span className="form-text text-muted">Per Unit</span>
              </div>
            )}
            {![ProductTypes.SERVICE].includes(type) && (
              <div className="col-lg-3">
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    name="units"
                    value={calculations[0].units}
                    min={0}
                    onChange={e =>
                      onCalculationPropertyChange(
                        calculations[0].id,
                        "units",
                        Number(parseInt(e.target.value) || "0").toString(),
                        false
                      )
                    }
                    onBlur={() => onRecalculateSpecific(calculations[0])}
                  />
                  <div className={preferences.bulk ? "input-group-append" : "input-group-append d-none"}>
                    <span className="input-group-text">{getUnitsDescription()}</span>
                  </div>
                </div>
                <span className="form-text text-muted">
                  {preferences.bulk ? "Total " + ProductDefinition.find(def => def.value === type)!.label : "Units"}
                  {![ProductTypes.LIQUID, ProductTypes.POWDER].includes(type) && (
                    <label className="kt-checkbox ml-3 pr-1 pl-4">
                      <input
                        type="checkbox"
                        checked={preferences.bulk}
                        onChange={() => onPreferenceChange("bulk", !preferences.bulk)}
                        className="ml-0"
                      />{" "}
                      &nbsp;Bulk
                      <span />
                    </label>
                  )}
                </span>
              </div>
            )}
            {![ProductTypes.SERVICE].includes(type) && (
              <div className="col-lg-3">
                <Select
                  className="select-default"
                  onChange={(value: any) => onPreferenceChange("selectedManufacturer", value.data)}
                  value={
                    preferences.selectedManufacturer
                      ? {
                          value: preferences.selectedManufacturer._id,
                          label: preferences.selectedManufacturer.name,
                          data: preferences.selectedManufacturer
                        }
                      : null
                  }
                  options={manufacturerOptions}
                />
                <span className="form-text text-muted">Manufacturer</span>
              </div>
            )}
            {![ProductTypes.SERVICE, ProductTypes.SOFTGEL, ProductTypes.CUSTOM].includes(type) && !preferences.bulk && (
              <div className="col-lg-3">
                <Select
                  className="select-default"
                  onChange={(value: any) => onPreferenceChange("selectedFiller", value?.data)}
                  isClearable={true}
                  value={
                    preferences.selectedFiller
                      ? {
                          value: preferences.selectedFiller._id,
                          label: preferences.selectedFiller.name,
                          data: preferences.selectedFiller
                        }
                      : null
                  }
                  placeholder={"Select Filler"}
                  options={fillerOptions}
                />
                <span className="form-text text-muted">
                  Filler (optional)
                  <Tooltip
                    tooltipText={
                      <span>
                        Add an additional filler, responsible for the final production steps such as bottling but not
                        for the production of the capsule, tablet, etc. itself <br />
                        If all production steps are done by one manufacturer do not select a filler. The filler will be
                        the target destination for packaging.
                      </span>
                    }
                  >
                    <i className="fa fa-info-circle ml-2 text-muted" />
                  </Tooltip>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeSelection;
