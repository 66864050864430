import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import i18n from "../../../../translations/i18n";
import { useWarehouseContext, useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { ProductFilterValuesEnum } from "../../../../utils/warehouseFilterUtils";
import { BatchContentSpecificType } from "../../../../model/warehouse/common.types";
import { useDataContext } from "../../../../context/dataContext";
import { resolveTranslation } from "../../../../utils/translationUtils";
import { BasicSelectOption } from "../../../../model/common.types";

const DISABLED_OPTIONS: Array<string> = [
  BatchContentSpecificType.CAPSULES,
  ProductFilterValuesEnum.ALL_PACKAGING,
  BatchContentSpecificType.BOTTLES,
  BatchContentSpecificType.BAGS,
  BatchContentSpecificType.BLISTERS,
  BatchContentSpecificType.LIDS,
  BatchContentSpecificType.PIPETTES,
  BatchContentSpecificType.SPRAY_PUMPS,
  BatchContentSpecificType.LABELS,
  BatchContentSpecificType.BOXES,
  BatchContentSpecificType.SLEEVES,
  BatchContentSpecificType.OTHER_PACKAGING,
  BatchContentSpecificType.FINISHED_PRODUCTS
];

interface ProductFilterSelectOption extends BasicSelectOption {
  rootValue: boolean;
}

const ProductFilter: React.FC = () => {
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const dispatch = useWarehouseDispatch();
  const { additionalProductFilter, productFilter } = warehouseContext;
  const { compositions, commoditycategories } = dataContext;

  const options: Array<ProductFilterSelectOption> = useMemo(() => {
    const sortProductFilterSelectOptions = (
      options: Array<ProductFilterSelectOption>
    ): Array<ProductFilterSelectOption> => {
      const rootOptions = options.filter(o => o.rootValue);
      const sortOptions = options.filter(o => !o.rootValue);
      sortOptions.sort((a, b) => a.label.localeCompare(b.label));
      return [...rootOptions, ...sortOptions];
    };

    const commodityOptions: Array<ProductFilterSelectOption> = sortProductFilterSelectOptions([
      {
        value: ProductFilterValuesEnum.ALL_RAW_MATERIALS,
        label: i18n.t("warehouse:" + ProductFilterValuesEnum.ALL_RAW_MATERIALS),
        rootValue: true
      },
      ...compositions.map(c => ({
        value: c._id.toString(),
        label: "\xa0 - " + resolveTranslation(c.name),
        rootValue: false
      })),
      ...commoditycategories.map(cat => ({
        value: cat._id.toString(),
        label: "\xa0 - " + resolveTranslation(cat.name),
        rootValue: false
      }))
    ]);
    const packagingOptions: Array<ProductFilterSelectOption> = sortProductFilterSelectOptions([
      {
        value: ProductFilterValuesEnum.ALL_PACKAGING,
        label: i18n.t("warehouse:" + ProductFilterValuesEnum.ALL_PACKAGING),
        rootValue: true
      },
      {
        value: BatchContentSpecificType.BOTTLES,
        label: "\xa0 - " + i18n.t("packaging:" + BatchContentSpecificType.BOTTLES),
        rootValue: false
      },
      {
        value: BatchContentSpecificType.LIDS,
        label: "\xa0 - " + i18n.t("warehouse:" + BatchContentSpecificType.LIDS),
        rootValue: false
      },
      {
        value: BatchContentSpecificType.LABELS,
        label: "\xa0 - " + i18n.t("warehouse:" + BatchContentSpecificType.LABELS),
        rootValue: false
      },
      {
        value: BatchContentSpecificType.BOXES,
        label: "\xa0 - " + i18n.t("warehouse:" + BatchContentSpecificType.BOXES),
        rootValue: false
      },
      {
        value: BatchContentSpecificType.SLEEVES,
        label: "\xa0 - " + i18n.t("warehouse:" + BatchContentSpecificType.SLEEVES),
        rootValue: false
      }
    ]);
    const productOptions: Array<ProductFilterSelectOption> = [
      {
        value: BatchContentSpecificType.CAPSULES,
        label: i18n.t("warehouse:" + BatchContentSpecificType.CAPSULES),
        rootValue: true
      },
      {
        value: BatchContentSpecificType.SOFTGELS,
        label: "\xa0 - " + i18n.t("warehouse:" + BatchContentSpecificType.SOFTGELS),
        rootValue: false
      },
      {
        value: BatchContentSpecificType.FINISHED_PRODUCTS,
        label: i18n.t("warehouse:" + BatchContentSpecificType.FINISHED_PRODUCTS),
        rootValue: true
      }
    ];
    return [
      {
        value: ProductFilterValuesEnum.ALL,
        label: i18n.t("warehouse:" + ProductFilterValuesEnum.ALL),
        rootValue: true
      },
      ...commodityOptions,
      ...packagingOptions,
      ...productOptions
    ];
  }, [compositions, commoditycategories]);

  const handleSelectProductFilter = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) =>
      dispatch({
        type: WarehouseActionType.PRODUCT_FILTER,
        payload: { productType: e.currentTarget.value }
      }),
    []
  );

  const handleToggleCheckBox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      dispatch({
        type: WarehouseActionType.ADDITIONAL_PRODUCT_FILTER,
        payload: { [e.target.name]: !_.get(additionalProductFilter, e.target.name) }
      }),
    [additionalProductFilter]
  );

  return (
    <div className="navi navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon pt-7">
      <h5 className=" m-2 font-weight-bold text-black">{i18n.t("warehouse:typesOfGoods")}</h5>
      <hr className="w-100" />
      <div className="navi-item my-2">
        <select
          value={productFilter}
          onChange={handleSelectProductFilter}
          className="form-control"
          id="kt_datatable_search_type"
        >
          {options.map((option: BasicSelectOption) => (
            <option disabled={DISABLED_OPTIONS.includes(option.value)} key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="navi-item my-2">
        <div className="navi-link">
          <label className="checkbox checkbox-sm checkbox-single flex-shrink-0 mr-2">
            <input
              type="checkbox"
              name="organic"
              onChange={handleToggleCheckBox}
              checked={additionalProductFilter.organic}
            />
            <span />
          </label>
          <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
            <span className="navi-text font-weight-bolder">{i18n.t("warehouse:onlyOrganic")}</span>
          </div>
        </div>
      </div>
      <div className="navi-item my-2">
        <div className="navi-link">
          <label className="checkbox checkbox-sm checkbox-single flex-shrink-0 mr-2">
            <input
              type="checkbox"
              name="providedByCustomer"
              onChange={handleToggleCheckBox}
              checked={additionalProductFilter.providedByCustomer}
            />
            <span />
          </label>
          <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
            <span className="navi-text font-weight-bolder">{i18n.t("warehouse:onlyDeliveredByCustomer")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFilter;
