import _ from "lodash";
import { BSON } from "realm-web";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ImageUploader from "react-images-upload";
import { toast } from "react-toastify";
import { ManufacturersDocument, ProductionLine } from "../../../model/manufacturers.types";
import dbManufacturerService from "../../../services/dbServices/dbManufacturerService";
import productionLineUtils from "../../../utils/productionLineUtils";
import userService from "../../../services/userService";
import manufacturerUtils from "../../../utils/manufacturerUtils";
import { ROLES } from "../../../utils/userdataUtils";
import authenticationService from "../../../services/authenticationService";
import { T_BLENDING, T_BLISTERING, T_BOTTLING, T_ENCAPSULATION, T_OTHER, T_TABLETING } from "./productionLineHelper";
import { DataContext } from "../../../context/dataContext";

interface AddProductionLineModalProps {
  onHide: () => void;
  selectedManufacturer: BSON.ObjectId | string | null;
  show: boolean;
  updateProductionLines: () => void;
  productionLine?: ProductionLine;
  manufacturer?: ManufacturersDocument;
  context: React.ContextType<typeof DataContext>;
}

interface AddProductionLineModalState {
  productionLineImage: string;
  productionLineName: string;
  capacity: string;
  type: string;
  selectedManufacturer: string;
  manufacturerLocked: boolean;
}

class ProductionLineModal extends Component<AddProductionLineModalProps, AddProductionLineModalState> {
  constructor(props: AddProductionLineModalProps) {
    super(props);
    const { context, manufacturer: man, productionLine } = props;
    const manufacturer = manufacturerUtils.checkCurrentUserManufacturer(context.manufacturers);
    this.state = {
      productionLineImage: productionLine ? productionLine.image : "",
      productionLineName: productionLine ? productionLine.name : "",
      capacity: productionLine ? productionLine.capacity.toString() : "0",
      type: productionLine ? productionLine.type : "capsule",
      selectedManufacturer: !!man
        ? man._id.toString()
        : manufacturer !== ""
        ? manufacturer
        : props.selectedManufacturer
        ? props.selectedManufacturer.toString()
        : "",
      manufacturerLocked: !!man || manufacturer !== ""
    };
  }

  componentDidUpdate(
    prevProps: Readonly<AddProductionLineModalProps>,
    prevState: Readonly<AddProductionLineModalState>,
    snapshot?: any
  ) {
    if (
      !_.isEqual(prevProps.productionLine, this.props.productionLine) ||
      !_.isEqual(prevProps.manufacturer, this.props.manufacturer) ||
      this.props.show !== prevProps.show
    ) {
      const { productionLine, manufacturer: man, selectedManufacturer, context } = this.props;
      const manufacturer = manufacturerUtils.checkCurrentUserManufacturer(context.manufacturers);
      this.setState({
        productionLineImage: productionLine ? productionLine.image : "",
        productionLineName: productionLine ? productionLine.name : "",
        capacity: productionLine ? productionLine.capacity.toString() : "0",
        type: productionLine ? productionLine.type : "capsule",
        selectedManufacturer: !!man
          ? man._id.toString()
          : manufacturer !== ""
          ? manufacturer
          : selectedManufacturer
          ? selectedManufacturer.toString()
          : "",
        manufacturerLocked: !!man || manufacturer !== ""
      });
    }
    if (
      !_.isEqual(prevProps.selectedManufacturer, this.props.selectedManufacturer) ||
      !_.isEqual(prevProps.context.manufacturers, this.props.context.manufacturers)
    ) {
      const manufacturer = manufacturerUtils.checkCurrentUserManufacturer(this.props.context.manufacturers);
      this.setState({
        selectedManufacturer:
          manufacturer !== ""
            ? manufacturer
            : this.props.selectedManufacturer
            ? this.props.selectedManufacturer.toString()
            : "",
        manufacturerLocked: manufacturer !== ""
      });
    }
  }

  /**
   * Submits the addition of a production line.
   */
  submitProductionLineAddition = async () => {
    const { updateProductionLines, productionLine } = this.props;
    const { productionLineImage, productionLineName, selectedManufacturer, capacity, type } = this.state;
    if (!this.isDataComplete()) return;
    const edited = !!productionLine;
    let res;
    const manufacturerId = new BSON.ObjectId(selectedManufacturer);
    if (edited)
      res = await dbManufacturerService.editProductionLine(
        productionLine!._id,
        productionLineName,
        productionLineImage,
        type,
        +capacity
      );
    else
      res = await dbManufacturerService.addProductionLine(manufacturerId, {
        _id: new BSON.ObjectId(),
        image: productionLineImage,
        name: productionLineName,
        lastUpdate: new Date(),
        capacity: +capacity,
        type: type
      });
    if (res) {
      toast.success(
        <b>
          <i className="fa fa-check mx-2" />
          {edited ? "Machine edited successfully" : "Machine added successfully"}
        </b>
      );
      // Only update data when the addition was successful
      this.props.onHide();
      updateProductionLines();
    } else {
      toast.error(
        <b>
          <i className="fa fa-exclamation mx-2" />
          {edited ? "Machine could not be edited" : "Machine could not be added"}
        </b>
      );
    }
  };

  isDataComplete = () => {
    const { productionLineName, selectedManufacturer, type, capacity } = this.state;
    return productionLineName.trim().length > 1 && selectedManufacturer.trim().length > 1 && !!type && +capacity > 0;
  };

  render() {
    const { context, show, productionLine, onHide } = this.props;
    const {
      productionLineImage,
      productionLineName,
      selectedManufacturer,
      type,
      capacity,
      manufacturerLocked
    } = this.state;
    const { manufacturers } = context;
    return (
      <Modal show={show} onHide={onHide} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{!!productionLine ? "Edit machine" : "Add new machine"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-3">
            <div className="col-3 text-dark align-self-center">Name</div>
            <div className="col-9">
              <input
                className="form-control"
                type="text"
                value={productionLineName}
                onChange={e => this.setState({ productionLineName: e.target.value })}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-3 text-dark align-self-center">Capacity/Week</div>
            <div className="col-9">
              <input
                className="form-control"
                type="number"
                min={0}
                value={capacity}
                onChange={e => this.setState({ capacity: Number(parseInt(e.target.value) || "0").toString() })}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-3 text-dark align-self-center">Type</div>
            <div className="col-9">
              <select className="form-control" value={type} onChange={e => this.setState({ type: e.target.value })}>
                <option value="" disabled>
                  Not set
                </option>
                {[T_ENCAPSULATION, T_BOTTLING, T_BLENDING, T_BLISTERING, T_TABLETING, T_OTHER].map(t => (
                  <option key={t} value={t}>
                    {_.upperFirst(t)}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-3 text-dark align-self-center">Location</div>
            <div className="col-9">
              <select
                className="form-control"
                value={selectedManufacturer}
                disabled={manufacturerLocked}
                onChange={manufacturerLocked ? undefined : e => this.setState({ selectedManufacturer: e.target.value })}
              >
                <option value="" disabled>
                  Not set
                </option>
                {manufacturers.map(m => {
                  return (
                    <option
                      key={m._id.toString()}
                      value={m._id.toString()}
                      disabled={
                        !(
                          userService.isAuthorizedForAction(ROLES.ADMIN) ||
                          manufacturerUtils.isEmployeeOfManufacturer(authenticationService.getUserDataID(), m)
                        )
                      }
                    >
                      {m.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-3 text-dark align-self-center">Image</div>
            <div className="col-9">
              <ImageUploader
                withIcon={false}
                withLabel={false}
                buttonText={"Upload image"}
                imgExtension={[".jpg", ".png", ".jpeg"]}
                maxFileSize={104857600}
                singleImage
                errorClass="px-3"
                fileContainerStyle={{
                  boxShadow: "None",
                  background: "None",
                  padding: "0",
                  margin: "0 0"
                }}
                buttonClassName="w-100"
                fileSizeError={"Image too large. Max 10 MB"}
                fileTypeError={"Image has wrong format. Only .jpg and .png are allowed"}
                onChange={files =>
                  productionLineUtils.handleImageUpload(files, productionLineName, productionLineImage =>
                    this.setState({ productionLineImage })
                  )
                }
              />
            </div>
          </div>
          {productionLineImage && (
            <div className="row mb-4">
              <div className="col-3" />
              <div className="col-9 text-center text-success h5">Image uploaded successfully</div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="float-right">
            <button className="btn btn-secondary mr-2" onClick={onHide}>
              Close
            </button>
            <button
              className={"btn btn-success font-weight-bold " + (!this.isDataComplete() && "disabled")}
              disabled={!this.isDataComplete()}
              onClick={this.submitProductionLineAddition}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ProductionLineModal;
