import React from "react";

interface HistoryBackButtonProps {
  history: any;
  text?: string;
}

const HistoryBackButton: React.FunctionComponent<HistoryBackButtonProps> = ({ history, text }) => {
  return (
    <div className="kt-portlet__head-toolbar">
      <div className="kt-portlet__head-wrapper">
        <button onClick={history.goBack} className="btn btn-clean kt-margin-r-10">
          <i className="la la-arrow-left" />
          <span className="kt-hidden-mobile">{text ?? "Back"}</span>
        </button>
      </div>
    </div>
  );
};

export default HistoryBackButton;
