import _ from "lodash";
import * as Realm from "realm-web";
import Select from "react-select";
import React, { PureComponent } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { PackagingPrice, PackagingsDocument, PackagingSupplier } from "../../model/packagings.types";
import { DataContext } from "../../context/dataContext";
import { PaginationState } from "../common/CustomTypes";
import packagingUtils, { PACKAGING_TYPES } from "../../utils/packagingUtils";
import baseUtils, { getComponentState } from "../../utils/baseUtils";
import dateUtils from "../../utils/dateUtils";
import SplashScreen from "../common/SplashScreen";
import Pagination, { paginate } from "../common/Pagination";
import { ManufacturerFilter, SearchBar } from "../listings/common/Filters";
import { ORDERORDERCOMMODITIES, PRODUCTION, PRODUCTIONQUEUE, WAITING } from "../../utils/orderUtils";
import PackagingStockOverview from "./PackagingStockOverview";
import manufacturerUtils from "../../utils/manufacturerUtils";
import { PackagingExtendedExport } from "./CustomTypes";
import accessUtils, { CREATELOCATIONS } from "../../utils/accessUtils";

interface PackagingsBaseListingProps extends RouteComponentProps<{}, {}, {}> {
  context: React.ContextType<typeof DataContext>;
}

interface PackagingsBaseListingState extends PaginationState {
  packagingStock: Array<PackagingExtended>;
  search: string;
  filter: { value: string; label: string };
  sortingField: { value: string; label: string };
  sortingOrder: { value: string; label: string };
  orderState: "" | { value: string; label: string };
  color: string;
  manufacturer: "" | { value: string; label: string };
  manufacturerLocked: boolean;
}

interface PackagingExtended {
  packaging: PackagingsDocument;
  fastestPrice: undefined | { price: PackagingPrice; _id: Realm.BSON.ObjectId };
  lowestPrice: { supplier: PackagingSupplier | undefined; price: number | undefined; moq: number | undefined };
  lastUpdate: Date | null;
  daysAgo: number | null;
  generalUsage: {
    stock: number;
    usage: number;
    required: { orders: number; amount: number };
    ordered: { orders: number; amount: number };
    available: number;
  };
  manufacturerStock: {
    [manufacturer: string]: {
      mStock: number;
      mUsage: number;
      mOrdered: { orders: number; amount: number };
      mRequired: { orders: number; amount: number };
      mAvailable: number;
    };
  };
}

const CONSTRUCTORNAME = "PackagingsBaseListing";

class PackagingsBaseListing extends PureComponent<PackagingsBaseListingProps, PackagingsBaseListingState> {
  constructor(props: PackagingsBaseListingProps, context: React.ContextType<typeof DataContext>) {
    super(props, context);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, CONSTRUCTORNAME);
    const packagingData = this.calculatePackagingStatistics();
    if (state) {
      this.setState({ ...state, packagingStock: packagingData });
    } else {
      this.setState(this.getDefaultState(), () => this.setState({ packagingStock: packagingData }));
    }
  }

  componentDidUpdate(
    prevProps: Readonly<PackagingsBaseListingProps>,
    prevState: Readonly<PackagingsBaseListingState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.context.orders, this.props.context.orders)) {
      this.setState({ packagingStock: this.calculatePackagingStatistics() });
    }
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(CONSTRUCTORNAME, _.omit(this.state, "packagingStock"));
  }

  handleReset = () => this.setState(this.getDefaultState(true));
  handleSelectChange = (name: string, entry: "" | { value: string; label: string }) => {
    // @ts-ignore
    this.setState({ [name]: entry, currentPage: 1 });
  };
  handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ search: e.target.value, currentPage: 1 });
  handleFilterChange = (name: string, entry: { value: string; label: string } | "") =>
    //@ts-ignore
    this.setState({ [name]: entry });

  getDefaultState = (reset?: boolean) => {
    const manufacturer = manufacturerUtils.checkCurrentUserManufacturerObject(this.props.context.manufacturers);
    const packagingStock = reset ? this.state.packagingStock : ([] as Array<PackagingExtended>);
    return {
      currentPage: 1,
      filter: { value: "", label: "All Packaging" },
      sortingField: { value: "default", label: "Default" },
      sortingOrder: { value: "descending", label: "Descending" },
      manufacturer,
      orderState: "",
      pageSize: 15,
      search: "",
      packagingStock: packagingStock,
      manufacturerLocked: manufacturer != ""
    } as PackagingsBaseListingState;
  };

  /**
   * Get the fastest delivery price for a packaging
   * @param packaging a packaging document
   * @returns {_id: BSON.ObjectId , price: PackagingPrice} object with id of the supplier and a commodity price object
   */
  getFastestDeliverySupplier(packaging: PackagingsDocument) {
    const packagingSuppliers = packaging.suppliers;
    // Collect all prices
    let allPrices: Array<{ id: Realm.BSON.ObjectId; price: PackagingPrice }> = [];
    let lowestMOQ: number;
    packagingSuppliers.forEach(cSupplier => {
      cSupplier.prices.forEach(sPrice => {
        const moq = sPrice.moq;
        if (!lowestMOQ || moq < lowestMOQ) lowestMOQ = moq;
        allPrices.push({ id: cSupplier._id as Realm.BSON.ObjectId, price: sPrice });
      });
    });
    if (allPrices.length === 0) {
      return;
    }
    allPrices.sort((a, b) => a.price.deliverytime - b.price.deliverytime);
    const lowestPrice = allPrices[0];
    return { _id: lowestPrice.id, price: lowestPrice.price };
  }

  /**
   * Calculates the currently stocked amounts of packaging + necessary information
   * @returns { Array<PackagingExtended> } Packaging extended with additional statistics
   */
  calculatePackagingStatistics = () => {
    const { context } = this.props;
    const { packagings, packagingStock, packagingOrders, orders } = context;
    const packagingMap: { [packagingId: string]: PackagingExtended } = {};

    const getPackaging = (id: string | Realm.BSON.ObjectId, packagingParam?: PackagingsDocument) => {
      if (!(id.toString() in packagingMap)) {
        const packaging = packagingParam ? packagingParam : baseUtils.getDocFromCollection(packagings, id);
        if (!packaging) return;
        const lastUpdate = packagingUtils.findLatestPriceUpdate(packaging.suppliers);
        packagingMap[id.toString()] = {
          packaging,
          fastestPrice: this.getFastestDeliverySupplier(packaging),
          lowestPrice: packagingUtils.getLowestPriceMOQ(packaging),
          lastUpdate,
          daysAgo: lastUpdate ? dateUtils.getDaysBetween(lastUpdate, new Date()) : null,
          generalUsage: {
            stock: 0,
            usage: 0,
            available: 0,
            required: { orders: 0, amount: 0 },
            ordered: { orders: 0, amount: 0 }
          },
          manufacturerStock: {}
        };
      }
      return packagingMap[id.toString()];
    };

    // Get stock data
    for (let i = 0; i < packagingStock.length; i++) {
      const pStock = packagingStock[i];
      const packaging = getPackaging(pStock.packaging);
      if (!packaging) continue;
      // Update general values
      packaging.generalUsage.stock += pStock.amount;
      packaging.generalUsage.available += pStock.amount;
      // Update manufacturer specific values
      if (pStock.location.toString() in packaging.manufacturerStock) {
        packaging.manufacturerStock[pStock.location.toString()].mStock += pStock.amount;
        packaging.manufacturerStock[pStock.location.toString()].mAvailable += pStock.amount;
      } else {
        // Initialize if not available yet
        packaging.manufacturerStock[pStock.location.toString()] = {
          mStock: pStock.amount,
          mUsage: 0,
          mAvailable: pStock.amount,
          mRequired: { orders: 0, amount: 0 },
          mOrdered: { orders: 0, amount: 0 }
        };
      }
    }

    // Get packaging order data
    for (let i = 0; i < packagingOrders.length; i++) {
      const pOrder = packagingOrders[i];
      const packaging = getPackaging(pOrder.packaging);
      if (!packaging) continue;
      // Update general values
      packaging.generalUsage.ordered.orders += 1;
      packaging.generalUsage.ordered.amount += pOrder.orderQuantity;
      packaging.generalUsage.available += pOrder.orderQuantity;
      // Update manufacturer specific values
      if (pOrder.destination.toString() in packaging.manufacturerStock) {
        packaging.manufacturerStock[pOrder.destination.toString()].mOrdered.orders += 1;
        packaging.manufacturerStock[pOrder.destination.toString()].mOrdered.amount += pOrder.orderQuantity;
        packaging.manufacturerStock[pOrder.destination.toString()].mAvailable += pOrder.orderQuantity;
      } else {
        // Initialize if not available yet
        packaging.manufacturerStock[pOrder.destination.toString()] = {
          mStock: 0,
          mUsage: 0,
          mAvailable: pOrder.orderQuantity,
          mRequired: { orders: 0, amount: 0 },
          mOrdered: { orders: pOrder.orderQuantity, amount: 1 }
        };
      }
    }

    // Get order data
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      // Ignore offers and invalid orders
      if (order.calculations.length !== 1) continue;
      const orderAge = dateUtils.getDaysBetween(order.createdOn, new Date());
      const isActive = [ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION].includes(order.state);
      for (let j = 0; j < order.calculations[0].packagings.length; j++) {
        const pPrice = order.calculations[0].packagings[j];
        const packaging = getPackaging(pPrice._id);
        if (!packaging) continue;
        // Update general values
        const amount = pPrice.amount * (1 + pPrice.buffer / 100) * order.calculations[0].units;
        // YTY usage
        if (orderAge <= 365) packaging.generalUsage.usage += amount;
        // If order still needs to be produced add required amount and subtract from available amount
        if (isActive) {
          packaging.generalUsage.required.orders += 1;
          packaging.generalUsage.required.amount += amount;
          packaging.generalUsage.available -= amount;
        }
        // Update manufacturer specific values
        const location = order.settings.filler || order.settings.manufacturer.toString();
        if (location in packaging.manufacturerStock) {
          // YTY usage
          if (orderAge <= 365) packaging.manufacturerStock[location].mUsage += amount;
          // If order still needs to be produced add required amount and subtract from available amount
          if (isActive) {
            packaging.manufacturerStock[location].mRequired.orders += 1;
            packaging.manufacturerStock[location].mRequired.amount += amount;
            packaging.manufacturerStock[location].mAvailable -= amount;
          }
        } else {
          // Initialize if not available yet
          packaging.manufacturerStock[location] = {
            mStock: 0,
            mUsage: orderAge > 365 ? 0 : amount,
            mAvailable: isActive ? -amount : 0,
            mRequired: { orders: isActive ? 1 : 0, amount: isActive ? amount : 0 },
            mOrdered: { orders: 0, amount: 0 }
          };
        }
      }
    }

    // Complete list with unhandled packaging
    for (let i = 0; i < packagings.length; i++) {
      const packaging = packagings[i];
      getPackaging(packaging._id, packaging);
    }

    return Object.values(packagingMap);
  };

  /**
   * Sort the given packagings by set field and order.
   * @param c1: PackagingsExtended
   * @param c2: PackagingsExtended
   * @returns { number } Represents the result of the sorting
   */
  sortPackagings = (c1: PackagingExtendedExport, c2: PackagingExtendedExport) => {
    const { sortingField, sortingOrder } = this.state;
    const order = sortingOrder.value === "descending" ? 1 : -1;
    switch (sortingField.value) {
      case "type":
        return order * c1.packaging.packaging_type.localeCompare(c2.packaging.packaging_type);
      case "usage":
        return order * (c2.usage.usage - c1.usage.usage);
      case "lastUpdate":
        return order * ((c2.lastUpdate ? c2.lastUpdate.getTime() : 0) - (c1.lastUpdate ? c1.lastUpdate.getTime() : 0));
      default:
        return order;
    }
  };

  /**
   *  Filters the packagings by the selected filters.
   * @returns {Array <PackagingExtendedExport>} filtered packaging list
   */
  filterOrderState = () => {
    const { orderState, filter, color, search, packagingStock, manufacturer } = this.state;
    let pStock = packagingStock;
    pStock = pStock.filter(p => {
      let retVal = true;
      if (retVal && filter.value) retVal = filter.value === p.packaging.packaging_type.toString();
      if (retVal && color) {
        const type = p.packaging.packaging_type;
        switch (type) {
          case "bottle":
            retVal = color === p.packaging.packaging_color;
            break;
          case "lid":
            retVal = color === p.packaging.lid_color;
            break;
          case "bag":
            retVal = color === p.packaging.bag_color;
            break;
          default:
            retVal = false;
        }
      }
      if (manufacturer && retVal) {
        let ms = p.manufacturerStock[manufacturer.value.toString()];
        if (!ms) return false;
        if (retVal && orderState && orderState.value === "orderRequired")
          retVal = ms.mRequired.amount > ms.mStock + ms.mOrdered.amount;
        if (retVal && orderState && orderState.value === "ordered") retVal = ms.mOrdered.amount > 0;
        if (retVal && orderState && orderState.value === "stock") retVal = ms.mStock > 0;
        return retVal;
      }
      if (!manufacturer && retVal) {
        if (retVal && orderState && orderState.value === "orderRequired")
          retVal = p.generalUsage.required.amount > p.generalUsage.stock + p.generalUsage.ordered.amount;
        if (retVal && orderState && orderState.value === "ordered") retVal = p.generalUsage.ordered.amount > 0;
        if (retVal && orderState && orderState.value === "stock") retVal = p.generalUsage.stock > 0;
        return retVal;
      }
    });
    if (search !== "")
      pStock = baseUtils.doFuseSearch(pStock, search, [""], {
        getFn: (obj: PackagingExtended) => {
          return packagingUtils.concatPackagingInfo(obj.packaging);
        }
      });

    if (!manufacturer) {
      return pStock.map(p => {
        return {
          packaging: p.packaging,
          fastestPrice: p.fastestPrice,
          lowestPrice: p.lowestPrice,
          lastUpdate: p.lastUpdate,
          daysAgo: p.daysAgo,
          usage: {
            usage: p.generalUsage.usage,
            required: p.generalUsage.required,
            ordered: p.generalUsage.ordered
          },
          totalStock: p.generalUsage.stock,
          available: p.generalUsage.available
        };
      });
    } else {
      return pStock.map(p => {
        let ms = p.manufacturerStock[manufacturer.value.toString()];
        if (!ms)
          return {
            packaging: p.packaging,
            fastestPrice: p.fastestPrice,
            lowestPrice: p.lowestPrice,
            lastUpdate: p.lastUpdate,
            daysAgo: p.daysAgo,
            usage: {
              usage: 0,
              required: { orders: 0, amount: 0 },
              ordered: { orders: 0, amount: 0 }
            },
            totalStock: 0,
            available: 0
          };
        return {
          packaging: p.packaging,
          fastestPrice: p.fastestPrice,
          lowestPrice: p.lowestPrice,
          lastUpdate: p.lastUpdate,
          daysAgo: p.daysAgo,
          usage: {
            usage: ms.mUsage,
            required: ms.mRequired,
            ordered: ms.mOrdered
          },
          totalStock: ms.mStock,
          available: ms.mAvailable
        };
      });
    }
  };

  render() {
    const { context } = this.props;
    const { manufacturers } = context;
    const {
      currentPage,
      pageSize,
      search,
      filter,
      color,
      orderState,
      manufacturer,
      sortingOrder,
      sortingField,
      manufacturerLocked
    } = this.state;
    const filteredPackaging = this.filterOrderState().sort((c1, c2) => this.sortPackagings(c1, c2));
    const typeFilter = PACKAGING_TYPES.find(p => p.value.toString() === filter.value)!;
    const packagingList = paginate(filteredPackaging, currentPage, pageSize);
    const colors = packagingUtils.getColors(packagingList.map(p => p.packaging));
    return (
      <>
        {filteredPackaging.length < 0 ? (
          <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
        ) : (
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              <div className="kt-portlet__head-label">
                <span className="kt-portlet__head-icon">
                  <i className="kt-font-brand fa fa-avatar" />
                </span>
                <h3 className="kt-portlet__head-title">Packagings</h3>
                <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
                  Reset
                </button>
              </div>
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">
                  <span onClick={this.props.history.goBack} className="btn btn-clean kt-margin-r-10">
                    <i className="la la-arrow-left" />
                    <span className="kt-hidden-mobile">Back</span>
                  </span>
                  {accessUtils.canCreateData(CREATELOCATIONS.PACKAGING) && (
                    <button type="button" className="btn btn-brand btn-icon-sm">
                      <i className="flaticon2-plus" />
                      <Link to="/create-packaging">
                        <span style={{ color: "white" }}>Add New</span>
                      </Link>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="kt-form kt-form--label-right kt-margin-b-10">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <SearchBar
                        onSearch={this.handleSearchChange}
                        search={search}
                        additionalSizeClasses="col-6 col-xl-2 mb-4 kt-margin-b-20-tablet-and-mobile"
                      />
                      <div className="col-6 col-xl-2 kt-margin-b-20-tablet-and-mobile">
                        <Select
                          className="select-default"
                          isClearable={true}
                          options={[
                            { value: "", label: "All States" },
                            { value: "orderRequired", label: "Order Required" },
                            { value: "ordered", label: "Ordered" },
                            { value: "stock", label: "In Stock" }
                          ]}
                          value={orderState ? orderState : { value: "", label: "All States" }}
                          onChange={(value: any) => this.handleSelectChange("orderState", value || "")}
                        />
                      </div>
                      <div className="col-6 col-xl-2 mb-4 kt-margin-b-20-tablet-and-mobile">
                        <Select
                          className="select-default"
                          isClearable={true}
                          options={PACKAGING_TYPES.map(p => {
                            return {
                              value: p.value,
                              label: p.label
                            };
                          })}
                          value={{
                            value: typeFilter.value,
                            label: typeFilter.value ? typeFilter.label : "All Packaging"
                          }}
                          onChange={(value: any) =>
                            this.handleFilterChange("filter", value || { value: "", label: "All Packaging" })
                          }
                        />
                      </div>
                      <ManufacturerFilter
                        noLabel={true}
                        setZIndex={false}
                        additionalSizeClasses={"select-default col-6 col-xl-2 "}
                        manufacturer={manufacturer}
                        manufacturers={manufacturers}
                        manufacturerLocked={manufacturerLocked}
                        onFilterSelect={this.handleSelectChange}
                      />
                      <div className="col-6 col-xl-2 mb-4 kt-margin-b-20-tablet-and-mobile">
                        <Select
                          className="select-default"
                          isClearable={true}
                          onChange={(value: any) => this.handleFilterChange("color", value ? value.value : "")}
                          value={
                            color
                              ? {
                                  value: color.toString(),
                                  label:
                                    color.toString() === "color" ? "Special colors" : _.upperFirst(color.toString())
                                }
                              : { value: "", label: "All Colors" }
                          }
                          options={colors.map(color => {
                            return {
                              value: color.toString(),
                              label: color.toString() === "color" ? "Special colors" : _.upperFirst(color.toString())
                            };
                          })}
                        />
                      </div>
                      <div className="col-6 col-xl-2 mb-4 kt-margin-b-20-tablet-and-mobile">
                        <Select
                          className="select-default"
                          isClearable={true}
                          options={[
                            { value: "default", label: "Default" },
                            { value: "type", label: "Type" },
                            { value: "usage", label: "Usage" },
                            { value: "lastUpdate", label: "Last Update" }
                          ]}
                          value={sortingField}
                          onChange={(value: any) =>
                            this.handleSelectChange("sortingField", value || { value: "default", label: "Default" })
                          }
                        />
                      </div>
                      <div className="col-6 col-xl-2 mb-4 kt-margin-b-20-tablet-and-mobile">
                        <Select
                          className="select-default"
                          options={[
                            { value: "descending", label: "Descending" },
                            { value: "ascending", label: "Ascending" }
                          ]}
                          value={sortingOrder ? sortingOrder : { value: "descending", label: "Descending" }}
                          onChange={(value: any) =>
                            this.handleSelectChange(
                              "sortingOrder",
                              value || { value: "descending", label: "Descending" }
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="kt-portlet__body kt-portlet__body--fit">
              <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive px-4">
                <PackagingStockOverview context={context} packagingsExtended={packagingList} />
                <div className="kt-datatable__pager kt-datatable--paging-loaded justify-content-center">
                  <Pagination
                    itemsCount={filteredPackaging.length}
                    pageSize={pageSize}
                    onPageChange={currentPage => this.setState({ currentPage })}
                    currentPage={currentPage}
                    onPageSizeChange={pageSize => this.setState({ pageSize, currentPage: 1 })}
                    baseSize={15}
                    additionalPageSizeClasses={"ml-2"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default withRouter(PackagingsBaseListing);
