import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { CompaniesDocument } from "../../model/companies.types";
import { DataContext } from "../../context/dataContext";
import PersonWidget from "../common/PersonWidget";

interface CompanyUsersProps extends RouteComponentProps<{}, {}, {}> {
  company: CompaniesDocument;
  context: React.ContextType<typeof DataContext>;
}

const CompanyUsers: React.FunctionComponent<CompanyUsersProps> = ({ company, context, history }) => {
  const users = context.userdata.filter(
    user => user.company_id && user.company_id.toString() === company._id.toString()
  );
  return (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
      <table className="kt-datatable__table d-table ">
        <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
          <tr className="kt-datatable__row d-table-row">
            <th className="kt-datatable__cell d-table-cell" style={{ width: "25%" }}>
              <span>Person</span>
            </th>
            <th className="kt-datatable__cell d-table-cell" style={{ width: "17.5%" }}>
              <span>Email</span>
            </th>
            <th className="kt-datatable__cell d-table-cell" style={{ width: "17.5%" }}>
              <span>Telephone</span>
            </th>
            <th className="kt-datatable__cell d-table-cell" style={{ width: "20%" }}>
              <span>Roles</span>
            </th>
            <th className="kt-datatable__cell d-table-cell text-right" style={{ width: "20%" }}>
              <span>SupplyCentral Access</span>
            </th>
          </tr>
        </thead>
        <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
          {users.map(user => (
            <tr
              key={user._id.toString()}
              className="kt-datatable__row d-table-row table-hover"
              onClick={() => history.push("/user/" + user._id.toString())}
            >
              <td className="kt-datatable__cell d-table-cell">
                <PersonWidget person={user} />
              </td>
              <td className="kt-datatable__cell d-table-cell">
                <div>
                  <span className="kt-badge kt-badge--inline kt-badge--pill kt-badge--light kt-font-bold">
                    {user.email[0]}
                  </span>
                </div>
              </td>
              <td className="kt-datatable__cell d-table-cell">
                <div>
                  {user.telephone[0] && (
                    <span className="kt-badge kt-badge--inline kt-badge--pill kt-badge--light kt-font-bold">
                      {user.telephone[0]}
                    </span>
                  )}
                </div>
              </td>
              <td className="kt-datatable__cell d-table-cell">
                <div>
                  {user.role.map(r => (
                    <span className="kt-badge kt-badge--inline kt-badge--pill kt-badge--info kt-font-bold">{r}</span>
                  ))}
                </div>
              </td>
              <td className="kt-datatable__cell d-table-cell text-right">
                {user.company_id !== "internal" ? (user.user_id ? "Yes" : "No") : "-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompanyUsers;
