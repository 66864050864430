import { BSON } from "realm-web";
import _ from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import ReplaceMaterialModal from "./ReplaceMaterialModal";
import { CustomOrder, OrderOptionInformation } from "../CustomTypes";
import { T_CUSTOM, T_SERVICE, T_SOFTGEL } from "../OrderHelper";
import dbService, { UpdateAction, ORDERS } from "../../../services/dbService";
import userService from "../../../services/userService";
import accessUtils, { ACTIONS } from "../../../utils/accessUtils";
import calculationUtils from "../../../utils/calculationUtils";
import orderCalculationUtils from "../../../utils/orderCalculationUtils";
import orderUtils from "../../../utils/orderUtils";
import { ROLES } from "../../../utils/userdataUtils";
import RemoveMaterialModal from "./RemoveMaterialModal";
import { T_COMMODITYFROMCUSTOMER, T_COMMODITYFROMSTOCK } from "../../../utils/timelineUtils";
import commodityUtils from "../../../utils/commodityUtils";
import { DataContext } from "../../../context/dataContext";
import { round } from "../../../utils/baseUtils";

interface OrderOptionsModalProps {
  order: CustomOrder;
  selectedCommodity: OrderOptionInformation;
  context: React.ContextType<typeof DataContext>;
  onClose: () => void;
}

interface OrderOptionsModalState {
  step: null | "customer" | "stock";
  stockPrice: string;
  customerAmount: string;
}

class OrderOptionsModal extends PureComponent<OrderOptionsModalProps, OrderOptionsModalState> {
  totalAmount: number | null = null;
  totalAmountUnit: string | null = null;
  constructor(props: OrderOptionsModalProps) {
    super(props);
    this.state = {
      step: null,
      stockPrice: "0",
      customerAmount: "0"
    };
  }

  componentDidMount() {
    this.initializeData();
  }

  componentDidUpdate(
    prevProps: Readonly<OrderOptionsModalProps>,
    prevState: Readonly<OrderOptionsModalState>,
    snapshot?: any
  ) {
    if (
      !_.isEqual(prevProps.order, this.props.order) ||
      !_.isEqual(prevProps.selectedCommodity, this.props.selectedCommodity)
    )
      this.initializeData();
  }

  /**
   * Initialize data
   */
  initializeData = () => {
    const { selectedCommodity, order } = this.props;
    const { commodity, price } = selectedCommodity;
    const type = order.settings.type;
    const manufacturer = order.settings.manufacturer;
    const averagePrice = calculationUtils.getAverageStockPrice(commodity.stock, manufacturer);
    let totalAmount = orderUtils.getTotalAmountWithBuffer(
      +order.settings.perUnit,
      order.calculations[0].units,
      price.amount,
      price.buffer,
      type
    );
    if ([T_CUSTOM, T_SOFTGEL].includes(type)) {
      totalAmount = totalAmount / 1000;
      this.totalAmount = totalAmount;
      this.totalAmountUnit = " tsd.";
    } else if (![T_SERVICE].includes(type)) {
      totalAmount = Math.round((totalAmount / (1000 * 1000)) * 1000000) / 1000000;
      this.totalAmount = totalAmount;
      this.totalAmountUnit = "kg";
    } else {
      this.totalAmount = totalAmount;
      this.totalAmountUnit = "pcs.";
    }
    this.setState({
      stockPrice: (Math.round(averagePrice * 100) / 100).toString(),
      customerAmount: totalAmount.toString()
    });
  };

  handleStep = (step: "customer" | "stock") => this.setState({ step });

  handleBack = () => this.setState({ step: null });

  handleStockValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove leading zeros
    let value = e.target.value.replaceAll(/^0+/g, "0");
    if (!value.includes(".")) value = Number(value).toString();
    this.setState({ stockPrice: value });
  };
  handleCustomerAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove leading zeros
    let value = e.target.value.replaceAll(/^0+/g, "0");
    if (!value.includes(".")) value = Number(value).toString();
    this.setState({ customerAmount: value });
  };

  handleUpdateOrder = async () => {
    if (!this.totalAmount) return;
    const { order, selectedCommodity, onClose } = this.props;
    const price = _.cloneDeep(selectedCommodity.price);
    const oldPrice = selectedCommodity.price;
    const { step, stockPrice, customerAmount } = this.state;
    const commodityPrice = step === "stock" ? +stockPrice : 0;
    const orderQuantity = step === "customer" ? +customerAmount : this.totalAmount;
    const supplierType = step === "stock" ? "ownstock" : "customer";
    const userId = userService.getUserId();
    const currentDate = new Date();
    price.ordered = step === "stock" ? currentDate : null;
    price.userOrdered = step === "stock" ? userId : null;
    price.delivered = step === "stock" ? currentDate : null;
    price.userDelivered = step === "stock" ? userId : null;
    price.eta = step === "stock" ? currentDate : price.eta;
    price.orderquantity = orderQuantity;
    price.price = commodityPrice;
    price.totalprice = commodityPrice * orderQuantity;
    price.supplier = supplierType;
    const newCalculationInfo = orderCalculationUtils.getCalculationInfoAfterPriceChange(order, oldPrice, price);
    const newState = orderUtils.checkOrderStateAfterPriceUpdate(order, price);
    try {
      const timelineEntry = {
        id: new BSON.ObjectId(),
        type: step === "stock" ? T_COMMODITYFROMSTOCK : T_COMMODITYFROMCUSTOMER,
        date: new Date(),
        person: userService.getUserId(),
        commodity: selectedCommodity.commodity._id
      };
      const actions: Array<UpdateAction> = [
        {
          collection: ORDERS,
          filter: { _id: order._id },
          update: {
            "calculations.0.prices.$[p].ordered": price.ordered,
            "calculations.0.prices.$[p].userOrdered": price.userOrdered,
            "calculations.0.prices.$[p].delivered": price.delivered,
            "calculations.0.prices.$[p].userDelivered": price.userDelivered,
            "calculations.0.prices.$[p].eta": price.eta,
            "calculations.0.prices.$[p].orderquantity": price.orderquantity,
            "calculations.0.prices.$[p].price": price.price,
            "calculations.0.prices.$[p].totalprice": price.totalprice,
            "calculations.0.prices.$[p].supplier": price.supplier,
            "calculations.0.prices.$[p].purchasePrice": null,
            "calculations.0.prices.$[p].purchaseCurrency": "",
            "calculations.0.prices.$[p].incoterm": "",
            "calculations.0.info": newCalculationInfo,
            state: newState ? newState : order.state
          },
          push: {
            timeline: timelineEntry
          },
          arrayFilters: [{ "p._id": price._id }]
        }
      ];
      const result = await dbService.updatesAsTransaction(actions);
      if (result) {
        toast.success("Commodity successfully updated");
        onClose();
      } else toast.error("Commodity update failed");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    }
  };

  hasSupplierType = (type?: string) => {
    const { supplier } = this.props.selectedCommodity;
    if (type === "stock")
      return typeof supplier === "string" ? ["ownstock", "accumulatedstock"].includes(supplier) : false;
    else if (type === "customer") return supplier === "customer";
    else return typeof supplier !== "string";
  };

  render() {
    const { selectedCommodity, onClose, order, context } = this.props;
    const { step, stockPrice, customerAmount } = this.state;
    const { price, commodity } = selectedCommodity;
    const manufacturerId = order.settings.manufacturer._id.toString();
    // check if stock at manufacturer is available and larger than the required amount of the commodity
    const stockValues = commodityUtils.getStockValuesForLocation(commodity, manufacturerId, context, order);
    const stockAvailable =
      stockValues.stock + stockValues.ordered - stockValues.reserved - stockValues.emOrdered >= this.totalAmount!;
    // flag if commodity is ordered and a commodity order exists for the order
    const commodityOrderExists =
      !!price.ordered &&
      !!commodity.orders &&
      commodity.orders.some(o => o.orders.some((o2: any) => o2.toString() === order._id.toString()));
    const canOrder = accessUtils.canPerformAction(ACTIONS.ORDERCOMMODITYORDER);
    return (
      <Modal
        show={!!selectedCommodity}
        onHide={onClose}
        size={"sm"}
        centered
        name={"orderOptionsModal"}
        backdropClassName={"customBackdrop"}
        style={{ zIndex: 950 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Commodity order options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {commodityOrderExists && !price.delivered ? (
            <span className="text-danger kt-font-bold mb-5 mt-2">
              <u>Commodity already ordered!</u>
              <br />
              <br />
              You are not able to change the corresponding order options. Please mind that already existing commodity
              orders cannot be changed by changing order options for this order.
              <br />
              <br />
            </span>
          ) : (
            <>
              {!commodityOrderExists && price.ordered && (
                <span className="text-warning kt-font-bold mb-5 mt-2">
                  <u>Commodity already ordered!</u>
                  <br />
                  <br />
                  Since no commodity order exists you are still able to change the corresponding order options. Please
                  keep in mind that corresponding order data may be overwritten.
                  <br />
                  This feature should be used with caution.
                  <br />
                  <br />
                </span>
              )}
              {step === null && (
                <>
                  <button
                    className={
                      "btn mb-2 d-block " +
                      (this.hasSupplierType("stock") ? "btn-success" : "btn-secondary") +
                      (stockAvailable ? "" : " disabled")
                    }
                    onClick={() => this.handleStep("stock")}
                    style={{ width: "100%" }}
                    disabled={!stockAvailable}
                    title={!stockAvailable ? "Not enough stock available" : ""}
                  >
                    Use Stock
                  </button>
                  <button
                    className={
                      "btn mb-2 d-block " + (this.hasSupplierType("customer") ? "btn-success" : "btn-secondary")
                    }
                    onClick={() => this.handleStep("customer")}
                    style={{ width: "100%" }}
                  >
                    Delivered by Customer
                  </button>
                  {canOrder ? (
                    <Link
                      to={"/commodity/" + commodity._id.toString() + "/" + order._id.toString()}
                      className={"btn mb-2 d-block " + (this.hasSupplierType() ? "btn-success" : "btn-secondary")}
                      style={{ width: "100%" }}
                    >
                      Regular Order
                    </Link>
                  ) : (
                    <button
                      className={
                        "btn mb-2 d-block disabled w-100 " +
                        (this.hasSupplierType("customer") ? "btn-success" : "btn-secondary")
                      }
                    >
                      Regular Order
                    </button>
                  )}
                  {order.settings.type !== "service" && userService.hasRole(ROLES.PROCUREMENT) && (
                    <>
                      <ReplaceMaterialModal
                        material={commodity}
                        order={order}
                        type="commodity"
                        buttonCSSClasses="btn mb-2 d-block btn-secondary w-100"
                        callback={onClose}
                      />
                      <RemoveMaterialModal
                        material={commodity}
                        order={order}
                        type="commodity"
                        buttonCSSClasses="btn mb-2 d-block btn-secondary w-100"
                        successCallback={onClose}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
          {step === "stock" && (
            <>
              <h5 className="kt-font-dark">Stock Overview:</h5>
              <div className="kt-font-dark">
                Current stock: {round(stockValues.stock)} {this.totalAmountUnit}
              </div>
              <div className="kt-font-dark">
                Ordered: {round(stockValues.ordered)} {this.totalAmountUnit}
              </div>
              <div className="kt-font-dark">
                Reserved: {round(stockValues.reserved + stockValues.emOrdered)} {this.totalAmountUnit}
              </div>
              <div className="kt-font-dark">
                Available:{" "}
                <span className={stockAvailable ? "text-success" : "text-danger"}>
                  <b>
                    {round(stockValues.available)} {this.totalAmountUnit}
                  </b>
                </span>
              </div>
              <div className="kt-font-dark">
                Required:{" "}
                <span className={stockAvailable ? "text-success" : "text-danger"}>
                  <b>
                    {round(this.totalAmount || 0)} {this.totalAmountUnit}
                  </b>
                </span>
              </div>
              <br />
              <br />
              <span className="kt-font-dark mt-2">If stock is used, a hypothetical purchase price is required.</span>
              <br />
              <br />
              <span className="text-success kt-font-bold mb-2">
                Since there is a stock for this commodity, the average price of the batches is used.
              </span>
              <br />
              <br />
              <div className="input-group mb-2 mt-2" style={{ width: "100%" }}>
                <input
                  type="number"
                  className="form-control d-block"
                  value={stockPrice}
                  min={0}
                  onChange={this.handleStockValue}
                />
                <div className="input-group-append">
                  <span className="input-group-text">€/{this.totalAmountUnit}</span>
                </div>
              </div>
            </>
          )}
          {step === "customer" && (
            <>
              <div className="kt-font-dark">
                Current customer stock:{" "}
                <b>
                  {round(stockValues.customerStock)} {this.totalAmountUnit}
                </b>
              </div>
              <br />
              <span className="kt-font-dark mt-2">
                At least <b>{this.totalAmount! + this.totalAmountUnit!}</b> are required for this order. <br />
                What quantity will be delivered? If already delivered enter the delivered amount.
              </span>
              <br />
              <br />
              <div className="input-group mb-2 mt-2" style={{ width: "100%" }}>
                <input
                  type="number"
                  className="form-control"
                  value={customerAmount}
                  onChange={this.handleCustomerAmount}
                />
                <div className="input-group-append">
                  <span className="input-group-text">{this.totalAmountUnit}</span>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {step === null && (
            <button className="btn btn-secondary btn-sm btn-upper" onClick={onClose}>
              Close
            </button>
          )}
          {step !== null && (
            <button className="btn btn-secondary btn-sm btn-upper" onClick={this.handleBack}>
              Back
            </button>
          )}
          {(step === "stock" || step === "customer") && (
            <button className="btn btn-primary btn-sm" onClick={this.handleUpdateOrder}>
              Update Order
            </button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default OrderOptionsModal;
