import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { RouteComponentProps } from "react-router-dom";
import React from "react";
import { ViewType } from "./CalendarView";
import HistoryBackButton from "../../common/HistoryBackButton";
import { ManufacturerFilter, SearchBar } from "../../common/Filters";
import { ManufacturersDocument } from "../../../../model/manufacturers.types";

interface CalendarViewHeaderProps extends RouteComponentProps {
  type: { value: string; label: string };
  search: string;
  status: { value: string; label: string };
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectChange: (name: string, entry: "" | { value: string; label: string }) => void;
  calendarType: ViewType;
  manufacturer?: "" | { value: string; label: string };
  manufactures?: Array<ManufacturersDocument>;
  manufacturerLocked?: boolean;
}

const CalendarViewHeader: React.FunctionComponent<CalendarViewHeaderProps> = ({
  manufacturer,
  manufactures,
  manufacturerLocked,
  search,
  status,
  history,
  type,
  calendarType,
  onSearch,
  onSelectChange
}) => {
  return (
    <div>
      <div className="kt-portlet__head kt-portlet__head--lg">
        <div className="kt-portlet__head-label">
          <span className="kt-portlet__head-icon">
            <i className="kt-font-brand flaticon-calendar-with-a-clock-time-tools" />
          </span>
          <h3 className="kt-portlet__head-title">Delivery Calendar</h3>
        </div>
        {calendarType === ViewType.FULL_VIEW && <HistoryBackButton history={history} />}
      </div>
      <div className="kt-portlet__body pb-0">
        <div className="kt-form kt-form--label-right kt-margin-b-10">
          <div className="row align-items-center">
            <SearchBar
              search={search}
              onSearch={onSearch}
              additionalSizeClasses={"col-md-2"}
              tooltip={
                <Tooltip id="searchBar">
                  {calendarType === ViewType.FULL_VIEW
                    ? "Search for commodity titles, suppliers, manufacturers, persons (creator of the order), AT numbers and order titles"
                    : "Search for commodity or packaging"}
                </Tooltip>
              }
            />
            {calendarType === ViewType.FULL_VIEW && (
              <ManufacturerFilter
                customLabel={"Destination"}
                additionalSizeClasses={"col-md-3 "}
                manufacturer={manufacturer ? manufacturer : ""}
                manufacturers={manufactures ? manufactures : []}
                manufacturerLocked={manufacturerLocked ? manufacturerLocked : false}
                onFilterSelect={onSelectChange}
                setZIndex={true}
              />
            )}
            <div className="kt-margin-b-20-tablet-and-mobile col-md-3">
              <div className="kt-form__group kt-form__group--inline">
                <div className="kt-form__label">
                  <label>Status</label>
                </div>
                <div className="kt-form__control">
                  <Select
                    isSearchable={false}
                    className="select-default"
                    options={[
                      { value: "", label: "All" },
                      { value: "open", label: "Open" },
                      { value: "delivered", label: "Delivered" }
                    ]}
                    value={status}
                    onChange={(value: any) => onSelectChange("status", value)}
                  />
                </div>
              </div>
            </div>
            <div className="kt-margin-b-20-tablet-and-mobile col-md-3">
              <div className="kt-form__group kt-form__group--inline">
                <div className="kt-form__label">
                  <label>Type</label>
                </div>
                <div className="kt-form__control">
                  <Select
                    isSearchable={false}
                    className="select-default"
                    options={[
                      { value: "", label: "All" },
                      { value: "commodities", label: "Commodities" },
                      { value: "packaging", label: "Packaging" }
                    ]}
                    value={type}
                    onChange={(value: any) => onSelectChange("type", value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="kt-margin-t-20">
            <OverlayTrigger
              placement={"right"}
              overlay={
                <Tooltip id="deliveryCalendarLegend">
                  {calendarType === ViewType.FULL_VIEW && (
                    <span style={{ fontSize: "14px" }}>
                      Manufacturer Colors:
                      <br />
                      <span
                        className="kt-badge kt-badge--inline kt-badge--pill mb-2"
                        style={{ backgroundColor: "rgba(0,155,155,0.1)", fontSize: "12px" }}
                      >
                        PLF Production CZ
                      </span>
                      <br />
                      <span
                        className="kt-badge kt-badge--inline kt-badge--pill mb-2"
                        style={{ backgroundColor: "rgba(255,0,0,0.1)", fontSize: "12px" }}
                      >
                        PLF Production PS
                      </span>
                      <br />
                      <span
                        className="kt-badge kt-badge--inline kt-badge--pill mb-2"
                        style={{ backgroundColor: "rgba(0,107,255,0.17)", fontSize: "12px" }}
                      >
                        PLF Production GE
                      </span>
                      <br />
                      <span
                        className="kt-badge kt-badge--inline kt-badge--pill mb-2"
                        style={{ backgroundColor: "rgba(255,196,0,0.1)", fontSize: "12px" }}
                      >
                        Unassigned
                      </span>
                    </span>
                  )}
                  {calendarType === ViewType.FULL_VIEW && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                  <span style={{ fontSize: "14px" }}>
                    Status Indicators:
                    <br />
                    <span>
                      <i className="fas fa-circle mr-2" style={{ color: "#0abb87" }} />
                      Delivered
                    </span>
                    <br />
                    <span>
                      <i className="fas fa-circle mr-2" style={{ color: "#ffb822" }} />
                      Overdue
                    </span>
                    <br />
                    <span>
                      <i className="fas fa-circle mr-2" style={{ color: "#e2e5ec" }} />
                      Open
                    </span>
                    <br />
                  </span>
                </Tooltip>
              }
            >
              <span>
                <i className="flaticon-list-2 kt-font-dark mr-2" style={{ fontSize: "1.7rem" }} />
                <span className="kt-font-dark">Legend</span>
              </span>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarViewHeader;
