import _ from "lodash";
import React, { PureComponent } from "react";
import * as Realm from "realm-web";
import { toast } from "react-toastify";
import { CalculationType, CustomCommoditiesDocument, Preferences, SelectedCommoditiesDocument } from "./CustomTypes";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { CommoditySelectionItem, RecipeItem } from "./CommodityItems";
import commodityUtils from "../../utils/commodityUtils";
import calculationUtils from "../../utils/calculationUtils";
import { ProductTypes } from "./configuratorConstants";
import { DataContext } from "../../context/dataContext";

interface CommoditySelectionProps {
  activeType: string;
  preferences: Preferences;
  commodities: Array<CustomCommoditiesDocument>;
  recipe: Array<SelectedCommoditiesDocument>;
  calculations: Array<CalculationType>;
  context: React.ContextType<typeof DataContext>;
  onRecipeAdd: (id: Realm.BSON.ObjectId, index?: number, amount?: number) => void;
  onRecipeSave: (id: Realm.BSON.ObjectId, amount: number) => void;
  onRecipeDelete: (id: Realm.BSON.ObjectId) => void;
  onDraggableItemMove: (oldIndex: number, newIndex: number) => void;
  recipeVolume: { value: number; noDefault: boolean };
}

interface CommoditySelectionState {
  filteredCommodities: Array<CustomCommoditiesDocument>;
  availableStock: { [key: string]: number };
  commoditiesShown: number;
  searchQuery: string;
  recipeSearchQuery: string;
}

class CommoditySelection extends PureComponent<CommoditySelectionProps, CommoditySelectionState> {
  constructor(props: CommoditySelectionProps) {
    super(props);
    const dateLimit = new Date(); // Set half year as limit
    dateLimit.setMonth(dateLimit.getMonth() + 6);
    this.state = {
      commoditiesShown: 20,
      searchQuery: "",
      recipeSearchQuery: "",
      filteredCommodities: props.commodities.filter(
        com => !(com.type || com.disabled) && !props.recipe.some(rec => rec._id.toString() === com._id.toString())
      ),
      availableStock: {}
    };
  }

  componentDidMount() {
    this.setState({ availableStock: this.generateAvailableStock() });
  }

  componentDidUpdate(prevProps: Readonly<CommoditySelectionProps>) {
    if (prevProps.activeType !== this.props.activeType) {
      this.setState({ commoditiesShown: 20, searchQuery: "", recipeSearchQuery: "" });
    }
    // Update filtered commodities when recipe changed
    if (!_.isEqual(this.props.recipe, prevProps.recipe)) {
      const filtered = this.props.commodities
        .filter(
          com =>
            !(com.type || com.disabled || !commodityUtils.isCommodityApproved(com)) &&
            !this.props.recipe.some(rec => rec._id.toString() === com._id.toString())
        )
        .sort((a, b) => a.title.en.localeCompare(b.title.en));
      this.setState({ filteredCommodities: filtered });
    }
    if (
      prevProps.preferences.selectedManufacturer !== this.props.preferences.selectedManufacturer ||
      !_.isEqual(this.props.context.orders, prevProps.context.orders)
    ) {
      this.setState({ availableStock: this.generateAvailableStock() });
    }
  }

  /**
   * Generates the available stock at the selected manufacturer.
   * @returns { { [key: string]: number } } Available stock object
   */
  generateAvailableStock = (): { [key: string]: number } => {
    const { preferences, context, recipe } = this.props;
    const { filteredCommodities } = this.state;
    return commodityUtils.getStockValues(
      filteredCommodities.concat(recipe),
      preferences.selectedManufacturer!._id.toString(),
      context,
      true
    );
  };

  /**
   * Get style for drag and drop
   * @param isDraggingOver bool indicating if something is currently dragging over
   * @returns style object
   */
  getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "#f7f8fa" : "#f7f8fa",
    padding: 8
  });

  /**
   * Update search query
   * @param e ChangeEvent
   */
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchQuery: e.target.value.toLowerCase()
    });
  };

  /**
   * Update search query for recipe selection
   * @param e ChangeEvent
   */
  handleRecipeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      recipeSearchQuery: e.target.value.toLowerCase()
    });
  };

  /**
   * Expend commodity list
   * @param e ClickEvent on Button
   */
  handleShowMore = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { commoditiesShown } = this.state;
    this.setState({ commoditiesShown: commoditiesShown + 20 });
  };

  /**
   * Fill up recipe with filler commodity
   */
  handleAutoFill = () => {
    const { commodities, recipeVolume, activeType, onRecipeAdd, onRecipeSave } = this.props;
    const recipe = _.cloneDeep(this.props.recipe);
    if (activeType !== ProductTypes.CAPSULES) return;
    const currentVolume = recipeVolume.value;
    const maxVolume = this.getMaxVolume();
    const filler = commodities.find(commodity => commodity._id.toString() === "5e6934b3aabd7e11ed06e7a2");
    if (!filler) toast.error("No proper filler commodity could be found.");
    else {
      const existingFiller = recipe.find(com => com._id.toString() === filler._id.toString());
      const remainingVolume = maxVolume! - currentVolume;
      const fillerDensity = filler.density ? +filler.density : 0.5;
      // m = p * V in gram
      const amount = fillerDensity * remainingVolume;
      // Update filler amount
      if (existingFiller) onRecipeSave(filler._id, amount * 1000 + existingFiller.amount!);
      // Add commodity with amount in mg
      else onRecipeAdd(filler._id, undefined, amount * 1000);
    }
  };

  /**
   * Search commodities
   */
  doCommoditySearch = () => {
    const { searchQuery, filteredCommodities, availableStock } = this.state;
    let commodities;
    if (searchQuery.trim() === "") commodities = filteredCommodities;
    else commodities = commodityUtils.doCommoditySearch(filteredCommodities, searchQuery.trim());
    return commodities.sort((a, b) => availableStock[b._id.toString()] - availableStock[a._id.toString()]);
  };

  /**
   * Search the current recipe
   */
  doRecipeSearch = () => {
    const { recipeSearchQuery } = this.state;
    const { recipe } = this.props;
    if (recipeSearchQuery.trim() === "") return recipe;
    return commodityUtils.doCommoditySearch(recipe, recipeSearchQuery.trim());
  };

  /**
   * Handle releasing a draggable element. Add or delete a commodity
   * @param result DropResult object with information about source and destination of the draggable
   */
  onDragEnd = (result: DropResult) => {
    const { onRecipeAdd, onRecipeDelete, onDraggableItemMove } = this.props;
    const { source, destination } = result;
    const commodityId = new Realm.BSON.ObjectId(result.draggableId);
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId && source.droppableId === "recipe") {
      onDraggableItemMove(source.index, destination.index);
    }
    if (source.droppableId && destination.droppableId && source.droppableId !== destination.droppableId) {
      if (destination.droppableId === "recipe") {
        onRecipeAdd(commodityId, destination.index);
      } else if (destination.droppableId === "commodities") {
        onRecipeDelete(commodityId);
      }
    }
  };

  /**
   * Get maximum volume for capsule or tablet
   * @returns the maximum volume for a selected capsule or tablet
   */
  getMaxVolume = () => {
    const { activeType, preferences } = this.props;
    let maxVolume: number | undefined;
    switch (activeType) {
      case ProductTypes.CAPSULES:
        maxVolume = preferences.selectedCapsule?.capsule_volume!;
        break;
      case ProductTypes.TABLETS:
        maxVolume = +preferences.selectedTablet?.volume!;
        break;
      default:
        break;
    }
    return maxVolume;
  };

  renderVolume = () => {
    const { recipeVolume, recipe } = this.props;
    const currentVolume = recipeVolume.value;
    const showInfo = !recipeVolume.noDefault;
    const maxVolume = this.getMaxVolume();
    const volumeTextClass =
      maxVolume && currentVolume > maxVolume ? "text-danger" : showInfo ? "text-warning" : "text-muted";
    return (
      <>
        <span className="text-muted mr-2" style={{ fontSize: 14 }}>
          Total Amount:{" "}
          {calculationUtils.formatAmount(
            recipe.reduce((a, b) => a + +b.amount!, 0),
            2
          )}
        </span>{" "}
        -
        <span className={volumeTextClass + " ml-2"} style={{ fontSize: 14 }}>
          Estimated Volume: {+currentVolume.toFixed(2)}
          {!maxVolume ? "ml" : `ml / ${+maxVolume.toFixed(2)}ml`}
        </span>
      </>
    );
  };

  render() {
    const { activeType, preferences, onRecipeSave, calculations } = this.props;
    const { commoditiesShown, searchQuery, recipeSearchQuery, availableStock } = this.state;
    const commodities = this.doCommoditySearch();
    const recipe = this.doRecipeSearch() as Array<SelectedCommoditiesDocument>;
    return (
      <>
        <div className="kt-form__section kt-form__section--first mt-3">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="row">
              <div className="col-6">
                <Droppable droppableId="commodities">
                  {(provided, snapshot) => (
                    <div style={this.getListStyle(snapshot.isDraggingOver)}>
                      <div className="board_title">
                        All Commodities <br />
                        <span className="text-muted" style={{ fontSize: 14 }}>
                          found {commodities.length} results
                        </span>
                      </div>
                      <div className="kt-input-icon kt-input-icon--left">
                        <input
                          type="text"
                          className="form-control calculation_search"
                          placeholder="Search..."
                          id="commoditySearch"
                          value={searchQuery}
                          onChange={this.handleSearch}
                        />
                        <span className="kt-input-icon__icon kt-input-icon__icon--left">
                          <span>
                            <i className="la la-search" />
                          </span>
                        </span>
                      </div>
                      <div ref={provided.innerRef} className="droppable_fixed">
                        {commodities.slice(0, commoditiesShown).map((c, index) => (
                          <CommoditySelectionItem
                            commodity={c}
                            index={index}
                            key={c._id.toString()}
                            availableStock={availableStock[c._id.toString() || 0]}
                          />
                        ))}
                        {commodities.length > commoditiesShown && (
                          <div key="showMore">
                            <div className="text-center ">
                              <button type="button" className="m-0 p-0 button-link" onClick={this.handleShowMore}>
                                Show More
                              </button>
                            </div>
                          </div>
                        )}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              </div>
              <div className="col-6">
                <Droppable droppableId="recipe">
                  {(provided, snapshot) => (
                    <div style={this.getListStyle(snapshot.isDraggingOver)}>
                      <div className="board_title">
                        Recipe <br />
                        {this.renderVolume()}
                        {activeType === ProductTypes.CAPSULES && (
                          <a onClick={this.handleAutoFill} className="kt-portlet__head-icon ml-3 ">
                            <i className="kt-font-brand fa fa-magic" />
                          </a>
                        )}
                      </div>
                      <div className="kt-input-icon kt-input-icon--left">
                        <input
                          type="text"
                          className="form-control calculation_search"
                          placeholder="Search..."
                          id="generalSearch"
                          name="recipeSearch"
                          value={recipeSearchQuery}
                          onChange={this.handleRecipeSearch}
                        />
                        <span className="kt-input-icon__icon kt-input-icon__icon--left">
                          <span>
                            <i className="la la-search" />
                          </span>
                        </span>
                      </div>
                      <div ref={provided.innerRef} className="droppable_fixed">
                        {recipe.map((recipeItem, index) => (
                          <RecipeItem
                            activeType={activeType}
                            commodity={recipeItem}
                            index={index}
                            calculations={calculations}
                            key={recipeItem._id.toString()}
                            preferences={preferences}
                            onRecipeSave={onRecipeSave}
                            availableStock={availableStock[recipeItem._id.toString() || 0]}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          </DragDropContext>
        </div>
      </>
    );
  }
}

export default CommoditySelection;
