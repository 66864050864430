import _ from "lodash";
import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import baseUtils from "../../utils/baseUtils";
import dbService, { ORDERS } from "../../services/dbService";
import SplashScreen from "../common/SplashScreen";
import { CustomOrder } from "./CustomTypes";
import OrderHeader from "./OrderHeader";
import OrderOverview from "./OrderOverview";
import OrderNote from "./OrderNote";
import OrderTabPanel from "./OrderTabPanel";
import { OrdersDocument } from "../../model/orders.types";
import OrderHelper from "./OrderHelper";

interface OrderParams {
  id: string;
}

interface OrderProps extends RouteComponentProps<OrderParams, {}, {}> {
  context: React.ContextType<typeof DataContext>;
}

interface OrderState {
  order?: CustomOrder;
}

class Order extends PureComponent<OrderProps, OrderState> {
  _isMounted = false;
  _id: string | undefined;
  materialCheckRef: any;

  constructor(props: OrderProps) {
    super(props);
    this._id = props.match.params.id;
    this.state = {};
  }

  componentDidMount = async () => {
    await this.initializeData();
  };

  componentDidUpdate = async (prevProps: Readonly<OrderProps>, prevState: Readonly<OrderState>) => {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this._id = this.props.match.params.id;
      await this.initializeData();
    }

    if (this._id && prevProps !== this.props) {
      const { orders } = this.props.context;
      const order = baseUtils.getDocFromCollection(orders, this._id);
      if (order) {
        const newOrder = this.getExtendedOrder(this.props, order);
        if (!_.isEqual(newOrder, prevState.order)) {
          this.setState({ order: newOrder });
        }
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  initializeData = async () => {
    const { history, context } = this.props;
    const { orders } = context;
    if (!this._id || !BSON.ObjectId.isValid(this._id)) {
      history.push("/allOrders");
      return;
    }
    this._isMounted = true;

    let order = baseUtils.getDocFromCollection(orders, this._id);
    if (!order) {
      // If order was not found try to load from backend
      order = await dbService.getDocumentFromCollection(ORDERS, this._id);
      if (!order) {
        console.error("No order could be loaded for id", this._id);
        history.push("/allOrders");
        return;
      }
      context.addDocuments(ORDERS, [order]);
    }
    const extendedOrder = this.getExtendedOrder(this.props, order);
    if (this._isMounted) this.setState({ order: extendedOrder as CustomOrder });
  };

  /**
   * Get the extended order
   * @param props the properties of the order
   * @param order an order document
   * @returns {CustomOrder} the extended order document
   */
  getExtendedOrder = (props: OrderProps, order: OrdersDocument) => {
    return OrderHelper.getExtendedOrder(order, props.context);
  };

  handleSave = (approve?: boolean, onSuccess?: () => void, finalCallback?: () => void) =>
    this.materialCheckRef.handleSaveCalculations(approve, onSuccess, finalCallback);

  setRef = (ref: any) => {
    this.materialCheckRef = ref;
  };

  render() {
    const { order } = this.state;
    if (!order)
      return (
        <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid ">
          <div className="kt-portlet ">
            <div className="kt-portlet__body" style={{ minHeight: "80vh" }}>
              <SplashScreen
                additionalSVGStyle={{
                  height: "80px",
                  width: "80px"
                }}
              />
            </div>
          </div>
        </div>
      );
    else {
      return (
        <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
          <OrderHeader order={order} {...this.props} onSave={this.handleSave} />
          <div className="row">
            <div className="col-xl-4">
              <OrderNote order={order} />
              <OrderOverview order={order} {...this.props} />
            </div>
            <div className="col-xl-8">
              <OrderTabPanel order={order} {...this.props} setRef={this.setRef} />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Order;
