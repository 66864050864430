import React, { PureComponent, useContext } from "react";
import { BSON } from "realm-web";
import { CalculationType, Preferences, SelectedPackagingsDocument } from "../CustomTypes";
import packagingUtils from "../../../utils/packagingUtils";
import { DataContext } from "../../../context/dataContext";

interface PackagingCalculationDetailsProps {
  type: string;
  selectedPackaging: Array<SelectedPackagingsDocument>;
  calculation: CalculationType;
  preferences: Preferences;
  onPackagingCalculationUpdate: (
    packagingId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    path: string,
    value: any
  ) => void;
  onPackagingSupplierChange: (
    packagingId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    newSupplier: BSON.ObjectId | "ownstock" | "customer"
  ) => void;
}
interface PackagingCalculationDetailsState {}

class PackagingCalculationDetails extends PureComponent<
  PackagingCalculationDetailsProps,
  PackagingCalculationDetailsState
> {
  render() {
    const {
      type,
      selectedPackaging,
      calculation,
      preferences,
      onPackagingCalculationUpdate,
      onPackagingSupplierChange
    } = this.props;
    return (
      <table className="kt-datatable__table">
        <thead className="kt-datatable__head text-align-left">
          <tr className="kt-datatable__row" style={{ backgroundColor: "#fcfcfc" }}>
            <th className=" kt-datatable__cell">
              <span>
                <b>Packaging</b>
              </span>
            </th>
          </tr>
          <tr className="kt-datatable__row">
            <th className=" kt-datatable__cell">
              <span style={{ width: 150 }}>Title</span>
            </th>
            <th className=" kt-datatable__cell">
              <span style={{ width: 70 }}>Auto</span>
            </th>
            <th className=" kt-datatable__cell">
              <span style={{ width: 60 }}>Amount</span>
            </th>
            <th className=" kt-datatable__cell">
              <span style={{ width: 75 }} />
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 150 }}>Supplier</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>Total</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>MOQ</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>€ / Item</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>€ / Unit</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>Total €</span>
            </th>
          </tr>
        </thead>
        <tbody className="kt-datatable__body ps ps--active-y">
          {selectedPackaging.map(packaging => (
            <PackagingCalculationDetailsRow
              key={packaging._id.toString()}
              type={type}
              packaging={packaging}
              calculation={calculation}
              preferences={preferences}
              onPackagingCalculationUpdate={onPackagingCalculationUpdate}
              onPackagingSupplierChange={onPackagingSupplierChange}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

interface PackagingCalculationDetailsRowProps {
  type: string;
  packaging: SelectedPackagingsDocument;
  calculation: CalculationType;
  preferences: Preferences;
  onPackagingCalculationUpdate: (
    packagingId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    path: string,
    value: any
  ) => void;
  onPackagingSupplierChange: (
    packagingId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    newSupplier: BSON.ObjectId | "ownstock" | "customer"
  ) => void;
}

const PackagingCalculationDetailsRow: React.FunctionComponent<PackagingCalculationDetailsRowProps> = React.memo(
  ({ packaging, calculation, preferences, onPackagingCalculationUpdate, onPackagingSupplierChange }) => {
    const context = useContext(DataContext);

    if (!calculation.priceDetails) return <></>;
    const matchingCalculation = packaging.calculations!.find(calc => calc.id.toString() === calculation.id.toString())!;
    const priceDetails = calculation.priceDetails!.packagingPrices.find(
      pack => pack._id.toString() === packaging._id.toString()
    )!;
    if (!priceDetails || !matchingCalculation) return <></>;
    const moq = matchingCalculation.supplier.price.moq;
    const totalAmount = matchingCalculation.totalAmount;
    const matchingMOQ = totalAmount >= moq;

    const getSupplierList = () => {
      const suppliers = context.suppliers;
      const packagingSuppliers = packaging.suppliers;
      let supplierList: Array<{ key: string | BSON.ObjectId; value: string }> = packagingSuppliers.map(supp => {
        const supplierName = suppliers.find(s => s._id.toString() === supp._id.toString())!.name;
        return { key: supp._id, value: supplierName };
      });
      supplierList.unshift({ key: "customer", value: "Customer (0€)" });
      const totalStock = packagingUtils.getAllStock(
        packaging,
        context.packagingStock,
        preferences.selectedFiller?._id || preferences.selectedManufacturer!._id
      );
      if (totalStock >= totalAmount) supplierList.unshift({ key: "ownstock", value: "Stock" });
      return supplierList;
    };

    const handlePackagingSupplierChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.currentTarget.value;
      const id = ["customer", "ownstock"].includes(value) ? value : new BSON.ObjectId(value);
      onPackagingSupplierChange(packaging._id, calculation.id, id as "ownstock" | "customer" | BSON.ObjectId);
    };

    return (
      <tr className="kt-datatable__row">
        <td className="kt-datatable__cell">
          <span style={{ width: 150 }}>
            <b>{packagingUtils.getShortPackagingInfo(packaging)}</b>
          </span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }}>
            <div className="custom-control custom-switch ml-2" style={{ display: "inline" }}>
              <input
                type="checkbox"
                className="custom-control-input"
                id={"autoselect_pack" + packaging._id.toString() + calculation.id.toString()}
                checked={matchingCalculation.auto}
                onChange={() =>
                  onPackagingCalculationUpdate(packaging._id, calculation.id, "auto", !matchingCalculation.auto)
                }
              />
              <label
                className="custom-control-label"
                htmlFor={"autoselect_pack" + packaging._id.toString() + calculation.id.toString()}
              />
            </div>
          </span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 60 }}>{packaging.amount}</span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 75 }} />
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 150 }}>
            <select
              className="form-control"
              disabled={matchingCalculation.auto}
              value={matchingCalculation.supplier._id.toString()}
              onChange={handlePackagingSupplierChange}
            >
              {getSupplierList().map(supp => (
                <option key={supp.key.toString()} value={supp.key.toString()}>
                  {supp.value}
                </option>
              ))}
            </select>
          </span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }}>{totalAmount}</span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }} className={"text-bold " + (matchingMOQ ? "" : "text-danger")}>
            {moq}
          </span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }}>
            {matchingCalculation.price.toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR"
            })}
          </span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }}>
            <b>
              {priceDetails.unitPrice.toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR"
              })}
            </b>
          </span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }}>
            {(matchingCalculation.totalPrice || 0).toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR"
            })}
          </span>
        </td>
      </tr>
    );
  }
);

export default PackagingCalculationDetails;
