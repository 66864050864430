import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { CustomOrder } from "../CustomTypes";
import CompanyWidget from "../../common/CompanyWidget";
import dbGeneralService from "../../../services/dbServices/dbGeneralService";
import orderUtils, { ORDERORDERCOMMODITIES } from "../../../utils/orderUtils";
import dateUtils from "../../../utils/dateUtils";
import userService from "../../../services/userService";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import { T_ORDERED, T_REQUESTAPPROVED, T_REQUESTAPPROVEDWITHRAWBIDS } from "../../../utils/timelineUtils";
import notificationService, { R_ORDERCONFIRMED } from "../../../services/notificationService";
import { FILLER_COMMODITIES } from "../../../utils/commodityUtils";
import { Input } from "../../common/Input";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { OrderData } from "../../../model/customTypes.types";
import { DataContext } from "../../../context/dataContext";

enum PlaceOrderModalSteps {
  ORDER_SETTINGS,
  PRICES_OVERVIEW
}

interface PlaceOrderModalProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
}

interface PlaceOrderModalState {
  show: boolean;
  saving: boolean;
  orderData: OrderData;
  step: number;
  nextLot: string;
  usedOrderNumbers: Set<string>;
}

class PlaceOrderModal extends PureComponent<PlaceOrderModalProps, PlaceOrderModalState> {
  constructor(props: PlaceOrderModalProps) {
    super(props);
    const orderData = this.getDefaultStateValues(props);
    this.state = {
      show: false,
      saving: false,
      orderData,
      step: PlaceOrderModalSteps.ORDER_SETTINGS,
      nextLot: "Not set",
      usedOrderNumbers: new Set()
    };
  }

  componentDidUpdate(prevProps: Readonly<PlaceOrderModalProps>, prevState: Readonly<PlaceOrderModalState>) {
    if (!_.isEqual(prevProps.order, this.props.order) || (!prevState.show && this.state.show)) {
      this.setState({ orderData: this.getDefaultStateValues(this.props) });
    }
    if (!_.isEqual(prevProps.context.orders, this.props.context.orders)) {
      this.setState({ usedOrderNumbers: this.getAllOrderNumbers() });
    }
  }

  /**
   * Get the default state values
   * @param props the modal properties
   * @returns {OrderData} default state values
   */
  getDefaultStateValues = (props: PlaceOrderModalProps): OrderData => {
    const { order } = props;
    return {
      title: order.title,
      subtitle: order.subtitle,
      note: order.note,
      identifier: "",
      priority: order.priority,
      targetDate: order.targetDate,
      selectedCalculation: order.calculations[0].id.toString(),
      shelfLife: 24
    };
  };

  /**
   * Get all unique order identifiers
   * @returns {Set<string>} a set containing all unique order identifiers
   */
  getAllOrderNumbers = (): Set<string> => {
    const { context } = this.props;
    return new Set(context.orders.map(o => o.identifier.toString()));
  };

  handleShow = async () =>
    this.setState({
      show: true,
      step: PlaceOrderModalSteps.ORDER_SETTINGS,
      nextLot: await dbGeneralService.getNextLotNumber()
    });
  handleClose = () => !this.state.saving && this.setState({ show: false });

  handleNext = () => {
    const { step } = this.state;
    if (step === PlaceOrderModalSteps.ORDER_SETTINGS) this.setState({ step: PlaceOrderModalSteps.PRICES_OVERVIEW });
  };
  handleBack = () => {
    const { step } = this.state;
    if (step === PlaceOrderModalSteps.PRICES_OVERVIEW) this.setState({ step: PlaceOrderModalSteps.ORDER_SETTINGS });
  };

  handlePlaceOrder = async () => {
    const { order } = this.props;
    const { orderData } = this.state;
    const currUser = userService.getUserId();
    const currDate = new Date();
    const { title, subtitle, note, identifier, priority, targetDate, selectedCalculation, shelfLife } = orderData;
    this.setState({ saving: true });
    // Get order nr
    const identifierAsNumber = parseInt(identifier, 10);
    const orderNr =
      identifier.length > 0 && identifierAsNumber ? identifierAsNumber : await dbGeneralService.getNextOrderNr();
    // get selected calculation
    const calculations = _.cloneDeep(order.calculations).filter(c => c.id.toString() === selectedCalculation);
    // Handle filler agents, set to delivered and ordered
    calculations[0].prices.forEach(price => {
      if (FILLER_COMMODITIES.includes(price._id.toString())) {
        price.ordered = currDate;
        price.delivered = currDate;
        price.eta = currDate;
        price.userOrdered = currUser;
        price.userDelivered = currUser;
      }
    });
    const timelineEntry = {
      _id: new BSON.ObjectId(),
      type: T_ORDERED,
      stats: calculations[0].info,
      ordernumber: orderNr,
      date: currDate,
      person: currUser
    };
    const fulfillment = {
      lot: await dbGeneralService.callGenerateLotNumberFunction(),
      exp: new Date(0), // set an obviously invalid date
      shelfLife,
      shippingNote: "",
      shippingGroups: []
    };
    const additionalUpdate = {
      $set: {
        title,
        subtitle,
        createdOn: currDate,
        note,
        identifier: orderNr,
        offerIdentifier: order.identifier, // save offer identifier
        priority,
        targetDate,
        calculations,
        fulfillment
      }
    };
    try {
      const result = await dbOrderService.switchState(
        order._id,
        ORDERORDERCOMMODITIES,
        timelineEntry,
        additionalUpdate
      );
      if (result && result.modifiedCount) {
        toast.success("Order successfully placed");
        notificationService.notify(R_ORDERCONFIRMED, order._id);
      } else toast.error("Order could not be created");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    }
    this.setState({ saving: false, show: false });
  };

  handleOrderDataChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const orderData = _.cloneDeep(this.state.orderData);
    const key = e.target.name as keyof OrderData;
    if (key === "targetDate") {
      const min = orderUtils.calculateEarliestDeliveryDate(this.props.order);
      const targetDate = (e as React.ChangeEvent<HTMLInputElement>).target.valueAsDate;
      orderData[key] = targetDate && targetDate >= min ? targetDate : min;
    } else if (key === "identifier") _.set(orderData, key, e.target.value);
    else _.set(orderData, key, e.target.type === "number" ? +e.target.value : e.target.value);
    this.setState({ orderData });
  };

  validateData = () => {
    const { orderData, step, usedOrderNumbers } = this.state;
    const errors: Array<string> = [];
    const warnings: Array<string> = [];
    if (step === PlaceOrderModalSteps.ORDER_SETTINGS) {
      if (!orderData.targetDate) errors.push("Please enter a valid target date");
      if (orderData.shelfLife <= 0) errors.push("Please enter a valid shelf life");
      if (orderData.identifier && usedOrderNumbers.has(orderData.identifier.toString()))
        warnings.push("Order identifier already exists");
    }
    return [errors, warnings];
  };

  render() {
    const { order } = this.props;
    const { show, saving, orderData, step, nextLot } = this.state;

    const latestApproval = order.timeline
      .slice()
      .reverse()
      .find(t => t.type === T_REQUESTAPPROVED || t.type === T_REQUESTAPPROVEDWITHRAWBIDS);
    let selectedCalculation = order.calculations.find(c => c.id.toString() === orderData.selectedCalculation);
    if (!selectedCalculation) selectedCalculation = order.calculations[0];
    const priceChanges = orderUtils.getCommodityPriceChanges(selectedCalculation);
    const [errors, warnings] = this.validateData();
    return (
      <>
        <button className="btn btn-sm btn-success btn-upper" onClick={this.handleShow}>
          Place Order
        </button>
        <Modal show={show} onHide={this.handleClose} size={"lg"} centered name={"placeOrderModal"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand flaticon-layer mr-2" />
              Place Order
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="kt-portlet__body">
              <div className="row">
                <div className="col-xl-12">
                  <div className="kt-section kt-section--first">
                    <div className="kt-section__body">
                      {step === PlaceOrderModalSteps.ORDER_SETTINGS && (
                        <Table>
                          <thead>
                            <tr>
                              <th colSpan={2}>
                                <h4 className="font-weight-bolder">Create Order for Offer AN-{order.identifier}</h4>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="align-middle font-weight-bold">Provisional LOT:</td>
                              <td>
                                <input type="text" className="form-control" disabled value={nextLot} />
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">Title:</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="title"
                                  value={orderData.title}
                                  onChange={this.handleOrderDataChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">AT-Number:</td>
                              <td>
                                <Input
                                  type="number"
                                  min={0}
                                  className="form-control"
                                  name="identifier"
                                  integerOnly={true}
                                  showEmpty={true}
                                  value={orderData.identifier}
                                  onChange={this.handleOrderDataChange}
                                />
                                <span className="text-success">Recommended: Leave empty for automated generation.</span>
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">Short description (optional):</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  aria-label="Text input with dropdown button"
                                  name="subtitle"
                                  value={orderData.subtitle}
                                  onChange={this.handleOrderDataChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">Note (optional):</td>
                              <td>
                                <textarea
                                  className="form-control"
                                  rows={5}
                                  name="note"
                                  value={orderData.note}
                                  onChange={this.handleOrderDataChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">Priority:</td>
                              <td>
                                <select
                                  name="priority"
                                  className="form-control"
                                  value={orderData.priority}
                                  onChange={this.handleOrderDataChange}
                                >
                                  <option value="high">High Priority</option>
                                  <option value="medium">Medium Priority</option>
                                  <option value="low">Low Priority</option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">Target Date:</td>
                              <td>
                                <input
                                  type="date"
                                  min={orderUtils.calculateEarliestDeliveryDate(order).toISOString().split("T")[0]}
                                  className="form-control"
                                  name="targetDate"
                                  value={orderData.targetDate ? orderData.targetDate.toISOString().split("T")[0] : ""}
                                  onChange={this.handleOrderDataChange}
                                />
                                <span className="text-danger">
                                  Please note that the target date must be in accordance with all delivery dates of raw
                                  materials and packaging.
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">Product Shelf Life:</td>
                              <td>
                                <div className="input-group">
                                  <Input
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    name="shelfLife"
                                    integerOnly={true}
                                    value={orderData.shelfLife}
                                    onChange={this.handleOrderDataChange}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">months</span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">Calculation:</td>
                              <td>
                                <select
                                  name="selectedCalculation"
                                  className="form-control"
                                  value={orderData.selectedCalculation}
                                  onChange={this.handleOrderDataChange}
                                >
                                  {order.calculations.map(calculation => (
                                    <option key={calculation.id.toString()} value={calculation.id.toString()}>
                                      {calculation.units} Units (
                                      {calculation.info.totalprice.toLocaleString("de-DE", {
                                        style: "currency",
                                        currency: "EUR"
                                      })}
                                      )
                                    </option>
                                  ))}
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle font-weight-bold">Customer:</td>
                              <td className="align-middle">
                                <div className="kt-widget__info h-100">
                                  <CompanyWidget company={order.createdFor} />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      )}
                      {step === PlaceOrderModalSteps.PRICES_OVERVIEW && (
                        <p
                          style={{
                            fontSize: "1.3rem",
                            color: "#48465b",
                            fontWeight: 600,
                            textAlign: "center"
                          }}
                        >
                          <br />
                          <br />
                          {priceChanges > 0 ? (
                            <i className="fa fa-exclamation text-danger" />
                          ) : (
                            <i className="fa fa-check-square text-success mr-2" />
                          )}
                          {priceChanges < 0
                            ? "Commodity prices decreased"
                            : priceChanges > 0
                            ? "Commodity prices increased"
                            : "Commodity prices as expected"}
                          <br />
                          {latestApproval && (
                            <span style={{ fontSize: "1rem", fontWeight: 300 }}>
                              Checked {dateUtils.getTimeAgo(latestApproval.date)}
                            </span>
                          )}
                          <br />
                          <br />
                          <span className={priceChanges > 0 ? "text-danger" : "text-success"}>
                            {priceChanges === 0 ? "Still" : "Now"}{" "}
                            {Math.round(selectedCalculation.info.percentmargin * 100) / 100}% Margin
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={"btn btn-secondary " + (saving && "disabled")}
              disabled={saving}
              onClick={this.handleClose}
            >
              Close
            </button>
            {step === PlaceOrderModalSteps.PRICES_OVERVIEW && (
              <button
                className={"btn btn-secondary " + (saving && "disabled")}
                disabled={saving}
                onClick={this.handleBack}
              >
                Back
              </button>
            )}
            <ErrorOverlayButton
              errors={errors}
              warnings={warnings}
              className={"btn btn-success"}
              saving={saving}
              onClick={step === PlaceOrderModalSteps.ORDER_SETTINGS ? this.handleNext : this.handlePlaceOrder}
              buttonText={
                <>
                  {saving && (
                    <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                      <svg className="button-splash-spinner" viewBox="0 0 50 50">
                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                      </svg>
                    </div>
                  )}
                  {step === PlaceOrderModalSteps.ORDER_SETTINGS ? "Next" : "Place Order"}
                </>
              }
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default PlaceOrderModal;
