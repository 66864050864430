import React from "react";
import { ExtendedEMCommodity } from "../../../model/customTypes.types";
import commodityUtils from "../../../utils/commodityUtils";
import calculationUtils from "../../../utils/calculationUtils";
import baseUtils from "../../../utils/baseUtils";

interface EmCommodityOverviewProps {
  commodity: ExtendedEMCommodity;
}

const EmCommodityOverview: React.FunctionComponent<EmCommodityOverviewProps> = ({ commodity }) => {
  return (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Overview</h3>
        </div>
        <div className="kt-portlet__head-toolbar" />
      </div>
      <div className="kt-form kt-form--label-right">
        <div className="kt-portlet__body">
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">Category</label>
            <div className="col-8 col-form-label">
              {commodity.category ? (
                <span
                  className="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded"
                  style={{
                    backgroundColor: commodityUtils.getCategoryColor(commodity.category.name.en),
                    color: commodity.category && commodity.category.name.en === "probiotics" ? "black" : "white",
                    display: "inline-block"
                  }}
                >
                  {commodity.category.name.en}
                </span>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">Composition</label>
            <div className="col-8 col-form-label">
              {commodity.form ? (
                <span className="kt-badge kt-badge--primary kt-badge--inline kt-badge--pill kt-badge--rounded mr-2">
                  {commodity.form.name.en}
                </span>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">Solvent</label>
            <div className="col-8 col-form-label">
              {commodity.solvent ? (
                <span
                  className="kt-badge kt-badge--primary kt-badge--inline kt-badge--pill kt-badge--rounded mr-2"
                  style={{ backgroundColor: "rgba(255,153,153,0.64)" }}
                >
                  {commodity.solvent.name.en}
                </span>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">Properties</label>
            <div className="col-8 col-form-label">
              {commodity.properties && commodity.properties.length > 0
                ? commodity.properties.map(property => (
                    <span
                      key={property._id.toString()}
                      className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill kt-badge--rounded mr-2"
                      style={{ backgroundColor: "#5bb3ff" }}
                    >
                      {property.name.en}
                    </span>
                  ))
                : "-"}
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">Active Substances</label>
            <div className="col-8 col-form-label">
              {commodity.activesubstance && commodity.activesubstance.length > 0
                ? commodity.activesubstance.map(as => (
                    <span
                      key={as._id.toString()}
                      className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded mr-2"
                    >
                      {`${as.value}% ${as.name.en}`}
                    </span>
                  ))
                : "-"}
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">Allergens</label>
            <div className="col-8 col-form-label">
              {commodity.allergens && commodity.allergens.length > 0
                ? commodity.allergens.map(al => (
                    <span
                      key={al._id.toString()}
                      className="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill kt-badge--rounded mr-2"
                    >
                      {al.name.en}
                    </span>
                  ))
                : "-"}
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">Expected required amount</label>
            <div className="col-8 col-form-label kt-font-bolder kt-font-dark">
              {commodity.amount ? calculationUtils.formatAmount(commodity.amount, 2) : "0kg"}
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">Delivery time</label>
            <div className="col-8 col-form-label kt-font-bolder kt-font-dark">{`from ${commodity.deliveryTime} days`}</div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">Price Range (kg)</label>
            <div className="col-8 col-form-label kt-font-bolder kt-font-dark">
              {commodity.price
                ? commodity.price.min === commodity.price.max
                  ? baseUtils.formatEuro(commodity.price.min)
                  : `${baseUtils.formatEuro(commodity.price.min)} - ${baseUtils.formatEuro(commodity.price.max)}`
                : "-,-- €"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmCommodityOverview;
