import Joi from "joi";
import React, { PureComponent } from "react";
import Select from "react-select";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";
import { Userdata } from "../../model/userdata.types";
import dbService, { USERDATA } from "../../services/dbService";
import userService from "../../services/userService";
import { ROLES } from "../../utils/userdataUtils";
import { DataContext } from "../../context/dataContext";

interface CreateUserProps extends RouteComponentProps<{}, {}, {}> {}

interface CreateUserState {
  submit: boolean;
  saving: boolean;
  title: "" | { value: string; label: string };
  prename: string;
  surname: string;
  email: string;
  roles: Array<string>;
  position: string;
  telephone: string;
}

class CreateUser extends PureComponent<CreateUserProps, CreateUserState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  emailSchema = Joi.string()
    .email({ tlds: { allow: false }, minDomainSegments: 1 })
    .required()
    .label("Email");

  constructor(props: CreateUserProps) {
    super(props);
    this.state = {
      submit: false,
      saving: false,
      title: "",
      prename: "",
      surname: "",
      email: "",
      roles: [ROLES.CUSTOMER],
      position: "",
      telephone: ""
    };
  }

  /**
   * Handles saving a new userdata document to the database.
   */
  handleSaveUser = async () => {
    const { history } = this.props;
    const { title, prename, surname, email, roles, position, telephone } = this.state;
    this.setState({ submit: true });
    if (prename.length >= 3 && surname.length >= 3 && !this.emailSchema.validate(email).error && roles.length >= 1) {
      this.setState({ saving: true });
      const user: Userdata = {
        title: title ? title.value : "",
        prename,
        surname,
        email: [email],
        role: roles,
        position,
        telephone: [telephone],
        user_id: "",
        img_url: "",
        company_id: "",
        password_valid: false,
        notifications: { email: [], contactMail: email, contactPhone: telephone, sms: [], language: "de", whatsapp: [] }
      };
      const res = await dbService.insertDocument(USERDATA, user);
      if (res && res.insertedId) {
        toast.success(
          <b>
            <i className="fa fa-check mx-2" />
            User successfully added
          </b>
        );
        await this.context.updateDocumentInContext(USERDATA, res.insertedId);
        history.replace("/user/" + res.insertedId.toString());
      } else {
        toast.error(
          <b>
            <i className="fa fa-exclamation mx-2" />
            Error adding user
          </b>
        );
      }
      this.setState({ submit: false, saving: false });
    }
  };

  render() {
    const { history } = this.props;
    const { submit, saving, title, prename, surname, email, roles, position, telephone } = this.state;
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand flaticon2-avatar" />
            </span>
            <h3 className="kt-portlet__head-title">Create a new user</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <button
              onClick={() => {
                history.goBack();
              }}
              className="btn btn-clean kt-margin-r-10"
            >
              <i className="la la-arrow-left" />
              <span className="kt-hidden-mobile">Back</span>
            </button>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-xl-2" />
            <div className="col-xl-8">
              <div className="kt-section">
                <div className="kt-section__body">
                  <h3 className="kt-section__title kt-section__title-lg">Userdata:</h3>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">Name</label>
                    <div className="col-3">
                      <Select
                        isClearable={true}
                        className="select-default"
                        options={[
                          { value: "Mr", label: "Mr." },
                          { value: "Mrs", label: "Mrs." }
                        ]}
                        value={title ? title : { value: "", label: "No Title" }}
                        onChange={(value: any) => this.setState({ title: value })}
                      />
                    </div>
                    <div className="col-3">
                      <input
                        className={"form-control" + (prename.length < 3 && submit ? " is-invalid" : "")}
                        type="text"
                        placeholder="Prename"
                        value={prename}
                        onChange={e => this.setState({ prename: e.target.value })}
                      />
                    </div>
                    <div className="col-3">
                      <input
                        className={"form-control" + (surname.length < 3 && submit ? " is-invalid" : "")}
                        type="text"
                        placeholder="Surname"
                        value={surname}
                        onChange={e => this.setState({ surname: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">Primary Email Address</label>
                    <div className="col-9">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="la la-at" />
                          </span>
                        </div>
                        <input
                          type="text"
                          className={
                            "form-control" + (this.emailSchema.validate(email).error && submit ? " is-invalid" : "")
                          }
                          placeholder="Email"
                          value={email}
                          onChange={e => this.setState({ email: e.target.value })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">Roles </label>
                    <div className="col-9">
                      <Dropdown
                        placeholder="Roles"
                        fluid
                        multiple
                        selection
                        disabled={!userService.isAuthorizedForAction(ROLES.ADMIN)}
                        options={Object.values(ROLES).map(r => {
                          return { key: r, text: r, value: r };
                        })}
                        value={roles}
                        onChange={(e, { value }) => {
                          if (value && Array.isArray(value)) this.setState({ roles: value.map(v => v.toString()) });
                        }}
                      />
                    </div>
                    <div className="col-3" />
                    <div className="col-9">
                      {submit && roles.length < 1 && (
                        <span className="text-danger">Please select a least one role!</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="kt-section">
                  <div className="kt-section__body">
                    <h3 className="kt-section__title kt-section__title-lg">Optional Data:</h3>

                    <div className="form-group row">
                      <label className="col-3 col-form-label">Position</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          type="text"
                          value={position}
                          onChange={e => this.setState({ position: e.target.value })}
                          placeholder="Position"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Telephone Number</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          type="text"
                          value={telephone}
                          onChange={e => this.setState({ telephone: e.target.value })}
                          placeholder="Telephone"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet__foot">
          <div className="float-right">
            <button
              type="button"
              className={
                "btn btn-brand" +
                (saving ? "btn btn-primary kt-spinner kt-spinner--left kt-spinner--md kt-spinner--light" : "")
              }
              onClick={this.handleSaveUser}
            >
              <i className={saving ? "d-none" : "la la-check"} />
              <span className="kt-hidden-mobile">Save User</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateUser);
