import React from "react";

export default class HMenuItemInner extends React.Component {
  itemCssClassWithBullet(item, parentItem) {
    if (item.bullet) {
      return `kt-menu__link-bullet--${item.bullet}`;
    }

    if (parentItem && parentItem.bullet) {
      return `kt-menu__link-bullet--${parentItem.bullet}`;
    }

    return "";
  }

  render() {
    const { item, parentItem } = this.props;
    return (
      <>
        {/* if menu item has icon */}
        {item.icon && this.props.color === "light" ? (
          <i
            style={{ color: "rgb(0 140 255)" }}
            className={`kt-menu__link-icon ${item.icon}`}
          />
        ) : item.icon ? (
          <i className={`kt-menu__link-icon ${item.icon}`} />
        ) : null}

        {/* if menu item using bullet */}
        {!item.icon && (item.bullet || (parentItem && parentItem.bullet)) && (
          <i
            className={`kt-menu__link-bullet ${this.itemCssClassWithBullet(
              item,
              parentItem
            )}`}
          >
            <span />
          </i>
        )}

        {!item.badge ? (
          <>
            <span className="kt-menu__item-here btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-3" />
            {/* menu item title text */}
            {this.props.color === "light" ? (
              <span
                className="kt-menu__link-text"
                style={{ color: "rgb(72 70 91)" }}
              >
                {item.title}
              </span>
            ) : (
              <span className="kt-menu__link-text">{item.title}</span>
            )}
          </>
        ) : (
          <>
            {/* menu item with badge */}
            <span className="kt-menu__link-text">{item.title}</span>
            <span className="kt-menu__link-badge">
              <span
                className={`kt-badge kt-badge--brand kt-badge--inline kt-badge--pill ${item.badge.type} `}
              >
                {item.badge.value}
              </span>
            </span>
          </>
        )}
      </>
    );
  }
}
