import React, { Component } from "react";
import { BSON } from "realm-web";
import { DataContext } from "../../../context/dataContext";
import ProductionLineModal from "./ProductionLineModal";

interface ProductionLineAddWidgetProps {
  updateProductionLines: () => void;
  selectedManufacturer: BSON.ObjectId | string | null;
}

interface ProductionLineAddWidgetState {
  image: string;
  productionLineName: string;
  selectedManufacturer: string;
  showAddModal: boolean;
}

class ProductionLineAddWidget extends Component<ProductionLineAddWidgetProps, ProductionLineAddWidgetState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: ProductionLineAddWidgetProps) {
    super(props);
    this.state = {
      image: "",
      productionLineName: "",
      selectedManufacturer: props.selectedManufacturer ? props.selectedManufacturer.toString() : "",
      showAddModal: false
    };
  }

  render() {
    const { selectedManufacturer, updateProductionLines } = this.props;
    const { showAddModal } = this.state;

    return (
      <>
        <div className="col-12 col-xl-3">
          <div
            className="kt-portlet table-hover"
            onClick={() => this.setState({ showAddModal: true })}
            style={{ borderRadius: "10px" }}
          >
            <div className="kt-portlet__body" style={{ height: "100px" }}>
              <div className="text-center h-100">
                <h2 className="h-100 d-inline-flex align-items-center" style={{ fontSize: "30px" }}>
                  <i className="flaticon2-plus mr-2" />
                  New machine
                </h2>
              </div>
            </div>
          </div>
        </div>
        <ProductionLineModal
          onHide={() => this.setState({ showAddModal: false })}
          selectedManufacturer={selectedManufacturer}
          show={showAddModal}
          updateProductionLines={updateProductionLines}
          context={this.context}
        />
      </>
    );
  }
}
export default ProductionLineAddWidget;
