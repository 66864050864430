import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import Select from "react-select";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { Nav, OverlayTrigger, Tab, Tooltip } from "react-bootstrap";
import countryList from "i18n-iso-countries";
import { DataContext } from "../../context/dataContext";
import { SuppliersDocument } from "../../model/suppliers.types";
import baseUtils from "../../utils/baseUtils";
import dbService, { SUPPLIERS } from "../../services/dbService";
import SplashScreen from "../common/SplashScreen";
import PersonWidget from "../common/PersonWidget";
import SupplierInformation from "./SupplierInformation";
import SelectEmployee from "../common/SelectEmployee";
import { UserdataDocument } from "../../model/userdata.types";
import SupplierDocuments from "./SupplierDocuments";
import DisableSupplierModal from "./modals/DisableSupplierModal";

interface SupplierParams {
  id: string;
}

interface SupplierProps extends RouteComponentProps<SupplierParams, {}, {}> {
  context: React.ContextType<typeof DataContext>;
}

interface SupplierState {
  supplier?: SuppliersDocument;
  activeTab: string;
  saving: boolean;
}

class Supplier extends PureComponent<SupplierProps, SupplierState> {
  _isMounted = false;
  _id: string | undefined;
  supplier: SuppliersDocument | undefined;
  constructor(props: SupplierProps) {
    super(props);
    this._id = props.match.params.id;
    this.state = {
      activeTab: "settings",
      saving: false
    };
  }

  componentDidMount() {
    this.initializeData();
  }

  componentDidUpdate = async (
    prevProps: Readonly<SupplierProps>,
    prevState: Readonly<SupplierState>,
    snapshot?: any
  ) => {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this._id = this.props.match.params.id;
      await this.initializeData();
    } else if (this._id) {
      const supplierCurrent = baseUtils.getDocFromCollection(this.props.context.suppliers, this._id);
      if (!_.isEqual(supplierCurrent, this.supplier)) {
        this.supplier = supplierCurrent;
        this.setState({ supplier: supplierCurrent });
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  initializeData = async () => {
    const { history, context } = this.props;
    const { suppliers } = context;
    if (!this._id || !BSON.ObjectId.isValid(this._id)) {
      history.push("/suppliers");
      return;
    }
    this._isMounted = true;

    let supplier = _.cloneDeep(baseUtils.getDocFromCollection(suppliers, this._id));
    if (!supplier) {
      supplier = await dbService.getDocumentFromCollection(SUPPLIERS, this._id);
      if (!supplier) {
        console.error("No supplier could be loaded for id", this._id);
        history.push("/suppliers");
        return;
      }
      context.addDocuments(SUPPLIERS, [supplier]);
    }
    if (this._isMounted) {
      this.supplier = supplier;
      this.setState({ supplier: supplier });
    }
  };

  /**
   * Handle changing the owner of the supplier
   * @param owner the new owner
   */
  handleOwnerChange = (owner: UserdataDocument | undefined) => {
    const supplier = _.cloneDeep(this.state.supplier);
    if (supplier) {
      supplier.owner = owner ? owner._id : undefined;
      this.setState({ supplier });
    }
  };

  /**
   * Handles the selection of suppliers
   * @param value the value the supplier rating gets set to
   */
  handleSupplierSelect = (value: { value: string; label: string }) => {
    const supplier = _.cloneDeep(this.state.supplier);
    if (supplier) {
      _.set(supplier, "rating", value.label);
      this.setState({ supplier });
    }
  };

  /**
   * Handle changes to supplier data
   * @param e React.ChangeEvent
   */
  handleSupplierChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const supplier = _.cloneDeep(this.state.supplier);
    if (supplier) {
      _.set(supplier, e.target.name, e.target.value);
      this.setState({ supplier });
    }
  };

  /**
   * Changes the country of an adress
   * @param value the country gets set to
   * @param index of the address that gets changed
   */
  handleAddressSelect = (value: { value: string; label: string }, index: number) => {
    const supplier = _.cloneDeep(this.state.supplier);
    if (supplier) {
      const address = supplier.address[index];
      _.set(address, "country", value.label);
      this.setState({ supplier });
    }
  };
  /**
   * Change an address
   * @param e React.ChangeEvent
   * @param index the index of the address to change
   */
  handleAddressChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
    const supplier = _.cloneDeep(this.state.supplier);
    if (supplier) {
      const address = supplier.address[index];
      _.set(address, e.target.name, e.target.value);
      this.setState({ supplier });
    }
  };

  /**
   * Add a new empty address
   */
  handleAddAddress = () => {
    const supplier = _.cloneDeep(this.state.supplier);
    if (supplier) {
      supplier.address.push({ street: "", streetnr: "", zip: "", city: "", country: "Germany" });
      this.setState({ supplier });
    }
  };

  /**
   * Remove an address
   * @param index index to remove
   */
  handleRemoveAddress = (index: number) => {
    const supplier = _.cloneDeep(this.state.supplier);
    if (supplier && supplier.address.length > 1) {
      supplier.address.splice(index, 1);
      this.setState({ supplier });
    }
  };

  /**
   * Changes a contact
   * @param value value the contact gets set to
   * @param index index of the contact
   */
  handleContactChange = (value: { value: string; label: string }, index: number) => {
    const supplier = _.cloneDeep(this.state.supplier);
    if (supplier && BSON.ObjectId.isValid(value.value)) {
      supplier.person.splice(index, 1, new BSON.ObjectId(value.value));
      this.setState({ supplier });
    }
  };

  /**
   * Add a new contact
   * @param contact the objectid of the contact person
   */
  handleAddContact = (contact: BSON.ObjectId) => {
    const supplier = _.cloneDeep(this.state.supplier);
    if (supplier) {
      supplier.person.push(contact);
      this.setState({ supplier });
    }
  };

  /**
   * Remove a contact
   * @param index the index of the contact to remove
   */
  handleRemoveContact = (index: number) => {
    const supplier = _.cloneDeep(this.state.supplier);
    if (supplier && supplier.person.length > 1) {
      supplier.person.splice(index, 1);

      this.setState({ supplier });
    }
  };

  /**
   * Change tab
   * @param tab the new tab
   */
  handleTabChange = (tab: string) => this.setState({ activeTab: tab });

  /**
   * Save changes to database
   */
  handleSave = async () => {
    const { supplier } = this.state;
    if (!supplier) return;
    this.setState({ saving: true });
    try {
      const result = await dbService.updateDocument(SUPPLIERS, supplier._id, _.omit(supplier, "_id"));
      if (result && result.modifiedCount) toast.success("Supplier successfully updated");
      else toast.error("Supplier could not be updated");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ saving: false });
    }
  };

  /**
   * Check the current input for errors.
   * @returns { Array<string> } Contains all validation errors
   */
  checkForErrors = () => {
    const { supplier } = this.state;
    const errors: Array<string> = [];
    if (!supplier) return errors;
    if (!supplier.owner) errors.push("No owner selected");
    if (supplier.name.trim().length < 2) errors.push("Supplier name too short");
    if (supplier.address.length > 1) {
      for (let i = 0; i < supplier.address.length; i++) {
        if (!supplier.address[i].street.trim()) errors.push("Address " + i + ": Street missing");
        if (!supplier.address[i].streetnr.trim()) errors.push("Address " + i + ": Street number missing");
        if (!supplier.address[i].zip.trim()) errors.push("Address " + i + ": Zip missing");
        if (!supplier.address[i].city.trim()) errors.push("Address " + i + ": City missing");
        if (!supplier.address[i].country.trim()) errors.push("Address " + i + ": Country missing");
      }
    }
    return errors;
  };

  render() {
    const { context } = this.props;
    const { activeTab, supplier, saving } = this.state;
    const { userdata } = context;
    if (!this.supplier || !supplier)
      return (
        <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid ">
          <div className="kt-portlet ">
            <div className="kt-portlet__body" style={{ minHeight: "80vh" }}>
              <SplashScreen
                additionalSVGStyle={{
                  height: "80px",
                  width: "80px"
                }}
              />
            </div>
          </div>
        </div>
      );
    else {
      const { name, address, person, owner } = this.supplier;
      const contactPerson = person.length > 0 ? baseUtils.getDocFromCollection(userdata, person[0]) : undefined;
      const ownerObject = baseUtils.getDocFromCollection(userdata, owner);
      const currentOwner = supplier.owner ? baseUtils.getDocFromCollection(userdata, supplier.owner) : undefined;
      const possibleContacts = userdata
        .filter(u => u.company_id !== "internal")
        .sort((u1, u2) => u1.surname.localeCompare(u2.surname));
      const nameSplit = name.split(/[ -]+/);
      const errors = this.checkForErrors();
      return (
        <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <div className="kt-widget kt-widget--user-profile-3">
                <div className="kt-widget__top">
                  <div className="kt-widget__media">
                    <div className="kt-badge kt-badge--xl kt-badge--primary">
                      {name
                        ? (nameSplit.length > 1 ? nameSplit[0][0] + nameSplit[1][0] : name.slice(0, 2)).toUpperCase()
                        : "?"}
                    </div>
                  </div>
                  <div className="kt-widget__content">
                    <div className="kt-widget__head">
                      <div className="kt-widget__user">
                        <span className={"kt-widget__username " + (supplier.disabled ? "text-danger" : "kt-font-dark")}>
                          {supplier.disabled && "[DISABLED] "}
                          {name}
                        </span>
                        <span className="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-success">
                          Supplier
                        </span>
                      </div>
                      <div className="kt-widget__action">
                        <DisableSupplierModal supplier={supplier} />
                      </div>
                    </div>
                    <div className="kt-widget__subhead">
                      <span style={{ fontWeight: 500, color: "#74788d" }}>
                        <i className="flaticon2-placeholder pr-2" />
                        {address.length > 0
                          ? `${address[0].street} ${address[0].streetnr}, ${address[0].zip} ${address[0].city}, ${address[0].country}`
                          : "Unknown"}
                      </span>
                    </div>
                    <div className="kt-user-card-v2 inline-flex mr-3 mt-1">
                      {contactPerson && <PersonWidget person={contactPerson} />}
                    </div>
                    <div className="kt-user-card-v2 inline-flex mt-1">
                      {!!ownerObject && <PersonWidget person={ownerObject} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4">
              <div className="kt-portlet kt-portlet--head-noborder">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">Note</h3>
                  </div>
                  <div className="kt-portlet__head-toolbar" />
                </div>
                <div className="kt-portlet__body kt-portlet__body--fit-top">
                  <div className="kt-section kt-section--space-sm">
                    {this.supplier.additionalInformation
                      ? this.supplier.additionalInformation
                      : "Important information about the supplier can be stored here so that this information is visible to everyone."}
                  </div>
                  <div className="kt-section kt-section--last">
                    <button
                      onClick={() => this.handleTabChange("settings")}
                      className="btn btn-label-brand btn-sm btn-bold"
                    >
                      Update Note
                    </button>
                  </div>
                </div>
              </div>
              <SupplierInformation
                supplier={this.supplier}
                contact={contactPerson}
                onTabChange={this.handleTabChange}
              />
            </div>
            <div className="col-xl-8">
              <Tab.Container
                id="tabContainer"
                activeKey={activeTab}
                onSelect={e => this.handleTabChange(e || "settings")}
              >
                <div className="kt-portlet kt-portlet--tabs mb-0">
                  <div className="kt-portlet__head">
                    <div className="kt-portlet__head-toolbar">
                      <ul
                        id={"packagingTabPanelTabs"}
                        className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand"
                        role="tablist"
                      >
                        <li className="nav-item" key="settings">
                          <Nav.Link role="tab" id={"settings"} eventKey={"settings"} active={"settings" === activeTab}>
                            <i className="flaticon2-gear" />
                            Settings
                          </Nav.Link>
                        </li>
                        <li className="nav-item" key="documents">
                          <Nav.Link
                            role="tab"
                            id={"documents"}
                            eventKey={"documents"}
                            active={"documents" === activeTab}
                          >
                            <i className="flaticon2-file-1" />
                            Documents
                          </Nav.Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="kt-portlet__body">
                    <Tab.Content>
                      <Tab.Pane key={"settings"} eventKey={"settings"} transition={false}>
                        <div className="kt-form kt-form--label-right">
                          <div className="kt-form__body">
                            <div className="kt-section kt-section--first">
                              <div className="kt-section__body">
                                <div className="row">
                                  <label className="col-lg-3" />
                                  <div className="col-lg-9 col-xl-6" />
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Note</label>
                                  <div className="col-lg-9 col-xl-6">
                                    <textarea
                                      className="form-control"
                                      onChange={this.handleSupplierChange}
                                      value={supplier.additionalInformation}
                                      name="additionalInformation"
                                      rows={3}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Rating</label>
                                  <div className="col-lg-9 col-xl-6">
                                    <Select
                                      className="select-default"
                                      options={[
                                        { value: "A", label: "A" },
                                        { value: "B", label: "B" },
                                        { value: "C", label: "C" }
                                      ]}
                                      value={
                                        supplier.rating !== ""
                                          ? { value: supplier.rating, label: supplier.rating.toString() }
                                          : {
                                              value: "",
                                              label: "Not set"
                                            }
                                      }
                                      onChange={(value: any) => this.handleSupplierSelect(value)}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Name & Address:</label>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Supplier Name</label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      className="form-control"
                                      type="text"
                                      onChange={this.handleSupplierChange}
                                      value={supplier.name}
                                      name="name"
                                    />
                                  </div>
                                </div>
                                {supplier.address.map((addr, index) => (
                                  <React.Fragment key={index.toString()}>
                                    <div className="form-group row">
                                      <label className="col-lg-3 col-form-label">
                                        {index === 0 ? "Primary Address" : "Address #" + (index + 1)}
                                      </label>
                                      <div className={"col-lg-3 col-xl-3"}>
                                        <input
                                          className="form-control"
                                          type="text"
                                          onChange={e => this.handleAddressChange(e, index)}
                                          value={addr.street}
                                          name="street"
                                          placeholder={"Street"}
                                        />
                                      </div>
                                      <div className="col-lg-2">
                                        <input
                                          className="form-control"
                                          type="text"
                                          onChange={e => this.handleAddressChange(e, index)}
                                          value={addr.streetnr}
                                          name="streetnr"
                                          placeholder={"Street Nr"}
                                        />
                                      </div>
                                      <div className="col-1 text-right">
                                        <button
                                          className="btn btn-danger"
                                          disabled={supplier.address.length === 1}
                                          onClick={() => this.handleRemoveAddress(index)}
                                        >
                                          <i className="fa fa-trash pr-0" />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-lg-3 col-form-label" />
                                      <div className="col-lg-2 col-xl-2">
                                        <input
                                          className="form-control"
                                          type="text"
                                          onChange={e => this.handleAddressChange(e, index)}
                                          value={addr.zip}
                                          name="zip"
                                          placeholder={"Zip"}
                                        />
                                      </div>
                                      <div className="col-lg-2">
                                        <input
                                          className="form-control"
                                          type="text"
                                          onChange={e => this.handleAddressChange(e, index)}
                                          value={addr.city}
                                          name="city"
                                          placeholder={"City"}
                                        />
                                      </div>
                                      <div className="col-lg-2">
                                        <Select
                                          className="select-default"
                                          options={Object.values(countryList.getNames("en")).map((item: string) => {
                                            return {
                                              value: item,
                                              label: _.upperFirst(item)
                                            };
                                          })}
                                          value={
                                            addr.country !== ""
                                              ? { value: addr.country, label: addr.country }
                                              : {
                                                  value: "",
                                                  label: "Select Country"
                                                }
                                          }
                                          onChange={(value: any) => this.handleAddressSelect(value, index)}
                                        />
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ))}
                                <div className="form-group row">
                                  <label className="col-3 col-form-label" />
                                  <div className="col-9 col-xl-6 text-right">
                                    <button className="btn btn-success" onClick={this.handleAddAddress}>
                                      <i className="fa fa-plus pr-0" />
                                    </button>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Contacts:</label>
                                </div>
                                {supplier.person.map((contact, index) => {
                                  const user = userdata.find(u => u._id.toString() === contact.toString())!;
                                  return (
                                    <div className="form-group row" key={contact.toString()}>
                                      <label className="col-lg-3 col-form-label">
                                        {index === 0 ? "Primary Contact Person" : "Contact #" + (index + 1)}
                                      </label>
                                      <div className="col-lg-8 col-xl-5">
                                        <Select
                                          className="select-default "
                                          options={possibleContacts.map(person => {
                                            return {
                                              value: person._id.toString(),
                                              label: person.prename + " " + person.surname
                                            };
                                          })}
                                          value={{
                                            value: contact.toString(),
                                            label: user.prename + " " + user.surname
                                          }}
                                          onChange={(value: any) => this.handleContactChange(value || "", index)}
                                        />
                                      </div>
                                      <div className="col-1 text-right">
                                        <button
                                          className="btn btn-danger"
                                          disabled={supplier.person.length === 1}
                                          onClick={() => this.handleRemoveContact(index)}
                                        >
                                          <i className="fa fa-trash pr-0" />
                                        </button>
                                      </div>
                                    </div>
                                  );
                                })}
                                <div className="form-group row">
                                  <div className="col-3" />
                                  <div className="col-9 col-xl-6 text-right">
                                    <button
                                      className="btn btn-success"
                                      onClick={() => this.handleAddContact(possibleContacts[0]._id)}
                                    >
                                      <i className="fa fa-plus pr-0" />
                                    </button>
                                  </div>
                                </div>
                                <div className="row">
                                  <label className="col-lg-3" />
                                  <div className="col-lg-9 col-xl-6">
                                    <h3 className="kt-section__title kt-section__title-sm">Owner:</h3>
                                    <SelectEmployee
                                      userdata={userdata}
                                      selectedUser={currentOwner}
                                      onSelectUser={this.handleOwnerChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="kt-separator kt-separator--space-lg kt-separator--fit kt-separator--border-solid" />
                            <div className="kt-form__actions text-right">
                              <button
                                className="btn btn-secondary mr-2"
                                onClick={() => this.setState({ supplier: this.supplier })}
                              >
                                Reset
                              </button>
                              {errors.length > 0 ? (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="supplierErrors">
                                      {errors.map((e, key) => {
                                        return (
                                          <React.Fragment key={key}>
                                            <span className="text-danger">
                                              <b>{e}</b>
                                            </span>
                                            <br />
                                          </React.Fragment>
                                        );
                                      })}
                                    </Tooltip>
                                  }
                                  placement="left"
                                >
                                  <button className="btn btn-success disabled">Save Changes</button>
                                </OverlayTrigger>
                              ) : (
                                <button className="btn btn-success" disabled={saving} onClick={this.handleSave}>
                                  {saving && (
                                    <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                                      <svg className="button-splash-spinner" viewBox="0 0 50 50">
                                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                                      </svg>
                                    </div>
                                  )}
                                  Save Changes
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane key={"documents"} eventKey={"documents"} transition={false}>
                        <SupplierDocuments supplier={supplier} />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Supplier;
