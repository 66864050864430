import React from "react";
import Select from "react-select";
import { NewPackagingUnitValues } from "../BookOutOverview";
import i18n from "../../../../../translations/i18n";

interface BookOutOverviewNewPackagingUnitsProps {
  newPackagingUnits: Array<NewPackagingUnitValues>;
}

export const BookOutOverviewNewPackagingUnits: React.FC<BookOutOverviewNewPackagingUnitsProps> = ({
  newPackagingUnits
}) => {
  if (newPackagingUnits.length === 0) return null;

  return (
    <>
      <hr className="w-100 my-4" />
      <div
        className="card card-custom card-fit card-border my-3"
        style={{
          boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.05)"
        }}
      >
        <div className="card-body">
          <div className="font-size-lg text-black d-block my-2 font-weight-normal">{i18n.t("warehouse:leftOvers")}</div>
          <hr />
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive px-2 mb-0">
            <table className="kt-datatable__table d-table p-5">
              <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
                <tr className="kt-datatable__row d-table-row">
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "13%" }}>
                    <span>{i18n.t("common:quantity")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "12%" }}>
                    <span>{i18n.t("warehouse:restAmount")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "25%" }}>
                    <span>{i18n.t("warehouse:packagingUnit")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "25%" }}>
                    <span>{i18n.t("warehouse:warehouseShort")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "25%" }}>
                    <span>{i18n.t("warehouse:storageSpace")}</span>
                  </th>
                </tr>
              </thead>
              <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                {newPackagingUnits.map((nPU, idx) => (
                  <BookOutOverviewNewPackagingUnit
                    key={nPU._id}
                    newPackagingUnit={nPU}
                    noBorder={idx === newPackagingUnits.length - 1}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

interface BookOutOverviewNewPackagingUnitProps {
  newPackagingUnit: NewPackagingUnitValues;
  noBorder?: boolean;
}

const BookOutOverviewNewPackagingUnit: React.FC<BookOutOverviewNewPackagingUnitProps> = ({
  newPackagingUnit,
  noBorder
}) => {
  const { selectedStorageSpace, selectedWarehouseArea, selectedPU, amount, quantity, unit } = newPackagingUnit;
  return (
    <tr className={"kt-datatable__row d-table-row " + (noBorder && "border-0")}>
      <td className="kt-datatable__cell d-table-cell py-1">
        <input
          type={"number"}
          min={0}
          disabled
          className={"form-control form-control-solid hide-arrows not-allowed-cursor"}
          value={quantity}
        />
      </td>
      <td className="kt-datatable__cell d-table-cell py-1">
        <div className="input-group not-allowed-cursor">
          <input type={"text"} disabled className="form-control form-control-solid not-allowed-cursor" value={amount} />
          <div className="input-group-append">
            <span className="input-group-text input-group-solid">{unit}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell py-1">
        <div className="not-allowed-cursor">
          <Select
            className="select-warehouse"
            classNamePrefix="select-warehouse"
            isDisabled={true}
            value={selectedPU}
            options={[]}
          />
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell py-1">
        <div className="not-allowed-cursor">
          <Select
            className="select-warehouse"
            classNamePrefix="select-warehouse"
            isDisabled={true}
            value={selectedWarehouseArea}
            options={[]}
          />
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell py-1">
        <div className="not-allowed-cursor">
          <Select
            className="select-warehouse "
            classNamePrefix="select-warehouse"
            isDisabled={true}
            value={selectedStorageSpace || { label: i18n.t("warehouse:entrance"), value: "", object: null }}
            options={[]}
          />
        </div>
      </td>
    </tr>
  );
};
