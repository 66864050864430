import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import manufacturerUtils from "../../utils/manufacturerUtils";
import { PaginationState } from "../common/CustomTypes";
import orderUtils, { REQUESTPENDING } from "../../utils/orderUtils";
import { OrdersDocument } from "../../model/orders.types";
import SplashScreen from "../common/SplashScreen";
import { paginate } from "../common/Pagination";
import { ManufacturerFilter, OwnerFilter, PriorityFilter, SearchBar } from "./common/Filters";
import HistoryBackButton from "./common/HistoryBackButton";
import BaseListing from "./BaseListing";
import baseUtils, { getComponentState } from "../../utils/baseUtils";
import CompanyWidget from "../common/CompanyWidget";
import { CreatedOnBadge, InCheckSinceBadge, OrderWidget, PriorityBadge } from "./common/BaseListingComponents";
import PersonWidget from "../common/PersonWidget";
import { T_REQUESTPENDING } from "../../utils/timelineUtils";

interface CheckCommoditiesProps extends RouteComponentProps {}

interface CheckCommoditiesState extends PaginationState {
  search: string;
  owner: "" | { value: string; label: string };
  manufacturer: "" | { value: string; label: string };
  priority: "" | { value: string; label: string };
  manufacturerLocked: boolean;
}

const CONSTRUCTORNAME = "CheckCommodities";

class CheckCommodities extends PureComponent<CheckCommoditiesProps, CheckCommoditiesState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: CheckCommoditiesProps, context: React.ContextType<typeof DataContext>) {
    super(props, context);
    this.state = this.getDefaultState(context);
  }

  componentDidMount() {
    const state = getComponentState(this.context, CONSTRUCTORNAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }

  handleReset = () => this.setState(this.getDefaultState(this.context));
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value, currentPage: 1 });
  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleSelectChange = (name: string, entry: "" | { value: string; label: string }) =>
    // @ts-ignore
    this.setState({ [name]: entry, currentPage: 1 });

  /**
   * Get the default state
   * @param context data context
   * @returns {CheckCommoditiesState} default state for the component
   */
  getDefaultState = (context: React.ContextType<typeof DataContext>) => {
    const manufacturer = manufacturerUtils.checkCurrentUserManufacturerObject(context.manufacturers);
    return {
      currentPage: 1,
      pageSize: 10,
      search: "",
      owner: "",
      manufacturer: manufacturer,
      priority: "",
      manufacturerLocked: manufacturer !== ""
    } as CheckCommoditiesState;
  };

  /**
   * Get offers where commodities need to be checked
   * @returns {Array<OrdersDocument>} list of orders
   */
  getCheckCommoditiesOffers = () => {
    const { orders, companies, userdata } = this.context;
    const { search, owner, priority, manufacturer } = this.state;
    let ccOffers = orderUtils.filterOrders(
      orders,
      REQUESTPENDING,
      owner ? owner.value : "",
      priority ? priority.value : "",
      undefined,
      manufacturer ? manufacturer.value : ""
    );
    if (search) {
      ccOffers = orderUtils.filterBySearch(ccOffers, companies, userdata, search) as Array<OrdersDocument>;
    }
    return ccOffers.sort((o1, o2) => {
      const commodityChecksO1 = o1.timeline.filter(t => t.type === T_REQUESTPENDING);
      const commodityChecksO2 = o2.timeline.filter(t => t.type === T_REQUESTPENDING);
      if (commodityChecksO1.length === 0 || commodityChecksO2.length === 0) return 0;
      const latestCheckO1 = commodityChecksO1[commodityChecksO1.length - 1].date;
      const latestCheckO2 = commodityChecksO2[commodityChecksO2.length - 1].date;
      return latestCheckO1.getTime() - latestCheckO2.getTime();
    });
  };

  render() {
    const { orders, userdata, manufacturers } = this.context;
    const { history } = this.props;
    const { pageSize, currentPage, owner, priority, manufacturer, manufacturerLocked, search } = this.state;
    const ccOffers = this.getCheckCommoditiesOffers();
    const selectableUsers = userdata.filter(user => user.company_id === "internal");
    const headerDefinition = [
      { title: "Description", size: 20 },
      { title: "Company", size: 20 },
      { title: "Created", size: 6 },
      { title: "Check since", size: 6 },
      { title: "Priority", size: 6 },
      { title: "Commodities", size: 8 },
      { title: "Requested", size: 9 },
      { title: "Updated", size: 9 },
      { title: "Owner", size: 15 }
    ];
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand flaticon2-analytics" />
            </span>
            <h3 className="kt-portlet__head-title">Approval Required</h3>
            <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
              Reset
            </button>
          </div>
          <HistoryBackButton history={history} />
        </div>
        <div className="kt-portlet__body pb-0">
          <div className="kt-form kt-form--label-right  kt-margin-b-10">
            <div className="row align-items-center">
              <SearchBar onSearch={this.handleSearch} search={search} />
              <OwnerFilter owner={owner} selectableUsers={selectableUsers} onFilterChange={this.handleSelectChange} />
              <PriorityFilter priority={priority} onFilterSelect={this.handleSelectChange} />
              <ManufacturerFilter
                manufacturer={manufacturer}
                manufacturerLocked={manufacturerLocked}
                manufacturers={manufacturers}
                onFilterSelect={this.handleSelectChange}
              />
            </div>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          {orders.length === 0 ? (
            <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
          ) : (
            <BaseListing
              headerDefinition={headerDefinition}
              documents={ccOffers}
              bodyContent={
                <>
                  {paginate(ccOffers, currentPage, pageSize).map(o => (
                    <CheckCommoditiesRow key={o._id.toString()} offer={o} context={this.context} {...this.props} />
                  ))}
                </>
              }
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
            />
          )}
        </div>
      </div>
    );
  }
}

interface CheckCommoditiesRowProps extends RouteComponentProps {
  offer: OrdersDocument;
  context: React.ContextType<typeof DataContext>;
}

const CheckCommoditiesRow: React.FunctionComponent<CheckCommoditiesRowProps> = ({ offer, context, history }) => {
  const { userdata, companies } = context;
  const company = baseUtils.getDocFromCollection(companies, offer.createdFor);
  const owner = baseUtils.getDocFromCollection(userdata, offer.createdFrom);
  const forwardOrder = () => history.push("/order/" + offer._id.toString());

  return (
    <tr className="kt-datatable__row d-table-row table-hover">
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <OrderWidget document={offer} prefix={"Offer AN-"} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <CompanyWidget company={company} type={"company"} />
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <CreatedOnBadge document={offer} />
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <InCheckSinceBadge document={offer} />
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <PriorityBadge document={offer} />
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">
              {offer.recipe.length + (offer.recipe.length === 1 ? " Commodity" : " Commodities")}
            </span>
          </div>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span>
          <div className="progress">
            <div
              className="progress-bar bg-warning"
              role="progressbar"
              style={{ width: orderUtils.getCommodityPercentageDone(offer.calculations[0].prices, "requested") }}
            />
          </div>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardOrder}>
        <span>
          <div className="progress">
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: orderUtils.getCommodityPercentageDone(offer.calculations[0].prices, "updated") }}
            />
          </div>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <PersonWidget person={owner} />
        </span>
      </td>
    </tr>
  );
};

export default CheckCommodities;
