import _ from "lodash";
import React, { Component } from "react";
import { PaginationState } from "../common/CustomTypes";
import { ProductionWeekDocument } from "../../model/productionPlan.types";
import { OrdersDocument } from "../../model/orders.types";
import { DataContext } from "../../context/dataContext";
import Pagination, { paginate } from "../common/Pagination";
import ProductionPlanHelper from "./ProductionPlanHelper";
import ProductionPlanWeekRow from "./ProductionPlanWeekRow";

interface ProductionPlanWeekProps {
  capacity: number;
  manufacturer: string;
  week: ProductionWeekDocument;
  orders: Array<OrdersDocument>;
  relevantOrders: Array<OrdersDocument>;
  context: React.ContextType<typeof DataContext>;
  onRescheduleOrder: (order: OrdersDocument) => void;
}

interface ProductionPlanWeekState extends PaginationState {}

class ProductionPlanWeek extends Component<ProductionPlanWeekProps, ProductionPlanWeekState> {
  constructor(props: ProductionPlanWeekProps) {
    super(props);
    this.state = {
      pageSize: 10,
      currentPage: 1
    };
  }

  componentDidUpdate(
    prevProps: Readonly<ProductionPlanWeekProps>,
    prevState: Readonly<ProductionPlanWeekState>,
    snapshot?: any
  ) {
    if (prevProps.orders.length > this.props.orders.length) {
      // Reset current page if amount of orders decreased, e.g. due to search
      this.setState({ currentPage: 1 });
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<ProductionPlanWeekProps>,
    nextState: Readonly<ProductionPlanWeekState>,
    nextContext: any
  ): boolean {
    return (
      !_.isEqual(nextProps.orders, this.props.orders) ||
      !_.isEqual(nextProps.relevantOrders, this.props.relevantOrders) ||
      nextProps.manufacturer !== this.props.manufacturer ||
      nextProps.capacity !== this.props.capacity ||
      this.state.currentPage !== nextState.currentPage ||
      this.state.pageSize !== nextState.pageSize
    );
  }

  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });

  render() {
    const { week, orders, relevantOrders, context, onRescheduleOrder, capacity, manufacturer } = this.props;
    const { currentPage, pageSize } = this.state;
    const today = new Date();
    const startDate = new Date(week.startDate.valueOf() + week.startDate.getTimezoneOffset() * 60 * 1000);
    const endDate = new Date(week.endDate.valueOf() + week.endDate.getTimezoneOffset() * 60 * 1000);
    const isCurrent = week.startDate.getTime() <= today.getTime() && today.getTime() <= week.endDate.getTime();
    const [reservedUnits, typeString] = ProductionPlanHelper.getPlannedOrdersInformation(
      week.plannedOrders,
      relevantOrders,
      context,
      undefined,
      manufacturer
    );
    const producedUnits = ProductionPlanHelper.getProducedUnitsForWeek(context.orders, week, manufacturer);
    const totalReservedUnits = reservedUnits + producedUnits;
    let percentage = (totalReservedUnits / capacity) * 100;
    let color = "success";
    if (percentage >= 100) color = "danger";
    else if (percentage >= 80) color = "warning";
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div
          className="kt-portlet__head kt-portlet__head--lg overflow-auto"
          style={{ backgroundColor: "#fafafa", borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
        >
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <div className="kt-badge kt-badge--lg kt-badge--primary">{week.week.split("-")[0]}</div>
            </span>
            <h4 className="kt-font-dark kt-font-bold">
              Week {week.week}
              {": "}
              <small>
                {startDate.toLocaleDateString("de-DE", {
                  month: "numeric",
                  day: "numeric",
                  year: "numeric"
                })}{" "}
                -{" "}
                {endDate.toLocaleDateString("de-DE", {
                  month: "numeric",
                  day: "numeric",
                  year: "numeric"
                })}
              </small>
            </h4>
            {isCurrent && (
              <span className="kt-badge kt-badge--primary kt-badge--inline kt-badge--pill ml-3 float-right">
                <b>Current Week</b>
              </span>
            )}
            <span className="kt-font-dark kt-font-bold ml-5">
              {totalReservedUnits} of {capacity} units
            </span>
            <div style={{ width: "200px" }} className={"ml-3 text-center"}>
              <div className="progress" style={{ height: "10px" }}>
                <div className={"progress-bar bg-" + color} role="progressbar" style={{ width: percentage + "%" }} />
              </div>
            </div>
          </div>
          {week.plannedOrders.length > 0 && (
            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-wrapper">
                <h4 className="kt-font-dark kt-font-bold mr-4 mt-2">{typeString}</h4>
              </div>
            </div>
          )}
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          {orders.length > 0 && (
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
              <table className="kt-datatable__table d-table" style={{ overflow: "visible" }}>
                <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
                  <tr className="kt-datatable__row d-table-row">
                    <th className={"kt-datatable__cell d-table-cell "} style={{ width: "14%" }}>
                      <span>Order</span>
                    </th>
                    <th className={"kt-datatable__cell d-table-cell "} style={{ width: "12%" }}>
                      <span>Customer</span>
                    </th>
                    <th className={"kt-datatable__cell d-table-cell "} style={{ width: "10%" }}>
                      <span>Owner</span>
                    </th>
                    <th className={"kt-datatable__cell d-table-cell text-center"} style={{ width: "8%" }}>
                      <span>Type</span>
                    </th>
                    <th className={"kt-datatable__cell d-table-cell "} style={{ width: "7%" }}>
                      <span>Priority</span>
                    </th>
                    <th className={"kt-datatable__cell d-table-cell "} style={{ width: "7%" }}>
                      <span>Target Week</span>
                    </th>
                    <th className={"kt-datatable__cell d-table-cell text-center"} style={{ width: "10%" }}>
                      <span>Total Amount</span>
                    </th>
                    <th className={"kt-datatable__cell d-table-cell "} style={{ width: "10%" }}>
                      <span>Packaging</span>
                    </th>
                    <th className={"kt-datatable__cell d-table-cell "} style={{ width: "10%" }}>
                      <span> Status</span>
                    </th>
                    <th className={"kt-datatable__cell d-table-cell "} style={{ width: "12%" }}>
                      <span>Action</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                  {paginate(orders, currentPage, pageSize).map(o => {
                    return (
                      <ProductionPlanWeekRow
                        key={o._id.toString()}
                        order={o}
                        context={context}
                        onRescheduleOrder={() => onRescheduleOrder(o)}
                      />
                    );
                  })}
                </tbody>
              </table>
              <div className="kt-datatable__pager kt-datatable--paging-loaded justify-content-center">
                <Pagination
                  itemsCount={orders.length}
                  pageSize={pageSize}
                  onPageChange={this.handlePageChange}
                  currentPage={currentPage}
                  onPageSizeChange={this.handlePageSizeChange}
                  additionalPageSizeClasses={"ml-2"}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ProductionPlanWeek;
