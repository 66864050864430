import _ from "lodash";
import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BSON } from "realm-web";
import CreateNewSettingValueModal from "./CreateNewSettingValueModal";
import DeleteSettingValueModal from "./DeleteSettingValueModal";
import { PaginationState } from "../common/CustomTypes";
import { DataContext } from "../../context/dataContext";
import Pagination, { paginate } from "../common/Pagination";
import { SettingsDocument } from "../../model/customTypes.types";
import { SearchBar } from "../listings/common/Filters";

interface SettingsTableProps extends RouteComponentProps<{}, {}, {}> {
  setting:
    | "ActiveSubstances"
    | "Allergens"
    | "CommodityCategories"
    | "CommodityProperties"
    | "Compositions"
    | "Colors"
    | "Solvents";
  settings: Array<SettingsDocument>;
  title: { table: string; modal: string };
  usageCheck: (_id: BSON.ObjectId) => boolean;
  onSave: (_id: BSON.ObjectId, de: string, en: string, additionalField?: string) => Promise<void>;
  onEdit: (_id: BSON.ObjectId, de: string, en: string, additionalField?: string) => Promise<void>;
  onDelete: (_id: BSON.ObjectId) => Promise<void>;
  additionalField?: {
    title: string;
    value: (setting: any) => string;
    content: (setting: any) => string | JSX.Element;
    type?: "number" | "text";
    append?: string | JSX.Element;
  };
  additionalFieldSelect?: Array<string>;
  additionalFieldImage?: boolean;
  onSearch?: (query: string) => void;
}
interface SettingsTableState extends PaginationState {
  settingsShown: Array<SettingsDocument>;
}

class SettingsTable extends PureComponent<SettingsTableProps, SettingsTableState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: SettingsTableProps) {
    super(props);
    this.state = {
      pageSize: 10,
      currentPage: 1,
      itemsCount: 0,
      settingsShown: paginate(props.settings, 1, 10)
    };
  }

  componentDidUpdate(prevProps: Readonly<SettingsTableProps>, prevState: Readonly<SettingsTableState>, snapshot?: any) {
    const { pageSize, currentPage } = this.state;
    if (
      !_.isEqual(prevProps.settings, this.props.settings) ||
      pageSize !== prevState.pageSize ||
      currentPage !== prevState.currentPage
    ) {
      this.setState({ settingsShown: paginate(this.props.settings, currentPage, pageSize) });
    }
  }

  /**
   * Performs the search callback and ensures that the current page is reset
   * @param query Query that should be looked for
   */
  handleSearch = (query: string) => {
    const { onSearch } = this.props;
    onSearch!(query);
    this.setState({ currentPage: 1 });
  };

  render() {
    const {
      additionalField,
      additionalFieldImage,
      additionalFieldSelect,
      history,
      onDelete,
      onEdit,
      onSave,
      onSearch,
      settings,
      title,
      usageCheck
    } = this.props;
    const { pageSize, currentPage, settingsShown } = this.state;

    return (
      <>
        <div className="alert alert-light alert-elevate" style={{ backgroundColor: "white" }}>
          <div className="alert-icon">
            <i className="flaticon-warning kt-font-danger" />
          </div>
          <div className="alert-text">
            Please note that changing these properties will also have a retroactive effect. The new wording is used
            wherever this property has already been used. In order to avoid complications, the system does a check if
            the properties are already used somewhere, so these properties cannot be deleted afterwards. Please be
            careful when changing these properties.
          </div>
        </div>
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand fas fa-fill-drip" />
              </span>
              <h3 className="kt-portlet__head-title">{title.table}</h3>
            </div>
            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-wrapper">
                <button
                  onClick={() => {
                    history.goBack();
                  }}
                  className="btn btn-clean kt-margin-r-10"
                >
                  <i className="la la-arrow-left" />
                  <span className="kt-hidden-mobile">Back</span>
                </button>
                <CreateNewSettingValueModal
                  title={title.modal}
                  onSave={onSave}
                  edit={false}
                  additionalField={additionalField ? { title: additionalField.title, value: "" } : undefined}
                  additionalSelectField={additionalFieldSelect}
                  additionalImageField={additionalFieldImage}
                />
              </div>
            </div>
          </div>
          {onSearch && (
            <div className="kt-portlet__body">
              <div className="kt-form kt-form--label-right">
                <div className="row align-items-center">
                  <SearchBar onSearch={e => this.handleSearch(e.target.value)} additionalSizeClasses={"col-md-2"} />
                  <div className="col-xl-4 order-1 order-xl-2 kt-align-right">
                    <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg d-xl-none" />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded px-4">
              <Table>
                <thead>
                  <tr>
                    <th style={{ width: additionalField ? "34%" : "40%" }}>German</th>
                    <th style={{ width: additionalField ? "34%" : "40%" }}>English</th>
                    {additionalField && <th style={{ width: "12%" }}>{additionalField.title}</th>}
                    <th className="text-right" style={{ width: "20%" }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="kt-datatable__body">
                  {settingsShown.map(s => {
                    const isUsed = usageCheck(s._id);
                    return (
                      <tr key={s._id.toString()}>
                        <td className="align-middle">{s.name.de}</td>
                        <td className="align-middle">{s.name.en}</td>
                        {additionalField && <td className="align-middle">{additionalField.content(s)}</td>}
                        <td className="text-right align-middle">
                          <span className="mr-2">
                            <CreateNewSettingValueModal
                              onSave={onEdit}
                              title={title.modal}
                              edit={true}
                              _id={s._id}
                              name={s.name}
                              additionalField={
                                additionalField
                                  ? {
                                      title: additionalField.title,
                                      value: additionalField.value(s),
                                      type: additionalField.type,
                                      append: additionalField.append
                                    }
                                  : undefined
                              }
                              additionalSelectField={additionalFieldSelect}
                              additionalImageField={additionalFieldImage}
                            />
                          </span>
                          <DeleteSettingValueModal
                            _id={s._id}
                            name={s.name}
                            title={title.modal}
                            onDelete={onDelete}
                            disabled={isUsed}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <Pagination
                  itemsCount={settings.length}
                  pageSize={pageSize}
                  onPageChange={page => this.setState({ currentPage: page })}
                  currentPage={currentPage}
                  onPageSizeChange={pageSize => this.setState({ currentPage: 1, pageSize })}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SettingsTable);
