import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { RouteComponentProps, withRouter } from "react-router-dom";
import baseUtils from "../../utils/baseUtils";
import fileUtils from "../../utils/fileUtils";
import { CommoditiesDocument } from "../../model/commodities.types";
import { OrdersDocument } from "../../model/orders.types";
import { ORDERORDERCOMMODITIES } from "../../utils/orderUtils";
import { T_SERVICE } from "../order/OrderHelper";

interface CommoditiesToOrderProps extends RouteComponentProps {
  orders: Array<OrdersDocument>;
  commodities: Array<CommoditiesDocument>;
}

interface CommoditiesToOrderState {
  generating: boolean;
}

class CommoditiesToOrder extends PureComponent<CommoditiesToOrderProps, CommoditiesToOrderState> {
  constructor(props: CommoditiesToOrderProps) {
    super(props);
    this.state = { generating: false };
  }

  handleClickGenerateCSV = () => {
    const { commodities, orders } = this.props;
    this.setState({ generating: true });
    const commoditiesToOrder: { [key: string]: { orders: Array<string>; amount: number } } = {};
    try {
      const relevantOrders = orders.filter(o => o.state === ORDERORDERCOMMODITIES);
      for (let i = 0; i < relevantOrders.length; i++) {
        const o = relevantOrders[i];
        for (let j = 0; j < o.calculations[0].prices.length; j++) {
          const p = o.calculations[0].prices[j];
          if (p.ordered) continue;
          if (commoditiesToOrder[p._id.toString()]) {
            commoditiesToOrder[p._id.toString()].orders.push(o.identifier.toString());
            commoditiesToOrder[p._id.toString()].amount += p.amount * (1 + p.buffer / 100) * o.calculations[0].units;
          } else
            commoditiesToOrder[p._id.toString()] = {
              orders: [o.identifier.toString()],
              amount: p.amount * (1 + p.buffer / 100) * o.calculations[0].units
            };
        }
      }
      const headers = ["ID", "Commodity Title", "Commodity Subtitle", "Amount KG", "AT"];
      const content = [];
      for (let key in commoditiesToOrder) {
        const com = commodities.find(c => c._id.toString() === key);

        if (com) {
          if (com.type === T_SERVICE) continue;
          content.push([
            com._id.toString(),
            com.title.en,
            com.subtitle.en,
            (commoditiesToOrder[key].amount / 1000 / 1000).toString(),
            commoditiesToOrder[key].orders.join("|")
          ]);
        }
      }
      const csv = fileUtils.exportAsCSV(headers, content);
      fileUtils.downloadFile(csv, "CommoditiesToOrder_" + baseUtils.formatDate(new Date()) + ".csv", "text/plain");
    } catch (e) {
      toast.error("Error generating CSV");
      console.error(e);
    } finally {
      this.setState({ generating: false });
    }
  };

  render() {
    const { history } = this.props;
    const { generating } = this.state;
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Commodities to Order</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <button
                onClick={() => {
                  history.goBack();
                }}
                className="btn btn-clean kt-margin-r-10"
              >
                <i className="la la-arrow-left" />
                <span className="kt-hidden-mobile">Back</span>
              </button>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col text-dark font-weight-bolder mb-5">
              Exports all commodities that are currently used in orders that are in state "Order Commodities".
            </div>
          </div>
        </div>
        <div className="kt-portlet__foot">
          <div className="float-right">
            <button
              className="btn btn-success"
              disabled={generating}
              onClick={generating ? undefined : this.handleClickGenerateCSV}
            >
              {generating ? "Generating..." : "Generate CSV"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CommoditiesToOrder);
