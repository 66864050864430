import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import ProductionOrders from "./ProductionOrders";

interface ProductionOrdersProps extends RouteComponentProps {}

const ProductionOrdersWrapper: React.FunctionComponent<ProductionOrdersProps> = props => {
  const context = useContext(DataContext);
  return <ProductionOrders context={context} {...props} />;
};

export default ProductionOrdersWrapper;
