import React from "react";
import { Accordion } from "react-bootstrap";
import { DataContext } from "../../../context/dataContext";
import PackagingWidget from "../../common/PackagingWidget";
import PackagingLocalStock from "../../packaging/PackagingLocalStock";
import packagingUtils from "../../../utils/packagingUtils";
import { PackagingExtended } from "../StockListing";
import PackagingStockStatistics from "./PackagingsStockStatisitcs";

interface PackagingStockAccordionContentProps {
  isActive: boolean;
  level: number;
  onToggleClick: (e: number) => void;
  packagingExtended: PackagingExtended;
  showDisabled: boolean;
  context: React.ContextType<typeof DataContext>;
}

const PackagingStockContent: React.FunctionComponent<PackagingStockAccordionContentProps> = ({
  isActive,
  level,
  onToggleClick,
  packagingExtended,
  showDisabled,
  context
}: PackagingStockAccordionContentProps) => {
  const { stock, ordered, reserved, available } = packagingExtended.stockStatistics;
  const localStock = packagingExtended.manufacturerRelatedLocalStock;
  const packaging = packagingExtended.packaging;
  const type = packaging.packaging_type;
  return (
    <>
      {localStock && (
        <div className="kt-portlet">
          <Accordion.Toggle as={"div"} className={"kt-portlet__head accordionToggle"} eventKey={isActive ? "1" : "0"}>
            <div className="row w-100 m-1" onClick={() => onToggleClick(level)}>
              <div className="col-2 align-self-center">
                <PackagingWidget packaging={packaging} context={context} />
              </div>
              <div className="col-1 align-self-center">
                <div style={packagingUtils.getBadgeColor(packaging)} />
              </div>
              <div className="col-1 align-self-center">
                <span className={"font-weight-bolder text-dark"}>
                  Type: {type ? packagingUtils.getPackagingDescription(type) : "unknown"}
                </span>
              </div>
              <PackagingStockStatistics
                stock={stock}
                ordered={ordered}
                reserved={reserved}
                available={available}
                outerClassName={"col-8 align-self-center w-100 row"}
                innerClassName={"col-3 align-self-center"}
              />
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={isActive ? "1" : "0"}>
            <div className="m-5">
              <PackagingLocalStock
                localStock={localStock}
                packaging={packaging}
                context={context}
                showDisabledStock={showDisabled}
                disableStockStatistics
              />
            </div>
          </Accordion.Collapse>
        </div>
      )}
    </>
  );
};

export default PackagingStockContent;
