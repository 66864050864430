import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic";
import { DataContext } from "../../context/dataContext";
import baseUtils from "../../utils/baseUtils";
import { EM_CHECKED, EM_OPEN, EM_ORDERED } from "../externalManufacturers/ExternalManufacturerHelper";
import { ExternalManufacturerOrdersDocument } from "../../model/externalManufacturerOrders.types";

interface RelatedExternalOrdersProps {
  orders: Array<BSON.ObjectId>;
  removedExternalOrders: Array<BSON.ObjectId>;
  context: React.ContextType<typeof DataContext>;
  onRelatedExternalOrder: (order: BSON.ObjectId, remove?: boolean) => void;
  edit: boolean;
}

interface RelatedExternalOrdersState {
  showAll: boolean;
}

class RelatedExternalOrders extends PureComponent<RelatedExternalOrdersProps, RelatedExternalOrdersState> {
  constructor(props: RelatedExternalOrdersProps) {
    super(props);
    this.state = {
      showAll: false
    };
  }

  handleRelatedExternalOrder = (order: ExternalManufacturerOrdersDocument, remove?: boolean) => {
    if (!this.props.edit) this.props.onRelatedExternalOrder(order._id, remove);
    else console.error("Order EMO-", order.identifier, "in state", order.state, "cannot be removed");
  };

  render() {
    const { orders, removedExternalOrders, context, edit } = this.props;
    const { showAll } = this.state;
    let fullOrders = orders.map(o => baseUtils.getDocFromCollection(context.externalManufacturerOrders, o)) as Array<
      ExternalManufacturerOrdersDocument
    >;
    fullOrders = fullOrders.filter(o => !!o);
    const sliceOrders = !showAll && fullOrders.length > 5;
    if (sliceOrders) fullOrders = fullOrders.slice(0, 5);
    return (
      <div>
        {fullOrders.map(o => (
          <RelatedExternalOrderWidget
            key={o._id.toString()}
            order={o}
            onRelatedExternalOrder={this.handleRelatedExternalOrder}
            removed={removedExternalOrders.some(rO => rO.toString() === o._id.toString())}
            edit={edit}
          />
        ))}
        {sliceOrders && (
          <div className="d-inline-flex">
            <div className="kt-section__content d-flex flex-wrap " style={{ minWidth: "180px" }}>
              <div className="kt-widget4__info mx-auto" style={{ position: "relative", top: "-19px" }}>
                <p
                  className="kt-widget4__text text-dark kt-link pointer"
                  onClick={() => this.setState({ showAll: true })}
                >
                  Show all
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

interface RelatedExternalOrderWidgetProps {
  order: ExternalManufacturerOrdersDocument;
  removed: boolean;
  onRelatedExternalOrder: (order: ExternalManufacturerOrdersDocument, remove?: boolean) => void;
  edit: boolean;
}

const RelatedExternalOrderWidget: React.FunctionComponent<RelatedExternalOrderWidgetProps> = ({
  order,
  removed,
  onRelatedExternalOrder,
  edit
}) => {
  return (
    <div className="d-inline-flex">
      <div className="kt-section__content d-flex flex-wrap " style={{ minWidth: "180px" }}>
        <span className="kt-media kt-media--circle kt-margin-r-5 kt-margin-t-5">
          <img
            src={toAbsoluteUrl("/media/icons/exchange.svg")}
            className="p-1"
            style={removed ? { opacity: 0.2 } : {}}
            alt="Unknown"
          />
        </span>
        <OverlayTrigger
          overlay={
            <Tooltip id="relatedExternalOrderTooltip">
              <span className="text-dark">
                {removed ? "Keep related external order" : "Unlink related external order"}
              </span>
            </Tooltip>
          }
        >
          <button
            className={
              "kt-badge kt-badge--light pos-relatedOrdersButton border-0 " +
              (!edit ? (removed ? "relatedOrderAdd" : "relatedOrderRemove") : "disabled")
            }
            disabled={edit}
            onClick={() => (removed ? onRelatedExternalOrder(order, false) : onRelatedExternalOrder(order, true))}
          >
            {removed ? <i className="fas fa-check" /> : <i className="fas fa-times" />}
          </button>
        </OverlayTrigger>
        <div
          className="kt-widget4__info mt-3 ml-1"
          style={{ position: "relative", left: "-22px", opacity: removed ? 0.2 : 1 }}
        >
          <Link to={"/externalOrder/" + order._id.toString()} className="kt-widget4__username ">
            {[EM_OPEN, EM_CHECKED].includes(order.state) ? "REQ" : "EMO"}-{order.identifier}
          </Link>
          {order.reference.length > 15 ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="titleTooltip">
                  <span className="text-dark">{order.reference}</span>
                </Tooltip>
              }
            >
              <p className="kt-widget4__text">{order.reference.slice(0, 12) + "..."}</p>
            </OverlayTrigger>
          ) : (
            <p className="kt-widget4__text">{order.reference}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RelatedExternalOrders;
