import React, { useCallback, useMemo } from "react";
import { PhysicalWarehouse, WarehouseDefinition } from "../../../../model/configuration/warehouseConfiguration.types";
import { resolveTranslation } from "../../../../utils/translationUtils";
import i18n from "../../../../translations/i18n";
import { useWarehouseContext, useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { filterListingDocuments, isBatches, isCommoditiesWithBatches } from "../../../../utils/warehouseFilterUtils";
import { CommodityWithBatches } from "../../../../model/warehouse/customTypes.types";
import { Batch } from "../../../../model/warehouse/batch.types";
import { DeliveryAnnouncement } from "../../../../model/warehouse/deliveryAnnouncement.types";

interface WarehouseHierarchyProps {
  hideAllSelection?: boolean;
  allowSelection?: boolean;
}

const WarehouseHierarchy: React.FC<WarehouseHierarchyProps> = ({ hideAllSelection, allowSelection }) => {
  const warehouseContext = useWarehouseContext();
  const dispatch = useWarehouseDispatch();
  const { configuration, selectedLocation, warehouseListingTabDocuments } = warehouseContext;

  const filteredDocuments = useMemo(
    () => filterListingDocuments(warehouseListingTabDocuments, warehouseContext, true),
    [warehouseContext]
  );

  const amountBatches = useMemo(() => {
    if (isBatches(filteredDocuments)) return filteredDocuments.length;
    else if (isCommoditiesWithBatches(filteredDocuments))
      return filteredDocuments.reduce((sum, com) => sum + com.batches.length, 0);
    else return 0;
  }, [filteredDocuments]);

  const handleSelectAllWarehouses = useCallback(
    () =>
      !allowSelection
        ? undefined
        : dispatch({
            type: WarehouseActionType.SELECT_LOCATION,
            payload: {
              location: null
            }
          }),
    [allowSelection]
  );

  // This case is already caught in a parent component
  if (!configuration) return null;
  const { warehouseStructure } = configuration.values;

  return (
    <div className="navi navi-hover navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon">
      <h5 className=" m-2 font-weight-bold text-black">{i18n.t("warehouse:warehouse")}</h5>
      <hr className="w-100"></hr>
      {!hideAllSelection && (
        <div className="navi-item my-2">
          <span
            className={"navi-link cursorhand " + (!selectedLocation ? "active" : "")}
            onClick={handleSelectAllWarehouses}
          >
            <span className="label label-box label-rounded label-light font-weight-bold mr-2">L</span>
            <span className="navi-text font-weight-bolder">{i18n.t("warehouse:allWarehouses")}</span>
            <span className="navi-label">
              <small>{amountBatches}</small>
            </span>
          </span>
        </div>
      )}
      {warehouseStructure.map(warehouse => (
        <WarehouseStructure
          key={warehouse._id.toString()}
          warehouseStructure={warehouse}
          filteredDocuments={filteredDocuments}
          allowSelection={allowSelection}
        />
      ))}
    </div>
  );
};

interface WarehouseStructureProps {
  warehouseStructure: WarehouseDefinition;
  filteredDocuments: Array<CommodityWithBatches | Batch | DeliveryAnnouncement>;
  allowSelection?: boolean;
}

const WarehouseStructure: React.FC<WarehouseStructureProps> = ({
  warehouseStructure,
  filteredDocuments,
  allowSelection
}) => {
  const warehouseContext = useWarehouseContext();
  const dispatch = useWarehouseDispatch();
  const { selectedLocation } = warehouseContext;

  const amountBatches = useMemo(() => {
    if (isBatches(filteredDocuments))
      return filteredDocuments.filter(b =>
        b.locations.some(l => l.location.warehouseSnapshot._id.toString() === warehouseStructure._id.toString())
      ).length;
    else if (isCommoditiesWithBatches(filteredDocuments))
      return filteredDocuments.reduce(
        (sum, com) =>
          sum +
          com.batches.filter(b =>
            b.locations.some(l => l.location.warehouseSnapshot._id.toString() === warehouseStructure._id.toString())
          ).length,
        0
      );
    else return 0;
  }, [filteredDocuments, warehouseStructure]);

  const handleSelectWarehouse = useCallback(
    () =>
      !allowSelection
        ? undefined
        : dispatch({
            type: WarehouseActionType.SELECT_LOCATION,
            payload: {
              location: {
                warehouse: warehouseStructure._id.toString()
              }
            }
          }),
    [warehouseStructure, allowSelection]
  );

  return (
    <>
      <div className="navi-item my-2">
        <span
          className={
            "navi-link cursorhand " +
            (!selectedLocation?.warehouseArea && selectedLocation?.warehouse === warehouseStructure._id.toString()
              ? "active"
              : "")
          }
          onClick={handleSelectWarehouse}
        >
          <span className="label label-box label-rounded label-light font-weight-bold mr-2">L</span>
          <span className="navi-text font-weight-bolder">{resolveTranslation(warehouseStructure.warehouseName)}</span>
          <span className="navi-label">
            <small>{amountBatches}</small>
          </span>
        </span>
      </div>
      {warehouseStructure.physicalWarehouses.map(physWarehouse => (
        <PhysicalWarehouseRow
          key={physWarehouse._id.toString()}
          warehouseStructure={warehouseStructure}
          physicalWarehouse={physWarehouse}
          filteredDocuments={filteredDocuments}
          allowSelection={allowSelection}
        />
      ))}
    </>
  );
};

interface PhysicalWarehouseProps {
  warehouseStructure: WarehouseDefinition;
  physicalWarehouse: PhysicalWarehouse;
  filteredDocuments: Array<CommodityWithBatches | Batch | DeliveryAnnouncement>;
  allowSelection?: boolean;
}

const PhysicalWarehouseRow: React.FC<PhysicalWarehouseProps> = ({
  warehouseStructure,
  physicalWarehouse,
  filteredDocuments,
  allowSelection
}) => {
  const warehouseContext = useWarehouseContext();
  const dispatch = useWarehouseDispatch();
  const { selectedLocation } = warehouseContext;

  const amountBatches = useMemo(() => {
    if (isBatches(filteredDocuments))
      return filteredDocuments.filter(b =>
        b.locations.some(
          l =>
            l.location.warehouseSnapshot._id.toString() === warehouseStructure._id.toString() &&
            l.location.warehouseArea._id.toString() === physicalWarehouse._id.toString()
        )
      ).length;
    else if (isCommoditiesWithBatches(filteredDocuments))
      return filteredDocuments.reduce(
        (sum, com) =>
          sum +
          com.batches.filter(b =>
            b.locations.some(
              l =>
                l.location.warehouseSnapshot._id.toString() === warehouseStructure._id.toString() &&
                l.location.warehouseArea._id.toString() === physicalWarehouse._id.toString()
            )
          ).length,
        0
      );
    else return 0;
  }, [filteredDocuments, warehouseStructure, physicalWarehouse]);

  const handleSelectWarehouse = useCallback(
    () =>
      !allowSelection
        ? undefined
        : dispatch({
            type: WarehouseActionType.SELECT_LOCATION,
            payload: {
              location: {
                warehouse: warehouseStructure._id.toString(),
                warehouseArea: physicalWarehouse._id.toString()
              }
            }
          }),
    [warehouseStructure, physicalWarehouse, allowSelection]
  );

  return (
    <div className="navi-item navi-sub-item my-2 ">
      <span
        className={
          "navi-link cursorhand " +
          (selectedLocation?.warehouse === warehouseStructure._id.toString() &&
          selectedLocation.warehouseArea === physicalWarehouse._id.toString()
            ? "active"
            : "")
        }
        onClick={handleSelectWarehouse}
      >
        <span className="label label-box label-rounded label-light font-weight-bold mr-2">P</span>
        <span className="navi-text font-weight-bolder">{resolveTranslation(physicalWarehouse.warehouseName)}</span>
        <span className="navi-label">
          <small>{amountBatches}</small>
        </span>
      </span>
    </div>
  );
};

export default WarehouseHierarchy;
