import { CustomOrder } from "../../components/order/CustomTypes";
import { DataContextType } from "../../context/dataContext";
import { CompaniesDocument } from "../../model/companies.types";
import pdfUtils from "./pdfUtils";
import calculationUtils from "../calculationUtils";
import {
  T_CAPSULE,
  T_CUSTOM,
  T_LIQUID,
  T_POWDER,
  T_SERVICE,
  T_SOFTGEL,
  T_TABLET
} from "../../components/order/OrderHelper";
import { CapsulesDocument } from "../../model/capsules.types";
import { PackagingsDocument } from "../../model/packagings.types";
import { PackagingTypes } from "../../components/configurator/configuratorConstants";
import { StickerForms } from "../../components/packaging/PackagingHelper";

/**
 * Get packaging translation
 * @param key key to get the translation for
 * @returns {string} translation for packaging
 */
function getTranslation(key: string | null | undefined) {
  return pdfUtils.getTranslation(key, "en");
}

/**
 * Get html to display customer information
 * @param customer a company document
 * @returns {string} html representation for the customer
 */
function getCustomerHTML(customer: CompaniesDocument) {
  let html = "";
  html += '<td><span style="font-size:14px; float:left;">Order is produced for:</span><br/>';
  html += pdfUtils.getCompanyHTML(customer);
  html += "</span></span></td>";
  return html;
}

/**
 * Get order type description
 * @param order the order document
 * @param capsule a capsule if type is capsule
 * @returns {string} description for the order type
 */
function getTypeDescription(order: CustomOrder, capsule?: CapsulesDocument) {
  let html = "";
  if (order.settings.type === T_CAPSULE) {
    html +=
      order.settings.perUnit +
      (capsule
        ? " Capsules (" +
          capsule.capsule_size +
          ", " +
          capsule.capsule_material.en +
          ", " +
          capsule.capsule_color.en +
          ")"
        : "Capsules (unknown)");
  } else if (order.settings.type === T_SOFTGEL) html += order.settings.perUnit + " Softgel Capsules";
  else if (order.settings.type === T_CUSTOM) html += order.settings.perUnit + " Items";
  else if (order.settings.type === T_TABLET) html += order.settings.perUnit + " Tablets";
  else if (order.settings.type === T_POWDER) html += " Powder";
  else if (order.settings.type === T_SERVICE) html += " Service";
  else if (order.settings.type === T_LIQUID) html += " Liquid";

  return html;
}

/**
 * Get packaging description
 * @param order an order document
 * @param packaging list of packaging
 * @returns {string} html representation of packaging
 */
function getPackagingDescription(order: CustomOrder, packaging: Array<PackagingsDocument>) {
  let html = "";
  html += '<br><table cellpadding="3" cellspacing="0" style="font-size:14px; width:100%; background-color:#fafafa">';
  html += "<tbody><tr><td><b>Packaging</b></td><td>";
  const bottle = packaging.find(pack => pack.packaging_type === PackagingTypes.BOTTLE);
  const liquidBottle = packaging.find(pack => pack.packaging_type === PackagingTypes.LIQUIDBOTTLE);
  const blister = packaging.find(pack => pack.packaging_type === PackagingTypes.BLISTER);
  const bag = packaging.find(pack => pack.packaging_type === PackagingTypes.BAG);
  const sprayPump = packaging.find(pack => pack.packaging_type === PackagingTypes.SPRAYPUMP);
  if (bottle) {
    html += `${getTranslation(bottle.packaging_type)}, ${bottle.packaging_volume}ml, ${getTranslation(
      bottle.packaging_material!
    )}, ${getTranslation(bottle.packaging_color!)}, ${bottle.packaging_neck}`;
  } else if (liquidBottle) {
    html += `${getTranslation(liquidBottle.packaging_type)}, ${liquidBottle.packaging_volume}ml, ${getTranslation(
      liquidBottle.packaging_material!
    )}, ${getTranslation(liquidBottle.packaging_color!)}, ${liquidBottle.packaging_neck}`;
  } else if (blister) {
    html += `${getTranslation(blister.packaging_type)}, ${blister.blister_capsules} capsules/blister`;
  } else if (bag) {
    html += `${getTranslation(bag.packaging_type)}, ${bag.bag_volume}ml, ${getTranslation(
      bag.bag_material!
    )}, ${getTranslation(bag.bag_color)}`;
  }
  html += "</td></tr>";

  const lid = packaging.find(pack => pack.packaging_type === PackagingTypes.LID);
  const pipette = packaging.find(pack => pack.packaging_type === PackagingTypes.PIPETTE);
  if (lid || pipette || sprayPump) {
    html += `<tr><td><b>Lid</b></td><td>`;
    if (lid) {
      html += `${getTranslation(lid.lid_material)}, ${getTranslation(lid.lid_color)}, ${getTranslation(
        lid.lid_shape
      )}, ${lid.lid_size}`;
    } else if (pipette) {
      html += `${getTranslation(pipette.packaging_type)}, ${getTranslation(pipette.packaging_color)}, ${getTranslation(
        pipette.packaging_height
      )}mm , ${getTranslation(pipette.packaging_neck)}`;
    } else if (sprayPump) {
      html += `SprayPump ${getTranslation(sprayPump.packaging_color)}, DIN ${getTranslation(
        sprayPump.packaging_norm
      )}, ${getTranslation(sprayPump.packaging_height)}mm , ${getTranslation(sprayPump.packaging_neck)}`;
    }
    html += "</td></tr>";
  }

  const sleeve = packaging.find(pack => pack.packaging_type === PackagingTypes.SLEEVE);
  if (sleeve) {
    html += "<tr><td><b>Sealing</b></td><td>";
    html += `Sleeve for ${sleeve.sleeve_size}mm, ${sleeve.sleeve_print ? "printed" : "without print"}`;
    html += "</td></tr>";
  }

  const label = packaging.find(pack => pack.packaging_type === PackagingTypes.LABEL);
  const multilayer = packaging.find(pack => pack.packaging_type === PackagingTypes.MULTILAYER_LABEL);
  if (label || multilayer) {
    html += "<tr><td><b>Label</b></td><td>";
    if (label) {
      html += `${getTranslation(label.packaging_type)}, ${getTranslation(label.label_quality)}, ${
        label.label_width
      }mm x ${label.label_height}mm`;
    } else if (multilayer) {
      html += `${getTranslation(multilayer.packaging_type)}, ${getTranslation(multilayer.label_quality)}, ${
        multilayer.label_width
      }mm x ${multilayer.label_height}mm`;
    }
    html += "</td></tr>";
  }

  const box = packaging.find(pack => pack.packaging_type === PackagingTypes.BOX);
  if (box) {
    html += "<tr><td><b>Box</b></td><td>";
    html += `${getTranslation(box.packaging_type)} in suitable size, ${getTranslation(box.box_quality)}`;
    html += "</td></tr>";
  }

  let accessoryCount = 1;
  const sticker = packaging.find(pack => pack.packaging_type === PackagingTypes.STICKER);
  if (sticker) {
    html += `<tr><td><b>Accessory ${accessoryCount++}</b></td><td>`;
    html += `${getTranslation(sticker.packaging_type)}, ${getTranslation(sticker.form)}, ${getTranslation(
      sticker.quality
    )}, ${
      sticker.form === StickerForms.ROUND
        ? `⌀ ${sticker.diameter} mm`
        : `${sticker.packaging_width}mm x ${sticker.packaging_height}mm`
    }`;
    html += "</td></tr>";
  }

  const spoon = packaging.find(pack => pack.packaging_type === PackagingTypes.SPOON);
  if (spoon) {
    html += `<tr><td><b>Accessory ${accessoryCount++}</b></td><td>`;
    html += `${getTranslation(spoon.packaging_type)}, ${spoon.capacity}g`;
    html += "</td></tr>";
  }

  const silicaGelBag = packaging.find(pack => pack.packaging_type === PackagingTypes.SILICAGELBAG);
  if (silicaGelBag) {
    html += `<tr><td><b>Accessory ${accessoryCount++}</b></td><td>`;
    html += `${getTranslation(silicaGelBag.packaging_type)}, ${silicaGelBag.weight}g`;
    html += "</td></tr>";
  }

  const packageInsert = packaging.find(pack => pack.packaging_type === PackagingTypes.PACKAGEINSERT);
  if (packageInsert) {
    html += `<tr><td><b>Accessory ${accessoryCount++}</b></td><td>${getTranslation(
      packageInsert.packaging_type
    )} in suitable size</td></tr>`;
  }

  html += "</tbody></table></td></tr></table>";
  return html;
}

/**
 * Render an empty row for notes
 * @param description description of the row
 * @returns {string} html string
 */
function emptyNoteRow(description: string) {
  return `<tr style="width:100%"><td style="border-bottom: 1px solid rgba(125,125,125,0.5); width: 200px"><b>${description}</b></td><td style="border-bottom: 1px solid rgba(125,125,125,0.5); border-left: 1px solid rgba(125,125,125,0.5);"></td></tr>`;
}

/**
 * Create a manufacturing sheet html
 * @param order an order document
 * @param context data context
 * @returns {string} html representation of manufacturing sheet
 */
function createManufacturingSheet(order: CustomOrder, context: DataContextType) {
  const { capsules, commodities, packagings } = context;
  const selectedCustomer = order.createdFor;
  const currentUser = order.createdFrom;
  const title = order.title;
  let capsule: CapsulesDocument | undefined;
  if (order.settings.type === T_CAPSULE)
    capsule = capsules.find(cap => cap._id.toString() === order.settings.id.toString());
  const calculation = order.calculations[0];
  const creationTime = new Date().toLocaleDateString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  });

  let html =
    '<head><link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"><meta http-equiv="content-type" content="text/html; charset=utf-8"></head>';
  html += '<body style="font-family: Helvetica ">';

  html += '<table cellpadding="0" cellspacing="0" style="width: 100%; "<tr>';
  html += getCustomerHTML(selectedCustomer);
  html += '<td style="text-align: right; font-size:16px"><b>Order Number ' + order.identifier + "</b>";
  if (order.fulfillment && order.fulfillment.lot) {
    html += '<span style="font-size: 14px"><br><b>LOT Number: ' + order.fulfillment.lot + "</b></span>";
  }
  html += '<span style="font-size: 14px"><br>Manufacturing sheet created on: </span><br>' + creationTime + "<br><br>";
  html += '<span style="font-size:14px; float:left;">Contact person for queries:</span><br>';
  html += pdfUtils.getResponsibleHTML(currentUser);
  html += '</td></tr><tr><td style="font-size:22px;"><span style="font-weight: bold;">Order AT-' + order.identifier;
  if (title) {
    html += '<span style="font-size:18px;">&nbsp;' + title + "</span>";
  }
  html += "</span>";
  html += '<br><span style="font-size:17px;"><b>';
  html += calculation.units + " units, ";
  html += getTypeDescription(order, capsule);
  if (order.priority === "low") {
    html += ' - <span style="color: green">&nbsp;LOW priority</span>';
  } else if (order.priority === "medium") {
    html += ' - <span style="color: orange">&nbsp;MEDIUM priority</span>';
  } else if (order.priority === "high") {
    html += ' - <span style="color: red">&nbsp;HIGH priority</span>';
  }
  html += "</b></span></td></tr></table>";

  if (order.settings.type === T_CAPSULE)
    html +=
      '<table cellpadding="3" cellspacing="0" style="font-size:14px; width:100%; background-color:#fafafa; margin-top:10px" ><head><tr style="background-color:#cccccc"><td><b>Description</b></td><td style="min-width: 100px;"><b style="white-space:nowrap;">Per Capsule</b></td><td style="min-width: 100px;"><b>Per Unit</b></td><td style="min-width: 100px;"><b style="white-space:nowrap;">Total</b></td><td style="min-width: 140px;"><b style="white-space:nowrap;">Batch</b></td><td style="min-width: 80px;"><b style="white-space:nowrap;">KG used</b></td></tr></head>';
  else if (order.settings.type === T_TABLET)
    html +=
      '<table cellpadding="3" cellspacing="0" style="font-size:14px; width:100%; background-color:#fafafa; margin-top:10px" ><head><tr style="background-color:#cccccc"><td><b>Description</b></td><td style="min-width: 100px;"><b style="white-space:nowrap;">Per Tablet</b></td><td style="min-width: 100px;"><b>Per Unit</b></td><td style="min-width: 100px;"><b style="white-space:nowrap;">Total</b></td><td style="min-width: 140px;"><b style="white-space:nowrap;">Batch</b></td><td style="min-width: 80px;"><b style="white-space:nowrap;">KG used</b></td></tr></head>';
  else if (order.settings.type === T_CUSTOM)
    html +=
      '<table cellpadding="3" cellspacing="0" style="font-size:14px; width:100%; background-color:#fafafa; margin-top:10px" ><head><tr style="background-color:#cccccc"><td><b>Description</b></td><td style="min-width: 100px;"><b style="white-space:nowrap;">Per Item</b></td><td style="min-width: 100px;"><b>Per Unit</b></td><td style="min-width: 100px;"><b style="white-space:nowrap;">Total</b></td><td style="min-width: 140px;"><b style="white-space:nowrap;">Batch</b></td><td style="min-width: 80px;"><b style="white-space:nowrap;">used</b></td></tr></head>';
  else if (order.settings.type === T_SOFTGEL)
    html +=
      '<table cellpadding="3" cellspacing="0" style="font-size:14px; width:100%; background-color:#fafafa; margin-top:10px" ><head><tr style="background-color:#cccccc"><td><b>Description</b></td><td style="min-width: 100px;"><b style="white-space:nowrap;">Per Capsule</b></td><td style="min-width: 100px;"><b>Per Unit</b></td><td style="min-width: 100px;"><b style="white-space:nowrap;">Total</b></td><td style="min-width: 140px;"><b style="white-space:nowrap;">Batch</b></td><td style="min-width: 80px;"><b style="white-space:nowrap;">used</b></td></tr></head>';
  else if (order.settings.type === T_SERVICE)
    html +=
      '<table cellpadding="3" cellspacing="0" style="font-size:14px; width:100%; background-color:#fafafa; margin-top:10px" ><head><tr style="background-color:#cccccc"><td><b>Description</b></td><td style="min-width: 100px;"><b style="white-space:nowrap;">Per Unit</b></td><td style="min-width: 100px;"><b>Per Unit</b></td><td style="min-width: 100px;"><b style="white-space:nowrap;">Total</b></td><td style="min-width: 140px;"><b style="white-space:nowrap;">Batch</b></td><td style="min-width: 80px;"><b style="white-space:nowrap;">used</b></td></tr></head>';
  else if (order.settings.type === T_POWDER)
    html +=
      '<table cellpadding="3" cellspacing="0" style="font-size:14px; width:100%; background-color:#fafafa; margin-top:10px" ><head><tr style="background-color:#cccccc"><td><b>Description</b></td><td style="min-width: 100px;"><b style="white-space:nowrap;"></b></td><td style="min-width: 100px;"><b>Per Unit</b></td><td style="min-width: 100px;"><b style="white-space:nowrap;">Total</b></td><td style="min-width: 140px;"><b style="white-space:nowrap;">Batch</b></td><td style="min-width: 80px;"><b style="white-space:nowrap;">KG used</b></td></tr></head>';
  else if (order.settings.type === T_LIQUID)
    html +=
      '<table cellpadding="3" cellspacing="0" style="font-size:14px; width:100%; background-color:#fafafa; margin-top:10px" ><head><tr style="background-color:#cccccc"><td><b>Description</b></td><td style="min-width: 100px;"><b style="white-space:nowrap;"></b></td><td style="min-width: 100px;"><b>Per Unit</b></td><td style="min-width: 100px;"><b style="white-space:nowrap;">Total</b></td><td style="min-width: 140px;"><b style="white-space:nowrap;">Batch</b></td><td style="min-width: 80px;"><b style="white-space:nowrap;">KG used</b></td></tr></head>';

  html += "<tbody>";
  for (let i = 0; i < calculation.prices.length; i++) {
    const comm = calculation.prices[i];
    const fullCommodity = commodities.find(com => com._id.toString() === comm._id.toString());
    if (!fullCommodity) {
      console.error("Commodity could not be found", comm._id.toString());
      return "ERROR";
    }
    html += '<tr style="width:100%"><td><b>' + fullCommodity.title.en;
    html += `(${fullCommodity.identifier}, Old: ${fullCommodity.internal_code}) </b>`;
    html += (fullCommodity.subtitle.en ? "<br>" + fullCommodity.subtitle.en : "") + "</td>";
    if (order.settings.type === T_CAPSULE || T_TABLET)
      html += "<td>" + calculationUtils.formatAmount(comm.amount, 2, false, true) + "</td>";
    else html += "<td>" + "</td>";

    if ([T_CAPSULE, T_TABLET].includes(order.settings.type))
      html += "<td>" + calculationUtils.formatAmount(+comm.amount * +order.settings.perUnit, 2) + "</td>";
    else if ([T_CUSTOM, T_SOFTGEL, T_SERVICE].includes(order.settings.type))
      html += "<td>" + +comm.amount * +order.settings.perUnit + "</td>";
    else html += "<td>" + calculationUtils.formatAmount(comm.amount) + "</td>";

    if ([T_CAPSULE, T_TABLET].includes(order.settings.type))
      html +=
        "<td>" +
        calculationUtils.formatAmount(+comm.amount * +order.settings.perUnit * +calculation.units, 2) +
        "</td></tr>";
    else if ([T_CUSTOM, T_SOFTGEL, T_SERVICE].includes(order.settings.type))
      html += "<td>" + +comm.amount * +order.settings.perUnit * calculation.units + "</td></tr>";
    else html += "<td>" + calculationUtils.formatAmount(+comm.amount * +calculation.units, 2) + "</td></tr>";
  }

  const commodityWeight = pdfUtils.getCommodityWeight(calculation);
  const commodityWeightPerUnit = commodityWeight * +order.settings.perUnit;
  const commodityWeightUnits = commodityWeightPerUnit * +calculation.units;
  const formattedWeight = calculationUtils.formatAmount(
    commodityWeight,
    2,
    false,
    [T_CAPSULE, T_TABLET].includes(order.settings.type)
  );
  const formattedWeightPerunit = calculationUtils.formatAmount(commodityWeightPerUnit, 2);
  const formattedWeightUnits = calculationUtils.formatAmount(commodityWeightUnits, 2);
  if (order.settings.type === T_CAPSULE) {
    html += '<tr style="width:100%"><td><b>Sipernat</b></td><td></td><td></td><td></td></tr>';
    html += '<tr style="width:100%"><td><b>Kemilub</b><br></td><td></td><td></td><td></td></tr>';
    html +=
      '<tr style="width:100%"><td style="width:100%; border-top: 3px solid rgba(125,125,125,0.5);"><b>Total / Commodities</b></td><td style="border-top: 3px solid rgba(125,125,125,0.5);">';
    html += formattedWeight + '</td><td style="border-top: 3px solid rgba(125,125,125,0.5);">';
    html += formattedWeightPerunit + '</td><td style="border-top: 3px solid rgba(125,125,125,0.5);">';
    html += formattedWeightUnits + "</td>";
    html += '<td style="border-top: 3px solid rgba(125,125,125,0.5);"></td>';
    html += '<td style="border-top: 3px solid rgba(125,125,125,0.5);"></td></tr><tr><td>';
    if (!capsule) {
      console.error("No capsule found for", order.settings.id.toString());
      return "ERROR";
    }
    const capsuleWeight = +capsule.capsule_weight;
    const capsuleWeightPerUnit = capsuleWeight * +order.settings.perUnit;
    const capsuleWeightUnits = capsuleWeightPerUnit * +calculation.units;
    html += "<b>Capsule " + capsule.capsule_size + ", " + capsule.capsule_material.en + ", " + capsule.capsule_color.en;
    html += "</b></td>";
    html += '<td style="width:100%;">' + calculationUtils.formatAmount(capsuleWeight, 2, false, true) + "</td>";
    html += '<td style="width:100%;">' + calculationUtils.formatAmount(capsuleWeightPerUnit, 2) + "</td>";
    html += '<td style="width:100%;">' + calculationUtils.formatAmount(capsuleWeightUnits, 2) + "</td>";
    html += "<td></td><td></td></tr>";
    html += "<tr><td>";
    html += "<b>Subotal";
    html += "</b></td>";
    html +=
      '<td style="width:100%;">' +
      calculationUtils.formatAmount(capsuleWeight + commodityWeight, 2, false, true) +
      "</td>";
    html +=
      '<td style="width:100%;">' +
      calculationUtils.formatAmount(capsuleWeightPerUnit + commodityWeightPerUnit, 2) +
      "</td>";
    html +=
      '<td style="width:100%;">' +
      calculationUtils.formatAmount(capsuleWeightUnits + commodityWeightUnits, 2) +
      "</td>";
    html += "<td></td><td></td></tr>";
  } else if (order.settings.type === T_TABLET) {
    html +=
      '<tr style="width:100%"><td style="width:100%; border-top: 3px solid rgba(125,125,125,0.5);"><b>Total / Commodities</b></td><td style="border-top: 3px solid rgba(125,125,125,0.5);">';
    html += formattedWeight + '</td><td style="border-top: 3px solid rgba(125,125,125,0.5);">';
    html += formattedWeightPerunit + '</td><td style="border-top: 3px solid rgba(125,125,125,0.5);">';
    html += formattedWeightUnits + "</td>";
    html += '<td style="border-top: 3px solid rgba(125,125,125,0.5);"></td>';
    html += '<td style="border-top: 3px solid rgba(125,125,125,0.5);"></td></tr>';
  } else if (order.settings.type === T_POWDER || order.settings.type === T_LIQUID) {
    html +=
      '<tr style="width:100%"><td style="width:100%; border-top: 3px solid rgba(125,125,125,0.5);"><b>Total / Commodities</b></td><td style="border-top: 3px solid rgba(125,125,125,0.5);">';
    html += '</td><td style="border-top: 3px solid rgba(125,125,125,0.5);">';
    html += formattedWeight + '</td><td style="border-top: 3px solid rgba(125,125,125,0.5);">';
    html += calculationUtils.formatAmount(commodityWeight * +calculation.units, 2) + "</td>";
    html += '<td style="border-top: 3px solid rgba(125,125,125,0.5);"></td>';
    html += '<td style="border-top: 3px solid rgba(125,125,125,0.5);"></td></tr>';
  } else if (order.settings.type === T_CUSTOM || order.settings.type === T_SOFTGEL) {
    html +=
      '<tr style="width:100%"><td style="width:100%; border-top: 3px solid rgba(125,125,125,0.5);"><b>Total / Items</b></td><td style="border-top: 3px solid rgba(125,125,125,0.5);">';
    html += commodityWeight + '</td><td style="border-top: 3px solid rgba(125,125,125,0.5);">';
    html += commodityWeightPerUnit + '</td><td style="border-top: 3px solid rgba(125,125,125,0.5);">';
    html += commodityWeightUnits + "</td>";
    html += '<td style="border-top: 3px solid rgba(125,125,125,0.5);"></td>';
    html += '<td style="border-top: 3px solid rgba(125,125,125,0.5);"></td></tr>';
  } else if (order.settings.type === T_SERVICE) {
    html +=
      '<tr style="width:100%"><td style="width:100%; border-top: 3px solid rgba(125,125,125,0.5);"><b>Total</b></td><td style="border-top: 3px solid rgba(125,125,125,0.5);">';
    html += '</td><td style="border-top: 3px solid rgba(125,125,125,0.5);">';
    html += '</td><td style="border-top: 3px solid rgba(125,125,125,0.5);">';
    html += "</td>";
    html += '<td style="border-top: 3px solid rgba(125,125,125,0.5);"></td>';
    html += '<td style="border-top: 3px solid rgba(125,125,125,0.5);"></td></tr>';
  }
  html += '<tr><td style="width:100%"><b>Packaging</b></td><td>' + "</td><td></td><td></td><td></td></tr>";
  html += '<tr><td style="width:100%"><b>Total</b></td><td>' + "</td><td></td><td></td><td></td></tr>";
  html += "</tbody>";
  html += "</table>";

  const fullPackaging = calculation.packagings.map(
    pack => packagings.find(p => p._id.toString() === pack._id.toString())!
  );
  html += getPackagingDescription(order, fullPackaging);

  html += '<br><table cellpadding="3" cellspacing="0" style="font-size:14px; width:100%; background-color:#fafafa" >';
  html += "<tbody>";
  html += '<tr style="width:100%"><td style="width:200px"><b>Preparation</b></td><td ></td></tr>';
  if (order.settings.type === T_CAPSULE) {
    html += '<tr style="width:100%"><td style=""><b>Capsule</b></td>';
    html += `<td>${capsule?.capsule_size}, ${capsule?.capsule_material.en}, ${capsule?.capsule_color.en}</td></tr>`;
  }
  html += '<tr style="width:100%"><td style=""><b>Per unit</b></td>';
  html += "<td >";
  if ([T_CAPSULE, T_TABLET, T_CUSTOM, T_SOFTGEL, T_SERVICE].includes(order.settings.type))
    html += order.settings.perUnit + " pcs</td></tr>";
  else html += formattedWeight + "</td></tr>";

  html += '<tr style="width:100%"><td ><b>Total amount</b></td>';
  html += "<td >";
  html += calculation.units + "</td></tr>";
  html += "</tbody></table>";
  html += '<br><table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; >';
  html += "<tbody>";
  html += emptyNoteRow("Adjustment of machine");
  html += emptyNoteRow("Speed");
  html += emptyNoteRow("Sensor");
  html += emptyNoteRow("1");
  html += emptyNoteRow("2");
  html += emptyNoteRow("3");
  html += emptyNoteRow("4");
  html += emptyNoteRow("5");
  html += emptyNoteRow("Notes / real weight");
  html += emptyNoteRow("Manufacturing");
  html += emptyNoteRow("Homogenisation");
  html += emptyNoteRow("Encapsulation / Tableting");
  html += emptyNoteRow("Bottled / labeled");
  html += emptyNoteRow("Batch / Exp.");
  html += emptyNoteRow("Total amount to warehouse");
  html += emptyNoteRow("Batch of packaging");
  html += emptyNoteRow("Batch of capsule");
  html += "</tbody></table>";
  html += "</div>";

  return html;
}

export default { createManufacturingSheet };
