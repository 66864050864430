import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import JSZip from "jszip";
// @ts-ignore
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import { DataContext } from "../../context/dataContext";
import config from "../../config/config.json";
import { T_FILE, T_INVOICEPAID, T_OFFERPDF, T_REPORTPDF } from "../../utils/timelineUtils";

interface ExportModalProps {
  context: React.ContextType<typeof DataContext>;
  additionalCSSClasses?: string;
}

interface ExportModalState {
  show: boolean;
  generating: boolean;
  type: string;
  period: string;
  status: string;
}

class ExportModal extends PureComponent<ExportModalProps, ExportModalState> {
  constructor(props: ExportModalProps) {
    super(props);
    this.state = { show: false, generating: false, type: "invoice", period: "previousmonth", status: "paid" };
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => {
    if (this.state.generating) return;
    this.setState({ show: false });
  };

  handleDownload = async () => {
    this.setState({ generating: true });
    const { orders } = this.props.context;
    const { type, status } = this.state;
    let files = [];
    const files2 = [];
    // Collect invoices
    if (type === "invoice" || type === "all") {
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        if (!order.invoices) continue;
        for (let j = 0; j < order.invoices.length; j++) {
          const invoice = order.invoices[j];
          files.push({
            name: invoice.path.replace("pdf-files/", ""),
            url: config.mediahubBase + invoice.path,
            object: invoice,
            order
          });
          if (invoice.reminder.length > 0) {
            for (let k = 0; k < invoice.reminder.length; k++) {
              const r = invoice.reminder[k];
              files.push({
                name: r.path.replace("pdf-files/", ""),
                url: config.mediahubBase + r.path,
                object: invoice,
                order
              });
            }
          }
          if (invoice.cancelation) {
            const c = invoice.cancelation;
            files.push({
              name: c.path.replace("pdf-files/", ""),
              url: config.mediahubBase + c.path,
              object: invoice,
              order
            });
          }
        }
      }
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const paidEntry = file.order.timeline.find(
          t => t.type === T_INVOICEPAID && t.invoiceNumber === file.object.invoiceNumber
        );
        if (paidEntry && status === "paid" && file.object.state === "paid" && this.isDateInPeriod(paidEntry.date)) {
          files2.push(file);
        }
        if (status === "created" && this.isInvoiceInPeriod(file.object)) {
          files2.push(file);
        }
      }
    }
    files = [];
    // Collect offer pdfs
    if (type === "offer" || type === "all") {
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        for (let j = 0; j < order.timeline.length; j++) {
          const entry = order.timeline[j];
          if (entry.type && entry.type === T_OFFERPDF && entry.path) {
            files.push({
              name: entry.path.replace("pdf-files/", ""),
              url: config.mediahubBase + entry.path,
              object: entry
            });
          }
        }
      }
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (status === "created" && this.isInPeriod(file.object)) {
          files2.push(file);
        }
      }
    }
    files = [];
    // Collect reports
    if (type === "report" || type === "all") {
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        for (let j = 0; j < order.timeline.length; j++) {
          const entry = order.timeline[j];
          if (entry.type && entry.type === T_REPORTPDF && entry.path) {
            files.push({
              name: entry.path.replace("pdf-files/", ""),
              url: config.mediahubBase + entry.path,
              object: entry
            });
          }
        }
      }
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (status === "created" && this.isInPeriod(file.object)) {
          files2.push(file);
        }
      }
    }
    files = [];
    // Collect uploaded files
    if (type === "upload" || type === "all") {
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        for (let j = 0; j < orders[i].timeline.length; j++) {
          const entry = order.timeline[j];
          if (entry.type && entry.type === T_FILE && entry.path) {
            files.push({
              name: entry.title || entry.path.replace(config.mediahubFileBase, ""),
              url: entry.path,
              object: entry
            });
          }
        }
      }
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (status === "created" && this.isInPeriod(file.object)) {
          files2.push(file);
        }
      }
    }
    let isodate = new Date().toISOString();
    isodate = isodate.split(":").join("-");
    isodate = isodate.split(".").join("-");
    const zip = new JSZip();
    const zipFilename = "Export-" + isodate + ".zip";
    let count = 0;
    for (let i = 0; i < files2.length; i++) {
      const file = files2[i];
      let filename = file.name;
      JSZipUtils.getBinaryContent(file.url, (err: any, data: any) => {
        if (!err) {
          count++;
          zip.file(filename, data, { binary: true });
        }
        if (count === files2.length) {
          zip.generateAsync({ type: "blob" }).then(content => {
            saveAs(content, zipFilename);
            this.setState({ generating: false });
          });
        }
      });
    }
  };

  isInPeriod = (entry: any) => {
    return this.isDateInPeriod(entry.date);
  };

  isInvoiceInPeriod = (entry: any) => {
    return this.isDateInPeriod(entry.invoiceDate);
  };

  isDateInPeriod = (date: Date) => {
    const [begin, end] = this.getPeriod();
    return begin.getTime() <= date.getTime() && date.getTime() <= end.getTime();
  };

  getPeriod = () => {
    const { period } = this.state;
    let begin;
    let end;
    switch (period) {
      case "today":
        begin = new Date(new Date().setHours(0, 0, 0, 0));
        end = new Date(new Date().setHours(23, 59, 59, 999));
        break;
      case "currentmonth":
        begin = new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 0);
        end = new Date();
        break;
      case "previousmonth":
        begin = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0, 0, 0);
        end = new Date(new Date().getFullYear(), new Date().getMonth(), 0, 23, 59, 59, 999);
        break;
      case "currentyear":
        begin = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0);
        end = new Date(Date.now());
        break;
      case "previousyear":
        begin = new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0, 0);
        end = new Date(new Date().getFullYear() - 1, 11, 31, 23, 59, 59, 999);
        break;
      default:
        begin = new Date(0);
        end = new Date();
    }
    return [begin, end];
  };

  render() {
    const { additionalCSSClasses } = this.props;
    const { show, generating, type, period, status } = this.state;
    return (
      <>
        <button
          className={"btn btn-secondary kt-margin-r-10" + (additionalCSSClasses ? additionalCSSClasses : "")}
          onClick={this.handleShow}
        >
          <i className="fa fa-download" />
          Export Files
        </button>
        <Modal show={show} centered onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand fa fa-download mr-1" />
              Export Files
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="kt-portlet__body">
              <div className="row">
                <div className="col-xl-12">
                  <div className="kt-section kt-section--first">
                    <div className="kt-section__body">
                      <div className="row">
                        <div className="col-3 col-form-label">
                          <span className="kt-font-dark kt-font-bolder">Type</span>
                        </div>
                        <div className="col-9">
                          <select
                            className="form-control"
                            value={type}
                            onChange={e =>
                              this.setState({
                                type: e.target.value,
                                status: e.target.value !== "invoice" ? "created" : "paid"
                              })
                            }
                          >
                            <option value="invoice">Invoice</option>
                            <option value="offer">Offer</option>
                            <option value="report">Report</option>
                            <option value="upload">Upload</option>
                            <option value="all">All</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-3 col-form-label">
                          <span className="kt-font-dark kt-font-bolder">Period</span>
                        </div>
                        <div className="col-9">
                          <select
                            className="form-control"
                            value={period}
                            onChange={e => this.setState({ period: e.target.value })}
                          >
                            <option value="today">Today</option>
                            <option value="currentmonth">Current Month</option>
                            <option value="previousmonth">Previous Month</option>
                            <option value="currentyear">Current Year</option>
                            <option value="previousyear">Previous Year</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-3 col-form-label">
                          <span className="kt-font-dark kt-font-bolder">Status</span>
                        </div>
                        <div className="col-9">
                          <select
                            className="form-control"
                            value={status}
                            onChange={e => this.setState({ status: e.target.value })}
                          >
                            <option value="paid" disabled={this.state.type !== "invoice"}>
                              Paid in selected period
                            </option>
                            <option value="created">Created in selected period</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className={"btn btn-secondary " + (generating && "disabled")}
              disabled={generating}
              onClick={this.handleClose}
            >
              Close
            </button>
            <button
              type="button"
              className={"btn btn-primary " + (generating && "disabled")}
              disabled={generating}
              onClick={this.handleDownload}
            >
              <i className="fa fa-download" />
              {generating && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              {generating ? "Preparing Download..." : "Export Files"}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ExportModal;
