/**
 * Generates a string representing the difference between the given date and the current date.
 * @param date
 * @returns { string } Represents the time difference
 */
function generateTimeDiffString(date?: Date) {
  if (!date) {
    return "-";
  }
  const date1 = date;
  const date2 = new Date();
  const diffTime = date1.getTime() - date2.getTime();

  const diffHours = diffTime / (1000 * 60 * 60);
  const diffDays = diffTime / (1000 * 60 * 60 * 24);

  if (diffTime / (1000 * 60) <= 1 && diffTime / (1000 * 60) > 0) {
    return "today";
  }
  if (diffHours <= 1 && diffHours > 0) {
    return "in " + Math.ceil(diffTime / (1000 * 60)) + " minute(s)";
  }
  if (diffHours <= 24 && diffHours > 0) {
    return "in " + Math.ceil(diffTime / (1000 * 60 * 60)) + " hour(s)";
  }
  if (diffHours >= -1 && diffHours <= 0) {
    return "since " + Math.abs(Math.ceil(Math.ceil(diffTime / (1000 * 60)))) + " minute(s)";
  }
  if (diffHours >= -24 && diffHours <= 0) {
    return "since " + Math.abs(Math.ceil(diffTime / (1000 * 60 * 60))) + " hour(s)";
  }
  if (diffDays > 0 && diffDays < 30) {
    return "in " + Math.ceil(diffDays) + " day(s)";
  }
  if (diffDays >= 30) {
    return "in " + Math.ceil(diffDays / 30) + " month(s)";
  }
  if (diffDays <= 0 && diffDays > -30) {
    return "since " + Math.abs(Math.ceil(diffDays)) + " day(s)";
  }
  if (diffDays <= -30) {
    return "since " + Math.abs(Math.ceil(diffDays / 30)) + " month(s)";
  }
}

/**
 * Generate a string representing how long ago the given date is.
 * @param date: Date whose age should be calculated
 * @returns { string } Indicates how long ago the date was
 */
function getTimeAgo(date: Date) {
  const diffTime = Math.abs(new Date().getTime() - date.getTime());
  const diffHours = diffTime / (1000 * 60 * 60);
  const diffDays = diffTime / (1000 * 60 * 60 * 24);
  if (diffTime / (1000 * 60) <= 1) {
    return "just now";
  }
  if (diffHours <= 1) {
    return Math.ceil(diffTime / (1000 * 60)) + "m ago";
  }
  if (diffHours <= 24) {
    return Math.ceil(diffTime / (1000 * 60 * 60)) + "h ago";
  }
  return Math.ceil(diffDays) + "d ago";
}

/**
 * Get the KW of the given date
 * @param date: Date whose KW should be resolved
 * @returns { string } Contains the KW of the date
 */
function getKW(date: Date) {
  let oneJan = new Date(date.getFullYear(), 0, 1);
  let week = Math.floor(((date.getTime() - oneJan.getTime()) / 86400000 + oneJan.getDay() + 1) / 7);
  return "KW " + week;
}

/**
 * Get calendar week for a date
 * @param date a date
 * @returns {number} the calendar week for the date
 */
function getCW(date: Date) {
  const tdt = new Date(date.valueOf());
  const dayn = (date.getDay() + 6) % 7;
  tdt.setDate(tdt.getDate() - dayn + 3);
  const firstThursday = tdt.valueOf();
  tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
  }
  return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
}

/**
 * Get date for time stamp
 * @returns {string} formatted date string
 */
function timeStampDate() {
  const date = new Date();
  return (
    date.getDate() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getFullYear() +
    "_" +
    date.getHours() +
    "-" +
    date.getMinutes() +
    "-" +
    date.getSeconds()
  );
}

/**
 * Get the calendar week of the provided date.
 * @param date: Date whose CW should be returned
 * @returns { number } CW of the week
 */
function getCwNumber(date: Date) {
  try {
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
  } catch {
    return 0;
  }
}

/**
 * Calculate the remaining weeks of the current year.
 * @returns { number } Remaining weeks of the year
 */
function getRemainingWeeksOfYear() {
  const newDate = new Date();
  return Math.round(
    (new Date(newDate.getFullYear(), 12, 0, 0, 0, 0).getTime() - newDate.getTime()) / (1000 * 60 * 60 * 24) / 7
  );
}

/**
 * Get the days remaining until the current date is reached
 * @param date a date
 * @returns {number} number of days until the date
 */
function getDaysUntil(date: Date) {
  const today = new Date();
  const diffTime = date.valueOf() - today.valueOf();
  return diffTime / (1000 * 60 * 60 * 24);
}
/**
 * Get the days between two given dates
 * @param date1 a date
 * @param date2 another date
 * @returns {number} number of days between the given dates
 */
function getDaysBetween(date1: Date, date2: Date) {
  const diffTime = Math.abs(date1.valueOf() - date2.valueOf());
  return diffTime / (1000 * 60 * 60 * 24);
}

/**
 * Get monday and sunday of the week of the given date
 * @param date a date
 * @returns {[Date, Date]} tuple with monday and sunday
 */
function getMondaySunday(date: Date) {
  const dateCopy = new Date(date);
  let day = dateCopy.getDay();
  let diff = dateCopy.getDate() - day + (day === 0 ? -6 : 1);
  let monday = new Date(dateCopy.setDate(diff));
  monday.setHours(0, 0, 0, 0);
  let sunday = new Date(monday.getTime() + 7 * 24 * 60 * 60 * 1000 - 1);
  return [monday, sunday];
}

/**
 * Get monday of the week of the given date
 * @param date a date
 * @returns {Date} date of monday
 */
function getMonday(date: Date) {
  const dateCopy = new Date(date);
  let day = dateCopy.getDay();
  let diff = dateCopy.getDate() - day + (day === 0 ? -6 : 1);
  let monday = new Date(dateCopy.setDate(diff));
  monday.setHours(0, 0, 0, 0);
  return monday;
}

/**
 * Get the days the date was ago
 * @param date the date
 * @returns {number} time the given date was ago in days
 */
function getCreatedDaysAgo(date: Date) {
  const date2 = new Date();
  const diffTime = Math.abs(date2.valueOf() - date.valueOf());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays - 1;
}

/**
 * Get the start day of an ISO week
 * @param week the week number
 * @param year the year
 * @returns {Date} start day of the week and year
 */
function getStartOfISOWeek(week: number, year: number) {
  const date = new Date(Date.UTC(year, 0, 1 + (week - 1) * 7));
  const dayOfWeek = date.getUTCDay();
  const weekStart = date;
  if (dayOfWeek <= 4) weekStart.setUTCDate(date.getUTCDate() - date.getUTCDay() + 1);
  else weekStart.setUTCDate(date.getUTCDate() + 8 - date.getUTCDay());
  return weekStart;
}

/**
 * Get a default period from 1.1.2020 till end of today
 * @param alternateStartDate optional, alternative start date
 * @returns { start: Date, end: Date} default period
 */
function getDefaultPeriod(alternateStartDate?: Date) {
  const startDate = alternateStartDate ?? new Date(2020, 0, 1);
  startDate.setHours(0, -startDate.getTimezoneOffset(), 0, 0);
  const endDate = new Date();
  endDate.setHours(23, 59 + -endDate.getTimezoneOffset(), 59, 999);
  return { start: startDate, end: endDate };
}

/**
 * Get the years between a given start and end date
 * @param startDate the start date
 * @param endDate the end date
 * @returns {Array<number>} the years between as numeric array
 */
function generateYearsBetween(startDate: Date, endDate: Date) {
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const result: Array<number> = [];
  for (let i = startYear; i <= endYear; i++) {
    result.push(i);
  }
  return result;
}

export default {
  generateYearsBetween,
  generateTimeDiffString,
  getRemainingWeeksOfYear,
  getKW,
  getCW,
  getCwNumber,
  timeStampDate,
  getTimeAgo,
  getDaysUntil,
  getDaysBetween,
  getMondaySunday,
  getMonday,
  getCreatedDaysAgo,
  getStartOfISOWeek,
  getDefaultPeriod
};
