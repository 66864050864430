import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { CustomOrder } from "../CustomTypes";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import { DataContext } from "../../../context/dataContext";
import toastUtils from "../../../utils/toastUtils";
import { ORDERS } from "../../../services/dbService";

interface EditTitleModalProps {
  context: React.ContextType<typeof DataContext>;
  order: CustomOrder;
}

interface EditTitleModalState {
  title: string;
  subtitle: string;
  show: boolean;
  saving: boolean;
}

class EditTitleModal extends PureComponent<EditTitleModalProps, EditTitleModalState> {
  constructor(props: EditTitleModalProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  handleShow = () => this.setState(this.getDefaultState(true));
  handleClose = () => this.setState({ show: false });

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSaveTitle = async () => {
    const { order, context } = this.props;
    const { title, subtitle } = this.state;
    const { updateDocumentInContext } = context;
    this.setState({ saving: true });
    try {
      const res = await dbOrderService.updateOrderTitle(order, title, subtitle);
      await toastUtils.databaseOperationToast(
        !!res && res.modifiedCount > 0,
        "Order title updated successfully",
        "Error updating order title",
        () => {
          updateDocumentInContext(ORDERS, order._id);
          this.setState({ show: false });
        }
      );
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (show: boolean): EditTitleModalState => {
    const { title, subtitle } = this.props.order;
    return { show, saving: false, title, subtitle };
  };

  validateData = () => {
    const { order } = this.props;
    const { subtitle, title } = this.state;
    const errors: Array<string> = [];
    if (title.trim().length < 3) errors.push("Please enter a meaningful title");
    if (title.trim() === order.title.trim() && subtitle.trim() === order.subtitle.trim())
      errors.push("Nothing was changed");
    return errors;
  };

  render() {
    const { show, saving, subtitle, title } = this.state;
    const errors = this.validateData();

    return (
      <>
        <button type="button" className="btn btn-secondary ml-1 p-1 border-0 mb-1" onClick={this.handleShow}>
          <i className="fa fa-pen pr-0" />
        </button>
        <Modal show={show} onHide={this.handleClose} centered size="lg" name="editTitleModal">
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand fa fa-pen mr-2" />
              Edit Order Title
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="kt-portlet__body">
              <div className="form-group row">
                <label className="col-2 col-form-label kt-font-dark kt-font-bold text-right">Title</label>
                <div className="col-10">
                  <input
                    className="form-control"
                    type="text"
                    value={title}
                    name="title"
                    onChange={this.handleChange}
                    disabled={saving}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-2 col-form-label kt-font-dark kt-font-bold text-right">Subtitle</label>
                <div className="col-10">
                  <input
                    className="form-control"
                    type="text"
                    value={subtitle}
                    name="subtitle"
                    onChange={this.handleChange}
                    disabled={saving}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <ErrorOverlayButton
              errors={errors}
              saving={saving}
              className="btn btn-success"
              buttonText="Save"
              onClick={this.handleSaveTitle}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default EditTitleModal;
