import { BSON } from "realm-web";
import { WarehouseActionNumber } from "../../utils/warehouseActionUtils";
import { LanguageObject } from "../common.types";
import { CompositionsDocument } from "../compositions.types";

export enum ContentType {
  COMMODITY = "commodity",
  PACKAGING = "packaging",
  CAPSULE = "capsule",
  FINISHEDPRODUCT = "finishedProduct"
}

export enum LocationType {
  STORAGESPACE = "storageSpace",
  PHYSICAL_WAREHOUSE = "physicalWarehouse"
}

export enum BatchContentSpecificType {
  CAPSULES = "capsules",
  SOFTGELS = "softgels",
  BOTTLES = "bottles",
  BAGS = "bags",
  BLISTERS = "blisters",
  LIDS = "lids",
  PIPETTES = "pipettes",
  SPRAY_PUMPS = "sprayPumps",
  LABELS = "labels",
  BOXES = "boxes",
  SLEEVES = "sleeves",
  OTHER_PACKAGING = "otherPackaging",
  FINISHED_PRODUCTS = "finishedProduct"
}

export type PUStorageSpaceAssignment = Record<string, Array<StorageSpaceAssignment>>;

export interface Content {
  type: ContentType; // general type
  details: ContentDetails;
}

export interface CommodityCategoriesSnapshot {
  _id: BSON.ObjectId;
  name: LanguageObject;
}

export interface CommoditySpecificTypeObject {
  composition: CompositionsDocument;
  category: CommodityCategoriesSnapshot;
}

// 'Snapshot' of materials, e.g. commodity, packaging, capsules, finished products, ...
export interface ContentDetails {
  _id: BSON.ObjectId; // commodity/packaging/capsules/FP ... id, reference
  specificType: BatchContentSpecificType | CommoditySpecificTypeObject; // e.g. softgel, powder, bottle, ..
  organic?: boolean;
  articleNo?: string; // currently only for commodity, but could be useful for everything tbh
  title: LanguageObject;
  subtitle: LanguageObject;
}

export interface StorageSpaceAssignment {
  quantity: number;
  location: string | undefined;
  locationType: LocationType;
}

export interface CurrencyValue {
  value: number;
  currency: string;
}

export interface OrderSnapshot {
  _id: BSON.ObjectId; // order id, reference
  identifier: number | string;
}

export interface ExtendedOrderSnapshot extends OrderSnapshot {
  title: string;
  subtitle: string;
}

export interface PersonSnapshot {
  _id: BSON.ObjectId; // person id, reference
  prename: string;
  surname: string;
}

export interface BaseActionModalProps {
  show: boolean;
  actionTrigger?: ActionTrigger;
  onHide: () => void;
}

export interface ActionTrigger {
  actionNumber: WarehouseActionNumber | null;
  batchId?: string;
  locationId?: string;
  reservationId?: string; // cancel reservation
  materialId?: string; // cancel reservation
  orderId?: string; // order id for cancel reservation reassign
}
