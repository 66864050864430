import dbService, { GENERAL } from "../dbService";

/**
 * Get the next available LOT number without updating the database.
 * @returns {string} Next available LOT number
 */
async function getNextLotNumber() {
  const general = await dbService.getCollection(GENERAL);
  const generalLot = general.find(g => g.data === "lot");
  const { date, value } = generalLot;

  return "DE" + date.split("-").join("") + ("000" + value).substr(-3);
}

/**
 * Calls the generateLotNumber in the backend and returns the next available LOT number. This updates the database.
 * @returns {string} Next available LOT number
 */
async function callGenerateLotNumberFunction() {
  return await dbService.callFunction("generateLotNumber", []);
}

/**
 * Updates the internal code value of the mentioned number circle.
 * @param data: Number circle that should be updated
 * @param value: Values that the number circle should be set to
 * @returns Result of the query
 */
async function retrieveInternalCodeValue(data: string, value: string | number) {
  return dbService
    .getDb()
    ?.collection(GENERAL)
    .findOneAndUpdate({ data }, { $inc: { value: 1 } }, { returnNewDocument: true });
}

/**
 * Retrieves the next available organic code.
 * @returns { number } Next available organic code
 */
async function getNextOrganicCode() {
  return await getAndIncreaseGeneralNr("internal_code_60000");
}

/**
 * Retrieves the next available order number.
 * @returns { number } Next available order number
 */
async function getNextOrderNr() {
  return await getAndIncreaseGeneralNr("ordernr");
}

/**
 * Retrieves the next available commodity order number.
 * @returns { number } Next available commodity order number
 */
async function getNextCommodityOrderNr() {
  return await getAndIncreaseGeneralNr("commodityordernr");
}

/**
 * Retrieve and increase a number in the general collection.
 * @param data: Key that should be received
 * @returns { number } Next available key
 */
async function getAndIncreaseGeneralNr(data: string) {
  const res = await dbService
    .getDb()
    ?.collection(GENERAL)
    .findOneAndUpdate({ data }, { $inc: { value: 1 } }, { returnNewDocument: true });
  if (res) {
    return res.value;
  }
}

/**
 * Retrieves the next available invoice number.
 * @returns { number } Next available invoice number
 */
async function getNextInvoiceNr() {
  const res = await dbService
    .getDb()
    ?.collection(GENERAL)
    .findOneAndUpdate({ data: "invoicenr" }, { $inc: { value: 1 } }, { returnNewDocument: false });
  if (res) {
    return res.value;
  }
}

/**
 * Retrieve the referenced general data from database.
 * @param data: Name of the data
 * @returns { GeneralDocument } Document that was referenced by the data key
 */
async function getGeneralData(data: string) {
  return dbService
    .getDb()
    ?.collection(GENERAL)
    .findOne({ data });
}

/**
 * Set the referenced general data.
 * @param data: Name of the data
 * @param value: Value that should be set
 * @returns { Realm.Services.MongoDB.UpdateResult<any> } Result of the query
 */
async function setGeneralData(data: string, value: string | number) {
  return dbService
    .getDb()
    ?.collection(GENERAL)
    .updateOne({ data }, { $set: { value } });
}

export default {
  callGenerateLotNumberFunction,
  getGeneralData,
  getAndIncreaseGeneralNr,
  getNextCommodityOrderNr,
  getNextLotNumber,
  getNextOrderNr,
  getNextOrganicCode,
  getNextInvoiceNr,
  retrieveInternalCodeValue,
  setGeneralData
};
