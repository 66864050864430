import React from "react";
import i18n from "i18next";
import { CustomerData, CustomOrder, DatasheetData } from "../../components/order/CustomTypes";
import { DataContext } from "../../context/dataContext";
import orderUtils from "../orderUtils";
import pdfUtils, { ExtendedPackaging } from "./pdfUtils";
import { Setting } from "../../model/orders.types";

/**
 * Create datasheet content
 * @param order an order document
 * @param customerData a customerData object
 * @param pdfData data sheet information
 * @param context the data context
 * @returns {string} html containing data sheet information
 */
function createDataSheet(
  order: CustomOrder,
  customerData: CustomerData,
  pdfData: DatasheetData,
  context: React.ContextType<typeof DataContext>
) {
  const { packagings } = context;
  const settings: Setting = {
    type: order.settings.type,
    perUnit: order.settings.perUnit,
    id: order.settings.id,
    manufacturer: order.settings.manufacturer._id,
    productionMachine: order.settings.productionMachine
  };

  const language = i18n.language === "de" ? "de" : "en";
  let todayDate = new Date();
  const today = todayDate.toLocaleDateString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
  const fullPackaging = order.calculations[0].packagings.map(pack => {
    return {
      amount: pack.amount,
      ...packagings.find(p => p._id.toString() === pack._id.toString())
    };
  }) as Array<ExtendedPackaging>;
  const currentUser = order.createdFrom;
  let html =
    '<head><link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"><style>tr:nth-child(even) {background-color: white;}</style><meta http-equiv="content-type" content="text/html; charset=utf-8"></head>';
  html += '<body style="font-family: Helvetica ">';
  html +=
    '</img><hr><img src="https://private-label-factory.de/config/assets/img/PLF_logo_bw.png" width="200"><br><table cellpadding="5" cellspacing="0" style="width: 100%; "<tr><td><br><br><span style="font-size:12px; float:left;"><u>Private Label Factory Deutschland GmbH, Philipp-Reis-Str. 7, 45659 Recklinghausen</u></span><br>';
  html += '<span style="font-size:16px; float:left;">';
  html += pdfUtils.getCustomerHTML(customerData);

  if (!orderUtils.isOrderState(order.state)) {
    html += `</span></td><td style="text-align: right; font-size:16px">${i18n.t("common:offerNumber")}: AN-${
      order.identifier
    }`;
  } else {
    html += `</span></td><td style="text-align: right; font-size:16px">${i18n.t("common:orderNumber")}: AT-${
      order.identifier
    }`;
  }
  html += "<br/>";
  if (pdfData.bioNumber) {
    html += `${i18n.t("common:bioControlNumber")}: ${pdfData.bioNumber}<br/>`;
  }
  html += `${i18n.t("common:date")}: ${today}<br/><br/>`;
  html += pdfUtils.getResponsibleHTML(currentUser);
  if (order && !orderUtils.isOrderState(order.state)) {
    html += `</td></tr><tr><td style="font-size:25px; font-weight: bold;"><br>${i18n.t("datasheet:datasheet")}: AN-${
      order.identifier
    } `;
  } else {
    html += `</td></tr><tr><td style="font-size:25px; font-weight: bold;"><br>${i18n.t("datasheet:datasheet")}: AT-${
      order.identifier
    } `;
  }
  if (pdfData.title) {
    html += '<br><span style="font-size:18px;">' + pdfData.title + "</span>";
  }
  html += pdfUtils.getSettingsDescription(order, settings, context, undefined, language);
  html += "<br></td></tr></table></body>";
  html += "</td></tr></table>";
  html += pdfUtils.getRecipeDescription(order, settings, context, pdfData.dataSheetText, undefined, language);
  html += "<br/><br/>";
  html += pdfUtils.getPackagingDescription(fullPackaging, language);
  html += `<br><span><b>${i18n.t("common:description")}</b></span><br>`;
  html += '<span style="font-size:17px">' + pdfData.description + "</span><br>";
  html += `<br><span><b>${i18n.t("datasheet:ingredients")}</b></span><br>`;
  if (pdfData.coloringAdditives) {
    html += `<span style="font-size:17px"> 
        ${pdfData.ingredients}, ${i18n.t("datasheet:coloringAdditives")}: ${pdfData.coloringAdditives}
      </span><br>`;
  } else {
    html += '<span style="font-size:17px">' + pdfData.ingredients + "</span><br>";
  }
  html += `<br><span><b>${i18n.t("datasheet:recommendedIntake")}</b></span><br>`;
  html += '<span style="font-size:17px">' + pdfData.consumption + "</span><br>";
  html += `<br><span><b>${i18n.t("datasheet:warning")}</b></span><br>`;
  html += '<span style="font-size:17px">' + pdfData.warning + "</span>";
  if (pdfData.notes !== "") {
    html += `<br/><br/><div><span><b>${i18n.t("datasheet:additions")}</b></span><br>`;
    html += '<span style="font-size:17px">' + pdfData.notes + "</span><br>";
    html += "</div>";
    html += `<br><span><i>${i18n.t("datasheet:asStatedBySup")}.</i></span>`;
  }
  html += i18n.t("template:additionalText3");
  html += i18n.t("template:additionalText1");

  return html;
}

export default { createDataSheet };
