import _ from "lodash";
import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PaginationState } from "../common/CustomTypes";
import Pagination, { paginate } from "../common/Pagination";
import { CompaniesDocument } from "../../model/companies.types";
import { OrdersDocument } from "../../model/orders.types";
import orderUtils, { ARCHIVE, CREATEINVOICE, DECLINED } from "../../utils/orderUtils";
import accessUtils, { ACCESS_AREAS } from "../../utils/accessUtils";

interface CompanyOrdersTabProps {
  orders: Array<OrdersDocument>;
  company: CompaniesDocument;
}
interface CompanyOrdersTabState extends PaginationState {
  companyOrders: Array<OrdersDocument>;
  orderFilter: string;
  bookTurnover: number;
  totalTurnover: number;
  totalMargin: number;
}

class CompanyOrdersTab extends PureComponent<CompanyOrdersTabProps, CompanyOrdersTabState> {
  constructor(props: CompanyOrdersTabProps) {
    super(props);
    const companyOrders = props.orders
      .filter(o => o.state !== DECLINED && o.createdFor.toString() === props.company._id.toString())
      .sort((o1, o2) => o1.createdOn.getTime() - o2.createdOn.getTime());
    this.state = {
      ...this.calculateTurnoverAndMargin(companyOrders),
      companyOrders,
      currentPage: 1,
      pageSize: 20,
      orderFilter: "all"
    };
  }

  componentDidUpdate(
    prevProps: Readonly<CompanyOrdersTabProps>,
    prevState: Readonly<CompanyOrdersTabState>,
    snapshot?: any
  ) {
    const { company, orders } = this.props;
    if (!_.isEqual(prevProps.company, company) || !_.isEqual(prevProps.orders, orders)) {
      const companyOrders = orders
        .filter(o => o.state !== DECLINED && o.createdFor.toString() === company._id.toString())
        .sort((o1, o2) => o1.createdOn.getTime() - o2.createdOn.getTime());
      this.setState({ ...this.calculateTurnoverAndMargin(companyOrders), companyOrders });
    }
  }

  /**
   * Calculates the turnover and the margin of the given orders.
   * @param companyOrders: Orders that should be used for the calculation.
   * @returns { totalTurnover: number, totalMargin: number } Calculated values
   */
  calculateTurnoverAndMargin = (companyOrders: Array<OrdersDocument>) => {
    const finishedOrders = companyOrders.filter(o => [CREATEINVOICE, ARCHIVE].includes(o.state));
    return {
      bookTurnover: companyOrders.reduce(
        (tt, o) =>
          tt + (o.fulfillment?.priceInfo ? o.fulfillment.priceInfo.totalPrice : o.calculations[0].info.totalprice),
        0
      ),
      totalTurnover: finishedOrders.reduce(
        (tt, o) =>
          tt + (o.fulfillment?.priceInfo ? o.fulfillment.priceInfo.totalPrice : o.calculations[0].info.totalprice),
        0
      ),
      totalMargin: finishedOrders.reduce(
        (tt, o) =>
          tt +
          (o.fulfillment?.priceInfo?.totalMargin
            ? o.fulfillment?.priceInfo?.totalMargin
            : o.calculations[0].info.totalmargin),
        0
      )
    };
  };

  /**
   * Filters the orders in regard to the selected filter.
   */
  filterOrders = () => {
    const { orderFilter, companyOrders } = this.state;
    if (orderFilter === "open") return companyOrders.filter(o => o.state !== ARCHIVE);
    if (orderFilter === "closed") return companyOrders.filter(o => o.state === ARCHIVE);
    return companyOrders;
  };

  render() {
    const { totalMargin, totalTurnover, bookTurnover, pageSize, currentPage, orderFilter } = this.state;
    const ordersFiltered = this.filterOrders();
    const ordersShown: Array<OrdersDocument> = paginate(ordersFiltered, currentPage, pageSize);
    const percentMargin = totalTurnover ? (totalMargin / totalTurnover) * 100 : 0;
    const canSeeFinanceData = accessUtils.canSeeFinanceData();
    return (
      <div className="kt-portlet__body">
        <div className="kt-widget kt-widget--user-profile-3">
          {canSeeFinanceData && (
            <div className="kt-widget__top">
              <div className="kt-widget__content" style={{ width: "33%" }}>
                <div className="kt-widget__head">
                  <span className="kt-widget__username text-center w-100">
                    <span className="kt-font-bolder text-success" style={{ fontSize: "2rem" }}>
                      {bookTurnover.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
                    </span>
                    <br />
                    <span style={{ fontSize: "1.5rem" }}>Book Turnover</span>
                  </span>
                </div>
              </div>
              <div className="kt-widget__content" style={{ width: "33%" }}>
                <div className="kt-widget__head">
                  <span className="kt-widget__username text-center w-100">
                    <span className="kt-font-bolder text-success" style={{ fontSize: "2rem" }}>
                      {totalTurnover.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
                    </span>
                    <br />
                    <span style={{ fontSize: "1.5rem" }}>Total Turnover</span>
                  </span>
                </div>
              </div>
              <div className="kt-widget__content" style={{ width: "33%" }}>
                <div className="kt-widget__head">
                  <span className="kt-widget__username text-center w-100">
                    <span className="kt-font-bolder text-success" style={{ fontSize: "2rem" }}>
                      {totalMargin.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
                    </span>
                    <br />
                    <span style={{ fontSize: "1.5rem" }}>Total Margin</span>
                  </span>
                </div>
              </div>
              <div className="kt-widget__content" style={{ width: "33%" }}>
                <div className="kt-widget__head">
                  <span className="kt-widget__username text-center w-100">
                    <span className="kt-font-bolder text-success" style={{ fontSize: "2rem" }}>
                      {(percentMargin ? percentMargin : 0).toFixed(2)} %
                    </span>
                    <br />
                    <span style={{ fontSize: "1.5rem" }}>Percent Margin</span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="form-group row mt-5">
          <div className="col-3">
            <select
              className="form-control"
              value={orderFilter}
              onChange={e => this.setState({ orderFilter: e.target.value })}
            >
              <option value="all">All Orders</option>
              <option value="open">Open Orders</option>
              <option value="closed">Closed Orders</option>
            </select>
          </div>
        </div>
        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
          <Table>
            <thead>
              <tr>
                <td style={{ width: "10%" }}>AN/AT</td>
                <td style={{ width: "40%" }}>Title</td>
                <td style={{ width: "20%" }}>State</td>
                <td style={{ width: "10%" }}>Target</td>
                <td style={{ width: canSeeFinanceData ? "20%" : "10%" }}>Units</td>
                {canSeeFinanceData && <td style={{ width: "10%" }}>Turnover</td>}
              </tr>
            </thead>
            <tbody>
              {ordersShown.length > 0 ? (
                ordersShown.map(o => {
                  return (
                    <tr key={o._id.toString()}>
                      <td>
                        <div className="kt-user-card-v2">
                          <div className="kt-user-card-v2__details">
                            <Link to={"/order/" + o._id.toString()} className="kt-user-card-v2__name kt-link">
                              {orderUtils.resolveOrderDescription(o)[1]}-{o.identifier}
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td>{o.title}</td>
                      <td>{orderUtils.getStateDescription(o.state)}</td>
                      <td>
                        {o.targetDate
                          ? o.targetDate.toLocaleDateString("de-DE", {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric"
                            })
                          : "-"}
                      </td>
                      <td>{o.calculations[0].units}</td>
                      {canSeeFinanceData && (
                        <td>
                          {(o.fulfillment?.priceInfo
                            ? o.fulfillment.priceInfo.totalPrice
                            : o.calculations[0].info.totalprice
                          ).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={6}>
                    <img
                      src={process.env.PUBLIC_URL + "/media/img/no_results.jpg"}
                      style={{
                        display: "block",
                        margin: "0 auto",
                        height: "500px"
                      }}
                      alt="No results found"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="kt-datatable__pager kt-datatable--paging-loaded">
            <Pagination
              itemsCount={ordersFiltered.length}
              pageSize={pageSize}
              onPageChange={currentPage => this.setState({ currentPage })}
              currentPage={currentPage}
              baseSize={20}
              onPageSizeChange={pageSize => this.setState({ pageSize })}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyOrdersTab;
