import React, { FunctionComponent, useMemo, useState } from "react";
import { BSON } from "realm-web";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic";
import { DataContext } from "../../../context/dataContext";
import { CustomOrder } from "../CustomTypes";
import { OrderFileTypes, pricingCommodities } from "../../../model/orders.types";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import orderUtils from "../../../utils/orderUtils";
import OrderHelper, { T_CUSTOM, T_SERVICE, T_SOFTGEL } from "../OrderHelper";
import calculationUtils from "../../../utils/calculationUtils";
import baseUtils from "../../../utils/baseUtils";
import { CommoditiesDocument } from "../../../model/commodities.types";
import { SuppliersDocument } from "../../../model/suppliers.types";
import commodityUtils from "../../../utils/commodityUtils";
import { OrderDetailsGeneral } from "../tabPanles/OrderDetails";
import dbService, { ORDERS, UpdateAction } from "../../../services/dbService";
import userService from "../../../services/userService";
import { T_PRODUCTIONREPORTAPPROVED } from "../../../utils/timelineUtils";
import toastUtils from "../../../utils/toastUtils";

interface ReviewProductionReportModalProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
}

const ReviewProductionReportModal: FunctionComponent<ReviewProductionReportModalProps> = ({ order, context }) => {
  const { commodities, suppliers } = context;
  const report = useMemo(() => {
    if (!order.files) return null;
    const orderFiles = order.files.slice().reverse();
    return orderFiles.find(f => f.type === OrderFileTypes.PRODUCTION_REPORT);
  }, [order]);

  const [show, setShow] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const handleApproveReport = async () => {
    setSaving(true);
    try {
      const action: UpdateAction = {
        collection: ORDERS,
        filter: { _id: order._id },
        update: {
          "fulfillment.productionReportApproval.approvedBy": userService.getUserId().toString(),
          "fulfillment.productionReportApproval.approvalDate": new Date()
        },
        push: {
          timeline: {
            _id: new BSON.ObjectId(),
            type: T_PRODUCTIONREPORTAPPROVED,
            date: new Date(),
            person: userService.getUserId()
          }
        }
      };
      const result = await dbService.updatesAsTransaction([action]);
      await toastUtils.databaseOperationToast(
        result,
        "Production report confirmed",
        "Production report could not be confirmed. Please try again later",
        handleHide
      );
    } finally {
      setSaving(false);
    }
  };

  if (!order.fulfillment?.productionReportApproval?.required || order.fulfillment?.productionReportApproval?.approvedBy)
    return null;
  return (
    <>
      <ErrorOverlayButton
        buttonText={"Review"}
        className={"btn btn-success btn-sm btn-upper"}
        errors={!report ? ["Production report missing"] : []}
        onClick={handleShow}
      />
      <Modal show={show} onHide={handleHide} size={"lg"} centered name="reviewProductionReport">
        <Modal.Header closeButton>
          <Modal.Title>Review Production Report</Modal.Title>
        </Modal.Header>
        <Modal.Body className={"overflow-auto"} style={{ maxHeight: "80vh" }}>
          <div>
            <div className="alert alert-warning" role="alert">
              <div className="alert-icon">
                <i className="flaticon-warning" />
              </div>
              <div className="alert-text">
                Please <b>review</b> the production report <b>thoroughly</b> and confirm that it complies with the
                requested recipe.
              </div>
            </div>
            {report && (
              <>
                <hr />
                <div className="form-group row" style={{ marginBottom: "1rem" }}>
                  <div className="col-12">
                    <div className="kt-widget4">
                      <div className="kt-widget4__item">
                        <div className="">
                          <a
                            href={report.path}
                            target="_blank"
                            rel="noopener noreferrer"
                            className=" kt-widget4__info  mr-2"
                          >
                            <span className={"kt-widget4__username"}>
                              <span className="kt-widget4__pic kt-widget4__pic--icon">
                                <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />
                              </span>
                              <span className="kt-font-bold  my-auto custom-link">Production Report</span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <hr />
            <OrderDetailsGeneral order={order} context={context} />
            <hr />
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--subtable kt-datatable--loaded table-responsive">
              <table className="kt-datatable__table d-table ">
                <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
                  <tr className="kt-datatable__row d-table-row pl-0">
                    <th className="kt-datatable__cell d-table-cell pl-0" style={{ width: "30%" }}>
                      <span>Commodity</span>
                    </th>
                    <th className="kt-datatable__cell d-table-cell" style={{ width: "13%" }}>
                      <span>Per Unit</span>
                    </th>
                    <th className="kt-datatable__cell d-table-cell" style={{ width: "13%" }}>
                      <span>Total Amount</span>
                    </th>
                    <th className="kt-datatable__cell d-table-cell" style={{ minWidth: "15%" }}>
                      <span>Price Change</span>
                    </th>
                    <th className="kt-datatable__cell d-table-cell" style={{ width: "14%" }}>
                      <span>Booked Amount</span>
                    </th>
                    <th className="kt-datatable__cell d-table-cell text-right" style={{ width: "15%" }}>
                      <span>Batches</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                  {order.calculations.map(calc =>
                    calc.prices.map(price => {
                      const units = calc.units;
                      const commodity = commodities.find(c => c._id.toString() === price._id.toString());
                      if (!commodity) return null;
                      const supplier =
                        typeof price.supplier === "string"
                          ? price.supplier
                          : suppliers.find(s => s._id.toString() === price.supplier.toString())!;
                      return (
                        <ReviewProductionReportModalCommodityEntry
                          key={calc.id.toString() + price._id.toString()}
                          type={order.settings.type}
                          units={units}
                          perUnit={order.settings.perUnit}
                          price={price}
                          commodity={commodity}
                          supplier={supplier}
                          order={order}
                        />
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={"btn btn-secondary" + (saving ? " disabled" : "")}
            onClick={saving ? undefined : handleHide}
          >
            Close
          </button>
          <button
            className={"btn btn-success" + (saving ? " disabled" : "")}
            onClick={saving ? undefined : handleApproveReport}
          >
            Approve Report
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface ReviewProductionReportModalCommodityEntryProps {
  type: string;
  units: number;
  perUnit: number;
  price: pricingCommodities;
  commodity: CommoditiesDocument;
  supplier: SuppliersDocument | "accumulatedstock" | "custom" | "customer" | "ownstock";
  order: CustomOrder;
}

const ReviewProductionReportModalCommodityEntry: React.FunctionComponent<
  ReviewProductionReportModalCommodityEntryProps
> = ({ type, units, perUnit, price, commodity, supplier, order }) => {
  const totalAmount = orderUtils.getTotalAmountWithBuffer(perUnit, units, price.amount, price.buffer, type);
  let batchPrice;
  let totalUsage = 0;
  let totalPrice = 0;
  const batches: Array<string> = [];
  // If there are used batches calculate show the average batch price and calculate the price difference to the batch price
  if (order.usedBatches) {
    for (let i = 0; i < order.usedBatches.length; i++) {
      let batch = order.usedBatches[i];
      if (batch.commodityId && batch.commodityId.toString() !== commodity._id.toString()) continue;
      totalUsage += +batch.used;
      totalPrice += +batch.used * +batch.price;
    }
    if (totalUsage !== 0) batchPrice = totalPrice / totalUsage;
    order.usedBatches.forEach(batch => {
      if (batch.commodityId && batch.commodityId.toString() === commodity._id.toString()) batches.push(batch.lot);
    });
  }
  const priceDifference =
    (batchPrice ? batchPrice / +price.estimatedprice - 1 : +price.price / +price.estimatedprice - 1) * 100;
  let pricePerUnit = batchPrice ? batchPrice : price.price;

  return (
    <tr className="kt-datatable__row d-table-row pl-0">
      <td className="kt-datatable__cell d-table-cell pl-0">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">{commodity.title.en}</span>
            <span className="kt-user-card-v2__email">{commodity.subtitle.en}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          {[T_CUSTOM, T_SERVICE, T_SOFTGEL].includes(type)
            ? price.amount + " pcs."
            : calculationUtils.formatAmount(price.amount, 2)}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          {[T_CUSTOM, T_SERVICE, T_SOFTGEL].includes(type)
            ? type === T_SERVICE
              ? totalAmount + " pcs."
              : totalAmount / 1000 + " tsd."
            : calculationUtils.formatAmount(totalAmount, 2)}
        </span>
      </td>
      <td className={"kt-datatable__cell d-table-cell"}>
        <span className="kt-font-bold">
          {baseUtils.formatEuro(pricePerUnit)}/{OrderHelper.getQuantityUnitForType(type)}
        </span>
        <span
          className={
            "kt-font-bold " + (priceDifference < 0 ? "text-success" : priceDifference > 0 ? "text-danger" : "")
          }
        >
          <p className="kt-user-card-v2__name mb-0">
            {priceDifference > 0 && <i className="fa fa-exclamation mr-1" />}
            {priceDifference < 0 && <i className="fa fa-caret-down mr-1" />}
            {priceDifference === 0 && <i className="fa fa-caret-right mr-1" />}
            {priceDifference > 0 && "+"}
            {supplier === "customer" ? "-" : priceDifference.toFixed(2) + "%"}
          </p>
        </span>
      </td>
      <td className={"kt-datatable__cell d-table-cell"}>
        <span>{commodityUtils.resolveStockUnit(totalUsage, commodity.type)}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell text-right" style={{ maxWidth: "8%" }}>
        <span>{batches.length === 0 ? "N/A" : batches.join(", ")}</span>
      </td>
    </tr>
  );
};

export default ReviewProductionReportModal;
