import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import { EM_CHECKED, EM_OPEN } from "../ExternalManufacturerHelper";
import { ExternalManufacturerOrdersDocument } from "../../../model/externalManufacturerOrders.types";

interface EmOrderWidgetProps {
  order: ExternalManufacturerOrdersDocument;
  internal?: boolean;
}

const EmOrderWidget: React.FunctionComponent<EmOrderWidgetProps> = ({ order, internal }) => {
  return (
    <span>
      <div className="kt-user-card-v2 ">
        <div className="kt-user-card-v2__pic d-none d-xl-block">
          <div className="kt-badge kt-badge--xl kt-badge--primary">
            <img src={toAbsoluteUrl("/media/icons/exchange.svg")} className="p-1" alt="Unknown" />
          </div>
        </div>
        <div className="kt-user-card-v2__details">
          <Link
            to={(internal ? "/externalOrder/" : "/order/") + order._id.toString()}
            onClick={e => e.stopPropagation()}
            className="kt-user-card-v2__name d-inline-block kt-link"
          >
            {[EM_OPEN, EM_CHECKED].includes(order.state) ? "REQ" : "EMO"}-{order.identifier}
          </Link>
          <br />
          <span className="kt-user-card-v2__email">{order.reference}</span>
        </div>
      </div>
    </span>
  );
};

export default EmOrderWidget;
