import _ from "lodash";
import React, { PureComponent } from "react";
import Chart from "react-apexcharts";
import { DataContext } from "../../../context/dataContext";
import { Period } from "../../common/CustomTypes";
import orderUtils, { DECLINED } from "../../../utils/orderUtils";
import { ROLES } from "../../../utils/userdataUtils";
import { T_FULFILLMENT } from "../../../utils/timelineUtils";
import { UserdataDocument } from "../../../model/userdata.types";
import contractUtils from "../../../utils/contractUtils";

interface SalesMarginQualityGraphProps {
  context: React.ContextType<typeof DataContext>;
  period: Period;
}

interface SalesMarginQualityGraphState {
  series: Array<{ name: string; data: Array<number> | Array<{ x: string; y: number; fillColor: string }> }>;
  options: any;
}

class SalesMarginQualityGraph extends PureComponent<SalesMarginQualityGraphProps, SalesMarginQualityGraphState> {
  sales: Array<UserdataDocument>;

  constructor(props: SalesMarginQualityGraphProps) {
    super(props);
    this.sales = props.context.userdata.filter(
      u =>
        (u.role.includes(ROLES.SALES) || u.role.includes(ROLES.ADMIN)) &&
        ["Christoph Stachelek", "Jana Papke", "Tayfun Bati", "Pierre Sönnichsen", "Fabian Geisler"].includes(
          u.prename + " " + u.surname
        )
    );
    this.state = {
      series: [],
      options: {
        chart: {
          type: "bar",
          id: "basic-bar-marginQuality",
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: true
          },
          zoom: { enabled: false }
        },
        colors: ["#ff0000", "#868686", "#3dff36"],
        xaxis: {
          categories: this.sales.map(u => u.prename + " " + u.surname)
        },
        yaxis: {
          labels: {
            formatter: (v: string) => +v
          }
        },
        dataLabels: { enabled: false }
      }
    };
  }

  componentDidMount() {
    this.setState({ series: this.calculateData() });
  }

  componentDidUpdate(
    prevProps: Readonly<SalesMarginQualityGraphProps>,
    prevState: Readonly<SalesMarginQualityGraphState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.context.orders, this.props.context.orders)) {
      this.setState({ series: this.calculateData() });
    }
  }

  calculateData = () => {
    const { context, period } = this.props;
    const orders = context.orders.filter(
      o =>
        orderUtils.isOrder(o) &&
        !contractUtils.isContract(o) &&
        o.state !== DECLINED &&
        o.createdOn.getTime() > period.beginning.getTime() &&
        o.createdOn.getTime() < period.end.getTime()
    );
    const data: Array<{ name: string; data: Array<number> }> = [
      { name: "Bad", data: [] },
      { name: "Neutral", data: [] },
      { name: "Good", data: [] }
    ];
    for (let i = 0; i < this.sales.length; i++) {
      const s = this.sales[i];
      const sOrders = orders.filter(o => o.createdFrom.toString() === s._id.toString());
      const marginQuality = { good: 0, neutral: 0, bad: 0 };
      for (let j = 0; j < sOrders.length; j++) {
        const sO = sOrders[j];
        const fulfillment = sO.timeline.find(t => t.type === T_FULFILLMENT);
        if (!fulfillment) continue;
        const unitMargin = sO.fulfillment?.priceInfo?.unitMargin || sO.calculations[0].info.unitmargin;
        if (unitMargin < 0.5) {
          marginQuality.bad += 1;
        } else if (unitMargin > 4) {
          marginQuality.good += 1;
        } else {
          marginQuality.neutral += 1;
        }
      }
      data[0].data.push(marginQuality.bad);
      data[1].data.push(marginQuality.neutral);
      data[2].data.push(marginQuality.good);
    }
    return data;
  };

  render() {
    const { series, options } = this.state;
    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">Margin Quality by Sales Employee</h3>
          </div>
        </div>
        <div className="kt-portlet__body p-3">
          <Chart series={series} type="bar" options={options} heigth="700" width="100%" />
        </div>
      </div>
    );
  }
}
export default SalesMarginQualityGraph;
