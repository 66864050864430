import i18n from "../translations/i18n";
import {
  PhysicalWarehouse,
  StorageSpace,
  WarehouseDefinition
} from "../model/configuration/warehouseConfiguration.types";
import { LocationType, PUStorageSpaceAssignment, StorageSpaceAssignment } from "../model/warehouse/common.types";
import { getBatchLocationInformationObject } from "./batchUtils";
import { BatchLocationInformation } from "../model/warehouse/batch.types";
import { RemoteWarehouseAssignment } from "../components/warehouse/common/AmountEntry";
import { SelectOption } from "../model/common.types";

/**
 * Retrieves the storage spaces from all physical warehouses listed under the given warehouse and returns them as select options
 * @param warehouse the logical warehouse from which all storage spaces should be retrieved from
 * @returns {Array<SelectOption<LocationType>>} storage spaces as select options or empty array if no storage spaces were found
 */
export function getStorageSpaceSelectOptions(
  warehouse: WarehouseDefinition | undefined
): Array<SelectOption<LocationType>> {
  if (warehouse === undefined) return [];
  const storageSpaceSelect = warehouse.physicalWarehouses.flatMap(physicalWarehouse => {
    const storageSpaces = physicalWarehouse.storageSpaces;
    if (!storageSpaces || storageSpaces.length === 0) {
      return []; // Skip physicalWarehouse if storageSpaces is undefined or empty
    }
    return storageSpaces.map(storageSpace => ({
      value: storageSpace._id.toString(),
      label: `(${physicalWarehouse.shortName}) ${storageSpace.storageSpaceNo}`,
      data: LocationType.STORAGESPACE
    }));
  });
  const entries = warehouse.physicalWarehouses.map(pW => ({
    value: pW._id.toString(),
    label: `(${pW.shortName}) ${i18n.t("warehouse:incomingTab")} `,
    data: LocationType.PHYSICAL_WAREHOUSE
  }));
  return [...entries, ...storageSpaceSelect];
}

/**
 * Retrieves the warehouse containing the given storage space
 * @param warehouse the logical warehouse which should be searched
 * @param storageSpaceId the id of the searched storage space
 * @returns {{ physicalWarehouse: PhysicalWarehouse, storageSpace: StorageSpace } | undefined} the physical warehouse and resolved storage space if the storage space was found in the warehouse, undefined if no physical warehouse with the given storage space was found
 */
export function getWarehouseWithStorageSpace(
  warehouse: WarehouseDefinition,
  storageSpaceId: string
): { physicalWarehouse: PhysicalWarehouse; storageSpace: StorageSpace } | undefined {
  let physicalWarehouse: PhysicalWarehouse | undefined;
  let storageSpace: StorageSpace | undefined = undefined;

  physicalWarehouse = warehouse.physicalWarehouses.find(warehouse => {
    storageSpace = warehouse.storageSpaces?.find(st => st._id.toString() === storageSpaceId);
    return storageSpace !== undefined;
  });
  if (physicalWarehouse === undefined || storageSpace === undefined) {
    return;
  } else {
    return { physicalWarehouse, storageSpace };
  }
}

/**
 * Returns the batch location information for a given storage space
 * @param locationId the id of the storage space or physical warehouse for which the BatchLocationInformation should be retrieved
 * @param locationType the type of the given id
 * @param warehouseStructure optional, if given, all warehouses will be searched
 * @param warehouseDefinition optional, if given only the physical warehouses from that definition will be searched
 * @returns {BatchLocationInformation | undefined} the batch location or undefined if the locationId was not found or the locationType not recognized
 */
export function getBatchLocationInformation(
  locationId: string,
  locationType: LocationType,
  warehouseStructure?: Array<WarehouseDefinition>,
  warehouseDefinition?: WarehouseDefinition
): BatchLocationInformation | undefined {
  let logicalWarehouse: WarehouseDefinition | undefined = undefined;
  let physicalWarehouse: PhysicalWarehouse | undefined = undefined;
  let storageSpace: StorageSpace | undefined = undefined;

  if (locationType === LocationType.STORAGESPACE) {
    if (warehouseDefinition) {
      logicalWarehouse = warehouseDefinition;
      const warehouseResult = getWarehouseWithStorageSpace(warehouseDefinition, locationId);
      if (warehouseResult !== undefined) {
        physicalWarehouse = warehouseResult.physicalWarehouse;
        storageSpace = warehouseResult.storageSpace;
      }
    } else if (warehouseStructure) {
      for (let i = 0; i < warehouseStructure.length; i++) {
        const searchedWarehouse = getWarehouseWithStorageSpace(warehouseStructure[i], locationId);
        if (searchedWarehouse !== undefined) {
          logicalWarehouse = warehouseStructure[i];
          physicalWarehouse = searchedWarehouse.physicalWarehouse;
          storageSpace = searchedWarehouse.storageSpace;
          break;
        }
      }
    }
    if (!logicalWarehouse || !physicalWarehouse || !storageSpace) return;
    return getBatchLocationInformationObject(logicalWarehouse, physicalWarehouse, storageSpace);
  } else if (locationType === LocationType.PHYSICAL_WAREHOUSE) {
    if (warehouseDefinition) {
      logicalWarehouse = warehouseDefinition;
      physicalWarehouse = warehouseDefinition.physicalWarehouses.find(pW => pW._id.toString() === locationId);
    } else if (warehouseStructure) {
      for (let i = 0; i < warehouseStructure.length; i++) {
        const searchedWarehouse = warehouseStructure[i].physicalWarehouses.find(pW => pW._id.toString() === locationId);
        if (searchedWarehouse !== undefined) {
          logicalWarehouse = warehouseStructure[i];
          physicalWarehouse = searchedWarehouse;
          break;
        }
      }
    }
    if (!logicalWarehouse || !physicalWarehouse) return;
    return getBatchLocationInformationObject(logicalWarehouse, physicalWarehouse);
  }
  return undefined;
}

/**
 * Checks if the given storageSpaceAssignments contain duplicate locations
 * @param storageSpaceAssignments the storage space assignments to check for duplicate locations
 * @returns {boolean} true if duplicate locations were found, false if not
 */
export function hasDuplicateLocations(
  storageSpaceAssignments: Array<StorageSpaceAssignment | RemoteWarehouseAssignment>
): boolean {
  const locationCounts = storageSpaceAssignments.reduce((counts, assignment) => {
    const location = assignment.location ?? "";
    counts.set(location, (counts.get(location) || 0) + 1);
    return counts;
  }, new Map<string, number>());
  return Array.from(locationCounts.values()).some(count => count > 1);
}

/**
 * Checks if all entries in the puStorageSpaceAssignment have the same destination
 * @param puStorageSpaceAssignment the storage space assignments for all packaging units which should be checked for same locations
 * @returns {boolean} true if one destination is selected for all entries, false if not
 */
export function hasOneLocation(puStorageSpaceAssignment: PUStorageSpaceAssignment): boolean {
  const uniqueLocation = new Set<string | undefined>();
  for (const pu in puStorageSpaceAssignment) {
    const assignments = puStorageSpaceAssignment[pu];
    const locations = assignments.map(assignment => assignment.location);
    for (const location of locations) {
      uniqueLocation.add(location);
    }
  }
  return uniqueLocation.size === 1;
}
