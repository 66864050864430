import _ from "lodash";
import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import SupplierPricesSelection from "../../common/supplier/SupplierPricesSelection";
import { CommoditiesDocument, CommoditySupplier } from "../../../model/commodities.types";
import { SupplierSelected } from "../../common/supplier/CustomTypes";
import { COMMODITIES } from "../../../services/dbService";
import accessUtils, { EDITLOCATIONS } from "../../../utils/accessUtils";
import commodityUtils from "../../../utils/commodityUtils";
import toastUtils from "../../../utils/toastUtils";
import dbCommodityService from "../../../services/dbServices/dbCommodityService";

interface PricingProps {
  commodity: CommoditiesDocument;
  updateDocumentInContext: (collection: string, _id: BSON.ObjectId) => Promise<boolean>;
}

interface PricingState {
  commodity: CommoditiesDocument;
}

class CommodityPricing extends PureComponent<PricingProps, PricingState> {
  constructor(props: PricingProps) {
    super(props);
    this.state = { commodity: _.cloneDeep(props.commodity) };
  }

  componentDidUpdate(prevProps: Readonly<PricingProps>) {
    if (
      !_.isEqual(prevProps.commodity.suppliers, this.props.commodity.suppliers) ||
      !_.isEqual(prevProps.commodity.rawbidsData?.rawbidsId, this.props.commodity.rawbidsData?.rawbidsId)
    )
      this.setState({ commodity: _.cloneDeep(this.props.commodity) });
  }

  /**
   * Handles changing suppliers and their prices.
   * @param suppliersSelected Updated suppliers that should be handled
   */
  handleSuppliersSelectedChange = (suppliersSelected: Array<SupplierSelected>) => {
    const commodity = _.cloneDeep(this.state.commodity);
    const suppliers = [];
    for (let supplier of suppliersSelected) {
      const sup: CommoditySupplier = { _id: supplier._id, prices: [] };
      for (let price of supplier.prices) {
        sup.prices.push({
          _id: price._id,
          moq: +price.moq,
          price: +price.totalPrice,
          purchasePrice: +price.purchasePrice,
          purchaseCurrency: price.purchasePriceCurrency,
          incoterm: price.incoterm ? price.incoterm : "",
          deliverytime: +price.deliveryTime,
          delivery: "",
          note: price.note,
          date: price.age
        });
      }
      suppliers.push(sup);
    }
    commodity.suppliers = suppliers;
    this.setState({ commodity });
  };

  /**
   * Handles saving edited prices and updated the timeline.
   */
  handleSavePrices = async () => {
    const { updateDocumentInContext } = this.props;
    const { commodity } = this.state;
    const { update, timeline } = commodityUtils.prepareSuppliersUpdate(this.props.commodity, commodity);
    const res = await dbCommodityService.updateCommodity(commodity._id, update, timeline);
    await toastUtils.databaseOperationToast(
      !!res && res.modifiedCount > 0,
      "Prices updated successfully",
      "Error updating prices",
      () => updateDocumentInContext(COMMODITIES, commodity._id)
    );
  };

  render() {
    const { commodity } = this.state;
    const canEdit = accessUtils.canEditData(EDITLOCATIONS.COMMODITYPRICES);

    return (
      <>
        <SupplierPricesSelection
          document={commodity}
          type={!commodity.type ? "Commodity" : commodity.type === "softgel" ? "Softgel" : "Custom"}
          onSuppliersSelectedChange={this.handleSuppliersSelectedChange}
          disabled={!canEdit}
        />
        <div className="kt-separator kt-separator--space-lg kt-separator--fit kt-separator--border-solid" />
        <div className="float-right">
          <button
            className={"btn btn-secondary mr-2 " + (!canEdit && "disabled")}
            disabled={!canEdit}
            onClick={canEdit ? () => this.setState({ commodity: _.cloneDeep(this.props.commodity) }) : undefined}
          >
            Reset
          </button>
          <button
            className={"btn btn-success " + (!canEdit && "disabled")}
            disabled={!canEdit}
            onClick={canEdit ? this.handleSavePrices : undefined}
          >
            Save changes
          </button>
        </div>
      </>
    );
  }
}

export default CommodityPricing;
