import _ from "lodash";
import React, { PureComponent } from "react";
import Chart from "react-apexcharts";
import DataTableInfoOverlay from "./DataTableInfoOverlay";
import dashboardUtils from "../../../utils/dashboardUtils";
import { DataContext } from "../../../context/dataContext";
import dateUtils from "../../../utils/dateUtils";
import { T_FULFILLMENT } from "../../../utils/timelineUtils";
import baseUtils from "../../../utils/baseUtils";

interface OutputByManufacturerProps {
  context: React.ContextType<typeof DataContext>;
  startDate: Date;
  endDate: Date;
}

interface OutputByManufacturerState {
  series: Array<{ name: string; data: Array<any> }>;
  options: any;
}

class OutputByManufacturer extends PureComponent<OutputByManufacturerProps, OutputByManufacturerState> {
  colorCounter: { [key: string]: number } = { "0": 0, "1": 0, "2": 0, "3": 0 };
  constructor(props: OutputByManufacturerProps) {
    super(props);
    this.state = {
      series: [],
      options: {
        chart: {
          type: "bar",
          id: "basic-bar-output",
          stacked: true,
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: ["", "DE-GE", "", "", "", "CZ", "", "", "", "DE-PS", ""],
          labels: {
            show: true,
            formatter: (v: string, t: any) => this.formatXAxis(v, t)
          }
        },
        yaxis: {
          labels: {
            formatter: (val: number) => {
              if (val === Infinity) return 1;
              if (val > 1000000) return Number((val / 1000000).toFixed(3)) + "M";
              if (val > 1000) return Number((val / 1000).toFixed(3)) + "K";
              return val;
            }
          }
        },
        colors: [(w: any) => this.resolveColor(w)],
        dataLabels: { enabled: false },
        legend: { show: false }
      }
    };
  }

  componentDidMount() {
    this.generateData();
  }

  componentDidUpdate(
    prevProps: Readonly<OutputByManufacturerProps>,
    prevState: Readonly<OutputByManufacturerState>,
    snapshot?: any
  ) {
    const { context, startDate, endDate } = this.props;
    if (
      !_.isEqual(context.orders, prevProps.context.orders) ||
      startDate !== prevProps.startDate ||
      endDate !== prevProps.endDate
    ) {
      this.generateData();
      this.colorCounter = { "0": 0, "1": 0, "2": 0, "3": 0 };
    }
  }

  /**
   * Resolves the color for the given series index.
   * @param seriesIndex: Index of the series
   * @returns { string } Color code in hex
   */
  resolveColor = ({ seriesIndex }: any) => {
    const cc = this.colorCounter[seriesIndex];
    const old = [0, 1, 4, 5, 8, 9];
    if (
      (this.colorCounter["0"] !== 11 && seriesIndex > 0) ||
      (this.colorCounter["1"] !== 11 && seriesIndex > 1) ||
      (this.colorCounter["2"] !== 11 && seriesIndex === 3)
    ) {
      this.colorCounter = { "0": 0, "1": 0, "2": 0, "3": 0 };
    } else {
      this.colorCounter[seriesIndex] += 1;
    }
    switch (seriesIndex) {
      case 0:
        return old.includes(cc) ? "#d4d4da" : "#d91137";
      case 1:
        return old.includes(cc) ? "#d4d4da" : "#20c997";
      case 2:
        return old.includes(cc) ? "#ededf4" : "#ededf4";
      case 3:
        return old.includes(cc) ? "#d4d4da" : "#15b789";
    }
  };

  /**
   * Format the X-Axis descriptions.
   * @param v: Value of the data point
   * @param t: Contains the value of the data point when on axis or an object with information when on data
   * @returns { string } Description
   */
  formatXAxis = (v: string, t: any) => {
    if (typeof t === "string") return t;
    const { startDate, endDate } = this.props;
    const start = new Date(startDate);
    const end = new Date(endDate);
    const interval = dateUtils.getDaysBetween(start, end);
    const dpi = (t && t.dataPointIndex) || t.dataPointIndex === 0 ? t.dataPointIndex : null;
    // Each manufacturer contains 3 periods and an empty index to separate them to name them properly we need to know
    // where we are
    let period = dpi || dpi === 0 ? dpi % 4 : -1;
    let text = v;
    // Data point index marks the X-th bar inside the graph. Each manufacturer has 3 indexes (3 periods) and there is
    // a gap between them to separate them more visible (index 4 and 7)
    if (dpi >= 0 && dpi < 3) {
      text = "DE-GE ";
    } else if (dpi > 3 && dpi < 7) {
      text = "CZ ";
    } else if (dpi > 7) {
      text = "DE-PS ";
    }
    if (period === 0) {
      start.setDate(start.getDate() + -2 * interval);
      end.setDate(end.getDate() + -2 * interval);
    } else if (period === 1) {
      start.setDate(start.getDate() + -interval);
      end.setDate(end.getDate() + -interval);
    }
    text += "(" + baseUtils.formatDate(start) + " - " + baseUtils.formatDate(end) + ")";
    return text;
  };

  /**
   * Generates the data that is needed for the graph.
   */
  generateData = () => {
    const { context, startDate, endDate } = this.props;
    const { general } = context;
    const days = Math.ceil(dateUtils.getDaysBetween(endDate, startDate));
    let shouldGE = general.find(g => g.data === "outputGE")?.value || 10000;
    shouldGE = Math.ceil((+shouldGE * days) / 7);
    const outputGE_2 = this.calculateOutput("6023c7f660ddad422b9627aa", -days * 2);
    const outputGE_1 = this.calculateOutput("6023c7f660ddad422b9627aa", -days);
    const outputGE = this.calculateOutput("6023c7f660ddad422b9627aa");
    let shouldCZ = general.find(g => g.data === "outputCZ")?.value || 35000;
    shouldCZ = Math.ceil((+shouldCZ * days) / 7);
    const outputCZ_2 = this.calculateOutput("5ef4fd72a4cf4fbc3202f609", -days * 2);
    const outputCZ_1 = this.calculateOutput("5ef4fd72a4cf4fbc3202f609", -days);
    const outputCZ = this.calculateOutput("5ef4fd72a4cf4fbc3202f609");
    let shouldUT = general.find(g => g.data === "outputUT")?.value || 15000;
    shouldUT = Math.ceil((+shouldUT * days) / 7);
    const outputUT_2 = this.calculateOutput("603f684583cc20e514cd1109", -days * 2);
    const outputUT_1 = this.calculateOutput("603f684583cc20e514cd1109", -days);
    const outputUT = this.calculateOutput("603f684583cc20e514cd1109");
    const series = [
      {
        name: "Output underperforming",
        data: [
          outputGE_2 > shouldGE ? 0 : outputGE_2,
          outputGE_1 > shouldGE ? 0 : outputGE_1,
          outputGE > shouldGE ? 0 : outputGE,
          0,
          outputCZ_2 > shouldCZ ? 0 : outputCZ_2,
          outputCZ_1 > shouldCZ ? 0 : outputCZ_1,
          outputCZ > shouldCZ ? 0 : outputCZ,
          0,
          outputUT_2 > shouldUT ? 0 : outputUT_2,
          outputUT_1 > shouldUT ? 0 : outputUT_1,
          outputUT > shouldUT ? 0 : outputUT
        ]
      },
      {
        name: "Output satisfied",
        data: [
          outputGE_2 > shouldGE ? shouldGE : 0,
          outputGE_1 > shouldGE ? shouldGE : 0,
          outputGE > shouldGE ? shouldGE : 0,
          0,
          outputCZ_2 > shouldCZ ? shouldCZ : 0,
          outputCZ_1 > shouldCZ ? shouldCZ : 0,
          outputCZ > shouldCZ ? shouldCZ : 0,
          0,
          outputUT_2 > shouldUT ? shouldUT : 0,
          outputUT_1 > shouldUT ? shouldUT : 0,
          outputUT > shouldUT ? shouldUT : 0
        ]
      },
      {
        name: "Missing to output goal",
        data: [
          outputGE_2 > shouldGE ? 0 : shouldGE - outputGE_2,
          outputGE_1 > shouldGE ? 0 : shouldGE - outputGE_1,
          outputGE > shouldGE ? 0 : shouldGE - outputGE,
          0,
          outputCZ_2 > shouldCZ ? 0 : shouldCZ - outputCZ_2,
          outputCZ_1 > shouldCZ ? 0 : shouldCZ - outputCZ_1,
          outputCZ > shouldCZ ? 0 : shouldCZ - outputCZ,
          0,
          outputUT_2 > shouldUT ? 0 : shouldUT - outputUT_2,
          outputUT_1 > shouldUT ? 0 : shouldUT - outputUT_1,
          outputUT > shouldUT ? 0 : shouldUT - outputUT
        ]
      },
      {
        name: "Overproduction",
        data: [
          outputGE_2 > shouldGE ? outputGE_2 - shouldGE : 0,
          outputGE_1 > shouldGE ? outputGE_1 - shouldGE : 0,
          outputGE > shouldGE ? outputGE - shouldGE : 0,
          0,
          outputCZ_2 > shouldCZ ? outputCZ_2 - shouldCZ : 0,
          outputCZ_1 > shouldCZ ? outputCZ_1 - shouldCZ : 0,
          outputCZ > shouldCZ ? outputCZ - shouldCZ : 0,
          0,
          outputUT_2 > shouldUT ? outputUT_2 - shouldUT : 0,
          outputUT_1 > shouldUT ? outputUT_1 - shouldUT : 0,
          outputUT > shouldUT ? outputUT - shouldUT : 0
        ]
      }
    ];
    this.setState({ series });
  };

  /**
   * Calculate the output for the referenced site.
   * @param site: Site whose output should be calculated
   * @param offset: Optional. Offset for the start and end date
   * @returns { number } Output
   */
  calculateOutput = (
    site: "6023c7f660ddad422b9627aa" | "5ef4fd72a4cf4fbc3202f609" | "603f684583cc20e514cd1109",
    offset?: number
  ) => {
    const { context, startDate, endDate } = this.props;
    const { manufacturers, orders } = context;
    const manufacturer = manufacturers.find(m => m._id.toString() === site);
    if (!manufacturer) return 0;
    const start = new Date(startDate.getTime());
    if (offset) start.setDate(startDate.getDate() + offset);
    const end = new Date(endDate.getTime());
    if (offset) end.setDate(endDate.getDate() + offset);
    let output = 0;
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      if (order.settings.manufacturer.toString() === manufacturer._id.toString()) {
        let fulfillmentDate;
        for (let j = 0; j < order.timeline.length; j++) {
          const timeline = order.timeline[j];
          if (timeline.type === T_FULFILLMENT) {
            fulfillmentDate = timeline.date;
            break;
          }
        }
        if (fulfillmentDate > start && fulfillmentDate < end) {
          if (order.fulfillment && order.fulfillment.totalUnits) {
            output += order.fulfillment.totalUnits;
          } else {
            output += order.calculations[0].units;
          }
        }
      }
    }
    return output;
  };

  render() {
    const { startDate, endDate } = this.props;
    const { options, series } = this.state;
    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">
              Output by Manufacturer ({dashboardUtils.calculateTimeframeDescription(startDate, endDate)}) and periods
              before <DataTableInfoOverlay unit="units" />
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit p-3">
          <Chart series={series} type="bar" options={options} heigth="325" width="100%" />
        </div>
      </div>
    );
  }
}

export default OutputByManufacturer;
