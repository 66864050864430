import _ from "lodash";
import {
  CalculationCapsulePriceDetails,
  CalculationCustomPriceDetails,
  CalculationCustomPriceObject,
  CalculationGeneralPriceDetails,
  CalculationPackagingPriceDetails,
  CalculationPriceDetails,
  CalculationType,
  ExtendedCapsule,
  Preferences,
  SelectedCommoditiesDocument,
  SelectedPackagingsDocument
} from "../CustomTypes";
import { MARGIN_BUFFER } from "../../../utils/calculationUtils";
import { ManufacturersDocument } from "../../../model/manufacturers.types";
import { PackagingTypes, ProductTypes } from "../configuratorConstants";
import { CapsulesDocument } from "../../../model/capsules.types";

export const CAPSULES = "capsules";
export const TABLETS = "tablets";
export const POWDER = "powder";
export const LIQUID = "liquids";
export const BLENDING = "blending";
export const BLISTERING = "blistering";
export const BOTTLING = "bottling";
export const ENCAPSULATION = "encapsulation";
export const TABLETING = "tableting";
export type PRODUCTION_TYPES =
  | typeof ENCAPSULATION
  | typeof TABLETING
  | typeof BLENDING
  | typeof BLISTERING
  | typeof BOTTLING;
const SIZE = "size";
const AMOUNT = "amount";
export const ALLTYPES: Array<PRODUCTION_TYPES> = [ENCAPSULATION, TABLETING, BOTTLING, BLISTERING, BLENDING];

/**
 * Update a calculation object with calculated prices
 * @param calculation the calculation object
 * @param prices prices for recipe, packaging, etc. and the total unit price
 */
function updateCalculationWithPrices(calculation: CalculationType, prices: CalculationPriceDetails) {
  const units = +calculation.units;
  let unitPriceNaked: number;
  let optionalCostsPerUnit: number;
  if (prices.customPrices?.optionalCosts) {
    optionalCostsPerUnit = prices.customPrices.optionalCosts / +calculation.units;
  } else {
    optionalCostsPerUnit = 0;
  }
  if (prices.customPrices?.marginBuffer !== undefined) {
    unitPriceNaked = prices.unitPrice + optionalCostsPerUnit + prices.customPrices.marginBuffer;
  } else {
    unitPriceNaked = prices.unitPrice + optionalCostsPerUnit + MARGIN_BUFFER;
  }
  let unitPrice: number;
  let unitMargin: number;
  let totalPrice: number;
  let totalMargin: number;
  let percentMargin: number;
  if (calculation.marginType === "euro") {
    unitPrice = +calculation.margin;
    unitMargin = unitPrice - unitPriceNaked;
    totalPrice = unitPrice * units;
    totalMargin = totalPrice - unitPriceNaked * units;
    percentMargin = (unitPrice / unitPriceNaked - 1) * 100;
  } else if (calculation.marginType === "percent") {
    percentMargin = +calculation.margin;
    unitPrice = unitPriceNaked * (1 + percentMargin / 100);
    unitMargin = unitPrice - unitPriceNaked;
    totalPrice = unitPrice * units;
    totalMargin = totalPrice - unitPriceNaked * units;
  }
  calculation.unitPriceNaked = unitPriceNaked;
  calculation.unitPrice = unitPrice!;
  calculation.unitMargin = unitMargin!;
  calculation.totalPrice = totalPrice!;
  calculation.totalMargin = totalMargin!;
  calculation.percentMargin = percentMargin!;
  calculation.priceDetails = prices;
  calculation.marginBuffer = prices.customPrices ? prices.customPrices.marginBuffer : MARGIN_BUFFER;
}

/**
 * Calculate the total unit price and all prices for recipe, packaging, capsules, etc.
 * @param type current product type
 * @param calculation calculation object
 * @param preferences preferences object
 * @param recipe all selected commodities
 * @param selectedPackaging all selected packaging
 * @param selectedCapsule the selected capsule
 * @param customCalculationDetails CalculationCustomPriceDetails object
 * @returns {CalculationPriceDetails} CalculationPriceDetails with all price data
 */
function calculateUnitPrice(
  type: string,
  calculation: CalculationType,
  preferences: Preferences,
  recipe: Array<SelectedCommoditiesDocument>,
  selectedPackaging: Array<SelectedPackagingsDocument>,
  selectedCapsule: ExtendedCapsule | null,
  customCalculationDetails?: CalculationCustomPriceDetails
): CalculationPriceDetails {
  const recipeUnitPrices = [];
  for (let i = 0; i < recipe.length; i++) {
    const commodity = recipe[i];
    const commodityUnitPrice = calculateCommodityUnitPrice(type, calculation, preferences, commodity);
    recipeUnitPrices.push({ ...commodity, unitPrice: commodityUnitPrice });
  }
  const packagingUnitPrices = [];
  for (let i = 0; i < selectedPackaging.length; i++) {
    const packaging = selectedPackaging[i];
    const packagingUnitPrice = calculatePackagingUnitPrice(calculation, packaging);
    packagingUnitPrices.push({ ...packaging, unitPrice: packagingUnitPrice } as CalculationPackagingPriceDetails);
  }
  let capsuleUnitPrice = undefined;
  if (selectedCapsule) {
    capsuleUnitPrice = {
      ...(_.omit(selectedCapsule, ["calculations"]) as CapsulesDocument),
      unitPrice: calculateCapsuleUnitPrice(type, calculation, preferences, selectedCapsule)
    } as CalculationCapsulePriceDetails;
  }

  const totalRecipeUnitPrice = recipeUnitPrices.reduce((a, b) => a + b.unitPrice, 0);
  const totalPackagingUnitPrice = packagingUnitPrices.reduce((a, b) => a + b.unitPrice, 0);
  const totalCapsuleUnitPrice = capsuleUnitPrice ? capsuleUnitPrice.unitPrice : 0;
  const generalUnitPrice = calculateGeneralUnitPrice(type, calculation, preferences, selectedPackaging);
  const totalGeneralUnitPrice = Object.values(generalUnitPrice).reduce((a, b) => a + b.unitPrice, 0);
  let totalCustomUnitPrice;
  let unitPrice: number;

  if (customCalculationDetails) {
    // get rid of withBuffer, optionalCost and note in customPrices
    const customPricesObj = calculateCustomUnitPrice(type, customCalculationDetails);
    totalCustomUnitPrice = Object.values(customPricesObj).reduce((a: any, b: any) => a + b.unitPrice, 0) as number;
    unitPrice = totalRecipeUnitPrice + totalPackagingUnitPrice + totalCapsuleUnitPrice + totalCustomUnitPrice;
  } else {
    unitPrice = totalRecipeUnitPrice + totalPackagingUnitPrice + totalCapsuleUnitPrice + totalGeneralUnitPrice;
  }
  return {
    recipeUnitPrice: totalRecipeUnitPrice,
    packagingUnitPrice: totalPackagingUnitPrice,
    capsuleUnitPrice: totalCapsuleUnitPrice,
    generalUnitPrice: totalGeneralUnitPrice,
    recipePrices: recipeUnitPrices,
    packagingPrices: packagingUnitPrices,
    capsulePrice: capsuleUnitPrice,
    generalPrices: generalUnitPrice,
    customPrices: customCalculationDetails,
    unitPrice: unitPrice,
    customUnitPrice: totalCustomUnitPrice
  } as CalculationPriceDetails;
}

/**
 * Recalculate all unit prices
 * @param type current product type
 * @param calculation calculation object
 * @param preferences preferences object
 * @param recipe the current recipe
 * @param selectedPackaging current selected packaging
 * @param selectedCapsule current selected capsule
 * @param recalculate defines if either recipe, packaging or everything needs to be recalculated
 * @param customCalculationDetails CalculationCustomPriceDetails object
 * @returns {CalculationPriceDetails | undefined} CalculationPriceDetails object with updated prices or undefined if priceDetails were found in the calculation
 */
function recalculateUnitPrices(
  type: string,
  calculation: CalculationType,
  preferences: Preferences,
  recipe: Array<SelectedCommoditiesDocument>,
  selectedPackaging: Array<SelectedPackagingsDocument>,
  selectedCapsule: ExtendedCapsule | null,
  recalculate: "recipe" | "packaging" | "capsules" | "full",
  customCalculationDetails?: CalculationCustomPriceDetails
): CalculationPriceDetails | undefined {
  const priceDetails = calculation.priceDetails;
  if (!priceDetails) return;
  let recipeUnitPrices = [];
  if (["recipe", "full"].includes(recalculate)) {
    for (let i = 0; i < recipe.length; i++) {
      const commodity = recipe[i];
      const commodityUnitPrice = calculateCommodityUnitPrice(type, calculation, preferences, commodity);
      recipeUnitPrices.push({ ...commodity, unitPrice: commodityUnitPrice });
    }
  }
  let packagingUnitPrices = [];
  if (["packaging", "full"].includes(recalculate)) {
    for (let i = 0; i < selectedPackaging.length; i++) {
      const packaging = selectedPackaging[i];
      const packagingUnitPrice = calculatePackagingUnitPrice(calculation, packaging);
      packagingUnitPrices.push({ ...packaging, unitPrice: packagingUnitPrice } as CalculationPackagingPriceDetails);
    }
  }
  let capsuleUnitPrice = undefined;
  if (selectedCapsule) {
    capsuleUnitPrice = {
      ...(_.omit(selectedCapsule, ["calculations"]) as CapsulesDocument),
      unitPrice: calculateCapsuleUnitPrice(type, calculation, preferences, selectedCapsule)
    } as CalculationCapsulePriceDetails;
  }

  const totalRecipeUnitPrice = recipeUnitPrices.reduce((a, b) => a + b.unitPrice, 0);
  const totalPackagingUnitPrice = packagingUnitPrices.reduce((a, b) => a + b.unitPrice, 0);
  const totalCapsuleUnitPrice = capsuleUnitPrice ? capsuleUnitPrice.unitPrice : 0;
  const generalUnitPrice = calculateGeneralUnitPrice(type, calculation, preferences, selectedPackaging);
  const totalGeneralUnitPrice = Object.values(generalUnitPrice).reduce((a, b) => a + b.unitPrice, 0);
  let totalCustomUnitPrice;
  if (customCalculationDetails) {
    // get rid of withBuffer, optionalCost and note in customPrices
    const customPricesObj = calculateCustomUnitPrice(type, customCalculationDetails);
    totalCustomUnitPrice = Object.values(customPricesObj).reduce((a: any, b: any) => a + b.unitPrice, 0) as number;
  }

  priceDetails.generalUnitPrice = totalGeneralUnitPrice;
  priceDetails.generalPrices = generalUnitPrice;
  priceDetails.customUnitPrice = totalCustomUnitPrice;
  if (["packaging", "full"].includes(recalculate)) {
    priceDetails.packagingUnitPrice = totalPackagingUnitPrice;
    priceDetails.packagingPrices = packagingUnitPrices;
  }
  if (["recipe", "full"].includes(recalculate)) {
    priceDetails.recipePrices = recipeUnitPrices;
    priceDetails.recipeUnitPrice = totalRecipeUnitPrice;
  }
  priceDetails.capsulePrice = capsuleUnitPrice;
  priceDetails.capsuleUnitPrice = totalCapsuleUnitPrice;
  if (customCalculationDetails && priceDetails.customUnitPrice) {
    priceDetails.unitPrice =
      priceDetails.customUnitPrice +
      priceDetails.capsuleUnitPrice +
      priceDetails.packagingUnitPrice +
      priceDetails.recipeUnitPrice;
  } else {
    priceDetails.unitPrice =
      priceDetails.generalUnitPrice +
      priceDetails.capsuleUnitPrice +
      priceDetails.packagingUnitPrice +
      priceDetails.recipeUnitPrice;
  }
  return priceDetails;
}

/**
 * Calculate unit price for a capsule
 * @param type current product type
 * @param calculation calculation object
 * @param preferences preferences object
 * @param capsule an extended capsule
 * @returns {number} unit price for the capsule
 */
function calculateCapsuleUnitPrice(
  type: string,
  calculation: CalculationType,
  preferences: Preferences,
  capsule: ExtendedCapsule
): number {
  if (type !== ProductTypes.CAPSULES) return 0;
  const capsuleCalculation = capsule.calculations.find(calc => calc.id.toString() === calculation.id.toString())!;
  const amountPerUnit = +preferences.amountPerUnit;
  const unitPrice = (amountPerUnit / 1000) * +capsuleCalculation.price;
  return unitPrice!;
}

/**
 * Calculate general unit prices for bottling, encapsulation, etc.
 * @param type the current product type
 * @param calculation calculation object
 * @param preferences preferences object
 * @param selectedPackaging the selected packaging
 * @returns CalculationGeneralPriceDetails with prices
 */
function calculateGeneralUnitPrice(
  type: string,
  calculation: CalculationType,
  preferences: Preferences,
  selectedPackaging: Array<SelectedPackagingsDocument>
): CalculationGeneralPriceDetails {
  if (type === ProductTypes.CAPSULES) {
    return calculateCapsulesUnitPrice(calculation, preferences, selectedPackaging);
  } else if (type === ProductTypes.TABLETS) {
    return calculateTabletsUnitPrice(calculation, preferences, selectedPackaging);
  } else if (type === ProductTypes.POWDER) {
    return calculatePowderUnitPrice(calculation, preferences, selectedPackaging);
  } else if (type === ProductTypes.LIQUID) {
    return calculateLiquidUnitPrice(calculation, preferences, selectedPackaging);
  } else if (type === ProductTypes.SOFTGEL) {
    return calculateSoftgelUnitPrice(calculation, preferences, selectedPackaging);
  } else {
    return {};
  }
}

/**
 * Calculate general unit prices for bottling, encapsulation, etc.
 * @param type the current product type
 * @param customCalculationDetails CalculationCustomPriceDetails object
 * @returns {CalculationCustomPriceObject} with prices
 */
function calculateCustomUnitPrice(
  type: string,
  customCalculationDetails: CalculationCustomPriceDetails
): CalculationCustomPriceObject {
  if (type === ProductTypes.CAPSULES) {
    return calculateCustomCapsulesUnitPrice(customCalculationDetails);
  } else if (type === ProductTypes.TABLETS) {
    return calculateCustomTabletsUnitPrice(customCalculationDetails);
  } else if (type === ProductTypes.POWDER) {
    return calculateCustomPowderUnitPrice(customCalculationDetails);
  } else if (type === ProductTypes.LIQUID) {
    return calculateCustomLiquidUnitPrice(customCalculationDetails);
  } else if (type === ProductTypes.SOFTGEL) {
    return calculateCustomSoftGelsUnitPrice(customCalculationDetails);
  } else {
    return {};
  }
}

/**
 * Calculate unit price for capsules
 * @param calculation calculation object
 * @param preferences preferences object
 * @param selectedPackaging the selected packaging
 * @returns {CalculationGeneralPriceDetails} cost object with unit price for capsule production
 */
function calculateCapsulesUnitPrice(
  calculation: CalculationType,
  preferences: Preferences,
  selectedPackaging: Array<SelectedPackagingsDocument>
): CalculationGeneralPriceDetails {
  const manufacturer = preferences.selectedManufacturer!;
  const filler = preferences.selectedFiller;
  const capsule = preferences.selectedCapsule!;
  const costObject: CalculationGeneralPriceDetails = {};
  const encapsulationTotalAmount = (+preferences.amountPerUnit * +calculation.units) / 1000;
  const encapsulationAmountPerUnit = +preferences.amountPerUnit;
  const encapsulationCost = getPriceFromManufacturer(manufacturer, CAPSULES, ENCAPSULATION, SIZE, capsule.capsule_size);
  costObject.encapsulation = {
    unitPrice: (+encapsulationCost * +preferences.amountPerUnit) / 1000,
    price: +encapsulationCost,
    totalAmount: encapsulationTotalAmount,
    amountPerUnit: encapsulationAmountPerUnit
  };

  const blister = selectedPackaging.find(pack => pack.packaging_type === PackagingTypes.BLISTER);
  const bag = selectedPackaging.some(pack => pack.packaging_type === PackagingTypes.BAG);
  if (!!blister) {
    const blisterAmount = +blister.amount!;
    const totalBlisterAmount = +calculation.units * blisterAmount;
    const blisteringCost = getPriceFromManufacturer(
      filler || manufacturer,
      CAPSULES,
      BLISTERING,
      AMOUNT,
      totalBlisterAmount.toString()
    );
    costObject.blistering = {
      unitPrice: +blisteringCost * blisterAmount,
      price: +blisteringCost,
      amountPerUnit: blisterAmount,
      totalAmount: totalBlisterAmount
    };
  } else if (!bag) {
    const bottleAmount = selectedPackaging.find(pack => pack.packaging_type === PackagingTypes.BOTTLE)?.amount;
    if (bottleAmount) {
      const totalBottleAmount = +calculation.units * bottleAmount;
      const bottlingCost = getPriceFromManufacturer(
        filler || manufacturer,
        CAPSULES,
        BOTTLING,
        AMOUNT,
        totalBottleAmount.toString()
      );
      costObject.bottling = {
        unitPrice: +bottlingCost * bottleAmount,
        price: +bottlingCost,
        amountPerUnit: bottleAmount,
        totalAmount: totalBottleAmount
      };
    }
  }
  return costObject;
}

/**
 * Calculate tablets unit price
 * @param calculation calculation object
 * @param preferences preferences object
 * @param selectedPackaging selected packaging
 * @returns {CalculationGeneralPriceDetails} cost object with unit price for tablet production
 */
function calculateTabletsUnitPrice(
  calculation: CalculationType,
  preferences: Preferences,
  selectedPackaging: Array<SelectedPackagingsDocument>
): CalculationGeneralPriceDetails {
  const costObject: CalculationGeneralPriceDetails = {};
  const manufacturer = preferences.selectedManufacturer!;
  const filler = preferences.selectedFiller;
  const totalAmount = (+calculation.units * +preferences.amountPerUnit) / 1000;
  const tablettingCost = getPriceFromManufacturer(manufacturer, TABLETS, TABLETING, AMOUNT, totalAmount.toString());
  costObject.tableting = {
    unitPrice: (+tablettingCost / 1000) * +preferences.amountPerUnit,
    price: +tablettingCost,
    totalAmount: totalAmount,
    amountPerUnit: +preferences.amountPerUnit
  };

  const bottleAmount = selectedPackaging.find(pack => pack.packaging_type === PackagingTypes.BOTTLE)?.amount;
  if (bottleAmount) {
    const totalBottleAmount = +calculation.units * bottleAmount;
    const bottlingCost = getPriceFromManufacturer(
      filler || manufacturer,
      TABLETS,
      BOTTLING,
      AMOUNT,
      totalBottleAmount.toString()
    );
    costObject.bottling = {
      unitPrice: +bottlingCost * bottleAmount,
      price: +bottlingCost,
      amountPerUnit: bottleAmount,
      totalAmount: totalBottleAmount
    };
  }
  return costObject;
}

/**
 * Calculate powder unit price
 * @param calculation calculation object
 * @param preferences preferences object
 * @param selectedPackaging selected packaging
 * @returns {CalculationGeneralPriceDetails} cost object with unit price for powder production
 */
function calculatePowderUnitPrice(
  calculation: CalculationType,
  preferences: Preferences,
  selectedPackaging: Array<SelectedPackagingsDocument>
): CalculationGeneralPriceDetails {
  const costObject: CalculationGeneralPriceDetails = {};
  const manufacturer = preferences.selectedManufacturer!;
  const filler = preferences.selectedFiller;
  const totalAmount = (+preferences.amountPerUnit * +calculation.units) / (1000 * 1000);
  const blendingCost = getPriceFromManufacturer(manufacturer, POWDER, BLENDING, AMOUNT, totalAmount.toString());
  costObject.blending = {
    unitPrice: +blendingCost * (+preferences.amountPerUnit / (1000 * 1000)),
    price: +blendingCost,
    totalAmount: totalAmount,
    amountPerUnit: 1
  };

  const bottleAmount = selectedPackaging.find(pack => pack.packaging_type === PackagingTypes.BOTTLE)?.amount;
  if (bottleAmount) {
    const totalBottleAmount = +calculation.units * bottleAmount;
    const bottlingCost = getPriceFromManufacturer(
      filler || manufacturer,
      POWDER,
      BOTTLING,
      AMOUNT,
      totalAmount.toString()
    );
    costObject.bottling = {
      unitPrice: +bottlingCost * bottleAmount,
      price: +bottlingCost,
      amountPerUnit: bottleAmount,
      totalAmount: totalBottleAmount
    };
  }
  return costObject;
}

/**
 * Calculate liquid unit price
 * @param calculation calculation object
 * @param preferences preferences object
 * @param selectedPackaging selected packaging
 * @returns cost object with unit price for liquid production
 */
function calculateLiquidUnitPrice(
  calculation: CalculationType,
  preferences: Preferences,
  selectedPackaging: Array<SelectedPackagingsDocument>
): CalculationGeneralPriceDetails {
  const costObject: CalculationGeneralPriceDetails = {};
  const manufacturer = preferences.selectedManufacturer!;
  const filler = preferences.selectedFiller;
  const totalAmount = (+preferences.amountPerUnit * +calculation.units) / (1000 * 1000);
  const blendingCost = getPriceFromManufacturer(manufacturer, LIQUID, BLENDING, AMOUNT, totalAmount.toString());
  costObject.blending = {
    unitPrice: +blendingCost * (+preferences.amountPerUnit / (1000 * 1000)),
    price: +blendingCost,
    totalAmount: totalAmount,
    amountPerUnit: 1
  };

  const bottleAmount = selectedPackaging.find(pack => pack.packaging_type === PackagingTypes.LIQUIDBOTTLE)?.amount;
  if (bottleAmount) {
    const totalBottleAmount = +calculation.units * bottleAmount;
    const bottlingCost = getPriceFromManufacturer(
      filler || manufacturer,
      LIQUID,
      BOTTLING,
      AMOUNT,
      totalAmount.toString()
    );
    costObject.bottling = {
      unitPrice: +bottlingCost * bottleAmount,
      price: +bottlingCost,
      amountPerUnit: bottleAmount,
      totalAmount: totalBottleAmount
    };
  }
  return costObject;
}

/**
 * Calculate softgel unit price
 * @param calculation calculation object
 * @param preferences preferences object
 * @param selectedPackaging selected packaging
 * @returns {CalculationGeneralPriceDetails} cost object with unit price for softgel production
 */
function calculateSoftgelUnitPrice(
  calculation: CalculationType,
  preferences: Preferences,
  selectedPackaging: Array<SelectedPackagingsDocument>
): CalculationGeneralPriceDetails {
  const manufacturer = preferences.selectedManufacturer!;
  const costObject: CalculationGeneralPriceDetails = {};
  const blister = selectedPackaging.find(pack => pack.packaging_type === PackagingTypes.BLISTER);
  const bottle = selectedPackaging.find(pack => pack.packaging_type === PackagingTypes.BOTTLE);

  if (!!blister) {
    const totalBlisterAmount = +calculation.units * +blister.amount!;
    const blisteringCost = getPriceFromManufacturer(
      manufacturer,
      CAPSULES,
      BLISTERING,
      AMOUNT,
      totalBlisterAmount.toString()
    );
    costObject.blistering = {
      unitPrice: +blisteringCost * +blister.amount!,
      price: +blisteringCost,
      amountPerUnit: +blister.amount!,
      totalAmount: totalBlisterAmount
    };
  } else if (bottle) {
    const bottleAmount = bottle.amount!;
    const totalBottleAmount = +calculation.units * bottleAmount;
    const bottlingCost = getPriceFromManufacturer(
      manufacturer,
      CAPSULES,
      BOTTLING,
      AMOUNT,
      totalBottleAmount.toString()
    );
    costObject.bottling = {
      unitPrice: +bottlingCost * bottleAmount,
      price: +bottlingCost,
      amountPerUnit: bottleAmount,
      totalAmount: totalBottleAmount
    };
  }
  return costObject;
}

/**
 * Calculate custom unit price for capsules
 * @param customPrices CalculationCustomPriceDetails object
 * @returns {CalculationCustomPriceObject} cost object with custom unit price for capsule production
 */
function calculateCustomCapsulesUnitPrice(customPrices: CalculationCustomPriceDetails): CalculationCustomPriceObject {
  let costObject: CalculationCustomPriceObject = {};
  if (customPrices?.encapsulation) {
    costObject = {
      encapsulation: {
        unitPrice: +customPrices.encapsulation.unitPrice,
        price: +customPrices.encapsulation.price,
        manufacturerId: customPrices.encapsulation.manufacturerId
      }
    };
  }
  if (customPrices?.blistering) {
    costObject.blistering = {
      unitPrice: +customPrices.blistering.unitPrice,
      price: +customPrices.blistering.price,
      manufacturerId: customPrices.blistering.manufacturerId
    };
  } else if (customPrices?.bottling) {
    costObject.bottling = {
      unitPrice: +customPrices.bottling.unitPrice,
      price: +customPrices.bottling.price,
      manufacturerId: customPrices.bottling.manufacturerId
    };
  }
  return costObject;
}

/**
 * Calculate custom unit price for capsules
 * @param customPrices CalculationCustomPriceDetails object
 * @returns {CalculationCustomPriceObject} cost object with custom unit price for capsule production
 */
function calculateCustomTabletsUnitPrice(customPrices: CalculationCustomPriceDetails): CalculationCustomPriceObject {
  let costObject: CalculationCustomPriceObject = {};
  if (customPrices?.tableting) {
    costObject = {
      tableting: {
        unitPrice: +customPrices.tableting.unitPrice,
        price: +customPrices.tableting.price,
        manufacturerId: customPrices.tableting.manufacturerId
      }
    };
  }
  if (customPrices?.bottling) {
    costObject.bottling = {
      unitPrice: +customPrices.bottling.unitPrice,
      price: +customPrices.bottling.price,
      manufacturerId: customPrices.bottling.manufacturerId
    };
  }
  return costObject;
}

/**
 * Calculate custom unit price for capsules
 * @param customPrices CalculationCustomPriceDetails object
 * @returns {CalculationCustomPriceObject} cost object with custom unit price for capsule production
 */
function calculateCustomPowderUnitPrice(customPrices: CalculationCustomPriceDetails): CalculationCustomPriceObject {
  let costObject: CalculationCustomPriceObject = {};
  if (customPrices?.blending) {
    costObject = {
      blending: {
        unitPrice: +customPrices.blending.unitPrice,
        price: +customPrices.blending.price,
        manufacturerId: customPrices.blending.manufacturerId
      }
    };
  }
  if (customPrices?.bottling) {
    costObject.bottling = {
      unitPrice: +customPrices.bottling.unitPrice,
      price: +customPrices.bottling.price,
      manufacturerId: customPrices.bottling.manufacturerId
    };
  }
  return costObject;
}

/**
 * Calculate custom unit price for capsules
 * @param customPrices CalculationCustomPriceDetails object
 * @returns {CalculationCustomPriceObject} cost object with custom unit price for capsule production
 */
function calculateCustomLiquidUnitPrice(customPrices: CalculationCustomPriceDetails): CalculationCustomPriceObject {
  let costObject: CalculationCustomPriceObject = {};
  if (customPrices?.blending) {
    costObject = {
      blending: {
        unitPrice: +customPrices.blending.unitPrice,
        price: +customPrices.blending.price,
        manufacturerId: customPrices.blending.manufacturerId
      }
    };
  }
  if (customPrices?.bottling) {
    costObject.bottling = {
      unitPrice: +customPrices.bottling.unitPrice,
      price: +customPrices.bottling.price,
      manufacturerId: customPrices.bottling.manufacturerId
    };
  }
  return costObject;
}

/**
 * Calculate custom unit price for capsules
 * @param customPrices CalculationCustomPriceDetails object
 * @returns {CalculationCustomPriceObject} cost object with custom unit price for capsule production
 */
function calculateCustomSoftGelsUnitPrice(customPrices: CalculationCustomPriceDetails): CalculationCustomPriceObject {
  const costObject: CalculationCustomPriceObject = {};
  if (customPrices?.blistering) {
    costObject.blistering = {
      unitPrice: +customPrices.blistering.unitPrice,
      price: +customPrices.blistering.price,
      manufacturerId: customPrices.blistering.manufacturerId
    };
  } else if (customPrices?.bottling) {
    costObject.bottling = {
      unitPrice: +customPrices.bottling.unitPrice,
      price: +customPrices.bottling.price,
      manufacturerId: customPrices.bottling.manufacturerId
    };
  }
  return costObject;
}

/**
 * Get pricing values from a manufacturer
 * @param manufacturer the manufacturer document
 * @param type the type e.g. capsules, tablets, ...
 * @param subtype the subtype e.g. encapsulation, bottling, ...
 * @param filterType the filter type e.g. amount or size
 * @param filter the filter value e.g. 00 for size
 * @returns {string} price as string
 */
function getPriceFromManufacturer(
  manufacturer: ManufacturersDocument,
  type: string,
  subtype: string,
  filterType: "amount" | "size",
  filter: string
): string {
  const obj = _.get(manufacturer, type);
  const costs = _.get(obj, subtype);
  if (costs.length === 1) return subtype === ENCAPSULATION ? costs[0].cost.toString() : costs[0].price.toString();
  let price = 0;
  if (filterType === AMOUNT) {
    let bestPrice;
    // get best price that also matches the amount
    for (let x of costs) {
      if (!bestPrice || (+x.amount <= +filter && +x.price < +bestPrice)) {
        bestPrice = x.price;
      }
    }
    price = bestPrice;
    if (!price) price = costs[0].price;
  } else if (filterType === SIZE) {
    price = costs.find((x: any) => x.size.trim() === filter.trim())?.cost;
    if (!price) price = costs[0].cost;
  }
  return price.toString();
}

/**
 * Calculate unit price for a commodity
 * @param type current product type
 * @param calculation calculation object
 * @param preferences preferences object
 * @param commodity a selected commodity
 * @returns {number} unit price for the commodity
 */
function calculateCommodityUnitPrice(
  type: string,
  calculation: CalculationType,
  preferences: Preferences,
  commodity: SelectedCommoditiesDocument
): number {
  const commodityCalculation = commodity.calculations.find(calc => calc.id.toString() === calculation.id.toString())!;
  const amountPerUnit = getAmountPerUnitForCommodity(type, preferences, commodity)!;
  let unitPrice: number;
  if ([ProductTypes.CAPSULES, ProductTypes.TABLETS, ProductTypes.POWDER, ProductTypes.LIQUID].includes(type)) {
    // price in €/kg
    unitPrice =
      ((+amountPerUnit * (1 + commodityCalculation.buffer / 100)) / (1000 * 1000)) * +commodityCalculation.price;
  } else if ([ProductTypes.CUSTOM, ProductTypes.SOFTGEL].includes(type)) {
    // price in €/1k
    unitPrice = ((amountPerUnit * (1 + commodityCalculation.buffer / 100)) / 1000) * +commodityCalculation.price;
  } else if (type === ProductTypes.SERVICE) {
    // price in €
    unitPrice = commodityCalculation!.price;
  }
  return unitPrice!;
}

/**
 * Get amount per unit for a commodity
 * @param type the current product type
 * @param preferences preferences object
 * @param commodity selected commodity document
 * @returns {number | undefined} amount per unit of commodity, undefined if no commodity amount is found
 */
function getAmountPerUnitForCommodity(
  type: string,
  preferences: Preferences,
  commodity: SelectedCommoditiesDocument
): number | undefined {
  switch (type) {
    case ProductTypes.CAPSULES:
    case ProductTypes.TABLETS:
      return +preferences.amountPerUnit * commodity.amount!;
    case ProductTypes.POWDER:
    case ProductTypes.LIQUID:
      return commodity.amount!;
    case ProductTypes.CUSTOM:
    case ProductTypes.SOFTGEL:
      return +preferences.amountPerUnit;
    case ProductTypes.SERVICE:
      return 1;
  }
}

/**
 * Calculate unit price for a selected packaging
 * @param calculation a calculation object
 * @param packaging a selected packaging document
 * @returns {number} unit price for the packaging
 */
function calculatePackagingUnitPrice(calculation: CalculationType, packaging: SelectedPackagingsDocument): number {
  if (!("calculations" in packaging) || !("amount" in packaging)) return 0;
  const packagingCalculation = packaging.calculations!.find(calc => calc.id.toString() === calculation.id.toString())!;
  const amountPerUnit = packaging.amount!;
  return packagingCalculation.price * amountPerUnit;
}

// eslint-disable-next-line
export default {
  recalculateUnitPrices,
  calculateUnitPrice,
  updateCalculationWithPrices,
  getPriceFromManufacturer
};
