import React, { useEffect, useState } from "react";

interface DateInputProps {
  value: Date | null;
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  min?: Date;
  max?: Date;
  disabled?: boolean;
  classes?: string;
  allowClear?: boolean;
}

const DateInput: React.FunctionComponent<DateInputProps> = ({
  value,
  onBlur,
  name,
  min,
  max,
  disabled,
  classes,
  allowClear
}) => {
  const [date, setDate] = useState(value);

  useEffect(() => {
    setDate(value);
  }, [value]);

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.valueAsDate || allowClear) onBlur(e);
  };

  return (
    <input
      type="date"
      className={classes ? classes : "form-control"}
      name={name}
      min={min ? min.toISOString().split("T")[0] : undefined}
      max={max ? max.toISOString().split("T")[0] : undefined}
      value={date ? date.toISOString().split("T")[0] : undefined}
      disabled={!!disabled}
      onChange={e => (e.target.valueAsDate || allowClear) && setDate(e.target.valueAsDate)}
      onBlur={disabled ? undefined : handleOnBlur}
    />
  );
};

export default DateInput;
