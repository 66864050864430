import _ from "lodash";
import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CompaniesDocument } from "../../model/companies.types";
import { OrdersDocument } from "../../model/orders.types";
import { UserdataDocument } from "../../model/userdata.types";
import CompanyWidget from "../common/CompanyWidget";
import { PaginationState } from "../common/CustomTypes";
import Pagination, { paginate } from "../common/Pagination";
import PersonWidget from "../common/PersonWidget";
import RatingInfo from "../common/RatingInfo";
import companyUtils from "../../utils/companyUtils";
import baseUtils from "../../utils/baseUtils";

interface CompaniesOverviewTableProps extends RouteComponentProps<{}, {}, {}> {
  companies: Array<CompaniesDocument>;
  orders: Array<OrdersDocument>;
  userdata: Array<UserdataDocument>;
  query: string;
  rating: string;
  orderStatus: string;
}

interface CompaniesOverviewTableState extends PaginationState {
  companiesPrepared: Array<CompaniesListing>;
}

interface CompaniesListing {
  company: CompaniesDocument;
  contact: UserdataDocument;
  fullName: string;
  turnover: number;
  orderStatus: { inTime: number; delayed: number };
}

class CompaniesOverviewTable extends PureComponent<CompaniesOverviewTableProps, CompaniesOverviewTableState> {
  constructor(props: CompaniesOverviewTableProps) {
    super(props);
    this.state = { companiesPrepared: [], currentPage: 1, pageSize: 10 };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(
    prevProps: Readonly<CompaniesOverviewTableProps>,
    prevState: Readonly<CompaniesOverviewTableState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.companies, this.props.companies) || !_.isEqual(prevProps.orders, this.props.orders)) {
      this.prepareData();
    }
    if (
      prevProps.query !== this.props.query ||
      prevProps.rating !== this.props.rating ||
      prevProps.orderStatus !== this.props.orderStatus
    ) {
      this.setState({ currentPage: 1 });
    }
  }

  /**
   * Prepares the data to be shown inside the table.
   */
  prepareData = () => {
    const { companies, orders, userdata } = this.props;
    // Collect all orders
    let companyOrdersMap: { [key: string]: Array<OrdersDocument> } = {};
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      if (companyOrdersMap[order.createdFor.toString()]) {
        companyOrdersMap[order.createdFor.toString()].push(order);
      } else {
        companyOrdersMap[order.createdFor.toString()] = [order];
      }
    }

    const companiesPrepared = [];
    for (let company of companies) {
      const companyOrders = companyOrdersMap[company._id.toString()] ? companyOrdersMap[company._id.toString()] : [];
      const contact = company.contactPerson ? baseUtils.getDocFromCollection(userdata, company.contactPerson) : null;
      companiesPrepared.push({
        company,
        contact: contact,
        fullName: contact ? contact.prename + " " + contact.surname : "",
        turnover: companyUtils.calculateTotalTurnover(company._id, companyOrders),
        orderStatus: companyUtils.getOrdersStatus(company._id, companyOrders)
      });
    }
    this.setState({ companiesPrepared });
  };

  /**
   * Filters the shown companies. The search is looking for matches inside companies name and the city, zip and country
   * of the first address.
   */
  filterCompanies = () => {
    const { orderStatus, query, rating } = this.props;
    const { companiesPrepared } = this.state;
    const ql = query.toLowerCase();
    let comPrep: CompaniesListing[];
    if (ql !== "") {
      comPrep = baseUtils.doFuseSearch(companiesPrepared, ql, [
        "company.name",
        "company.address.street",
        "company.address.streetnr",
        "company.address.city",
        "company.address.zip",
        "company.address.country",
        "fullName"
      ]);
    } else comPrep = companiesPrepared;
    if (orderStatus === "inTime")
      comPrep = comPrep.filter(c => c.orderStatus.delayed === 0 && c.orderStatus.inTime > 0);
    else if (orderStatus === "delayed") comPrep = comPrep.filter(c => c.orderStatus.delayed > 0);
    else if (orderStatus === "noOrder")
      comPrep = comPrep.filter(c => c.orderStatus.delayed === 0 && c.orderStatus.inTime === 0);
    if (rating !== "all") comPrep = comPrep.filter(c => c.company.rating === rating);
    return comPrep.sort((c1, c2) => c1.company.name.localeCompare(c2.company.name));
  };

  render() {
    const { userdata } = this.props;
    const { currentPage, pageSize } = this.state;
    const companiesFiltered = this.filterCompanies();
    const coms: Array<CompaniesListing> = paginate(companiesFiltered, currentPage, pageSize);
    return (
      <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive px-4">
        <Table>
          <thead>
            <tr className="kt-datatable__row">
              <th style={{ width: "25%" }}>Company</th>
              <th style={{ width: "25%" }}>Contact Person</th>
              <th style={{ width: "15%" }}>Turnover</th>
              <th style={{ width: "10%" }}>Rating</th>
              <th style={{ width: "15%" }}>Status</th>
              <th style={{ width: "20%" }}>Owner</th>
            </tr>
          </thead>
          <tbody>
            {coms &&
              coms.map(c => {
                const contact = c.contact;
                const owner = userdata.find(u => u._id.toString() === c.company.owner.toString());
                return (
                  <tr key={c.company._id.toString()}>
                    <td className="align-middle">
                      <CompanyWidget company={c.company} />
                    </td>
                    <td className="align-middle">{contact && <PersonWidget person={contact} />}</td>
                    <td className="align-middle">
                      <span className={"h6 font-weight-bold " + (c.turnover === 0 ? "text-warning" : "text-success")}>
                        {c.turnover.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
                      </span>
                    </td>
                    <td className="align-middle">
                      <RatingInfo rating={c.company.rating} />
                    </td>
                    <td className="align-middle">
                      <span>{c.orderStatus.inTime + c.orderStatus.delayed} orders pending</span>
                      <br />
                      {c.orderStatus.delayed > 0 && (
                        <span className="text-danger font-weight-bold">{c.orderStatus.delayed} delayed</span>
                      )}
                    </td>
                    <td className="align-middle">{owner && <PersonWidget person={owner} />}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <div className="kt-datatable__pager kt-datatable--paging-loaded">
          <Pagination
            itemsCount={companiesFiltered.length}
            pageSize={pageSize}
            onPageChange={currentPage => this.setState({ currentPage })}
            currentPage={this.state.currentPage}
            onPageSizeChange={pageSize => this.setState({ pageSize, currentPage: 1 })}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(CompaniesOverviewTable);
