import _ from "lodash";
import React, { PureComponent } from "react";
import Select from "react-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BSON } from "realm-web";
import { Dropdown } from "semantic-ui-react";
import { toAbsoluteUrl } from "../../_metronic";
import { ActiveSubstance } from "./CustomTypes";
import SelectActiveSubstance from "./SelectActiveSubstance";
import CanOnlyBeDoneByProcurement from "../common/CanOnlyBeDoneByProcurement";
import CountryPicker from "../common/CountryPicker";
import { SupplierSelected } from "../common/supplier/CustomTypes";
import SupplierPricesSelection from "../common/supplier/SupplierPricesSelection";
import { DataContext } from "../../context/dataContext";
import { AllergensDocument } from "../../model/allergens.types";
import { Commodities, CommoditySupplier } from "../../model/commodities.types";
import { CommoditypropertiesDocument } from "../../model/commodityproperties.types";
import { COMMODITIES } from "../../services/dbService";
import dbGeneralService from "../../services/dbServices/dbGeneralService";
import accessUtils, { CREATELOCATIONS } from "../../utils/accessUtils";
import generalUtils from "../../utils/generalUtils";
import toastUtils from "../../utils/toastUtils";
import baseUtils from "../../utils/baseUtils";
import dbCommodityService from "../../services/dbServices/dbCommodityService";
import slackService from "../../services/slackService";

interface CreateCommodityProps extends RouteComponentProps<{}, {}, {}> {
  type: "commodity" | "softgel" | "custom" | "service";
}

interface CreateCommodityState {
  commodity: {
    titleDe: string;
    titleEn: string;
    subtitleDe: string;
    subtitleEn: string;
    form: "" | { label: string; value: string };
    category: "" | { label: string; value: string };
    density: string;
    solvent: null | { value: string; label: string };
    internalCode: string;
    hsCode: string;
    casNumber: string;
    color: "" | { label: string; value: string } | null;
    organic: boolean;
    organicCode: string;
    toxicAmount: string | null;
    articleNumber: string;
    note: string;
    country: "" | { label: string; value: string };
    properties: Array<CommoditypropertiesDocument>;
    allergens: Array<AllergensDocument>;
    activeSubstances: Array<ActiveSubstance>;
    suppliers: Array<SupplierSelected>;
  };
  specifications: Array<{
    date: Date;
    id: BSON.ObjectId;
    path: string;
    type: string;
  }>;
  query: string;
  saving: boolean;
}

class CreateCommodity extends PureComponent<CreateCommodityProps, CreateCommodityState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: CreateCommodityProps) {
    super(props);
    this.state = {
      commodity: {
        titleDe: "",
        titleEn: "",
        subtitleDe: "",
        subtitleEn: "",
        form: "",
        category: "",
        density: "0.5",
        solvent: null,
        internalCode: "",
        hsCode: "",
        casNumber: "",
        color: null,
        organic: false,
        organicCode: "",
        toxicAmount: null,
        articleNumber: "",
        note: "",
        country: "",
        properties: [],
        allergens: [],
        activeSubstances: [],
        suppliers: []
      },
      specifications: [],
      query: "",
      saving: false
    };
  }

  /**
   * Converts the commodity properties into the format needed for the Dropdown.
   * @returns {Array<key: string, text: string, value: string>} List of values
   */
  convertForDropdown = (collection: string) => {
    let documents: Array<CommoditypropertiesDocument | AllergensDocument>;
    if (collection === "properties") {
      documents = this.context.commodityproperties;
    } else {
      documents = this.context.allergens;
    }
    const convertedDocuments: Array<{ key: string; text: string; value: string }> = [];
    documents.map(document =>
      convertedDocuments.push({
        key: document._id.toString(),
        text: document.name.en.trim(),
        value: document._id.toString()
      })
    );
    return _.sortBy(convertedDocuments, item => item.text.toLowerCase());
  };

  /**
   * Handle the change of a commodity property.
   * @param name of the changing commodity value
   * @param value  that the state is set to
   */
  handleSelect = (name: string, value: { value: string; label: string } | "") => {
    const commodity = _.cloneDeep(this.state.commodity);
    const { commoditycategories, general } = this.context;
    if (!value) {
      // @ts-ignore
      commodity[name] = value;
      this.setState({ commodity });
      return;
    }
    if (name === "category") {
      const category = commoditycategories.find(c => c._id.toString() === value.value.toString())!;
      commodity.internalCode = generalUtils.getNextInternalCode(category.internal_code_reference, general);
    }
    // @ts-ignore
    commodity[name] = value;
    this.setState({ commodity });
  };

  /**
   * Handle the change of a commodity property.
   * @param e: Change event
   */
  handleCommodityChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const commodity = _.cloneDeep(this.state.commodity);
    let value: string = e.target.value;
    const name = e.target.name;
    if (["density", "toxicAmount"].includes(name)) {
      value = value.replaceAll(/^0+/g, "0");
      if (!value.includes(".")) value = Number(value).toString();
      if (!value || +value < 0) return;
    }
    // @ts-ignore
    commodity[e.target.name] = value;
    this.setState({ commodity });
  };

  /**
   * Handles the change of the organic flag and updated the organic code.
   */
  handleCheckOrganic = () => {
    const commodity = _.cloneDeep(this.state.commodity);
    const { general } = this.context;
    if (commodity.organic) {
      commodity.organic = false;
      commodity.organicCode = "";
    } else {
      commodity.organic = true;
      commodity.organicCode = generalUtils.getNextInternalCode("internal_code_60000", general);
    }
    this.setState({ commodity });
  };

  /**
   * Handles the change of the dropdown fields.
   * @param type: Determines if the allergens or properties are changed.
   * @param value: New values for the fields
   */
  handleDropdownChange = (type: "allergens" | "properties", value: Array<string>) => {
    const commodity = _.cloneDeep(this.state.commodity);
    const { allergens, commodityproperties } = this.context;
    if (type === "allergens") {
      commodity.allergens = allergens.filter(a => value.includes(a._id.toString()));
    } else if (type === "properties") {
      commodity.properties = commodityproperties.filter(cp => value.includes(cp._id.toString()));
    }
    this.setState({ commodity });
  };

  /**
   * Handles the change of the selected suppliers.
   * @param suppliersSelected: List of selected suppliers with their prices
   */
  handleSuppliersSelectedChange = (suppliersSelected: Array<SupplierSelected>) => {
    const commodity = _.cloneDeep(this.state.commodity);
    const suppliers = [];
    for (let supplier of suppliersSelected) {
      const sup: SupplierSelected = { _id: supplier._id, prices: [] };
      for (let price of supplier.prices) {
        sup.prices.push(_.cloneDeep(price));
      }
      suppliers.push(sup);
    }
    commodity.suppliers = suppliers;
    this.setState({ commodity });
  };

  handleAddActiveSubstance = () => {
    const commodity = _.cloneDeep(this.state.commodity);
    commodity.activeSubstances.push({ _id: "", nrv: "" });
    this.setState({ commodity });
  };

  handleChangeActiveSubstance = (idx: number, _id: string, nrv: string) => {
    const commodity = _.cloneDeep(this.state.commodity);
    commodity.activeSubstances[idx] = { _id, nrv };
    this.setState({ commodity });
  };

  handleDeleteActiveSubstance = (idx: number) => {
    const commodity = _.cloneDeep(this.state.commodity);
    commodity.activeSubstances.splice(idx, 1);
    this.setState({ commodity });
  };

  /**
   * Collects all data of the new commodity and saves it into the database.
   */
  createCommodity = async () => {
    const { history } = this.props;
    const { commodity } = this.state;
    const { commoditycategories, updateDocumentInContext } = this.context;
    this.setState({ saving: true });
    const suppliers: Array<CommoditySupplier> = commodity.suppliers.map(s => {
      const prices = [];
      for (let price of s.prices) {
        prices.push({
          _id: price._id,
          date: price.age,
          moq: +price.moq,
          note: price.note,
          price: +price.totalPrice,
          deliverytime: +price.deliveryTime,
          delivery: "",
          purchasePrice: +price.purchasePrice,
          purchaseCurrency: price.purchasePriceCurrency,
          incoterm: price.incoterm!
        });
      }
      return { _id: s._id, prices };
    });

    // Collect correct types, forms, density and category for each commodity type
    let type = "",
      form: BSON.ObjectId | "custom" = "custom",
      density: number | null = null,
      category: BSON.ObjectId | "custom" = "custom",
      internal_code = "";
    switch (this.props.type) {
      case "commodity":
        if (!commodity.category) return;
        const categoryReference = baseUtils.getDocFromCollection(
          commoditycategories,
          commodity.category.value
        )!.internal_code_reference;
        if (!commodity.form || this.checkForErrors().length) return;
        internal_code = (await dbGeneralService.getAndIncreaseGeneralNr(categoryReference)).toString();
        density = +commodity.density;
        category = new BSON.ObjectId(commodity.category.value);
        form = new BSON.ObjectId(commodity.form.value);
        break;
      case "service":
        type = "service";
        break;
      case "softgel":
        type = "softgel";
        break;
      case "custom":
        type = "purchased";
        break;
    }
    const commodityDocument: Commodities = {
      approved: false,
      title: { de: commodity.titleDe, en: commodity.titleEn },
      subtitle: { de: commodity.subtitleDe, en: commodity.subtitleEn },
      type,
      form,
      color: commodity.color ? new BSON.ObjectId(commodity.color.value) : null,
      country: commodity.country ? commodity.country.value : "",
      category,
      density,
      hs_code: commodity.hsCode,
      orders: [],
      solvent: commodity.solvent ? new BSON.ObjectId(commodity.solvent.value) : null,
      organic: commodity.organic,
      stock: [],
      properties: commodity.properties.map(p => p._id),
      allergens: commodity.allergens.map(a => a._id),
      activesubstance: commodity.activeSubstances
        .filter(as => as._id)
        .map(as => {
          return {
            _id: new BSON.ObjectId(as._id),
            value: Number(as.nrv)
          };
        }),
      note: commodity.note,
      disabled: false,
      organic_code: commodity.organic ? (await dbGeneralService.getNextOrganicCode()).toString() : "",
      internal_code,
      specifications: [],
      toxic_amount: commodity.toxicAmount && +commodity.toxicAmount ? +commodity.toxicAmount : null,
      forecast: [],
      timeline: [],
      article_number: commodity.articleNumber,
      cas_number: commodity.casNumber,
      suppliers,
      identifier: "-1" // invalid default identifier, to be replaced in backend
    };
    const res = await dbCommodityService.createCommodity(commodityDocument);
    await toastUtils.databaseOperationToast(
      !!res && !!res.insertedId,
      "Commodity created successfully",
      "Error creating commodity",
      () => (res && "insertedId" in res ? updateDocumentInContext(COMMODITIES, res.insertedId) : undefined)
    );
    this.setState({ saving: false });

    if (!!res && res.insertedId) {
      const message = `:information_source: A commodity <${
        process.env.REACT_APP_BASE_URL
      }commodity/${res.insertedId.toString()}|*${
        commodityDocument.title.en
      }*> has been created and needs to be approved!`;
      slackService.sendMessage("#notifications-commodities", message);
      history.push("/commodity/" + res.insertedId.toString());
    }
  };

  /**
   * Checks the currently inserted information for errors
   * @returns {Array<string>} Contains all errors
   */
  checkForErrors = () => {
    const { type } = this.props;
    const { commodity } = this.state;
    const errors = [];
    if (commodity.titleDe.length < 3) {
      errors.push("German title too short");
    }
    if (commodity.titleEn.length < 3) {
      errors.push("English title too short");
    }
    if (type === "commodity" && !commodity.form) {
      errors.push("Composition not set");
    }
    if (type === "commodity" && !commodity.category) {
      errors.push("Category not set");
    }
    for (let i = 0; i < commodity.suppliers.length; i++) {
      const supplier = commodity.suppliers[i];
      for (let price of supplier.prices!) {
        if (!parseFloat(price.totalPrice.toString())) {
          errors.push("Price of supplier " + (i + 1) + " is invalid");
        }
        if (!parseFloat(price.purchasePrice.toString())) {
          errors.push("Purchase price of supplier " + (i + 1) + " is invalid");
        }
      }
    }
    return errors;
  };

  /**
   * Resolve descriptions for the different fields that are used in the commodity creation dialog.
   * @returns {
   *    header: string, icon: string, sectionTitle: string, title: string, titlePlaceholderDe: string,
   *    titlePlaceholderEn: string, subtitle: string, subtitlePlaceholderDe: string,
   *    subtitlePlaceholderEn: string, saveButton: string
   *  } Descriptions for different commodity types
   */
  resolveFieldDescriptions = () => {
    const { type } = this.props;
    switch (type) {
      case "commodity":
        return {
          header: "Commodity",
          icon: "flaticon2-supermarket",
          sectionTitle: "Commodity Info",
          title: "Commodity Title",
          titlePlaceholderDe: "Allgemeine Rohstoffbezeichnung",
          titlePlaceholderEn: "Common Commodity Title",
          subtitle: "Commodity Subtitle",
          subtitlePlaceholderDe: "Rohstoffdetails, z.B. Herkunft, Geschmack, Verfahren",
          subtitlePlaceholderEn: "Commodity details, e.g. origin, flavor or method",
          saveButton: "Create Commodity"
        };
      case "softgel":
        return {
          header: "Softgel",
          icon: "fas fa-capsules",
          sectionTitle: "Softgel Info",
          title: "Softgel Title",
          titlePlaceholderDe: "Allgemeine Softgelbezeichnung",
          titlePlaceholderEn: "Common Softgel Title",
          subtitle: "Softgel Description",
          subtitlePlaceholderDe: "Softgeldetails, z.B. Herkunft, Geschmack, Verfahren",
          subtitlePlaceholderEn: "Softgel details, e.g. origin, flavor or method",
          saveButton: "Create Softgel"
        };
      case "custom":
        return {
          header: "Custom Product",
          icon: "fas fa-boxes",
          sectionTitle: "Product Info",
          title: "Product Title",
          titlePlaceholderDe: "Allgemeine Produktbezeichnung",
          titlePlaceholderEn: "Common Product Title",
          subtitle: "Product Description",
          subtitlePlaceholderDe: "Produktdetails, z.B. Herkunft, Geschmack, Verfahren",
          subtitlePlaceholderEn: "Product details, e.g. origin, flavor or method",
          saveButton: "Create Custom Product"
        };
      case "service":
        return {
          header: "Service",
          icon: "fas fa-hands",
          sectionTitle: "Service Info",
          title: "Service Title",
          titlePlaceholderDe: "Allgemeine Dienstleistungsbezeichnung",
          titlePlaceholderEn: "Common Service Title",
          subtitle: "Service Description",
          subtitlePlaceholderDe: "Details zur Dienstleistung",
          subtitlePlaceholderEn: "Details of the Service",
          saveButton: "Create Service"
        };
    }
  };

  render() {
    const { history, type } = this.props;
    const { commodity, saving } = this.state;
    const { activesubstances, colors, commoditycategories, compositions, solvents } = this.context;
    const errors = this.checkForErrors();
    const description = this.resolveFieldDescriptions();
    const canCreate = accessUtils.canCreateData(CREATELOCATIONS.COMMODITY);

    return (
      <div>
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className={"kt-font-brand " + description.icon} />
              </span>
              <h3 className="kt-portlet__head-title">Create a new {description.header}</h3>
            </div>
            <div className="kt-portlet__head-toolbar">
              <button onClick={history.goBack} className="btn btn-clean kt-margin-r-10">
                <i className="la la-arrow-left" />
                <span className="kt-hidden-mobile">Back</span>
              </button>
            </div>
          </div>
          <div className="kt-portlet__body">
            {canCreate ? (
              <div className="row">
                <div className="col-1" />
                <div className="col-10">
                  <div className="kt-section">
                    <div className="kt-section__body">
                      <h3 className="kt-section__title kt-section__title-lg">{description.sectionTitle}:</h3>
                      <div className="form-group row">
                        <label className="col-3 col-form-label">{description.title}</label>
                        <div className="col-9">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <img
                                  alt="country-de"
                                  src={toAbsoluteUrl("media/icons/icon-de.png")}
                                  className="country-icon"
                                />
                              </span>
                            </div>
                            <input
                              className={"form-control" + (commodity.titleDe.length < 3 ? " is-invalid" : "")}
                              type="text"
                              value={commodity.titleDe}
                              onChange={this.handleCommodityChange}
                              placeholder={description.titlePlaceholderDe}
                              name="titleDe"
                            />
                          </div>
                          <div className="input-group mt-2">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <img
                                  alt="country-en"
                                  src={toAbsoluteUrl("media/icons/icon-en.png")}
                                  className="country-icon"
                                />
                              </span>
                            </div>
                            <input
                              className={"form-control" + (commodity.titleEn.length < 3 ? " is-invalid" : "")}
                              type="text"
                              onChange={this.handleCommodityChange}
                              placeholder={description.titlePlaceholderEn}
                              value={commodity.titleEn}
                              name="titleEn"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-3 col-form-label">{description.subtitle}</label>
                        <div className="col-9">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <img
                                  alt="country-de"
                                  src={toAbsoluteUrl("media/icons/icon-de.png")}
                                  className="country-icon"
                                />
                              </span>
                            </div>
                            <input
                              className="form-control"
                              type="text"
                              onChange={this.handleCommodityChange}
                              value={commodity.subtitleDe}
                              placeholder={description.subtitlePlaceholderDe}
                              name="subtitleDe"
                            />
                          </div>
                          <div className="input-group mt-2">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <img
                                  alt="country-en"
                                  src={toAbsoluteUrl("media/icons/icon-en.png")}
                                  className="country-icon"
                                />
                              </span>
                            </div>
                            <input
                              className="form-control"
                              type="text"
                              onChange={this.handleCommodityChange}
                              value={commodity.subtitleEn}
                              placeholder={description.subtitlePlaceholderEn}
                              name="subtitleEn"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
                      {type === "commodity" && (
                        <>
                          <div className="form-group row">
                            <label className="col-3 col-form-label">Settings</label>
                            <div className="col-3">
                              <Select
                                className={"select-default" + (!commodity.category ? " is-invalid" : "")}
                                options={_.sortBy(commoditycategories, item => item.name.en.trim().toLowerCase()).map(
                                  item => {
                                    return { value: item._id, label: _.upperFirst(item.name.en) };
                                  }
                                )}
                                value={commodity.category || { value: "", label: "Select Category" }}
                                onChange={(value: any) => this.handleSelect("category", value || "")}
                              />
                            </div>
                            <div className="col-3">
                              <Select
                                className={"select-default" + (!commodity.form ? " is-invalid" : "")}
                                options={_.sortBy(compositions, item => item.name.en.trim().toLowerCase()).map(item => {
                                  return { value: item._id, label: _.upperFirst(item.name.en) };
                                })}
                                value={commodity.form || { value: "", label: "Select Composition" }}
                                onChange={(value: any) => this.handleSelect("form", value || "")}
                              />
                            </div>
                            <div className="col-3">
                              <div className="input-group">
                                <input
                                  className="form-control"
                                  type="number"
                                  value={commodity.density}
                                  onChange={this.handleCommodityChange}
                                  placeholder="Bulk Density"
                                  name="density"
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    g / cm<sup>3</sup>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-3" />
                            <div className="col-3">
                              <input
                                className="form-control"
                                type="number"
                                value={commodity.internalCode}
                                disabled
                                placeholder="Internal article number"
                              />
                            </div>
                            <div className="col-3">
                              <input
                                className="form-control"
                                type="text"
                                value={commodity.hsCode}
                                onChange={this.handleCommodityChange}
                                name="hsCode"
                                placeholder="HS-Code"
                              />
                            </div>
                            <div className="col-3">
                              <input
                                className="form-control"
                                type="text"
                                value={commodity.casNumber}
                                onChange={this.handleCommodityChange}
                                name="casNumber"
                                placeholder="CAS Number"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-3" />
                            <div className="col-3">
                              <div className="input-group">
                                <input
                                  className="form-control"
                                  type="number"
                                  value={commodity.toxicAmount ? commodity.toxicAmount : ""}
                                  onChange={this.handleCommodityChange}
                                  name="toxicAmount"
                                  placeholder="Toxic Amount"
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">mg / day</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <Select
                                className="select-default"
                                isClearable={true}
                                options={_.sortBy(colors, item => item.name.en.trim().toLowerCase()).map(item => {
                                  return { value: item._id.toString(), label: _.upperFirst(item.name.en) };
                                })}
                                value={commodity.color || { value: "", label: "Select Color" }}
                                onChange={(value: any) => this.handleSelect("color", value || "")}
                              />
                            </div>
                            <div className="col-3">
                              <CountryPicker
                                value={commodity.country || { value: "", label: "Select Country" }}
                                handleChange={this.handleSelect}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-3" />
                            <div className="col-3">
                              <div className="mt-2">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={this.handleCheckOrganic}
                                    checked={commodity.organic}
                                    name="organic"
                                  />
                                  <label
                                    className="form-check-label kt-font-dark ml-1"
                                    onClick={this.handleCheckOrganic}
                                  >
                                    Organic commodity
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <input
                                className="form-control"
                                type="text"
                                value={commodity.articleNumber}
                                onChange={this.handleCommodityChange}
                                name="articleNumber"
                                placeholder="Article number"
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="form-group row">
                        <label className="col-3 col-form-label">Properties </label>
                        <div className="col-9">
                          <Dropdown
                            placeholder="Properties"
                            fluid
                            multiple
                            selection
                            options={this.convertForDropdown("properties")}
                            value={commodity.properties.map(p => p._id.toString())}
                            onChange={(e, { value }) => {
                              // @ts-ignore
                              this.handleDropdownChange("properties", value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-3 col-form-label">Allergens </label>
                        <div className="col-9">
                          <Dropdown
                            placeholder="Allergens"
                            fluid
                            multiple
                            selection
                            options={this.convertForDropdown("allergens")}
                            value={commodity.allergens.map(a => a._id.toString())}
                            onChange={(e, { value }) => {
                              // @ts-ignore
                              this.handleDropdownChange("allergens", value);
                            }}
                          />
                        </div>
                      </div>
                      {type === "commodity" && (
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Solvent (optional)</label>
                          <div className="col-9">
                            <Select
                              className="select-default"
                              isClearable={true}
                              options={_.sortBy(solvents, item => item.name.en.trim().toLowerCase()).map(s => {
                                return {
                                  value: s._id,
                                  label: s.name.en
                                };
                              })}
                              value={commodity.solvent ? commodity.solvent : { value: "", label: "None" }}
                              onChange={(value: any) => this.handleSelect("solvent", value || null)}
                            />
                          </div>
                        </div>
                      )}
                      {type === "commodity" && (
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Active Substance </label>
                          <div className="col-9">
                            <SelectActiveSubstance
                              selected={commodity.activeSubstances}
                              activeSubstances={activesubstances}
                              onAdd={this.handleAddActiveSubstance}
                              onChange={this.handleChangeActiveSubstance}
                              onDelete={this.handleDeleteActiveSubstance}
                            />
                          </div>
                        </div>
                      )}
                      <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
                      <div className="form-group row">
                        <label className="col-3 col-form-label">Suppliers / Pricing </label>
                        <div className="col-9">
                          <SupplierPricesSelection
                            type={type === "commodity" ? "Commodity" : type === "service" ? "Service" : "Custom"}
                            onSuppliersSelectedChange={this.handleSuppliersSelectedChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <CanOnlyBeDoneByProcurement location={type} />
            )}
          </div>
          <div className="kt-portlet__foot text-right">
            {errors.length > 0 || !canCreate ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id="commodity-invalid">
                    {!canCreate ? (
                      <span className="text-danger">
                        <b>You are not allowed to create commodities</b>
                      </span>
                    ) : (
                      errors.map((e, key) => {
                        return (
                          <React.Fragment key={key}>
                            <span className="text-danger">
                              <b>{e}</b>
                            </span>
                            <br />
                          </React.Fragment>
                        );
                      })
                    )}
                  </Tooltip>
                }
                placement="left"
              >
                <button className="btn btn-success disabled">{description.saveButton}</button>
              </OverlayTrigger>
            ) : (
              <button
                className={"btn btn-success" + (saving ? " disabled" : "")}
                disabled={saving}
                onClick={this.createCommodity}
              >
                {saving ? "Creating..." : description.saveButton}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateCommodity);
