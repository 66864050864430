import React, { useMemo } from "react";
import Tooltip from "../../../../common/Tooltip";
import { resolveTranslation } from "../../../../../utils/translationUtils";
import { formatNumValue } from "../../../../../utils/baseUtils";
import { BookOutReservation } from "../../../../../model/warehouse/customTypes.types";
import i18n from "../../../../../translations/i18n";

interface BookOutOverviewReservationsProps {
  reservations: Array<BookOutReservation>;
}

export const BookOutOverviewReservations: React.FC<BookOutOverviewReservationsProps> = ({ reservations }) => {
  if (reservations.length === 0) return null;
  return (
    <>
      <hr className="w-100 my-4" />
      <div
        className="card card-custom card-fit card-border my-3"
        style={{
          boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.05)"
        }}
      >
        <div className="card-body">
          <div className="font-size-lg text-black d-block my-2 font-weight-normal">
            {i18n.t("warehouse:reservations")}
          </div>
          <hr />
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive px-2 mb-0">
            <table className="kt-datatable__table d-table p-5">
              <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
                <tr className="kt-datatable__row d-table-row">
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "15%" }}>
                    <span>{i18n.t("warehouse:reservation")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "13%" }}>
                    <span>{i18n.t("common:order")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "20%" }}>
                    <span>{i18n.t("warehouse:location")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "20%" }}>
                    <span>{i18n.t("warehouse:reservedAmount")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "20%" }}>
                    <span>{i18n.t("warehouse:usedAmount")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "12%" }}>
                    <span>{i18n.t("warehouse:restAmount")}</span>
                  </th>
                </tr>
              </thead>
              <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                {reservations.map((r, idx) => (
                  <BookOutOverviewReservation key={r._id} reservation={r} noBorder={idx === reservations.length - 1} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

interface BookOutOverviewReservationProps {
  reservation: BookOutReservation;
  noBorder?: boolean;
}

const BookOutOverviewReservation: React.FC<BookOutOverviewReservationProps> = ({
  reservation: reservationProp,
  noBorder
}) => {
  const { reservation, location } = reservationProp;

  const amountLeft = useMemo(
    () => (reservation.removeReservation ? 0 : reservation.reservedAmount.value - +reservation.amountToBookOut),
    [reservation.reservedAmount, reservation.amountToBookOut, reservation.removeReservation]
  );

  return (
    <tr className={"kt-datatable__row d-table-row " + (noBorder && "border-0")}>
      <td className="kt-datatable__cell d-table-cell pb-0">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <span className="kt-user-card-v2__name text-black ">RV-{reservation.identifier}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell pb-0">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <span className="kt-user-card-v2__name text-black font-weight-300">AT-{reservation.order.identifier}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell pb-0">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <Tooltip
              tooltipText={
                <>
                  {`${resolveTranslation(location.warehouseSnapshot.warehouseName)} - ${resolveTranslation(
                    location.warehouseArea.warehouseName
                  )}`}
                  <br />
                  {`${i18n.t("warehouse:storageSpace")}: ${
                    location.storageSpace ? location.storageSpace.storageSpaceNo : i18n.t("warehouse:entrance")
                  }`}
                </>
              }
            >
              <span className="kt-user-card-v2__name text-black ">
                {location.warehouseSnapshot.shortName} - {location.warehouseArea.shortName}{" "}
                {location.storageSpace ? location.storageSpace.storageSpaceNo : i18n.t("warehouse:entrance")}
              </span>
            </Tooltip>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell pb-0">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <span className="kt-user-card-v2__name text-black">{formatNumValue(reservation.reservedAmount)}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell pb-0">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <span className="kt-user-card-v2__name text-black">
              {formatNumValue({ ...reservation.reservedAmount, value: +reservation.amountToBookOut })}
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell pb-0">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <span className="kt-user-card-v2__name text-black">
              {formatNumValue({ ...reservation.reservedAmount, value: amountLeft })}
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
};
