import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import Packaging from "./Packaging";

interface PackagingWrapperProps extends RouteComponentProps<PackagingParams, {}, {}> {}

interface PackagingParams {
  id: string;
  order: string;
}

const PackagingWrapper: React.FunctionComponent<PackagingWrapperProps> = props => {
  const context = useContext(DataContext);
  return <Packaging context={context} {...props} />;
};

export default PackagingWrapper;
