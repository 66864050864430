import React, { PureComponent } from "react";
import { CustomerData } from "../../CustomTypes";

interface CustomerDataComponentProps {
  customerData: CustomerData;
  onCustomerChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

interface CustomerDataComponentState {}

class CustomerDataComponent extends PureComponent<CustomerDataComponentProps, CustomerDataComponentState> {
  render() {
    const { customerData, onCustomerChange, disabled } = this.props;
    return (
      <div className="col-12 col-sm-6">
        <div>
          <BareCustomerData customerData={customerData} onCustomerChange={onCustomerChange} disabled={disabled} />
        </div>
      </div>
    );
  }
}

export class BareCustomerData extends PureComponent<CustomerDataComponentProps, CustomerDataComponentState> {
  render() {
    const { customerData, onCustomerChange, disabled } = this.props;
    return (
      <>
        <div className="col-12 mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Customer"
            value={customerData.name}
            name="name"
            onChange={onCustomerChange}
            disabled={!!disabled}
          />
        </div>
        <div className="col-12 mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="c/o"
            value={customerData.careOf}
            name="careOf"
            onChange={onCustomerChange}
            disabled={!!disabled}
          />
        </div>
        <div className="col-12 mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Street"
            value={customerData.street}
            name="street"
            onChange={onCustomerChange}
            disabled={!!disabled}
          />
        </div>
        {!disabled && (
          <div className="col-12 mb-2">
            <input
              type="text"
              className="form-control"
              placeholder="Additional address"
              value={customerData.additionalAddress}
              name="additionalAddress"
              onChange={onCustomerChange}
              disabled={!!disabled}
            />
          </div>
        )}
        <div className="input-group">
          <div className="col-4 mb-2 pr-1">
            <input
              type="text"
              className="form-control"
              placeholder="ZIP"
              value={customerData.zip}
              name="zip"
              onChange={onCustomerChange}
              disabled={!!disabled}
            />
          </div>
          <div className="col-8 mb-2 pl-1">
            <input
              type="text"
              className="form-control"
              placeholder="City"
              value={customerData.city}
              name="city"
              onChange={onCustomerChange}
              disabled={!!disabled}
            />
          </div>
        </div>
        <div className="col-12 mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Country"
            value={customerData.country}
            name="country"
            onChange={onCustomerChange}
            disabled={!!disabled}
          />
        </div>
      </>
    );
  }
}

export default CustomerDataComponent;
