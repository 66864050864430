import _ from "lodash";
import countryList from "i18n-iso-countries";
import React from "react";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_metronic";
import baseUtils from "../../utils/baseUtils";
import commodityUtils from "../../utils/commodityUtils";
import {
  CalculationCustomPriceDetails,
  CalculationType,
  CapsuleCalculation,
  CapsuleCalculationSupplier,
  CommodityCalculation,
  CustomCommoditiesDocument,
  CustomPackagingsDocument,
  ExtendedCapsule,
  ExtendedCustomer,
  ExtendedOrder,
  PackagingCalculation,
  PartialPreferences,
  Preferences,
  SelectedCommoditiesDocument,
  SelectedPackagingsDocument
} from "./CustomTypes";
import { CommoditiesDocument } from "../../model/commodities.types";
import { CommoditycategoriesDocument } from "../../model/commoditycategories.types";
import { CommoditypropertiesDocument } from "../../model/commodityproperties.types";
import { CompositionsDocument } from "../../model/compositions.types";
import { ActivesubstancesDocument } from "../../model/activesubstances.types";
import { AllergensDocument } from "../../model/allergens.types";
import calculationUtils, { MARGIN_BUFFER } from "../../utils/calculationUtils";
import {
  calculation,
  CapsuleCalculationPrice,
  CustomCalculationInfo,
  OrdersDocument,
  pricing,
  pricingCommodities,
  StandardCalculationInfo
} from "../../model/orders.types";
import { DataContextType } from "../../context/dataContext";
import dbService, { ORDERS, REQUESTS } from "../../services/dbService";
import companyUtils from "../../utils/companyUtils";
import { RequestsDocument } from "../../model/requests.types";
import { ProductTypes } from "./configuratorConstants";
import OrderHelper from "../order/OrderHelper";
import userService from "../../services/userService";
import { MARGIN } from "../../utils/orderCalculationUtils";
import { ROLES } from "../../utils/userdataUtils";
import manufacturerUtils from "../../utils/manufacturerUtils";
import { ALLTYPES } from "./calculationDetails/calculationHelper";
import { CapsulesDocument, CapsuleSupplierType } from "../../model/capsules.types";
import { ManufacturersDocument } from "../../model/manufacturers.types";
import { CustomOrder } from "../order/CustomTypes";
import { SuppliersDocument } from "../../model/suppliers.types";

/**
 * Load data and prepare the state object
 * @param collection the collection to load
 * @param id the id of the document
 * @param productType default product type in case it is needed
 * @param calculations default calculations in case it is needed
 * @param context data context
 * @returns triple with the state to be loaded, flag if it was loaded from an existing order and the origin order
 */
async function loadConfiguratorState(
  collection: string | undefined,
  id: string | undefined,
  productType: string,
  calculations: Array<CalculationType>,
  context: DataContextType
): Promise<[any, boolean, OrdersDocument | RequestsDocument | undefined]> {
  let fromExisting;
  const {
    capsules: caps,
    tablets,
    commodities,
    packagings: packaging,
    manufacturers,
    commoditycategories,
    commodityproperties,
    compositions,
    allergens,
    activesubstances
  } = context;
  // sort capsules
  const capsules = caps.sort(sortCapsules);
  const approvedCommodities = commodities.filter(com => commodityUtils.isCommodityApproved(com));

  const extendedCommodities: Array<CustomCommoditiesDocument> = getExtendedCommodities(
    approvedCommodities,
    commoditycategories,
    commodityproperties,
    compositions,
    activesubstances,
    allergens
  );
  let existingConfig: any = {};
  let order: OrdersDocument | RequestsDocument | undefined;
  if (collection && id && BSON.ObjectId.isValid(id))
    [existingConfig, order] = await loadExistingConfiguration(collection, id, extendedCommodities, packaging, context);

  const state: any = {
    packaging,
    commodities: extendedCommodities
  };
  if (_.isEmpty(existingConfig)) {
    fromExisting = false;
    const defaultPreferences = getDefaultPreferences(productType);
    if (capsules.length > 0) {
      const defaultCapsule = capsules.find(cap => cap._id.toString() === "5e39b767491379a137dd5ffd");
      if (defaultCapsule) defaultPreferences.selectedCapsule = defaultCapsule;
      else defaultPreferences.selectedCapsule = capsules[0];
    }
    if (tablets.length > 0) defaultPreferences.selectedTablet = tablets[0];
    if (manufacturers.length > 0) {
      const filteredManufacturer = calculationUtils.getFilteredManufacturers(
        manufacturers,
        productType,
        defaultPreferences.selectedCapsule
      );
      const revi = filteredManufacturer.find(man => man._id.toString() === "5ef4fd72a4cf4fbc3202f609");
      const ownManufacturer = userService.hasRole(ROLES.PRODUCTION, true)
        ? filteredManufacturer.find(man => manufacturerUtils.isEmployeeOfManufacturer(userService.getUserId(), man))
        : null;
      defaultPreferences.selectedManufacturer = ownManufacturer
        ? ownManufacturer
        : revi
        ? revi
        : filteredManufacturer[0];
    }
    if (defaultPreferences.selectedCapsule) {
      state.selectedCapsule = getDefaultSelectedCapsule(
        defaultPreferences.selectedCapsule,
        defaultPreferences.selectedManufacturer,
        +defaultPreferences.amountPerUnit,
        calculations,
        context
      );
    }

    state.preferences = defaultPreferences;
  } else {
    Object.assign(state, existingConfig);
    fromExisting = true;
  }
  return [state, fromExisting, order];
}

/**
 * Get a default extended capsule for the given information
 * @param capsule a capsule document
 * @param manufacturer a manufacturer document
 * @param amountPerUnit the amount of capsules per unit
 * @param calculations list of calculations
 * @param context data context
 * @returns {ExtendedCapsule} capsule document with calculation
 */
function getDefaultSelectedCapsule(
  capsule: CapsulesDocument,
  manufacturer: ManufacturersDocument | undefined,
  amountPerUnit: number,
  calculations: Array<CalculationType>,
  context: DataContextType
): ExtendedCapsule {
  return {
    ...capsule,
    calculations: calculations.map(c => {
      const quantity = (+c.units * amountPerUnit) / 1000;
      return calculationUtils.getCapsulePrice(
        capsule,
        context.suppliers,
        context.manufacturers,
        quantity,
        c,
        manufacturer
      );
    })
  } as ExtendedCapsule;
}

/**
 * Load an existing configuration into the state
 * @param collection parameter that indicates whether it is a request, order or sample recipe
 * @param id the id of the collection document
 * @param commodities loaded commodities
 * @param packagings loaded packagings
 * @param context data context
 * @returns object to be loaded into configurator state
 */
async function loadExistingConfiguration(
  collection: string,
  id: string,
  commodities: Array<CustomCommoditiesDocument>,
  packagings: Array<CustomPackagingsDocument>,
  context: DataContextType
) {
  try {
    switch (collection.toLowerCase()) {
      case "request": {
        const document: RequestsDocument = await dbService.getDocumentFromCollection(REQUESTS, id);
        if (!document) return [{}, undefined];
        return [loadDataFromRequest(document, commodities, packagings, context), document];
      }
      case "order": {
        const document: OrdersDocument = await dbService.getDocumentFromCollection(ORDERS, id);
        if (!document) return [{}, undefined];
        return [loadDataFromDocument(document, commodities, packagings, context), document];
      }
    }
  } catch (e) {
    toast.error("An unexpected error occurred while trying to load data. Loaded default values.");
    console.error(e);
    return [{}, undefined];
  }
  return [{}, undefined];
}

/**
 * Load configuration from an existing request or order
 * @param document orders document
 * @param commodities loaded commodities
 * @param packagings loaded packagings
 * @param context data context
 * @returns object to be loaded into configurator state
 */
function loadDataFromDocument(
  document: OrdersDocument,
  commodities: Array<CustomCommoditiesDocument>,
  packagings: Array<CustomPackagingsDocument>,
  context: DataContextType
) {
  const stateObject: any = getInitialStateForExistingData(document, context);
  // Load calculations
  const calculations = getCalculations(document);
  stateObject.calculations = calculations;
  // Load custom calculation if given
  stateObject.customCalculations = getCustomCalculationFromExistingOrder(document);
  // Load preferences
  const selectedPrefs = getSelectedPreferenceForTab(document, context, stateObject.productType);
  Object.assign(stateObject.preferences, selectedPrefs);
  // Load capsule
  if (stateObject.productType === ProductTypes.CAPSULES && stateObject.preferences.selectedCapsule)
    stateObject.selectedCapsule = getOrderCapsule(document, stateObject.preferences, calculations, context);
  // Load recipe
  const recipe = getOrderRecipe(document, commodities, calculations, context, stateObject.productType);
  stateObject.recipe = recipe;
  stateObject.recipeVolume = calculationUtils.getRecipeVolume(recipe);
  // // Load packaging/selected packaging
  stateObject.selectedPackaging = getOrderPackaging(document, packagings, calculations, context);
  return stateObject;
}

/**
 * Load configuration from an existing request or order
 * @param document orders document
 * @param commodities loaded commodities
 * @param packagings loaded packagings
 * @param context data context
 * @returns object to be loaded into configurator state
 */
function loadDataFromRequest(
  document: RequestsDocument,
  commodities: Array<CustomCommoditiesDocument>,
  packagings: Array<CustomPackagingsDocument>,
  context: DataContextType
) {
  const stateObject: any = getInitialStateForExistingData(document, context);
  // Get calculations
  const calculations = document.calculations.map(calc => getDefaultCalculation(undefined, calc.units.toString()));
  stateObject.calculations = calculations;
  // Load preferences
  const selectedPrefs = getSelectedPreferenceForTab(document, context, stateObject.productType);
  Object.assign(stateObject.preferences, selectedPrefs);
  // Load capsule
  if (stateObject.productType === ProductTypes.CAPSULES && stateObject.preferences.selectedCapsule)
    stateObject.selectedCapsule = getRequestCapsule(stateObject.preferences, calculations, context);
  // Load recipe
  const recipe = getRequestRecipe(document, commodities, calculations, context, stateObject.productType);
  stateObject.recipe = recipe;
  stateObject.recipeVolume = calculationUtils.getRecipeVolume(recipe);
  // Load packaging/selected packaging
  stateObject.selectedPackaging = getRequestPackaging(document, packagings, calculations, context);

  return stateObject;
}

/**
 * Get the initial state for an existing request or order
 * @param object order
 * @param context data context
 * @returns state object with default values
 */
function getInitialStateForExistingData(object: OrdersDocument | RequestsDocument, context: DataContextType) {
  const { userdata, companies } = context;
  const activeTab = calculationUtils.getTabForType(object.settings.type);
  const bulk = OrderHelper.isBulk(object);
  const company = _.cloneDeep(companies.find(comp => comp._id.toString() === object.createdFor.toString()));
  let customer = null;
  if (company) {
    customer = _.omit(company, "owner") as ExtendedCustomer;
    const [contact, owner] = companyUtils.getContactAndOwner(company, userdata);
    if (owner) {
      customer.owner = owner;
      customer.contact = contact;
      customer.selected = true;
    }
  }
  const stateObject: any = {
    productType: activeTab,
    customer: customer,
    preferences: {
      amountPerUnit: getAmountPerUnitForTab(object),
      title: object.title,
      subtitle: object.subtitle,
      note: "note" in object ? object.note : "",
      priority: object.priority,
      bulk: bulk
    }
  };
  return stateObject;
}

/**
 * Get the amount per Unit from an existing request. The value differs depending on the tab
 * @param object orders
 * @returns the amount per unit as string
 */
function getAmountPerUnitForTab(object: OrdersDocument | RequestsDocument) {
  return object.settings.perUnit.toString();
}

/**
 * Gets calculations from a request and orders and transforms it to match the configurator state
 * @param object order
 * @returns calculations array of length 3 to match configurator state
 */
function getCalculations(object: OrdersDocument | CustomOrder): Array<CalculationType> {
  return object.calculations.map((calc: calculation) => {
    return {
      id: BSON.ObjectId.isValid(calc.id) ? new BSON.ObjectId(calc.id) : new BSON.ObjectId(),
      oldId: BSON.ObjectId.isValid(calc.id) ? null : calc.id,
      units: calc.units.toString(),
      unitPrice: calc.info.unitprice,
      unitPriceNaked: calc.info.unitpricenaked,
      unitMargin: calc.info.unitmargin,
      totalPrice: calc.info.totalprice,
      totalMargin: calc.info.totalmargin,
      percentMargin: calc.info.percentmargin,
      marginBuffer:
        calc.info.marginBuffer !== undefined && calc.info.marginBuffer !== null
          ? calc.info.marginBuffer
          : MARGIN_BUFFER,
      margin: calc.info.unitprice.toString(),
      marginType: "euro"
    } as CalculationType;
  });
}

/**
 * Get custom calculation information from existing order on edit
 * @param order the given order document
 * @returns {Array<CalculationCustomPriceDetails>} Array of CalculationPriceDetails for the given order
 */
function getCustomCalculationFromExistingOrder(order: OrdersDocument): Array<CalculationCustomPriceDetails> {
  const customCalcArray: Array<CalculationCustomPriceDetails> = [];
  for (let i = 0; i < order.calculations.length; i++) {
    const calculationInfo = order.calculations[i].info;
    if (calculationInfo.customCalculation) {
      const customCalc: CalculationCustomPriceDetails = { calculationId: order.calculations[i].id, note: "" };
      if (calculationInfo.customCalculation) {
        for (const t of ALLTYPES) {
          if (calculationInfo.customCalculation[t]) {
            customCalc[t] = calculationInfo.customCalculation[t];
          }
        }
        if (calculationInfo.customCalculation.optionalCosts) {
          customCalc.optionalCosts = calculationInfo.customCalculation.optionalCosts;
        }
        if (calculationInfo.marginBuffer !== undefined && calculationInfo.marginBuffer !== null) {
          customCalc.marginBuffer = calculationInfo.marginBuffer;
        } else {
          customCalc.marginBuffer = MARGIN_BUFFER;
        }
        if (calculationInfo.customCalculation.note) {
          customCalc.note = calculationInfo.customCalculation.note;
        }
      }
      customCalcArray.push(customCalc);
    }
  }
  return customCalcArray;
}

/**
 * Resolve capsule information for an order
 * @param object an order document
 * @param preferences calculation preferences
 * @param calculations list of calculations
 * @param context data context
 * @returns {ExtendedCapsule} capsule document with calculation
 */
function getOrderCapsule(
  object: OrdersDocument,
  preferences: Preferences,
  calculations: Array<CalculationType>,
  context: DataContextType
): ExtendedCapsule {
  const capsuleCalculations: Array<CapsuleCalculation> = [];
  const amountPerUnit = +preferences.amountPerUnit;
  const capsule = preferences.selectedCapsule!;

  for (let calculation of calculations) {
    try {
      const objectCalculation = object.calculations.find(
        cal => cal.id.toString() === calculation.id.toString() || cal.id.toString() === calculation.oldId?.toString()
      );
      if (!objectCalculation)
        throw new Error("No matching calculation found for " + calculation + ". Order cannot be loaded");
      const price = objectCalculation.info.standardCalculation?.capsule;
      if (!price) throw new Error("No price found for capsule in calculation. Order cannot be loaded");
      const quantity = (+calculation.units * amountPerUnit) / 1000;
      const totalPrice = price.price * quantity;
      const supplier = getCapsuleCalculationSupplier(price, capsule, context) as CapsuleCalculationSupplier;
      if (!supplier)
        throw new Error("No matching supplier could be found for current calculation. Order cannot be loaded");
      const capsuleCalculation: CapsuleCalculation = {
        id: calculation.id,
        auto: !!price.auto,
        buffer: price.buffer,
        totalAmount: quantity,
        estimatedPrice: price.estimatedPrice ? price.estimatedPrice : price.price,
        price: price.price,
        supplier,
        deliveryTime: price.deliveryTime || supplier.price.deliverytime,
        totalPrice,
        delivery: supplier.price.delivery
      };
      capsuleCalculations.push(capsuleCalculation);
    } catch (e) {
      toast.warning("Calculation could not be loaded for capsule. Creating new calculation");
      console.error(e.message);
      const quantity = (+calculation.units * amountPerUnit) / 1000;
      capsuleCalculations.push(
        calculationUtils.getCapsulePrice(
          capsule,
          context.suppliers,
          context.manufacturers,
          quantity,
          calculation,
          preferences.selectedManufacturer
        )
      );
    }
  }

  return { ...capsule, calculations: capsuleCalculations } as ExtendedCapsule;
}

/**
 * Resolve capsule information for a request
 * @param preferences calculation preferences
 * @param calculations list of calculations
 * @param context data context
 * @returns {ExtendedCapsule} capsule document with calculation
 */
function getRequestCapsule(
  preferences: Preferences,
  calculations: Array<CalculationType>,
  context: DataContextType
): ExtendedCapsule {
  const capsule = preferences.selectedCapsule!;
  return getDefaultSelectedCapsule(
    capsule,
    preferences.selectedManufacturer,
    +preferences.amountPerUnit,
    calculations,
    context
  );
}

/**
 * Find a matching supplier for the given capsule and price
 * @param price the price to search for
 * @param capsule the capsule document including suppliers
 * @param context data context
 * @returns {CapsuleCalculationSupplier | undefined} a capsule calculation supplier object with given data or undefined
 */
function getCapsuleCalculationSupplier(
  price: CapsuleCalculationPrice,
  capsule: CapsulesDocument,
  context: DataContextType
): CapsuleCalculationSupplier | undefined {
  const { suppliers, manufacturers } = context;
  let supplier: Partial<CapsuleCalculationSupplier> = {
    _id: price.supplier
  };
  if (price.supplier === "ownstock") {
    supplier.name = "Stock";
    supplier.price = {
      _id: new BSON.ObjectId(),
      moq: 0,
      price: price.price,
      deliverytime: 7,
      delivery: "",
      note: "",
      date: null
    };
  } else if (price.supplier === "customer") {
    supplier.name = "Customer";
    supplier.price = {
      _id: new BSON.ObjectId(),
      moq: 0,
      price: 0,
      deliverytime: 7,
      delivery: "",
      note: "",
      date: null
    };
  } else if (BSON.ObjectId.isValid(price.supplier)) {
    const collection: Array<ManufacturersDocument | SuppliersDocument> =
      price.supplierType === CapsuleSupplierType.MANUFACTURER ? manufacturers : suppliers;
    const supplierDocument = collection.find(
      (doc: ManufacturersDocument | SuppliersDocument) => doc._id.toString() === price.supplier.toString()
    );
    if (!supplierDocument)
      throw new Error(`Could not find ${price.supplierType || "supplier"} document for ${price.supplier.toString()}`);
    // Set name and type
    supplier.name = supplierDocument.name;
    if (price.supplierType) supplier.type = price.supplierType;
    const capsuleSupplier = capsule.suppliers.find(s => s._id.toString() === price.supplier.toString());
    if (!capsuleSupplier)
      throw new Error("Could not find supplier" + price.supplier.toString() + " in capsule " + capsule._id.toString());
    const capsulePrice = capsuleSupplier.prices.find(
      cPrice =>
        price.price === cPrice.price &&
        price.moq === cPrice.moq &&
        (!price.deliveryTime || price.deliveryTime === cPrice.deliverytime)
    );
    if (!capsulePrice) throw new Error("Could not find a matching price for " + JSON.stringify(price));
    // Set price
    supplier.price = capsulePrice;
  } else {
    toast.error("Could not find supplier" + price.supplier.toString());
    return;
  }
  return supplier as CapsuleCalculationSupplier;
}

/**
 * Resolve all commodities of an order
 * @param object an order document
 * @param commodities list of all available commodities
 * @param calculations list of calculations
 * @param context data context
 * @param productType the current product type
 * @returns recipe to be loaded into the configurator state
 */
function getOrderRecipe(
  object: OrdersDocument,
  commodities: Array<CustomCommoditiesDocument> | Array<CommoditiesDocument>,
  calculations: Array<CalculationType>,
  context: DataContextType,
  productType: string
) {
  let recipe: Array<SelectedCommoditiesDocument> = [];
  // request and sample recipes use _id, while order uses id
  for (let rec of object.recipe) {
    const commodityDoc = baseUtils.getDocFromCollection(commodities, rec.id);
    if (!commodityDoc) continue;
    const commodity: SelectedCommoditiesDocument = {
      ...commodityDoc,
      calculations: []
    };
    // Add amount
    commodity.amount = rec.amount;
    commodity.calculations = getCommodityCalculations(object, commodity, calculations, context, productType);
    recipe.push(commodity);
  }
  return recipe;
}

/**
 * Resolve all commodities of an order
 * @param request a request document
 * @param commodities list of all available commodities
 * @param calculations list of calculations
 * @param context data context
 * @param productType the current product type
 * @returns recipe to be loaded into the configurator state
 */
function getRequestRecipe(
  request: RequestsDocument,
  commodities: Array<CustomCommoditiesDocument>,
  calculations: Array<CalculationType>,
  context: DataContextType,
  productType: string
) {
  let recipe: Array<SelectedCommoditiesDocument> = [];
  // request and sample recipes use _id, while order uses id
  for (let rec of request.recipe) {
    const commodityDoc = baseUtils.getDocFromCollection(commodities, rec._id);
    if (!commodityDoc) continue;
    const commodity: SelectedCommoditiesDocument = {
      ...commodityDoc,
      calculations: []
    };
    // Add amount
    commodity.amount = rec.amount;
    commodity.calculations = calculations.map(calc =>
      calculationUtils.getCommodityPrice(
        commodity,
        context.suppliers,
        +request.settings.perUnit,
        +commodity.amount!,
        productType,
        calc
      )
    );
    recipe.push(commodity);
  }
  return recipe;
}

/**
 * Resolve existing calculations for a commodity
 * @param object an order document
 * @param commodity the commodity to resolve a calculation for
 * @param calculations list of calculations
 * @param context data context
 * @param productType the current product type
 * @returns list of resolved commodity calculations
 */
function getCommodityCalculations(
  object: OrdersDocument,
  commodity: SelectedCommoditiesDocument,
  calculations: Array<CalculationType>,
  context: DataContextType,
  productType: string
) {
  let commodityCalculations: Array<CommodityCalculation> = [];
  if (commodity.disabled)
    toast.error(`${commodity.title.en} is disabled. Please remove it from the recipe.`, { autoClose: false });
  for (let calculation of calculations) {
    try {
      const objectCalculation = object.calculations.find(
        cal => cal.id.toString() === calculation.id.toString() || cal.id.toString() === calculation.oldId?.toString()
      );
      if (!objectCalculation)
        throw new Error("No matching calculation found for " + calculation + ". Order cannot be loaded");
      const price = objectCalculation.prices.find(price => price._id.toString() === commodity._id.toString());
      if (!price)
        throw new Error(
          "Commodity could not be found" + commodity._id.toString() + " in calculation. Order cannot be loaded"
        );
      const totalAmount = calculationUtils.getTotalAmountWithBuffer(
        object.settings.perUnit,
        +calculation.units,
        price.amount,
        productType,
        price.buffer
      );
      const totalPrice = calculationUtils.getTotalPrice(totalAmount, price.price, productType);
      const supplier = getCommodityCalculationSupplier(price, commodity, context);
      if (!supplier)
        throw new Error("No matching supplier could be found for current calculation. Order cannot be loaded");
      const commodityCalculation: CommodityCalculation = {
        id: calculation.id,
        auto: price.auto === "approved" ? false : price.auto,
        buffer: price.buffer,
        delivery: price.delivery,
        incoterm: price.incoterm,
        purchaseCurrency: price.purchaseCurrency,
        purchasePrice: price.purchasePrice,
        requested: price.requested,
        updated: price.updated,
        ordered: price.ordered,
        delivered: price.delivered,
        eta: price.eta,
        deliveryTime: price.deliverytime,
        price: price.price,
        orderQuantity: price.orderquantity,
        estimatedPrice: price.estimatedprice ? price.estimatedprice : price.price,
        totalPrice: totalPrice,
        totalAmount: totalAmount,
        supplier: supplier
      };
      if (price.userOrdered !== undefined) commodityCalculation.userOrdered = price.userOrdered;
      if (price.userDelivered !== undefined) commodityCalculation.userDelivered = price.userDelivered;
      commodityCalculations.push(commodityCalculation);
    } catch (e) {
      toast.warning("Calculation could not be loaded. Creating new calculation");
      console.error(e.message);
      commodityCalculations.push(
        calculationUtils.getCommodityPrice(
          commodity,
          context.suppliers,
          +object.settings.perUnit,
          +commodity.amount!,
          productType,
          calculation
        )
      );
    }
  }
  return commodityCalculations;
}

/**
 * Find a matching supplier for the given commodity and price
 * @param price the price to search for
 * @param commodity the commodity object including suppliers
 * @param context context
 * @returns found supplier object with id, name and price or undefined
 */
function getCommodityCalculationSupplier(
  price: pricingCommodities,
  commodity: CustomCommoditiesDocument,
  context: DataContextType
) {
  const { suppliers } = context;
  let supplier: any = {
    _id: price.supplier
  };
  if (price.supplier === "ownstock") {
    supplier.name = "Stock";
    supplier.price = {
      _id: new BSON.ObjectId(),
      moq: 0,
      price: price.price,
      deliverytime: 7,
      delivery: "",
      note: "",
      date: null,
      purchasePrice: null,
      purchaseCurrency: "",
      incoterm: ""
    };
  } else if (price.supplier === "customer") {
    supplier.name = "Customer";
    supplier.price = {
      _id: new BSON.ObjectId(),
      moq: 0,
      price: 0,
      deliverytime: 7,
      delivery: "",
      note: "",
      date: null,
      purchasePrice: null,
      purchaseCurrency: "",
      incoterm: ""
    };
  } else if (BSON.ObjectId.isValid(price.supplier)) {
    const supplierDocument = suppliers.find(supp => supp._id.toString() === price.supplier.toString());
    if (!supplierDocument) throw new Error("Could not find supplier document for" + price.supplier.toString());
    supplier.name = supplierDocument.name;
    const commoditySupplier = commodity.suppliers.find(supp => supp._id.toString() === price.supplier.toString());
    if (!commoditySupplier)
      throw new Error(
        "Could not find supplier" + price.supplier.toString() + " in commodity " + commodity._id.toString()
      );
    const commodityPrice = commoditySupplier.prices.find(
      cPrice =>
        price.price === cPrice.price && price.incoterm === cPrice.incoterm && price.deliverytime === cPrice.deliverytime
    );
    if (!commodityPrice) throw new Error("Could not find a matching price for " + JSON.stringify(price));
    supplier.price = {
      _id: commodityPrice._id,
      moq: commodityPrice.moq,
      price: price.price,
      note: commodityPrice.note,
      date: commodityPrice.date,
      deliverytime: price.deliverytime,
      delivery: price.delivery,
      purchasePrice: price.purchasePrice,
      purchaseCurrency: price.purchaseCurrency,
      incoterm: price.incoterm
    };
  } else {
    throw new Error("Could not identify supplier" + price.supplier.toString());
  }
  return supplier;
}

/**
 * Resolve all packaging of an order
 * @param object an order document
 * @param packaging list of all available packaging
 * @param calculations list of calculations
 * @param context data context
 * @returns selected packaging to be loaded into the configurator state
 */
function getOrderPackaging(
  object: OrdersDocument,
  packaging: Array<CustomPackagingsDocument>,
  calculations: Array<CalculationType>,
  context: DataContextType
) {
  let selectedPackaging: Array<SelectedPackagingsDocument> = [];
  for (let pack of object.calculations[0].packagings) {
    const packagingDoc = baseUtils.getDocFromCollection(packaging, pack._id);
    if (!packaging) continue;
    const selectedPack: SelectedPackagingsDocument = {
      ...packagingDoc
    };
    selectedPack.amount = pack.amount;
    selectedPack.calculations = getPackagingCalculations(object, selectedPack, calculations, context);
    selectedPackaging.push(selectedPack);
  }
  return selectedPackaging;
}

/**
 * Resolve all packaging of an order
 * @param request an order document
 * @param packaging list of all available packaging
 * @param calculations list of calculations
 * @param context data context
 * @returns selected packaging to be loaded into the configurator state
 */
function getRequestPackaging(
  request: RequestsDocument,
  packaging: Array<CustomPackagingsDocument>,
  calculations: Array<CalculationType>,
  context: DataContextType
) {
  let selectedPackaging: Array<SelectedPackagingsDocument> = [];
  for (let pack of request.calculations[0].packagings) {
    const packagingDoc = baseUtils.getDocFromCollection(packaging, pack._id);
    if (!packaging) continue;
    const selectedPack: SelectedPackagingsDocument = {
      ...packagingDoc
    };
    selectedPack.amount = pack.amount;
    selectedPack.calculations = calculations.map(calc =>
      calculationUtils.getPackagingPrice(selectedPack, context.suppliers, +selectedPack.amount!, calc)
    );
    selectedPackaging.push(selectedPack);
  }
  return selectedPackaging;
}

/**
 * Resolve existing calculations for a packaging
 * @param object an order document
 * @param packaging the packaging to resolve a calculation for
 * @param calculations list of calculations
 * @param context data context
 * @returns list of resolved packaging calculations
 */
function getPackagingCalculations(
  object: OrdersDocument,
  packaging: SelectedPackagingsDocument,
  calculations: Array<CalculationType>,
  context: DataContextType
) {
  let packagingCalculations: Array<PackagingCalculation> = [];
  for (let calculation of calculations) {
    try {
      const objectCalculation = object.calculations.find(
        cal => cal.id.toString() === calculation.id.toString() || cal.id.toString() === calculation.oldId?.toString()
      );
      if (!objectCalculation)
        throw new Error("No matching calculation found for " + calculation + ". Order cannot be loaded");
      const price = objectCalculation.packagings.find(price => price._id.toString() === packaging._id.toString());
      if (!price)
        throw new Error("Packaging could not be found" + packaging._id.toString() + ". Order cannot be loaded");
      const totalAmount = packaging.amount! * +calculation.units;
      const totalPrice = totalAmount * price.price;
      const supplier = getPackagingCalculationSupplier(price, packaging, context);
      if (!supplier)
        throw new Error("No matching supplier could be found for current calculation. Order cannot be loaded");
      const packagingCalculation: PackagingCalculation = {
        id: calculation.id,
        auto: price.auto === "approved" ? false : price.auto,
        buffer: price.buffer,
        delivery: price.delivery,
        requested: price.requested,
        updated: price.updated,
        ordered: price.ordered,
        delivered: price.delivered,
        eta: price.eta,
        deliveryTime: price.deliverytime,
        price: price.price,
        orderQuantity: price.orderquantity,
        estimatedPrice: price.estimatedprice ? price.estimatedprice : price.price,
        totalPrice: totalPrice,
        totalAmount: totalAmount,
        supplier: supplier
      };
      if (price.userOrdered !== undefined) packagingCalculation.userOrdered = price.userOrdered;
      if (price.userDelivered !== undefined) packagingCalculation.userDelivered = price.userDelivered;
      packagingCalculations.push(packagingCalculation);
    } catch (e) {
      toast.warning("Calculation could not be loaded for packaging. Creating new calculation");
      console.error(e.message);
      packagingCalculations.push(
        calculationUtils.getPackagingPrice(packaging, context.suppliers, +packaging.amount!, calculation)
      );
    }
  }
  return packagingCalculations;
}

/**
 * Find a matching supplier for the given packaging and price
 * @param price the price to search for
 * @param packaging the packaging object including suppliers
 * @param context context
 * @returns found supplier object with id, name and price or undefined
 */
function getPackagingCalculationSupplier(
  price: pricing,
  packaging: CustomPackagingsDocument,
  context: DataContextType
) {
  const { suppliers } = context;
  let supplier: any = {
    _id: price.supplier
  };
  if (price.supplier === "ownstock") {
    supplier.name = "Stock";
    supplier.price = {
      _id: new BSON.ObjectId(),
      moq: 0,
      price: price.price,
      deliverytime: 7,
      delivery: "",
      note: "",
      date: null
    };
  } else if (price.supplier === "customer") {
    supplier.name = "Customer";
    supplier.price = {
      _id: new BSON.ObjectId(),
      moq: 0,
      price: 0,
      deliverytime: 7,
      delivery: "",
      note: "",
      date: null
    };
  } else if (BSON.ObjectId.isValid(price.supplier)) {
    const supplierDocument = suppliers.find(supp => supp._id.toString() === price.supplier.toString());
    if (!supplierDocument) throw new Error("Could not find supplier document for" + price.supplier.toString());
    supplier.name = supplierDocument.name;
    const packagingSupplier = packaging.suppliers.find(supp => supp._id.toString() === price.supplier.toString());
    if (!packagingSupplier)
      throw new Error(
        "Could not find for supplier" + price.supplier.toString() + " in packaging " + packaging._id.toString()
      );
    const packagingPrice = packagingSupplier.prices.find(
      cPrice =>
        price.price === cPrice.price && price.deliverytime === cPrice.deliverytime && price.delivery === cPrice.delivery
    );
    if (!packagingPrice) throw new Error("Could not find a matching price for " + JSON.stringify(price));
    supplier.price = {
      _id: packagingPrice._id,
      moq: packagingPrice.moq,
      price: price.price,
      note: packagingPrice.note,
      date: packagingPrice.date,
      deliverytime: price.deliverytime,
      delivery: price.delivery
    };
  } else {
    toast.error("Could not find supplier" + price.supplier.toString());
    return;
  }
  return supplier;
}

/**
 * Gets selected tablet and capsule for preferences object
 * @param object  order
 * @param context context object
 * @param productType the product type
 * @returns {PartialPreferences} with selectedCapsule, selectedTablet and pre-existing standard calculation
 */
function getSelectedPreferenceForTab(
  object: OrdersDocument | ExtendedOrder | RequestsDocument,
  context: DataContextType,
  productType: string
): PartialPreferences {
  const { capsules, tablets, manufacturers } = context;
  const partialPreferences: PartialPreferences = {
    selectedCapsule: capsules[0],
    selectedTablet: tablets[0],
    selectedManufacturer: object.settings.manufacturer
      ? baseUtils.getDocFromCollection(manufacturers, object.settings.manufacturer)
      : undefined,
    selectedFiller:
      "filler" in object.settings && object.settings.filler
        ? baseUtils.getDocFromCollection(manufacturers, object.settings.filler)
        : undefined
  };
  let selectedCapsule;
  if (object.settings.id) {
    // Set selected capsule/tablet according to active tab
    if (productType === ProductTypes.CAPSULES) {
      selectedCapsule = baseUtils.getDocFromCollection(capsules, object.settings.id);
      if (selectedCapsule) partialPreferences.selectedCapsule = selectedCapsule;
    } else if (productType === ProductTypes.TABLETS) {
      const selectedTablet = baseUtils.getDocFromCollection(tablets, object.settings.id);
      if (selectedTablet) partialPreferences.selectedTablet = selectedTablet;
    }
  }

  const filteredManufacturer = calculationUtils.getFilteredManufacturers(manufacturers, productType, selectedCapsule);
  // If no supplier was already selected (requests), get a fitting one
  if (!partialPreferences.selectedManufacturer) {
    const revi = filteredManufacturer.find(man => man._id.toString() === "5ef4fd72a4cf4fbc3202f609");
    partialPreferences.selectedManufacturer = revi ? revi : filteredManufacturer[0];
  } else {
    // if already selected manufacturer cannot actually produce it select a new one
    if (
      !filteredManufacturer.some(man => man._id.toString() === partialPreferences.selectedManufacturer!._id.toString())
    ) {
      toast.error(`${partialPreferences.selectedManufacturer.name} cannot produce this product. Selecting a new one`);
      const revi = filteredManufacturer.find(man => man._id.toString() === "5ef4fd72a4cf4fbc3202f609");
      partialPreferences.selectedManufacturer = revi ? revi : filteredManufacturer[0];
    }
  }

  if (partialPreferences.selectedFiller) {
    const filteredFillers = manufacturers.filter(
      man =>
        man._id.toString() !== partialPreferences.selectedManufacturer?._id.toString() &&
        Object.keys(man).some(key => key.startsWith(productType))
    );
    if (!filteredFillers.some(man => man._id.toString() === partialPreferences.selectedFiller!._id.toString())) {
      toast.error("Selected filler is invalid.");
      partialPreferences.selectedFiller = undefined;
    }
  }

  // Checking for existing calculations
  if (
    "info" in object.calculations[0] &&
    object.calculations[0].info.standardCalculation &&
    partialPreferences.selectedManufacturer
  ) {
    partialPreferences.existingStandardCalculation = {
      relatedCalculation: object.calculations[0].info.standardCalculation,
      relatedManufacturer: partialPreferences.selectedManufacturer,
      relatedFiller: partialPreferences.selectedFiller
    };
  }
  return partialPreferences;
}

/**
 * Returns default preferences object for configurator
 * @param type product type as string
 * @returns Object with default preferences for configurator state
 */
function getDefaultPreferences(type: string): Preferences {
  const amountPerUnit = type && getAmountPerUnit(type);
  return {
    bulk: false,
    amountPerUnit: amountPerUnit,
    title: "",
    subtitle: "",
    note: "",
    priority: "medium"
  };
}

/**
 * Get default calculation
 * @param id optional object id
 * @param units optional units amount
 * @return CalculationType
 */
function getDefaultCalculation(id?: BSON.ObjectId, units?: string): CalculationType {
  return {
    id: id ? id : new BSON.ObjectId(),
    units: units ? units : "1000",
    unitPrice: 0,
    unitPriceNaked: 0,
    unitMargin: 0,
    totalPrice: 0,
    percentMargin: 0,
    totalMargin: 0,
    margin: "0",
    marginType: "percent"
  };
}

/**
 * Get default amount per unit for product type
 * @param type the current product type
 * @returns string with default amount per unit for the given type
 */
function getAmountPerUnit(type: string) {
  switch (type) {
    case ProductTypes.POWDER:
    case ProductTypes.LIQUID:
      return "0";
    case ProductTypes.SERVICE:
    case ProductTypes.CUSTOM:
      return "1";
    default:
      return "120";
  }
}

/**
 * Sort capsules
 * @param a one capsule object
 * @param b another capsule object
 * @returns result of locale compare
 */
function sortCapsules(a: any, b: any) {
  return `${a.capsule_size}${a.capsule_material.en}`.localeCompare(`${b.capsule_size}${b.capsule_material.en}`);
}

/**
 * Extend commodities with data
 * @param commodities list of commodities
 * @param categories list of all commodity categories
 * @param properties list of all commodity properties
 * @param compositions list of all compositions/commodity forms
 * @param substances list of all active substances
 * @param allergens list of all allergens
 * @returns List of extended commodities
 */
function getExtendedCommodities(
  commodities: Array<CommoditiesDocument>,
  categories: Array<CommoditycategoriesDocument>,
  properties: Array<CommoditypropertiesDocument>,
  compositions: Array<CompositionsDocument>,
  substances: Array<ActivesubstancesDocument>,
  allergens: Array<AllergensDocument>
): Array<CustomCommoditiesDocument> {
  let extendedCommodities: Array<CustomCommoditiesDocument> = [];
  const formMap: { [id: string]: CompositionsDocument } = {};
  const catMap: { [id: string]: CommoditycategoriesDocument } = {};
  const propMap: { [id: string]: CommoditypropertiesDocument } = {};
  const asMap: { [id: string]: ActivesubstancesDocument } = {};
  const allergenMap: { [id: string]: AllergensDocument } = {};

  const getDocument = <T,>(documents: Array<T>, id: BSON.ObjectId | string, map: { [id: string]: T }) => {
    let tempDoc = map[id.toString()];
    if (!tempDoc) {
      tempDoc = baseUtils.getDocFromCollection(documents, id) as T;
      map[id.toString()] = tempDoc;
    }
    return tempDoc;
  };

  for (let i = 0; i < commodities.length; i++) {
    const commodity = commodities[i];
    let tempCommodity = { ..._.omit(commodity, ["form", "category", "properties", "activesubstance", "allergens"]) };
    const tempForm = getDocument(compositions, commodity.form, formMap);
    if (tempForm) _.set(tempCommodity, "form", tempForm.name);
    const tempCat = getDocument(categories, commodity.category, catMap);
    if (tempCat) _.set(tempCommodity, "category", tempCat.name);

    const tempProps = [];
    for (let j = 0; j < commodity.properties.length; j++) {
      const comProp = commodity.properties[j];
      const tempProp = getDocument(properties, comProp, propMap);
      if (tempProp) tempProps.push(tempProp.name);
    }
    _.set(tempCommodity, "properties", tempProps);

    const tempSubstances = [];
    for (let j = 0; j < commodity.activesubstance.length; j++) {
      const activeSub = commodity.activesubstance[j];
      const activeSubDoc = getDocument(substances, activeSub._id, asMap);
      const tmpActiveSub = {
        ...activeSubDoc,
        value: activeSub.value
      };
      if (tmpActiveSub) tempSubstances.push(tmpActiveSub);
    }
    _.set(tempCommodity, "activesubstance", tempSubstances);

    const tempAllergens = [];
    for (let j = 0; j < commodity.allergens.length; j++) {
      const allergen = commodity.allergens[j];
      const tmpAllergen = getDocument(allergens, allergen, allergenMap);
      if (tmpAllergen) tempAllergens.push(tmpAllergen.name);
    }
    _.set(tempCommodity, "allergens", tempAllergens);
    extendedCommodities.push(tempCommodity as CustomCommoditiesDocument);
  }
  return extendedCommodities;
}

/**
 * Render all properties, categories, allergens, ... of a commodity
 * @param commodity a commodities document
 * @returns JSX Element displaying all properties
 */
function renderCommodityProperties(commodity: CustomCommoditiesDocument | SelectedCommoditiesDocument) {
  return (
    <div>
      {commodity.form && (
        <span className="kt-badge kt-badge--primary kt-badge--inline kt-badge--pill kt-badge--rounded mr-2">
          {commodity.form.en}
        </span>
      )}
      {commodity.category && (
        <span
          className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded mr-2"
          style={{ background: commodityUtils.getCategoryColor(commodity.category.en) }}
        >
          {commodity.category.en}
        </span>
      )}
      {commodity.properties &&
        commodity.properties.map(property => (
          <span
            key={property.en}
            className="kt-badge kt-badge--secondary kt-badge--inline kt-badge--pill kt-badge--rounded mr-2"
            style={{ backgroundColor: "rgb(247, 248, 250)", color: "#50566a" }}
          >
            {property.en}
          </span>
        ))}
      {commodity.allergens &&
        commodity.allergens.map(allergen => (
          <span
            key={allergen.en}
            className="kt-badge kt-badge--secondary kt-badge--inline kt-badge--pill kt-badge--rounded mr-2"
            style={{ backgroundColor: "rgb(247, 248, 250)", color: "#ac3333" }}
          >
            {allergen.en}
          </span>
        ))}
      {commodity.toxic_amount ? (
        <span className="text-danger mr-2" style={{ fontSize: ".8rem" }}>
          <i className="fas fa-ambulance mr-1" />
          {commodity.toxic_amount + " mg / day"}
        </span>
      ) : null}
      {commodity.country && (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="button-tooltip product-popover">{countryList.getName(commodity.country, "en")}</Tooltip>
          }
        >
          <img
            style={{ width: 20, height: 19, borderRadius: 4, objectFit: "cover" }}
            alt={commodity.country}
            src={toAbsoluteUrl("/media/icons/countries/" + commodity.country.toLowerCase() + ".png")}
            className="country-icon"
          />
        </OverlayTrigger>
      )}
      {commodity.article_number ? <span className="text-warning">&nbsp;{commodity.article_number}</span> : ""}
    </div>
  );
}

/**
 * Get the id of selected capsule or tablet
 * @param productType the product type
 * @param preferences preferences object
 * @returns ObjectId of selected capsule or tablet or string
 */
function getSelectedPreferenceId(productType: string, preferences: Preferences) {
  switch (productType) {
    case ProductTypes.CAPSULES:
      return preferences.selectedCapsule!._id;
    case ProductTypes.TABLETS:
      return preferences.selectedTablet!._id;
    default:
      return "";
  }
}

/**
 * Create an order object
 * @param productType the product type
 * @param preferences preferences object
 * @param recipe list of selected commodities
 * @param selectedPackaging list of selected packaging
 * @param selectedCapsule the selected capsule
 * @param calculations list of calculations
 * @param customer the selected customer
 * @param identifier the identifier
 * @param withDefaults flag if order will be moved back to an offer state
 * @param contractInformation existing contractInformation of an orign order
 * @returns OrdersDocument order document
 */
function createOrderObject(
  productType: string,
  preferences: Preferences,
  recipe: Array<SelectedCommoditiesDocument>,
  selectedPackaging: Array<SelectedPackagingsDocument>,
  selectedCapsule: ExtendedCapsule | null,
  calculations: Array<CalculationType>,
  customer: ExtendedCustomer,
  identifier?: number | string,
  withDefaults?: boolean,
  contractInformation?: { contractId: BSON.ObjectId | string; callId: BSON.ObjectId | string } | null
) {
  const type = calculationUtils.getTypeForTab(productType);
  const perUnit = calculationUtils.getAmountPerUnit(productType, preferences, recipe);
  if (!type || perUnit === undefined || !preferences.selectedManufacturer) return;
  const order: Partial<OrdersDocument> = {
    title: preferences.title,
    subtitle: preferences.subtitle,
    note: preferences.note,
    priority: preferences.priority,
    createdFor: customer!._id,
    identifier: identifier,
    settings: {
      type,
      perUnit,
      id: getSelectedPreferenceId(productType, preferences),
      manufacturer: preferences.selectedManufacturer!._id,
      bulk: preferences.bulk
    },
    fulfillment: null,
    usedBatches: null,
    usedPackagingBatches: null,
    delivery: null,
    targetDate: null,
    reorder: false,
    contract: null,
    contractInformation: contractInformation ? contractInformation : null,
    product: null
  };
  if (
    preferences.selectedFiller &&
    preferences.selectedFiller._id.toString() !== preferences.selectedManufacturer._id.toString()
  )
    order.settings!.filler = preferences.selectedFiller._id.toString();
  // Build recipe
  const recipeList = [];
  for (let com of recipe) {
    recipeList.push({ id: com._id, amount: Number(com.amount), buffer: +com.calculations[0].buffer });
  }
  order.recipe = recipeList;
  // Build calculation
  const orderCalculations: Array<calculation> = [];
  for (let calculation of calculations) {
    const unitPrice = Math.ceil(calculation.unitPrice * 100) / 100;
    const customCalculation = getCustomCalculationForOrder(calculation);
    const calculationObject: calculation = {
      id: calculation.id.toString(),
      units: +calculation.units,
      margin: +calculation.percentMargin,
      info: {
        unitprice: unitPrice,
        unitpricenaked: calculation.unitPriceNaked,
        unitmargin: calculation.unitMargin,
        totalprice: unitPrice * +calculation.units,
        totalmargin: calculation.totalMargin,
        percentmargin: calculation.percentMargin,
        marginBuffer: calculation.marginBuffer
      },
      prices: [],
      packagings: []
    };
    if (customCalculation) calculationObject.info.customCalculation = customCalculation;
    else
      calculationObject.info.standardCalculation = getStandardCalculationForOrder(
        productType,
        calculation,
        preferences,
        selectedCapsule
      );
    // Add recipe
    const prices = [];
    for (let com of recipe) {
      prices.push(calculationUtils.getRecipeCalculationEntry(com, calculation.id, withDefaults));
    }
    calculationObject.prices = prices;
    // Add packaging
    const packaging = [];
    for (let pack of selectedPackaging) {
      packaging.push(calculationUtils.getPackagingCalculationEntry(pack, calculation.id, withDefaults));
    }
    calculationObject.packagings = packaging;
    // Push calculation entry
    orderCalculations.push(calculationObject);
  }
  if (orderCalculations.length === 0) return;
  // @ts-ignore
  order.calculations = orderCalculations;
  return order as OrdersDocument;
}

/**
 * Create a custom calculation object
 * @param calculation calculation to get customCalculation object for
 * @returns {CustomCalculationInfo | undefined} the custom calculation object for the order, or undefined if no custom prices were found in the calculation
 */
function getCustomCalculationForOrder(calculation: CalculationType): CustomCalculationInfo | undefined {
  const customCalc = calculation.priceDetails?.customPrices;
  if (customCalc) {
    const customCalcObject: CustomCalculationInfo = { note: "" };
    if (customCalc.note) {
      customCalcObject.note = customCalc.note;
    }
    if (customCalc.optionalCosts) {
      customCalcObject.optionalCosts = customCalc.optionalCosts;
    }
    for (const t of ALLTYPES) {
      if (customCalc[t]) {
        customCalcObject[t] = {
          price: customCalc[t]?.price!,
          unitPrice: customCalc[t]?.unitPrice!,
          manufacturerId: customCalc[t]?.manufacturerId!
        };
      }
    }
    // can happen if only margin buffer changed
    if (_.isEmpty(customCalcObject)) return undefined;
    return customCalcObject;
  }
}

/**
 * Create a standard calculation object
 * @param productType the product type
 * @param calculation calculation to get standardCalculation object for
 * @param preferences the preferences of the calculation
 * @param selectedCapsule the selected capsule
 * @returns {StandardCalculationInfo | undefined} the standard calculation object for the order
 */
function getStandardCalculationForOrder(
  productType: string,
  calculation: CalculationType,
  preferences: Preferences,
  selectedCapsule: ExtendedCapsule | null
): StandardCalculationInfo | undefined {
  const generalPrices = calculation.priceDetails?.generalPrices;
  const capsulePrice = calculation.priceDetails?.capsulePrice;
  const manufacturer = preferences.selectedManufacturer?._id;
  if (!generalPrices || !manufacturer) return;
  let standardCalcObject: StandardCalculationInfo = {};
  for (const t of ALLTYPES) {
    if (generalPrices[t]) {
      standardCalcObject[t] = {
        price: generalPrices[t]!.price,
        unitPrice: generalPrices[t]!.unitPrice
      };
    }
  }
  // capsule handling
  if (productType === ProductTypes.CAPSULES && capsulePrice && selectedCapsule) {
    const capsuleCalculation = selectedCapsule.calculations.find(
      calc => calc.id.toString() === calculation.id.toString()
    );
    if (capsuleCalculation) {
      standardCalcObject.capsule = {
        auto: capsuleCalculation.auto,
        buffer: capsuleCalculation.buffer,
        totalAmount: capsuleCalculation.totalAmount,
        estimatedPrice: capsuleCalculation.estimatedPrice,
        price: capsuleCalculation.price,
        supplier: capsuleCalculation.supplier._id.toString(),
        ...(capsuleCalculation.supplier.type ? { supplierType: capsuleCalculation.supplier.type } : {}),
        moq: capsuleCalculation.supplier.price.moq,
        totalPrice: capsuleCalculation.totalPrice ?? capsuleCalculation.estimatedPrice,
        unitPrice: capsulePrice.unitPrice,
        deliveryTime: capsuleCalculation.deliveryTime
      };
    }
  }
  return standardCalcObject;
}

/**
 * Get a message formatted for slack to notify about a newly created offer
 * @param offer the created offer
 * @param id the id of the offer
 * @returns {string} message for slack
 */
function getOfferSlackMessage(offer: OrdersDocument, id: BSON.ObjectId): string {
  const currUser = userService.getUserData();
  let message = `*${currUser.prename} ${
    currUser.surname
  }* created the offer <https://www.admincentral.private-label-factory.com/order/${id.toString()}|AN-${
    offer.identifier
  }>: \r\n`;
  for (let calculation of offer.calculations) {
    let icon = ":red_circle:";
    if (calculation.info.percentmargin >= MARGIN.GOOD) icon = ":large_green_circle:";
    if (calculation.info.percentmargin < MARGIN.GOOD && calculation.info.percentmargin >= MARGIN.BAD)
      icon = ":large_yellow_circle:";
    if (calculation.info.percentmargin < MARGIN.BAD && calculation.info.percentmargin >= MARGIN.CRITICAL)
      icon = ":large_orange_circle:";
    message += `• ${calculation.units} units á ${baseUtils.formatEuro(
      calculation.info.unitprice
    )}. Margin: ${icon} ${calculation.info.percentmargin.toFixed(2)}%, ${baseUtils.formatEuro(
      calculation.info.totalmargin
    )} \r\n`;
  }
  return message;
}

// eslint-disable-next-line
export default {
  createOrderObject,
  getDefaultPreferences,
  getDefaultCalculation,
  getExtendedCommodities,
  getCalculations,
  renderCommodityProperties,
  getAmountPerUnit,
  loadConfiguratorState,
  getSelectedPreferenceForTab,
  getOfferSlackMessage,
  getDefaultSelectedCapsule
};
