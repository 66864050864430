import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import { CommodityBatch } from "../../../model/commodities.types";
import accessUtils, { DELETELOCATIONS } from "../../../utils/accessUtils";

interface DeleteBatchModalProps {
  batch: CommodityBatch;
  onBatchDisable: (_id: BSON.ObjectId | string) => void;
}

interface DeleteBatchModalState {
  show: boolean;
}

class DisableBatchModal extends PureComponent<DeleteBatchModalProps, DeleteBatchModalState> {
  constructor(props: DeleteBatchModalProps) {
    super(props);
    this.state = { show: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  /**
   * Handles the deletion of a batch.
   */
  handleDisableBatch = () => {
    const { batch, onBatchDisable } = this.props;
    onBatchDisable(batch._id);
    this.handleHide();
  };

  render() {
    const { batch } = this.props;
    const { show } = this.state;
    const canDelete = accessUtils.canDeleteData(DELETELOCATIONS.COMMODITYSTOCK);

    return (
      <>
        <button
          className={"btn btn-secondary btn-sm" + (canDelete ? "" : " disabled")}
          onClick={canDelete ? this.handleShow : undefined}
          disabled={!canDelete}
        >
          <i className={"fas pr-0 " + (batch.disabled ? "fa-undo" : "fa-times")} />
        </button>
        <Modal show={show} onHide={this.handleHide} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {batch.disabled ? "Enable Batch" : "Disable batch"} {batch.lot}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-warning" role="alert">
              <div className="alert-icon">
                <i className="flaticon-warning" />
              </div>
              <div className="alert-text">
                Do you really want to {batch.disabled ? "enable" : "disable"} batch {batch.lot}?
                <br />
                <br />
                {!batch.disabled && (
                  <>
                    This action will clear the stock and mark it as disabled.
                    <br />
                    <br />
                    This can be reverted as long as you have not clicked "Save changes" yet.
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            <button className="btn btn-secondary" onClick={this.handleDisableBatch}>
              {batch.disabled ? "Enable" : "Disable"} batch
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default DisableBatchModal;
