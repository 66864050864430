import React from "react";
import sanitizeHtml from "sanitize-html";

const defaultOptions = {
  allowedTags: ["b", "i", "em", "strong", "u", "span"],
  allowedAttributes: { span: ["class"] },
  allowedIframeHostnames: []
};

const sanitize = (dirty: string, options: any) => ({
  __html: sanitizeHtml(dirty, { ...defaultOptions, ...options })
});

interface SanitizedHTMLProps {
  html: string;
  options?: any;
}

export const SanitizedHTML: React.FunctionComponent<SanitizedHTMLProps> = ({ html, options }) => (
  <div dangerouslySetInnerHTML={sanitize(html, options)} />
);
