import { BSON } from "realm-web";
import { ManufacturersDocument } from "../model/manufacturers.types";
import { SuppliersDocument } from "../model/suppliers.types";
import { CompaniesDocument } from "../model/companies.types";
import { OrdersDocument } from "../model/orders.types";
import { AvisUnit } from "../model/warehouse/deliveryAnnouncement.types";
import { CommoditiesDocument } from "../model/commodities.types";
import { PackagingsDocument } from "../model/packagings.types";

export interface OrderWithMaterial {
  order: OrdersDocument;
  commodities: Array<CommoditiesDocument>;
  packagings: Array<PackagingsDocument>;
}

export interface AvisRow {
  _id: BSON.ObjectId;
  descriptionOrder: string;
  descriptionArticle: string;
  order?: OrderWithMaterial;
  articleSelected?: CommoditiesDocument | PackagingsDocument;
  totalAmount: number;
  units: AvisUnit;
}

export interface AvisDescriptionOption {
  name: string;
  id: string;
  order: OrderWithMaterial;
}

export const AvisUnitOptions = [
  { value: AvisUnit.KG, label: "kg" },
  { value: AvisUnit.UNITS, label: "units" },
  { value: AvisUnit.PCS, label: "pcs." }
];

export interface AvisInformation {
  company: CompaniesDocument | SuppliersDocument | ManufacturersDocument | null;
  orders: Array<{
    order: OrdersDocument;
    commodities: Array<CommoditiesDocument>;
    packagings: Array<PackagingsDocument>;
  }> | null;
  shipmentCode: string;
}

/**
 * Generate the default avis row
 * @returns {AvisRow} Default avis row
 */
export function getDefaultAvisRow(): AvisRow {
  return { _id: new BSON.ObjectId(), descriptionOrder: "", descriptionArticle: "", totalAmount: 0, units: AvisUnit.KG };
}
