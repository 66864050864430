import * as Realm from "realm-web";
import { BSON } from "realm-web";

const app = new Realm.App({
  id: process.env.REACT_APP_SCNAME!
});

/**
 * Registers a new user at the system.
 * @param email: Email of the user
 * @returns { { id: BSON.ObjectId, token: Realm.Auth.ApiKey, user: any } | null } User information if creation was successful, else null
 */
async function registerUser(email: string) {
  const pw = new BSON.ObjectId().toString();
  const credentials = Realm.Credentials.emailPassword(email, pw);
  let user;
  try {
    // Register user
    await app.emailPasswordAuth.registerUser(email, pw);
    // Try to log in user
    user = await app.logIn(credentials);
    const apiKey = await user.apiKeys.create("firstLogin");
    return { id: user.id, token: apiKey, user };
  } catch (err) {
    console.error("Failed to authenticate", err);
    return null;
  }
}

/**
 * Delete a user from the realm application
 * @param user user object
 * @returns {Promise<any>} result of delete function
 */
async function deleteUser(user: Realm.User) {
  return app.deleteUser(user);
}

export default { registerUser, deleteUser };
