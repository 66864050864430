import _ from "lodash";
import { BSON } from "realm-web";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import ErrorOverlayButton from "../../ErrorOverlayButton";
import { CommoditiesDocument } from "../../../../model/commodities.types";
import { RawbidsSupplierSelected } from "../CustomTypes";
import {
  DEFAULT_PACKAGING_SIZE,
  fetchRawbidsPrices,
  getPackagingSizes,
  getUniqueAmounts,
  RawbidsPrice,
  RawbidsPricesResult,
  validateAmount
} from "../../../../utils/rawbidsUtils";
import SplashScreen from "../../SplashScreen";
import baseUtils from "../../../../utils/baseUtils";

interface RawbidsRequestModalProps {
  commodity: CommoditiesDocument;
  supplier: RawbidsSupplierSelected;
  onUpdateRawbidsSupplier: (prices: RawbidsPricesResult) => void;
}

const RawbidsRequestModal: React.FunctionComponent<RawbidsRequestModalProps> = ({
  commodity,
  supplier,
  onUpdateRawbidsSupplier
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amounts, setAmounts] = useState(
    getUniqueAmounts(supplier.prices, commodity.rawbidsData?.commoditySnapshot.packagingSizes)
  );
  const [step, setStep] = useState(0);
  const [prices, setPrices] = useState<RawbidsPricesResult | null>(null);

  useEffect(() => {
    if (show) setAmounts(getUniqueAmounts(supplier.prices, commodity.rawbidsData?.commoditySnapshot.packagingSizes));
  }, [show]);

  const hasRawbidsData = useMemo(() => !!commodity.rawbidsData, [commodity.rawbidsData]);
  const packagingSizes = useMemo(() => getPackagingSizes(commodity.rawbidsData), [commodity.rawbidsData]);
  const errorsFirstStep = useMemo(() => {
    const errors: Array<string> = [];
    amounts.forEach(amount => {
      const error = validateAmount(amount.amount, packagingSizes);
      if (error) errors.push(error);
    });
    return errors;
  }, [amounts, packagingSizes]);
  const errorsSecondStep = useMemo(() => {
    const errors: Array<string> = [];
    if (!prices) errors.push("No prices currently available");
    return errors;
  }, [prices]);

  const handleShow = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShow(true);
    setStep(0);
    setPrices(null);
  }, []);

  const handleHide = useCallback(() => setShow(false), []);

  const handleBack = useCallback(() => {
    if (step === 1) setPrices(null);
    setStep(prevState => {
      if (prevState > 0) return prevState - 1;
      return prevState;
    });
  }, [step]);

  const handleAddAmount = useCallback(() => {
    setAmounts(prevState => {
      const newState = [...prevState];
      newState.push({
        _id: new BSON.ObjectId(),
        amount: prevState.length > 0 ? prevState[prevState.length - 1].amount * 2 : packagingSizes[0] * 2
      });
      return newState;
    });
  }, []);

  const handleRemoveAmount = useCallback((id: BSON.ObjectId | string) => {
    setAmounts(prevState => {
      return prevState.filter(a => a._id.toString() !== id.toString());
    });
  }, []);

  const handleChangeAmount = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    const id = e.target.name;
    val = val.replaceAll(/^0+/g, "0");
    if (!val.includes(".")) val = Number(val).toString();
    if (+val < 0) return;
    setAmounts(prevState => {
      return prevState.map(entry => {
        if (entry._id.toString() === id) return { ...entry, amount: +val };
        return entry;
      });
    });
  }, []);

  const handleRequestPrices = useCallback(async () => {
    if (!commodity.rawbidsData?.rawbidsId) return;
    setLoading(true);
    setStep(1);
    try {
      const result = await fetchRawbidsPrices(
        commodity.rawbidsData.rawbidsId,
        amounts.map(a => a.amount)
      );
      setPrices(result);
    } finally {
      setLoading(false);
    }
  }, [amounts]);

  const handleUpdateRawbidsSupplier = useCallback(() => {
    if (!prices) return;
    onUpdateRawbidsSupplier(prices);
    handleHide();
  }, [prices]);

  return (
    <>
      <button className="btn bg-rawbids" onClick={handleShow}>
        <i className="fas fa-sync text-white pr-0" />
      </button>
      <Modal show={show} onHide={handleHide} size={step === 0 ? undefined : "lg"} centered name={"rawbidsRequestModal"}>
        <Modal.Header closeButton>
          <Modal.Title>Request Prices From Rawbids</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === 0 && (
            <>
              {hasRawbidsData ? (
                <div className="alert alert-info mt-4" role="alert">
                  <div className="alert-icon">
                    <i className="flaticon-exclamation-1" />
                  </div>
                  <div className="alert-text">
                    {commodity.rawbidsData?.commoditySnapshot.title.en} can only be requested in multiples of{" "}
                    {commodity.rawbidsData?.commoditySnapshot.packagingSizes?.map(p => p.packagingSize).join(", ") ||
                      DEFAULT_PACKAGING_SIZE.toString()}
                    {commodity.rawbidsData?.commoditySnapshot.unit}
                  </div>
                </div>
              ) : (
                <div className="alert alert-danger mt-4" role="alert">
                  <div className="alert-icon">
                    <i className="flaticon-exclamation-1" />
                  </div>
                  <div className="alert-text">
                    No Rawbids Information is available. Prices cannot be requested. Please connect with Rawbids first.
                  </div>
                </div>
              )}
              <RawbidsRequestAmounts
                amounts={amounts}
                packagingSizes={packagingSizes}
                onAddAmount={handleAddAmount}
                onRemoveAmount={handleRemoveAmount}
                onChangeAmount={handleChangeAmount}
              />
            </>
          )}
          {step === 1 && <RawbidsPricesOverview prices={prices} loading={loading} />}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleHide}>
            Close
          </button>
          {step > 0 && (
            <button className="btn btn-secondary" onClick={handleBack}>
              Back
            </button>
          )}
          {step === 0 && (
            <ErrorOverlayButton
              buttonText={
                <span>
                  <img
                    style={{
                      width: 12,
                      height: 12,
                      borderRadius: 0,
                      objectFit: "cover"
                    }}
                    alt={"R"}
                    src={"https://app.rawbids.com/rawbidsFavicon.ico"}
                    className="country-icon mb-1"
                  />
                  equest
                </span>
              }
              className={"btn btn-dark bg-rawbids"}
              errors={errorsFirstStep}
              onClick={handleRequestPrices}
              saving={loading}
            />
          )}
          {step === 1 && (
            <ErrorOverlayButton
              buttonText={"Apply"}
              className={"btn btn-dark bg-rawbids"}
              errors={errorsSecondStep}
              onClick={handleUpdateRawbidsSupplier}
              saving={loading}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface RawbidsRequestAmountsProps {
  amounts: Array<{ _id: BSON.ObjectId; amount: number }>;
  packagingSizes: Array<number>;
  onAddAmount: () => void;
  onRemoveAmount: (id: BSON.ObjectId | string) => void;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RawbidsRequestAmounts: React.FunctionComponent<RawbidsRequestAmountsProps> = ({
  amounts,
  packagingSizes,
  onAddAmount,
  onRemoveAmount,
  onChangeAmount
}) => {
  const isRemovable = useMemo(() => amounts.length > 1, [amounts]);
  return (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive overflow-hidden mb-0">
      <Table className={"table-small-padding"}>
        <thead>
          <tr>
            <th style={{ width: "80%" }}>Amount</th>
            <th style={{ width: "20%" }} />
          </tr>
        </thead>
        <tbody>
          {amounts.map(amount => (
            <RawbidsRequestAmount
              key={amount._id.toString()}
              amount={amount}
              packagingSizes={packagingSizes}
              isRemovable={isRemovable}
              onRemoveAmount={onRemoveAmount}
              onChangeAmount={onChangeAmount}
            />
          ))}
          <tr>
            <td className="align-middle"></td>
            <td className="align-middle text-right">
              <button className="btn btn-sm btn-success" onClick={onAddAmount}>
                <i className="fa fa-plus pr-0" />
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

interface RawbidsRequestAmountProps {
  amount: { _id: BSON.ObjectId; amount: number };
  packagingSizes: Array<number>;
  isRemovable: boolean;
  onRemoveAmount: (id: BSON.ObjectId | string) => void;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RawbidsRequestAmount: React.FunctionComponent<RawbidsRequestAmountProps> = ({
  amount,
  packagingSizes,
  isRemovable,
  onRemoveAmount,
  onChangeAmount: onBlurAmount
}) => {
  const [value, setValue] = useState(amount.amount.toString());

  const handleRemoveAmount = useCallback(() => {
    if (!isRemovable) return;
    onRemoveAmount(amount._id);
  }, [amount, isRemovable]);

  const handleChangeAmount = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    val = val.replaceAll(/^0+/g, "0");
    if (!val.includes(".")) val = Number(val).toString();
    if (+val < 0) return;
    setValue(val);
  }, []);

  const errors = useMemo(() => {
    return validateAmount(+value, packagingSizes);
  }, [value, packagingSizes]);

  return (
    <tr>
      <td className="align-middle">
        <div className="input-group">
          <input
            className={"form-control input-number-arrows-low-pr" + (errors ? " is-invalid" : "")}
            title={errors}
            type="number"
            value={value}
            min={0}
            onChange={handleChangeAmount}
            onBlur={onBlurAmount}
            name={amount._id.toString()}
          />
          <div className="input-group-append">
            <span className="input-group-text">kg</span>
          </div>
        </div>
      </td>
      <td className="align-middle text-right">
        <button
          className={"btn btn-sm btn-light" + (!isRemovable ? " disabled" : "")}
          onClick={handleRemoveAmount}
          disabled={!isRemovable}
        >
          <i className="fa fa-trash pr-0" />
        </button>
      </td>
    </tr>
  );
};

interface RawbidsPricesOverviewProps {
  prices: RawbidsPricesResult | null;
  loading: boolean;
}

const RawbidsPricesOverview: React.FunctionComponent<RawbidsPricesOverviewProps> = ({ prices, loading }) => {
  const pricesPerType = useMemo(() => (prices ? Object.entries(prices.prices) : []), [prices]);
  const invalidAmounts = useMemo(() => (prices?.invalidAmounts ? Object.entries(prices.invalidAmounts) : []), [prices]);
  const [showIssues, setShowIssues] = useState(false);

  const handleToggleShowIssues = useCallback(() => setShowIssues(prevState => !prevState), []);

  if (loading)
    return (
      <div>
        <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
        <h5 className="text-center text-dark">Requesting prices. Please Wait...</h5>
      </div>
    );
  if (!prices || !prices.hasPrices)
    return (
      <div className="alert alert-warning mt-4" role="alert">
        <div className="alert-icon">
          <i className="flaticon-exclamation-1" />
        </div>
        <div className="alert-text">
          No prices currently available.{" "}
          {invalidAmounts.length > 0 && (
            <>
              Following issues occurred:{" "}
              {invalidAmounts.map(([amount, errors]) => {
                return (
                  <div key={amount}>
                    {amount}kg: {errors.map(e => e.errorMessage).join(" ")}
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    );
  return (
    <>
      <div className="alert alert-info mt-4" role="alert">
        <div className="alert-icon">
          <i className="flaticon-exclamation-1" />
        </div>
        <div className="alert-text">
          Please note that the following prices are not guaranteed and are subject to change anytime.
          {invalidAmounts.length > 0 && (
            <div>
              {invalidAmounts.reduce((sum, errors) => sum + errors.length, 0)} issues occurred.{" "}
              <button className="btn btn-link text-white p-0" onClick={handleToggleShowIssues}>
                {showIssues ? "Hide" : "Show"}
              </button>
              {showIssues && (
                <div>
                  {invalidAmounts.map(([amount, errors]) => {
                    return (
                      <div key={amount}>
                        {amount}kg: {errors.map(e => e.errorMessage).join(" ")}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {pricesPerType.map(([type, pricesForType], idx) => {
        return (
          <React.Fragment key={type}>
            {pricesForType.length > 0 && (
              <>
                <RawbidsPricesTable key={type} title={_.upperFirst(type)} prices={pricesForType} />
                {idx < pricesPerType.length - 1 && <hr />}
              </>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

interface RawbidsPricesTableProps {
  title: string;
  prices: Array<RawbidsPrice>;
}

const RawbidsPricesTable: React.FunctionComponent<RawbidsPricesTableProps> = ({ title, prices }) => {
  const sortedPrices = useMemo(() => prices.sort((p1, p2) => p1.amount - p2.amount), [prices]);
  return (
    <div>
      <h5 className="text-dark">{title}</h5>
      <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive overflow-hidden mb-0">
        <Table className="mb-0">
          <thead>
            <tr>
              <th style={{ width: "25%" }}>Price</th>
              <th style={{ width: "25%" }}>Incoterm</th>
              <th style={{ width: "25%" }}>Amount</th>
              <th className="text-right" style={{ width: "25%" }}>
                Delivery Time
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedPrices.map(p => (
              <tr key={p._id.toString()}>
                <td className="align-middle">
                  <span className="kt-font-bold">{baseUtils.formatCurrency(p.price, p.currency)}</span>
                </td>
                <td className="align-middle">{p.incoterm}</td>
                <td className="align-middle">{p.amount}kg</td>
                <td className="align-middle text-right">
                  {p.deliveryTime} {p.deliveryTime === 1 ? "Day" : "Days"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default RawbidsRequestModal;
