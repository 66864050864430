import _ from "lodash";
import React, { PureComponent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { Dropdown } from "semantic-ui-react";
import CanOnlyBeDoneByProcurement from "../common/CanOnlyBeDoneByProcurement";
import { SupplierSelected } from "../common/supplier/CustomTypes";
import SupplierPricesSelection from "../common/supplier/SupplierPricesSelection";
import { DataContext } from "../../context/dataContext";
import { CapsuleSupplier } from "../../model/capsules.types";
import { AdditiveDocument } from "../../model/additives.types";
import dbService, { CAPSULES } from "../../services/dbService";
import accessUtils, { CREATELOCATIONS } from "../../utils/accessUtils";
import SelectAdditives from "./SelectAdditives";
import baseUtils from "../../utils/baseUtils";

interface CreateCapsuleProps extends RouteComponentProps<{}, {}, {}> {}
interface CreateCapsuleState {
  hasAdditives: boolean;
  additiveList: Array<AdditiveDocument | "">;
  material_de: string;
  material_en: string;
  color_de: string;
  color_en: string;
  size: string;
  volume: number;
  weight: number;
  properties: Array<BSON.ObjectId>;
  suppliers: Array<CapsuleSupplier>;
  saving: boolean;
}

class CreateCapsule extends PureComponent<CreateCapsuleProps, CreateCapsuleState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: CreateCapsuleProps) {
    super(props);
    this.state = {
      hasAdditives: false,
      additiveList: [] as Array<AdditiveDocument>,
      material_de: "",
      material_en: "",
      color_de: "",
      color_en: "",
      size: "",
      volume: 0,
      weight: 0,
      properties: [],
      suppliers: [],
      saving: false
    };
  }

  /**
   * Convert commodity properties into a format the dropdown can use.
   * @returns { key: string, text: string, value: string } Triple for dropdown
   */
  convertProperties = () => {
    const { commodityproperties } = this.context;
    return commodityproperties.map(property => {
      return {
        key: property._id.toString(),
        text: property.name.en,
        value: property._id.toString()
      };
    });
  };

  /**
   * Check the capsule creation for missing data.
   * @returns { Array<string> } Contains all missing data descriptions
   */
  checkForMissingData = () => {
    const { color_de, color_en, material_de, material_en, size, volume, weight, suppliers, properties } = this.state;
    const missingData = [];
    if (suppliers.length === 0) missingData.push("Supplier missing");
    if (properties.length === 0) missingData.push("Properties missing");
    if (!material_de) missingData.push("Material german missing");
    if (!material_en) missingData.push("Material english missing");
    if (!color_de) missingData.push("Color german missing");
    if (!color_en) missingData.push("Color english missing");
    if (!size) missingData.push("Size missing");
    if (!volume) missingData.push("Capsule can not have volume of 0");
    if (!weight) missingData.push("Capsule can not have weight of 0");
    return missingData;
  };

  submitCapsule = async () => {
    const { history } = this.props;
    const { color_de, color_en, additiveList, material_de, material_en, properties, size, suppliers, volume, weight } =
      this.state;
    const { updateDocumentInContext } = this.context;
    this.setState({ saving: true });
    const additives = additiveList.filter(aL => aL !== "");
    const capsule = {
      capsule_material: { de: material_de, en: material_en },
      capsule_color: { de: color_de, en: color_en },
      capsule_size: size,
      capsule_volume: volume,
      capsule_bottlingcosts: 0,
      properties: properties,
      capsule_weight: weight,
      suppliers: suppliers,
      additives: additives.length > 0 ? additives.map(a => a && a._id) : []
    };
    const res = await dbService.insertDocument(CAPSULES, capsule);
    if (res && res.insertedId) {
      toast.success(
        <b>
          <i className="fa fa-check mx-2" />
          Capsule inserted successfully
        </b>
      );
      await updateDocumentInContext(CAPSULES, res.insertedId);
      history.push("/capsule/" + res.insertedId.toString());
    } else {
      toast.error(
        <b>
          <i className="fa fa-exclamation mx-2" />
          Error inserting capsule
        </b>
      );
    }
    this.setState({ saving: false });
  };

  handleAddAdditive = () => {
    const additiveList = _.cloneDeep(this.state.additiveList);
    additiveList.push("");
    this.setState({ additiveList });
  };

  handleAdditiveChange = (idx: number, id: BSON.ObjectId) => {
    const additiveList = _.cloneDeep(this.state.additiveList);
    additiveList[idx] = baseUtils.getDocFromCollection(this.context.additives, id);
    this.setState({ additiveList });
  };

  handleDeleteAdditive = (idx: number) => {
    const additiveList = _.cloneDeep(this.state.additiveList);
    additiveList.splice(idx, 1);
    this.setState({ additiveList });
  };

  handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = ["volume", "weight"].includes(e.target.name)
      ? parseFloat(e.target.value) > 0
        ? parseFloat(e.target.value)
        : 0
      : e.target.value;
    // @ts-ignore
    this.setState({ [e.target.name]: val });
  };

  handleSuppliersSelectedChange = (suppliersSelected: Array<SupplierSelected>) => {
    const suppliers = [];
    for (let supplier of suppliersSelected) {
      const sup: CapsuleSupplier = { _id: supplier._id, prices: [], ...(supplier.type ? { type: supplier.type } : {}) };
      for (let price of supplier.prices) {
        sup.prices.push({
          _id: price._id.toString(),
          moq: +price.moq,
          price: +price.totalPrice,
          deliverytime: +price.deliveryTime,
          delivery: "",
          note: price.note,
          date: price.age
        });
      }
      suppliers.push(sup);
    }
    this.setState({ suppliers });
  };

  render() {
    const { history } = this.props;
    const { material_en, material_de, color_en, color_de, size, volume, weight, properties, saving, additiveList } =
      this.state;
    const { commodityproperties, additives } = this.context;
    const missingData = this.checkForMissingData();
    const canCreate = accessUtils.canCreateData(CREATELOCATIONS.CAPSULE);
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand fa fa-building" />
            </span>
            <h3 className="kt-portlet__head-title">Create a New Capsule</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <a
              onClick={() => {
                history.goBack();
              }}
              className="btn btn-clean kt-margin-r-10"
            >
              <i className="la la-arrow-left" />
              <span className="kt-hidden-mobile">Back</span>
            </a>
            <div className="btn-group">
              {missingData.length > 0 || !canCreate ? (
                <OverlayTrigger
                  overlay={
                    <Tooltip id={"commodity-invalid"}>
                      {canCreate ? (
                        missingData.map((e, key) => {
                          return (
                            <React.Fragment key={key}>
                              <span className="text-danger">
                                <b>{e}</b>
                              </span>
                              <br />
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <span className="text-danger">
                          <b>You are not allowed to create capsules</b>
                        </span>
                      )}
                    </Tooltip>
                  }
                  placement={"left"}
                >
                  <button
                    type="button"
                    className={
                      "btn btn-brand disabled" +
                      (saving ? " kt-spinner kt-spinner--left kt-spinner--md kt-spinner--light" : "")
                    }
                    onClick={this.submitCapsule}
                  >
                    <i className={saving ? "d-none" : "la la-check"} />
                    <span className="kt-hidden-mobile">Create Capsule</span>
                  </button>
                </OverlayTrigger>
              ) : (
                <button
                  type="button"
                  className={
                    "btn btn-brand" + (saving ? " kt-spinner kt-spinner--left kt-spinner--md kt-spinner--light" : "")
                  }
                  onClick={this.submitCapsule}
                >
                  <i className={saving ? "d-none" : "la la-check"} />
                  <span className="kt-hidden-mobile">Create Capsule</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="kt-portlet__body">
          {canCreate ? (
            <div className="row">
              <div className="col-xl-1" />
              <div className="col-xl-10">
                <div className="kt-section">
                  <div className="kt-section__body">
                    <h3 className="kt-section__title kt-section__title-lg">Capsule Info:</h3>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Capsule Material </label>
                      <div className="col-9">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <img src="media/icons/icon-de.png" className="country-icon" />
                            </span>
                          </div>
                          <input
                            className="form-control"
                            type="text"
                            onChange={this.handleValueChange}
                            placeholder="Kapselmaterial"
                            value={material_de}
                            name="material_de"
                          />
                        </div>
                        <div className="input-group mt-2">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <img src="media/icons/icon-en.png" className="country-icon" />
                            </span>
                          </div>
                          <input
                            className="form-control"
                            type="text"
                            onChange={this.handleValueChange}
                            placeholder="Capsule material"
                            value={material_en}
                            name="material_en"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Capsule Color </label>
                      <div className="col-9">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <img src="media/icons/icon-de.png" className="country-icon" />
                            </span>
                          </div>
                          <input
                            className="form-control"
                            type="text"
                            onChange={this.handleValueChange}
                            placeholder="Kapselfarbe"
                            value={color_de}
                            name="color_de"
                          />
                        </div>
                        <div className="input-group mt-2">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <img src="media/icons/icon-en.png" className="country-icon" />
                            </span>
                          </div>
                          <input
                            className="form-control"
                            type="text"
                            onChange={this.handleValueChange}
                            placeholder="Capsule color"
                            value={color_en}
                            name="color_en"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
                    <div className={"form-group row"}>
                      <label className={"col-3 col-form-label"}>Capsule Additives</label>
                      <div className="col-9">
                        <SelectAdditives
                          selectedAdditives={additiveList}
                          additives={additives}
                          context={this.context}
                          onAdd={this.handleAddAdditive}
                          onChange={this.handleAdditiveChange}
                          onDelete={this.handleDeleteAdditive}
                        />
                      </div>
                    </div>
                    <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Size / Volume / Weight </label>
                      <div className="col-3">
                        <input
                          className="form-control"
                          type="text"
                          onChange={this.handleValueChange}
                          placeholder="Size"
                          value={size}
                          name="size"
                        />
                      </div>
                      <div className="col-3">
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="number"
                            onChange={this.handleValueChange}
                            placeholder="Volume"
                            value={volume.toString()}
                            name="volume"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              cm<sup>3</sup>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="number"
                            onChange={this.handleValueChange}
                            placeholder="Weight"
                            value={weight.toString()}
                            name="weight"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">mg</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Properties </label>
                      <div className="col-9">
                        <Dropdown
                          placeholder="Properties"
                          fluid
                          multiple
                          selection
                          options={this.convertProperties()}
                          value={properties.map(p => p.toString())}
                          onChange={(e, { value }) => {
                            if (Array.isArray(value)) {
                              this.setState({
                                properties: commodityproperties
                                  .filter(cp => value.includes(cp._id.toString()))
                                  .map(cp => cp._id)
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Suppliers / Pricing </label>
                      <div className="col-9">
                        <SupplierPricesSelection
                          type="Capsule"
                          onSuppliersSelectedChange={this.handleSuppliersSelectedChange}
                          allowManufacturer={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <CanOnlyBeDoneByProcurement location="capsule" />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(CreateCapsule);
