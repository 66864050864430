import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import Select from "react-select";
import { DataContext } from "../../../context/dataContext";
import { PaginationState } from "../../common/CustomTypes";
import baseUtils, { getComponentState } from "../../../utils/baseUtils";
import HistoryBackButton from "../../listings/common/HistoryBackButton";
import { SearchBar } from "../../listings/common/Filters";
import {
  EM_ACCEPTED,
  EM_ARRIVED,
  EM_CANCELED,
  EM_CHECKED,
  EM_DELIVERED,
  EM_EXPIRED,
  EM_FINISHED,
  EM_OPEN,
  EM_ORDERED
} from "../ExternalManufacturerHelper";
import DateInput from "../../common/DateInput";
import SplashScreen from "../../common/SplashScreen";
import BaseListing from "../../listings/BaseListing";
import { paginate } from "../../common/Pagination";
import EMOrdersRow from "../orders/EMOrdersRow";
import dateUtils from "../../../utils/dateUtils";

interface EmOrdersProps extends RouteComponentProps<{}, {}, {}> {}

interface EmOrdersState extends PaginationState {
  search: string;
  status: "" | { value: string; label: string };
  commodity: "" | { value: string; label: string };
  company: "" | { value: string; label: string };
  hideCanceled: boolean;
  hideExpired: boolean;
  period: { start: Date; end: Date };
}

const CONSTRUCTORNAME = "AllEMOrders";

class AllEMOrders extends PureComponent<EmOrdersProps, EmOrdersState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: EmOrdersProps, context: React.ContextType<typeof DataContext>) {
    super(props, context);
    this.state = {
      pageSize: 10,
      currentPage: 1,
      search: "",
      status: "",
      commodity: "",
      company: "",
      hideCanceled: true,
      hideExpired: true,
      period: dateUtils.getDefaultPeriod()
    };
  }

  componentDidMount() {
    const state = getComponentState(this.context, CONSTRUCTORNAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }

  handleFilter = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value, currentPage: 1 });
  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleReset = () =>
    this.setState({
      pageSize: 10,
      currentPage: 1,
      search: "",
      status: "",
      company: "",
      commodity: "",
      hideExpired: true,
      hideCanceled: true,
      period: dateUtils.getDefaultPeriod()
    });
  handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const period = _.cloneDeep(this.state.period);
    const type = e.target.name as "start" | "end";
    let value = e.target.valueAsDate;
    if (!value) return;
    if (type === "start") value.setHours(0, -value.getTimezoneOffset(), 0, 0);
    else value.setHours(23, 59 + -value.getTimezoneOffset(), 59, 999);
    _.set(period, type, value);
    this.setState({ period, currentPage: 1 });
  };

  getFilteredOrders = () => {
    const { search, status, commodity, period, hideCanceled, hideExpired } = this.state;
    let filteredOrders = this.context.externalManufacturerOrders.filter(
      o => period.start <= o.createdOn && o.createdOn <= period.end
    );
    if (status) filteredOrders = filteredOrders.filter(emo => emo.state === status.value);
    if (commodity) filteredOrders = filteredOrders.filter(emo => emo.commodityId.toString() === commodity.value);
    if (hideExpired && !status) filteredOrders = filteredOrders.filter(emo => emo.state !== EM_EXPIRED);
    if (hideCanceled && !status) filteredOrders = filteredOrders.filter(emo => emo.state !== EM_CANCELED);
    if (search.trim() === "") return filteredOrders;
    return baseUtils.doFuseSearch(filteredOrders, search, ["reference", "identifier", "note", "state", "amount"]);
  };

  render() {
    const { history } = this.props;
    const { externalManufacturerOrders, commodities, companies } = this.context;
    const { pageSize, currentPage, search, status, period, hideCanceled, hideExpired, commodity, company } = this.state;
    let orders = this.getFilteredOrders();
    const requests = orders
      .filter(o => [EM_OPEN, EM_CHECKED].includes(o.state))
      .sort((a, b) => (b.state === EM_OPEN ? 1 : 0) - (a.state === EM_OPEN ? 1 : 0));
    orders = orders.filter(o => !requests.some(r => r._id.toString() === o._id.toString()));
    const commoditiesWithOrders = commodities.filter(c =>
      externalManufacturerOrders.some(o => o.commodityId.toString() === c._id.toString())
    );
    const companiesWithOrders = companies.filter(c =>
      externalManufacturerOrders.some(o => o.company.toString() === c._id.toString())
    );
    const concatenatedOrders = requests.concat(orders);

    const headerDefinition = [
      { title: "Order", size: 20 },
      { title: "Status", size: 7 },
      { title: "Amount", size: 8 },
      { title: "Created", size: 7 },
      { title: "Price", size: 8 },
      { title: "Expected Delivery", size: 10 },
      { title: "Limit Kilo Price", size: 10 },
      { title: "Latest Delivery Date", size: 10 },
      { title: "Action", size: 10 }
    ];
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand flaticon-business" />
            </span>
            <h3 className="kt-portlet__head-title">External Orders</h3>
            <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
              Reset
            </button>
          </div>
          <HistoryBackButton history={history} />
        </div>
        <div className="kt-portlet__body pb-0">
          <div className="kt-form kt-form--label-right kt-margin-b-10">
            <div className="row align-items-center">
              <SearchBar onSearch={this.handleFilter} search={search} name={"search"} />
              <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                <Select
                  className="select-default"
                  options={commoditiesWithOrders.map(c => {
                    return { value: c._id.toString(), label: c.title.en };
                  })}
                  isClearable={true}
                  value={commodity || { value: "", label: "Commodities" }}
                  onChange={(e: any) => this.setState({ commodity: e || "" })}
                />
              </div>
              <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                <Select
                  className="select-default"
                  options={companiesWithOrders.map(c => {
                    return { value: c._id.toString(), label: c.name };
                  })}
                  isClearable={true}
                  value={company || { value: "", label: "Companies" }}
                  onChange={(e: any) => this.setState({ company: e || "" })}
                />
              </div>
              <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                <Select
                  className="select-default"
                  options={[
                    EM_OPEN,
                    EM_CHECKED,
                    EM_ACCEPTED,
                    EM_ORDERED,
                    EM_DELIVERED,
                    EM_CANCELED,
                    EM_ARRIVED,
                    EM_FINISHED,
                    EM_EXPIRED
                  ].map(s => {
                    return { value: s, label: _.upperFirst(s) };
                  })}
                  isClearable={true}
                  value={status || { value: "", label: "States" }}
                  onChange={(e: any) => this.setState({ status: e || "" })}
                />
              </div>
              <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                <DateInput value={period.start} onBlur={this.handlePeriodChange} name={"start"} />
              </div>
              <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                <DateInput value={period.end} onBlur={this.handlePeriodChange} name={"end"} />
              </div>
            </div>
            <div className="row align-items-center kt-margin-t-10">
              <div className="col-md-12 kt-margin-b-20-tablet-and-mobile">
                {status === "" && (
                  <div className="float-right">
                    <label className="kt-checkbox kt-font-dark m-0 p-0 px-4">
                      <input
                        type="checkbox"
                        checked={hideCanceled}
                        onChange={() => this.setState({ hideCanceled: !hideCanceled })}
                      />
                      <span />
                      <div className="pl-1 kt-font-dark">Hide canceled</div>
                    </label>
                    <label className="kt-checkbox kt-font-dark m-0 p-0 px-4">
                      <input
                        type="checkbox"
                        checked={hideExpired}
                        onChange={() => this.setState({ hideExpired: !hideExpired })}
                      />
                      <span />
                      <div className="pl-1 kt-font-dark">Hide expired</div>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          {externalManufacturerOrders.length === 0 ? (
            <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
          ) : (
            <BaseListing
              headerDefinition={headerDefinition}
              documents={concatenatedOrders}
              bodyContent={
                <>
                  {paginate(concatenatedOrders, currentPage, pageSize).map(o => (
                    <EMOrdersRow key={o._id.toString()} order={o} internal={true} {...this.props} />
                  ))}
                </>
              }
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AllEMOrders;
