import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DataContext } from "../../context/dataContext";
import { MinimumCalendarPackaging, PackagingsDocument } from "../../model/packagings.types";
import packagingUtils from "../../utils/packagingUtils";
import { DeliveryCalendarUserContext } from "../../context/deliveryCalendarUserContext";

interface PackagingWidgetProps {
  packaging: PackagingsDocument | MinimumCalendarPackaging;
  context: React.ContextType<typeof DataContext> | React.ContextType<typeof DeliveryCalendarUserContext>;
  additionalClasses?: string;
}

class PackagingWidget extends PureComponent<PackagingWidgetProps, {}> {
  /**
   * Renders the correct image for the given commodity.
   * @returns { JSX.Element } Image tag with correct link
   */
  renderImage = () => {
    const { packaging } = this.props;
    return (
      <img
        src={packagingUtils.getPackagingImage(packaging)}
        alt="packaging"
        style={{ height: "40px", width: "40px", objectFit: "cover" }}
      />
    );
  };

  render() {
    const { packaging, additionalClasses } = this.props;
    const description = packagingUtils.getShortPackagingInfo(packaging);
    const longDescription = packagingUtils.resolvePackagingProperties(packaging);
    return (
      <div className={"kt-user-card-v2 " + (additionalClasses ? additionalClasses : "")}>
        <div className="kt-user-card-v2__pic d-none d-xl-block">{this.renderImage()}</div>
        <div className="kt-user-card-v2__details">
          <Link
            to={"/packaging/" + packaging._id.toString()}
            className="kt-user-card-v2__name kt-link"
            onClick={e => e.stopPropagation()}
          >
            {description.length > 33 ? description.slice(0, 30) + "..." : description}
          </Link>
          <span className="kt-user-card-v2__email">
            {longDescription.length > 33 ? (
              <OverlayTrigger placement="auto" overlay={<Tooltip id="tooltip">{longDescription}</Tooltip>}>
                <span>{longDescription.slice(0, 30) + "..."}</span>
              </OverlayTrigger>
            ) : (
              longDescription
            )}
          </span>
        </div>
      </div>
    );
  }
}

export default PackagingWidget;
