import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import i18n from "../../../translations/i18n";
import { BaseActionModalProps } from "../../../model/warehouse/common.types";
import { DataContextType, useDataContext } from "../../../context/dataContext";
import { useWarehouseContext } from "../../../context/warehouseContext";
import { Batch, BatchFile } from "../../../model/warehouse/batch.types";
import { resolveTranslation } from "../../../utils/translationUtils";
import { FileType, PackagingUnitDefinition } from "../../../model/configuration/warehouseConfiguration.types";
import fileUtils from "../../../utils/fileUtils";
import baseUtils, { formatNumValue } from "../../../utils/baseUtils";
import { getBatchFileEntry } from "../../../utils/batchUtils";
import toastUtils from "../../../utils/toastUtils";
import { DEFAULTWEIGHTUNIT } from "../../../utils/warehouseUtils";
import Tooltip from "../../common/Tooltip";
import ChangeBBDView from "../common/ChangeBBDView";
import dbService, { BATCH } from "../../../services/dbService";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { getBatchIdFromSelectedEntries } from "../../../utils/warehouseActionUtils";
import { ReservationState } from "../../../model/warehouse/reservation.types";
import UploadFileDragNDrop from "../common/UploadFileDragNDrop";

enum View {
  BATCHDATAVIEW,
  BBDVIEW
}

interface ChangeBatchDataModalState {
  batchId: string;
  batchProductName: string;
  batchSenderName: string;
  lot: string;
  stocked: Date;
  bbd: Date;
  bbdComment: string;
  files: Array<BatchFile>;
  lotOfAnother: boolean;
  showUpload: boolean;
  pU?: PackagingUnitDefinition;
  amountPerPU?: string;
  isEverythingInEntrance: boolean;
  view: View;
}

const getDefaultState = (batchEntry: Batch | undefined, dataContext: DataContextType): ChangeBatchDataModalState => {
  // parent batch from selected location
  const everythingInEntrance =
    batchEntry?.locations.length === 1 &&
    batchEntry.locations[0].location.storageSpace === undefined &&
    batchEntry.locations[0].packagingUnits.length === 1 &&
    dataContext.reservation
      .filter(r => r.state === ReservationState.OPEN)
      .every(r =>
        r.materials.every(m => m.material.details._id.toString() !== batchEntry.content.details._id.toString())
      );
  if (batchEntry) {
    return {
      batchId: batchEntry._id.toString(),
      batchProductName: resolveTranslation(batchEntry.content.details.title),
      batchSenderName: batchEntry.sender.name,
      lot: batchEntry.lot,
      stocked: batchEntry.stocked,
      bbd: batchEntry.expiry,
      bbdComment: "",
      files: batchEntry.files,
      lotOfAnother: false,
      showUpload: false,
      pU: everythingInEntrance ? batchEntry.locations[0].packagingUnits[0].puSnapshot : undefined,
      amountPerPU: everythingInEntrance
        ? batchEntry.locations[0].packagingUnits[0].amountPerPu.value.toString()
        : undefined,
      isEverythingInEntrance: everythingInEntrance,
      view: View.BATCHDATAVIEW
    };
  } else {
    return {
      batchId: "",
      batchProductName: "",
      batchSenderName: "",
      lot: "",
      stocked: new Date(),
      bbd: new Date(),
      bbdComment: "",
      files: [],
      lotOfAnother: false,
      showUpload: false,
      isEverythingInEntrance: false,
      view: View.BATCHDATAVIEW
    };
  }
};

const ChangeBatchDataModal: React.FC<BaseActionModalProps> = ({ show, onHide }) => {
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { updateDocumentInContext } = dataContext;
  const { selectedEntries } = warehouseContext;

  const batchEntry = useMemo(() => {
    return show && selectedEntries.length > 0
      ? dataContext.batch.find(b => b._id.toString() === getBatchIdFromSelectedEntries(selectedEntries))
      : undefined;
  }, [dataContext.batch, show, selectedEntries]);

  const [state, setState] = useState(getDefaultState(batchEntry, dataContext));
  const [saving, setSaving] = useState<boolean>(false);

  const errors = useMemo(() => {
    const newErrors = [];
    if (state.view === View.BATCHDATAVIEW) {
      if (state.lot === "") newErrors.push(i18n.t("warehouse:lotMissing"));
      if (state.lotOfAnother) newErrors.push(`${i18n.t("warehouse:batchExistingWarningOnEdit")}`);
      if (state.amountPerPU && +state.amountPerPU < 0) newErrors.push(i18n.t("warehouse:amountPerPUError"));
      if (
        state.pU &&
        state.pU.maxFillingWeight &&
        state.amountPerPU &&
        state.pU.maxFillingWeight.value < +state.amountPerPU
      )
        newErrors.push(i18n.t("warehouse:packagingUnitTooSmall"));
    } else {
      if (batchEntry && state.bbd === batchEntry.expiry) newErrors.push(i18n.t("warehouse:bbdNotChanged"));
      if (state.bbdComment === "") newErrors.push(i18n.t("warehouse:addCommentPlaceholder"));
    }
    return newErrors;
  }, [state, batchEntry]);

  const hasTotalAmountChanged = useMemo(() => {
    const batchEntry =
      show && selectedEntries.length > 0
        ? dataContext.batch.find(b => b._id.toString() === getBatchIdFromSelectedEntries(selectedEntries))
        : undefined;
    return (
      state.amountPerPU !== "" &&
      batchEntry &&
      batchEntry.locations.length > 0 &&
      state.amountPerPU !== batchEntry.locations[0].packagingUnits[0].amountPerPu.value.toString()
    );
  }, [show, dataContext.batch, state.amountPerPU]);

  useEffect(() => {
    setState(getDefaultState(batchEntry, dataContext));
  }, [dataContext.batch, batchEntry]);

  const handleHide = () => {
    if (saving) return;
    setState(getDefaultState(batchEntry, dataContext));
    onHide();
  };

  const handleChangeLot = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.currentTarget.value.trim();
      const lotOfAnother = dataContext.batch.some(b => b._id.toString() !== state.batchId && b.lot === newValue);
      setState(prevState => {
        return { ...prevState, lot: newValue, lotOfAnother };
      });
    },
    [dataContext.batch, state.batchId]
  );

  const handleChangeDates = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    const value = e.currentTarget.value;
    setState(prevState => {
      return { ...prevState, [key]: new Date(value) };
    });
  }, []);

  const handleChangeAmountPerPu = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    let newValue = value.replaceAll(/^0+/g, "0");
    if (!Number(newValue) && Number(newValue) !== 0) return;
    if (!newValue.includes(".")) newValue = Number(newValue).toString();
    setState(prevState => {
      return { ...prevState, amountPerPU: newValue };
    });
  }, []);

  const handleChangePU = useCallback(
    (e: any) => {
      const value = e.value;
      const newPu = warehouseContext.configuration?.values.packagingUnitDefinitions.find(
        pu => pu._id.toString() === value
      );
      setState(prevState => {
        return {
          ...prevState,
          pU: newPu
        };
      });
    },
    [warehouseContext.configuration]
  );

  const handleChangeBBDComment = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.currentTarget.value;
    setState(prevState => {
      return { ...prevState, bbdComment: newValue };
    });
  }, []);

  const handleShowUpload = useCallback(() => {
    setState(prevState => {
      return { ...prevState, showUpload: !prevState.showUpload };
    });
  }, []);

  const handleFileUpload = useCallback(
    (file: File, title: string, type: FileType) => {
      const changedFiles = _.cloneDeep(state.files);
      const fileType = fileUtils.getFileExtension(file!.name);
      const fileName = baseUtils.encodeString(title) + "." + fileType;

      const path = fileUtils.uploadFile(file, fileName);
      if (path) {
        const batchFile = getBatchFileEntry(path, file, type);
        changedFiles.push(batchFile);

        setState(prevState => {
          return { ...prevState, files: changedFiles };
        });
      } else {
        toast.error(i18n.t("common:errorFileUpload"));
      }
    },
    [state.files]
  );

  const handleRemoveFile = useCallback(
    (fileId: string) => {
      const newFiles = state.files.filter(f => f._id.toString() !== fileId);
      setState(prevState => {
        return { ...prevState, files: newFiles };
      });
    },
    [state.files]
  );

  const handleChangeView = useCallback(
    (view: View) => {
      if (saving) return;
      setState(prevState => {
        return { ...prevState, view };
      });
    },
    [saving]
  );

  const handleSaveBBD = useCallback(async () => {
    if (!batchEntry) {
      toast.error(i18n.t("warehouse:adjustBBDFail"));
      return;
    }
    const res = await dbService.callFunction(
      "changeBBD",
      [batchEntry._id, state.bbd, state.bbdComment, state.files],
      true
    );
    await toastUtils.databaseOperationToast(
      res,
      i18n.t("warehouse:adjustBBDSuccess"),
      i18n.t("warehouse:adjustBBDFail"),
      () => handleChangeView(View.BATCHDATAVIEW)
    );
  }, [state, batchEntry]);

  const handleSave = useCallback(async () => {
    if (!batchEntry) {
      toast.error(i18n.t("warehouse:batchDataChangeFailure"));
      return;
    }
    setSaving(true);
    try {
      const res = await dbService.callFunction<boolean>(
        "changeBatchData",
        [
          batchEntry._id,
          state.lot,
          state.files,
          state.stocked,
          batchEntry.locations.length === 1 && state.pU ? state.pU : undefined,
          batchEntry.locations.length === 1 && state.amountPerPU ? +state.amountPerPU : undefined
        ],
        true
      );
      await toastUtils.databaseOperationToast(
        res,
        i18n.t("warehouse:batchDataChangeSuccess"),
        i18n.t("warehouse:batchDataChangeFailure"),
        () => {
          updateDocumentInContext(BATCH, batchEntry._id);
          setState(getDefaultState(batchEntry, dataContext));
          onHide();
        }
      );
    } finally {
      setSaving(false);
    }
  }, [state, batchEntry]);

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>
            {state.view === View.BATCHDATAVIEW ? i18n.t("warehouse:changeBatchData") : i18n.t("warehouse:addNewBbd")}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          {state.view === View.BATCHDATAVIEW ? (
            <>
              <h4 className="mb-5 font-weight-bold text-black">{i18n.t("warehouse:changeBatchData")}</h4>
              <div className="alert alert-warning mt-2" role="alert">
                <div className="alert-icon">
                  <i className="flaticon-warning" />
                </div>
                <div className="alert-text">
                  <p>{i18n.t("warehouse:cannotChangeComAndSup")}</p>
                  <p>{i18n.t("warehouse:wholeBatchAdjusted")}</p>
                  {hasTotalAmountChanged &&
                    batchEntry &&
                    batchEntry.locations[0].packagingUnits[0].quantity &&
                    state.amountPerPU && (
                      <p>
                        {i18n.t("warehouse:totalAmountChanged", {
                          amountBefore: batchEntry.totalAmount.value,
                          unit: batchEntry.totalAmount.unit,
                          amountAfter: +state.amountPerPU * batchEntry.locations[0].packagingUnits[0].quantity
                        })}
                      </p>
                    )}
                </div>
              </div>
              <div className="row my-2 font-size-lg text-black" style={{ fontWeight: 500 }}>
                <div className="col-4 align-self-center">{i18n.t("warehouse:commodity")} </div>
                <div className="col-8">{state.batchProductName}</div>
              </div>
              <div className="row my-2 font-size-lg text-black" style={{ fontWeight: 500 }}>
                <div className="col-4 align-self-center">{i18n.t("warehouse:supplier")} </div>
                <div className="col-8">{state.batchSenderName}</div>
              </div>
              <div className="row my-2 font-size-lg text-black" style={{ fontWeight: 500 }}>
                <div className="col-4 align-self-center">{i18n.t("warehouse:batchNumber")}</div>
                <div className="col-8">
                  <input className="form-control form-control-solid" value={state.lot} onChange={handleChangeLot} />
                </div>
              </div>
              <div className="row my-2 font-size-lg text-black" style={{ fontWeight: 500 }}>
                <div className="col-4 align-self-center">{i18n.t("warehouse:stocked")}</div>
                <div className="col-8">
                  <input
                    className="form-control form-control-solid"
                    type="date"
                    name="stocked"
                    value={state.stocked.toISOString().split("T")[0]}
                    onChange={handleChangeDates}
                  />
                </div>
              </div>
              <div className="row my-2 font-size-lg text-black" style={{ fontWeight: 500 }}>
                <div className="col-4 align-self-center">{i18n.t("warehouse:bbd")}</div>
                <div className="col-8">
                  <button
                    type="button"
                    className="btn btn-secondary w-100"
                    onClick={() => handleChangeView(View.BBDVIEW)}
                  >
                    {i18n.t("warehouse:changeMHD")}
                  </button>
                </div>
              </div>
              {state.isEverythingInEntrance && (
                <div>
                  <div className="row my-2 font-size-lg text-black" style={{ fontWeight: 500 }}>
                    <div className="col-4 align-self-center">{i18n.t("warehouse:packagingUnit")}</div>
                    <div className="col-8">
                      <Select
                        className="select-warehouse w-100"
                        classNamePrefix="select-warehouse w-100"
                        onChange={handleChangePU}
                        value={{
                          value: state.pU?._id.toString(),
                          label: state.pU
                            ? `${resolveTranslation(state.pU.label)} (${
                                state.pU.maxFillingWeight ? formatNumValue(state.pU.maxFillingWeight) : "-"
                              })`
                            : ""
                        }}
                        placeholder={i18n.t("warehouse:commodity")}
                        options={warehouseContext.configuration?.values.packagingUnitDefinitions.map(pu => {
                          return {
                            value: pu._id.toString(),
                            label: `${resolveTranslation(pu.label)} (${
                              pu.maxFillingWeight ? formatNumValue(pu.maxFillingWeight) : "-"
                            })`
                          };
                        })}
                      />
                    </div>
                  </div>
                  <div className="row my-2 font-size-lg text-black" style={{ fontWeight: 500 }}>
                    <div className="col-4 align-self-center">{i18n.t("warehouse:amountPerPU")}</div>
                    <div className="col-8">
                      <div className="input-group">
                        <input
                          className="form-control form-control-solid"
                          value={state.amountPerPU}
                          onChange={handleChangeAmountPerPu}
                        />
                        <div className="input-group-append">
                          <div className="btn btn-secondary">{DEFAULTWEIGHTUNIT}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row my-2 font-size-lg text-black" style={{ fontWeight: 500 }}>
                <div className="col-4 align-self-center">{i18n.t("common:files")}</div>
                <div className="col-8">
                  <Tooltip
                    tooltipText={
                      state.files.length === 0 ? (
                        `0 ${i18n.t("common:files")}`
                      ) : (
                        <span>
                          {state.files.map(f => {
                            return <p key={f._id.toString()}>{f.title}</p>;
                          })}
                        </span>
                      )
                    }
                  >
                    <img
                      className="align-self-center"
                      src="/media/files/pdf.svg"
                      alt="pdf symbol"
                      style={state.files.length ? { height: 20 } : { height: 20, opacity: "35%" }}
                    />
                  </Tooltip>
                  <button className="btn btn-text text-muted mr-2" onClick={handleShowUpload}>
                    {i18n.t("warehouse:uploadFile")}
                  </button>
                </div>
              </div>
              {state.showUpload && warehouseContext.configuration && (
                <div className="row">
                  <UploadFileDragNDrop
                    fileTypes={warehouseContext.configuration.values.fileTypes}
                    onUploadFile={handleFileUpload}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <h4 className="mb-5 font-weight-bold text-black">{i18n.t("warehouse:addNewBbd")}</h4>
              <ChangeBBDView
                bbd={state.bbd}
                comment={state.bbdComment}
                files={state.files}
                onChangeBBD={handleChangeDates}
                onChangeComment={handleChangeBBDComment}
                onUploadFile={handleFileUpload}
                onRemoveFile={handleRemoveFile}
              />
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          disabled={saving}
          className={"btn btn-secondary " + (saving ? "disabled" : "")}
          onClick={state.view === View.BATCHDATAVIEW ? handleHide : () => handleChangeView(View.BATCHDATAVIEW)}
        >
          {state.view === View.BATCHDATAVIEW ? i18n.t("common:close") : i18n.t("common:back")}
        </button>
        <ErrorOverlayButton
          buttonText={i18n.t("common:save")}
          className="btn btn-success"
          errors={errors}
          saving={saving}
          onClick={state.view === View.BATCHDATAVIEW ? handleSave : handleSaveBBD}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeBatchDataModal;
