import _ from "lodash";
import React, { PureComponent } from "react";
import Chart from "react-apexcharts";
import baseUtils from "../../../utils/baseUtils";

interface OutputGoalsProps {
  type: "bottles" | "capsules" | "turnover" | "profit";
}

interface OutputGoalsState {}

class OutputGoals extends PureComponent<OutputGoalsProps, OutputGoalsState> {
  series: Array<{ name: string; data: Array<{ x: string; y: number }> }>;
  options: any;

  constructor(props: OutputGoalsProps) {
    super(props);
    this.series = [
      {
        name: "Output goal - " + _.upperFirst(props.type),
        data: this.resolveData()
      }
    ];
    this.options = {
      chart: {
        type: "bar",
        id: "basic-bar-output-goals",
        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories: ["2022", "2023", "2024", "2025", "2026"]
      },
      yaxis: {
        labels: {
          formatter: (v: string) => {
            if (["profit", "turnover"].includes(props.type)) return baseUtils.formatEuro(+v);
            return (+v).toLocaleString();
          }
        }
      },
      dataLabels: {
        formatter: (v: string) => {
          if (["profit", "turnover"].includes(props.type)) return baseUtils.formatEuro(+v);
          return (+v).toLocaleString();
        }
      },
      colors: this.resolveColors(),
      plotOptions: {
        bar: {
          distributed: true,
          dataLabels: { position: "top" }
        }
      }
    };
  }

  /**
   * Resolves the data that should be shown in the given output goal graph.
   * @returns { Array<object> } Contains x and y values for the given type
   */
  resolveData = () => {
    const { type } = this.props;
    switch (type) {
      case "bottles":
        return [
          { x: "2022", y: 2500000 },
          { x: "2023", y: 2900000 },
          { x: "2024", y: 3300000 },
          { x: "2025", y: 3600000 },
          { x: "2026", y: 4100000 }
        ];
      case "capsules":
        return [
          { x: "2022", y: 450000000 },
          { x: "2023", y: 520000000 },
          { x: "2024", y: 580000000 },
          { x: "2025", y: 650000000 },
          { x: "2026", y: 750000000 }
        ];
      case "turnover":
        return [
          { x: "2022", y: 17500000 },
          { x: "2023", y: 20000000 },
          { x: "2024", y: 22500000 },
          { x: "2025", y: 25000000 },
          { x: "2026", y: 28750000 }
        ];
      case "profit":
        return [
          { x: "2022", y: 4812500 },
          { x: "2023", y: 5500000 },
          { x: "2024", y: 6187500 },
          { x: "2025", y: 6875000 },
          { x: "2026", y: 7900000 }
        ];
    }
  };

  /**
   * Resolve colors that should be used for the given output goal graph.
   * @returns { Array<string> } Contains the used colors in hex
   */
  resolveColors = () => {
    const { type } = this.props;
    switch (type) {
      case "bottles":
        return ["#ff8080", "#ff6060", "#ff4040", "#ff2020", "#ff0000"];
      case "capsules":
        return ["#9b9b9b", "#828282", "#737373", "#646464", "#555555"];
      case "turnover":
        return ["#8080a8", "#6060a8", "#4040a8", "#2020a8", "#0000a8"];
      case "profit":
        return ["#80a880", "#60a860", "#40a840", "#20a820", "#00a800"];
    }
  };

  render() {
    const { type } = this.props;
    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">Output Goals - {_.upperFirst(type)}</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit p-3">
          <Chart series={this.series} type="bar" options={this.options} heigth="325" width="100%" />
        </div>
      </div>
    );
  }
}

export default OutputGoals;
