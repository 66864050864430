import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import ExternalManufacturersCommodities from "./ExternalManufacturersCommodities";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";

interface EmCommoditiesWrapperProps extends RouteComponentProps {}

const EmCommoditiesWrapper: React.FunctionComponent<EmCommoditiesWrapperProps> = props => {
  const context = useContext(ExternalManufacturerContext);
  return <ExternalManufacturersCommodities {...props} context={context} />;
};

export default EmCommoditiesWrapper;
