import { BSON } from "realm-web";
import { Content, ContentDetails, ExtendedOrderSnapshot, PersonSnapshot } from "./common.types";
import { LocationWarehouseSnapshot } from "./batch.types";
import { NumValue } from "../common.types";

export enum ReservationState {
  OPEN = "open",
  DONE = "done",
  CANCELED = "canceled"
}

export enum ReservationTimelineType {
  RESERVATIONCREATED = "reservationCreated",
  BATCHESUPDATED = "batchesUpdated",
  BATCHBOOKEDOUT = "batchBookedOut",
  RESERVATIONCANCELED = "reservationCanceled"
}

export interface Reservation {
  _id: BSON.ObjectId; // unique id
  identifier: string;
  state: ReservationState;
  created: Date;
  person: PersonSnapshot;
  materials: Array<ReservedMaterial>;
  order: ExtendedOrderSnapshot;
  timeline: Array<ReservationTimelineEntry>;
}

export interface ReservedMaterial {
  _id: BSON.ObjectId;
  material: ReservationMaterialContent;
  warehouseSnapshot: LocationWarehouseSnapshot;
  reservedAmount: NumValue;
}

export interface ReservationTimelineEntry {
  _id: BSON.ObjectId;
  type: ReservationTimelineType;
  date: Date;
  person: PersonSnapshot;
  payload: object;
  diff: { pre: object; post: object };
}

export interface ReservationMaterialContent extends Omit<Content, "details"> {
  details: ReservationMaterialContentDetails;
}

export interface ReservationMaterialContentDetails
  extends Pick<ContentDetails, "_id" | "articleNo" | "title" | "subtitle"> {}
