import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import Select from "react-select";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";
import { PaginationState } from "../../common/CustomTypes";
import baseUtils, { getComponentState } from "../../../utils/baseUtils";
import HistoryBackButton from "../../listings/common/HistoryBackButton";
import { SearchBar } from "../../listings/common/Filters";
import SplashScreen from "../../common/SplashScreen";
import BaseListing from "../../listings/BaseListing";
import { paginate } from "../../common/Pagination";
import ExternalManufacturerHelper, {
  EM_ACCEPTED,
  EM_ARRIVED,
  EM_CANCELED,
  EM_CHECKED,
  EM_DELIVERED,
  EM_EXPIRED,
  EM_FINISHED,
  EM_OPEN,
  EM_ORDERED
} from "../ExternalManufacturerHelper";
import { ExtendedEMOrder } from "../../../model/customTypes.types";
import DateInput from "../../common/DateInput";
import EMOrdersRow from "./EMOrdersRow";
import dateUtils from "../../../utils/dateUtils";

interface ExternalManufacturerOrdersProps extends RouteComponentProps<{}, {}, {}> {
  context: React.ContextType<typeof ExternalManufacturerContext>;
}

interface ExternalManufacturerOrdersState extends PaginationState {
  search: string;
  status: "" | { value: string; label: string };
  hideCanceled: boolean;
  hideExpired: boolean;
  period: { start: Date; end: Date };
  orders: Array<ExtendedEMOrder>;
}

const CONSTRUCTORNAME = "ExternalManufacturerOrder";

class ExternalManufacturerOrders extends PureComponent<
  ExternalManufacturerOrdersProps,
  ExternalManufacturerOrdersState
> {
  constructor(props: ExternalManufacturerOrdersProps) {
    super(props);
    const orders = this.getExtendedOrders(props);
    this.state = {
      pageSize: 10,
      currentPage: 1,
      search: "",
      status: "",
      hideCanceled: true,
      hideExpired: true,
      period: dateUtils.getDefaultPeriod(),
      orders
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, CONSTRUCTORNAME);
    if (state) this.setState({ ...state });
  }

  componentDidUpdate(
    prevProps: Readonly<ExternalManufacturerOrdersProps>,
    prevState: Readonly<ExternalManufacturerOrdersState>,
    snapshot?: any
  ) {
    if (!_.isEqual(this.props.context.externalManufacturerOrders, prevProps.context.externalManufacturerOrders)) {
      this.setState({ orders: this.getExtendedOrders(this.props) });
    }
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(CONSTRUCTORNAME, _.omit(this.state, "orders"));
  }

  handleSelectChange = (entry: "" | { value: string; label: string }) =>
    this.setState({ status: entry ? entry : "", currentPage: 1 });

  handleFilter = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value, currentPage: 1 });
  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleReset = () =>
    this.setState({
      pageSize: 10,
      currentPage: 1,
      search: "",
      status: "",
      hideExpired: true,
      hideCanceled: true,
      period: dateUtils.getDefaultPeriod()
    });
  handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const period = _.cloneDeep(this.state.period);
    const type = e.target.name as "start" | "end";
    let value = e.target.valueAsDate;
    if (!value) return;
    if (type === "start") value.setHours(0, -value.getTimezoneOffset(), 0, 0);
    else value.setHours(23, 59 + -value.getTimezoneOffset(), 59, 999);
    _.set(period, type, value);
    this.setState({ period, currentPage: 1 });
  };

  /**
   * Get extended orders with commodity
   * @param props the components properties
   * @returns {Array<ExtendedEMOrder>} list of extended orders
   */
  getExtendedOrders = (props: ExternalManufacturerOrdersProps) => {
    const { context } = props;
    const { externalManufacturerOrders, commodities } = context;
    let extendedOrders: Array<ExtendedEMOrder> = [];
    for (let i = 0; i < externalManufacturerOrders.length; i++) {
      const emo = _.cloneDeep(externalManufacturerOrders[i]) as ExtendedEMOrder;
      const commodity = baseUtils.getDocFromCollection(commodities, emo.commodityId);
      emo.commodity = commodity ? ExternalManufacturerHelper.getExtendedCommodity(commodity, context) : null;
      extendedOrders.push(emo);
    }
    return extendedOrders;
  };

  /**
   * Get filtered orders
   * @returns { Array<ExternalManufacturerOrders> } filtered orders
   */
  getFilteredOrders = () => {
    const { search, status, orders, period, hideExpired, hideCanceled } = this.state;
    let filteredOrders = orders.filter(o => period.start <= o.createdOn && o.createdOn <= period.end);
    if (status) filteredOrders = filteredOrders.filter(emo => emo.state === status.value);
    if (hideExpired && (!status || status.value !== EM_EXPIRED))
      filteredOrders = filteredOrders.filter(emo => emo.state !== EM_EXPIRED);
    if (hideCanceled && (!status || status.value !== EM_CANCELED))
      filteredOrders = filteredOrders.filter(emo => emo.state !== EM_CANCELED);
    if (search.trim() === "") return filteredOrders;
    return baseUtils.doFuseSearch(filteredOrders, search, [
      "reference",
      "identifier",
      "commodity.title.en",
      "commodity.subtitle.en",
      "commodity.category.name.en",
      "commodity.form.name.en",
      "commodity.composition.name.de",
      "note",
      "state",
      "amount"
    ]);
  };

  render() {
    const { context, history } = this.props;
    const { externalManufacturerOrders } = context;
    const { pageSize, currentPage, search, status, period, hideCanceled, hideExpired } = this.state;
    const orders = this.getFilteredOrders();
    const headerDefinition = [
      { title: "Order", size: 15 },
      { title: "Commodity", size: 20 },
      { title: "Status", size: 7 },
      { title: "Amount", size: 10 },
      { title: "Created", size: 8 },
      { title: "Price", size: 10 },
      { title: "Expected Delivery", size: 10 },
      { title: "Limit Kilo Price", size: 10 },
      { title: "Latest Delivery Date", size: 10 }
    ];

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand flaticon-layer" />
            </span>
            <h3 className="kt-portlet__head-title">Requests & Orders</h3>
            <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
              Reset
            </button>
          </div>
          <HistoryBackButton history={history} />
        </div>
        <div className="kt-portlet__body pb-0">
          <div className="kt-form kt-form--label-right kt-margin-b-10">
            <div className="row align-items-center">
              <SearchBar onSearch={this.handleFilter} search={search} name={"search"} />
              <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                <Select
                  className="select-default"
                  isClearable={true}
                  options={[
                    EM_OPEN,
                    EM_CHECKED,
                    EM_ACCEPTED,
                    EM_ORDERED,
                    EM_DELIVERED,
                    EM_CANCELED,
                    EM_ARRIVED,
                    EM_FINISHED,
                    EM_EXPIRED
                  ].map(status => {
                    return {
                      value: status,
                      label: _.upperFirst(status)
                    };
                  })}
                  value={status ? status : { value: "", label: "All States" }}
                  onChange={(value: any) => this.handleSelectChange(value || "")}
                />
              </div>
              <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                <DateInput value={period.start} onBlur={this.handlePeriodChange} name={"start"} />
              </div>
              <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                <DateInput value={period.end} onBlur={this.handlePeriodChange} name={"end"} />
              </div>
            </div>
            <div className="row align-items-center kt-margin-t-10">
              <div className="col-md-12 kt-margin-b-20-tablet-and-mobile">
                {status === "" && (
                  <div className="float-right">
                    <label className="kt-checkbox kt-font-dark m-0 p-0 px-4">
                      <input
                        type="checkbox"
                        checked={hideCanceled}
                        onChange={() => this.setState({ hideCanceled: !hideCanceled })}
                      />
                      <span />
                      <div className="pl-1 kt-font-dark">Hide canceled</div>
                    </label>
                    <label className="kt-checkbox kt-font-dark m-0 p-0 px-4">
                      <input
                        type="checkbox"
                        checked={hideExpired}
                        onChange={() => this.setState({ hideExpired: !hideExpired })}
                      />
                      <span />
                      <div className="pl-1 kt-font-dark">Hide expired</div>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          {externalManufacturerOrders.length === 0 ? (
            <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
          ) : (
            <BaseListing
              headerDefinition={headerDefinition}
              documents={orders}
              bodyContent={
                <>
                  {paginate(orders, currentPage, pageSize).map(o => (
                    <EMOrdersRow key={o._id.toString()} order={o} {...this.props} />
                  ))}
                </>
              }
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ExternalManufacturerOrders;
