import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Table } from "react-bootstrap";
import i18n from "../../../translations/i18n";
import HistoryBackButton from "../../listings/common/HistoryBackButton";
import { SearchBar } from "../../listings/common/Filters";
import Pagination, { paginate } from "../../common/Pagination";
import baseUtils from "../../../utils/baseUtils";
import { useWarehouseContext } from "../../../context/warehouseContext";
import CreateStorageSpacesModal from "./modals/CreateStorageSpacesModal";
import {
  PhysicalWarehouse,
  StorageSpace,
  WarehouseDefinition
} from "../../../model/configuration/warehouseConfiguration.types";
import { resolveTranslation } from "../../../utils/translationUtils";
import { useDataContext } from "../../../context/dataContext";
import { ModalMode } from "../../../model/common.types";

interface StorageSpacesSettingsProps extends RouteComponentProps {}

interface StorageSpacesWithWarehouse {
  storageSpace: StorageSpace;
  warehouse: WarehouseDefinition;
  warehouseArea: PhysicalWarehouse;
}

const StorageSpacesSettings: React.FunctionComponent<StorageSpacesSettingsProps> = ({ history }) => {
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();

  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({ pageSize: 10, currentPage: 1 });
  const storageSpacesMap = useMemo(() => {
    const stSp: Array<StorageSpacesWithWarehouse> = [];
    if (warehouseContext.configuration) {
      for (let i = 0; i < warehouseContext.configuration.values.warehouseStructure.length; i++) {
        const wh = warehouseContext.configuration.values.warehouseStructure[i];
        for (let j = 0; j < wh.physicalWarehouses.length; j++) {
          const pwh = wh.physicalWarehouses[j];
          if (pwh.storageSpaces) {
            for (let k = 0; k < pwh.storageSpaces.length; k++) {
              stSp.push({ storageSpace: pwh.storageSpaces[k], warehouse: wh, warehouseArea: pwh });
            }
          }
        }
      }
      return stSp;
    }
  }, [warehouseContext.configuration?.values.warehouseStructure]);

  const searchResult = useMemo(() => {
    const ql = search.trim().toLowerCase();
    return ql !== ""
      ? _.cloneDeep(
          baseUtils.doFuseSearch<StorageSpacesWithWarehouse>(storageSpacesMap ?? [], ql, [
            "storageSpace.storageSpaceNo",
            "warehouse.warehouseName.de",
            "warehouse.shortName",
            "warehouseArea.warehouseName.de",
            "warehouseArea.shortName"
          ])
        )
      : _.cloneDeep(storageSpacesMap ?? []);
  }, [search, storageSpacesMap]);

  const storageSpaces = useMemo(() => {
    return paginate(searchResult, pagination.currentPage, pagination.pageSize);
  }, [searchResult, pagination]);

  const handleSearch = useCallback(e => {
    setSearch(e.target.value);
    setPagination(prevState => {
      return { ...prevState, currentPage: 1 };
    });
  }, []);

  const handleChangePage = useCallback(page => {
    setPagination(prevState => {
      return { ...prevState, currentPage: page };
    });
  }, []);

  const handleChangePageSize = useCallback(pageSize => {
    setPagination({ pageSize, currentPage: 1 });
  }, []);

  /**
   * Check if packaging unit is used somewhere in batch, reservation and other when implemented later
   */
  const inUseMap = useMemo(() => {
    const usedStSp: {
      [key: string]: boolean;
    } = {};
    const flatBatch = dataContext.batch.flatMap(b => b.locations.flatMap(l => l.location.storageSpace));
    if (storageSpacesMap) {
      for (let i = 0; i < storageSpacesMap.length; i++) {
        const stSp = storageSpacesMap[i];
        usedStSp[stSp.storageSpace._id.toString()] = flatBatch.some(
          stSpSnap => stSpSnap?._id.toString() === stSp.storageSpace._id.toString()
        );
      }
    }
    return usedStSp;
  }, [dataContext.batch, dataContext.reservation, storageSpacesMap]);

  return (
    <>
      <div className="alert alert-light alert-elevate" style={{ backgroundColor: "white" }}>
        <div className="alert-icon">
          <i className="flaticon-warning kt-font-danger" />
        </div>
        <div className="alert-text">{i18n.t("warehouse:storageSpaceConfigWarning")}</div>
      </div>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">{i18n.t("warehouse:storageSpaces")}</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <HistoryBackButton history={history} text={i18n.t("common:back")} />
              <CreateStorageSpacesModal mode={ModalMode.CREATE} />
            </div>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-form kt-form--label-right">
            <div className="row align-items-center">
              <SearchBar search={search} onSearch={handleSearch} additionalSizeClasses={"col-md-2"} />
              <div className="col-xl-4 order-1 order-xl-2 kt-align-right">
                <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg d-xl-none" />
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded px-4">
            <Table>
              <thead>
                <tr>
                  <th style={{ width: "15%" }}>{i18n.t("warehouse:storageSpace")}</th>
                  <th style={{ width: "15%" }}>{i18n.t("warehouse:warehouse")}</th>
                  <th style={{ width: "15%" }}>{i18n.t("warehouse:warehouseArea")}</th>
                  <th style={{ width: "15%" }}>{i18n.t("warehouse:lwh")}</th>
                  <th style={{ width: "10%" }}>{i18n.t("packaging:shape")}</th>
                  <th style={{ width: "10%" }}>{i18n.t("warehouse:maxWeight")}</th>
                  <th className="text-right" style={{ width: "20%" }}>
                    {i18n.t("common:actions")}
                  </th>
                </tr>
              </thead>
              <tbody className="kt-datatable__body">
                {storageSpaces &&
                  storageSpaces.map(stSp => {
                    return (
                      <tr key={stSp.storageSpace._id.toString()}>
                        <td className="align-middle">{stSp.storageSpace.storageSpaceNo}</td>
                        <td className="align-middle">{`${resolveTranslation(stSp.warehouse.warehouseName)} - ${
                          stSp.warehouse.shortName
                        }`}</td>
                        <td className="align-middle">{`${resolveTranslation(stSp.warehouseArea.warehouseName)} - ${
                          stSp.warehouseArea.shortName
                        }`}</td>
                        <td className="align-middle">
                          {stSp.storageSpace.dimensions
                            ? `${stSp.storageSpace.dimensions.length} x ${stSp.storageSpace.dimensions.width} x ${stSp.storageSpace.dimensions.height} ${stSp.storageSpace.dimensions.unit}`
                            : "-"}
                        </td>
                        <td className="align-middle">
                          {stSp.storageSpace.dimensions
                            ? i18n.t(`warehouse:${stSp.storageSpace.dimensions.shape}`)
                            : "-"}
                        </td>
                        <td className="align-middle">{`${stSp.storageSpace.maxWeight?.value ?? "-"} ${
                          stSp.storageSpace.maxWeight?.unit ?? ""
                        }`}</td>
                        <td className="text-right align-middle">
                          <span className="mr-2">
                            <CreateStorageSpacesModal
                              mode={ModalMode.EDIT}
                              storageSpaceEdit={stSp.storageSpace}
                              warehouse={stSp.warehouse}
                              warehouseArea={stSp.warehouseArea}
                              isInUse={inUseMap[stSp.storageSpace._id.toString()]}
                            />
                          </span>
                          <CreateStorageSpacesModal
                            mode={ModalMode.DELETE}
                            storageSpaceEdit={stSp.storageSpace}
                            warehouse={stSp.warehouse}
                            warehouseArea={stSp.warehouseArea}
                            isInUse={inUseMap[stSp.storageSpace._id.toString()]}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div className="kt-datatable__pager kt-datatable--paging-loaded justify-content-center">
              <Pagination
                itemsCount={storageSpacesMap ? storageSpacesMap.length : 0}
                pageSize={pagination.pageSize}
                onPageChange={handleChangePage}
                currentPage={pagination.currentPage}
                onPageSizeChange={handleChangePageSize}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StorageSpacesSettings;
