import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic";
import { Forecast } from "../../../model/commodities.types";

interface ForecastModalProps {
  name: string;
  onSave: ((forecast: Forecast) => void) | ((forecast: Forecast, idx: number) => void);
  forecast?: Forecast;
  idx?: number;
  disabled?: boolean;
}

interface ForecastModalState {
  date: string;
  direction: "neutral" | "up" | "down";
  reasonEn: string;
  reasonDe: string;
  show: boolean;
}

class ForecastModal extends PureComponent<ForecastModalProps, ForecastModalState> {
  constructor(props: ForecastModalProps) {
    super(props);
    this.state = this.getDefaultState(props.forecast);
  }

  /**
   * Return the default state for the forecast modal.
   * @returns { show: boolean, date: string, direction: "neutral" | "up" | "down", reasonEn: string, reasonDe: string}
   * Default state.
   */
  getDefaultState = (forecast?: Forecast) => {
    // Needed to define the type. Else there is a type mismatch between the here returned state and the state definition
    const direction: "neutral" | "up" | "down" = forecast ? forecast.forecast : "neutral";
    return {
      show: false,
      date: (forecast ? forecast.date : new Date()).toISOString().split("T")[0],
      direction,
      reasonEn: forecast ? forecast.reason.en : "",
      reasonDe: forecast ? forecast.reason.de : ""
    };
  };

  componentDidUpdate(prevProps: Readonly<ForecastModalProps>, prevState: Readonly<ForecastModalState>, snapshot?: any) {
    const { forecast } = this.props;
    if (!prevState.show && this.state.show && forecast) {
      this.setState({
        date: forecast.date.toISOString().split("T")[0],
        direction: forecast.forecast,
        reasonEn: forecast.reason.en,
        reasonDe: forecast.reason.de
      });
    }
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState(this.getDefaultState());

  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * Handles saving a new forecast.
   */
  handleSave = () => {
    const { onSave, idx } = this.props;
    const { date, direction, reasonDe, reasonEn } = this.state;
    const forecast = { forecast: direction, reason: { de: reasonDe, en: reasonEn }, date: new Date(date) };
    onSave(forecast, idx ? idx : 0);
    this.setState(this.getDefaultState());
  };

  render() {
    const { forecast, name, disabled } = this.props;
    const { date, direction, reasonDe, reasonEn, show } = this.state;
    const edit = !!forecast;
    return (
      <>
        {edit ? (
          <button
            className={"btn btn-secondary " + (disabled && "disabled")}
            disabled={disabled}
            onClick={disabled ? undefined : this.handleShow}
          >
            <i className="fa fa-pen pr-0" />
          </button>
        ) : (
          <div className="text-right">
            <button
              className={"btn btn-success " + (disabled && "disabled")}
              disabled={disabled}
              onClick={disabled ? undefined : this.handleShow}
            >
              <i className="fa fa-plus pr-0" />
            </button>
          </div>
        )}
        <Modal centered show={show} onHide={this.handleHide}>
          <Modal.Header closeButton>
            <Modal.Title>{(edit ? "Edit forecast of " : "Add new forecast for ") + name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Date</label>
              <div className="col-9">
                <input type="date" className="form-control" name="date" value={date} onChange={this.handleChange} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Direction</label>
              <div className="col-9">
                <select className="form-control" name="direction" value={direction} onChange={this.handleChange}>
                  <option value="neutral">Neutral</option>
                  <option value="up">Up</option>
                  <option value="down">Down</option>
                </select>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">
                Reason
                <img src={toAbsoluteUrl("/media/icons/icon-en.png")} className="country-icon2 ml-1" alt="en" />
              </label>
              <div className="col-9">
                <textarea className="form-control" name="reasonEn" value={reasonEn} onChange={this.handleChange} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">
                Reason
                <img src={toAbsoluteUrl("/media/icons/icon-de.png")} className="country-icon2 ml-1" alt="de" />
              </label>
              <div className="col-9">
                <textarea className="form-control" name="reasonDe" value={reasonDe} onChange={this.handleChange} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            <button className="btn btn-success" onClick={this.handleSave}>
              Save changes
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ForecastModal;
