import React, { PureComponent } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BSON } from "realm-web";
import { ExtendedEMOrder } from "../../../model/customTypes.types";
import { ExternalManufacturerOrdersDocument } from "../../../model/externalManufacturerOrders.types";
import { DataContext } from "../../../context/dataContext";
import DateInput from "../../common/DateInput";
import baseUtils from "../../../utils/baseUtils";
import ExternalManufacturerHelper, { EM_CHECKED, EM_OPEN } from "../ExternalManufacturerHelper";
import dbService, { EMORDERS } from "../../../services/dbService";
import toastUtils from "../../../utils/toastUtils";
import userService from "../../../services/userService";
import EMCommodityWidget from "../EMCommodityWidget";

interface UpdatePriceModalProps {
  order: ExtendedEMOrder | ExternalManufacturerOrdersDocument;
  small?: boolean;
}

interface UpdatePriceModalState {
  show: boolean;
  saving: boolean;
  price: string;
  delivery: Date | null;
}

class UpdatePriceModal extends PureComponent<UpdatePriceModalProps, UpdatePriceModalState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: UpdatePriceModalProps) {
    super(props);
    this.state = { show: false, price: "0", delivery: null, saving: false };
  }

  handleShow = (e: any) => {
    e.stopPropagation();
    this.setState({ show: true });
  };
  handleClose = () => {
    if (this.state.saving) return;
    this.setState({ show: false, price: "0", delivery: null });
  };

  handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replaceAll(/^0+/g, "0");
    if (!value.includes(".")) value = Number(value).toString();
    this.setState({ price: value });
  };
  handleDeliveryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val: string | number | Date = e.target.value;
    if (val) {
      this.setState({ delivery: new Date(val) });
      return;
    }
    this.setState({ delivery: null });
  };

  handleUpdateOrder = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const { order } = this.props;
    const { price, delivery } = this.state;
    const actions = [
      {
        collection: EMORDERS,
        filter: { _id: order._id },
        update: {
          state: EM_CHECKED,
          actualPrice: +price,
          actualDeliveryDate: delivery
        },
        push: {
          timeline: {
            _id: new BSON.ObjectId(),
            type: EM_CHECKED,
            date: new Date(),
            person: userService.getUserId()
          }
        }
      }
    ];
    const result = await dbService.updatesAsTransaction(actions);
    await toastUtils.databaseOperationToast(
      !!result,
      "Request updated successfully",
      "Request could not be updated",
      () => {
        this.context.updateDocumentInContext(EMORDERS, order._id);
        this.handleClose();
      }
    );
  };

  render() {
    const { order, small } = this.props;
    const { show, price, delivery, saving } = this.state;
    const commodity =
      "commodity" in order
        ? order.commodity
        : baseUtils.getDocFromCollection(this.context.commodities, order.commodityId);
    const extendedCommodity =
      "commodity" in order
        ? commodity
        : commodity
        ? ExternalManufacturerHelper.getExtendedCommodity(commodity, this.context)
        : null;

    return (
      <>
        <button
          className={small ? "btn btn-primary btn-sm py-1 px-2" : "btn btn-primary btn-sm btn-upper"}
          onClick={order.state === EM_OPEN ? this.handleShow : undefined}
        >
          Update Price
        </button>
        <Modal show={show} onHide={this.handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              Update Price for REQ-{order.identifier} <em>{order.reference}</em>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-info" role="alert">
              <div className="alert-icon">
                <i className="flaticon-warning" />
              </div>
              <div className="alert-text">
                Upon updating the price the request will be marked as <em>checked</em> and the external manufacturer
                will be able to accept the request.
              </div>
            </div>
            <div className="form-group row my-2">
              <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Commodity</label>
              <div className="col-8 align-self-center">
                <EMCommodityWidget commodity={extendedCommodity} />
              </div>
            </div>
            <div className="form-group row mb-2">
              <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Amount</label>
              <div className="col-8 align-self-center">
                <span className={"font-weight-bold text-dark"}>{order.amount} kg</span>
              </div>
            </div>
            {order.priceLimit && (
              <div className="form-group row mb-2">
                <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Price Limit</label>
                <div className="col-8 align-self-center">
                  <span className={"font-weight-bold text-dark"}>{baseUtils.formatEuro(order.priceLimit)}/kg</span>
                </div>
              </div>
            )}
            {order.deliveryDateLimit && (
              <div className="form-group row mb-2">
                <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Delivery Limit</label>
                <div className="col-8 align-self-center">
                  <span className={"font-weight-bold text-dark"}>{baseUtils.formatDate(order.deliveryDateLimit)}</span>
                </div>
              </div>
            )}
            {order.note && (
              <div className="form-group row mb-2">
                <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Note</label>
                <div className="col-8 align-self-center">
                  <span className="text-dark">{order.note}</span>
                </div>
              </div>
            )}
            <div className="form-group row mb-2">
              <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Price</label>
              <div className="col-8">
                <div className="input-group">
                  <OverlayTrigger
                    show={!(price && order.priceLimit && +price > order.priceLimit) ? false : undefined}
                    overlay={
                      <Tooltip id="priceWarning">
                        {price && order.priceLimit && (
                          <span>
                            Price of {baseUtils.formatEuro(+price)} exceeds limit of{" "}
                            {baseUtils.formatEuro(order.priceLimit)}
                          </span>
                        )}
                      </Tooltip>
                    }
                  >
                    <input
                      className={
                        "form-control " + (price && order.priceLimit && +price > order.priceLimit ? "is-invalid" : "")
                      }
                      type="number"
                      value={price}
                      name="price"
                      onChange={this.handlePriceChange}
                    />
                  </OverlayTrigger>
                  <div className="input-group-append">
                    <span className="input-group-text">€/kg</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row mb-2">
              <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Est. Delivery</label>
              <div className="col-8">
                <OverlayTrigger
                  show={
                    !(delivery && order.deliveryDateLimit && delivery > order.deliveryDateLimit) ? false : undefined
                  }
                  overlay={
                    <Tooltip id="deliveryWarning">
                      {delivery && order.deliveryDateLimit && (
                        <span>
                          Delivery date of {baseUtils.formatDate(delivery)} exceeds limit of{" "}
                          {baseUtils.formatDate(order.deliveryDateLimit)}
                        </span>
                      )}
                    </Tooltip>
                  }
                >
                  <div>
                    <DateInput
                      classes={
                        "form-control " +
                        (delivery && order.deliveryDateLimit && delivery > order.deliveryDateLimit ? "is-invalid" : "")
                      }
                      value={delivery}
                      onBlur={this.handleDeliveryChange}
                      name={"delivery"}
                      allowClear={true}
                    />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <button
              className={"btn btn-success " + (saving ? "disabled" : "")}
              disabled={saving}
              onClick={saving ? undefined : this.handleUpdateOrder}
            >
              Update Price
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default UpdatePriceModal;
