import _ from "lodash";
import { BSON } from "realm-web";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "react-bootstrap";
import { CustomOrder, ExtendedCommoditySupplier } from "../../CustomTypes";
import { CommoditiesDocument } from "../../../../model/commodities.types";
import { calculation, pricingCommodities } from "../../../../model/orders.types";
import OrderHelper, { T_CUSTOM, T_SERVICE, T_SOFTGEL } from "../../OrderHelper";
import calculationUtils from "../../../../utils/calculationUtils";
import { useDataContext } from "../../../../context/dataContext";
import orderUtils from "../../../../utils/orderUtils";
import { INCOTERMS } from "../../../../utils/suppliersUtils";
import orderCalculationUtils from "../../../../utils/orderCalculationUtils";
import baseUtils, { getNumericValue } from "../../../../utils/baseUtils";

interface RawbidsCommoditiesCheckProps {
  order: CustomOrder;
  commodities: Array<CommoditiesDocument>;
  rawbidsSupplierId: string | null;
  calculations: Array<calculation>;
  onCalculationChange: (calculation: calculation, priceId: string | BSON.ObjectId) => void;
}

const headerDefinition = [
  { title: "Amount", size: 20 },
  { title: "Commodity", size: 50 },
  { title: "Requested", size: 15 },
  { title: "Updated", size: 15 }
];

const RawbidsCommoditiesCheck: React.FunctionComponent<RawbidsCommoditiesCheckProps> = ({
  order,
  commodities,
  rawbidsSupplierId,
  calculations,
  onCalculationChange
}) => {
  return (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--subtable kt-datatable--loaded table-responsive">
      <table className="kt-datatable__table d-table ">
        <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
          <tr className="kt-datatable__row d-table-row">
            {headerDefinition.map(def => (
              <th
                key={def.title + def.size}
                className={"kt-datatable__cell d-table-cell"}
                style={{ width: `${def.size}%` }}
              >
                <span>{def.title}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
          {order.calculations[0].prices.map(price => {
            const commodity = commodities.find(c => c._id.toString() === price._id.toString());
            if (!commodity) return null;
            return (
              <RawbidsCommoditiesCheckItem
                key={price._id.toString()}
                order={order}
                price={price}
                commodity={commodity}
                rawbidsSupplierId={rawbidsSupplierId}
                calculations={calculations}
                onCalculationChange={onCalculationChange}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

interface RawbidsCommoditiesCheckItemProps {
  order: CustomOrder;
  price: pricingCommodities;
  commodity: CommoditiesDocument;
  rawbidsSupplierId: string | null;
  calculations: Array<calculation>;
  onCalculationChange: (calculation: calculation, priceId: string | BSON.ObjectId) => void;
}

const RawbidsCommoditiesCheckItem: React.FunctionComponent<RawbidsCommoditiesCheckItemProps> = ({
  order,
  price,
  commodity,
  rawbidsSupplierId,
  calculations,
  onCalculationChange
}) => {
  const [showDetails, setShowDetails] = useState(true);

  const handleToggleDetails = useCallback(() => {
    setShowDetails(prevShowDetails => !prevShowDetails);
  }, []);

  return (
    <>
      <tr className="kt-datatable__row d-table-row material-row">
        <td className=" kt-datatable__cell d-table-cell" onClick={handleToggleDetails}>
          <span>
            {[T_CUSTOM, T_SERVICE, T_SOFTGEL].includes(order.settings.type)
              ? price.amount + " pcs."
              : calculationUtils.formatAmount(price.amount, 2)}
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell" onClick={handleToggleDetails}>
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <Link className="kt-user-card-v2__name kt-link" to={"/commodity/" + commodity._id.toString()}>
                {commodity.title.en}
                <span className="text-success"> (ID: {commodity.identifier})</span>
              </Link>
              <span className="kt-user-card-v2__email">{commodity.subtitle.en}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell" onClick={handleToggleDetails}>
          <span>
            <label className="kt-checkbox kt-checkbox--single checkbox-dark my-auto">
              <input type="checkbox" disabled={true} checked={true} />
              <span />
            </label>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell" onClick={handleToggleDetails}>
          <span>
            <label className="kt-checkbox kt-checkbox--single checkbox-dark my-auto">
              <input type="checkbox" disabled={true} checked={true} />
              <span />
            </label>
          </span>
        </td>
      </tr>
      <tr className={showDetails ? "kt-datatable__row d-table-row " : "d-none"}>
        <td colSpan={5}>
          <div
            className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded"
            style={{ boxShadow: "none" }}
          >
            <table className="kt-datatable__table d-table">
              <tbody className="kt-datatable__body " style={{ backgroundColor: "#fafafa", display: "table-row-group" }}>
                {calculations.map(c => (
                  <RawbidsCommoditiesCheckItemDetails
                    key={c.id.toString()}
                    calculation={c}
                    order={order}
                    price={price}
                    commodity={commodity}
                    rawbidsSupplierId={rawbidsSupplierId}
                    onCalculationChange={onCalculationChange}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </>
  );
};

interface RawbidsCommoditiesCheckItemDetailsProps {
  calculation: calculation;
  order: CustomOrder;
  price: pricingCommodities;
  commodity: CommoditiesDocument;
  rawbidsSupplierId: string | null;
  onCalculationChange: (calculation: calculation, priceId: string | BSON.ObjectId) => void;
}

const RawbidsCommoditiesCheckItemDetails: React.FunctionComponent<RawbidsCommoditiesCheckItemDetailsProps> = ({
  calculation,
  order,
  price,
  commodity,
  rawbidsSupplierId,
  onCalculationChange
}) => {
  const dataContext = useDataContext();
  const { suppliers } = dataContext;

  const [extendedRBSupplier, setExtendedRBSupplier] = useState<ExtendedCommoditySupplier | undefined>(undefined);
  const [priceObject, setPriceObject] = useState<pricingCommodities | undefined>(undefined);

  useEffect(() => {
    const rbSupplier = suppliers.find(s => s._id.toString() === rawbidsSupplierId);
    const rbCommoditySupplier = commodity.suppliers.find(s => s._id.toString() === rawbidsSupplierId);
    if (!rbSupplier || !rbCommoditySupplier) return;
    const extendedRBSupplier = {
      _id: rbCommoditySupplier._id,
      prices: rbCommoditySupplier.prices,
      ...rbSupplier
    } as ExtendedCommoditySupplier;
    setExtendedRBSupplier(extendedRBSupplier);
  }, [commodity, suppliers, rawbidsSupplierId]);

  useEffect(() => {
    const priceObject = calculation.prices.find(p => p._id.toString() === price._id.toString());
    setPriceObject(priceObject);
  }, [calculation.prices, price]);

  const handleOrderQuantityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedCalculation = _.cloneDeep(calculation);
      const tmpPrice = updatedCalculation.prices.find(p => p._id.toString() === price._id.toString());
      if (!tmpPrice) return;
      const newOrderquantity = getNumericValue(e, false);
      _.set(tmpPrice, e.currentTarget.name, Number(newOrderquantity));
      setPriceObject(tmpPrice);
    },
    [calculation, price]
  );

  const handleOrderQuantityBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const updatedCalculation = _.cloneDeep(calculation);
      const updatedPriceObject = updatedCalculation.prices.find(p => p._id.toString() === price._id.toString());
      const newOrderQuantity = getNumericValue(e, false);
      if (!updatedPriceObject || !newOrderQuantity) return;
      const commodityPrice = orderCalculationUtils.getMatchingCommodityPrice(
        commodity,
        order.settings.manufacturer,
        Number(newOrderQuantity),
        extendedRBSupplier?._id ?? updatedPriceObject.supplier
      );
      orderUtils.updatePriceObject(updatedPriceObject, commodityPrice, Number(newOrderQuantity));
      onCalculationChange(updatedCalculation, price._id);
    },
    [calculation, price, commodity, order.settings.manufacturer, extendedRBSupplier]
  );

  const handleIncotermChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const updatedCalculation = _.cloneDeep(calculation);
      const updatedPriceObject = updatedCalculation.prices.find(p => p._id.toString() === price._id.toString());
      if (!updatedPriceObject || !updatedPriceObject.orderquantity) return;
      const quantity = updatedPriceObject.orderquantity;
      const targetIncoterm = e.currentTarget.value;
      const commodityPrice = orderCalculationUtils.getMatchingCommodityPrice(
        commodity,
        order.settings.manufacturer,
        quantity,
        extendedRBSupplier?._id ?? updatedPriceObject.supplier,
        targetIncoterm
      );
      if (commodityPrice.price.incoterm !== targetIncoterm) {
        toast.error("No matching price could be found for incoterm: " + targetIncoterm);
        return;
      }
      orderUtils.updatePriceObject(updatedPriceObject, commodityPrice, quantity);
      onCalculationChange(updatedCalculation, price._id);
    },
    [calculation, price, commodity, order.settings.manufacturer, extendedRBSupplier]
  );

  const handleDeliveryTimeChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const updatedCalculation = _.cloneDeep(calculation);
      const updatedPriceObject = updatedCalculation.prices.find(p => p._id.toString() === price._id.toString());
      if (!updatedPriceObject || !updatedPriceObject.orderquantity) return;
      const quantity = updatedPriceObject.orderquantity;
      const targetDeliveryTime = +e.currentTarget.value;
      const commodityPrice = orderCalculationUtils.getMatchingCommodityPrice(
        commodity,
        order.settings.manufacturer,
        quantity,
        extendedRBSupplier?._id ?? updatedPriceObject.supplier,
        updatedPriceObject.incoterm,
        targetDeliveryTime
      );
      if (commodityPrice.price.deliverytime !== targetDeliveryTime) {
        toast.error("No matching price could be found for the selected delivery time: " + targetDeliveryTime);
        return;
      }
      orderUtils.updatePriceObject(updatedPriceObject, commodityPrice, quantity);
      onCalculationChange(updatedCalculation, price._id);
    },
    [calculation, price, commodity, order.settings.manufacturer, extendedRBSupplier]
  );

  const totalAmount = useMemo(() => {
    return orderUtils.getTotalAmountWithBuffer(
      +order.settings.perUnit,
      +calculation.units,
      price.amount,
      price.buffer,
      order.settings.type
    );
  }, [order.settings, calculation.units, price]);

  const priceDifference = useMemo(() => {
    return priceObject ? (+priceObject.price / +priceObject.estimatedprice - 1) * 100 : 0;
  }, [priceObject]);

  const priceSupplier = useMemo(() => {
    return suppliers.find(s => s._id.toString() === priceObject?.supplier.toString());
  }, [suppliers, priceObject?.supplier]);

  const [availableIncoterms, deliveryTimesForIncoterm] = useMemo(() => {
    let incoterms = [...INCOTERMS];
    const incotermList: Array<string> = [];
    const deliveryTimesByIncoterm: { [incoterm: string]: Array<number> } = {};
    let deliveryTimesForIncoterm: Array<number> = [];

    if (priceSupplier && commodity.suppliers && priceObject) {
      const moq = orderCalculationUtils.getMatchingMOQ(commodity, priceSupplier._id, +priceObject.orderquantity!);
      const selectedSupplierPrices = commodity.suppliers.find(s => s._id.toString() === priceSupplier._id.toString());
      if (selectedSupplierPrices) {
        selectedSupplierPrices.prices.forEach(p => {
          if (moq >= p.moq) {
            if (!incotermList.includes(p.incoterm)) {
              deliveryTimesByIncoterm[p.incoterm] = [p.deliverytime];
              if (p.incoterm.trim() !== "") incotermList.push(p.incoterm);
            } else if (!deliveryTimesByIncoterm[p.incoterm].includes(p.deliverytime)) {
              deliveryTimesByIncoterm[p.incoterm].push(p.deliverytime);
            }
          }
          incoterms = incotermList;
        });
      }
    }
    if (priceObject && !_.isEmpty(deliveryTimesByIncoterm) && deliveryTimesByIncoterm[priceObject.incoterm])
      deliveryTimesForIncoterm = deliveryTimesByIncoterm[priceObject.incoterm];

    return [incoterms, deliveryTimesForIncoterm];
  }, [priceSupplier, commodity.suppliers, priceObject]);

  const unit = useMemo(() => {
    return OrderHelper.getQuantityUnitForType(order.settings.type);
  }, [order.settings.type]);

  const matchingMOQ = useMemo(() => {
    if (!extendedRBSupplier || !priceObject || !priceObject.orderquantity) return undefined;
    return orderCalculationUtils.getMatchingMOQ(commodity, extendedRBSupplier._id, +priceObject.orderquantity);
  }, [commodity, extendedRBSupplier, priceObject]);

  const invalidMOQ = useMemo(() => {
    if (!priceSupplier || !priceObject || !priceObject.orderquantity) return undefined;
    return (
      orderCalculationUtils.getMatchingMOQ(commodity, priceSupplier._id, +priceObject.orderquantity!) >
      priceObject.orderquantity
    );
  }, [commodity, priceSupplier, priceObject]);

  if (!priceObject) return null;

  return (
    <tr className="kt-datatable__row d-table-row">
      <td className=" kt-datatable__cell d-table-cell" style={{ width: "8%", paddingTop: 5, paddingBottom: 5 }}>
        <span>{calculation.units + " units"}</span>
      </td>
      <td className=" kt-datatable__cell d-table-cell" style={{ width: "8%", paddingTop: 5, paddingBottom: 5 }}>
        <span className="kt-font-bold ">{OrderHelper.getFormattedAmount(totalAmount, order.settings.type)}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell" style={{ width: "14%", paddingTop: 5, paddingBottom: 5 }}>
        <span>
          <div className="form-group " style={{ marginBottom: "0" }}>
            <div className="input-group input-group-sm">
              <input
                type="number"
                className="form-control"
                name="orderquantity"
                min={1}
                value={priceObject.orderquantity!.toString()}
                onChange={handleOrderQuantityChange}
                onBlur={handleOrderQuantityBlur}
              />
              <div className="input-group-append">
                <span className="input-group-text">{unit}</span>
              </div>
            </div>
          </div>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" style={{ width: "22%", paddingTop: 5, paddingBottom: 5 }}>
        <span>
          <select
            className="form-control"
            style={invalidMOQ ? { border: "1px solid red" } : {}}
            value={priceObject.supplier.toString()}
          >
            {extendedRBSupplier && matchingMOQ && (
              <option
                key={extendedRBSupplier._id.toString()}
                value={extendedRBSupplier._id.toString()}
                disabled={!priceObject.orderquantity || priceObject.orderquantity < matchingMOQ}
              >
                {baseUtils.truncateString(extendedRBSupplier.name, 18) +
                  ` MOQ ${OrderHelper.formatAmount(matchingMOQ, order.settings.type)}`}
              </option>
            )}
          </select>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" style={{ width: "10%", paddingTop: 5, paddingBottom: 5 }}>
        <span>
          <select
            className="form-control"
            value={priceObject.incoterm}
            onChange={handleIncotermChange}
            disabled={availableIncoterms.length === 0 || invalidMOQ}
          >
            <option disabled key={""} value={""}>
              not set
            </option>
            {availableIncoterms.map(incoterm => (
              <option key={incoterm} value={incoterm}>
                {incoterm}
              </option>
            ))}
            {!availableIncoterms.includes(priceObject.incoterm) && priceObject.incoterm.trim() !== "" && (
              // Add current incoterm if it isnt included anymore
              <option key={priceObject.incoterm} value={priceObject.incoterm}>
                {priceObject.incoterm}
              </option>
            )}
          </select>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" style={{ width: "10%", paddingTop: 5, paddingBottom: 5 }}>
        <span>
          <select
            className="form-control input-number-arrows-low-pr"
            disabled={deliveryTimesForIncoterm.length < 2 || invalidMOQ}
            value={priceObject.deliverytime}
            name="deliverytime"
            onChange={handleDeliveryTimeChange}
          >
            {deliveryTimesForIncoterm.map((d: number) => (
              <option key={d} value={d}>
                {d} days
              </option>
            ))}
            {!deliveryTimesForIncoterm.includes(priceObject.deliverytime) && (
              // Add current deliverytime if it isn't included anymore
              <option key={priceObject.deliverytime} value={priceObject.deliverytime}>
                {priceObject.deliverytime} days
              </option>
            )}
          </select>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" style={{ width: "10%", paddingTop: 5, paddingBottom: 5 }}>
        <span>
          {priceObject.purchasePrice && priceObject.purchasePrice > 0 ? (
            <span className="mr-1">
              {priceObject.purchasePrice.toFixed(2) + " " + priceObject.purchaseCurrency + `/${unit}`}
            </span>
          ) : (
            <span className="mr-1">not set</span>
          )}
          <Tooltip
            id={priceObject._id.toString() + "_purchasePrice"}
            title="Purchase price and currency, excluding transport, custom, fees."
            placement="top"
          >
            <i className="la la-question-circle" />
          </Tooltip>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" style={{ width: "10%", paddingTop: 5, paddingBottom: 5 }}>
        <span>
          <span className="mr-1">
            {priceObject.price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) + `/${unit}`}
          </span>
          <Tooltip
            id={priceObject._id.toString() + "_price"}
            title="Estimated total price including transport, custom, fees on an average basis. This price is used for a rough indication while calculating."
            placement="top"
          >
            <i className="la la-question-circle" />
          </Tooltip>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
        <span className={priceDifference <= 0 ? "text-success" : priceDifference > 0 ? "text-danger" : ""}>
          <b>{priceDifference.toFixed(2) + "%"}</b>
        </span>
      </td>
    </tr>
  );
};

export default RawbidsCommoditiesCheck;
