import React, { useCallback, useContext, useEffect, useState } from "react";
import { AvisRoleOptions } from "../../utils/warehouseUtils";
import { CompaniesDocument } from "../../model/companies.types";
import { SuppliersDocument } from "../../model/suppliers.types";
import { ManufacturersDocument } from "../../model/manufacturers.types";
import { DataContext } from "../../context/dataContext";
import userService from "../../services/userService";
import authenticationService from "../../services/authenticationService";
import { AvisInformation, OrderWithMaterial } from "../../utils/avisUtils";
import { SenderType } from "../../model/warehouse/batch.types";
import dbService from "../../services/dbService";

interface AvisShipperInformationProps {
  role: SenderType | null;
  accessCode: Array<string>;
  company: CompaniesDocument | SuppliersDocument | ManufacturersDocument | null | undefined;
  onChangeRole: (role: SenderType | null) => void;
  onChangeCode: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeData: (
    company: CompaniesDocument | SuppliersDocument | ManufacturersDocument | null,
    order: Array<OrderWithMaterial> | null,
    shipmentCode: string
  ) => void;
}

const AvisShipperInformation: React.FC<AvisShipperInformationProps> = ({
  role,
  accessCode,
  company,
  onChangeRole,
  onChangeCode,
  onChangeData
}) => {
  const context = useContext(DataContext);

  const [validating, setValidating] = useState(false);

  const handleValidate = useCallback(async () => {
    if (!role) return false;
    if (!userService.getUser()) {
      if (!(await authenticationService.anonymousLogin())) return false;
    }
    const ac = accessCode.join("");
    const { company, orders, shipmentCode } = await dbService.callFunction<AvisInformation>(
      "checkAvisCodeAndReturnInformation",
      [role, ac],
      true
    );
    onChangeData(company, orders, shipmentCode);
  }, [accessCode, onChangeData, context.companies, context.suppliers, context.manufacturers]);

  const handleChangeRole = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => onChangeRole(e.target.value as SenderType),
    [onChangeRole]
  );

  useEffect(() => {
    if (!company && accessCode.join("").trim().length === 4) {
      setValidating(true);
      handleValidate().then(() => setValidating(false));
    }
  }, [accessCode]);

  return (
    <>
      <h1 className="mb-10 font-weight-bold text-dark">Shipper Information</h1>
      <div className="form-group">
        <label>Select your Role</label>
        <select
          className="form-control form-control-solid form-control-lg"
          name="role"
          onChange={handleChangeRole}
          value={role || "default"}
        >
          <option value="default" disabled hidden>
            Select a Role...
          </option>
          {AvisRoleOptions.map(o => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      </div>
      <hr />
      <div className="text-center" style={{ height: "110px" }}>
        <h1 className="mb-10 font-weight-bold text-dark">Access Code</h1>
        {validating ? (
          <div className="h-100">
            <h4 className="text-dark mt-4">Validating code...</h4>
          </div>
        ) : company ? (
          <div className="h-100">
            <h4 className="text-dark mt-4">Company: {company.name}</h4>
          </div>
        ) : (
          <div>
            <div className="input-group justify-content-center h-100">
              <input
                id="AC0"
                type="text"
                maxLength={1}
                className={"form-control form-control-solid form-control-lg m-1 " + (!role && "disabled not-allowed")}
                name="AC0"
                style={{ maxWidth: "60px" }}
                disabled={!role}
                value={accessCode[0]}
                onChange={!role ? undefined : onChangeCode}
              />
              <input
                id="AC1"
                type="text"
                maxLength={1}
                className={"form-control form-control-solid form-control-lg m-1 " + (!role && "disabled not-allowed")}
                name="AC1"
                style={{ maxWidth: "60px" }}
                disabled={!role}
                value={accessCode[1]}
                onChange={!role ? undefined : onChangeCode}
              />
              <input
                id="AC2"
                type="text"
                maxLength={1}
                className={"form-control form-control-solid form-control-lg m-1 " + (!role && "disabled not-allowed")}
                name="AC2"
                style={{ maxWidth: "60px" }}
                disabled={!role}
                value={accessCode[2]}
                onChange={!role ? undefined : onChangeCode}
              />
              <input
                id="AC3"
                type="text"
                maxLength={1}
                className={"form-control form-control-solid form-control-lg m-1 " + (!role && "disabled not-allowed")}
                name="AC3"
                style={{ maxWidth: "60px" }}
                disabled={!role}
                value={accessCode[3]}
                onChange={!role ? undefined : onChangeCode}
              />
            </div>
            {company === null && <small className="text-danger">Invalid Access Code</small>}
          </div>
        )}
      </div>
    </>
  );
};

export default AvisShipperInformation;
