import _ from "lodash";
import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";
import countryList from "i18n-iso-countries";
import { toAbsoluteUrl } from "../../../_metronic";
import { CommoditySettingsPrepared } from "../CustomTypes";
import DeleteForecastModal from "../modals/DeleteForecastModal";
import ForecastModal from "../modals/ForecastModal";
import SelectActiveSubstance from "../SelectActiveSubstance";
import CountryPicker from "../../common/CountryPicker";
import { DataContext } from "../../../context/dataContext";
import { CommoditiesDocument, Forecast } from "../../../model/commodities.types";
import { COMMODITIES } from "../../../services/dbService";
import dbGeneralService from "../../../services/dbServices/dbGeneralService";
import accessUtils, { EDITLOCATIONS } from "../../../utils/accessUtils";
import baseUtils from "../../../utils/baseUtils";
import commodityUtils from "../../../utils/commodityUtils";
import generalUtils from "../../../utils/generalUtils";
import toastUtils from "../../../utils/toastUtils";
import dbCommodityService from "../../../services/dbServices/dbCommodityService";

interface CommoditySettingsProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
  saving: boolean;
}

interface CommoditySettingsState {
  commodity: CommoditySettingsPrepared;
  saving: boolean;
}

class CommoditySettings extends PureComponent<CommoditySettingsProps, CommoditySettingsState> {
  constructor(props: CommoditySettingsProps) {
    super(props);
    this.state = {
      commodity: this.prepareCommodity(),
      saving: props.saving
    };
  }

  componentDidMount() {
    this.setState({ commodity: this.prepareCommodity() });
  }

  componentDidUpdate(prevProps: Readonly<CommoditySettingsProps>) {
    if (!_.isEqual(prevProps.commodity, this.props.commodity) || prevProps.saving !== this.props.saving) {
      this.setState({ commodity: this.prepareCommodity(), saving: this.props.saving });
    }
  }

  /**
   * Prepares the commodity for usage inside the settings component by transforming all its properties to formats that
   * are easier handleable inside input fields.
   * @returns { CommoditySettingsPrepared } Prepared commodity
   */
  prepareCommodity = () => {
    const { context } = this.props;
    const { allergens, commodityproperties } = context;
    const c = _.cloneDeep(this.props.commodity);
    const allergensUsed = c.allergens.map(a => a.toString());
    const cAllergens = allergens.filter(a => allergensUsed.includes(a._id.toString()));
    const propertiesUsed = c.properties.map(p => p.toString());
    const cProperties = commodityproperties.filter(cp => propertiesUsed.includes(cp._id.toString()));
    const activeSubstances = c.activesubstance.map(as => {
      return { _id: as._id.toString(), nrv: as.value.toString() };
    });
    const commodity: CommoditySettingsPrepared = {
      titleDe: c.title.de,
      titleEn: c.title.en,
      subtitleDe: c.subtitle.de,
      subtitleEn: c.subtitle.en,
      identifier: c.identifier,
      note: c.note,
      category: c.category.toString(),
      form: c.form.toString(),
      density: c.density ? c.density.toString() : "",
      country: c.country ? { value: c.country, label: countryList.getName(c.country, "en") } : "",
      internalCode: c.internal_code,
      hsCode: c.hs_code,
      casNumber: c.cas_number ? c.cas_number : "",
      articleNumber: c.article_number,
      toxicAmount: c.toxic_amount ? c.toxic_amount.toString() : "",
      color: c.color ? c.color.toString() : "",
      organic: c.organic,
      organicCode: c.organic_code,
      properties: cProperties,
      allergens: cAllergens,
      solvent: c.solvent ? c.solvent.toString() : "",
      activeSubstances,
      type: c.type ? c.type : "",
      forecast: _.sortBy(c.forecast, f => f.date.getTime())
    };
    return commodity;
  };

  /**
   * Handles saving changes that were made to the commodity.
   */
  handleSaveCommodity = async () => {
    const { context } = this.props;
    const { commodity } = this.state;
    const { commoditycategories, updateDocumentInContext } = context;
    this.setState({ saving: true });

    try {
      // Remove useless active substances
      commodity.activeSubstances = commodity.activeSubstances.filter(as => BSON.ObjectId.isValid(as._id) && +as.nrv);

      // Transform the prepared commodity back to a commodities document
      const comOld = this.props.commodity;
      const comNew = _.cloneDeep(comOld);
      comNew.title = { de: commodity.titleDe, en: commodity.titleEn };
      comNew.subtitle = { de: commodity.subtitleDe, en: commodity.subtitleEn };
      comNew.note = commodity.note;
      comNew.category = BSON.ObjectId.isValid(commodity.category) ? new BSON.ObjectId(commodity.category) : "custom";
      comNew.form = BSON.ObjectId.isValid(commodity.form) ? new BSON.ObjectId(commodity.form) : "custom";
      comNew.density = isNaN(+commodity.density) || !commodity.density ? null : +commodity.density;
      comNew.country = commodity.country ? commodity.country.value : "";
      // Only if the internal code has switched we need to adjust the code. There are categories with same code
      // references. In this case we do not have to adjust the code.
      if (comOld.internal_code !== commodity.internalCode) {
        const cat = commoditycategories.find(cc => cc._id.toString() === commodity.category)!;
        const internalCode = await dbGeneralService.getAndIncreaseGeneralNr(cat.internal_code_reference);
        if (!internalCode) {
          toast.error("Update failed. Could not get new internal code.");
          return;
        }
        comNew.internal_code = internalCode.toString();
      }
      comNew.hs_code = commodity.hsCode;
      comNew.cas_number = commodity.casNumber;
      comNew.article_number = commodity.articleNumber;
      comNew.toxic_amount = commodity.toxicAmount ? +commodity.toxicAmount : null;
      comNew.color = commodity.color ? new BSON.ObjectId(commodity.color) : null;
      comNew.organic = commodity.organic;
      // If the organic code flag was switched we need to handle the adjustment of the organic code field.
      if (!comOld.organic && commodity.organic) {
        const organicCode = await dbGeneralService.getNextOrganicCode();
        if (!organicCode) {
          toast.error("Update failed. Could not get new organic code.");
          return;
        }
        comNew.organic_code = organicCode.toString();
      } else if (comOld.organic && !commodity.organic) {
        comNew.organic_code = "";
      }
      comNew.properties = commodity.properties.map(p => p._id);
      comNew.allergens = commodity.allergens.map(a => a._id);
      comNew.solvent = commodity.solvent ? new BSON.ObjectId(commodity.solvent) : null;
      comNew.activesubstance = commodity.activeSubstances.map(as => {
        return { _id: new BSON.ObjectId(as._id), value: +as.nrv };
      });
      comNew.forecast = commodity.forecast;
      if (comOld.identifier !== commodity.identifier) {
        const id = await dbCommodityService.getCommodityIdentifier(commodity.category, commodity.organic);
        if (!id) {
          toast.error("Couldn't get identifier. Commodity can't be updated");
          return;
        }
        comNew.identifier = id;
      }
      const res = await commodityUtils.updateCommodityWithTimeline(comOld, comNew);
      await toastUtils.databaseOperationToast(!!res, "Commodity updated successfully", "Error updating commodity", () =>
        updateDocumentInContext(COMMODITIES, comNew._id)
      );
    } finally {
      this.setState({ saving: false });
    }
  };

  /**
   * Handles adding a new forecast.
   * @param forecast: Forecast that should be added to the commodity
   */
  handleAddForecast = async (forecast: Forecast) => {
    const commodity = _.cloneDeep(this.state.commodity);
    commodity.forecast.push(forecast);
    this.setState({ commodity });
  };

  handleEditForecast = async (forecast: Forecast, idx: number) => {
    const commodity = _.cloneDeep(this.state.commodity);
    commodity.forecast[idx] = forecast;
    this.setState({ commodity });
  };

  handleDeleteForecast = async (idx: number) => {
    const commodity = _.cloneDeep(this.state.commodity);
    commodity.forecast.splice(idx, 1);
    this.setState({ commodity });
  };

  /**
   * Resets the settings input fields to the current database state.
   */
  handleReset = () => {
    this.setState({ commodity: this.prepareCommodity() });
  };

  /**
   * Handles adding an active substance to the commodity.
   */
  handleAddActiveSubstance = () => {
    const commodity = _.cloneDeep(this.state.commodity);
    commodity.activeSubstances.push({ _id: "", nrv: "" });
    this.setState({ commodity });
  };

  /**
   * Handles changing an active substance of the commodity. The change can be a new id or nrv.
   * @param idx: Index of the active substance that is changed
   * @param _id: ID of the active substance
   * @param nrv: NRV of the active substance
   */
  handleChangeActiveSubstance = (idx: number, _id: string, nrv: string) => {
    const commodity = _.cloneDeep(this.state.commodity);
    commodity.activeSubstances[idx] = { _id, nrv };
    this.setState({ commodity });
  };

  /**
   * Handles the deletion of an active substance.
   * @param idx: Index of the substance that should be deleted
   */
  handleDeleteActiveSubstance = (idx: number) => {
    const commodity = _.cloneDeep(this.state.commodity);
    commodity.activeSubstances.splice(idx, 1);
    this.setState({ commodity });
  };

  /**
   * Handles the change of a dropdown value. This is handled inside an own function since the data has to be
   * transformed before saving.
   * @param type: Determines whether properties or allergens are changed
   * @param value: All selected values
   */
  handleDropdownChange = (type: "properties" | "allergens", value: Array<string>) => {
    const { allergens, commodityproperties } = this.props.context;
    const commodity = _.cloneDeep(this.state.commodity);
    if (type === "properties") {
      commodity.properties = commodityproperties.filter(cp => value.includes(cp._id.toString()));
    } else if (type === "allergens") {
      commodity.allergens = allergens.filter(a => value.includes(a._id.toString()));
    }
    this.setState({ commodity });
  };

  /**
   * Generalized change function for the commodity.
   * @param name of the commodity parameter that needs to be changed
   * @param value the paramter is set to
   */
  handleSelect = (name: string, value: { value: string; label: string } | "") => {
    const commodity = _.cloneDeep(this.state.commodity);
    commodity.country = value;
    this.setState({ commodity });
  };

  /**
   * Generalized change function for the commodity.
   * @param e: Change event
   */
  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { commodity: propCommodity, context } = this.props;
    const { general, commoditycategories } = context;
    const commodity = _.cloneDeep(this.state.commodity);
    let val = e.target.value;
    if (e.target.type === "number") {
      val = val.replaceAll(/^0+/g, "0");
      if (!val.includes(".")) val = Number(val).toString();
      if (!val || Number(val) < 0) return;
    }
    // When the organic field is changed the organic code has to be adjusted too
    if (e.target.name === "organic") {
      if (!commodity.organic) {
        commodity.organic = true;
        commodity.organicCode = generalUtils.getNextOrganicCode(this.props.context.general);
        if (propCommodity.organic) commodity.identifier = propCommodity.identifier;
        else commodity.identifier = "TBD";
      } else {
        commodity.organic = false;
        commodity.organicCode = "";
        if (!propCommodity.organic && propCommodity.category.toString() === commodity.category.toString())
          commodity.identifier = propCommodity.identifier;
        else commodity.identifier = "TBD";
      }
      // When changing the category the code cycle might have changed and a new internal code might be needed
    } else if (e.target.name === "category") {
      const catPropCommodity = commoditycategories.find(cc => cc._id.toString() === propCommodity.category.toString());
      const catOld = commoditycategories.find(cc => cc._id.toString() === commodity.category)!;
      const catNew = commoditycategories.find(cc => cc._id.toString() === val)!;
      if (
        catNew.internal_code_reference !== catOld.internal_code_reference &&
        catNew.internal_code_reference !== catPropCommodity?.internal_code_reference
      ) {
        commodity.internalCode = generalUtils.getNextInternalCode(catNew.internal_code_reference, general);
      } else if (catPropCommodity?.internal_code_reference === catNew.internal_code_reference)
        commodity.internalCode = propCommodity.internal_code;
      if (
        !commodity.organic &&
        catNew.identifierReference !== catOld.identifierReference &&
        catNew.identifierReference !== catPropCommodity?.identifierReference
      )
        commodity.identifier = "TBD";
      else if (
        !commodity.organic &&
        !propCommodity.organic &&
        catPropCommodity?.identifierReference === catNew.identifierReference
      )
        commodity.identifier = propCommodity.identifier;
      commodity.category = val;
    } else {
      // @ts-ignore
      commodity[e.target.name] = val;
    }
    this.setState({ commodity });
  };

  /**
   * Validates numbers on blur so that the validation is not too annoying for the user.
   * @param e: Change event
   */
  handleBlurNumberFields = (e: React.ChangeEvent<HTMLInputElement>) => {
    const commodity = _.cloneDeep(this.state.commodity);
    // @ts-ignore
    commodity[e.target.name] = Number(e.target.value).toString();
    this.setState({ commodity });
  };

  render() {
    const { context } = this.props;
    const { activesubstances, allergens, colors, commoditycategories, commodityproperties, compositions, solvents } =
      context;
    const { commodity, saving } = this.state;
    const type = commodity.type;
    const canEdit = accessUtils.canEditData(EDITLOCATIONS.COMMODITYSETTINGS);

    return (
      <>
        <div className="kt-form__body">
          <div className="kt-section kt-section--first">
            <div className="kt-section__body">
              <div className="row">
                <div className="col-12">
                  <h3 className="kt-section__title kt-section__title-sm">Base Information:</h3>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Title</label>
                <div className="col-9 col-xl-6">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img src={toAbsoluteUrl("/media/icons/icon-de.png")} className="country-icon" alt="de" />
                      </span>
                    </div>
                    <input
                      className="form-control"
                      type="text"
                      value={commodity.titleDe}
                      name="titleDe"
                      onChange={canEdit ? this.handleChange : undefined}
                      disabled={!canEdit}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label" />
                <div className="col-9 col-xl-6">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img src={toAbsoluteUrl("/media/icons/icon-en.png")} className="country-icon" alt="en" />
                      </span>
                    </div>
                    <input
                      className="form-control"
                      type="text"
                      value={commodity.titleEn}
                      name="titleEn"
                      onChange={canEdit ? this.handleChange : undefined}
                      disabled={!canEdit}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Subtitle</label>
                <div className="col-9 col-xl-6">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img src={toAbsoluteUrl("/media/icons/icon-de.png")} className="country-icon" alt="de" />
                      </span>
                    </div>
                    <input
                      className="form-control"
                      type="text"
                      value={commodity.subtitleDe}
                      name="subtitleDe"
                      onChange={canEdit ? this.handleChange : undefined}
                      disabled={!canEdit}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label" />
                <div className="col-9 col-xl-6">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img src={toAbsoluteUrl("/media/icons/icon-en.png")} className="country-icon" alt="en" />
                      </span>
                    </div>
                    <input
                      className="form-control"
                      type="text"
                      value={commodity.subtitleEn}
                      name="subtitleEn"
                      onChange={canEdit ? this.handleChange : undefined}
                      disabled={!canEdit}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Note</label>
                <div className="col-9 col-xl-6">
                  <textarea
                    className="form-control"
                    value={commodity.note}
                    disabled={!canEdit}
                    name="note"
                    rows={3}
                    onChange={canEdit ? this.handleChange : undefined}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h3 className="kt-section__title kt-section__title-sm">Properties:</h3>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Article Number</label>
                <div className="col-9 col-xl-6">
                  <input
                    className="form-control"
                    type="text"
                    value={commodity.articleNumber}
                    name="articleNumber"
                    onChange={canEdit ? this.handleChange : undefined}
                    disabled={!canEdit}
                    placeholder="Article Number"
                  />
                </div>
              </div>
              {!type && (
                <>
                  <div className="form-group row">
                    <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Category</label>
                    <div className="col-9 col-xl-6">
                      <div className="input-group">
                        <select
                          className="form-control"
                          name="category"
                          value={commodity.category.toString()}
                          onChange={canEdit ? this.handleChange : undefined}
                          disabled={!canEdit}
                        >
                          {_.sortBy(commoditycategories, item => item.name.en.trim().toLowerCase()).map(item => (
                            <option key={item._id.toString()} value={item._id.toString()}>
                              {item.name.en}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Identifier</label>
                    <div className="col-9 col-xl-6">
                      <input
                        disabled
                        className="form-control"
                        type="text"
                        value={commodity.identifier}
                        name="identifier"
                        placeholder="Internal identifier"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Internal Code</label>
                    <div className="col-9 col-xl-6">
                      <input
                        disabled
                        className="form-control"
                        type="text"
                        value={commodity.internalCode}
                        name="internalCode"
                        placeholder="Internal article number"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Composition</label>
                    <div className="col-9 col-xl-6">
                      <div className="input-group">
                        <select
                          className="form-control"
                          value={commodity.form.toString()}
                          name="form"
                          onChange={canEdit ? this.handleChange : undefined}
                          disabled={!canEdit}
                        >
                          {_.sortBy(compositions, item => item.name.en.trim().toLowerCase()).map(item => (
                            <option key={item._id.toString()} value={item._id.toString()}>
                              {item.name.en}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Density</label>
                    <div className="col-9 col-xl-6">
                      <input
                        className="form-control"
                        type="number"
                        value={commodity.density ? commodity.density.toString() : "0"}
                        name="density"
                        min={0}
                        onChange={canEdit ? this.handleChange : undefined}
                        disabled={!canEdit}
                        onBlur={canEdit ? this.handleBlurNumberFields : undefined}
                        inputMode={"decimal"}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Country</label>
                    <div className="col-9 col-xl-6">
                      <CountryPicker
                        handleChange={this.handleSelect}
                        value={commodity.country || { value: "", label: "Select String" }}
                        disabled={!canEdit}
                        allowClear={true}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">HS Code</label>
                    <div className="col-9 col-xl-6">
                      <input
                        className="form-control"
                        type="text"
                        value={commodity.hsCode}
                        name="hsCode"
                        onChange={canEdit ? this.handleChange : undefined}
                        disabled={!canEdit}
                        placeholder="HS-Code"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">CAS Number</label>
                    <div className="col-9 col-xl-6">
                      <input
                        className="form-control"
                        type="text"
                        value={commodity.casNumber}
                        name="casNumber"
                        onChange={canEdit ? this.handleChange : undefined}
                        disabled={!canEdit}
                        placeholder="CAS Number"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Toxic Amount</label>
                    <div className="col-9 col-xl-6">
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="number"
                          value={commodity.toxicAmount ? commodity.toxicAmount.toString() : "0"}
                          name="toxicAmount"
                          min={0}
                          onChange={canEdit ? this.handleChange : undefined}
                          disabled={!canEdit}
                          onBlur={canEdit ? this.handleBlurNumberFields : undefined}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">mg / day</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Color</label>
                    <div className="col-9 col-xl-6">
                      <select
                        className="form-control"
                        name="color"
                        value={commodity.color ? commodity.color.toString() : ""}
                        onChange={canEdit ? this.handleChange : undefined}
                        disabled={!canEdit}
                      >
                        <option value="">Select Color</option>
                        {_.sortBy(colors, item => item.name.en.toLowerCase()).map(item => (
                          <option key={item._id.toString()} value={item._id.toString()}>
                            {item.name.en}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Organic</label>
                    <div className="col-9 col-xl-6">
                      <div className="form-check mt-2">
                        <input
                          className="form-check-input mr-2"
                          type="checkbox"
                          name="organic"
                          checked={commodity.organic}
                          onChange={canEdit ? this.handleChange : undefined}
                          disabled={!canEdit}
                        />
                        <label className="form-check-label kt-font-dark text-right">Organic commodity</label>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="form-group row">
                <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Properties</label>
                <div className="col-9 col-xl-6">
                  <Dropdown
                    placeholder="Properties"
                    fluid
                    multiple
                    selection
                    disabled={!canEdit}
                    options={_.sortBy(commodityproperties, item => item.name.en.trim().toLowerCase()).map(cp => {
                      return { key: cp._id.toString(), text: cp.name.en, value: cp._id.toString() };
                    })}
                    value={commodity.properties.map(p => p._id.toString())}
                    onChange={
                      canEdit
                        ? (e, { value }) => {
                            // @ts-ignore
                            this.handleDropdownChange("properties", value);
                          }
                        : undefined
                    }
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Allergens</label>
                <div className="col-9 col-xl-6">
                  <Dropdown
                    placeholder="Allergens"
                    fluid
                    multiple
                    selection
                    disabled={!canEdit}
                    options={_.sortBy(allergens, item => item.name.en.trim().toLowerCase()).map(a => {
                      return { key: a._id.toString(), text: a.name.en, value: a._id.toString() };
                    })}
                    value={commodity.allergens.map(a => a._id.toString())}
                    onChange={
                      canEdit
                        ? (e, { value }) => {
                            // @ts-ignore
                            this.handleDropdownChange("allergens", value);
                          }
                        : undefined
                    }
                  />
                </div>
              </div>
              {!type && (
                <>
                  <div className="form-group row">
                    <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Solvent</label>
                    <div className="col-9 col-xl-6">
                      <select
                        className="form-control"
                        name="solvent"
                        value={commodity.solvent ? commodity.solvent.toString() : ""}
                        onChange={canEdit ? this.handleChange : undefined}
                        disabled={!canEdit}
                      >
                        <option value="">None</option>
                        {_.sortBy(solvents, item => item.name.en.trim().toLowerCase()).map(s => (
                          <option key={s._id.toString()} value={s._id}>
                            {s.name.en}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">
                      Active Substances
                    </label>
                    <div className="col-9 col-xl-6">
                      <SelectActiveSubstance
                        selected={commodity.activeSubstances}
                        activeSubstances={activesubstances}
                        onAdd={this.handleAddActiveSubstance}
                        onChange={this.handleChangeActiveSubstance}
                        onDelete={this.handleDeleteActiveSubstance}
                        disabled={!canEdit}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="form-group row">
                <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right pt-3">Forecast</label>
                {commodity.forecast.length > 0 && (
                  <>
                    <div className="col-9 col-xl-6 overflow-auto">
                      <Table>
                        <thead>
                          <tr>
                            <th style={{ width: "5%" }} />
                            <th style={{ width: "15%" }}>Date</th>
                            <th style={{ width: "35%" }}>
                              Reason{" "}
                              <img src={toAbsoluteUrl("/media/icons/icon-en.png")} className="country-icon2" alt="en" />
                            </th>
                            <th style={{ width: "35%" }}>
                              <img src={toAbsoluteUrl("/media/icons/icon-de.png")} className="country-icon2" alt="de" />
                            </th>
                            <th style={{ width: "10%" }} />
                          </tr>
                        </thead>
                        <tbody>
                          {commodity.forecast.map((f, key) => (
                            <tr key={f.forecast + f.date.getTime() + key}>
                              <td className="align-middle text-center">
                                <i
                                  className={commodityUtils.resolveForecastIconClasses(f.forecast)}
                                  style={{ fontSize: "20px" }}
                                />
                              </td>
                              <td className="align-middle">{baseUtils.formatDate(f.date)}</td>
                              <td className="align-middle">{f.reason.en ? f.reason.en : "-"}</td>
                              <td className="align-middle">{f.reason.de ? f.reason.de : "-"}</td>
                              <td className="align-middle text-right pr-0">
                                <div className="btn-group">
                                  <ForecastModal
                                    name={commodity.titleEn}
                                    onSave={this.handleEditForecast}
                                    forecast={f}
                                    idx={key}
                                    disabled={!canEdit}
                                  />
                                  <DeleteForecastModal
                                    name={commodity.titleEn}
                                    forecast={f}
                                    idx={key}
                                    onDelete={this.handleDeleteForecast}
                                    disabled={!canEdit}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <div className="col-3 d-none d-xl-flex" />
                    <div className="col-3" />
                  </>
                )}
                <div className="col-lg-9 col-xl-6">
                  <div className="form-group row">
                    <div className="col-9" />
                    <div className="col-3">
                      <ForecastModal name={commodity.titleEn} onSave={this.handleAddForecast} disabled={!canEdit} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-separator kt-separator--space-lg kt-separator--fit kt-separator--border-solid" />
        <div className="float-right">
          <button
            className={"btn btn-secondary mr-2" + (saving || !canEdit ? " disabled" : "")}
            disabled={saving || !canEdit}
            onClick={saving || !canEdit ? undefined : this.handleReset}
          >
            Reset
          </button>
          <button
            className={"btn btn-success" + (saving || !canEdit ? " disabled" : "")}
            disabled={saving || !canEdit}
            onClick={saving || !canEdit ? undefined : this.handleSaveCommodity}
          >
            {saving ? "Saving..." : "Save changes"}
          </button>
        </div>
      </>
    );
  }
}

export default CommoditySettings;
