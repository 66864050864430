import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import {
  PackagingUnitDefinition,
  PackagingUnitShape
} from "../../../../model/configuration/warehouseConfiguration.types";
import i18n from "../../../../translations/i18n";
import { useWarehouseContext, WarehouseContext } from "../../../../context/warehouseContext";
import dbService, { CONFIGURATION, UpdateAction } from "../../../../services/dbService";
import toastUtils from "../../../../utils/toastUtils";
import { DEFAULTLENGTHUNIT, DEFAULTWEIGHTUNIT } from "../../../../utils/warehouseUtils";
import { getConfigTimelineEntry } from "../../../../utils/warehouseConfigUtils";
import { ModalMode } from "../../../../model/common.types";
import baseUtils from "../../../../utils/baseUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";

interface CreatePackagingUnitModalProps {
  mode: ModalMode;
  pUEdit?: PackagingUnitDefinition;
  isPUUsed?: boolean;
}

interface CreatePackagingUnitModalState {
  labelDe: string;
  length: number;
  width: number;
  height: number;
  shape: PackagingUnitShape;
  deadWeightValue: number;
  maxFillingWeightValue: number;
}

const getDefaultPackagingUnit = (mode: ModalMode, pUEdit?: PackagingUnitDefinition): CreatePackagingUnitModalState => {
  if ((mode === ModalMode.EDIT || mode === ModalMode.DELETE) && pUEdit) {
    return {
      labelDe: pUEdit.label.de,
      length: pUEdit.dimensions.length,
      width: pUEdit.dimensions.width,
      height: pUEdit.dimensions.height,
      shape: pUEdit.dimensions.shape,
      deadWeightValue: pUEdit.deadWeight.value,
      maxFillingWeightValue: pUEdit.maxFillingWeight?.value ?? 0
    };
  } else {
    return {
      labelDe: "",
      length: 0,
      width: 0,
      height: 0,
      shape: PackagingUnitShape.ROUND,
      deadWeightValue: 0,
      maxFillingWeightValue: 0
    };
  }
};

const CreatePackagingUnitModal: React.FunctionComponent<CreatePackagingUnitModalProps> = ({
  mode,
  pUEdit,
  isPUUsed
}) => {
  const warehouseContext = useWarehouseContext();

  const [show, setShow] = useState(false);
  const [packagingUnit, setPackagingUnit] = useState(getDefaultPackagingUnit(mode, pUEdit));
  const [saving, setSaving] = useState(false);

  const packagingOptions = useMemo(() => {
    return [
      { value: PackagingUnitShape.ROUND, label: i18n.t("warehouse:round") },
      {
        value: PackagingUnitShape.ANGULAR,
        label: i18n.t("warehouse:angular")
      }
    ];
  }, []);

  const errors = useMemo(() => {
    const newErrors = [];
    if (packagingUnit.labelDe === "") newErrors.push(i18n.t("warehouse:labelMissing"));
    if (packagingUnit.length <= 0) newErrors.push(i18n.t("warehouse:pUCreationLengthError"));
    if (packagingUnit.width <= 0) newErrors.push(i18n.t("warehouse:pUCreationWidthError"));
    if (packagingUnit.height <= 0) newErrors.push(i18n.t("warehouse:pUCreationHeightError"));
    if (packagingUnit.deadWeightValue <= 0) newErrors.push(i18n.t("warehouse:pUCreationDeadWeightError"));
    return newErrors;
  }, [packagingUnit]);

  const handleShow = () => {
    setShow(true);
    setPackagingUnit(getDefaultPackagingUnit(mode, pUEdit));
  };
  const handleClose = () => setShow(false);

  const handleChangeLabel = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const labelDe = e.currentTarget.value;
    setPackagingUnit(prevState => {
      return { ...prevState, labelDe };
    });
  }, []);

  const handleChangeForm = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const newShape = e.currentTarget.value as PackagingUnitShape;
    setPackagingUnit(prevState => {
      return { ...prevState, shape: newShape };
    });
  }, []);

  const handleChangeNumericData = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove leading zeros
    const { value, name } = e.target;
    let newValue = value.replaceAll(/^0+/g, "0");
    if ((!Number(newValue) && Number(newValue) !== 0) || Number(newValue) < 0) return;
    if (!newValue.includes(".")) newValue = Number(newValue).toString();
    setPackagingUnit(prevState => {
      return { ...prevState, [name]: newValue };
    });
  }, []);

  const handleSave = useCallback(async () => {
    setSaving(true);
    const packagingUnitDefinition: PackagingUnitDefinition = getPackagingUnitDefinition();
    try {
      if (warehouseContext.configuration) {
        const newConfig = _.cloneDeep(warehouseContext.configuration.values);
        if (mode === ModalMode.EDIT && pUEdit) {
          const changedPUIndex = newConfig.packagingUnitDefinitions.findIndex(
            pu => pu._id.toString() === pUEdit._id.toString()
          );
          newConfig.packagingUnitDefinitions[changedPUIndex] = packagingUnitDefinition;

          const actions: Array<UpdateAction> = [
            {
              collection: CONFIGURATION,
              filter: { _id: warehouseContext.configuration?._id },
              update: { "values.packagingUnitDefinitions.$[filter]": packagingUnitDefinition, lastUpdate: new Date() },
              arrayFilters: [{ "filter._id": pUEdit._id }],
              push: {
                timeline: getConfigTimelineEntry(warehouseContext.configuration.values, newConfig)
              }
            }
          ];
          const res = await dbService.transaction(actions);
          await toastUtils.databaseOperationToast(
            res,
            i18n.t("warehouse:pUEditSuccess"),
            i18n.t("warehouse:pUEditError"),
            () => {
              setShow(false);
            }
          );
        } else if (!isPUUsed && mode === ModalMode.DELETE && pUEdit) {
          const changedPUIndex = newConfig.packagingUnitDefinitions.findIndex(
            pu => pu._id.toString() === pUEdit._id.toString()
          );
          newConfig.packagingUnitDefinitions.splice(changedPUIndex, 1);

          const action: UpdateAction = {
            collection: CONFIGURATION,
            filter: { _id: warehouseContext.configuration?._id },
            update: { lastUpdate: new Date() },
            pull: { "values.packagingUnitDefinitions": { _id: pUEdit._id } },
            push: {
              timeline: getConfigTimelineEntry(warehouseContext.configuration.values, newConfig)
            }
          };
          const res = await dbService.transaction([action]);
          await toastUtils.databaseOperationToast(
            res,
            i18n.t("warehouse:pUDeleteSuccess"),
            i18n.t("warehouse:pUDeleteError"),
            () => {
              setShow(false);
            }
          );
        } else {
          newConfig.packagingUnitDefinitions.push(packagingUnitDefinition);
          const action: UpdateAction = {
            collection: CONFIGURATION,
            filter: { _id: warehouseContext.configuration?._id },
            update: { lastUpdate: new Date() },
            push: {
              "values.packagingUnitDefinitions": packagingUnitDefinition,
              timeline: getConfigTimelineEntry(warehouseContext.configuration.values, newConfig)
            }
          };
          const res = await dbService.transaction([action]);
          await toastUtils.databaseOperationToast(
            res,
            i18n.t("warehouse:pUCreateSuccess"),
            i18n.t("warehouse:pUCreateError"),
            () => {
              setShow(false);
            }
          );
        }
      }
    } catch (e) {
      toast.error(
        `${
          mode === ModalMode.DELETE
            ? i18n.t("warehouse:pUDeleteError")
            : mode === ModalMode.EDIT
            ? i18n.t("warehouse:pUEditError")
            : i18n.t("warehouse:pUCreateError")
        }: ` + e
      );
    } finally {
      setPackagingUnit(getDefaultPackagingUnit(mode, pUEdit));
      setSaving(false);
    }
  }, [packagingUnit, isPUUsed, mode, pUEdit, warehouseContext.configuration]);

  const getPackagingUnitDefinition = useCallback(() => {
    const packagingUnitDefinition: PackagingUnitDefinition = {
      _id: pUEdit ? pUEdit._id : new BSON.ObjectId(),
      label: { de: baseUtils.capitalizeAllWords(packagingUnit.labelDe.trim()) },
      dimensions: {
        length: +packagingUnit.length,
        height: +packagingUnit.height,
        width: +packagingUnit.width,
        shape: packagingUnit.shape,
        unit: DEFAULTLENGTHUNIT
      },
      deadWeight: {
        value: +packagingUnit.deadWeightValue,
        unit: DEFAULTWEIGHTUNIT
      }
    };
    if (+packagingUnit.maxFillingWeightValue !== 0)
      packagingUnitDefinition.maxFillingWeight = {
        value: +packagingUnit.maxFillingWeightValue,
        unit: DEFAULTWEIGHTUNIT
      };
    return packagingUnitDefinition;
  }, [packagingUnit, pUEdit]);

  return (
    <>
      {mode === ModalMode.DELETE ? (
        <ErrorOverlayButton
          className="btn btn-secondary"
          buttonText={
            <>
              <i className="fa fa-trash" />
              {i18n.t("warehouse:delete")}
            </>
          }
          errors={isPUUsed ? [i18n.t("warehouse:pUUsed")] : []}
          onClick={handleShow}
        />
      ) : (
        <button className={"btn " + (mode === ModalMode.EDIT ? "btn-secondary" : "btn-primary")} onClick={handleShow}>
          <i className={mode === ModalMode.EDIT ? "fa fa-pen" : " fas fa-plus-circle"} />
          {mode === ModalMode.EDIT ? i18n.t("common:edit") : i18n.t("common:create")}
        </button>
      )}
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {mode === ModalMode.EDIT
              ? i18n.t("warehouse:pUEdit")
              : mode === ModalMode.DELETE
              ? i18n.t("warehouse:pUDeletion")
              : i18n.t("warehouse:pUCreation")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">{i18n.t("packaging:label")}</div>
            <div className="col-8">
              <input
                className="form-control"
                value={packagingUnit.labelDe}
                disabled={mode === ModalMode.DELETE}
                onChange={mode === ModalMode.DELETE ? undefined : handleChangeLabel}
              />
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">{i18n.t("warehouse:length")}</div>
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  value={packagingUnit.length}
                  name="length"
                  type="number"
                  disabled={mode === ModalMode.DELETE}
                  onChange={mode === ModalMode.DELETE ? undefined : handleChangeNumericData}
                />
                <div className="input-group-append">
                  <span className="input-group-text">{DEFAULTLENGTHUNIT}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">{i18n.t("packaging:width")}</div>
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  value={packagingUnit.width}
                  name="width"
                  type="number"
                  disabled={mode === ModalMode.DELETE}
                  onChange={mode === ModalMode.DELETE ? undefined : handleChangeNumericData}
                />
                <div className="input-group-append">
                  <span className="input-group-text">{DEFAULTLENGTHUNIT}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">{i18n.t("packaging:height")}</div>
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  value={packagingUnit.height}
                  name="height"
                  type="number"
                  disabled={mode === ModalMode.DELETE}
                  onChange={mode === ModalMode.DELETE ? undefined : handleChangeNumericData}
                />
                <div className="input-group-append">
                  <span className="input-group-text">{DEFAULTLENGTHUNIT}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">{i18n.t("packaging:shape")}</div>
            <div className="col-8">
              <select
                value={packagingUnit.shape}
                disabled={mode === ModalMode.DELETE}
                className="form-control"
                onChange={mode === ModalMode.DELETE ? undefined : handleChangeForm}
              >
                {packagingOptions.map(s => (
                  <option key={s.value} value={s.value}>
                    {s.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">{i18n.t("warehouse:deadWeight")}</div>
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  value={packagingUnit.deadWeightValue}
                  name="deadWeightValue"
                  type="number"
                  disabled={mode === ModalMode.DELETE}
                  onChange={mode === ModalMode.DELETE ? undefined : handleChangeNumericData}
                />
                <div className="input-group-append">
                  <span className="input-group-text">{DEFAULTWEIGHTUNIT}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">
              {i18n.t("warehouse:maxFillingWeight")} ({i18n.t("warehouse:optional")})
            </div>
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  value={packagingUnit.maxFillingWeightValue}
                  name="maxFillingWeightValue"
                  type="number"
                  disabled={mode === ModalMode.DELETE}
                  onChange={mode === ModalMode.DELETE ? undefined : handleChangeNumericData}
                />
                <div className="input-group-append">
                  <span className="input-group-text">{DEFAULTWEIGHTUNIT}</span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            {i18n.t("common:close")}
          </button>
          {mode === ModalMode.DELETE ? (
            <ErrorOverlayButton
              errors={isPUUsed ? [i18n.t("warehouse:pUUsed")] : []}
              className="btn btn-danger"
              saving={saving}
              buttonText={i18n.t("warehouse:delete")}
              onClick={handleSave}
            />
          ) : (
            <ErrorOverlayButton
              buttonText={i18n.t("common:save")}
              className="btn btn-success"
              onClick={handleSave}
              errors={errors}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreatePackagingUnitModal;
