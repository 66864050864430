import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BSON } from "realm-web";
import SettingsTable from "./SettingsTable";
import { DataContext } from "../../context/dataContext";
import dbService, { COLORS } from "../../services/dbService";
import toastUtils from "../../utils/toastUtils";
import { ColorsDocument } from "../../model/colors.types";

interface ColorsProps extends RouteComponentProps<{}, {}, {}> {}

interface ColorsState {
  query: string;
}

class CommodityCategories extends PureComponent<ColorsProps, ColorsState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: ColorsProps) {
    super(props);
    this.state = { query: "" };
  }

  /**
   * Handles creating a new color.
   * @param _id: ID of the color that is created
   * @param de: Name of the color in german
   * @param en: Name of the color in english
   * @param image: Reference to the image representing the color
   */
  handleCreateColor = async (_id: BSON.ObjectId, de: string, en: string, image?: string) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.insertDocument(COLORS, {
      _id,
      name: { de, en },
      image: image ? image : ""
    });
    await toastUtils.databaseOperationToast(
      !!res && res.insertedId,
      "Color successfully added",
      "Adding color failed",
      () => updateDocumentInContext(COLORS, _id)
    );
  };

  /**
   * Handles editing a color.
   * @param _id: ID of the color that is edited
   * @param de: Name of the color in german
   * @param en: Name of the color in english
   * @param image: Reference to the image representing the color
   */
  handleEditColor = async (_id: BSON.ObjectId, de: string, en: string, image?: string) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.updateDocument(COLORS, _id, {
      name: { de, en },
      image: image ? image : ""
    });
    await toastUtils.databaseOperationToast(
      !!res && res.modifiedCount > 0,
      "Color successfully edited",
      "Editing color failed",
      () => updateDocumentInContext(COLORS, _id)
    );
  };

  /**
   * Handles deleting a color.
   * @param _id: ID of the color that should be deleted
   */
  handleDeleteColor = async (_id: BSON.ObjectId) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.deleteDocument(COLORS, _id);
    await toastUtils.databaseOperationToast(
      !!res && res.deletedCount > 0,
      "Color successfully deleted",
      "Deleting color failed",
      () => updateDocumentInContext(COLORS, _id)
    );
  };

  render() {
    const { query } = this.state;
    const { commodities, colors } = this.context;

    const ql = query.toLowerCase();
    const c = _.cloneDeep(
      colors.filter(c => c.name.de.toLowerCase().includes(ql) || c.name.en.toLowerCase().includes(ql))
    );

    return (
      <SettingsTable
        setting="Colors"
        settings={c.sort((c1, c2) => c1.name.de.localeCompare(c2.name.de))}
        title={{ table: "Colors", modal: "Color" }}
        usageCheck={_id => commodities.some(c => c.color && c.color.toString() === _id.toString())}
        onDelete={this.handleDeleteColor}
        onSave={this.handleCreateColor}
        onEdit={this.handleEditColor}
        onSearch={query => this.setState({ query })}
        additionalField={{
          title: "Code reference",
          content: (cc: ColorsDocument) => (
            <img
              src={
                cc.image
                  ? "https://mediahub.private-label-factory.com/" + cc.image
                  : "https://mediahub.private-label-factory.com/files/unknown.png"
              }
              style={{ width: "50px" }}
            />
          ),
          value: (cc: ColorsDocument) => cc.image
        }}
        additionalFieldImage={true}
      />
    );
  }
}

export default withRouter(CommodityCategories);
