import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { CommoditiesDocument, RawbidsCommodity } from "../../../model/commodities.types";
import config from "../../../config/config.json";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import {
  fetchRawbidsCommodities,
  filterRawbidsCommodities,
  getRawbidsCommodityProperty,
  RawbidsProperties
} from "../../../utils/rawbidsUtils";
import SplashScreen from "../../common/SplashScreen";
import Pagination, { paginate } from "../../common/Pagination";
import CommodityCountryWidget from "../common/CommodityCountryWidget";
import { DataContext } from "../../../context/dataContext";
import { SearchBar } from "../../listings/common/Filters";
import dbService, { COMMODITIES, UpdateAction } from "../../../services/dbService";
import toastUtils from "../../../utils/toastUtils";
import { RawbidsCommodityComparison } from "../RawbidsCommodityComparison";

const PAGESIZE = 10;

interface RawbidsConnectionModalProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
}

const RawbidsConnectionModal: React.FunctionComponent<RawbidsConnectionModalProps> = ({ commodity, context }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [rbCommodities, setRBCommodities] = useState<Array<RawbidsCommodity>>([]);
  const [selectedCommodity, setSelectedCommodity] = useState<RawbidsCommodity | null>(null);
  const isMounted = useRef(true); // component is mounted

  useEffect(() => {
    fetchRawbidsCommodities(context)
      .then(data => {
        if (isMounted.current) setRBCommodities(data);
      })
      .finally(() => setLoading(false));
    return () => {
      // handle unmounted component
      isMounted.current = false;
    };
  }, []);

  const isLinked = useMemo(() => commodity.rawbidsData?.rawbidsId, [commodity]);

  const handleButtonClick = useCallback(() => {
    if (isLinked) {
      window.open(`${config.rawbidsBase}commodity/${commodity.rawbidsData?.rawbidsId}`, "_blank");
    } else setShow(true);
  }, [isLinked]);

  const handleHide = useCallback(() => {
    setSelectedCommodity(null);
    setShow(false);
  }, []);

  const handleBack = useCallback(() => setSelectedCommodity(null), []);

  const handleSelectCommodity = useCallback((com: RawbidsCommodity) => setSelectedCommodity(com), []);

  const handleSaveConnection = useCallback(async () => {
    if (!selectedCommodity) return;
    setSaving(true);
    try {
      const action: UpdateAction = {
        collection: COMMODITIES,
        filter: { _id: commodity._id },
        update: {
          rawbidsData: {
            rawbidsId: selectedCommodity._id,
            commoditySnapshot: selectedCommodity,
            lastUpdate: new Date()
          }
        }
      };
      const res = await dbService.transaction([action]);
      toastUtils.databaseOperationToast(res, "Successfully updated commodity", "Commodity could not be updated", () => {
        handleHide();
        context.updateDocumentInContext(COMMODITIES, commodity._id);
      });
    } finally {
      setSaving(false);
    }
  }, [selectedCommodity]);

  return (
    <>
      <button type="button" className={"btn btn-sm btn-upper btn-dark bg-rawbids"} onClick={handleButtonClick}>
        <img
          style={{
            width: 12,
            height: 12,
            borderRadius: 0,
            objectFit: "cover"
          }}
          alt="R"
          src={"https://app.rawbids.com/rawbidsFavicon.ico"}
          className="country-icon mr-1"
        />
        {isLinked ? "Open" : "Connect"}
      </button>
      <Modal show={show} onHide={handleHide} centered name="RawbidsConnectionModal" size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Connect with Rawbids</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto" style={{ maxHeight: "80vh" }}>
          {loading ? (
            <div>
              <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
              <h5 className="text-center text-dark">Loading...</h5>
              <div className="text-center text-dark">Please wait. This may take a while.</div>
            </div>
          ) : selectedCommodity ? (
            <RawbidsCommodityComparison commodity={commodity} rawbidsCommodity={selectedCommodity} context={context} />
          ) : (
            <RawbidsCommoditySelection
              commodity={commodity}
              rbCommodities={rbCommodities}
              onSelectCommodity={handleSelectCommodity}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleHide}>
            Close
          </button>
          {selectedCommodity && (
            <button className="btn btn-secondary" onClick={handleBack}>
              Back
            </button>
          )}
          {selectedCommodity && (
            <ErrorOverlayButton
              buttonText={"Connect"}
              className={"btn btn-dark bg-rawbids"}
              errors={[]}
              onClick={handleSaveConnection}
              saving={saving}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface RawbidsCommoditySelectionProps {
  commodity: CommoditiesDocument;
  rbCommodities: Array<RawbidsCommodity>;
  onSelectCommodity: (com: RawbidsCommodity) => void;
}

const RawbidsCommoditySelection: React.FunctionComponent<RawbidsCommoditySelectionProps> = ({
  commodity,
  rbCommodities,
  onSelectCommodity
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>(commodity.title.en);

  const handleChangePage = useCallback(currentPage => setCurrentPage(currentPage), []);
  const handleSearch = useCallback(e => setSearch(e.target.value), []);

  const filteredCommodities = useMemo(() => filterRawbidsCommodities(rbCommodities, search), [rbCommodities, search]);

  return (
    <div>
      <div className="mb-2">
        <SearchBar search={search} onSearch={handleSearch} additionalSizeClasses={"col-12 col-lg-6"} />
      </div>
      <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "29%" }}>Name</th>
              <th style={{ width: "18%" }}>Appearance</th>
              <th style={{ width: "15%" }}>Category</th>
              <th style={{ width: "10%" }}>Form</th>
              <th style={{ width: "10%" }}>Origin</th>
              <th style={{ width: "18%" }}>Additional Information</th>
            </tr>
          </thead>
          <tbody>
            {filteredCommodities.length === 0 ? (
              <tr>
                <td colSpan={10}>
                  <img
                    src={process.env.PUBLIC_URL + "/media/img/no_results.jpg"}
                    style={{
                      display: "block",
                      margin: "0 auto",
                      height: "500px"
                    }}
                    alt="No results"
                  />
                </td>
              </tr>
            ) : (
              paginate(filteredCommodities, currentPage, PAGESIZE).map(com => (
                <RawbidsCommodityRow key={com._id} commodity={com} onSelectCommodity={onSelectCommodity} />
              ))
            )}
          </tbody>
        </table>
        <div className="kt-datatable__pager kt-datatable--paging-loaded justify-content-center">
          <Pagination
            itemsCount={filteredCommodities.length}
            pageSize={PAGESIZE}
            onPageChange={handleChangePage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
};

interface RawbidsCommodityRowProps {
  commodity: RawbidsCommodity;
  onSelectCommodity: (com: RawbidsCommodity) => void;
}

const RawbidsCommodityRow: React.FunctionComponent<RawbidsCommodityRowProps> = ({ commodity, onSelectCommodity }) => {
  const category = getRawbidsCommodityProperty(commodity, RawbidsProperties.CATEGORY);
  const composition = getRawbidsCommodityProperty(commodity, RawbidsProperties.COMPOSITION);

  const handleSelectCommodity = useCallback(() => onSelectCommodity(commodity), [commodity]);

  return (
    <tr className={"table-hover"} onClick={handleSelectCommodity}>
      <td className="align-middle">
        <span>
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name">
                {commodity.title.en}
                {commodity.organic && <i className="fas fa-leaf text-success ml-1" />}
              </span>
              <span className="kt-user-card-v2__email">{commodity.subtitle.en}</span>
            </div>
          </div>
        </span>
      </td>
      <td className="align-middle">{commodity.appearance.en || "-"}</td>
      <td className="align-middle">{category || "-"}</td>
      <td className="align-middle">{composition || "-"}</td>
      <td className="align-middle">
        <CommodityCountryWidget countryCode={commodity.country.code} />
      </td>
      <td className="align-middle">
        <div>HS Code: {commodity.hsCode}</div>
        <div>CAS Number: {commodity.casNumber.join(", ")}</div>
      </td>
    </tr>
  );
};

export default RawbidsConnectionModal;
