import _ from "lodash";
import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { CustomOrder } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import calculationUtils from "../../../utils/calculationUtils";
import { PackagingsDocument } from "../../../model/packagings.types";
import { T_CAPSULE, T_CUSTOM, T_LIQUID, T_POWDER, T_SERVICE, T_SOFTGEL, T_TABLET } from "../OrderHelper";
import packagingUtils from "../../../utils/packagingUtils";
import baseUtils from "../../../utils/baseUtils";
import { ALLTYPES } from "../../configurator/calculationDetails/calculationHelper";
import { CustomCalculationInfo } from "../../../model/orders.types";

interface OrderDetailsProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
}

interface OrderDetailsState {}

class OrderDetails extends PureComponent<OrderDetailsProps, OrderDetailsState> {
  /**
   * Get the displayed amount of a commodity
   * @param commodity object from an order recipe
   * @returns {string} formatted amount of the commodity
   */
  getCommodityAmount = (commodity: { id: BSON.ObjectID; amount: number; buffer: number }) => {
    const { order } = this.props;
    if ([T_CUSTOM, T_SOFTGEL, T_SERVICE].includes(order.settings.type)) {
      return order.settings.perUnit + " items";
    } else {
      return calculationUtils.formatAmount(commodity.amount, 2);
    }
  };

  /**
   * Resolves packaging information and builds common objects with information to be displayed
   * @param packaging the packaging document
   * @returns {string} packaging information
   */
  resolvePackagingProperties(packaging: PackagingsDocument) {
    return packagingUtils.resolvePackagingProperties(packaging);
  }

  renderCommodities = () => {
    const { order, context } = this.props;
    const { commodities } = context;
    return order.recipe.map(commodity => {
      const fullCommodity = commodities.find(comm => comm._id.toString() === commodity.id.toString());
      if (!fullCommodity) {
        console.error("No commodity found for", commodity.id.toString());
        return <></>;
      }
      return (
        <tr
          key={fullCommodity._id.toString()}
          className="kt-datatable__row kt-datatable__row--even kt-datatable__row--hover "
        >
          <td className="kt-datatable__cell pl-0" style={{ width: "15%" }}>
            <span>{this.getCommodityAmount(commodity)}</span>
          </td>
          <td className="kt-datatable__cell pl-0" style={{ width: "85%" }}>
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <span className="kt-user-card-v2__name">
                  {fullCommodity.title.en}
                  <span className="text-success"> {fullCommodity.article_number}</span>
                </span>
                <span className="kt-user-card-v2__email">{fullCommodity.subtitle.en}</span>
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  renderPackaging = () => {
    const { order, context } = this.props;
    const { packagings } = context;
    if (!(order.calculations && order.calculations[0] && order.calculations[0].packagings)) return <></>;
    return order.calculations[0].packagings.map(packaging => {
      const fullPackaging = packagings.find(pack => pack._id.toString() === packaging._id.toString());
      if (!fullPackaging) {
        console.error("No packaging found for", packaging._id.toString());
        return <></>;
      }
      return (
        <tr
          key={packaging._id.toString()}
          className="kt-datatable__row kt-datatable__row--even kt-datatable__row--hover "
        >
          <td className="kt-datatable__cell pl-0" style={{ width: "15%" }}>
            <span>{packaging.amount}</span>
          </td>
          <td className="kt-datatable__cell pl-0" style={{ width: "85%" }}>
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <span className="kt-user-card-v2__name">{this.resolvePackagingProperties(fullPackaging)}</span>
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  renderCustomCalculation = (customCalculation: CustomCalculationInfo, units: number) => {
    return (
      <table key={units} className="kt-datatable__table table-responsive">
        <thead className="kt-datatable__head">
          <tr className="kt-datatable__row" style={{ left: 0 }}>
            <th className="kt-datatable__cell pl-0" style={{ width: "10%" }}>
              <span>Units</span>
            </th>
            <th className="kt-datatable__cell pl-0" style={{ width: "30%" }}>
              <span>Description</span>
            </th>
            <th className="kt-datatable__cell pl-0" style={{ width: "30%" }}>
              <span>Manufacturer</span>
            </th>
            <th className="kt-datatable__cell pl-0" style={{ width: "30%" }}>
              <span>Costs</span>
            </th>
          </tr>
        </thead>
        <tbody className="kt-datatable__body">
          {ALLTYPES.map(t => {
            const unitPrice = customCalculation[t]?.unitPrice;
            if (unitPrice) {
              return (
                <tr key={t} className="kt-datatable__row kt-datatable__row--even kt-datatable__row--hover ">
                  <td className="kt-datatable__cell pl-0" style={{ width: "10%" }}>
                    {units}
                  </td>
                  <td className="kt-datatable__cell pl-0" style={{ width: "30%" }}>
                    {_.upperFirst(t)}
                  </td>
                  <td className="kt-datatable__cell pl-0" style={{ width: "30%" }}>
                    <span className="kt-font">
                      {
                        baseUtils.getDocFromCollection(
                          this.props.context.manufacturers,
                          customCalculation[t]?.manufacturerId || ""
                        )?.name
                      }
                    </span>
                  </td>
                  <td className="kt-datatable__cell pl-0" style={{ width: "30%" }}>
                    <span className="kt-font-bolder">{baseUtils.formatEuro(unitPrice)} per Unit</span>
                  </td>
                </tr>
              );
            }
          })}
          {!!customCalculation.optionalCosts && (
            <tr className="kt-datatable__row kt-datatable__row--even kt-datatable__row--hover ">
              <td className="kt-datatable__cell pl-0" style={{ width: "10%" }}></td>
              <td className="kt-datatable__cell pl-0" style={{ width: "30%" }}>
                Additional Costs
              </td>
              <td className="kt-datatable__cell pl-0" style={{ width: "30%" }}></td>
              <td className="kt-datatable__cell pl-0" style={{ width: "30%" }}>
                <span className="kt-font-bolder">{baseUtils.formatEuro(customCalculation.optionalCosts)}</span>
              </td>
            </tr>
          )}
          {!!customCalculation.note && (
            <tr className="kt-datatable__row kt-datatable__row--even kt-datatable__row--hover ">
              <td className="kt-datatable__cell pl-0" style={{ width: "10%" }}>
                Note
              </td>
              <td className="kt-datatable__cell pl-0" style={{ width: "90%" }} colSpan={3}>
                <span className="kt-font">{customCalculation.note}</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  render() {
    const { order, context } = this.props;
    const customCalcs = order.calculations.filter(c => c.info.customCalculation);
    return (
      <>
        <div className="row">
          <div className="col-6">
            <h4 style={{ fontSize: "1.3rem", color: "#48465b", fontWeight: 600 }}>General</h4>
            <OrderDetailsGeneral order={order} context={context} />
          </div>
          {order.settings.type !== T_SERVICE && (
            <div className="col-6">
              <h4 style={{ fontSize: "1.3rem", color: "#48465b", fontWeight: 600 }}>Packagings</h4>
              {!(order.calculations && order.calculations[0] && order.calculations[0].packagings) ? (
                <span style={{ fontSize: "1.3rem", color: "#48465b", fontWeight: 400 }} className="mt-5 pt-5">
                  No packaging or calculation
                </span>
              ) : order.calculations[0].packagings.length === 0 ? (
                <span style={{ fontSize: "1.3rem", color: "#48465b", fontWeight: 400 }} className="mt-5 pt-5">
                  Bulk
                </span>
              ) : (
                <div className="kt-datatable kt-datatable--default kt-datatable--loaded">
                  <table className="kt-datatable__table" style={{ display: "block" }}>
                    <thead className="kt-datatable__head">
                      <tr className="kt-datatable__row" style={{ left: 0 }}>
                        <th className="kt-datatable__cell pl-0" style={{ width: "15%" }}>
                          <span>Amount</span>
                        </th>
                        <th className="kt-datatable__cell pl-0" style={{ width: "85%" }}>
                          <span>Packaging</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="kt-datatable__body">{this.renderPackaging()}</tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-6">
            <h4 style={{ fontSize: "1.3rem", color: "#48465b", fontWeight: 600 }}>
              {order.settings.type === T_SERVICE ? "Service" : "Recipe"}
            </h4>
            <div className="kt-datatable kt-datatable--default kt-datatable--loaded">
              <table className="kt-datatable__table table-responsive">
                <thead className="kt-datatable__head">
                  <tr className="kt-datatable__row" style={{ left: 0 }}>
                    <th className="kt-datatable__cell pl-0" style={{ width: "15%" }}>
                      <span>Amount</span>
                    </th>
                    <th className="kt-datatable__cell pl-0" style={{ width: "85%" }}>
                      <span>Commodity</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="kt-datatable__body">{this.renderCommodities()}</tbody>
              </table>
            </div>
          </div>
          {customCalcs.length > 0 && (
            <div className="col-6">
              <h4 style={{ fontSize: "1.3rem", color: "#48465b", fontWeight: 600 }}>Custom Calculation</h4>
              <div className="kt-datatable kt-datatable--default kt-datatable--loaded">
                {customCalcs.map((cc, index) => {
                  if (cc.info.customCalculation) {
                    return this.renderCustomCalculation(cc.info.customCalculation, cc.units);
                  }
                })}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

interface OrderDetailsGeneralProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
}

export const OrderDetailsGeneral: React.FunctionComponent<OrderDetailsGeneralProps> = ({ order, context }) => {
  const { capsules, tablets } = context;
  let content;
  switch (order.settings.type) {
    case T_CAPSULE:
      const capsule = capsules.find(cap => cap._id.toString() === order.settings.id.toString());
      content =
        _.upperFirst(order.settings.type) +
        (capsule ? `, size ${capsule.capsule_size}, ${capsule.capsule_material.en}, ${capsule.capsule_color.en}` : "");
      break;
    case T_TABLET:
      const tablet = tablets.find(tab => tab._id.toString() === order.settings.id.toString());
      content = _.upperFirst(order.settings.type) + (tablet ? `,  ${tablet.volume}ml, shape: ${tablet.shape}` : "");
      break;
    default:
      content = _.upperFirst(order.settings.type);
      break;
  }

  let amountPerUnit;
  switch (order.settings.type) {
    case T_CAPSULE:
      amountPerUnit = `${order.settings.perUnit} capsules`;
      break;
    case T_TABLET:
      amountPerUnit = `${order.settings.perUnit} tablets`;
      break;
    case T_POWDER:
    case T_LIQUID:
      amountPerUnit = `${calculationUtils.formatAmount(order.settings.perUnit, 2)}`;
      break;
    case T_CUSTOM:
    case T_SOFTGEL:
    case T_SERVICE:
      amountPerUnit = `${order.settings.perUnit} items`;
      break;
  }

  return (
    <>
      <div className="kt-datatable kt-datatable--default kt-datatable--loaded">
        <table className="kt-datatable__table table-responsive">
          <thead className="kt-datatable__head">
            <tr className="kt-datatable__row" style={{ left: 0 }}>
              <th className="kt-datatable__cell pl-0" style={{ width: "15%" }}>
                <span>Value</span>
              </th>
              <th className="kt-datatable__cell pl-0" style={{ width: "85%" }}>
                <span>Description</span>
              </th>
            </tr>
          </thead>
          <tbody className="kt-datatable__body">
            <tr className="kt-datatable__row kt-datatable__row--even kt-datatable__row--hover ">
              <td className="kt-datatable__cell pl-0" style={{ width: "15%" }}>
                <span>Product Type</span>
              </td>
              <td className="kt-datatable__cell pl-0" style={{ width: "85%" }}>
                <span className="kt-font-bolder">{content}</span>
              </td>
            </tr>
            <tr className="kt-datatable__row kt-datatable__row--even kt-datatable__row--hover ">
              <td className="kt-datatable__cell pl-0" style={{ width: "15%" }}>
                <span>Per Unit</span>
              </td>
              <td className="kt-datatable__cell pl-0" style={{ width: "85%" }}>
                <span className="kt-font-bolder">{amountPerUnit}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default OrderDetails;
