import React from "react";
import { Table } from "react-bootstrap";
import { PohodaEntry } from "../../../../utils/pohoda/pohodaUtils";

interface MissingCommodityOrBatchOverviewProps {
  missingPohodaEntries: Array<PohodaEntry>;
}

const MissingCommodityOrBatchOverview: React.FunctionComponent<MissingCommodityOrBatchOverviewProps> = ({
  missingPohodaEntries
}) => {
  return (
    <Table>
      <thead>
        <tr>
          <th style={{ width: "33%" }}>Internal Code</th>
          <th style={{ width: "33%" }}>Lot</th>
          <th style={{ width: "33%" }}>Name</th>
        </tr>
      </thead>
      <tbody>
        {missingPohodaEntries.map((missingEntry, index) => (
          <tr key={missingEntry.name + index}>
            <td className="align-middle font-weight-bold">{missingEntry.internalCode}</td>
            <td className="align-middle font-weight-bold">{missingEntry.lot}</td>
            <td className="align-middle font-weight-bold">{missingEntry.name}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default MissingCommodityOrBatchOverview;
