import _ from "lodash";
import React, { PureComponent } from "react";
import Select from "react-select";
import { RouteComponentProps } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic";
import { DataContext } from "../../context/dataContext";
import { PaginationState } from "../common/CustomTypes";
import HistoryBackButton from "../listings/common/HistoryBackButton";
import manufacturerUtils from "../../utils/manufacturerUtils";
import CommoditiesStockListing from "./Commodities/CommoditiesStockListing";
import { ManufacturerFilter, SearchBar } from "../listings/common/Filters";
import baseUtils, { getComponentState } from "../../utils/baseUtils";
import { CommoditiesDocument } from "../../model/commodities.types";
import { PackagingsDocument } from "../../model/packagings.types";
import PackagingStockListing from "./Packaging/PackagingStockListing";
import packagingUtils, { PACKAGING_TYPES } from "../../utils/packagingUtils";
import { LocalStock } from "../commodities/CustomTypes";
import { ColorsDocument } from "../../model/colors.types";
import { LocalPackagingStock } from "../packaging/CustomTypes";
import commodityUtils from "../../utils/commodityUtils";

export interface StockStatistics {
  stock: number;
  ordered: number;
  reserved: number;
  available: number;
}

export interface CommodityExtended {
  categoryName: string | undefined;
  color: ColorsDocument | undefined;
  commodity: CommoditiesDocument;
  stockStatistics: StockStatistics;
  manufacturerRelatedLocalStock: LocalStock | undefined;
  manufacturerRelatedDisabledLocalStock: LocalStock | undefined;
}

export interface PackagingExtended {
  packaging: PackagingsDocument;
  stockStatistics: StockStatistics;
  manufacturerRelatedLocalStock: LocalPackagingStock | undefined;
}

interface StockListingProps extends RouteComponentProps {
  context: React.ContextType<typeof DataContext>;
}

interface StockListingState extends PaginationState {
  commodityCategory: { value: string; label: string };
  commodityComposition: { value: string; label: string };
  expandAll: boolean;
  filteredCommodities: Array<CommodityExtended>;
  filteredPackagings: Array<PackagingExtended>;
  manufactureScopedCommodities: Array<CommoditiesDocument>;
  manufactureScopedPackagings: Array<PackagingsDocument>;
  manufacturer: { value: string; label: string };
  manufacturerLocked: boolean;
  packagingColor: "" | { value: string; label: string };
  packagingTypeFilter: { value: string; label: string };
  query: string;
  showDisabledStock: { value: boolean; label: string };
  type: { value: string; label: string };
  sortOrder: { value: "asc"; label: "Ascending" } | { value: "desc"; label: "Descending" };
  selectedStatistic:
    | { value: "currentStock"; label: "Current Stock" }
    | { value: "ordered"; label: "Ordered" }
    | { value: "reserved"; label: "Reserved" }
    | { value: "available"; label: "Available" };
  contentPage: number;
  contentPageSize: number;
  selectedContent: Array<boolean>;
}

const CONSTRUCTORNAME = "StockListing";

class StockListing extends PureComponent<StockListingProps, StockListingState> {
  constructor(props: StockListingProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, CONSTRUCTORNAME);
    if (state) this.setState({ ...state });
    else {
      const relevantCommodities = this.getManufacturerScopedCommodities();
      const relevantPackagings = this.getManufacturerScopedPackagings();

      this.setState({
        manufactureScopedCommodities: relevantCommodities,
        manufactureScopedPackagings: relevantPackagings,
        filteredCommodities: this.getFilteredStockCommodities(relevantCommodities),
        filteredPackagings: this.getFilteredStockPackagings(relevantPackagings)
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<StockListingProps>, prevState: Readonly<StockListingState>) {
    const {
      expandAll,
      query,
      manufacturer,
      commodityCategory,
      commodityComposition,
      packagingTypeFilter,
      packagingColor,
      showDisabledStock,
      type,
      sortOrder,
      selectedStatistic,
      contentPageSize,
      manufactureScopedCommodities,
      manufactureScopedPackagings
    } = this.state;

    const newSelections = new Array<boolean>(contentPageSize).fill(expandAll);
    if (type.value === "commodities") {
      if (
        !_.isEqual(prevProps.context.commodities, this.props.context.commodities) ||
        prevState.manufacturer !== manufacturer
      ) {
        // recalculate the relevant commodities
        const relevantCommodities = this.getManufacturerScopedCommodities();
        this.setState({
          manufactureScopedCommodities: relevantCommodities,
          filteredCommodities: this.getFilteredStockCommodities(relevantCommodities),
          contentPage: 1,
          selectedContent: newSelections
        });
      } else if (
        !_.isEqual(prevState.commodityComposition, commodityComposition) ||
        !_.isEqual(prevState.commodityCategory, commodityCategory) ||
        !_.isEqual(prevState.sortOrder, sortOrder) ||
        !_.isEqual(prevState.selectedStatistic, selectedStatistic) ||
        prevState.query !== query ||
        prevState.showDisabledStock !== showDisabledStock
      ) {
        this.setState({
          filteredCommodities: this.getFilteredStockCommodities(manufactureScopedCommodities),
          contentPage: 1,
          selectedContent: newSelections
        });
      }
    } else {
      // type packagings
      if (
        !_.isEqual(prevProps.context.packagings, this.props.context.packagings) ||
        !_.isEqual(prevProps.context.packagingStock, this.props.context.packagingStock) ||
        prevState.manufacturer !== manufacturer
      ) {
        // recalculate the relevant packagings
        const relevantPackagings = this.getManufacturerScopedPackagings();
        this.setState({
          manufactureScopedPackagings: relevantPackagings,
          filteredPackagings: this.getFilteredStockPackagings(relevantPackagings),
          contentPage: 1,
          selectedContent: newSelections
        });
      } else if (
        !_.isEqual(prevState.packagingTypeFilter, packagingTypeFilter) ||
        !_.isEqual(prevState.sortOrder, sortOrder) ||
        !_.isEqual(prevState.selectedStatistic, selectedStatistic) ||
        prevState.query !== query ||
        prevState.packagingColor !== packagingColor ||
        prevState.showDisabledStock !== showDisabledStock
      ) {
        this.setState({
          filteredPackagings: this.getFilteredStockPackagings(manufactureScopedPackagings),
          contentPage: 1,
          selectedContent: newSelections
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ query: e.target.value });

  handleSelectChange = (
    name: string,
    entry: "" | { value: string; label: string } | { value: boolean; label: string }
  ) => {
    if (name !== "type") {
      // @ts-ignore
      this.setState({ [name]: entry ? entry : "" });
    } else {
      // @ts-ignore
      this.setState({ [name]: entry ? entry : "", activeLevel: "-1" });
    }
  };

  handleContentToggle = (e: number) => {
    const selectedContent = _.cloneDeep(this.state.selectedContent);
    selectedContent[e] = !selectedContent[e];
    this.setState({ selectedContent });
  };

  handlePageChange = (e: number) => {
    const { contentPageSize, expandAll } = this.state;
    const selectedContent = new Array<boolean>(contentPageSize).fill(expandAll);
    this.setState({ selectedContent, contentPage: e });
  };

  handlePageSizeChange = (e: number) => {
    const { contentPageSize, expandAll } = this.state;
    const selectedContent = _.cloneDeep(this.state.selectedContent);

    if (e > contentPageSize) {
      const extendedActiveLevels = new Array<boolean>(e - contentPageSize).fill(expandAll);
      selectedContent.concat(extendedActiveLevels);
    } else {
      const difference = contentPageSize - e;
      selectedContent.splice(contentPageSize - difference - 1, difference);
    }

    this.setState({ selectedContent, contentPageSize: e });
  };

  handleExpandAll = () => {
    const { contentPageSize } = this.state;
    this.setState({ selectedContent: new Array<boolean>(contentPageSize).fill(true), expandAll: true });
  };

  handleCompressAll = () => {
    const { contentPageSize } = this.state;
    this.setState({ selectedContent: new Array<boolean>(contentPageSize).fill(false), expandAll: false });
  };

  handleReset = () => {
    this.setState({
      commodityCategory: { value: "", label: "All Categories" },
      commodityComposition: { value: "", label: "All Compositions" },
      expandAll: false,
      packagingColor: "",
      packagingTypeFilter: { value: "", label: "All Packaging" },
      query: "",
      showDisabledStock: { value: false, label: "Show enabled" },
      sortOrder: { value: "desc", label: "Descending" },
      selectedStatistic: { value: "currentStock", label: "Current Stock" },
      type: { value: "commodities", label: "Commodities" },
      contentPage: 1,
      contentPageSize: 10
    });
  };

  /**
   * Get the default state
   * @returns {StockListingState} default state for the component
   */
  getDefaultState = () => {
    const userManufacturer = manufacturerUtils.checkCurrentUserManufacturerObject(this.props.context.manufacturers);
    const revi = this.props.context.manufacturers.find(m => m._id.toString() === "5ef4fd72a4cf4fbc3202f609");
    const manufacturerScopedDefaultCommodities = [] as Array<CommoditiesDocument>;
    const manufacturerScopedDefaultPackaging = [] as Array<PackagingsDocument>;
    const defaultCommodities = [] as Array<CommodityExtended>;
    const defaultPackagings = [] as Array<PackagingExtended>;
    const defaultSelectedContent = [] as Array<boolean>;
    const manufacturer: { value: string; label: string } | "" =
      userManufacturer !== ""
        ? userManufacturer
        : revi
        ? { value: revi!._id.toString(), label: revi!.name }
        : {
            value: this.props.context.manufacturers[0]._id.toString(),
            label: this.props.context.manufacturers[0]._id.name
          };

    return {
      commodityCategory: { value: "", label: "All Categories" },
      commodityComposition: { value: "", label: "All Compositions" },
      expandAll: false,
      filteredCommodities: defaultCommodities,
      filteredPackagings: defaultPackagings,
      manufactureScopedCommodities: manufacturerScopedDefaultCommodities,
      manufactureScopedPackagings: manufacturerScopedDefaultPackaging,
      manufacturer: manufacturer,
      manufacturerLocked: userManufacturer !== "",
      packagingColor: "",
      packagingTypeFilter: { value: "", label: "All Packaging" },
      query: "",
      showDisabledStock: { value: false, label: "Show enabled" },
      sortOrder: { value: "desc", label: "Descending" },
      selectedStatistic: { value: "currentStock", label: "Current Stock" },
      type: { value: "commodities", label: "Commodities" },
      contentPage: 1,
      contentPageSize: 10,
      selectedContent: defaultSelectedContent
    } as StockListingState;
  };

  /**
   * Receive the relevant commodities corresponding to the pre-/selected manufacturer
   * @returns {Array<CommoditiesDocument>} manufacturer scoped commodities
   */
  getManufacturerScopedCommodities = () => {
    const { context } = this.props;
    const { commodities } = context;
    const { manufacturer } = this.state;

    return commodities.filter(com => {
      return com.stock.some(stockEntity => {
        return stockEntity.location.toString() === manufacturer.value;
      });
    });
  };

  /**
   * Method to receive the local and local disabled stock of a commodity
   * @param relatedCommodity the related commodity to check
   * @returns {localStock: LocalStock,localDisabledStock: LocalStock} the corresponding local and local disabled stock
   */
  receiveRelatedLocalStockOfCommodity = (relatedCommodity: CommoditiesDocument) => {
    const { context } = this.props;
    const { manufacturer } = this.state;
    const { manufacturers } = context;
    let localStock = {} as LocalStock;
    let localDisabledStock = {} as LocalStock;

    // receive corresponding manufacturer
    const manufacturerDoc = baseUtils.getDocFromCollection(manufacturers, manufacturer.value);
    if (manufacturerDoc) {
      const stock = [];
      const disabledStock = [];
      for (let j = 0; j < relatedCommodity.stock.length; j++) {
        const b = relatedCommodity.stock[j];
        if (manufacturerDoc._id.toString() === b.location.toString() && !b.disabled) {
          stock.push(b);
        } else if (manufacturerDoc._id.toString() === b.location.toString() && b.disabled) {
          disabledStock.push(b);
        }
      }
      localStock = { manufacturer: manufacturerDoc, localStock: stock };
      localDisabledStock = { manufacturer: manufacturerDoc, localStock: disabledStock };
    }
    return { localStock, localDisabledStock };
  };

  /**
   * Function to extend a commodity for specific values (color, stock, categoryName)
   * @param commodity commodity to be extended
   * @returns {CommodityExtended} the extended commodity
   */
  extendCommodity = (commodity: CommoditiesDocument) => {
    const { context } = this.props;
    const { manufacturer } = this.state;
    // receive Corresponding Color
    const color = commodity.color
      ? context.colors.find(col => commodity.color && col._id.toString() === commodity.color!.toString())
      : undefined;

    // receive Corresponding Stock
    const stock = commodity.stock.reduce(
      (stock, batch) => stock + (manufacturer.value === batch.location.toString() ? batch.amount : 0),
      0
    );

    // receive category name
    const categoryName = context.commoditycategories.find(
      category => category._id.toString() === commodity.category.toString()
    )?.name.en;

    // receive the manufacturer related local stock
    const { localStock, localDisabledStock } = this.receiveRelatedLocalStockOfCommodity(commodity);

    // calculate Stock statistics
    const stockStatistics = commodityUtils.calculateStockValues(commodity, context, localStock) as StockStatistics;

    return {
      categoryName: categoryName,
      color: color,
      commodity: commodity,
      stockStatistics: stockStatistics,
      manufacturerRelatedLocalStock: localStock,
      manufacturerRelatedDisabledLocalStock: localDisabledStock
    } as CommodityExtended;
  };

  /**
   * Method to filter the relevant commodities corresponding to specific state filter parameter
   * @param commodities manufacturer scoped commodities
   * @returns {Array<CommodityExtended>} filtered commodities
   */
  getFilteredStockCommodities = (commodities: Array<CommoditiesDocument>) => {
    const { sortOrder, selectedStatistic } = this.state;
    let filteredStockCommodities = commodities;

    // checking for search term
    filteredStockCommodities = this.filterCommoditiesBySearch(commodities);

    // filter by category
    filteredStockCommodities = this.filterCommoditiesByCategory(filteredStockCommodities);

    // filter by composition
    filteredStockCommodities = this.filterCommoditiesByComposition(filteredStockCommodities);

    // return extended filtered commodities
    let extendedFilteredCommodities = filteredStockCommodities
      .map(filteredCommodity => this.extendCommodity(filteredCommodity))
      .filter(extendedCommodity => extendedCommodity.manufacturerRelatedLocalStock !== undefined);

    // order by specific stock values
    extendedFilteredCommodities = _.orderBy(
      extendedFilteredCommodities,
      item => {
        switch (selectedStatistic.value) {
          case "available":
            return item.stockStatistics.available;
          case "currentStock":
            return item.stockStatistics.stock;
          case "ordered":
            return item.stockStatistics.ordered;
          case "reserved":
            return item.stockStatistics.reserved;
        }
      },
      sortOrder.value
    );
    return extendedFilteredCommodities;
  };

  /**
   * Filter commodities by search terms
   * @param commodities manufacturer scoped commodities
   * @returns {Array<CommoditiesDocument>} filtered result
   */
  filterCommoditiesBySearch = (commodities: Array<CommoditiesDocument>) => {
    const { query } = this.state;
    let filteredCommodities = commodities;
    const ql = query.trim();

    // checking for search term
    if (ql !== "") {
      filteredCommodities = baseUtils.doFuseSearch(filteredCommodities, ql, [
        "title.en",
        "title.de",
        "subtitle.en",
        "subtitle.de",
        "identifier"
      ]);
    }
    return filteredCommodities;
  };

  /**
   * Filter commodities by category
   * @param commodities manufacturer scoped commodities
   * @returns {Array<CommoditiesDocument>} filtered result
   */
  filterCommoditiesByCategory = (commodities: Array<CommoditiesDocument>) => {
    const { commodityCategory } = this.state;
    let filteringResult = commodities;

    if (commodityCategory.value !== "") {
      filteringResult = filteringResult.filter(com => com.category.toString() === commodityCategory.value);
    }

    return filteringResult;
  };

  /**
   * Filter commodities by composition
   * @param commodities manufacturer scoped commodities
   * @returns {Array<CommoditiesDocument>} filtered result
   */
  filterCommoditiesByComposition = (commodities: Array<CommoditiesDocument>) => {
    const { commodityComposition } = this.state;
    let filteringResult = commodities;

    if (commodityComposition.value !== "") {
      filteringResult = filteringResult.filter(commodity => commodity.form.toString() === commodityComposition.value);
    }

    return filteringResult;
  };

  /**
   * Receive the relevant packaging's corresponding to the pre-/selected manufacturer
   * @returns {Array<PackagingsDocument>} manufacturer scoped packaging's
   */
  getManufacturerScopedPackagings = () => {
    const { context } = this.props;
    const { manufacturer } = this.state;
    const { packagingStock, packagings } = context;

    // receive the relevant packaging's ids which are in stock corresponding to the selected manufacturer
    const relevantPackagingsIds = packagingStock
      .filter(ps => ps.location.toString() === manufacturer.value)
      .map(filteredPackagingStock => filteredPackagingStock.packaging.toString());

    // receive the relevant packaging's
    return packagings.filter(packaging => relevantPackagingsIds.includes(packaging._id.toString()));
  };

  /**
   * Function to extend a packaging for specific values (local stock)
   * @param packaging packaging to be extended
   * @returns {PackagingExtended} the extended packaging
   */
  extendPackaging = (packaging: PackagingsDocument) => {
    const { context } = this.props;
    // receive the manufacturer related local stock
    const localStock = this.receiveRelatedLocalStockOfPackaging(packaging);
    const stockStatistics = packagingUtils.calculateStockValues(packaging, context, localStock) as StockStatistics;

    return {
      packaging: packaging,
      stockStatistics: stockStatistics,
      manufacturerRelatedLocalStock: localStock
    } as PackagingExtended;
  };

  /**
   * Method to receive the local stock of a packaging
   * @param packaging the related packaging
   * @returns {LocalPackagingStock} the corresponding local stock
   */
  receiveRelatedLocalStockOfPackaging = (packaging: PackagingsDocument) => {
    const { context } = this.props;
    const { manufacturer } = this.state;
    const { packagingStock, manufacturers } = context;
    const manufacturerDoc = manufacturers.find(man => man._id.toString() === manufacturer.value);
    let localStock = {} as LocalPackagingStock;

    if (manufacturerDoc) {
      const stockForPackaging = packagingStock.filter(ps => ps.packaging.toString() === packaging._id.toString());
      const stock = [];
      for (let j = 0; j < stockForPackaging.length; j++) {
        const b = stockForPackaging[j];
        if (manufacturerDoc._id.toString() === b.location.toString()) {
          stock.push(b);
        }
      }
      localStock = { manufacturer: manufacturerDoc, stock: stock };
    }
    return localStock;
  };

  /**
   * Method to filter the relevant packaging's corresponding to specific state filter parameter
   * @param packagings manufacturer scoped packaging's
   * @returns {Array<PackagingExtended>} filtered packaging's
   */
  getFilteredStockPackagings = (packagings: Array<PackagingsDocument>) => {
    const { sortOrder, selectedStatistic } = this.state;
    let filteredPackagings = this.filterPackagingsBySearch(packagings);

    // filtering by selected types
    filteredPackagings = this.filterPackagingsByType(filteredPackagings);

    // filtering by color
    filteredPackagings = this.filterPackagingsByColor(filteredPackagings);

    // return extended packagings
    let extendedFilteredPackagings = filteredPackagings
      .map(filteredPackaging => this.extendPackaging(filteredPackaging))
      .filter(extendedPackaging => extendedPackaging.manufacturerRelatedLocalStock !== undefined);

    // order by specific stock value
    extendedFilteredPackagings = _.orderBy(
      extendedFilteredPackagings,
      item => {
        switch (selectedStatistic.value) {
          case "available":
            return item.stockStatistics.available;
          case "currentStock":
            return item.stockStatistics.stock;
          case "ordered":
            return item.stockStatistics.ordered;
          case "reserved":
            return item.stockStatistics.reserved;
        }
      },
      sortOrder.value
    );

    return extendedFilteredPackagings;
  };

  /**
   * Filter packaging's by search terms
   * @param packagings manufacturer scoped packaging's
   * @returns {Array<PackagingsDocument>} filtered result
   */
  filterPackagingsBySearch = (packagings: Array<PackagingsDocument>) => {
    const { query } = this.state;
    const ql = query.trim();
    let filteringResult = packagings;

    // checking for search term
    if (ql !== "") {
      filteringResult = baseUtils.doFuseSearch(packagings, ql, [""], {
        getFn: (obj: PackagingsDocument) => {
          return packagingUtils.concatPackagingInfo(obj);
        }
      });
    }
    return filteringResult;
  };

  /**
   * Filter packaging's by type
   * @param packagings manufacturer scoped packaging's
   * @returns {Array<PackagingsDocument>} filtered result
   */
  filterPackagingsByType = (packagings: Array<PackagingsDocument>) => {
    const { packagingTypeFilter } = this.state;
    let filteringResult = packagings;

    if (packagingTypeFilter.value !== "") {
      filteringResult = filteringResult.filter(packaging => packaging.packaging_type === packagingTypeFilter.value);
    }
    return filteringResult;
  };

  /**
   * Filter packaging's by color
   * @param packagings manufacturer scoped packaging's
   * @returns {Array<PackagingsDocument>} filtered result
   */
  filterPackagingsByColor = (packagings: Array<PackagingsDocument>) => {
    const { packagingColor } = this.state;
    let filteringResult = packagings;

    if (packagingColor !== "") {
      filteringResult = filteringResult.filter(packaging => {
        if (packaging.packaging_color) {
          return packaging.packaging_color === packagingColor.value;
        }
        if (packaging.lid_color) {
          return packaging.lid_color === packagingColor.value;
        }
        if (packaging.bag_color) {
          return packaging.bag_color === packagingColor.value;
        }
      });
    }

    return filteringResult;
  };

  render() {
    const { context, history } = this.props;
    const { commoditycategories, compositions } = context;
    const {
      manufacturer,
      manufacturerLocked,
      type,
      showDisabledStock,
      selectedStatistic,
      sortOrder,
      contentPage,
      contentPageSize,
      selectedContent,
      query,
      packagingTypeFilter,
      commodityCategory,
      commodityComposition,
      packagingColor,
      filteredCommodities,
      filteredPackagings
    } = this.state;
    return (
      <>
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand fas fa-warehouse" />
              </span>
              <h3 className="kt-portlet__head-title">Stock Overview</h3>
              <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
                Reset
              </button>
            </div>
            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-wrapper">
                <button className="btn btn-clean kt-margin-r-10" onClick={this.handleExpandAll}>
                  <i className="fa fa-chevron-down" />
                  <span className="kt-hidden-mobile">Expand all</span>
                </button>
                <button className="btn btn-clean kt-margin-r-10" onClick={this.handleCompressAll}>
                  <i className="fa fa-chevron-up" />
                  <span className="kt-hidden-mobile">Compress all</span>
                </button>
                <HistoryBackButton history={history} />
              </div>
            </div>
          </div>
          <div className="my-4 px-2 pt-2 font-weight-bolder text-dark row">
            <div className="col-2 align-self-center">
              <div className="pl-5 kt-widget__label">
                <div className="h4 text-dark" style={{ color: "rgb(72, 70, 91)" }}>
                  {context.manufacturers.find(m => m._id.toString() === manufacturer.value)?.name}
                </div>
              </div>
            </div>
            <SearchBar onSearch={this.handleSearch} search={query} additionalSizeClasses="col-2" />
            <div className="col-2 kt-margin-b-20-tablet-and-mobile">
              <Select
                className="select-default"
                options={[
                  { value: "commodities", label: "Commodities" },
                  { value: "packagings", label: "Packagings" }
                ]}
                value={
                  type.value === "commodities"
                    ? { value: "commodities", label: "Commodities" }
                    : { value: "packagings", label: "Packagings" }
                }
                onChange={(value: any) =>
                  this.handleSelectChange("type", value ? value : { value: "commodities", label: "Commodities" })
                }
              />
            </div>
            <div className="col-2 kt-margin-b-20-tablet-and-mobile">
              <Select
                className="select-default"
                options={[
                  { value: false, label: "Show enabled" },
                  { value: true, label: "Show disabled" }
                ]}
                value={showDisabledStock}
                onChange={(value: any) =>
                  this.handleSelectChange("showDisabledStock", value ? value : { value: false, label: "Show enabled" })
                }
              />
            </div>
            <div className="col-2 kt-margin-b-20-tablet-and-mobile">
              <ManufacturerFilter
                notClearable
                noLabel={true}
                additionalSizeClasses={"select-default"}
                manufacturer={manufacturer}
                manufacturers={context.manufacturers}
                manufacturerLocked={manufacturerLocked}
                onFilterSelect={this.handleSelectChange}
              />
            </div>
          </div>
          <div className="mb-3 px-2 pt-2 font-weight-bolder text-dark row">
            {type.value === "commodities" ? (
              <>
                <div className="col-2" />
                <div className="col-2 kt-margin-b-20-tablet-and-mobile">
                  <Select
                    className="select-default"
                    isClearable={true}
                    options={commoditycategories.map(category => {
                      return {
                        value: category._id.toString(),
                        label: category.name.en
                      };
                    })}
                    value={commodityCategory ? commodityCategory : { value: "", label: "All Categories" }}
                    onChange={(value: any) =>
                      this.handleSelectChange(
                        "commodityCategory",
                        value ? value : { value: "", label: "All Categories" }
                      )
                    }
                  />
                </div>
                <div className="col-2 kt-margin-b-20-tablet-and-mobile">
                  <Select
                    className="select-default"
                    isClearable={true}
                    options={compositions.map(composition => {
                      return {
                        value: composition._id.toString(),
                        label: composition.name.en
                      };
                    })}
                    value={commodityComposition ? commodityComposition : { value: "", label: "All Compositions" }}
                    onChange={(value: any) =>
                      this.handleSelectChange(
                        "commodityComposition",
                        value ? value : { value: "", label: "All Compositions" }
                      )
                    }
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-2" />
                <div className="col-2 kt-margin-b-20-tablet-and-mobile">
                  <Select
                    className="select-default"
                    isClearable={true}
                    options={PACKAGING_TYPES.map(p => {
                      return {
                        value: p.value,
                        label: p.label
                      };
                    })}
                    value={
                      packagingTypeFilter
                        ? {
                            value: packagingTypeFilter.value,
                            label: packagingTypeFilter.label
                          }
                        : { value: "", label: "All Packaging" }
                    }
                    onChange={(value: any) =>
                      this.handleSelectChange(
                        "packagingTypeFilter",
                        value ? value : { value: "", label: "All Packaging" }
                      )
                    }
                  />
                </div>
                <div className="col-2 kt-margin-b-20-tablet-and-mobile">
                  <Select
                    className="select-default"
                    isClearable={true}
                    onChange={(value: any) => this.handleSelectChange("packagingColor", value ? value : "")}
                    value={
                      packagingColor
                        ? {
                            value: packagingColor.value.toString(),
                            label:
                              packagingColor.label.toString() === "color"
                                ? "Special colors"
                                : _.upperFirst(packagingColor.label.toString())
                          }
                        : { value: "", label: "All Colors" }
                    }
                    options={packagingUtils.getColors(context.packagings).map(color => {
                      return {
                        value: color.toString(),
                        label: color.toString() === "color" ? "Special colors" : _.upperFirst(color.toString())
                      };
                    })}
                  />
                </div>
              </>
            )}
            <div className="col-2 kt-margin-b-20-tablet-and-mobile">
              <Select
                className="select-default"
                options={[
                  { value: "asc", label: "Ascending" },
                  { value: "desc", label: "Descending" }
                ]}
                value={sortOrder ? sortOrder : { value: "desc", label: "Descending" }}
                onChange={(value: any) =>
                  this.handleSelectChange("sortOrder", value || { value: "desc", label: "Descending" })
                }
              />
            </div>
            <div className="col-2 kt-margin-b-20-tablet-and-mobile">
              <Select
                className="select-default"
                options={[
                  { value: "currentStock", label: "Current Stock" },
                  { value: "ordered", label: "Ordered" },
                  { value: "reserved", label: "Reserved" },
                  { value: "available", label: "Available" }
                ]}
                value={selectedStatistic ? selectedStatistic : { value: "currentStock", label: "Current Stock" }}
                onChange={(value: any) =>
                  this.handleSelectChange("selectedStatistic", value || { value: "desc", label: "Descending" })
                }
              />
            </div>
          </div>
        </div>
        {type.value === "commodities" && filteredCommodities.length > 0 ? (
          <CommoditiesStockListing
            currentPage={contentPage}
            pageSize={contentPageSize}
            activeLevels={selectedContent}
            showDisabled={showDisabledStock.value}
            filteredCommodities={filteredCommodities}
            onPageChange={this.handlePageChange}
            onPageSizeChange={this.handlePageSizeChange}
            onContentToggle={this.handleContentToggle}
            context={context}
          />
        ) : type.value === "packagings" && filteredPackagings.length > 0 ? (
          <PackagingStockListing
            currentPage={contentPage}
            pageSize={contentPageSize}
            activeLevels={selectedContent}
            showDisabled={showDisabledStock.value}
            filteredPackagings={filteredPackagings}
            onPageChange={this.handlePageChange}
            onPageSizeChange={this.handlePageSizeChange}
            onContentToggle={this.handleContentToggle}
            context={context}
          />
        ) : (
          <img
            src={toAbsoluteUrl("/media/img/no_results.jpg")}
            className="d-block my-0 mx-auto "
            style={{ height: "750px" }}
          />
        )}
      </>
    );
  }
}

export default StockListing;
