import React, { SetStateAction } from "react";

type Callback<S> = (state: S) => void | (() => void | undefined);

type DispatchWithCallback<A, S> = (value: A, callback?: Callback<S>) => void;

export function useStateWithCallback<S>(initialState: S): [S, DispatchWithCallback<SetStateAction<S>, S>] {
  const callbackRef = React.useRef(null);

  const [value, setValue] = React.useState(initialState);

  React.useEffect(() => {
    if (callbackRef.current) {
      // @ts-ignore
      callbackRef.current(value);

      callbackRef.current = null;
    }
  }, [value]);

  const setValueWithCallback = React.useCallback((newValue, callback) => {
    callbackRef.current = callback;

    return setValue(newValue);
  }, []);

  return [value, setValueWithCallback];
}
