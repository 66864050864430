import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import PackagingsBaseListing from "./PackagingsBaseListing";

interface PackagingWrapperProps extends RouteComponentProps<{}, {}> {}

const PackagingsWrapper: React.FunctionComponent<PackagingWrapperProps> = props => {
  const context = useContext(DataContext);
  return <PackagingsBaseListing context={context} {...props} />;
};

export default PackagingsWrapper;
