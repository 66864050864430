import {
  T_BOX,
  T_LABEL,
  T_LID,
  T_MULTILAYERLABEL,
  T_PACKAGEINSERT,
  T_PIPETTE,
  T_SILICAGELBAG,
  T_SLEEVE,
  T_SPOON,
  T_SPRAYPUMP,
  T_STICKER,
  T_BOTTLE,
  T_BAG,
  T_BLISTER,
  T_LIQUIDBOTTLE
} from "../../utils/packagingUtils";

export const ProductTypes = {
  CAPSULES: "capsules",
  TABLETS: "tablets",
  POWDER: "powder",
  LIQUID: "liquid",
  SOFTGEL: "softgel",
  CUSTOM: "custom",
  SERVICE: "service"
};

/**
 * PACKAGING FILTER
 */
export const PackagingTypes = {
  BOTTLE: T_BOTTLE,
  BAG: T_BAG,
  BLISTER: T_BLISTER,
  BOX: T_BOX,
  LIQUIDBOTTLE: T_LIQUIDBOTTLE,
  PIPETTE: T_PIPETTE,
  SPRAYPUMP: T_SPRAYPUMP,
  SLEEVE: T_SLEEVE,
  MULTILAYER_LABEL: T_MULTILAYERLABEL,
  LABEL: T_LABEL,
  LID: T_LID,
  STICKER: T_STICKER,
  SPOON: T_SPOON,
  SILICAGELBAG: T_SILICAGELBAG,
  PACKAGEINSERT: T_PACKAGEINSERT
};

export const TORSOSELECTION: any = {
  [ProductTypes.CAPSULES]: {
    baseTypes: [T_BOTTLE, T_BAG],
    additionalTypes: [
      {
        preference: ["selectedCapsule.capsule_size", ["0", "00", "1"]],
        types: [T_BLISTER]
      }
    ]
  },
  [ProductTypes.TABLETS]: { baseTypes: [T_BOTTLE] },
  [ProductTypes.POWDER]: { baseTypes: [T_BOTTLE, T_BAG] },
  [ProductTypes.LIQUID]: { baseTypes: [T_LIQUIDBOTTLE] },
  [ProductTypes.SOFTGEL]: { baseTypes: [T_BOTTLE, T_BAG] },
  [ProductTypes.CUSTOM]: { baseTypes: [] }
};

// List in list means that it is either or, otherwise it is and
export const PACKAGINGCOMBINATIONS: any = {
  bottle: [T_LID, T_SLEEVE, [T_LABEL, T_MULTILAYERLABEL], T_BOX, T_STICKER, T_SPOON, T_SILICAGELBAG, T_PACKAGEINSERT],
  bag: [T_LABEL, T_STICKER, T_SPOON, T_SILICAGELBAG],
  blister: [T_BOX, T_STICKER, T_PACKAGEINSERT],
  liquidbottle: [
    [T_PIPETTE, T_SPRAYPUMP, T_LID],
    T_SLEEVE,
    [T_LABEL, T_MULTILAYERLABEL],
    T_BOX,
    T_STICKER,
    T_PACKAGEINSERT
  ]
};

export const ALLTORSO: Array<string> = Object.keys(PACKAGINGCOMBINATIONS);

export const ProductDefinition = [
  { value: ProductTypes.CAPSULES, label: "Capsules" },
  { value: ProductTypes.TABLETS, label: "Tablets" },
  { value: ProductTypes.POWDER, label: "Powder" },
  { value: ProductTypes.LIQUID, label: "Liquid" },
  { value: ProductTypes.SOFTGEL, label: "Softgels" },
  { value: ProductTypes.CUSTOM, label: "Custom Products" },
  { value: ProductTypes.SERVICE, label: "Service" }
];
