import React, { PureComponent } from "react";
import { PackagingsDocument } from "../../model/packagings.types";
import accessUtils, { EDITLOCATIONS } from "../../utils/accessUtils";
import packagingUtils, {
  T_BAG,
  T_BLISTER,
  T_BOTTLE,
  T_BOX,
  T_LABEL,
  T_LID,
  T_LIQUIDBOTTLE,
  T_MULTILAYERLABEL,
  T_PIPETTE,
  T_SPRAYPUMP,
  T_SLEEVE,
  T_STICKER,
  T_SPOON,
  T_SILICAGELBAG,
  T_PACKAGEINSERT
} from "../../utils/packagingUtils";
import { StickerForms } from "./PackagingHelper";

interface PackagingInformationProps {
  packaging: PackagingsDocument;
  onTabChange: (tab: string) => void;
}

interface PackagingInformationState {}

class PackagingInformation extends PureComponent<PackagingInformationProps, PackagingInformationState> {
  /**
   * Resolves packaging information and builds common objects with information to be displayed
   * @returns {Array<key: string, value: string>} List with properties
   */
  resolvePackagingProperties() {
    const { packaging } = this.props;
    const { packaging_type: type } = packaging;
    // Wrapper function for translation with namespace packaging
    let properties = [];
    switch (type) {
      case T_BOTTLE:
      case T_LIQUIDBOTTLE:
        properties = [
          { key: "Type", value: packagingUtils.getPackagingDescription(packaging.packaging_type) },
          { key: "Shape", value: packagingUtils.getPackagingDescription(packaging.packaging_shape!) },
          { key: "Material", value: packagingUtils.getPackagingDescription(packaging.packaging_material!) },
          { key: "Color", value: packagingUtils.getPackagingDescription(packaging.packaging_color!) },
          { key: "Volume", value: packaging.packaging_volume + " ml" },
          { key: "Height", value: packaging.packaging_height + " mm" },
          { key: "Width", value: packaging.packaging_width + " mm" },
          { key: "Label height", value: packaging.packaging_label_height + " mm" },
          { key: "Neck", value: packaging.packaging_neck }
        ];
        return properties;
      case T_BOX:
        properties = [
          { key: "Type", value: packagingUtils.getPackagingDescription(packaging.packaging_type) },
          { key: "Quality", value: packagingUtils.getPackagingDescription(packaging.box_quality!) },
          { key: "Height", value: packaging.box_height + " mm" },
          { key: "Width", value: packaging.box_width + " mm" },
          { key: "Depth", value: packaging.box_depth + " mm" }
        ];
        return properties;
      case T_LID:
        properties = [
          { key: "Type", value: packagingUtils.getPackagingDescription(packaging.packaging_type) },
          { key: "Shape", value: packagingUtils.getPackagingDescription(packaging.lid_shape!) },
          { key: "Material", value: packagingUtils.getPackagingDescription(packaging.lid_material!) },
          { key: "Color", value: packagingUtils.getPackagingDescription(packaging.lid_color!) },
          { key: "Size", value: packaging.lid_size }
        ];
        return properties;
      case T_BAG:
        properties = [
          { key: "Type", value: packagingUtils.getPackagingDescription(packaging.packaging_type) },
          { key: "Shape", value: packagingUtils.getPackagingDescription(packaging.bag_shape!) },
          { key: "Material", value: packagingUtils.getPackagingDescription(packaging.bag_material!) },
          { key: "Color", value: packagingUtils.getPackagingDescription(packaging.bag_color!) },
          { key: "Volume", value: packaging.bag_volume + " ml" },
          { key: "Height", value: packaging.bag_height + " mm" },
          { key: "Width", value: packaging.bag_width + " mm" },
          { key: "Zipper", value: packagingUtils.getPackagingDescription(packaging.bag_zipper!) }
        ];
        return properties;
      case T_BLISTER:
        properties = [
          { key: "Type", value: packagingUtils.getPackagingDescription(packaging.packaging_type) },
          { key: "Capsules", value: packaging.blister_capsules },
          { key: "Height", value: packaging.blister_height + " mm" },
          { key: "Width", value: packaging.blister_width + " mm" },
          { key: "Depth", value: packaging.blister_depth + " mm" }
        ];
        return properties;
      case T_LABEL:
      case T_MULTILAYERLABEL:
        properties = [
          { key: "Type", value: packagingUtils.getPackagingDescription(packaging.packaging_type) },
          { key: "Quality", value: packagingUtils.getPackagingDescription(packaging.label_quality!) },
          { key: "Height", value: packaging.label_height + " mm" },
          { key: "Width", value: packaging.label_width + " mm" }
        ];
        return properties;
      case T_SLEEVE:
        properties = [
          { key: "Type", value: packagingUtils.getPackagingDescription(packaging.packaging_type) },
          { key: "Size", value: packaging.sleeve_size + " mm" },
          { key: "Print", value: packaging.sleeve_print ? "Yes" : "No" }
        ];
        return properties;
      case T_PIPETTE:
        properties = [
          { key: "Type", value: packagingUtils.getPackagingDescription(packaging.packaging_type) },
          { key: "Color", value: packagingUtils.getPackagingDescription(packaging.packaging_color!) },
          { key: "Height", value: packaging.packaging_height + " mm" },
          { key: "Neck", value: packaging.packaging_neck }
        ];
        return properties;
      case T_SPRAYPUMP:
        properties = [
          { key: "Type", value: packagingUtils.getPackagingDescription(packaging.packaging_type) },
          { key: "Color", value: packagingUtils.getPackagingDescription(packaging.packaging_color!) },
          { key: "Norm", value: "DIN " + packaging.packaging_norm },
          { key: "Height", value: packaging.packaging_height + " mm" },
          { key: "Neck", value: packaging.packaging_neck }
        ];
        return properties;
      case T_STICKER:
        properties = [
          { key: "Type", value: packagingUtils.getPackagingDescription(packaging.packaging_type) },
          { key: "Quality", value: packagingUtils.getPackagingDescription(packaging.quality!) },
          { key: "Form", value: packagingUtils.getPackagingDescription(packaging.form!) }
        ];
        if (packaging.form === StickerForms.ROUND)
          properties.push({ key: "Diameter", value: packaging.diameter + " mm" });
        else
          properties = properties.concat([
            { key: "Height", value: packaging.label_height + " mm" },
            { key: "Width", value: packaging.label_width + " mm" }
          ]);
        return properties;
      case T_SPOON:
        properties = [
          { key: "Type", value: packagingUtils.getPackagingDescription(packaging.packaging_type) },
          { key: "Color", value: packagingUtils.getPackagingDescription(packaging.packaging_color!) },
          { key: "Capacity", value: packaging.capacity + " g" }
        ];
        return properties;
      case T_SILICAGELBAG:
        properties = [
          { key: "Type", value: packagingUtils.getPackagingDescription(packaging.packaging_type) },
          { key: "Weight", value: packaging.weight + " g" }
        ];
        return properties;
      case T_PACKAGEINSERT:
        properties = [
          { key: "Type", value: packagingUtils.getPackagingDescription(packaging.packaging_type) },
          { key: "Size", value: "Depending on box" }
        ];
        return properties;
    }
  }

  render() {
    const properties = this.resolvePackagingProperties();
    const canEdit = accessUtils.canEditData(EDITLOCATIONS.PACKAGINGSETTINGS);

    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Overview</h3>
          </div>
          {canEdit && (
            <div className="kt-portlet__head-toolbar">
              <button
                onClick={() => this.props.onTabChange("settings")}
                className="btn btn-label-brand btn-sm btn-bold"
              >
                Update Information
              </button>
            </div>
          )}
        </div>
        <div className="kt-form kt-form--label-right">
          <div className="kt-portlet__body">
            {properties &&
              properties.map(p => (
                <div key={p.key} className="form-group form-group-xs row">
                  <label className="col-4 col-form-label">{p.key}:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext kt-font-bolder">{p.value}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default PackagingInformation;
