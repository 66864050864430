import React, { PureComponent } from "react";

interface TrustworthinessInfoProps {
  isTrustworthy: boolean | undefined;
}

interface TrustworthinessInfoState {}

class TrustworthinessInfo extends PureComponent<TrustworthinessInfoProps, TrustworthinessInfoState> {
  render() {
    const { isTrustworthy } = this.props;
    let className = "kt-badge kt-badge--inline kt-badge--pill ";
    if (isTrustworthy) {
      className += "kt-badge--success";
    } else {
      className += "kt-badge--warning";
    }
    return <span className={className}>{!isTrustworthy ? "Dunning enabled" : "Dunning disabled"}</span>;
  }
}

export default TrustworthinessInfo;
