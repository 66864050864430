import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { Modal, Table } from "react-bootstrap";
import PersonWidget from "../common/PersonWidget";
import { UserdataDocument } from "../../model/userdata.types";
import { ManufacturersDocument } from "../../model/manufacturers.types";
import manufacturerUtils from "../../utils/manufacturerUtils";
import { ROLES } from "../../utils/userdataUtils";

interface AddEmployeeModalProps {
  show: boolean;
  onHide: () => void;
  manufacturer: Array<ManufacturersDocument>;
  userdata: Array<UserdataDocument>;
  addEmployee: (_id: BSON.ObjectId) => void;
}

class AddEmployeeModal extends PureComponent<AddEmployeeModalProps, {}> {
  /**
   * Add the given employee to the manufacturer and close the modal.
   * @param _id: ID of the user that should be added
   */
  addEmployee = (_id: BSON.ObjectId) => {
    const { addEmployee, onHide } = this.props;
    addEmployee(_id);
    onHide();
  };

  render() {
    const { show, onHide, manufacturer, userdata } = this.props;
    const possibleEmployees = userdata.filter(
      u =>
        u.user_id &&
        u.company_id === "internal" &&
        !u.role.includes(ROLES.SALES) &&
        !u.role.includes(ROLES.PROCUREMENT) &&
        !manufacturerUtils.isEmployeeOfAnyManufacturer(u._id, manufacturer)
    );
    return (
      <Modal centered show={show} onHide={onHide} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="kt-font-brand flaticon2-avatar mr-1" />
            Add Employee
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: "600px", overflowY: "scroll" }}>
            <Table>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Mail</th>
                  <th>Phone</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {possibleEmployees.map(pe => {
                  return (
                    <tr key={pe._id.toString()}>
                      <td>
                        <PersonWidget person={pe} />
                      </td>
                      <td>{pe.email[0]}</td>
                      <td>{pe.telephone[0]}</td>
                      <td className="align-middle">
                        <div className="btn btn-secondary" onClick={() => this.addEmployee(pe._id)}>
                          <i className="fa fa-plus pr-0" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AddEmployeeModal;
