import _ from "lodash";
import React, { PureComponent } from "react";
import { Line } from "react-chartjs-2";
import { OrdersDocument } from "../../../model/orders.types";
import dateUtils from "../../../utils/dateUtils";
import orderUtils from "../../../utils/orderUtils";
import dashboardUtils from "../../../utils/dashboardUtils";

const DEFAULT_DATA = {
  labels: [] as Array<string>,
  datasets: [
    {
      label: "Beyond Capacity",
      data: [] as Array<number>,
      fill: true,
      backgroundColor: "rgba(255,98,67,0.15)",
      borderColor: "rgba(255,98,67,0)"
    },
    {
      label: "Below Capacity",
      data: [] as Array<number>,
      fill: true,
      backgroundColor: "rgba(0,255,150, 0.25)",
      borderColor: "rgba(255,98,67,0)"
    },
    {
      label: "Reserved Capacity",
      data: [] as Array<number>,
      fill: false,
      borderColor: "red"
    }
  ]
};

interface Period {
  monday: Date;
  sunday: Date;
  planned: number;
}

interface WorkloadProps {
  orders: Array<OrdersDocument>;
}

interface WorkloadState {}

class Workload extends PureComponent<WorkloadProps, WorkloadState> {
  /**
   * Get initial periods
   * @returns {Array<Period>} list of periods
   */
  getInitialPeriods = () => {
    let periods: Array<Period> = [];
    for (let i = 0; i < 6; i++) {
      const d = new Date(Date.now() + i * 7 * 24 * 60 * 60 * 1000);
      const [monday, sunday] = dateUtils.getMondaySunday(d);
      periods.push({ monday: monday, sunday: sunday, planned: 0 });
    }
    return periods;
  };

  /**
   * Get all data to display
   * @returns {object} data object with all datasets and labels
   */
  getData = () => {
    const { orders } = this.props;
    const data = _.cloneDeep(DEFAULT_DATA);
    const periods = this.getInitialPeriods();

    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      if (orderUtils.isOrder(order)) {
        const units = orderUtils.hasFulfillmentPriceInfo(order)
          ? order.fulfillment?.totalUnits!
          : order.calculations[0].units;
        for (let p = 0; p < periods.length; p++) {
          const period = periods[p];
          const mondayTime = period.monday.getTime();
          const sundayTime = period.sunday.getTime();
          if (dashboardUtils.checkCorrectWeek(order, mondayTime, sundayTime)) period.planned += units;
        }
      }
    }
    let plannedUnits = [];
    let labels: Array<string> = [];
    let maxValue = 0;
    for (let i = 0; i < periods.length; i++) {
      const period = periods[i];
      labels.push("KW" + dateUtils.getCW(period.monday));
      plannedUnits.push(period.planned);
      if (period.planned > maxValue) maxValue = period.planned;
    }
    const deliveredUnits = dashboardUtils.getEMA(periods);
    if (maxValue * 1.4 < deliveredUnits) maxValue = deliveredUnits * 1.4;
    else maxValue = maxValue * 1.4;

    data.datasets[0].data = new Array(6).fill(maxValue);
    data.datasets[1].data = new Array(6).fill(deliveredUnits);
    data.datasets[2].data = plannedUnits;
    data.labels = labels;
    return data;
  };

  render() {
    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">Reserved Capacity</h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <Line data={this.getData()} />
        </div>
      </div>
    );
  }
}

export default Workload;
