import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import authenticationService from "../../../services/authenticationService";
import ChangePassword from "../../../components/userdata/ChangePassword";
import userService from "../../../services/userService";
import { ROLES } from "../../../utils/userdataUtils";

class UserProfile extends React.Component {
  render() {
    const { showAvatar } = this.props;
    const user = authenticationService.getUser();
    // If an anon user this landing here logout the user
    if (user.providerType === "anon-user") window.location.href = "/logout";
    const customData = user.customData;
    return (
      <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
        <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-user-profile">
          <div className="kt-header__topbar-user kt-font-dark">
            Welcome,&nbsp;
            <span className="kt-font-bolder">
              {customData ? customData.prename + " " + customData.surname : "(loading user...)"}
            </span>
            &nbsp;&nbsp;
            {showAvatar && <img alt="Avatar" src={customData ? customData.img_url : null} />}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          <div
            className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`
            }}
          >
            <div className="kt-user-card__avatar">
              <img alt="Pic" className="kt-hidden" src={customData ? customData.img_url : null} />

              <img
                alt="Pic"
                className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
                src={customData ? customData.img_url : null}
              />
            </div>
            <div className="kt-user-card__name">
              {customData ? customData.prename + " " + customData.surname : "(loading user...)"}
            </div>
          </div>
          <div className="kt-notification">
            <div className="kt-notification__custom">
              {!userService.hasExclusiveRole(ROLES.EXTERNALMANUFACTURER) &&
                !userService.hasExclusiveRole(ROLES.DELIVERYCALENDERONLY) && (
                  <Link
                    to={"/user/" + authenticationService.getUserDataID().toString()}
                    className="btn btn-label-success btn-sm btn-bold"
                  >
                    Profile
                  </Link>
                )}
              <ChangePassword />
              <Link to="/logout" className="btn btn-label-brand btn-sm btn-bold ml-2">
                Sign Out
              </Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default UserProfile;
