import { BSON } from "realm-web";
import i18n from "../translations/i18n";
import userService from "../services/userService";
import {
  FileTypes,
  WarehouseConfigurationTimelineEntry,
  WarehouseConfigurationValues
} from "../model/configuration/warehouseConfiguration.types";

/**
 * Get timeline entry for warehouse configuration values
 * @param pre warehouse configuration values before change
 * @param post warehouse configuration values after change
 * @returns {WarehouseConfigurationTimelineEntry} The timeline entry for warehouse configuration
 */
export function getConfigTimelineEntry(
  pre: WarehouseConfigurationValues,
  post: WarehouseConfigurationValues
): WarehouseConfigurationTimelineEntry {
  return {
    _id: new BSON.ObjectId(),
    date: new Date(),
    person: userService.getUserId().toString(),
    pre,
    post
  };
}

/**
 * Returns (translated) names based on the given file type
 * @param type the FileTypes for which the name should be retrieved
 * @returns {string} the resolved and translated FileTypes as string
 */
export function resolveFileTypeName(type: FileTypes): string {
  switch (type) {
    case FileTypes.BATCH:
      return i18n.t("warehouse:batch");
    case FileTypes.PURCHASEORDER:
      return i18n.t("warehouse:purchaseOrder");
    default:
      return i18n.t("warehouse:unknownFileType");
  }
}
