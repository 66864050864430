import React, { PureComponent } from "react";
import { OrdersDocument } from "../../../model/orders.types";
import dashboardUtils from "../../../utils/dashboardUtils";

interface YearToYearDevelopmentProps {
  orders: Array<OrdersDocument>;
  year: number;
}

interface YearToYearDevelopmentState {}

class YearToYearDevelopment extends PureComponent<YearToYearDevelopmentProps, YearToYearDevelopmentState> {
  /**
   * Calculates all data that is needed for the development widget.
   * @returns { object } Contains all KPIs that are shown in the widget
   */
  calculateData = () => {
    const { orders, year } = this.props;
    const beginning = new Date(year, 0, 1, 0, 0, 0, 0);
    const end = new Date();
    if (year !== end.getFullYear()) end.setFullYear(year + 1);
    const period = { beginning, end };
    const thisYearOOV = dashboardUtils.getOutstandingOrderVolume(orders, period);
    const thisYearAIV = dashboardUtils.getAlreadyInvoicedVolume(orders, period);
    const thisYearBM = dashboardUtils.getBookMargin(orders, period);
    const thisYearWP = dashboardUtils.getWrittenProfit(orders, period);
    const thisYearTM = dashboardUtils.getAverageTotalMargin(orders, period);
    const thisYearAOV = dashboardUtils.getAverageOrderVolume(orders, period);
    const thisYearAPT = dashboardUtils.getAverageProcessingTime(orders, period);
    const thisYearAC = dashboardUtils.getActiveCustomers(orders, period);
    beginning.setFullYear(year - 1);
    end.setFullYear(year - 1);
    const lastYearOOV = dashboardUtils.getOutstandingOrderVolume(orders, period);
    const lastYearAIV = dashboardUtils.getAlreadyInvoicedVolume(orders, period);
    const lastYearBM = dashboardUtils.getBookMargin(orders, period);
    const lastYearWP = dashboardUtils.getWrittenProfit(orders, period);
    const lastYearTM = dashboardUtils.getAverageTotalMargin(orders, period);
    const lastYearAOV = dashboardUtils.getAverageOrderVolume(orders, period);
    const lastYearAPT = dashboardUtils.getAverageProcessingTime(orders, period);
    const lastYearAC = dashboardUtils.getActiveCustomers(orders, period);
    const outstandingOrderVolumeYTY = (thisYearOOV / lastYearOOV - 1) * 100 || 0;
    const alreadyInvoicedVolumeYTY = (thisYearAIV / lastYearAIV - 1) * 100 || 0;
    const bookMarginYTY = (thisYearBM / lastYearBM - 1) * 100 || 0;
    const writtenProfitYTY = (thisYearWP / lastYearWP - 1) * 100 || 0;
    const totalMarginYTY = (thisYearTM / lastYearTM - 1) * 100 || 0;
    const averageOrderVolumeYTY = (thisYearAOV / lastYearAOV - 1) * 100 || 0;
    const averageProcessingTimeYTY = (thisYearAPT / lastYearAPT - 1) * 100 || 0;
    const activeCustomersYTY = (thisYearAC / lastYearAC - 1) * 100 || 0;
    return {
      outstandingOrderVolumeYTY,
      alreadyInvoicedVolumeYTY,
      bookMarginYTY,
      writtenProfitYTY,
      totalMarginYTY,
      averageOrderVolumeYTY,
      averageProcessingTimeYTY,
      activeCustomersYTY
    };
  };

  render() {
    const { year } = this.props;
    const {
      outstandingOrderVolumeYTY,
      alreadyInvoicedVolumeYTY,
      bookMarginYTY,
      writtenProfitYTY,
      totalMarginYTY,
      averageOrderVolumeYTY,
      averageProcessingTimeYTY,
      activeCustomersYTY
    } = this.calculateData();
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">Development YTY</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-widget1">
            <div className="kt-widget1__item">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title kt-font-bolder">Book turnover YTY </h3>
              </div>
              <span
                className={
                  "kt-widget1__number text-right " +
                  (outstandingOrderVolumeYTY > 0 ? "kt-font-success" : "kt-font-warning")
                }
              >
                {outstandingOrderVolumeYTY === Infinity
                  ? "No data from " + (year - 1)
                  : (outstandingOrderVolumeYTY > 0 ? "+" : "") + outstandingOrderVolumeYTY.toFixed(2) + "%"}
              </span>
            </div>
            <div className="kt-widget1__item">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title kt-font-bolder">Written turnover YTY </h3>
              </div>
              <span
                className={
                  "kt-widget1__number text-right " +
                  (alreadyInvoicedVolumeYTY > 0 ? "kt-font-success" : "kt-font-warning")
                }
              >
                {alreadyInvoicedVolumeYTY === Infinity
                  ? "No data from " + (year - 1)
                  : (alreadyInvoicedVolumeYTY > 0 ? "+" : "") + alreadyInvoicedVolumeYTY.toFixed(2) + "%"}
              </span>
            </div>
            <div className="kt-widget1__item">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title kt-font-bolder">Book margin YTY </h3>
              </div>
              <span
                className={
                  "kt-widget1__number text-right " + (bookMarginYTY > 0 ? "kt-font-success" : "kt-font-warning")
                }
              >
                {bookMarginYTY === Infinity
                  ? "No data from " + (year - 1)
                  : (bookMarginYTY > 0 ? "+" : "") + bookMarginYTY.toFixed(2) + "%"}
              </span>
            </div>
            <div className="kt-widget1__item">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title kt-font-bolder">Written margin YTY </h3>
              </div>
              <span
                className={
                  "kt-widget1__number text-right " + (writtenProfitYTY > 0 ? "kt-font-success" : "kt-font-warning")
                }
              >
                {writtenProfitYTY === Infinity
                  ? "No data from " + (year - 1)
                  : (writtenProfitYTY > 0 ? "+" : "") + writtenProfitYTY.toFixed(2) + "%"}
              </span>
            </div>
            <div className="kt-widget1__item">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title kt-font-bolder">Active customers YTY </h3>
              </div>
              <span
                className={
                  "kt-widget1__number text-right " + (activeCustomersYTY > 0 ? "kt-font-success" : "kt-font-warning")
                }
              >
                {activeCustomersYTY === Infinity
                  ? "No data from " + (year - 1)
                  : (activeCustomersYTY > 0 ? "+" : "") + activeCustomersYTY.toFixed(2) + "%"}
              </span>
            </div>
            <div className="kt-widget1__item">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title kt-font-bolder">Average margin YTY </h3>
              </div>
              <span
                className={
                  "kt-widget1__number text-right " + (totalMarginYTY > 0 ? "kt-font-success" : "kt-font-warning")
                }
              >
                {totalMarginYTY === Infinity
                  ? "No data from " + (year - 1)
                  : (totalMarginYTY > 0 ? "+" : "") + totalMarginYTY.toFixed(2) + "%"}
              </span>
            </div>
            <div className="kt-widget1__item">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title kt-font-bolder">Average order amount YTY </h3>
              </div>
              <span
                className={
                  "kt-widget1__number text-right " + (averageOrderVolumeYTY > 0 ? "kt-font-success" : "kt-font-warning")
                }
              >
                {(averageOrderVolumeYTY > 0 ? "+" : "") + averageOrderVolumeYTY.toFixed(2) + "%"}
              </span>
            </div>
            <div className="kt-widget1__item">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title kt-font-bolder">Average processing time YTY </h3>
              </div>
              <span
                className={
                  "kt-widget1__number text-right " +
                  (averageProcessingTimeYTY > 0 ? "kt-font-warning" : "kt-font-success")
                }
              >
                {(averageProcessingTimeYTY > 0 ? "+" : "") + averageProcessingTimeYTY.toFixed(2) + "%"}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default YearToYearDevelopment;
