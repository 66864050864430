import _ from "lodash";
import React, { PureComponent } from "react";
import { Badge, Table } from "react-bootstrap";
import Select from "react-select";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import CompanyWidget from "../common/CompanyWidget";
import Pagination, { paginate } from "../common/Pagination";
import PersonWidget from "../common/PersonWidget";
import { DataContext } from "../../context/dataContext";
import { UserdataDocument } from "../../model/userdata.types";
import userdataUtils from "../../utils/userdataUtils";
import { getComponentState } from "../../utils/baseUtils";
import { SearchBar } from "../listings/common/Filters";

interface UserdataProps extends RouteComponentProps<{}, {}, {}> {
  mode: "customer" | "internal" | "all";
}

interface UserdataState {
  mode: string;
  query: string;
  currentPage: number;
  pageSize: number;
}

const CONSTRUCTORNAME = "Userdata";

class Userdata extends PureComponent<UserdataProps, UserdataState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: UserdataProps) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  componentDidMount() {
    const state = getComponentState(this.context, CONSTRUCTORNAME + this.props.mode);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.context.saveComponentState(CONSTRUCTORNAME + this.props.mode, this.state);
  }
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ query: e.target.value, currentPage: 1 });
  handleReset = () => this.setState(this.getDefaultState(this.props));

  /**
   * Get the default state
   * @param props the components properties
   * @returns {UserdataState} state for the component
   */
  getDefaultState = (props: UserdataProps) => {
    return { query: "", currentPage: 1, pageSize: 10, mode: props.mode } as UserdataState;
  };

  /**
   * Filters the userdata by the selected mode and entered query.
   * @returns { Array<UserdataDocument> } Filtered userdata
   */
  filterUserdata = () => {
    const { mode, query } = this.state;
    const { userdata, companies } = this.context;
    let usersDisplayed: Array<UserdataDocument>;
    if (mode === "customer") {
      usersDisplayed = userdata.filter(u => u.company_id !== "internal");
    } else if (mode === "internal") {
      usersDisplayed = userdata.filter(u => u.company_id === "internal");
    } else {
      usersDisplayed = userdata;
    }
    return userdataUtils.filterUserdata(query, companies, usersDisplayed);
  };

  componentDidUpdate(prevProps: Readonly<UserdataProps>, prevState: Readonly<UserdataState>, snapshot?: any) {
    // Needed in case the mode is changed by selecting a different site in the menu
    if (this.props.mode !== prevProps.mode) {
      this.setState({ mode: this.props.mode });
    }
  }

  /**
   * Handles changing the view mode of the userdata listing. Also alters the URL to reflect the mode.
   * @param mode: New mode
   */
  handleModeChange = (mode: { value: string; label: string }) => {
    const { history } = this.props;
    this.setState({ mode: mode.value, currentPage: 1 });
    if (mode.value === "customer") {
      history.replace("/customers");
    } else if (mode.value === "internal") {
      history.replace("/employees");
    } else {
      history.replace("/userdata");
    }
  };

  render() {
    const { history } = this.props;
    const { currentPage, mode, pageSize, query } = this.state;
    const { companies, userdata } = this.context;
    const userdataFiltered = this.filterUserdata();
    const userdataDocuments: Array<UserdataDocument> = paginate(userdataFiltered, currentPage, pageSize);

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand flaticon2-avatar" />
            </span>
            <h3 className="kt-portlet__head-title">
              {mode === "customer" ? "Customers" : mode === "internal" ? "Employees" : "Users"}
            </h3>
            <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
              Reset
            </button>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <button
                onClick={() => {
                  history.goBack();
                }}
                className="btn btn-clean kt-margin-r-10"
              >
                <i className="la la-arrow-left" />
                <span className="kt-hidden-mobile">Back</span>
              </button>
              <button type="button" className="btn btn-brand btn-icon-sm">
                <Link to="/create-user">
                  <i className="fa fa-plus text-white" />
                  <span style={{ color: "white" }}>Create</span>
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <SearchBar onSearch={this.handleSearch} search={query} additionalSizeClasses={"col-md-2"} />
            <div className="float-right col-md-2">
              <Select
                className="select-default"
                isClearable={true}
                onChange={(value: any) => this.handleModeChange(value || { value: "all", label: "All" })}
                options={[
                  { value: "customer", label: "Customers" },
                  { value: "internal", label: "Internals" }
                ]}
                value={
                  mode !== "all" ? { value: mode, label: _.upperFirst(mode) + "s" } : { value: "all", label: "All" }
                }
              />
            </div>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded px-4">
            <Table>
              <thead className="kt-datatable__head">
                <tr>
                  <th style={{ width: "30%" }}>Person</th>
                  <th style={{ width: "15%" }}>Company</th>
                  <th style={{ width: "15%" }}>Email</th>
                  <th style={{ width: "15%" }}>Telephone</th>
                  <th style={{ width: "15%" }}>Owner</th>
                  <th style={{ width: "10%" }}>Roles</th>
                  <th style={{ width: "10%" }} className="text-right">
                    SC
                  </th>
                </tr>
              </thead>
              <tbody className="kt-datatable__body" style={{}}>
                {userdataDocuments.map(u => {
                  const company = companies.find(c => u.company_id && c._id.toString() === u.company_id.toString());
                  const owner = company ? userdata.find(o => o._id.toString() === company.owner.toString()) : null;
                  return (
                    <tr
                      key={u._id.toString()}
                      className="table-hover"
                      onClick={() => history.push("/user/" + u._id.toString())}
                    >
                      <td className="align-middle">
                        <PersonWidget person={u} />
                      </td>
                      <td className="align-middle">
                        {company || u.company_id === "internal" ? (
                          <CompanyWidget company={company ? company : "internal"} />
                        ) : (
                          "No company linked"
                        )}
                      </td>
                      <td className="align-middle">
                        <h5>
                          <Badge pill variant="light">
                            {u.email[0]}
                          </Badge>
                        </h5>
                        {u.email[1] && (
                          <h5>
                            <Badge pill variant="light">
                              {u.email[1]}
                            </Badge>
                          </h5>
                        )}
                      </td>
                      <td className="align-middle">
                        <h5>
                          <Badge pill variant="light">
                            {u.telephone[0]}
                          </Badge>
                        </h5>
                        {u.telephone[1] && (
                          <h5>
                            <Badge pill variant="light">
                              {u.telephone[1]}
                            </Badge>
                          </h5>
                        )}
                      </td>
                      <td className="align-middle">{owner ? <PersonWidget person={owner} /> : "-"}</td>
                      <td className="align-middle">
                        {u.role.map(r => (
                          <Badge key={r} pill variant="secondary" className="mx-1 mb-1">
                            {r}
                          </Badge>
                        ))}
                      </td>
                      <td className="align-middle text-right">
                        {u.company_id !== "internal" ? (u.user_id ? "Yes" : "No") : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
              <Pagination
                itemsCount={userdataFiltered.length}
                pageSize={pageSize}
                onPageChange={page => this.setState({ currentPage: page })}
                currentPage={currentPage}
                onPageSizeChange={size => this.setState({ pageSize: size })}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Userdata);
