import _ from "lodash";
import React, { PureComponent } from "react";
import { Emoji, EmojiProvider } from "react-apple-emojis";
import emojiData from "react-apple-emojis/src/data.json";
import dashboardUtils from "../../../utils/dashboardUtils";
import dateUtils from "../../../utils/dateUtils";
import baseUtils from "../../../utils/baseUtils";

interface OutputDetailsProps {
  targetOutput: number;
  targetOutputTurnover: number;
  targetOutputProfit: number;
  outputHistory: Array<any>;
  year: number;
  onInvestorDashboard?: boolean;
}

interface OutputDetailsPropsState {}

class OutputDetails extends PureComponent<OutputDetailsProps, OutputDetailsPropsState> {
  /**
   * Get the total output. Since the history is sorted by date and the y-value is the accumulated output we can
   * access the last entry.
   */
  getTotalOutput() {
    const { outputHistory } = this.props;
    if (outputHistory.length > 0) {
      return outputHistory[outputHistory.length - 1].y;
    }
    return 0;
  }
  /**
   * Get the output of the last week. Since the history is sorted by date and the y-value is the accumulated output we
   * can access the last entry.
   * @returns { number } Last week's output
   */
  getLastWeekOutput() {
    const { outputHistory } = this.props;
    let lastWeekOrders = _.filter(
      outputHistory,
      o => dateUtils.getCwNumber(new Date(o.t)) <= dateUtils.getCwNumber(new Date()) - 1
    );
    if (lastWeekOrders.length > 0) {
      return lastWeekOrders[lastWeekOrders.length - 1].y;
    } else {
      return 0;
    }
  }

  render() {
    const {
      outputHistory,
      targetOutput,
      targetOutputProfit,
      targetOutputTurnover,
      onInvestorDashboard,
      year
    } = this.props;
    const totalOutput = this.getTotalOutput();
    const curYear = year ? year : new Date().getFullYear();
    const remainingWeeks = year !== new Date().getFullYear() ? 0 : dateUtils.getRemainingWeeksOfYear();
    const unitTargetReached = targetOutput - totalOutput < 0;
    return (
      <div className="kt-portlet  kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">Output Details</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-widget1">
            <div className="kt-widget1__item">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title kt-font-bolder">Output Goals {curYear}</h3>
              </div>
              <span className="kt-widget1__number kt-font-success text-right">
                {targetOutput.toLocaleString() + " Units"}
              </span>
            </div>
            {!onInvestorDashboard && (
              <>
                <div className="kt-widget1__item">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">Goal: Turnover</h3>
                  </div>
                  <span className="kt-widget1__number kt-font-success text-right">
                    {baseUtils.formatEuro(targetOutputTurnover)}
                  </span>
                </div>
                <div className="kt-widget1__item">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">Goal: Margin</h3>
                  </div>
                  <span className="kt-widget1__number kt-font-success text-right">
                    {baseUtils.formatEuro(targetOutputProfit)}
                  </span>
                </div>
              </>
            )}
            {remainingWeeks > 0 && (
              <div className="kt-widget1__item">
                <div className="kt-widget1__info">
                  <h3 className="kt-widget1__title kt-font-bolder">Output Week {dateUtils.getCwNumber(new Date())}</h3>
                </div>
                <span className="kt-widget1__number kt-font-success text-right">
                  {(totalOutput - this.getLastWeekOutput()).toLocaleString() + " Units"}
                </span>
              </div>
            )}
            <div className="kt-widget1__item">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title kt-font-bolder">Total Output {curYear}</h3>
              </div>
              <span className="kt-widget1__number kt-font-success text-right">
                {totalOutput.toLocaleString() + " Units"}
              </span>
            </div>
            <div className="kt-widget1__item">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title kt-font-bolder">Archived Output {curYear}</h3>
              </div>
              <span className="kt-widget1__number kt-font-success text-right">
                {((totalOutput / targetOutput) * 100).toFixed(2) + "%"}
              </span>
            </div>
            {remainingWeeks > 0 && (
              <>
                <div className="kt-widget1__item">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">Required Output {curYear}</h3>
                  </div>
                  <span
                    className={
                      "kt-widget1__number text-right " + (unitTargetReached ? "kt-font-success" : "kt-font-danger")
                    }
                  >
                    {unitTargetReached ? (
                      <>
                        Goal Reached
                        <EmojiProvider data={emojiData}>
                          <Emoji className="ml-2" name="party-popper" width="30" />
                        </EmojiProvider>
                      </>
                    ) : (
                      (targetOutput - totalOutput).toLocaleString() + " Units"
                    )}
                  </span>
                </div>
                <div className="kt-widget1__item">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">Projection (31.12.{curYear})</h3>
                  </div>
                  <span
                    className={
                      "kt-widget1__number text-right " + (unitTargetReached ? "kt-font-success" : "kt-font-danger")
                    }
                  >
                    {Math.round(dashboardUtils.createProjection(outputHistory, "units")[1].y).toLocaleString() +
                      " Units"}
                  </span>
                </div>
                {!onInvestorDashboard && (
                  <>
                    <div className="kt-widget1__item">
                      <div className="kt-widget1__info">
                        <h3 className="kt-widget1__title kt-font-bolder">Projection: Turnover</h3>
                      </div>
                      <span className="kt-widget1__number kt-font-danger text-right">
                        {baseUtils.formatEuro(dashboardUtils.createProjection(outputHistory, "turnover")[1].y)}
                      </span>
                    </div>
                    <div className="kt-widget1__item">
                      <div className="kt-widget1__info">
                        <h3 className="kt-widget1__title kt-font-bolder">Projection: Margin</h3>
                      </div>
                      <span className="kt-widget1__number kt-font-danger text-right">
                        {baseUtils.formatEuro(dashboardUtils.createProjection(outputHistory, "profit")[1].y)}
                      </span>
                    </div>
                  </>
                )}
                <div className="kt-widget1__item">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">Remaining Time</h3>
                  </div>
                  <span className="kt-widget1__number kt-font-danger text-right">{remainingWeeks} Weeks</span>
                </div>
                <div className="kt-widget1__item">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">Required Weekly Output</h3>
                  </div>
                  <span
                    className={
                      "kt-widget1__number text-right " + (unitTargetReached ? "kt-font-success" : "kt-font-danger")
                    }
                  >
                    {unitTargetReached ? (
                      <>
                        Goal Reached
                        <EmojiProvider data={emojiData}>
                          <Emoji className="ml-2" name="party-popper" width="30" />
                        </EmojiProvider>
                      </>
                    ) : (
                      ((targetOutput - totalOutput) / remainingWeeks).toLocaleString() + " Units"
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default OutputDetails;
