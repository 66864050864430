import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import {
  EditPackagingBag,
  EditPackagingBlister,
  EditPackagingBottle,
  EditPackagingBox,
  EditPackagingLabel,
  EditPackagingLid,
  EditPackagingPipette,
  EditPackagingSilicaGelBag,
  EditPackagingSleeve,
  EditPackagingSpoon,
  EditPackagingSprayPump,
  EditPackagingSticker
} from "./PackagingSettingsContent";
import {
  BagData,
  BlisterData,
  BottleData,
  BoxData,
  GeneralPackagingData,
  LabelData,
  LidData,
  PipetteData,
  SprayPumpData,
  SleeveData,
  StickerData,
  SpoonData,
  SilicaGelBagData
} from "../CustomTypes";
import UploadImage from "../../common/UploadImage";
import { DataContext } from "../../../context/dataContext";
import { PackagingsDocument } from "../../../model/packagings.types";
import { PACKAGINGS } from "../../../services/dbService";
import accessUtils, { EDITLOCATIONS } from "../../../utils/accessUtils";
import packagingUtils, {
  T_BAG,
  T_BLISTER,
  T_BOTTLE,
  T_BOX,
  T_LABEL,
  T_LID,
  T_LIQUIDBOTTLE,
  T_MULTILAYERLABEL,
  T_PIPETTE,
  T_SPRAYPUMP,
  T_SLEEVE,
  T_STICKER,
  T_SPOON,
  T_SILICAGELBAG,
  T_PACKAGEINSERT
} from "../../../utils/packagingUtils";
import { StickerForms } from "../PackagingHelper";

interface PackagingSettingsProps {
  packaging: PackagingsDocument;
  context: React.ContextType<typeof DataContext>;
}

interface PackagingSettingsState {
  generalData: GeneralPackagingData;
  packagingData:
    | BottleData
    | BagData
    | BlisterData
    | BoxData
    | LidData
    | PipetteData
    | SprayPumpData
    | SleeveData
    | LabelData
    | StickerData
    | SpoonData
    | SilicaGelBagData
    | undefined;
  saving: boolean;
}

class PackagingSettings extends PureComponent<PackagingSettingsProps, PackagingSettingsState> {
  constructor(props: PackagingSettingsProps) {
    super(props);
    const packaging = props.packaging;
    this.state = {
      saving: false,
      packagingData: this.getDataFromPackaging(packaging),
      generalData: {
        images: packaging.images,
        suppliers: packaging.suppliers,
        articleNumber: packaging.article_number || "",
        note: packaging.note || ""
      }
    };
  }

  getDataFromPackaging = (packaging: PackagingsDocument) => {
    switch (packaging.packaging_type) {
      case T_BOTTLE:
      case T_LIQUIDBOTTLE:
        return {
          shape: packaging.packaging_shape,
          material: packaging.packaging_material,
          color: packaging.packaging_color,
          neck: packaging.packaging_neck,
          labelHeight: packaging.packaging_label_height,
          volume: packaging.packaging_volume,
          height: packaging.packaging_height,
          width: packaging.packaging_width
        } as BottleData;
      case T_BAG:
        return {
          shape: packaging.bag_shape,
          material: packaging.bag_material,
          color: packaging.bag_color,
          zipper: packaging.bag_zipper,
          volume: packaging.bag_volume,
          height: packaging.bag_height,
          width: packaging.bag_width
        } as BagData;
      case T_BLISTER:
        return {
          capsules: packaging.blister_capsules,
          depth: packaging.blister_depth,
          height: packaging.blister_height,
          width: packaging.blister_width
        } as BlisterData;
      case T_BOX:
        return {
          quality: packaging.box_quality,
          depth: packaging.box_depth,
          height: packaging.box_height,
          width: packaging.box_width
        } as BoxData;
      case T_LABEL:
      case T_MULTILAYERLABEL:
        return {
          quality: packaging.label_quality,
          height: packaging.label_height,
          width: packaging.label_width
        } as LabelData;
      case T_LID:
        return {
          shape: packaging.lid_shape,
          material: packaging.lid_material,
          color: packaging.lid_color,
          size: packaging.lid_size
        } as LidData;
      case T_PIPETTE:
        return {
          color: packaging.packaging_color,
          height: packaging.packaging_height,
          neck: packaging.packaging_neck
        } as PipetteData;
      case T_SPRAYPUMP:
        return {
          color: packaging.packaging_color,
          norm: packaging.packaging_norm,
          height: packaging.packaging_height,
          neck: packaging.packaging_neck
        } as SprayPumpData;
      case T_SLEEVE:
        return {
          size: packaging.sleeve_size,
          print: packaging.sleeve_print
        } as SleeveData;
      case T_STICKER:
        return {
          quality: packaging.quality,
          form: packaging.form,
          height: packaging.packaging_height ?? "0",
          width: packaging.packaging_width ?? "0",
          diameter: packaging.diameter ?? "0"
        } as StickerData;
      case T_SPOON:
        return {
          capacity: packaging.capacity,
          color: packaging.packaging_color
        } as SpoonData;
      case T_SILICAGELBAG:
        return {
          weight: packaging.weight
        } as SilicaGelBagData;
      default:
        return undefined;
    }
  };

  handlePackagingChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const packagingData = _.cloneDeep(this.state.packagingData);
    if (!packagingData) return;
    const key = e.target.name;
    let val = e.target.value;
    if (e.target.type === "number") {
      val = val.replaceAll(/^0+/g, "0");
      if (!val.includes(".")) val = Number(val).toString();
      if (!val || +val < 0) return;
    }
    if (key === "print") _.set(packagingData, key, !_.get(packagingData, key));
    else _.set(packagingData, key, val);
    this.setState({ packagingData });
  };

  handleGeneralChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const generalData = _.cloneDeep(this.state.generalData);
    const key = e.target.name as keyof GeneralPackagingData;
    _.set(generalData, key, e.target.value);
    this.setState({ generalData });
  };

  handleImageUpload = (path: string) => {
    const generalData = _.cloneDeep(this.state.generalData);
    const images = generalData.images;
    images.unshift(path);
    this.setState({ generalData });
  };

  handleSave = async () => {
    const { context, packaging } = this.props;
    const { generalData, packagingData } = this.state;
    if (!packagingData && packaging.packaging_type !== T_PACKAGEINSERT) return;
    this.setState({ saving: true });
    const { note, articleNumber, images } = generalData;
    const update: any = {
      images,
      article_number: articleNumber,
      note,
      suppliers: packaging.suppliers,
      specifications: packaging.specifications,
      packaging_type: packaging.packaging_type,
      timeline: packaging.timeline
    };
    let data: any;
    switch (packaging.packaging_type) {
      case T_BOTTLE:
      case T_LIQUIDBOTTLE:
        data = packagingData as BottleData;
        if (packaging.packaging_type === T_BOTTLE) update.packaging_shape = data.shape;
        update.packaging_material = data.material;
        update.packaging_color = data.color;
        update.packaging_volume = data.volume;
        update.packaging_height = data.height;
        update.packaging_width = data.width;
        update.packaging_label_height = data.labelHeight;
        update.packaging_neck = data.neck;
        break;
      case T_BAG:
        data = packagingData as BagData;
        update.bag_shape = data.shape;
        update.bag_material = data.material;
        update.bag_zipper = data.zipper;
        update.bag_volume = data.volume;
        update.bag_height = data.height;
        update.bag_width = data.width;
        update.bag_color = data.color;
        break;
      case T_BLISTER:
        data = packagingData as BlisterData;
        update.blister_capsules = data.capsules;
        update.blister_depth = data.depth;
        update.blister_height = data.height;
        update.blister_width = data.width;
        break;
      case T_BOX:
        data = packagingData as BoxData;
        update.box_quality = data.quality;
        update.box_depth = data.depth;
        update.box_height = data.height;
        update.box_width = data.width;
        break;
      case T_LID:
        data = packagingData as LidData;
        update.lid_shape = data.shape;
        update.lid_material = data.material;
        update.lid_size = data.size;
        update.lid_color = data.color;
        break;
      case T_PIPETTE:
        data = packagingData as PipetteData;
        update.packaging_color = data.color;
        update.packaging_height = data.height;
        update.packaging_neck = data.neck;
        break;
      case T_SPRAYPUMP:
        data = packagingData as SprayPumpData;
        update.packaging_color = data.color;
        update.packaging_height = data.height;
        update.packaging_norm = data.norm;
        update.packaging_neck = data.neck;
        break;
      case T_SLEEVE:
        data = packagingData as SleeveData;
        update.sleeve_print = data.print;
        update.sleeve_size = data.size;
        break;
      case T_LABEL:
      case T_MULTILAYERLABEL:
        data = packagingData as LabelData;
        update.label_quality = data.quality;
        update.label_width = data.width;
        update.label_height = data.height;
        break;
      case T_STICKER:
        {
          data = packagingData as StickerData;
          update.form = data.form;
          update.quality = data.quality;
          if (data.form === StickerForms.ROUND) {
            update.diameter = data.diameter;
            update.packaging_height = undefined;
            update.packaging_width = undefined;
          } else if (data.form === StickerForms.ANGULAR) {
            update.packaging_height = data.height;
            update.packaging_width = data.width;
          }
        }
        break;
      case T_SPOON:
        data = packagingData as SpoonData;
        update.capacity = data.capacity;
        update.packaging_color = data.color;
        break;
      case T_SILICAGELBAG:
        data = packagingData as SilicaGelBagData;
        update.weight = data.weight;
        break;
    }
    try {
      const result = await packagingUtils.updatePackagingWithTimeline(packaging, update);
      await context.updateDocumentInContext(PACKAGINGS, packaging._id);
      if (!!result) {
        toast.success("Packaging updated successfully");
      } else toast.error("Packaging could not be updated");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ saving: false });
    }
  };

  handleReset = () => {
    const { packaging } = this.props;
    const packagingData = this.getDataFromPackaging(packaging);
    const generalData = {
      note: packaging.note || "",
      articleNumber: packaging.article_number || "",
      suppliers: packaging.suppliers,
      images: packaging.images
    };
    this.setState({ packagingData, generalData });
  };

  render() {
    const { packaging } = this.props;
    const { packagingData, generalData, saving } = this.state;
    const { note, articleNumber } = generalData;
    const canEdit = accessUtils.canEditData(EDITLOCATIONS.PACKAGINGSETTINGS);

    return (
      <div className="kt-form kt-form--label-right">
        <div className="kt-form__body">
          <div className="kt-section kt-section--first">
            <div className="kt-section__body">
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Article Number</label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    className="form-control"
                    type="text"
                    onChange={canEdit ? this.handleGeneralChange : undefined}
                    value={articleNumber}
                    name="articleNumber"
                    disabled={!canEdit}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Note</label>
                <div className="col-lg-9 col-xl-6">
                  <textarea
                    className="form-control"
                    onChange={this.handleGeneralChange}
                    value={note}
                    disabled={!canEdit}
                    name="note"
                    rows={3}
                  />
                </div>
              </div>
              {[T_BOTTLE, T_LIQUIDBOTTLE].includes(packaging.packaging_type) && (
                <EditPackagingBottle
                  data={packagingData as BottleData}
                  onPackagingDataChange={this.handlePackagingChange}
                  liquid={packaging.packaging_type === T_LIQUIDBOTTLE}
                  disabled={!canEdit}
                />
              )}
              {packaging.packaging_type === T_PIPETTE && (
                <EditPackagingPipette
                  data={packagingData as PipetteData}
                  onPackagingDataChange={this.handlePackagingChange}
                  disabled={!canEdit}
                />
              )}
              {packaging.packaging_type === T_SPRAYPUMP && (
                <EditPackagingSprayPump
                  data={packagingData as SprayPumpData}
                  onPackagingDataChange={this.handlePackagingChange}
                  disabled={!canEdit}
                />
              )}
              {packaging.packaging_type === T_LID && (
                <EditPackagingLid
                  data={packagingData as LidData}
                  onPackagingDataChange={this.handlePackagingChange}
                  disabled={!canEdit}
                />
              )}
              {packaging.packaging_type === T_BAG && (
                <EditPackagingBag
                  data={packagingData as BagData}
                  onPackagingDataChange={this.handlePackagingChange}
                  disabled={!canEdit}
                />
              )}
              {packaging.packaging_type === T_BLISTER && (
                <EditPackagingBlister
                  data={packagingData as BlisterData}
                  onPackagingDataChange={this.handlePackagingChange}
                  disabled={!canEdit}
                />
              )}
              {packaging.packaging_type === T_BOX && (
                <EditPackagingBox
                  data={packagingData as BoxData}
                  onPackagingDataChange={this.handlePackagingChange}
                  disabled={!canEdit}
                />
              )}
              {[T_LABEL, T_MULTILAYERLABEL].includes(packaging.packaging_type) && (
                <EditPackagingLabel
                  data={packagingData as LabelData}
                  onPackagingDataChange={this.handlePackagingChange}
                  disabled={!canEdit}
                />
              )}
              {packaging.packaging_type === T_SLEEVE && (
                <EditPackagingSleeve
                  data={packagingData as SleeveData}
                  onPackagingDataChange={this.handlePackagingChange}
                  disabled={!canEdit}
                />
              )}
              {packaging.packaging_type === T_STICKER && (
                <EditPackagingSticker
                  data={packagingData as StickerData}
                  onPackagingDataChange={this.handlePackagingChange}
                  disabled={!canEdit}
                />
              )}
              {packaging.packaging_type === T_SPOON && (
                <EditPackagingSpoon
                  data={packagingData as SpoonData}
                  onPackagingDataChange={this.handlePackagingChange}
                  disabled={!canEdit}
                />
              )}
              {packaging.packaging_type === T_SILICAGELBAG && (
                <EditPackagingSilicaGelBag
                  data={packagingData as SilicaGelBagData}
                  onPackagingDataChange={this.handlePackagingChange}
                  disabled={!canEdit}
                />
              )}
              {canEdit && (
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Image:</label>
                  <div className="col-lg-9 col-xl-6">
                    <span className="form-control-plaintext kt-font-bolder">
                      <UploadImage onUploadFile={this.handleImageUpload} />
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="kt-separator kt-separator--space-lg kt-separator--fit kt-separator--border-solid" />
        <div className="kt-form__actions">
          <div className="float-right">
            <button
              className={"btn btn-secondary mr-2 " + (saving || !canEdit ? " disabled" : "")}
              disabled={saving || !canEdit}
              onClick={saving || !canEdit ? undefined : this.handleReset}
            >
              Reset
            </button>
            <button
              className={"btn btn-success " + (saving || !canEdit ? " disabled" : "")}
              disabled={saving || !canEdit}
              onClick={saving || !canEdit ? undefined : this.handleSave}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default PackagingSettings;
