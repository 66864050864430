import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import EventApi from "@fullcalendar/core/api/EventApi";
import View from "@fullcalendar/core/View";
import { EventSourceInput } from "@fullcalendar/core/structs/event-source";
import { DataContext } from "../../../../context/dataContext";
import commodityUtils from "../../../../utils/commodityUtils";
import baseUtils from "../../../../utils/baseUtils";
import manufacturerUtils from "../../../../utils/manufacturerUtils";
import { CommoditiesDocument, CommodityOrder, StockTransferOrder } from "../../../../model/commodities.types";
import { UserdataDocument } from "../../../../model/userdata.types";
import { SuppliersDocument } from "../../../../model/suppliers.types";
import { ManufacturersDocument } from "../../../../model/manufacturers.types";
import { OrdersDocument } from "../../../../model/orders.types";
import { PackagingsDocument } from "../../../../model/packagings.types";
import { PackagingOrderDocument } from "../../../../model/packagingOrders.types";
import packagingUtils from "../../../../utils/packagingUtils";
import OrderOverviewModal from "../shared/OrderOverviewModal";
import CalendarView, { ViewType } from "../shared/CalendarView";
import CalendarViewHeader from "../shared/CalendarViewHeader";

export interface EventData {
  material: CommoditiesDocument | PackagingsDocument;
  materialOrder: CommodityOrder | PackagingOrderDocument;
  person: UserdataDocument | null | undefined;
  supplier: SuppliersDocument | null | undefined;
  destination: ManufacturersDocument | null | undefined;
  orders: Array<OrdersDocument> | null | undefined;
  stockTransferOrder?: Array<StockTransferOrder>;
  warehouseDestination?: ManufacturersDocument;
}

interface DeliveryCalendarProps extends RouteComponentProps {
  context: React.ContextType<typeof DataContext>;
}

interface DeliveryCalendarState {
  loading: boolean;
  search: string;
  status: { value: string; label: string };
  manufacturer: "" | { value: string; label: string };
  type: { value: string; label: string };
  manufacturerLocked: boolean;
  selectedEvent: EventData | null;
  events: Array<EventSourceInput>;
}

const MANUFACTURER_COLORS = {
  "5ef4fdb6a4cf4fbc3202f60a": "rgba(255,255,255,1)", // HV
  "5ef4fd72a4cf4fbc3202f609": "rgba(0,155,155,0.1)", // CZ
  "6023c7f660ddad422b9627aa": "rgba(0,107,255,0.17)", // GE
  "603f684583cc20e514cd1109": "rgba(255,0,0,0.1)" // PS
};

class DeliveryCalendar extends PureComponent<DeliveryCalendarProps, DeliveryCalendarState> {
  constructor(props: DeliveryCalendarProps) {
    super(props);
    const manufacturer = manufacturerUtils.checkCurrentUserManufacturerObject(props.context.manufacturers);
    this.state = {
      search: "",
      status: { value: "", label: "All" },
      manufacturer: manufacturer,
      type: { value: "commodities", label: "Commodities" },
      manufacturerLocked: manufacturer !== "",
      loading: true,
      selectedEvent: null,
      events: []
    };
  }

  componentDidMount() {
    this.setState({ events: this.collectData(), loading: true });
  }

  componentDidUpdate(
    prevProps: Readonly<DeliveryCalendarProps>,
    prevState: Readonly<DeliveryCalendarState>,
    snapshot?: any
  ) {
    if (
      !_.isEqual(this.props.context.commodities, prevProps.context.commodities) ||
      !_.isEqual(this.props.context.packagings, prevProps.context.packagings) ||
      !_.isEqual(this.props.context.packagingOrders, prevProps.context.packagingOrders) ||
      this.state.type.value !== prevState.type.value
    ) {
      this.setState({ events: this.collectData() });
    }
  }

  /**
   * Collect events to be displayed in the calendar
   * @returns {Array<EventSourceInput>} list of calendar events populated with additional information and properties
   */
  collectData = () => {
    const { commodities, suppliers, userdata, manufacturers, orders, packagings, packagingOrders } = this.props.context;
    const { type } = this.state;
    const events: Array<EventSourceInput> = [];
    if (!type.value || type.value === "commodities") {
      for (let i = 0; i < commodities.length; i++) {
        const commodity = commodities[i];
        if (
          !commodity.orders ||
          (Array.isArray(commodity.orders) && commodity.orders.length === 0) ||
          !Array.isArray(commodity.orders)
        )
          continue;
        for (let j = 0; j < commodity.orders.length; j++) {
          const commodityOrder = commodity.orders[j];
          const supplier = commodityOrder.supplier
            ? baseUtils.getDocFromCollection(suppliers, commodityOrder.supplier)
            : null;
          const person = commodityOrder.person ? baseUtils.getDocFromCollection(userdata, commodityOrder.person) : null;
          const personName = person ? person.prename + "" + person.surname : null;
          const destination = commodityOrder.destination
            ? baseUtils.getDocFromCollection(manufacturers, commodityOrder.destination)
            : null;
          const relatedOrders = commodityOrder.orders
            ? commodityOrder.orders.map(order => baseUtils.getDocFromCollection(orders, order))
            : null;
          const deliveryDate = commodityUtils.resolveDeliveryDate(commodityOrder);
          const warehouseDestination = commodityOrder.warehouseDestination
            ? baseUtils.getDocFromCollection(manufacturers, commodityOrder.warehouseDestination)
            : null;
          events.push({
            title:
              commodityUtils.resolveStockUnit(commodityOrder.orderquantity, commodity.type) +
              " of " +
              commodity.title.en,
            start: deliveryDate.toISOString().split("T")[0],
            material: commodity,
            materialOrder: commodityOrder,
            supplier: supplier,
            person: person,
            personName: personName,
            destination: destination,
            warehouseDestination: warehouseDestination,
            stockTransferOrder: commodityOrder.stockTransferOrders,
            orders: relatedOrders ? relatedOrders.filter(o => !!o) : null,
            className: commodityOrder.delivered
              ? "fc-event-success"
              : deliveryDate < new Date()
              ? "fc-event-warning"
              : "",
            backgroundColor:
              commodityOrder.destination && destination
                ? _.get(MANUFACTURER_COLORS, commodityOrder.destination.toString())
                : "rgba(255,196,0,0.1)"
          });
        }
      }
    }
    if (!type.value || type.value === "packaging") {
      for (let i = 0; i < packagingOrders.length; i++) {
        const pOrder = packagingOrders[i];
        const packaging = packagings.find(p => p._id.toString() === pOrder.packaging.toString())!;
        const supplier = pOrder.supplier ? baseUtils.getDocFromCollection(suppliers, pOrder.supplier) : null;
        const person = pOrder.person ? baseUtils.getDocFromCollection(userdata, pOrder.person) : null;
        const personName = person ? person.prename + "" + person.surname : null;
        const destination = pOrder.destination
          ? baseUtils.getDocFromCollection(manufacturers, pOrder.destination)
          : null;
        const relatedOrders = pOrder.relatedOrders
          ? pOrder.relatedOrders.map(order => baseUtils.getDocFromCollection(orders, order))
          : null;
        const deliveryDate = pOrder.expectedDelivery;
        events.push({
          title: `${pOrder.orderQuantity} pcs. of ${packagingUtils.getShortPackagingInfo(packaging)}`,
          start: deliveryDate.toISOString().split("T")[0],
          material: packaging,
          materialOrder: pOrder,
          supplier: supplier,
          person: person,
          personName: personName,
          destination: destination,
          orders: relatedOrders ? relatedOrders.filter(o => !!o) : null,
          className: pOrder.delivered ? "fc-event-success" : deliveryDate < new Date() ? "fc-event-warning" : "",
          backgroundColor:
            pOrder.destination && destination
              ? _.get(MANUFACTURER_COLORS, pOrder.destination.toString())
              : "rgba(255,196,0,0.1)"
        });
      }
    }
    return events;
  };

  /**
   * Get events filtered by manufacturer and search
   * @returns {Array<EventSourceInput>} list of filtered events to display in the calendar
   */
  getFilteredEvents = () => {
    const { manufacturer, events, search, status, type } = this.state;
    let keys: string[];
    if (type.value === "") {
      // case all
      keys = [
        "title",
        "material.packaging_type",
        "personName",
        "supplier.name",
        "orders.title",
        "orders.identifier",
        "material.title.de",
        "material.title.en",
        "destination.name"
      ];
    } else {
      keys =
        type.value === "packaging"
          ? ["material.packaging_type", "personName", "supplier.name", "orders.title", "orders.identifier"] // case packaging
          : [
              "title",
              "destination.name",
              "material.title.de",
              "material.tile.en",
              "personName",
              "supplier.name",
              "orders.title",
              "orders.identifier"
            ]; // case commodity
    }

    let tmpEvents = events.slice();
    if (manufacturer) {
      tmpEvents = tmpEvents.filter(
        (event: any) =>
          event.materialOrder.warehouseDestination === manufacturer.value ||
          (event.materialOrder.destination && event.materialOrder.destination.toString() === manufacturer.value) ||
          (event.materialOrder.stockTransferOrders &&
            event.materialOrder.stockTransferOrders.some(
              (sto: StockTransferOrder) => sto.destination.toString() === manufacturer.value
            ))
      );
    }
    if (search.trim()) {
      return baseUtils.doFuseSearch(tmpEvents, search, keys);
    }
    if (status.value !== "") {
      tmpEvents = tmpEvents.filter((event: any) =>
        status.value === "open" ? !event.materialOrder.delivered : event.materialOrder.delivered
      );
    }
    return tmpEvents;
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value.toLowerCase() });

  handleSelectEvent = (arg: { el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: View }) => {
    //arg.jsEvent.preventDefault();
    this.setState({ selectedEvent: arg.event._def.extendedProps });
  };

  handleSelectChange = (name: string, entry: "" | { value: string; label: string }) =>
    // @ts-ignore
    this.setState({ [name]: entry });

  handleHideModal = () => this.setState({ selectedEvent: null });

  render() {
    const { history, location, match, context } = this.props;
    const { manufacturers } = context;
    const { manufacturer, manufacturerLocked, selectedEvent, search, status, type, loading } = this.state;
    const events = this.getFilteredEvents();

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <OrderOverviewModal
          calendarType={ViewType.FULL_VIEW}
          event={selectedEvent}
          context={context}
          onClose={this.handleHideModal}
        />
        <CalendarViewHeader
          type={type}
          calendarType={ViewType.FULL_VIEW}
          search={search}
          status={status}
          onSearch={this.handleSearch}
          onSelectChange={this.handleSelectChange}
          history={history}
          location={location}
          match={match}
          manufacturer={manufacturer}
          manufactures={manufacturers}
          manufacturerLocked={manufacturerLocked}
        />
        <CalendarView events={events} onSelectEvents={this.handleSelectEvent} />
      </div>
    );
  }
}

export default DeliveryCalendar;
