import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import Order from "./Order";
import { DataContext } from "../../context/dataContext";

interface OrderWrapperProps extends RouteComponentProps<OrderParams, {}, {}> {}

interface OrderParams {
  id: string;
}

const OrderWrapper: React.FunctionComponent<OrderWrapperProps> = props => {
  const context = useContext(DataContext);
  return <Order context={context} {...props} />;
};

export default OrderWrapper;
