import i18n from "../../../translations/i18n";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BaseListing from "../../listings/BaseListing";
import { paginate } from "../../common/Pagination";
import DefaultBatchRow from "./listingRows/DefaultBatchRow";
import { useDataContext } from "../../../context/dataContext";
import { Batch } from "../../../model/warehouse/batch.types";
import {
  useWarehouseContext,
  useWarehouseDispatch,
  WarehouseActionType,
  WarehouseListingTabNames
} from "../../../context/warehouseContext";
import {
  filterListingDocuments,
  getBatches,
  getDocumentsForTab,
  isBatch,
  isCommodityWithBatches,
  isDeliveryAnnouncement
} from "../../../utils/warehouseFilterUtils";
import { CommodityWithBatches } from "../../../model/warehouse/customTypes.types";
import DefaultCommodityWithBatchesRow from "./listingRows/DefaultCommodityWithBatchesRow";
import { isRemoteWarehouse } from "../../../utils/warehouseUtils";
import { DeliveryAnnouncement } from "../../../model/warehouse/deliveryAnnouncement.types";
import DefaultDeliveryAnnouncementRow from "./listingRows/DefaultDeliveryAnnouncementRow";

const DEFAULT_PAGE_SIZE = 100;

export interface TabDefinition {
  name: WarehouseListingTabNames;
  tableHeader: Array<{ title: string; size?: number; colSpan?: number; spanClasses?: string }>;
  tabContent: JSX.Element | string;
  additionalTabContentFn?: () => number; // display a number next to tab content
  additionalTabContentDependencies?: React.DependencyList;
  isDisabled?: boolean | (() => boolean);
}

const WarehouseListingTabPanel: React.FC = () => {
  const dataContext = useDataContext();
  const warehouseContext = useWarehouseContext();
  const dispatch = useWarehouseDispatch();
  const { batch, deliveryAnnouncement, reservation } = dataContext;
  const {
    activeTab: activeTabContext,
    configuration,
    selectedLocation,
    productFilter,
    additionalProductFilter,
    query,
    warehouseListingTabDocuments
  } = warehouseContext;

  const standardBatchTableHeader = useMemo(
    () => [
      { title: "#", size: 1 },
      { title: i18n.t("warehouse:rawMaterial") },
      { title: i18n.t("common:articleNumber") },
      { title: i18n.t("warehouse:supplier") },
      { title: i18n.t("warehouse:batch") },
      { title: i18n.t("warehouse:bbd") },
      { title: i18n.t("warehouse:amount") },
      { title: i18n.t("warehouse:warehouse") }
    ],
    []
  );

  const standardCommodityTableHeader = useMemo(
    () => [
      { title: "#", size: 1 },
      { title: i18n.t("warehouse:rawMaterial") },
      { title: i18n.t("common:articleNumberAbbreviation") },
      { title: i18n.t("warehouse:supplier") },
      { title: i18n.t("warehouse:bbd") },
      { title: i18n.t("warehouse:amount") },
      { title: i18n.t("warehouse:reserved") },
      { title: i18n.t("warehouse:warehouse") }
    ],
    []
  );

  const standardDeliveryAnnouncementTableHeader = useMemo(
    () => [
      { title: i18n.t("warehouse:reference") },
      { title: i18n.t("common:article") },
      { title: i18n.t("warehouse:weight") },
      { title: i18n.t("warehouse:sender") },
      { title: i18n.t("common:date") },
      { title: i18n.t("warehouse:warehouse"), size: 9 }
    ],
    []
  );

  const rawMaterialTab: TabDefinition = useMemo(
    () => ({
      name: WarehouseListingTabNames.RAW_MATERIAL,
      tabContent: i18n.t("warehouse:rawMaterials"),
      tableHeader: standardCommodityTableHeader
    }),
    []
  );
  const avisTab: TabDefinition = useMemo(
    () => ({
      name: WarehouseListingTabNames.AVIS,
      tabContent: i18n.t("warehouse:avisTab"),
      tableHeader: standardDeliveryAnnouncementTableHeader
    }),
    []
  );
  const incomingTab: TabDefinition = useMemo(
    () => ({
      name: WarehouseListingTabNames.INCOMING,
      tabContent: i18n.t("warehouse:incomingTab"),
      tableHeader: standardBatchTableHeader,
      additionalTabContentFn: () => getBatches(WarehouseListingTabNames.INCOMING, dataContext, warehouseContext).length,
      additionalTabContentDependencies: [
        batch,
        configuration,
        selectedLocation,
        productFilter,
        additionalProductFilter,
        query
      ],
      isDisabled:
        !!selectedLocation &&
        isRemoteWarehouse(selectedLocation.warehouse, selectedLocation.warehouseArea, configuration)
    }),
    [batch, configuration, selectedLocation, productFilter, additionalProductFilter, query]
  );
  const availableTab: TabDefinition = useMemo(
    () => ({
      name: WarehouseListingTabNames.AVAILABLE,
      tabContent: i18n.t("warehouse:available"),
      tableHeader: standardCommodityTableHeader
    }),
    []
  );
  const reservedTab: TabDefinition = useMemo(
    () => ({
      name: WarehouseListingTabNames.RESERVED,
      tabContent: i18n.t("warehouse:reserved"),
      tableHeader: standardCommodityTableHeader
    }),
    []
  );

  const tabDefinition = useMemo((): Array<TabDefinition> => {
    return [rawMaterialTab, avisTab, incomingTab, availableTab, reservedTab];
  }, [rawMaterialTab, avisTab, incomingTab, availableTab, reservedTab]);

  const [activeTab, setActiveTab] = useState<TabDefinition>(rawMaterialTab);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  // on mount and batch or configuration update
  useEffect(() => {
    dispatch({
      type: WarehouseActionType.SELECT_TAB,
      payload: {
        tab: activeTab.name,
        documents: getDocumentsForTab(activeTab.name, dataContext, warehouseContext),
        keepSelected: true
      }
    });
  }, [batch, deliveryAnnouncement, reservation, configuration]);

  useEffect(() => {
    if (activeTabContext !== activeTab.name) {
      const tabDef = tabDefinition.find(t => t.name === activeTabContext);
      if (tabDef) setActiveTab(tabDef);
    }
  }, [activeTabContext]);

  // Reset current page if any filter changes
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedLocation]);

  const filteredDocuments: Array<Batch | CommodityWithBatches | DeliveryAnnouncement> = useMemo(() => {
    return filterListingDocuments(warehouseListingTabDocuments, warehouseContext);
  }, [warehouseListingTabDocuments, selectedLocation, query, productFilter, additionalProductFilter]);

  const handleChangePage = useCallback(currentPage => setCurrentPage(currentPage), []);
  const handleChangePageSize = useCallback(pageSize => setPageSize(pageSize), []);

  const handleChangeTab = useCallback(
    (tab: TabDefinition) => {
      setActiveTab(tab);
      setCurrentPage(1);
      dispatch({
        type: WarehouseActionType.SELECT_TAB,
        payload: { tab: tab.name, documents: getDocumentsForTab(tab.name, dataContext, warehouseContext) }
      });
    },
    [dataContext, warehouseContext]
  );

  return (
    <>
      <div className="mb-2 bg-light" style={{ borderBottom: "1px solid #ebedf2" }}>
        <div>
          <WarehouseListingTabs
            activeTab={activeTab.name}
            tabDefinition={tabDefinition}
            onChangeTab={handleChangeTab}
          />
        </div>
      </div>
      <div className="kt-portlet__body px-0 pt-0">
        <BaseListing
          headerDefinition={activeTab.tableHeader}
          documents={filteredDocuments}
          bodyContent={
            <>
              {paginate(filteredDocuments, currentPage, pageSize).map(entry => (
                <WarehouseListingRow key={entry._id.toString()} activeTab={activeTab.name} entry={entry} />
              ))}
            </>
          }
          currentPage={currentPage}
          pageSize={pageSize}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangePageSize}
        />
      </div>
    </>
  );
};

interface WarehouseListingTabsProps {
  activeTab: WarehouseListingTabNames;
  tabDefinition: Array<TabDefinition>;
  onChangeTab: (tab: TabDefinition) => void;
}

const WarehouseListingTabs: React.FC<WarehouseListingTabsProps> = ({ activeTab, tabDefinition, onChangeTab }) => {
  return (
    <ul className="nav nav-tabs justify-content-end border-bottom-0 mb-0">
      {tabDefinition.map(tab => {
        return <WarehouseListingTab key={tab.name} activeTab={activeTab} tab={tab} onChangeTab={onChangeTab} />;
      })}
    </ul>
  );
};

interface WarehouseListingTabProps {
  activeTab: WarehouseListingTabNames;
  tab: TabDefinition;
  onChangeTab: (tab: TabDefinition) => void;
}

const WarehouseListingTab: React.FC<WarehouseListingTabProps> = ({ activeTab, tab, onChangeTab }) => {
  const handleClick = useCallback(() => onChangeTab(tab), [tab]);

  const additionalTabContent = useMemo(
    () => (tab.additionalTabContentFn ? tab.additionalTabContentFn() : 0),
    tab.additionalTabContentDependencies || []
  );

  return (
    <li
      className={"nav-item " + (tab.isDisabled ? "not-allowed" : "cursorhand")}
      onClick={tab.isDisabled ? undefined : handleClick}
    >
      <span
        className={"nav-link px-2 " + (activeTab === tab.name ? "active" : "") + (tab.isDisabled ? " disabled" : "")}
      >
        <span className="text-black">
          {tab.tabContent}{" "}
          {!tab.isDisabled && tab.additionalTabContentFn && (
            <span className="text-danger kt-font-bold">{additionalTabContent}</span>
          )}
        </span>
      </span>
    </li>
  );
};

interface WarehouseListingRowProps {
  activeTab: WarehouseListingTabNames;
  entry: Batch | CommodityWithBatches | DeliveryAnnouncement;
}

const WarehouseListingRow: React.FC<WarehouseListingRowProps> = ({ activeTab, entry }) => {
  if (isBatch(entry))
    return (
      <DefaultBatchRow
        activeTab={activeTab}
        batch={entry}
        expandInitially={activeTab === WarehouseListingTabNames.BATCHES}
      />
    );
  else if (isCommodityWithBatches(entry))
    return <DefaultCommodityWithBatchesRow activeTab={activeTab} commodity={entry} />;
  else if (isDeliveryAnnouncement(entry)) return <DefaultDeliveryAnnouncementRow deliveryAnnouncement={entry} />;
  return null;
};

export default WarehouseListingTabPanel;
