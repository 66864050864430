import React, { ChangeEvent, PureComponent } from "react";
import { toast } from "react-toastify";
import { ExtendedEMOrder } from "../../../model/customTypes.types";
import dbService, { EMORDERS } from "../../../services/dbService";

interface EMOrderNoteProps {
  order: ExtendedEMOrder;
  internal?: boolean;
}

interface EMOrderNoteState {
  edit: boolean;
  note: string;
}

class EMOrderNote extends PureComponent<EMOrderNoteProps, EMOrderNoteState> {
  constructor(props: EMOrderNoteProps) {
    super(props);
    this.state = { edit: false, note: props.order.note };
  }

  componentDidUpdate(prevProps: Readonly<EMOrderNoteProps>, prevState: Readonly<EMOrderNoteState>, snapshot?: any) {
    if (prevProps.order.note !== this.props.order.note) this.setState({ note: this.props.order.note });
  }

  updateNote = async () => {
    const { order, internal } = this.props;
    if (internal) return;
    const { note } = this.state;
    try {
      const result = await dbService.updateDocument(EMORDERS, order._id, { note: note.trim() });
      if (result && result.modifiedCount) toast.success("Note successfully updated");
      else toast.error("Note could not be updated");
    } catch (e) {
      toast.error("An unexpected error occurred: " + e.message);
    }
  };

  handleButton = () => {
    const { edit } = this.state;
    if (edit) this.updateNote();
    this.setState({ edit: !edit });
  };

  handleNote = (e: ChangeEvent<HTMLTextAreaElement>) => this.setState({ note: e.target.value });

  render() {
    const { internal } = this.props;
    const { edit, note } = this.state;
    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Note</h3>
          </div>
        </div>
        <div className="kt-portlet__body ">
          {!edit ? (
            <div className="kt-section kt-section--space-sm">
              <span className={note ? "kt-font-dark kt-font-bolder" : ""} style={{ fontSize: "1.1rem" }}>
                {note ? note : "Additional information about the order can be stored here."}
              </span>
            </div>
          ) : (
            <div className="form-group form-group-last mb-3">
              <textarea className="form-control" id="noteTextarea" rows={2} onChange={this.handleNote} value={note} />
            </div>
          )}
          {!internal && (
            <div className="kt-section kt-section--last">
              <button type="button" onClick={this.handleButton} className="btn btn-label-brand btn-sm btn-bold">
                {edit ? "Save Note" : "Edit Note"}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default EMOrderNote;
