import _ from "lodash";
import Joi from "joi";
import Select from "react-select";
import countryList from "i18n-iso-countries";
import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { RouteComponentProps } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import CanOnlyBeDoneByProcurement from "../common/CanOnlyBeDoneByProcurement";
import SelectEmployee from "../common/SelectEmployee";
import { DataContext } from "../../context/dataContext";
import { UserdataDocument } from "../../model/userdata.types";
import userService from "../../services/userService";
import accessUtils, { CREATELOCATIONS } from "../../utils/accessUtils";
import dbService, { SUPPLIERS, USERDATA } from "../../services/dbService";
import userdataUtils, { PersonData } from "../../utils/userdataUtils";
import { Suppliers } from "../../model/suppliers.types";

interface CreateSupplierProps extends RouteComponentProps<{}, {}, {}> {}

interface CreateSupplierState {
  supplierData: {
    name: string;
    street: string;
    streetNr: string;
    zip: string;
    city: string;
    country: string;
    rating: string;
    additionalInformation: string;
  };
  owner: UserdataDocument | undefined;
  primaryContact: string;
  person: PersonData;
  saving: boolean;
}

class CreateSupplier extends PureComponent<CreateSupplierProps, CreateSupplierState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  emailSchema = Joi.string()
    .email({ tlds: { allow: false }, minDomainSegments: 1 })
    .required()
    .label("Email");

  constructor(props: CreateSupplierProps) {
    super(props);
    this.state = {
      supplierData: {
        name: "",
        street: "",
        streetNr: "",
        zip: "",
        city: "",
        country: "",
        rating: "?",
        additionalInformation: ""
      },
      person: {
        title: "",
        prename: "",
        surname: "",
        email: "",
        position: "",
        phone: ""
      },
      owner: userService.getUserData(),
      primaryContact: "",
      saving: false
    };
  }

  handleSupplierChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const supplierData = _.cloneDeep(this.state.supplierData);
    _.set(supplierData, e.target.name, e.target.value);
    this.setState({ supplierData });
  };
  handleSelectChange = (name: string, entry: { value: string; label: string } | "") => {
    const supplierData = _.cloneDeep(this.state.supplierData);
    _.set(supplierData, [name], entry ? entry.value : "");
    this.setState({ supplierData });
  };
  handleContactChange = (entry: { value: string; label: string } | "") => {
    this.setState({ primaryContact: entry ? entry.value : "" });
  };

  handlePersonChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const person = _.cloneDeep(this.state.person);
    _.set(person, e.target.name, e.target.value);
    this.setState({ person });
  };
  handlePersonSelect = (entry: { value: string; label: string }) => {
    const person = _.cloneDeep(this.state.person);
    _.set(person, "title", entry.value);
    this.setState({ person });
  };

  /**
   * Handle supplier and if required person creation
   * If person was created and supplier creation failed it aborts, but sets the person as selected contact person
   * so the user can try again. Success text is also individually shown for each creation
   */
  handleSave = async () => {
    const { history } = this.props;
    const { supplierData, person, owner, primaryContact } = this.state;
    const { name, street, streetNr, zip, city, country, rating, additionalInformation } = supplierData;
    const { updateDocumentInContext } = this.context;
    this.setState({ saving: true });
    // Create person first
    let personId = primaryContact;
    try {
      if (!primaryContact) {
        const user = userdataUtils.getDefaultUserdata(person);
        const result = await dbService.insertDocument(USERDATA, user);
        if (result && result.insertedId) {
          personId = result.insertedId.toString();
          toast.success(`User for ${person.prename} ${person.surname} successfully created`);
          updateDocumentInContext(USERDATA, result.insertedId);
        } else {
          toast.error("Contact person could not be created. Aborting process");
          return;
        }
      }
      // Create supplier
      const supplier: Suppliers = {
        name: name,
        address: [{ street, streetnr: streetNr, zip, city, country }],
        owner: new BSON.ObjectId((!!owner ? owner._id : userService.getUserId()).toString()),
        rating,
        additionalInformation,
        person: [new BSON.ObjectId(personId)],
        files: [],
        disabled: false
      };
      const result = await dbService.insertDocument(SUPPLIERS, supplier);
      if (result && result.insertedId) {
        toast.success("Supplier and contract person successfully created");
        updateDocumentInContext(SUPPLIERS, result.insertedId);
        history.push("/supplier/" + result.insertedId.toString());
      } else {
        toast.error("Supplier could not be created.");
        // Set primary contact if only supplier could not be created
        if (!primaryContact) this.setState({ primaryContact: personId });
      }
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ saving: false });
    }
  };

  /**
   * Check the current input for errors.
   * @returns { Array<string> } Contains all validation errors
   */
  checkForErrors = () => {
    const { supplierData, person, owner, primaryContact } = this.state;
    const { name, street, streetNr, zip, city, country } = supplierData;
    const { prename, surname, email } = person;
    const errors: Array<string> = [];
    if (name.length < 2) errors.push("Supplier name too short");
    if (!country) errors.push("Primary address country missing");
    if (!street) errors.push("Primary address street missing");
    if (!streetNr) errors.push("Primary address street number missing");
    if (!zip) errors.push("Primary address zip missing");
    if (!city) errors.push("Primary address city missing");
    if (!owner) errors.push("No owner assigned");
    if (!primaryContact) {
      if (!prename) errors.push("Contact prename missing");
      if (!surname) errors.push("Contact surname missing");
      if (this.emailSchema.validate(email).error) errors.push("Contact email invalid");
    }
    return errors;
  };

  /**
   * Renders the customer rating summaries
   * @param rating: Rating whose summary should be rendered
   * @returns { JSX.Element } Customer rating summary
   */
  renderCustomerRatingSummary = (rating: string) => {
    let header, content;
    switch (rating) {
      case "A":
        header = "A-Supplier";
        content = "Very reliable supplier with a huge selection, very good prices and fast delivery";
        break;
      case "B":
        header = "B-Supplier";
        content = "Reliable supplier with a large selection, good prices and fast delivery";
        break;
      case "C":
        header = "C-Supplier";
        content = "Sometimes unreliable, poor communication, limited choice, rather expensive and long delivery times";
        break;
      default:
        header = "Unknown";
        content = "New supplier for whom no experience has yet been gained";
    }
    return (
      <span className="kt-option__label">
        <span className="kt-option__head">
          <span className="kt-option__title">{header}</span>
        </span>
        <span className="kt-option__body">{content}</span>
      </span>
    );
  };

  render() {
    const { history } = this.props;
    const { supplierData, person, saving, primaryContact, owner } = this.state;
    const { userdata } = this.context;
    const possibleContacts = userdata
      .filter(u => u.company_id !== "internal")
      .sort((u1, u2) => u1.surname.localeCompare(u2.surname));
    const errors = this.checkForErrors();
    const canCreate = accessUtils.canCreateData(CREATELOCATIONS.SUPPLIER);
    const contact = possibleContacts.find(u => u._id.toString() === primaryContact);
    const countryLabel = Object.values(countryList.getNames("en")).find(
      c => countryList.getAlpha2Code(c, "en") === supplierData.country
    );

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand fa fa-building" />
            </span>
            <h3 className="kt-portlet__head-title">Create a New Supplier</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <button onClick={history.goBack} className="btn btn-clean kt-margin-r-10">
              <i className="la la-arrow-left" />
              <span className="kt-hidden-mobile">Back</span>
            </button>
          </div>
        </div>
        <div className="kt-portlet__body">
          {canCreate ? (
            <div className="row">
              <div className="col-xl-2" />
              <div className="col-xl-8">
                <div className="kt-section kt-section--first">
                  <div className="kt-section__body">
                    <h3 className="kt-section__title kt-section__title-lg">Supplier Info:</h3>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Supplier name</label>
                      <div className="col-9">
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          value={supplierData.name}
                          onChange={this.handleSupplierChange}
                          placeholder="Supplier"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Primary Address</label>
                      <div className="col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="street"
                          value={supplierData.street}
                          onChange={this.handleSupplierChange}
                          placeholder="Street"
                        />
                      </div>
                      <div className="col-1">
                        <input
                          className="form-control"
                          type="text"
                          name="streetNr"
                          value={supplierData.streetNr}
                          onChange={this.handleSupplierChange}
                          placeholder="Nr."
                        />
                      </div>
                      <div className="col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="zip"
                          value={supplierData.zip}
                          onChange={this.handleSupplierChange}
                          placeholder="Zip"
                        />
                      </div>
                      <div className="col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="city"
                          value={supplierData.city}
                          onChange={this.handleSupplierChange}
                          placeholder="City"
                        />
                      </div>
                      <div className="col-2">
                        <Select
                          className="select-default"
                          options={Object.values(countryList.getNames("en")).map((item: string) => {
                            return {
                              value: item,
                              label: _.upperFirst(item)
                            };
                          })}
                          value={
                            supplierData.country !== ""
                              ? { value: supplierData.country, label: supplierData.country }
                              : {
                                  value: "",
                                  label: "Select Country"
                                }
                          }
                          onChange={(value: any) => this.handleSelectChange("country", value)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Owner</label>
                      <div className="col-9">
                        <SelectEmployee
                          userdata={userdata}
                          selectedUser={owner}
                          onSelectUser={owner => this.setState({ owner })}
                        />
                      </div>
                    </div>
                    <h3 className="kt-section__title kt-section__title-lg">Optional Data:</h3>
                    <div className="row">
                      {["A", "B", "C", "?"].map(rat => (
                        <div key={rat} className="col-lg-3 equal">
                          <label className="kt-option">
                            <span className="kt-option__control">
                              <span className="kt-radio">
                                <input
                                  type="radio"
                                  name="rating"
                                  value={rat}
                                  checked={supplierData.rating === rat}
                                  onChange={this.handleSupplierChange}
                                />
                                <span />
                              </span>
                            </span>
                            {this.renderCustomerRatingSummary(rat)}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="row">
                      <div className="col-12 mt-4">
                        <div className="form-group form-group-last">
                          <textarea
                            className="form-control"
                            rows={3}
                            name="additionalInformation"
                            value={supplierData.additionalInformation}
                            onChange={this.handleSupplierChange}
                            placeholder="Additional Information"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
                <div className="kt-section">
                  <div className="kt-section__body">
                    <h3 className="kt-section__title kt-section__title-lg">Contact Person:</h3>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Primary contact person</label>
                      <div className="col-9">
                        <Select
                          className="select-default"
                          isClearable={true}
                          options={[{ value: "", label: "Create New" }].concat(
                            possibleContacts.map(c => {
                              return {
                                value: c._id.toString(),
                                label: c.prename + " " + c.surname
                              };
                            })
                          )}
                          value={
                            primaryContact !== ""
                              ? {
                                  value: primaryContact,
                                  label: contact ? contact.prename + " " + contact.surname : "Unknown User"
                                }
                              : { value: "new", label: "Create New" }
                          }
                          onChange={(value: any) => this.handleContactChange(value || "")}
                        />
                      </div>
                    </div>
                    {primaryContact === "" && (
                      <>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Name</label>
                          <div className="col-3">
                            <Select
                              isClearable={true}
                              className="select-default"
                              options={[
                                { value: "Mr", label: "Mr." },
                                { value: "Mrs", label: "Mrs." }
                              ]}
                              value={
                                person.title !== ""
                                  ? { value: person.title, label: person.title + "." }
                                  : { value: "", label: "No Title" }
                              }
                              onChange={(value: any) =>
                                this.handlePersonSelect(value || { value: "", label: "No Title" })
                              }
                            />
                          </div>
                          <div className="col-3">
                            <input
                              className="form-control"
                              type="text"
                              name="prename"
                              value={person.prename}
                              onChange={this.handlePersonChange}
                              placeholder="Prename"
                            />
                          </div>
                          <div className="col-3">
                            <input
                              className="form-control"
                              type="text"
                              name="surname"
                              value={person.surname}
                              onChange={this.handlePersonChange}
                              placeholder="Surname"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Primary Email Address</label>
                          <div className="col-9">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="la la-at" />
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                name="email"
                                value={person.email}
                                onChange={this.handlePersonChange}
                                placeholder="Email"
                              />
                            </div>
                          </div>
                        </div>
                        <h3 className="kt-section__title kt-section__title-lg">Optional Data:</h3>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Position</label>
                          <div className="col-9">
                            <input
                              className="form-control"
                              type="text"
                              name="position"
                              value={person.position}
                              onChange={this.handlePersonChange}
                              placeholder="Position"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Telephone Number</label>
                          <div className="col-9">
                            <input
                              className="form-control"
                              type="text"
                              name="phone"
                              value={person.phone}
                              onChange={this.handlePersonChange}
                              placeholder="Telephone Number"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <CanOnlyBeDoneByProcurement location="supplier" />
          )}
        </div>
        <div className="kt-portlet__foot text-right">
          {errors.length > 0 || !canCreate ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="supplierErrors">
                  {canCreate ? (
                    errors.map((e, key) => {
                      return (
                        <React.Fragment key={key}>
                          <span className="text-danger">
                            <b>{e}</b>
                          </span>
                          <br />
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <span className="text-danger">
                      <b>You are not allowed to create suppliers</b>
                    </span>
                  )}
                </Tooltip>
              }
              placement="left"
            >
              <button className="btn btn-success disabled">Save</button>
            </OverlayTrigger>
          ) : (
            <button className="btn btn-success" disabled={saving} onClick={this.handleSave}>
              {saving && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Save
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default CreateSupplier;
