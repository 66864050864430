import React, { PureComponent } from "react";

interface RatingInfoProps {
  rating: string;
}

interface RatingInfoState {}

class RatingInfo extends PureComponent<RatingInfoProps, RatingInfoState> {
  render() {
    const { rating } = this.props;
    let className = "kt-badge kt-badge--inline kt-badge--pill ";
    if (rating === "A") {
      className += "kt-badge--success";
    }
    if (rating === "B") {
      className += "kt-badge--warning";
    }
    if (rating === "C") {
      className += "kt-badge--danger";
    }
    if (rating === "?") {
      className += "kt-badge--light";
    }
    return <span className={className}>{rating}</span>;
  }
}

export default RatingInfo;
