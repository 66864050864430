import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Joi from "joi";
import userService from "../../services/userService";
import { ROLES } from "../../utils/userdataUtils";
import HistoryBackButton from "../listings/common/HistoryBackButton";
import { DataContext } from "../../context/dataContext";
import baseUtils from "../../utils/baseUtils";
import supplyCentralService from "../../services/supplyCentralService";
import dbService, { USERDATA } from "../../services/dbService";
import slackService from "../../services/slackService";
import config from "../../config/config.json";

interface SupplyCentralUserCreationProps extends RouteComponentProps<{}, {}, {}> {}

interface SupplyCentralUserCreationState {
  creating: boolean;
  loginMail: string;
  connectedUser: "" | { label: string; value: string };
  userList: Array<{ label: string; value: string }>;
  tokenLink: string;
  copied: boolean;
}

class SupplyCentralUserCreation extends PureComponent<SupplyCentralUserCreationProps, SupplyCentralUserCreationState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  emailSchema = Joi.string()
    .email({ tlds: { allow: false }, minDomainSegments: 1 })
    .required()
    .label("Email");
  constructor(props: SupplyCentralUserCreationProps, context: React.ContextType<typeof DataContext>) {
    super(props);
    this.state = {
      creating: false,
      loginMail: "",
      connectedUser: "",
      tokenLink: "",
      copied: false,
      userList: this.getUserList(context)
    };
  }

  /**
   * Get prepared users
   * @param context data context
   * @returns {Array<{ value: string; label: string }>} list of selectable users
   */
  getUserList = (context: React.ContextType<typeof DataContext>) =>
    context.userdata
      .filter(u => u.company_id && u.company_id !== "internal" && !u.user_id)
      .sort((u1, u2) => `${u1.prename} ${u1.surname}`.trim().localeCompare(`${u2.prename} ${u2.surname}`.trim()))
      .map(u => {
        const company = baseUtils.getDocFromCollection(context.companies, u.company_id);
        // Ignore entries without valid company
        if (!company) return;
        return {
          value: u._id.toString(),
          label: `${u.prename} ${u.surname} ${company ? "- " + company.name : ""}`.trim()
        };
      })
      .filter(u => !!u) as Array<{ label: string; value: string }>;

  /**
   * Check the current input for errors.
   * @returns { object } Contains all validation errors
   */
  checkForErrors = () => {
    const { loginMail, connectedUser } = this.state;
    const errors: any = {};
    if (this.emailSchema.validate(loginMail).error) errors["loginMail"] = "Login email invalid";
    if (!connectedUser) errors["connectedUser"] = "No user selected";
    return errors;
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });

  handleCreateUser = async () => {
    const { loginMail, connectedUser } = this.state;
    if (!connectedUser || this.emailSchema.validate(loginMail).error) return;
    this.setState({ creating: true });
    const slackChannel = "#novacode";
    const registerResult = await supplyCentralService.registerUser(loginMail);
    if (!registerResult) {
      toast.error("User could not be registered");
      await slackService.sendMessage(
        slackChannel,
        `SupplyCentral account creation failed in Step 1 for email (${loginMail}) and user ${connectedUser.label} (${connectedUser.value})`
      );
      this.setState({ creating: false });
      return;
    }
    const { id: userId, token, user } = registerResult;
    const deleteUser = async () => {
      if (user && user.isLoggedIn) {
        await supplyCentralService.deleteUser(user);
        await user.logOut();
      }
    };
    if (!userId) {
      toast.error("User could not be registered");
      await slackService.sendMessage(
        slackChannel,
        `SupplyCentral account creation failed in Step 1 for account id ${userId} (${loginMail}) and user ${connectedUser.label} (${connectedUser.value})`
      );
      await deleteUser();
      this.setState({ creating: false });
      return;
    }
    let result;
    try {
      result = await dbService.callFunction("setUserAndApiKeyValidity", [loginMail, userId, connectedUser.value]);
      if (result && result.result) {
        toast.success("SupplyCentral account successfully created");
        await slackService.sendMessage(
          slackChannel,
          `SupplyCentral account created for account id ${userId} (${loginMail}) and user ${connectedUser.label} (${connectedUser.value})`
        );
        this.context.updateDocumentInContext(USERDATA, connectedUser.value);
        this.setState({
          tokenLink: `${
            process.env.NODE_ENV === "production" ? config.supplyCentral : "http://localhost:3001/"
          }invitation?t=${token.key}`,
          creating: false
        });
      } else {
        toast.error("SupplyCentral account could not be linked with userdata");
        await slackService.sendMessage(
          slackChannel,
          `SupplyCentral account creation failed in Step 2 for account id ${userId} (${loginMail}) and user ${connectedUser.label} (${connectedUser.value}): ${result.message}`
        );
        await deleteUser();
        this.setState({ creating: false });
      }
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
      await slackService.sendMessage(
        slackChannel,
        `An exception was caught while creating a SupplyCentral account failed for account id ${userId} (${loginMail}) and user ${connectedUser.label} (${connectedUser.value})`
      );
      await deleteUser();
    } finally {
      if (user && user.isLoggedIn) user.logOut();
      this.setState({ creating: false });
    }
  };

  handleCopyToClipboard = async (e: any) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(this.state.tokenLink);
    } else {
      document.execCommand("copy", true, this.state.tokenLink);
    }
    this.setState({ copied: true });
  };

  handleReset = () => {
    this.setState({
      creating: false,
      loginMail: "",
      connectedUser: "",
      tokenLink: "",
      copied: false,
      userList: this.getUserList(this.context)
    });
  };

  render() {
    const { history } = this.props;
    const { creating, loginMail, connectedUser, userList, tokenLink, copied } = this.state;
    // Only allow access for sales and admin
    const canCreate = userService.hasRole(ROLES.SALES, false);
    if (!canCreate)
      return (
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand flaticon2-user-1" />
              </span>
              <h3 className="kt-portlet__head-title">Create a new SupplyCentral user</h3>
            </div>
            <div className="kt-portlet__head-toolbar">
              <HistoryBackButton history={history} />
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row justify-content-center">
              <div className="col-auto">
                <div className="alert alert-secondary my-2" role="alert">
                  <div className="alert-icon">
                    <i className="flaticon-warning" />
                  </div>
                  <div className="alert-text">You are not authorized for this action</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    const errors = this.checkForErrors();
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand flaticon2-user-1" />
            </span>
            <h3 className="kt-portlet__head-title">Create a new SupplyCentral user</h3>
            <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
              Reset
            </button>
          </div>
          <div className="kt-portlet__head-toolbar">
            <HistoryBackButton history={history} />
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-xl-2" />
            <div className="col-xl-8">
              <div className="kt-section">
                <div className="kt-section__body">
                  <div className="form-group row">
                    <label className="col-3 col-form-label">Login Email</label>
                    <div className="col-9">
                      <input
                        className={"form-control " + (errors["loginMail"] && "is-invalid")}
                        type="text"
                        autoComplete={"off"}
                        name="loginMail"
                        value={loginMail}
                        disabled={!!tokenLink}
                        onChange={this.handleChange}
                      />
                      {errors["loginMail"] && <span className="text-danger">{errors["loginMail"]}</span>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">Connected User</label>
                    <div className="col-9">
                      <Select
                        className={"select-default " + (errors["connectedUser"] && "is-invalid")}
                        options={userList}
                        isDisabled={!!tokenLink}
                        isClearable={true}
                        value={connectedUser || { value: "", label: "Select User" }}
                        onChange={(e: any) => this.setState({ connectedUser: e || "" })}
                      />
                      {errors["connectedUser"] && <span className="text-danger">{errors["connectedUser"]}</span>}
                    </div>
                  </div>
                  {tokenLink && (
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Login Link</label>
                      <div className="col-9">
                        <div className="input-group">
                          <input
                            className={"form-control"}
                            type="text"
                            name="tokenLink"
                            disabled={true}
                            value={tokenLink}
                          />
                          <div className="input-group-append">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="button-tooltip">{copied ? "Copied!" : "Copy to clipboard!"}</Tooltip>
                              }
                            >
                              <button className="btn btn-secondary" onClick={this.handleCopyToClipboard}>
                                <i className="flaticon2-copy pr-0" />
                              </button>
                            </OverlayTrigger>
                          </div>
                        </div>
                        <span className="text-danger">Note: The invitation link won't be available afterwards.</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-2" />
          </div>
        </div>
        <div className="kt-portlet__foot">
          <div className="float-right">
            {!!tokenLink ? (
              <button className="btn btn-secondary" onClick={this.handleReset}>
                Reset
              </button>
            ) : (
              <button
                type="button"
                disabled={Object.keys(errors).length > 0}
                className={
                  "btn btn-primary " +
                  (creating ? " kt-spinner kt-spinner--left kt-spinner--md kt-spinner--light" : "") +
                  (Object.keys(errors).length > 0 && " disabled")
                }
                onClick={creating ? undefined : this.handleCreateUser}
              >
                <i className={creating ? "d-none" : "la la-check"} />
                <span>Create User</span>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SupplyCentralUserCreation;
