import React from "react";
import { Modal } from "react-bootstrap";
import { DataContext } from "../../../../context/dataContext";
import commodityUtils from "../../../../utils/commodityUtils";
import CommodityWidget from "../../../common/CommodityWidget";
import PackagingWidget from "../../../common/PackagingWidget";
import baseUtils from "../../../../utils/baseUtils";
import {
  I_CIFA,
  I_CIFS,
  I_CIPA,
  I_CIPS,
  I_COUR,
  I_CPT,
  I_DAP,
  I_DDP,
  I_DPU,
  I_EXW,
  I_FAS,
  I_FCA,
  I_FOBA,
  I_FOBS
} from "../../../../utils/suppliersUtils";
import CompanyWidget from "../../../common/CompanyWidget";
import ManufacturerWidget from "../../../common/ManufacturerWidget";
import { DestinationCell } from "../../../commodities/tabPanels/CommodityCommodityOrders";
import PersonWidget from "../../../common/PersonWidget";
import { OrderWidget } from "../../common/BaseListingComponents";
import CreateCommodityOrderModal from "../../../commodities/modals/CreateCommodityOrderModal";
import DeliverCommodityOrderModal from "../../../commodities/modals/DeliverCommodityOrderModal";
import CreatePackagingOrderModal from "../../../packaging/modals/CreatePackagingOrderModal";
import DeliverPackagingOrderModal from "../../../packaging/modals/DeliverPackagingOrderModal";
import { EventData } from "../fullView/DeliveryCalendar";
import { DeliveryCalendarUserContext } from "../../../../context/deliveryCalendarUserContext";
import { MinimumEventData } from "../minimumView/MinimumDeliveryCalendar";
import { MinimumCalendarPackaging, PackagingsDocument } from "../../../../model/packagings.types";
import { MinimumCalendarPackagingOrder, PackagingOrderDocument } from "../../../../model/packagingOrders.types";
import { ViewType } from "./CalendarView";

interface OrderOverviewModalProps {
  event: EventData | MinimumEventData | null;
  calendarType: ViewType;
  context: React.ContextType<typeof DataContext> | React.ContextType<typeof DeliveryCalendarUserContext>;
  onClose: () => void;
}

const OrderOverviewModal: React.FunctionComponent<OrderOverviewModalProps> = ({
  event,
  context,
  calendarType,
  onClose
}) => {
  if (!event) return <div />;

  let additionalBodyContent;
  let alternativeFooterContent;

  if (calendarType === ViewType.FULL_VIEW) {
    const { material, materialOrder, person, supplier, orders, destination, stockTransferOrder, warehouseDestination } =
      event as EventData;
    const contextObject = context as React.ContextType<typeof DataContext>;

    // additional full view content
    additionalBodyContent = (
      <>
        <div className="form-group row mb-2">
          <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Supplier</label>
          <div className="col-8 align-self-center">
            {supplier ? (
              <CompanyWidget company={supplier} type={"supplier"} />
            ) : (
              <span className="text-muted">Not set</span>
            )}
          </div>
        </div>
        {stockTransferOrder && stockTransferOrder.length !== 0 ? (
          <div className={`form-group row ${stockTransferOrder.length === 1 ? " my-4" : " mb-2"}`}>
            <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Stock Transfer Order</label>
            <div className="col-8 align-self-center">
              <DestinationCell
                stockTransferOrders={stockTransferOrder}
                context={contextObject}
                customCommodity={commodityUtils.isCommodity(material) && !!material.type}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {warehouseDestination && (
          <div className="form-group row mb-2 ml-2">
            <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Warehouse</label>
            <ManufacturerWidget manufacturer={warehouseDestination} />
          </div>
        )}
        <div className="form-group row mb-2">
          <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Creator</label>
          <div className="col-8 align-self-center">
            {person ? <PersonWidget person={person} /> : <span className="text-muted">Not set</span>}
          </div>
        </div>
        <div className="form-group row mb-2">
          {commodityUtils.isCommodityOrder(materialOrder) ? (
            <>
              <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Shelf life</label>
              <div className="col-8 align-self-center">
                <span className="text-muted ">{materialOrder.shelflife} Months</span>
              </div>
            </>
          ) : (
            <>
              <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Expiry</label>
              <div className="col-8 align-self-center">
                <span className="text-muted ">
                  {materialOrder.expiry ? baseUtils.formatDate(materialOrder.expiry) : "Not set"}
                </span>
              </div>
            </>
          )}
        </div>
        <div className="form-group row mb-2">
          <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Incoterm</label>
          <div className="col-8 align-self-center">
            <span className="text-muted">{materialOrder.incoterm ? materialOrder.incoterm : "Not set"}</span>
          </div>
        </div>
        {materialOrder.carrier && (
          <div className="form-group row mb-2">
            <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">
              {materialOrder.incoterm === I_EXW
                ? "Pickup Company"
                : [I_FCA, I_CIFA, I_CIFS].includes(materialOrder.incoterm!)
                ? "Destination Port"
                : [I_FAS, I_FOBA, I_FOBS].includes(materialOrder.incoterm!)
                ? "Departure Port"
                : "Carrier"}
            </label>
            <div className="col-8 align-self-center">
              <span className="text-muted ">{materialOrder.carrier}</span>
            </div>
          </div>
        )}
        {materialOrder.packages !== null &&
          materialOrder.packages !== undefined &&
          materialOrder.packages.toString().trim() !== "" && (
            <div className="form-group row mb-2">
              <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Packages</label>
              <div className="col-8 align-self-center">
                <span className="text-muted ">{materialOrder.packages}</span>
              </div>
            </div>
          )}
        {materialOrder.notify && [I_FCA, I_FAS, I_FOBA, I_FOBS, I_CIFA, I_CIFS].includes(materialOrder.incoterm!) && (
          <div className="form-group row mb-2">
            <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Notify</label>
            <div className="col-8 align-self-center">
              <span className="text-muted ">{materialOrder.notify}</span>
            </div>
          </div>
        )}
        {((commodityUtils.isCommodityOrder(materialOrder) && materialOrder.notes) ||
          (!commodityUtils.isCommodityOrder(materialOrder) && materialOrder.note)) && (
          <div className="form-group row mb-2">
            <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Note</label>
            <div className="col-8 align-self-center">
              <span className="text-muted ">
                {commodityUtils.isCommodityOrder(materialOrder) ? materialOrder.notes : materialOrder.note}
              </span>
            </div>
          </div>
        )}
        {materialOrder.purchasePrice && (
          <div className="form-group row mb-2">
            <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Total Purchase Price</label>
            <div className="col-8 align-self-center">
              <span className="text-muted ">
                {`${materialOrder.purchasePrice.toFixed(2)} ${
                  commodityUtils.isCommodityOrder(materialOrder)
                    ? materialOrder.currency
                    : materialOrder.purchaseCurrency
                }`}
              </span>
            </div>
          </div>
        )}
        {orders && orders.length > 0 && (
          <div className="form-group row mb-2">
            <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Related Orders</label>
            <div className="col-8">
              {orders.map(order => (
                <OrderWidget key={order._id.toString()} document={order} prefix={"AT-"} />
              ))}
            </div>
          </div>
        )}
      </>
    );
    // footer full view content
    alternativeFooterContent = (
      <>
        {!materialOrder.delivered ? (
          <div className="btn-group">
            {commodityUtils.isCommodityOrder(materialOrder) && commodityUtils.isCommodity(material) ? (
              <>
                <CreateCommodityOrderModal
                  commodity={material}
                  context={contextObject}
                  commodityOrder={materialOrder}
                />
                <DeliverCommodityOrderModal
                  commodity={material}
                  context={contextObject}
                  commodityOrder={materialOrder}
                />
              </>
            ) : (
              !commodityUtils.isCommodityOrder(materialOrder) &&
              !commodityUtils.isCommodity(material) && (
                <>
                  <CreatePackagingOrderModal
                    packaging={material}
                    context={contextObject}
                    packagingOrder={materialOrder}
                  />
                  <DeliverPackagingOrderModal
                    packaging={material}
                    context={contextObject}
                    packagingOrder={materialOrder}
                  />
                </>
              )
            )}
          </div>
        ) : (
          <button className="btn btn-secondary" onClick={onClose}>
            Close
          </button>
        )}
      </>
    );
  }

  const { material, materialOrder, destination } = event;
  // using minimum view content as base
  return (
    <Modal
      show={!!event}
      onHide={onClose}
      centered
      name={"orderOverviewModal"}
      backdropClassName={"customBackdrop"}
      style={{ zIndex: 950 }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Order Overview</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto" style={{ maxHeight: "80vh" }}>
        <div className="form-group row mb-2">
          <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">
            {commodityUtils.isCommodity(material) ? "Commodity" : "Packaging"}
          </label>
          <div className="col-8 align-self-center">
            {commodityUtils.isCommodity(material) ? (
              <CommodityWidget commodity={material} context={context} />
            ) : (
              <PackagingWidget
                packaging={material as PackagingsDocument | MinimumCalendarPackaging}
                context={context}
              />
            )}
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Status</label>
          <div className="col-8 align-self-center">
            {materialOrder.delivered ? (
              <span className={"kt-badge kt-badge--inline kt-badge--pill kt-badge--success"}>Delivered</span>
            ) : (commodityUtils.isCommodityOrder(materialOrder) &&
                commodityUtils.resolveDeliveryDate(materialOrder) < new Date()) ||
              (materialOrder as PackagingOrderDocument | MinimumCalendarPackagingOrder).expectedDelivery <
                new Date() ? (
              <span className={"kt-badge kt-badge--inline kt-badge--pill kt-badge--warning"}>Overdue</span>
            ) : (
              <span className={"kt-badge kt-badge--inline kt-badge--pill kt-badge--light"}>Open</span>
            )}
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Quantity</label>
          <div className="col-8 align-self-center">
            <span className="text-dark font-weight-bold">
              {commodityUtils.isCommodityOrder(materialOrder) && commodityUtils.isCommodity(material)
                ? commodityUtils.resolveStockUnit(materialOrder.orderquantity, material.type)
                : !commodityUtils.isCommodityOrder(materialOrder) &&
                  (materialOrder as PackagingOrderDocument | MinimumCalendarPackagingOrder).orderQuantity + " pcs."}
            </span>
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Created</label>
          <div className="col-8 align-self-center">
            <span className="text-dark font-weight-bold">{baseUtils.formatDate(materialOrder.created)}</span>
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">
            {[I_FCA, I_CIFA, I_CIFS, I_CIPA, I_CIPS, I_DDP, I_DAP, I_DPU, I_CPT, I_COUR].includes(
              materialOrder.incoterm!
            )
              ? "ETA"
              : "Pickup Date"}
          </label>
          <div className="col-8 align-self-center">
            <span className="text-dark font-weight-bold">
              {baseUtils.formatDate(
                commodityUtils.isCommodityOrder(materialOrder)
                  ? commodityUtils.resolveDeliveryDate(materialOrder)
                  : (materialOrder as PackagingOrderDocument | MinimumCalendarPackagingOrder).expectedDelivery
              )}
            </span>
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Total Price</label>
          <div className="col-8 align-self-center">
            <span className="text-dark font-weight-bold">
              {materialOrder.totalPrice ? baseUtils.formatEuro(materialOrder.totalPrice) : "-.-- €"}
            </span>
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Destination</label>
          <div className="col-8 align-self-center">
            {destination ? (
              <ManufacturerWidget manufacturer={destination} />
            ) : (
              <span className="text-muted">Not set</span>
            )}
          </div>
        </div>
        {additionalBodyContent}
      </Modal.Body>
      <Modal.Footer>
        {alternativeFooterContent !== undefined ? (
          alternativeFooterContent
        ) : (
          <button className="btn btn-secondary" onClick={onClose}>
            Close
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default OrderOverviewModal;
