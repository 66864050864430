import _ from "lodash";
import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { CustomOrder } from "../CustomTypes";
import { CommoditiesDocument } from "../../../model/commodities.types";
import accessUtils, { ACTIONS } from "../../../utils/accessUtils";
import { PackagingsDocument } from "../../../model/packagings.types";
import packagingUtils from "../../../utils/packagingUtils";
import { calculation, calculationInfo, pricing, pricingCommodities } from "../../../model/orders.types";
import orderCalculationUtils from "../../../utils/orderCalculationUtils";
import baseUtils from "../../../utils/baseUtils";
import { DataContext } from "../../../context/dataContext";
import { T_COMMODITYREMOVED, T_PACKAGINGREMOVED } from "../../../utils/timelineUtils";
import userService from "../../../services/userService";
import dbService, { ORDERS, UpdateAction } from "../../../services/dbService";
import { T_LIQUID, T_POWDER } from "../OrderHelper";
import orderUtils, { ORDERORDERCOMMODITIES, WAITING } from "../../../utils/orderUtils";
import commodityUtils from "../../../utils/commodityUtils";

interface RemoveMaterialModalProps {
  material: CommoditiesDocument | PackagingsDocument;
  order: CustomOrder;
  type: "commodity" | "packaging";
  buttonCSSClasses?: string;
  additionalDropdownClasses?: string;
  successCallback?: () => void;
  showAsDropdown?: boolean;
}

interface RemoveMaterialModalState {
  saving: boolean;
  show: boolean;
}

class RemoveMaterialModal extends PureComponent<RemoveMaterialModalProps, RemoveMaterialModalState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: RemoveMaterialModalProps) {
    super(props);
    this.state = {
      show: false,
      saving: false
    };
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });

  handleRemove = async (newCalculation: calculation) => {
    const { order, material, type, successCallback } = this.props;
    const { commodities, packagingOrders } = this.context;
    this.setState({ saving: true });
    let timeline: any = {
      id: new BSON.ObjectId(),
      type: type === "commodity" ? T_COMMODITYREMOVED : T_PACKAGINGREMOVED,
      date: new Date(),
      person: userService.getUserId()
    };
    if (type === "commodity") timeline.commodity = material._id;
    else timeline.packaging = material._id;
    const action: UpdateAction = {
      collection: ORDERS,
      filter: { _id: order._id },
      update: { calculations: [newCalculation] },
      push: { timeline }
    };
    // Update state if required
    if ([ORDERORDERCOMMODITIES, WAITING].includes(order.state)) {
      const newState = orderUtils.checkOrderStateAfterPriceUpdate(order);
      if (newState) {
        // @ts-ignore
        action.update["state"] = newState;
      }
    }
    // Update recipe
    if (type === "commodity") action.pull = { recipe: { id: material._id } };
    // Recalculate amount per unit for powder/liquid
    if ([T_POWDER, T_LIQUID].includes(order.settings.type) && type === "commodity") {
      // @ts-ignore
      action.update["settings.perUnit"] = +newCalculation.prices.reduce((a, b) => a + +b.amount, 0);
    }
    const actions = [action];
    if (type === "commodity") {
      const commodityOrderUpdate = commodityUtils.getCommodityOrderUpdate(material._id.toString, order, commodities);
      if (commodityOrderUpdate) actions.push(commodityOrderUpdate);
    } else {
      const packagingOrderUpdate = packagingUtils.getPackagingOrderUpdate(
        material._id.toString(),
        order,
        packagingOrders
      );
      if (packagingOrderUpdate) actions.push(packagingOrderUpdate);
    }
    try {
      const result = await dbService.updatesAsTransaction(actions);
      if (result) {
        toast.success(
          `${
            "title" in material ? material.title.en : packagingUtils.getPackagingType(material.packaging_type)
          } successfully removed from Order AT-${order.identifier}`
        );
        this.context.updateDocumentInContext(ORDERS, order._id);
        this.handleClose();
        if (successCallback) successCallback();
      } else {
        toast.error("Order recipe could not be updated");
      }
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { order, material, type, buttonCSSClasses, additionalDropdownClasses, showAsDropdown } = this.props;
    const { show, saving } = this.state;
    const canRemove = accessUtils.canPerformAction(
      type === "commodity" ? ACTIONS.ORDERCOMMODITYREPLACE : ACTIONS.ORDERPACKAGINGREPLACE
    );

    const Button = (
      <>
        {showAsDropdown ? (
          <>
            <button
              type="button"
              className={
                "btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split px-2 py-1 " +
                (additionalDropdownClasses ? additionalDropdownClasses : "")
              }
              data-toggle="dropdown"
              style={{ marginLeft: 0, borderLeft: "none" }}
            />
            <div className="dropdown-menu dropdown-menu-right">
              <h6 className="dropdown-header pointer" onClick={this.handleShow}>
                Remove
              </h6>
            </div>
          </>
        ) : (
          <button
            className={
              (buttonCSSClasses ? buttonCSSClasses : "btn btn-sm btn-secondary px-2 py-1") +
              (canRemove ? "" : " disabled")
            }
            onClick={canRemove ? this.handleShow : undefined}
            disabled={!canRemove}
          >
            Remove
          </button>
        )}
      </>
    );
    if (!show) return Button;

    const calculation = order.calculations[0];
    const price: pricing | pricingCommodities | undefined =
      type === "commodity"
        ? calculation.prices.find(p => p._id.toString() === material._id.toString())
        : calculation.packagings.find(p => p._id.toString() === material._id.toString());
    if (!price) return <div />;
    const calculationInfo = calculation.info;
    const newCalculation = _.cloneDeep(calculation);
    let newCalculationInfo: calculationInfo;
    if (type === "commodity") {
      newCalculation.prices = newCalculation.prices.filter(p => p._id.toString() !== material._id.toString());
      newCalculationInfo = orderCalculationUtils.recalculateInfoOnCommodityChanges(order, newCalculation)!;
    } else {
      newCalculation.packagings = newCalculation.packagings.filter(p => p._id.toString() !== material._id.toString());
      newCalculationInfo = orderCalculationUtils.recalculateInfoOnPackagingChanges(order, newCalculation)!;
    }
    newCalculation.info = newCalculationInfo;
    return (
      <>
        {Button}
        <Modal show={show} onHide={this.handleClose} centered name={"RemoveMaterialModal"} size={"lg"}>
          <Modal.Header closeButton>
            <Modal.Title>
              Remove{" "}
              {"title" in material ? material.title.en : packagingUtils.getPackagingType(material.packaging_type)} from
              Order AT-{order.identifier}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="overflow-auto" style={{ maxHeight: "80vh" }}>
            <div className="text-dark">
              Are you sure you want to remove '
              {type === "commodity" && "title" in material
                ? material.title.en
                : packagingUtils.resolvePackagingProperties(material as PackagingsDocument)}
              ' from {type === "commodity" ? "recipe" : "packaging"} of order AT-{order.identifier}?
            </div>
            <div>
              <div className="row mt-4 text-dark">
                <div className="col-3 text-right">Turnover (Unit)</div>
                <div className="col-4">{baseUtils.formatEuro(calculationInfo.unitprice)}</div>
                <div className="col-1">
                  <i className="fa fa-arrow-right mx-2" />
                </div>
                <div className="col-4">{baseUtils.formatEuro(newCalculationInfo.unitprice)}</div>
              </div>
              <div className="row text-dark">
                <div className="col-3 text-right">Turnover (Total)</div>
                <div className="col-4">{baseUtils.formatEuro(calculationInfo.totalprice)}</div>
                <div className="col-1">
                  <i className="fa fa-arrow-right mx-2" />
                </div>
                <div className="col-4">{baseUtils.formatEuro(newCalculationInfo.totalprice)}</div>
              </div>
              <div className="row text-dark">
                <div className="col-3 text-right">Margin (%)</div>
                <div className="col-4">{calculationInfo.percentmargin.toFixed(2)}%</div>
                <div className="col-1">
                  <i className="fa fa-arrow-right mx-2" />
                </div>
                <div className="col-4">{newCalculationInfo.percentmargin.toFixed(2)}%</div>
              </div>
              <div className="row text-dark">
                <div className="col-3 text-right">Margin (Unit)</div>
                <div className="col-4">{baseUtils.formatEuro(calculationInfo.unitmargin)}</div>
                <div className="col-1">
                  <i className="fa fa-arrow-right mx-2" />
                </div>
                <div className="col-4">{baseUtils.formatEuro(newCalculationInfo.unitmargin)}</div>
              </div>
              <div className="row text-dark">
                <div className="col-3 text-right">Cost (Unit)</div>
                <div className="col-4">{baseUtils.formatEuro(calculationInfo.unitpricenaked)}</div>
                <div className="col-1">
                  <i className="fa fa-arrow-right mx-2" />
                </div>
                <div className="col-4">{baseUtils.formatEuro(newCalculationInfo.unitpricenaked)}</div>
              </div>
              <div className="row text-dark">
                <div className="col-3 text-right">Margin (Total)</div>
                <div className="col-4">{baseUtils.formatEuro(calculationInfo.totalmargin)}</div>
                <div className="col-1">
                  <i className="fa fa-arrow-right mx-2" />
                </div>
                <div className="col-4">{baseUtils.formatEuro(newCalculationInfo.totalmargin)}</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <button
              className={"btn btn-secondary" + (saving ? " disabled" : "")}
              disabled={saving}
              onClick={() => this.handleRemove(newCalculation)}
            >
              {saving && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Remove
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default RemoveMaterialModal;
