import _, { isArray } from "lodash";
import React, { PureComponent } from "react";
import Select from "react-select";
import packagingUtils, { PACKAGING_TYPES } from "../../utils/packagingUtils";
import { CalculationType, CustomPackagingsDocument, Preferences, SelectedPackagingsDocument } from "./CustomTypes";
import Pagination, { paginate } from "../common/Pagination";
import PackagingItem from "./PackagingItem";
import { AdvancedPackagingFilter } from "./advancedPackagingFilter";
import { ALLTORSO, PACKAGINGCOMBINATIONS, ProductTypes } from "./configuratorConstants";
import { SearchBar } from "../listings/common/Filters";
import baseUtils from "../../utils/baseUtils";

interface PackagingSelectionProps {
  activeType: string;
  fullscreen: boolean;
  preferences: Preferences;
  recipeVolume: { value: number; noDefault: boolean };
  calculations: Array<CalculationType>;
  packaging: Array<CustomPackagingsDocument>;
  selectedPackaging: Array<SelectedPackagingsDocument>;
  onPackagingSelect: (packaging: CustomPackagingsDocument) => void;
  onPackagingAmount: (packaging: SelectedPackagingsDocument, add: boolean) => void;
}

interface PackagingSelectionState {
  currentPage: number;
  searchQuery: string;
  filter: any;
  hideUnnecessary: boolean;
  showSelected: boolean;
  filteredPackaging: Array<CustomPackagingsDocument>;
}

const PAGESIZE = 12;

class PackagingSelection extends PureComponent<PackagingSelectionProps, PackagingSelectionState> {
  _advancedFilter: AdvancedPackagingFilter;
  constructor(props: PackagingSelectionProps) {
    super(props);
    this._advancedFilter = new AdvancedPackagingFilter(props.packaging, props.selectedPackaging);
    const torsoSelection = this.getTorsoSelection();
    const filteredPackaging = this.getFilteredPackaging(torsoSelection, props);
    this.state = {
      currentPage: 1,
      searchQuery: "",
      filter: this.getDefaultFilter(),
      showSelected: true,
      hideUnnecessary: true,
      // filter out selected packaging to not show it twice and do not show disabled
      filteredPackaging: filteredPackaging.filter(fp => !fp.disabled)
    };
  }

  /**
   * Get the filtered packaging by usage of the advanced filter class. The filtered packaging depends on the current
   * and future selection
   * @param torsoSelection the valid torso packaging for the current type
   * @param props properties object to be able to use function in constructor
   * @returns Array with filtered packaging matching for the current and future selection
   */
  getFilteredPackaging = (torsoSelection: Array<string>, props: PackagingSelectionProps) => {
    const { packaging, preferences, selectedPackaging, activeType, recipeVolume } = props;
    const selectedTorso = selectedPackaging.find(sPack => ALLTORSO.includes(sPack.packaging_type));
    if (selectedPackaging.length === 0 || !selectedTorso) {
      if (activeType === ProductTypes.CUSTOM) return packaging;
      else {
        return this._advancedFilter.getFilteredTorsoPackaging(activeType, torsoSelection, preferences, recipeVolume);
      }
    } else {
      let currentCombination = _.get(PACKAGINGCOMBINATIONS, selectedTorso.packaging_type);
      // filter out packaging types that are already selected
      currentCombination = currentCombination.filter(
        (type: string | Array<string>) =>
          !selectedPackaging.some(sPack => {
            if (isArray(type)) {
              return type.includes(sPack.packaging_type);
            } else {
              return sPack.packaging_type === type;
            }
          })
      );
      return this._advancedFilter.getFilteredPackaging(selectedPackaging, currentCombination);
    }
  };

  getTorsoSelection = () => {
    const { activeType, preferences } = this.props;
    return packagingUtils.getTorsoTypeForProduct(activeType, preferences).reverse();
  };

  getDefaultFilter = () => {
    return { color: "", type: "" };
  };

  handleFilterChange = (type: string, value: string) => {
    const filter = { ...this.state.filter };
    _.set(filter, type, value);
    this.setState({ filter, currentPage: 1 });
  };

  handleHideUnnecessary = () => {
    const { hideUnnecessary } = this.state;
    this.setState({
      hideUnnecessary: !hideUnnecessary,
      filter: { type: "", color: "" },
      currentPage: 1,
      searchQuery: ""
    });
  };

  handleShowSelected = () => {
    const { showSelected } = this.state;
    this.setState({ showSelected: !showSelected });
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ searchQuery: e.target.value, currentPage: 1 });

  handlePageChange = (page: number) => {
    this.setState({ currentPage: page });
  };

  /**
   * Handle flags like hideUnnecessary, showSelected as well as filter and search query and get the packaging that should
   * be displayed
   */
  getDisplayedPackaging = () => {
    const { selectedPackaging, packaging: allPackaging } = this.props;
    const { filter, searchQuery, showSelected, hideUnnecessary, filteredPackaging } = this.state;
    let packaging = filteredPackaging.slice();
    if (!hideUnnecessary) {
      // extend packaging with unnecessary packaging i.e. packaging that is not in filtered but in props.packaging
      packaging = packaging.concat(
        allPackaging.filter(aPack => !filteredPackaging.some(fPack => aPack._id.toString() === fPack._id.toString()))
      );
    }
    const filterSet = Object.values(filter).some(entry => entry !== "");
    const search = searchQuery.trim();
    if (!filterSet && !search) {
      if (showSelected) return selectedPackaging.concat(packaging);
      return packaging;
    }
    let finalPackaging: Array<CustomPackagingsDocument | SelectedPackagingsDocument> = packaging.slice();
    // filter packaging if filter is set
    if (filterSet) {
      const filters = Object.entries(filter);
      for (let setFilter of filters) {
        if ((setFilter[1] as string).trim() === "") continue;
        finalPackaging = packagingUtils.filterPackaging(finalPackaging, setFilter[0], setFilter[1] as string);
      }
    }
    // search packaging if search is set
    if (search) {
      finalPackaging = baseUtils.doFuseSearch(finalPackaging, search, [""], {
        getFn: (obj: any, path) => {
          return packagingUtils.concatPackagingInfo(obj);
        }
      });
    }
    // prepend selected packaging if flag is set
    if (showSelected) finalPackaging = selectedPackaging.concat(finalPackaging);
    return finalPackaging;
  };

  handlePackagingSelect = (packaging: CustomPackagingsDocument) => {
    // Extend packaging select with state reset for filter, page and search
    this.setState({ filter: { type: "", color: "" }, currentPage: 1, searchQuery: "" });
    this.props.onPackagingSelect(packaging);
  };

  componentDidUpdate(
    prevProps: Readonly<PackagingSelectionProps>,
    prevState: Readonly<PackagingSelectionState>,
    snapshot?: any
  ) {
    if (prevProps.activeType !== this.props.activeType) {
      this.setState({
        currentPage: 1,
        searchQuery: "",
        filter: this.getDefaultFilter(),
        showSelected: true,
        hideUnnecessary: true
      });
    }
    if (
      !_.isEqual(prevProps.packaging, this.props.packaging) ||
      !_.isEqual(prevProps.selectedPackaging, this.props.selectedPackaging)
    ) {
      this._advancedFilter.setPackaging(this.props.packaging, this.props.selectedPackaging);
      const torsoSelection = this.getTorsoSelection();
      this.setState({
        filteredPackaging: this.getFilteredPackaging(torsoSelection, this.props).filter(fp => !fp.disabled)
      });
    }
  }

  render() {
    const { onPackagingAmount, fullscreen, preferences, calculations } = this.props;
    const { filter, hideUnnecessary, showSelected, searchQuery, currentPage } = this.state;
    const displayedPackaging = this.getDisplayedPackaging();
    return (
      <div>
        <PackagingSelectionToolbar
          searchQuery={searchQuery}
          filter={filter}
          fullscreen={fullscreen}
          colors={packagingUtils.getColors(displayedPackaging)}
          hideUnnecessary={hideUnnecessary}
          showSelected={showSelected}
          onHideUnnecessary={this.handleHideUnnecessary}
          onShowSelected={this.handleShowSelected}
          onFilterChange={this.handleFilterChange}
          onSearch={this.handleSearch}
        />
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
            <div className="row mt-3">
              {displayedPackaging.length === 0 ? (
                <h3 className="my-10 mx-auto text-muted">No suitable packaging found</h3>
              ) : (
                paginate(displayedPackaging, currentPage, PAGESIZE).map(entry => {
                  return (
                    <React.Fragment key={("amount" in entry ? "s_" : "") + entry._id.toString()}>
                      <PackagingItem
                        packaging={entry}
                        preferences={preferences}
                        calculations={calculations}
                        onPackagingSelect={this.handlePackagingSelect}
                        onPackagingAmount={onPackagingAmount}
                      />
                    </React.Fragment>
                  );
                })
              )}
            </div>
            <div className="kt-datatable__pager kt-datatable--paging-loaded mx-auto justify-content-center">
              <Pagination
                itemsCount={displayedPackaging.length}
                pageSize={PAGESIZE}
                onPageChange={this.handlePageChange}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface PackagingSelectionToolbarProps {
  searchQuery: string;
  filter: any;
  fullscreen: boolean;
  colors: Array<string>;
  hideUnnecessary: boolean;
  showSelected: boolean;
  onHideUnnecessary: any;
  onShowSelected: any;
  onFilterChange: any;
  onSearch: any;
}

const PackagingSelectionToolbar: React.FunctionComponent<PackagingSelectionToolbarProps> = ({
  searchQuery,
  filter,
  fullscreen,
  colors,
  hideUnnecessary,
  showSelected,
  onHideUnnecessary,
  onShowSelected,
  onFilterChange,
  onSearch
}) => {
  const packagingType = PACKAGING_TYPES.find(p => p.value.toString() === filter.type)!;
  return (
    <div
      className={!fullscreen ? "kt-portlet kt-portlet--tabs mt-1" : "kt-portlet kt-portlet--tabs mt-1 d-none"}
      style={{ boxShadow: "none" }}
    >
      <div className="kt-portlet__body pb-1 pl-0 pr-0">
        <div className="from-group row mb-0">
          <SearchBar onSearch={onSearch} search={searchQuery} additionalSizeClasses="col-md-3" />
          <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
            <Select
              className="select-default"
              isClearable={true}
              onChange={(value: any) => onFilterChange("type", value ? value.value : "")}
              value={
                packagingType
                  ? { value: packagingType.value, label: packagingType.label }
                  : { value: "", label: "Unknown" }
              }
              options={PACKAGING_TYPES.map(type => {
                return {
                  value: type.value,
                  label: type.label
                };
              })}
            />
            <span className="form-text text-muted">Packaging Type</span>
          </div>
          <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
            <Select
              className="select-default"
              isClearable={true}
              onChange={(value: any) => onFilterChange("color", value ? value.value : "")}
              value={
                filter.color
                  ? {
                      value: filter.color,
                      label: filter.color === "color" ? "Special colors" : _.upperFirst(filter.color)
                    }
                  : { value: "", label: "All Colors" }
              }
              options={colors.map(color => {
                return {
                  value: color,
                  label: color === "color" ? "Special colors" : _.upperFirst(color)
                };
              })}
            />
            <span className="form-text text-muted">Colors</span>
          </div>
        </div>
        <div className="from-group row mb-0">
          <div className="col-12 mt-2" style={{ textAlign: "right", float: "right" }}>
            <label className="kt-checkbox kt-font-dark m-0 p-0 pl-4 pr-4">
              <input type="checkbox" onChange={onHideUnnecessary} checked={hideUnnecessary} />
              <span />
              <div className="pl-1">Hide unnecessary</div>
            </label>
            <label className="kt-checkbox kt-font-dark m-0 p-0 pl-4">
              <input type="checkbox" onChange={onShowSelected} checked={showSelected} />
              <span />
              <div className="pl-1">Show selected</div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagingSelection;
