import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { RouteComponentProps } from "react-router-dom";
import dbService, { PASSWORDCHANGETOKENS } from "../../services/dbService";
import userService from "../../services/userService";
import { ROLES } from "../../utils/userdataUtils";
import HistoryBackButton from "../listings/common/HistoryBackButton";
import { PaginationState } from "../common/CustomTypes";
import { paginate } from "../common/Pagination";
import BaseListing from "../listings/BaseListing";
import { UserdataDocument } from "../../model/userdata.types";
import { DataContext } from "../../context/dataContext";
import baseUtils from "../../utils/baseUtils";
import PersonWidget from "../common/PersonWidget";
import toastUtils from "../../utils/toastUtils";

interface OpenInvitationsProps extends RouteComponentProps<{}, {}, {}> {}

interface OpenInvitationsState extends PaginationState {
  openInvitations: Array<Invitation>;
  loading: boolean;
}

interface Invitation {
  _id: BSON.ObjectId;
  userName: string;
  date: Date;
  userId: BSON.ObjectId;
  user: UserdataDocument;
}

class OpenInvitations extends PureComponent<OpenInvitationsProps, OpenInvitationsState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  constructor(props: OpenInvitationsProps) {
    super(props);
    this.state = {
      openInvitations: [],
      loading: true,
      currentPage: 1,
      pageSize: 20
    };
  }

  async componentDidMount() {
    await this.initializeData();
  }

  initializeData = async () => {
    // Get open invitations => userId is set
    const invitations = await dbService.getFilteredCollection(PASSWORDCHANGETOKENS, { userId: { $exists: true } });
    const preparedInvitations = invitations
      .map(inv => {
        const userdata = baseUtils.getDocFromCollection(this.context.userdata, inv.userId);
        if (!userdata) return;
        return { ...inv, user: userdata };
      })
      .filter(inv => !!inv) as Array<Invitation>;
    this.setState({ openInvitations: preparedInvitations });
  };

  handleExtendLink = async (invitation: Invitation) => {
    const exp = new Date();
    exp.setDate(exp.getDate() + 1);
    const result = await dbService.updateDocument(PASSWORDCHANGETOKENS, invitation._id, { date: exp });
    await toastUtils.databaseOperationToast(
      !!result && !!result.modifiedCount,
      `Invitation link for ${invitation.user.prename} ${invitation.user.surname} extended successfully`,
      `Invitation link for ${invitation.user.prename} ${invitation.user.surname} could not be extended`,
      () => this.initializeData()
    );
  };

  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });

  render() {
    const { history } = this.props;
    const { pageSize, currentPage, openInvitations } = this.state;
    const canView = userService.hasRole(ROLES.SALES, false);
    if (!canView)
      return (
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand flaticon2-mail-1" />
              </span>
              <h3 className="kt-portlet__head-title mb-2">Open Invitations</h3>
            </div>
            <div className="kt-portlet__head-toolbar">
              <HistoryBackButton history={history} />
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row justify-content-center">
              <div className="col-auto">
                <div className="alert alert-secondary my-2" role="alert">
                  <div className="alert-icon">
                    <i className="flaticon-warning" />
                  </div>
                  <div className="alert-text">You are not authorized to see open invitations</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    const headerDefinition = [
      { title: "Username", size: 25 },
      { title: "Linked User", size: 25 },
      { title: "Expiry", size: 25 },
      { title: "Action", size: 25 }
    ];
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand flaticon2-mail-1" />
            </span>
            <h3 className="kt-portlet__head-title mb-2">Open Invitations</h3>
            <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1 mb-2" onClick={this.initializeData}>
              Refresh
            </button>
          </div>
          <div className="kt-portlet__head-toolbar">
            <HistoryBackButton history={history} />
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <BaseListing
            headerDefinition={headerDefinition}
            documents={openInvitations}
            bodyContent={
              <>
                {paginate(openInvitations, currentPage, pageSize).map(inv => (
                  <tr className="kt-datatable__row d-table-row" key={inv._id.toString()}>
                    <td className="kt-datatable__cell d-table-cell">
                      <span className="kt-font-bold">{inv.userName}</span>
                    </td>
                    <td className="kt-datatable__cell d-table-cell">
                      <PersonWidget person={inv.user} />
                    </td>
                    <td className="kt-datatable__cell d-table-cell">
                      <span className={inv.date < new Date() ? "text-danger" : ""}>
                        {baseUtils.formatDate(inv.date)}{" "}
                        {inv.date.toLocaleTimeString("de-DE", { hour: "2-digit", minute: "2-digit" })}
                      </span>
                    </td>
                    <td className="kt-datatable__cell d-table-cell">
                      <button className="btn btn-secondary btn-sm" onClick={() => this.handleExtendLink(inv)}>
                        Extend Link
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            }
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={this.handlePageChange}
            onPageSizeChange={this.handlePageSizeChange}
          />
        </div>
      </div>
    );
  }
}

export default OpenInvitations;
