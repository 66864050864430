import React from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CommoditiesDocument } from "../../../model/commodities.types";
import { CustomCommoditiesDocument, SelectedCommoditiesDocument } from "../../configurator/CustomTypes";

interface OpenCommodityIconProps {
  commodity: CommoditiesDocument | CustomCommoditiesDocument | SelectedCommoditiesDocument;
}

const OpenCommodityIcon: React.FunctionComponent<OpenCommodityIconProps> = ({ commodity }) => {
  return (
    <Link
      onClick={e => e.stopPropagation()}
      to={`/commodity/${commodity._id.toString()}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <OverlayTrigger overlay={<Tooltip id={"openCommodity"}>Open Commodity</Tooltip>}>
        <i className="fas fa-external-link-alt text-muted text-hover-gray-300 hover ml-2" />
      </OverlayTrigger>
    </Link>
  );
};

export default OpenCommodityIcon;
