import _ from "lodash";
import { BSON } from "realm-web";
import dbService, { PACKAGINGS } from "../dbService";
import { PackagingTimeline } from "../../model/packagings.types";
import { TIMELINE_SLICE_AMOUNT } from "../../utils/materialUtils";

/**
 * Update the referenced packaging.
 * @param _id ID of the packaging
 * @param update Partial update that should be performed
 * @param timeline Optional, if passed element is pushed to timeline and the timelines size is reduced
 * @param unset Optional, object to unset values
 * @returns Result of the query
 */
async function updatePackaging(_id: BSON.ObjectId, update: object, timeline?: PackagingTimeline, unset?: object) {
  const updateObj: any = {};
  if (!_.isEmpty(update)) updateObj["$set"] = update;
  if (unset && !_.isEmpty(unset)) updateObj["$unset"] = unset;
  if (timeline) updateObj["$push"] = { timeline: { $each: [timeline], $slice: TIMELINE_SLICE_AMOUNT } };
  return dbService.getDb()?.collection(PACKAGINGS).updateOne({ _id }, updateObj);
}

export default { updatePackaging };
