import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { paginate } from "../common/Pagination";
import { DataContext } from "../../context/dataContext";
import { OrdersDocument } from "../../model/orders.types";
import dbService, { ORDERS } from "../../services/dbService";
import HistoryBackButton from "./common/HistoryBackButton";
import { ManufacturerFilter, MarginFilter, OwnerFilter, PriorityFilter, SearchBar } from "./common/Filters";
import manufacturerUtils from "../../utils/manufacturerUtils";
import SplashScreen from "../common/SplashScreen";
import orderUtils, { ARCHIVE } from "../../utils/orderUtils";
import { OrderDeclinedRow, OrderInvoiceRow } from "./OrdersRows";
import { headerOrderDeclined, headerOrderInvoice, headerOrderInvoiceProd } from "./OrdersHeaders";
import BaseListing from "./BaseListing";
import { getComponentState } from "../../utils/baseUtils";
import accessUtils from "../../utils/accessUtils";

interface ClosedOrdersTableProps extends RouteComponentProps<{}, {}, {}> {
  type: "declined" | "archive";
}

interface ClosedOrdersTableState {
  orders: Array<OrdersDocument>;
  currentPage: number;
  pageSize: number;
  search: string;
  rating: string;
  state: string;
  priority: "" | { value: string; label: string };
  margin: "" | { value: string; label: string };
  owner: "" | { value: string; label: string };
  manufacturer: "" | { value: string; label: string };
  manufacturerLocked: boolean;
  loading: boolean;
}

const CONSTRUCTORNAME = "ClosedOrdersTable";

class ClosedOrdersTable extends PureComponent<ClosedOrdersTableProps, ClosedOrdersTableState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: ClosedOrdersTableProps, context: React.ContextType<typeof DataContext>) {
    super(props, context);
    this.state = this.getDefaultState(context);
  }

  async componentDidMount() {
    let state = getComponentState(this.context, CONSTRUCTORNAME + this.props.type);
    state = state ? state : {};
    if (this.props.type === "declined") {
      this.setState({ loading: true });
      const orders = await dbService.getFilteredCollection(ORDERS, { state: this.props.type });
      state.orders = orders;
      this.context.addDocuments(ORDERS, orders);
    } else {
      state.orders = this.context.orders.filter(o => o.state === ARCHIVE);
    }
    state.loading = false;
    this.setState({ ...state });
  }

  async componentDidUpdate(
    prevProps: Readonly<ClosedOrdersTableProps>,
    prevState: Readonly<ClosedOrdersTableState>,
    snapshot?: any
  ) {
    if (prevProps.type !== this.props.type) {
      if (this.props.type === "declined") {
        this.setState({ orders: [], loading: true });
        this.setState({
          orders: await dbService.getFilteredCollection(ORDERS, { state: this.props.type }),
          loading: false
        });
      } else {
        this.setState({ orders: this.context.orders.filter(o => o.state === ARCHIVE) });
      }
    }
  }

  componentWillUnmount() {
    this.context.saveComponentState(CONSTRUCTORNAME + this.props.type, this.state);
  }

  handleReset = () => this.setState(this.getDefaultState(this.context, true));

  handleSelectChange = (name: string, entry: "" | { value: string; label: string }) => {
    // @ts-ignore
    this.setState({ [name]: entry ? { value: entry.value, label: entry.label } : "", currentPage: 1 });
  };
  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ currentPage: 1, pageSize });
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value, currentPage: 1 });

  /**
   * Get the default state
   * @param context data context
   * @param reset flag if it is a reset or not. on reset orders are kept
   * @returns {ClosedOrdersTableState} default state for the component
   */
  getDefaultState = (context: React.ContextType<typeof DataContext>, reset?: boolean) => {
    const manufacturer = manufacturerUtils.checkCurrentUserManufacturerObject(context.manufacturers);
    const orders = reset ? this.state.orders : [];
    return {
      orders: orders,
      currentPage: 1,
      pageSize: 10,
      search: "",
      rating: "",
      state: "",
      priority: "",
      margin: "",
      owner: "",
      manufacturer: manufacturer,
      manufacturerLocked: manufacturer !== "",
      loading: false
    } as ClosedOrdersTableState;
  };

  /**
   * Get filtered orders
   * @returns {Array<OrdersDocument>} list of filtered and sorted orders
   */
  getFilteredOrders = () => {
    const { companies, userdata } = this.context;
    const { manufacturer, owner, priority, margin, search } = this.state;
    let filteredOrders = this.state.orders.slice();
    const query = search.trim();
    filteredOrders = orderUtils.filterOrders(
      filteredOrders,
      undefined,
      owner ? owner.value : "",
      priority ? priority.value : "",
      margin ? margin.value : "",
      manufacturer ? manufacturer.value : "",
      undefined
    );
    if (query) {
      filteredOrders = orderUtils.filterBySearch(filteredOrders, companies, userdata, query) as Array<OrdersDocument>;
    }
    return filteredOrders.sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime());
  };

  /**
   * Get row component for current view
   * @param order order document
   * @returns {JSX.Element} Row component for current view
   */
  getOrderRow = (order: OrdersDocument) => {
    const { type } = this.props;
    switch (type) {
      case "archive":
        return <OrderInvoiceRow order={order} context={this.context} {...this.props} />;
      case "declined":
        return <OrderDeclinedRow order={order} context={this.context} {...this.props} />;
    }
  };

  render() {
    const { history, type } = this.props;
    const { manufacturers, userdata } = this.context;
    const {
      currentPage,
      manufacturer,
      owner,
      pageSize,
      priority,
      margin,
      manufacturerLocked,
      search,
      loading
    } = this.state;
    const canSeeFinanceData = accessUtils.canSeeFinanceData();
    // decline date, declined by for decline
    const filteredOrders = this.getFilteredOrders();
    const selectableUsers = userdata.filter(user => user.company_id === "internal");
    const headerDefinition =
      type === "archive" ? (canSeeFinanceData ? headerOrderInvoice : headerOrderInvoiceProd) : headerOrderDeclined;
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand fa fa-file" />
            </span>
            <h3 className="kt-portlet__head-title">{type === "archive" ? "Archived" : "Declined"} Orders</h3>
            <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
              Reset
            </button>
          </div>
          <HistoryBackButton history={history} />
        </div>
        <div className="kt-portlet__body">
          <div className="kt-form kt-margin-b-10">
            <div className="row align-items-center">
              <SearchBar onSearch={this.handleSearch} search={search} />
              <OwnerFilter owner={owner} selectableUsers={selectableUsers} onFilterChange={this.handleSelectChange} />
              <PriorityFilter priority={priority} onFilterSelect={this.handleSelectChange} />
              <MarginFilter margin={margin} onFilterSelect={this.handleSelectChange} isDisabled={!canSeeFinanceData} />
              <ManufacturerFilter
                manufacturer={manufacturer}
                manufacturers={manufacturers}
                manufacturerLocked={manufacturerLocked}
                onFilterSelect={this.handleSelectChange}
              />
            </div>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          {loading ? (
            <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
          ) : (
            <BaseListing
              headerDefinition={headerDefinition}
              documents={filteredOrders}
              bodyContent={
                <>
                  {paginate(filteredOrders, currentPage, pageSize).map(o => (
                    <React.Fragment key={o._id.toString()}>{this.getOrderRow(o)}</React.Fragment>
                  ))}
                </>
              }
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ClosedOrdersTable;
