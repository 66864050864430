import _ from "lodash";
import React, { PureComponent } from "react";
import Select from "react-select";
import countryList from "i18n-iso-countries";

interface CountryPickerProps {
  handleChange: (name: string, value: { value: string; label: string } | "") => void;
  value: "" | { value: string; label: string };
  disabled?: boolean;
  allowClear?: boolean;
}

interface CountryPickerState {}

class CountryPicker extends PureComponent<CountryPickerProps, CountryPickerState> {
  render() {
    const { disabled, handleChange, value, allowClear } = this.props;
    return (
      <Select
        className="select-default"
        options={Object.values(countryList.getNames("en")).map((item: string) => {
          return {
            value: countryList.getAlpha2Code(item, "en"),
            label: _.upperFirst(item)
          };
        })}
        value={value ? value : { value: "", label: "Select Country" }}
        onChange={disabled ? undefined : (value: any) => handleChange("country", value || "")}
        disabled={disabled}
        isClearable={allowClear}
      />
    );
  }
}

export default CountryPicker;
