import _ from "lodash";
import React, { PureComponent } from "react";
import { CalculationType } from "../CustomTypes";
import baseUtils from "../../../utils/baseUtils";
import { ALLTYPES } from "./calculationHelper";
const UNIT_MAP: { [key: string]: string } = {
  encapsulation: "k",
  tableting: "k",
  bottling: "",
  blending: "kg",
  blistering: ""
};

interface GeneralCalculationDetailsProps {
  calculation: CalculationType;
}
interface GeneralCalculationDetailsState {}

class GeneralCalculationDetails extends PureComponent<GeneralCalculationDetailsProps, GeneralCalculationDetailsState> {
  render() {
    const { calculation } = this.props;
    const generalPrices = calculation.priceDetails?.generalPrices;
    const customPrices = calculation.priceDetails?.customPrices;
    if (!generalPrices) return <div />;
    return (
      <table className="kt-datatable__table">
        <thead className="kt-datatable__head text-align-left">
          <tr className="kt-datatable__row" style={{ backgroundColor: "#fcfcfc" }}>
            <th className=" kt-datatable__cell">
              <span>
                <b>{calculation.priceDetails?.customPrices ? "Custom" : "General"}</b>
              </span>
            </th>
          </tr>
          <tr className="kt-datatable__row">
            <th className=" kt-datatable__cell">
              <span style={{ width: 150 }}>Title</span>
            </th>
            <th className=" kt-datatable__cell">
              <span style={{ width: 70 }} />
            </th>
            <th className=" kt-datatable__cell">
              <span style={{ width: 60 }}>Amount</span>
            </th>
            <th className=" kt-datatable__cell">
              <span style={{ width: 75 }} />
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 150 }} />
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>Total</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }} />
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>€ / Item</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>€ / Unit</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>Total €</span>
            </th>
          </tr>
        </thead>
        <tbody className="kt-datatable__body ps ps--active-y">
          {customPrices &&
            ALLTYPES.map(cT => {
              if (generalPrices[cT] && customPrices[cT]) {
                return (
                  <CustomCalculationDetailsRow
                    key={cT}
                    name={cT}
                    price={customPrices[cT]?.price}
                    unitPrice={customPrices[cT]?.unitPrice}
                    amountPerUnit={generalPrices[cT]?.amountPerUnit}
                    totalAmount={generalPrices[cT]?.totalAmount}
                  />
                );
              }
            })}
          {customPrices && customPrices?.optionalCosts !== undefined && (
            <tr className="kt-datatable__row">
              <td className="kt-datatable__cell" colSpan={8}>
                <span style={{ width: 860 }}>
                  <b>Additional Costs</b>
                </span>
              </td>
              <td className="kt-datatable__cell">
                <span style={{ width: 70 }}>
                  <b>{baseUtils.formatEuro(customPrices.optionalCosts / +calculation.units)}</b>
                </span>
              </td>
              <td className="kt-datatable__cell">
                <span style={{ width: 70 }}>{baseUtils.formatEuro(customPrices.optionalCosts)}</span>
              </td>
            </tr>
          )}
          {customPrices && customPrices?.marginBuffer !== undefined && (
            <tr className="kt-datatable__row">
              <td className="kt-datatable__cell" colSpan={8}>
                <span style={{ width: 860 }}>
                  <b>Margin Buffer</b>
                </span>
              </td>
              <td className="kt-datatable__cell">
                <span style={{ width: 70 }}>
                  <b>{baseUtils.formatEuro(customPrices.marginBuffer)}</b>
                </span>
              </td>
              <td className="kt-datatable__cell">
                <span style={{ width: 70 }}>
                  {baseUtils.formatEuro(customPrices.marginBuffer * +calculation.units)}
                </span>
              </td>
            </tr>
          )}
          {!customPrices &&
            Object.entries(generalPrices).map((price, key) => (
              <GeneralCalculationDetailsRow key={price[0] + key} name={price[0]} price={price[1]} />
            ))}
        </tbody>
      </table>
    );
  }
}

interface GeneralCalculationDetailsRowProps {
  name: string;
  price: { price: number; unitPrice: number; amountPerUnit: number; totalAmount: number };
}

const GeneralCalculationDetailsRow: React.FunctionComponent<GeneralCalculationDetailsRowProps> = React.memo(
  ({ name, price }) => {
    return (
      <tr className="kt-datatable__row">
        <td className="kt-datatable__cell">
          <span style={{ width: 150 }}>
            <b>{_.upperFirst(name)}</b>
          </span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }} />
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 60 }}>{price.amountPerUnit}</span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 75 }} />
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 150 }} />
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }}>{price.totalAmount + UNIT_MAP[name]}</span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }} />
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }}>{baseUtils.formatEuro(price.price)}</span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }}>
            <b>{baseUtils.formatEuro(price.unitPrice)}</b>
          </span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }}>{baseUtils.formatEuro(price.price * price.totalAmount)}</span>
        </td>
      </tr>
    );
  }
);

interface CustomCalculationDetailsRowProps {
  name: string;
  price?: number;
  unitPrice?: number;
  amountPerUnit?: number;
  totalAmount?: number;
}

const CustomCalculationDetailsRow: React.FunctionComponent<CustomCalculationDetailsRowProps> = React.memo(
  ({ name, price, unitPrice, amountPerUnit, totalAmount }) => {
    if (price && unitPrice && amountPerUnit && totalAmount) {
      return (
        <tr className="kt-datatable__row">
          <td className="kt-datatable__cell">
            <span style={{ width: 150 }}>
              <b>{_.upperFirst(name)}</b>
            </span>
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 70 }} />
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 60 }}>{amountPerUnit}</span>
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 75 }} />
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 150 }} />
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 70 }}>{totalAmount + UNIT_MAP[name]}</span>
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 70 }} />
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 70 }}>{baseUtils.formatEuro(price)}</span>
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 70 }}>
              <b>{baseUtils.formatEuro(unitPrice)}</b>
            </span>
          </td>
          <td className="kt-datatable__cell">
            <span style={{ width: 70 }}>{baseUtils.formatEuro(price * totalAmount)}</span>
          </td>
        </tr>
      );
    } else {
      return <tr />;
    }
  }
);

export default GeneralCalculationDetails;
