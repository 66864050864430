import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { CustomOrder } from "../CustomTypes";
import SplashScreen from "../../common/SplashScreen";
import manufacturingSheetGeneration from "../../../utils/pdf/manufacturingSheetGeneration";
import { DataContext } from "../../../context/dataContext";
import userService from "../../../services/userService";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import orderUtils from "../../../utils/orderUtils";
import dateUtils from "../../../utils/dateUtils";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import config from "../../../config/config.json";
import { T_MANUFACTURINGPDF } from "../../../utils/timelineUtils";

interface CreateManufacturingSheetModalProps {
  order: CustomOrder;
  disabled?: boolean;
  context: React.ContextType<typeof DataContext>;
}

interface CreateManufacturingSheetModalState {
  show: boolean;
  uploading: boolean;
}

class CreateManufacturingSheetModal extends PureComponent<
  CreateManufacturingSheetModalProps,
  CreateManufacturingSheetModalState
> {
  constructor(props: CreateManufacturingSheetModalProps) {
    super(props);
    this.state = {
      show: false,
      uploading: false
    };
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });

  handleUpload = async () => {
    const { order } = this.props;
    this.setState({ uploading: true });
    const pdfResult = await this.createManufacturingPDF();
    if (!pdfResult.result || !pdfResult.path) {
      toast.error("Manufacturing sheet creation failed: " + pdfResult.message);
      this.setState({ uploading: false });
      return;
    }
    window.open(config.mediahubBase + pdfResult.path, "_blank");
    const timelineEntry = {
      id: new BSON.ObjectId(),
      type: T_MANUFACTURINGPDF,
      date: new Date(),
      path: pdfResult.path,
      person: new BSON.ObjectId(userService.getUserData()._id),
      identifier: order.identifier
    };
    try {
      const result = await dbOrderService.pushToTimeline(order._id, timelineEntry);
      if (result && result.modifiedCount) {
        toast.success("Manufacturing sheet created successfully");
        this.setState({ uploading: false, show: false });
      } else toast.error("Adding of timeline entry failed. " + pdfResult.path);
    } catch (e) {
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ uploading: false });
    }
  };

  createManufacturingPDF = async () => {
    const { order, context } = this.props;
    const data = JSON.stringify({
      html: manufacturingSheetGeneration.createManufacturingSheet(order, context),
      fileName:
        "Manufacturing-Sheet_AT-" +
        order.identifier +
        "_V" +
        orderUtils.getPDFVersion(order, T_MANUFACTURINGPDF) +
        "_" +
        dateUtils.timeStampDate() +
        ".pdf"
    });
    let path;
    try {
      path = await pdfUtils.uploadAndReturnPath(data);
    } catch (e) {
      return { result: false, message: e.message };
    }
    return { result: true, path: path };
  };

  render() {
    const { disabled } = this.props;
    const { show, uploading } = this.state;
    return (
      <>
        <button
          className={"btn btn-label-brand btn-sm btn-bold mr-3 my-1 " + (disabled ? "btn-disabled" : "")}
          onClick={this.handleShow}
          disabled={disabled}
        >
          <i className="fas fa-cog" />
          Manufacturing Sheet
        </button>
        <Modal show={show} onHide={this.handleClose} centered name={"createManufacturingSheetModal"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand fas fa-cog mr-2" />
              Create Manufacturing Sheet
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {uploading ? (
              <>
                <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
                <h5 className="text-center">Generating Manufacturing Sheet. Please Wait...</h5>
              </>
            ) : (
              <h5 className="text-center">Are you sure you want to create a manufacturing sheet?</h5>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <button
              className={!uploading ? "btn btn-success" : "btn btn-success disabled"}
              disabled={uploading}
              onClick={this.handleUpload}
            >
              {uploading && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Create Manufacturing Sheet
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateManufacturingSheetModal;
