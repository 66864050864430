import React, { PureComponent } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Pagination from "../common/Pagination";
import { NewsDocument } from "../../model/news.types";
import NewsObject from "./NewsObject";
import baseUtils, { getComponentState } from "../../utils/baseUtils";
import { DataContext } from "../../context/dataContext";
import { SearchBar } from "../listings/common/Filters";

interface NewsProps extends RouteComponentProps<{}, {}, {}> {
  news: Array<NewsDocument>;
}

interface NewsState {
  currentPage: number;
  pageSize: number;
  query: string;
}

const CONSTRUCTORNAME = "News";

class News extends PureComponent<NewsProps, NewsState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  constructor(props: NewsProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const state = getComponentState(this.context, CONSTRUCTORNAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }

  handleReset = () => this.setState(this.getDefaultState());

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ query: e.target.value.toLowerCase(), currentPage: 1 });
  };

  handlePageChange = (page: number) => {
    this.setState({ currentPage: page });
  };

  handlePageSizeChange = (pageSize: number) => {
    this.setState({ pageSize, currentPage: 1 });
  };

  /**
   * Get the default state
   * @returns {NewsState} state for the component
   */
  getDefaultState = () => {
    return {
      currentPage: 1,
      pageSize: 10,
      query: ""
    } as NewsState;
  };

  doNewsSearch = () => {
    const { news } = this.props;
    const { query } = this.state;
    if (!news) return [];
    if (query.trim()) return baseUtils.doFuseSearch(news, query, ["header", "content", "tags"]);
    return news;
  };

  render() {
    const { history } = this.props;
    const { currentPage, pageSize, query } = this.state;
    const news = this.doNewsSearch();
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand flaticon2-checking" />
            </span>
            <h3 className="kt-portlet__head-title">News</h3>
            <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
              Reset
            </button>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <button onClick={history.goBack} className="btn btn-clean kt-margin-r-10">
                <i className="la la-arrow-left" />
                <span className="kt-hidden-mobile">Back</span>
              </button>
              <button type="button" className="btn btn-brand btn-icon-sm">
                <i className="flaticon2-plus" />
                <Link
                  to={{
                    pathname: "/create-news"
                  }}
                >
                  <span style={{ color: "white" }}>Create New</span>
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-form kt-form--label-right kt-margin-b-10">
            <div className="row align-items-center">
              <div className="col-xl-8 order-2 order-xl-1">
                <div className="row align-items-center">
                  <SearchBar onSearch={this.handleSearch} search={query} additionalSizeClasses={"col-md-2"} />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            {news.map(newsObject => (
              <div key={newsObject._id.toString()} className="col-3">
                <NewsObject newsObject={newsObject} />
              </div>
            ))}
          </div>
          <div
            className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded"
            id="column_rendering"
          >
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
              <Pagination
                itemsCount={news.length}
                pageSize={pageSize}
                onPageChange={this.handlePageChange}
                currentPage={currentPage}
                onPageSizeChange={this.handlePageSizeChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default News;
