import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import { PaginationState } from "../common/CustomTypes";
import manufacturerUtils from "../../utils/manufacturerUtils";
import orderUtils, {
  CREATEINVOICE,
  FULFILLMENT,
  ORDERORDERCOMMODITIES,
  PRODUCTION,
  PRODUCTIONQUEUE,
  WAITING
} from "../../utils/orderUtils";
import { OrdersDocument } from "../../model/orders.types";
import HistoryBackButton from "./common/HistoryBackButton";
import SplashScreen from "../common/SplashScreen";
import BaseListing from "./BaseListing";
import { paginate } from "../common/Pagination";
import {
  OrderCommoditiesRow,
  OrderInvoiceRow,
  OrderPackagingRow,
  OrderProductionRow,
  OrdersDefaultRow
} from "./OrdersRows";
import {
  headerBaseDefinition,
  headerBaseDefinitionProd,
  headerOrderCommodities,
  headerOrderCommoditiesProd,
  headerOrderFulfillment,
  headerOrderFulfillmentProd,
  headerOrderInvoice,
  headerOrderPackaging,
  headerOrderProduction,
  headerOrderProductionProd
} from "./OrdersHeaders";
import orderListingUtils, {
  V_FULFILLMENT,
  V_INVOICING,
  V_ORDERCOMMODITIES,
  V_ORDERPACKAGING,
  V_PRODUCTION,
  V_READY,
  V_WAITING,
  VIEWS
} from "./orderListingUtils";
import OrdersFilter, { FILTER_STATES } from "./OrdersFilter";
import userService from "../../services/userService";
import dashboardUtils, { PERIODTYPES } from "../../utils/dashboardUtils";
import Stat from "../common/Stat";
import { getComponentState } from "../../utils/baseUtils";
import accessUtils from "../../utils/accessUtils";
import { ROLES } from "../../utils/userdataUtils";

interface OrdersParams {
  view: string;
}

interface OrdersProps extends RouteComponentProps<OrdersParams, {}, {}> {}

interface OrdersState extends PaginationState {
  search: string;
  filterState: "" | { value: string; label: string };
  owner: "" | { value: string; label: string };
  minVolume: "" | { value: string; label: string };
  maxVolume: "" | { value: string; label: string };
  manufacturer: "" | { value: string; label: string };
  priority: "" | { value: string; label: string };
  margin: "" | { value: string; label: string };
  manufacturerLocked: boolean;
  period: { start: Date; end: Date };
  filterType: "" | { value: string; label: string };
  dateType: { value: string; label: string };
  sortValue: { value: string; label: string };
  sortOrder: { value: "asc"; label: "Ascending" } | { value: "desc"; label: "Descending" };
  view: "" | { value: string; label: string };
  invoiceFilter: "" | { value: string; label: string };
}

const CONSTRUCTORNAME = "Orders";

class Orders extends PureComponent<OrdersProps, OrdersState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: OrdersProps, context: React.ContextType<typeof DataContext>) {
    super(props, context);
    this.state = this.getDefaultState(props, context);
  }

  componentDidMount() {
    const state = getComponentState(this.context, CONSTRUCTORNAME + (this.state.view ? this.state.view.value : ""));
    if (state) this.setState({ ...state });
  }

  componentDidUpdate(prevProps: Readonly<OrdersProps>, prevState: Readonly<OrdersState>, snapshot?: any) {
    if (!_.isEqual(prevProps.match.params.view, this.props.match.params.view)) {
      let paramView = this.props.match.params.view;
      this.resetStateForView(paramView);
    }
  }

  componentWillUnmount() {
    this.context.saveComponentState(CONSTRUCTORNAME + (this.state.view ? this.state.view.value : ""), this.state);
  }

  handleReset = () => this.setState(this.getDefaultState(this.props, this.context));
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value, currentPage: 1 });
  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleSelectChange = (name: string, entry: "" | { value: string; label: string }) => {
    // @ts-ignore
    if (["sortOrder", "sortValue"].includes(name)) this.setState({ [name]: entry }); // dont reset page for sort order changes, but for everything else
    // @ts-ignore
    this.setState({ [name]: entry, currentPage: 1 });
  };
  handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const period = _.cloneDeep(this.state.period);
    const type = e.target.name as "start" | "end";
    let value = e.target.valueAsDate;
    if (!value) return;
    if (type === "start") value.setHours(0, -value.getTimezoneOffset(), 0, 0);
    else value.setHours(23, 59 + -value.getTimezoneOffset(), 59, 999);
    _.set(period, type, value);
    this.setState({ period, currentPage: 1 });
  };
  handleViewChange = (entry: string) => {
    // switch view and reset filter that can't be set in the new view
    this.resetStateForView(entry);
  };

  /**
   * Workaround for type problems
   * gets the right view object {value:string,label:string}  according to the input string
   * @param paramView the input string that represents a view
   * @return  {value:string,label:string}|"" View object
   */
  getViewObject = (paramView: string) => {
    if (
      paramView &&
      [V_ORDERCOMMODITIES, V_ORDERPACKAGING, V_WAITING, V_READY, V_PRODUCTION, V_FULFILLMENT, V_INVOICING].includes(
        paramView
      )
    )
      return VIEWS.find(v => v.value === paramView) || "";

    return "";
  };

  /**
   * Get the default state
   * @param props the components properties
   * @param context data context
   * @returns {OrdersState} state for the component
   */
  getDefaultState = (props: OrdersProps, context: React.ContextType<typeof DataContext>) => {
    const manufacturer = userService.hasRole(ROLES.WAREHOUSE)
      ? ""
      : manufacturerUtils.checkCurrentUserManufacturerObject(context.manufacturers);
    const startDate = new Date(2020, 0, 1);
    startDate.setHours(0, -startDate.getTimezoneOffset(), 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59 + -endDate.getTimezoneOffset(), 59, 999);
    const paramView = props.match.params.view;
    let view = this.getViewObject(paramView);
    return {
      search: "",
      pageSize: 10,
      currentPage: 1,
      filterState: this.getStateFilterForView(view ? paramView : ""),
      owner: "",
      priority: "",
      margin: "",
      maxVolume: "",
      minVolume: "",
      manufacturer: manufacturer,
      manufacturerLocked: manufacturer !== "",
      filterType: "",
      dateType: { value: "ordered", label: "Order Date" },
      period: { start: startDate, end: endDate },
      sortValue: { value: "AT", label: "AT-Number" },
      sortOrder: { value: "desc", label: "Descending" },
      view: view,
      invoiceFilter: ""
    } as OrdersState;
  };

  /**
   * Get state to filter for view
   * @param entry current view
   * @returns{ {value:string,label:string}|""} state filter
   */
  getStateFilterForView = (entry: string) => {
    switch (entry) {
      case V_ORDERCOMMODITIES:
      case V_ORDERPACKAGING:
        return FILTER_STATES.find(fs => fs.value === ORDERORDERCOMMODITIES) || "";
      case V_WAITING:
        return FILTER_STATES.find(fs => fs.value === WAITING) || "";
      case V_READY:
        return FILTER_STATES.find(fs => fs.value === PRODUCTIONQUEUE) || "";
      case V_PRODUCTION:
        return FILTER_STATES.find(fs => fs.value === PRODUCTION) || "";
      case V_FULFILLMENT:
        return FILTER_STATES.find(fs => fs.value === FULFILLMENT) || "";
      case V_INVOICING:
        return FILTER_STATES.find(fs => fs.value === CREATEINVOICE) || "";
      case "":
      default:
        return "";
    }
  };

  /**
   * Reset the state according to the new view
   * @param entry the new view
   */
  resetStateForView = (entry: string) => {
    switch (entry) {
      case V_ORDERCOMMODITIES:
      case V_ORDERPACKAGING:
      case V_WAITING:
      case V_READY:
      case V_PRODUCTION:
      case V_FULFILLMENT:
        this.setState({
          view: this.getViewObject(entry),
          minVolume: "",
          maxVolume: "",
          invoiceFilter: "",
          currentPage: 1,
          filterState: this.getStateFilterForView(entry)
        });
        break;
      case V_INVOICING:
        this.setState({
          view: this.getViewObject(entry)!,
          minVolume: "",
          maxVolume: "",
          priority: "",
          currentPage: 1,
          filterState: this.getStateFilterForView(entry)
        });
        break;
      case "":
      default:
        this.setState({ view: "", margin: "", priority: "", currentPage: 1, filterState: "", invoiceFilter: "" });
        break;
    }
  };

  /**
   * Get filtered and sorted orders
   * @returns {Array<OrdersDocument>} filtered and sorted orders according to the filters
   */
  getFilteredOrders = () => {
    const { orders, companies, userdata } = this.context;
    const {
      search,
      filterState,
      owner,
      manufacturer,
      filterType,
      period,
      margin,
      priority,
      dateType,
      minVolume,
      maxVolume,
      sortOrder,
      sortValue,
      view,
      invoiceFilter
    } = this.state;
    let filteredOrders = orderListingUtils.filterByView(orders, view ? view.value : "");
    filteredOrders = orderUtils.filterByPeriod(filteredOrders, period, dateType.value);
    filteredOrders = orderUtils.filterByVolume(
      filteredOrders,
      minVolume ? minVolume.value : "",
      maxVolume ? maxVolume.value : ""
    );
    filteredOrders = orderUtils.filterOrders(
      filteredOrders,
      filterState ? filterState.value : "",
      owner ? owner.value : "",
      priority ? priority.value : "",
      margin ? margin.value : "",
      manufacturer ? manufacturer.value : "",
      filterType ? filterType.value : ""
    );
    filteredOrders = orderUtils.filterByInvoice(filteredOrders, invoiceFilter ? invoiceFilter.value : "");
    if (search) {
      filteredOrders = orderUtils.filterBySearch(filteredOrders, companies, userdata, search) as Array<OrdersDocument>;
    }
    if (view && view.value === V_ORDERCOMMODITIES) {
      filteredOrders = filteredOrders.filter(o => o.calculations[0].prices.some(p => !p.ordered));
    } else if (view && view.value === V_ORDERPACKAGING) {
      filteredOrders = filteredOrders.filter(o => o.calculations[0].packagings.some(p => !p.ordered));
    }
    return orderUtils.sortOrders(filteredOrders, sortValue.value, sortOrder.value);
  };

  /**
   * Get the header definition for the current view
   * @returns {Array<{title: string, size: number}>} Header definition for current view
   */
  getHeaderDefinition = () => {
    const { view } = this.state;
    const viewSwitch = view ? view.value : "";
    const canSeeFinanceData = accessUtils.canSeeFinanceData();
    switch (viewSwitch) {
      case V_ORDERCOMMODITIES:
      case V_WAITING:
        return canSeeFinanceData ? headerOrderCommodities : headerOrderCommoditiesProd;
      case V_ORDERPACKAGING:
        return headerOrderPackaging;
      case V_READY:
      case V_PRODUCTION:
        return canSeeFinanceData ? headerOrderProduction : headerOrderProductionProd;
      case V_FULFILLMENT:
        return canSeeFinanceData ? headerOrderFulfillment : headerOrderFulfillmentProd;
      case V_INVOICING:
        return headerOrderInvoice;
      case "":
      default:
        return canSeeFinanceData ? headerBaseDefinition : headerBaseDefinitionProd;
    }
  };

  /**
   * Get row component for current view
   * @param order order document
   * @returns {JSX.Element} Row component for current view
   */
  getOrderRow = (order: OrdersDocument) => {
    const { view, dateType } = this.state;
    const viewSwitch = view ? view.value : "";
    switch (viewSwitch) {
      case V_ORDERCOMMODITIES:
      case V_WAITING:
        return <OrderCommoditiesRow order={order} context={this.context} {...this.props} />;
      case V_ORDERPACKAGING:
        return <OrderPackagingRow order={order} context={this.context} {...this.props} />;
      case V_READY:
      case V_PRODUCTION:
      case V_FULFILLMENT:
        return (
          <OrderProductionRow
            order={order}
            context={this.context}
            {...this.props}
            hideUpdate={viewSwitch === V_FULFILLMENT}
          />
        );
      case V_INVOICING:
        return <OrderInvoiceRow order={order} context={this.context} {...this.props} />;
      case "":
      default:
        return <OrdersDefaultRow order={order} context={this.context} {...this.props} dateType={dateType} />;
    }
  };

  render() {
    const { orders, userdata, manufacturers } = this.context;
    const { history } = this.props;
    const {
      search,
      pageSize,
      currentPage,
      filterState,
      owner,
      manufacturer,
      manufacturerLocked,
      period,
      margin,
      priority,
      filterType,
      dateType,
      minVolume,
      maxVolume,
      sortOrder,
      sortValue,
      view,
      invoiceFilter
    } = this.state;
    const filteredOrders = this.getFilteredOrders();
    const selectableUsers = userdata.filter(user => user.company_id === "internal");
    const headerDefinition = this.getHeaderDefinition();
    const stats = userService.isAdmin()
      ? dashboardUtils.getSalesStats(
          filteredOrders,
          selectableUsers.find(u => owner && u._id.toString() === owner.value.toString()),
          {
            beginning: period.start,
            end: period.end
          },
          dateType.value as PERIODTYPES
        )
      : null;
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand flaticon-layer" />
            </span>
            <h3 className="kt-portlet__head-title">All Offers & Orders</h3>
            <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
              Reset
            </button>
          </div>
          <HistoryBackButton history={history} />
        </div>
        <div className="kt-portlet__body pb-0">
          <div className="kt-form kt-form--label-right  kt-margin-b-10">
            <OrdersFilter
              search={search}
              filterState={filterState}
              owner={owner}
              minVolume={minVolume}
              maxVolume={maxVolume}
              manufacturer={manufacturer}
              priority={priority}
              margin={margin}
              manufacturerLocked={manufacturerLocked}
              period={period}
              filterType={filterType}
              dateType={dateType}
              sortValue={sortValue}
              sortOrder={sortOrder}
              view={view}
              invoiceFilter={invoiceFilter}
              selectableUsers={selectableUsers}
              manufacturers={manufacturers}
              onSelectChange={this.handleSelectChange}
              onSearch={this.handleSearch}
              onPeriodChange={this.handlePeriodChange}
              onViewChange={this.handleViewChange}
            />
            {stats && view === "" && (
              <div className="row mx-auto mt-4 py-3 bg-light" style={{ borderRadius: "15px" }}>
                <Stat title={"Offer Volume"} value={stats.OfferVolume} unit={"euro"} />
                <Stat title={"Book Sales"} value={stats.BookSales} unit={"euro"} />
                <Stat title={"Book Margin (%)"} value={stats.BookMargin} unit={"percent"} />
                <Stat title={"Written Sales"} value={stats.WrittenSales} unit={"euro"} />
                <Stat title={"Written Margin (%)"} value={stats.WrittenMargin} unit={"percent"} />
                <Stat title={"Total Written Margin"} value={stats.TotalMargin} unit={"euro"} />
                <hr className="d-block w-100" />
                <Stat title={"Commission"} value={stats.Commission} unit={"euro"} />
                <Stat title={"Offers"} value={stats.PendingOffers} unit={"number"} />
                <Stat title={"Orders"} value={stats.PendingOrders} unit={"number"} />
                <Stat title={"Units Output"} value={stats.Output} unit={"number"} />
                <Stat title={"Margin < 20%"} value={stats.BadMargin} unit={"number"} />
                <Stat title={"Margin > 40%"} value={stats.GoodMargin} unit={"number"} />
              </div>
            )}
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          {orders.length === 0 ? (
            <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
          ) : (
            <BaseListing
              headerDefinition={headerDefinition}
              documents={filteredOrders}
              bodyContent={
                <>
                  {paginate(filteredOrders, currentPage, pageSize).map(o => (
                    <React.Fragment key={o._id.toString()}>{this.getOrderRow(o)}</React.Fragment>
                  ))}
                </>
              }
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Orders;
