import _ from "lodash";
import React, { useCallback, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { CommoditiesExtended } from "../CommoditiesBaseListing";
import { exportDatasheet } from "../../../utils/excelUtils";
import { DataContext } from "../../../context/dataContext";
import baseUtils from "../../../utils/baseUtils";

const selectableProperties = {
  "commodity.title.de": true,
  "commodity.title.en": false,
  "commodity.subtitle.de": true,
  "commodity.subtitle.en": false,
  "color.name.de": true,
  "color.name.en": false,
  "category.name.de": true,
  "category.name.en": false,
  "allergens.de": true,
  "allergens.en": false,
  "properties.de": true,
  "properties.en": false,
  "activeSubstance.de": true,
  "activeSubstance.en": false,
  "commodity.country": true,
  "commodity.density": true,
  "commodity.organic": true,
  "commodity.toxic_amount": true,
  "commodity.identifier": true
};

const propertyLabels: Record<string, string> = {
  "commodity.title.de": "Title (German)",
  "commodity.title.en": "Title (English)",
  "commodity.subtitle.de": "Subtitle (German)",
  "commodity.subtitle.en": "Subtitle (English)",
  "color.name.de": "Color (German)",
  "color.name.en": "Color (English)",
  "category.name.de": "Category (German)",
  "category.name.en": "Category (English)",
  "allergens.de": "Allergens (German)",
  "allergens.en": "Allergens (English)",
  "properties.de": "Overall Properties (German)",
  "properties.en": "Overall Properties (English)",
  "activeSubstance.de": "Act. Substances (German)",
  "activeSubstance.en": "Act. Substances (English)",
  "commodity.country": "Country",
  "commodity.density": "Density",
  "commodity.organic": "Organic",
  "commodity.toxic_amount": "Toxic Amount",
  "commodity.identifier": "Identifier"
};

interface ExportCommoditiesModalProps {
  commodities: Array<CommoditiesExtended>;
}

const ExportCommoditiesModal: React.FC<ExportCommoditiesModalProps> = ({ commodities }) => {
  const context = useContext(DataContext);
  const [show, setShow] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [propertySelection, setPropertySelection] = useState<Record<string, boolean>>(selectableProperties);

  const handleShow = useCallback(() => setShow(true), []);
  const handleHide = useCallback(() => setShow(false), []);
  const handleToggleProperty = useCallback((property: string) => {
    setPropertySelection(prev => ({ ...prev, [property]: !prev[property] }));
  }, []);

  const handleExportXLSX = useCallback(() => {
    const { allergens, commodityproperties, activesubstances } = context;
    setGenerating(true);

    const datasheet: Array<Array<string>> = [];
    const selectedProperties = Object.keys(propertySelection).filter(property => propertySelection[property]);

    // Headers
    const headers = selectedProperties.map(header => propertyLabels[header]);
    datasheet.push(headers);

    // Rows
    commodities.forEach(commodityExtended => {
      const row: Array<string> = selectedProperties.map(property => {
        switch (property) {
          case "allergens.de":
            return allergens
              .filter(a => commodityExtended.commodity.allergens.map(ca => ca.toString()).includes(a._id.toString()))
              .map(fa => fa.name.de)
              .join(" - ");
          case "allergens.en":
            return allergens
              .filter(a => commodityExtended.commodity.allergens.map(ca => ca.toString()).includes(a._id.toString()))
              .map(fa => fa.name.en)
              .join(" - ");
          case "properties.de":
            return commodityproperties
              .filter(p => commodityExtended.commodity.properties.map(cp => cp.toString()).includes(p._id.toString()))
              .map(fp => fp.name.de)
              .join(" - ");
          case "properties.en":
            return commodityproperties
              .filter(p => commodityExtended.commodity.properties.map(cp => cp.toString()).includes(p._id.toString()))
              .map(fp => fp.name.en)
              .join(" - ");
          case "activeSubstance.de":
            return activesubstances
              .filter(as =>
                commodityExtended.commodity.activesubstance.map(ca => ca._id.toString()).includes(as._id.toString())
              )
              .map(fas => fas.name.de)
              .join(" - ");
          case "activeSubstance.en":
            return activesubstances
              .filter(as =>
                commodityExtended.commodity.activesubstance.map(ca => ca._id.toString()).includes(as._id.toString())
              )
              .map(fas => fas.name.en)
              .join(" - ");
          default:
            const value = _.get(commodityExtended, property);
            if (typeof value === "boolean") {
              return value ? "Yes" : "No";
            }
            return value || "";
        }
      });
      datasheet.push(row);
    });

    exportDatasheet(datasheet, `Commodities_${baseUtils.formatDate(new Date(), true)}`);
    setGenerating(false);
  }, [propertySelection, commodities, context.allergens, context.activesubstances, context.commodityproperties]);

  return (
    <>
      <button className="btn btn-secondary kt-margin-r-10" onClick={handleShow}>
        <i className="fa fa-download" /> Export Commodities
      </button>
      <Modal show={show} onHide={generating ? undefined : handleHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="kt-font-brand fa fa-download mr-1" /> Export Commodities
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-portlet__body">
            <div className="mb-4 ml-3">
              <span className="kt-font-dark kt-font-bold">
                Please select the properties to be included in the exported file:
              </span>
            </div>
            <div className="d-flex justify-content-center">
              <div className="text-left">
                <div className="row ml-3">
                  {Object.keys(selectableProperties).map((property, idx) => (
                    <div className="col-6 mb-2" key={idx}>
                      <div className="form-check mr-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={() => handleToggleProperty(property)}
                          checked={propertySelection[property]}
                        />
                        <label className="form-check-label kt-font-dark ml-1">
                          {propertyLabels[property] || property}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            disabled={generating}
            onClick={generating ? undefined : handleHide}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            disabled={generating}
            onClick={generating ? undefined : handleExportXLSX}
          >
            <i className="fa fa-download" />
            {generating ? (
              <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                <svg className="button-splash-spinner" viewBox="0 0 50 50">
                  <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                </svg>
              </div>
            ) : (
              "Export File"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExportCommoditiesModal;
