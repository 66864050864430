import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";
import ExternalManufacturerOrders from "./ExternalManufacturerOrders";

interface EmOrdersWrapperProps extends RouteComponentProps<{}, {}, {}> {}

const EmOrdersWrapper: React.FunctionComponent<EmOrdersWrapperProps> = props => {
  const context = useContext(ExternalManufacturerContext);
  return <ExternalManufacturerOrders context={context} {...props} />;
};

export default EmOrdersWrapper;
