import React, { PureComponent } from "react";
import { SuppliersDocument } from "../../model/suppliers.types";
import RatingInfo from "../common/RatingInfo";
import { UserdataDocument } from "../../model/userdata.types";

interface SupplierInformationProps {
  supplier: SuppliersDocument;
  contact?: UserdataDocument;
  onTabChange: (tab: string) => void;
}

interface SupplierInformationState {}

class SupplierInformation extends PureComponent<SupplierInformationProps, SupplierInformationState> {
  render() {
    const { supplier, contact, onTabChange } = this.props;
    const address = supplier.address.length > 0 ? supplier.address[0] : undefined;
    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Company</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <button onClick={() => onTabChange("settings")} className="btn btn-label-brand btn-sm btn-bold">
              Update Information
            </button>
          </div>
        </div>
        <div className="kt-form kt-form--label-right">
          <div className="kt-portlet__body">
            <div className="form-group form-group-xs row">
              <label className="col-4 col-form-label">Name:</label>
              <div className="col-8">
                <span className="form-control-plaintext kt-font-bolder">{supplier.name}</span>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-4 col-form-label">Location:</label>
              <div className="col-8">
                <span className="form-control-plaintext kt-font-bolder">
                  {address ? address.city + ", " + address.country : "Not set"}
                </span>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-4 col-form-label">Rating:</label>
              <div className="col-8">
                <span className="form-control-plaintext kt-font-bolder">
                  <RatingInfo rating={supplier.rating} />
                </span>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-4 col-form-label">Contact Person:</label>
              <div className="col-8">
                <span className="form-control-plaintext kt-font-bolder">
                  {contact ? contact.prename + " " + contact.surname : "Not set"}
                </span>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-4 col-form-label">Email</label>
              <div className="col-8">
                <span className="form-control-plaintext kt-font-bolder">
                  {contact && contact.email.length > 0 ? contact.email[0] : "Not set"}
                </span>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-4 col-form-label">Telephone</label>
              <div className="col-8">
                <span className="form-control-plaintext kt-font-bolder">
                  {contact && contact.telephone.length > 0 ? contact.telephone[0] : "Not set"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SupplierInformation;
