import _ from "lodash";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BSON } from "realm-web";
import { toAbsoluteUrl } from "../../_metronic";
import { CustomOrder } from "./CustomTypes";
import { CommoditiesDocument } from "../../model/commodities.types";
import baseUtils from "../../utils/baseUtils";
import { CapsulesDocument } from "../../model/capsules.types";
import { DataContext, DataContextType } from "../../context/dataContext";
import calculationUtils from "../../utils/calculationUtils";
import { OrdersDocument } from "../../model/orders.types";
import { RequestsDocument } from "../../model/requests.types";

export const T_CUSTOM = "custom";
export const T_SOFTGEL = "softgel";
export const T_SERVICE = "service";
export const T_CAPSULE = "capsule";
export const T_TABLET = "tablet";
export const T_POWDER = "powder";
export const T_LIQUID = "liquid";

/**
 * Render a tooltip for detailed information
 * @param data null or list of tuples
 * @param noLocale flag if values should not be parsed to locale currency string
 * @returns {JSX.Element} an overlay trigger to display or nothing
 */
function renderDetailsTooltip(data: any, noLocale?: boolean) {
  if (!data) return;
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="unitPriceInfo">
          {data.map((tuple: any) => {
            const key = tuple[0];
            let value = tuple[1].toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR"
            });
            if (noLocale) value = (Math.round(tuple[1] * 100) / 100).toString() + " %";
            return (
              <React.Fragment key={key}>
                <b className="mr-2" style={{ fontSize: "18px" }}>
                  {key + " Units : " + value}
                </b>
                <br />
              </React.Fragment>
            );
          })}
        </Tooltip>
      }
    >
      <i className="fa fa-info-circle ml-2 text-muted" />
    </OverlayTrigger>
  );
}

/**
 * Get image with file icon for the given file type
 * @param timelineEntry a timeline entry
 * @param fileType the file type
 * @returns {JSX.Element} image component
 */
function getFileTypeImage(timelineEntry: any, fileType: string) {
  if (["JPEG", "JPG", "PNG", "GIF"].includes(fileType)) {
    return <img src={timelineEntry.path} alt="" />;
  } else if (fileType === "PDF") {
    return <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />;
  } else {
    return <img src={toAbsoluteUrl("/media/icons/file_icon.png")} alt="" />;
  }
}

/**
 * Get path for the image to display
 * @param timelineEntry a timeline entry
 * @param fileType the file type
 * @returns { string } path to the image
 */
function getFileTypeImagePath(timelineEntry: any, fileType: string) {
  if (["JPEG", "JPG", "PNG", "GIF"].includes(fileType)) {
    return timelineEntry.path;
  } else if (fileType === "PDF") {
    return toAbsoluteUrl("/media/icons/pdf_icon.png");
  } else {
    return toAbsoluteUrl("/media/icons/file_icon.png");
  }
}

/**
 * Get a string of all commodity titles of an order
 * @param order an order document
 * @param context data context
 * @param includeCapsule flag if capsule title should be added or not
 * @param specificLanguage the language to use
 * @returns {string} all commodities + optional capsule titles concatenated as string
 */
function getCommodityList(
  order: CustomOrder,
  context: DataContextType,
  includeCapsule?: boolean,
  specificLanguage?: string
) {
  const commodities = getCommoditiesDescription(order, context.commodities, specificLanguage);
  let capsule;
  if (includeCapsule) capsule = getCapsuleDescription(order, context.capsules, specificLanguage);
  let titles = commodities;
  if (capsule) titles.push(capsule);
  return titles
    .sort((t1, t2) => t2.amount - t1.amount)
    .map(t => t.title)
    .join(", ");
}

/**
 * Get a string of all coloring additives
 * @param order an order document
 * @param context data context
 * @returns {string | undefined} all coloring additives if defined
 */
function getColorAdditiveList(order: CustomOrder, context: DataContextType) {
  if (!order.settings.id || !BSON.ObjectId.isValid(order.settings.id)) return;
  const capsule = context.capsules.find(cap => cap._id.toString() === order.settings.id.toString());
  if (!capsule) return;
  const additives =
    capsule.additives &&
    context.additives.filter(a => capsule.additives?.map(cA => cA.toString()).includes(a._id.toString()));
  if (additives) return additives.map(a => a.eNumber).join(", ");
}

/**
 * Get a string of all commodity titles except filler material like sipernat
 * @param order an order document
 * @param commodities list of all commodity documents
 * @param specificLanguage the language to use
 * @returns {Array<CommoditiesDocument>} list of all commodity documents contained in the order recipe
 */
function getCommoditiesDescription(
  order: CustomOrder,
  commodities: Array<CommoditiesDocument>,
  specificLanguage?: string
) {
  const recipe = order.recipe;
  const commodityList: Array<{ title: string; amount: number }> = [];
  for (let i = 0; i < recipe.length; i++) {
    const commodity = recipe[i];
    if (["5e693761a8942807a217f235", "5e693651a8942807a217f234"].includes(commodity.id.toString())) continue;
    const fullCommodity = baseUtils.getDocFromCollection(commodities, commodity.id);
    const commodityTitle: string = specificLanguage === "en" ? fullCommodity.title.en : fullCommodity.title.de;
    if (fullCommodity) commodityList.push({ title: commodityTitle, amount: commodity.amount });
  }
  return commodityList;
}

/**
 * Get the description for a capsule
 * @param order an order document
 * @param capsules list of capsule documents
 * @param specificLanguage the language to use
 * @returns {object} null or object with id and description for the capsule
 */
function getCapsuleDescription(order: CustomOrder, capsules: Array<CapsulesDocument>, specificLanguage?: string) {
  if (!order.settings.id || !BSON.ObjectId.isValid(order.settings.id)) return null;
  const capsule = capsules.find(cap => cap._id.toString() === order.settings.id.toString());
  if (!capsule) return null;
  let capsuleTitle = specificLanguage === "en" ? capsule.capsule_material.en : capsule.capsule_material.de;
  capsuleTitle = capsuleTitle === "HPMC" ? "Hydroxypropylmethylcellulose" : capsuleTitle;
  capsuleTitle += specificLanguage === "en" ? " (Capsule shell)" : " (Kapselhülle)";
  return {
    _id: capsule._id,
    title: capsuleTitle,
    amount: capsule.capsule_weight
  };
}

/**
 * Extract customer data from a custom order document
 * @param order a custom order document
 * @returns {object} extracted customer data, may contain default values
 */
function getCustomerData(order: CustomOrder) {
  const customer = order.createdFor;
  return {
    name: customer.name,
    careOf: customer.address[0].careOf ? customer.address[0].careOf : "",
    street: customer.address.length > 0 ? customer.address[0].street + " " + customer.address[0].streetnr : "",
    additionalAddress: "",
    zip: customer.address.length > 0 ? customer.address[0].zip : "",
    city: customer.address.length > 0 ? customer.address[0].city : "",
    country: customer.address.length > 0 ? customer.address[0].country : ""
  };
}

/**
 * Format a commodity amount according to the type
 * @param amount the amount to format
 * @param type the type of the order
 * @returns {string} formatted amount
 */
function formatAmount(amount: number | undefined | null, type: string) {
  if (amount === null || amount === undefined) return "not set";
  else if ([T_CUSTOM, T_SOFTGEL].includes(type)) return amount + " tsd.";
  else if ([T_SERVICE].includes(type)) return amount + " pcs.";
  else return Math.round(amount * 100) / 100 + "kg";
}

/**
 * Convert the amount to the correct unit
 * @param amount amount to convert in tsd., mg, pcs.
 * @param type the order type
 * @returns {number} the converted amount
 */
function getOrderQuantityForType(amount: number, type: string) {
  if ([T_CUSTOM, T_SOFTGEL].includes(type)) return amount / 1000;
  else if ([T_SERVICE].includes(type)) return amount;
  else return Math.round((amount / (1000 * 1000)) * 100) / 100;
}

/**
 * Get matching commodity amount unit for the given order type
 * @param type the order type
 * @returns {string} matching unit for commodity amounts
 */
function getQuantityUnitForType(type: string) {
  if ([T_CUSTOM, T_SOFTGEL].includes(type)) return " tsd.";
  else if ([T_SERVICE].includes(type)) return " pcs.";
  else return "kg";
}

/**
 * Get the formatted amount according to the type
 * @param amount amount to format
 * @param type the order type
 * @returns {string} formatted amount
 */
function getFormattedAmount(amount: number, type: string) {
  return [T_CUSTOM, T_SERVICE, T_SOFTGEL].includes(type)
    ? type === T_SERVICE
      ? amount + " pcs."
      : amount / 1000 + " tsd."
    : calculationUtils.formatAmount(amount, 2);
}

/**
 * Get the extended order
 * @param order an order document
 * @param context the data context
 * @returns {CustomOrder} the extended order document
 */
const getExtendedOrder = (order: OrdersDocument, context: React.ContextType<typeof DataContext>) => {
  const { companies, manufacturers, userdata } = context;
  const extendedOrder = _.omit(_.cloneDeep(order), ["createdFrom", "createdFor", "settings"]) as any;
  extendedOrder.createdFrom = baseUtils.getDocFromCollection(userdata, order.createdFrom)!;
  extendedOrder.createdFor = baseUtils.getDocFromCollection(companies, order.createdFor)!;
  const settings = _.cloneDeep(order.settings);
  settings.manufacturer = baseUtils.getDocFromCollection(manufacturers, settings.manufacturer)!;
  if (settings.filler) settings.filler = baseUtils.getDocFromCollection(manufacturers, settings.filler)!;
  extendedOrder.settings = settings;
  return extendedOrder;
};

/**
 * Check if an order or request is bulk
 * @param order order or request document
 * @param checkPowderLiquid flag to include power and liquid, e.g. outside configurator
 * @returns {boolean} true if it is a bulk, else false
 */
function isBulk(order: OrdersDocument | RequestsDocument | CustomOrder, checkPowderLiquid?: true) {
  return (
    ("bulk" in order.settings && !!order.settings.bulk) ||
    (order.calculations[0].packagings.length === 0 &&
      [T_SOFTGEL, T_TABLET, T_CAPSULE, T_CUSTOM].includes(order.settings.type)) ||
    (!!checkPowderLiquid &&
      [T_POWDER, T_LIQUID].includes(order.settings.type) &&
      order.calculations[0].packagings.length === 0)
  );
}

export default {
  renderDetailsTooltip,
  getFileTypeImage,
  getColorAdditiveList,
  getFileTypeImagePath,
  getCommodityList,
  getCustomerData,
  getOrderQuantityForType,
  getQuantityUnitForType,
  getFormattedAmount,
  getExtendedOrder,
  formatAmount,
  isBulk
};
