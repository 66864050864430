import _ from "lodash";
import React, { PureComponent } from "react";
import Chart from "react-apexcharts";
import { DataContext } from "../../../context/dataContext";
import { Period } from "../../common/CustomTypes";
import baseUtils from "../../../utils/baseUtils";
import orderUtils, { DECLINED } from "../../../utils/orderUtils";
import invoiceUtils, { I_CANCELED } from "../../../utils/invoiceUtils";
import dashboardUtils from "../../../utils/dashboardUtils";
import { ROLES } from "../../../utils/userdataUtils";
import contractUtils from "../../../utils/contractUtils";

interface SalesProfitTurnoverGraphProps {
  context: React.ContextType<typeof DataContext>;
  period: Period;
}

interface SalesProfitTurnoverGraphState {
  dataType: "profit" | "turnover";
  series: Array<{ name: string; data: Array<number> | Array<{ x: string; y: number }> }>;
  options: any;
}

class SalesProfitTurnoverGraph extends PureComponent<SalesProfitTurnoverGraphProps, SalesProfitTurnoverGraphState> {
  constructor(props: SalesProfitTurnoverGraphProps) {
    super(props);
    this.state = {
      dataType: "profit",
      series: [],
      options: {
        chart: {
          type: "area",
          id: "basic-bar-profit-turnover",
          toolbar: {
            show: true
          },
          zoom: { enabled: false }
        },
        xaxis: {
          type: "datetime"
        },
        yaxis: {
          labels: {
            formatter: (v: string) => baseUtils.formatEuro(+v)
          }
        },
        stroke: {
          curve: "straight"
        },
        dataLabels: { enabled: false },
        colors: ["#00aaff", "#c8c8c8", "#00d185", "#f15151", "#00c8ff", "#15523c"],
        fill: { colors: ["transparent"], type: "solid" }
      }
    };
  }

  componentDidMount() {
    this.setState({ series: this.calculateData() });
  }

  componentDidUpdate(
    prevProps: Readonly<SalesProfitTurnoverGraphProps>,
    prevState: Readonly<SalesProfitTurnoverGraphState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.context.orders, this.props.context.orders) || prevState.dataType !== this.state.dataType) {
      this.setState({ series: this.calculateData() });
    }
  }

  calculateData = () => {
    const { context, period } = this.props;
    const { dataType } = this.state;
    const sales = context.userdata.filter(
      u =>
        (u.role.includes(ROLES.SALES) || u.role.includes(ROLES.ADMIN)) &&
        ["Christoph Stachelek", "Jana Papke", "Tayfun Bati", "Pierre Sönnichsen", "Fabian Geisler"].includes(
          u.prename + " " + u.surname
        )
    );
    const orders = context.orders.filter(
      o =>
        orderUtils.isOrder(o) &&
        !contractUtils.isContract(o) &&
        o.state !== DECLINED &&
        o.createdOn.getTime() > period.beginning.getTime() &&
        o.createdOn.getTime() < period.end.getTime()
    );
    const data = [];
    for (let i = 0; i < sales.length; i++) {
      const s = sales[i];
      const sOrders = orders.filter(o => o.createdFrom.toString() === s._id.toString());
      let sData = [{ x: period.beginning.toISOString(), y: 0 }];
      for (let j = 0; j < sOrders.length; j++) {
        const sO = sOrders[j];
        const percentMargin = dashboardUtils.getPercentMargin(sO);
        if (dataType === "profit" && percentMargin < 0) {
          console.warn("Found negative margin for order", sO._id.toString());
        }
        if (sO.invoices) {
          for (let k = 0; k < sO.invoices.length; k++) {
            const inv = sO.invoices[k];
            if (inv.state !== I_CANCELED && period.beginning <= inv.invoiceDate && period.end >= inv.invoiceDate) {
              const total = invoiceUtils.getSubtotal(inv.positions);
              sData.push({
                x: inv.invoiceDate.toISOString(),
                y: dataType === "profit" ? invoiceUtils.calculateAbsoluteMargin(total, percentMargin) : total
              });
            }
          }
        }
      }
      sData = sData.sort((d1, d2) => new Date(d1.x).getTime() - new Date(d2.x).getTime());
      for (let l = 1; l < sData.length; l++) {
        sData[l].y = sData[l].y + sData[l - 1].y;
      }
      sData.push({ x: period.end.toISOString(), y: sData.length > 0 ? sData[sData.length - 1].y : 0 });
      data.push({ name: s.prename + " " + s.surname, data: sData });
    }
    return data;
  };

  render() {
    const { dataType, series, options } = this.state;
    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">
              {dataType === "profit" ? "Profit" : "Turnover"} by Sales Employee
            </h3>
          </div>
          <div className="kt-portlet__head--right align-self-center">
            <div className="btn-group">
              <button
                className={"btn " + (dataType === "profit" ? "btn-success" : "btn-secondary")}
                onClick={() => this.setState({ dataType: "profit" })}
              >
                Profit
              </button>
              <button
                className={"btn " + (dataType === "turnover" ? "btn-success" : "btn-secondary")}
                onClick={() => this.setState({ dataType: "turnover" })}
              >
                Turnover
              </button>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body p-3">
          <Chart series={series} type="area" options={options} heigth="700" width="100%" />
        </div>
      </div>
    );
  }
}
export default SalesProfitTurnoverGraph;
