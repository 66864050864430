import React, { useEffect, useState } from "react";
import { WarehouseProvider } from "../../context/warehouseContext";
import WarehouseSelectionPanel from "./layout/WarehouseSelectionPanel";
import WarehouseActionPanel from "./layout/WarehouseActionPanel";
import WarehouseInformationPanel from "./layout/WarehouseInformationPanel";
import WarehouseUpdatesPanel from "./layout/WarehouseUpdatesPanel";
import WarehouseListing from "./layout/WarehouseListing";
import i18n from "../../translations/i18n";

const WarehouseManagementSystem: React.FC = () => {
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    i18n.changeLanguage("de").then(() => setReady(true));
  }, []);

  if (!ready) return null;
  return (
    <WarehouseProvider>
      <div className="container-fluid w-100">
        <div className="row">
          <div className="col-2">
            <WarehouseSelectionPanel />
          </div>
          <div className="col-10">
            <div className="row">
              <div className="col-8">
                <WarehouseListing />
              </div>
              <div className="col-4">
                <WarehouseActionPanel />
                <WarehouseInformationPanel />
                <WarehouseUpdatesPanel />
              </div>
            </div>
          </div>
        </div>
      </div>
    </WarehouseProvider>
  );
};

export default WarehouseManagementSystem;
