import React, { PureComponent } from "react";
import { CustomOrder } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import baseUtils from "../../../utils/baseUtils";
import { OrdersDocument } from "../../../model/orders.types";
import { UserdataDocument } from "../../../model/userdata.types";
import PersonWidget from "../../common/PersonWidget";
import { CustomerNotesExtended, PaginationState } from "../../common/CustomTypes";
import ReadCustomerNoteModal from "../../customerNotes/ReadCustomerNoteModal";
import ContinueOrderConversationNotesModal from "../../customerNotes/ContinueOrderConversationNotesModal";
import Pagination, { paginate } from "../../common/Pagination";
import StartConversationModal from "../modals/StartConversationModal";
import { CompaniesDocument } from "../../../model/companies.types";

interface OrderConversationNotesProps {
  order: CustomOrder | OrdersDocument;
  context: React.ContextType<typeof DataContext>;
}

interface OrderConversationNotesState extends PaginationState {}

class OrderConversationNotes extends PureComponent<OrderConversationNotesProps, OrderConversationNotesState> {
  constructor(props: OrderConversationNotesProps) {
    super(props);
    this.state = { currentPage: 1, pageSize: 10 };
  }

  handlePageChange = (currentPage: number) => this.setState({ currentPage });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize });

  prepareNotes = () => {
    const { order: o, context } = this.props;
    const notes: Array<CustomerNotesExtended> = [];
    if (!o.customerNotes) return notes;
    for (let i = 0; i < o.customerNotes.length; i++) {
      const n = o.customerNotes[i];
      const person: UserdataDocument | undefined = baseUtils.getDocFromCollection(context.userdata, n.person);
      if (person) {
        const company: CompaniesDocument | "internal" | undefined =
          person.company_id === "internal"
            ? person.company_id
            : baseUtils.getDocFromCollection(context.companies, person.company_id);
        if (company) {
          if (o.customerNotes.length - 1 === i) {
            notes.push({ order: o, note: n, person, company, lastMessage: true });
          } else {
            notes.push({ order: o, note: n, person, company });
          }
        }
      }
    }
    return notes.sort((n1, n2) => n1.note.date.getTime() - n2.note.date.getTime());
  };

  render() {
    const { currentPage, pageSize } = this.state;
    const { order } = this.props;
    const notes = this.prepareNotes();
    const customerNotesPaginated = paginate(notes, currentPage, pageSize);
    const relatedCompany = order.createdFor as CompaniesDocument;
    return (
      <div className="kt-notification">
        <div className="kt-widget4">
          {relatedCompany && !order.customerNotes && relatedCompany.featureAccess?.notes && (
            <div className="kt-section">
              <div className="kt-section__content kt-section__content--solid " style={{ borderLeft: "none" }}>
                <StartConversationModal order={order} />
              </div>
            </div>
          )}
          {notes.length > 0 ? (
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
              <table className="kt-datatable__table d-table table">
                <thead>
                  <tr className="kt-datatable__row">
                    <th style={{ width: "15%" }}>Date</th>
                    <th style={{ width: "15%" }}>Person</th>
                    <th style={{ width: "50%" }}>Note</th>
                    <th className="text-right" style={{ width: "20%" }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {customerNotesPaginated.map(note => {
                    return <OrderConversationNote key={note.note._id.toString()} messageObject={note} />;
                  })}
                </tbody>
              </table>
              <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <Pagination
                  itemsCount={notes.length}
                  pageSize={pageSize}
                  onPageChange={this.handlePageChange}
                  currentPage={currentPage}
                  onPageSizeChange={this.handlePageSizeChange}
                  additionalNavClasses="justify-content-center flex-grow-1"
                />
              </div>
            </div>
          ) : (
            <div className="text-center mb-3 my-auto">
              <span className="d-block my-5 ml-5 mr-0 pb-5">
                <i className="fa fa-exclamation-circle mr-1" style={{ color: "rgb(191,197,212)" }} />
                {relatedCompany && !relatedCompany.featureAccess?.notes
                  ? "The SupplyCentral conversation notes feature is not enabled"
                  : "No order related conversion has been started yet."}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default OrderConversationNotes;

interface OrderConversationNoteProps {
  messageObject: CustomerNotesExtended;
}

class OrderConversationNote extends PureComponent<OrderConversationNoteProps> {
  render() {
    const { messageObject } = this.props;
    return (
      <tr className="kt-datatable__row d-table-row table-hover">
        <td className="kt-datatable__cell d-table-cell align-middle">
          {baseUtils.formatDate(messageObject.note.date, true)}
        </td>
        <td className="kt-datatable__cell d-table-cell align-middle">
          <PersonWidget person={messageObject.person} />
        </td>
        <td className="kt-datatable__cell d-table-cell align-middle">
          <span className="text-dark">{messageObject.note.note}</span>
        </td>
        <td className="kt-datatable__cell d-table-cell align-middle text-right">
          {messageObject.person.company_id !== "internal" &&
            (messageObject.note.readDate ? (
              <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill mr-2">
                <i className="fa fa-check mr-2" />
                Read
              </span>
            ) : (
              <ReadCustomerNoteModal note={messageObject} />
            ))}
          <div className="mt-2 mr-2">
            {messageObject.lastMessage && <ContinueOrderConversationNotesModal note={messageObject} />}
          </div>
        </td>
      </tr>
    );
  }
}
