import _ from "lodash";
import React, { Component } from "react";
import { OrdersDocument } from "../../model/orders.types";
import { DataContext } from "../../context/dataContext";
import { PaginationState } from "../common/CustomTypes";
import Pagination, { paginate } from "../common/Pagination";
import ProductionOrdersListingRow from "./ProductionOrdersListingRow";

interface ProductionOrdersListingProps {
  type: string;
  standardView: boolean;
  icon: JSX.Element;
  orders: Array<OrdersDocument>;
  context: React.ContextType<typeof DataContext>;
  onSelectOrder: (order: OrdersDocument) => void;
}

interface ProductionOrdersListingState extends PaginationState {}

class ProductionOrdersListing extends Component<ProductionOrdersListingProps, ProductionOrdersListingState> {
  constructor(props: ProductionOrdersListingProps) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10
    };
  }

  componentDidUpdate(
    prevProps: Readonly<ProductionOrdersListingProps>,
    prevState: Readonly<ProductionOrdersListingState>,
    snapshot?: any
  ) {
    if (prevProps.orders.length > this.props.orders.length) {
      // Reset current page if amount of orders decreased, e.g. due to search
      this.setState({ currentPage: 1 });
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<ProductionOrdersListingProps>,
    nextState: Readonly<ProductionOrdersListingState>,
    nextContext: any
  ): boolean {
    return (
      !_.isEqual(nextProps.orders, this.props.orders) ||
      nextProps.standardView !== this.props.standardView ||
      nextProps.context !== this.props.context ||
      this.state.currentPage !== nextState.currentPage ||
      this.state.pageSize !== nextState.pageSize
    );
  }

  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });

  render() {
    const { type, orders, icon, standardView, context, onSelectOrder } = this.props;
    const { currentPage, pageSize } = this.state;
    const header = standardView
      ? [
          { title: "Order", size: 25 },
          { title: "Priority", size: 6 },
          { title: "Target Week", size: 7 },
          { title: "Total Amount", size: 12, classes: "text-center" },
          { title: "Packaging", size: 12 },
          { title: "Commodity Status", size: 6 },
          { title: "Packaging Status", size: 6 },
          { title: "Overall Status", size: 16 }
        ]
      : [
          { title: "Order", size: 20 },
          { title: "Priority", size: 8 },
          { title: "Target Week", size: 7 },
          { title: "Total Amount", size: 10, classes: "text-center" },
          { title: "Torso", size: 20 },
          { title: "Other Packaging", size: 20 },
          { title: "Status", size: 15 }
        ];
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div
          className="kt-portlet__head kt-portlet__head--lg"
          style={{ backgroundColor: "#fafafa", borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
        >
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">{icon}</span>
            <h3 className="kt-portlet__head-title">{type}</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          {orders.length > 0 && (
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
              <table className="kt-datatable__table d-table">
                <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
                  <tr className="kt-datatable__row d-table-row">
                    {header.map(h => (
                      <th
                        key={h.title}
                        className={"kt-datatable__cell d-table-cell " + (h.classes ? h.classes : "")}
                        style={{ width: h.size + "%" }}
                      >
                        <span>{h.title}</span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                  {paginate(orders, currentPage, pageSize).map(o => {
                    return (
                      <ProductionOrdersListingRow
                        key={o._id.toString()}
                        order={o}
                        context={context}
                        standardView={standardView}
                        onSelectOrder={() => onSelectOrder(o)}
                      />
                    );
                  })}
                </tbody>
              </table>
              <div className="kt-datatable__pager kt-datatable--paging-loaded justify-content-center">
                <Pagination
                  itemsCount={orders.length}
                  pageSize={pageSize}
                  onPageChange={this.handlePageChange}
                  currentPage={currentPage}
                  onPageSizeChange={this.handlePageSizeChange}
                  additionalPageSizeClasses={"ml-2"}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ProductionOrdersListing;
