import { CustomOrder, ShippingLabelData } from "../../components/order/CustomTypes";
import baseUtils from "../baseUtils";

export const TITLEFONT = "28px Helvetica";
export const TITLESIZE = 375;
export const CUSTOMERFONT = "22px Helvetica";
export const CUSTOMERSIZE = 300;

function createShippingLabel(order: CustomOrder, pdfData: ShippingLabelData) {
  const { title, customer, lot, amount, bestBefore } = pdfData;
  let c = document.getElementById("canvas");
  const productTitle = baseUtils.cropTextSize(c, title, TITLEFONT, TITLESIZE);
  const customerName = baseUtils.cropTextSize(c, customer, CUSTOMERFONT, CUSTOMERSIZE);
  return `
<head>
  <link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet">
  <meta http-equiv="content-type" content="text/html; charset=utf-8">
</head>
<body style="font-family: Helvetica; ">
  <div style="position: absolute; top: 35px; left: 25px">
    <div style="font-size: 40px; font-weight: 900">AT-${order.identifier}</div>
    <span style="font-size: 28px; white-space: nowrap; overflow: hidden; -o-text-overflow: ellipsis; text-overflow: ellipsis; -ms-text-overflow: ellipsis;">${productTitle}</span>
  </div>
  <div style="position: absolute; top: 150px; left: 25px">
    <table style="font-size: 22px">
      <tbody>
        <tr>
          <td>
            <b>Amount:</b>
          </td>
          <td>
            ${amount}
          </td>
        </tr>
        <tr>
          <td>
            <b>LOT-Nr.:</b>
          </td>
          <td>
            ${lot}
          </td>
        </tr>
        <tr>
          <td>
            <b>Best Before:</b>
          </td>
          <td>
            ${bestBefore}
          </td>
        </tr>
        <tr>
          <td>
            <b>Customer:</b>
          </td>
          <td>
            ${customerName}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</body>
`;
}

export default { createShippingLabel };
