import React, { PureComponent } from "react";
import { Badge, Table } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import PersonWidget from "../common/PersonWidget";
import { ManufacturersDocument, ProductionLine } from "../../model/manufacturers.types";
import userService from "../../services/userService";
import manufacturerUtils from "../../utils/manufacturerUtils";
import { ROLES } from "../../utils/userdataUtils";

interface ManufacturersProps extends RouteComponentProps<{}, {}, {}> {}

interface ManufacturersState {
  manufacturersShown: Array<ManufacturersDocument>;
}

class Manufacturers extends PureComponent<ManufacturersProps, ManufacturersState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: ManufacturersProps) {
    super(props);
    this.state = { manufacturersShown: [] };
  }

  componentDidMount() {
    const { history } = this.props;
    const { manufacturers } = this.context;
    let manufacturersShown = [];
    if (userService.hasRole(ROLES.PRODUCTION, true)) {
      const manufacturer = manufacturers.find(m =>
        manufacturerUtils.isEmployeeOfManufacturer(userService.getUserData()._id, m)
      );
      if (manufacturer) {
        history.replace("/manufacturer/" + manufacturer._id.toString());
        return;
      }
    }
    // Only show all manufacturers to admins and sales
    if (!userService.isAuthorizedForAction(ROLES.SALES)) {
      for (let manufacturer of manufacturers) {
        if (manufacturerUtils.isEmployeeOfManufacturer(userService.getUserData()._id, manufacturer)) {
          manufacturersShown.push(manufacturer);
        }
      }
    } else {
      manufacturersShown = manufacturers;
    }
    this.setState({ manufacturersShown });
  }

  /**
   * Renders the given production lines.
   * @param productionLines: Production lines that should be rendered
   * @returns { JSX.Element } Row containing production lines
   */
  renderProductionLines = (productionLines: Array<ProductionLine>) => {
    return (
      <div className="row">
        {productionLines.map(pl => {
          return (
            <Badge className="mx-1" pill variant="secondary" key={pl._id.toString()}>
              {pl.name}
            </Badge>
          );
        })}
      </div>
    );
  };

  /**
   * Renders the capabilities of the given manufacturer.
   * @param manufacturer: Manufacturer whose capabilities should be rendered
   * @returns { JSX.Element } Row containing capabilities
   */
  renderCapabilities = (manufacturer: ManufacturersDocument) => {
    return (
      <div className="row">
        {manufacturer.capsules && (
          <Badge className="mx-1" pill variant="secondary">
            Capsules
          </Badge>
        )}
        {manufacturer.liquids && (
          <Badge className="mx-1" pill variant="secondary">
            Liquids
          </Badge>
        )}
        {manufacturer.powder && (
          <Badge className="mx-1" pill variant="secondary">
            Powder
          </Badge>
        )}
        {manufacturer.tablets && (
          <Badge className="mx-1" pill variant="secondary">
            Tablets
          </Badge>
        )}
      </div>
    );
  };

  render() {
    const { history } = this.props;
    const { manufacturersShown } = this.state;
    const { userdata } = this.context;
    return (
      <div className="kt-portlet kt-portlet--mobile h-100">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand fa fa-file" />
            </span>
            <h3 className="kt-portlet__head-title">Manufacturers</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <a
                onClick={() => {
                  history.goBack();
                }}
                className="btn btn-clean kt-margin-r-10"
              >
                <i className="la la-arrow-left" />
                <span className="kt-hidden-mobile">Back</span>
              </a>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div
            className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded px-4"
            id="column_rendering"
          >
            <Table>
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>Name</th>
                  <th style={{ width: "10%" }}>City</th>
                  <th style={{ width: "10%" }}>Country</th>
                  <th style={{ width: "20%" }}>Responsible</th>
                  <th style={{ width: "20%" }}>Production Lines</th>
                  <th style={{ width: "20%" }}>Capabilities</th>
                </tr>
              </thead>
              <tbody className="kt-datatable__body" style={{}}>
                {manufacturersShown.map(m => {
                  const responsible = userdata.find(u => u._id.toString() === m.person.toString())!;
                  return (
                    <tr
                      key={m._id.toString()}
                      className="table-hover"
                      onClick={() => history.push("/manufacturer/" + m._id.toString())}
                    >
                      <td className="align-middle h5">
                        <div className="kt-user-card-v2__pic">
                          <div className="kt-badge kt-badge--xl kt-badge--primary mr-2">
                            {m.name.slice(-2).toUpperCase()}
                          </div>
                          {m.name}
                        </div>
                      </td>
                      <td className="align-middle">{m.city}</td>
                      <td className="align-middle">{m.country}</td>
                      <td className="align-middle">
                        <PersonWidget person={responsible} />
                      </td>
                      <td className="align-middle">
                        {m.productionLines ? this.renderProductionLines(m.productionLines) : "-"}
                      </td>
                      <td className="align-middle">{this.renderCapabilities(m)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Manufacturers);
