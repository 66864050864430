import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { toAbsoluteUrl } from "../../../_metronic";
import UploadCommodityFile from "../UploadCommodityFile";
import DeleteCommodityDocumentModal from "../modals/DeleteCommodityDocumentModal";
import { DataContext } from "../../../context/dataContext";
import { CommoditiesDocument } from "../../../model/commodities.types";
import { COMMODITIES } from "../../../services/dbService";
import dbCommodityService from "../../../services/dbServices/dbCommodityService";
import dateUtils from "../../../utils/dateUtils";
import fileUtils from "../../../utils/fileUtils";
import toastUtils from "../../../utils/toastUtils";

interface DocumentsProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
}

interface DocumentsState {}

class CommodityDocuments extends PureComponent<DocumentsProps, DocumentsState> {
  /**
   * Resolves the supplier name that is related to the specification.
   * @param _id: ID of the supplier
   * @returns { string } Name of the supplier or "unknown" if it could not be found
   */
  resolveDocumentSupplier = (_id: BSON.ObjectId) => {
    const { suppliers } = this.props.context;
    const sup = suppliers.find(s => s._id.toString() === _id.toString());
    if (sup) return sup.name;
    return "unknown";
  };

  /**
   * Handles the upload of a new file.
   * @param id: ID of the supplier that is related to the file
   * @param path: Path to the file
   * @param type: Type of the file (Specification, Flow Chart etc.)
   */
  handleAddUpload = async (id: BSON.ObjectId, path: string, type: string) => {
    const { commodity, context } = this.props;
    const { updateDocumentInContext } = context;
    const spec = { id, type, path, date: new Date() };
    const fileType = fileUtils.getDisplayableText(type);
    const res = await dbCommodityService.addSpecification(commodity._id, spec);
    await toastUtils.databaseOperationToast(
      !!res && res.modifiedCount > 0,
      fileType + " uploaded successfully",
      "Error uploading " + fileType,
      () => updateDocumentInContext(COMMODITIES, commodity._id)
    );
  };

  render() {
    const { commodity, context } = this.props;
    return (
      <div className="kt-notification">
        <div className="kt-widget4">
          <UploadCommodityFile addUpload={this.handleAddUpload} commodity={commodity} />
          {commodity.specifications.length > 0 &&
            commodity.specifications
              .sort((s1, s2) => s1.date.getTime() - s2.date.getTime())
              .map((item, key) => (
                <div key={item.path + item.date.toString() + key} className="kt-widget4__item">
                  <div className="kt-widget4__pic kt-widget4__pic--icon">
                    <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />
                  </div>
                  <div className="kt-widget4__info">
                    <div>
                      <a
                        href={item.path}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="kt-widget4__username mr-2"
                      >
                        {fileUtils.getDisplayableText(item.type)} - {this.resolveDocumentSupplier(item.id)}
                      </a>
                    </div>
                    <p className="kt-widget4__text">{dateUtils.getTimeAgo(item.date)}</p>
                  </div>
                  <DeleteCommodityDocumentModal commodity={commodity} context={context} specification={item} />
                </div>
              ))}
        </div>
      </div>
    );
  }
}

export default CommodityDocuments;
