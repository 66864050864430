import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "../../../translations/i18n";
import { useWarehouseDispatch, WarehouseActionType } from "../../../context/warehouseContext";
import { useDataContext } from "../../../context/dataContext";
import { ActionTrigger, BaseActionModalProps } from "../../../model/warehouse/common.types";
import { Reservation, ReservationState } from "../../../model/warehouse/reservation.types";
import { DEFAULTWEIGHTUNIT } from "../../../utils/warehouseUtils";
import baseUtils, { formatNumValue } from "../../../utils/baseUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import BaseListing from "../../listings/BaseListing";
import { paginate } from "../../common/Pagination";
import dbService, { RESERVATION } from "../../../services/dbService";
import { WarehouseActionNumber } from "../../../utils/warehouseActionUtils";
import { CommoditiesDocument } from "../../../model/commodities.types";
import SplashScreen from "../../common/SplashScreen";

enum CancelMode {
  CANCEL,
  REASSIGN
}

interface CancelReservationModalState {
  saving: boolean;
  material: CommoditiesDocument | undefined;
  relevantReservations: Array<Reservation>;
  selectedReservations: Array<string>;
}

const getDefaultState = (): CancelReservationModalState => {
  return {
    saving: false,
    material: undefined,
    relevantReservations: [],
    selectedReservations: []
  };
};

const CancelReservationModal: React.FC<BaseActionModalProps> = ({ show, actionTrigger, onHide }) => {
  const dataContext = useDataContext();
  const dispatch = useWarehouseDispatch();
  const { commodities, reservation, updateDocumentInContext } = dataContext;

  const [state, setState] = useState(getDefaultState());
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    let relevantReservations: Array<Reservation> = [];
    let selectedReservations: Array<string> = [];
    if (!show || !actionTrigger?.materialId) return;
    const material = commodities.find(c => c._id.toString() === actionTrigger?.materialId);
    if (material) {
      relevantReservations = reservation.filter(
        r =>
          (!actionTrigger?.reservationId || actionTrigger.reservationId === r._id.toString()) &&
          r.state === ReservationState.OPEN &&
          r.materials.some(
            m =>
              m.material.details._id.toString() === material._id.toString() &&
              (!actionTrigger.locationId || m.warehouseSnapshot._id.toString() === actionTrigger.locationId)
          )
      );
      selectedReservations = Array.from(new Set(relevantReservations.map(r => r._id.toString())));
    }

    setState(prevState => {
      return {
        ...prevState,
        material,
        relevantReservations,
        selectedReservations
      };
    });
  }, [show, commodities, reservation, actionTrigger]);

  const errors = useMemo(() => {
    const errors = new Set<string>();
    if (state.selectedReservations.length === 0) {
      errors.add(i18n.t("warehouse:reservationNoOrderSelectedError"));
    }
    return Array.from(errors);
  }, [state.selectedReservations]);

  const handleHide = () => {
    if (state.saving) return;
    onHide();
  };

  const handleChangePage = useCallback(currentPage => setCurrentPage(currentPage), []);
  const handleChangePageSize = useCallback(pageSize => setPageSize(pageSize), []);

  const handleCheckReservation = useCallback(
    (reservationId: string) => {
      let selectedReservations = _.cloneDeep(state.selectedReservations);
      const existingSelection = selectedReservations.indexOf(reservationId);
      if (existingSelection !== -1) {
        selectedReservations = selectedReservations.filter(r => r !== reservationId);
      } else {
        selectedReservations.push(reservationId);
      }
      setState(prevState => {
        return {
          ...prevState,
          selectedReservations
        };
      });
    },
    [state.selectedReservations]
  );

  const handleCancelReservation = useCallback(
    async (mode: CancelMode) => {
      const { selectedReservations, material, relevantReservations } = state;
      if (selectedReservations.length === 0 || !material) return;
      setState(prevState => {
        return { ...prevState, saving: true };
      });
      try {
        const result = await dbService.callFunction<boolean>(
          "cancelReservations",
          [selectedReservations, material._id.toString(), actionTrigger?.locationId],
          true
        );
        if (result) {
          const updates = selectedReservations.map(r => updateDocumentInContext(RESERVATION, r));
          await Promise.all(updates);
          toast.success(i18n.t("warehouse:reservationCancellationSuccess"));
          onHide();
          if (mode === CancelMode.REASSIGN) {
            const payload: ActionTrigger = {
              actionNumber: WarehouseActionNumber.CREATE_RESERVATION,
              orderId: relevantReservations.length === 1 ? relevantReservations[0].order._id.toString() : undefined,
              materialId: material._id.toString()
            };
            dispatch({
              type: WarehouseActionType.TRIGGER_ACTION,
              payload: payload
            });
          }
        } else {
          toast.error(i18n.t("warehouse:reservationCancellationFailure"));
        }
      } finally {
        setState(prevState => {
          return { ...prevState, saving: false };
        });
      }
    },
    [state.material, state.selectedReservations, state.relevantReservations, actionTrigger]
  );

  const tableHeader = [{ title: "#", size: 1 }, { title: i18n.t("common:order") }, { title: i18n.t("common:amount") }];

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>{i18n.t("warehouse:deleteReservation")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          <h4 className="mb-3 font-weight-bold text-black">{i18n.t("warehouse:cancelReservationConfirmation")}</h4>
          <div className="alert alert-warning" role="alert">
            <div className="alert-icon">
              <i className="flaticon-warning"></i>
            </div>
            <div className="alert-text">{i18n.t("warehouse:cancelReservationOrderWarning")}</div>
          </div>
          {state.saving ? (
            <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
          ) : state.material ? (
            <>
              <hr className="w-100" />
              <BaseListing
                headerDefinition={tableHeader}
                documents={state.relevantReservations}
                bodyContent={
                  <>
                    {paginate(state.relevantReservations, currentPage, pageSize).map(reservation => (
                      <CancelReservationRow
                        key={reservation._id.toString()}
                        materialId={state.material!._id.toString()}
                        locationId={actionTrigger?.locationId}
                        reservation={reservation}
                        isSelected={state.selectedReservations.includes(reservation._id.toString())}
                        onCheckReservation={handleCheckReservation}
                      />
                    ))}
                  </>
                }
                currentPage={currentPage}
                pageSize={pageSize}
                onPageChange={handleChangePage}
                onPageSizeChange={handleChangePageSize}
              />
            </>
          ) : (
            <div>{i18n.t("warehouse:noReservationForSelectionFound")}</div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ErrorOverlayButton
          buttonText={i18n.t("common:close")}
          className={"btn btn-secondary"}
          saving={state.saving}
          onClick={onHide}
        />
        <ErrorOverlayButton
          buttonText={i18n.t("warehouse:reassign")}
          className="btn btn-success"
          errors={errors}
          saving={state.saving}
          onClick={() => handleCancelReservation(CancelMode.REASSIGN)}
        />
        <ErrorOverlayButton
          buttonText={i18n.t("warehouse:cancelReservation")}
          className="btn btn-danger"
          errors={errors}
          saving={state.saving}
          onClick={() => handleCancelReservation(CancelMode.CANCEL)}
        />
      </Modal.Footer>
    </Modal>
  );
};

interface CancelReservationRowProps {
  materialId: string;
  locationId: string | undefined;
  reservation: Reservation;
  isSelected: boolean;
  onCheckReservation: (reservationId: string) => void;
}

const CancelReservationRow: React.FC<CancelReservationRowProps> = ({
  materialId,
  locationId,
  reservation,
  isSelected,
  onCheckReservation
}) => {
  const reservationMaterials = useMemo(() => {
    return reservation.materials.filter(
      m =>
        m.material.details._id.toString() === materialId &&
        (!locationId || m.warehouseSnapshot._id.toString() === locationId)
    );
  }, [reservation, materialId, locationId]);

  const reservedAmount = useMemo(() => {
    // Currently assumes every batch amount has the same unit (kg)
    const totalAmount = reservationMaterials.reduce(
      (totalAmount, material) => totalAmount + material.reservedAmount.value,
      0
    );
    return { value: totalAmount, unit: DEFAULTWEIGHTUNIT };
  }, [reservationMaterials]);

  return (
    <tr className={"kt-datatable__row d-table-row  "}>
      <td className="kt-datatable__cell d-table-cell ">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => onCheckReservation(reservation._id.toString())}
              className="ml-0 kt-checkbox--solid"
            />
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details  text-black kt-font-bold">
            {`AT-${reservation.order.identifier} ${baseUtils.truncateString(reservation.order.title, 25)}`}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details  text-black kt-font-bold">{formatNumValue(reservedAmount)}</div>
        </div>
      </td>
    </tr>
  );
};

export default CancelReservationModal;
