export interface PohodaEntry {
  internalCode: string;
  lot: string;
  name: string;
}

/**
 * Try to parse a given csv to pohoda entries.
 * @param csv: Initial parsed csv
 * @returns {false | Array<PohodaEntry>} false for failed pohoda mapping, otherwise pohoda entry set as objects
 */
export function parsePohodaCSV(csv: Array<Array<string>>) {
  // parsing csv
  try {
    let parsedPohodaEntries: Array<PohodaEntry> = [];
    let growingParsingEntityLength = 0;

    csv.forEach((parsedCSVEntity, index) => {
      // ignore header (index === 0), #### marks a new entry
      if (index !== 0 && parsedCSVEntity[0] === "0") {
        // parsedCSVEntity[0] === "0"  => new pohoda entry
        growingParsingEntityLength = parsedPohodaEntries.push({
          lot: parsedCSVEntity[1] !== "" ? parsedCSVEntity[1].trim() : "lot not specified!", // Evidenční č
          internalCode: parsedCSVEntity[2].trim(), // Kód zásoby
          name: parsedCSVEntity[3].trim() // Název
        } as PohodaEntry);
      } else if (index !== 0 && parsedCSVEntity[0] === "") {
        // parsedCSVEntity[0] === "" => marking a new line with additional name descriptions
        const relatedPohodaEntry = parsedPohodaEntries[growingParsingEntityLength - 1];
        relatedPohodaEntry.name += parsedCSVEntity[3];
      }
    });

    // pohoda list can contain empty lots
    if (parsedPohodaEntries.some(entity => !entity.lot || !entity.internalCode || isNaN(Number(entity.internalCode))))
      return false;
    return parsedPohodaEntries;
  } catch (e) {
    return false;
  }
}

export default {
  parsePohodaCSV
};
