import React, { PureComponent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { UserdataDocument } from "../../model/userdata.types";

interface SelectEmployeeProps {
  selectedUser: UserdataDocument | undefined;
  userdata: Array<UserdataDocument>;
  onSelectUser: (owner: UserdataDocument | undefined) => void;
}

interface SelectEmployeeState {
  employees: Array<UserdataDocument>;
}

class SelectEmployee extends PureComponent<SelectEmployeeProps, SelectEmployeeState> {
  constructor(props: SelectEmployeeProps) {
    super(props);
    this.state = {
      employees: props.userdata.filter(data => data.company_id === "internal")
    };
  }

  componentDidUpdate(
    prevProps: Readonly<SelectEmployeeProps>,
    prevState: Readonly<SelectEmployeeState>,
    snapshot?: any
  ) {
    if (prevProps.userdata !== this.props.userdata)
      this.setState({ employees: this.props.userdata.filter(data => data.company_id === "internal") });
  }

  render() {
    const { selectedUser, onSelectUser } = this.props;
    const { employees } = this.state;
    return selectedUser ? (
      <div className="kt-section__content d-flex flex-wrap">
        <span className="kt-media kt-media--circle kt-margin-r-5 kt-margin-t-5">
          <img src={selectedUser.img_url} alt="image" />
        </span>
        <span className="kt-badge kt-badge--light pos-selectEmployee" onClick={() => onSelectUser(undefined)}>
          <i className="fas fa-times" />
        </span>
        <div className="kt-widget4__info mt-3 ml-1">
          <a href="#" className="kt-widget4__username">
            {selectedUser.prename + " " + selectedUser.surname}
          </a>
          <p className="kt-widget4__text">{selectedUser.position}</p>
        </div>
      </div>
    ) : (
      <div className="kt-section__content d-flex flex-wrap ">
        {employees.map(user => (
          <React.Fragment key={user._id.toString()}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="userInfo">{user.prename + " " + user.surname}</Tooltip>}
            >
              <span
                id={user._id.toString()}
                className="kt-media kt-media--circle kt-margin-r-5 kt-margin-t-5 selectuser"
                onClick={() => {
                  onSelectUser(user);
                }}
              >
                <img src={user.img_url} alt={user.prename + "\n" + user.surname} />
              </span>
            </OverlayTrigger>
          </React.Fragment>
        ))}
      </div>
    );
  }
}

export default SelectEmployee;
