import { toSVG } from "bwip-js";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { CompaniesAddress, CompaniesDocument } from "../../model/companies.types";
import { SuppliersDocument } from "../../model/suppliers.types";
import { ManufacturersDocument } from "../../model/manufacturers.types";
import ErrorOverlayButton from "../common/ErrorOverlayButton";
import {
  AvisDescriptionOption,
  AvisInformation,
  AvisRow,
  getDefaultAvisRow,
  OrderWithMaterial
} from "../../utils/avisUtils";
import AvisShipperInformation from "./AvisShipperInformation";
import orderUtils from "../../utils/orderUtils";
import AvisShipmentOverview from "./AvisShipmentOverview";
import AvisShipmentCode from "./AvisShipmentCode";
import {
  AvisType,
  DeliveryAnnouncement,
  Notification,
  NotificationOrderType,
  NotificationState
} from "../../model/warehouse/deliveryAnnouncement.types";
import manufacturerUtils from "../../utils/manufacturerUtils";
import { CommoditiesDocument } from "../../model/commodities.types";
import { PackagingsDocument } from "../../model/packagings.types";
import packagingUtils, { isPackaging } from "../../utils/packagingUtils";
import { SenderType } from "../../model/warehouse/batch.types";
import dbService from "../../services/dbService";

enum AvisStep {
  START,
  SHIPMENT_LIST,
  SHIPMENT_CODE
}

interface AvisState {
  step: AvisStep;
  role: SenderType | null;
  accessCode: Array<string>;
  company: CompaniesDocument | SuppliersDocument | ManufacturersDocument | null | undefined;
  descriptionOptions: Array<AvisDescriptionOption>;
  rows: Array<AvisRow>;
  shipmentCode: string;
}

const getDefaultState = (): AvisState => {
  return {
    step: AvisStep.START,
    role: null,
    rows: [],
    company: undefined,
    descriptionOptions: [],
    accessCode: ["", "", "", ""],
    shipmentCode: ""
  };
};

const Avis: React.FC = () => {
  const [state, setState] = useState<AvisState>(getDefaultState());
  const [saving, setSaving] = useState<boolean>(false);
  const { step, rows, role, descriptionOptions, company, accessCode, shipmentCode } = state;

  const handleChangeCode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const idx = Number(name.split("AC")[1]);
      const aC = [...accessCode];
      aC[idx] = value.toUpperCase();
      setState(prevState => ({ ...prevState, accessCode: aC, company: undefined, descriptionOptions: [] }));
      if (value) {
        if (idx < 3) document.getElementById(`AC${idx + 1}`)?.focus();
      } else if (idx > 0) document.getElementById(`AC${idx - 1}`)?.focus();
    },
    [accessCode]
  );

  const handleChangeRole = useCallback((role: SenderType | null) => {
    setState(prevState => ({
      ...prevState,
      role,
      company: undefined,
      accessCode: ["", "", "", ""],
      descriptionOptions: [],
      rows: [getDefaultAvisRow()]
    }));
  }, []);

  const handleChangeData = useCallback(
    (
      company: CompaniesDocument | SuppliersDocument | ManufacturersDocument | null,
      orders: Array<OrderWithMaterial> | null,
      shipmentCode: string
    ) => {
      const descriptionOptions = orders
        ? orders.map(o => ({
            name: `${orderUtils.isOrder(o.order) ? "AT" : "AN"}-${o.order.identifier} ${o.order.title}`,
            id: o.order._id.toString(),
            order: o
          }))
        : [];
      setState(prevState => ({
        ...prevState,
        company,
        descriptionOptions,
        shipmentCode
      }));
    },
    []
  );

  const handleChangeRow = useCallback(
    (
      id: BSON.ObjectId,
      field: string,
      value: string | OrderWithMaterial | CommoditiesDocument | PackagingsDocument
    ) => {
      const rowsNew = [...rows];
      const row = rowsNew.find(r => r._id.toString() === id.toString());
      if (row) {
        if (["descriptionOrder", "descriptionArticle"].includes(field) && typeof value !== "string") {
          if ("order" in value) {
            row.descriptionOrder = `${orderUtils.isOrder(value.order) ? "AT" : "AN"}-${value.order.identifier} ${
              value.order.title
            }`;
            row.order = value;
          } else {
            row.descriptionArticle =
              "packaging_type" in value ? packagingUtils.getPackagingDescription(value.packaging_type) : value.title.en;
            row.articleSelected = value;
          }
        } else {
          _.set(row, field, value);
        }
        setState(prevState => ({ ...prevState, rows: rowsNew }));
      }
    },
    [rows]
  );
  const handlePreviousStep = useCallback(() => setState(prevState => ({ ...prevState, step: step - 1 })), [step]);
  const handleNextStep = useCallback(() => setState(prevState => ({ ...prevState, step: step + 1 })), [step]);

  const handleAddRow = useCallback(() => {
    const rowsNew = [...rows, getDefaultAvisRow()];
    setState(prevState => ({ ...prevState, rows: rowsNew }));
  }, [rows]);

  const handleRemoveRow = useCallback(
    (_id: BSON.ObjectId) => {
      const rowsNew = rows.filter(r => r._id.toString() !== _id.toString());
      setState(prevState => ({ ...prevState, rows: rowsNew }));
    },
    [rows]
  );

  const handleConfirmShipment = useCallback(async () => {
    if (!company || !role) return;
    let address: CompaniesAddress;
    try {
      setSaving(true);
      if (manufacturerUtils.isManufacturer(company))
        address = {
          city: company.city,
          country: company.country,
          zip: "",
          street: "",
          streetnr: ""
        };
      else address = company.address[0];
      const notification: Array<Notification> = [];
      for (let i = 0; i < rows.length; i++) {
        const r = rows[i];
        if (!r.articleSelected || !r.order) continue;
        let type, title, subtitle;
        if (isPackaging(r.articleSelected)) {
          type = AvisType.PACKAGING;
          const packDesc = packagingUtils.getPackagingDescription(r.articleSelected.packaging_type);
          const packInfo = packagingUtils.getShortPackagingInfo(r.articleSelected);
          title = { en: packDesc, de: packDesc };
          subtitle = { en: packInfo, de: packInfo };
        } else {
          type = AvisType.COMMODITY;
          title = r.articleSelected.title;
          subtitle = r.articleSelected.subtitle;
        }
        notification.push({
          _id: new BSON.ObjectId(),
          order: { reference: r.order.order._id.toString(), type: NotificationOrderType.CUSTOMER_ORDER },
          content: {
            type,
            details: { _id: r.articleSelected._id, title, subtitle }
          },
          totalAmount: { value: Number(r.totalAmount), unit: r.units },
          state: NotificationState.CREATED,
          estimatedDeliveryDate: new Date()
        });
      }
      const deliveryAnnouncement: DeliveryAnnouncement = {
        _id: new BSON.ObjectId(),
        shipmentCode,
        created: new Date(),
        files: [],
        lastUpdate: new Date(),
        sender: { _id: company._id, type: role, referenceNumber: accessCode.join(""), name: company.name, address },
        notification
      };
      const res = await dbService.callFunction<boolean>("insertDeliveryAnnouncement", [deliveryAnnouncement], true);
      if (res) {
        toast.success("Delivery announcement successfully added.");
      } else {
        toast.error("Error adding delivery announcement");
      }
      setState(prevState => ({ ...prevState, step: AvisStep.SHIPMENT_CODE }));
    } finally {
      setSaving(false);
    }
  }, [company, role, rows, shipmentCode, accessCode]);

  const handleClickCreateAnother = useCallback(async () => {
    try {
      setSaving(true);
      const { orders, shipmentCode } = await dbService.callFunction<AvisInformation>(
        "checkAvisCodeAndReturnInformation",
        [role, accessCode.join("")],
        true
      );
      if (company && shipmentCode) {
        handleChangeData(company, orders, shipmentCode);
        setState(prevState => ({ ...prevState, step: AvisStep.SHIPMENT_LIST, rows: [getDefaultAvisRow()] }));
      } else setState(getDefaultState());
    } finally {
      setSaving(false);
    }
  }, [role, accessCode, company]);

  const qrCode = useMemo(() => {
    if (!shipmentCode) return "";
    return toSVG({ bcid: "qrcode", text: shipmentCode });
  }, [shipmentCode]);

  const errors = useMemo(() => {
    const errors: Array<string> = [];
    for (let i = 0; i < rows.length; i++) {
      const r = rows[i];
      if (!r.descriptionOrder.trim()) errors.push(`Row ${i + 1} does not contain a related order`);
      if (!r.descriptionArticle.trim()) errors.push(`Row ${i + 1} does not contain a related article`);
      if (r.totalAmount <= 0) errors.push(`Row ${i + 1} does contain an invalid amount`);
    }
    return errors;
  }, [rows]);

  return (
    <>
      <div className="align-self-center non-print-content" style={{ minWidth: "40%", maxWidth: "1000px" }}>
        <div className="container m-2">
          <div className="card">
            <div className="card-header">
              <img src="https://private-label-factory.de/config/assets/img/PLF_logo_bw.png" width="200" alt="PLF" />
            </div>
            <div className="card-body">
              {step === AvisStep.START && (
                <AvisShipperInformation
                  role={role}
                  accessCode={accessCode}
                  company={company}
                  onChangeRole={handleChangeRole}
                  onChangeCode={handleChangeCode}
                  onChangeData={handleChangeData}
                />
              )}
              {step === AvisStep.SHIPMENT_LIST && (
                <AvisShipmentOverview
                  avisRows={rows}
                  descriptionOptions={descriptionOptions}
                  company={company}
                  onChangeRow={handleChangeRow}
                  onAddRow={handleAddRow}
                  onRemoveRow={handleRemoveRow}
                />
              )}
              {step === AvisStep.SHIPMENT_CODE && <AvisShipmentCode shipmentCode={shipmentCode} qrCode={qrCode} />}
              <div className="d-flex justify-content-between border-top mt-5 pt-10">
                <div className="mr-2">
                  {step === AvisStep.SHIPMENT_LIST && (
                    <ErrorOverlayButton
                      saving={saving}
                      buttonText="Previous"
                      className="btn btn-light font-weight-bold text-uppercase px-9 py-4 mt-4"
                      onClick={handlePreviousStep}
                    />
                  )}
                </div>
                <div>
                  {step === AvisStep.START && (
                    <ErrorOverlayButton
                      buttonText="Next Step"
                      className="btn btn-success font-weight-bold text-uppercase px-9 py-4 mt-4"
                      onClick={handleNextStep}
                      disabled={!company}
                    />
                  )}
                  {step === AvisStep.SHIPMENT_LIST && (
                    <ErrorOverlayButton
                      errors={errors}
                      saving={saving}
                      buttonText="Confirm Shipment"
                      className="btn btn-success font-weight-bold text-uppercase px-9 py-4 mt-4"
                      onClick={handleConfirmShipment}
                    />
                  )}
                  {step === AvisStep.SHIPMENT_CODE && (
                    <ErrorOverlayButton
                      saving={saving}
                      buttonText="Create Another"
                      className="btn btn-light font-weight-bold text-uppercase px-9 py-4 mt-4"
                      onClick={handleClickCreateAnother}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="print-content text-center">
        <div style={{ marginBottom: "15rem" }}>
          <h1 className="text-dark-50 font-size-lg text-black" style={{ fontSize: "10rem" }}>
            <b>{shipmentCode}</b>
          </h1>
        </div>
        <div>
          <img src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCode)}`} alt="QRCode" />
        </div>
      </div>
    </>
  );
};

export default Avis;
