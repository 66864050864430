import _ from "lodash";
import React, { PureComponent } from "react";
import { ResponsiveStream } from "@nivo/stream";
import { OrdersCommodityUsage } from "./CustomTypes";
import dateUtils from "../../utils/dateUtils";
import { DataContext } from "../../context/dataContext";

interface OrderAmountChartProps {
  orders: Array<OrdersCommodityUsage>;
  manufacturer: string;
  onChangeManufacturer: (manufacturer: string) => void;
  context: React.ContextType<typeof DataContext>;
}

interface OrderAmountChartState {
  data: Array<{ [key: string]: number }>;
  keys: Array<string>;
}

class OrderAmountChart extends PureComponent<OrderAmountChartProps, OrderAmountChartState> {
  constructor(props: OrderAmountChartProps) {
    super(props);
    this.state = { data: [], keys: [] };
  }

  componentDidMount() {
    this.generateData();
  }

  componentDidUpdate(
    prevProps: Readonly<OrderAmountChartProps>,
    prevState: Readonly<OrderAmountChartState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.orders, this.props.orders) || prevProps.manufacturer !== this.props.manufacturer) {
      this.generateData();
    }
  }

  handleChangeManufacturer = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.onChangeManufacturer(e.target.value);
  };

  /**
   * Generates the data that is needed for the graph.
   */
  generateData = () => {
    const { orders } = this.props;
    const data = [];
    const keys = [];

    // Collect how many customers are mentioned
    for (let i = 0; i < orders.length; i++) {
      const o = orders[i];
      let existing = keys.some(k => k === o.customer.name);
      if (!existing && o.commodityPrice.ordered) {
        keys.push(o.customer.name);
      }
    }

    // Set default values
    for (let i = 0; i < 12; i++) {
      data.push(this.setDefaultValues(keys));
    }

    // Fill in the ordered amount of every customer per time entry
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < orders.length; j++) {
        const o = orders[j];
        if (
          o.commodityPrice.ordered &&
          Math.round(dateUtils.getDaysBetween(o.commodityPrice.ordered, new Date()) / 30) === i
        ) {
          data[i][o.customer.name] += o.amountRaw;
        }
      }
    }
    this.setState({ keys, data: data.reverse() });
  };

  /**
   * Generates a map where the values for every member are set to 0.
   * @param keys: Keys that should be set to 0
   * @returns { { [key: string]: number } } Map with default values
   */
  setDefaultValues = (keys: Array<string>) => {
    let graphValue: { [key: string]: number } = {};
    for (let i = 0; i < keys.length; ++i) graphValue[keys[i]] = 0;
    return graphValue;
  };

  render() {
    const { manufacturer } = this.props;
    const { manufacturers } = this.props.context;
    const { data, keys } = this.state;
    return (
      <>
        <>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-9">
              <span className="h4 text-dark">Commodity Consumption</span>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <select className="form-control" value={manufacturer} onChange={this.handleChangeManufacturer}>
                <option value="all">All Manufacturers</option>
                {manufacturers.map(m => (
                  <option key={m._id.toString()} value={m._id.toString()}>
                    {m.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {data.length > 0 && keys.length > 0 && (
            <div style={{ height: 350, width: "100%" }}>
              <ResponsiveStream
                data={data}
                keys={keys}
                margin={{ top: 50, right: 250, bottom: 50, left: 60 }}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "",
                  legendOffset: -40
                }}
                enableGridX={false}
                enableGridY={true}
                curve="basis"
                offsetType="none"
                colors={{ scheme: "nivo" }}
                fillOpacity={0.85}
                borderColor={{ theme: "background" }}
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "#2c998f",
                    size: 4,
                    padding: 2,
                    stagger: true
                  },
                  {
                    id: "squares",
                    type: "patternSquares",
                    background: "inherit",
                    color: "#e4c912",
                    size: 6,
                    padding: 2,
                    stagger: true
                  }
                ]}
                fill={[
                  {
                    match: {
                      id: "Paul"
                    },
                    id: "dots"
                  },
                  {
                    match: {
                      id: "Marcel"
                    },
                    id: "squares"
                  }
                ]}
                dotSize={8}
                dotColor={{ from: "color" }}
                dotBorderWidth={2}
                dotBorderColor={{ from: "color", modifiers: [["darker", 0.7]] }}
                enableStackTooltip={false}
                legends={[
                  {
                    anchor: "bottom-right",
                    direction: "column",
                    translateX: 130,
                    itemWidth: 120,
                    itemHeight: 15,
                    itemTextColor: "#999999",
                    symbolSize: 11,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000000"
                        }
                      }
                    ]
                  }
                ]}
              />
            </div>
          )}
        </>
      </>
    );
  }
}

export default OrderAmountChart;
