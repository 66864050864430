import _ from "lodash";
import React, { PureComponent } from "react";
import Select from "react-select";
import { BSON } from "realm-web";
import { toAbsoluteUrl } from "../../_metronic";
import { AdditiveDocument } from "../../model/additives.types";
import CreateAdditiveModal from "./modals/CreateAdditiveModal";
import { DataContext } from "../../context/dataContext";

interface SelectAdditivesProps {
  selectedAdditives: Array<AdditiveDocument | "">;
  additives: Array<AdditiveDocument>;
  onAdd: () => void;
  onChange: (idx: number, _id: BSON.ObjectId) => void;
  onDelete: (idx: number) => void;
  disabled?: boolean;
  context: React.ContextType<typeof DataContext>;
  compact?: boolean;
}

interface SelectAdditivesState {}

class SelectAdditives extends PureComponent<SelectAdditivesProps, SelectAdditivesState> {
  getFilteredAdditives = () => {
    const { additives, selectedAdditives } = this.props;
    selectedAdditives.filter(s => s !== "");
    const adSelected = selectedAdditives.map(s => s && s._id.toString());
    return additives.filter(ad => !adSelected.includes(ad._id.toString()));
  };

  render() {
    const { disabled, selectedAdditives, onAdd, onChange, onDelete, compact } = this.props;
    const filteredAdditives = this.getFilteredAdditives();
    const addDisabled = selectedAdditives.some(s => s === "") || disabled;

    return (
      <>
        {selectedAdditives.map((s, idx) =>
          compact ? (
            <div key={s && s._id ? s._id.toString() : ""} className={"form-row mb-2 "}>
              <Select
                className={"select-default col-auto flex-grow-1"}
                options={filteredAdditives.map(item => {
                  return {
                    value: item._id,
                    label: _.upperFirst(item.eNumber) + " | DE: " + item.name.de + " | EN: " + item.name.en
                  };
                })}
                value={
                  s && s._id
                    ? {
                        value: s._id,
                        label: _.upperFirst(s.eNumber) + " | DE: " + s.name.de + " | EN: " + s.name.en
                      }
                    : { value: "", label: "Select E-Number" }
                }
                onChange={(value: any) => onChange(idx, value.value)}
              />
              {!disabled && (
                <button onClick={() => onDelete(idx)} className="btn btn-danger mr-2">
                  <i className="fa fa-trash pr-0" />
                </button>
              )}
            </div>
          ) : (
            <div key={s && s._id ? s._id.toString() : ""} className={"form-group row"}>
              <div className={"col-3 mr-3"}>
                <Select
                  className={"select-default flex-grow-1"}
                  options={filteredAdditives.map(item => {
                    return { value: item._id, label: _.upperFirst(item.eNumber) };
                  })}
                  value={s && s._id ? { value: s._id, label: s.eNumber } : undefined}
                  placeholder={"Select E-Number"}
                  onChange={(value: any) => onChange(idx, value.value)}
                />
              </div>
              <div className={"col-4 mr-3 "}>
                <div className={"input-group"}>
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <img src={toAbsoluteUrl("/media/icons/icon-de.png")} className="country-icon" />
                    </span>
                  </div>
                  <Select
                    className={"select-default border-radius-left-0 flex-grow-1"}
                    options={filteredAdditives.map(item => {
                      return { value: item._id, label: _.upperFirst(item.name.de) };
                    })}
                    value={s && s._id ? { value: s._id, label: s.name.de } : undefined}
                    placeholder={"Select German Name"}
                    onChange={(value: any) => onChange(idx, value.value)}
                  />
                </div>
              </div>
              <div className={"col-4 mr-3"}>
                <div className={"input-group"}>
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <img src={toAbsoluteUrl("/media/icons/icon-en.png")} className="country-icon" />
                    </span>
                  </div>

                  <Select
                    className={"select-default border-radius-left-0 flex-grow-1"}
                    options={filteredAdditives.map(item => {
                      return { value: item._id, label: _.upperFirst(item.name.en) };
                    })}
                    value={s && s._id ? { value: s._id, label: s.name.en } : undefined}
                    placeholder={"Select English Name"}
                    onChange={(value: any) => onChange(idx, value.value)}
                  />
                </div>
              </div>
              {!disabled && (
                <div className=" text-right">
                  <button onClick={() => onDelete(idx)} className="btn btn-danger">
                    <i className="fa fa-trash pr-0" />
                  </button>
                </div>
              )}
            </div>
          )
        )}
        {compact ? (
          <div className={"form-row "}>
            <div className="col-sm">
              <CreateAdditiveModal context={this.props.context} />
            </div>
            <div className=" mr-2">
              {!disabled && (
                <button
                  onClick={addDisabled ? undefined : onAdd}
                  className={"btn btn-success" + (addDisabled ? " disabled" : "")}
                  disabled={addDisabled}
                >
                  <i className="fa fa-plus pr-0" />
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className={"form-group row  pr-0 align-self-end"}>
            <div className=" col text-right">
              <CreateAdditiveModal context={this.props.context} />
            </div>
            <div className=" text-right ml-3">
              {!disabled && (
                <button
                  onClick={addDisabled ? undefined : onAdd}
                  className={"btn btn-success" + (addDisabled ? " disabled" : "")}
                  disabled={addDisabled}
                >
                  <i className="fa fa-plus pr-0" />
                </button>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default SelectAdditives;
