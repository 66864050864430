import _ from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import OrderProgressbar from "./OrderProgressbar";
import { CustomOrder, OrderOptionInformation } from "../../CustomTypes";
import OrderOptionsModal from "../../modals/OrderOptionsModal";
import OrderHelper, { T_CUSTOM, T_SERVICE, T_SOFTGEL } from "../../OrderHelper";
import DeliverCommodityOrderModal from "../../../commodities/modals/DeliverCommodityOrderModal";
import { DataContext } from "../../../../context/dataContext";
import { CommoditiesDocument, StockTransferOrder } from "../../../../model/commodities.types";
import { pricingCommodities } from "../../../../model/orders.types";
import { SuppliersDocument } from "../../../../model/suppliers.types";
import accessUtils, { ACTIONS } from "../../../../utils/accessUtils";
import baseUtils from "../../../../utils/baseUtils";
import calculationUtils from "../../../../utils/calculationUtils";
import dateUtils from "../../../../utils/dateUtils";
import orderUtils from "../../../../utils/orderUtils";
import { T_COMMODITYFROMCUSTOMERDELIVERED, T_COMMODITYFROMSTOCK } from "../../../../utils/timelineUtils";
import userService from "../../../../services/userService";
import dbService, { ORDERS, UpdateAction } from "../../../../services/dbService";
import commodityUtils from "../../../../utils/commodityUtils";
import orderCalculationUtils from "../../../../utils/orderCalculationUtils";

interface OrderCommoditiesOrderProps {
  order: CustomOrder;
  commodities: Array<CommoditiesDocument>;
  suppliers: Array<SuppliersDocument>;
  readOnly?: boolean;
}

interface OrderCommoditiesOrderState {
  selectedCommodity: OrderOptionInformation | null;
}

class OrderCommoditiesOrder extends PureComponent<OrderCommoditiesOrderProps, OrderCommoditiesOrderState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: OrderCommoditiesOrderProps) {
    super(props);
    this.state = {
      selectedCommodity: null
    };
  }

  /**
   * Handle selection of an order to show order options modal
   * @param price the commodity price entry that will be updated
   * @param commodity the commodity document related to the price
   * @param supplier the supplier related to the price
   */
  handleShowOrderOptions = (
    price: pricingCommodities,
    commodity: CommoditiesDocument,
    supplier: SuppliersDocument | "accumulatedstock" | "custom" | "customer" | "ownstock"
  ) => {
    this.setState({ selectedCommodity: { price, commodity, supplier } });
  };

  handleClose = () => this.setState({ selectedCommodity: null });

  handleBookStockPrice = async (price: pricingCommodities) => {
    const { order } = this.props;
    if (price.supplier !== "ownstock" && price.supplier !== "customer") return;
    const pricePost = _.cloneDeep(price);
    const userId = userService.getUserId();
    const currentDate = new Date();
    pricePost.ordered = currentDate;
    pricePost.userOrdered = userId;
    pricePost.delivered = currentDate;
    pricePost.userDelivered = userId;
    const newState = orderUtils.checkOrderStateAfterPriceUpdate(order, pricePost);
    try {
      const timelineEntry = {
        id: new BSON.ObjectId(),
        type: price.supplier === "customer" ? T_COMMODITYFROMCUSTOMERDELIVERED : T_COMMODITYFROMSTOCK,
        date: new Date(),
        person: userService.getUserId(),
        commodity: price._id
      };
      const action: UpdateAction = {
        collection: ORDERS,
        filter: { _id: order._id },
        update: {
          "calculations.0.prices.$[p].ordered": new Date(),
          "calculations.0.prices.$[p].userOrdered": userService.getUserId(),
          "calculations.0.prices.$[p].delivered": new Date(),
          "calculations.0.prices.$[p].userDelivered": userService.getUserId()
        },
        push: {
          timeline: timelineEntry
        },
        arrayFilters: [{ "p._id": price._id }]
      };
      if (newState && newState !== order.state) _.set(action, "update.state", newState);
      const result = await dbService.updatesAsTransaction([action]);
      if (result) {
        toast.success("Commodity successfully booked");
      } else toast.error("Booking failed");
    } catch (e) {
      toast.error("Booking failed. Please try again.");
      console.error("Booking failed:", e);
    }
  };

  render() {
    const { order, commodities, suppliers, readOnly } = this.props;
    const { selectedCommodity } = this.state;
    const totalCommodityUnitPrice = orderCalculationUtils.calculateCommoditiesUnitPrice(order);
    return (
      <>
        {selectedCommodity && (
          <OrderOptionsModal
            order={order}
            selectedCommodity={selectedCommodity}
            context={this.context}
            onClose={this.handleClose}
          />
        )}
        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--subtable kt-datatable--loaded table-responsive">
          <table className="kt-datatable__table d-table ">
            <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
              <tr className="kt-datatable__row d-table-row">
                <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                  <span>Amount</span>
                </th>
                <th className="kt-datatable__cell d-table-cell" style={{ width: "23%" }}>
                  <span>Commodity</span>
                </th>
                {!readOnly && (
                  <th className="kt-datatable__cell d-table-cell" style={{ width: "23%" }}>
                    <span>Supplier</span>
                  </th>
                )}
                <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                  <span>Quantity</span>
                </th>
                <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                  <span>€</span>
                </th>
                <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                  <span>Ordered</span>
                </th>
                <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                  <span>Delivered</span>
                </th>
                {!readOnly && (
                  <th className="kt-datatable__cell d-table-cell text-right" style={{ width: "8%" }}>
                    <span>Action</span>
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
              {order.calculations[0].prices.map(price => {
                const units = order.calculations[0].units;
                const commodity = commodities.find(c => c._id.toString() === price._id.toString());
                if (!commodity) return null;
                let supplier: SuppliersDocument | "accumulatedstock" | "custom" | "customer" | "ownstock" =
                  typeof price.supplier === "string"
                    ? price.supplier
                    : suppliers.find(s => s._id.toString() === price.supplier.toString())!;
                if (!supplier) supplier = baseUtils.getDocFromCollection(this.context.suppliers, price.supplier)!;
                return (
                  <OrderCommodityOrder
                    key={price._id.toString()}
                    order={order}
                    type={order.settings.type}
                    units={units}
                    perUnit={order.settings.perUnit}
                    price={price}
                    commodity={commodity}
                    supplier={supplier}
                    totalCommodityUnitPrice={totalCommodityUnitPrice}
                    onShowOrderOptions={() => this.handleShowOrderOptions(price, commodity, supplier)}
                    readOnly={readOnly}
                    onBookStockPrice={this.handleBookStockPrice}
                    context={this.context}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

interface OrderCommodityOrderProps {
  order: CustomOrder;
  type: string;
  units: number;
  perUnit: number;
  readOnly?: boolean;
  price: pricingCommodities;
  commodity: CommoditiesDocument;
  supplier: SuppliersDocument | "accumulatedstock" | "custom" | "customer" | "ownstock";
  totalCommodityUnitPrice: number;
  onShowOrderOptions: () => void;
  onBookStockPrice: (price: pricingCommodities) => void;
  context: React.ContextType<typeof DataContext>;
}

const OrderCommodityOrder: React.FunctionComponent<OrderCommodityOrderProps> = ({
  order,
  type,
  units,
  perUnit,
  readOnly,
  price,
  commodity,
  supplier,
  totalCommodityUnitPrice,
  onShowOrderOptions,
  onBookStockPrice,
  context
}) => {
  const totalAmount = orderUtils.getTotalAmountWithBuffer(perUnit, units, price.amount, price.buffer, type);
  if (!commodity) return null;
  const co = commodity.orders
    ? commodity.orders.find(comOrd => comOrd.orders.some(o => o.toString() === order._id.toString()))
    : null;
  const canOrder = accessUtils.canPerformAction(ACTIONS.ORDERCOMMODITYORDER);
  const stockTransferOrder: StockTransferOrder | undefined = co?.stockTransferOrders?.find(
    sto => sto.destination.toString() === order.settings.manufacturer._id.toString()
  );
  const activeSTO = Boolean(stockTransferOrder && !stockTransferOrder?.delivered);
  const isOnProductionTransport = Boolean(activeSTO && stockTransferOrder?.created);
  const pricePerProducedUnit = orderCalculationUtils.getCommodityPricePerProducedUnit(
    type,
    price.price,
    price.buffer,
    price.amount,
    perUnit
  );

  return (
    <tr className="kt-datatable__row d-table-row">
      <td className=" kt-datatable__cell d-table-cell">
        <span>
          {[T_CUSTOM, T_SERVICE, T_SOFTGEL].includes(type)
            ? price.amount + " pcs."
            : calculationUtils.formatAmount(price.amount, 2)}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <Link className="kt-user-card-v2__name kt-link" to={"/commodity/" + commodity._id.toString()}>
              {commodity.title.en}
              {commodity.article_number && <span className="text-success"> {commodity.article_number}</span>}
            </Link>
          </div>
        </div>
        <OrderProgressbar price={price} />
      </td>
      {!readOnly && (
        <td className="kt-datatable__cell d-table-cell">
          <span>{orderUtils.getSupplierName(supplier)}</span>
        </td>
      )}
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            {!price.orderquantity ? (
              <span className="kt-user-card-v2__email">
                {[T_CUSTOM, T_SERVICE, T_SOFTGEL].includes(type)
                  ? type === T_SERVICE
                    ? totalAmount + " pcs."
                    : totalAmount / 1000 + " tsd."
                  : calculationUtils.formatAmount(totalAmount, 2)}
              </span>
            ) : (
              <>
                <span className="kt-user-card-v2__name">{OrderHelper.formatAmount(price.orderquantity, type)}</span>
                <span className="kt-user-card-v2__email">
                  {[T_CUSTOM, T_SERVICE, T_SOFTGEL].includes(type)
                    ? type === T_SERVICE
                      ? totalAmount + " pcs."
                      : totalAmount / 1000 + " tsd."
                    : calculationUtils.formatAmount(totalAmount, 2)}{" "}
                  req.
                </span>
              </>
            )}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            {!price.ordered ? (
              <>
                <span className="kt-user-card-v2__email">
                  {price.price ? baseUtils.formatEuro(price.price) + "/kg" : "-"}
                </span>
                <br />
                <span className="kt-user-card-v2__email">
                  {type !== T_SERVICE && pricePerProducedUnit > 0 && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="note">
                          {Math.round((pricePerProducedUnit / totalCommodityUnitPrice) * 100 * 100) / 100}% of total
                          commodity cost per unit
                        </Tooltip>
                      }
                    >
                      <span className="kt-font-muted">{baseUtils.formatEuro(pricePerProducedUnit)}/unit</span>
                    </OverlayTrigger>
                  )}
                </span>
              </>
            ) : (
              <>
                <span className="kt-user-card-v2__name">
                  {price.orderquantity ? baseUtils.formatEuro(price.price * price.orderquantity) : "-"}
                </span>
                <span className="kt-user-card-v2__email">
                  {baseUtils.formatEuro(price.price)}/
                  {[T_CUSTOM, T_SERVICE, T_SOFTGEL].includes(type) ? (type === T_SERVICE ? " pcs." : " tsd.") : "kg"}
                </span>
                <br />
                <span className="kt-user-card-v2__email">
                  {type !== T_SERVICE && pricePerProducedUnit > 0 && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="note">
                          {Math.round((pricePerProducedUnit / totalCommodityUnitPrice) * 100 * 100) / 100}% of total
                          commodity cost per unit
                        </Tooltip>
                      }
                    >
                      <span className="kt-font-muted">{baseUtils.formatEuro(pricePerProducedUnit)}/unit</span>
                    </OverlayTrigger>
                  )}
                </span>
              </>
            )}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            {!price.ordered ? (
              <span className="kt-user-card-v2__email">-</span>
            ) : (
              <>
                <span className="kt-user-card-v2__name">{dateUtils.getTimeAgo(price.ordered)}</span>
                <span className="kt-user-card-v2__email">
                  {price.ordered.toLocaleDateString("de-DE", {
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })}
                </span>
              </>
            )}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            {!price.ordered && !price.delivered ? (
              <span className="kt-user-card-v2__email">-</span>
            ) : price.delivered ? (
              <>
                <span className="kt-user-card-v2__name text-success">
                  {supplier === "ownstock" ? "Stock" : "Delivered"}
                </span>
                <span className="kt-user-card-v2__email">
                  {price.delivered.toLocaleDateString("de-DE", {
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })}
                </span>
              </>
            ) : (
              <>
                {price.viaWarehouse && isOnProductionTransport ? (
                  <span className="kt-user-card-v2__name text-warning">
                    In Transit
                    <br />({commodityUtils.resolveStockUnit(stockTransferOrder!.amount, commodity.type)})
                  </span>
                ) : (
                  price.viaWarehouse &&
                  stockTransferOrder &&
                  !stockTransferOrder.created && (
                    <span className="kt-user-card-v2__name text-warning">
                      Pending
                      <br />({commodityUtils.resolveStockUnit(stockTransferOrder.amount, commodity.type)})
                    </span>
                  )
                )}
                {price.viaWarehouse && !stockTransferOrder && (
                  <span className="kt-user-card-v2__name text-warning">
                    Pending
                    <br />
                    TBD
                  </span>
                )}
                {!price.viaWarehouse && (
                  <span className="kt-user-card-v2__name text-warning">
                    {price.incoterm ? price.incoterm + ", pending" : ""}
                  </span>
                )}
                <span className="kt-user-card-v2__email">
                  {!isOnProductionTransport
                    ? price.eta && price.eta.getTime() !== price.ordered!.getTime()
                      ? price.eta.toLocaleDateString("de-DE", {
                          year: "numeric",
                          month: "long",
                          day: "numeric"
                        })
                      : "TBD"
                    : stockTransferOrder
                    ? baseUtils.formatDate(stockTransferOrder.created!)
                    : "TBD"}
                </span>
              </>
            )}
          </div>
        </div>
      </td>
      {!readOnly && (
        <td className="kt-datatable__cell d-table-cell text-right align-middle">
          {!price.ordered && !price.delivered ? (
            <div className="btn-group">
              {price.supplier === "ownstock" || price.supplier === "customer" ? (
                <button className="btn btn-sm btn-primary kt-font-bold" onClick={() => onBookStockPrice(price)}>
                  Book
                </button>
              ) : canOrder ? (
                <Link
                  to={"/commodity/" + commodity._id.toString() + "/" + order._id.toString()}
                  className="btn btn-sm btn-primary kt-font-bold"
                >
                  Order
                </Link>
              ) : (
                <button className="btn btn-sm btn-primary kt-font-bold disabled" disabled>
                  Order
                </button>
              )}
              <button className="btn btn-sm btn-primary px-2" onClick={onShowOrderOptions}>
                <i className="fa fa-cog px-1" />
              </button>
            </div>
          ) : co &&
            (!co.delivered ||
              (co.stockTransferOrders &&
                co.stockTransferOrders.length > 0 &&
                !co.stockTransferOrders.find(
                  sto => sto.destination.toString() === order.settings.manufacturer._id.toString()
                )?.delivered &&
                co.stockTransferOrders?.find(
                  sto => sto.destination.toString() === order.settings.manufacturer._id.toString()
                )?.created)) ? (
            <DeliverCommodityOrderModal
              commodity={commodity}
              context={context}
              commodityOrder={co}
              order={order}
              buttonCSSClasses="btn btn-sm btn-secondary px-2"
            />
          ) : (
            <button className="btn btn-sm btn-secondary px-2" onClick={onShowOrderOptions}>
              <i className="fa fa-cog px-1" />
            </button>
          )}
        </td>
      )}
    </tr>
  );
};

export default OrderCommoditiesOrder;
