import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { CustomOrder } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import { OrdersDocument } from "../../../model/orders.types";
import { ORDERS } from "../../../services/dbService";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import userService from "../../../services/userService";
import accessUtils, { ACTIONS } from "../../../utils/accessUtils";
import { PRODUCTIONQUEUE } from "../../../utils/orderUtils";
import { T_RETURNPRODUCTIONQUEUE } from "../../../utils/timelineUtils";

interface ReturnToQueueModalProps {
  order: CustomOrder | OrdersDocument;
  variant?: "productionDashboard";
}

interface ReturnToQueueModalState {
  show: boolean;
  reason: string;
}

class ReturnToQueueModal extends PureComponent<ReturnToQueueModalProps, ReturnToQueueModalState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  constructor(props: ReturnToQueueModalProps) {
    super(props);
    this.state = {
      show: false,
      reason: ""
    };
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false, reason: "" });

  handleReturnToQueue = async () => {
    const { order } = this.props;
    const { reason } = this.state;
    const timelineEntry = {
      type: T_RETURNPRODUCTIONQUEUE,
      reason: reason,
      date: new Date(),
      person: userService.getUserId()
    };
    try {
      const result = await dbOrderService.switchState(order._id, PRODUCTIONQUEUE, timelineEntry);
      if (result && result.modifiedCount) {
        toast.success("Order successfully updated");
        this.context.updateDocumentInContext(ORDERS, order._id);
      } else toast.error("Order could not be updated");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    }
    this.handleClose();
  };

  render() {
    const { variant } = this.props;
    const { show, reason } = this.state;
    const canReturnToQueue = accessUtils.canPerformAction(ACTIONS.ORDERRETURNTOQUEUE);
    return (
      <>
        {variant === "productionDashboard" ? (
          <button
            className={"btn btn-secondary btn-xs mr-2" + (canReturnToQueue ? "" : " disabled")}
            onClick={canReturnToQueue ? this.handleShow : undefined}
            disabled={!canReturnToQueue}
          >
            <i className="fa fa-times " />
            Cancel
          </button>
        ) : (
          <button
            className={"btn btn-sm btn-upper btn-danger" + (canReturnToQueue ? "" : " disabled")}
            onClick={canReturnToQueue ? this.handleShow : undefined}
            disabled={!canReturnToQueue}
          >
            Return To Queue
          </button>
        )}
        <Modal show={show} onHide={this.handleClose} size={"sm"} centered name={"returnToQueueModal"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand fa fa-info mr-2" />
              Return to queue
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group form-group-last">
              <label>Please justify the termination</label>
              <textarea
                onChange={e => this.setState({ reason: e.target.value })}
                className="form-control"
                value={reason}
                rows={3}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <button className={"btn btn-secondary"} onClick={this.handleReturnToQueue}>
              Return to queue
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ReturnToQueueModal;
