import React, { PureComponent } from "react";
import { DataContext } from "../../../context/dataContext";
import TransportTypeExporter from "./commodityOrdersToTransportType/TransportTypeExporter";

class CommodityOrderStatistics extends PureComponent {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  render() {
    return <TransportTypeExporter context={this.context} />;
  }
}
export default CommodityOrderStatistics;
