import React, { PureComponent } from "react";
import { DataContext } from "../../context/dataContext";
import { Period } from "../common/CustomTypes";
import SalesProfitTurnoverGraph from "./presentation/SalesProfitTurnoverGraph";
import SalesMarginMissGraph from "./presentation/SalesMarginMissGraph";
import SalesMarginQualityGraph from "./presentation/SalesMarginQualityGraph";
import ACMarginsVsCalculatedMarginsGraph from "./presentation/ACMarginsVsCalculatedMarginsGraph";

interface PresentationDashboardProps {
  context: React.ContextType<typeof DataContext>;
}

interface PresentationDashboardState {}

class PresentationDashboard extends PureComponent<PresentationDashboardProps, PresentationDashboardState> {
  period: Period;

  constructor(props: PresentationDashboardProps) {
    super(props);
    const periodStart = new Date();
    const periodEnd = new Date();
    periodStart.setFullYear(periodStart.getFullYear() - 2);
    this.period = { beginning: periodStart, end: periodEnd };
    this.state = {};
  }

  render() {
    const { context } = this.props;
    return (
      <div className="row">
        <div className="col-12 col-xl-6">
          <SalesProfitTurnoverGraph context={context} period={this.period} />
        </div>
        <div className="col-12 col-xl-6">
          <SalesMarginMissGraph context={context} period={this.period} />
        </div>
        <div className="col-12 col-xl-6">
          <SalesMarginQualityGraph context={context} period={this.period} />
        </div>
        <div className="col-12 col-xl-6">
          <ACMarginsVsCalculatedMarginsGraph context={context} />
        </div>
      </div>
    );
  }
}

export default PresentationDashboard;
