import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { CustomOrder } from "../CustomTypes";
import SplashScreen from "../../common/SplashScreen";
import LanguageSelectionDropdown from "../../common/LanguageSelectionDropdown";
import { DataContext } from "../../../context/dataContext";
import userService from "../../../services/userService";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import orderUtils, { OFFER, REQUESTPENDING } from "../../../utils/orderUtils";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import config from "../../../config/config.json";
import { T_OFFERPDF, T_PRODUCTSPECIFICATION } from "../../../utils/timelineUtils";
import notificationService, { R_ORDERFILEUPLOAD } from "../../../services/notificationService";

interface CreateOfferPDFModalProps {
  order: CustomOrder;
  disabled?: boolean;
  context: React.ContextType<typeof DataContext>;
}

interface CreateOfferPDFModalState {
  show: boolean;
  uploading: boolean;
}

class CreateOfferPDFModal extends PureComponent<CreateOfferPDFModalProps, CreateOfferPDFModalState> {
  constructor(props: CreateOfferPDFModalProps) {
    super(props);
    this.state = {
      show: false,
      uploading: false
    };
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });

  handleUpload = async () => {
    const { order, context } = this.props;
    this.setState({ uploading: true });
    const pdfResult = await pdfUtils.createOfferPDF(order, context);
    const productSpecification = [OFFER, REQUESTPENDING].includes(order.state);
    if (productSpecification) {
      if (!pdfResult.result || !pdfResult.path) {
        toast.error("Product Specification PDF creation failed: " + pdfResult.message);
        this.setState({ uploading: false });
        return;
      }
      window.open(config.mediahubBase + pdfResult.path, "_blank");
      const timelineEntry = {
        id: new BSON.ObjectId(),
        type: T_PRODUCTSPECIFICATION,
        date: new Date(),
        path: pdfResult.path,
        person: userService.getUserId(),
        version: orderUtils.getPDFVersion(order, T_PRODUCTSPECIFICATION),
        offernumber: order.identifier
      };
      try {
        const result = await dbOrderService.pushToTimeline(order._id, timelineEntry);
        // fire and forget
        await dbOrderService.incrementVersion(order._id);
        if (result && result.modifiedCount) {
          toast.success("Product Specification created successfully");
          /* notificationService.notify(R_ORDERFILEUPLOAD, order._id, {
            de: ": Angebotsdokument",
            en: ": Offer document"
          });  TODO AC-381 */
          this.setState({ uploading: false, show: false });
        } else toast.error("Adding of timeline entry failed. " + pdfResult.path);
      } catch (e) {
        toast.error("An unexpected error occurred: " + e.message);
      } finally {
        this.setState({ uploading: false });
      }
    } else {
      if (!pdfResult.result || !pdfResult.path) {
        toast.error("Offer PDF creation failed: " + pdfResult.message);
        this.setState({ uploading: false });
        return;
      }
      window.open(config.mediahubBase + pdfResult.path, "_blank");
      const timelineEntry = {
        id: new BSON.ObjectId(),
        type: T_OFFERPDF,
        date: new Date(),
        path: pdfResult.path,
        person: userService.getUserId(),
        version: orderUtils.getPDFVersion(order, T_OFFERPDF),
        offernumber: order.identifier
      };
      try {
        const result = await dbOrderService.pushToTimeline(order._id, timelineEntry);
        // fire and forget
        await dbOrderService.incrementVersion(order._id);
        if (result && result.modifiedCount) {
          toast.success("Offer PDF created successfully");
          notificationService.notify(R_ORDERFILEUPLOAD, order._id, {
            de: ": Angebotsdokument",
            en: ": Offer document"
          });
          this.setState({ uploading: false, show: false });
        } else toast.error("Adding of timeline entry failed. " + pdfResult.path);
      } catch (e) {
        toast.error("An unexpected error occurred: " + e.message);
      } finally {
        this.setState({ uploading: false });
      }
    }
  };

  render() {
    const { disabled, order } = this.props;
    const { show, uploading } = this.state;
    const productSpecification = [OFFER, REQUESTPENDING].includes(order.state);
    return (
      <>
        <button
          className={"btn btn-label-brand btn-sm btn-bold mr-3 my-1 " + (disabled ? "btn-disabled" : "")}
          onClick={this.handleShow}
          disabled={disabled}
        >
          <i className="flaticon2-list-1" style={{ fontSize: "0.9rem" }} />
          {productSpecification ? "Product Specification" : "Offer PDF"}
        </button>
        <Modal show={show} onHide={this.handleClose} centered name={"CreateOfferPDFModal"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand flaticon2-list-1 mr-2" />
              {productSpecification ? "Create Product Specification" : "Create Offer PDF"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {uploading ? (
              <>
                <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
                <h5 className="text-center text-dark">
                  {productSpecification
                    ? "Generating Product Specification. Please Wait..."
                    : "Generating Offer PDF. Please Wait..."}
                </h5>
              </>
            ) : (
              <>
                <h5 className="text-center text-dark mb-3">
                  {productSpecification
                    ? "Are you sure you want to create a product specification?"
                    : "Are you sure you want to create an offer pdf?"}
                </h5>
                <LanguageSelectionDropdown
                  labelPosition="front"
                  additionalWrapperStyles={{ width: "340px" }}
                  labelColumnClasses={"col-form-label"}
                  labelClasses={"ml-3 kt-font-dark"}
                  wrapperClasses={"form-group row mx-auto mb-2"}
                />
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <button
              className={!uploading ? "btn btn-success" : "btn btn-success disabled"}
              disabled={uploading}
              onClick={this.handleUpload}
            >
              {uploading && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              {productSpecification ? "Create Product Specification" : " Create Offer PDF"}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateOfferPDFModal;
