import React, { PureComponent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import baseUtils from "../../utils/baseUtils";

interface StatProps {
  title: string;
  value: number;
  unit: "euro" | "percent" | "number";
  alternativeSizeClass?: string;
}

interface StatState {}

class Stat extends PureComponent<StatProps, StatState> {
  /**
   * Format euro
   * @param euro amount of euros
   * @returns {string} properly formatted euro string
   */
  formatEuro = (euro: number) => {
    return this.formatNumber(euro) + " €";
  };

  /**
   * Format any number
   * @param amount the amount
   * @returns {string} properly formatted string in tsd., mio.
   */
  formatNumber = (amount: number) => {
    const value = Math.round(amount);
    if (value >= 1000000) return Math.round((value / 1000000) * 100) / 100 + " Mio.";
    else if (value >= 1000) return Math.round((value / 1000) * 100) / 100 + " tsd.";
    else return value;
  };

  render() {
    const { title, unit, value, alternativeSizeClass } = this.props;
    return (
      <div
        className={
          (alternativeSizeClass ? alternativeSizeClass : "col-md-2 ") +
          " kt-margin-b-20-tablet-and-mobile pointer text-center"
        }
      >
        <OverlayTrigger
          overlay={
            <Tooltip id={title}>
              {title +
                ": " +
                (!isNaN(value) && value !== null
                  ? unit === "euro"
                    ? baseUtils.formatEuro(value)
                    : unit === "number"
                    ? value.toLocaleString()
                    : unit === "percent"
                    ? Math.round(value * 10000) / 10000 + "%"
                    : "Unknown unit"
                  : "Not available")}
            </Tooltip>
          }
          placement={"top"}
        >
          <span className="text-dark kt-font-bold mb-0 d-block" style={{ fontSize: "1.5rem" }}>
            {!isNaN(value) && value !== null
              ? unit === "euro"
                ? this.formatEuro(value)
                : unit === "number"
                ? this.formatNumber(value)
                : unit === "percent"
                ? Math.round(value * 100) / 100 + "%"
                : "-"
              : "-"}
          </span>
        </OverlayTrigger>
        <span className="text-muted font-size-sm" style={{ fontWeight: 500 }}>
          {title}
        </span>
      </div>
    );
  }
}

export default Stat;
