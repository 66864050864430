import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { CustomerNotesExtended } from "../common/CustomTypes";
import { CustomerNote } from "../../model/orders.types";
import dbOrderService from "../../services/dbServices/dbOrderService";
import userService from "../../services/userService";
import orderUtils from "../../utils/orderUtils";
import notificationService, { R_ANSWERONORDER } from "../../services/notificationService";

interface ContinueOrderConversationNotesModalProps {
  note: CustomerNotesExtended;
}

interface ContinueOrderConversationNotesModalState {
  show: boolean;
  message: string;
  saving: boolean;
}

class ContinueOrderConversationNotesModal extends PureComponent<
  ContinueOrderConversationNotesModalProps,
  ContinueOrderConversationNotesModalState
> {
  constructor(props: ContinueOrderConversationNotesModalProps) {
    super(props);
    this.state = { show: false, saving: false, message: "" };
  }

  handleShow = () => this.setState({ show: true, message: "" });
  handleHide = () => this.setState({ show: false });

  handleChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ message: e.target.value });
  };

  handleNoteConfirm = async () => {
    const { note } = this.props;
    const { message } = this.state;
    this.setState({ saving: true });
    try {
      const cNote: CustomerNote = {
        _id: new BSON.ObjectId(),
        date: new Date(),
        person: userService.getUserId(),
        note: message
      };
      const res = await dbOrderService.addCustomerNote(note.order._id, cNote);
      if (res && res.modifiedCount > 0) {
        await dbOrderService.markCustomerNoteAsRead(note.order._id, note.note._id);
        toast.success("Answer added successfully");
        notificationService.notify(R_ANSWERONORDER, note.order._id);
        this.setState({ show: false });
      } else {
        toast.error("Error adding answer");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { note } = this.props;
    const { show, message, saving } = this.state;
    const isOrder = orderUtils.isOrder(note.order);
    return (
      <>
        <button className="btn btn-sm btn-success" onClick={this.handleShow}>
          Continue Conversation
        </button>
        <Modal show={show} centered onHide={this.handleHide} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Continue chat on {(isOrder ? "AT-" : "AN-") + note.order.identifier}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-3 col kt-font-dark">Last message:</div>
              <div className="col-md-9 col">
                <textarea
                  className="form-control disabled"
                  style={{ resize: "none" }}
                  disabled
                  value={note.note.note}
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-3 col kt-font-dark">Continue message:</div>
              <div className="col-md-9 col">
                <textarea
                  className={"form-control" + (saving ? " disabled" : "")}
                  onChange={this.handleChangeInput}
                  value={message}
                  disabled={saving}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            <button
              className={"btn btn-sm btn-success" + (saving ? " disabled" : "")}
              onClick={this.handleNoteConfirm}
              disabled={saving}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ContinueOrderConversationNotesModal;
