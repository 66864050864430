import React, { useMemo } from "react";
import { getBatchPackagingUnitDescription } from "../../../../../utils/warehouseUtils";
import { formatNumValue } from "../../../../../utils/baseUtils";
import { BookOutLocation } from "../BookOutOverview";
import i18n from "../../../../../translations/i18n";

interface BookOutOverviewRemoteWarehouseDataProps {
  location: BookOutLocation;
}

export const BookOutOverviewRemoteWarehouseData: React.FC<BookOutOverviewRemoteWarehouseDataProps> = ({ location }) => {
  const { remoteWarehouseData } = location;

  const amountLeft = useMemo(
    () => (remoteWarehouseData ? remoteWarehouseData.amountAtLocation.value - +remoteWarehouseData.amountToBookOut : 0),
    [location.remoteWarehouseData]
  );
  // this case should never appear
  if (!remoteWarehouseData) return null;
  return (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive px-2 mb-0">
      <table className="kt-datatable__table d-table p-5">
        <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
          <tr className="kt-datatable__row d-table-row">
            <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "25%" }}>
              <span>{i18n.t("warehouse:packagingUnits")}</span>
            </th>
            <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "25%" }}>
              <span>{i18n.t("warehouse:amount")}</span>
            </th>
            <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "25%" }}>
              <span>{i18n.t("warehouse:usedAmount")}</span>
            </th>
            <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "25%" }}>
              <span>{i18n.t("warehouse:restAmount")}</span>
            </th>
          </tr>
        </thead>
        <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
          <tr className="kt-datatable__row d-table-row border-0">
            <td className="kt-datatable__cell d-table-cell pb-0">
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details ">
                  <span className="kt-user-card-v2__name text-black ">
                    {getBatchPackagingUnitDescription(remoteWarehouseData.packagingUnits)}
                  </span>
                </div>
              </div>
            </td>
            <td className="kt-datatable__cell d-table-cell pb-0">
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details ">
                  <span className="kt-user-card-v2__name text-black ">
                    {formatNumValue(remoteWarehouseData.amountAtLocation)}
                  </span>
                </div>
              </div>
            </td>
            <td className="kt-datatable__cell d-table-cell pb-0">
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details ">
                  <span className="kt-user-card-v2__name text-black ">
                    {formatNumValue({
                      value: +remoteWarehouseData.amountToBookOut,
                      unit: remoteWarehouseData.amountAtLocation.unit
                    })}
                  </span>
                </div>
              </div>
            </td>
            <td className="kt-datatable__cell d-table-cell pb-0">
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details ">
                  <span className="kt-user-card-v2__name text-black ">
                    {formatNumValue({
                      value: amountLeft,
                      unit: remoteWarehouseData.amountAtLocation.unit
                    })}
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
