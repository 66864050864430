import React from "react";
import {
  BagData,
  BlisterData,
  BottleData,
  BoxData,
  LabelData,
  LidData,
  PipetteData,
  SprayPumpData,
  SleeveData,
  StickerData,
  SpoonData,
  SilicaGelBagData
} from "../CustomTypes";
import {
  COLORS,
  BAG_MATERIALS,
  BAG_SHAPES,
  BAG_ZIPPERS,
  BOTTLE_MATERIALS,
  BOTTLE_NECKS,
  BOTTLE_SHAPES,
  BOX_QUALITIES,
  LABEL_QUALITIES,
  LID_MATERIALS,
  LID_SHAPES,
  LIQUIDBOTTLE_NECKS,
  LIQUIDBOTTLELID_NECKS,
  SLEEVE_SIZES,
  SPRAYPUMP_LENGTH,
  SPRAYPUMP_NORMS,
  STICKER_FORMS,
  StickerForms
} from "../PackagingHelper";

function renderOptions(options: Array<{ value: string; label: string }>) {
  return options.map(o => (
    <option key={o.value} value={o.value}>
      {o.label}
    </option>
  ));
}

interface EditPackagingBaseProps {
  onPackagingDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  disabled: boolean;
}

interface EditPackagingBottleProps extends EditPackagingBaseProps {
  data: BottleData;
  liquid?: boolean;
}

export const EditPackagingBottle: React.FunctionComponent<EditPackagingBottleProps> = ({
  data,
  onPackagingDataChange,
  liquid,
  disabled
}) => {
  return (
    <>
      {!liquid && (
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Shape</label>
          <div className="col-lg-9 col-xl-6">
            <select
              className="form-control"
              name="shape"
              value={data.shape}
              onChange={disabled ? undefined : onPackagingDataChange}
              disabled={disabled}
            >
              {renderOptions(BOTTLE_SHAPES)}
            </select>
          </div>
        </div>
      )}
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Material</label>
        <div className="col-lg-9 col-xl-6">
          <select
            className="form-control"
            name="material"
            value={data.material}
            onChange={disabled ? undefined : onPackagingDataChange}
            disabled={disabled}
          >
            {renderOptions(BOTTLE_MATERIALS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Color</label>
        <div className="col-lg-9 col-xl-6">
          <select
            className="form-control"
            name="color"
            value={data.color}
            onChange={disabled ? undefined : onPackagingDataChange}
            disabled={disabled}
          >
            {renderOptions(COLORS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Volume </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.volume.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Volume"
              name="volume"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.volume}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">ml</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Height</label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.height.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Height"
              name="height"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.height}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Width</label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.width.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Width"
              name="width"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.width}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Label height </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.labelHeight.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="max. Label height"
              name="labelHeight"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.labelHeight}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Neck</label>
        <div className="col-lg-9 col-xl-6">
          <select
            className="form-control"
            name="neck"
            onChange={disabled ? undefined : onPackagingDataChange}
            value={data.neck}
            disabled={disabled}
          >
            {renderOptions(liquid ? LIQUIDBOTTLE_NECKS : BOTTLE_NECKS)}
          </select>
        </div>
      </div>
    </>
  );
};

interface EditPackagingPipetteProps extends EditPackagingBaseProps {
  data: PipetteData;
}

export const EditPackagingPipette: React.FunctionComponent<EditPackagingPipetteProps> = ({
  data,
  onPackagingDataChange,
  disabled
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Height </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.height.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Length in bottle"
              name="height"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.height}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Color </label>
        <div className="col-lg-9 col-xl-6">
          <select
            className={"form-control"}
            name="color"
            onChange={disabled ? undefined : onPackagingDataChange}
            value={data.color}
            disabled={disabled}
          >
            {renderOptions(COLORS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Neck </label>
        <div className="col-lg-9 col-xl-6">
          <select
            className={"form-control"}
            name="neck"
            onChange={disabled ? undefined : onPackagingDataChange}
            value={data.neck}
            disabled={disabled}
          >
            {renderOptions(LIQUIDBOTTLE_NECKS)}
          </select>
        </div>
      </div>
    </div>
  );
};

interface EditPackagingSprayPumpProps extends EditPackagingBaseProps {
  data: SprayPumpData;
}

export const EditPackagingSprayPump: React.FunctionComponent<EditPackagingSprayPumpProps> = ({
  data,
  onPackagingDataChange,
  disabled
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Color </label>
        <div className="col-lg-9 col-xl-6">
          <select
            className={"form-control"}
            name="color"
            onChange={disabled ? undefined : onPackagingDataChange}
            value={data.color}
            disabled={disabled}
          >
            {renderOptions(COLORS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Norm </label>
        <div className="col-lg-9 col-xl-6">
          <select
            className={"form-control"}
            name="norm"
            onChange={disabled ? undefined : onPackagingDataChange}
            value={data.norm}
            disabled={disabled}
          >
            {renderOptions(SPRAYPUMP_NORMS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Height </label>
        <div className="col-lg-9 col-xl-6">
          <select
            className={"form-control"}
            name="height"
            onChange={disabled ? undefined : onPackagingDataChange}
            value={data.height}
            disabled={disabled}
          >
            {renderOptions(SPRAYPUMP_LENGTH)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Neck </label>
        <div className="col-lg-9 col-xl-6">
          <select
            className={"form-control"}
            name="neck"
            onChange={disabled ? undefined : onPackagingDataChange}
            value={data.neck}
            disabled={disabled}
          >
            {renderOptions(LIQUIDBOTTLE_NECKS)}
          </select>
        </div>
      </div>
    </div>
  );
};

interface EditPackagingLidProps extends EditPackagingBaseProps {
  data: LidData;
}

export const EditPackagingLid: React.FunctionComponent<EditPackagingLidProps> = ({
  data,
  onPackagingDataChange,
  disabled
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Shape </label>
        <div className="col-lg-9 col-xl-6">
          <select
            className={"form-control"}
            name="shape"
            onChange={disabled ? undefined : onPackagingDataChange}
            value={data.shape}
            disabled={disabled}
          >
            {renderOptions(LID_SHAPES)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Material </label>
        <div className="col-lg-9 col-xl-6">
          <select
            className={"form-control"}
            name="material"
            onChange={disabled ? undefined : onPackagingDataChange}
            value={data.material}
            disabled={disabled}
          >
            {renderOptions(LID_MATERIALS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Color </label>
        <div className="col-lg-9 col-xl-6">
          <select
            className={"form-control"}
            name="color"
            onChange={disabled ? undefined : onPackagingDataChange}
            value={data.color}
            disabled={disabled}
          >
            {renderOptions(COLORS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Size </label>
        <div className="col-lg-9 col-xl-6">
          <select
            className={"form-control"}
            name="size"
            onChange={disabled ? undefined : onPackagingDataChange}
            value={data.size}
            disabled={disabled}
          >
            {renderOptions(LIQUIDBOTTLELID_NECKS)}
          </select>
        </div>
      </div>
    </div>
  );
};

interface EditPackagingBagProps extends EditPackagingBaseProps {
  data: BagData;
}

export const EditPackagingBag: React.FunctionComponent<EditPackagingBagProps> = ({
  data,
  onPackagingDataChange,
  disabled
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Shape </label>
        <div className="col-lg-9 col-xl-6">
          <select
            className={"form-control"}
            name="shape"
            onChange={disabled ? undefined : onPackagingDataChange}
            value={data.shape}
            disabled={disabled}
          >
            {renderOptions(BAG_SHAPES)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Material </label>
        <div className="col-lg-9 col-xl-6">
          <select
            className={"form-control"}
            name="material"
            onChange={disabled ? undefined : onPackagingDataChange}
            value={data.material}
            disabled={disabled}
          >
            {renderOptions(BAG_MATERIALS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Zipper </label>
        <div className="col-lg-9 col-xl-6">
          <select
            className={"form-control"}
            name="zipper"
            onChange={disabled ? undefined : onPackagingDataChange}
            value={data.zipper}
            disabled={disabled}
          >
            {renderOptions(BAG_ZIPPERS)}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Volume </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.volume.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Volume"
              name="volume"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.volume}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">ml</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Height </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.height.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Height"
              name="height"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.height}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Width </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.width.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Width"
              name="width"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.width}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Color </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <select
              className={"form-control"}
              name="color"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.color}
              disabled={disabled}
            >
              {renderOptions(COLORS)}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

interface EditPackagingBlisterProps extends EditPackagingBaseProps {
  data: BlisterData;
}

export const EditPackagingBlister: React.FunctionComponent<EditPackagingBlisterProps> = ({
  data,
  onPackagingDataChange,
  disabled
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Depth </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.depth.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Depth"
              name="depth"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.depth}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Height </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.height.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Height"
              name="height"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.height}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Width </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.width.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Width"
              name="width"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.width}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Capsules </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.capsules.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Capsules"
              name="capsules"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.capsules}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">pcs</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface EditPackagingBoxProps extends EditPackagingBaseProps {
  data: BoxData;
}

export const EditPackagingBox: React.FunctionComponent<EditPackagingBoxProps> = ({
  data,
  onPackagingDataChange,
  disabled
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Height </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.height.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Height"
              name="height"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.height}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Width </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.width.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Width"
              name="width"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.width}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Depth </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.depth.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Depth"
              name="depth"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.depth}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Quality </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <select
              className={"form-control"}
              name="quality"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.quality}
              disabled={disabled}
            >
              {renderOptions(BOX_QUALITIES)}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

interface EditPackagingLabelProps extends EditPackagingBaseProps {
  data: LabelData;
}

export const EditPackagingLabel: React.FunctionComponent<EditPackagingLabelProps> = ({
  data,
  onPackagingDataChange,
  disabled
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Height </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.height.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Height"
              name="height"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.height}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Width </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.width.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Width"
              name="width"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.width}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Quality </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <select
              className={"form-control"}
              name="quality"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.quality}
              disabled={disabled}
            >
              {renderOptions(LABEL_QUALITIES)}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

interface EditPackagingSleeveProps extends EditPackagingBaseProps {
  data: SleeveData;
}

export const EditPackagingSleeve: React.FunctionComponent<EditPackagingSleeveProps> = ({
  data,
  onPackagingDataChange,
  disabled
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Size </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <select
              className={"form-control"}
              name="size"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.size}
              disabled={disabled}
            >
              {renderOptions(SLEEVE_SIZES)}
            </select>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Printed </label>
        <div className="col-lg-9 col-xl-6 ">
          <div className="custom-control custom-switch custom-switch-lg">
            <input
              type="checkbox"
              className="custom-control-input"
              id="printCheckbox"
              name="print"
              checked={data.print}
              onChange={disabled ? undefined : onPackagingDataChange}
              disabled={disabled}
            />
            <label className="custom-control-label align-middle mb-3" htmlFor="printCheckbox" />
          </div>
        </div>
      </div>
    </div>
  );
};

interface EditPackagingStickerProps extends EditPackagingBaseProps {
  data: StickerData;
}

export const EditPackagingSticker: React.FunctionComponent<EditPackagingStickerProps> = ({
  data,
  onPackagingDataChange,
  disabled
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Form</label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <select
              className={"form-control"}
              name="form"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.form}
              disabled={disabled}
            >
              {renderOptions(STICKER_FORMS)}
            </select>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Quality</label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <select
              className={"form-control"}
              name="quality"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.quality}
              disabled={disabled}
            >
              {renderOptions(LABEL_QUALITIES)}
            </select>
          </div>
        </div>
      </div>
      {data.form === StickerForms.ANGULAR ? (
        <>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Height</label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group">
                <input
                  className={"form-control " + (data.height.trim() === "" && "is-invalid")}
                  type="number"
                  min={0}
                  placeholder="Height"
                  name="height"
                  onChange={disabled ? undefined : onPackagingDataChange}
                  value={data.height}
                  disabled={disabled}
                />
                <div className="input-group-append">
                  <span className="input-group-text">mm</span>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Width</label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group">
                <input
                  className={"form-control " + (data.width.trim() === "" && "is-invalid")}
                  type="number"
                  min={0}
                  placeholder="Width"
                  name="width"
                  onChange={disabled ? undefined : onPackagingDataChange}
                  value={data.width}
                  disabled={disabled}
                />
                <div className="input-group-append">
                  <span className="input-group-text">mm</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : data.form === StickerForms.ROUND ? (
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Diameter</label>
          <div className="col-lg-9 col-xl-6">
            <div className="input-group">
              <input
                className={"form-control " + (data.diameter.trim() === "" && "is-invalid")}
                type="number"
                min={0}
                placeholder="Diameter"
                name="diameter"
                onChange={disabled ? undefined : onPackagingDataChange}
                value={data.diameter}
                disabled={disabled}
              />
              <div className="input-group-append">
                <span className="input-group-text">mm</span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

interface EditPackagingSpoonProps extends EditPackagingBaseProps {
  data: SpoonData;
}

export const EditPackagingSpoon: React.FunctionComponent<EditPackagingSpoonProps> = ({
  data,
  onPackagingDataChange,
  disabled
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Capacity</label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.capacity.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Capacity"
              name="capacity"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.capacity}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">g</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Color </label>
        <div className="col-lg-9 col-xl-6">
          <select
            className={"form-control"}
            name="color"
            onChange={disabled ? undefined : onPackagingDataChange}
            value={data.color}
            disabled={disabled}
          >
            {renderOptions(COLORS)}
          </select>
        </div>
      </div>
    </div>
  );
};

interface EditPackagingSilicaGelBagProps extends EditPackagingBaseProps {
  data: SilicaGelBagData;
}

export const EditPackagingSilicaGelBag: React.FunctionComponent<EditPackagingSilicaGelBagProps> = ({
  data,
  onPackagingDataChange,
  disabled
}) => {
  return (
    <div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Capacity</label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group">
            <input
              className={"form-control " + (data.weight.trim() === "" && "is-invalid")}
              type="number"
              min={0}
              placeholder="Weight"
              name="weight"
              onChange={disabled ? undefined : onPackagingDataChange}
              value={data.weight}
              disabled={disabled}
            />
            <div className="input-group-append">
              <span className="input-group-text">g</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
