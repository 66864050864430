import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { Modal, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { CustomerData, CustomOrder, OrderConfirmationData, InvoicePosition } from "../CustomTypes";
import OrderHelper from "../OrderHelper";
import CustomerDataComponent from "./common/CustomerData";
import LanguageSelectionDropdown from "../../common/LanguageSelectionDropdown";
import orderUtils from "../../../utils/orderUtils";
import { DataContext } from "../../../context/dataContext";
import { getNumericValue } from "../../../utils/baseUtils";
import dateUtils from "../../../utils/dateUtils";
import orderConfirmationGeneration from "../../../utils/pdf/orderConfirmationGeneration";
import PDFPreview from "../../common/PDFPreview";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import userService from "../../../services/userService";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import config from "../../../config/config.json";
import invoiceUtils from "../../../utils/invoiceUtils";
import PositionRow from "./common/PositionRow";
import { T_OFFERCONFIRMATION } from "../../../utils/timelineUtils";
import notificationService, { R_ORDERFILEUPLOAD } from "../../../services/notificationService";
import i18n from "../../../translations/i18n";
import PaymentTargetSelect from "../../common/PaymentTargetSelect";
import BioNumberSelection from "./common/BioNumberSelection";

interface CreateOrderConfirmationModalProps {
  order: CustomOrder;
  disabled?: boolean;
  context: React.ContextType<typeof DataContext>;
}

interface CreateOrderConfirmationModalState {
  show: boolean;
  uploading: boolean;
  reverseCharge: boolean;
  customerData: CustomerData;
  orderConfirmationData: OrderConfirmationData;
  positions: Array<InvoicePosition>;
}

class CreateOrderConfirmationModal extends PureComponent<
  CreateOrderConfirmationModalProps,
  CreateOrderConfirmationModalState
> {
  constructor(props: CreateOrderConfirmationModalProps) {
    super(props);
    const { orderConfirmationData, customerData, positions } = this.getDefaultStateValues(props);
    this.state = {
      show: false,
      uploading: false,
      reverseCharge: false,
      customerData,
      orderConfirmationData,
      positions
    };
  }

  componentDidUpdate(
    prevProps: Readonly<CreateOrderConfirmationModalProps>,
    prevState: Readonly<CreateOrderConfirmationModalState>
  ) {
    if ((this.state.show && !_.isEqual(prevProps.order, this.props.order)) || (!prevState.show && this.state.show)) {
      const { orderConfirmationData, customerData, positions } = this.getDefaultStateValues(this.props);
      this.setState({ orderConfirmationData, customerData, positions });
    }
  }

  /**
   * Get the default state values
   * @param props the modal properties
   * @returns {object} default state values
   */
  getDefaultStateValues = (props: CreateOrderConfirmationModalProps) => {
    const { order, context } = props;
    const customerData = OrderHelper.getCustomerData(order);
    const orderConfirmationData = {
      title: order.title,
      subtitle: order.subtitle || "",
      note: i18n.t("template:orderConfirmationNote"),
      paymentNote: i18n.t("template:orderConfirmationPaymentNote"),
      text: i18n.t("template:orderConfirmationText", { identifier: order.identifier.toString() }),
      dueIn: order.createdFor && order.createdFor.paymentTarget ? order.createdFor.paymentTarget : 0,
      bioNumber: ""
    };
    const positions = [invoiceUtils.getDefaultPosition(order, context)];
    return { orderConfirmationData, customerData, positions };
  };

  /**
   * Use the properties and state to create the order confirmation pdf HTML
   * @param subTotal total amount without VAT
   * @param vatList list with values for all VATs
   * @param total total amount with VAT
   * @returns {string} html representation for order confirmation pdf
   */
  doCreatePDF = (subTotal: number, vatList: Array<{ vat: string; value: number; total: number }>, total: number) => {
    const { order } = this.props;
    const { customerData, orderConfirmationData, positions, reverseCharge } = this.state;
    const offerIdentifier = orderUtils.getOfferIdentifier(order);
    return orderConfirmationGeneration.createOrderConfirmation(
      order,
      customerData,
      orderConfirmationData,
      positions,
      reverseCharge,
      subTotal,
      vatList,
      total,
      order.fulfillment ? order.fulfillment.lot : undefined,
      undefined,
      offerIdentifier
    );
  };

  /**
   * Get the total amount of all positions with discount included
   * @returns {number} total amount of all positions with discount included without VAT
   */
  getSubTotal = () => {
    const { positions } = this.state;
    return invoiceUtils.getSubtotal(positions);
  };

  /**
   * Calculate the amount and total amount for all existing vat values
   * @returns { Array<{vat: string, value: number, total: number}>} Objects with vat values and the respective (total) amount
   */
  getVatAmount = () => {
    const { positions } = this.state;
    return invoiceUtils.getVatAmount(positions);
  };

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });

  handleReverseCharge = () => {
    const { reverseCharge, positions } = this.state;
    const positionsNew = positions.map(p => {
      p.vat = reverseCharge ? "7" : "0";
      return p;
    });
    this.setState({ reverseCharge: !reverseCharge, positions: positionsNew });
  };
  /**
   * Handle upload of pdf
   * @param subTotal total amount without VAT
   * @param vatList list with values for all VATs
   * @param total total amount with VAT
   */
  handleUpload =
    (subTotal: number, vatList: Array<{ vat: string; value: number; total: number }>, total: number) => async () => {
      const { order } = this.props;
      this.setState({ uploading: true });
      const pdfResult = await this.createOrderConfirmationPDF(subTotal, vatList, total);
      if (!pdfResult.result || !pdfResult.path) {
        toast.error("Order confirmation creation failed: " + pdfResult.message);
        this.setState({ uploading: false });
        return;
      }
      window.open(config.mediahubBase + pdfResult.path, "_blank");
      const timelineEntry = {
        id: new BSON.ObjectId(),
        type: T_OFFERCONFIRMATION,
        date: new Date(),
        path: pdfResult.path,
        person: new BSON.ObjectId(userService.getUserData()._id),
        identifier: order.identifier
      };
      try {
        const result = await dbOrderService.pushToTimeline(order._id, timelineEntry);
        if (result && result.modifiedCount) {
          toast.success("Order confirmation created successfully");
          notificationService.notify(R_ORDERFILEUPLOAD, order._id, {
            de: ": Auftragsbestätigung",
            en: ": Order confirmation"
          });
          this.setState({ uploading: false, show: false });
        } else toast.error("Adding of timeline entry failed. " + pdfResult.path);
      } catch (e) {
        toast.error("An unexpected error occurred: " + e.message);
      } finally {
        this.setState({ uploading: false });
      }
    };

  /**
   * Create the order confirmation pdf
   * @param subTotal total amount without VAT
   * @param vatList list with values for all VATs
   * @param total total amount with VAT
   * @returns { result: boolean, path?: string, message?: string} Result of pdf creation with path or error message
   */
  createOrderConfirmationPDF = async (
    subTotal: number,
    vatList: Array<{ vat: string; value: number; total: number }>,
    total: number
  ) => {
    const { order } = this.props;
    const data = JSON.stringify({
      html: this.doCreatePDF(subTotal, vatList, total),
      fileName:
        "Auftragsbestätigung_AT-" +
        order.identifier +
        "_" +
        order._id.toString() +
        "_V" +
        orderUtils.getPDFVersion(order, T_OFFERCONFIRMATION) +
        "_" +
        dateUtils.timeStampDate() +
        ".pdf"
    });
    let path;
    try {
      path = await pdfUtils.uploadAndReturnPath(data);
    } catch (e) {
      return { result: false, message: e.message };
    }
    return { result: true, path: path };
  };

  handleCustomerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const customerData = { ...this.state.customerData };
    const key = e.target.name as keyof CustomerData;
    customerData[key] = e.target.value;
    this.setState({ customerData });
  };

  handleOrderConfirmationChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    number?: boolean
  ) => {
    const orderConfirmationData = { ...this.state.orderConfirmationData };
    const key = e.target.name as keyof OrderConfirmationData;
    // @ts-ignore
    orderConfirmationData[key] = number ? +e.target.value : e.target.value;
    this.setState({ orderConfirmationData });
  };

  handlePositionValueChange = (id: BSON.ObjectId) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const positions = _.cloneDeep(this.state.positions);
    const position = positions.find(p => p.id.toString() === id.toString())!;
    const key = e.target.name as keyof InvoicePosition;
    if (["description", "unit"].includes(key)) {
      // @ts-ignore
      position[key] = e.target.value;
    } else {
      const value = getNumericValue(e as React.ChangeEvent<HTMLInputElement>, true);
      if (!value) return;
      // @ts-ignore
      position[key] = value;
    }
    // Recalculate total if amount or price changed
    if (["amount", "price"].includes(key)) {
      position.total = (Math.round(+position.amount * +position.price * 100) / 100).toString();
    }
    this.setState({ positions });
  };

  handlePositionRemove = (id: BSON.ObjectId) => {
    const positions = _.cloneDeep(this.state.positions);
    if (positions.length < 2) return;
    this.setState({ positions: positions.filter(p => p.id.toString() !== id.toString()) });
  };

  handleAddFreePosition = () => {
    const { reverseCharge } = this.state;
    const positions = [...this.state.positions];
    positions.push({
      id: new BSON.ObjectId(),
      type: "freeposition",
      description: "Beschreibung",
      amount: "0",
      unit: "item",
      price: "0",
      total: "0",
      vat: reverseCharge ? "0" : "7",
      discount: "0"
    });
    this.setState({ positions });
  };

  /**
   * Callback when language changed
   */
  languageChangeCallback = () => {
    const { order, context } = this.props;
    const orderConfirmationData = _.cloneDeep(this.state.orderConfirmationData);
    orderConfirmationData.note = i18n.t("template:orderConfirmationNote");
    orderConfirmationData.paymentNote = i18n.t("template:orderConfirmationPaymentNote");
    orderConfirmationData.text = i18n.t("template:orderConfirmationText", {
      identifier: order.identifier.toString()
    });
    const positions = [invoiceUtils.getDefaultPosition(order, context)];
    this.setState({ orderConfirmationData, positions });
  };

  /**
   * Render the pdf preview component
   * @param subTotal total amount without VAT
   * @param vatList list with values for all VATs
   * @param total total amount with VAT
   * @returns {JSX.Element} the pdf preview component to render
   */
  renderPreview = (subTotal: number, vatList: Array<{ vat: string; value: number; total: number }>, total: number) => {
    const { order } = this.props;
    const fileName =
      "ENTWURF_Auftragsbestätigung_AT-" +
      order.identifier +
      "_" +
      order._id.toString() +
      "_" +
      dateUtils.timeStampDate() +
      ".pdf";
    return <PDFPreview createPDF={() => this.doCreatePDF(subTotal, vatList, total)} fileName={fileName} />;
  };

  render() {
    const { order, disabled } = this.props;
    const { show, uploading, customerData, orderConfirmationData, positions, reverseCharge } = this.state;
    const subTotal = this.getSubTotal();
    const vatList = this.getVatAmount();
    const total = subTotal + vatList.reduce((a, b) => a + b.value, 0);
    const offerIdentifier = orderUtils.getOfferIdentifier(order);
    return (
      <>
        <button
          className={"btn btn-label-brand btn-sm btn-bold mr-3 my-1 " + (disabled ? "btn-disabled" : "")}
          onClick={this.handleShow}
          disabled={disabled}
        >
          <i className="fa fa-file-contract" />
          Order Confirmation
        </button>
        <Modal show={show} onHide={this.handleClose} size={"xl"} centered name={"createOrderConfirmationModal"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand fa fa-file-contract mr-2" />
              Create Order Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <CustomerDataComponent customerData={customerData} onCustomerChange={this.handleCustomerChange} />
              <div className="col-6">
                <div className="input-group row mb-2">
                  <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                    Order:
                  </label>
                  <div className="input-group-prepend">
                    <span className="input-group-text">AT-</span>
                  </div>
                  <input type="text" className="form-control" disabled value={order.identifier} />
                </div>
                {offerIdentifier && (
                  <div className="input-group row mb-2">
                    <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      From Offer:
                    </label>
                    <div className="input-group-prepend">
                      <span className="input-group-text">AN-</span>
                    </div>
                    <input type="text" className="form-control" disabled value={offerIdentifier} />
                  </div>
                )}
                <BioNumberSelection
                  label={"Bio Number:"}
                  bioNumber={orderConfirmationData.bioNumber}
                  onChange={this.handleOrderConfirmationChange}
                  wrapperClasses={"input-group row mb-2"}
                  labelColumnClasses={"col-lg-3 col-form-label ml-3"}
                  labelClasses={"kt-widget24__stats kt-font-dark kt-font-bold mb-0"}
                />
                <PaymentTargetSelect
                  label={"Due in:"}
                  name={"dueIn"}
                  value={orderConfirmationData.dueIn}
                  onChange={e => this.handleOrderConfirmationChange(e, true)}
                  wrapperClasses={"input-group row mb-2"}
                  labelColumnClasses={"col-lg-3 col-form-label ml-3"}
                  labelClasses={"kt-widget24__stats kt-font-dark kt-font-bold mb-0"}
                />
                <LanguageSelectionDropdown
                  labelPosition="front"
                  wrapperClasses={"input-group row mb-2"}
                  labelColumnClasses={"col-lg-3 col-form-label ml-3"}
                  labelClasses={"kt-widget24__stats kt-font-dark kt-font-bold mb-0"}
                  selectClasses={"select-default border-radius-left-0 col px-0"}
                  label={"PDF Language:"}
                  languageChangeCallback={this.languageChangeCallback}
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <div>
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Title:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      value={orderConfirmationData.title}
                      name={"title"}
                      onChange={this.handleOrderConfirmationChange}
                    />
                  </div>
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Subtitle:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subtitle"
                      value={orderConfirmationData.subtitle}
                      name={"subtitle"}
                      onChange={this.handleOrderConfirmationChange}
                    />
                  </div>
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Text:
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Text"
                      rows={2}
                      value={orderConfirmationData.text}
                      name={"text"}
                      onChange={this.handleOrderConfirmationChange}
                    />
                  </div>
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Payment:
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Payment Note"
                      rows={1}
                      value={orderConfirmationData.paymentNote}
                      name={"paymentNote"}
                      onChange={this.handleOrderConfirmationChange}
                    />
                  </div>
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Note:
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Note"
                      rows={3}
                      value={orderConfirmationData.note}
                      name={"note"}
                      onChange={this.handleOrderConfirmationChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <Table className="table-responsive">
                  <thead>
                    <tr>
                      <th className="kt-datatable__cell" style={{ width: "3%" }}>
                        #
                      </th>
                      <th className="kt-datatable__cell w-25">Description</th>
                      <th className="kt-datatable__cell" style={{ width: "10%" }}>
                        Amount
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "10%" }}>
                        Unit
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "12%" }}>
                        Price
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "10%" }}>
                        VAT
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "10%" }}>
                        Discount
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "15%" }}>
                        Total
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "5%" }} />
                    </tr>
                  </thead>
                  <tbody className="kt-datatable__body">
                    {positions.map((position, index) => (
                      <PositionRow
                        key={position.id.toString()}
                        position={position}
                        index={index}
                        disable={positions.length < 2}
                        reverseCharge={reverseCharge}
                        onChange={this.handlePositionValueChange}
                        onRemove={this.handlePositionRemove}
                      />
                    ))}
                  </tbody>
                </Table>
                <div className="row">
                  <div className="col-6">
                    <button className="btn btn-secondary" onClick={this.handleAddFreePosition}>
                      Add Position
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-6">
                <div className="kt-checkbox-list">
                  <label className="kt-checkbox kt-checkbox--solid">
                    <input type="checkbox" checked={reverseCharge} onChange={this.handleReverseCharge} />
                    Reverse-Charge
                    <span />
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="">
                  <div className="input-group row">
                    <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">
                      Subtotal:
                    </label>
                    <label
                      className="col-lg-9 col-form-label kt-widget24__stats kt-font-dark kt-font-bold"
                      style={{ textAlign: "right" }}
                    >
                      {subTotal.toLocaleString("de-DE", {
                        style: "currency",
                        currency: "EUR"
                      })}
                    </label>
                  </div>
                  {vatList.map(vat => (
                    <div key={vat.vat} className="input-group row">
                      <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark  kt-font-bold">
                        VAT ({vat.vat}%):
                      </label>
                      <label
                        className="col-lg-9 col-form-label kt-widget24__stats kt-font-dark kt-font-bold"
                        style={{ textAlign: "right" }}
                      >
                        {vat.value.toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR"
                        })}
                      </label>
                    </div>
                  ))}
                  <div className="input-group row">
                    <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">
                      Total:
                    </label>
                    <label
                      className="col-lg-9 col-form-label kt-widget24__stats kt-font-dark kt-font-bold"
                      style={{ textAlign: "right" }}
                    >
                      {total.toLocaleString("de-DE", {
                        style: "currency",
                        currency: "EUR"
                      })}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            {this.renderPreview(subTotal, vatList, total)}
            <button
              className={!uploading ? "btn btn-success" : "btn btn-success disabled"}
              disabled={uploading}
              onClick={this.handleUpload(subTotal, vatList, total)}
            >
              {uploading && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Create Order Confirmation
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateOrderConfirmationModal;
