import {
  SelectedBatchEntry,
  SelectedCommodityEntry,
  SelectedDeliveryAnnouncementEntry,
  WarehouseListingTabNames
} from "../context/warehouseContext";
import { Batch } from "../model/warehouse/batch.types";
import { CommodityWithBatches } from "../model/warehouse/customTypes.types";
import {
  isSelectedBatchEntries,
  isSelectedCommodityEntries,
  isSelectedDeliveryAnnouncementEntries
} from "./warehouseActionUtils";
import {
  SelectedBatchEntryType,
  SelectedCommodityEntryType,
  SelectedDeliveryAnnouncementEntryType
} from "./warehouseUtils";
import { isBatches, isCommoditiesWithBatches, isDeliveryAnnouncements } from "./warehouseFilterUtils";
import { DeliveryAnnouncement } from "../model/warehouse/deliveryAnnouncement.types";

export interface SelectTabPayload {
  tab: WarehouseListingTabNames;
  documents: Array<Batch | CommodityWithBatches | DeliveryAnnouncement>;
  keepSelected?: boolean;
}

/**
 * Get updated selected entries based on the payload of context action
 * @param currentSelectedEntries list of currently selected entries
 * @param payload payload of the warehouse context action
 * @returns {Array<SelectedBatchEntry | SelectedCommodityEntry | SelectedDeliveryAnnouncementEntry>} updated list of selected entries
 */
export const getUpdatedSelectedEntries = (
  currentSelectedEntries: Array<SelectedBatchEntry | SelectedCommodityEntry | SelectedDeliveryAnnouncementEntry>,
  payload: SelectTabPayload
): Array<SelectedBatchEntry | SelectedCommodityEntry | SelectedDeliveryAnnouncementEntry> => {
  const { documents, keepSelected } = payload;
  if (!keepSelected) return [];
  // Filter out entries not existing anymore
  if (isSelectedBatchEntries(currentSelectedEntries) && isBatches(documents)) {
    return currentSelectedEntries.filter(e => {
      if (e.type === SelectedBatchEntryType.BATCH) return documents.some(d => d._id.toString() === e.parentId);
      if (e.type === SelectedBatchEntryType.LOCATION)
        return documents.some(
          d => d._id.toString() === e.parentId && (!e.childId || d.locations.some(l => l._id.toString() === e.childId!))
        );
    });
  } else if (isSelectedCommodityEntries(currentSelectedEntries) && isCommoditiesWithBatches(documents)) {
    return currentSelectedEntries.filter(e => {
      if (e.type === SelectedCommodityEntryType.COMMODITY) {
        return documents.some(d => d._id.toString() === e.commodityId);
      }
      if (e.type === SelectedCommodityEntryType.BATCH_LOCATION) {
        return documents.some(
          d =>
            d._id.toString() === e.commodityId &&
            (!(e.batchId && e.locationId) ||
              d.batches.some(
                b => b._id.toString() === e.batchId! && b.locations.some(l => l._id.toString() === e.locationId!)
              ))
        );
      }
    });
  } else if (isSelectedDeliveryAnnouncementEntries(currentSelectedEntries) && isDeliveryAnnouncements(documents)) {
    return currentSelectedEntries.filter(e => {
      if (e.type === SelectedDeliveryAnnouncementEntryType.NOTIFICATION) {
        return documents.some(d => d._id.toString() === e.deliveryAnnouncementId);
      }
    });
  }
  return [];
};
