import _ from "lodash";
import React, { PureComponent } from "react";
import { Nav, Tab } from "react-bootstrap";
import CommodityCommodityOrders from "./tabPanels/CommodityCommodityOrders";
import CommodityDocuments from "./tabPanels/CommodityDocuments";
import CommodityTimeline from "./tabPanels/CommodityTimeline";
import CommodityOrders from "./tabPanels/CommodityOrders";
import CommodityPricing from "./tabPanels/CommodityPricing";
import CommoditySettings from "./tabPanels/CommoditySettings";
import CommodityStock from "./tabPanels/CommodityStock";
import { TabDefinition } from "../common/CustomTypes";
import { DataContext } from "../../context/dataContext";
import { CommoditiesDocument } from "../../model/commodities.types";
import CommodityExternalOrders from "./tabPanels/CommodityExternalOrders";
import accessUtils, { ACCESS_AREAS } from "../../utils/accessUtils";
import RawbidsSettings from "./RawbidsSettings";

interface CommodityTabPanelProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
  saving: boolean;
}

interface CommodityTabPanelState {
  currentTab: string;
}

class CommodityTabPanel extends PureComponent<CommodityTabPanelProps, CommodityTabPanelState> {
  constructor(props: CommodityTabPanelProps) {
    super(props);
    this.state = { currentTab: this.getTabDefinitions()[1].name };
  }

  componentDidUpdate(prevProps: Readonly<CommodityTabPanelProps>) {
    if (_.isEqual(prevProps.commodity, this.props.commodity)) {
      const activeTabDef = this.getTabDefinitions().find(t => t.name === this.state.currentTab);
      if (!activeTabDef || !("condition" in activeTabDef) || !activeTabDef.condition) return;
      if (!activeTabDef.condition(this.props.commodity)) {
        // Reset tab if condition of current tab is not valid anymore
        this.setState({ currentTab: this.getTabDefinitions()[1].name });
      }
    }
  }

  getTabDefinitions = (): Array<TabDefinition> => {
    const { commodity, context, saving } = this.props;
    return [
      {
        name: "timeline",
        tabContent: (
          <>
            <i className="flaticon2-time" /> Timeline
          </>
        ),
        component: <CommodityTimeline commodity={commodity} context={context} />
      },
      {
        name: "pricing",
        tabContent: (
          <>
            <i className="flaticon-price-tag" /> Pricing
          </>
        ),
        component: <CommodityPricing commodity={commodity} updateDocumentInContext={context.updateDocumentInContext} />
      },
      {
        name: "stock",
        tabContent: (
          <>
            <i className="flaticon2-box-1" /> Stock
          </>
        ),
        component: <CommodityStock commodity={commodity} context={context} />,
        condition: (commodity: CommoditiesDocument) => commodity.type !== "service"
      },
      {
        name: "commodityOrders",
        tabContent: (
          <>
            <i className="flaticon-shopping-basket" /> Commodity Orders
          </>
        ),
        component: <CommodityCommodityOrders commodity={commodity} context={context} size="full" />,
        condition: (commodity: CommoditiesDocument) => commodity.type !== "service"
      },
      {
        name: "Orders",
        tabContent: (
          <>
            <i className="flaticon-notepad" /> Related Orders
          </>
        ),
        component: <CommodityOrders commodity={commodity} context={context} />
      },
      {
        name: "emOrders",
        tabContent: (
          <>
            <i className="flaticon-notepad" /> External Orders
          </>
        ),
        component: <CommodityExternalOrders commodity={commodity} context={context} />,
        condition: (commodity: CommoditiesDocument) =>
          !commodity.type &&
          accessUtils.canAccessOneOfAreas([ACCESS_AREAS.PROCUREMENT_NOT_PRODUCTION, ACCESS_AREAS.FINANCE])
      },
      {
        name: "documents",
        tabContent: (
          <>
            <i className="flaticon2-google-drive-file" /> Documents
          </>
        ),
        component: <CommodityDocuments commodity={commodity} context={context} />
      },
      {
        name: "settings",
        tabContent: (
          <>
            <i className="flaticon2-settings" /> Settings
          </>
        ),
        component: <CommoditySettings commodity={commodity} context={context} saving={saving} />
      },
      {
        name: "rawbids",
        tabContent: (
          <>
            <i className="flaticon2-settings" /> Rawbids
          </>
        ),
        component: <RawbidsSettings commodity={commodity} context={context} />,
        condition: (commodity: CommoditiesDocument) => !!commodity.rawbidsData?.commoditySnapshot
      }
    ];
  };

  /**
   * Handle tab changes
   * @param eventKey new tab
   */
  handleTab = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
  };

  render() {
    const { commodity } = this.props;
    const { currentTab } = this.state;
    const tabs = this.getTabDefinitions();
    return (
      <Tab.Container id="commodityTabs" activeKey={currentTab} onSelect={e => this.handleTab(e)}>
        <div className="kt-portlet kt-portlet--tabs">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-toolbar">
              <ul
                className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand"
                role="tablist"
              >
                {tabs.map(
                  t =>
                    (!t.condition || t.condition(commodity)) && (
                      <li key={t.name + "_link"} className="nav-item">
                        <Nav.Link role="tab" id={t.name} eventKey={t.name} active={currentTab === t.name}>
                          {t.tabContent}
                        </Nav.Link>
                      </li>
                    )
                )}
              </ul>
            </div>
          </div>
          <div className="kt-portlet__body">
            <Tab.Content>
              {tabs.map(
                t =>
                  (!t.condition || t.condition(commodity)) && (
                    <Tab.Pane key={t.name} eventKey={t.name} transition={false}>
                      {t.component}
                    </Tab.Pane>
                  )
              )}
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    );
  }
}

export default CommodityTabPanel;
