import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import Commodity from "./Commodity";
import { DataContext } from "../../context/dataContext";

interface CommodityWrapperProps extends RouteComponentProps<CommodityParams, {}, {}> {}

interface CommodityParams {
  id: string;
  order: string;
}

const CommodityWrapper: React.FunctionComponent<CommodityWrapperProps> = props => {
  const context = useContext(DataContext);
  return <Commodity context={context} {...props} />;
};

export default CommodityWrapper;
