import React from "react";
import { Link } from "react-router-dom";
import { OrdersDocument } from "../../../model/orders.types";
import dateUtils from "../../../utils/dateUtils";
import orderUtils from "../../../utils/orderUtils";
import { CustomOrder } from "../../order/CustomTypes";
import { T_REQUESTPENDING, T_TARGETDATEUPDATED } from "../../../utils/timelineUtils";
import { MARGIN } from "../../../utils/orderCalculationUtils";
import baseUtils from "../../../utils/baseUtils";

interface CreatedOnBadgeProps {
  document: OrdersDocument;
}

export const CreatedOnBadge: React.FunctionComponent<CreatedOnBadgeProps> = ({ document }) => {
  const daysCreatedAgo = dateUtils.getCreatedDaysAgo(document.createdOn);
  return (
    <span className="kt-font-dark kt-font-bold">
      <span
        className={
          "kt-badge kt-badge--inline kt-badge--pill " +
          (daysCreatedAgo <= 1 ? "kt-badge--success" : daysCreatedAgo === 2 ? "kt-badge--warning" : "kt-badge--danger")
        }
      >
        {document.createdOn && baseUtils.formatDate(document.createdOn)}
      </span>
    </span>
  );
};

interface InCheckSinceBadgeProps {
  document: OrdersDocument;
}

export const InCheckSinceBadge: React.FunctionComponent<InCheckSinceBadgeProps> = ({ document }) => {
  const commodityChecks = document.timeline.filter(t => t.type === T_REQUESTPENDING);
  let inCheckSince, daysSince;
  if (commodityChecks.length > 0) {
    inCheckSince = commodityChecks[commodityChecks.length - 1];
    daysSince = dateUtils.getCreatedDaysAgo(inCheckSince.date);
  }
  return (
    <span className="kt-font-dark kt-font-bold">
      <span
        className={
          "kt-badge kt-badge--inline kt-badge--pill " +
          (daysSince !== undefined
            ? daysSince <= 2
              ? "kt-badge--success"
              : daysSince > 2 && daysSince <= 4
              ? "kt-badge--warning"
              : "kt-badge--danger"
            : "kt-badge--danger")
        }
      >
        {inCheckSince ? baseUtils.formatDate(inCheckSince.date) : "unknown"}
      </span>
    </span>
  );
};

interface PriorityBadgeProps {
  document: OrdersDocument;
}

export const PriorityBadge: React.FunctionComponent<PriorityBadgeProps> = ({ document }) => {
  return (
    <span>
      {document.priority === "high" ? (
        <span className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill">high</span>
      ) : document.priority === "medium" ? (
        <span className="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill">medium</span>
      ) : (
        <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill">low</span>
      )}
    </span>
  );
};

interface OrderWidgetProps {
  document: OrdersDocument | CustomOrder;
  prefix: string;
  showSubtitle?: boolean;
  noLink?: boolean;
  titleClasses?: string;
  popover?: JSX.Element;
}

export const OrderWidget: React.FunctionComponent<OrderWidgetProps> = ({
  document,
  titleClasses,
  prefix,
  showSubtitle,
  noLink,
  popover
}) => {
  return (
    <span>
      <div className="kt-user-card-v2">
        <div className="kt-user-card-v2__pic d-none d-xl-block">
          <div className="kt-badge kt-badge--xl kt-badge--primary">
            {document.settings.type && (
              <img src={process.env.PUBLIC_URL + "/media/icons/" + document.settings.type + ".png"} className="p-1" />
            )}
          </div>
        </div>
        <div className="kt-user-card-v2__details">
          {noLink ? (
            <span className={"kt-user-card-v2__name d-inline-block " + (titleClasses ? titleClasses : "")}>
              {prefix + document.identifier}
              {showSubtitle && " - " + document.title}
            </span>
          ) : (
            <Link
              className={"kt-user-card-v2__name d-inline-block kt-link" + (titleClasses ? titleClasses : "")}
              to={"/order/" + document._id.toString()}
              onClick={e => e.stopPropagation()}
            >
              {prefix + document.identifier}
              {showSubtitle && " - " + document.title}
            </Link>
          )}
          {popover ? popover : null}
          <br />
          <span className="kt-user-card-v2__email">{showSubtitle ? document.subtitle : document.title}</span>
        </div>
      </div>
    </span>
  );
};

interface MarginBadgeProps {
  order: OrdersDocument;
}

export const MarginBadge: React.FunctionComponent<MarginBadgeProps> = ({ order }) => {
  const averagePercentMargin = orderUtils.getAverageValue(order, "percentmargin");
  return (
    <span>
      {averagePercentMargin[1] <= MARGIN.CRITICAL ? (
        <span className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill">forbidden</span>
      ) : averagePercentMargin[1] > MARGIN.CRITICAL && averagePercentMargin[1] < MARGIN.BAD ? (
        <span className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill">low</span>
      ) : averagePercentMargin[1] >= MARGIN.BAD && averagePercentMargin[1] < MARGIN.GOOD ? (
        <span className="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill">medium</span>
      ) : (
        <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill">high</span>
      )}
    </span>
  );
};

interface CalendarWeekBadgeProps {
  order: OrdersDocument | CustomOrder;
  onClick?: (e: React.MouseEvent<any>) => void;
}

export const CalendarWeekBadge: React.FunctionComponent<CalendarWeekBadgeProps> = ({ order, onClick }) => {
  const currentCW = dateUtils.getCW(new Date());
  let targetDate = order.targetDate;
  let oldTargetDate;
  const history = order.timeline.filter(t => t.type === T_TARGETDATEUPDATED);
  if (history.length > 0) oldTargetDate = history[0].oldDate;
  let targetCW = targetDate ? dateUtils.getCW(targetDate) : Infinity;
  let targetCWOld = oldTargetDate ? dateUtils.getCW(oldTargetDate) : Infinity;
  const currentYear = new Date().getFullYear();
  const targetYear = targetDate ? targetDate.getFullYear() : null;
  // Calculate calendar week diff via mondays of each week
  const cwDiff = targetDate
    ? Math.round(
        (dateUtils.getMonday(targetDate).getTime() - dateUtils.getMonday(new Date()).getTime()) /
          (7 * 24 * 60 * 60 * 1000)
      )
    : Infinity;
  const cwDiffOld = oldTargetDate
    ? Math.round(
        (dateUtils.getMonday(oldTargetDate).getTime() - dateUtils.getMonday(new Date()).getTime()) /
          (7 * 24 * 60 * 60 * 1000)
      )
    : Infinity;
  return (
    <span className={onClick ? "pointer" : ""} onClick={onClick}>
      <span
        className={
          "kt-badge kt-badge--inline kt-badge--pill " +
          ((targetCW < currentCW && targetYear === currentYear) || (targetYear && targetYear < currentYear)
            ? "kt-badge--danger"
            : targetCW === currentCW && targetYear === currentYear
            ? "kt-badge--warning"
            : targetCW === Infinity || !targetYear
            ? "kt-badge--secondary"
            : "kt-badge--success")
        }
      >
        {targetCW !== Infinity ? "CW " + targetCW : "-"} {targetCWOld !== Infinity ? " (" + targetCWOld + ")" : ""}
      </span>
      <p className={"kt-font-bolder mt-2 " + (cwDiff >= 0 ? "text-success" : "text-danger")}>
        {targetCW !== Infinity ? cwDiff + (cwDiff === 1 ? " week" : " weeks") : ""}
        {targetCWOld !== Infinity ? ` (${cwDiffOld})` : ""}
      </p>
    </span>
  );
};
