import { SuppliersDocument } from "../../model/suppliers.types";
import { UserdataDocument } from "../../model/userdata.types";
import { CommoditiesDocument } from "../../model/commodities.types";
import { CommodityOrderExtended } from "../../components/commodities/CustomTypes";
import { ManufacturersDocument } from "../../model/manufacturers.types";
import { CommoditypropertiesDocument } from "../../model/commodityproperties.types";
import { OrdersDocument } from "../../model/orders.types";
import { INCOTERMS } from "../suppliersUtils";
import pdfUtils from "./pdfUtils";

/**
 * Generates a commodity order PDF.
 * @param supplier: Supplier where the commodities are ordered
 * @param orders: Commodity orders that should be handled
 * @param user: User issuing the generation
 * @param commodities: List of commodities. Needed to get names and properties
 * @param orderNumber: Commodity order number
 * @param properties: List of properties
 * @param manufacturers: List of manufacturers
 * @param ordersDocuments: List of orders
 * @returns { string } Prepared HTML
 */
function createCommodityOrder(
  supplier: SuppliersDocument,
  orders: Array<CommodityOrderExtended>,
  user: UserdataDocument,
  commodities: Array<CommoditiesDocument>,
  orderNumber: number,
  properties: Array<CommoditypropertiesDocument>,
  manufacturers: Array<ManufacturersDocument>,
  destinationName: ManufacturersDocument | undefined,
  ordersDocuments: Array<OrdersDocument>
) {
  let html =
    '<head><link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"><style>tr:nth-child(even) {background-color: white;}</style><meta http-equiv="content-type" content="text/html; charset=utf-8">';

  html += "</head>";
  html += '<body style="font-family: Helvetica ">';

  html +=
    '<hr><img src="https://private-label-factory.de/config/assets/img/PLF_logo_bw.png" width="200"><br><table cellpadding="5" cellspacing="0" style="width: 100%;"><tr><td><br><br><span style="font-size:12px; float:left;"><u>Private Label Factory Deutschland GmbH, Philipp-Reis-Str. 7, 45659 Recklinghausen</u></span><br>';
  html += '<span style="font-size:16px; float:left;">';
  html += pdfUtils.getCompanyHTML(supplier);
  html +=
    '</span></td><td style="text-align: right; font-size:16px">Order PO-' +
    orderNumber +
    "<br>Date: " +
    new Date().toLocaleDateString() +
    "<br><br>";
  html += pdfUtils.getResponsibleHTML(user, true);

  const incoterm =
    orders[0].order.incoterm +
    (orders[0].order.incoterm.toString().toUpperCase() === "CIFAIR" ? " MUNICH" : "") +
    (orders[0].order.incoterm.toString().toUpperCase() === "CIFSEA" ? " HAMBURG" : "");
  const name = destinationName ? destinationName.name.toString().toUpperCase() : "";
  html +=
    '</td></tr><tr><td style="font-size:25px; font-weight: bold;"><br>Order PO-' +
    orderNumber +
    (incoterm ? " - " + incoterm.toUpperCase() : "") +
    " - " +
    name;
  html += "</td></tr></table></body>";
  html += '<table style="width:100%"><tr><td style="width:50%">';
  html += "</td>";
  html += "</td></tr></table>";
  html +=
    '<table cellpadding="5" cellspacing="0" style="font-size:14px; width:100%; background-color:#fafafa" ><tr style="background-color:#cccccc"><td style="width: 46%"><b>Description</b></td><td style="width: 10%"><b style="white-space:nowrap;">Shelf life</b></td><td style="width: 10%"><b style="white-space:nowrap;">Packages</b></td><td style="width: 10%"><b style="white-space:nowrap;">Quantity</b></td><td style="width: 12%"><b style="white-space:nowrap;">Price (kg)</b></td><td style="width: 12%"><b>Price (total)</b></td></tr>';

  html += "<tbody>";
  for (let i = 0; i < orders.length; i++) {
    let references = "";
    for (let x = 0; x < orders[i].order.orders.length; x++) {
      const order = ordersDocuments.find(o => o._id.toString() === orders[i].order.orders[x].toString());
      let at = order ? order.identifier : "TBD";
      if (at) {
        references += "AT-" + at + " ";
      }
    }
    let cmd = commodities.find(c => c._id.toString() === orders[i]._id.toString())!;
    html +=
      '<tr style="width:100%; font-size: 14px">' +
      "<td><b>" +
      cmd.title.en +
      "</b>" +
      (cmd.subtitle.en ? "<br>" + cmd.subtitle.en : "") +
      (cmd.cas_number ? "<br>" + "CAS Number: " + cmd.cas_number : "<br>" + "CAS Number:") +
      (cmd.hs_code ? "<br>" + "HS Code: " + cmd.hs_code : "<br>" + "HS Code: ");
    if (cmd.properties.length > 0) {
      html += "<br>Properties: ";
      for (let r = 0; r < cmd.properties.length; r++) {
        html += properties.find(p => p._id.toString() === cmd.properties[r].toString())!.name.en + ", ";
      }
    }

    html +=
      (references ? "<br><i>" + "Internal references: " + references + "</i>" : "") +
      "</td>" +
      "<td>" +
      orders[i].order.shelflife +
      " months" +
      "</td>" +
      "<td>" +
      (orders[i].order.packages ? orders[i].order.packages : "-") +
      "</td>" +
      "<td>" +
      orders[i].order.orderquantity.toFixed(2) +
      " kg</td><td>";
    html +=
      (orders[i].order.purchasePrice / orders[i].order.orderquantity).toFixed(2) +
      " " +
      orders[i].order.currency +
      "</td><td>" +
      orders[i].order.purchasePrice.toFixed(2) +
      " " +
      orders[i].order.currency +
      "</u></td>";
    html += "</tr>";
  }

  html += "</tbody>";
  html += "</table>";

  html +=
    '<table style="width:100%"><tr><td style="width:50%; padding-right:20px;"><span style="font-size:15px;"><br><br><br><b>Quality as specified in E-Mail attached specification sheets. </b><br><b>According to EC with Pesticides, ETO, Solvents Residue, Microbiology, Heavy Metals and PHA4.</b><br>';

  html += "<br><b>Incoterm: " + (incoterm ? incoterm.toUpperCase() : "undefined") + "</b><br>";
  if (INCOTERMS.includes(incoterm)) {
    html += "<br><b>ETA: </b>";
  } else {
    html += "<br><b>Pickup Date: </b>";
  }

  if (orders[0].order.asap) {
    html += "<b>ASAP</b>";
  } else {
    try {
      html += "<b>" + orders[0].order.deliverytime + " days </b>";
    } catch {
      html += "not set.";
    }
  }

  html +=
    "<br><b>Destination: </b> " +
    (orders[0].order.destination
      ? manufacturers.find(m => m._id.toString() === orders[0].order.destination!.toString())!.name
      : "");

  html +=
    "<br><b>Payment condition: </b> " +
    (orders[0].paymentCondition && orders[0].paymentCondition.value !== "0"
      ? orders[0].paymentCondition.value + " Days"
      : "Payment in advance") +
    "<br>";

  html += "</span>";
  const inc = orders[0].order.incoterm.toString().toUpperCase();
  if (inc === "FCA" || inc === "FAS" || inc === "FOBAIR" || inc === "FOBSEA" || inc === "CIFAIR" || inc === "CIFSEA") {
    html += '<table style="width:100%"><tr><td style="width:50%; padding-right:20px;"><span style="font-size:15px;">';
    html +=
      "<br><b>Consignee:</b><br>&nbsp;&nbsp;<b>1. Notify:</b><br>" +
      renderContact(
        "asap logistics GmbH",
        "Matthias Rothermel",
        "Cargo Modul H, 85356 Munich Airport",
        "+49 89 - 97330125"
      );
    html +=
      "&nbsp;&nbsp;<b>2. Consignee:</b><br>" +
      renderContact(
        "Private Label Factory Deutschland GmbH",
        "Christina Hofbauer",
        "Philipp-Reis-Str. 7 - 45659 Recklinghausen - Germany",
        "+49(0)209-51956631"
      );
    html +=
      "<b>Destination airport:</b> MUC<br><b>VAT-number:</b> DE317701381<br><b>Trade Register Number:</b> HRB 14580";
    html += "</span><br></td></tr></table>";
  } else if (inc === "DDP" || inc === "DAP" || inc === "EXW" || inc === "COURIER" || inc === "DPU") {
    html += '<table style="width:100%"><tr><td style="width:50%; padding-right:20px;"><span style="font-size:15px;">';
    html +=
      "<br><b>Invoice Address:</b><br>" +
      pdfUtils.renderAddress(
        "Private Label Factory Deutschland GmbH",
        "Philipp-Reis-Str. 7",
        "45659 Recklinghausen",
        "Germany",
        "DE317701381",
        "Christoph Stachelek"
      );
    html += "<br><b>Delivery Address:</b><br>" + pdfUtils.getDeliveryAddress(name);
    html += "</span><br></td></tr></table>";
  }
  html +=
    '<table style="width:100%"><tr><td style="width:50%; padding-right:20px;"><span style="font-size:15px;"><br><br>If you have any questions, feel free to contact me any time.<br><br>Best regards,<br><b>' +
    user.prename +
    " " +
    user.surname +
    "</b></span><br></td></tr></table>";

  html +=
    '<br><br><br><table style="width:100%"><tr><td style="width:10%; padding-right:20px;"><span style="font-size:15px;"><b>Recipient</b></span></td></tr><tr><td style="width:10%; padding-right:20px;"></td><td style="width:30%; padding-right:20px; border-top: 1px solid black"><span style="font-size: 12px">Name, date and signature</span></td><td style="width: 70%"></td></tr></table>';

  html +=
    '<br><br></td></tr></table><p style="font-size:13px; position: absolute; bottom: 0px"><b>Private Label Factory Deutschland GmbH</b> | Philipp-Reis-Str. 7 | 45659 Recklinghausen, Germany | VAT ID: DE317701381 | Reg. Court AG Recklinghausen, HRB 9376 | CEO: Christoph Stachelek | DE25422600010108460500 | GENODEM1GBU / Volksbank Ruhr Mitte</p></body>';
  return html;
}

function renderContact(name: string, person: string, address: string, telephone: string) {
  const indent = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
  return `<div >${indent}Company name: ${name}<br>${indent}Contact person: ${person}<br>${indent}Address: ${address}<br>${indent}Telephone: ${telephone}</div>`;
}

export default { createCommodityOrder };
