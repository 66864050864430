import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { BSON } from "realm-web";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";
import { ExtendedEMOrder } from "../../../model/customTypes.types";
import baseUtils from "../../../utils/baseUtils";
import dbService, { EMORDERS } from "../../../services/dbService";
import ExternalManufacturerHelper from "../ExternalManufacturerHelper";
import SplashScreen from "../../common/SplashScreen";
import EmOrderHeader from "./EMOrderHeader";
import EMOrderNote from "./EMOrderNote";
import EMOrderOverview from "./EMOrderOverview";
import EMOrderTimeline from "./EMOrderTimeline";
import { DataContext } from "../../../context/dataContext";

interface EMOrderParams {
  id: string;
}

interface ExternalManufacturerOrderProps extends RouteComponentProps<EMOrderParams, {}, {}> {
  internal?: boolean;
  context: React.ContextType<typeof ExternalManufacturerContext | typeof DataContext>;
}

interface ExternalManufacturerOrderState {
  order?: ExtendedEMOrder;
}

class ExternalManufacturerOrder extends PureComponent<ExternalManufacturerOrderProps, ExternalManufacturerOrderState> {
  _isMounted = false;
  _id: string | undefined;
  constructor(props: ExternalManufacturerOrderProps) {
    super(props);
    this._id = props.match.params.id;
    this.state = {};
  }

  componentDidMount = async () => {
    await this.initializeData();
  };

  componentDidUpdate = async (
    prevProps: Readonly<ExternalManufacturerOrderProps>,
    prevState: Readonly<ExternalManufacturerOrderState>,
    snapshot?: any
  ) => {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this._id = this.props.match.params.id;
      await this.initializeData();
    }
    if (
      this._id &&
      !_.isEqual(prevProps.context.externalManufacturerOrders, this.props.context.externalManufacturerOrders)
    ) {
      const { commodities, externalManufacturerOrders: orders } = this.props.context;
      const order = baseUtils.getDocFromCollection(orders, this._id);
      const emo = _.cloneDeep(order) as ExtendedEMOrder;
      const commodity = baseUtils.getDocFromCollection(commodities, emo.commodityId);
      emo.commodity = commodity ? ExternalManufacturerHelper.getExtendedCommodity(commodity, this.props.context) : null;
      if (emo) {
        if (!_.isEqual(emo, prevState.order)) {
          this.setState({ order: emo });
        }
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  initializeData = async () => {
    const { history, context } = this.props;
    const { externalManufacturerOrders: orders, commodities } = context;
    if (!this._id || !BSON.ObjectId.isValid(this._id)) {
      history.push("/orders");
      return;
    }
    this._isMounted = true;
    let order = baseUtils.getDocFromCollection(orders, this._id);
    if (!order) {
      const ordersCol = await dbService.getCollection(EMORDERS);
      order = baseUtils.getDocFromCollection(ordersCol, this._id);
      if (!order) {
        console.error("No order could be loaded for id", this._id);
        history.push("/orders");
        return;
      }
    }
    const emo = _.cloneDeep(order) as ExtendedEMOrder;
    const commodity = baseUtils.getDocFromCollection(commodities, emo.commodityId);
    emo.commodity = commodity ? ExternalManufacturerHelper.getExtendedCommodity(commodity, context) : null;
    if (this._isMounted) this.setState({ order: emo });
  };

  render() {
    const { context, internal } = this.props;
    const { order } = this.state;
    if (!order)
      return (
        <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid ">
          <div className="kt-portlet ">
            <div className="kt-portlet__body" style={{ minHeight: "80vh" }}>
              <SplashScreen
                additionalSVGStyle={{
                  height: "80px",
                  width: "80px"
                }}
              />
            </div>
          </div>
        </div>
      );
    return (
      <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <EmOrderHeader order={order} internal={internal} />
        <div className="row">
          <div className="col-xl-4">
            <EMOrderNote order={order} internal={internal} />
            <EMOrderOverview order={order} />
          </div>
          <div className="col-xl-8">
            <EMOrderTimeline order={order} context={context} />
          </div>
        </div>
      </div>
    );
  }
}

export default ExternalManufacturerOrder;
