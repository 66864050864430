import _ from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CommoditiesDocument } from "../../../../model/commodities.types";
import { CustomOrder } from "../../CustomTypes";
import { SuppliersDocument } from "../../../../model/suppliers.types";
import { pricingCommodities } from "../../../../model/orders.types";
import OrderHelper, { T_CUSTOM, T_SERVICE, T_SOFTGEL } from "../../OrderHelper";
import calculationUtils from "../../../../utils/calculationUtils";
import orderUtils, {
  ARCHIVE,
  CONTRACT,
  CREATEINVOICE,
  FULFILLMENT,
  PRODUCTION,
  PRODUCTIONQUEUE
} from "../../../../utils/orderUtils";
import ReplaceMaterialModal from "../../modals/ReplaceMaterialModal";
import userService from "../../../../services/userService";
import { ROLES } from "../../../../utils/userdataUtils";
import baseUtils from "../../../../utils/baseUtils";
import RemoveMaterialModal from "../../modals/RemoveMaterialModal";
import accessUtils, { ACTIONS } from "../../../../utils/accessUtils";
import orderCalculationUtils from "../../../../utils/orderCalculationUtils";

interface OrderCommoditiesProps {
  order: CustomOrder;
  commodities: Array<CommoditiesDocument>;
  suppliers: Array<SuppliersDocument>;
}

interface OrderCommoditiesState {
  commodityToReplace?: CommoditiesDocument;
}

class OrderCommodities extends PureComponent<OrderCommoditiesProps, OrderCommoditiesState> {
  constructor(props: OrderCommoditiesProps) {
    super(props);
    this.state = {};
  }

  handleReplaceCommodity = (commodity: CommoditiesDocument) => this.setState({ commodityToReplace: commodity });
  handleHide = () => this.setState({ commodityToReplace: undefined });

  render() {
    const { order, commodities, suppliers } = this.props;
    const { commodityToReplace } = this.state;
    let replaceable =
      ([PRODUCTIONQUEUE, PRODUCTION].includes(order.state) && userService.hasRole(ROLES.PRODUCTION)) ||
      (order.state === PRODUCTIONQUEUE && userService.hasRole(ROLES.PROCUREMENT));

    // special case order contract
    if (!replaceable && order.state === CONTRACT) {
      if (order.contract) {
        replaceable =
          order.contract.some(con => !con.finished) &&
          userService.hasOneOfRoles([ROLES.PACKAGINGPROCUREMENT, ROLES.PROCUREMENT]);
      }
    }
    const showBatches = !replaceable && [FULFILLMENT, CREATEINVOICE, ARCHIVE].includes(order.state);
    const totalCommodityUnitPrice = orderCalculationUtils.calculateCommoditiesUnitPrice(order);
    return (
      <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--subtable kt-datatable--loaded table-responsive">
        <ReplaceMaterialModal
          order={order}
          type={"commodity"}
          material={commodityToReplace}
          externalHandling={true}
          onHide={this.handleHide}
        />
        <table className="kt-datatable__table d-table ">
          <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
            <tr className="kt-datatable__row d-table-row">
              <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                <span>Units</span>
              </th>
              <th
                className="kt-datatable__cell d-table-cell"
                style={{ width: replaceable || showBatches ? "16%" : "24%" }}
              >
                <span>Commodity</span>
              </th>
              <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                <span>Amount</span>
              </th>
              <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                <span>Required</span>
              </th>
              <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                <span>Order Quantity</span>
              </th>
              <th className="kt-datatable__cell d-table-cell" style={{ width: "18%" }}>
                <span>Supplier</span>
              </th>
              <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                <span>Delivery</span>
              </th>
              <th className="kt-datatable__cell d-table-cell" style={{ width: "12%" }}>
                <span>Price</span>
              </th>
              <th
                className={"kt-datatable__cell d-table-cell" + (replaceable ? "" : " text-right")}
                style={{ minWidth: "6%" }}
              >
                <span>Change</span>
              </th>
              {(replaceable || showBatches) && (
                <th className="kt-datatable__cell d-table-cell text-right" style={{ width: "8%" }}>
                  <span>{replaceable ? "Action" : "Batches"}</span>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
            {order.calculations.map(calc =>
              calc.prices.map(price => {
                const units = calc.units;
                const commodity = commodities.find(c => c._id.toString() === price._id.toString());
                if (!commodity) return null;
                const supplier =
                  typeof price.supplier === "string"
                    ? price.supplier
                    : suppliers.find(s => s._id.toString() === price.supplier.toString())!;
                return (
                  <OrderCommodity
                    key={calc.id.toString() + price._id.toString()}
                    type={order.settings.type}
                    units={units}
                    perUnit={order.settings.perUnit}
                    price={price}
                    commodity={commodity}
                    supplier={supplier}
                    replaceable={replaceable}
                    showBatches={showBatches}
                    order={order}
                    totalCommodityUnitPrice={totalCommodityUnitPrice}
                    onReplaceCommodity={this.handleReplaceCommodity}
                  />
                );
              })
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

interface OrderCommodityProps {
  type: string;
  units: number;
  perUnit: number;
  price: pricingCommodities;
  commodity: CommoditiesDocument;
  supplier: SuppliersDocument | "accumulatedstock" | "custom" | "customer" | "ownstock";
  replaceable: boolean;
  showBatches: boolean;
  order: CustomOrder;
  totalCommodityUnitPrice: number;
  onReplaceCommodity: (commodity: CommoditiesDocument) => void;
}

const OrderCommodity: React.FunctionComponent<OrderCommodityProps> = ({
  type,
  units,
  perUnit,
  price,
  commodity,
  supplier,
  replaceable,
  showBatches,
  order,
  totalCommodityUnitPrice,
  onReplaceCommodity
}) => {
  const totalAmount = orderUtils.getTotalAmountWithBuffer(perUnit, units, price.amount, price.buffer, type);
  let batchPrice;
  let totalUsage = 0;
  let totalPrice = 0;
  const batches: Array<string> = [];
  // If there are used batches calculate show the average batch price and calculate the price difference to the batch price
  if (showBatches && order.usedBatches) {
    for (let i = 0; i < order.usedBatches.length; i++) {
      let batch = order.usedBatches[i];
      if (batch.commodityId && batch.commodityId.toString() !== commodity._id.toString()) continue;
      totalUsage += +batch.used;
      totalPrice += +batch.used * +batch.price;
    }
    if (totalUsage !== 0) batchPrice = totalPrice / totalUsage;
    order.usedBatches.forEach(batch => {
      if (batch.commodityId && batch.commodityId.toString() === commodity._id.toString()) batches.push(batch.lot);
    });
  }
  const priceDifference =
    (batchPrice ? batchPrice / +price.estimatedprice - 1 : +price.price / +price.estimatedprice - 1) * 100;
  const canReplace = accessUtils.canPerformAction(ACTIONS.ORDERCOMMODITYREPLACE);
  let pricePerUnit = batchPrice ? batchPrice : price.price;
  const pricePerProducedUnit = orderCalculationUtils.getCommodityPricePerProducedUnit(
    type,
    pricePerUnit,
    price.buffer,
    price.amount,
    perUnit
  );

  return (
    <tr className="kt-datatable__row d-table-row">
      <td className=" kt-datatable__cell d-table-cell">
        <span>{units}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <Link className="kt-user-card-v2__name kt-link" to={"/commodity/" + commodity._id.toString()}>
              {commodity.title.en}
              {commodity.article_number && <span className="text-success"> {commodity.article_number}</span>}
            </Link>
            <span className="kt-user-card-v2__email">{commodity.subtitle.en}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          {[T_CUSTOM, T_SERVICE, T_SOFTGEL].includes(type)
            ? price.amount + " pcs."
            : calculationUtils.formatAmount(price.amount, 2)}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          {[T_CUSTOM, T_SERVICE, T_SOFTGEL].includes(type)
            ? type === T_SERVICE
              ? totalAmount + " pcs."
              : totalAmount / 1000 + " tsd."
            : calculationUtils.formatAmount(totalAmount, 2)}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>{OrderHelper.formatAmount(price.orderquantity, type)}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className={"kt-font-bold " + (!supplier && "text-warning")}>
          {!supplier ? "Invalid supplier" : typeof supplier === "string" ? _.upperFirst(supplier) : supplier.name}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-font-bold">{price.deliverytime ? price.deliverytime + " days" : "not set"}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-font-bold">
          {baseUtils.formatEuro(pricePerUnit)}/{OrderHelper.getQuantityUnitForType(type)}
        </span>
        {type !== T_SERVICE && pricePerProducedUnit > 0 && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="note">
                {Math.round((pricePerProducedUnit / totalCommodityUnitPrice) * 100 * 100) / 100}% of total commodity
                cost per unit
              </Tooltip>
            }
          >
            <span className="kt-font-muted">{baseUtils.formatEuro(pricePerProducedUnit)}/unit</span>
          </OverlayTrigger>
        )}
      </td>
      <td className={"kt-datatable__cell d-table-cell" + (replaceable ? "" : " text-right")}>
        <span
          className={
            "kt-font-bold " + (priceDifference < 0 ? "text-success" : priceDifference > 0 ? "text-danger" : "")
          }
        >
          <p className="kt-user-card-v2__name mb-0">
            {priceDifference > 0 && <i className="fa fa-exclamation mr-1" />}
            {priceDifference < 0 && <i className="fa fa-caret-down mr-1" />}
            {priceDifference === 0 && <i className="fa fa-caret-right mr-1" />}
            {priceDifference > 0 && "+"}
            {supplier === "customer" ? "-" : priceDifference.toFixed(2) + "%"}
          </p>
        </span>
      </td>
      {replaceable && (
        <td className="kt-datatable__cell d-table-cell text-right">
          <div className="btn-group">
            <button
              className={"btn btn-sm btn-secondary px-2 py-1 " + (canReplace ? "" : " disabled")}
              onClick={canReplace ? () => onReplaceCommodity(commodity) : undefined}
              disabled={!canReplace}
            >
              Replace
            </button>
            <RemoveMaterialModal material={commodity} order={order} type={"commodity"} showAsDropdown={true} />
          </div>
        </td>
      )}
      {showBatches && (
        <td className="kt-datatable__cell d-table-cell text-right" style={{ maxWidth: "8%" }}>
          <span>{batches.length === 0 ? "N/A" : batches.join(", ")}</span>
        </td>
      )}
    </tr>
  );
};

export default OrderCommodities;
