import React from "react";
import { Accordion } from "react-bootstrap";
import { DataContext } from "../../../context/dataContext";
import CommoditiesStockContent from "./CommoditiesStockContent";
import Pagination, { paginate } from "../../common/Pagination";
import { CommodityExtended } from "../StockListing";

interface CommoditiesStockAccordionListingProps {
  filteredCommodities: Array<CommodityExtended>;
  pageSize: number;
  currentPage: number;
  activeLevels: Array<boolean>;
  onContentToggle: (e: number) => void;
  onPageChange: (e: number) => void;
  onPageSizeChange: (e: number) => void;
  showDisabled: boolean;
  context: React.ContextType<typeof DataContext>;
}

const CommoditiesStockListing: React.FunctionComponent<CommoditiesStockAccordionListingProps> = ({
  filteredCommodities,
  pageSize,
  currentPage,
  activeLevels,
  onContentToggle,
  onPageChange,
  onPageSizeChange,
  showDisabled,
  context
}: CommoditiesStockAccordionListingProps) => {
  return (
    <>
      <Accordion activeKey={"1"}>
        {paginate(filteredCommodities, currentPage, pageSize).map((extendedCommodity, index) => {
          return (
            <CommoditiesStockContent
              isActive={activeLevels[index]}
              level={index}
              onToggleClick={onContentToggle}
              key={extendedCommodity.commodity._id.toString()}
              extendedCommodity={extendedCommodity}
              showDisabled={showDisabled}
              context={context}
            />
          );
        })}
      </Accordion>
      {filteredCommodities.length > 0 && (
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive px-4">
              <div className="kt-datatable__pager kt-datatable--paging-loaded justify-content-center">
                <Pagination
                  itemsCount={filteredCommodities.length}
                  pageSize={pageSize}
                  onPageChange={currentPage => onPageChange(currentPage)}
                  currentPage={currentPage}
                  onPageSizeChange={pageSize => onPageSizeChange(pageSize)}
                  additionalPageSizeClasses={"ml-2"}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CommoditiesStockListing;
