import _ from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { DataContext } from "../../../context/dataContext";
import DateInput from "../../common/DateInput";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { Invoice } from "../../../model/orders.types";
import baseUtils from "../../../utils/baseUtils";
import fileUtils from "../../../utils/fileUtils";
import {
  I_CANCELED,
  I_FREEPOSITION,
  I_OPEN,
  I_PAID,
  I_PARTLYPAID,
  I_POSITION,
  I_PAYMENTINADVANCE,
  I_REMINDER,
  I_SERVICE
} from "../../../utils/invoiceUtils";
import { CompaniesDocument } from "../../../model/companies.types";
import orderUtils from "../../../utils/orderUtils";

enum PeriodValues {
  TODAY,
  PREVIOUS_MONTH,
  CURRENT_YEAR,
  PREVIOUS_YEAR,
  CUSTOM
}

enum ExportMode {
  INVOICE_BOOK,
  OUTSTANDING_AMOUNTS,
  REVENUE
}

interface InvoiceContainer {
  relatedInvoice: Invoice;
  relatedCompany: string;
}

interface CompanyStatistics {
  id: string;
  companyName: string;
  overallPaid: number;
  remainingPositions: number;
}

const I_EXPORTMODEOPTIONS = [
  { value: ExportMode.INVOICE_BOOK, label: "Outgoing Invoice Book" },
  { value: ExportMode.OUTSTANDING_AMOUNTS, label: "Outstanding Amounts" },
  { value: ExportMode.REVENUE, label: "Revenue Statistics" }
];

const I_PERIODOPTIONS = [
  { value: PeriodValues.TODAY, label: "Today" },
  { value: PeriodValues.PREVIOUS_MONTH, label: "Previous Month" },
  { value: PeriodValues.CURRENT_YEAR, label: "Current Year" },
  { value: PeriodValues.PREVIOUS_YEAR, label: "Previous Year" },
  { value: PeriodValues.CUSTOM, label: "Custom" }
];

const I_STATEOPTIONS = [
  { value: I_OPEN, label: "Open" },
  { value: I_PAID, label: "Paid" },
  { value: I_PARTLYPAID, label: "Partly Paid" },
  { value: I_PAYMENTINADVANCE, label: "Payment In Advance" },
  { value: I_CANCELED, label: "Canceled" }
];

const I_POSITIONOPTIONS = [
  { value: I_POSITION, label: "General Positions" },
  { value: I_FREEPOSITION, label: "Free Positions" },
  { value: I_SERVICE, label: "Service Positions" },
  { value: I_REMINDER, label: "Reminder" }
];

interface ExportOutgoingInvoiceBookModalProps {
  context: React.ContextType<typeof DataContext>;
  additionalCSSClasses?: string;
}

interface ExportOutgoingInvoiceBookState {
  show: boolean;
  generating: boolean;
  errors: Array<string>;
  period: { value: PeriodValues; label: string };
  selectedMode: { value: ExportMode; label: string };
  selectedPositions: Array<{ value: string; label: string }>;
  selectedStates: Array<{ value: string; label: string }>;
  customTimePeriod: {
    from: Date | null;
    to: Date | null;
  };
}

const ExportOutgoingInvoiceBookModal: React.FunctionComponent<ExportOutgoingInvoiceBookModalProps> = ({
  context,
  additionalCSSClasses
}) => {
  const [modalState, setModalState] = useState<ExportOutgoingInvoiceBookState>({
    show: false,
    generating: false,
    errors: [],
    period: { value: PeriodValues.TODAY, label: "Today" },
    selectedMode: I_EXPORTMODEOPTIONS[0],
    selectedStates: [
      { value: I_PAID, label: "Paid" },
      { value: I_PARTLYPAID, label: "Partly Paid" }
    ],
    selectedPositions: [
      { value: I_POSITION, label: "General Positions" },
      { value: I_REMINDER, label: "Reminder" }
    ],
    customTimePeriod: {
      from: null,
      to: null
    }
  });
  const { show, errors, generating, period, selectedMode, selectedPositions, selectedStates, customTimePeriod } =
    modalState;

  useEffect(() => {
    getErrors();
  }, [customTimePeriod, period, selectedPositions, selectedStates, selectedMode]);

  const handleShow = () => {
    setModalState(prevState => {
      return {
        ...prevState,
        show: true
      };
    });
  };

  const handlePeriodChange = (e: any) => {
    setModalState(prevState => {
      return {
        ...prevState,
        period: e
      };
    });
  };

  const handleExportModeChange = (e: any) => {
    setModalState(prevState => {
      return {
        ...prevState,
        selectedMode: e
      };
    });
  };

  const handleStateChange = (e: any) => {
    setModalState(prevState => {
      return {
        ...prevState,
        selectedStates: e ? e : []
      };
    });
  };

  const handlePositionChange = (e: any) => {
    setModalState(prevState => {
      return {
        ...prevState,
        selectedPositions: e ? e : []
      };
    });
  };

  const handleClose = () => {
    setModalState(prevState => {
      return {
        ...prevState,
        show: false
      };
    });
  };

  const handleCustomTimeChange = (type: string, e: Date) => {
    setModalState(prevState => {
      let customTimePeriod;
      if (type === "to") {
        customTimePeriod = {
          ...prevState.customTimePeriod,
          to: e
        };
      } else {
        customTimePeriod = {
          ...prevState.customTimePeriod,
          from: e
        };
      }
      return {
        ...prevState,
        customTimePeriod: customTimePeriod
      };
    });
  };

  /**
   * Exporting Outgoing invoice book as csv
   */
  const handleExport = () => {
    const exportMode = selectedMode.value;
    let startDate: Date;
    let endDate: Date;
    const actualDate = new Date();

    // calculated the selected time period
    switch (period.value) {
      case PeriodValues.TODAY:
        startDate = new Date(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate(), 0, 0);
        endDate = actualDate;
        break;
      case PeriodValues.CURRENT_YEAR:
        startDate = new Date(actualDate.getFullYear(), 0, 1);
        endDate = actualDate;
        break;
      case PeriodValues.PREVIOUS_MONTH:
        // In case of underflow the december of the year before is selected
        startDate = new Date(actualDate.getFullYear(), actualDate.getMonth() - 1, 1);
        endDate = new Date(actualDate.getFullYear(), actualDate.getMonth(), 1);
        break;
      case PeriodValues.PREVIOUS_YEAR:
        startDate = new Date(actualDate.getFullYear() - 1, 0, 1);
        endDate = new Date(actualDate.getFullYear(), 0, 1);
        break;
      case PeriodValues.CUSTOM:
        startDate = customTimePeriod.from ? customTimePeriod.from : new Date();
        endDate = customTimePeriod.to ? customTimePeriod.to : new Date();
        break;
    }

    // receive the related invoices
    const { orders, companies } = context;
    let filteredInvoiceContainer = [] as Array<InvoiceContainer>;
    const companyStatistics = [] as Array<CompanyStatistics>;
    orders.forEach(order => {
      if (order.invoices) {
        order.invoices.forEach(invoice => {
          if (
            invoice.invoiceDate.getTime() >= startDate.getTime() &&
            invoice.invoiceDate.getTime() <= endDate.getTime() &&
            (selectedStates.map(state => state.value).includes(invoice.state) ||
              (selectedStates.map(state => state.value).includes(I_PAYMENTINADVANCE) && invoice.dueIn === -1)) // payment in advanced is represented as dueIn === -1
          ) {
            const relatedCompany: CompaniesDocument | undefined = baseUtils.getDocFromCollection(
              companies,
              order.createdFor
            );
            if (relatedCompany !== undefined) {
              // avoiding duplications
              if (!companyStatistics.find(statistic => statistic.id === relatedCompany._id.toString())) {
                companyStatistics.push({
                  id: relatedCompany._id.toString(),
                  companyName: relatedCompany.name,
                  overallPaid: 0,
                  remainingPositions: 0
                });
              }
              filteredInvoiceContainer.push({ relatedInvoice: invoice, relatedCompany: relatedCompany._id.toString() });
            }
          }
        });
      }
    });

    if (filteredInvoiceContainer.length > 0) {
      // ordering invoice by date
      filteredInvoiceContainer = _.orderBy(
        filteredInvoiceContainer,
        invoice => invoice.relatedInvoice.invoiceDate.getTime(),
        "asc"
      );

      // building related csv file
      const invoiceBookContent: Array<Array<string>> = [];
      filteredInvoiceContainer.forEach(invoice => {
        let paid = 0;

        // Adding the overall Invoice Part
        invoiceBookContent.push([
          "Rechnung",
          invoice.relatedInvoice.state,
          baseUtils.formatDate(invoice.relatedInvoice.invoiceDate),
          invoice.relatedInvoice.dueIn !== -1
            ? "RE-" + String(invoice.relatedInvoice.invoiceNumber)
            : String(invoice.relatedInvoice.invoiceNumber) + "-A",
          baseUtils.formatStringForCSV(invoice.relatedInvoice.pdfData.customer),
          '""', // for intently inserting empty values
          '""',
          '""',
          '""',
          '""'
        ]);

        // Adding related positions
        invoice.relatedInvoice.positions.forEach(position => {
          // check if position meets selected parameters
          if (selectedPositions.map(position => position.value).includes(position.type)) {
            const vat = Number((position.total * (position.vat / 100)).toFixed(2));
            const totalPositionValue = position.total + (!invoice.relatedInvoice.reverseCharge ? vat : 0);
            invoiceBookContent.push([
              "",
              "",
              "",
              "",
              "",
              baseUtils.formatStringForCSV(position.type),
              baseUtils.formatStringForCSV(position.description), // avoiding breaking columns
              baseUtils.formatEuroForCSV(position.total),
              baseUtils.formatEuroForCSV(vat) +
                " (" +
                (position.vat + "%") +
                (invoice.relatedInvoice.reverseCharge ? " Reverse-Charge)" : ")"),
              baseUtils.formatEuroForCSV(totalPositionValue)
            ]);

            // collecting remaining positions for company statistics
            if (invoice.relatedInvoice.state === "open") {
              // this statistic is only feasible for open invoices
              const index = companyStatistics.findIndex(statistic => statistic.id === invoice.relatedCompany);
              if (
                index !== -1 &&
                orderUtils.getInvoiceDue(invoice.relatedInvoice.dueIn, invoice.relatedInvoice.invoiceDate).getTime() <=
                  new Date(actualDate.getFullYear() + 1, 0, 1).getTime()
              ) {
                const relatedCompanyStatic = _.cloneDeep(companyStatistics[index]);
                relatedCompanyStatic.remainingPositions += totalPositionValue;
                companyStatistics[index] = relatedCompanyStatic;
              }
            }
          }
        });

        // adding existing reminder to positions (not listed in the overall invoice positions state)
        let accumulatedReminderValue = 0;
        invoice.relatedInvoice.reminder.forEach(reminder => {
          if (reminder.position) {
            if (selectedPositions.map(position => position.value).includes("reminder")) {
              invoiceBookContent.push([
                "",
                "",
                "",
                "",
                "",
                "reminder",
                baseUtils.formatStringForCSV(reminder.position.description), // avoiding breaking columns
                "",
                "",
                baseUtils.formatEuroForCSV(reminder.position.total)
              ]);
              accumulatedReminderValue += reminder.position.total;
            }
          }
        });

        // Adding related payments
        invoice.relatedInvoice.payments.forEach(payment => {
          invoiceBookContent.push([
            "",
            "",
            "",
            "",
            "",
            "Zahlung",
            baseUtils.formatDate(payment.date),
            "",
            "",
            baseUtils.formatEuroForCSV(payment.amount)
          ]);
          paid += payment.amount;

          // collecting overall paid (Turnover) for company statistics
          const index = companyStatistics.findIndex(statistic => statistic.id === invoice.relatedCompany);
          if (index !== -1) {
            const relatedCompanyStatic = _.cloneDeep(companyStatistics[index]);
            relatedCompanyStatic.overallPaid += payment.amount;
            companyStatistics[index] = relatedCompanyStatic;
          }
        });

        // Adding saldo
        const saldo = invoice.relatedInvoice.totalGross + accumulatedReminderValue - paid;
        invoiceBookContent.push([
          "Saldo",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          baseUtils.formatEuroForCSV(Number(saldo.toFixed(2)))
        ]);
      });

      // generating csv
      try {
        const invoiceBookHeaders = [
          "Rechnung/Saldo", // Rechnung or Saldo header
          "Status",
          "Datum",
          "Auftrag",
          "Kunde",
          "Postion/Zahlung", // Position or Payment header
          "Postionsbezeichner/Zahldatum",
          "Netto",
          "MwSt",
          "Brutto"
        ];
        const openPositionsHeaders = ["Kunde", "Offener Gesamtbetrag"];
        const revenueHeaders = ["Kunde", "Umsatz"];
        const companyStatisticsRevenue = companyStatistics
          .sort((a, b) => b.overallPaid - a.overallPaid)
          .map(statistic => {
            const name = baseUtils.formatStringForCSV(statistic.companyName);
            const value = baseUtils.formatEuroForCSV(statistic.overallPaid);
            return [name, value];
          });
        const companyStatisticsOpenPositions = companyStatistics
          .sort((a, b) => b.remainingPositions - a.remainingPositions)
          .map(statistic => {
            const name = baseUtils.formatStringForCSV(statistic.companyName);
            const value = baseUtils.formatEuroForCSV(statistic.remainingPositions);
            return [name, value];
          });

        let csv: string;
        let fileName: string;
        switch (exportMode) {
          case ExportMode.INVOICE_BOOK:
            fileName = "Rechnungsausgangsbuch.csv";
            csv = fileUtils.exportAsCSV(invoiceBookHeaders, invoiceBookContent);
            break;
          case ExportMode.OUTSTANDING_AMOUNTS:
            fileName = "OffenerAußenstand.csv";
            csv = fileUtils.exportAsCSV(openPositionsHeaders, companyStatisticsOpenPositions);
            break;
          case ExportMode.REVENUE:
            fileName = "Umsatzliste.csv";
            csv = fileUtils.exportAsCSV(revenueHeaders, companyStatisticsRevenue);
            break;
        }

        fileUtils.downloadFile(csv, fileName, "text/plain");
      } catch (e) {
        toast.error("Error generating CSV");
        console.error(e);
      }
    } else {
      toast.info("No Content found with the given parameters");
    }
  };

  const getErrors = () => {
    const errors = [] as Array<string>;

    if (period.value === PeriodValues.CUSTOM) {
      if (!customTimePeriod.from) {
        errors.push("Start date is missing");
      }

      if (!customTimePeriod.to) {
        errors.push("End date is missing");
      }

      if (
        customTimePeriod.from &&
        customTimePeriod.to &&
        customTimePeriod.from.getTime() >= customTimePeriod.to.getTime()
      ) {
        errors.push("The start date is greater or equal than the end date");
      }
    }

    if (selectedStates.length === 0) {
      errors.push("Please select at least one invoice state type");
    }

    if (selectedPositions.length === 0) {
      errors.push("Please select at least one invoice position type");
    }

    setModalState(prevState => {
      return {
        ...prevState,
        errors: errors
      };
    });
  };
  return (
    <>
      <button
        className={"btn btn-secondary kt-margin-r-10" + (additionalCSSClasses ? additionalCSSClasses : "")}
        onClick={handleShow}
      >
        <i className="fa fa-list" />
        Export Invoice Statistics
      </button>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="kt-font-brand fa fa-list mr-1" />
            Export Invoice Statistics
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-xl-12">
                <div className="kt-section kt-section--first">
                  <div className="kt-section__body">
                    <div className="mt-2 form-group row ">
                      <label className="col-3 col-form-label kt-font-dark kt-font-bolder">State(s)</label>
                      <div className="col-9">
                        <Select
                          className="select-default"
                          isMulti={true}
                          options={I_STATEOPTIONS}
                          value={selectedStates}
                          onChange={handleStateChange}
                        />
                      </div>
                    </div>
                    <div className="mt-2 form-group row ">
                      <label className="col-3 col-form-label kt-font-dark kt-font-bolder">Position(s)</label>
                      <div className="col-9">
                        <Select
                          className="select-default"
                          isMulti={true}
                          options={I_POSITIONOPTIONS}
                          value={selectedPositions}
                          onChange={handlePositionChange}
                        />
                      </div>
                    </div>
                    <div className="mt-2 form-group row ">
                      <label className="col-3 col-form-label kt-font-dark kt-font-bolder">Period</label>
                      <div className="col-9">
                        <Select
                          className="select-default"
                          options={I_PERIODOPTIONS}
                          value={period}
                          onChange={handlePeriodChange}
                        />
                      </div>
                    </div>
                    {period.value === PeriodValues.CUSTOM && (
                      <div className="mt-2 form-group row">
                        <label className="col-3 col-form-label kt-font-dark kt-font-bolder">Custom Period</label>
                        <div className="col-4">
                          <DateInput
                            value={customTimePeriod.from}
                            onBlur={e => handleCustomTimeChange("from", new Date(e.target.valueAsDate!))}
                            name="from"
                          />
                        </div>
                        <div className="col-1 align-self-center">
                          <span className="kt-font-dark kt-font-bolder">-</span>
                        </div>
                        <div className="col-4">
                          <DateInput
                            value={customTimePeriod.to}
                            onBlur={e => handleCustomTimeChange("to", new Date(e.target.valueAsDate!))}
                            name="to"
                          />
                        </div>
                      </div>
                    )}
                    <div className="mt-2 form-group row ">
                      <label className="col-3 col-form-label kt-font-dark kt-font-bolder">Export Mode</label>
                      <div className="col-9">
                        <Select
                          className="select-default"
                          options={I_EXPORTMODEOPTIONS}
                          value={selectedMode}
                          onChange={handleExportModeChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={"btn btn-secondary " + (generating && "disabled")}
            disabled={generating}
            onClick={handleClose}
          >
            Close
          </button>
          <ErrorOverlayButton
            errors={errors}
            saving={generating}
            className="btn btn-success"
            buttonText="Export"
            onClick={handleExport}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExportOutgoingInvoiceBookModal;
