import _ from "lodash";
import React, { PureComponent } from "react";
import Select from "react-select";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import manufacturerUtils from "../../utils/manufacturerUtils";
import { OrdersDocument } from "../../model/orders.types";
import HistoryBackButton from "../listings/common/HistoryBackButton";
import SplashScreen from "../common/SplashScreen";
import { ManufacturerFilter, OwnerFilter, PriorityFilter, SearchBar } from "../listings/common/Filters";
import orderUtils, { ORDERORDERCOMMODITIES, PRODUCTION, PRODUCTIONQUEUE, WAITING } from "../../utils/orderUtils";
import { T_CAPSULE, T_CUSTOM, T_LIQUID, T_POWDER, T_SERVICE, T_SOFTGEL, T_TABLET } from "../order/OrderHelper";
import dateUtils from "../../utils/dateUtils";
import ProductionOrdersListing from "./ProductionOrdersListing";
import PlanOrderModal from "./PlanOrderModal";
import { getComponentState } from "../../utils/baseUtils";

interface ProductionOrdersProps extends RouteComponentProps {
  context: React.ContextType<typeof DataContext>;
}

interface ProductionOrdersState {
  search: string;
  owner: "" | { value: string; label: string };
  manufacturer: "" | { value: string; label: string };
  priority: "" | { value: string; label: string };
  state: "" | { value: string; label: string };
  volume: "" | { value: string; label: string };
  manufacturerLocked: boolean;
  standardView: boolean;
  showProduction: boolean;
  relevantOrders: Array<OrdersDocument>;
  orderMap: object | null;
  orderToPlan: OrdersDocument | null;
  sortValue: { value: string; label: string };
  sortOrder: { value: "desc"; label: "Descending" } | { value: "asc"; label: "Ascending" };
}

const CONSTRUCTORNAME = "ProductionOrders";

class ProductionOrders extends PureComponent<ProductionOrdersProps, ProductionOrdersState> {
  constructor(props: ProductionOrdersProps) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  componentDidMount() {
    const relevantOrders = this.getRelevantOrders();
    const filteredOrders = this.getFilteredOrders(relevantOrders);
    const state = getComponentState(this.props.context, CONSTRUCTORNAME);
    if (state) {
      state.relevantOrders = relevantOrders;
      this.setState({ ...state });
    } else {
      this.setState({
        relevantOrders,
        orderMap: this.getOrdersByType(filteredOrders)
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<ProductionOrdersProps>,
    prevState: Readonly<ProductionOrdersState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.context.orders, this.props.context.orders)) {
      const relevantOrders = this.getRelevantOrders();
      const filteredOrders = this.getFilteredOrders(relevantOrders);
      this.setState({
        relevantOrders,
        orderMap: this.getOrdersByType(filteredOrders)
      });
    }
    if (
      prevState.search !== this.state.search ||
      prevState.owner !== this.state.owner ||
      prevState.priority !== this.state.priority ||
      prevState.state !== this.state.state ||
      prevState.volume !== this.state.volume ||
      prevState.sortValue !== this.state.sortValue ||
      prevState.sortOrder !== this.state.sortOrder ||
      prevState.showProduction !== this.state.showProduction ||
      (!this.state.manufacturerLocked && prevState.manufacturer !== this.state.manufacturer)
    ) {
      const filteredOrders = this.getFilteredOrders(this.state.relevantOrders);
      this.setState({ orderMap: this.getOrdersByType(filteredOrders) });
    }
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }

  handleReset = () => this.setState(this.getDefaultState(this.props, true));
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });
  handleSelectOrder = (order: OrdersDocument) => this.setState({ orderToPlan: order });
  handleClose = () => this.setState({ orderToPlan: null });
  handleSelectChange = (name: string, entry: "" | { value: string; label: string }) => {
    // @ts-ignore
    this.setState({ [name]: entry ? { value: entry.value, label: entry.label } : "" });
  };

  /**
   * Get the default state
   * @param props the components properties
   * @param reset flag if it is a reset or not. on reset orders are kept
   * @returns {ProductionOrdersState} default state for the component
   */
  getDefaultState = (props: ProductionOrdersProps, reset?: boolean) => {
    const manufacturer = manufacturerUtils.checkCurrentUserManufacturerObject(props.context.manufacturers);
    const relevantOrders = reset ? this.state.relevantOrders : [];
    const orderMap = reset ? this.state.orderMap : null;
    return {
      search: "",
      owner: "",
      priority: "",
      state: "",
      volume: "",
      manufacturer: manufacturer,
      manufacturerLocked: manufacturer !== "",
      standardView: true,
      showProduction: true,
      relevantOrders,
      orderMap,
      orderToPlan: null,
      sortValue: { value: "", label: "Default" },
      sortOrder: { value: "desc", label: "Descending" }
    } as ProductionOrdersState;
  };

  /**
   * Get only relevant orders, i.e. in correct state, no service and if manufacturer
   * is locked only orders of that manufacturer
   * @returns {Array<OrdersDocument>} list of relevant orders
   */
  getRelevantOrders = () => {
    const { manufacturer, manufacturerLocked } = this.state;
    return this.props.context.orders.filter(
      o =>
        [ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION].includes(o.state) &&
        o.settings.type !== T_SERVICE &&
        (!manufacturerLocked ||
          o.settings.manufacturer.toString() === (manufacturer ? manufacturer.value : manufacturer))
    );
  };

  /**
   * Get filtered orders
   * @param relevantOrders list of relevant orders to filter
   * @returns {Array<OrdersDocument>} list of all orders matching the filters
   */
  getFilteredOrders = (relevantOrders: Array<OrdersDocument>) => {
    const { companies, userdata, packagings } = this.props.context;
    const { search, owner, manufacturer, priority, volume, state, manufacturerLocked, showProduction } = this.state;
    let filteredOrders = relevantOrders.slice();
    if (!showProduction) filteredOrders = filteredOrders.filter(o => o.state !== PRODUCTION);
    filteredOrders = orderUtils.filterOrders(
      filteredOrders,
      undefined,
      owner ? owner.value : "",
      priority ? priority.value : "",
      undefined,
      manufacturerLocked ? undefined : manufacturer ? manufacturer.value : ""
    );
    if (volume)
      filteredOrders = filteredOrders.filter(o => {
        const units = +o.calculations[0].units;
        switch (volume.value) {
          case "sm":
            return units <= 2000;
          case "md":
            return units > 2000 && units <= 5000;
          case "lg":
            return units > 5000;
        }
      });
    if (state)
      filteredOrders = filteredOrders.filter(o => {
        const cond = orderUtils.isReadyForProduction(o, packagings);
        switch (state.value) {
          case "ready":
            return cond && (!o.productionWeek || !o.settings.productionMachine);
          case "readyPlanned":
            return cond;
          case "notReady":
            return !cond;
          case "woPlanned":
            return !o.productionWeek && !o.settings.productionMachine;
          case "overdue":
            return o.targetDate && dateUtils.getCW(o.targetDate) <= dateUtils.getCW(new Date());
          case "comOnStock":
            return o.calculations[0].prices.every(p => p.delivered);
        }
      });
    if (search.trim() !== "") {
      filteredOrders = orderUtils.filterBySearch(filteredOrders, companies, userdata, search) as Array<OrdersDocument>;
    }
    return filteredOrders;
  };

  /**
   * Build an initial map with keys for all types including capsule sizes etc.
   * @returns {[object, object]} initial empty map for dividing orders by their type + additional key map to match capsule/tablet ids to their keys
   */
  getInitialMap = () => {
    const { capsules, tablets } = this.props.context;
    const map: any = { Powder: [], Liquid: [], Softgel: [], Custom: [] };
    let keyMap: any = {};
    for (let capsule of capsules) {
      const key = "Capsule " + capsule.capsule_size;
      map[key] = [];
      keyMap[capsule._id.toString()] = key;
    }
    for (let tablet of tablets) {
      const key = `Tablet ${tablet.volume}ml, ${tablet.shape}`;
      map[key] = [];
      keyMap[tablet._id.toString()] = key;
    }
    return [map, keyMap];
  };

  /**
   * Get the key for the map according to their type
   * @param order an order document
   * @param keyMap the key map to get keys for capsules and tablets from
   * @returns {string} the key for order map
   */
  getMapKey = (order: OrdersDocument, keyMap: any) => {
    switch (order.settings.type) {
      case T_POWDER:
        return "Powder";
      case T_LIQUID:
        return "Liquid";
      case T_CAPSULE:
      case T_TABLET:
        return keyMap[order.settings.id.toString()];
      case T_SOFTGEL:
        return "Softgel";
      case T_CUSTOM:
        return "Custom";
    }
  };

  /**
   * Get filtered orders divided by their type
   * @param orders list of orders to map
   * @returns {object} orders split by their type including capsule size, etc.
   */
  getOrdersByType = (orders: Array<OrdersDocument>) => {
    const sortedOrders = this.getSortedOrders(orders);
    const [map, keyMap] = this.getInitialMap();
    for (let i = 0; i < sortedOrders.length; i++) {
      const order = sortedOrders[i];
      const key = this.getMapKey(order, keyMap);
      map[key].push(order);
    }
    return map;
  };

  /**
   * Sort orders
   * @param orders orders to sort
   * @returns {Array<OrdersDocument>} list of sorted orders
   */
  getSortedOrders = (orders: Array<OrdersDocument>) => {
    const { sortOrder, sortValue } = this.state;
    switch (sortValue.value) {
      case "CW":
        return _.orderBy(orders, o => (o.targetDate ? o.targetDate : Infinity), sortOrder.value);
      default:
        return _.orderBy(
          orders,
          o => {
            const ready = orderUtils.isReadyForProduction(o, this.props.context.packagings);
            return +ready + (o.productionWeek && o.settings.productionMachine && ready ? 1 : 0);
          },
          sortOrder.value
        );
    }
  };

  /**
   * Get a matching icon for the listing type
   * @param type type of the listing
   * @returns {JSX.Element} img component with icon acccording to the type
   */
  getIcon = (type: string) => {
    let fileName = "";
    if (type.includes("Powder")) fileName = T_POWDER;
    else if (type.includes("Liquid")) fileName = T_LIQUID;
    else if (type.includes("Softgel")) fileName = T_SOFTGEL;
    else if (type.includes("Custom")) fileName = T_CUSTOM;
    else if (type.includes("Capsule")) fileName = T_CAPSULE;
    else if (type.includes("Tablet")) fileName = T_TABLET;

    return (
      <img
        src={process.env.PUBLIC_URL + "/media/icons/" + fileName + ".png"}
        alt={fileName}
        className="py-1 px-0"
        style={{ height: "45px" }}
      />
    );
  };

  render() {
    const { history, context } = this.props;
    const { orders, userdata, manufacturers } = context;
    const {
      owner,
      manufacturer,
      manufacturerLocked,
      priority,
      volume,
      state,
      standardView,
      orderToPlan,
      orderMap,
      sortOrder,
      sortValue,
      search,
      showProduction
    } = this.state;
    const selectableUsers = userdata.filter(user => user.company_id === "internal");
    return (
      <>
        <PlanOrderModal
          order={orderToPlan}
          context={context}
          onClose={this.handleClose}
          reschedule={!!orderToPlan && !!orderToPlan.settings.productionMachine && !!orderToPlan?.productionWeek}
        />
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand flaticon2-search" />
              </span>
              <h3 className="kt-portlet__head-title">Toolbar</h3>
              <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
                Reset
              </button>
            </div>
            <HistoryBackButton history={history} />
          </div>
          <div className="kt-portlet__body pb-0">
            <div className="kt-form kt-form--label-right kt-margin-b-10">
              <div className="row align-items-center">
                <SearchBar onSearch={this.handleSearch} search={search} />
                <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                  <Select
                    className="select-default"
                    options={[
                      { value: "ready", label: "Everything Ready" },
                      { value: "readyPlanned", label: "Ready and Planned" },
                      { value: "notReady", label: "Not Ready" },
                      { value: "woPlanned", label: "Not Planned" },
                      { value: "overdue", label: "Overdue Orders" },
                      { value: "comOnStock", label: "Commodities on Stock" }
                    ]}
                    value={state ? state : { value: "all", label: "All Statuses" }}
                    isClearable={true}
                    onChange={(value: any) => this.handleSelectChange("state", value || "")}
                  />
                </div>
                <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                  <Select
                    className="select-default"
                    options={[
                      { value: "sm", label: "Small Volume (-2000 Units)" },
                      { value: "md", label: "Medium Volume (2001-5000 Units)" },
                      { value: "lg", label: "Large Volume (5001+ Units" }
                    ]}
                    value={volume ? volume : { value: "all", label: "All Volumes" }}
                    isClearable={true}
                    onChange={(value: any) => this.handleSelectChange("volume", value || "")}
                  />
                </div>
                <OwnerFilter
                  owner={owner}
                  selectableUsers={selectableUsers}
                  onFilterChange={this.handleSelectChange}
                  noLabel={true}
                />
                <PriorityFilter priority={priority} onFilterSelect={this.handleSelectChange} noLabel={true} />
                <ManufacturerFilter
                  manufacturer={manufacturer}
                  manufacturerLocked={manufacturerLocked}
                  manufacturers={manufacturers}
                  onFilterSelect={this.handleSelectChange}
                  noLabel={true}
                  additionalSizeClasses={"col-md-2"}
                />
              </div>
              <div className="row align-items-center kt-margin-t-10">
                <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                  <Select
                    className="select-default"
                    options={[
                      { value: "", label: "Default" },
                      { value: "CW", label: "Calendar Week" }
                    ]}
                    value={sortValue}
                    onChange={(value: any) => this.handleSelectChange("sortValue", value)}
                  />
                </div>
                <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                  <Select
                    className="select-default"
                    options={[
                      { value: "desc", label: "Descending" },
                      { value: "asc", label: "Ascending" }
                    ]}
                    value={sortOrder}
                    onChange={(value: any) => this.handleSelectChange("sortOrder", value)}
                  />
                </div>
              </div>
              <div className="row align-items-center kt-margin-t-10">
                <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                  <span className="kt-font-dark  kt-font-lg">
                    Current Calendar Week: <span className="kt-font-bolder">{dateUtils.getCW(new Date())}</span>
                  </span>
                </div>
                <div className="col-md-10 kt-margin-b-20-tablet-and-mobile">
                  <div className="float-right">
                    <label className="kt-checkbox kt-font-dark m-0 p-0 px-4">
                      <input
                        type="checkbox"
                        checked={showProduction}
                        onChange={() => this.setState({ showProduction: !showProduction })}
                      />
                      <span />
                      <div className="pl-1 kt-font-dark">Show orders in production</div>
                    </label>
                    <label className="kt-checkbox kt-font-dark m-0 p-0 px-4">
                      <input
                        type="checkbox"
                        checked={standardView}
                        onChange={() => this.setState({ standardView: !standardView })}
                      />
                      <span />
                      <div className="pl-1 kt-font-dark">Standard view</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {orders.length === 0 || !orderMap ? (
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__body pb-0">
              <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
            </div>
          </div>
        ) : (
          <>
            {Object.entries(orderMap)
              .sort()
              .map(([type, orders]) => (
                <ProductionOrdersListing
                  key={type}
                  type={type}
                  standardView={standardView}
                  orders={orders as Array<OrdersDocument>}
                  context={context}
                  icon={this.getIcon(type)}
                  onSelectOrder={this.handleSelectOrder}
                />
              ))}
          </>
        )}
      </>
    );
  }
}

export default ProductionOrders;
