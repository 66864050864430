import _ from "lodash";
import i18n from "i18next";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { CustomerData, CustomOrder, DeliveryNoteData, DeliveryNotePosition, ShippingGroupType } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import OrderHelper from "../OrderHelper";
import CustomerDataComponent, { BareCustomerData } from "./common/CustomerData";
import orderUtils from "../../../utils/orderUtils";
import dateUtils from "../../../utils/dateUtils";
import PDFPreview from "../../common/PDFPreview";
import deliveryInformationGeneration from "../../../utils/pdf/deliveryInformationGeneration";
import config from "../../../config/config.json";
import userService from "../../../services/userService";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import ShippingGroup from "./common/ShippingGroup";
import { T_DELIVERYINFORMATION } from "../../../utils/timelineUtils";
import notificationService, { R_ORDERFILEUPLOAD } from "../../../services/notificationService";
import BioNumberSelection from "./common/BioNumberSelection";
import LanguageSelectionDropdown from "../../common/LanguageSelectionDropdown";
import findAndReplaceTranslation from "../../../utils/translationUtils";
import { getNumericValue } from "../../../utils/baseUtils";

interface DateFormatOption {
  value: string;
  label: string;
  locale: string;
  object: Intl.DateTimeFormatOptions;
}

interface CreateDeliveryNoteModalProps {
  order: CustomOrder;
  disabled?: boolean;
  context: React.ContextType<typeof DataContext>;
}

interface CreateDeliveryNoteModalState {
  show: boolean;
  uploading: boolean;
  deviatingDelivery: boolean;
  deviatingAddress: CustomerData | null;
  customerData: CustomerData;
  pdfData: DeliveryNoteData;
  positions: Array<DeliveryNotePosition>;
  shippingGroups: Array<ShippingGroupType>;
  exp: Date;
  dateFormat: DateFormatOption;
}

const DEFAULT_DATE_FORMAT: DateFormatOption = {
  value: "mm/yyyy",
  label: "mm/yyyy",
  locale: "en-US",
  object: {
    year: "numeric",
    month: "2-digit",
    day: undefined
  }
};

const DATE_FORMATS: Array<DateFormatOption> = [
  DEFAULT_DATE_FORMAT,
  {
    value: "dd/mm/yyyy",
    label: "dd/mm/yyyy",
    locale: "en-GB",
    object: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }
  },
  {
    value: "mm.yyyy",
    label: "mm.yyyy",
    locale: "de-DE",
    object: {
      year: "numeric",
      month: "2-digit",
      day: undefined
    }
  },
  {
    value: "dd.mm.yyyy",
    label: "dd.mm.yyyy",
    locale: "de-DE",
    object: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }
  }
];

enum DeliveryNoteTranslationType {
  NOTE,
  POSITION,
  FREE_POSITION
}

class CreateDeliveryNoteModal extends PureComponent<CreateDeliveryNoteModalProps, CreateDeliveryNoteModalState> {
  constructor(props: CreateDeliveryNoteModalProps) {
    super(props);
    this.state = this.getDefaultStateValues(props);
  }

  componentDidUpdate(
    prevProps: Readonly<CreateDeliveryNoteModalProps>,
    prevState: Readonly<CreateDeliveryNoteModalState>
  ) {
    if ((this.state.show && !_.isEqual(prevProps.order, this.props.order)) || (!prevState.show && this.state.show)) {
      const { pdfData, customerData, positions, shippingGroups, deviatingDelivery, deviatingAddress, exp } =
        this.getDefaultStateValues(this.props);
      this.setState({ pdfData, customerData, positions, shippingGroups, deviatingAddress, deviatingDelivery, exp });
    }
  }

  /**
   * Get the default state values
   * @param props the modal properties
   * @returns {CreateDeliveryNoteModalState} default state values
   */
  getDefaultStateValues = (props: CreateDeliveryNoteModalProps): CreateDeliveryNoteModalState => {
    const { order, context } = props;
    const calculation = order.calculations[0];
    const expDate = new Date();
    const exp = order.fulfillment?.exp || new Date(expDate.setFullYear(expDate.getFullYear() + 2));
    const customerData = OrderHelper.getCustomerData(order);
    const hasFfInfo = orderUtils.hasFulfillmentPriceInfo(order);
    const pdfData: DeliveryNoteData = {
      title: order.title,
      subtitle: order.subtitle || "",
      note: i18n.t("template:deliveryNote"),
      bioNumber: "",
      trackingNumber: "",
      creationDate: new Date()
    };
    const positions: any = [
      {
        id: new BSON.ObjectId(),
        type: "position",
        description: orderUtils.getProductDescription(order, context),
        amount: hasFfInfo ? order.fulfillment?.totalUnits!.toString() : calculation.units.toString()
      }
    ];
    const shippingGroups = order.fulfillment
      ? order.fulfillment?.shippingGroups.map(sg => {
          return {
            id: BSON.ObjectId.isValid(sg.id) ? new BSON.ObjectId(sg.id) : new BSON.ObjectId(),
            number: sg.number,
            boxes: sg.boxes.toString(),
            items: sg.items.toString(),
            weight: sg.weight.toString()
          };
        })
      : [];
    return {
      pdfData,
      customerData,
      positions,
      shippingGroups,
      deviatingAddress: null,
      deviatingDelivery: false,
      exp,
      show: false,
      uploading: false,
      dateFormat: DEFAULT_DATE_FORMAT
    };
  };

  /**
   * Use props and state to create data sheet pdf html
   * @returns {string} html representation for data sheet pdf
   */
  doCreatePDF = () => {
    const { order } = this.props;
    const { customerData, pdfData, positions, shippingGroups, deviatingDelivery, deviatingAddress, exp, dateFormat } =
      this.state;
    return deliveryInformationGeneration.createDeliveryInformation(
      order,
      customerData,
      pdfData,
      positions,
      shippingGroups,
      exp,
      deviatingDelivery && deviatingAddress ? deviatingAddress : undefined,
      { locale: dateFormat.locale, options: dateFormat.object }
    );
  };

  /**
   * Function to create the new translation string corresponding to the language, without deleting possible user extensions
   * @param key the key of the datasheet locals file
   * @param content the content string of an input field
   * @param translationType the type of the translation
   * @returns {string} the content string with changed language, if it was detectable
   */
  translateModalContent = (key: string, content: string, translationType: DeliveryNoteTranslationType) => {
    const { order, context } = this.props;

    // replace standard translation
    let result = findAndReplaceTranslation(key, content, "template");

    // replace deeper commodity descriptions
    switch (translationType) {
      case DeliveryNoteTranslationType.POSITION:
        result = orderUtils.getProductDescription(order, context, i18n.language);
        break;
      case DeliveryNoteTranslationType.FREE_POSITION:
        result = i18n.t("common:description", { lng: i18n.language });
        break;
    }
    return result;
  };

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });

  handleCustomerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const customerData = { ...this.state.customerData };
    const key = e.target.name as keyof CustomerData;
    customerData[key] = e.target.value;
    this.setState({ customerData });
  };

  handleDeliveryDataChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const pdfData = { ...this.state.pdfData };
    const key = e.target.name as keyof DeliveryNoteData;
    // @ts-ignore
    pdfData[key] = e.target.value;
    this.setState({ pdfData });
  };

  handlePDFDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pdfData = { ...this.state.pdfData };
    pdfData.creationDate = e.target.valueAsDate || pdfData.creationDate;
    this.setState({ pdfData });
  };

  handlePositionValueChange = (id: BSON.ObjectId) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const positions = _.cloneDeep(this.state.positions);
    const position = positions.find(p => p.id.toString() === id.toString())!;
    const key = e.target.name as keyof DeliveryNotePosition;
    if (key === "amount") {
      const value = getNumericValue(e as React.ChangeEvent<HTMLInputElement>, true);
      if (!value) return;
      // @ts-ignore
      position[key] = value;
    }
    // @ts-ignore
    else position[key] = e.target.value;
    this.setState({ positions });
  };

  handlePositionRemove = (id: BSON.ObjectId) => {
    const positions = _.cloneDeep(this.state.positions);
    if (positions.length < 2) return;
    this.setState({ positions: positions.filter(p => p.id.toString() !== id.toString()) });
  };

  handleAddFreePosition = () => {
    const positions = [...this.state.positions];
    positions.push({
      id: new BSON.ObjectId(),
      type: "freeposition",
      description: i18n.t("common:description"),
      amount: "0"
    });
    this.setState({ positions });
  };

  handleShippingGroupValueChange =
    (id: BSON.ObjectId) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const shippingGroups = _.cloneDeep(this.state.shippingGroups);
      const shippingGroup = shippingGroups.find(p => p.id.toString() === id.toString())!;
      const key = e.target.name as keyof ShippingGroupType;
      let val = e.target.value;
      if ("boxes" === key) shippingGroup[key] = Number(parseInt(val) || "0").toString();
      else if (["items", "weight"].includes(key)) {
        val = val.replaceAll(/^0+/g, "0");
        if (!val.includes(".")) val = Number(val).toString();
        // @ts-ignore
        shippingGroup[key] = val;
      }
      // @ts-ignore
      else shippingGroup[key] = val;
      // @ts-ignore
      this.setState({ shippingGroups });
    };

  handleShippingGroupRemove = (id: BSON.ObjectId) => {
    const shippingGroups = _.cloneDeep(this.state.shippingGroups);
    if (shippingGroups.length < 2) return;
    let sgs = shippingGroups.filter(sg => sg.id.toString() !== id.toString());
    for (let i = 0; i < sgs.length; i++) sgs[i].number = i + 1;
    this.setState({ shippingGroups: sgs });
  };

  handleAddShippingGroup = (shippingGroup: ShippingGroupType) => {
    const shippingGroups = _.cloneDeep(this.state.shippingGroups);
    shippingGroups.push(shippingGroup);
    this.setState({ shippingGroups });
  };

  handleUpload = async () => {
    const { order } = this.props;
    this.setState({ uploading: true });
    const pdfResult = await this.createDeliveryPDF();
    if (!pdfResult.result || !pdfResult.path) {
      toast.error("Delivery note creation failed: " + pdfResult.message);
      this.setState({ uploading: false });
      return;
    }
    window.open(config.mediahubBase + pdfResult.path, "_blank");
    const timelineEntry = {
      id: new BSON.ObjectId(),
      type: T_DELIVERYINFORMATION,
      date: new Date(),
      path: pdfResult.path,
      person: new BSON.ObjectId(userService.getUserData()._id),
      identifier: order.identifier
    };
    try {
      const result = await dbOrderService.pushToTimeline(order._id, timelineEntry);
      if (result && result.modifiedCount) {
        toast.success("Delivery note created successfully");
        notificationService.notify(R_ORDERFILEUPLOAD, order._id, {
          de: ": " + i18n.t("deliveryNote:deliveryNote", { lng: "de" }),
          en: ": " + i18n.t("deliveryNote:deliveryNote", { lng: "en" })
        });
        this.setState({ uploading: false, show: false });
      } else toast.error("Adding of timeline entry failed. " + pdfResult.path);
    } catch (e) {
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ uploading: false });
    }
  };

  handleDeviatingDelivery = () => {
    const { order } = this.props;
    const { deviatingDelivery } = this.state;
    if (deviatingDelivery) this.setState({ deviatingDelivery: false, deviatingAddress: null });
    else {
      const customerData = OrderHelper.getCustomerData(order);
      this.setState({ deviatingDelivery: true, deviatingAddress: customerData });
    }
  };

  handleChangeDeviatingAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const deviatingAddress = { ...this.state.deviatingAddress };
    if (!deviatingAddress) return;
    const key = e.target.name as keyof CustomerData;
    deviatingAddress[key] = e.target.value;
    this.setState({ deviatingAddress: deviatingAddress as CustomerData });
  };

  handleSelectDeviatingAddress = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { order } = this.props;
    const customer = order.createdFor;
    const address = customer.address[+e.target.value];
    const deviatingAddress = {
      name: address.deviatingCompanyName?.trim() ? address.deviatingCompanyName : order.createdFor.name,
      careOf: address.careOf ? address.careOf : "",
      street: address.street + address.streetnr,
      additionalAddress: "",
      zip: address.zip,
      city: address.city,
      country: address.country
    };
    this.setState({ deviatingAddress });
  };

  handleLanguageChange = () => {
    const { pdfData, positions } = this.state;

    const pdfDataTranslation: DeliveryNoteData = {
      bioNumber: pdfData.bioNumber,
      note: this.translateModalContent("deliveryNote", pdfData.note, DeliveryNoteTranslationType.NOTE),
      title: pdfData.title,
      subtitle: pdfData.subtitle,
      trackingNumber: pdfData.trackingNumber,
      creationDate: pdfData.creationDate
    };

    let positionsTranslation: DeliveryNotePosition[] = _.cloneDeep(positions);
    positionsTranslation = positionsTranslation.map(position => {
      const tmp = _.cloneDeep(position);
      tmp.description = this.translateModalContent(
        "",
        position.description,
        tmp.type === "position" ? DeliveryNoteTranslationType.POSITION : DeliveryNoteTranslationType.FREE_POSITION
      );
      return tmp;
    });
    this.setState({ pdfData: pdfDataTranslation, positions: positionsTranslation });
  };

  handleChangeExp = (e: React.ChangeEvent<HTMLInputElement>) => {
    const exp = new Date(e.target.value);
    if (isNaN(exp.getTime())) return;
    this.setState({ exp });
  };

  handleChangeDateFormat = (e: any) => this.setState({ dateFormat: e });

  createDeliveryPDF = async () => {
    const { order } = this.props;
    const data = JSON.stringify({
      html: this.doCreatePDF(),
      fileName:
        i18n.t("deliveryNote:deliveryNote") +
        "_AT-" +
        order.identifier +
        "_V" +
        orderUtils.getPDFVersion(order, T_DELIVERYINFORMATION) +
        "_" +
        dateUtils.timeStampDate() +
        ".pdf"
    });
    let path;
    try {
      path = await pdfUtils.uploadAndReturnPath(data);
    } catch (e) {
      return { result: false, message: e.message };
    }
    return { result: true, path: path };
  };

  /**
   * Render a position
   * @param position the position to render
   * @param index index of the position
   * @returns {JSX.Element} rendered position
   */
  renderPosition = (position: DeliveryNotePosition, index: number) => {
    return (
      <>
        <td className=" kt-datatable__cell d-table-cell">
          <span>{index + 1}</span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className="mx-0">
            <textarea
              rows={3}
              className="form-control"
              value={position.description}
              name="description"
              placeholder="Beschreibung"
              onChange={this.handlePositionValueChange(position.id)}
            />
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className="mx-0">
            <input
              type="number"
              className="form-control"
              value={position.amount}
              name="amount"
              onChange={this.handlePositionValueChange(position.id)}
            />
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className="mx-0 text-center">
            <button
              onClick={() => this.handlePositionRemove(position.id)}
              className="btn btn-sm btn-clean btn-icon btn-icon-sm"
              title="Delete"
            >
              <i className="flaticon2-delete" />
            </button>
          </span>
        </td>
      </>
    );
  };

  /**
   * Render the pdf preview
   * @returns {JSX.Element} the pdf preview with required data
   */
  renderPreview = () => {
    const { order } = this.props;
    const fileName =
      i18n.t("common:draft") +
      "_+" +
      i18n.t("deliveryNote:deliveryNote") +
      "_AT-" +
      order.identifier +
      "_" +
      dateUtils.timeStampDate() +
      ".pdf";
    return <PDFPreview createPDF={this.doCreatePDF} fileName={fileName} />;
  };

  render() {
    const { disabled, order } = this.props;
    const {
      show,
      uploading,
      customerData,
      pdfData,
      positions,
      shippingGroups,
      deviatingDelivery,
      deviatingAddress,
      exp,
      dateFormat
    } = this.state;
    const customer = order.createdFor;
    return (
      <>
        <button
          className={"btn btn-label-brand btn-sm btn-bold mr-3 my-1 " + (disabled ? "btn-disabled" : "")}
          onClick={this.handleShow}
          disabled={disabled}
        >
          <i className="fa fa-file-invoice" />
          Delivery Note
        </button>
        <Modal
          show={show}
          onHide={this.handleClose}
          size={"xl"}
          dialogClassName={"extendedModalDialog"}
          centered
          name={"createDeliveryNoteModal"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand fa fa-file-invoice mr-2" />
              Delivery Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "80vh", overflowY: "auto" }}>
            <div className="row">
              <CustomerDataComponent customerData={customerData} onCustomerChange={this.handleCustomerChange} />
              <div className="col-12 col-sm-6">
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <div className="row mb-2">
                    <label className="col-2 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">Order:</label>
                    <div className="input-group col-10">
                      <div className="input-group-prepend">
                        <span className="input-group-text">AT-</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Order Number"
                        disabled
                        style={{ backgroundColor: "#fafafa" }}
                        value={order.identifier}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <label className="col-2 col-form-label kt-widget24__stats kt-font-dark  kt-font-bold">LOT:</label>
                    <div className="col-10">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Order Number"
                        disabled
                        style={{ backgroundColor: "#fafafa" }}
                        value={order.fulfillment ? order.fulfillment.lot : "not set"}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <label className="col-2 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">Exp.:</label>
                    <div className="col-10">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Order Number"
                        value={exp.toISOString().split("T")[0]}
                        onChange={this.handleChangeExp}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <label className="col-2 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">Format:</label>
                    <div className="col-10">
                      <Select
                        className={"select-default"}
                        options={DATE_FORMATS}
                        isSearchable={false}
                        value={dateFormat}
                        onChange={this.handleChangeDateFormat}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <label className="col-2 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">
                      Tracking:
                    </label>
                    <div className="col-10">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Tracking number"
                        value={pdfData.trackingNumber}
                        name={"trackingNumber"}
                        onChange={this.handleDeliveryDataChange}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <label className="col-2 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">
                      PDF Date:
                    </label>
                    <div className="col-10">
                      <input
                        type="date"
                        className="form-control"
                        value={pdfData.creationDate.toISOString().split("T")[0]}
                        name={"creationDate"}
                        onChange={this.handlePDFDateChange}
                      />
                    </div>
                  </div>
                  <LanguageSelectionDropdown
                    labelPosition="front"
                    wrapperClasses={"row mb-2"}
                    labelColumnClasses={"col-lg-2 col-form-label"}
                    labelClasses={"kt-widget24__stats kt-font-dark kt-font-bold"}
                    selectClasses={"select-default col"}
                    label={"Language: "}
                    languageChangeCallback={this.handleLanguageChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div>
                  <div className="col-12">
                    <label className="kt-checkbox ">
                      <input type="checkbox" checked={deviatingDelivery} onChange={this.handleDeviatingDelivery} />
                      <span />
                      <div className="kt-font-dark text-info">Deviating delivery address</div>
                    </label>
                  </div>
                  {deviatingDelivery && (
                    <>
                      <div className="col-12 mb-2">
                        <select className="form-control" name="status" onChange={this.handleSelectDeviatingAddress}>
                          {customer.address.map((addr, index) => {
                            const addrString = `${
                              addr.deviatingCompanyName?.trim() ? addr.deviatingCompanyName + ", " : ""
                            }   ${addr.street} ${addr.streetnr}, ${addr.zip} ${addr.city}, ${addr.country}`;
                            return (
                              <option key={addrString} value={index}>
                                {addrString}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <BareCustomerData
                        customerData={deviatingAddress!}
                        onCustomerChange={this.handleChangeDeviatingAddress}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div className="col-12">
                <div className="row mb-2">
                  <label className="col-2 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">Title:</label>
                  <div className="col-10">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      value={pdfData.title}
                      name={"title"}
                      onChange={this.handleDeliveryDataChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <label className="col-2 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">Subtitle:</label>
                  <div className="col-10">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subtitle"
                      value={pdfData.subtitle}
                      name={"subtitle"}
                      onChange={this.handleDeliveryDataChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <div
                  className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded kt-datatable__cell--left table-responsive"
                  style={{ textAlign: "left" }}
                >
                  <table className="kt-datatable__table d-table " style={{ display: "block" }}>
                    <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
                      <tr className="kt-datatable__row d-table-row" style={{ left: "0px" }}>
                        <th className="kt-datatable__cell d-table-cell " style={{ minWidth: "20px" }}>
                          <span className="mx-0 px-0">#</span>
                        </th>
                        <th className="kt-datatable__cell d-table-cell " style={{ minWidth: "500px" }}>
                          <span className="mx-0 px-0">Description</span>
                        </th>
                        <th className="kt-datatable__cell d-table-cell " style={{ minWidth: "100px" }}>
                          <span className="mx-0 px-0">Amount</span>
                        </th>
                        <th className="kt-datatable__cell d-table-cell " style={{ minWidth: "30px" }}>
                          <span className="mx-0 px-0" />
                        </th>
                      </tr>
                    </thead>
                    <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                      {positions.map((position, key) => (
                        <tr
                          key={position.id.toString()}
                          className="kt-datatable__row d-table-row"
                          style={{ left: "0px", backgroundColor: "#fafafa" }}
                        >
                          {this.renderPosition(position, key)}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-6">
                    <button className="btn btn-secondary" onClick={this.handleAddFreePosition}>
                      Add Position
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div
                className="col-12"
                style={{ backgroundColor: "rgb(250, 250, 250)", paddingTop: 10, paddingBottom: 10 }}
              >
                {shippingGroups.map(sg => (
                  <ShippingGroup
                    key={sg.id.toString()}
                    shippingGroup={sg}
                    shippingGroups={shippingGroups}
                    onAddShippingGroup={() => {}}
                    onRemoveShippingGroup={() => this.handleShippingGroupRemove(sg.id)}
                    onChangeShippingGroup={this.handleShippingGroupValueChange(sg.id)}
                  />
                ))}
                {
                  <ShippingGroup
                    shippingGroups={shippingGroups}
                    onAddShippingGroup={this.handleAddShippingGroup}
                    onRemoveShippingGroup={() => {}}
                    onChangeShippingGroup={() => {}}
                  />
                }
              </div>
            </div>
            <hr />
            <div>
              <div className="col-12">
                <BioNumberSelection
                  bioNumber={pdfData.bioNumber}
                  onChange={this.handleDeliveryDataChange}
                  wrapperClasses={"row mb-2"}
                  labelColumnClasses={"col-lg-2 col-form-label"}
                  labelClasses={"kt-widget24__stats kt-font-dark kt-font-bold mb-0"}
                  selectColumnClasses={"col-10"}
                />
                <div className="row mb-2">
                  <label className="col-2 col-form-label kt-widget24__stats kt-font-dark kt-font-bold">Note:</label>
                  <div className="col-10">
                    <textarea
                      className="form-control"
                      placeholder="Note"
                      rows={3}
                      value={pdfData.note}
                      name={"note"}
                      onChange={this.handleDeliveryDataChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            {this.renderPreview()}
            <button
              className={!uploading ? "btn btn-success" : "btn btn-success disabled"}
              disabled={uploading}
              onClick={this.handleUpload}
            >
              {uploading && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Create Delivery Information
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateDeliveryNoteModal;
