import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import Select from "react-select";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";
import HistoryBackButton from "../../listings/common/HistoryBackButton";
import SplashScreen from "../../common/SplashScreen";
import BaseListing from "../../listings/BaseListing";
import { paginate } from "../../common/Pagination";
import { PaginationState } from "../../common/CustomTypes";
import { SearchBar } from "../../listings/common/Filters";
import baseUtils, { getComponentState } from "../../../utils/baseUtils";
import { ExtendedEMCommodity } from "../../../model/customTypes.types";
import commodityUtils from "../../../utils/commodityUtils";
import calculationUtils from "../../../utils/calculationUtils";
import ExternalManufacturerHelper from "../ExternalManufacturerHelper";
import EMCommodityRequestModal from "../modals/EMCommodityRequestModal";
import EMCommodityWidget from "../EMCommodityWidget";

interface ExternalManufacturersCommoditiesProps extends RouteComponentProps {
  context: React.ContextType<typeof ExternalManufacturerContext>;
}

interface ExternalManufacturersCommoditiesState extends PaginationState {
  search: string;
  category: "" | { value: string; label: string };
  composition: "" | { value: string; label: string };
  commodities: Array<ExtendedEMCommodity>;
  commodityToOrder: ExtendedEMCommodity | null;
}

const CONSTRUCTORNAME = "ExternalManufacturersCommodities";

class ExternalManufacturersCommodities extends PureComponent<
  ExternalManufacturersCommoditiesProps,
  ExternalManufacturersCommoditiesState
> {
  constructor(props: ExternalManufacturersCommoditiesProps) {
    super(props);
    const commodities = this.getExtendedCommodities(props);
    this.state = {
      pageSize: 10,
      currentPage: 1,
      search: "",
      category: "",
      composition: "",
      commodities,
      commodityToOrder: null
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, CONSTRUCTORNAME);
    if (state) this.setState({ ...state });
  }

  componentDidUpdate(
    prevProps: Readonly<ExternalManufacturersCommoditiesProps>,
    prevState: Readonly<ExternalManufacturersCommoditiesState>,
    snapshot?: any
  ) {
    if (!_.isEqual(this.props.context, prevProps.context)) {
      this.setState({ commodities: this.getExtendedCommodities(this.props) });
    }
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(CONSTRUCTORNAME, _.omit(this.state, "commodities"));
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value, currentPage: 1 });
  handleSelectChange = (name: string, entry: "" | { value: string; label: string }) =>
    // @ts-ignore
    this.setState({ [name]: entry, currentPage: 1 });
  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleReset = () => this.setState({ pageSize: 10, currentPage: 1, search: "", composition: "", category: "" });
  handleOrderCommodity = (commodity: ExtendedEMCommodity) => this.setState({ commodityToOrder: commodity });
  handleCloseOrderModal = () => this.setState({ commodityToOrder: null });

  /**
   * Extend commodities with data
   * @param props components properties
   * @returns List of extended commodities
   */
  getExtendedCommodities = (props: ExternalManufacturersCommoditiesProps) => {
    const { context } = props;
    const { commodities } = context;
    let extendedCommodities: Array<ExtendedEMCommodity> = [];
    for (let i = 0; i < commodities.length; i++) {
      const commodity = commodities[i];
      extendedCommodities.push(
        ExternalManufacturerHelper.getExtendedCommodity(commodity, context) as ExtendedEMCommodity
      );
    }
    return extendedCommodities;
  };

  getFilteredCommodities = () => {
    const { commodities, search, category, composition } = this.state;
    let filteredCommodities = commodities.slice();
    if (category)
      filteredCommodities = filteredCommodities.filter(c => c.category && c.category._id.toString() === category.value);
    if (composition)
      filteredCommodities = filteredCommodities.filter(c => c.form && c.form._id.toString() === composition.value);
    if (search.trim() === "") return filteredCommodities;
    return baseUtils.doFuseSearch(filteredCommodities, this.state.search, [
      "title.en",
      "title.de",
      "subtitle.en",
      "subtitle.de",
      "category.name.en",
      "category.name.de",
      "form.name.en",
      "form.name.de",
      "composition.name.de",
      "composition.name.en",
      "activesubstance.name.de",
      "activesubstance.name.en",
      "properties.name.de",
      "properties.name.de",
      "country"
    ]);
  };

  render() {
    const { context, history } = this.props;
    const { pageSize, currentPage, search, category, composition, commodityToOrder } = this.state;
    const { commodities, compositions, commoditycategories } = context;
    const filteredCommodities = this.getFilteredCommodities();
    const headerDefinition = [
      { title: "Commodity", size: 20 },
      { title: "Category", size: 8 },
      { title: "Composition", size: 6 },
      { title: "Properties", size: 12 },
      { title: "Active Substances", size: 12 },
      { title: "Currently Required", size: 8 },
      { title: "Delivery Time", size: 7 },
      { title: "Price Range (kg)", size: 7 },
      { title: "Action", size: 5 }
    ];

    return (
      <>
        <EMCommodityRequestModal commodity={commodityToOrder} onClose={this.handleCloseOrderModal} />
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand flaticon2-supermarket" />
              </span>
              <h3 className="kt-portlet__head-title">Commodities</h3>
              <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
                Reset
              </button>
            </div>
            <HistoryBackButton history={history} />
          </div>
          <div className="kt-portlet__body pb-0">
            <div className="kt-form kt-form--label-right  kt-margin-b-10">
              <div className="row align-items-center">
                <SearchBar onSearch={this.handleSearch} search={search} />
                <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                  <Select
                    className="select-default"
                    isClearable={true}
                    options={commoditycategories.map(category => {
                      return {
                        value: category._id.toString(),
                        label: _.upperFirst(category.name.en)
                      };
                    })}
                    value={category ? category : { value: "", label: "All Categories" }}
                    onChange={(value: any) => this.handleSelectChange("category", value || "")}
                  />
                </div>
                <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                  <Select
                    className="select-default"
                    isClearable={true}
                    options={compositions.map(c => {
                      return {
                        value: c._id.toString(),
                        label: _.upperFirst(c.name.en)
                      };
                    })}
                    value={composition ? composition : { value: "", label: "All Compositions" }}
                    onChange={(value: any) => this.handleSelectChange("composition", value || "")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="kt-portlet__body kt-portlet__body--fit">
            {commodities.length === 0 ? (
              <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
            ) : (
              <BaseListing
                headerDefinition={headerDefinition}
                documents={filteredCommodities}
                bodyContent={
                  <>
                    {paginate(filteredCommodities, currentPage, pageSize).map(c => (
                      <EMCommoditiesRow
                        key={c._id.toString()}
                        commodity={c}
                        {...this.props}
                        onOrderCommodity={this.handleOrderCommodity}
                      />
                    ))}
                  </>
                }
                currentPage={currentPage}
                pageSize={pageSize}
                onPageChange={this.handlePageChange}
                onPageSizeChange={this.handlePageSizeChange}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

interface EMCommoditiesRowProps extends RouteComponentProps {
  commodity: ExtendedEMCommodity;
  dashboard?: boolean;
  onOrderCommodity: (commodity: ExtendedEMCommodity) => void;
}

export const EMCommoditiesRow: React.FunctionComponent<EMCommoditiesRowProps> = ({
  commodity,
  history,
  onOrderCommodity,
  dashboard
}) => {
  const forwardCommodity = () => history.push("/commodity/" + commodity._id.toString());

  const handleOrder = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onOrderCommodity(commodity);
  };

  return (
    <tr className="kt-datatable__row table-hover d-table-row" onClick={forwardCommodity}>
      <td className="kt-datatable__cell d-table-cell">
        <EMCommodityWidget commodity={commodity} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        {commodity.category && (
          <span
            className="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded"
            style={{
              backgroundColor: commodityUtils.getCategoryColor(commodity.category.name.en),
              color: commodity.category && commodity.category.name.en === "probiotics" ? "black" : "white",
              display: "inline-block"
            }}
          >
            {commodity.category.name.en}
          </span>
        )}
      </td>
      {!dashboard && (
        <>
          <td className="kt-datatable__cell d-table-cell">
            <span>
              {commodity.form && (
                <span className="kt-badge kt-badge--primary kt-badge--inline kt-badge--pill kt-badge--rounded mr-2">
                  {commodity.form.name.en}
                </span>
              )}
            </span>
          </td>
          <td className="kt-datatable__cell d-table-cell">
            <span>
              {commodity.properties.map(property => (
                <span
                  key={property._id.toString()}
                  className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill kt-badge--rounded mr-2"
                  style={{ backgroundColor: "#5bb3ff" }}
                >
                  {property.name.en}
                </span>
              ))}
            </span>
          </td>
          <td className="kt-datatable__cell d-table-cell">
            <span>
              {commodity.activesubstance.map(as => (
                <span
                  key={as._id.toString()}
                  className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded mr-2"
                >
                  {as.value + "% " + as.name.en}
                </span>
              ))}
            </span>
          </td>
        </>
      )}
      <td className="kt-datatable__cell d-table-cell">
        <span className={"kt-font-bold " + (commodity.amount && commodity.amount > 0 ? "text-success" : "text-muted")}>
          {commodity.amount && commodity.amount > 0 ? calculationUtils.formatAmount(commodity.amount, 2) : "-"}
        </span>
        {commodity.nextDelivery && (
          <div className={"text-muted"}>until {baseUtils.formatDate(commodity.nextDelivery)}</div>
        )}
      </td>
      {!dashboard && (
        <td className="kt-datatable__cell d-table-cell">
          <span className="kt-font-bold">
            min. {commodity.deliveryTime + (commodity.deliveryTime === 1 ? " day" : " days")}
          </span>
        </td>
      )}
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-font-bold">
          {commodity.price
            ? commodity.price.min === commodity.price.max
              ? baseUtils.formatEuro(commodity.price.min)
              : `${baseUtils.formatEuro(commodity.price.min)} - ${baseUtils.formatEuro(commodity.price.max)}`
            : "-,-- €"}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <button className="btn btn-secondary py-1 px-3" onClick={handleOrder}>
          Request
        </button>
      </td>
    </tr>
  );
};

export default ExternalManufacturersCommodities;
