import {
  NotificationConfiguration,
  NotificationTypes,
  NotificationUser
} from "../model/configuration/notificationConfiguration.types";
import { DataContextType } from "../context/dataContext";
import { BaseConfiguration, ConfigurationKeys } from "../model/configuration/configuration.types";
import { getConfigurationFromCollection } from "./configurationUtils";

export enum NotificationTypeFilter {
  EMAIL,
  SLACK
}

/**
 * Get notification targets from context
 * @param type type of notification
 * @param context data context
 * @param filter optional, filter for specific notification type
 * @returns {Array<NotificationUser> | null} list of users to be notified or null
 */
export const getNotificationTargetsContext = (
  type: NotificationTypes,
  context: DataContextType,
  filter?: NotificationTypeFilter
): Array<NotificationUser> | null => {
  const { configuration } = context;
  return getNotificationTargetsCollection(type, configuration, filter);
};

/**
 * Get notification targets from context
 * @param type type of notification
 * @param configurations configuration collection
 * @param filter optional, filter for specific notification type
 * @returns {Array<NotificationUser> | null} list of users to be notified or null
 */
export const getNotificationTargetsCollection = (
  type: NotificationTypes,
  configurations: Array<BaseConfiguration>,
  filter?: NotificationTypeFilter
): Array<NotificationUser> | null => {
  const notificationConfiguration = getConfigurationFromCollection<NotificationConfiguration>(
    ConfigurationKeys.NOTIFICATION,
    configurations
  );
  if (!notificationConfiguration) return null;
  return getNotificationTargets(type, notificationConfiguration, filter);
};

/**
 * Get notification targets from context
 * @param type type of notification
 * @param configuration notification configuration
 * @param filter optional, filter for specific notification type
 * @returns {Array<NotificationUser> | null} list of users to be notified or null
 */
export const getNotificationTargets = (
  type: NotificationTypes,
  configuration: NotificationConfiguration,
  filter?: NotificationTypeFilter
): Array<NotificationUser> | null => {
  const matchTypeFilter = (userSetting: NotificationUser) => {
    switch (filter) {
      case NotificationTypeFilter.EMAIL:
        return userSetting.email;
      case NotificationTypeFilter.SLACK:
        return userSetting.slack;
    }
    return false;
  };
  const { settings } = configuration.values;
  const setting = settings.find(s => s.type === type);
  if (!setting) return null;
  if (filter === undefined) return setting.users;
  return setting.users.filter(u => matchTypeFilter(u));
};
