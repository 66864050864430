import _ from "lodash";
import React, { FormEvent, PureComponent } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { Alert, Form } from "react-bootstrap";
import auth from "../../services/authenticationService";

interface PasswordResetState {
  data: { email: string };
  success: boolean;
}

interface PasswordResetProps extends RouteComponentProps<{}, {}, PasswordResetState> {}

/**
 * Handles the request of users for resetting their password.
 */
class PasswordResetRequest extends PureComponent<PasswordResetProps, PasswordResetState> {
  constructor(props: PasswordResetProps) {
    super(props);
    this.state = {
      // Note always init with empty string or value
      data: { email: "" },
      success: false
    };
  }

  /**
   * Handle the submit of a form
   * @param e: the submit form event
   */
  handleSubmit = (e: FormEvent) => {
    // Disable default behavior
    e.preventDefault();
    this.doSubmit();
  };

  /**
   * Handle change event, i.e. validate the value
   * @param input: the target property of the change event
   */
  handleChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...this.state.data };
    _.set(data, input.name, input.value);
    this.setState({ data });
  };

  /**
   * Trigger a password reset for the given mail inside the backend.
   */
  doSubmit = async () => {
    try {
      const { data } = this.state;
      if (await auth.userExistsAndConfirmed(data.email)) {
        await auth.sendResetPasswordMail(data.email);
      }
    } catch (ex) {}
    this.setState({ success: true, data: { email: "" } });
  };

  render() {
    if (auth.getUser()) return <Redirect to="/" />;
    const { success } = this.state;
    return (
      <div className="root " style={{ height: "100%" }}>
        <div className="kt-grid kt-grid--ver kt-grid--root" style={{ height: "100%" }}>
          <div className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v3 kt-login--signin" id="kt_login">
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
              style={{
                height: "100%!important",
                backgroundImage: "url(/media/bg/bg-3.jpg)"
              }}
            >
              <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper text-center align-self-auto">
                <div className="kt-login__container  ">
                  <div className="kt-login__logo">
                    <a href="#">
                      <img src={process.env.PUBLIC_URL + "/media/logos/logo-5.png"} alt="plf" />
                    </a>
                  </div>
                  <div className="kt-login__signin">
                    <div className="kt-login__head">
                      <h3 className="kt-login__title mb-4">Please Enter Email</h3>
                      <div>
                        <p>Please enter primary email address (username) to request a password reset link:</p>
                      </div>
                    </div>
                    <Form onSubmit={this.handleSubmit}>
                      {success && (
                        <Alert variant="success" className="text-left" style={{ whiteSpace: "pre-wrap" }}>
                          {"Email with password reset link was send to the given email address."}
                        </Alert>
                      )}
                      {!success && (
                        <div className="form-group text-center">
                          <div className="form-group kt-form mb-4">
                            <input
                              className={"form-control"}
                              type={"email"}
                              placeholder={"Email Adress"}
                              name={"email"}
                              onChange={this.handleChange}
                              required={true}
                            />
                          </div>
                          <button
                            id="reset-request-submit"
                            className="btn btn-brand btn-elevate kt-login__btn-primary mx-auto"
                          >
                            Confirm
                          </button>
                        </div>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordResetRequest;
