import { Document, Model } from "mongoose";
import { BSON } from "realm-web";

export enum CapsuleSupplierType {
  MANUFACTURER = "manufacturer",
  SUPPLIER = "supplier"
}

export interface Capsules {
  capsule_size: string;
  capsule_volume: number;
  capsule_weight: number;
  capsule_bottlingcosts: number;
  properties: Array<BSON.ObjectId>;
  additives?: Array<BSON.ObjectId>;
  suppliers: Array<CapsuleSupplier>;
  capsule_material: {
    de: string;
    en: string;
  };
  capsule_color: {
    de: string;
    en: string;
  };
}

export interface CapsuleSupplier {
  _id: BSON.ObjectId;
  prices: Array<CapsulePrice>;
  type?: CapsuleSupplierType;
}

export interface CapsulePrice {
  _id: BSON.ObjectId | string;
  moq: number;
  price: number;
  deliverytime: number;
  delivery: string;
  note: string;
  date: Date | null;
}

export interface CapsulesDocument extends Capsules, Document {}

export interface CapsulesModel extends Model<CapsulesDocument> {}
