import React, { PureComponent } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Select from "react-select";
import { DataContext } from "../../context/dataContext";
import SplashScreen from "../common/SplashScreen";
import Pagination, { paginate } from "../common/Pagination";
import { PaginationState } from "../common/CustomTypes";
import { PackagingsDocument } from "../../model/packagings.types";
import packagingUtils, { PACKAGING_TYPES } from "../../utils/packagingUtils";
import PackagingOverviewItem from "./PackagingOverviewItem";
import baseUtils, { getComponentState } from "../../utils/baseUtils";
import { SearchBar } from "../listings/common/Filters";
import accessUtils, { CREATELOCATIONS } from "../../utils/accessUtils";

interface PackagingOverviewProps extends RouteComponentProps<{}, {}, {}> {}

interface PackagingOverviewState extends PaginationState {
  searchQuery: string;
  filter: string;
}

const CONSTRUCTORNAME = "PackagingOverview";

class PackagingOverview extends PureComponent<PackagingOverviewProps, PackagingOverviewState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: PackagingOverviewProps, context: React.ContextType<typeof DataContext>) {
    super(props, context);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const state = getComponentState(this.context, CONSTRUCTORNAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }

  handleReset = () => this.setState(this.getDefaultState());
  handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ searchQuery: e.target.value, currentPage: 1 });
  handleFilterChange = (entry: { value: string; label: string } | "") =>
    this.setState({ filter: entry ? entry.value : "", currentPage: 1 });
  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });

  /**
   * Get the default state
   * @returns {PackagingOverviewState} state for the component
   */
  getDefaultState = () => {
    return { currentPage: 1, filter: "", pageSize: 12, searchQuery: "" } as PackagingOverviewState;
  };

  getFilteredPackaging = () => {
    const { packagings } = this.context;
    const { filter, searchQuery } = this.state;
    let filteredPackaging: Array<PackagingsDocument> = [...packagings];
    if (filter) filteredPackaging = packagingUtils.filterPackaging(filteredPackaging, "packaging_type", filter);
    if (searchQuery) {
      filteredPackaging = baseUtils.doFuseSearch(filteredPackaging, searchQuery, [""], {
        getFn: (obj: any, path) => {
          return packagingUtils.concatPackagingInfo(obj);
        }
      });
    }
    return filteredPackaging;
  };

  render() {
    const { packagings } = this.context;
    const { currentPage, pageSize, searchQuery, filter } = this.state;
    const filteredPackaging = this.getFilteredPackaging();
    const filterPackaging = PACKAGING_TYPES.find(p => p.value.toString() === filter)!;

    return (
      <>
        {packagings.length === 0 ? (
          <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
        ) : (
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              <div className="kt-portlet__head-label">
                <span className="kt-portlet__head-icon">
                  <i className="kt-font-brand fa fa-avatar" />
                </span>
                <h3 className="kt-portlet__head-title">Packagings</h3>
                <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
                  Reset
                </button>
              </div>
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">
                  <span onClick={this.props.history.goBack} className="btn btn-clean kt-margin-r-10">
                    <i className="la la-arrow-left" />
                    <span className="kt-hidden-mobile">Back</span>
                  </span>
                  {accessUtils.canCreateData(CREATELOCATIONS.PACKAGING) && (
                    <button type="button" className="btn btn-brand btn-icon-sm">
                      <i className="flaticon2-plus" />
                      <Link to="/create-packaging">
                        <span style={{ color: "white" }}>Add New</span>
                      </Link>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="kt-form kt-form--label-right kt-margin-b-10">
                <div className="row align-items-center">
                  <SearchBar
                    onSearch={this.handleSearchChange}
                    search={searchQuery}
                    additionalSizeClasses="col-6 col-xl-2"
                  />
                  <div className="col-6 col-xl-2 kt-margin-b-20-tablet-and-mobile">
                    <Select
                      className="select-default"
                      isClearable={true}
                      options={PACKAGING_TYPES.map(p => {
                        return {
                          value: p.value,
                          label: p.label
                        };
                      })}
                      value={
                        filter !== ""
                          ? { value: filterPackaging.value, label: filterPackaging.label }
                          : { value: "", label: "All" }
                      }
                      onChange={(value: any) => this.handleFilterChange(value || "")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="kt-portlet__body kt-portlet__body--fit">
              <div className="row mx-3">
                {paginate(filteredPackaging, currentPage, pageSize).map(p => (
                  <PackagingOverviewItem key={p._id.toString()} packaging={p} />
                ))}
              </div>
              <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                <div className="kt-datatable__pager kt-datatable--paging-loaded justify-content-center">
                  <Pagination
                    itemsCount={filteredPackaging.length}
                    pageSize={pageSize}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                    onPageSizeChange={this.handlePageSizeChange}
                    baseSize={12}
                    additionalPageSizeClasses={"ml-2"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default PackagingOverview;
