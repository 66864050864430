import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BSON } from "realm-web";
import { LayoutSplashScreen } from "../../_metronic";
import { DataContext } from "../../context/dataContext";
import baseUtils from "../../utils/baseUtils";

interface StockCheckState {
  loading: boolean;
  faultyStocks: Array<Stock>;
}

interface Stock {
  commodity: BSON.ObjectId;
  _id: string;
  location: BSON.ObjectId;
  lot: string;
  price: number;
  difference: number;
}

class StockCheck extends Component<{}, StockCheckState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: {}) {
    super(props);
    this.state = { loading: true, faultyStocks: [] };
  }

  componentDidMount() {
    this.generateFaultyList();
  }

  /**
   * Generates the list of all stocks that look faulty (more than 75% difference in price in relation to supplier prices)
   */
  generateFaultyList = () => {
    const commodities = this.context.commodities.filter(c => c.stock.length > 0);

    const faultyStocks: Array<Stock> = [];

    for (let commodity of commodities) {
      let approxSupplierPrice = 0;
      let pricesCount = 0;
      for (let supplier of commodity.suppliers) {
        for (let price of supplier.prices) {
          approxSupplierPrice += price.price;
          pricesCount++;
        }
      }
      approxSupplierPrice /= pricesCount;

      for (let stock of commodity.stock) {
        const difference = Math.abs(1 - stock.price / approxSupplierPrice);
        if (stock.price === 0 || difference > 0.75) {
          faultyStocks.push({
            commodity: commodity._id,
            _id: stock._id,
            location: stock.location,
            lot: stock.lot,
            price: stock.price,
            difference
          });
        }
      }
    }
    this.setState({ faultyStocks, loading: false });
  };

  render() {
    const { faultyStocks, loading } = this.state;
    if (loading) {
      return <LayoutSplashScreen />;
    }
    return (
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Lot</th>
            <th>Location</th>
            <th>Difference</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {faultyStocks.map(f => {
            const location = baseUtils.getDocFromCollection(this.context.manufacturers, f.location);
            return (
              <tr key={f.commodity.toString() + "_" + f._id}>
                <td>{baseUtils.getDocFromCollection(this.context.commodities, f.commodity).title.en}</td>
                <td>{f.lot}</td>
                <td>{location ? location.name : "unknown"}</td>
                <td>{(f.difference * 100).toFixed(2)}%</td>
                <td>
                  <Link to={"/commodity/" + f.commodity.toString()}>to commodity</Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

export default StockCheck;
