import React, { PureComponent } from "react";

interface CommodityStockWidgetProps {
  header: string;
  content: string;
  colorClass?: string;
}

interface CommodityStockWidgetState {}

class CommodityStockWidget extends PureComponent<CommodityStockWidgetProps, CommodityStockWidgetState> {
  render() {
    const { header, content, colorClass } = this.props;
    return (
      <div className="kt-widget__item p-0 m-2">
        <div className="kt-widget__label text-center">
          <span className="font-weight-bold text-uppercase" style={{ color: "rgb(72, 70, 91)" }}>
            {header}
          </span>
          <br />
          <span
            className={
              "d-inline-block font-weight-bold text-uppercase btn btn-sm btn-bold w-75 " +
              (colorClass ? colorClass : "btn-label-light")
            }
          >
            <div>{content}</div>
          </span>
        </div>
      </div>
    );
  }
}

export default CommodityStockWidget;
