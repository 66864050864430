import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { CustomerNotesExtended } from "../common/CustomTypes";
import orderUtils from "../../utils/orderUtils";
import dbOrderService from "../../services/dbServices/dbOrderService";
import userService from "../../services/userService";

interface ReadCustomerNoteModalProps {
  note: CustomerNotesExtended;
}

interface ReadCustomerNoteModalState {
  show: boolean;
  saving: boolean;
  message: string;
}

class ReadCustomerNoteModal extends PureComponent<ReadCustomerNoteModalProps, ReadCustomerNoteModalState> {
  constructor(props: ReadCustomerNoteModalProps) {
    super(props);
    this.state = { show: false, saving: false, message: "" };
  }

  handleShow = () => this.setState({ show: true, message: "" });
  handleHide = () => this.setState({ show: false });

  handleReadConfirm = async () => {
    const { note } = this.props;
    const { message } = this.state;
    this.setState({ saving: true });
    try {
      const res = await dbOrderService.markCustomerNoteAsRead(note.order._id, note.note._id);
      if (res && res.modifiedCount > 0) {
        toast.success("Note successfully marked as read");
        if (message.trim() !== "") {
          const answer = {
            _id: new BSON.ObjectId(),
            date: new Date(),
            person: userService.getUserId(),
            note: message,
            referencedNote: note.note._id
          };
          const resAns = await dbOrderService.addCustomerNote(note.order._id, answer);
          if (resAns && res.modifiedCount > 0) {
            toast.success("Answer added successfully");
          } else {
            toast.error("Error adding answer. Please try again.");
          }
        }
        this.setState({ show: false });
      } else {
        toast.error("Error marking note as read");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ message: e.target.value });
  };

  render() {
    const { note } = this.props;
    const { show, saving, message } = this.state;
    const isOrder = orderUtils.isOrder(note.order);
    return (
      <>
        <button className="btn btn-sm btn-secondary mr-2" onClick={this.handleShow}>
          Read
        </button>
        <Modal show={show} centered onHide={this.handleHide} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Mark note of {(isOrder ? "AT-" : "AN-") + note.order.identifier} as read</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-3 col kt-font-dark">Original Message:</div>
              <div className="col-md-9 col">
                <textarea
                  className="form-control disabled"
                  style={{ resize: "none" }}
                  disabled
                  value={note.note.note}
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-3 col kt-font-dark">Optional Answer:</div>
              <div className="col-md-9 col">
                <textarea
                  className={"form-control" + (saving ? " disabled" : "")}
                  onChange={this.handleChangeInput}
                  value={message}
                  disabled={saving}
                />
              </div>
            </div>
            <hr />
            <span className="kt-font-dark">
              Do you want to mark this note as read? <br /> The customer will be able to see this inside SupplyCentral.
            </span>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            <button
              className={"btn btn-sm btn-success" + (saving ? " disabled" : "")}
              onClick={this.handleReadConfirm}
              disabled={saving}
            >
              Confirm
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ReadCustomerNoteModal;
