import React, { PureComponent } from "react";
import { PackagingsDocument } from "../../model/packagings.types";
import accessUtils, { EDITLOCATIONS } from "../../utils/accessUtils";
import packagingUtils from "../../utils/packagingUtils";

interface PackagingPreviewProps {
  packaging: PackagingsDocument;
  onTabChange: (tab: string) => void;
}

interface PackagingPreviewState {}

class PackagingPreview extends PureComponent<PackagingPreviewProps, PackagingPreviewState> {
  getImageUrl = () => {
    return packagingUtils.getPackagingImage(this.props.packaging);
  };

  render() {
    const canEdit = accessUtils.canEditData(EDITLOCATIONS.PACKAGINGSETTINGS);
    return (
      <div className="kt-portlet kt-portlet--head-noborder">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Preview</h3>
          </div>
          {canEdit && (
            <div className="kt-portlet__head-toolbar">
              <button
                onClick={() => this.props.onTabChange("settings")}
                className="btn btn-label-brand btn-sm btn-bold pointer"
              >
                Update Images
              </button>
            </div>
          )}
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit-top">
          <img className="packaging_img" src={this.getImageUrl()} />
        </div>
      </div>
    );
  }
}

export default PackagingPreview;
