import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import OrderConversationNotesListing from "./OrderConversationNotesListing";
import { DataContext } from "../../context/dataContext";

interface CustomerNotesWrapperProps extends RouteComponentProps {}

const CustomerNotesWrapper: React.FunctionComponent<CustomerNotesWrapperProps> = props => {
  const context = useContext(DataContext);
  return <OrderConversationNotesListing context={context} {...props} />;
};

export default CustomerNotesWrapper;
