import React from "react";
import PackagingStockWidget from "./PackagingStockWidget";

interface PackagingsStockStatisticsProps {
  stock: number;
  ordered: number;
  reserved: number;
  available: number;
  outerClassName?: string;
  innerClassName?: string;
}

const PackagingStockStatistics: React.FunctionComponent<PackagingsStockStatisticsProps> = ({
  stock,
  ordered,
  reserved,
  available,
  outerClassName,
  innerClassName
}: PackagingsStockStatisticsProps) => {
  return (
    <div className={outerClassName}>
      <div className={innerClassName}>
        <PackagingStockWidget
          header="Current Stock"
          content={`${stock} pcs.`}
          colorClass={stock > 0 ? "btn-label-success" : ""}
        />
      </div>
      <div className={innerClassName}>
        <PackagingStockWidget
          header="Ordered"
          content={`${ordered} pcs.`}
          colorClass={ordered > 0 ? "btn-label-success" : ""}
        />
      </div>
      <div className={innerClassName}>
        <PackagingStockWidget
          header="Reserved"
          content={`${reserved} pcs.`}
          colorClass={reserved > 0 ? "btn-label-warning" : ""}
        />
      </div>
      <div className={innerClassName}>
        <PackagingStockWidget
          header="Available"
          content={`${available} pcs.`}
          colorClass={available < 0 ? "btn-label-danger" : "btn-label-success"}
        />
      </div>
    </div>
  );
};
export default PackagingStockStatistics;
