import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { DataContext } from "../../../context/dataContext";
import { CommoditiesDocument, CommoditySpecification } from "../../../model/commodities.types";
import { COMMODITIES } from "../../../services/dbService";
import dbCommodityService from "../../../services/dbServices/dbCommodityService";
import fileUtils from "../../../utils/fileUtils";
import toastUtils from "../../../utils/toastUtils";

interface DeleteCommodityDocumentModalProps {
  commodity: CommoditiesDocument;
  specification: CommoditySpecification;
  context: React.ContextType<typeof DataContext>;
}

interface DeleteCommodityDocumentModalState {
  saving: boolean;
  show: boolean;
}

class DeleteCommodityDocumentModal extends PureComponent<
  DeleteCommodityDocumentModalProps,
  DeleteCommodityDocumentModalState
> {
  constructor(props: DeleteCommodityDocumentModalProps) {
    super(props);
    this.state = { saving: false, show: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  /**
   * Handles the deletion of a commodity document.
   */
  handleDocumentDelete = async () => {
    const { commodity, context, specification } = this.props;
    const { updateDocumentInContext } = context;
    this.setState({ saving: true });
    const res = await dbCommodityService.removeSpecification(commodity._id, specification);
    const type = fileUtils.getDisplayableText(specification.type);
    await toastUtils.databaseOperationToast(
      !!res && res.modifiedCount > 0,
      type + " deleted successfully",
      "Error deleting " + type,
      () => updateDocumentInContext(COMMODITIES, commodity._id)
    );
    this.setState({ saving: false, show: false });
  };

  render() {
    const { commodity, specification } = this.props;
    const { saving, show } = this.state;
    return (
      <>
        <button type="button" className="btn btn-danger btn-sm p-2" onClick={this.handleShow}>
          <i className="flaticon2-cross px-1" />
        </button>
        <Modal show={show} centered onHide={this.handleHide}>
          <Modal.Header closeButton>
            <Modal.Title>
              Delete {fileUtils.getDisplayableText(specification.type)} for {commodity.title.en}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span className="text-dark h6">
              Do you really want to delete this {fileUtils.getDisplayableText(specification.type)}? This can not be
              undone.
            </span>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            <button
              className={"btn btn-danger" + (saving ? " disabled" : "")}
              disabled={saving}
              onClick={this.handleDocumentDelete}
            >
              Confirm
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default DeleteCommodityDocumentModal;
