import _ from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import CompanyWidget from "../../common/CompanyWidget";
import PersonWidget from "../../common/PersonWidget";
import { DataContext } from "../../../context/dataContext";
import { CompaniesDocument } from "../../../model/companies.types";
import { OrdersDocument } from "../../../model/orders.types";
import { UserdataDocument } from "../../../model/userdata.types";
import baseUtils from "../../../utils/baseUtils";
import orderUtils from "../../../utils/orderUtils";

interface MissingPrintingFilesProps {
  context: React.ContextType<typeof DataContext>;
}

interface MissingPrintingFilesState {
  missingPrintingFiles: Array<{ order: OrdersDocument; owner: UserdataDocument; company: CompaniesDocument }>;
  employee: string;
  sortBy: "at" | "customer" | "ordered" | "target" | "employee";
  sortDirection: "asc" | "desc";
}

class MissingPrintingFiles extends PureComponent<MissingPrintingFilesProps, MissingPrintingFilesState> {
  constructor(props: MissingPrintingFilesProps) {
    super(props);
    this.state = { missingPrintingFiles: [], employee: "", sortBy: "ordered", sortDirection: "asc" };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(
    prevProps: Readonly<MissingPrintingFilesProps>,
    prevState: Readonly<MissingPrintingFilesState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.context.orders, this.props.context.orders)) this.prepareData();
  }

  handleChangeEmployee = (e: React.ChangeEvent<HTMLSelectElement>) => this.setState({ employee: e.target.value });

  /**
   * Handles changing the sorting field. If the same field is clicked twice the order is inverted.
   * @param name: Field that should be used for sorting
   */
  handleChangeSort(name: "at" | "customer" | "ordered" | "target" | "employee") {
    const { sortBy, sortDirection } = this.state;
    if (sortBy === name) this.setState({ sortDirection: sortDirection === "asc" ? "desc" : "asc" });
    else this.setState({ sortBy: name, sortDirection: "asc" });
  }

  /**
   * Prepares the data for usage
   */
  prepareData = () => {
    const { context } = this.props;
    const { companies, orders, userdata, packagings } = context;
    const missingPrintingFiles = [];
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      if (!orderUtils.isOrder(order) || !orderUtils.isActive(order)) continue;
      const printingFileReq = orderUtils.getPrintingFileReq(order, packagings);
      if (printingFileReq.required > printingFileReq.uploaded) {
        missingPrintingFiles.push({
          order,
          owner: baseUtils.getDocFromCollection(userdata, order.createdFrom)!,
          company: baseUtils.getDocFromCollection(companies, order.createdFor)!
        });
      }
    }
    this.setState({
      missingPrintingFiles
    });
  };

  /**
   * Handle sorting the data.
   * @param data: Contains the orders with missing printing files, the responsible and the customer company
   * @returns { Array<{ order: OrdersDocument; owner: UserdataDocument; company: CompaniesDocument }> } Sorted data
   */
  sortData = (data: Array<{ order: OrdersDocument; owner: UserdataDocument; company: CompaniesDocument }>) => {
    const { sortBy, sortDirection } = this.state;
    switch (sortBy) {
      case "at":
        return _.orderBy(data, o => o.order.identifier, [sortDirection]);
      case "customer":
        return _.orderBy(data, o => o.company.name, [sortDirection]);
      case "ordered":
        return _.orderBy(data, o => o.order.createdOn.getTime(), [sortDirection]);
      case "target":
        return _.orderBy(data, o => (o.order.targetDate ? o.order.targetDate.getTime() : 0), [sortDirection]);
      case "employee":
        return _.orderBy(data, o => o.owner.prename, [sortDirection]);
    }
  };

  /**
   * Renders the correct sorting icon.
   * @param key: Key for which the icon should be rendered
   * @returns { JSX.Element } Sorting icon
   */
  renderIcon = (key: string) => {
    const { sortBy, sortDirection } = this.state;
    return (
      <span className="text-secondary">
        {sortBy === key ? (
          sortDirection === "asc" ? (
            <i className="fas fa-chevron-circle-up" />
          ) : (
            <i className="fas fa-chevron-circle-down" />
          )
        ) : (
          <i className="fas fa-circle" />
        )}
      </span>
    );
  };

  render() {
    const { context } = this.props;
    const { employee, missingPrintingFiles, sortBy, sortDirection } = this.state;
    let missingFilesFiltered = employee
      ? missingPrintingFiles.filter(m => m.owner._id.toString() === employee)
      : missingPrintingFiles;
    missingFilesFiltered = this.sortData(missingFilesFiltered);
    return (
      <div className="kt-portlet kt-portlet--height-fluid" style={{ height: "625px" }}>
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">Missing Printing files</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="row">
            <div className="col-6">
              <div className="kt-widget1 text-center">
                <h3 className="kt-font-danger">
                  <b>{missingFilesFiltered.length} Orders</b>
                </h3>
              </div>
            </div>
            <div className="col-6 kt-widget1 text-center">
              <select className="form-control" onChange={this.handleChangeEmployee} value={employee}>
                <option value="">All</option>
                {context.userdata
                  .filter(u => u.company_id === "internal")
                  .map(u => (
                    <option key={u._id.toString()} value={u._id.toString()}>
                      {u.prename} {u.surname}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="table-responsive" style={{ maxHeight: 450, overflow: "scroll" }}>
            <table className="table">
              <thead>
                <tr>
                  <th className="pointer" style={{ width: "20%" }} onClick={() => this.handleChangeSort("at")}>
                    Order {this.renderIcon("at")}
                  </th>
                  <th className="pointer" style={{ width: "25%" }} onClick={() => this.handleChangeSort("customer")}>
                    Customer {this.renderIcon("customer")}
                  </th>
                  <th className="pointer" style={{ width: "15%" }} onClick={() => this.handleChangeSort("ordered")}>
                    Ordered {this.renderIcon("ordered")}
                  </th>
                  <th className="pointer" style={{ width: "15%" }} onClick={() => this.handleChangeSort("target")}>
                    Target {this.renderIcon("target")}
                  </th>
                  <th className="pointer" style={{ width: "25%" }} onClick={() => this.handleChangeSort("employee")}>
                    Owner {this.renderIcon("employee")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {missingFilesFiltered.map(m => (
                  <tr key={m.order._id.toString()}>
                    <td className="align-middle">
                      <Link
                        to={"/order/" + m.order._id.toString()}
                        className="kt-user-card-v2__name kt-link text-dark kt-font-bolder"
                      >
                        {"Order AT-" + m.order.identifier}
                      </Link>
                      <br />
                      <span className="text-dark">
                        {m.order.title.length > 20 ? m.order.title.substring(0, 17) + "..." : m.order.title}
                      </span>
                    </td>
                    <td className="align-middle">
                      <CompanyWidget company={m.company} type="company" />
                    </td>
                    <td className="align-middle">
                      {m.order.createdOn.toLocaleDateString("de-DE", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric"
                      })}
                    </td>
                    <td className="align-middle">
                      {m.order.targetDate
                        ? m.order.targetDate.toLocaleDateString("de-DE", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric"
                          })
                        : "Not set"}
                    </td>
                    <td className="align-middle">
                      <PersonWidget person={m.owner} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default MissingPrintingFiles;
