import React from "react";
import { ManufacturersDocument } from "../model/manufacturers.types";
import { MinimumCalendarPackaging } from "../model/packagings.types";
import { MinimumCalendarPackagingOrder } from "../model/packagingOrders.types";
import { MinimumCalendarCommodity } from "../model/commodities.types";
import { ColorsDocument } from "../model/colors.types";

export const DeliveryCalendarUserContext = React.createContext({
  commodities: [] as Array<MinimumCalendarCommodity>,
  colors: [] as Array<ColorsDocument>,
  manufacturer: {} as ManufacturersDocument,
  packagings: [] as Array<MinimumCalendarPackaging>,
  packagingOrders: [] as Array<MinimumCalendarPackagingOrder>,
  savedState: {} as any,
  saveComponentState: (key: string, state: any) => undefined
});
