import React, { PureComponent } from "react";
import { CustomOrder } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import baseUtils from "../../../utils/baseUtils";
import dateUtils from "../../../utils/dateUtils";
import { T_NOTE } from "../../../utils/timelineUtils";
import { OrdersDocument } from "../../../model/orders.types";
import { UserdataDocument } from "../../../model/userdata.types";
import { OrderTimelineNote } from "./OrderTimeline";

interface OrderNotesProps {
  order: CustomOrder | OrdersDocument;
  context: React.ContextType<typeof DataContext>;
  alternateText?: boolean;
  small?: boolean;
}

interface OrderNotesState {}

class OrderNotes extends PureComponent<OrderNotesProps, OrderNotesState> {
  render() {
    const { order, context, alternateText, small } = this.props;
    const notes = order.timeline.filter(entry => entry.type === T_NOTE);

    return (
      <div className="kt-notification">
        <OrderTimelineNote order={order} context={context} small={small} />
        <div className="kt-widget4 mt-4">
          {notes.length > 0 ? (
            <>
              {notes
                .slice()
                .reverse()
                .map(note => {
                  const user = baseUtils.getDocFromCollection(context.userdata, note.person);
                  return <OrderNote key={note.text + note.date} user={user} date={note.date} text={note.text} />;
                })}
            </>
          ) : (
            <div className="text-center mb-3 my-auto">
              <span className="d-block my-5 ml-5 mr-0 pb-5">
                {alternateText ? (
                  <>
                    <i className="fa fa-check text-success mr-1 kt-font__bold" />
                    Nobody left any special notes for this order that should be considered.
                  </>
                ) : (
                  <>
                    <i className="fa fa-exclamation-circle mr-1" style={{ color: "rgb(191,197,212)" }} />
                    No notes available. Feel free to create your first note.
                  </>
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default OrderNotes;

interface OrderNoteProps {
  user: UserdataDocument;
  text: string;
  date: Date;
}

class OrderNote extends PureComponent<OrderNoteProps> {
  render() {
    const { user, text, date } = this.props;
    const userName = user ? user.prename + " " + user.surname : "-";
    return (
      <div className="kt-notes">
        <div className="kt-notes__items">
          <div className="kt-notes__item pb-2 ">
            <div className="kt-notes__media">
              <img
                src={
                  user && user.img_url
                    ? user.img_url
                    : "https://private-label-factory.de/img/mail/placeholder_female.jpg"
                }
                alt="image"
              />
            </div>
            <div className="kt-widget4__info">
              <span className="kt-widget4__username">{userName}</span>
              <br />
              <span style={{ color: "#595d6e" }}>{text}</span>
              <p className="kt-widget4__text">{dateUtils.getTimeAgo(date)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
