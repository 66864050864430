import _ from "lodash";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import dbService from "../services/dbService";
import { DataContext } from "../context/dataContext";
import { RawbidsCommodity, RawbidsData, RawbidsOrderSnapshot } from "../model/commodities.types";
import baseUtils from "./baseUtils";
import { SupplierSelectedPrices } from "../components/common/supplier/CustomTypes";
import { GeneralDocument } from "../model/general.types";

export const DEFAULT_PACKAGING_SIZE = 25;

// RB CO States
export const CO_REQUESTEDBYCUSTOMER = "requestedByCustomer" as const; // Limit order type
export const CO_REQUESTEDSTOCK = "requestedStock" as const; // state before confirming stock order by creating order confirmation
export const CO_ORDEREDBYCUSTOMER = "orderedByCustomer" as const;
export const CO_ORDEREDATSUPPLIER = "orderedAtSupplier" as const;
export const CO_ARRIVEDATSTARTINGPORT = "customerOrderArrivedAtStartingPort" as const;
export const CO_SHIPPEDFROMSUPPLIER = "shippedFromSupplier" as const;
export const CO_HANDLEDATCUSTOMS = "handledAtCustoms" as const;
export const CO_SHIPPEDTOWAREHOUSE = "shippedToWarehouse" as const;
export const CO_HANDLEDATWAREHOUSE = "handledAtWarehouse" as const;
export const CO_PROCESSINGATWAREHOUSE = "processingAtWarehouse" as const; // warehouse order type
export const CO_PERFORMINGSERVICES = "performingServices" as const;
export const CO_SHIPPEDTOCUSTOMER = "shippedToCustomer" as const;
export const CO_ARCHIVED = "archived" as const;
export const CO_CANCELED = "canceled" as const;

const RB_SUPPLIER_KEY = "rawbidsSupplier";

export enum RawbidsProperties {
  COMPOSITION = "composition",
  SOLVENT = "solvent",
  ALLERGEN = "allergen",
  CATEGORY = "category",
  ODOR = "odor"
}

export interface RawbidsPricesResult {
  hasErrors: boolean;
  hasPrices: boolean;
  prices: {
    air: Array<RawbidsPrice>;
    sea: Array<RawbidsPrice>;
    warehouse: Array<RawbidsPrice>;
    incoming: Array<RawbidsPrice>;
  };
  invalidAmounts: { [amount: string]: Array<{ errorCode?: string; errorMessage: string }> };
}

export interface RawbidsRequestResult {
  neededAmounts: Array<number>;
  requestedAmounts: Array<number>;
  requestedCommodityId: string;
  rawbidsId: string;
  priceResult: Array<SupplierSelectedPrices>;
}

export interface RawbidsPrice {
  _id: BSON.ObjectId;
  amount: number;
  price: number;
  deliveryTime: number;
  currency: string;
  incoterm: string;
  date: Date;
}

export interface RawbidsOrderResult {
  success: boolean;
  data: RawbidsOrderSnapshot;
}

/**
 * Get a commodity property
 * @param commodity a rawbids commodity
 * @param type the rawbids property type
 * @returns {string | null} the property name or null if not found
 */
export function getRawbidsCommodityProperty(commodity: RawbidsCommodity, type: RawbidsProperties): string | null {
  const prop = commodity.properties.find(p => p.type === type);
  return prop?.name.en || null;
}

/**
 * Get a list of commodity properties
 * @param commodity a rawbids commodity
 * @param type the rawbids property type
 * @returns {Array<string> | null} list of property names or null if not found
 */
export function getRawbidsCommodityProperties(
  commodity: RawbidsCommodity,
  type: RawbidsProperties
): Array<string> | null {
  const props = commodity.properties.filter(p => p.type === type);
  return props.length > 0 ? props.map(p => p.name.en) : null;
}

/**
 * Get a list of commodity properties for multiple types
 * @param commodity a rawbids commodity
 * @param excludedTypes multiple property types that should be excluded
 * @returns {Array<string> | null} list of property names or null if not found
 */
export function getRawbidsCommodityPropertiesExcluded(
  commodity: RawbidsCommodity,
  excludedTypes: Array<string>
): Array<string> | null {
  const props = commodity.properties.filter(p => !excludedTypes.includes(p.type));
  return props.length > 0 ? props.map(p => p.name.en) : null;
}

/**
 * Fetch rawbids commodities
 * @param context optional, if set commodities are cached in context and taken from context if already existing
 * @param forceRefresh optional, flag to force refresh and not take cached results
 * @returns {Promise<Array<RawbidsCommodity>>} promise with list of rawbids commodities
 */
export async function fetchRawbidsCommodities(
  context?: React.ContextType<typeof DataContext>,
  forceRefresh?: boolean
): Promise<Array<RawbidsCommodity>> {
  if (context) {
    // If commodities already exist in context and are at max 12 hours old, take them from context
    if (
      !forceRefresh &&
      context.rawbidsCommodities &&
      context.rawbidsCommodities.commodities.length > 0 &&
      Math.abs(context.rawbidsCommodities.date.getTime() - new Date().getTime()) <= 12 * 60 * 60 * 1000
    ) {
      return context.rawbidsCommodities.commodities;
    }
  }
  const result = (await dbService.callFunction("fetchRawbidsCommodities", [], true)) as {
    success: boolean;
    data: Array<RawbidsCommodity>;
    error?: string;
  };
  if (!result.success) toast.error("Commodities could not be loaded from rawbids: " + result.error);
  else if (context) context.setRawbidsCommodities(result.data);

  return result.data;
}

/**
 * Fetch a single rawbids commodities
 * @param commodityId commodity id
 * @param context optional, if set commodities are cached in context and taken from context if already existing
 * @param forceFetch optional, flag to force fetching from rawbids and not take cached results
 * @returns {Promise<RawbidsCommodity | null>} promise with rawbids commodity or null
 */
export async function fetchRawbidsCommodity(
  commodityId: string,
  context?: React.ContextType<typeof DataContext>,
  forceFetch?: boolean
): Promise<RawbidsCommodity | null> {
  if (context) {
    // If commodities already exist in context and are at max 12 hours old, take them from context
    if (
      !forceFetch &&
      context.rawbidsCommodities &&
      context.rawbidsCommodities.commodities.length > 0 &&
      Math.abs(context.rawbidsCommodities.date.getTime() - new Date().getTime()) <= 12 * 60 * 60 * 1000
    ) {
      return baseUtils.getDocFromCollection(context.rawbidsCommodities.commodities, commodityId);
    }
  }
  const result = (await dbService.callFunction("fetchRawbidsCommodity", [commodityId], true)) as {
    success: boolean;
    data: RawbidsCommodity;
    error?: string;
  };
  if (!result.success) toast.error("Commodities could not be loaded from rawbids: " + result.error);

  return result.data;
}

/**
 * Filter rawbids commodities with search query
 * @param commodities list of rawbids commodities
 * @param search search query
 * @param keys optional, list of keys to search in
 * @returns {Array<RawbidsCommodity>} list of filtered rawbids commodities
 */
export function filterRawbidsCommodities(
  commodities: Array<RawbidsCommodity>,
  search: string,
  keys?: Array<string>
): Array<RawbidsCommodity> {
  if (!search.trim()) return commodities;
  return baseUtils.doFuseSearch(
    commodities,
    search,
    keys ?? ["title.en", "subtitle.en", "appearance.en", "hsCode", "casNumber", "properties.name.en"],
    { threshold: 0.15 }
  );
}

/**
 * Request prices for a list of amounts from rawbids
 * @param commodityId the commodity id
 * @param amounts list of amounts to request
 * @returns {Promise<RawbidsPricesResult>} prices result with rawbids prices
 */
export async function fetchRawbidsPrices(commodityId: BSON.ObjectId | string, amounts: Array<Number>) {
  return await dbService.callFunction<RawbidsPricesResult>("fetchRawbidsPrices", [commodityId, amounts], true);
}

/**
 * Fetch information about the given order from rawbids.
 * @param orderNo OrderNo of the order in rawbids (without leading RB-)
 * @returns {Promise<RawbidsOrderResult>} Result with success indicator and order information
 */
export async function fetchRawbidsOrder(orderNo: string): Promise<RawbidsOrderResult> {
  return await dbService.callFunction<RawbidsOrderResult>("fetchRawbidsOrder", [orderNo], true);
}

/**
 * Retrieves all available packaging sizes from rawbids data
 * @param rawbidsData the data saved in a commodity from which the packaging sizes should be retrieved from
 * @returns {Array<number>} an array including the found packaging size(s) or the default packaging size if none were found
 */
export function getPackagingSizes(rawbidsData: RawbidsData | null | undefined): Array<number> {
  return !!rawbidsData?.commoditySnapshot.packagingSizes?.length
    ? rawbidsData?.commoditySnapshot.packagingSizes?.map(p => p.packagingSize)
    : [DEFAULT_PACKAGING_SIZE];
}

/**
 * Validate an amount against the packaging sizes
 * @param amount a numeric value
 * @param packagingSizes list of available packaging sizes
 * @returns {string | undefined} an error message or undefined if amount is valid
 */
export function validateAmount(amount: number, packagingSizes: Array<number>): string | undefined {
  if (!isFinite(amount) || 0 || amount < 0) return `Invalid value ${amount}`;
  else if (!packagingSizes.some(pS => amount % pS === 0))
    return `Value ${amount} is not a multiple of ${packagingSizes.join(", ")}`;
  return undefined;
}

/**
 * Convert a rawbids price to a SupplierSelectedPrices object
 * @param type transport type
 * @param price a rawbids price object
 * @returns {SupplierSelectedPrices} a price object used by supplier prices components
 */
export function convertRawbidsPrice(type: string, price: RawbidsPrice): SupplierSelectedPrices {
  return {
    _id: price._id,
    totalPrice: price.price.toString(),
    purchasePrice: price.price.toString(),
    purchasePriceCurrency: price.currency,
    incoterm: price.incoterm,
    moq: price.amount.toString(),
    deliveryTime: price.deliveryTime.toString(),
    age: price.date,
    note: `${_.upperFirst(type)}. Requested via Rawbids API`
  };
}

/**
 * Converts a RawbidsPricesResult into an Array of SupplierSelectedPrices
 * @param rawbidsPrices the RawbidsPriceResult to convert
 * @returns {Array<SupplierSelectedPrices>} the converted prices as SupplierSelectedPrices Array
 */
export function convertRawbidsPriceResult(rawbidsPrices: RawbidsPricesResult): Array<SupplierSelectedPrices> {
  const allPrices: Array<[type: string, price: RawbidsPrice]> = [];
  Object.entries(rawbidsPrices.prices).forEach(([type, pricesForType]) => {
    if (pricesForType.length === 0) return;
    pricesForType.forEach(p => allPrices.push([type, p]));
  });
  return allPrices.map(([type, p]) => convertRawbidsPrice(type, p)).sort((p1, p2) => +p1.moq - +p2.moq);
}

/**
 * Get unique amounts for list of supplier prices
 * @param prices list of supplier prices
 * @param packagingSizes list of packaging sizes for the commodity
 * @returns {Array<{_id: BSON.ObjectId, amount: number>}} list of unique amounts with objectIds
 */
export function getUniqueAmounts(
  prices: Array<SupplierSelectedPrices>,
  packagingSizes: Array<{ _id: string; type: string; packagingSize: number }> | undefined
): Array<{ _id: BSON.ObjectId; amount: number }> {
  let amounts = prices.map(p => {
    return { _id: new BSON.ObjectId(), amount: +p.moq };
  });
  amounts = amounts.filter((v, i, a) => a.findIndex(v2 => v2.amount === v.amount) === i);
  if (amounts.length === 0)
    return [
      {
        _id: new BSON.ObjectId(),
        amount: packagingSizes && packagingSizes.length > 0 ? packagingSizes[0].packagingSize : DEFAULT_PACKAGING_SIZE
      }
    ];
  return amounts;
}

/**
 * Calculate the next valid request amount based on the available packaging sizes for each neededAmount given
 * @param packagingSizes the packaging sizes to match
 * @param neededAmounts how much is needed of that commodity, in kg
 * @param includeMultiples an optional array, if given, the calculated matched amount will be multiplied with each of the given numbers and added to the result (if not already existing)
 * @returns {Array<number>} an array including unique amounts matching the requested amounts in accordance with the available packaging sizes, and multiples of those results if includeMultiples was given
 */
export function getMatchingRequestAmounts(
  packagingSizes: Array<number>,
  neededAmounts: Array<number>,
  includeMultiples?: Array<number>
): Array<number> {
  const sortedPackagingSizes = [...packagingSizes].sort((a, b) => a - b);
  const smallestPackagingSize = sortedPackagingSizes[0]; // smallest packaging size
  let result: Set<number> = new Set(); // make sure each amount is unique
  for (let i = 0; i < neededAmounts.length; i++) {
    const amount = neededAmounts[i];
    let nearestMultiple = sortedPackagingSizes.find(size => size === amount)
      ? amount
      : Math.ceil(amount / smallestPackagingSize) * smallestPackagingSize;
    // Add not only the amount with the next best packaging size, but also multiples in case higher amounts would fetch a better price
    result.add(nearestMultiple);
    if (includeMultiples) {
      for (let j = 0; j < includeMultiples.length; j++) {
        result.add(nearestMultiple * includeMultiples[j]);
      }
    }
  }
  return Array.from(result);
}

/**
 * Retrieves the entry with the lowest purchase price
 * @param prices list of supplier prices
 * @returns {SupplierSelectedPrices} the SupplierSelectedPrice with the lowest purchase price
 */
export function getLowestPrice(prices: Array<SupplierSelectedPrices>): SupplierSelectedPrices {
  return prices.reduce((lowest, current) => {
    return parseFloat(current.purchasePrice) < parseFloat(lowest.purchasePrice) ? current : lowest;
  }, prices[0]);
}

/**
 * Retrieves the price entry with the fastest delivery time
 * @param prices list of supplier prices
 * @returns {SupplierSelectedPrices} the SupplierSelectedPrice with the fastest delivery time
 */
export function getPriceWithFastestDelivery(prices: Array<SupplierSelectedPrices>): SupplierSelectedPrices {
  return prices.reduce((lowest, current) => {
    return parseFloat(current.deliveryTime) < parseFloat(lowest.deliveryTime) ? current : lowest;
  }, prices[0]);
}

/**
 * Get they id for the rawbids supplier from context
 * @param general list of general documents
 * @returns {string | null} the id of rawbids supplier or null
 */
export function getRawbidsSupplierId(general: Array<GeneralDocument>): string | null {
  const generalValue: GeneralDocument | undefined = general.find(g => g.data === RB_SUPPLIER_KEY);
  if (generalValue) return generalValue.value.toString();
  return null;
}

/**
 * Get the default Rawbids Order Snapshot.
 * @returns {RawbidsOrderSnapshot} Default snapshot
 */
export function getDefaultRawbidsOrderSnapshot(): RawbidsOrderSnapshot {
  return {
    _id: "",
    amount: 0,
    orderNo: "",
    changedETA: "",
    currency: "",
    commodity: { _id: "", title: { en: "" }, subtitle: { en: "" } },
    createdAt: "",
    customerReference: "",
    noteCustomer: "",
    terms: { deliveryTerms: "", note: "", paymentTerms: "", paymentTermConditions: "", deliveryCity: "" },
    state: "",
    destination: "",
    totalPrice: 0,
    unit: "",
    targetDate: "",
    transport: "",
    supplier: { name: "" }
  };
}

/**
 * Retrieves the meaning of the Rawbids Customer Order state.
 * @param order Order Snapshot whose order state should be retrieved, if undefined is passed "Unknown" is returned
 * @returns {string} State description
 */
export function getRawbidsOrderStateDescription(order: RawbidsOrderSnapshot | undefined): string {
  const state = order?.state;
  switch (state) {
    case CO_REQUESTEDBYCUSTOMER:
    case CO_REQUESTEDSTOCK:
      return "Requested";
    case CO_ORDEREDBYCUSTOMER:
    case CO_ORDEREDATSUPPLIER:
      return "Ordered";
    case CO_ARRIVEDATSTARTINGPORT:
    case CO_SHIPPEDFROMSUPPLIER:
      return "Shipped by Supplier";
    case CO_HANDLEDATCUSTOMS:
      return "At Customs";
    case CO_SHIPPEDTOWAREHOUSE:
      return "Shipped to Rawbids Warehouse";
    case CO_HANDLEDATWAREHOUSE:
    case CO_PROCESSINGATWAREHOUSE:
      return "Handled at Rawbids Warehouse";
    case CO_PERFORMINGSERVICES:
      return "Performing Services";
    case CO_SHIPPEDTOCUSTOMER:
      return "Delivery Imminent";
    case CO_ARCHIVED:
      return "Order Completed";
    case CO_CANCELED:
      return "Order Canceled";
    default:
      return "Unknown";
  }
}
