import userService from "../services/userService";
import { ROLES } from "./userdataUtils";

export const EDITLOCATIONS = {
  CAPSULESETTINGS: "capsuleSettings",
  COMMODITYSETTINGS: "commoditySettings",
  COMMODITYSTOCK: "commodityStock",
  COMMODITYORDER: "commodityOrders",
  COMMODITYPRICES: "commodityPrices",
  ORDERTITLE: "orderTitle",
  PACKAGINGSTOCK: "packagingStock",
  PACKAGINGORDER: "packagingOrder",
  PACKAGINGSETTINGS: "packagingSettings",
  PACKAGINGPRICES: "packagingPrices",
  GENERALSETTINGS: "generalSettings"
};

export const CREATELOCATIONS = {
  COMMODITY: "commodity",
  COMMODITYORDER: "commodityOrder",
  COMMODITYSTOCK: "commodityStock",
  PACKAGINGSTOCK: "packagingStock",
  PACKAGINGORDER: "packagingOrder",
  PACKAGING: "packaging",
  GENERALSETTINGS: "generalSettings",
  SUPPLIER: "supplier",
  INVOICE: "invoice",
  CAPSULE: "capsule"
};

export const DELETELOCATIONS = {
  COMMODITY: "commodity",
  PACKAGING: "packaging",
  COMMODITYSTOCK: "commodityStock",
  PACKAGINGSTOCK: "packagingStock",
  GENERALSETTINGS: "generalSettings",
  SUPPLIER: "supplier"
};

export const ACTIONS = {
  COMMODITYDELIVER: "commodityDeliver",
  COMMODITYCANCEL: "commodityCancel",
  PACKAGINGORDER: "orderPackagingOrder",
  PACKAGINGCANCEL: "packagingCancel",
  PACKAGINGDELIVER: "packagingDeliver",
  INVOICEPAY: "invoicePay",
  INVOICEREMINDER: "invoiceReminder",
  INVOICECANCEL: "invoiceCancel",
  ORDERCOMMODITYCHECK: "orderCommodityCheck",
  ORDERPACKAGINGCHECK: "orderPackagingCheck",
  ORDERCOMMODITYORDER: "orderCommodityOrder",
  ORDERPACKAGINGORDER: "orderPackagingOrder",
  ORDERCOMMODITYREPLACE: "orderCommodityReplace",
  ORDERPACKAGINGREPLACE: "orderPackagingReplace",
  ORDERRETURNTOQUEUE: "orderReturnToQueue",
  ORDERPRODUCTIONFINISH: "orderProductionFinish",
  ORDERSHIPPED: "orderShipped",
  ORDERFINISH: "orderFinish",
  PRODUCTIONPLANPLAN: "productionPlanPlan",
  PRODUCTIONPLANRESCHEDULE: "productionPlanReschedule",
  PRODUCTIONPLANCANCEL: "productionPlanCancel",
  RESERVEMATERIAL: "reserveMaterial",
  SUPPLYCENTRALFEATURE: "supplyCentralFeature"
};

export const ACCESS_AREAS = {
  FINANCE: "finance",
  PRODUCTION: "production",
  SALES: "sales",
  PROCUREMENT: "procurement",
  PROCUREMENT_NOT_PRODUCTION: "procurementNotProduction",
  WAREHOUSE: "warehouse"
};

/**
 * Checks if the current user has a role that allows them to access the requested area.
 * @param area Access area whose access should be checked
 * @returns { boolean } Indicating whether the access is allowed or not
 */
function canAccessArea(area: string) {
  switch (area) {
    case ACCESS_AREAS.FINANCE:
      return userService.hasOneOfRoles([ROLES.FINANCE, ROLES.CONTROLLING]);
    case ACCESS_AREAS.PRODUCTION:
      return userService.hasOneOfRoles([ROLES.PRODUCTION, ROLES.CONTROLLING]);
    case ACCESS_AREAS.SALES:
      return userService.hasOneOfRoles([ROLES.SALES, ROLES.CONTROLLING]);
    case ACCESS_AREAS.PROCUREMENT_NOT_PRODUCTION:
      return (
        userService.hasOneOfRoles([ROLES.PROCUREMENT, ROLES.PACKAGINGPROCUREMENT]) &&
        !userService.hasRole(ROLES.PRODUCTION, true)
      );
    case ACCESS_AREAS.PROCUREMENT:
      return userService.hasOneOfRoles([ROLES.PROCUREMENT, ROLES.PACKAGINGPROCUREMENT]);
    case ACCESS_AREAS.WAREHOUSE:
      return userService.hasOneOfRoles([ROLES.WAREHOUSE, ROLES.CONTROLLING]);
    default:
      return false;
  }
}

/**
 * Checks if the current user has a role that allows them to access one of the requested areas.
 * @param areas Array of areas whose access should be checked
 * @returns { boolean } Indicating whether the access is allowed or not
 */
function canAccessOneOfAreas(areas: Array<string>) {
  let canAccess = false;
  for (let i = 0; i < areas.length; i++) {
    canAccess = canAccess || canAccessArea(areas[i]);
  }
  return canAccess;
}

/**
 * Wrapper function to check if the current user has access to financial data. Currently sales, finance, and procurement
 * that is not also production has access.
 * @returns { boolean } Indicating whether the current user satisfies the conditions or not
 */
function canSeeFinanceData() {
  return canAccessOneOfAreas([ACCESS_AREAS.SALES, ACCESS_AREAS.FINANCE, ACCESS_AREAS.PROCUREMENT_NOT_PRODUCTION]);
}

/**
 * Determine if the user can edit the data at the referenced location
 * @param location Location where data should be edited
 * @returns { boolean } Indicates whether the user can edit the data or not
 */
function canEditData(location: string) {
  switch (location) {
    case EDITLOCATIONS.PACKAGINGSETTINGS:
    case EDITLOCATIONS.PACKAGINGPRICES:
    case EDITLOCATIONS.PACKAGINGORDER:
      return userService.hasOneOfRoles([ROLES.PROCUREMENT, ROLES.PACKAGINGPROCUREMENT, ROLES.DATAMAINTENANCE]);
    case EDITLOCATIONS.PACKAGINGSTOCK:
      return userService.hasOneOfRoles([
        ROLES.PRODUCTION,
        ROLES.PROCUREMENT,
        ROLES.PACKAGINGPROCUREMENT,
        ROLES.DATAMAINTENANCE
      ]);
    case EDITLOCATIONS.COMMODITYSETTINGS:
    case EDITLOCATIONS.COMMODITYORDER:
    case EDITLOCATIONS.COMMODITYPRICES:
    case EDITLOCATIONS.GENERALSETTINGS:
      return userService.hasOneOfRoles([ROLES.PROCUREMENT, ROLES.DATAMAINTENANCE]);
    case EDITLOCATIONS.CAPSULESETTINGS:
      return userService.hasOneOfRoles([ROLES.PRODUCTION, ROLES.PROCUREMENT, ROLES.DATAMAINTENANCE]);
    case EDITLOCATIONS.COMMODITYSTOCK:
      return userService.hasOneOfRoles([ROLES.PRODUCTION, ROLES.PROCUREMENT, ROLES.DATAMAINTENANCE]);
    case EDITLOCATIONS.ORDERTITLE:
      return userService.hasRole(ROLES.SALES);
    default:
      return false;
  }
}

/**
 * Determine if the user can create data at the referenced location.
 * @param location Location where data should be created
 * @returns { boolean } Indicates whether the user can create the data or not
 */
function canCreateData(location: string) {
  switch (location) {
    case CREATELOCATIONS.PACKAGING:
    case CREATELOCATIONS.PACKAGINGORDER:
      return userService.hasOneOfRoles([ROLES.PROCUREMENT, ROLES.PACKAGINGPROCUREMENT, ROLES.DATAMAINTENANCE]);
    case CREATELOCATIONS.PACKAGINGSTOCK:
      return userService.hasOneOfRoles([
        ROLES.PRODUCTION,
        ROLES.PROCUREMENT,
        ROLES.PACKAGINGPROCUREMENT,
        ROLES.DATAMAINTENANCE
      ]);
    case CREATELOCATIONS.COMMODITY:
    case CREATELOCATIONS.COMMODITYORDER:
    case CREATELOCATIONS.GENERALSETTINGS:
    case CREATELOCATIONS.SUPPLIER:
      return userService.hasOneOfRoles([ROLES.PROCUREMENT, ROLES.DATAMAINTENANCE]);
    case CREATELOCATIONS.CAPSULE:
      return userService.hasOneOfRoles([ROLES.PRODUCTION, ROLES.PROCUREMENT, ROLES.DATAMAINTENANCE]);
    case CREATELOCATIONS.COMMODITYSTOCK:
      return userService.hasOneOfRoles([ROLES.PRODUCTION, ROLES.PROCUREMENT, ROLES.DATAMAINTENANCE]);
    case CREATELOCATIONS.INVOICE:
      return userService.hasRole(ROLES.FINANCE);
    default:
      return false;
  }
}

/**
 * Determine if the user can delete data at the referenced location.
 * @param location Location where data should be deleted
 * @returns { boolean } Indicates whether the user can delete the data or not
 */
function canDeleteData(location: string) {
  switch (location) {
    case DELETELOCATIONS.COMMODITY:
      return userService.hasOneOfRoles([ROLES.PROCUREMENT, ROLES.DATAMAINTENANCE]);
    case DELETELOCATIONS.GENERALSETTINGS:
      return userService.hasRole(ROLES.ADMIN);
    case DELETELOCATIONS.PACKAGING:
      return userService.hasOneOfRoles([ROLES.PROCUREMENT, ROLES.PACKAGINGPROCUREMENT, ROLES.DATAMAINTENANCE]);
    case DELETELOCATIONS.PACKAGINGSTOCK:
      return userService.hasOneOfRoles([
        ROLES.PRODUCTION,
        ROLES.PACKAGINGPROCUREMENT,
        ROLES.PROCUREMENT,
        ROLES.DATAMAINTENANCE
      ]);
    case DELETELOCATIONS.COMMODITYSTOCK:
      return userService.hasOneOfRoles([ROLES.PRODUCTION, ROLES.PROCUREMENT, ROLES.DATAMAINTENANCE]);
    case DELETELOCATIONS.SUPPLIER:
      return userService.hasOneOfRoles([
        ROLES.PROCUREMENT,
        ROLES.PACKAGINGPROCUREMENT,
        ROLES.DATAMAINTENANCE,
        ROLES.CONTROLLING
      ]);
    default:
      return false;
  }
}

/**
 * Determine if the user can perform the referenced action.
 * @param action Action that the user wants to perform
 * @returns { boolean } Indicates whether the user can perform the action or not
 */
function canPerformAction(action: string) {
  switch (action) {
    case ACTIONS.PACKAGINGORDER:
    case ACTIONS.PACKAGINGDELIVER:
    case ACTIONS.RESERVEMATERIAL:
      return userService.hasOneOfRoles([ROLES.PROCUREMENT, ROLES.PACKAGINGPROCUREMENT, ROLES.PRODUCTION]);
    case ACTIONS.ORDERPACKAGINGCHECK:
    case ACTIONS.ORDERPACKAGINGORDER:
    case ACTIONS.PACKAGINGCANCEL:
      return userService.hasOneOfRoles([ROLES.PROCUREMENT, ROLES.PACKAGINGPROCUREMENT]);
    case ACTIONS.COMMODITYDELIVER:
    case ACTIONS.ORDERCOMMODITYREPLACE:
      return userService.hasOneOfRoles([ROLES.PROCUREMENT, ROLES.PRODUCTION]);
    case ACTIONS.ORDERPACKAGINGREPLACE:
      return userService.hasOneOfRoles([ROLES.PROCUREMENT, ROLES.PRODUCTION, ROLES.PACKAGINGPROCUREMENT]);
    case ACTIONS.ORDERCOMMODITYCHECK:
    case ACTIONS.ORDERCOMMODITYORDER:
    case ACTIONS.COMMODITYCANCEL:
      return userService.hasRole(ROLES.PROCUREMENT);
    case ACTIONS.INVOICEPAY:
    case ACTIONS.INVOICEREMINDER:
    case ACTIONS.INVOICECANCEL:
      return userService.hasRole(ROLES.FINANCE);
    case ACTIONS.ORDERRETURNTOQUEUE:
    case ACTIONS.ORDERPRODUCTIONFINISH:
      return userService.hasOneOfRoles([ROLES.SALES, ROLES.PRODUCTION, ROLES.CONTROLLING, ROLES.DATAMAINTENANCE]);
    case ACTIONS.PRODUCTIONPLANPLAN:
    case ACTIONS.PRODUCTIONPLANRESCHEDULE:
    case ACTIONS.PRODUCTIONPLANCANCEL:
      return userService.hasRole(ROLES.PRODUCTION);
    case ACTIONS.ORDERSHIPPED:
      return userService.hasOneOfRoles([
        ROLES.PRODUCTION,
        ROLES.SALES,
        ROLES.FINANCE,
        ROLES.CONTROLLING,
        ROLES.DATAMAINTENANCE
      ]);
    case ACTIONS.ORDERFINISH:
      return userService.hasOneOfRoles([ROLES.FINANCE, ROLES.CONTROLLING, ROLES.DATAMAINTENANCE]);
    case ACTIONS.SUPPLYCENTRALFEATURE:
      return userService.isAdmin();
    default:
      return false;
  }
}

export default {
  canCreateData,
  canDeleteData,
  canEditData,
  canPerformAction,
  canAccessArea,
  canAccessOneOfAreas,
  canSeeFinanceData
};
