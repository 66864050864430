import React from "react";
import { toAbsoluteUrl } from "../../../_metronic";
import { ExtendedEMCommodity } from "../../../model/customTypes.types";
import fileUtils from "../../../utils/fileUtils";
import dateUtils from "../../../utils/dateUtils";

interface EmCommodityDocumentsProps {
  commodity: ExtendedEMCommodity;
}

const EMCommodityDocuments: React.FunctionComponent<EmCommodityDocumentsProps> = ({ commodity }) => {
  const documentCounter: any = {};

  const getDocumentNumber = (type: string) => {
    if (type in documentCounter) {
      documentCounter[type]++;
    } else {
      documentCounter[type] = 1;
    }
    return documentCounter[type];
  };

  return (
    <div className="kt-portlet ">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Documents</h3>
        </div>
        <div className="kt-portlet__head-toolbar" />
      </div>
      <div className="kt-portlet__body">
        <div className="kt-notification">
          <div className="kt-widget4">
            {commodity.specifications.length > 0 &&
              commodity.specifications
                .sort((s1, s2) => s1.date.getTime() - s2.date.getTime())
                .map((item, key) => (
                  <div key={item.path + item.date.toString() + key} className="kt-widget4__item">
                    <div className="kt-widget4__pic kt-widget4__pic--icon">
                      <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />
                    </div>
                    <div className="kt-widget4__info">
                      <div>
                        <a
                          href={item.path}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="kt-widget4__username mr-2"
                        >
                          {fileUtils.getDisplayableText(item.type)} - {getDocumentNumber(item.type)}
                        </a>
                      </div>
                      <p className="kt-widget4__text">{dateUtils.getTimeAgo(item.date)}</p>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EMCommodityDocuments;
