import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { ExtendedBatchLocation, ExtendedPackagingUnit } from "../../../../../model/warehouse/customTypes.types";
import { formatNumValue, round } from "../../../../../utils/baseUtils";
import { resolveTranslation } from "../../../../../utils/translationUtils";
import Tooltip from "../../../../common/Tooltip";
import i18n from "../../../../../translations/i18n";

interface DirectlyManagedWarehousePackagingUnitsProps {
  location: ExtendedBatchLocation;
  onChangePUAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPUTakeAll: (packagingUnitId: string) => void;
}

export const DirectlyManagedWarehousePackagingUnits: React.FC<DirectlyManagedWarehousePackagingUnitsProps> = ({
  location,
  onChangePUAmount,
  onPUTakeAll
}) => {
  return (
    <>
      <tr className={"kt-datatable__row d-table-row "}>
        <td colSpan={7} className="px-0">
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive py-2 px-5 bg-light mb-0">
            <table className="kt-datatable__table d-table bg-light">
              <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
                <tr className="kt-datatable__row d-table-row border-0">
                  <th className="kt-datatable__cell d-table-cell pb-0 ">
                    <span className="font-weight-300">{i18n.t("warehouse:packagingUnits")}</span>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </td>
      </tr>
      <tr className={"kt-datatable__row d-table-row"}>
        <td colSpan={7} className="px-0">
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive py-2 px-5 mb-0">
            <table className="kt-datatable__table d-table p-5">
              <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
                <tr className="kt-datatable__row d-table-row">
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "12%" }}>
                    <span className="font-weight-300">{i18n.t("common:description")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "12%" }}>
                    <span className="font-weight-300">{i18n.t("common:quantity")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "23%" }}>
                    <span className="font-weight-300">{i18n.t("warehouse:amount")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "23%" }}>
                    <span className="font-weight-300">{i18n.t("warehouse:usedAmount")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "20%" }}>
                    <span className="font-weight-300">{i18n.t("warehouse:restAmount")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "10%" }}>
                    <span className="font-weight-300">{i18n.t("warehouse:restQuantity")}</span>
                  </th>
                </tr>
              </thead>
              <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                {location.packagingUnits.map(pU => (
                  <ExtendedPackagingUnitRow
                    key={pU._id.toString()}
                    packagingUnit={pU}
                    onChangePUAmount={onChangePUAmount}
                    onPUTakeAll={onPUTakeAll}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </>
  );
};

interface ExtendedPackagingUnitRowProps {
  packagingUnit: ExtendedPackagingUnit;
  onChangePUAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPUTakeAll: (packagingUnitId: string) => void;
}

const ExtendedPackagingUnitRow: React.FC<ExtendedPackagingUnitRowProps> = React.memo(
  ({ packagingUnit, onChangePUAmount, onPUTakeAll }) => {
    const handlePUTakeAll = useCallback(() => onPUTakeAll(packagingUnit._id.toString()), []);

    const totalAmount = useMemo(
      () => packagingUnit.amountPerPu.value * (packagingUnit.quantity || 0),
      [packagingUnit.amountPerPu, packagingUnit.quantity]
    );

    const amountLeft = useMemo(
      () => totalAmount - +packagingUnit.amountToBookOut,
      [totalAmount, packagingUnit.amountToBookOut]
    );

    const packagingUnitsLeft = useMemo(
      () => round(amountLeft / packagingUnit.amountPerPu.value),
      [amountLeft, packagingUnit.amountPerPu]
    );

    const nothingLeft = useMemo(() => amountLeft <= 0, [amountLeft]);

    return (
      <tr className="kt-datatable__row d-table-row border-0">
        <td className="kt-datatable__cell d-table-cell pb-0">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details ">
              <span className="kt-user-card-v2__name text-black ">
                {formatNumValue(packagingUnit.amountPerPu)} {resolveTranslation(packagingUnit.puSnapshot.label)}
              </span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell pb-0">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details ">
              <span className="kt-user-card-v2__name text-black font-weight-300">{packagingUnit.quantity || "-"}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell pb-0">
          <div className="input-group input-group-sm">
            <input
              type={"text"}
              disabled
              className="form-control form-control-sm form-control-solid "
              value={totalAmount}
            />
            <div className="input-group-append">
              <span className="input-group-text input-group-solid">{packagingUnit.amountPerPu.unit}</span>
            </div>
            <div className="input-group-append input-group-solid">
              <Tooltip tooltipText={i18n.t(`warehouse:${nothingLeft ? "nothingLeft" : "useTotalAmount"}`)}>
                <button
                  className={"btn btn-light " + (!packagingUnit.quantity || nothingLeft ? "disabled" : "")}
                  onClick={!packagingUnit.quantity || nothingLeft ? undefined : handlePUTakeAll}
                >
                  <i className="fas fa-angle-double-right pr-0" />
                </button>
              </Tooltip>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell pb-0">
          <div className="input-group input-group-sm">
            <input
              type={"number"}
              min={0}
              name={packagingUnit._id.toString()}
              className={
                "form-control form-control-sm form-control-solid hide-arrows " + (amountLeft < 0 ? "is-invalid" : "")
              }
              onChange={onChangePUAmount}
              value={packagingUnit.amountToBookOut}
            />
            <div className="input-group-append">
              <span className="input-group-text input-group-solid">{packagingUnit.amountPerPu.unit}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell pb-0">
          <div className="input-group input-group-sm">
            <input
              type={"text"}
              disabled
              className="form-control form-control-sm form-control-solid "
              value={amountLeft}
            />
            <div className="input-group-append">
              <span className="input-group-text input-group-solid">{packagingUnit.amountPerPu.unit}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell pb-0 ">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details mx-auto">
              <span className="kt-user-card-v2__name text-black font-weight-300">{packagingUnitsLeft}</span>
            </div>
          </div>
        </td>
      </tr>
    );
  },
  _.isEqual
);
