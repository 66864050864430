import _ from "lodash";
import React, { PureComponent } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";
import {
  EmOrderTimelineEntry,
  ExternalManufacturerOrdersDocument
} from "../../../model/externalManufacturerOrders.types";
import baseUtils from "../../../utils/baseUtils";
import {
  EM_ARRIVED,
  EM_CANCELED,
  EM_CHECKED,
  EM_DELIVERED,
  EM_EXPIRED,
  EM_FINISHED,
  EM_ACCEPTED,
  EM_ORDERCANCELED,
  EM_ORDERCREATED,
  EM_ORDERED,
  EM_ORDEREDITED,
  EM_ORDERUNLINKED,
  EM_OPEN
} from "../ExternalManufacturerHelper";

interface Activity extends EmOrderTimelineEntry {
  order: ExternalManufacturerOrdersDocument;
}

interface EmDashboardActivitiesProps extends RouteComponentProps {
  context: React.ContextType<typeof ExternalManufacturerContext>;
}

interface EmDashboardActivitiesState {
  activities: Array<Activity>;
}

class EmDashboardActivities extends PureComponent<EmDashboardActivitiesProps, EmDashboardActivitiesState> {
  constructor(props: EmDashboardActivitiesProps) {
    super(props);
    this.state = {
      activities: this.getActivities(props)
    };
  }

  componentDidUpdate(
    prevProps: Readonly<EmDashboardActivitiesProps>,
    prevState: Readonly<EmDashboardActivitiesState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.context.externalManufacturerOrders, this.props.context.externalManufacturerOrders)) {
      this.setState({ activities: this.getActivities(this.props) });
    }
  }

  /**
   * Get Activities for external manufacturers
   * @param props the components properties
   * @returns {Array<Activity>} list of activities
   */
  getActivities = (props: EmDashboardActivitiesProps) => {
    const { externalManufacturerOrders: orders } = props.context;
    let activities: Array<Activity> = [];
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      if (!order.timeline || order.timeline.length === 0) continue;
      for (let j = 0; j < order.timeline.length; j++) {
        const activity = _.cloneDeep(order.timeline[j]) as Activity;
        activity.order = order;
        activities.push(activity);
      }
    }
    return activities.sort((a1, a2) => a2.date.getTime() - a1.date.getTime()).slice(0, 50);
  };

  render() {
    const { activities } = this.state;
    return (
      <div className="kt-portlet kt-portlet--height-fluid" style={{ minHeight: "40vh" }}>
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">Recent Updates</h3>
          </div>
        </div>
        <div className="kt-portlet__body ">
          {activities.length > 0 ? (
            <div className="kt-timeline-v2 overflow-auto" style={{ maxHeight: "50vh" }}>
              <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
                {activities.map(activity => (
                  <EmDashboardActivity key={activity._id.toString()} activity={activity} context={this.props.context} />
                ))}
              </div>
            </div>
          ) : (
            <div className="text-center text-muted my-5">No recent updates available</div>
          )}
        </div>
      </div>
    );
  }
}

interface EmDashboardActivityProps {
  activity: Activity;
  context: React.ContextType<typeof ExternalManufacturerContext>;
}

const EmDashboardActivity: React.FunctionComponent<EmDashboardActivityProps> = ({ activity, context }) => {
  const getActivityContent = () => {
    const { userdata, commodities } = context;
    const user = baseUtils.getDocFromCollection(userdata, activity.person);
    const userName = user ? `${user.prename} ${user.surname}` : "Unknown";
    const commodity = baseUtils.getDocFromCollection(commodities, activity.order.commodityId);
    if (!commodity)
      return (
        <>
          <span className="kt-font-bold mr-2">Unknown</span>
        </>
      );
    switch (activity.type) {
      case EM_ORDERCREATED:
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} created a new request for <em>{commodity.title.en}</em>.
            </span>
            <div>
              Requested {activity.order.amount}kg of {commodity.title.en}.
            </div>
          </>
        );
      case EM_CHECKED:
        return (
          <>
            <span className="kt-font-bold mr-2">Request was checked by {userName}.</span>
            <div>
              A price for {activity.order.amount}kg of {commodity.title.en} was set.
            </div>
          </>
        );
      case EM_ACCEPTED:
        return (
          <>
            <span className="kt-font-bold mr-2">{userName} accepted the suggested price.</span>
            <div>
              The suggested price for {activity.order.amount}kg of {commodity.title.en} was confirmed.
            </div>
          </>
        );
      case EM_ARRIVED:
        return (
          <>
            <span className="kt-font-bold mr-2">The package arrived at its final destination.</span>
            <div>
              {activity.order.amount}kg of {commodity.title.en} were successfully delivered.
            </div>
          </>
        );
      case EM_CANCELED:
        return (
          <>
            <span className="kt-font-bold mr-2">{userName} canceled the order.</span>
            <div>
              Order of {activity.order.amount}kg of {commodity.title.en} was canceled.
            </div>
          </>
        );
      case EM_ORDERCANCELED:
        return (
          <>
            <span className="kt-font-bold mr-2">{userName} reset the order.</span>
            <div>
              Order of {activity.order.amount}kg of {commodity.title.en} was reset due to the commodity order being.
              canceled by procurement
            </div>
          </>
        );
      case EM_ORDERED:
        return (
          <>
            <span className="kt-font-bold mr-2">{userName} included the required amount in a commodity order.</span>
            <div>
              Actual price: {activity.order.actualPrice ? baseUtils.formatEuro(activity.order.actualPrice) : "-"},
              Expected delivery:{" "}
              {activity.order.actualDeliveryDate ? baseUtils.formatDate(activity.order.actualDeliveryDate) : "-"}
            </div>
          </>
        );
      case EM_DELIVERED:
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} has received the commodity and is preparing further shipment to the final destination.
            </span>
            <div>
              {activity.order.amount}kg of {commodity.title.en} arrived at the airport and is now on the way to its
              final destination.
            </div>
          </>
        );
      case EM_EXPIRED:
        return (
          <>
            <span className="kt-font-bold mr-2">Order expired</span>
            <div>
              The order reached the latest delivery date {baseUtils.formatDate(activity.order.deliveryDateLimit!)} and
              expired.
            </div>
          </>
        );
      case EM_FINISHED:
        return (
          <>
            <span className="kt-font-bold mr-2">{userName} confirmed the arrival at the final destination.</span>
            <div>
              {activity.order.amount}kg of {commodity.title.en} arrived at its final destination.
            </div>
          </>
        );
      case EM_ORDERUNLINKED:
        return (
          <>
            <span className="kt-font-bold mr-2">{userName} reset the order.</span>
            <div>
              {activity.order.amount}kg of {commodity.title.en} couldn't be handled with the previously selected
              commodity order and was reset.
            </div>
          </>
        );
      case EM_ORDEREDITED:
        return (
          <>
            <span className="kt-font-bold mr-2">{userName} has edited the order.</span>
            <div>
              {activity.order.amount}kg of {commodity.title.en} was edited.
            </div>
          </>
        );
      default:
        return <span className="kt-font-bold mr-2">Unknown</span>;
    }
  };

  const activityContent = getActivityContent();
  return (
    <div className="kt-timeline-v2__item">
      <span className="kt-timeline-v2__item-time " style={{ fontSize: "0.95rem", paddingTop: ".32rem" }}>
        {activity.date.toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" })}
      </span>
      <div className="kt-timeline-v2__item-cricle lightblue">
        <i className="fa fa-genderless" />
      </div>
      <div className="kt-timeline-v2__item-text  kt-padding-top-5">
        <Link className="kt-link kt-font-bolder mr-1" to={"/order/" + activity.order._id.toString()}>
          {`${[EM_OPEN, EM_CHECKED].includes(activity.order.state) ? "REQ" : "EMO"}-${activity.order.identifier} ${
            activity.order.reference
          }:`}
        </Link>
        {activityContent}
      </div>
    </div>
  );
};

export default EmDashboardActivities;
