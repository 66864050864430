import React, { useCallback, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Table } from "react-bootstrap";
import i18n from "../../../translations/i18n";
import { useWarehouseContext } from "../../../context/warehouseContext";
import HistoryBackButton from "../../listings/common/HistoryBackButton";
import { SearchBar } from "../../listings/common/Filters";
import baseUtils from "../../../utils/baseUtils";
import Pagination, { paginate } from "../../common/Pagination";
import { ModalMode } from "../../../model/common.types";
import { resolveTranslation } from "../../../utils/translationUtils";
import { resolveFileTypeName } from "../../../utils/warehouseConfigUtils";
import CreateFileTypeModal from "./modals/CreateFileTypeModal";
import { useDataContext } from "../../../context/dataContext";
import { FileType } from "../../../model/configuration/warehouseConfiguration.types";

interface FileTypeSettingsProps extends RouteComponentProps {}

const FileTypeSettings: React.FunctionComponent<FileTypeSettingsProps> = ({ history }) => {
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();

  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({ pageSize: 10, currentPage: 1 });

  const searchResult = useMemo(() => {
    const ql = search.trim().toLowerCase();
    const fileTypes = warehouseContext.configuration?.values.fileTypes ?? [];
    return ql !== "" ? baseUtils.doFuseSearch<FileType>(fileTypes, ql, ["type.de", "shortName"]) : fileTypes;
  }, [search, warehouseContext.configuration?.values.fileTypes]);

  const paginatedFileTypes = useMemo(() => {
    return paginate(searchResult, pagination.currentPage, pagination.pageSize);
  }, [searchResult, pagination]);

  const handleSearch = useCallback(e => {
    setSearch(e.target.value);
    setPagination(prevState => {
      return { ...prevState, currentPage: 1 };
    });
  }, []);

  const handleChangePage = useCallback(page => {
    setPagination(prevState => {
      return { ...prevState, currentPage: page };
    });
  }, []);

  const handleChangePageSize = useCallback(pageSize => {
    setPagination({ pageSize, currentPage: 1 });
  }, []);

  /**
   * Check if file type is used somewhere in batch
   */
  const inUseMap = useMemo(() => {
    const { configuration: config } = warehouseContext;
    const usedFileTypes: {
      [key: string]: boolean;
    } = {};
    if (config) {
      const batchFileTypes = dataContext.batch.flatMap(b => b.files.flatMap(f => f.type));
      for (let i = 0; i < config.values.fileTypes.length; i++) {
        const fileType = config.values.fileTypes[i];
        usedFileTypes[fileType._id.toString()] = batchFileTypes.some(
          fT => fT._id.toString() === fileType._id.toString()
        );
      }
    }
    return usedFileTypes;
  }, [warehouseContext.configuration, dataContext.batch]);

  return (
    <>
      <div className="alert alert-light alert-elevate" style={{ backgroundColor: "white" }}>
        <div className="alert-icon">
          <i className="flaticon-warning kt-font-danger" />
        </div>
        <div className="alert-text">{i18n.t("warehouse:fileTypeConfigWarning")}</div>
      </div>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">{i18n.t("warehouse:fileTypes")}</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <HistoryBackButton history={history} text={i18n.t("common:back")} />
              <CreateFileTypeModal mode={ModalMode.CREATE} />
            </div>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-form kt-form--label-right">
            <div className="row align-items-center">
              <SearchBar search={search} onSearch={handleSearch} additionalSizeClasses={"col-md-2"} />
              <div className="col-xl-4 order-1 order-xl-2 kt-align-right">
                <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg d-xl-none" />
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded px-4">
            <Table>
              <thead>
                <tr>
                  <th style={{ width: "25%" }}>{i18n.t("warehouse:fileType")}</th>
                  <th style={{ width: "25%" }}>{i18n.t("warehouse:shortName")}</th>
                  <th style={{ width: "25%" }}>{i18n.t("warehouse:subtype")}</th>
                  <th className="text-right" style={{ width: "25%" }}>
                    {i18n.t("common:actions")}
                  </th>
                </tr>
              </thead>
              <tbody className="kt-datatable__body">
                {paginatedFileTypes.length > 0 &&
                  paginatedFileTypes.map(fT => {
                    return (
                      <tr key={fT._id.toString()}>
                        <td className="align-middle">{resolveTranslation(fT.type)}</td>
                        <td className="align-middle">{fT.shortName}</td>
                        <td className="align-middle">{resolveFileTypeName(fT.subtype)}</td>
                        <td className="text-right align-middle">
                          <span className="mr-2">
                            <CreateFileTypeModal
                              mode={ModalMode.EDIT}
                              fileTypeEdit={fT}
                              isFileTypeUsed={inUseMap[fT._id.toString()]}
                            />
                          </span>
                          <CreateFileTypeModal
                            mode={ModalMode.DELETE}
                            fileTypeEdit={fT}
                            isFileTypeUsed={inUseMap[fT._id.toString()]}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div className="kt-datatable__pager kt-datatable--paging-loaded justify-content-center">
              <Pagination
                itemsCount={warehouseContext.configuration ? warehouseContext.configuration.values.fileTypes.length : 0}
                pageSize={pagination.pageSize}
                onPageChange={handleChangePage}
                currentPage={pagination.currentPage}
                onPageSizeChange={handleChangePageSize}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileTypeSettings;
