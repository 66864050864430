import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BSON } from "realm-web";
import SampleRecipeItem from "./SampleRecipeItem";
import SampleRecipeModal from "./SampleRecipesModal";
import { PaginationState } from "../common/CustomTypes";
import Pagination, { paginate } from "../common/Pagination";
import { DataContext } from "../../context/dataContext";
import { SampleRecipesDocument } from "../../model/sampleRecipes.types";
import dbService, { SAMPLERECIPES } from "../../services/dbService";
import toastUtils from "../../utils/toastUtils";
import baseUtils, { getComponentState } from "../../utils/baseUtils";
import { SearchBar } from "../listings/common/Filters";

interface SampleRecipesProps extends RouteComponentProps<{}, {}, {}> {}
interface SampleRecipesState extends PaginationState {
  showId: BSON.ObjectId | null;
  query: string;
}

const CONSTRUCTORNAME = "SampleRecipes";

class SampleRecipes extends PureComponent<SampleRecipesProps, SampleRecipesState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: SampleRecipesProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const state = getComponentState(this.context, CONSTRUCTORNAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }

  handleReset = () => this.setState(this.getDefaultState());
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ query: e.target.value, currentPage: 1 });
  handleShowRecipe = (_id: BSON.ObjectId) => {
    this.setState({ showId: _id });
  };

  handleDeleteRecipe = async (_id: BSON.ObjectId) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.deleteDocument(SAMPLERECIPES, _id);
    await toastUtils.databaseOperationToast(
      !!res && res.deletedCount > 0,
      "Sample recipe deleted successfully",
      "Error deleting sample recipe",
      () => updateDocumentInContext(SAMPLERECIPES, _id)
    );
  };

  /**
   * Get the default state
   * @returns {SampleRecipesState} state for the component
   */
  getDefaultState = () => {
    return {
      currentPage: 1,
      pageSize: 10,
      showId: null,
      query: ""
    } as SampleRecipesState;
  };

  /**
   * Filters the sample recipes list. Title, subtitle and note are checked for matches.
   * @returns { Array<SampleRecipesDocument> } Filtered sample recipes list
   */
  filterRecipes = () => {
    const { query } = this.state;
    const { sampleRecipes } = this.context;
    const ql = query.trim().toLowerCase();
    if (ql !== "") {
      return baseUtils.doFuseSearch(sampleRecipes, ql, [
        "title.de",
        "title.en",
        "subtitle.de",
        "subtitle.en",
        "note.de",
        "note.en"
      ]);
    }
    return sampleRecipes;
  };

  render() {
    const { history } = this.props;
    const { currentPage, pageSize, showId, query } = this.state;
    const { sampleRecipes, userdata } = this.context;
    let recipeSelected = showId ? sampleRecipes.find(s => s._id.toString() === showId.toString()) : null;
    const recipesFiltered = this.filterRecipes();
    const recipesPaginated: Array<SampleRecipesDocument> = paginate(recipesFiltered, currentPage, pageSize);
    return (
      <>
        {recipeSelected && (
          <SampleRecipeModal
            show={!!showId}
            sampleRecipe={recipeSelected}
            onClose={() => this.setState({ showId: null })}
          />
        )}
        <div>
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              <div className="kt-portlet__head-label">
                <span className="kt-portlet__head-icon">
                  <i className="kt-font-brand fa fa-file" />
                </span>
                <h3 className="kt-portlet__head-title">Sample Recipes</h3>
                <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
                  Reset
                </button>
              </div>
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">
                  <button
                    onClick={() => {
                      history.goBack();
                    }}
                    className="btn btn-clean kt-margin-r-10"
                  >
                    <i className="la la-arrow-left" />
                    <span className="kt-hidden-mobile">Back</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="kt-form kt-form--label-right  kt-margin-b-10">
                <div className="row align-items-center">
                  <div className="col-xl-12 order-2 order-xl-1">
                    <div className="row align-items-center">
                      <SearchBar onSearch={this.handleSearch} search={query} additionalSizeClasses={"col-md-2"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="kt-portlet__body kt-portlet__body--fit">
              <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded px-4">
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: "22%" }}>Description English</th>
                      <th style={{ width: "22%" }}>Description German</th>
                      <th style={{ width: "10%" }}>Creation Date</th>
                      <th style={{ width: "10%" }} />
                      <th style={{ width: "10%" }} />
                      <th style={{ width: "16%" }}>Owner</th>
                      <th style={{ width: "10%" }} />
                    </tr>
                  </thead>
                  <tbody>
                    {recipesPaginated.length > 0 ? (
                      recipesPaginated.map(r => {
                        const owner = userdata.find(u => u._id.toString() === r.createdFrom.toString())!;
                        return (
                          <SampleRecipeItem
                            key={r._id.toString()}
                            recipeItem={r}
                            owner={owner}
                            onShow={this.handleShowRecipe}
                            onDelete={this.handleDeleteRecipe}
                          />
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={7}>
                          <img
                            src={process.env.PUBLIC_URL + "/media/img/no_results.jpg"}
                            style={{
                              display: "block",
                              margin: "0 auto",
                              height: "500px"
                            }}
                            alt="No result"
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="kt-datatable__pager kt-datatable--paging-loaded">
                  <Pagination
                    itemsCount={recipesPaginated.length}
                    pageSize={pageSize}
                    onPageChange={currentPage => this.setState({ currentPage })}
                    currentPage={currentPage}
                    onPageSizeChange={pageSize => this.setState({ pageSize })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SampleRecipes);
