import React, { Component } from "react";
import PersonWidget from "./PersonWidget";
import { DataContext } from "../../context/dataContext";
import { ROLES } from "../../utils/userdataUtils";

interface CanOnlyBeDoneByProcurementProps {
  location: "commodity" | "softgel" | "custom" | "service" | "packaging" | "supplier" | "capsule";
}

interface CanOnlyBeDoneByProcurementState {}

class CanOnlyBeDoneByProcurement extends Component<CanOnlyBeDoneByProcurementProps, CanOnlyBeDoneByProcurementState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  /**
   * Resolves the text of the location that can only be accessed by procurement.
   * @returns { string } Text for the location
   */
  resolveLocationText = () => {
    const { location } = this.props;
    switch (location) {
      case "commodity":
        return "Commodities";
      case "softgel":
        return "Softgels";
      case "custom":
        return "Custom products";
      case "service":
        return "Services";
      case "packaging":
        return "Packaging";
      case "supplier":
        return "Suppliers";
      case "capsule":
        return "Capsules";
    }
  };

  render() {
    const { location } = this.props;
    const { userdata } = this.context;
    const procurementUsers = userdata.filter(
      u =>
        !u.role.includes(ROLES.DATAMAINTENANCE) &&
        (u.role.includes(ROLES.PROCUREMENT) ||
          (location === "packaging" && u.role.includes(ROLES.PACKAGINGPROCUREMENT)))
    );
    return (
      <div className="align-self-center">
        <span className="h5 text-dark">
          {this.resolveLocationText()} can only be created by procurement.
          <br />
          Please contact one of the following users:
        </span>
        {procurementUsers.map(p => (
          <div className="m-4" key={p._id.toString()}>
            <PersonWidget person={p} />
          </div>
        ))}
      </div>
    );
  }
}

export default CanOnlyBeDoneByProcurement;
