import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import Select from "react-select";
import { DataContext } from "../../context/dataContext";
import manufacturerUtils from "../../utils/manufacturerUtils";
import HistoryBackButton from "../listings/common/HistoryBackButton";
import { ManufacturerFilter, OwnerFilter, PriorityFilter, SearchBar } from "../listings/common/Filters";
import dateUtils from "../../utils/dateUtils";
import SplashScreen from "../common/SplashScreen";
import { ProductionWeekDocument } from "../../model/productionPlan.types";
import { OrdersDocument } from "../../model/orders.types";
import orderUtils, { ARCHIVE, CREATEINVOICE, FULFILLMENT, PRODUCTION, PRODUCTIONQUEUE } from "../../utils/orderUtils";
import { T_CAPSULE, T_CUSTOM, T_LIQUID, T_POWDER, T_SERVICE, T_SOFTGEL, T_TABLET } from "../order/OrderHelper";
import ProductionPlanWeek from "./ProductionPlanWeek";
import PlanOrderModal from "./PlanOrderModal";
import { getComponentState } from "../../utils/baseUtils";
import ProductionPlanHelper from "./ProductionPlanHelper";

interface ProductionPlanProps extends RouteComponentProps {
  context: React.ContextType<typeof DataContext>;
}

interface ProductionPlanState {
  search: string;
  owner: "" | { value: string; label: string };
  priority: "" | { value: string; label: string };
  type: "" | { value: string; label: string };
  state: { value: string; label: string };
  manufacturer: "" | { value: string; label: string };
  manufacturerLocked: boolean;
  showEmptyWeeks: boolean;
  capacity: number;
  relevantOrders: Array<OrdersDocument>;
  filteredOrders: Array<OrdersDocument>;
  rescheduleOrder: OrdersDocument | null;
}

const CONSTRUCTORNAME = "ProductionPlan";

class ProductionPlan extends PureComponent<ProductionPlanProps, ProductionPlanState> {
  typeFilter: Array<{ value: string; label: string; matches?: Array<string> }> = [
    { value: T_CAPSULE, label: "Capsule" },
    { value: T_TABLET, label: "Tablet" },
    { value: T_POWDER, label: "Powder" },
    { value: T_LIQUID, label: "Liquid" },
    { value: T_CUSTOM, label: "Custom" },
    { value: T_SOFTGEL, label: "Softgel" }
  ];
  constructor(props: ProductionPlanProps) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  /**
   * Initialize the type filter
   * @param context the data context
   */
  initializeTypeFilter = (context: React.ContextType<typeof DataContext>) => {
    const { capsules } = context;
    let capsuleTypes: Array<{ value: string; label: string; matches: Array<string> }> = [];
    for (let i = 0; i < capsules.length; i++) {
      const capsule = capsules[i];
      const capsuleType = capsuleTypes.find(c => c.value === T_CAPSULE + capsule.capsule_size);
      if (capsuleType) capsuleType.matches.push(capsule._id.toString());
      else
        capsuleTypes.push({
          value: T_CAPSULE + capsule.capsule_size,
          label: `Capsule (Size ${capsule.capsule_size})`,
          matches: [capsule._id.toString()]
        });
    }
    this.typeFilter = this.typeFilter
      .slice(0, 1)
      .concat(capsuleTypes)
      .concat(this.typeFilter.slice(1));
  };

  componentDidMount() {
    const relevantOrders = this.getRelevantOrders();
    const state = getComponentState(this.props.context, CONSTRUCTORNAME);
    if (state) {
      state.relevantOrders = relevantOrders;
      state.filteredOrders = this.getFilteredOrders(relevantOrders, state);
      state.capacity = ProductionPlanHelper.getCalculatedCapacity(
        this.props.context.orders,
        this.state.manufacturer ? this.state.manufacturer.value : ""
      );
      this.setState({ ...state });
    } else {
      this.setState({
        relevantOrders,
        filteredOrders: this.getFilteredOrders(relevantOrders)
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<ProductionPlanProps>,
    prevState: Readonly<ProductionPlanState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.context.orders, this.props.context.orders)) {
      const relevantOrders = this.getRelevantOrders();
      this.setState({
        relevantOrders,
        filteredOrders: this.getFilteredOrders(relevantOrders),
        capacity: ProductionPlanHelper.getCalculatedCapacity(
          this.props.context.orders,
          this.state.manufacturer ? this.state.manufacturer.value : ""
        )
      });
    }
    if (
      prevState.search !== this.state.search ||
      prevState.owner !== this.state.owner ||
      prevState.priority !== this.state.priority ||
      prevState.type !== this.state.type ||
      prevState.state !== this.state.state ||
      (!this.state.manufacturerLocked && prevState.manufacturer !== this.state.manufacturer)
    ) {
      if (prevState.manufacturer !== this.state.manufacturer)
        this.setState({
          filteredOrders: this.getFilteredOrders(this.state.relevantOrders),
          capacity: ProductionPlanHelper.getCalculatedCapacity(
            this.props.context.orders,
            this.state.manufacturer ? this.state.manufacturer.value : ""
          )
        });
      else this.setState({ filteredOrders: this.getFilteredOrders(this.state.relevantOrders) });
    }
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }

  handleReset = () => this.setState(this.getDefaultState(this.props, true));
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });
  handleSelectChange = (name: string, entry: "" | { value: string; label: string }) => {
    // @ts-ignore
    this.setState({ [name]: entry ? { value: entry.value, label: entry.label } : "" });
  };
  handleReschedule = (order: OrdersDocument) => this.setState({ rescheduleOrder: order });
  handleClose = () => this.setState({ rescheduleOrder: null });

  /**
   * Get the default state
   * @param props the components properties
   * @param reset flag if it is a reset or not. on reset orders are kept
   * @returns {ProductionPlanState} default state for the component
   */
  getDefaultState = (props: ProductionPlanProps, reset?: boolean) => {
    const manufacturer = manufacturerUtils.checkCurrentUserManufacturerObject(props.context.manufacturers);
    const relevantOrders = reset ? this.state.relevantOrders : [];
    const filteredOrders = reset ? this.state.filteredOrders : [];
    const capacity = ProductionPlanHelper.getCalculatedCapacity(
      props.context.orders,
      manufacturer ? manufacturer.value : ""
    );
    return {
      capacity,
      search: "",
      owner: "",
      priority: "",
      type: "",
      state: { value: "", label: "All Statuses" },
      manufacturer: manufacturer,
      manufacturerLocked: manufacturer !== "",
      showEmptyWeeks: false,
      relevantOrders,
      filteredOrders,
      rescheduleOrder: null
    } as ProductionPlanState;
  };

  /**
   * Get only relevant orders, i.e. in correct state, no service and if manufacturer
   * is locked only orders of that manufacturer
   * @returns {Array<OrdersDocument>} list of relevant orders
   */
  getRelevantOrders = () => {
    const { manufacturer, manufacturerLocked } = this.state;
    return this.props.context.orders.filter(
      o =>
        [PRODUCTIONQUEUE, PRODUCTION, FULFILLMENT, CREATEINVOICE, ARCHIVE].includes(o.state) &&
        o.settings.type !== T_SERVICE &&
        (!manufacturerLocked || o.settings.manufacturer.toString() === (manufacturer ? manufacturer.value : "")) &&
        o.settings.productionMachine &&
        o.productionWeek
    );
  };

  /**
   * Get filtered orders
   * @param relevantOrders list of relevant orders to filter
   * @param preferredState pass a state object to use instead of this.state to filter orders for
   * @returns {Array<OrdersDocument>} list of all orders matching the filters
   */
  getFilteredOrders = (relevantOrders: Array<OrdersDocument>, preferredState?: ProductionPlanState) => {
    const { companies, userdata } = this.props.context;
    const { search, owner, manufacturer, priority, type, state, manufacturerLocked } = preferredState
      ? preferredState
      : this.state;
    let filteredOrders = orderUtils.filterOrders(
      relevantOrders,
      undefined,
      owner ? owner.value : "",
      priority ? priority.value : "",
      undefined,
      manufacturerLocked ? undefined : manufacturer ? manufacturer.value : ""
    );
    if (type) {
      const typeFilter = this.typeFilter.find(t => t.value === type.value);
      filteredOrders = filteredOrders.filter(o => {
        if (typeFilter && typeFilter.matches) return typeFilter.matches.includes(o.settings.id.toString());
        else return o.settings.type === type.value;
      });
    }
    if (state.value)
      filteredOrders = filteredOrders.filter(o => {
        switch (state.value) {
          case "overdue":
            return o.targetDate && dateUtils.getCW(o.targetDate) <= dateUtils.getCW(new Date());
        }
      });
    if (search.trim() !== "")
      filteredOrders = orderUtils.filterBySearch(filteredOrders, companies, userdata, search) as Array<OrdersDocument>;
    return filteredOrders;
  };

  /**
   * Get all orders for a week that matches the filter
   * @param week the week document
   * @returns {Array<OrdersDocument>} list of orders of the given week matching filters, etc.
   */
  getOrdersForWeek = (week: ProductionWeekDocument) => {
    const { filteredOrders } = this.state;
    const weekOrders = week.plannedOrders.map(o => filteredOrders.find(o2 => o2._id.toString() === o.toString()));
    // only return defined entries
    return weekOrders.filter(o => !!o) as Array<OrdersDocument>;
  };

  /**
   * Sort weeks in an ascending order
   * @param w1 first week
   * @param w2 second week
   * @returns {number} negative value if w2 is later than w1 else positive value
   */
  sortWeeks = (w1: ProductionWeekDocument, w2: ProductionWeekDocument) => {
    const split1 = w1.week.split("-");
    const split2 = w2.week.split("-");
    const week1 = +split1[0];
    const week2 = +split2[0];
    const year1 = +split1[1];
    const year2 = +split2[1];
    if (year1 === year2) return week1 - week2;
    else return year1 - year2;
  };

  render() {
    const { history, context } = this.props;
    const { orders, userdata, manufacturers, productionPlan } = context;
    const {
      owner,
      manufacturer,
      manufacturerLocked,
      priority,
      type,
      relevantOrders,
      rescheduleOrder,
      state,
      search,
      capacity,
      showEmptyWeeks
    } = this.state;
    const selectableUsers = userdata.filter(user => user.company_id === "internal");
    return (
      <>
        <PlanOrderModal order={rescheduleOrder} context={context} onClose={this.handleClose} reschedule={true} />
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand flaticon2-search" />
              </span>
              <h3 className="kt-portlet__head-title">Toolbar</h3>
              <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
                Reset
              </button>
            </div>
            <HistoryBackButton history={history} />
          </div>
          <div className="kt-portlet__body pb-0">
            <div className="kt-form kt-form--label-right kt-margin-b-10">
              <div className="row align-items-center">
                <SearchBar onSearch={this.handleSearch} search={search} />
                <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                  <Select
                    className="select-default"
                    isClearable={true}
                    options={[
                      { value: "", label: "All Statuses" },
                      { value: "overdue", label: "Overdue orders" }
                    ]}
                    value={state}
                    onChange={(value: any) =>
                      this.handleSelectChange("state", value || { value: "", label: "All Statuses" })
                    }
                  />
                </div>
                <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                  <Select
                    className="select-default"
                    isClearable={true}
                    options={this.typeFilter.map(t => {
                      return {
                        value: t.value,
                        label: t.label
                      };
                    })}
                    value={type ? type : { value: "", label: "All Types" }}
                    onChange={(value: any) => this.handleSelectChange("type", value || "")}
                  />
                </div>
                <OwnerFilter
                  owner={owner}
                  selectableUsers={selectableUsers}
                  onFilterChange={this.handleSelectChange}
                  noLabel={true}
                />
                <PriorityFilter priority={priority} onFilterSelect={this.handleSelectChange} noLabel={true} />
                <ManufacturerFilter
                  manufacturer={manufacturer}
                  manufacturerLocked={manufacturerLocked}
                  manufacturers={manufacturers}
                  onFilterSelect={this.handleSelectChange}
                  noLabel={true}
                  additionalSizeClasses={"col-md-2"}
                />
              </div>
              <div className="row align-items-center kt-margin-t-10">
                <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                  <span className="kt-font-dark  kt-font-lg">
                    Current Calendar Week: <span className="kt-font-bolder">{dateUtils.getCW(new Date())}</span>
                  </span>
                </div>
                <div className="col-md-10 kt-margin-b-20-tablet-and-mobile">
                  <div className="float-right">
                    <label className="kt-checkbox kt-font-dark m-0 p-0 pl-4">
                      <input
                        type="checkbox"
                        checked={showEmptyWeeks}
                        onChange={() => this.setState({ showEmptyWeeks: !showEmptyWeeks })}
                      />
                      <span />
                      <div className="pl-1 kt-font-dark">Show empty weeks</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {orders.length === 0 ? (
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__body pb-0">
              <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
            </div>
          </div>
        ) : (
          <>
            {productionPlan
              .slice()
              .sort(this.sortWeeks)
              .map(week => {
                const orders = this.getOrdersForWeek(week);
                if (orders.length === 0 && !showEmptyWeeks) return;
                return (
                  <ProductionPlanWeek
                    key={week._id.toString()}
                    manufacturer={manufacturer ? manufacturer.value : ""}
                    capacity={capacity}
                    week={week}
                    orders={orders}
                    relevantOrders={relevantOrders}
                    context={context}
                    onRescheduleOrder={this.handleReschedule}
                  />
                );
              })}
          </>
        )}
      </>
    );
  }
}

export default ProductionPlan;
