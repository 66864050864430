import React, { PureComponent } from "react";
import { ManufacturersDocument } from "../../model/manufacturers.types";

interface ManufacturerWidgetProps {
  manufacturer: ManufacturersDocument;
  additionalClasses?: string;
}

class ManufacturerWidget extends PureComponent<ManufacturerWidgetProps, {}> {
  render() {
    const { manufacturer, additionalClasses } = this.props;
    const nameSplit = manufacturer.name.split(/[ -]+/);
    return (
      <div className={"kt-user-card-v2 " + (additionalClasses ? additionalClasses : "")}>
        <div className="kt-user-card-v2__pic d-none d-xl-block">
          <div className="kt-badge kt-badge--xl kt-badge--primary">
            {(nameSplit.length > 1
              ? nameSplit[nameSplit.length - 1].slice(-2)
              : manufacturer.name.slice(-2)
            ).toUpperCase()}
          </div>
        </div>
        <div className="kt-user-card-v2__details">
          <span className="kt-user-card-v2__name kt-link">
            {manufacturer.name.trim() !== "" ? manufacturer.name : "<Empty Name - Please fill in>"}
          </span>
          <span className="kt-user-card-v2__email">{`${manufacturer.city}, ${manufacturer.country}`}</span>
        </div>
      </div>
    );
  }
}

export default ManufacturerWidget;
