import _ from "lodash";
import { BSON } from "realm-web";
import React, { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "../../../../translations/i18n";
import { LanguageObject, ModalMode } from "../../../../model/common.types";
import { FileType, FileTypes } from "../../../../model/configuration/warehouseConfiguration.types";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { useWarehouseContext } from "../../../../context/warehouseContext";
import { getConfigTimelineEntry, resolveFileTypeName } from "../../../../utils/warehouseConfigUtils";
import dbService, { CONFIGURATION, UpdateAction } from "../../../../services/dbService";
import toastUtils from "../../../../utils/toastUtils";
import { capitalizeAllWords } from "../../../../utils/baseUtils";

interface CreateFileTypeModalProps {
  fileTypeEdit?: FileType;
  mode: ModalMode;
  isFileTypeUsed?: boolean;
}

interface CreateFileTypeModalState {
  type: LanguageObject;
  shortName: string;
  subtype: FileTypes;
}

const getDefaultFileType = (mode: ModalMode, fileTypeEdit?: FileType): CreateFileTypeModalState => {
  if ((mode === ModalMode.EDIT || mode === ModalMode.DELETE) && fileTypeEdit) {
    return {
      type: fileTypeEdit.type,
      shortName: fileTypeEdit.shortName,
      subtype: fileTypeEdit.subtype
    };
  } else {
    return {
      type: {
        de: ""
      },
      shortName: "",
      subtype: FileTypes.BATCH
    };
  }
};

const CreateFileTypeModal: React.FunctionComponent<CreateFileTypeModalProps> = ({
  fileTypeEdit,
  mode,
  isFileTypeUsed
}) => {
  const warehouseContext = useWarehouseContext();

  const [show, setShow] = useState(false);
  const [fileType, setFileType] = useState(getDefaultFileType(mode, fileTypeEdit));
  const [saving, setSaving] = useState(false);

  const subtypeOptions = useMemo(() => {
    return Object.entries(FileTypes).map(([key, value]) => (
      <option key={key} value={value}>
        {resolveFileTypeName(value)}
      </option>
    ));
  }, []);

  const errors = useMemo(() => {
    const errors: Array<string> = [];
    if (fileType.type.de.trim() === "") errors.push(i18n.t("warehouse:fileTypeMissing"));
    if (fileType.shortName.trim() === "") errors.push(i18n.t("warehouse:shortNameMissing"));
    return errors;
  }, [fileType]);

  const handleShow = () => {
    setShow(true);
    setFileType(getDefaultFileType(mode, fileTypeEdit));
  };

  const handleClose = useCallback(() => setShow(false), []);

  const handleChangeFileType = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newFileType = _.cloneDeep(fileType.type);
      newFileType.de = e.currentTarget.value;
      setFileType(prevState => {
        return { ...prevState, type: newFileType };
      });
    },
    [fileType.type]
  );

  const handleChangeShortName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const shortName = e.currentTarget.value;
    setFileType(prevState => {
      return { ...prevState, shortName };
    });
  }, []);

  const handleChangeSubtype = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const subtype = e.currentTarget.value as FileTypes;
    setFileType(prevState => {
      return { ...prevState, subtype };
    });
  }, []);

  const handleSave = useCallback(async () => {
    setSaving(true);
    const newFileType: FileType = getFileType();

    try {
      if (!warehouseContext.configuration) return;
      const newConfig = _.cloneDeep(warehouseContext.configuration.values);
      if (mode === ModalMode.EDIT && fileTypeEdit) {
        const changedFileTypeIndex = newConfig.fileTypes.findIndex(
          fT => fT._id.toString() === fileTypeEdit._id.toString()
        );
        if (changedFileTypeIndex === -1) {
          toast.error(i18n.t("warehouse:fileTypeEditError"));
          return;
        }
        newConfig.fileTypes[changedFileTypeIndex] = newFileType;
        const actions: Array<UpdateAction> = [
          {
            collection: CONFIGURATION,
            filter: { _id: warehouseContext.configuration._id },
            update: { "values.fileTypes.$[filter]": newFileType, lastUpdate: new Date() },
            arrayFilters: [{ "filter._id": fileTypeEdit._id }],
            push: {
              timeline: getConfigTimelineEntry(warehouseContext.configuration.values, newConfig)
            }
          }
        ];
        const res = await dbService.transaction(actions);
        await toastUtils.databaseOperationToast(
          res,
          i18n.t("warehouse:fileTypeEditSuccess"),
          i18n.t("warehouse:fileTypeEditError"),
          () => {
            setShow(false);
          }
        );
      } else if (!isFileTypeUsed && mode === ModalMode.DELETE && fileTypeEdit) {
        const changedFileTypeIndex = newConfig.fileTypes.findIndex(
          fT => fT._id.toString() === fileTypeEdit._id.toString()
        );
        if (changedFileTypeIndex === -1) {
          toast.error(i18n.t("warehouse:fileTypeDeleteError"));
          return;
        }
        newConfig.fileTypes.splice(changedFileTypeIndex, 1);
        const action: UpdateAction = {
          collection: CONFIGURATION,
          filter: { _id: warehouseContext.configuration._id },
          update: { lastUpdate: new Date() },
          pull: { "values.fileTypes": { _id: fileTypeEdit._id } },
          push: {
            timeline: getConfigTimelineEntry(warehouseContext.configuration.values, newConfig)
          }
        };
        const res = await dbService.transaction([action]);
        await toastUtils.databaseOperationToast(
          res,
          i18n.t("warehouse:fileTypeDeleteSuccess"),
          i18n.t("warehouse:fileTypeDeleteError"),
          () => {
            setShow(false);
          }
        );
      } else {
        newConfig.fileTypes.push(newFileType);
        const action: UpdateAction = {
          collection: CONFIGURATION,
          filter: { _id: warehouseContext.configuration._id },
          update: { lastUpdate: new Date() },
          push: {
            "values.fileTypes": newFileType,
            timeline: getConfigTimelineEntry(warehouseContext.configuration.values, newConfig)
          }
        };
        const res = await dbService.transaction([action]);
        await toastUtils.databaseOperationToast(
          res,
          i18n.t("warehouse:fileTypeCreateSuccess"),
          i18n.t("warehouse:fileTypeCreateError"),
          () => {
            setShow(false);
          }
        );
      }
    } catch (e) {
      toast.error(
        `${
          mode === ModalMode.DELETE
            ? i18n.t("warehouse:fileTypeDeleteError")
            : mode === ModalMode.EDIT
            ? i18n.t("warehouse:fileTypeEditError")
            : i18n.t("warehouse:fileTypeCreateError")
        }: ` + e
      );
    } finally {
      setFileType(getDefaultFileType(mode, fileTypeEdit));
      setSaving(false);
    }
  }, [warehouseContext.configuration, mode, fileTypeEdit, fileType]);

  const getFileType = useCallback(() => {
    const updatedFileType: FileType = {
      _id: fileTypeEdit ? fileTypeEdit._id : new BSON.ObjectId(),
      type: {
        de: capitalizeAllWords(fileType.type.de.trim())
      },
      shortName: capitalizeAllWords(fileType.shortName.trim()),
      subtype: fileType.subtype
    };
    return updatedFileType;
  }, [fileType, fileTypeEdit]);

  return (
    <>
      {mode === ModalMode.DELETE ? (
        <ErrorOverlayButton
          className="btn btn-secondary"
          buttonText={
            <>
              <i className="fa fa-trash" />
              {i18n.t("warehouse:delete")}
            </>
          }
          errors={isFileTypeUsed ? [i18n.t("warehouse:fileTypeUsed")] : []}
          onClick={handleShow}
        />
      ) : (
        <button className={"btn " + (mode === ModalMode.EDIT ? "btn-secondary" : "btn-primary")} onClick={handleShow}>
          <i className={mode === ModalMode.EDIT ? "fa fa-pen" : " fas fa-plus-circle"} />
          {mode === ModalMode.EDIT ? i18n.t("common:edit") : i18n.t("common:create")}
        </button>
      )}
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {mode === ModalMode.EDIT
              ? i18n.t("warehouse:fileTypeEdit")
              : mode === ModalMode.DELETE
              ? i18n.t("warehouse:fileTypeDeletion")
              : i18n.t("warehouse:fileTypeCreation")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">{i18n.t("warehouse:fileType")}</div>
            <div className="col-8">
              <input
                className="form-control"
                value={fileType.type.de}
                disabled={mode === ModalMode.DELETE}
                onChange={mode === ModalMode.DELETE ? undefined : handleChangeFileType}
              />
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">{i18n.t("warehouse:shortName")}</div>
            <div className="col-8">
              <input
                className="form-control"
                value={fileType.shortName}
                disabled={mode === ModalMode.DELETE}
                onChange={mode === ModalMode.DELETE ? undefined : handleChangeShortName}
              />
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">{i18n.t("warehouse:subtype")}</div>
            <div className="col-8">
              <select
                className="form-control"
                value={fileType.subtype}
                disabled={mode === ModalMode.DELETE}
                onChange={mode === ModalMode.DELETE ? undefined : handleChangeSubtype}
              >
                {subtypeOptions}
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            {i18n.t("common:close")}
          </button>
          {mode === ModalMode.DELETE ? (
            <ErrorOverlayButton
              errors={isFileTypeUsed ? [i18n.t("warehouse:fileTypeUsed")] : []}
              className="btn btn-danger"
              saving={saving}
              buttonText={i18n.t("warehouse:delete")}
              onClick={handleSave}
            />
          ) : (
            <ErrorOverlayButton
              buttonText={i18n.t("common:save")}
              className="btn btn-success"
              onClick={handleSave}
              errors={errors}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateFileTypeModal;
