import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BSON } from "realm-web";
import SettingsTable from "./SettingsTable";
import { DataContext } from "../../context/dataContext";
import dbService, { SOLVENTS } from "../../services/dbService";
import toastUtils from "../../utils/toastUtils";

interface SolventsProps extends RouteComponentProps<{}, {}, {}> {}

interface SolventsState {
  query: string;
}

class Solvents extends PureComponent<SolventsProps, SolventsState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: SolventsProps) {
    super(props);
    this.state = { query: "" };
  }

  /**
   * Handles creating a new solvent.
   * @param _id: ID of the solvent that is created
   * @param de: Name of the solvent in german
   * @param en: Name of the solvent in english
   */
  handleCreateSolvent = async (_id: BSON.ObjectId, de: string, en: string) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.insertDocument(SOLVENTS, { _id, name: { de, en } });
    await toastUtils.databaseOperationToast(
      !!res && res.insertedId,
      "Solvent successfully added",
      "Adding solvent failed",
      () => updateDocumentInContext(SOLVENTS, _id)
    );
  };

  /**
   * Handles editing a solvent.
   * @param _id: ID of the solvent that is edited
   * @param de: Name of the solvent in german
   * @param en: Name of the solvent in english
   */
  handleEditSolvent = async (_id: BSON.ObjectId, de: string, en: string) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.updateDocument(SOLVENTS, _id, { name: { de, en } });
    await toastUtils.databaseOperationToast(
      !!res && res.modifiedCount > 0,
      "Solvent successfully edited",
      "Editing solvent failed",
      () => updateDocumentInContext(SOLVENTS, _id)
    );
  };

  /**
   * Handles deleting a solvent.
   * @param _id: ID of the solvent that should be deleted
   */
  handleDeleteSolvent = async (_id: BSON.ObjectId) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.deleteDocument(SOLVENTS, _id);
    await toastUtils.databaseOperationToast(
      !!res && res.deletedCount > 0,
      "Solvent successfully deleted",
      "Deleting solvent failed",
      () => updateDocumentInContext(SOLVENTS, _id)
    );
  };

  render() {
    const { query } = this.state;
    const { commodities, solvents } = this.context;

    const ql = query.toLowerCase();
    const s = _.cloneDeep(
      solvents.filter(s => s.name.de.toLowerCase().includes(ql) || s.name.en.toLowerCase().includes(ql))
    );

    return (
      <SettingsTable
        setting="Solvents"
        settings={s.sort((s1, s2) => s1.name.de.localeCompare(s2.name.de))}
        title={{ table: "Solvents", modal: "Solvent" }}
        usageCheck={_id => commodities.some(c => c.solvent && c.solvent.toString() === _id.toString())}
        onDelete={this.handleDeleteSolvent}
        onSave={this.handleCreateSolvent}
        onEdit={this.handleEditSolvent}
        onSearch={query => this.setState({ query })}
      />
    );
  }
}

export default withRouter(Solvents);
