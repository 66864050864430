import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { CommoditiesDocument } from "../../../model/commodities.types";
import userService from "../../../services/userService";
import { ROLES } from "../../../utils/userdataUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import dbService, { COMMODITIES } from "../../../services/dbService";
import toastUtils from "../../../utils/toastUtils";

interface DeleteCommodityModalProps extends RouteComponentProps<{}, {}, {}> {
  commodity: CommoditiesDocument;
}

interface DeleteCommodityModalState {
  show: boolean;
  saving: boolean;
}

class DeleteCommodityModal extends PureComponent<DeleteCommodityModalProps, DeleteCommodityModalState> {
  constructor(props: DeleteCommodityModalProps) {
    super(props);
    this.state = { show: false, saving: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false, saving: false });

  handleDeleteCommodity = async () => {
    try {
      this.setState({ saving: true });
      const { history, commodity } = this.props;
      const db = dbService.getDb();
      const res = await db?.collection(COMMODITIES).deleteOne({ _id: commodity._id });
      await toastUtils.databaseOperationToast(
        !!res,
        "Commodity successfully deleted",
        "Commodity could not be deleted",
        () => {
          history.push("/commodities");
          this.setState({ show: false });
        }
      );
    } catch (e) {
      toast.error("Deletion of commodity failed: " + e.message);
      console.error(e);
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { commodity } = this.props;
    const { saving, show } = this.state;
    const canDelete = userService.hasOneOfRoles([ROLES.PROCUREMENT, ROLES.DATAMAINTENANCE]);
    return (
      <>
        <ErrorOverlayButton
          iconClass="fas fa-times"
          errors={!canDelete ? ["Only procurement and data maintenance can delete the commodity"] : []}
          buttonText={"Delete"}
          className="btn btn-danger btn-sm btn-upper"
          onClick={this.handleShow}
          saving={saving}
          disabled={!canDelete}
        />
        <Modal show={show} centered onHide={this.handleHide} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Delete {commodity.title.en}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="alert alert-secondary mt-4 font-weight-bolder"
              role="alert"
              style={{ background: "white", border: 0 }}
            >
              <div className="alert-icon">
                <i className="flaticon2-information" style={{ color: "orange" }} />
              </div>
              <div className="text-dark h5 my-auto">
                Are you sure you want to delete this commodity?
                <br />
                <b>This action cannot be undone!</b>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            <button
              className={"btn btn-secondary" + (saving ? " disabled" : "")}
              onClick={saving ? undefined : this.handleDeleteCommodity}
              disabled={saving}
            >
              Delete
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withRouter(DeleteCommodityModal);
