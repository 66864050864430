import _ from "lodash";
import { BSON } from "realm-web";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { CustomOrder } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import { ManufacturersDocument } from "../../../model/manufacturers.types";
import calculationUtils from "../../../utils/calculationUtils";
import baseUtils, { round } from "../../../utils/baseUtils";
import { T_FILLERCHANGED, T_MANUFACTURERCHANGED } from "../../../utils/timelineUtils";
import userService from "../../../services/userService";
import dbService, { UpdateAction, ORDERS } from "../../../services/dbService";
import Tooltip from "../../common/Tooltip";
import { CapsuleSupplierType } from "../../../model/capsules.types";

export enum ManufacturerType {
  MANUFACTURER = "manufacturer",
  FILLER = "filler"
}

interface ChangeManufacturerModalProps {
  order: CustomOrder;
  type: ManufacturerType;
}

const ChangeManufacturerModal: React.FunctionComponent<ChangeManufacturerModalProps> = ({ order, type }) => {
  const context = useContext(DataContext);
  const { info, units } = order.calculations[0];
  const document = useMemo(
    () => (type === ManufacturerType.MANUFACTURER ? order.settings.manufacturer : order.settings.filler || null),
    [order.settings, type]
  );

  const [newDocument, setNewDocument] = useState<ManufacturersDocument | null>(document || null);
  const [reconstructedPrice, setReconstructedPrice] = useState<{ generalPrice: number; total: number } | undefined>(
    undefined
  );
  const [calculationInfo, setCalculationInfo] = useState(_.cloneDeep(info));
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const generalPrice = info.customCalculation
      ? calculationUtils.calculateCustomUnitPriceFromOrder(order)
      : getGeneralUnitPrice().generalUnitPrice;
    const otherPrice = calculationUtils.recalculateUnitPrice(order, context);
    // Reset states on order change
    setNewDocument(null);
    setCalculationInfo(_.cloneDeep(info));
    setReconstructedPrice({ generalPrice, total: generalPrice + otherPrice });
  }, [order]);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);
  const handleReset = () => {
    setNewDocument(document);
    setCalculationInfo(info);
  };
  const handleChangeManufacturer = (e: any) => {
    if (!reconstructedPrice) return;
    if (document && e.manufacturer._id.toString() === document._id.toString()) {
      handleReset();
      return;
    }
    setNewDocument(e.manufacturer);
    // No changes needed for custom calculation
    if (info.customCalculation) return;
    const { generalUnitPrice, prices } = getGeneralUnitPrice(e.manufacturer._id.toString());
    const unitPriceNaked = reconstructedPrice.total - reconstructedPrice.generalPrice + generalUnitPrice;
    const percentMargin = (info.unitprice / unitPriceNaked - 1) * 100;
    const unitMargin = info.unitprice - unitPriceNaked;
    const totalMargin = info.totalprice - unitPriceNaked * units;

    const calculationInfo = {
      ...info,
      unitpricenaked: unitPriceNaked,
      unitmargin: unitMargin,
      totalmargin: totalMargin,
      percentmargin: percentMargin
    };
    if (calculationInfo.standardCalculation)
      calculationInfo.standardCalculation = { ...calculationInfo.standardCalculation, ...prices };
    setCalculationInfo(calculationInfo);
  };

  const handleSave = async () => {
    if (!reconstructedPrice || !newDocument) return;
    setSaving(true);
    try {
      const userId = new BSON.ObjectId(userService.getUserData()._id);
      const timelineEntry = {
        id: new BSON.ObjectId(),
        type: type === ManufacturerType.MANUFACTURER ? T_MANUFACTURERCHANGED : T_FILLERCHANGED,
        date: new Date(),
        person: userId,
        prices: { oldPrice: reconstructedPrice.total, newPrice: calculationInfo.unitpricenaked },
        manufacturer: {
          old: type === ManufacturerType.FILLER && !document ? order.settings.manufacturer : document,
          new: newDocument
        }
      };
      let action: UpdateAction;
      if (type === ManufacturerType.MANUFACTURER) {
        action = {
          collection: ORDERS,
          filter: { _id: order._id },
          update: {
            "settings.manufacturer": newDocument._id,
            "calculations.0.info": calculationInfo,
            "calculations.0.margin": calculationInfo.percentmargin
          },
          push: { timeline: { $each: [timelineEntry] } }
        };
        if (order.settings.filler && order.settings.filler._id.toString() === newDocument._id.toString())
          action.unset = { "settings.filler": "" };
      } else {
        action = {
          collection: ORDERS,
          filter: { _id: order._id },
          update: {
            "calculations.0.info": calculationInfo,
            "calculations.0.margin": calculationInfo.percentmargin
          },
          push: { timeline: { $each: [timelineEntry] } }
        };
        if (order.settings.manufacturer._id.toString() === newDocument._id.toString())
          action.unset = { "settings.filler": "" };
        else {
          // @ts-ignore
          action.update["settings.filler"] = newDocument._id.toString();
        }
      }

      const actions: Array<UpdateAction> = [action];
      const res = await dbService.transaction(actions);
      if (res) {
        toast.success(
          <b>
            <i className="fa fa-check mr-2" />
            {_.upperFirst(type)} updated successfully
          </b>
        );
        context.updateDocumentInContext(ORDERS, order._id);
        handleHide();
      } else {
        toast.error(
          <b>
            <i className="fa fa-exclamation mr-2" />
            Changing {type} failed
          </b>
        );
      }
    } finally {
      setSaving(false);
    }
  };

  const getGeneralUnitPrice = (manufacturerId?: string) => {
    const { generalUnitPrice, prices } = calculationUtils.recalculateGeneralUnitPrice(
      order,
      context,
      manufacturerId,
      !!manufacturerId,
      type === ManufacturerType.FILLER
    );
    return { generalUnitPrice: generalUnitPrice + (info.marginBuffer || 0), prices };
  };

  // Prices differ
  const difference = useMemo(
    () =>
      Math.round((info.unitpricenaked || 0) * 10000) / 10000 -
      Math.round((reconstructedPrice?.total || 0) * 10000) / 10000,
    [info.unitpricenaked, reconstructedPrice?.total]
  );

  const disabled = useMemo(
    () =>
      !reconstructedPrice ||
      (difference !== 0 && !userService.isAdmin()) ||
      (!!newDocument && newDocument._id.toString() === document?._id.toString()),
    [reconstructedPrice, difference, newDocument, document]
  );

  const filteredManufacturers = useMemo(
    () =>
      type === ManufacturerType.MANUFACTURER
        ? calculationUtils.getFilteredManufacturersForOrder(order, context)
        : context.manufacturers.filter(man => Object.keys(man).some(key => key.startsWith(order.settings.type))),
    [order, context.manufacturers, type]
  );

  const canReplace = useMemo(
    () => type === ManufacturerType.FILLER || !order.calculations[0].prices.some(p => p.ordered && !p.delivered),
    [type, order.calculations[0]]
  );

  const capsuleIsFromManufacturer = useMemo(
    () =>
      type === ManufacturerType.MANUFACTURER &&
      order.calculations[0].info.standardCalculation?.capsule?.supplierType === CapsuleSupplierType.MANUFACTURER,
    [type, order.calculations[0].info.standardCalculation?.capsule?.supplierType]
  );

  return (
    <>
      <Tooltip
        show={canReplace && !capsuleIsFromManufacturer ? false : undefined}
        tooltipText={
          capsuleIsFromManufacturer
            ? "Can't change manufacturer as the capsule is provided by the manufacturer. Please change via configurator until it is implemented here."
            : "Can't change manufacturer since at least one commodity is ordered and not delivered."
        }
      >
        <button
          className={
            "btn btn-sm btn-secondary py-0 px-2 " + ((!canReplace || capsuleIsFromManufacturer) && "not-allowed-cursor")
          }
          onClick={canReplace && !capsuleIsFromManufacturer ? handleShow : undefined}
          style={{ opacity: canReplace && !capsuleIsFromManufacturer ? 1 : 0.7 }}
        >
          {type === ManufacturerType.FILLER && !order.settings.filler ? "Set" : "Edit"}
        </button>
      </Tooltip>
      <Modal show={show} onHide={handleHide} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Change {_.upperFirst(type)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-portlet__body">
            <div className="kt-widget kt-widget--user-profile-2">
              <div className="kt-widget__body">
                <div className="kt-widget__content">
                  <div className="kt-widget__stats " style={{ paddingBottom: "0.3rem" }}>
                    <div className="kt-widget__icon">
                      <i className="flaticon-piggy-bank" />
                    </div>
                    <div className="kt-widget__details">
                      <span className="kt-widget__title">Unit Price Naked</span>
                      <span className="kt-widget__value">{baseUtils.formatEuro(calculationInfo.unitpricenaked)}</span>
                    </div>
                  </div>
                  <div className="kt-widget__stats " style={{ paddingBottom: "0.3rem" }}>
                    <div className="kt-widget__icon">
                      <i className="flaticon-pie-chart" />
                    </div>
                    <div className="kt-widget__details">
                      <span className="kt-widget__title">Total Margin</span>
                      <span className="kt-widget__value">{calculationInfo.percentmargin.toFixed(2)}%</span>
                    </div>
                  </div>
                  <div className="kt-widget__stats " style={{ paddingBottom: "0.3rem" }}>
                    <div className="kt-widget__icon">
                      <i className="flaticon-pie-chart" />
                    </div>
                    <div className="kt-widget__details">
                      <span className="kt-widget__title">Unit Margin</span>
                      <span className="kt-widget__value">{baseUtils.formatEuro(calculationInfo.unitmargin)}</span>
                    </div>
                  </div>
                  <div className="kt-widget__stats" style={{ paddingBottom: "0.3rem" }}>
                    <div className="kt-widget__icon">
                      <i className="flaticon-pie-chart" />
                    </div>
                    <div className="kt-widget__details">
                      <span className="kt-widget__title">Total Margin</span>
                      <span className="kt-widget__value">{baseUtils.formatEuro(calculationInfo.totalmargin)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {difference !== 0 && (
              <div className="mb-3">
                <span className="kt-badge kt-badge--inline py-1 kt-badge--danger text-left font-weight-bold w-100">
                  Cost per unit could not be reconstructed correctly!
                  <br />
                  Cost per unit in order: {round(order.calculations[0].info.unitpricenaked)}€, reconstructed cost per
                  unit: {round(reconstructedPrice?.total || 0)}€
                  <br />
                  Unfortunately this prevents changing the {type}
                </span>
              </div>
            )}
            {info.customCalculation && (
              <div className="mb-3">
                <span className="kt-badge kt-badge--inline py-1 kt-badge--warning text-left font-weight-bold w-100">
                  The order contains a custom calculation.
                  <br />
                  The custom calculation will not be altered if the {type} is changed.
                </span>
              </div>
            )}
            <div className="row">
              <div className="col-xl-12">
                <div className="kt-section kt-section--first mb-3">
                  <div className="kt-section__body">
                    <br />
                    <div className="mx-auto" style={{ maxWidth: "300px" }}>
                      <label className="kt-font-bold kt-font-dark mx-auto">New {_.upperFirst(type)}</label>
                      <Select
                        className="select-default"
                        isDisabled={difference !== 0}
                        onChange={(e: any) => handleChangeManufacturer(e)}
                        value={
                          newDocument
                            ? {
                                value: newDocument._id.toString(),
                                label: newDocument.name || ""
                              }
                            : { value: "", label: "Not set" }
                        }
                        options={
                          filteredManufacturers.map(manufacturer => {
                            return {
                              value: manufacturer._id,
                              label: manufacturer.name,
                              manufacturer
                            };
                          }) as Array<{ value: string; label: string; manufacturer?: ManufacturersDocument }>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleHide} disabled={saving}>
            Close
          </button>
          <button className="btn btn-secondary" onClick={handleReset} disabled={saving}>
            Reset
          </button>
          <button
            className={"btn btn-success " + (saving || disabled ? "disabled" : "")}
            onClick={saving || disabled ? undefined : handleSave}
            disabled={saving || disabled}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangeManufacturerModal;
