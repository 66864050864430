import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import { DataContext } from "../../context/dataContext";
import { CommoditiesDocument } from "../../model/commodities.types";
import fileUtils from "../../utils/fileUtils";
import { PackagingsDocument } from "../../model/packagings.types";

interface UploadFileModalProps {
  material: CommoditiesDocument | PackagingsDocument;
  type: string;
  addUpload: (id: BSON.ObjectId, path: string, type: string, title?: string) => void;
  onClose: () => void;
  hideSupplier?: boolean;
}

interface UploadFileModalState {
  error: boolean;
  file?: any;
  success: boolean;
  supplierId: string;
  title: string;
}

class UploadMaterialFileModal extends PureComponent<UploadFileModalProps, UploadFileModalState> {
  private readonly selectFileRef: React.RefObject<any>;
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: UploadFileModalProps) {
    super(props);
    this.selectFileRef = React.createRef();
    this.state = {
      error: false,
      success: false,
      title: "",
      supplierId: props.material.suppliers[0]._id.toString()
    };
  }

  onChangeSupplier = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ supplierId: e.target.value });
  };

  /**
   * Uploads the selected file to the mediaHub and updates the commodity or packaging to show it.
   */
  handleUpload = () => {
    const { addUpload, material, type } = this.props;
    const { file, success, supplierId, title } = this.state;

    if (!file || !supplierId) return;

    const xhr = new XMLHttpRequest();
    let isodate = new Date(Date.now()).toISOString();
    isodate = isodate.split(":").join("-");
    isodate = isodate.split(".").join("-");
    const fileType = fileUtils.getFileExtension(file.name);

    const fileName =
      (type || "file") + "_" + material._id.toString() + "_" + title.trim() + "_" + isodate + "." + fileType;

    xhr.open("POST", "https://mediahub.private-label-factory.com/upload/" + fileName, true);

    xhr.setRequestHeader("Content-Type", "application/octate-stream");
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          this.setState({ success: true });
          addUpload(
            new BSON.ObjectId(supplierId),
            "https://mediahub.private-label-factory.com/files/" + xhr.responseText,
            type,
            title
          );
        } else {
          this.setState({ error: true });
        }
      }
    };
    xhr.send(file);
    if (success) this.handleClose();
  };

  handleClose = () => {
    const { onClose } = this.props;
    this.setState({ file: undefined, success: false, error: false });
    onClose();
  };

  render() {
    const { material, hideSupplier, type } = this.props;
    const { error, file, success, supplierId, title } = this.state;

    const supplierIds = material.suppliers.map(s => s._id.toString());
    const suppliersAvailable = this.context.suppliers.filter(s => supplierIds.includes(s._id.toString()));

    let fileName;
    if (file) {
      fileName = file.name;
      const fileSize = file.size < 1048576 ? file.size / 1024 + " KB" : file.size / 1024 / 1024 + " MB";
      if (fileName.length > 25) {
        fileName = fileName.slice(0, 23) + "..." + " (" + fileSize + ")";
      }
    }

    return (
      <Modal show={true} onHide={this.handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="kt-font-brand fa fa-file-alt mr-2" />
            Upload {fileUtils.getDisplayableText(type)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <h4 className="font-weight-bolder text-dark">File title</h4>
              <input
                type="text"
                className="form-control"
                placeholder="Description"
                // Dont allow special characters but _
                onChange={e => this.setState({ title: e.target.value.replaceAll(/[\W\s]/g, "") })}
                value={title}
              />
            </div>
            {!hideSupplier && (
              <div className="col-12">
                <h4 className="mt-4 font-weight-bolder text-dark">Supplier</h4>
                <select
                  className="form-control"
                  id="selectUploadSupplier"
                  onChange={this.onChangeSupplier}
                  value={supplierId}
                >
                  {suppliersAvailable.map(supplier => (
                    <option key={supplier._id.toString()} value={supplier._id.toString()} id={supplier._id.toString()}>
                      {supplier.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="col-3 my-4">
              <button className="btn btn-success" type="button" onClick={() => this.selectFileRef.current.click()}>
                Select File
              </button>
              <input
                type="file"
                ref={this.selectFileRef}
                accept="*"
                style={{ display: "none" }}
                onChange={e =>
                  this.setState({
                    file: e.target.files![0],
                    success: false,
                    error: false
                  })
                }
              />
            </div>
            <div className="col-9 my-4">
              <div className="text-muted h-100 d-flex align-items-center">
                <div>{!file ? "No file selected" : fileName}</div>
                {file && !success && !error && (
                  <div className="ml-2">
                    <span style={{ color: "rgb(191, 197, 212)" }}>ready</span>
                  </div>
                )}
                {file && success && (
                  <div className="ml-2">
                    <span className="text-success">
                      successfully uploaded
                      <i className={"fa fa-check"} />
                    </span>
                  </div>
                )}
                {file && error && (
                  <div className="ml-2">
                    <span className="text-danger">
                      an error occurred
                      <i className={"fa fa-exclamation-circle"} />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <button
              className={file ? "btn btn-success" : "btn btn-success disabled"}
              disabled={!file}
              onClick={() => this.handleUpload()}
            >
              Upload File
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    );
  }
}

export default UploadMaterialFileModal;
