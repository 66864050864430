import { BSON } from "realm-web";

export enum ConfigurationKeys {
  WAREHOUSE = "warehouseConfiguration",
  NOTIFICATION = "notificationConfiguration"
}

export interface BaseConfiguration {
  _id: BSON.ObjectId;
  key: ConfigurationKeys;
  values: object;
  lastUpdate: Date;
  timeline: Array<object>;
}
