import React, { PureComponent } from "react";
import { ManufacturersDocument } from "../../model/manufacturers.types";

interface ManufacturerCapabilitiesProps {
  canChange: boolean;
  changeCapabilityValue: (
    typeProduct: "liquids" | "powder" | "tablets" | "capsules",
    typePrice: "blending" | "bottling" | "encapsulation",
    typeValue: "amount" | "price",
    key: number,
    value: string
  ) => void;
  manufacturer: ManufacturersDocument;
  onClickAddCapabilityPrice: (
    typeProduct: "liquids" | "powder" | "tablets" | "capsules",
    typePrice: "blending" | "bottling" | "encapsulation"
  ) => void;
  onClickReset: () => void;
  onClickSave: (typeData: "capabilities" | "employees") => void;
  onClickDeleteCapabilityPrice: (
    typeProduct: "liquids" | "powder" | "tablets" | "capsules",
    typePrice: "blending" | "bottling" | "encapsulation",
    key: number
  ) => void;
}

class ManufacturerCapabilities extends PureComponent<ManufacturerCapabilitiesProps, {}> {
  render() {
    const {
      canChange,
      changeCapabilityValue,
      manufacturer,
      onClickAddCapabilityPrice,
      onClickReset,
      onClickSave,
      onClickDeleteCapabilityPrice
    } = this.props;
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand flaticon2-pie-chart-3" />
            </span>
            <h3 className="kt-portlet__head-title">Capabilities</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="row mx-4 mt-4">
            <div className="col-1" />
            <div className="col-4 h6 text-dark">Blend-/Blister-/Tableting</div>
            <div className="col-4 h6 text-dark">Bottling</div>
            <div className="col-3 h6 text-dark">Encapsulation</div>
          </div>
          <div className="row mx-4 mt-4">
            <div className="col-1" />
            <div className="col-4 text-dark">
              <div className="row">
                <div className="col-5">Amount</div>
                <div className="col-7">Price</div>
              </div>
            </div>
            <div className="col-4 text-dark">
              <div className="row">
                <div className="col-5">Amount</div>
                <div className="col-7">Price</div>
              </div>
            </div>
            <div className="col-3 text-dark">
              <div className="row">
                <div className="col-4">Size</div>
                <div className="col-8">Price</div>
              </div>
            </div>
          </div>
          {manufacturer.liquids && (
            <div className="row m-4">
              <div className="col-1 h6 text-dark">Liquids</div>
              <div className="col-4">
                {manufacturer.liquids.blending.map((b, key) => {
                  return (
                    <div key={key} className="row">
                      <div className="col-5">
                        <input
                          className={"form-control mr-1 mb-1" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={b.amount}
                          onChange={e => changeCapabilityValue("liquids", "blending", "amount", key, e.target.value)}
                        />
                      </div>
                      <div className="col-5">
                        <input
                          className={"form-control" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={b.price}
                          onChange={e => changeCapabilityValue("liquids", "blending", "price", key, e.target.value)}
                        />
                      </div>
                      <div className="col-2 pl-0">
                        {canChange && (
                          <button
                            className={
                              "btn btn-secondary mb-1" + (manufacturer.liquids!.blending.length > 1 ? "" : " disabled")
                            }
                            disabled={manufacturer.liquids!.blending.length <= 1}
                            onClick={() => onClickDeleteCapabilityPrice("liquids", "blending", key)}
                          >
                            <i className="fa fa-trash pr-0" />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
                {canChange && (
                  <div className="btn btn-secondary" onClick={() => onClickAddCapabilityPrice("liquids", "blending")}>
                    <i className="fa fa-plus pr-0" />
                  </div>
                )}
              </div>
              <div className="col-4">
                {manufacturer.liquids.bottling.map((b, key) => {
                  return (
                    <div key={key} className="row">
                      <div className="col-5">
                        <input
                          className={"form-control mr-1 mb-1" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={b.amount}
                          onChange={e => changeCapabilityValue("liquids", "bottling", "amount", key, e.target.value)}
                        />
                      </div>
                      <div className="col-5">
                        <input
                          className={"form-control" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={b.price}
                          onChange={e => changeCapabilityValue("liquids", "bottling", "price", key, e.target.value)}
                        />
                      </div>
                      <div className="col-2 pl-0">
                        {canChange && (
                          <button
                            className={
                              "btn btn-secondary mb-1 ml-1" +
                              (manufacturer.liquids!.bottling.length > 1 ? "" : " disabled")
                            }
                            disabled={manufacturer.liquids!.bottling.length <= 1}
                            onClick={() => onClickDeleteCapabilityPrice("liquids", "bottling", key)}
                          >
                            <i className="fa fa-trash pr-0" />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
                {canChange && (
                  <div className="btn btn-secondary" onClick={() => onClickAddCapabilityPrice("liquids", "bottling")}>
                    <i className="fa fa-plus pr-0" />
                  </div>
                )}
              </div>
            </div>
          )}
          {manufacturer.powder && (
            <div className="row m-4">
              <div className="col-1 h6 text-dark">Powder</div>
              <div className="col-4">
                {manufacturer.powder.blending.map((b, key) => {
                  return (
                    <div key={key} className="row">
                      <div className="col-5">
                        <input
                          className={"form-control mr-1 mb-1" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={b.amount}
                          onChange={e => changeCapabilityValue("powder", "blending", "amount", key, e.target.value)}
                        />
                      </div>
                      <div className="col-5">
                        <input
                          className={"form-control" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={b.price}
                          onChange={e => changeCapabilityValue("powder", "blending", "price", key, e.target.value)}
                        />
                      </div>
                      <div className="col-2 pl-0">
                        {canChange && (
                          <button
                            className={
                              "btn btn-secondary mb-1 ml-1" +
                              (manufacturer.powder!.blending.length > 1 ? "" : " disabled")
                            }
                            disabled={manufacturer.powder!.blending.length <= 1}
                            onClick={() => onClickDeleteCapabilityPrice("powder", "blending", key)}
                          >
                            <i className="fa fa-trash pr-0" />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
                {canChange && (
                  <div className="btn btn-secondary" onClick={() => onClickAddCapabilityPrice("powder", "blending")}>
                    <i className="fa fa-plus pr-0" />
                  </div>
                )}
              </div>
              <div className="col-4">
                {manufacturer.powder.bottling.map((b, key) => {
                  return (
                    <div key={key} className="row">
                      <div className="col-5">
                        <input
                          className={"form-control mr-1 mb-1" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={b.amount}
                          onChange={e => changeCapabilityValue("powder", "bottling", "amount", key, e.target.value)}
                        />
                      </div>
                      <div className="col-5">
                        <input
                          className={"form-control" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={b.price}
                          onChange={e => changeCapabilityValue("powder", "bottling", "price", key, e.target.value)}
                        />
                      </div>
                      <div className="col-2 pl-0">
                        {canChange && (
                          <button
                            className={
                              "btn btn-secondary mb-1 ml-1" +
                              (manufacturer.powder!.bottling.length > 1 ? "" : " disabled")
                            }
                            disabled={manufacturer.powder!.bottling.length <= 1}
                            onClick={() => onClickDeleteCapabilityPrice("powder", "bottling", key)}
                          >
                            <i className="fa fa-trash pr-0" />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
                {canChange && (
                  <div className="btn btn-secondary" onClick={() => onClickAddCapabilityPrice("powder", "bottling")}>
                    <i className="fa fa-plus pr-0" />
                  </div>
                )}
              </div>
            </div>
          )}
          {manufacturer.tablets && (
            <div className="row m-4">
              <div className="col-1 h6 text-dark">Tablets</div>
              <div className="col-4">
                {manufacturer.tablets.tableting.map((t, key) => {
                  return (
                    <div key={key} className="row">
                      <div className="col-5">
                        <input
                          className={"form-control mr-1 mb-1" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={t.amount}
                          onChange={e => changeCapabilityValue("tablets", "blending", "amount", key, e.target.value)}
                        />
                      </div>
                      <div className="col-5">
                        <input
                          className={"form-control" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={t.price}
                          onChange={e => changeCapabilityValue("tablets", "blending", "price", key, e.target.value)}
                        />
                      </div>
                      <div className="col-2 pl-0">
                        {canChange && (
                          <button
                            className={
                              "btn btn-secondary mb-1 ml-1" +
                              (manufacturer.tablets!.tableting.length > 1 ? "" : " disabled")
                            }
                            disabled={manufacturer.tablets!.tableting.length <= 1}
                            onClick={() => onClickDeleteCapabilityPrice("tablets", "blending", key)}
                          >
                            <i className="fa fa-trash pr-0" />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
                {canChange && (
                  <div className="btn btn-secondary" onClick={() => onClickAddCapabilityPrice("tablets", "blending")}>
                    <i className="fa fa-plus pr-0" />
                  </div>
                )}
              </div>
              <div className="col-4">
                {manufacturer.tablets.bottling.map((b, key) => {
                  return (
                    <div key={key} className="row">
                      <div className="col-5">
                        <input
                          className={"form-control mr-1 mb-1" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={b.amount}
                          onChange={e => changeCapabilityValue("tablets", "bottling", "amount", key, e.target.value)}
                        />
                      </div>
                      <div className="col-5">
                        <input
                          className={"form-control" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={b.price}
                          onChange={e => changeCapabilityValue("tablets", "bottling", "price", key, e.target.value)}
                        />
                      </div>
                      <div className="col-2 pl-0">
                        {canChange && (
                          <button
                            className={
                              "btn btn-secondary mb-1 ml-1" +
                              (manufacturer.tablets!.bottling.length > 1 ? "" : " disabled")
                            }
                            disabled={manufacturer.tablets!.bottling.length <= 1}
                            onClick={() => onClickDeleteCapabilityPrice("tablets", "bottling", key)}
                          >
                            <i className="fa fa-trash pr-0" />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
                {canChange && (
                  <div className="btn btn-secondary" onClick={() => onClickAddCapabilityPrice("tablets", "bottling")}>
                    <i className="fa fa-plus pr-0" />
                  </div>
                )}
              </div>
            </div>
          )}
          {manufacturer.capsules && (
            <div className="row m-4">
              <div className="col-1 h6 text-dark">Capsules</div>
              <div className="col-4">
                {manufacturer.capsules.blistering.map((b, key) => {
                  return (
                    <div key={key} className="row">
                      <div className="col-5">
                        <input
                          className={"form-control mr-1 mb-1" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={b.amount}
                          onChange={e => changeCapabilityValue("capsules", "blending", "amount", key, e.target.value)}
                        />
                      </div>
                      <div className="col-5">
                        <input
                          className={"form-control" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={b.price}
                          onChange={e => changeCapabilityValue("capsules", "blending", "price", key, e.target.value)}
                        />
                      </div>
                      <div className="col-2 pl-0">
                        {canChange && (
                          <button
                            className={
                              "btn btn-secondary mb-1 ml-1" +
                              (manufacturer.capsules!.blistering.length > 1 ? "" : " disabled")
                            }
                            disabled={manufacturer.capsules!.blistering.length <= 1}
                            onClick={() => onClickDeleteCapabilityPrice("tablets", "blending", key)}
                          >
                            <i className="fa fa-trash pr-0" />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
                {canChange && (
                  <div className="btn btn-secondary" onClick={() => onClickAddCapabilityPrice("capsules", "blending")}>
                    <i className="fa fa-plus pr-0" />
                  </div>
                )}
              </div>
              <div className="col-4">
                {manufacturer.capsules.bottling.map((b, key) => {
                  return (
                    <div key={key} className="row">
                      <div className="col-5">
                        <input
                          className={"form-control mr-1 mb-1" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={b.amount}
                          onChange={e => changeCapabilityValue("capsules", "bottling", "amount", key, e.target.value)}
                        />
                      </div>
                      <div className="col-5">
                        <input
                          className={"form-control" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={b.price}
                          onChange={e => changeCapabilityValue("capsules", "bottling", "price", key, e.target.value)}
                        />
                      </div>
                      <div className="col-2 pl-0">
                        {canChange && (
                          <button
                            className={
                              "btn btn-secondary mb-1 ml-1" +
                              (manufacturer.capsules!.bottling.length > 1 ? "" : " disabled")
                            }
                            disabled={manufacturer.capsules!.bottling.length <= 1}
                            onClick={() => onClickDeleteCapabilityPrice("capsules", "bottling", key)}
                          >
                            <i className="fa fa-trash pr-0" />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
                {canChange && (
                  <div className="btn btn-secondary" onClick={() => onClickAddCapabilityPrice("capsules", "bottling")}>
                    <i className="fa fa-plus pr-0" />
                  </div>
                )}
              </div>
              <div className="col-3">
                {manufacturer.capsules.encapsulation.map((e, key) => {
                  return (
                    <div key={key} className="row">
                      <div className="col-4">
                        <input
                          className={"form-control mb-1" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="text"
                          value={e.size}
                          onChange={e =>
                            changeCapabilityValue("capsules", "encapsulation", "amount", key, e.target.value)
                          }
                        />
                      </div>
                      <div className="col-6">
                        <input
                          className={"form-control" + (canChange ? "" : " disabled")}
                          disabled={!canChange}
                          type="number"
                          value={e.cost}
                          onChange={e =>
                            changeCapabilityValue("capsules", "encapsulation", "price", key, e.target.value)
                          }
                        />
                      </div>
                      <div className="col-2 pl-0">
                        {canChange && (
                          <button
                            className={
                              "btn btn-secondary mb-1" +
                              (manufacturer.capsules!.encapsulation.length > 1 ? "" : " disabled")
                            }
                            disabled={manufacturer.capsules!.encapsulation.length <= 1}
                            onClick={() => onClickDeleteCapabilityPrice("capsules", "encapsulation", key)}
                          >
                            <i className="fa fa-trash pr-0" />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
                {canChange && (
                  <div
                    className="btn btn-secondary"
                    onClick={() => onClickAddCapabilityPrice("capsules", "encapsulation")}
                  >
                    <i className="fa fa-plus pr-0" />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {canChange && (
          <div className="kt-portlet__foot">
            <div className="float-right">
              <div className="btn btn-secondary mr-2" onClick={onClickReset}>
                Reset
              </div>
              <div className="btn btn-success" onClick={() => onClickSave("capabilities")}>
                Save
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ManufacturerCapabilities;
