import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { BSON } from "realm-web";
import PersonWidget from "../common/PersonWidget";
import { SampleRecipesDocument } from "../../model/sampleRecipes.types";
import { UserdataDocument } from "../../model/userdata.types";

interface SampleRecipeItemProps {
  recipeItem: SampleRecipesDocument;
  owner: UserdataDocument;
  onShow: (_id: BSON.ObjectId) => void;
  onDelete: (_id: BSON.ObjectId) => void;
}
interface SampleRecipeItemState {}

class SampleRecipeItem extends PureComponent<SampleRecipeItemProps, SampleRecipeItemState> {
  render() {
    const { recipeItem, owner, onDelete, onShow } = this.props;
    return (
      <tr>
        <td className="align-middle pointer" onClick={() => onShow(recipeItem._id)}>
          <span style={{ width: 400 }}>
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__pic">
                <div className="kt-badge kt-badge--xl kt-badge--primary">
                  <img
                    src={process.env.PUBLIC_URL + "/media/icons/" + recipeItem.settings.type + ".png"}
                    className="p-1"
                  />
                </div>
              </div>
              <div className="kt-user-card-v2__details">
                <Link to="/sample-recipes" className="kt-user-card-v2__name kt-link">
                  {recipeItem.title.en}
                </Link>
                <span className="kt-user-card-v2__email">{recipeItem.subtitle.en}</span>
              </div>
            </div>
          </span>
        </td>
        <td className="align-middle pointer" onClick={() => onShow(recipeItem._id)}>
          <span style={{ width: 300 }}>
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <Link to="/sample-recipes" className="kt-user-card-v2__name kt-link">
                  {recipeItem.title.de}
                </Link>
                <span className="kt-user-card-v2__email">{recipeItem.subtitle.de}</span>
              </div>
            </div>
          </span>
        </td>
        <td className="align-middle pointer" onClick={() => onShow(recipeItem._id)}>
          {recipeItem.date.toLocaleDateString()}
        </td>
        <td className="align-middle pointer" onClick={() => onShow(recipeItem._id)}>
          {recipeItem.recipe.length + " commodities"}
        </td>
        <td className="align-middle pointer" onClick={() => onShow(recipeItem._id)}>
          {recipeItem.used + " usages"}
        </td>
        <td className="align-middle pointer" onClick={() => onShow(recipeItem._id)}>
          <PersonWidget person={owner} />
        </td>
        <td className="align-middle text-right">
          <span style={{ width: 100 }}>
            <button className="btn btn-danger btn-sm" type="button" onClick={() => onDelete(recipeItem._id)}>
              <i className="fa fa-trash" />
              Delete
            </button>
          </span>
        </td>
      </tr>
    );
  }
}

export default SampleRecipeItem;
