import React from "react";
import { toast } from "react-toastify";

/**
 * Show a toast depending on the success value and execute a function on success or error.
 * @param success Indicating whether the success or the error case should be executed
 * @param successText Toast text for success case
 * @param errorText Toast text for error case
 * @param onSuccess Function that is executed in success case
 * @param onError Function that is executed in error case
 * @returns {Promise<void>}
 */
async function databaseOperationToast(
  success: boolean,
  successText: string,
  errorText: string,
  onSuccess?: () => void | Promise<void> | Promise<boolean>,
  onError?: () => void | Promise<void>
): Promise<void> {
  if (success) {
    toast.success(
      <b>
        <i className="fa fa-check mx-2" />
        {successText}
      </b>
    );
    onSuccess && (await onSuccess());
  } else {
    toast.error(
      <b>
        <i className="fa fa-exclamation mx-2" />
        {errorText}
      </b>
    );
    onError && (await onError());
  }
}

export default { databaseOperationToast };
