import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BSON } from "realm-web";
import SettingsTable from "./SettingsTable";
import { DataContext } from "../../context/dataContext";
import dbService, { ACTIVESUBSTANCES } from "../../services/dbService";
import toastUtils from "../../utils/toastUtils";
import baseUtils from "../../utils/baseUtils";

interface ActiveSubstancesProps extends RouteComponentProps<{}, {}, {}> {}
interface ActiveSubstancesState {
  query: string;
}

class ActiveSubstances extends PureComponent<ActiveSubstancesProps, ActiveSubstancesState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: ActiveSubstancesProps) {
    super(props);
    this.state = { query: "" };
  }

  /**
   * Handle the creating a new active substance.
   * @param _id: ID of the active substance
   * @param de: German name of the active substance
   * @param en: English name of the active substance
   * @param nrv: NRV of the active substance
   */
  handleCreateActiveSubstance = async (_id: BSON.ObjectId, de: string, en: string, nrv?: string) => {
    const { updateDocumentInContext } = this.context;
    const nrvNum = nrv ? parseFloat(nrv) : null;
    const res = await dbService.insertDocument(ACTIVESUBSTANCES, { _id, name: { de, en }, nrv: nrvNum });
    await toastUtils.databaseOperationToast(
      res && res.insertedId,
      "Active substance successfully added",
      "Error adding active substance",
      () => updateDocumentInContext(ACTIVESUBSTANCES, _id)
    );
  };

  /**
   * Handle the editing an already existing active substance.
   * @param _id: ID of the active substance
   * @param de: German name of the active substance
   * @param en: English name of the active substance
   * @param nrv: NRV of the active substance
   */
  handleEditActiveSubstance = async (_id: BSON.ObjectId, de: string, en: string, nrv?: string) => {
    const { updateDocumentInContext } = this.context;
    const nrvNum = nrv ? parseFloat(nrv) : null;
    const res = await dbService.updateDocument(ACTIVESUBSTANCES, _id, { name: { de, en }, nrv: nrvNum });
    await toastUtils.databaseOperationToast(
      !!res && res.modifiedCount > 0,
      "Active substance successfully edited",
      "Error editing active substance",
      () => updateDocumentInContext(ACTIVESUBSTANCES, _id)
    );
  };

  /**
   * Handle deleting an active substance.
   * @param _id: ID of the active substance
   */
  handleDeleteActiveSubstance = async (_id: BSON.ObjectId) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.deleteDocument(ACTIVESUBSTANCES, _id);
    await toastUtils.databaseOperationToast(
      !!res && res.deletedCount > 0,
      "Active substance successfully deleted",
      "Error deleting active substance",
      () => updateDocumentInContext(ACTIVESUBSTANCES, _id)
    );
  };

  render() {
    const { query } = this.state;
    const { commodities, activesubstances } = this.context;

    const ql = query.trim().toLowerCase();
    const as =
      ql !== ""
        ? _.cloneDeep(baseUtils.doFuseSearch(activesubstances, ql, ["name.de", "name.en"]))
        : _.cloneDeep(activesubstances);

    return (
      <SettingsTable
        setting="ActiveSubstances"
        settings={as.sort((a1, a2) => a1.name.de.localeCompare(a2.name.de))}
        title={{ table: "Active Substances", modal: "Active Substance" }}
        usageCheck={_id => commodities.some(c => c.activesubstance.map(a => a._id.toString()).includes(_id.toString()))}
        onDelete={this.handleDeleteActiveSubstance}
        onSave={this.handleCreateActiveSubstance}
        onEdit={this.handleEditActiveSubstance}
        onSearch={query => this.setState({ query })}
        additionalField={{
          title: "NRV",
          value: activeSubstance => (activeSubstance.nrv ? "" + activeSubstance.nrv : ""),
          content: activeSubstance => (activeSubstance.nrv ? activeSubstance.nrv + " mg" : ""),
          type: "number",
          append: "mg"
        }}
      />
    );
  }
}

export default withRouter(ActiveSubstances);
