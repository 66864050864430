import { BSON } from "realm-web";
import { SuppliersDocument } from "../model/suppliers.types";
import { CapsulePrice } from "../model/capsules.types";
import { CommodityPrice } from "../model/commodities.types";
import { PackagingPrice } from "../model/packagings.types";
import {
  RawbidsSupplierSelected,
  SupplierSelected,
  SupplierSelectedPrices
} from "../components/common/supplier/CustomTypes";
import { ManufacturersDocument } from "../model/manufacturers.types";
import { CompaniesDocument } from "../model/companies.types";

export const I_EXW = "EXW";
export const I_FCA = "FCA";
export const I_FAS = "FAS";
export const I_FOBA = "FOBair";
export const I_FOBS = "FOBsea";
export const I_CFR = "CFR";
export const I_CIFA = "CIFair";
export const I_CIFS = "CIFsea";
export const I_CIPA = "CIPair";
export const I_CIPS = "CIPsea";
export const I_DAP = "DAP";
export const I_DPU = "DPU";
export const I_CPT = "CPT";
export const I_CIP = "CIP";
export const I_DDP = "DDP";
export const I_COUR = "Courier";

export const INCOTERMS = [
  I_EXW,
  I_FCA,
  I_FAS,
  I_FOBA,
  I_FOBS,
  I_CFR,
  I_CIFA,
  I_CIFS,
  I_CIPA,
  I_CIPS,
  I_DAP,
  I_DPU,
  I_CPT,
  I_CIP,
  I_DDP,
  I_COUR
];

export enum AlternativeProviders {
  CUSTOMER = "customer",
  OWNSTOCK = "ownstock",
  ACCUMULATEDSTOCK = "accumulatedstock"
}

/**
 * Filters the given suppliers by the given query. The name, cities, country and zip of the supplier are checked.
 * @param query Query to filter for
 * @param suppliers Items that should be filtered
 * @returns {Array<SuppliersDocument>} Filtered and paginated list
 */
function filterSuppliers(query: string, suppliers: Array<SuppliersDocument>): Array<SuppliersDocument> {
  return suppliers.filter(s => {
    const q = query.toLowerCase().trim();
    const a = s.address.map(a => [a.city.toLowerCase().trim(), a.country.toLowerCase().trim(), a.zip]).flat();
    return s.name.toLowerCase().trim().includes(q) || a.find(ai => ai.includes(q));
  });
}

/**
 * Convert a normal material price into SupplierSelectedPrices objects
 * @param type the material type
 * @param price a material price, e.g. commodity, capsule or packaging price
 * @returns {SupplierSelectedPrices} a transformed price object
 */
function getSupplierSelectedPrice(
  type: string,
  price: CapsulePrice | CommodityPrice | PackagingPrice
): SupplierSelectedPrices {
  // @ts-ignore
  const pp = ["Commodity", "Service", "Softgel", "Custom"].includes(type) ? price.purchasePrice : price.price;
  // @ts-ignore
  const ppc = ["Commodity", "Service", "Softgel", "Custom"].includes(type) ? price.purchaseCurrency : "EUR";
  // @ts-ignore
  const it = ["Commodity", "Service", "Softgel", "Custom"].includes(type) ? price.incoterm : undefined;
  return {
    _id: BSON.ObjectId.isValid(price._id) ? new BSON.ObjectId(price._id) : new BSON.ObjectId(),
    totalPrice: price.price.toString(),
    purchasePrice: pp,
    purchasePriceCurrency: ppc,
    incoterm: it,
    moq: price.moq.toString(),
    deliveryTime: price.deliverytime.toString(),
    age: price.date,
    note: price.note
  };
}

/**
 * Get a default selected supplier price
 * @param type material type
 * @returns {SupplierSelectedPrices} a default SupplierSelectedPrices object
 */
function getDefaultSupplierSelectedPrice(type: string): SupplierSelectedPrices {
  return {
    _id: new BSON.ObjectId(),
    totalPrice: "0",
    purchasePrice: "0",
    purchasePriceCurrency: "EUR",
    incoterm: ["Commodity", "Service", "Softgel", "Custom"].includes(type) ? INCOTERMS[0] : undefined,
    moq: "0",
    deliveryTime: "0",
    age: new Date(),
    note: ""
  };
}

/**
 * Check if the selected supplier is the rawbids supplier, i.e. contains rawbids specific data
 * @param supplier a supplier
 */
function isRawbidsSupplier(supplier: SupplierSelected): supplier is RawbidsSupplierSelected {
  return "rawbids" in supplier && !!supplier.rawbids && "material" in supplier && !!supplier.material;
}

/**
 * Check if the given input is a supplier
 * @param supplier the document to check
 * @returns { boolean } true if document is a SuppliersDocument, false if not
 */
function isSupplier(
  supplier: SuppliersDocument | ManufacturersDocument | CompaniesDocument | "internal" | undefined
): supplier is SuppliersDocument {
  if (!supplier || supplier === "internal") return false;
  return !("productionLines" in supplier || "paymentTarget" in supplier);
}

export default {
  filterSuppliers,
  getSupplierSelectedPrice,
  getDefaultSupplierSelectedPrice,
  isRawbidsSupplier,
  isSupplier
};
