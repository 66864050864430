import React, { PureComponent } from "react";
import { ContractCall, OrdersDocument } from "../../../../model/orders.types";
import { CustomOrder } from "../../CustomTypes";
import { ARCHIVE, CREATEINVOICE, FULFILLMENT } from "../../../../utils/orderUtils";
import EditContractSettingsModal from "../../modals/EditContractSettingsModal";
import contractUtils from "../../../../utils/contractUtils";
import OrderContractItem from "./OrderContractItem";
import baseUtils from "../../../../utils/baseUtils";

interface OrderContractProps {
  order: CustomOrder;
  orders: Array<OrdersDocument>;
}

class OrderContract extends PureComponent<OrderContractProps> {
  /**
   * Get the call which is up next
   * @returns {ContractCall} the next call or null if no call is available
   */
  getNextCall = () => {
    const { order } = this.props;
    return contractUtils.getNextCall(order);
  };

  render() {
    const { order, orders } = this.props;
    const calculation = order.calculations[0];
    const contract = order.contract ? order.contract?.slice() : [];
    contract.sort((a, b) => {
      if (!a.date || !b.date) return 1;
      return a.date.valueOf() - b.date.valueOf();
    });
    const size = +calculation.units;
    const nextCall = this.getNextCall();
    const calls = contract.map(c => {
      return {
        call: c,
        order: orders.find(o => o.contractInformation && o.contractInformation.callId.toString() === c._id.toString())
      };
    });
    const produced = calls!.reduce(
      (a, b) =>
        a +
        (b.call.output
          ? +b.call.output
          : b.order && [FULFILLMENT, CREATEINVOICE, ARCHIVE].includes(b.order.state)
          ? b.order.calculations[0].units
          : 0),
      0
    );
    const remaining = size - produced;
    return (
      <div className="row">
        <div
          className="kt-section__content kt-section__content--solid"
          style={{
            borderLeft: "none",
            padding: "1.5rem",
            backgroundColor: "#f7f8fa",
            width: "100%"
          }}
        >
          <div className="kt-widget kt-widget--user-profile-3">
            <div className="kt-widget__bottom mt-0 pt-0 border-top-0">
              <div className="kt-widget__item mt-0 pt-0">
                <div className="kt-widget__details">
                  <span className="kt-widget__title">Contract Size</span>
                  <span className="kt-widget__value">{size}</span>
                </div>
              </div>
              <div className="kt-widget__item mt-0 pt-0">
                <div className="kt-widget__details">
                  <span className="kt-widget__title">Already produced</span>
                  <span className="kt-widget__value">{produced}</span>
                </div>
              </div>
              <div className="kt-widget__item mt-0 pt-0">
                <div className="kt-widget__details">
                  <span className="kt-widget__title">Remaining</span>
                  <span className="kt-widget__value">{remaining}</span>
                </div>
              </div>
              <div className="kt-widget__item mt-0 pt-0">
                <div className="kt-widget__details">
                  <span className="kt-widget__title">Next Call</span>
                  <span className="kt-widget__value">
                    {nextCall && nextCall.date ? baseUtils.formatDate(nextCall.date) : "-"}
                  </span>
                </div>
              </div>
              <div className="kt-widget__item mt-0 pt-0">
                <div className="kt-widget__details">
                  <span className="kt-widget__title">Next Call Size</span>
                  <span className="kt-widget__value">{nextCall?.value ?? "-"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded mt-4 table-responsive w-100">
          <table className="kt-datatable__table d-table">
            <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
              <tr className="kt-datatable__row d-table-row">
                <th className="kt-datatable__cell d-table-cell text-dark">Amount</th>
                <th className="kt-datatable__cell d-table-cell text-dark">Planned Call</th>
                <th className="kt-datatable__cell d-table-cell text-dark">Called</th>
                <th className="kt-datatable__cell d-table-cell text-dark">Finished</th>
                <th className="kt-datatable__cell d-table-cell text-dark">Real Amount</th>
              </tr>
            </thead>
            <tbody className="kt-datatable__body " style={{ display: "table-row-group" }}>
              {!contract || contract.length === 0 ? (
                <tr className="kt-datatable__row d-table-row">
                  <td className="kt-datatable__cell d-table-cell">-</td>
                  <td className="kt-datatable__cell d-table-cell">-</td>
                  <td className="kt-datatable__cell d-table-cell">-</td>
                  <td className="kt-datatable__cell d-table-cell">-</td>
                  <td className="kt-datatable__cell d-table-cell">-</td>
                </tr>
              ) : (
                calls.map(call => {
                  return <OrderContractItem key={call.call._id.toString()} call={call.call} order={call.order} />;
                })
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-3 mb-5 mx-auto">
          <EditContractSettingsModal order={order} calls={calls} />
        </div>
      </div>
    );
  }
}

export default OrderContract;
