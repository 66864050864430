import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { DataContext } from "../../../context/dataContext";
import { CommoditiesDocument } from "../../../model/commodities.types";
import toastUtils from "../../../utils/toastUtils";
import userService from "../../../services/userService";
import { ROLES } from "../../../utils/userdataUtils";
import slackService from "../../../services/slackService";
import commodityUtils from "../../../utils/commodityUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";

interface ApproveCommodityModalProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
}

interface ApproveCommodityModalState {
  show: boolean;
  saving: boolean;
}

class ApproveCommodityModal extends PureComponent<ApproveCommodityModalProps, ApproveCommodityModalState> {
  constructor(props: ApproveCommodityModalProps) {
    super(props);
    this.state = { show: false, saving: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false, saving: false });

  handleApproveCommodity = async () => {
    const { commodity } = this.props;
    const commodityNew = _.cloneDeep(commodity);
    commodityNew.approved = true;
    this.setState({ saving: true });
    try {
      const res = await commodityUtils.updateCommodityWithTimeline(commodity, commodityNew);
      await toastUtils.databaseOperationToast(
        !!res,
        "Commodity successfully approved",
        "Commodity could not be approved",
        () => {
          const message = `:heavy_check_mark: Commodity <${
            process.env.REACT_APP_BASE_URL
          }commodity/${commodity._id.toString()}|*${commodity.title.en}*> has been approved!`;
          slackService.sendMessage("#notifications-commodities", message);
          this.setState({ show: false });
        }
      );
    } catch (e) {
      toast.error("An error occurred: " + e);
      console.error(e);
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { commodity } = this.props;
    const { saving, show } = this.state;
    const canApprove = userService.hasOneOfRoles([ROLES.PROCUREMENT, ROLES.DATAMAINTENANCE]);
    return (
      <>
        <ErrorOverlayButton
          iconClass="fas fa-check"
          errors={!canApprove ? ["Only procurement and data maintenance can approve the commodity"] : []}
          buttonText={"Approve"}
          className="btn btn btn-success btn-sm btn-upper"
          onClick={this.handleShow}
          saving={saving}
          disabled={!canApprove}
        />
        <Modal show={show} centered onHide={this.handleHide} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Approve {commodity.title.en}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="alert alert-secondary mt-4 font-weight-bolder"
              role="alert"
              style={{ background: "white", border: 0 }}
            >
              <div className="alert-icon">
                <i className="flaticon2-information" style={{ color: "orange" }} />
              </div>
              <div className="text-dark h5 my-auto">Do you really want to approve {commodity.title.en}?</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            <button
              className={"btn btn-secondary" + (saving ? " disabled" : "")}
              onClick={saving ? undefined : this.handleApproveCommodity}
              disabled={saving}
            >
              Approve
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ApproveCommodityModal;
