import React, { PureComponent } from "react";
import { Accordion } from "react-bootstrap";
import OverdueInvoices from "./finance/OverdueInvoices";
import { DataContext } from "../../context/dataContext";

interface FinanceDashboardProps {}

interface FinanceDashboardState {
  activeLevel: string;
}

class FinanceDashboard extends PureComponent<FinanceDashboardProps, FinanceDashboardState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: FinanceDashboardProps) {
    super(props);
    this.state = { activeLevel: "2" };
  }

  render() {
    const { activeLevel } = this.state;
    return (
      <Accordion activeKey={activeLevel} onSelect={e => this.setState({ activeLevel: e ? e : "" })}>
        <OverdueInvoices context={this.context} level="0" activeKey={activeLevel} />
        <OverdueInvoices context={this.context} level="1" activeKey={activeLevel} />
        <OverdueInvoices context={this.context} level="2" activeKey={activeLevel} />
      </Accordion>
    );
  }
}

export default FinanceDashboard;
