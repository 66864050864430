import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import Supplier from "./Supplier";

interface SupplierWrapperProps extends RouteComponentProps<SupplierParams, {}, {}> {}

interface SupplierParams {
  id: string;
}

const SupplierWrapper: React.FunctionComponent<SupplierWrapperProps> = props => {
  const context = useContext(DataContext);
  return <Supplier context={context} {...props} />;
};

export default SupplierWrapper;
