import _ from "lodash";
import React, { PureComponent } from "react";
import { DataContext } from "../../../context/dataContext";
import { CustomOrder } from "../CustomTypes";
import NotAvailable from "../../common/NotAvailable";
import OrderFiles from "./OrderFiles";
import { T_SHIPPINGLABEL } from "../../../utils/timelineUtils";
import { UsedBatch, UsedPackagingBatch } from "../../../model/orders.types";
import baseUtils from "../../../utils/baseUtils";
import CommodityWidget from "../../common/CommodityWidget";
import PackagingWidget from "../../common/PackagingWidget";
import commodityUtils from "../../../utils/commodityUtils";
import { T_SERVICE } from "../OrderHelper";

interface OrderFulfillmentProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
}

interface OrderFulfillmentState {}

class OrderFulfillment extends PureComponent<OrderFulfillmentProps, OrderFulfillmentState> {
  render() {
    const { order, context } = this.props;
    const company = order.createdFor;
    return (
      <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "70vh" }}>
        {order.fulfillment && order.fulfillment.shippingGroups.length > 0 ? (
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="kt-section">
                <div className="alert alert-secondary">
                  <div className="alert-text">
                    <h5 className="alert-heading">Shipping Notes</h5>
                    <p>
                      {company.name ? company.name : "unknown"} will receive{" "}
                      {order.fulfillment.shippingGroups.reduce((a, b) => a + +b.boxes, 0)} boxes containing{" "}
                      {order.fulfillment.shippingGroups.reduce((a, b) => a + +b.items * +b.boxes, 0)} items.
                      <br />
                      Approx. weight:{" "}
                      {Math.round(order.fulfillment.shippingGroups.reduce((a, b) => a + +b.weight * +b.boxes, 0))}
                      kg.
                      {order.fulfillment.shippingNote && (
                        <span className="d-block mt-3">
                          <b>{order.fulfillment.shippingNote}</b>
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered dataTable no-footer dtr-inline" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>Boxes</th>
                      <th>Items per box</th>
                      <th>Weight</th>
                      <th>Size</th>
                      <th>Total Items</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.fulfillment &&
                      order.fulfillment.shippingGroups.length > 0 &&
                      order.fulfillment.shippingGroups.map((item, key) => (
                        <tr key={key}>
                          <td>{item.boxes}</td>
                          <td>{item.items}</td>
                          <td>{item.weight + "kg"}</td>
                          <td>60x40x30cm</td>
                          <td>{+item.boxes * +item.items}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-2">
                <OrderFiles {...this.props} timelineTypes={[T_SHIPPINGLABEL]} />
              </div>
            </div>
          </div>
        ) : (
          <NotAvailable />
        )}
        {order.usedBatches && order.usedBatches.length > 0 && (
          <OrderUsedBatches type={"commodity"} usedBatches={order.usedBatches} context={context} />
        )}
        {order.usedPackagingBatches && order.usedPackagingBatches.length > 0 && (
          <OrderUsedBatches type={"packaging"} usedBatches={order.usedPackagingBatches} context={context} />
        )}
      </div>
    );
  }
}

interface OrderUsedBatchesProps {
  type: "commodity" | "packaging";
  usedBatches: Array<UsedBatch | UsedPackagingBatch>;
  context: React.ContextType<typeof DataContext>;
}

export const OrderUsedBatches: React.FunctionComponent<OrderUsedBatchesProps> = ({ type, usedBatches, context }) => {
  return (
    <div className="mt-5">
      <div className="kt-section w-100 mb-0">
        <div className="alert alert-secondary mb-0">
          <div className="alert-text">
            <h5 className="alert-heading">
              {type === "commodity" ? "Used Commodity Batches" : "Used Packaging Batches"}
            </h5>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered dataTable no-footer dtr-inline" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ width: "35%" }}>{type === "commodity" ? "Commodity" : "Packaging"}</th>
              <th style={{ width: "15%" }}>Amount</th>
              <th style={{ width: "20%" }}>Batch</th>
              <th style={{ width: "15%" }}>Price</th>
              <th style={{ width: "15%" }}>Total Price</th>
            </tr>
          </thead>
          <tbody>
            {usedBatches.map(b => (
              <OrderUsedBatch key={b.id.toString()} type={type} usedBatch={b} context={context} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

interface OrderUsedBatchProps {
  type: "commodity" | "packaging";
  usedBatch: UsedBatch | UsedPackagingBatch;
  context: React.ContextType<typeof DataContext>;
}

const OrderUsedBatch: React.FunctionComponent<OrderUsedBatchProps> = ({ type, usedBatch, context }) => {
  const isUsedBatch = (batch: UsedBatch | UsedPackagingBatch): batch is UsedBatch => type === "commodity";
  const isUsedPackagingBatch = (batch: UsedBatch | UsedPackagingBatch): batch is UsedPackagingBatch =>
    type === "packaging";
  let document;
  if ((isUsedBatch(usedBatch) && usedBatch.commodityId) || (isUsedPackagingBatch(usedBatch) && usedBatch.packagingId))
    document = baseUtils.getDocFromCollection(
      type === "commodity" ? context.commodities : context.packagings,
      isUsedBatch(usedBatch) ? usedBatch.commodityId : usedBatch.packagingId
    );
  return (
    <tr>
      <td>
        {document ? (
          type === "commodity" ? (
            <CommodityWidget commodity={document} context={context} />
          ) : (
            <PackagingWidget packaging={document} context={context} />
          )
        ) : (
          `Unknown ${_.upperFirst(type)}`
        )}
      </td>
      <td>
        {document
          ? type === "commodity"
            ? commodityUtils.resolveStockUnit(+usedBatch.used, document.type)
            : usedBatch.used + " pcs."
          : "-"}
      </td>
      <td>{usedBatch.lot}</td>
      <td>
        {baseUtils.formatEuro(+usedBatch.price)}
        {document
          ? type === "commodity"
            ? document.type === ""
              ? "/kg"
              : document.type === T_SERVICE
              ? "/item"
              : "/tsd."
            : "/item"
          : "/unit"}
      </td>
      <td>{baseUtils.formatEuro(+usedBatch.price * +usedBatch.used)}</td>
    </tr>
  );
};

export default OrderFulfillment;
