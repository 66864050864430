import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DataContext } from "../../context/dataContext";
import baseUtils from "../../utils/baseUtils";
import { OrdersDocument } from "../../model/orders.types";
import { ORDERORDERCOMMODITIES, WAITING } from "../../utils/orderUtils";

interface RelatedOrdersProps {
  orders: Array<BSON.ObjectId>;
  removedOrders: Array<BSON.ObjectId>;
  context: React.ContextType<typeof DataContext>;
  onRelatedOrder: (order: BSON.ObjectId, remove?: boolean) => void;
  alwaysRemovable?: boolean;
}

interface RelatedOrdersState {
  showAll: boolean;
}

class RelatedOrders extends PureComponent<RelatedOrdersProps, RelatedOrdersState> {
  constructor(props: RelatedOrdersProps) {
    super(props);
    this.state = {
      showAll: false
    };
  }

  handleRelatedOrder = (order: OrdersDocument, remove?: boolean) => {
    const { alwaysRemovable, onRelatedOrder } = this.props;
    if ([ORDERORDERCOMMODITIES, WAITING].includes(order.state) || alwaysRemovable) onRelatedOrder(order._id, remove);
    else console.error("Order AT-", order.identifier, "in state", order.state, "cannot be removed");
  };

  render() {
    const { alwaysRemovable, orders, removedOrders, context } = this.props;
    const { showAll } = this.state;
    let fullOrders = orders.map(o => baseUtils.getDocFromCollection(context.orders, o)) as Array<OrdersDocument>;
    fullOrders = fullOrders.filter(o => !!o);
    const sliceOrders = !showAll && fullOrders.length > 5;
    if (sliceOrders) fullOrders = fullOrders.slice(0, 5);
    return (
      <div>
        {fullOrders.map(o => (
          <RelatedOrderWidget
            key={o._id.toString()}
            order={o}
            onRelatedOrder={this.handleRelatedOrder}
            removed={removedOrders.some(rO => rO.toString() === o._id.toString())}
            alwaysRemovable={alwaysRemovable}
          />
        ))}
        {sliceOrders && (
          <div className="d-inline-flex">
            <div className="kt-section__content d-flex flex-wrap " style={{ minWidth: "180px" }}>
              <div className="kt-widget4__info mx-auto" style={{ position: "relative", top: "-19px" }}>
                <p
                  className="kt-widget4__text text-dark kt-link pointer"
                  onClick={() => this.setState({ showAll: true })}
                >
                  Show all
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

interface RelatedOrderWidgetProps {
  order: OrdersDocument;
  removed: boolean;
  onRelatedOrder: (order: OrdersDocument, remove?: boolean) => void;
  alwaysRemovable?: boolean;
}

const RelatedOrderWidget: React.FunctionComponent<RelatedOrderWidgetProps> = ({
  order,
  removed,
  onRelatedOrder,
  alwaysRemovable
}) => {
  const canBeDisabled = [ORDERORDERCOMMODITIES, WAITING].includes(order.state) || alwaysRemovable;
  return (
    <div className="d-inline-flex">
      <div className="kt-section__content d-flex flex-wrap " style={{ minWidth: "180px" }}>
        <span className="kt-media kt-media--circle kt-margin-r-5 kt-margin-t-5">
          {order.settings.type && (
            <img
              src={process.env.PUBLIC_URL + "/media/icons/" + order.settings.type + ".png"}
              className="p-1"
              style={removed ? { opacity: 0.2 } : {}}
            />
          )}
        </span>
        <OverlayTrigger
          overlay={
            <Tooltip id="relatedOrderTooltip">
              <span className="text-dark">{removed ? "Keep related order" : "Unlink related order"}</span>
            </Tooltip>
          }
        >
          <button
            className={
              "kt-badge kt-badge--light pos-relatedOrdersButton border-0 " +
              (canBeDisabled ? (removed ? "relatedOrderAdd" : "relatedOrderRemove") : "disabled")
            }
            disabled={!canBeDisabled}
            onClick={() => (removed ? onRelatedOrder(order, false) : onRelatedOrder(order, true))}
          >
            {removed ? <i className="fas fa-check" /> : <i className="fas fa-times" />}
          </button>
        </OverlayTrigger>
        <div
          className="kt-widget4__info mt-3 ml-1"
          style={{ position: "relative", left: "-22px", opacity: removed ? 0.2 : 1 }}
        >
          <Link to={"/order/" + order._id.toString()} className="kt-widget4__username">
            AT-{order.identifier}
          </Link>
          {order.title.length > 15 ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="titleTooltip">
                  <span className="text-dark">{order.title}</span>
                </Tooltip>
              }
            >
              <p className="kt-widget4__text">{order.title.slice(0, 12) + "..."}</p>
            </OverlayTrigger>
          ) : (
            <p className="kt-widget4__text">{order.title}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RelatedOrders;
