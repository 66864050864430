import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import UploadMaterialFileModal from "../common/UploadMaterialFileModal";
import { CommoditiesDocument } from "../../model/commodities.types";

interface UploadCommodityFileProps {
  commodity: CommoditiesDocument;
  addUpload: (id: BSON.ObjectId, path: string, type: string) => void;
}

interface UploadCommodityFileState {
  type: string;
  showUploadCommodityFileModal: boolean;
}

class UploadCommodityFile extends PureComponent<UploadCommodityFileProps, UploadCommodityFileState> {
  constructor(props: UploadCommodityFileProps) {
    super(props);
    this.state = { type: "", showUploadCommodityFileModal: false };
  }

  onClickUpload = (type: string) => {
    this.setState({ showUploadCommodityFileModal: true, type });
  };

  onCloseModal = () => {
    this.setState({ showUploadCommodityFileModal: false });
  };

  render() {
    const { addUpload, commodity } = this.props;
    const { showUploadCommodityFileModal, type } = this.state;
    const disabled = commodity.suppliers.length === 0;
    return (
      <>
        {showUploadCommodityFileModal && (
          <UploadMaterialFileModal addUpload={addUpload} material={commodity} type={type} onClose={this.onCloseModal} />
        )}
        <div className="tab-pane" role="tabpanel">
          <div className="kt-section">
            <div className="kt-section__content kt-section__content--solid" style={{ borderLeft: "none" }}>
              {disabled ? (
                <div className="text-center">Add a supplier to upload documents</div>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-label-brand btn-sm btn-bold m-2"
                    onClick={() => this.onClickUpload("specification")}
                  >
                    <i className="fa fa-file-alt" />
                    Specification
                  </button>
                  <button
                    type="button"
                    className="btn btn-label-brand btn-sm btn-bold m-2"
                    onClick={() => this.onClickUpload("flowChart")}
                  >
                    <i className="fa fa-file-alt" />
                    Flow Chart
                  </button>
                  <button
                    type="button"
                    className="btn btn-label-brand btn-sm btn-bold m-2"
                    onClick={() => this.onClickUpload("MSDS")}
                  >
                    <i className="fa fa-file-alt" />
                    MSDS
                  </button>
                  <button
                    type="button"
                    className="btn btn-label-brand btn-sm btn-bold m-2"
                    onClick={() => this.onClickUpload("testReport")}
                  >
                    <i className="fa fa-file-alt" />
                    Test Report
                  </button>
                  <button
                    type="button"
                    className="btn btn-label-brand btn-sm btn-bold m-2"
                    onClick={() => this.onClickUpload("compositionStatement")}
                  >
                    <i className="fa fa-file-alt" />
                    Composition Statement
                  </button>
                  <button
                    type="button"
                    className="btn btn-label-brand btn-sm btn-bold m-2"
                    onClick={() => this.onClickUpload("nutritionalValues")}
                  >
                    <i className="fa fa-file-alt" />
                    Nutritional Values
                  </button>
                  <button
                    type="button"
                    className="btn btn-label-brand btn-sm btn-bold m-2"
                    onClick={() => this.onClickUpload("naturalStatement")}
                  >
                    <i className="fa fa-file-alt" />
                    Natural Statement
                  </button>
                  <button
                    type="button"
                    className="btn btn-label-brand btn-sm btn-bold m-2"
                    onClick={() => this.onClickUpload("rawMaterialOriginStatement")}
                  >
                    <i className="fa fa-file-alt" />
                    Raw Material Origin Statement
                  </button>
                  <button
                    type="button"
                    className="btn btn-label-brand btn-sm btn-bold m-2"
                    onClick={() => this.onClickUpload("CoA")}
                  >
                    <i className="fa fa-file-alt" />
                    CoA
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UploadCommodityFile;
