import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SettingsTable from "./SettingsTable";
import { DataContext } from "../../context/dataContext";
import dbService, { COMPOSITIONS } from "../../services/dbService";
import toastUtils from "../../utils/toastUtils";
import baseUtils from "../../utils/baseUtils";

interface CompositionsProps extends RouteComponentProps<{}, {}, {}> {}

interface CompositionsState {
  query: string;
}

class Compositions extends PureComponent<CompositionsProps, CompositionsState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: CompositionsProps) {
    super(props);
    this.state = { query: "" };
  }

  /**
   * Handles creating a new composition.
   * @param _id ID of the composition that is created
   * @param de Name of the composition in german
   * @param en Name of the composition in english
   */
  handleCreateCompositions = async (_id: BSON.ObjectId, de: string, en: string) => {
    const { updateDocumentInContext } = this.context;
    const deUpdated = baseUtils.capitalizeAllWords(de.trim());
    const enUpdated = baseUtils.capitalizeAllWords(en.trim());
    const res = await dbService.insertDocument(COMPOSITIONS, { _id, name: { de: deUpdated, en: enUpdated } });
    await toastUtils.databaseOperationToast(
      !!res && res.insertedId,
      "Composition successfully added",
      "Adding composition failed",
      () => updateDocumentInContext(COMPOSITIONS, _id)
    );
  };

  /**
   * Handles editing a composition.
   * @param _id ID of the composition that is edited
   * @param de Name of the composition in german
   * @param en Name of the composition in english
   */
  handleEditCompositions = async (_id: BSON.ObjectId, de: string, en: string) => {
    const { updateDocumentInContext } = this.context;
    const deUpdated = baseUtils.capitalizeAllWords(de.trim());
    const enUpdated = baseUtils.capitalizeAllWords(en.trim());
    const res = await dbService.updateDocument(COMPOSITIONS, _id, { name: { de: deUpdated, en: enUpdated } });
    await toastUtils.databaseOperationToast(
      !!res && res.modifiedCount > 0,
      "Composition successfully edited",
      "Editing composition failed",
      () => updateDocumentInContext(COMPOSITIONS, _id)
    );
  };

  /**
   * Handles deleting a composition.
   * @param _id ID of the composition that should be deleted
   */
  handleDeleteCompositions = async (_id: BSON.ObjectId) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.deleteDocument(COMPOSITIONS, _id);
    await toastUtils.databaseOperationToast(
      !!res && res.deletedCount > 0,
      "Composition successfully deleted",
      "Deleting composition failed",
      () => updateDocumentInContext(COMPOSITIONS, _id)
    );
  };

  render() {
    const { query } = this.state;
    const { commodities, compositions } = this.context;

    const ql = query.toLowerCase();
    const c = _.cloneDeep(
      compositions.filter(c => c.name.de.toLowerCase().includes(ql) || c.name.en.toLowerCase().includes(ql))
    );

    return (
      <SettingsTable
        setting="Compositions"
        settings={c.sort((c1, c2) => c1.name.de.localeCompare(c2.name.de))}
        title={{ table: "Compositions", modal: "Composition" }}
        usageCheck={_id => commodities.some(c => c.form.toString() === _id.toString())}
        onDelete={this.handleDeleteCompositions}
        onSave={this.handleCreateCompositions}
        onEdit={this.handleEditCompositions}
        onSearch={query => this.setState({ query })}
      />
    );
  }
}

export default withRouter(Compositions);
