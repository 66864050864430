import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import PackagingHeader from "./PackagingHeader";
import { PackagingsDocument } from "../../model/packagings.types";
import baseUtils from "../../utils/baseUtils";
import dbService, { PACKAGINGS } from "../../services/dbService";
import SplashScreen from "../common/SplashScreen";
import PackagingPreview from "./PackagingPreview";
import PackagingNote from "./PackagingNote";
import PackagingInformation from "./PackagingInformation";
import PackagingTabPanel from "./PackagingTabPanel";
import { OrdersDocument } from "../../model/orders.types";
import PackagingPackagingOrders from "./tabPanels/PackagingPackagingOrders";

interface PackagingParams {
  id: string;
  order: string;
}

interface PackagingProps extends RouteComponentProps<PackagingParams, {}, {}> {
  context: React.ContextType<typeof DataContext>;
}

interface PackagingState {
  packaging?: PackagingsDocument;
  order?: OrdersDocument;
}

class Packaging extends PureComponent<PackagingProps, PackagingState> {
  _isMounted = false;
  _id: string | undefined;
  tabPanelRef: any;

  constructor(props: PackagingProps) {
    super(props);
    this._id = props.match.params.id;
    this.state = {};
  }

  componentDidMount = async () => {
    await this.initializeData();
  };

  componentDidUpdate = async (
    prevProps: Readonly<PackagingProps>,
    prevState: Readonly<PackagingState>,
    snapshot?: any
  ) => {
    if (
      prevProps.match.params.id !== this.props.match.params.id ||
      prevProps.match.params.order !== this.props.match.params.order
    ) {
      if (prevProps.match.params.id !== this.props.match.params.id) this._id = this.props.match.params.id;
      await this.initializeData();
    }
    const { packagings } = this.props.context;
    const packaging = baseUtils.getDocFromCollection(packagings, this._id!);
    if (packaging) {
      if (!_.isEqual(packaging, prevState.packaging) || prevProps !== this.props) {
        this.setState({ packaging });
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  initializeData = async () => {
    const { history, context } = this.props;
    const { packagings } = context;
    if (!this._id || !BSON.ObjectId.isValid(this._id)) {
      history.push("/packagings");
      return;
    }
    this._isMounted = true;
    let order;
    if (this.props.match.params.order) {
      order = baseUtils.getDocFromCollection(context.orders, this.props.match.params.order);
    }
    let packaging = baseUtils.getDocFromCollection(packagings, this._id);
    if (!packaging) {
      packaging = await dbService.getDocumentFromCollection(PACKAGINGS, this._id);
      if (!packaging) {
        console.error("No packaging could be loaded for id", this._id);
        history.push("/packagings");
        return;
      }
      context.addDocuments(PACKAGINGS, [packaging]);
    }
    if (this._isMounted) this.setState({ packaging, order });
  };

  handleChangeTab = (tab: string) => this.tabPanelRef.handleTab(tab);

  setRef = (ref: any) => {
    this.tabPanelRef = ref;
  };

  render() {
    const { context } = this.props;
    const { packaging, order } = this.state;
    if (!packaging)
      return (
        <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid ">
          <div className="kt-portlet ">
            <div className="kt-portlet__body" style={{ minHeight: "80vh" }}>
              <SplashScreen
                additionalSVGStyle={{
                  height: "80px",
                  width: "80px"
                }}
              />
            </div>
          </div>
        </div>
      );
    else
      return (
        <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
          <PackagingHeader packaging={packaging} context={context} onTabChange={this.handleChangeTab} />
          <div className="row">
            <div className="col-xl-4">
              <PackagingPreview packaging={packaging} onTabChange={this.handleChangeTab} />
              <div className="kt-portlet kt-portlet--head-noborder">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">Open Packaging Orders</h3>
                  </div>
                </div>
                <div className="kt-portlet__body">
                  <PackagingPackagingOrders
                    packaging={packaging}
                    context={context}
                    size="small"
                    onTabChange={this.handleChangeTab}
                    order={order}
                  />
                </div>
              </div>
              <PackagingNote packaging={packaging} onTabChange={this.handleChangeTab} />
              <PackagingInformation packaging={packaging} onTabChange={this.handleChangeTab} />
            </div>
            <div className="col-xl-8">
              <PackagingTabPanel packaging={packaging} context={context} ref={this.setRef} />
            </div>
          </div>
        </div>
      );
  }
}

export default Packaging;
