import React, { PureComponent } from "react";
import { OrdersDocument } from "../../model/orders.types";
import { DataContext } from "../../context/dataContext";
import OrderCommoditiesOrder from "../order/tabPanles/materialPanels/OrderCommoditiesOrder";
import OrderPackagingOrder from "../order/tabPanles/materialPanels/OrderPackagingOrder";
import OrderHelper from "../order/OrderHelper";
import OrderFiles from "../order/tabPanles/OrderFiles";
import OrderNotes from "../order/tabPanles/OrderNotes";
import OrderTimeline from "../order/tabPanles/OrderTimeline";
import ProductionOrderHeader from "./ProductionOrderHeader";

interface ProductionPlanOrderDetailsProps {
  order: OrdersDocument;
  context: React.ContextType<typeof DataContext>;
  onCancelSchedule: () => void;
  reschedule?: boolean;
}

interface ProductionPlanOrderDetailsState {}

class ProductionPlanOrderDetails extends PureComponent<
  ProductionPlanOrderDetailsProps,
  ProductionPlanOrderDetailsState
> {
  render() {
    const { order: originOrder, context, reschedule, onCancelSchedule } = this.props;
    const order = OrderHelper.getExtendedOrder(originOrder, context);
    const { commodities, packagings } = context;
    return (
      <div>
        <ProductionOrderHeader
          order={order}
          context={context}
          reschedule={reschedule}
          onCancelSchedule={onCancelSchedule}
        />
        <span className="kt-font-bolder kt-font-dark h5">Commodities</span>
        <hr />
        <OrderCommoditiesOrder order={order} commodities={commodities} suppliers={[]} readOnly={true} />
        {order.calculations[0].packagings.length > 0 && (
          <>
            <span className="kt-font-bolder kt-font-dark h5">Packaging</span>
            <hr />
            <OrderPackagingOrder order={order} packaging={packagings} suppliers={[]} readOnly={true} />
          </>
        )}
        <span className="kt-font-bolder kt-font-dark h5">Files</span>
        <hr />
        <div className={"mx-3"} style={{ marginBottom: "25px" }}>
          <OrderFiles order={order} context={context} readOnly={true} />
        </div>
        <span className="kt-font-bolder kt-font-dark h5">Notes</span>
        <hr />
        <div className={"mx-3"}>
          <OrderNotes order={order} context={context} alternateText={true} />
        </div>
        <span className="kt-font-bolder kt-font-dark h5">Timeline</span>
        <hr />
        <div className={"mx-3"}>
          <OrderTimeline order={order} context={context} readOnly={true} />
        </div>
      </div>
    );
  }
}

export default ProductionPlanOrderDetails;
