import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import FinanceDetails from "./FinanceDetails";

interface FinanceDetailsWrapperProps extends RouteComponentProps {}

const FinanceDetailsWrapper: React.FunctionComponent<FinanceDetailsWrapperProps> = props => {
  const context = useContext(DataContext);
  return <FinanceDetails context={context} {...props} />;
};

export default FinanceDetailsWrapper;
