import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import i18n from "../../../translations/i18n";
import CreatePackagingUnitModal from "./modals/CreatePackagingUnitModal";
import { useWarehouseContext } from "../../../context/warehouseContext";
import { resolveTranslation } from "../../../utils/translationUtils";
import { SearchBar } from "../../listings/common/Filters";
import Pagination, { paginate } from "../../common/Pagination";
import baseUtils from "../../../utils/baseUtils";
import { useDataContext } from "../../../context/dataContext";
import HistoryBackButton from "../../listings/common/HistoryBackButton";
import { ModalMode } from "../../../model/common.types";
import { PackagingUnitDefinition } from "../../../model/configuration/warehouseConfiguration.types";

interface PackagingUnitsSettingsProps extends RouteComponentProps {}

const PackagingUnitsSettings: React.FunctionComponent<PackagingUnitsSettingsProps> = ({ history }) => {
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();

  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({ pageSize: 10, currentPage: 1 });
  const searchResult = useMemo(() => {
    const ql = search.trim().toLowerCase();
    const packagingUnitDefinitions = warehouseContext.configuration?.values.packagingUnitDefinitions ?? [];
    return ql !== ""
      ? _.cloneDeep(
          baseUtils.doFuseSearch<PackagingUnitDefinition>(packagingUnitDefinitions, ql, ["label.de", "label.en"])
        )
      : _.cloneDeep(warehouseContext.configuration?.values.packagingUnitDefinitions ?? []);
  }, [search, warehouseContext.configuration?.values.packagingUnitDefinitions]);

  const pUs = useMemo(() => {
    return paginate(searchResult, pagination.currentPage, pagination.pageSize);
  }, [searchResult, pagination]);

  const handleSearch = useCallback(e => {
    setSearch(e.target.value);
    setPagination(prevState => {
      return { ...prevState, currentPage: 1 };
    });
  }, []);

  const handleChangePage = useCallback(page => {
    setPagination(prevState => {
      return { ...prevState, currentPage: page };
    });
  }, []);

  const handleChangePageSize = useCallback(pageSize => {
    setPagination({ pageSize, currentPage: 1 });
  }, []);

  /**
   * Check if packaging unit is used somewhere in batch and other when implemented later
   */
  const inUseMap = useMemo(() => {
    const usedPus: {
      [key: string]: boolean;
    } = {};
    const batchPUs = dataContext.batch.flatMap(b => b.locations.flatMap(l => l.packagingUnits));
    if (warehouseContext.configuration) {
      for (let i = 0; i < warehouseContext.configuration.values.packagingUnitDefinitions.length; i++) {
        const pu = warehouseContext.configuration.values.packagingUnitDefinitions[i];
        usedPus[pu._id.toString()] = batchPUs.some(p => p.puSnapshot._id.toString() === pu._id.toString());
      }
    }
    return usedPus;
  }, [warehouseContext.configuration, dataContext.batch]);

  return (
    <>
      <div className="alert alert-light alert-elevate" style={{ backgroundColor: "white" }}>
        <div className="alert-icon">
          <i className="flaticon-warning kt-font-danger" />
        </div>
        <div className="alert-text">{i18n.t("warehouse:packagingUnitConfigWarning")}</div>
      </div>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">{i18n.t("warehouse:packagingUnitConfiguration")}</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <HistoryBackButton history={history} text={i18n.t("common:back")} />
              <CreatePackagingUnitModal mode={ModalMode.CREATE} />
            </div>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-form kt-form--label-right">
            <div className="row align-items-center">
              <SearchBar search={search} onSearch={e => handleSearch(e)} additionalSizeClasses={"col-md-2"} />
              <div className="col-xl-4 order-1 order-xl-2 kt-align-right">
                <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg d-xl-none" />
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded px-4">
            <Table>
              <thead>
                <tr>
                  <th style={{ width: "16%" }}>{i18n.t("packaging:label")}</th>
                  <th style={{ width: "16%" }}>{i18n.t("warehouse:lwh")}</th>
                  <th style={{ width: "16%" }}>{i18n.t("packaging:shape")}</th>
                  <th style={{ width: "16%" }}>{i18n.t("warehouse:deadWeight")}</th>
                  <th style={{ width: "16%" }}>{i18n.t("warehouse:maxFillingWeight")}</th>
                  <th className="text-right" style={{ width: "20%" }}>
                    {i18n.t("common:actions")}
                  </th>
                </tr>
              </thead>
              <tbody className="kt-datatable__body">
                {pUs &&
                  pUs.map(pu => {
                    return (
                      <tr key={pu._id.toString()}>
                        <td className="align-middle">{resolveTranslation(pu.label)}</td>
                        <td className="align-middle">{`${pu.dimensions.length} x ${pu.dimensions.width} x ${pu.dimensions.height} ${pu.dimensions.unit}`}</td>
                        <td className="align-middle">{i18n.t(`warehouse:${pu.dimensions.shape}`)}</td>
                        <td className="align-middle">{`${pu.deadWeight.value} ${pu.deadWeight.unit}`}</td>
                        <td className="align-middle">{`${pu.maxFillingWeight?.value ?? "-"} ${
                          pu.maxFillingWeight?.unit ?? ""
                        }`}</td>
                        <td className="text-right align-middle">
                          <span className="mr-2">
                            <CreatePackagingUnitModal
                              mode={ModalMode.EDIT}
                              pUEdit={pu}
                              isPUUsed={inUseMap[pu._id.toString()]}
                            />
                          </span>
                          <CreatePackagingUnitModal
                            mode={ModalMode.DELETE}
                            pUEdit={pu}
                            isPUUsed={inUseMap[pu._id.toString()]}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div className="kt-datatable__pager kt-datatable--paging-loaded justify-content-center">
              <Pagination
                itemsCount={
                  warehouseContext.configuration
                    ? warehouseContext.configuration.values.packagingUnitDefinitions.length
                    : 0
                }
                pageSize={pagination.pageSize}
                onPageChange={handleChangePage}
                currentPage={pagination.currentPage}
                onPageSizeChange={handleChangePageSize}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackagingUnitsSettings;
