import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import i18n from "../../../translations/i18n";
import { BaseActionModalProps } from "../../../model/warehouse/common.types";
import { useWarehouseDispatch, WarehouseActionType } from "../../../context/warehouseContext";
import { useDataContext } from "../../../context/dataContext";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { Input } from "../../common/Input";
import { resolveTranslation } from "../../../utils/translationUtils";

const ReadCodeModal: React.FC<BaseActionModalProps> = ({ show, onHide }) => {
  const dispatch = useWarehouseDispatch();
  const { batch, universalBarcode, individualBarcode } = useDataContext();

  const [code, setCode] = useState<string>("");

  useEffect(() => {
    setCode("");
  }, [show]);

  const barcode = useMemo(() => {
    if (code.includes("_")) {
      const ib = individualBarcode.find(ib => ib._id.toString() === code.split("_")[0]);
      if (ib && code.substring(code.indexOf("_") + 1) === ib.packageNumber.toString()) {
        return ib;
      }
    } else {
      return universalBarcode.find(ub => ub._id.toString() === code);
    }
  }, [code, individualBarcode, universalBarcode]);

  const barcodeBatch = useMemo(() => {
    if (barcode)
      return batch.find(b => {
        return b._id.toString() === barcode.batch;
      });
  }, [barcode, batch]);

  const errors = useMemo(() => {
    const errors = [];
    if (!barcodeBatch) errors.push(i18n.t("warehouse:batchNotFoundError"));
    return errors;
  }, [barcodeBatch]);

  const handleChangeCode = useCallback((e: ChangeEvent<HTMLInputElement>) => setCode(e.currentTarget.value), []);

  const handleSearchBatch = useCallback(() => {
    if (barcodeBatch) {
      dispatch({
        type: WarehouseActionType.SEARCH,
        payload: { query: barcodeBatch.lot }
      });
      onHide();
    }
  }, [barcodeBatch]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>{i18n.t("warehouse:readCode")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          <h4 className="mb-5 font-weight-bold text-black">{i18n.t("warehouse:code")}</h4>
          <Input
            className="form-control form-control-solid"
            name="readCode"
            placeholder={i18n.t("warehouse:barcodeNumber")}
            value={code}
            onInput={handleChangeCode}
          />
        </div>
        {barcodeBatch && (
          <div className="mt-5 px-2 text-black">
            <h5>{i18n.t("warehouse:batch")}</h5>
            <div>
              {i18n.t("warehouse:commodity")}: {resolveTranslation(barcodeBatch.content.details.title)} -
              {resolveTranslation(barcodeBatch.content.details.subtitle)}
            </div>
            <div>
              {i18n.t("warehouse:lot")}: {barcodeBatch.lot}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary " onClick={onHide}>
          {i18n.t("common:close")}
        </button>
        <ErrorOverlayButton
          buttonText={i18n.t("warehouse:readCode")}
          className="btn btn-success"
          errors={errors}
          onClick={handleSearchBatch}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ReadCodeModal;
