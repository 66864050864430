import React, { PureComponent } from "react";
import { PackagingsDocument } from "../../model/packagings.types";

interface PackagingNoteProps {
  packaging: PackagingsDocument;
  onTabChange: (tab: string) => void;
}

interface PackagingNoteState {}

class PackagingNote extends PureComponent<PackagingNoteProps, PackagingNoteState> {
  render() {
    const { packaging } = this.props;
    return (
      <div className="kt-portlet kt-portlet--head-noborder">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Note</h3>
          </div>
          <div className="kt-portlet__head-toolbar" />
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit-top">
          <div className="kt-section kt-section--space-sm">
            {packaging.note
              ? packaging.note
              : "Important additional information about the packaging that cannot be found anywhere else can be stored here."}
          </div>
          <div className="kt-section kt-section--last">
            <button onClick={() => this.props.onTabChange("settings")} className="btn btn-label-brand btn-sm btn-bold">
              Update Note
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default PackagingNote;
