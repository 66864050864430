import React, { PureComponent } from "react";
import { Badge, Table } from "react-bootstrap";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { PaginationState } from "../common/CustomTypes";
import Pagination, { paginate } from "../common/Pagination";
import { DataContext } from "../../context/dataContext";
import { CapsulesDocument } from "../../model/capsules.types";
import capsuleUtils from "../../utils/capsuleUtils";
import baseUtils, { getComponentState } from "../../utils/baseUtils";
import { SearchBar } from "../listings/common/Filters";
import accessUtils, { CREATELOCATIONS } from "../../utils/accessUtils";

interface CapsulesProps extends RouteComponentProps<{}, {}, {}> {}
interface CapsulesState extends PaginationState {
  searchQuery: string;
  calculationQuantity: string;
}

const CONSTRUCTORNAME = "Capsules";

class Capsules extends PureComponent<CapsulesProps, CapsulesState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: CapsulesProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const state = getComponentState(this.context, CONSTRUCTORNAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }

  /**
   * Get the default state
   * @returns {CapsulesState} state for the component
   */
  getDefaultState = () => {
    return {
      currentPage: 1,
      pageSize: 10,
      itemsCount: 50,
      searchQuery: "",
      calculationQuantity: "100000"
    } as CapsulesState;
  };

  /**
   * Filters all capsules by the given search query. The size, color and material is checked for matches.
   * @returns { Array<CapsulesDocument> } Filtered list of capsules
   */
  filterCapsules = () => {
    const { searchQuery } = this.state;
    const { capsules } = this.context;
    const query = searchQuery.toLowerCase();
    if (query !== "")
      return baseUtils.doFuseSearch(capsules, query, [
        "capsule_size",
        "capsule_color.en",
        "capsule_color.de",
        "capsule_material.de",
        "capsule_material.en"
      ]);
    return capsules;
  };
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ searchQuery: e.target.value, currentPage: 1 });
  handleReset = () => this.setState(this.getDefaultState());
  handleChangeCalculationQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value ? parseInt(e.target.value) : 0;
    if (!val && val !== 0) return;
    this.setState({ calculationQuantity: val.toString() });
  };

  render() {
    const { history } = this.props;
    const { calculationQuantity, currentPage, pageSize, searchQuery } = this.state;
    const { commodityproperties, suppliers } = this.context;
    const capFiltered = this.filterCapsules();
    const capsules: Array<CapsulesDocument> = paginate(capFiltered, currentPage, pageSize);

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand fa fa-avatar" />
            </span>
            <h3 className="kt-portlet__head-title">Capsules</h3>
            <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
              Reset
            </button>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <button
                onClick={() => {
                  history.goBack();
                }}
                className="btn btn-clean kt-margin-r-10"
              >
                <i className="la la-arrow-left" />
                <span className="kt-hidden-mobile">Back</span>
              </button>
              {accessUtils.canCreateData(CREATELOCATIONS.CAPSULE) && (
                <Link to="/create-capsule">
                  <button type="button" className="btn btn-brand btn-icon-sm">
                    <i className="flaticon2-plus" />
                    <span style={{ color: "white" }}>Add New</span>
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-form kt-form--label-right  kt-margin-b-10">
            <div className="row align-items-center">
              <div className="col-xl-8 order-2 order-xl-1">
                <div className="row">
                  <SearchBar onSearch={this.handleSearch} search={searchQuery} additionalSizeClasses="col-md-3" />
                  <div className="col-3">
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Calculate for"
                        onChange={this.handleChangeCalculationQuantity}
                        value={calculationQuantity}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">pcs</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded px-4">
            <Table>
              <thead>
                <tr>
                  <th style={{ width: "15%" }}>Capsule Size</th>
                  <th style={{ width: "10%" }}>Color</th>
                  <th style={{ width: "20%" }}>Properties</th>
                  <th style={{ width: "15%" }}>Offers</th>
                  <th style={{ width: "15%" }}>Best Price</th>
                  <th style={{ width: "15%" }}>Fastest Delivery</th>
                  <th style={{ width: "10%" }}>Stock</th>
                </tr>
              </thead>
              <tbody className="kt-datatable__body">
                {capsules.map(capsule => {
                  const offers = capsule.suppliers.reduce((amount, s) => amount + s.prices.length, 0);
                  const bestPrice = capsuleUtils.getBestPrice(capsule, Number(calculationQuantity));
                  const fastestDelivery = capsuleUtils.getFastestDelivery(capsule, Number(calculationQuantity));
                  return (
                    <tr className="kt-datatable__row" key={capsule._id.toString()}>
                      <td className="align-middle">
                        <div className="kt-user-card-v2">
                          <div className="kt-user-card-v2__pic">
                            <div className="kt-badge kt-badge--xl kt-badge--primary">{capsule.capsule_size}</div>
                          </div>
                          <div className="kt-user-card-v2__details">
                            <span className="kt-user-card-v2__name">
                              <Link to={"/capsule/" + capsule._id.toString()} className="kt-user-card-v2__name kt-link">
                                {"Size: " + capsule.capsule_size}
                              </Link>
                            </span>
                            <span className="kt-user-card-v2__email">{capsule.capsule_material.en}</span>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">{capsule.capsule_color.en}</td>
                      <td className="align-middle">
                        {capsule.properties.map(p => (
                          <Badge key={p.toString()} className="m-1" pill variant="secondary">
                            {commodityproperties.find(cp => cp._id.toString() === p.toString())?.name.en}
                          </Badge>
                        ))}
                      </td>
                      <td className="align-middle">
                        <div className="kt-user-card-v2">
                          <div className="kt-user-card-v2__details">
                            <span className="kt-user-card-v2__name">
                              {offers} {offers > 1 ? "Offers" : "Offer"}
                            </span>
                            <span className="kt-user-card-v2__email">
                              from {capsule.suppliers.length} {capsule.suppliers.length > 1 ? "Suppliers" : "Supplier"}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">
                        <span>
                          <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__details">
                              <span className="kt-user-card-v2__name">
                                {bestPrice.supplier
                                  ? bestPrice.price.toLocaleString("de-DE", {
                                      style: "currency",
                                      currency: "EUR"
                                    })
                                  : "-"}
                              </span>
                              <span className="kt-user-card-v2__email kt-link">
                                {bestPrice.supplier &&
                                  suppliers.find(s => s._id.toString() === bestPrice.supplier?.toString())?.name}
                              </span>
                            </div>
                          </div>
                        </span>
                      </td>
                      <td className="align-middle">
                        <div className="kt-user-card-v2">
                          <div className="kt-user-card-v2__details">
                            <span className="kt-user-card-v2__name">
                              {fastestDelivery.supplier
                                ? fastestDelivery.days + " " + (fastestDelivery.days > 1 ? "Days" : "Day")
                                : "No supplier available"}
                            </span>
                            <span className="kt-user-card-v2__email kt-link">
                              {suppliers.find(s => s._id.toString() === fastestDelivery.supplier?.toString())?.name}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">Not implemented yet</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
              <Pagination
                itemsCount={capFiltered.length}
                pageSize={pageSize}
                onPageChange={currentPage => this.setState({ currentPage })}
                currentPage={currentPage}
                onPageSizeChange={pageSize => this.setState({ currentPage: 1, pageSize })}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Capsules);
