import _ from "lodash";
import React, { PureComponent } from "react";
import { LocalStock } from "../CustomTypes";
import CommodityLocalStock from "../CommodityLocalStock";
import { DataContext } from "../../../context/dataContext";
import { CommodityBatch, CommoditiesDocument } from "../../../model/commodities.types";
import { COMMODITIES } from "../../../services/dbService";
import commodityUtils from "../../../utils/commodityUtils";
import toastUtils from "../../../utils/toastUtils";
import manufacturerUtils from "../../../utils/manufacturerUtils";

interface CommodityStockProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
}

interface CommodityStockState {
  commodityEdited: CommoditiesDocument;
  manufacturer: string;
  manufacturerLocked: boolean;
  stocks: Array<LocalStock>;
  showDisabledStock: boolean;
  disabledStocks: Array<LocalStock>;
}

class CommodityStock extends PureComponent<CommodityStockProps, CommodityStockState> {
  constructor(props: CommodityStockProps) {
    super(props);
    const userManufacturer = manufacturerUtils.checkCurrentUserManufacturerObject(this.props.context.manufacturers);
    const revi = props.context.manufacturers.find(m => m._id.toString() === "5ef4fd72a4cf4fbc3202f609");
    this.state = {
      commodityEdited: _.cloneDeep(props.commodity),
      manufacturer:
        userManufacturer !== ""
          ? userManufacturer.value
          : revi
          ? revi._id.toString()
          : props.context.manufacturers[0]._id.toString(),
      manufacturerLocked: userManufacturer !== "",
      stocks: [],
      showDisabledStock: false,
      disabledStocks: []
    };
  }

  componentDidMount() {
    this.calculateGlobalStock();
  }

  componentDidUpdate(
    prevProps: Readonly<CommodityStockProps>,
    prevState: Readonly<CommodityStockState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.commodity, this.props.commodity)) {
      this.setState({ commodityEdited: _.cloneDeep(this.props.commodity) });
    } else if (prevProps !== this.props || !_.isEqual(prevState.commodityEdited, this.state.commodityEdited))
      this.calculateGlobalStock();
  }

  /**
   * Handles the update of the stock of the commodity.
   * @param stock: Stock after update
   * @param saveChanges: If True the changes are saved to database
   */
  handleUpdateStock = async (stock: Array<CommodityBatch>, saveChanges: boolean) => {
    const commodityEdited = _.cloneDeep(this.state.commodityEdited);
    commodityEdited.stock = stock;
    this.setState({ commodityEdited });
    if (saveChanges) await this.handleSaveData(commodityEdited);
  };

  /**
   * Handles the change of the selected manufacturer.
   * @param manufacturer: ID of the manufacturer as string
   */
  handleManufacturerChange = (manufacturer: string) => {
    this.setState({ manufacturer });
  };

  /**
   * Calculates the global stock values for all stocks and every single manufacturer.
   */
  calculateGlobalStock = () => {
    const { context } = this.props;
    const { commodityEdited } = this.state;
    const { manufacturers } = context;
    const stocks = [];
    const disabledStocks = [];
    for (let i = 0; i < manufacturers.length; i++) {
      const m = manufacturers[i];
      const stock = [];
      const disabledStock = [];
      for (let j = 0; j < commodityEdited.stock.length; j++) {
        const b = commodityEdited.stock[j];
        if (m._id.toString() === b.location.toString() && !b.disabled) {
          stock.push(b);
        } else if (m._id.toString() === b.location.toString() && b.disabled) {
          disabledStock.push(b);
        }
      }
      stocks.push({ manufacturer: m, localStock: stock });
      disabledStocks.push({ manufacturer: m, localStock: disabledStock });
    }
    this.setState({ stocks, disabledStocks });
  };

  /**
   * Handles resetting the data.
   */
  handleResetData = () => {
    this.setState({ commodityEdited: _.cloneDeep(this.props.commodity) });
  };

  /**
   * Handles saving the stock data to the database.
   * @param commodityPost: Optional, needed for saving right after a state update when the state might not be
   * updated yet.
   */
  handleSaveData = async (commodityPost?: CommoditiesDocument) => {
    const { commodity, context } = this.props;
    const { commodityEdited } = this.state;
    const { updateDocumentInContext } = context;
    const res = await commodityUtils.updateCommodityWithTimeline(
      commodity,
      commodityPost ? commodityPost : commodityEdited
    );
    await toastUtils.databaseOperationToast(!!res, "Stock updated successfully", "Error updating stock", () =>
      updateDocumentInContext(COMMODITIES, commodity._id)
    );
  };

  render() {
    const { context } = this.props;
    const { commodityEdited, manufacturer, stocks, showDisabledStock, manufacturerLocked, disabledStocks } = this.state;
    const localStock = stocks.find(s => s.manufacturer._id.toString() === manufacturer);
    const localDisabledStock = disabledStocks.find(s => s.manufacturer._id.toString() === manufacturer);
    return (
      <>
        <div className="my-4 px-2 pt-2 font-weight-bolder text-dark row">
          <div className="col-6 align-self-center">
            <div className="kt-widget__label">
              <div className="h4 text-dark" style={{ color: "rgb(72, 70, 91)" }}>
                {context.manufacturers.find(m => m._id.toString() === manufacturer)?.name}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-auto my-auto">
                <label className="kt-checkbox kt-font-dark m-0 pl-4">
                  <input
                    type="checkbox"
                    onChange={() => this.setState({ showDisabledStock: !showDisabledStock })}
                    checked={showDisabledStock}
                  />
                  <span />
                  <div className="pl-1 kt-font-dark">Show disabled stock</div>
                </label>
              </div>
              <div className="col">
                <select
                  value={manufacturer}
                  className="form-control"
                  disabled={manufacturerLocked}
                  onChange={manufacturerLocked ? undefined : e => this.handleManufacturerChange(e.target.value)}
                >
                  {context.manufacturers.map(m => (
                    <option key={m._id.toString()} value={m._id.toString()}>
                      {m.name +
                        " - " +
                        commodityUtils.resolveStockUnit(
                          stocks
                            ?.find(s => s.manufacturer._id.toString() === m._id.toString())
                            ?.localStock.reduce((sum, b) => sum + (b.amount > 0 && !b.disabled ? b.amount : 0), 0) || 0,
                          commodityEdited.type
                        )}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        {localStock && (
          <CommodityLocalStock
            localStock={localStock}
            localDisabledStock={localDisabledStock}
            commodity={commodityEdited}
            onStockUpdate={this.handleUpdateStock}
            context={context}
            showDisabledStock={showDisabledStock}
          />
        )}
        <div className="kt-separator kt-separator--space-lg kt-separator--fit kt-separator--border-solid" />
        <div className="float-right">
          <button className="btn btn-secondary mr-2" onClick={this.handleResetData}>
            Reset
          </button>
          <button className="btn btn-success" onClick={() => this.handleSaveData()}>
            Save changes
          </button>
        </div>
      </>
    );
  }
}

export default CommodityStock;
