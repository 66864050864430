import React, { useCallback } from "react";
import { getBBDColor } from "../../../../utils/warehouseUtils";
import baseUtils from "../../../../utils/baseUtils";
import { Batch } from "../../../../model/warehouse/batch.types";
import { useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { WarehouseActionNumber } from "../../../../utils/warehouseActionUtils";

interface BBDInformationProps {
  batch: Batch;
}

const BBDInformation: React.FC<BBDInformationProps> = ({ batch }) => {
  const dispatch = useWarehouseDispatch();

  const handleTriggerAction = useCallback(() => {
    dispatch({
      type: WarehouseActionType.TRIGGER_ACTION,
      payload: {
        actionNumber: WarehouseActionNumber.CHANGE_BDD,
        batchId: batch._id.toString()
      }
    });
  }, [batch._id]);

  return (
    <div className="kt-user-card-v2 pointer" onClick={handleTriggerAction}>
      <div className="kt-user-card-v2__details">
        <span className={"kt-user-card-v2__name font-weight-300 " + getBBDColor(batch.expiry)}>
          <span>{baseUtils.formatDate(batch.expiry)}</span>
        </span>
      </div>
    </div>
  );
};

export default BBDInformation;
