import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { OrdersDocument } from "../../../model/orders.types";
import { CompaniesDocument } from "../../../model/companies.types";
import { PackagingsDocument } from "../../../model/packagings.types";
import orderUtils from "../../../utils/orderUtils";
import { ManufacturersDocument } from "../../../model/manufacturers.types";

interface MissingLabelsProps {
  orders: Array<OrdersDocument>;
  companies: Array<CompaniesDocument>;
  manufacturers: Array<ManufacturersDocument>;
  packagings: Array<PackagingsDocument>;
}

interface MissingLabelsState {}

class MissingLabels extends PureComponent<MissingLabelsProps, MissingLabelsState> {
  render() {
    const { orders, companies, packagings, manufacturers } = this.props;
    return (
      <div className="kt-portlet" style={{ height: "500px" }}>
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">Fehlende Druckdateien</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit m-1 mb-2" style={{ overflowY: "scroll" }}>
          <div className="kt-widget27__container kt-portlet__space-x mt-3">
            <div className="tab-content">
              <div className="tab-pane active">
                <div className="kt-widget11">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <td>Auftrag</td>
                          <td>Status</td>
                          <td className="kt-align-right">Produktion</td>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map(order => {
                          const printingFileReq = orderUtils.getPrintingFileReq(order, packagings);
                          // Only display orders with missing labels
                          if (printingFileReq.required <= printingFileReq.uploaded) return;
                          const company = companies.find(c => c._id.toString() === order.createdFor.toString());
                          const manufacturer = manufacturers.find(
                            m => m._id.toString() === order.settings.manufacturer.toString()
                          );
                          return (
                            <MissingLabelsEntry
                              key={order._id.toString()}
                              order={order}
                              company={company}
                              manufacturer={manufacturer}
                              requirement={printingFileReq}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface MissingLabelsEntryProps {
  order: OrdersDocument;
  company?: CompaniesDocument;
  manufacturer?: ManufacturersDocument;
  requirement: { required: number; uploaded: number };
}

const MissingLabelsEntry: React.FunctionComponent<MissingLabelsEntryProps> = ({
  order,
  company,
  requirement,
  manufacturer
}) => {
  return (
    <tr>
      <td>
        <Link to={"/order/" + order._id.toString()} className="kt-user-card-v2__name kt-link kt-font-bolder">
          {"Order AT-" + order.identifier}
        </Link>
        {company ? (
          <Link to={"/company/" + company._id.toString()} className="kt-widget11__sub kt-font-dark">
            {company.name}
          </Link>
        ) : (
          <span className="kt-widget11__sub kt-font-dark">Unbekannt</span>
        )}
      </td>
      <td>
        {requirement.required <= requirement.uploaded ? (
          <span className="text-success kt-font-bold"> Alle Dateien hochgeladen</span>
        ) : requirement.required === 0 ? (
          <span> Keine Dateien benötigt</span>
        ) : (
          <React.Fragment>
            <span className="text-danger kt-font-bold">
              {requirement.required - requirement.uploaded === 1
                ? requirement.required - requirement.uploaded + " Datei ausstehend"
                : requirement.required - requirement.uploaded + " Dateien ausstehend"}
            </span>
            <br />
            <span>{requirement.uploaded + " von " + requirement.required} hochgeladen</span>
          </React.Fragment>
        )}
      </td>
      <td className="kt-align-right kt-font-brand kt-font-bold kt-font-dark">
        {manufacturer ? manufacturer.name : "Unbekannt"}
      </td>
    </tr>
  );
};

export default MissingLabels;
