import React, { useMemo } from "react";
import { BookOutPackagingUnit } from "../../../../../model/warehouse/customTypes.types";
import i18n from "../../../../../translations/i18n";
import { formatNumValue } from "../../../../../utils/baseUtils";
import { resolveTranslation } from "../../../../../utils/translationUtils";
import { getFormattedPackagingUnitTotalAmount } from "../../../../../utils/warehouseUtils";
import { BookOutSpecificLocation } from "../BookOutOverview";

interface BookOutOverviewSpecificLocationProps {
  specificLocation: BookOutSpecificLocation;
}

export const BookOutOverviewSpecificLocation: React.FC<BookOutOverviewSpecificLocationProps> = ({
  specificLocation
}) => {
  const { storageSpace, packagingUnits } = specificLocation;
  return (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive mt-2 mb-0">
      <table className="kt-datatable__table d-table ">
        <tbody className="kt-datatable__body " style={{ display: "table-row-group" }}>
          <tr className="kt-datatable__row d-table-row bg-light">
            <td className="kt-datatable__cell d-table-cell" colSpan={10}>
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details ">
                  <span className={"kt-user-card-v2__name text-black"}>
                    {storageSpace
                      ? `${i18n.t("warehouse:storageSpace")} ${storageSpace.storageSpaceNo}`
                      : i18n.t("warehouse:entrance")}
                  </span>
                </div>
              </div>
            </td>
          </tr>
          <tr className="kt-datatable__row d-table-row border-0">
            <td className="kt-datatable__cell d-table-cell px-0 pb-0" colSpan={10}>
              <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive px-2 mb-0">
                <table className="kt-datatable__table d-table p-5">
                  <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
                    <tr className="kt-datatable__row d-table-row">
                      <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "20%" }}>
                        <span>{i18n.t("common:description")}</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "20%" }}>
                        <span>{i18n.t("common:quantity")}</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "20%" }}>
                        <span>{i18n.t("warehouse:totalAmount")}</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "20%" }}>
                        <span>{i18n.t("warehouse:newQuantity")}</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "20%" }}>
                        <span>{i18n.t("warehouse:restAmount")}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                    {packagingUnits.map((pU, idx) => (
                      <BookOutOverviewSpecificLocationPU
                        key={pU._id.toString()}
                        packagingUnit={pU}
                        noBorder={idx === packagingUnits.length - 1}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

interface BookOutOverviewSpecificLocationPUProps {
  packagingUnit: BookOutPackagingUnit;
  noBorder?: boolean;
}

const BookOutOverviewSpecificLocationPU: React.FC<BookOutOverviewSpecificLocationPUProps> = ({
  packagingUnit,
  noBorder
}) => {
  const totalAmount = useMemo(
    () => packagingUnit.amountPerPu.value * (packagingUnit.quantity || 0),
    [packagingUnit.amountPerPu, packagingUnit.quantity]
  );

  const amountLeft = useMemo(
    () =>
      totalAmount -
      ((packagingUnit.quantity || 0) - +packagingUnit.adjustedValues.quantity) * packagingUnit.amountPerPu.value,
    [totalAmount, packagingUnit.adjustedValues, packagingUnit.amountPerPu]
  );

  return (
    <tr className={"kt-datatable__row d-table-row " + (noBorder && "border-0")}>
      <td className="kt-datatable__cell d-table-cell py-1">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <span className={"kt-user-card-v2__name text-black "}>
              {formatNumValue(packagingUnit.amountPerPu)} {resolveTranslation(packagingUnit.puSnapshot.label)}
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell py-1">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <span className={"kt-user-card-v2__name text-black font-weight-300"}>{packagingUnit.quantity || "-"}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell py-1">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <span className={"kt-user-card-v2__name text-black "}>
              {getFormattedPackagingUnitTotalAmount(packagingUnit)}
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell py-1">
        <input
          type={"number"}
          min={0}
          disabled
          className={"form-control form-control-solid hide-arrows"}
          value={packagingUnit.adjustedValues.quantity}
        />
      </td>
      <td className="kt-datatable__cell d-table-cell py-1">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <span className="kt-user-card-v2__name text-black">
              {formatNumValue({ value: amountLeft, unit: packagingUnit.amountPerPu.unit }, 2)}
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
};
