import React, { PureComponent } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageUploader from "react-images-upload";
import { BSON } from "realm-web";
import accessUtils, { CREATELOCATIONS, EDITLOCATIONS } from "../../utils/accessUtils";
import baseUtils from "../../utils/baseUtils";

interface CreateNewSettingValueModalProps {
  onSave: (_id: BSON.ObjectId, de: string, en: string, additionalField?: string) => Promise<void>;
  title: string;
  edit: boolean;
  _id?: BSON.ObjectId;
  name?: { de: string; en: string };
  additionalField?: { title: string; value: string; type?: "number" | "text"; append?: string | JSX.Element };
  additionalSelectField?: Array<string>;
  additionalImageField?: boolean;
}

interface CreateNewSettingValueModalState {
  de: string;
  en: string;
  show: boolean;
  savingData: boolean;
  additionalValue?: string;
}

class CreateNewSettingValueModal extends PureComponent<
  CreateNewSettingValueModalProps,
  CreateNewSettingValueModalState
> {
  constructor(props: CreateNewSettingValueModalProps) {
    super(props);
    this.state = {
      de: props.name ? props.name.de : "",
      en: props.name ? props.name.en : "",
      show: false,
      savingData: false,
      additionalValue: props.additionalField ? props.additionalField.value : ""
    };
  }

  handleShow = () => {
    const { name } = this.props;
    this.setState({ de: name ? name.de : "", en: name ? name.en : "", show: true });
  };
  handleClose = () => this.setState({ show: false });

  /**
   * Handles saving a new settings value. Closes the modal afterwards.
   */
  handleSave = async () => {
    const { onSave, _id } = this.props;
    const { de, en, additionalValue } = this.state;
    this.setState({ savingData: true });
    await onSave(_id ? _id : new BSON.ObjectId(), de, en, additionalValue);
    this.setState({ show: false, savingData: false });
  };

  /**
   * Handles the change of the additional field.
   * @param e: Event that triggered the change
   */
  handleChangeAdditionalField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { additionalField } = this.props;
    if (!additionalField) return;
    let val = e.target.value;
    if (additionalField.type === "number") {
      val = val.replaceAll(/^0+/g, "0");
      if (!val.includes(".")) val = Number(val).toString();
      if (isNaN(Number(val))) return;
    }
    this.setState({ additionalValue: val });
  };

  /**
   * Check the input fields of the names for missing data
   * @returns { Array<string> } Errors found
   */
  checkForErrors = () => {
    const { de, en } = this.state;
    const errors = [];
    if (!de) errors.push("German name required");
    if (!en) errors.push("English name required");
    return errors;
  };

  renderAdditionalField = () => {
    const { additionalField, additionalSelectField, additionalImageField } = this.props;
    const { additionalValue, en } = this.state;
    if (!additionalField) return;
    return (
      <>
        <div className="row my-2">
          <div className="col-4 text-dark align-self-center">{additionalField.title}</div>
          <div className="col-8">
            {additionalSelectField ? (
              <select
                className="form-control"
                onChange={e => this.setState({ additionalValue: e.target.value })}
                value={additionalValue}
              >
                {additionalSelectField.map(a => {
                  return (
                    <option key={a} value={a}>
                      {a}
                    </option>
                  );
                })}
              </select>
            ) : additionalImageField ? (
              <ImageUploader
                withIcon={false}
                withLabel={false}
                buttonText={"Upload image"}
                imgExtension={[".jpg", ".png", ".jpeg"]}
                maxFileSize={104857600}
                singleImage
                errorClass="px-3"
                fileContainerStyle={{
                  boxShadow: "None",
                  background: "None",
                  padding: "0",
                  margin: "0 auto"
                }}
                buttonClassName="w-100"
                fileSizeError={"Image too large. Max 10 MB"}
                fileTypeError={"Image has wrong format. Only .jpg and .png are allowed"}
                onChange={files =>
                  baseUtils.handleFileUpload(files, en, image => this.setState({ additionalValue: image }), false)
                }
              />
            ) : (
              <div className="input-group">
                <input
                  className="form-control"
                  value={additionalValue ? additionalValue : "0"}
                  onChange={this.handleChangeAdditionalField}
                  type={additionalField.type ? additionalField.type : "text"}
                />
                {additionalField.append && (
                  <div className="input-group-append">
                    <span className="input-group-text">{additionalField.append}</span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {additionalImageField && additionalValue && (
          <div className="row my-2">
            <div className="col-4" />
            <div className="col-8 text-center">
              <img src={"https://mediahub.private-label-factory.com/" + additionalValue} style={{ width: "100px" }} />
            </div>
          </div>
        )}
      </>
    );
  };

  render() {
    const { edit, title } = this.props;
    const { de, en, show, savingData } = this.state;
    const errors = this.checkForErrors();
    const canClickButton = edit
      ? accessUtils.canEditData(EDITLOCATIONS.GENERALSETTINGS)
      : accessUtils.canCreateData(CREATELOCATIONS.GENERALSETTINGS);

    return (
      <>
        <button
          className={"btn " + (edit ? "btn-secondary" : "btn-primary") + (canClickButton ? "" : " disabled")}
          onClick={canClickButton ? this.handleShow : undefined}
          disabled={!canClickButton}
        >
          <i className={edit ? "fa fa-pen" : " fas fa-plus-circle"} />
          {edit ? "Edit" : "Create"}
        </button>
        <Modal show={show} centered onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {edit ? "Edit" : "Create new"} {title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row my-2">
              <div className="col-4 text-dark align-self-center">German</div>
              <div className="col-8">
                <input className="form-control" value={de} onChange={e => this.setState({ de: e.target.value })} />
              </div>
            </div>
            <div className="row my-2">
              <div className="col-4 text-dark align-self-center">English</div>
              <div className="col-8">
                <input className="form-control" value={en} onChange={e => this.setState({ en: e.target.value })} />
              </div>
            </div>
            {this.renderAdditionalField()}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            {errors.length > 0 ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id={"commodity-invalid"}>
                    {errors.map((e, key) => {
                      return (
                        <React.Fragment key={key}>
                          <span className="text-danger">
                            <b>{e}</b>
                          </span>
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </Tooltip>
                }
                placement={"left"}
              >
                <button className="btn btn-success disabled">Save</button>
              </OverlayTrigger>
            ) : (
              <button className="btn btn-success" onClick={this.handleSave}>
                {savingData ? "Saving..." : "Save"}
              </button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateNewSettingValueModal;
