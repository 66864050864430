import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";
import ExternalManufacturerCommodity from "./ExternalManufacturerCommodity";

interface EMCommodityParams {
  id: string;
}

interface EMCommodityWrapperProps extends RouteComponentProps<EMCommodityParams, {}, {}> {}

const EmCommodityWrapper: React.FunctionComponent<EMCommodityWrapperProps> = props => {
  const context = useContext(ExternalManufacturerContext);
  return <ExternalManufacturerCommodity {...props} context={context} />;
};

export default EmCommodityWrapper;
