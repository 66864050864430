import React, { ChangeEvent, PureComponent } from "react";
import { toast } from "react-toastify";
import { CustomOrder } from "./CustomTypes";
import dbOrderService from "../../services/dbServices/dbOrderService";
import { SanitizedHTML } from "../common/SanitizedHTML";

interface OrderNoteProps {
  order: CustomOrder;
}

interface OrderNoteState {
  edit: boolean;
  note: string;
}

class OrderNote extends PureComponent<OrderNoteProps, OrderNoteState> {
  constructor(props: OrderNoteProps) {
    super(props);
    this.state = { edit: false, note: props.order.note };
  }

  componentDidUpdate(prevProps: Readonly<OrderNoteProps>, prevState: Readonly<OrderNoteState>, snapshot?: any) {
    if (prevProps.order.note !== this.props.order.note) this.setState({ note: this.props.order.note });
  }

  updateNote = async () => {
    const { order } = this.props;
    const { note } = this.state;
    try {
      const result = await dbOrderService.updateNote(order._id, note.trim());
      if (result && result.modifiedCount) toast.success("Note successfully updated");
      else toast.error("Note could not be updated");
    } catch (e) {
      toast.error("An unexpected error occurred: " + e.message);
    }
  };

  handleButton = () => {
    const { edit } = this.state;
    if (edit) this.updateNote();
    this.setState({ edit: !edit });
  };

  handleNote = (e: ChangeEvent<HTMLTextAreaElement>) => this.setState({ note: e.target.value });

  render() {
    const { edit, note } = this.state;
    return (
      <div className="kt-portlet kt-portlet--head-noborder">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Order Information</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit-top">
          {!edit ? (
            <div className="kt-section kt-section--space-sm">
              <span
                className={note ? "text-danger kt-font-bolder" : ""}
                style={{ fontSize: "1.1rem", whiteSpace: "pre-wrap" }}
              >
                {note ? (
                  <SanitizedHTML
                    html={note.replaceAll(/(\B@[a-zA-Z]+)/g, "<span class='text-info'><em><u>$&</u></em></span>")}
                  />
                ) : (
                  "Important information about the offer can be stored here so that this information is visible to everyone."
                )}
              </span>
            </div>
          ) : (
            <div className="form-group form-group-last mb-3">
              <textarea className="form-control" id="noteTextarea" rows={2} onChange={this.handleNote} value={note} />
            </div>
          )}
          <div className="kt-section kt-section--last">
            <button type="button" onClick={this.handleButton} className="btn btn-label-brand btn-sm btn-bold">
              {edit ? "Save Information" : "Edit Information"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderNote;
