import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";
import EmDashboardOrders from "./EMDashboardOrders";
import EMDashboardActivities from "./EMDashboardActivities";
import EmDashboardCommodities from "./EMDashboardCommodities";

interface DashboardProps extends RouteComponentProps<{}, {}, {}> {}

interface DashboardState {}

class ExternalManufacturerDashboard extends PureComponent<DashboardProps, DashboardState> {
  static contextType = ExternalManufacturerContext;
  context!: React.ContextType<typeof ExternalManufacturerContext>;
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12 col-xl-6">
            <EmDashboardCommodities context={this.context} {...this.props} />
          </div>
          <div className="col-12 col-xl-6">
            <EmDashboardOrders context={this.context} {...this.props} request={true} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-6">
            <EmDashboardOrders context={this.context} {...this.props} />
          </div>
          <div className="col-12 col-xl-6">
            <EMDashboardActivities context={this.context} {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

export default ExternalManufacturerDashboard;
