import _ from "lodash";
import React from "react";
import { ExtendedEMCommodity } from "../../../model/customTypes.types";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";
import EmCommodityRelatedOrder from "./EMCommodityRelatedOrder";

interface EmCommodityRelatedOrdersProps {
  commodity: ExtendedEMCommodity;
  context: React.ContextType<typeof ExternalManufacturerContext>;
}

const EmCommodityRelatedOrders: React.FunctionComponent<EmCommodityRelatedOrdersProps> = ({ commodity, context }) => {
  const { externalManufacturerOrders } = context;
  const orders = _.orderBy(
    externalManufacturerOrders.filter(emo => emo.commodityId.toString() === commodity._id.toString()),
    o => o.createdOn,
    "desc"
  );
  return (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            <i className="flaticon-notepad mr-2" style={{ color: "#a2a5b9" }} />
            Related Requests & Orders
          </h3>
        </div>
        <div className="kt-portlet__head-toolbar" />
      </div>
      <div className="kt-form kt-form--label-right">
        <div className="kt-portlet__body" style={{ minHeight: "50vh" }}>
          {orders && orders.length > 0 ? (
            <div
              className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive"
              style={{ maxHeight: "70vh" }}
            >
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}>Order</th>
                    <th style={{ width: "10%" }}>Created</th>
                    <th style={{ width: "10%" }}>Status</th>
                    <th style={{ width: "10%" }}>Amount</th>
                    <th style={{ width: "12%" }}>Price</th>
                    <th style={{ width: "13%" }}>Expected Delivery</th>
                    <th style={{ width: "12%" }}>Limit Kilo Price</th>
                    <th style={{ width: "13%" }}>Latest Delivery Date</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map(emo => (
                    <EmCommodityRelatedOrder key={emo._id.toString()} order={emo} />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center text-muted my-5">
              No requests were created yet for commodity '{commodity.title.en}'
              <br />
              <br />
              <br />
              <button
                className={"btn btn-primary btn-sm "}
                onClick={
                  commodity.amount
                    ? () => {
                        const orderButton = document.getElementById("headerOrderCommodityButton");
                        if (orderButton) orderButton.click();
                      }
                    : undefined
                }
              >
                Request Commodity
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmCommodityRelatedOrders;
