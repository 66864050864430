import _ from "lodash";
import { Link } from "@material-ui/core";
import React from "react";
import i18n from "../../translations/i18n";

interface paginationProps {
  itemsCount: number;
  pageSize: number;
  onPageChange: (page: number) => any;
  currentPage: number;
  onPageSizeChange?: (size: number) => any;
  baseSize?: number;
  additionalNavClasses?: string;
  additionalPageSizeClasses?: string;
  compact?: boolean;
}

const Pagination: React.FunctionComponent<paginationProps> = ({
  itemsCount,
  pageSize,
  onPageChange,
  currentPage,
  onPageSizeChange,
  baseSize,
  additionalNavClasses,
  additionalPageSizeClasses,
  compact
}) => {
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth
  });

  // Handle resizing of the window
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth
      });
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (itemsCount === 0) return null;

  // Show less options on smaller screens
  const availWidth = dimensions.width;
  let rangePerSide = 6;
  if (availWidth < 400) {
    rangePerSide = 1;
  } else if (availWidth < 600) {
    rangePerSide = 2;
  } else if (availWidth < 800) {
    rangePerSide = 4;
  }
  let pages = _.range(1, pagesCount + 1);
  if (pagesCount > rangePerSide * 2 - 1) {
    const defaultMax = Math.max(1, currentPage - rangePerSide);
    const defaultMin = Math.min(currentPage + rangePerSide, pagesCount);
    const range = defaultMax - defaultMin + rangePerSide * 2;
    if (range > 0 && currentPage < rangePerSide * 2) {
      pages = _.range(defaultMax, Math.min(currentPage + rangePerSide + range, pagesCount + 1));
    } else if (range > 0) {
      pages = _.range(Math.max(1, currentPage - rangePerSide - range), defaultMin);
    } else {
      pages = _.range(defaultMax, defaultMin);
    }

    // Check that there are always at least 2 additional pages shown on first page
    if (currentPage === 1 && pages[pages.length - 1] === currentPage + 1 && pagesCount > currentPage + 1) {
      pages = pages.concat([currentPage + 2]);
      // Check that when not on last page the next page is always shown
    } else if (pages[pages.length - 1] === currentPage && currentPage !== pagesCount) {
      pages = pages.concat([currentPage + 1]);
      // Check that when on last page this page is always shown
    } else if (pages[pages.length - 1] === currentPage - 1 && currentPage === pagesCount) {
      pages = pages.concat([currentPage]);
    }
  }

  if (!baseSize) {
    baseSize = 10;
  }

  let actualPageSize = pageSize < itemsCount ? pageSize : itemsCount;
  if (currentPage === pagesCount && pagesCount > 1) {
    actualPageSize = itemsCount - pageSize * (pagesCount - 1);
  }

  return (
    <>
      <ul className={"kt-datatable__pager-nav " + (additionalNavClasses ? additionalNavClasses : "")}>
        <li>
          <Link
            onClick={() => onPageChange(1)}
            title="First"
            className={
              currentPage === 1
                ? "kt-datatable__pager-link kt-datatable__pager-link--first kt-datatable__pager-link--disabled"
                : "kt-datatable__pager-link kt-datatable__pager-link--first "
            }
          >
            <i className="flaticon2-fast-back" />
          </Link>
        </li>
        <li>
          <Link
            onClick={() => onPageChange(currentPage === 1 ? 1 : currentPage - 1)}
            title="Previous"
            className={
              currentPage > 1
                ? "kt-datatable__pager-link kt-datatable__pager-link--prev"
                : "kt-datatable__pager-link kt-datatable__pager-link--prev kt-datatable__pager-link--disabled"
            }
          >
            <i className="flaticon2-back" />
          </Link>
        </li>

        {!compact && pagesCount > 10 && !pages.find(e => e === 2) && (
          <li>
            <Link className="kt-datatable__pager-link kt-datatable__pager-link-number" onClick={() => onPageChange(2)}>
              ...
            </Link>
          </li>
        )}
        {!compact &&
          pages.map(page => (
            <li key={page.toString()}>
              <Link
                key={page}
                className={
                  page === currentPage
                    ? "kt-datatable__pager-link kt-datatable__pager-link-number kt-datatable__pager-link--active"
                    : "kt-datatable__pager-link kt-datatable__pager-link-number"
                }
                data-page={page}
                title={page.toString()}
                onClick={() => onPageChange(page)}
              >
                {page}
              </Link>
            </li>
          ))}
        {!compact && pagesCount > 10 && !pages.find(e => e === pagesCount) && (
          <>
            {!pages.find(e => e === pagesCount - 1) && (
              <li>
                <Link
                  className="kt-datatable__pager-link kt-datatable__pager-link-number"
                  onClick={() => onPageChange(pagesCount - 1)}
                >
                  ...
                </Link>
              </li>
            )}
            <li>
              <Link
                className={
                  "kt-datatable__pager-link kt-datatable__pager-link-number " +
                  (pagesCount === currentPage ? "kt-datatable__pager-link--active" : "")
                }
                onClick={() => onPageChange(pagesCount)}
              >
                {pagesCount}
              </Link>
            </li>
          </>
        )}
        <li>
          <Link
            onClick={() => onPageChange(currentPage === pagesCount ? pagesCount : currentPage + 1)}
            title="Next"
            className={
              currentPage < pagesCount
                ? "kt-datatable__pager-link kt-datatable__pager-link--next"
                : "kt-datatable__pager-link kt-datatable__pager-link--next kt-datatable__pager-link--disabled"
            }
          >
            <i className="flaticon2-next" />
          </Link>
        </li>
        <li>
          <Link
            onClick={() => onPageChange(pagesCount)}
            title="Last"
            className={
              currentPage === pagesCount
                ? "kt-datatable__pager-link kt-datatable__pager-link--last kt-datatable__pager-link--disabled"
                : "kt-datatable__pager-link kt-datatable__pager-link--last"
            }
          >
            <i className="flaticon2-fast-next" />
          </Link>
        </li>
      </ul>
      {onPageSizeChange && (
        <div className={"kt-datatable__pager-info " + (additionalPageSizeClasses ? additionalPageSizeClasses : "")}>
          <div className="dropdown bootstrap-select kt-datatable__pager-size">
            <select
              className="selectpicker kt-datatable__pager-size btn dropdown-toggle btn-light"
              title="Select page size"
              data-width="20px"
              data-container="body"
              data-selected={10}
              tabIndex={-98}
              value={pageSize}
              onChange={value => onPageSizeChange(Number(value.target.value))}
            >
              <option value={baseSize}>{baseSize}</option>
              <option value={baseSize * 2}>{baseSize * 2}</option>
              <option value={baseSize * 3}>{baseSize * 3}</option>
              <option value={baseSize * 5}>{baseSize * 5}</option>
              <option value={baseSize * 10}>{baseSize * 10}</option>
            </select>
          </div>
          <span className="kt-datatable__pager-detail">
            {i18n.t("common:showAmountOfAmount", { firstAmount: actualPageSize, secondAmount: itemsCount })}
          </span>
        </div>
      )}
      {compact && (
        <div className={"kt-datatable__pager-info " + (additionalPageSizeClasses ? additionalPageSizeClasses : "")}>
          <span className="kt-datatable__pager-detail">
            {i18n.t("common:showAmountOfAmount", {
              firstAmount: `${pageSize * (currentPage - 1) + 1}-${(currentPage - 1) * pageSize + actualPageSize}`,
              secondAmount: itemsCount
            })}
          </span>
        </div>
      )}
    </>
  );
};

export function paginate<T>(items: Array<T>, pageNumber: number, pageSize: number): Array<T> {
  const startIndex = (pageNumber - 1) * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
}

export default Pagination;
