import _ from "lodash";
import React, { useCallback, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import HistoryBackButton from "../listings/common/HistoryBackButton";
import DateInput from "../common/DateInput";
import dateUtils from "../../utils/dateUtils";
import { useStateWithCallback } from "../../utils/useStateCallback";
import baseUtils, { round } from "../../utils/baseUtils";
import fileUtils, { DEFAULT_SEPARATOR, purgeStringForCSV } from "../../utils/fileUtils";
import { DataContext } from "../../context/dataContext";
import { CommoditiesDocument, CommodityOrder } from "../../model/commodities.types";
import { OrdersDocument } from "../../model/orders.types";
import orderUtils from "../../utils/orderUtils";

interface CommodityData {
  commodity: CommoditiesDocument;
  commodityOrders: Array<{
    cOrder: CommodityOrder;
    orders: Array<OrdersDocument>;
    required: number;
    bookedOut: number;
    notYetBookedOut: number;
    expectedWaste: number;
    currentlyExpectedWaste: number;
    actualWaste: number | null;
    loss: number | null;
  }>;
}

interface CommodityWasteProps extends RouteComponentProps {}

const CommodityWaste: React.FunctionComponent<CommodityWasteProps> = ({ history }) => {
  const context = React.useContext(DataContext);
  const [period, setPeriod] = useState<{ start: Date; end: Date }>(dateUtils.getDefaultPeriod(new Date(2023, 0, 1)));
  const [generating, setGenerating] = useStateWithCallback<boolean>(false);

  const handlePeriodChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as "start" | "end";
    let value = e.target.valueAsDate;
    setPeriod(prevPeriod => {
      const newPeriod = _.cloneDeep(prevPeriod);
      if (!value) return prevPeriod;
      if (name === "start") value.setHours(0, -value.getTimezoneOffset(), 0, 0);
      else value.setHours(23, 59 + -value.getTimezoneOffset(), 59, 999);
      _.set(newPeriod, name, value);
      return newPeriod;
    });
  }, []);

  const handleGenerateCSV = useCallback(async () => {
    const { commodities } = context;
    const { start, end } = period;
    setGenerating(true, () => {
      try {
        const commodityList: Array<CommodityData> = [];
        for (let i = 0; i < commodities.length; i++) {
          const commodity = commodities[i];
          if (commodity.type || !commodity.orders || commodity.orders.length === 0) continue;

          const commodityData: CommodityData = { commodity, commodityOrders: [] };

          for (let j = 0; j < commodity.orders.length; j++) {
            const commodityOrder = commodity.orders[j];
            if (
              commodityOrder.orders.length === 0 ||
              !(start <= commodityOrder.created && commodityOrder.created <= end)
            )
              continue;

            const orders: Array<OrdersDocument> = commodityOrder.orders
              .map(o => baseUtils.getDocFromCollection(context.orders, o))
              .filter(o => !!o) as Array<OrdersDocument>;
            const required = orderUtils.getRequiredAmount(commodity, orders);
            const bookedOut = orderUtils.getBookedOutAmount(commodity, orders);
            const notYetBookedOut = orderUtils.getNotBookedOutAmount(commodity, orders);
            const actualWaste = notYetBookedOut > 0 ? null : commodityOrder.orderquantity - bookedOut;

            commodityData.commodityOrders.push({
              cOrder: commodityOrder,
              orders,
              required,
              bookedOut,
              notYetBookedOut,
              expectedWaste: commodityOrder.orderquantity - required,
              currentlyExpectedWaste: commodityOrder.orderquantity - (bookedOut + notYetBookedOut),
              actualWaste,
              loss: actualWaste ? actualWaste * (commodityOrder.totalPrice / commodityOrder.orderquantity) : null
            });
          }
          if (commodityData.commodityOrders.length > 0) commodityList.push(commodityData);
        }

        const csvData: Array<Array<string>> = [];
        commodityList.forEach(({ commodity, commodityOrders }) => {
          const comOrders = commodityOrders.slice();
          const firstEntry = comOrders.shift();
          if (!firstEntry) return;
          csvData.push([
            purgeStringForCSV(`${commodity.identifier} - ${commodity.title.en}`),
            `#1 - ${baseUtils.formatDate(firstEntry.cOrder.created)}`,
            `${round(firstEntry.cOrder.orderquantity).toLocaleString()}kg`,
            firstEntry.orders.map(o => `AT-${o.identifier}`).join(", "),
            `${round(firstEntry.required).toLocaleString()}kg`,
            `${round(firstEntry.bookedOut).toLocaleString()}kg`,
            `${round(firstEntry.notYetBookedOut).toLocaleString()}kg`,
            `${round(firstEntry.expectedWaste).toLocaleString()}kg`,
            `${round(firstEntry.currentlyExpectedWaste).toLocaleString()}kg`,
            firstEntry.actualWaste ? `${round(firstEntry.actualWaste).toLocaleString()}kg` : "N/A",
            firstEntry.loss ? baseUtils.formatEuro(firstEntry.loss) : "N/A"
          ]);
          comOrders.forEach((entry, key) => {
            const {
              cOrder,
              orders,
              required,
              bookedOut,
              notYetBookedOut,
              expectedWaste,
              currentlyExpectedWaste,
              actualWaste,
              loss
            } = entry;
            csvData.push([
              "",
              `#${key + 2} - ${baseUtils.formatDate(cOrder.created)}`,
              `${round(cOrder.orderquantity).toLocaleString()}kg`,
              orders.map(o => `AT-${o.identifier}`).join(", "),
              `${round(required).toLocaleString()}kg`,
              `${round(bookedOut).toLocaleString()}kg`,
              `${round(notYetBookedOut).toLocaleString()}kg`,
              `${round(expectedWaste).toLocaleString()}kg`,
              `${round(currentlyExpectedWaste).toLocaleString()}kg`,
              actualWaste ? `${round(actualWaste).toLocaleString()}kg` : "N/A",
              loss ? baseUtils.formatEuro(loss) : "N/A"
            ]);
          });
        });

        const headers = [
          "Commodity",
          "Commodity Order",
          "Ordered Amount",
          "Orders",
          "Required (by Recipe)",
          "Booked Out",
          "Not Yet Booked Out",
          "Expected Waste (Ordered Amount - Required)",
          "Currently Expected Waste (Ordered Amount - (Booked Out + Not Yet Booked Out))",
          "Actual Waste (Ordered Amount - Booked Out)",
          "Loss in €"
        ];
        const fileName = `Commodity_Waste${baseUtils.formatDate(start)}-${baseUtils.formatDate(end, undefined, {
          timeZone: "UTC"
        })}_${baseUtils.formatDate(new Date())}.csv`;

        const csv = fileUtils.exportAsCSV(headers, csvData, DEFAULT_SEPARATOR);
        fileUtils.downloadFile(csv, fileName, "text/plain");
      } finally {
        setGenerating(false);
      }
    });
  }, [context, period]);

  return (
    <div className="kt-portlet kt-portlet--mobile">
      <div className="kt-portlet__head kt-portlet__head--lg">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Commodity Waste</h3>-
        </div>
        <HistoryBackButton history={history} />
      </div>
      <div className="kt-portlet__body">
        <div className="row mt-2">
          <div className="col text-dark font-weight-bolder mb-5">
            Exports commodity orders with the amount that was actually required and booked out for orders as well as the
            amount that was left as "waste" at the end.
          </div>
        </div>
        <div className="row">
          <div className="col col-6 col-md-4 col-lg-3 align-self-center">From</div>
          <div className="col col-6 col-md-8 col-lg-3">
            <DateInput
              value={period.start}
              min={dateUtils.getDefaultPeriod().start}
              onBlur={handlePeriodChange}
              name={"start"}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col col-6 col-md-4 col-lg-3 align-self-center">To</div>
          <div className="col col-6 col-md-8 col-lg-3">
            <DateInput value={period.end} max={new Date()} onBlur={handlePeriodChange} name={"end"} />
          </div>
        </div>
      </div>
      <div className="kt-portlet__foot">
        <div className="float-right">
          <button
            className="btn btn-success"
            disabled={generating}
            onClick={generating ? undefined : handleGenerateCSV}
          >
            {generating ? "Generating..." : "Generate CSV"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommodityWaste;
