import React, { useCallback, useMemo } from "react";
import { BSON } from "realm-web";
import { Typeahead } from "react-bootstrap-typeahead";
import { AvisDescriptionOption, AvisRow, AvisUnitOptions, OrderWithMaterial } from "../../utils/avisUtils";
import { CommoditiesDocument } from "../../model/commodities.types";
import { PackagingsDocument } from "../../model/packagings.types";
import { CompaniesDocument } from "../../model/companies.types";
import { SuppliersDocument } from "../../model/suppliers.types";
import { ManufacturersDocument } from "../../model/manufacturers.types";
import packagingUtils from "../../utils/packagingUtils";
import { AvisType } from "../../model/warehouse/deliveryAnnouncement.types";

interface AvisShipmentOverviewProps {
  avisRows: Array<AvisRow>;
  descriptionOptions: Array<AvisDescriptionOption>;
  company: CompaniesDocument | SuppliersDocument | ManufacturersDocument | null | undefined;
  onChangeRow: (
    id: BSON.ObjectId,
    field: string,
    value: string | OrderWithMaterial | CommoditiesDocument | PackagingsDocument
  ) => void;
  onAddRow: () => void;
  onRemoveRow: (_id: BSON.ObjectId) => void;
}

const AvisShipmentOverview: React.FC<AvisShipmentOverviewProps> = ({
  avisRows,
  descriptionOptions,
  company,
  onChangeRow,
  onAddRow,
  onRemoveRow
}) => {
  return (
    <div>
      <h1 className="mb-3 font-weight-bold text-dark">Shipment</h1>
      <p className="text-dark-50 mb-10 font-size-lg" style={{ fontSize: "1.2rem" }}>
        Select all goods you want to send in this shipment. Ensure the correct reference is used (AT-Numbers) so we can
        automatically identify your shipment. Proper identification speeds up the process and reduces errors.
      </p>
      {avisRows.map(row => (
        <AvisShipmentRow
          key={row._id.toString()}
          row={row}
          company={company}
          descriptionOptions={descriptionOptions}
          onChangeRow={onChangeRow}
          onRemoveRow={onRemoveRow}
        />
      ))}
      <p className="text-black float-right">
        <span className="kt-font-bold" onClick={onAddRow}>
          + Add Row
        </span>
      </p>
    </div>
  );
};

interface AvisShipmentRowProps {
  row: AvisRow;
  descriptionOptions: Array<AvisDescriptionOption>;
  company: CompaniesDocument | SuppliersDocument | ManufacturersDocument | null | undefined;
  onChangeRow: (
    id: BSON.ObjectId,
    field: string,
    value: string | OrderWithMaterial | CommoditiesDocument | PackagingsDocument
  ) => void;
  onRemoveRow: (_id: BSON.ObjectId) => void;
}

const AvisShipmentRow: React.FC<AvisShipmentRowProps> = ({
  row,
  descriptionOptions,
  company,
  onChangeRow,
  onRemoveRow
}) => {
  const handleRemoveRow = useCallback(() => onRemoveRow(row._id), [onRemoveRow]);

  const handleChangeOrderDescription = useCallback(
    (description: Array<AvisDescriptionOption>) => {
      if (!description[0]) {
        onChangeRow(row._id, "descriptionOrder", "");
        onChangeRow(row._id, "descriptionArticle", "");
      } else onChangeRow(row._id, "descriptionOrder", description[0].order);
    },
    [row, onChangeRow]
  );

  const handleChangeArticleDescription = useCallback(
    (description: Array<{ name: string; article: CommoditiesDocument | PackagingsDocument; type: AvisType }>) => {
      if (!description[0]) onChangeRow(row._id, "descriptionArticle", "");
      else onChangeRow(row._id, "descriptionArticle", description[0].article);
    },
    [row, onChangeRow]
  );

  const handleChangeRow = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => onChangeRow(row._id, e.target.name, e.target.value),
    [onChangeRow]
  );

  const articleOptions = useMemo(() => {
    const articleOptions: Array<{ name: string; article: CommoditiesDocument | PackagingsDocument; type: AvisType }> =
      [];
    if (company && row.order) {
      articleOptions.push(
        ...row.order.commodities.map(c => ({ name: c.title.en, article: c, type: AvisType.COMMODITY }))
      );
      articleOptions.push(
        ...row.order.packagings.map(p => ({
          name: packagingUtils.getPackagingDescription(p.packaging_type),
          article: p,
          type: AvisType.PACKAGING
        }))
      );
    }
    return articleOptions;
  }, [company, row.order]);

  return (
    <div className="row">
      <div className="col-xl-4">
        <div className="form-group">
          <Typeahead
            id={row._id.toString() + "order"}
            inputProps={{ className: "form-control form-control-solid form-control-lg" }}
            labelKey="name"
            onChange={(selected: any) => handleChangeOrderDescription(selected)}
            options={descriptionOptions}
            placeholder="Search for Orders, Products, References..."
            selected={row.descriptionOrder ? [row.descriptionOrder] : undefined}
          />
        </div>
      </div>
      <div className="col-xl-4">
        <div className="form-group">
          <Typeahead
            id={row._id.toString() + "article"}
            inputProps={{ className: "form-control form-control-solid form-control-lg" }}
            labelKey="name"
            onChange={(selected: any) => handleChangeArticleDescription(selected)}
            options={articleOptions}
            placeholder="Search for Commodities, Packaging..."
            disabled={!row.descriptionOrder}
            selected={!row.descriptionOrder ? [] : row.descriptionArticle ? [row.descriptionArticle] : undefined}
          />
        </div>
      </div>
      <div className="col-xl-4">
        <div className="form-group">
          <div className="input-group">
            <input
              type="text"
              className="form-control form-control-solid form-control-lg"
              name="totalAmount"
              placeholder="Total Amount"
              value={row.totalAmount}
              onChange={handleChangeRow}
            />
            <div className="input-group-append">
              <select
                name="unit"
                className="form-control form-control-solid form-control-lg"
                onChange={handleChangeRow}
              >
                {AvisUnitOptions.map(a => (
                  <option key={a.value}>{a.label}</option>
                ))}
              </select>
            </div>
            <button
              className="btn btn-text font-weight-bold text-uppercase text-danger pl-4 pr-0 py-4"
              onClick={handleRemoveRow}
            >
              <i className="font-size-h4 flaticon2-cross text-danger" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvisShipmentOverview;
