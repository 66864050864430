import _ from "lodash";
import React, { PureComponent } from "react";
import Chart from "react-apexcharts";
import DataTableInfoOverlay from "./DataTableInfoOverlay";
import { T_CAPSULE, T_CUSTOM, T_LIQUID, T_POWDER, T_SOFTGEL, T_TABLET } from "../../order/OrderHelper";
import dashboardUtils from "../../../utils/dashboardUtils";
import { DataContext } from "../../../context/dataContext";
import dateUtils from "../../../utils/dateUtils";
import { T_FULFILLMENT } from "../../../utils/timelineUtils";
import baseUtils from "../../../utils/baseUtils";

interface OutputByProductProps {
  context: React.ContextType<typeof DataContext>;
  product: typeof T_CAPSULE | typeof T_TABLET | typeof T_LIQUID | typeof T_POWDER | typeof T_SOFTGEL | typeof T_CUSTOM;
  startDate: Date;
  endDate: Date;
}

interface OutputByProductState {
  series: Array<{ name: string; data: Array<number> }>;
  options: any;
}

class OutputByProduct extends PureComponent<OutputByProductProps, OutputByProductState> {
  colorCounter: { [key: string]: number } = { "0": 0, "1": 0, "2": 0, "3": 0 };

  constructor(props: OutputByProductProps) {
    super(props);
    this.state = {
      series: [],
      options: {
        chart: {
          type: "bar",
          stacked: true,
          id: "basic-bar" + props.product,
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: this.generateManufacturers(false),
          labels: {
            show: true,
            formatter: (v: string, t: any) => this.formatXAxis(v, t)
          }
        },
        yaxis: {},
        dataLabels: { enabled: false },
        colors: [(v: any) => this.resolveColor(v)],
        plotOptions: { bar: { columnWidth: this.resolveBarWidth() } }
      }
    };
  }

  componentDidMount() {
    const { product } = this.props;
    const options = _.cloneDeep(this.state.options);
    // Adjust the axis and tooltip based upon the product
    options.yaxis = {
      labels: {
        formatter: (val: number) => {
          if (val === Infinity) return 1;
          if (val > 1000000) return val / 1000000 + ([T_POWDER, T_LIQUID].includes(product) ? " kt" : " M");
          if (val > 1000) return val / 1000 + ([T_POWDER, T_LIQUID].includes(product) ? " t" : " K");
          return val + ([T_POWDER, T_LIQUID].includes(product) ? " kg" : "");
        }
      }
    };
    options.tooltip = {
      y: {
        formatter: (val: number) => {
          if (val === Infinity) return 1;
          if (val > 1000000)
            return Number((val / 1000000).toFixed(3)) + ([T_POWDER, T_LIQUID].includes(product) ? " kt" : " M");
          if (val > 1000)
            return Number((val / 1000).toFixed(3)) + ([T_POWDER, T_LIQUID].includes(product) ? " t" : " K");
          return Number(val.toFixed(3)) + ([T_POWDER, T_LIQUID].includes(product) ? " kg" : "");
        }
      }
    };
    this.setState({ options });
    this.generateData();
  }

  componentDidUpdate(
    prevProps: Readonly<OutputByProductProps>,
    prevState: Readonly<OutputByProductState>,
    snapshot?: any
  ) {
    const { context, startDate, endDate } = this.props;
    if (
      !_.isEqual(context.orders, prevProps.context.orders) ||
      startDate !== prevProps.startDate ||
      endDate !== prevProps.endDate
    ) {
      this.generateData();
      this.colorCounter = { "0": 0, "1": 0, "2": 0, "3": 0 };
    }
  }

  /**
   * Resolves the color for the given series index.
   * @param seriesIndex: Index of the series
   * @returns { string } Color code in hex
   */
  resolveColor = ({ seriesIndex }: any) => {
    const { product } = this.props;
    const cc = this.colorCounter[seriesIndex];
    switch (product) {
      case "capsule":
      case "tablet":
        const old = [0, 1, 4, 5, 8, 9];
        if (this.colorCounter[seriesIndex] === 0) {
          this.colorCounter[seriesIndex] = 1;
          if (seriesIndex === 0) return "#008cff";
          if (seriesIndex === 1) return "#0064ff";
          if (seriesIndex === 2) return "#00b4ff";
          if (seriesIndex === 3) return "#003cff";
        }
        this.colorCounter[seriesIndex] += 1;
        switch (seriesIndex) {
          case 0:
            return old.includes(cc - 1) ? "#d4d4da" : "#008cff";
          case 1:
            return old.includes(cc - 1) ? "#ceceda" : "#0064ff";
          case 2:
            return old.includes(cc - 1) ? "#c7c7da" : "#00b4ff";
          case 3:
            return old.includes(cc - 1) ? "#c0c0da" : "#003cff";
        }
        break;
      case "powder":
      case "liquid":
        this.colorCounter[seriesIndex] += 1;
        return [2, 7].includes(cc) ? "#008cff" : "#d4d4da";
      case "softgel":
        this.colorCounter[seriesIndex] += 1;
        return cc === 3 ? "#008cff" : "#d4d4da";
      case "custom":
        this.colorCounter[seriesIndex] += 1;
        return [3, 7, 11].includes(cc) ? "#008cff" : "#d4d4da";
    }
  };

  /**
   * Format the X-Axis descriptions.
   * @param v: Value of the data point
   * @param t: Contains the value of the data point when on axis or an object with information when on data
   * @returns { string } Description
   */
  formatXAxis = (v: string, t: any) => {
    if (typeof t === "string") return t;
    const { startDate, endDate } = this.props;
    const manufacturers = this.generateManufacturers(false);
    const start = new Date(startDate);
    const end = new Date(endDate);
    const interval = dateUtils.getDaysBetween(start, end);
    const dpi = (t && t.dataPointIndex) || t.dataPointIndex === 0 ? t.dataPointIndex : null;
    // Each manufacturer contains 3 periods and an empty index to separate them to name them properly we need to know
    // where we are
    let period = dpi || dpi === 0 ? dpi % 4 : -1;
    let text = v;
    // Data point index marks the X-th bar inside the graph. Since every product supports different manufacturers we
    // need to pick the correct one which is stored inside the manufacturers array on the indexes 1, 5 and 9. The other
    // indexes are filled with empty spaces to format the x-axis correctly
    if (dpi >= 0 && dpi < 3) {
      text = manufacturers[1] + " ";
    } else if (dpi > 3 && dpi < 7) {
      text = manufacturers[5] + " ";
    } else if (dpi > 7) {
      text = manufacturers[9] + " ";
    }
    if (period === 0) {
      start.setDate(start.getDate() + -2 * interval);
      end.setDate(end.getDate() + -2 * interval);
    } else if (period === 1) {
      start.setDate(start.getDate() + -interval);
      end.setDate(end.getDate() + -interval);
    }
    text += "(" + baseUtils.formatDate(start) + " - " + baseUtils.formatDate(end) + ")";
    return text;
  };

  resolveBarWidth = () => {
    const { product } = this.props;
    if (product === T_SOFTGEL) return 70 * (1 / 3) + "%";
    if ([T_TABLET, T_POWDER, T_LIQUID].includes(product)) return 70 * (2 / 3) + "%";
    return "70%";
  };

  /**
   * Generate the list of matching manufacturers for the current product.
   * @param long: Determines whether long or short name should be used
   * @returns { Array<string> } List of valid manufacturers
   */
  generateManufacturers = (long: boolean) => {
    const { product } = this.props;
    const manufacturers = [];
    if (long) {
      manufacturers.push("PLF Filling DE-GE");
      if (product !== T_SOFTGEL) {
        if (![T_TABLET, T_LIQUID].includes(product)) {
          manufacturers.push("PLF Production CZ");
        }
        if (product !== T_POWDER) {
          manufacturers.push("PLF Production DE-PS");
        }
      }
    } else {
      // Empty strings are pushed to add empty entries on x-axis
      manufacturers.push("");
      manufacturers.push("DE-GE");
      manufacturers.push("");
      if (product !== T_SOFTGEL) {
        if (![T_TABLET, T_LIQUID].includes(product)) {
          manufacturers.push("");
          manufacturers.push("");
          manufacturers.push("CZ");
          manufacturers.push("");
        }
        if (product !== T_POWDER) {
          manufacturers.push("");
          manufacturers.push("");
          manufacturers.push("DE-PS");
          manufacturers.push("");
        }
      }
    }
    return manufacturers;
  };

  /**
   * Generates the data that is used to populate the graph.
   */
  generateData = () => {
    const { endDate, product, startDate } = this.props;
    const series = [];
    const days = Math.ceil(dateUtils.getDaysBetween(startDate, endDate));
    const output_2 = this.calculateOutput(-days * 2);
    const output_1 = this.calculateOutput(-days);
    const output = this.calculateOutput();
    const productData: { [key: string]: { [key: string]: Array<number> } } = {};
    for (let manufacturer in output) {
      if (product === T_CAPSULE) {
        for (let key in output[manufacturer].capsules) {
          if (!productData[key]) productData[key] = {};
          if (!productData[key][manufacturer]) productData[key][manufacturer] = [0, 0, 0];
          productData[key][manufacturer][0] = output_2[manufacturer].capsules[key];
          productData[key][manufacturer][1] = output_1[manufacturer].capsules[key];
          productData[key][manufacturer][2] = output[manufacturer].capsules[key];
        }
      } else if (product === T_TABLET) {
        for (let key in output[manufacturer].tablets) {
          if (!productData[key]) productData[key] = {};
          if (!productData[key][manufacturer]) productData[key][manufacturer] = [0, 0, 0];
          productData[key][manufacturer][0] = output_2[manufacturer].tablets[key];
          productData[key][manufacturer][1] = output_1[manufacturer].tablets[key];
          productData[key][manufacturer][2] = output[manufacturer].tablets[key];
        }
      } else if (product === T_POWDER) {
        if (!productData[T_POWDER]) productData[T_POWDER] = {};
        if (!productData[T_POWDER][manufacturer]) productData[T_POWDER][manufacturer] = [0, 0, 0];
        productData[T_POWDER][manufacturer][0] = output_2[manufacturer].powder;
        productData[T_POWDER][manufacturer][1] = output_1[manufacturer].powder;
        productData[T_POWDER][manufacturer][2] = output[manufacturer].powder;
      } else if (product === T_LIQUID) {
        if (!productData[T_LIQUID]) productData[T_LIQUID] = {};
        if (!productData[T_LIQUID][manufacturer]) productData[T_LIQUID][manufacturer] = [0, 0, 0];
        productData[T_LIQUID][manufacturer][0] = output_2[manufacturer].liquids;
        productData[T_LIQUID][manufacturer][1] = output_1[manufacturer].liquids;
        productData[T_LIQUID][manufacturer][2] = output[manufacturer].liquids;
      } else if (product === T_SOFTGEL) {
        if (!productData[T_SOFTGEL]) productData[T_SOFTGEL] = {};
        if (!productData[T_SOFTGEL][manufacturer]) productData[T_SOFTGEL][manufacturer] = [0, 0, 0];
        productData[T_SOFTGEL][manufacturer][0] = output_2[manufacturer].softgels;
        productData[T_SOFTGEL][manufacturer][1] = output_1[manufacturer].softgels;
        productData[T_SOFTGEL][manufacturer][2] = output[manufacturer].softgels;
      } else if (product === T_CUSTOM) {
        if (!productData[T_CUSTOM]) productData[T_CUSTOM] = {};
        if (!productData[T_CUSTOM][manufacturer]) productData[T_CUSTOM][manufacturer] = [0, 0, 0];
        productData[T_CUSTOM][manufacturer][0] = output_2[manufacturer].custom;
        productData[T_CUSTOM][manufacturer][1] = output_1[manufacturer].custom;
        productData[T_CUSTOM][manufacturer][2] = output[manufacturer].custom;
      }
    }
    const manufacturers = Object.keys(output);
    for (let i = 0; i < manufacturers.length; i++) {
      const manufacturer = manufacturers[i];
      for (let key in productData) {
        const data = series.find(s => s.name === key);
        const val = productData[key][manufacturer] ? productData[key][manufacturer] : [0, 0, 0];
        if (!data) {
          if (i !== manufacturers.length - 1) val.push(0);
          series.push({ name: key, data: val });
        } else {
          data.data = data.data.concat(val);
          if (i !== manufacturers.length - 1) data.data.push(0);
        }
      }
    }
    this.setState({ series });
  };

  /**
   * Calculates the output of each valid manufacturer by product.
   * @returns { object } Contains the output of each valid manufacturer by product
   */
  calculateOutput = (offset?: number) => {
    const { context, startDate, endDate } = this.props;
    const { capsules, manufacturers, orders, tablets } = context;
    const manufacturerNames = this.generateManufacturers(true);
    const start = new Date(startDate.getTime());
    if (offset) start.setDate(startDate.getDate() + offset);
    const end = new Date(endDate.getTime());
    if (offset) end.setDate(endDate.getDate() + offset);
    const output: {
      [key: string]: {
        capsules: { [key: string]: number };
        tablets: { [key: string]: number };
        powder: number;
        liquids: number;
        softgels: number;
        custom: number;
      };
    } = {};
    for (let name of manufacturerNames) {
      const manufacturer = manufacturers.find(m => m.name === name);
      let outputCapsules: { [key: string]: number } = {};
      let outputTablets: { [key: string]: number } = {};
      let outputLiquids = 0;
      let outputPowder = 0;
      let outputSoftgels = 0;
      let outputCustom = 0;
      if (manufacturer) {
        for (let i = 0; i < orders.length; i++) {
          const order = orders[i];
          if (order.settings.manufacturer.toString() === manufacturer._id.toString()) {
            let fulfillmentDate;
            for (let j = 0; j < order.timeline.length; j++) {
              const timeline = order.timeline[j];
              if (timeline.type === T_FULFILLMENT) {
                fulfillmentDate = timeline.date;
                break;
              }
            }
            if (fulfillmentDate > start && fulfillmentDate < end) {
              let output = 0;
              if (order.fulfillment && order.fulfillment.totalUnits) {
                output = order.fulfillment.totalUnits;
              } else {
                output = order.calculations[0].units;
              }
              if (order.settings.type === T_CAPSULE) {
                const capsule = capsules.find(c => c._id.toString() === order.settings.id.toString())!;
                output *= order.settings.perUnit;
                if (outputCapsules[capsule.capsule_size]) {
                  outputCapsules[capsule.capsule_size] += output;
                } else {
                  outputCapsules[capsule.capsule_size] = output;
                }
              } else if (order.settings.type === T_TABLET) {
                const tablet = tablets.find(t => t._id.toString() === order.settings.id.toString())!;
                output *= order.settings.perUnit;
                const key = tablet.shape + " " + tablet.volume + "ml";
                if (outputTablets[key]) {
                  outputTablets[key] += output;
                } else {
                  outputTablets[key] = output;
                }
              } else if (order.settings.type === T_POWDER) {
                outputPowder += (order.settings.perUnit * output) / 1000 / 1000;
              } else if (order.settings.type === T_LIQUID) {
                outputLiquids += (order.settings.perUnit * output) / 1000 / 1000;
              } else if (order.settings.type === T_SOFTGEL) {
                outputSoftgels += order.settings.perUnit * output;
              } else if (order.settings.type === T_CUSTOM) {
                outputCustom += (order.settings.perUnit / 1000) * output;
              }
            }
          }
        }
      }
      output[name] = {
        capsules: outputCapsules,
        tablets: outputTablets,
        powder: outputPowder,
        liquids: outputLiquids,
        softgels: outputSoftgels,
        custom: outputCustom
      };
    }
    return output;
  };

  /**
   * Resolve the description that should be shown at the header of the widget.
   * @returns { string } Description of the product type
   */
  resolveOutputHeader = () => {
    const { product } = this.props;
    switch (product) {
      case T_CAPSULE:
        return "Capsules";
      case T_TABLET:
        return "Tablets";
      case T_POWDER:
        return "Powder";
      case T_LIQUID:
        return "Liquids";
      case T_SOFTGEL:
        return "Softgels";
      case T_CUSTOM:
        return "Custom Products";
    }
  };

  /**
   * Resolve the unit that is used for the information overlay on top of the graph.
   * @returns { string } Unit
   */
  resolveUnit = () => {
    const { product } = this.props;
    switch (product) {
      case "capsule":
      case "tablet":
        return product + "s";
      case "powder":
      case "liquid":
        return "weight";
      default:
        return "units";
    }
  };

  render() {
    const { startDate, endDate } = this.props;
    const { options, series } = this.state;
    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">
              Output for {this.resolveOutputHeader()} (
              {dashboardUtils.calculateTimeframeDescription(startDate, endDate)}) and periods before{" "}
              <DataTableInfoOverlay unit={this.resolveUnit()} />
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit p-3">
          <Chart series={series} type="bar" options={options} heigth="325" width="100%" />
        </div>
      </div>
    );
  }
}

export default OutputByProduct;
