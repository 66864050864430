import _ from "lodash";
import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";
import { BSON } from "realm-web";
import { SupplierSelected, SupplierSelectedPrices } from "./CustomTypes";
import CompanyWidget from "../CompanyWidget";
import PersonWidget from "../PersonWidget";
import RatingInfo from "../RatingInfo";
import { DataContext } from "../../../context/dataContext";
import dateUtils from "../../../utils/dateUtils";
import suppliersUtils, { INCOTERMS } from "../../../utils/suppliersUtils";
import baseUtils from "../../../utils/baseUtils";
import manufacturerUtils from "../../../utils/manufacturerUtils";
import { ManufacturersDocument } from "../../../model/manufacturers.types";
import { SuppliersDocument } from "../../../model/suppliers.types";
import ManufacturerWidget from "../ManufacturerWidget";

interface SupplierPricesEntryProps {
  onRemoveSupplierClick: (_id: BSON.ObjectId) => void;
  onAddNewPriceClick: (_id: BSON.ObjectId) => void;
  onRemovePriceClick: (supplier: BSON.ObjectId, price: BSON.ObjectId) => void;
  onNumberChange: (supplier: BSON.ObjectId, price: BSON.ObjectId, e: React.ChangeEvent<HTMLInputElement>) => void;
  onStringChange: (
    supplier: BSON.ObjectId,
    price: BSON.ObjectId,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  supplierSelected: SupplierSelected;
  type: "Capsule" | "Commodity" | "Packaging" | "Custom" | "Service" | "Softgel";
  activeKey: string;
  onChangeActiveKey: (activeKey: string) => void;
  disabled?: boolean;
  allowManufacturer: boolean;
}

interface SupplierPricesEntryState {
  prices: Array<SupplierSelectedPrices>;
}

class SupplierPricesEntry extends PureComponent<SupplierPricesEntryProps, SupplierPricesEntryState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: SupplierPricesEntryProps) {
    super(props);
    this.state = { prices: props.supplierSelected.prices };
  }

  componentDidUpdate(prevProps: Readonly<SupplierPricesEntryProps>) {
    if (prevProps.supplierSelected !== this.props.supplierSelected) {
      this.setState({ prices: this.props.supplierSelected.prices });
    }
  }

  /**
   * Handle the change of a number value.
   * @param price ID of the price that is changed
   * @param e Event that triggered the change
   */
  handleNumberChange = (price: BSON.ObjectId, e: React.ChangeEvent<HTMLInputElement>) => {
    const prices = _.cloneDeep(this.state.prices);
    const idx = prices.findIndex(p => p._id.toString() === price.toString());
    let val = e.target.value;
    val = val.replaceAll(/^0+/g, "0");
    if (!val.includes(".")) val = Number(val).toString();
    if (idx === -1 || (!val && val !== "0") || Number(val) < 0) return;
    switch (e.target.name) {
      case "totalPrice":
        prices[idx].totalPrice = val;
        break;
      case "purchasePrice":
        prices[idx].purchasePrice = val;
        break;
      case "moq":
        prices[idx].moq = val;
        break;
      case "deliveryTime":
        prices[idx].deliveryTime = val;
        break;
    }
    prices[idx].age = new Date();
    this.setState({ prices });
  };

  /**
   * Handle the change of a string value.
   * @param price ID of the price that is changed
   * @param e Event that triggered the change
   */
  handleStringChange = (price: BSON.ObjectId, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const prices = _.cloneDeep(this.state.prices);
    const idx = prices.findIndex(p => p._id.toString() === price.toString());
    if (idx === -1) return;
    switch (e.target.name) {
      case "incoterm":
        prices[idx].incoterm = e.target.value;
        break;
      case "note":
        prices[idx].note = e.target.value;
        break;
      case "purchaseCurrency":
        prices[idx].purchasePriceCurrency = e.target.value;
        break;
    }
    prices[idx].age = new Date();
    this.setState({ prices });
  };

  render() {
    const {
      onAddNewPriceClick,
      onRemovePriceClick,
      onRemoveSupplierClick,
      onNumberChange,
      onStringChange,
      onChangeActiveKey,
      supplierSelected,
      type,
      activeKey,
      disabled,
      allowManufacturer
    } = this.props;
    const { prices } = this.state;
    const { currencies, suppliers, manufacturers, userdata } = this.context;

    let provider: SuppliersDocument | ManufacturersDocument | undefined;
    if (supplierSelected.type === "manufacturer") {
      provider = manufacturers.find(su => su._id.toString() === supplierSelected._id.toString());
    } else {
      provider = suppliers.find(su => su._id.toString() === supplierSelected._id.toString());
    }
    if (!provider) return <></>;
    const isManufacturer = manufacturerUtils.isManufacturer(provider);
    const contact = isManufacturer
      ? userdata.find(u => u._id.toString() === (provider as ManufacturersDocument).person.toString())
      : (provider as SuppliersDocument).person.length > 0
      ? userdata.find(u => u._id.toString() === (provider as SuppliersDocument).person[0].toString())
      : null;
    const supplierDisabled = suppliersUtils.isSupplier(provider) ? provider.disabled : null;

    const bestPrice = supplierSelected.prices.reduce(
      (bp, p) => (+p.totalPrice < bp ? +p.totalPrice : bp),
      Number.POSITIVE_INFINITY
    );
    const fastestDelivery = supplierSelected.prices.reduce(
      (fd, p) => (+p.deliveryTime < fd ? +p.deliveryTime : fd),
      Number.POSITIVE_INFINITY
    );
    const lastUpdate = supplierSelected.prices.reduce(
      (lu, p) => (p.age && p.age.getTime() > lu.getTime() ? p.age : lu),
      new Date(0)
    );
    const isCom = !["Capsule", "Packaging"].includes(type);
    return (
      <>
        <tr
          className="row-hover"
          onClick={() =>
            onChangeActiveKey(activeKey === supplierSelected._id.toString() ? "" : supplierSelected._id.toString())
          }
          style={{ backgroundColor: "#fafafa" }}
        >
          <td className="align-middle">
            {isManufacturer ? (
              <ManufacturerWidget manufacturer={provider as ManufacturersDocument} />
            ) : (
              <CompanyWidget company={provider as SuppliersDocument} type={"supplier"} />
            )}
          </td>
          {allowManufacturer && <td className="align-middle">{isManufacturer ? "Manufacturer" : "Supplier"}</td>}
          <td className="align-middle">{contact && <PersonWidget person={contact} />}</td>
          <td className="align-middle">
            {isManufacturer ? <span> - </span> : <RatingInfo rating={(provider as SuppliersDocument).rating} />}
          </td>
          <td className="align-middle">{bestPrice === Infinity ? "-" : baseUtils.formatEuro(+bestPrice)}</td>
          <td className="align-middle">{fastestDelivery === Infinity ? "-" : `${fastestDelivery} Days`}</td>
          <td className="align-middle">{lastUpdate.getTime() !== 0 ? dateUtils.getTimeAgo(lastUpdate) : "-"}</td>
          <td className="align-middle text-right">
            {!disabled && (
              <button className="btn btn-danger" onClick={() => onRemoveSupplierClick(supplierSelected._id)}>
                <i className="fa fa-trash pr-0" />
              </button>
            )}
          </td>
        </tr>
        <tr>
          <td
            colSpan={6}
            className={"m-0 p-0 " + (activeKey === supplierSelected._id.toString() ? "active show" : "d-none")}
          >
            <Table responsive>
              <thead>
                <tr>
                  <th className="align-middle" style={{ width: isCom ? "15%" : "31%", minWidth: "125px" }}>
                    Total Price
                  </th>
                  {isCom && (
                    <>
                      <th className="align-middle" style={{ width: "16%", minWidth: "150px" }}>
                        Purchase Price
                      </th>
                      <th className="align-middle" style={{ width: "10%", minWidth: "100px" }}>
                        Incoterm
                      </th>
                    </>
                  )}
                  <th className="align-middle" style={{ width: isCom ? "13%" : "18%", minWidth: "125px" }}>
                    MOQ
                  </th>
                  <th className="align-middle" style={{ width: isCom ? "11%" : "16%", minWidth: "110px" }}>
                    Del. Time
                  </th>
                  <th className="align-middle" style={{ width: "25%", minWidth: "125px" }}>
                    Note
                  </th>
                  <th className="align-middle" style={{ width: "10%", minWidth: "75px" }}>
                    Age
                  </th>
                  {!disabled && !supplierDisabled && (
                    <th className="text-right" style={{ width: "10%" }}>
                      <button
                        className="btn btn-sm btn-success"
                        onClick={() => onAddNewPriceClick(supplierSelected._id)}
                      >
                        <i className="fa fa-plus pr-0" />
                      </button>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {prices.map(p => {
                  return (
                    <tr key={p._id.toString()}>
                      <td className="align-middle">
                        <div className="input-group">
                          <input
                            className={
                              "form-control input-number-arrows-low-pr" + (+p.totalPrice === 0 ? " is-invalid" : "")
                            }
                            type="number"
                            value={p.totalPrice}
                            min={0}
                            onChange={e => (disabled ? undefined : this.handleNumberChange(p._id, e))}
                            disabled={disabled}
                            onBlur={e => (disabled ? undefined : onNumberChange(supplierSelected._id, p._id, e))}
                            name="totalPrice"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">€</span>
                          </div>
                        </div>
                      </td>
                      {isCom && (
                        <>
                          <td className="align-middle">
                            <div className="input-group">
                              <input
                                className={
                                  "form-control input-number-arrows-low-pr" +
                                  (["Capsule", "Packaging"].includes(type)
                                    ? " disabled"
                                    : +p.purchasePrice === 0
                                    ? " is-invalid"
                                    : "")
                                }
                                disabled={["Capsule", "Packaging"].includes(type) || disabled}
                                type="number"
                                value={p.purchasePrice}
                                min={0}
                                onChange={e => (disabled ? undefined : this.handleNumberChange(p._id, e))}
                                onBlur={e => (disabled ? undefined : onNumberChange(supplierSelected._id, p._id, e))}
                                name="purchasePrice"
                              />
                              <div className="input-group-append">
                                <select
                                  className={
                                    "form-control pl-1 pr-0" + (p.purchasePriceCurrency === "" ? " is-invalid" : "")
                                  }
                                  value={p.purchasePriceCurrency}
                                  onChange={e => (disabled ? undefined : this.handleStringChange(p._id, e))}
                                  disabled={disabled}
                                  onBlur={e => (disabled ? undefined : onStringChange(supplierSelected._id, p._id, e))}
                                  name="purchaseCurrency"
                                  style={{ maxWidth: "60px", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                >
                                  <option disabled hidden value="">
                                    Not set
                                  </option>
                                  {currencies &&
                                    currencies.map(c => (
                                      <option key={c.code} value={c.code}>
                                        {c.code}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">
                            <select
                              className={"form-control" + (p.incoterm === "" ? " is-invalid" : "")}
                              value={p.incoterm}
                              onChange={e => (disabled ? undefined : this.handleStringChange(p._id, e))}
                              disabled={disabled}
                              onBlur={e => (disabled ? undefined : onStringChange(supplierSelected._id, p._id, e))}
                              name="incoterm"
                            >
                              <option value="" disabled hidden>
                                Not set
                              </option>
                              {INCOTERMS.map((i, key) => {
                                return (
                                  <option key={key} value={i}>
                                    {i}
                                  </option>
                                );
                              })}
                            </select>
                          </td>
                        </>
                      )}
                      <td className="align-middle">
                        <div className="input-group">
                          <input
                            className="form-control input-number-arrows-low-pr"
                            type="number"
                            value={p.moq}
                            min={0}
                            onChange={e => (disabled ? undefined : this.handleNumberChange(p._id, e))}
                            disabled={disabled}
                            onBlur={e => (disabled ? undefined : onNumberChange(supplierSelected._id, p._id, e))}
                            name="moq"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              {type === "Commodity" ? "KG" : ["Packaging", "Service"].includes(type) ? "PCS" : "TSD"}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">
                        <div className="input-group">
                          <input
                            className="form-control input-number-arrows-low-pr"
                            type="number"
                            value={p.deliveryTime}
                            min={0}
                            onChange={e => (disabled ? undefined : this.handleNumberChange(p._id, e))}
                            disabled={disabled}
                            onBlur={e => (disabled ? undefined : onNumberChange(supplierSelected._id, p._id, e))}
                            name="deliveryTime"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">D</span>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">
                        <input
                          className="form-control"
                          type="text"
                          value={p.note}
                          onChange={e => (disabled ? undefined : this.handleStringChange(p._id, e))}
                          disabled={disabled}
                          onBlur={e => (disabled ? undefined : onStringChange(supplierSelected._id, p._id, e))}
                          name="note"
                        />
                      </td>
                      <td className="align-middle">{p.age ? dateUtils.getTimeAgo(p.age) : "-"}</td>
                      {!disabled && (
                        <td className="align-middle text-right">
                          <button
                            className={"btn btn-sm btn-danger" + (prices.length === 1 ? " disabled" : "")}
                            onClick={() => onRemovePriceClick(supplierSelected._id, p._id)}
                            disabled={prices.length === 1}
                          >
                            <i className="fa fa-trash pr-0" />
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </td>
        </tr>
      </>
    );
  }
}

export default SupplierPricesEntry;
