import i18n from "i18next";
import {
  CustomerData,
  CustomOrder,
  DeliveryNoteData,
  DeliveryNotePosition,
  ShippingGroupType
} from "../../components/order/CustomTypes";
import pdfUtils from "./pdfUtils";
import baseUtils from "../baseUtils";
/**
 * Create html representation for the delivery information pdf
 * @param order an order document
 * @param customer customer data
 * @param pdfData data for the delivery pdf
 * @param positions list of positions
 * @param shippingGroups list of shipping groups
 * @param exp the expiry date
 * @param deviatingDeliveryAddress optional, an additional delivery address deviating from the standard address
 * @param dateFormat optional, a specific date format
 * @returns {string} html representation of delivery information
 */
function createDeliveryInformation(
  order: CustomOrder,
  customer: CustomerData,
  pdfData: DeliveryNoteData,
  positions: Array<DeliveryNotePosition>,
  shippingGroups: Array<ShippingGroupType>,
  exp: Date,
  deviatingDeliveryAddress?: CustomerData,
  dateFormat?: { options: Intl.DateTimeFormatOptions; locale: string }
) {
  const currentUser = order.createdFrom;
  const deliveryAddress = deviatingDeliveryAddress ? deviatingDeliveryAddress : customer;

  let html =
    '<head><link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"><style>tr:nth-child(even) {background-color: white;}</style><meta http-equiv="content-type" content="text/html; charset=utf-8"></head>';
  html += '<body style="font-family: Helvetica ">';
  html +=
    '</img><hr><img src="https://private-label-factory.de/config/assets/img/PLF_logo_bw.png" width="200"><br/><table cellpadding="5" cellspacing="0" style="width: 100%; "<tr><td><br/><br/><span style="font-size:12px; float:left;"><u>Private Label Factory Deutschland GmbH, Philipp-Reis-Str. 7, 45659 Recklinghausen</u></span><br/>';
  html += '<span style="font-size:16px; float:left;">';
  html += pdfUtils.getCustomerHTML(customer);
  html += `</span></td><td style="text-align: right; font-size:16px">${i18n.t("common:orderNumber")}: AT-${
    order.identifier
  } `;
  html += "<br/>";
  if (pdfData.bioNumber) {
    html += `${i18n.t("common:bioControlNumber")}: ${pdfData.bioNumber}</br>`;
  }
  html += `${i18n.t("common:date")}: ${baseUtils.formatDate(pdfData.creationDate)} </br></br>`;
  html += pdfUtils.getResponsibleHTML(currentUser);
  html += `</td></tr><tr><td style="font-size:25px; font-weight:bold;"><br/>${i18n.t(
    "deliveryNote:deliveryNote"
  )}: AT-${order.identifier}`;
  if (pdfData.title) {
    html += '<br/><span style="font-size:18px;">' + pdfData.title + "</span>";
  }
  if (pdfData.subtitle) {
    html += '<br/><span style="font-size:16px; font-weight: normal">' + pdfData.subtitle + "</span>";
  }
  html += "<br/></td></tr></table>";
  html += '<table style="width:100%"><tr><td style="width:50%">';
  html += "</td><td></td></tr>";
  html += "</tbody></table></td></tr></table>";
  html += `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" ><tr style="background-color:#cccccc"><td><b>#</b></td><td style="width: 70px"><b>${i18n.t(
    "common:amount"
  )}</b></td><td style="min-width: 250px"><b style="white-space:nowrap">${i18n.t("common:description")}</b></td></tr>`;
  html += "<tbody>";
  for (let i = 0; i < positions.length; i++) {
    const position = positions[i];
    html += '<tr style="width:100%"><td><b>' + (i + 1) + "</b></td>";
    html += "<td>" + position.amount + "</td><td>" + position.description;
    html += "</td><td></tr>";
  }
  html += "</tbody></table></br></br>";

  html += `<h4>${i18n.t("deliveryNote:shippingInformation")}</h4>`;
  html += `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px"><tr style="background-color:#cccccc; width: 100%"><td><b>#</b></td><td><b>${i18n.t(
    "packaging:cartons"
  )}</b></td><td><b style="white-space:nowrap">${i18n.t(
    "packaging:content"
  )}</b></td><td><b style="white-space:nowrap">${i18n.t(
    "packaging:size"
  )}</b></td><td><b style="white-space:nowrap">${i18n.t(
    "packaging:weight"
  )}</b></td><td><b style="white-space:nowrap">LOT</b></td><td><b style="white-space:nowrap">${i18n.t(
    "deliveryNote:bb"
  )}</b></td><td><b style="white-space:nowrap">${i18n.t("common:date")}</b></td></tr>`;
  html += "<tbody>";
  for (let i = 0; i < shippingGroups.length; i++) {
    const shippingGroup = shippingGroups[i];
    html += '<tr style="width:100%"><td><b>' + shippingGroup.number + "</b></td>";
    html +=
      "<td>" +
      shippingGroup.boxes +
      "</td><td>" +
      shippingGroup.items +
      "</td><td>" +
      "60x40x30cm" +
      "</td><td>" +
      shippingGroup.weight +
      "kg" +
      "</td><td>" +
      (order.fulfillment ? order.fulfillment.lot : "lot not set") +
      "</td><td>" +
      baseUtils.formatDate(exp, false, dateFormat?.options, dateFormat?.locale) +
      "</td><td>" +
      baseUtils.formatDate(pdfData.creationDate);
    html += "</td><td></tr>";
  }
  html += "</tbody>";
  html += "</table>";

  // shipping address and tracking number
  html += "</br>";
  html += '<table style="width: 100%">';
  html += '<tr style="text-align: left">';
  html += `<th style="width: 50%"><b>${i18n.t("deliveryNote:shippingAddress")}</b></th>`;
  html += `<th style="padding-left: 20px"><b>${pdfData.trackingNumber === "" ? "" : "Tracking"}</b></th>`;
  html += "</tr>";
  html += "<tbody>";
  html += "<tr>";
  html += '<td style="background-color: #fafafa;font-size:25px;">';
  html += "<span><b>";
  html += deliveryAddress.name;
  html += "</b><br/>";
  if (deliveryAddress.careOf && deliveryAddress.careOf.length > 0) {
    html += "c/o " + deliveryAddress.careOf.replaceAll("c/o", "") + "</br>";
  }
  if (deliveryAddress.additionalAddress.length > 0) {
    html += deliveryAddress.additionalAddress + "</br>";
  }
  html += deliveryAddress.street;
  html += "</br>";
  html += deliveryAddress.zip + " " + deliveryAddress.city;
  html += "</br>";
  html += deliveryAddress.country;
  html += "</span>";
  html += "</td>";
  html += '<td style="padding-left: 20px; vertical-align: top">';
  html += "<b>" + pdfData.trackingNumber + "</b>";
  html += "</td>";
  html += "</tbody>";
  html += "</table>";
  html += `<br/><br/><span style="font-size:17px">${i18n.t("template:deliveryTerms")}</span>`;
  html += '<br/><br/><span style="font-size:17px">' + pdfData.note + "</span><br/>";
  html += i18n.t("template:additionalText1");
  html += "</body>";
  return html;
}

export default { createDeliveryInformation };
