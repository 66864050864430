import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import User from "./User";

interface UserWrapperProps extends RouteComponentProps<UserParams, {}, {}> {}

interface UserParams {
  id: string;
}

const UserWrapper: React.FunctionComponent<UserWrapperProps> = props => {
  const context = useContext(DataContext);
  return <User context={context} {...props} />;
};

export default UserWrapper;
