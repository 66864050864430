import { CustomerData, CustomOrder, OrderConfirmationData, InvoicePosition } from "../../components/order/CustomTypes";
import pdfUtils from "./pdfUtils";
import orderUtils from "../orderUtils";
import baseUtils from "../baseUtils";
import i18n from "../../translations/i18n";

/**
 * Create an order confirmation pdf
 * @param order an order document
 * @param customer data about the customer
 * @param pdfData general pdf data
 * @param positions list of positions
 * @param reverseCharge flag if it is a reverse charge or not
 * @param subTotal the subtotal value, i.e. total amount without VAT
 * @param vatList list with all vat values
 * @param total the total amount with VAT
 * @param lot optional lot number for product
 * @param vatId optional vatID for customer
 * @param offerIdentifier optional identifier of the offer
 * @returns {string} html representation for order confirmation pdf
 */
function createOrderConfirmation(
  order: CustomOrder,
  customer: CustomerData,
  pdfData: OrderConfirmationData,
  positions: Array<InvoicePosition>,
  reverseCharge: boolean,
  subTotal: number,
  vatList: Array<{ vat: string; value: number; total: number }>,
  total: number,
  lot?: string,
  vatId?: string,
  offerIdentifier?: string
) {
  let today = baseUtils.formatDate(new Date());
  const currentUser = order.createdFrom;

  let html =
    '<head><link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"><style>tr:nth-child(even) {background-color: white;}</style><meta http-equiv="content-type" content="text/html; charset=utf-8"></head>';
  html += '<body style="font-family: Helvetica ">';
  html +=
    '</img><hr><img src="https://private-label-factory.de/config/assets/img/PLF_logo_bw.png" width="200"/><br/><table cellpadding="5" cellspacing="0" style="width: 100%; "><tbody><tr><td><br/><br/><span style="font-size:12px; float:left;"><u>Private Label Factory Deutschland GmbH, Philipp-Reis-Str. 7, 45659 Recklinghausen</u></span><br/>';
  html += '<span style="font-size:16px; float:left;">';
  html += pdfUtils.getCustomerHTML(customer);

  const trackingId = orderUtils.getTrackingId(order);
  html +=
    `</span></td><td style="text-align: right; font-size:16px">${i18n.t("order:orderNumber")} ` +
    "AT-" +
    order.identifier;
  if (pdfData.bioNumber) {
    html += `<br/>${i18n.t("order:organicControlNumber")}: ` + pdfData.bioNumber;
  }
  html +=
    `<br/>${i18n.t("order:orderDate")}: ` +
    today +
    `<br/>${i18n.t(
      "order:trackingNumber"
    )}: <a target="_blank" href="https://private-label-factory.com/tracking?trackingId=` +
    trackingId +
    '">' +
    trackingId +
    "</a><br/><br/>";
  html += pdfUtils.getResponsibleHTML(currentUser);
  html +=
    `</td></tr></tbody></table><table cellpadding="5" cellspacing="0" style="width: 100%; "><tbody><tr><td style="font-size:25px; font-weight: bold;"><br/>${i18n.t(
      "order:orderConfirmation"
    )} AT-` + order.identifier;
  if (offerIdentifier) html += ` ${i18n.t("order:forOffer")} ${offerIdentifier}`;
  if (pdfData.title) {
    html += '<br/><span style="font-size:18px;">' + pdfData.title + "</span>";
  }
  if (pdfData.subtitle) {
    html += '<br/><span style="font-size:16px; font-weight: normal">' + pdfData.subtitle + "</span>";
  }
  html += "<br/></td></tr></table></body>";
  html += '<table style="width:100%"><tr><td style="width:50%">';
  if (pdfData.text) {
    html += '<span style="font-size:16px; position: relative; top:0px; left:0px">' + pdfData.text + "</span>";
  }
  html += "</td><td></td></tr>";

  html += "</tbody></table></td></tr></table>";
  html += `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" ><head><tr style="background-color:#cccccc"><td><b>#</b></td><td style="min-width: 250px"><b style="white-space:nowrap">${i18n.t(
    "pdf:name"
  )}</b></td><td><b>${i18n.t("offer:amount")}</b></td><td><b style="white-space:nowrap;">${i18n.t(
    "order:unit"
  )}</b></td><td><b style="white-space:nowrap;">${i18n.t(
    "order:single"
  )}</b></td><td><b style="white-space:nowrap;">${i18n.t(
    "order:vat"
  )}</b></td><td><b style="white-space:nowrap;">${i18n.t(
    "order:discount"
  )}</b></td><td><b style="white-space:nowrap;">${i18n.t("order:total")}</b></td></tr></head>`;
  html += "<tbody>";
  for (let i = 0; i < positions.length; i++) {
    const position = positions[i];
    html += pdfUtils.getPositionHTML(position, i, lot);
  }
  html += "</tbody>";
  html += "</table>";

  html += pdfUtils.getSubtotalHTML(subTotal);
  html += pdfUtils.getVatHTML(vatList);
  html += pdfUtils.getTotalHTML(total);
  html += pdfUtils.getReverseChargeHTML(reverseCharge, customer.name, vatId);
  html += i18n.t("template:additionalInvoiceText");
  html += "<br/><br/>";
  html += i18n.t("template:deliveryTerms");
  html += pdfUtils.getPaymentHTML("orderConfirmation", pdfData.dueIn);

  html += '<span style="font-size:15px">' + pdfData.paymentNote + "</span><br/><br/>";
  html += '<span style="font-size:17px">' + pdfData.note + "</span><br/>";
  html += "<br/><br/><br/>";
  html += i18n.t("template:additionalText2");
  html += i18n.t("template:additionalText1");
  return html;
}

export default { createOrderConfirmation };
