import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { CommodityBatchFile } from "../../model/commodities.types";
import baseUtils from "../../utils/baseUtils";
import dateUtils from "../../utils/dateUtils";
import { PackagingBatchFile } from "../../model/packagingStock.types";

interface BatchFilesModalProps {
  lot: string;
  files: Array<CommodityBatchFile | PackagingBatchFile>;
}

interface BatchFilesModalState {
  show: boolean;
}

class BatchFilesModal extends PureComponent<BatchFilesModalProps, BatchFilesModalState> {
  constructor(props: BatchFilesModalProps) {
    super(props);
    this.state = { show: false };
  }
  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  render() {
    const { files, lot } = this.props;
    const { show } = this.state;
    if (files.length > 0 && files.length < 3) {
      return (
        <>
          <a
            href={files[0].path}
            target="_blank"
            rel="noopener noreferrer"
            className={"kt-widget4__username " + (files.length === 2 && "mr-2")}
          >
            <img
              src={process.env.PUBLIC_URL + "/media/icons/pdf_icon.png"}
              alt="files"
              style={{
                width: 30
              }}
              className="pointer"
            />
          </a>
          {files.length === 2 && (
            <a href={files[1].path} target="_blank" rel="noopener noreferrer" className="kt-widget4__username">
              <img
                src={process.env.PUBLIC_URL + "/media/icons/pdf_icon.png"}
                alt="files"
                style={{
                  width: 30
                }}
                className="pointer"
              />
            </a>
          )}
        </>
      );
    }
    return (
      <>
        <div
          className="kt-widget4__pic kt-widget4__pic--icon"
          onClick={files && files.length > 0 ? this.handleShow : undefined}
        >
          <img
            src={process.env.PUBLIC_URL + "/media/icons/pdf_icon.png"}
            alt="files"
            style={{
              width: 30,
              opacity: files.length > 0 ? 1 : 0.25
            }}
            className={files.length > 0 ? "pointer" : ""}
          />
        </div>
        <Modal show={show} onHide={this.handleHide} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>File for {lot}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row mt-4">
              <label className="col-2 col-form-label text-right text-dark kt-font-bold">Files</label>
              <div className="col-10">
                {files.map((f, key) => (
                  <div className="kt-widget4 mb-2" key={f.path + f.date.toString() + key}>
                    <div className="kt-widget4__item">
                      <div className="kt-widget4__pic kt-widget4__pic--icon">
                        <img src={process.env.PUBLIC_URL + "/media/icons/pdf_icon.png"} alt="pdf icon" />
                      </div>
                      <div className="kt-widget4__info">
                        <div>
                          <a
                            href={f.path}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="kt-widget4__username mr-2"
                          >
                            {(f.title ? f.title : "File #" + (key + 1)) + " - " + baseUtils.formatDate(f.date)}
                          </a>
                        </div>
                        <p className="kt-widget4__text">{dateUtils.getTimeAgo(f.date)}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default BatchFilesModal;
