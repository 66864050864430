import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Batch } from "../../../../model/warehouse/batch.types";
import { useDataContext } from "../../../../context/dataContext";
import { Reservation, ReservedMaterial } from "../../../../model/warehouse/reservation.types";
import { NumValue } from "../../../../model/common.types";
import i18n from "../../../../translations/i18n";
import baseUtils, { formatNumValue, getNumericValue } from "../../../../utils/baseUtils";
import { resolveTranslation } from "../../../../utils/translationUtils";
import { WarehouseTypes } from "../../../../model/configuration/warehouseConfiguration.types";
import {
  ExtendedBatchLocation,
  ExtendedPackagingUnit,
  ExtendedReservation
} from "../../../../model/warehouse/customTypes.types";
import { ExtendedLocationRow } from "./bookOutSpecificSelection/ExtendedLocationRow";
import { getExtendedLocationsDescription } from "../../../../utils/warehouseUtils";

interface BookOutSpecificSelectionProps {
  batch: Batch;
  amount: number;
  onChangeBatchLocationSettings: (locations: Array<ExtendedBatchLocation>) => void;
}

/**
 * Get default state extended batch locations
 * @param batch a batch document
 * @param amount preselected amount to be booked out
 * @param reservation list of reservations
 * @returns {Array<ExtendedBatchLocation>} list of extended batch locations
 */
const getDefaultState = (
  batch: Batch,
  amount: number,
  reservation: Array<Reservation>
): Array<ExtendedBatchLocation> => {
  let amountLeft = amount;
  const locations: Array<ExtendedBatchLocation> = [];
  batch.locations.forEach(l => {
    const isRemoteLocation = l.location.warehouseArea.type === WarehouseTypes.REMOTE;
    const existingReservations = reservation.filter(r =>
      r.materials.some(m => m.material.details._id.toString() === batch.content.details._id.toString())
    );

    let totalAmountToBookOut = 0;

    // Get extended pus
    const extendedPUs = l.packagingUnits.map(pU => {
      const totalAmount = isRemoteLocation ? 0 : pU.amountPerPu.value * (pU.quantity || 0);
      // Auto allocate amount to book out
      const amountToBookOut = totalAmount < amountLeft ? totalAmount : amountLeft;
      amountLeft -= amountToBookOut;
      totalAmountToBookOut += amountToBookOut;
      return { ...pU, amountToBookOut: amountToBookOut.toString() } as ExtendedPackagingUnit;
    });

    let remoteWarehouseData = null;
    if (isRemoteLocation) {
      // Auto allocate amount to book out for remote warehouses
      const amountToBookOut = l.amountAtLocation.value < amountLeft ? l.amountAtLocation.value : amountLeft;
      amountLeft -= amountToBookOut;
      totalAmountToBookOut += amountToBookOut;
      remoteWarehouseData = { amountToBookOut: amountToBookOut.toString() };
    }

    // If no reservations exist we are finished here
    if (existingReservations.length === 0) {
      locations.push({
        ...l,
        reservations: null,
        packagingUnits: extendedPUs,
        remoteWarehouseData
      });
    } else {
      // handle reservations
      let extendedReservations = existingReservations.map(r => {
        // get only relevant batches
        const reservedMaterials: Array<ReservedMaterial> = r.materials.filter(
          m => m.material.details._id.toString() === batch.content.details._id.toString()
        );
        // get total amount reserved for this batch and location
        let reservedAmountAtLocation: NumValue | null = null;
        reservedMaterials.forEach(b => {
          if (!reservedAmountAtLocation) reservedAmountAtLocation = b.reservedAmount;
          else reservedAmountAtLocation.value += b.reservedAmount.value;
        });
        if (!reservedAmountAtLocation) return null;
        return {
          ...r,
          amountToBookOut: "0",
          removeReservation: false,
          reservedAmount: reservedAmountAtLocation as NumValue
        } as ExtendedReservation;
      });
      // filter out null values (invalid reservations)
      let reservations = extendedReservations.filter(r => !!r) as Array<ExtendedReservation>;
      // Auto allocate amount to book out for reservations based of the amount allocated above for this location
      if (totalAmountToBookOut > 0 && reservations.length > 0) {
        reservations = reservations.map(r => {
          if (totalAmountToBookOut > 0) {
            const amountToBookOut =
              r.reservedAmount.value > totalAmountToBookOut ? totalAmountToBookOut : r.reservedAmount.value;
            totalAmountToBookOut -= amountToBookOut;
            return {
              ...r,
              amountToBookOut: amountToBookOut.toString(),
              removeReservation: r.reservedAmount.value * 0.9 < amountToBookOut
            };
          } else return r;
        });
      }

      locations.push({
        ...l,
        reservations: reservations.length > 0 ? reservations : null,
        packagingUnits: extendedPUs,
        remoteWarehouseData
      });
    }
  });
  return locations;
};

const BookOutSpecificSelection: React.FC<BookOutSpecificSelectionProps> = ({
  batch,
  amount,
  onChangeBatchLocationSettings
}) => {
  const dataContext = useDataContext();
  const { reservation } = dataContext;

  const [extendedLocations, setExtendedLocations] = useState<Array<ExtendedBatchLocation>>(
    getDefaultState(batch, amount, reservation)
  );

  useEffect(() => {
    onChangeBatchLocationSettings(extendedLocations);
  }, [extendedLocations]);

  const headerDefinition = useMemo(
    () => [
      { title: <i className="fas fa-lock text-muted" style={{ right: 0 }} />, size: 1 },
      { title: i18n.t("warehouse:warehouseShort") },
      { title: i18n.t("warehouse:storageSpace") },
      { title: i18n.t("warehouse:packagingUnitAbbreviation") },
      { title: i18n.t("warehouse:reservations") },
      { title: i18n.t("warehouse:availableAmount") }
    ],
    []
  );

  const reservedAmount = useMemo(
    () =>
      extendedLocations.reduce(
        (sum, l) => sum + (l.reservations?.reduce((sum2, r) => sum2 + r.reservedAmount.value, 0) || 0),
        0
      ),
    [extendedLocations]
  );

  const handleChangeReservationAmount = useCallback((e: React.ChangeEvent<HTMLInputElement>, locationId: string) => {
    const value = getNumericValue(e);
    const name = e.target.name;
    if (!value) return;
    setExtendedLocations(prevState => {
      const locationsCopy = _.cloneDeep(prevState);
      const loc = locationsCopy.find(l => l._id.toString() === locationId);
      if (!loc || !loc.reservations) return prevState;
      const reservation = loc.reservations.find(r => r._id.toString() === name);
      if (!reservation) return prevState;
      reservation.amountToBookOut = value;
      // If amount is close to the reserved amount set remove reservation to true
      reservation.removeReservation = reservation.reservedAmount.value * 0.9 < +value;
      return locationsCopy;
    });
  }, []);

  const handleReservationTakeAll = useCallback((locationId: string, reservationId: string) => {
    setExtendedLocations(prevState => {
      const locationsCopy = _.cloneDeep(prevState);
      const loc = locationsCopy.find(l => l._id.toString() === locationId);
      if (!loc || !loc.reservations) return prevState;
      const reservation = loc.reservations.find(r => r._id.toString() === reservationId);
      if (!reservation) return prevState;
      reservation.amountToBookOut = reservation.reservedAmount.value.toString();
      reservation.removeReservation = true;
      return locationsCopy;
    });
  }, []);

  const handleToggleReservationCheckbox = useCallback((e: React.ChangeEvent<HTMLInputElement>, locationId: string) => {
    const name = e.target.name;
    setExtendedLocations(prevState => {
      const locationsCopy = _.cloneDeep(prevState);
      const loc = locationsCopy.find(l => l._id.toString() === locationId);
      if (!loc || !loc.reservations) return prevState;
      const reservation = loc.reservations.find(r => r._id.toString() === name);
      if (!reservation) return prevState;
      // If amount is close to the reserved amount set remove reservation to true
      reservation.removeReservation = !reservation.removeReservation;
      return locationsCopy;
    });
  }, []);

  const handleChangePUAmount = useCallback((e: React.ChangeEvent<HTMLInputElement>, locationId: string) => {
    const value = getNumericValue(e);
    const name = e.target.name;
    if (!value) return;
    setExtendedLocations(prevState => {
      const locationsCopy = _.cloneDeep(prevState);
      const loc = locationsCopy.find(l => l._id.toString() === locationId);
      if (!loc) return prevState;
      const packagingUnit = loc.packagingUnits.find(p => p._id.toString() === name);
      if (!packagingUnit) return prevState;
      packagingUnit.amountToBookOut = value;
      return locationsCopy;
    });
  }, []);

  const handlePUTakeAll = useCallback((locationId: string, packagingUnitId: string) => {
    setExtendedLocations(prevState => {
      const locationsCopy = _.cloneDeep(prevState);
      const loc = locationsCopy.find(l => l._id.toString() === locationId);
      if (!loc) return prevState;
      const packagingUnit = loc.packagingUnits.find(p => p._id.toString() === packagingUnitId);
      if (!packagingUnit) return prevState;
      packagingUnit.amountToBookOut = (packagingUnit.amountPerPu.value * (packagingUnit.quantity || 0)).toString();
      return locationsCopy;
    });
  }, []);

  const handleChangeRemoteWarehouseAmount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, locationId: string) => {
      const value = getNumericValue(e);
      const name = e.target.name;
      if (!value) return;
      setExtendedLocations(prevState => {
        const locationsCopy = _.cloneDeep(prevState);
        const loc = locationsCopy.find(l => l._id.toString() === locationId);
        if (!loc || !loc.remoteWarehouseData) return prevState;
        loc.remoteWarehouseData.amountToBookOut = value;
        return locationsCopy;
      });
    },
    []
  );

  const handleRemoteWarehouseTakeAll = useCallback((locationId: string) => {
    setExtendedLocations(prevState => {
      const locationsCopy = _.cloneDeep(prevState);
      const loc = locationsCopy.find(l => l._id.toString() === locationId);
      if (!loc || !loc.remoteWarehouseData) return prevState;
      loc.remoteWarehouseData.amountToBookOut = loc.amountAtLocation.value.toString();
      return locationsCopy;
    });
  }, []);

  return (
    <>
      <div className="px-2 py-4 bg-light mx-auto" style={{ width: "100%" }}>
        <div className="row mx-3">
          <div className="col-6">
            <h5 className="text-black font-weight-bold mb-3 d-block">{i18n.t("warehouse:information")}</h5>
            <div className="form-group form-group-xs d-flex row w-100">
              <div className="col-6  text-black kt-font-bold">{i18n.t("warehouse:batch")}</div>
              <div className="col-6  text-black text-right">{batch.lot}</div>
            </div>
            <div className="form-group form-group-xs d-flex row w-100">
              <div className="col-6  text-black kt-font-bold">{i18n.t("warehouse:supplier")}</div>
              <div className="col-6  text-black text-right">{batch.sender.name}</div>
            </div>
            <div className="form-group form-group-xs d-flex row w-100">
              <div className="col-6  text-black kt-font-bold">{i18n.t("warehouse:totalAmount")}</div>
              <div className="col-6  text-black text-right">{formatNumValue(batch.totalAmount, 2)}</div>
            </div>
            <div className="form-group form-group-xs d-flex row w-100">
              <div className="col-6  text-black kt-font-bold">{i18n.t("warehouse:bbd")}</div>
              <div className="col-6  text-warning text-right">{baseUtils.formatDate(batch.expiry)}</div>
            </div>
          </div>
          <div className="col-6">
            <h5 className="text-black font-weight-bold mb-3 d-block">&nbsp;</h5>
            <div className="form-group form-group-xs d-flex row w-100">
              <div className="col-6  text-black kt-font-bold">{i18n.t(`warehouse:${batch.content.type}`)}</div>
              <div className="col-6  text-black text-right">{resolveTranslation(batch.content.details.title)}</div>
            </div>
            <div className="form-group form-group-xs d-flex row w-100">
              <div className="col-6  text-black kt-font-bold">{i18n.t("warehouse:locations")}</div>
              <div className="col-6  text-black text-right">
                {getExtendedLocationsDescription(batch.locations, false)}
              </div>
            </div>
            <div className="form-group form-group-xs d-flex row w-100">
              <div className="col-6  text-black kt-font-bold">{i18n.t("warehouse:reservedAmount")}</div>
              <div className="col-6  text-black text-right">
                {extendedLocations.length > 0
                  ? formatNumValue({ value: reservedAmount, unit: extendedLocations[0].amountAtLocation.unit }, 2)
                  : "-"}
              </div>
            </div>
            <div className="form-group form-group-xs d-flex row w-100">
              <div className="col-6  text-black kt-font-bold">{i18n.t("warehouse:entryDate")}</div>
              <div className="col-6  kt-font-bold text-success text-right">{baseUtils.formatDate(batch.stocked)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive mt-2">
        <table className="kt-datatable__table d-table ">
          <thead className="kt-datatable__head " style={{ display: "table-header-group" }}>
            <tr className="kt-datatable__row d-table-row">
              {headerDefinition.map((def, index) => (
                <th
                  key={index.toString()}
                  className="kt-datatable__cell d-table-cell"
                  style={{ width: `${def.size}%` }}
                >
                  <span className={"text-black"}>{def.title}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="kt-datatable__body " style={{ display: "table-row-group" }}>
            {extendedLocations.map(l => (
              <ExtendedLocationRow
                key={l._id.toString()}
                location={l}
                onChangeReservationAmount={handleChangeReservationAmount}
                onChangePUAmount={handleChangePUAmount}
                onChangeRemoteWarehouseAmount={handleChangeRemoteWarehouseAmount}
                onReservationTakeAll={handleReservationTakeAll}
                onPUTakeAll={handlePUTakeAll}
                onRemoteWarehouseTakeAll={handleRemoteWarehouseTakeAll}
                onToggleReservationCheckbox={handleToggleReservationCheckbox}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BookOutSpecificSelection;
