import { BSON } from "realm-web";
import orderUtils, { ARCHIVE, CREATEINVOICE, DECLINED } from "./orderUtils";
import { CompaniesDocument } from "../model/companies.types";
import { UserdataDocument } from "../model/userdata.types";
import { OrdersDocument } from "../model/orders.types";

/**
 * Get contact person and owner for a company
 * @param company the company document
 * @param userdata list of userdata
 * @returns tuple with contact person and owner for a company
 */
function getContactAndOwner(
  company: CompaniesDocument,
  userdata: Array<UserdataDocument>
): [UserdataDocument | undefined, UserdataDocument | undefined] {
  const contactId = company.contactPerson ? company.contactPerson.toString() : null;
  const ownerId = company.owner.toString();
  let ownerData;
  let contactData;
  for (let i = 0; i < userdata.length; i++) {
    const data = userdata[i];
    if (!contactData && contactId && data._id.toString() === contactId) {
      contactData = data;
      continue;
    }
    if (!ownerData && (data._id.toString() === ownerId || (data.user_id && data.user_id.toString() === ownerId))) {
      ownerData = data;
    }
  }
  return [contactData, ownerData];
}

/**
 * Calculate the total turnover for the given orders.
 * @param _id: ID of the company
 * @param orders: Orders that should be used for calculation
 * @returns { number } Total turnover of the company
 */
function calculateTotalTurnover(_id: BSON.ObjectId, orders: Array<OrdersDocument>) {
  const o = orders.filter(
    o => o.createdFor.toString() === _id.toString() && [CREATEINVOICE, ARCHIVE].includes(o.state)
  );
  let turnover = 0;
  for (let order of o) {
    if (orderUtils.hasFulfillmentPriceInfo(order)) turnover += order.fulfillment!.priceInfo!.totalPrice;
    else turnover += orderUtils.getAverageValue(order, "totalprice")[1];
  }
  return turnover;
}

/**
 * Collect the status of all open orders of the company.
 * @param _id: ID of the company
 * @param orders: Orders that should be used for status calculation
 * @returns { inTime: number, delayed: number } Amount of orders that are in time and delayed
 */
function getOrdersStatus(_id: BSON.ObjectId, orders: Array<OrdersDocument>) {
  const o = orders.filter(
    o => o.createdFor.toString() === _id.toString() && ![CREATEINVOICE, ARCHIVE, DECLINED].includes(o.state)
  );
  const status = { inTime: 0, delayed: 0 };
  const today = new Date().getTime();
  for (let order of o) {
    const target = order.targetDate?.getTime();
    if (!target || target <= today) {
      status.inTime += 1;
    } else {
      status.delayed += 1;
    }
  }
  return status;
}

// eslint-disable-next-line
export default {
  calculateTotalTurnover,
  getContactAndOwner,
  getOrdersStatus
};
