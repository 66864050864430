import _ from "lodash";
import React from "react";
import { CommoditiesDocument } from "../../../model/commodities.types";
import { DataContext } from "../../../context/dataContext";
import EmCommodityRelatedOrder from "../../externalManufacturers/commodities/EMCommodityRelatedOrder";

interface CommodityExternalOrdersProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
}

const CommodityExternalOrders: React.FunctionComponent<CommodityExternalOrdersProps> = ({ commodity, context }) => {
  const { externalManufacturerOrders } = context;
  const orders = _.orderBy(
    externalManufacturerOrders.filter(emo => emo.commodityId.toString() === commodity._id.toString()),
    o => o.createdOn,
    "desc"
  );
  return (
    <>
      {orders && orders.length > 0 ? (
        <div
          className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive"
          style={{ maxHeight: "70vh" }}
        >
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Order</th>
                <th style={{ width: "10%" }}>Created</th>
                <th style={{ width: "10%" }}>Status</th>
                <th style={{ width: "10%" }}>Amount</th>
                <th style={{ width: "12%" }}>Price</th>
                <th style={{ width: "13%" }}>Expected Delivery</th>
                <th style={{ width: "12%" }}>Limit Kilo Price</th>
                <th style={{ width: "13%" }}>Latest Delivery Date</th>
              </tr>
            </thead>
            <tbody>
              {orders.map(emo => (
                <EmCommodityRelatedOrder key={emo._id.toString()} order={emo} internal={true} />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center text-muted my-5">No external orders were created yet for this commodity</div>
      )}
    </>
  );
};

export default CommodityExternalOrders;
