import { StockTransferOrder } from "../model/commodities.types";
import { ManufacturersDocument } from "../model/manufacturers.types";
import baseUtils from "./baseUtils";
import { OrdersDocument } from "../model/orders.types";

/**
 * Resolve and extend destination and orders corresponding to the given parameters
 * @param stockTransferOrder the corresponding stock transfer order
 * @param manufacturers the corresponding manufacturer
 * @param orders the corresponding orders
 * @returns {{ManufacturersDocument,Array<OrdersDocument>}} an object containing the corresponding transport destination and extended order documents
 */
function resolveDestinationAndOrders(
  stockTransferOrder: StockTransferOrder,
  manufacturers: Array<ManufacturersDocument>,
  orders: Array<OrdersDocument>
) {
  const destination: ManufacturersDocument = baseUtils.getDocFromCollection(
    manufacturers,
    stockTransferOrder.destination
  );
  const extendedOrders: Array<OrdersDocument> = [];
  for (let i = 0; i < stockTransferOrder.orderIds.length; i++) {
    const o = baseUtils.getDocFromCollection(orders, stockTransferOrder.orderIds[i]);
    if (o) extendedOrders.push(o);
  }
  return { destination, extendedOrders };
}

export default { resolveDestinationAndOrders };
