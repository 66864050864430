import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { PaginationState } from "../common/CustomTypes";
import { paginate } from "../common/Pagination";
import { DataContext } from "../../context/dataContext";
import SplashScreen from "../common/SplashScreen";
import baseUtils, { getComponentState } from "../../utils/baseUtils";
import { RequestsDocument } from "../../model/requests.types";
import CompanyWidget from "../common/CompanyWidget";
import PersonWidget from "../common/PersonWidget";
import dateUtils from "../../utils/dateUtils";
import orderUtils, { CACHED_REQUEST } from "../../utils/orderUtils";
import { OwnerFilter, SearchBar } from "./common/Filters";
import HistoryBackButton from "./common/HistoryBackButton";
import BaseListing from "./BaseListing";

interface RequestsProps extends RouteComponentProps<{}, {}, {}> {}

interface RequestsState extends PaginationState {
  search: string;
  owner: "" | { value: string; label: string };
}

const CONSTRUCTORNAME = "Requests";

class Requests extends PureComponent<RequestsProps, RequestsState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: RequestsProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const state = getComponentState(this.context, CONSTRUCTORNAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }

  handleReset = () => this.setState(this.getDefaultState());
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value, currentPage: 1 });
  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value, currentPage: 1 });
  handleSelectChange = (name: string, entry: "" | { value: string; label: string }) => {
    // @ts-ignore
    this.setState({ [name]: entry ? { value: entry.value, label: entry.label } : "", currentPage: 1 });
  };
  /**
   * Get the default state
   * @returns {RequestsState} state for the component
   */
  getDefaultState = () => {
    return {
      currentPage: 1,
      pageSize: 10,
      search: "",
      owner: ""
    } as RequestsState;
  };

  /**
   * Get filtered requests
   * @returns {Array<RequestsDocument>} list of requests matching the filter or search
   */
  getFilteredRequests = () => {
    const { companies, userdata } = this.context;
    let requests = this.context.requests.slice().filter(r => r.state !== CACHED_REQUEST);
    const { search, owner } = this.state;

    if (owner) {
      requests = requests.filter(r => r.createdFrom && r.createdFrom.toString() === owner.value);
    }
    if (search) {
      requests = orderUtils.filterBySearch(requests, companies, userdata, search) as Array<RequestsDocument>;
    }
    return requests.sort((r1, r2) => r2.createdOn.getTime() - r1.createdOn.getTime());
  };

  render() {
    const { requests, userdata } = this.context;
    const { history } = this.props;
    const { currentPage, pageSize, owner, search } = this.state;
    const filteredRequests = this.getFilteredRequests();
    const selectableUsers = userdata.filter(user => user.company_id === "internal");
    const headerDefinition = [
      { title: "Description", size: 20 },
      { title: "Company", size: 20 },
      { title: "Created", size: 10 },
      { title: "Units", size: 10 },
      { title: "Creation Date", size: 10 },
      { title: "Owner", size: 15 },
      { title: "Action", size: 15 }
    ];
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand flaticon-download-1" />
            </span>
            <h3 className="kt-portlet__head-title">Open Requests</h3>
            <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
              Reset
            </button>
          </div>
          <HistoryBackButton history={history} />
        </div>
        <div className="kt-portlet__body pb-0">
          <div className="kt-form kt-form--label-right  kt-margin-b-10">
            <div className="row align-items-center">
              <SearchBar onSearch={this.handleSearch} search={search} />
              <OwnerFilter
                owner={owner}
                selectableUsers={selectableUsers}
                onFilterChange={this.handleSelectChange}
                additionalSizeClasses={"col-md-3"}
              />
            </div>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          {requests.length === 0 ? (
            <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
          ) : (
            <BaseListing
              headerDefinition={headerDefinition}
              documents={filteredRequests}
              bodyContent={
                <>
                  {paginate(filteredRequests, currentPage, pageSize).map(req => (
                    <RequestsRow key={req._id.toString()} request={req} context={this.context} {...this.props} />
                  ))}
                </>
              }
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
            />
          )}
        </div>
      </div>
    );
  }
}

interface RequestsRowProps extends RouteComponentProps {
  request: RequestsDocument;
  context: React.ContextType<typeof DataContext>;
}

const RequestsRow: React.FunctionComponent<RequestsRowProps> = ({ history, request, context }) => {
  const { userdata, companies } = context;
  const company = baseUtils.getDocFromCollection(companies, request.createdFor);
  const owner = request.createdFrom ? baseUtils.getDocFromCollection(userdata, request.createdFrom) : null;
  const daysCreatedAgo = dateUtils.getCreatedDaysAgo(request.createdOn);

  return (
    <tr className="kt-datatable__row d-table-row">
      <td className="kt-datatable__cell d-table-cell d-table-cell">
        <span>
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__pic">
              <div className="kt-badge kt-badge--xl kt-badge--primary">
                {request.settings.type && (
                  <img
                    src={process.env.PUBLIC_URL + "/media/icons/" + request.settings.type + ".png"}
                    className="p-1"
                  />
                )}
              </div>
            </div>
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name">{"Request REQ-" + request.identifier}</span>
              <span className="kt-user-card-v2__email">{request.title}</span>
            </div>
          </div>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <CompanyWidget company={company} type={"company"} />
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-font-dark kt-font-bold">
          {request.recipe.length + (request.recipe.length === 1 ? " commodity" : " commodities")}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-font-dark kt-font-bold">
          {request.calculations.length === 1
            ? request.calculations[0].units
            : request.calculations.map(c => (c.units >= 1000 ? c.units / 1000 : c.units)).join(", ") + " tsd."}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-font-dark kt-font-bold">
          <span
            className={
              "kt-badge kt-badge--success kt-badge--inline kt-badge--pill " + (daysCreatedAgo > 1 && "kt-badge--danger")
            }
          >
            {request.createdOn.toLocaleDateString("de-DE", {
              month: "numeric",
              day: "numeric",
              year: "numeric"
            })}
          </span>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>{owner ? <PersonWidget person={owner} /> : <span className="text-muted h6">Unassigned</span>}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <button
            type="button"
            className="btn btn-success btn-sm"
            onClick={() => history.push("/calculation/request/" + request._id.toString())}
          >
            <i className="fa fa-pen" />
            Create Offer
          </button>
        </span>
      </td>
    </tr>
  );
};

export default Requests;
