import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import CompanyWidget from "../../common/CompanyWidget";
import { DataContext } from "../../../context/dataContext";
import { OrdersDocument } from "../../../model/orders.types";
import dbService, { PACKAGINGS } from "../../../services/dbService";
import orderUtils, { ARCHIVE, CREATEINVOICE, DECLINED } from "../../../utils/orderUtils";
import toastUtils from "../../../utils/toastUtils";
import accessUtils, { DELETELOCATIONS } from "../../../utils/accessUtils";
import { PackagingsDocument } from "../../../model/packagings.types";
import packagingUtils from "../../../utils/packagingUtils";

interface DisablePackagingModalProps {
  packaging: PackagingsDocument;
  context: React.ContextType<typeof DataContext>;
}

interface DisablePackagingModalState {
  show: boolean;
  saving: boolean;
}

class DisablePackagingModal extends PureComponent<DisablePackagingModalProps, DisablePackagingModalState> {
  constructor(props: DisablePackagingModalProps) {
    super(props);
    this.state = { show: false, saving: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false, saving: false });

  /**
   * Handles disabling a packaging. If the packaging is already disabled it is enabled.
   */
  handleDisablePackaging = async () => {
    const { packaging, context } = this.props;
    this.setState({ saving: true });
    const disabled = !packaging.disabled;
    const res = await dbService.updateDocument(PACKAGINGS, packaging._id, { disabled });
    await toastUtils.databaseOperationToast(
      !!res && res.modifiedCount > 0,
      "Packaging " + (disabled ? "disabled" : "enabled") + " successfully",
      "Error " + (disabled ? "disabling" : "enabling") + " packaging",
      () => context.updateDocumentInContext(PACKAGINGS, packaging._id)
    );
    this.setState({ show: false, saving: false });
  };

  /**
   * Calculate the used amount of the packaging inside the given order.
   * @param o: Order whose usage should be calculated.
   * @returns { string } Used amount with correct unit
   */
  calculateUsedAmount = (o: OrdersDocument) => {
    const { packaging } = this.props;
    const price = o.calculations[0].packagings.find(p => p._id.toString() === packaging._id.toString());
    if (!price) return "-";
    return price.amount;
  };

  /**
   * Collect all orders that use the given packaging.
   * @returns { Array<OrdersDocument> } All, active, orders that use the given packaging
   */
  getOrdersWithPackaging = () => {
    const { packaging, context } = this.props;
    const { orders } = context;
    return orders.filter(
      o =>
        ![ARCHIVE, DECLINED, CREATEINVOICE].includes(o.state) &&
        o.calculations[0].packagings.some(p => p._id.toString() === packaging._id.toString())
    );
  };

  render() {
    const { context, packaging } = this.props;
    const { saving, show } = this.state;
    const { companies } = context;
    const ordersWithPackaging: Array<OrdersDocument> = this.getOrdersWithPackaging();
    const canDisable = accessUtils.canDeleteData(DELETELOCATIONS.PACKAGING);
    const description = packagingUtils.getShortPackagingInfo(packaging);
    return (
      <>
        <button
          type="button"
          className={"btn btn-secondary btn-sm btn-upper" + (saving || !canDisable ? " disabled" : "")}
          onClick={canDisable ? (packaging.disabled ? this.handleDisablePackaging : this.handleShow) : undefined}
          disabled={saving || !canDisable}
        >
          <i className={packaging.disabled ? "fa fa-plus" : "fas fa-times"} />
          {packaging.disabled ? "Enable" : "Disable"}
        </button>
        <Modal show={show} centered onHide={this.handleHide} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Disable {description}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {ordersWithPackaging.length === 0 ? (
              <>
                <div
                  className="alert alert-secondary mt-4 font-weight-bolder"
                  role="alert"
                  style={{ background: "white", border: 0 }}
                >
                  <div className="alert-icon">
                    <i className="flaticon-exclamation-1" style={{ color: "red" }} />
                  </div>
                  <div className="text-dark h5 my-auto">
                    Do you really want to disable {description}?
                    <br />
                    Disabled packaging can no longer be used for new calculations.
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="alert alert-warning" role="alert">
                  <div className="alert-icon">
                    <i className="flaticon-warning" />
                  </div>
                  <div className="alert-text">
                    Packaging {description} can not be disabled since it is used for {ordersWithPackaging.length}{" "}
                    orders.
                    <br />
                    Please decline / finish the offers / orders below. Then the packaging can be disabled.
                  </div>
                </div>
                <table className="table overflow-auto" style={{ maxHeight: "60vh" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "15%" }}>AN/AT</th>
                      <th style={{ minWidth: "35%" }}>Title</th>
                      <th style={{ width: "35%" }}>Customer</th>
                      <th style={{ width: "15%" }}>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ordersWithPackaging.map(o => {
                      return (
                        <tr key={o._id.toString()}>
                          <td className="align-middle">
                            <Link to={"/order/" + o._id.toString()} className="kt-link kt-font-dark kt-font-bolder">
                              {orderUtils.resolveOrderDescription(o)[1]}-{o.identifier}
                            </Link>
                          </td>
                          <td className="align-middle">{o.title ? o.title : "-"}</td>
                          <td className="align-middle">
                            <CompanyWidget
                              company={companies.find(c => c._id.toString() === o.createdFor.toString())!}
                            />
                          </td>
                          <td className="align-middle">{this.calculateUsedAmount(o)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            <button
              className={"btn btn-secondary" + (saving || ordersWithPackaging.length > 0 ? " disabled" : "")}
              onClick={this.handleDisablePackaging}
              disabled={saving || ordersWithPackaging.length > 0}
            >
              Disable
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default DisablePackagingModal;
