import React, { PureComponent } from "react";
import { Nav, Tab } from "react-bootstrap";
import { CustomOrder } from "./CustomTypes";
import orderUtils from "../../utils/orderUtils";
import OrderTimeline from "./tabPanles/OrderTimeline";
import OrderNotes from "./tabPanles/OrderNotes";
import OrderDetails from "./tabPanles/OrderDetails";
import OrderFiles from "./tabPanles/OrderFiles";
import OrderFulfillment from "./tabPanles/OrderFulfillment";
import OrderFinance from "./tabPanles/OrderFinance";
import { DataContext } from "../../context/dataContext";
import OrderContract from "./tabPanles/contract/OrderContract";
import OrderMaterials from "./tabPanles/OrderMaterials";
import { TabDefinition } from "../common/CustomTypes";
import accessUtils, { ACCESS_AREAS } from "../../utils/accessUtils";
import OrderConversationNotes from "./tabPanles/OrderConversationNotes";
import contractUtils from "../../utils/contractUtils";

interface OrderTabPanelProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
  setRef: (ref: any) => void;
}

interface OrderTabPanelState {
  currentTab: string;
}

class OrderTabPanel extends PureComponent<OrderTabPanelProps, OrderTabPanelState> {
  constructor(props: OrderTabPanelProps) {
    super(props);
    this.state = {
      currentTab: this.getTabDefinitions()[0].name
    };
  }

  getTabDefinitions = (): Array<TabDefinition> => {
    const { order, context } = this.props;
    const unreadCustomerNotes = orderUtils.getUnreadCustomerNotes(order, context.userdata, context.companies);
    return [
      {
        name: "timeline",
        tabContent: (
          <>
            <i className="flaticon2-time" /> Timeline
          </>
        ),
        component: <OrderTimeline order={order} context={context} />,
        condition: () => true
      },
      {
        name: "details",
        tabContent: (
          <div id="detailsTab">
            <i className="flaticon2-list-1" />
            Details
          </div>
        ),
        component: <OrderDetails order={order} context={context} />,
        condition: () => true
      },
      {
        name: "contract",
        tabContent: (
          <>
            <i className="flaticon2-pie-chart" /> Contract
          </>
        ),
        component: <OrderContract order={this.props.order} orders={this.props.context.orders} />,
        condition: (order: CustomOrder) => contractUtils.isContract(order)
      },
      {
        name: "files",
        tabContent: (
          <>
            <i className="flaticon2-file" /> Files
          </>
        ),
        component: <OrderFiles order={order} context={context} />,
        condition: () => true
      },
      {
        name: "notes",
        tabContent: (
          <>
            <i className="flaticon2-pen" /> Notes
          </>
        ),
        component: <OrderNotes order={order} context={context} />,
        condition: () => true
      },
      {
        name: "customerNotes",
        tabContent: (
          <>
            <i className="flaticon2-chat" /> Order Chat{" "}
            {unreadCustomerNotes.length > 0 ? "(" + unreadCustomerNotes.length + ")" : ""}
          </>
        ),
        component: <OrderConversationNotes order={order} context={context} />,
        condition: () =>
          accessUtils.canAccessOneOfAreas([
            ACCESS_AREAS.SALES,
            ACCESS_AREAS.FINANCE,
            ACCESS_AREAS.PROCUREMENT_NOT_PRODUCTION
          ])
      },
      {
        name: "commodities",
        tabContent: (
          <>
            <i className="flaticon2-delivery-truck" /> Commodities
          </>
        ),
        component: <OrderMaterials order={order} context={context} ref={this.props.setRef} />,
        condition: () => true
      },
      {
        name: "fulfillment",
        tabContent: (
          <>
            <i className="flaticon2-box" /> Fulfillment
          </>
        ),
        component: <OrderFulfillment order={order} context={context} />,
        condition: () => true
      },
      {
        name: "finance",
        tabContent: (
          <>
            <i className="flaticon2-box" /> Finance
          </>
        ),
        component: <OrderFinance order={order} context={context} />,
        condition: () => accessUtils.canAccessOneOfAreas([ACCESS_AREAS.SALES, ACCESS_AREAS.FINANCE])
      }
    ];
  };

  handleTab = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
  };

  renderTabs = (tabDefinition: Array<TabDefinition>) => {
    return (
      <ul
        id={"orderTabPanelTabs"}
        className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand"
        role="tablist"
      >
        {tabDefinition.map(tab => {
          if (!tab.condition || tab.condition(this.props.order))
            return (
              <li className="nav-item" key={tab.name}>
                {this.renderTab(tab.name, tab.tabContent)}
              </li>
            );
        })}
      </ul>
    );
  };

  renderTab = (name: string, content: JSX.Element) => {
    return (
      <Nav.Link role="tab" id={name} eventKey={name} active={name === this.state.currentTab}>
        {content}
      </Nav.Link>
    );
  };

  render() {
    const { order } = this.props;
    const { currentTab } = this.state;
    const tabDefinition = this.getTabDefinitions();
    return (
      <Tab.Container id="tabContainer" activeKey={currentTab} onSelect={this.handleTab}>
        <div className="kt-portlet kt-portlet--tabs" style={{ minHeight: "100%" }}>
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-toolbar">{this.renderTabs(tabDefinition)}</div>
          </div>
          <div className="kt-portlet__body">
            <Tab.Content>
              {tabDefinition.map(tab => {
                if (!tab.condition || tab.condition(order))
                  return (
                    <Tab.Pane key={tab.name} eventKey={tab.name} transition={false}>
                      {tab.component}
                    </Tab.Pane>
                  );
              })}
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    );
  }
}

export default OrderTabPanel;
