import { OrdersDocument } from "../../model/orders.types";
import { T_SERVICE } from "../order/OrderHelper";

export const V_ORDERCOMMODITIES = "orderCommodities";
export const V_ORDERPACKAGING = "orderPackaging";
export const V_WAITING = "waiting";
export const V_READY = "ready";
export const V_PRODUCTION = "production";
export const V_FULFILLMENT = "fulfillment";
export const V_INVOICING = "invoicing";

export const VIEWS = [
  { value: "", label: "Default view" },
  { value: V_ORDERCOMMODITIES, label: "Order commodities view" },
  { value: V_ORDERPACKAGING, label: "Order packaging view" },
  { value: V_WAITING, label: "On hold view" },
  { value: V_READY, label: "Production queue view" },
  { value: V_PRODUCTION, label: "Production view" },
  { value: V_FULFILLMENT, label: "Fulfillment view" },
  { value: V_INVOICING, label: "Invoicing view" }
];

export const VIEWS_PRODUCTION = [
  { value: "", label: "Default view" },
  { value: V_ORDERCOMMODITIES, label: "Order commodities view" },
  { value: V_ORDERPACKAGING, label: "Order packaging view" },
  { value: V_WAITING, label: "On hold view" },
  { value: V_READY, label: "Production queue view" },
  { value: V_PRODUCTION, label: "Production view" },
  { value: V_FULFILLMENT, label: "Fulfillment view" }
];

/**
 * Filter orders according to the current view
 * @param orders list of order documents
 * @param view current view to filter for
 * @returns {Array<OrdersDocument>} list of filtered orders
 */
function filterByView(orders: Array<OrdersDocument>, view: string) {
  switch (view) {
    case V_ORDERCOMMODITIES:
    case V_ORDERPACKAGING:
    case V_WAITING:
    case V_READY:
    case V_PRODUCTION:
    case V_FULFILLMENT:
    case V_INVOICING:
      return orders.filter(o => o.settings.type !== T_SERVICE);
    case "":
    default:
      return orders.slice();
  }
}

export default { filterByView };
