import _ from "lodash";
import React from "react";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import countryList from "i18n-iso-countries";
import { DataContext } from "../../../context/dataContext";
import { CommodityBatch } from "../../../model/commodities.types";
import CommodityLocalStock from "../../commodities/CommodityLocalStock";
import commodityUtils from "../../../utils/commodityUtils";
import toastUtils from "../../../utils/toastUtils";
import { COMMODITIES } from "../../../services/dbService";
import CommodityBaseImage from "../../commodities/CommodityBaseImage";
import CommoditiesStockStatistics from "./CommoditiesStockStatistics";
import { CommodityExtended } from "../StockListing";

interface CommoditiesStockAccordionContentProps {
  extendedCommodity: CommodityExtended;
  isActive: boolean;
  level: number;
  onToggleClick: (e: number) => void;
  showDisabled: boolean;
  context: React.ContextType<typeof DataContext>;
}

const CommoditiesStockContent: React.FunctionComponent<CommoditiesStockAccordionContentProps> = ({
  extendedCommodity,
  isActive,
  level,
  onToggleClick,
  showDisabled,
  context
}: CommoditiesStockAccordionContentProps) => {
  /**
   * Handles the update of the stock of the commodity.
   * @param stock: Stock after update
   * @param saveChanges: If True the changes are saved to database
   */
  const handleUpdateStock = async (stock: Array<CommodityBatch>, saveChanges: boolean) => {
    const { updateDocumentInContext } = context;
    const commodityEdited = _.cloneDeep(extendedCommodity);
    commodityEdited.commodity.stock = stock;
    if (saveChanges) {
      const res = await commodityUtils.updateCommodityWithTimeline(
        extendedCommodity.commodity,
        commodityEdited.commodity
      );
      await toastUtils.databaseOperationToast(!!res, "Stock updated successfully", "Error updating stock", () =>
        updateDocumentInContext(COMMODITIES, extendedCommodity.commodity._id)
      );
    }
  };

  const { stock, ordered, reserved, available } = extendedCommodity.stockStatistics;
  const localStock = extendedCommodity.manufacturerRelatedLocalStock;
  const localStockDisabled = extendedCommodity.manufacturerRelatedDisabledLocalStock;
  const commodity = extendedCommodity.commodity;
  const id = commodity._id.toString();
  const type = commodity.type;
  const titleEn = commodity.title.en;
  const subTitleEn = commodity.subtitle.en;
  const country = commodity.country;
  const categoryName = extendedCommodity.categoryName;
  return (
    <>
      {localStock && (
        <div className="kt-portlet">
          <Accordion.Toggle as={"div"} className={"kt-portlet__head accordionToggle "} eventKey={isActive ? "1" : "0"}>
            <div className="row w-100 m-1" onClick={() => onToggleClick(level)}>
              <div className="col-2 align-self-center">
                <div className="kt-user-card-v2">
                  <div className="kt-user-card-v2__pic">
                    <div className="kt-widget__media">
                      <CommodityBaseImage commodity={commodity} context={context} />
                    </div>
                  </div>
                  <div className="kt-user-card-v2__details m-3">
                    <span className="kt-user-card-v2__name">
                      <Link
                        to={"/commodity/" + id}
                        className="kt-user-card-v2__name pr-2"
                        style={{ display: "inline" }}
                      >
                        {titleEn}
                      </Link>
                      {country && (
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 100, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip product-popover">{countryList.getName(country, "en")}</Tooltip>
                          }
                        >
                          <img
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 14,
                              objectFit: "cover"
                            }}
                            alt={country}
                            src={"media/icons/countries/" + country.toLowerCase() + ".png"}
                            className="country-icon"
                          />
                        </OverlayTrigger>
                      )}
                    </span>
                    <span className="kt-user-card-v2__email">{subTitleEn}</span>
                  </div>
                </div>
              </div>
              <div className="col-2 align-self-center">
                <span
                  className="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded"
                  style={{
                    backgroundColor: commodityUtils.getCategoryColor(categoryName ? categoryName : ""),
                    color: categoryName && categoryName === "probiotics" ? "black" : "white",
                    display: "inline-block"
                  }}
                >
                  {categoryName ? categoryName : "unknown"}
                </span>
              </div>
              <CommoditiesStockStatistics
                stock={stock}
                ordered={ordered}
                reserved={reserved}
                available={available}
                commodityType={type}
                outerClassName={"col-8 align-self-center w-100 row"}
                innerClassName={"col-3 align-self-center"}
              />
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={isActive ? "1" : "0"}>
            <div className="m-5">
              <CommodityLocalStock
                localStock={localStock}
                localDisabledStock={localStockDisabled}
                commodity={commodity}
                onStockUpdate={handleUpdateStock}
                context={context}
                showDisabledStock={showDisabled}
                disableStatistics
              />
            </div>
          </Accordion.Collapse>
        </div>
      )}
    </>
  );
};
export default CommoditiesStockContent;
