import React, { PureComponent } from "react";
import { SuppliersDocument } from "../../model/suppliers.types";
import { UserdataDocument } from "../../model/userdata.types";
import RatingInfo from "../common/RatingInfo";
import CompanyWidget from "../common/CompanyWidget";
import PersonWidget from "../common/PersonWidget";

interface SupplierOverviewItemProps {
  supplier: SuppliersDocument;
  owner?: UserdataDocument;
  contactPerson?: UserdataDocument;
}

interface SupplierOverviewItemState {}

class SupplierOverviewItem extends PureComponent<SupplierOverviewItemProps, SupplierOverviewItemState> {
  render() {
    const { supplier, owner, contactPerson } = this.props;
    return (
      <tr className="kt-datatable__row table-hover d-table-row">
        <td className="kt-datatable__cell d-table-cell">
          <CompanyWidget company={supplier} type={"supplier"} />
        </td>
        <td className="kt-datatable__cell d-table-cell">
          {contactPerson ? <PersonWidget person={contactPerson} /> : "unknown"}
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className="mx-auto">
            <RatingInfo rating={supplier.rating} />
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell ">{owner ? <PersonWidget person={owner} /> : "Unknown"}</td>
      </tr>
    );
  }
}

export default SupplierOverviewItem;
