import _ from "lodash";
import React, { PureComponent, useCallback, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Select from "react-select";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { DataContext } from "../../context/dataContext";
import { CommoditiesDocument } from "../../model/commodities.types";
import fileUtils, { DEFAULT_SEPARATOR, purgeStringForCSV } from "../../utils/fileUtils";
import baseUtils from "../../utils/baseUtils";
import dateUtils from "../../utils/dateUtils";
import DateInput from "../common/DateInput";
import orderUtils, { DECLINED } from "../../utils/orderUtils";
import { I_CANCELED } from "../../utils/invoiceUtils";
import { OrdersDocument } from "../../model/orders.types";
import { useStateWithCallback } from "../../utils/useStateCallback";
import { CompaniesDocument } from "../../model/companies.types";
import { SuppliersDocument } from "../../model/suppliers.types";
import commodityUtils from "../../utils/commodityUtils";

interface TopPerformersProps extends RouteComponentProps {}

class TopPerformers extends PureComponent<TopPerformersProps> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  render() {
    return (
      <>
        <TopCommodities context={this.context} {...this.props} />
        <TopSuppliers />
        <TopCustomers />
      </>
    );
  }
}

interface TopCommoditiesProps extends RouteComponentProps {
  context: React.ContextType<typeof DataContext>;
}

interface TopCommoditiesState {
  supplier: { value: string; label: string } | "";
  generating: boolean;
}

class TopCommodities extends PureComponent<TopCommoditiesProps, TopCommoditiesState> {
  constructor(props: TopCommoditiesProps) {
    super(props);
    this.state = { generating: false, supplier: "" };
  }

  handleChangeSupplier = (supplier: { value: string; label: string } | "") => this.setState({ supplier });

  handleGenerateCSV = () => {
    const { supplier } = this.state;
    const { commodities } = this.props.context;

    this.setState({ generating: true }, () => {
      const commodityPerformance: Array<{ commodity: CommoditiesDocument; turnover: number; orders: number }> = [];

      try {
        for (let i = 0; i < commodities.length; i++) {
          const c = commodities[i];
          if (!commodityUtils.isCommodityApproved(c)) continue;
          if (!c.orders) continue;
          let turnover = 0;
          let ordersCount = 0;
          for (let j = 0; j < c.orders.length; j++) {
            const o = c.orders[j];
            if (supplier && o.supplier && supplier.value !== o.supplier.toString()) continue;
            turnover += o.totalPrice;
            ordersCount++;
          }
          if (ordersCount > 0 && turnover > 0)
            commodityPerformance.push({ commodity: c, turnover, orders: ordersCount });
        }
        const headers = ["ID", "Commodity", "Volume in €", "Amount of Orders"];
        const dataPrepared = commodityPerformance.map(c => [
          c.commodity._id.toString(),
          purgeStringForCSV(c.commodity.title.en, DEFAULT_SEPARATOR),
          c.turnover,
          c.orders
        ]);
        const csv = fileUtils.exportAsCSV(
          headers,
          _.sortBy(dataPrepared, c => -c[2]),
          DEFAULT_SEPARATOR
        );
        fileUtils.downloadFile(
          csv,
          "TopCommodities_" + (supplier ? supplier.label + "_" : "") + baseUtils.formatDate(new Date()) + ".csv",
          "text/plain"
        );
      } catch (e) {
        toast.error("Error generating csv");
        console.error(e);
      } finally {
        this.setState({ generating: false });
      }
    });
  };

  render() {
    const { history, context } = this.props;
    const { supplier, generating } = this.state;
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Top Performing Commodities</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <button
                onClick={() => {
                  history.goBack();
                }}
                className="btn btn-clean kt-margin-r-10"
              >
                <i className="la la-arrow-left" />
                <span className="kt-hidden-mobile">Back</span>
              </button>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row mt-2">
            <div className="col text-dark font-weight-bolder mb-5">
              Exports the trading volume of all commodities from the system. If supplier is set only the results for a
              single supplier is shown.
            </div>
          </div>
          <div className="row">
            <div className="col col-6 col-md-4 col-lg-3 align-self-center">Supplier</div>
            <div className="col col-6 col-md-8 col-lg-3">
              <Select
                className="select-default"
                options={context.suppliers.map(s => {
                  return { value: s._id.toString(), label: s.name };
                })}
                isClearable={true}
                value={supplier || { value: "", label: "Select Supplier (Optional)" }}
                onChange={(e: any) => this.handleChangeSupplier(e || "")}
              />
            </div>
          </div>
        </div>
        <div className="kt-portlet__foot">
          <div className="float-right">
            <button
              className="btn btn-success"
              disabled={generating}
              onClick={generating ? undefined : this.handleGenerateCSV}
            >
              {generating ? "Generating..." : "Generate CSV"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

enum ExportTypes {
  TURNOVER,
  AMOUNT
}

const EXPORT_OPTIONS = [
  {
    value: ExportTypes.TURNOVER,
    label: "Turnover"
  },
  {
    value: ExportTypes.AMOUNT,
    label: "Amount in kg"
  }
];

const TopSuppliers: React.FunctionComponent = () => {
  const context = React.useContext(DataContext);
  const [type, setType] = useState<ExportTypes>(ExportTypes.TURNOVER);
  const [count, setCount] = useState<number>(20);
  const [period, setPeriod] = useState<{ start: Date; end: Date }>(dateUtils.getDefaultPeriod());
  const [generating, setGenerating] = useStateWithCallback<boolean>(false);

  const handleChangeType = useCallback((e: any) => {
    setType(e.value);
  }, []);

  const handlePeriodChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as "start" | "end";
    let value = e.target.valueAsDate;
    setPeriod(prevPeriod => {
      const newPeriod = _.cloneDeep(prevPeriod);
      if (!value) return prevPeriod;
      if (name === "start") value.setHours(0, -value.getTimezoneOffset(), 0, 0);
      else value.setHours(23, 59 + -value.getTimezoneOffset(), 59, 999);
      _.set(newPeriod, name, value);
      return newPeriod;
    });
  }, []);

  const handleGenerateCSV = useCallback(() => {
    const { suppliers, commodities } = context;
    const { start, end } = period;
    setGenerating(true, () => {
      try {
        const supplierMap: { [supplierId: string]: number } = {};

        for (let i = 0; i < commodities.length; i++) {
          const commodity = commodities[i];
          // Skip customs and softgels as amount in kg
          if (!commodity.orders || (type === ExportTypes.AMOUNT && commodity.type)) continue;
          for (let j = 0; j < commodity.orders.length; j++) {
            const cOrder = commodity.orders[j];
            // Skip orders with invalid supplier and orders that are not delivered yet or where not delivered within the given period
            if (
              !cOrder.supplier ||
              !BSON.ObjectId.isValid(cOrder.supplier) ||
              !cOrder.delivered ||
              !(start <= cOrder.delivered && cOrder.delivered <= end)
            )
              continue;
            let value = 0;
            if (type === ExportTypes.TURNOVER) value += cOrder.totalPrice;
            else value += cOrder.orderquantity;

            const supplierId = cOrder.supplier.toString();
            if (supplierId in supplierMap) supplierMap[supplierId] += value;
            else supplierMap[supplierId] = value;
          }
        }

        const supplierEntries = _.orderBy(Object.entries(supplierMap), e => e[1], "desc").slice(0, count);
        const supplierData = supplierEntries.map(([sId, value]: [sId: string, value: number]) => {
          const supplier: SuppliersDocument | undefined = baseUtils.getDocFromCollection(suppliers, sId);
          if (type === ExportTypes.AMOUNT)
            return [
              purgeStringForCSV(supplier?.name || "unknown", DEFAULT_SEPARATOR),
              (Math.round(value * 100) / 100).toLocaleString() + " kg"
            ];
          return [purgeStringForCSV(supplier?.name || "unknown", DEFAULT_SEPARATOR), baseUtils.formatEuro(value)];
        });

        const headers = ["Lieferant", type === ExportTypes.TURNOVER ? "Umsatz in €" : "Menge in kg"];
        const fileName = `Top${count !== Infinity ? count : ""}SuppliersBy${
          type === ExportTypes.TURNOVER ? "Turnover" : "Amount"
        }_${baseUtils.formatDate(start)}-${baseUtils.formatDate(end, undefined, {
          timeZone: "UTC"
        })}_${baseUtils.formatDate(new Date())}.csv`;

        const csv = fileUtils.exportAsCSV(headers, supplierData, DEFAULT_SEPARATOR);
        fileUtils.downloadFile(csv, fileName, "text/plain");
      } finally {
        setGenerating(false);
      }
    });
  }, [period, context, type, count]);

  return (
    <div className="kt-portlet kt-portlet--mobile">
      <div className="kt-portlet__head kt-portlet__head--lg">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Top Performing Suppliers</h3>
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="row mt-2">
          <div className="col text-dark font-weight-bolder mb-5">
            Exports the top suppliers by turnover or amount in kg for a given period
          </div>
        </div>
        <div className="row">
          <div className="col col-6 col-md-4 col-lg-3 align-self-center">Performance Indicator</div>
          <div className="col col-6 col-md-8 col-lg-3">
            <Select
              className="select-default"
              options={EXPORT_OPTIONS}
              value={EXPORT_OPTIONS.find(o => o.value === type)}
              onChange={handleChangeType}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col col-6 col-md-4 col-lg-3 align-self-center">Top</div>
          <div className="col col-6 col-md-8 col-lg-3">
            <select className="form-control" value={count} onChange={e => setCount(+e.currentTarget.value)}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={Infinity}>All</option>
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col col-6 col-md-4 col-lg-3 align-self-center">From</div>
          <div className="col col-6 col-md-8 col-lg-3">
            <DateInput
              value={period.start}
              min={dateUtils.getDefaultPeriod().start}
              onBlur={handlePeriodChange}
              name={"start"}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col col-6 col-md-4 col-lg-3 align-self-center">To</div>
          <div className="col col-6 col-md-8 col-lg-3">
            <DateInput value={period.end} max={new Date()} onBlur={handlePeriodChange} name={"end"} />
          </div>
        </div>
      </div>
      <div className="kt-portlet__foot">
        <div className="float-right">
          <button
            className="btn btn-success"
            disabled={generating}
            onClick={generating ? undefined : handleGenerateCSV}
          >
            {generating ? "Generating..." : "Generate CSV"}
          </button>
        </div>
      </div>
    </div>
  );
};

const TopCustomers: React.FunctionComponent = () => {
  const context = React.useContext(DataContext);
  const [count, setCount] = useState<number>(20);
  const [period, setPeriod] = useState<{ start: Date; end: Date }>(dateUtils.getDefaultPeriod());
  const [generating, setGenerating] = useStateWithCallback<boolean>(false);

  const handlePeriodChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as "start" | "end";
    let value = e.target.valueAsDate;
    setPeriod(prevPeriod => {
      const newPeriod = _.cloneDeep(prevPeriod);
      if (!value) return prevPeriod;
      if (name === "start") value.setHours(0, -value.getTimezoneOffset(), 0, 0);
      else value.setHours(23, 59 + -value.getTimezoneOffset(), 59, 999);
      _.set(newPeriod, name, value);
      return newPeriod;
    });
  }, []);

  const handleGenerateCSV = useCallback(async () => {
    const { orders, companies } = context;
    const { start, end } = period;
    setGenerating(true, () => {
      try {
        const customersMap: {
          [customerId: string]: { totalTurnover: number; recipes: { [recipeString: string]: number } };
        } = {};
        for (let i = 0; i < orders.length; i++) {
          const order = orders[i];
          // Skip offers, declined orders or orders with no invoices or payments not within the desired period
          if (
            !orderUtils.isOrder(order) ||
            order.state === DECLINED ||
            !order.invoices ||
            order.invoices.length === 0 ||
            !order.invoices.some(
              inv =>
                inv.state !== I_CANCELED && inv.payments.some(payment => start <= payment.date && payment.date <= end)
            )
          )
            continue;

          const recipe = getRecipeString(order);
          // Get paid amount for the order
          const turnover = order.invoices
            .filter(inv => inv.state !== I_CANCELED && inv.payments)
            .reduce(
              (sum, inv) =>
                sum +
                inv.payments.reduce((paySum, p) => paySum + (start <= p.date && p.date <= end ? +p.amount : 0), 0),
              0
            );

          const customerId = order.createdFor.toString();
          if (customerId in customersMap) {
            customersMap[customerId].totalTurnover += turnover;
            if (recipe in customersMap[customerId].recipes) customersMap[customerId].recipes[recipe] += turnover;
            else customersMap[customerId].recipes[recipe] = turnover;
          } else {
            customersMap[customerId] = {
              totalTurnover: turnover,
              recipes: { [recipe]: turnover }
            };
          }
        }

        let customerIntermediateData: Array<[string, number, { [recipeString: string]: number }]> = [];
        Object.entries(customersMap).forEach(
          ([cId, data]: [
            cId: string,
            data: { totalTurnover: number; recipes: { [recipeString: string]: number } }
          ]) => {
            const customer: CompaniesDocument | undefined = baseUtils.getDocFromCollection(companies, cId);
            customerIntermediateData.push([
              purgeStringForCSV(customer?.name.trim() || "unknown"),
              data.totalTurnover,
              data.recipes
            ]);
          }
        );

        // Intermediate data structure to sort and slice customers
        customerIntermediateData = _.orderBy(
          customerIntermediateData,
          ([, totalTurnover]) => totalTurnover,
          "desc"
        ).slice(0, count);

        const customerData: Array<Array<string>> = [];
        customerIntermediateData.forEach(([name, totalTurnover, recipes]) => {
          customerData.push([name, baseUtils.formatEuro(totalTurnover), `Alle (${Object.keys(recipes).length})`]);
          // Intermediate recipe data to sort recipes of a customer within each other
          let recipeData: Array<[number, string]> = [];
          Object.entries(recipes).forEach(([recipe, recipeTurnover]: [recipe: string, recipeTurnOver: number]) => {
            recipeData.push([recipeTurnover, recipe]);
          });
          recipeData = _.orderBy(recipeData, ([turnover]) => turnover, "desc");
          // Add final recipe data
          recipeData.forEach(([recipeTurnover, recipe]) =>
            customerData.push(["", baseUtils.formatEuro(recipeTurnover), recipe])
          );
        });

        const headers = ["Kunde", "Umsatz in €", "Rezeptur"];
        const fileName = `Top${count !== Infinity ? count : ""}Customers_${baseUtils.formatDate(
          start
        )}-${baseUtils.formatDate(end, undefined, {
          timeZone: "UTC"
        })}_${baseUtils.formatDate(new Date())}.csv`;

        const csv = fileUtils.exportAsCSV(headers, customerData, DEFAULT_SEPARATOR);
        fileUtils.downloadFile(csv, fileName, "text/plain");
      } finally {
        setGenerating(false);
      }
    });
  }, [context, count, period]);

  const getRecipeString = useCallback(
    (order: OrdersDocument) => {
      return orderUtils.getRecipeString(order, context);
    },
    [context]
  );

  return (
    <div className="kt-portlet kt-portlet--mobile">
      <div className="kt-portlet__head kt-portlet__head--lg">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Top Performing Customers</h3>
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="row mt-2">
          <div className="col text-dark font-weight-bolder mb-5">
            Exports the top customers by turnover for a given period. The statistics is also broken down into their
            recipes
          </div>
        </div>
        <div className="row">
          <div className="col col-6 col-md-4 col-lg-3 align-self-center">Top</div>
          <div className="col col-6 col-md-8 col-lg-3">
            <select className="form-control" value={count} onChange={e => setCount(+e.currentTarget.value)}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={Infinity}>All</option>
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col col-6 col-md-4 col-lg-3 align-self-center">From</div>
          <div className="col col-6 col-md-8 col-lg-3">
            <DateInput
              value={period.start}
              min={dateUtils.getDefaultPeriod().start}
              onBlur={handlePeriodChange}
              name={"start"}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col col-6 col-md-4 col-lg-3 align-self-center">To</div>
          <div className="col col-6 col-md-8 col-lg-3">
            <DateInput value={period.end} max={new Date()} onBlur={handlePeriodChange} name={"end"} />
          </div>
        </div>
      </div>
      <div className="kt-portlet__foot">
        <div className="float-right">
          <button
            className="btn btn-success"
            disabled={generating}
            onClick={generating ? undefined : handleGenerateCSV}
          >
            {generating ? "Generating..." : "Generate CSV"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(TopPerformers);
