import React, { PureComponent } from "react";
import { Modal, Table } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic";
import { Forecast } from "../../../model/commodities.types";
import baseUtils from "../../../utils/baseUtils";
import commodityUtils from "../../../utils/commodityUtils";

interface DeleteForecastModalProps {
  name: string;
  forecast: Forecast;
  idx: number;
  disabled?: boolean;
  onDelete: (idx: number) => void;
}

interface DeleteForecastModalState {
  show: boolean;
}

class DeleteForecastModal extends PureComponent<DeleteForecastModalProps, DeleteForecastModalState> {
  constructor(props: DeleteForecastModalProps) {
    super(props);
    this.state = { show: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  /**
   * Handle deleting a forecast. Hides the modal afterwards.
   */
  handleClickDelete = () => {
    const { onDelete, idx } = this.props;
    onDelete(idx);
    this.handleHide();
  };

  render() {
    const { name, forecast, disabled } = this.props;
    const { show } = this.state;
    return (
      <>
        <button
          className={"btn btn-danger " + (disabled && "disabled")}
          disabled={disabled}
          onClick={disabled ? undefined : this.handleShow}
        >
          <i className="fa fa-trash pr-0" />
        </button>
        <Modal show={show} onHide={this.handleHide} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete forecast for {name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-warning my-2" role="alert">
              <div className="alert-icon">
                <i className="flaticon-warning" />
              </div>
              <div className="alert-text">
                Do you really want to delete the following forecast of {name}?
                <br />
                <br />
                This can be undone as long as you have not clicked "Save changes"
              </div>
            </div>
            <Table>
              <thead>
                <tr>
                  <th style={{ width: "5%" }} />
                  <th style={{ width: "15%" }}>Date</th>
                  <th style={{ width: "40%" }}>
                    Reason <img src={toAbsoluteUrl("/media/icons/icon-en.png")} className="country-icon2" alt="en" />
                  </th>
                  <th style={{ width: "40%" }}>
                    <img src={toAbsoluteUrl("/media/icons/icon-de.png")} className="country-icon2" alt="de" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle text-center">
                    <i
                      className={commodityUtils.resolveForecastIconClasses(forecast.forecast)}
                      style={{ fontSize: "20px" }}
                    />
                  </td>
                  <td className="align-middle">{baseUtils.formatDate(forecast.date)}</td>
                  <td className="align-middle">{forecast.reason.en ? forecast.reason.en : "-"}</td>
                  <td className="align-middle">{forecast.reason.de ? forecast.reason.de : "-"}</td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            <button className="btn btn-danger" onClick={this.handleClickDelete}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default DeleteForecastModal;
