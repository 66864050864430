import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { DataContext } from "../../context/dataContext";
import fileUtils, { parseCSV } from "../../utils/fileUtils";
import baseUtils from "../../utils/baseUtils";
import { CommoditiesDocument } from "../../model/commodities.types";
import { CommoditycategoriesDocument } from "../../model/commoditycategories.types";
import { CompositionsDocument } from "../../model/compositions.types";
import { ColorsDocument } from "../../model/colors.types";
import { CommoditypropertiesDocument } from "../../model/commodityproperties.types";
import { AllergensDocument } from "../../model/allergens.types";
import { SolventsDocument } from "../../model/solvents.types";
import { ActivesubstancesDocument } from "../../model/activesubstances.types";
import dbService, {
  UpdateAction,
  ACTIVESUBSTANCES,
  ALLERGENS,
  COLORS,
  COMMODITIES,
  COMMODITYCATEGORIES,
  COMMODITYPROPERTIES,
  COMPOSITIONS,
  SOLVENTS
} from "../../services/dbService";
import commodityUtils from "../../utils/commodityUtils";

interface CommodityImportExportProps extends RouteComponentProps {}

interface CommodityImportExportState {
  mode: { value: string; label: string };
  importStage: "initial" | "upload" | "summary" | "completed";
  generating: boolean;
  fieldsToUpdate: {
    [key: string]: boolean;
  };
  content: Array<string>;
  categoriesNew: Array<Partial<CommoditycategoriesDocument>>;
  compositionsNew: Array<Partial<CompositionsDocument>>;
  colorsNew: Array<Partial<ColorsDocument>>;
  propertiesNew: Array<Partial<CommoditypropertiesDocument>>;
  allergensNew: Array<Partial<AllergensDocument>>;
  solventsNew: Array<Partial<SolventsDocument>>;
  activeSubstancesNew: Array<Partial<ActivesubstancesDocument>>;
  commoditiesNew: Array<Partial<CommoditiesDocument>>;
  commoditiesUpdate: Array<Partial<CommoditiesDocument>>;
}

class CommodityImportExport extends PureComponent<CommodityImportExportProps, CommodityImportExportState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  selectFileRef: React.RefObject<HTMLInputElement>;

  constructor(props: CommodityImportExportProps) {
    super(props);
    this.selectFileRef = React.createRef();
    this.state = {
      mode: { value: "export", label: "Export" },
      importStage: "initial",
      generating: false,
      fieldsToUpdate: {
        enabled: true,
        title: true,
        subtitle: true,
        note: true,
        category: true,
        composition: true,
        density: true,
        country: true,
        hsCode: true,
        casNumber: true,
        toxicAmount: true,
        color: true,
        organic: true,
        properties: true,
        allergens: true,
        solvent: true,
        activeSubstances: true
      },
      content: [],
      categoriesNew: [],
      compositionsNew: [],
      colorsNew: [],
      propertiesNew: [],
      allergensNew: [],
      solventsNew: [],
      activeSubstancesNew: [],
      commoditiesNew: [],
      commoditiesUpdate: []
    };
  }

  handleChangeMode = (mode: { value: string; label: string }) => {
    this.setState({ mode, importStage: "initial" });
  };

  handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldsToUpdate = _.cloneDeep(this.state.fieldsToUpdate);
    const { name, checked } = e.target; // Checked is containing the value after the action
    if (name === "all") {
      for (const key in fieldsToUpdate) {
        fieldsToUpdate[key] = checked;
      }
    } else {
      fieldsToUpdate[name] = checked;
    }
    this.setState({ fieldsToUpdate });
  };

  handleParseCommodityCSV = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const {
      commoditycategories,
      compositions,
      colors,
      commodityproperties,
      allergens,
      solvents,
      activesubstances,
      commodities
    } = this.context;
    this.setState({ generating: true });
    const file = await e.target.files[0].text();
    const content: Array<string> = file.split(/[\r\n]+/);
    this.setState({ content });
    const parsedCSV = parseCSV(content, ";");
    const categoriesNew: Array<Partial<CommoditycategoriesDocument>> = [];
    const compositionsNew: Array<Partial<CompositionsDocument>> = [];
    const colorsNew: Array<Partial<ColorsDocument>> = [];
    const propertiesNew: Array<Partial<CommoditypropertiesDocument>> = [];
    const allergensNew: Array<Partial<AllergensDocument>> = [];
    const solventsNew: Array<Partial<SolventsDocument>> = [];
    const activeSubstancesNew: Array<Partial<ActivesubstancesDocument>> = [];
    const commoditiesNew: Array<Partial<CommoditiesDocument>> = [];
    const commoditiesUpdate: Array<Partial<CommoditiesDocument>> = [];
    for (let i = 1; i < parsedCSV.length; i++) {
      try {
        const l = parsedCSV[i];
        const [
          id,
          enabled,
          title,
          subtitle,
          note,
          articleNumber,
          category,
          identifier,
          internalCode,
          composition,
          type,
          density,
          country,
          hsCode,
          casNumber,
          toxicAmount,
          color,
          organic,
          organicCode,
          properties,
          allergen,
          solvent,
          activeSubstances,
          activeSubstancesAmount
        ] = l;
        if (!title.trim()) {
          console.error("LINE", i, "CONTAINED NO TITLE - SKIPPING");
          continue;
        }
        let categoryID =
          category === "custom"
            ? category
            : commoditycategories.find(cc => cc.name.en.toLowerCase().trim() === category.toLowerCase().trim())?._id;
        if (!categoryID) {
          categoryID = categoriesNew.find(
            cn => cn.name?.en.toLowerCase().trim() === category.toLowerCase().trim()
          )?._id;
        }
        if (!categoryID) {
          const newCat: Partial<CommoditycategoriesDocument> = {
            _id: new BSON.ObjectId(),
            name: { en: category.trim(), de: "" },
            internal_code_reference: ""
          };
          categoryID = newCat._id.toString();
          categoriesNew.push(newCat);
        }
        let compositionID =
          composition === "custom"
            ? composition
            : compositions.find(c => c.name.en.toLowerCase().trim() === composition.toLowerCase().trim())?._id;
        if (composition !== "" && !compositionID) {
          compositionID = compositionsNew.find(
            cn => cn.name?.en.toLowerCase().trim() === composition.toLowerCase().trim()
          )?._id;
        }
        if (composition !== "" && !compositionID) {
          const newComp: Partial<CompositionsDocument> = {
            _id: new BSON.ObjectId(),
            name: { en: composition.trim(), de: "" }
          };
          compositionID = newComp._id;
          compositionsNew.push(newComp);
        }
        let colorID = colors.find(c => c.name.en.toLowerCase().trim() === color.toLowerCase().trim())?._id;
        if (color !== "" && !colorID) {
          colorID = colorsNew.find(cn => cn.name?.en.toLowerCase().trim() === color.toLowerCase().trim())?._id;
        }
        if (color !== "" && !colorID) {
          const newCol: Partial<ColorsDocument> = {
            _id: new BSON.ObjectId(),
            name: { en: color.trim(), de: "" },
            image: ""
          };
          colorID = newCol._id;
          colorsNew.push(newCol);
        }
        const propertyIDsParsed = properties.split(", ");
        const propertyIDs: Array<BSON.ObjectId> = [];
        for (let j = 0; j < propertyIDsParsed.length; j++) {
          const p = propertyIDsParsed[j];
          if (p === "") continue;
          let pID = commodityproperties.find(cp => cp.name.en.toLowerCase().trim() === p.toLowerCase().trim())?._id;
          if (!pID) {
            pID = propertiesNew.find(pn => pn.name?.en.toLowerCase().trim() === p.toLowerCase().trim())?._id;
          }
          if (!pID) {
            const pNew: Partial<CommoditypropertiesDocument> = {
              _id: new BSON.ObjectId(),
              name: { en: p.trim(), de: "" }
            };
            propertiesNew.push(pNew);
            pID = pNew._id;
          }
          propertyIDs.push(pID);
        }
        const allergenIDsParsed = allergen.split(", ");
        const allergensIDs: Array<BSON.ObjectId> = [];
        for (let j = 0; j < allergenIDsParsed.length; j++) {
          const a = allergenIDsParsed[j];
          if (a === "") continue;
          let aID = allergens.find(al => al.name.en.toLowerCase().trim() === a.toLowerCase().trim())?._id;
          if (!aID) {
            aID = allergensNew.find(an => an.name?.en.toLowerCase().trim() === a.toLowerCase().trim());
          }
          if (!aID) {
            const aNew: Partial<AllergensDocument> = { _id: new BSON.ObjectId(), name: { en: a.trim(), de: "" } };
            allergensNew.push(aNew);
            aID = aNew._id;
          }
          allergensIDs.push(aID);
        }
        let solventID = solvents.find(s => s.name.en.toLowerCase().trim() === solvent.toLowerCase().trim())?._id;
        if (solvent !== "" && !solventID) {
          solventID = solventsNew.find(sn => sn.name?.en.toLowerCase().trim() === solvent.toLowerCase().trim())?._id;
        }
        if (solvent !== "" && !solventID) {
          const sNew: Partial<SolventsDocument> = { _id: new BSON.ObjectId(), name: { en: solvent.trim(), de: "" } };
          solventsNew.push(sNew);
          solventID = sNew._id;
        }
        const asNames = activeSubstances.split(", ");
        const asValues = activeSubstancesAmount.split(", ");
        const activeSubstancesObjects: Array<{ _id: BSON.ObjectId; value: number }> = [];
        if (asNames.length !== asValues.length) {
          console.error(
            "Length of actives substances and their values for:",
            title,
            "did not match - skipping the active substances of",
            title
          );
        } else {
          for (let j = 0; j < asNames.length; j++) {
            const as = asNames[j];
            if (as === "") continue;
            let asID = activesubstances.find(
              actSus => actSus.name.en.toLowerCase().trim() === as.toLowerCase().trim()
            )?._id;
            if (!asID) {
              asID = activeSubstancesNew.find(
                actSus => actSus.name?.en.toLowerCase().trim() === as.toLowerCase().trim()
              )?._id;
            }
            if (!asID) {
              const asNew: Partial<ActivesubstancesDocument> = {
                _id: new BSON.ObjectId(),
                name: { en: as, de: "" },
                nrv: null
              };
              activeSubstancesNew.push(asNew);
              asID = asNew._id;
            }
            const asValue = !isNaN(Number(asValues[j])) ? Number(asValues[j]) : 0;
            activeSubstancesObjects.push({ _id: asID, value: asValue });
          }
        }

        const commodity: Partial<CommoditiesDocument> = {
          _id: BSON.ObjectId.isValid(id) ? new BSON.ObjectId(id) : new BSON.ObjectId(),
          disabled: enabled.toLowerCase().trim() === "no",
          title: { en: title, de: "" },
          subtitle: { en: subtitle, de: "" },
          note,
          article_number: articleNumber,
          category: categoryID ?? "",
          identifier,
          internal_code: internalCode,
          form: compositionID ?? "",
          type,
          density,
          hs_code: hsCode,
          country,
          cas_number: casNumber,
          toxic_amount: toxicAmount && !isNaN(Number(toxicAmount)) ? Number(toxicAmount) : null,
          color: colorID ?? null,
          organic: organic.toLowerCase().trim() === "yes",
          organic_code: organic.toLowerCase().trim() === "yes" ? organicCode : "",
          properties: propertyIDs,
          allergens: allergensIDs,
          solvent: solventID ?? null,
          activesubstance: activeSubstancesObjects
        };
        if (BSON.ObjectId.isValid(id) && commodities.some(c => c._id.toString() === id)) {
          commoditiesUpdate.push(commodity);
        } else {
          commodity.stock = [];
          commodity.orders = [];
          commodity.forecast = [];
          commodity.suppliers = [];
          commodity.timeline = [];
          commodity.specifications = [];
          commoditiesNew.push(commodity);
        }
      } catch (e) {
        console.error("ERROR IN LINE", i, ":", e.toString());
      }
    }
    this.setState({
      generating: false,
      importStage: "upload",
      categoriesNew,
      colorsNew,
      commoditiesNew,
      commoditiesUpdate,
      compositionsNew,
      allergensNew,
      activeSubstancesNew,
      propertiesNew,
      solventsNew
    });
  };

  handlePrepareOperations = () => {
    const { fieldsToUpdate, commoditiesUpdate } = this.state;
    this.setState({ generating: true });
    const commoditiesUpdatePrepared: Array<Partial<CommoditiesDocument>> = [];
    for (let i = 0; i < commoditiesUpdate.length; i++) {
      const cu = commoditiesUpdate[i];
      const cPrepared: Partial<CommoditiesDocument> = { _id: cu._id };
      if (fieldsToUpdate["enabled"]) {
        cPrepared.disabled = cu.disabled;
      }
      if (fieldsToUpdate["title"]) {
        cPrepared.title = cu.title;
      }
      if (fieldsToUpdate["subtitle"]) {
        cPrepared.subtitle = cu.subtitle;
      }
      if (fieldsToUpdate["note"]) {
        cPrepared.note = cu.note;
      }
      if (fieldsToUpdate["category"]) {
        cPrepared.category = cu.category;
      }
      if (fieldsToUpdate["composition"]) {
        cPrepared.form = cu.form;
      }
      if (fieldsToUpdate["density"]) {
        cPrepared.density = cu.density;
      }
      if (fieldsToUpdate["hsCode"]) {
        cPrepared.hs_code = cu.hs_code;
      }
      if (fieldsToUpdate["casNumber"]) {
        cPrepared.cas_number = cu.cas_number;
      }
      if (fieldsToUpdate["toxicAmount"]) {
        cPrepared.toxic_amount = cu.toxic_amount;
      }
      if (fieldsToUpdate["color"]) {
        cPrepared.color = cu.color;
      }
      if (fieldsToUpdate["organic"]) {
        cPrepared.organic = cu.organic;
        cPrepared.organic_code = cu.organic_code;
      }
      if (fieldsToUpdate["properties"]) {
        cPrepared.properties = cu.properties;
      }
      if (fieldsToUpdate["allergens"]) {
        cPrepared.allergens = cu.allergens;
      }
      if (fieldsToUpdate["solvent"]) {
        cPrepared.solvent = cu.solvent;
      }
      if (fieldsToUpdate["activeSubstances"]) {
        cPrepared.activesubstance = cu.activesubstance;
      }
      commoditiesUpdatePrepared.push(cPrepared);
    }
    this.setState({ generating: false, importStage: "summary", commoditiesUpdate: commoditiesUpdatePrepared });
  };

  handleUpdateDatabase = async () => {
    const {
      compositionsNew,
      propertiesNew,
      colorsNew,
      categoriesNew,
      allergensNew,
      solventsNew,
      activeSubstancesNew,
      commoditiesNew,
      commoditiesUpdate
    } = this.state;
    this.setState({ generating: true });
    try {
      if (compositionsNew.length > 0) dbService.insertDocuments(COMPOSITIONS, compositionsNew);
      if (propertiesNew.length > 0) dbService.insertDocuments(COMMODITYPROPERTIES, propertiesNew);
      if (colorsNew.length > 0) dbService.insertDocuments(COLORS, colorsNew);
      if (categoriesNew.length > 0) dbService.insertDocuments(COMMODITYCATEGORIES, categoriesNew);
      if (allergensNew.length > 0) dbService.insertDocuments(ALLERGENS, allergensNew);
      if (solventsNew.length > 0) dbService.insertDocuments(SOLVENTS, solventsNew);
      if (activeSubstancesNew.length > 0) dbService.insertDocuments(ACTIVESUBSTANCES, activeSubstancesNew);
      if (commoditiesNew.length > 0) dbService.insertDocuments(COMMODITIES, commoditiesNew);
      if (commoditiesUpdate.length > 0) {
        const actions: Array<UpdateAction> = [];
        for (let i = 0; i < commoditiesUpdate.length; i++) {
          const cu = commoditiesUpdate[i];
          actions.push({ collection: COMMODITIES, update: cu, filter: { _id: cu._id } });
        }
        const res = await dbService.updatesAsTransaction(actions);
        if (res) {
          toast.success("Commodities successfully updated");
        } else {
          toast.error("Error updating commodities");
        }
      }
    } finally {
      this.setState({ generating: false, importStage: "completed" });
    }
  };

  handleGenerateCSV = () => {
    const {
      commodities,
      commoditycategories,
      compositions,
      colors,
      commodityproperties,
      allergens,
      solvents,
      activesubstances
    } = this.context;
    this.setState({ generating: true });
    const headers = [
      "OID",
      "Enabled",
      "Title",
      "Subtitle",
      "Note",
      "Article Number",
      "Category",
      "Identifier",
      "Internal Code",
      "Composition",
      "Type",
      "Density (g/cm³)",
      "Country",
      "HS Code",
      "CAS Number",
      "Toxic Amount (mg/day)",
      "Color",
      "Organic",
      "Organic Code",
      "Properties",
      "Allergens",
      "Solvent",
      "Active Substances",
      "Active Substances Amounts"
    ];
    const content = [];
    for (let i = 0; i < commodities.length; i++) {
      const c = commodities[i];
      if (!commodityUtils.isCommodityApproved(c)) continue;
      const entry = [];
      const category =
        c.category === "custom"
          ? c.category
          : commoditycategories.find(cc => cc._id.toString() === c.category.toString())?.name.en;
      const composition =
        c.form === "custom" ? c.form : compositions.find(comp => comp._id.toString() === c.form.toString())?.name.en;
      const color = c.color ? colors.find(col => col._id.toString() === c.color!.toString())?.name.en : undefined;
      const properties = c.properties.map(
        p => commodityproperties.find(cp => cp._id.toString() === p.toString())?.name.en
      );
      const allergensCom = c.allergens.map(a => allergens.find(all => all._id.toString() === a.toString())?.name.en);
      const solvent = c.solvent ? solvents.find(s => s._id.toString() === c.solvent!.toString())?.name.en : undefined;
      const activeSubstances = c.activesubstance.map(as => {
        const asObj = activesubstances.find(actSus => as._id.toString() === actSus._id.toString())?.name.en;
        return { name: asObj, value: as.value.toString() };
      });
      entry.push(c._id.toString());
      entry.push(c.disabled ? "No" : "Yes");
      entry.push(c.title.en.replaceAll(";", ","));
      entry.push(c.subtitle.en.replaceAll(";", ",").replaceAll(/[\r\n]+/g, ""));
      entry.push(c.note.replaceAll(";", ",").replaceAll(/[\r\n]+/g, ""));
      entry.push(c.article_number);
      entry.push(category ?? "");
      entry.push(c.identifier);
      entry.push(c.internal_code);
      entry.push(composition ?? "");
      entry.push(c.type ? c.type : "");
      entry.push(c.density ? c.density.toString() : "");
      entry.push(c.country);
      entry.push(c.hs_code);
      entry.push(c.cas_number ? c.cas_number.toString().replaceAll(";", ",") : "");
      entry.push(c.toxic_amount ? c.toxic_amount.toString() : "");
      entry.push(color ?? "");
      entry.push(c.organic ? "Yes" : "No");
      entry.push(c.organic ? c.organic_code : "");
      entry.push(properties.join(", "));
      entry.push(allergensCom.join(", "));
      entry.push(solvent ?? "");
      entry.push(activeSubstances.map(as => as.name).join(", "));
      entry.push(activeSubstances.map(as => as.value).join(", "));
      content.push(entry);
    }
    try {
      const csv = fileUtils.exportAsCSV(headers, content, ";", false);
      fileUtils.downloadFile(csv, "Commodities_" + baseUtils.formatDate(new Date()) + ".csv", "text/plain");
    } catch (e) {
      toast.error("Error generating csv");
      console.error(e);
    } finally {
      this.setState({ generating: false });
    }
  };

  render() {
    const { history } = this.props;
    const {
      generating,
      mode,
      fieldsToUpdate,
      importStage,
      commoditiesUpdate,
      commoditiesNew,
      colorsNew,
      categoriesNew,
      propertiesNew,
      solventsNew,
      compositionsNew,
      allergensNew,
      activeSubstancesNew,
      content
    } = this.state;
    const importMode = mode.value === "import";

    const fieldsToUpdateList = Object.keys(fieldsToUpdate).map(key => {
      return { key, checked: fieldsToUpdate[key] };
    });

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Commodities Import/Export</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <button
                onClick={() => {
                  history.goBack();
                }}
                className="btn btn-clean kt-margin-r-10"
              >
                <i className="la la-arrow-left" />
                <span className="kt-hidden-mobile">Back</span>
              </button>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row mt-2">
            <div className="col-2 text-dark font-weight-bolder mb-5 align-self-center">Mode</div>
            <div className="col-2 text-dark font-weight-bolder mb-5">
              <Select
                className="select-default"
                options={[
                  { value: "import", label: "Import" },
                  { value: "export", label: "Export" }
                ]}
                onChange={(e: any) => this.handleChangeMode(e)}
                value={mode}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col text-dark font-weight-bolder mb-5">
              {importMode ? (
                <>
                  <div>Imports the given commodities into the system. Commodities that already exist are updated.</div>
                  <div className="mt-2">Please beware of the following constraints:</div>
                  <div>- Fields that are binary (Enabled, Organic) require "Yes" or "No" as entry</div>
                  <div>- The country field should contain the country code (e.g. CN)</div>
                  <div>
                    - Numeric fields (Density, Active Substances Amounts, Toxic Amount) should contain numbers without
                    units
                  </div>
                  <div>
                    - Fields that contain multiple values (Properties, Allergens, Active Substances, Active Substances
                    Amounts) should have their values separated via ", "
                  </div>
                </>
              ) : (
                "Exports all commodities that are currently saved inside the system."
              )}
            </div>
          </div>
          {importMode &&
            (importStage === "upload" ? (
              <>
                <div className="fs-4 mt-6 text-dark font-weight-bolder">
                  Values to update (not applied to commodities that are newly added)
                </div>
                <div className="table-responsive mt-5">
                  <table className="table mb-0">
                    <thead>
                      <tr className="fw-bolder">
                        <th className="align-middle">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="all"
                              checked={fieldsToUpdateList.every(f => f.checked)}
                              onChange={this.handleCheck}
                            />
                          </div>
                        </th>
                        <th className="align-middle">Field</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fieldsToUpdateList.map(f => (
                        <tr key={f.key}>
                          <td>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={f.key}
                                checked={f.checked}
                                onChange={this.handleCheck}
                              />
                            </div>
                          </td>
                          <td className="align-middle">
                            <span>{f.key}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : importStage === "summary" ? (
              <>
                <div className="fs-4 mt-6 text-dark font-weight-bolder">
                  {content.length - 1} lines of content were provided.
                </div>
                <div className="table-responsive mt-5">
                  <table className="table mb-0">
                    <thead>
                      <tr className="fw-bolder">
                        <th className="align-middle">Collection</th>
                        <th className="align-middle">Amount of Additions</th>
                        <th className="align-middle">Amount of Updates</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="align-middle">
                          <span>Commodities</span>
                        </td>
                        <td className="align-middle">
                          <span>{commoditiesNew.length}</span>
                        </td>
                        <td className="align-middle">
                          <span>{commoditiesUpdate.length}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-middle">
                          <span>Colors</span>
                        </td>
                        <td className="align-middle">
                          <span>{colorsNew.length}</span>
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td className="align-middle">
                          <span>Categories</span>
                        </td>
                        <td className="align-middle">
                          <span>{categoriesNew.length}</span>
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td className="align-middle">
                          <span>Categories</span>
                        </td>
                        <td className="align-middle">
                          <span>{categoriesNew.length}</span>
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td className="align-middle">
                          <span>Properties</span>
                        </td>
                        <td className="align-middle">
                          <span>{propertiesNew.length}</span>
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td className="align-middle">
                          <span>Solvents</span>
                        </td>
                        <td className="align-middle">
                          <span>{solventsNew.length}</span>
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td className="align-middle">
                          <span>Allergens</span>
                        </td>
                        <td className="align-middle">
                          <span>{allergensNew.length}</span>
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td className="align-middle">
                          <span>Compositions</span>
                        </td>
                        <td className="align-middle">
                          <span>{compositionsNew.length}</span>
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td className="align-middle">
                          <span>Active Substances</span>
                        </td>
                        <td className="align-middle">
                          <span>{activeSubstancesNew.length}</span>
                        </td>
                        <td />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <></>
            ))}
          <div className="kt-portlet__foot">
            <div className="float-right">
              {importMode ? (
                importStage !== "completed" && (
                  <>
                    <button
                      className="btn btn-success"
                      type="button"
                      disabled={generating}
                      onClick={() =>
                        importStage === "initial"
                          ? this.selectFileRef.current?.click()
                          : importStage === "upload"
                          ? this.handlePrepareOperations()
                          : this.handleUpdateDatabase()
                      }
                    >
                      {importStage === "initial"
                        ? "Upload Commodity CSV"
                        : importStage === "upload"
                        ? "Prepare Summary"
                        : "Update Database"}
                    </button>
                    <input
                      type="file"
                      ref={this.selectFileRef}
                      accept="text/csv"
                      style={{ display: "none" }}
                      onChange={generating ? undefined : this.handleParseCommodityCSV}
                    />
                  </>
                )
              ) : (
                <button
                  className="btn btn-success"
                  disabled={generating}
                  onClick={generating ? undefined : this.handleGenerateCSV}
                >
                  {generating ? "Generating..." : "Export Commodities"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommodityImportExport;
