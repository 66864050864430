import _ from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { PackagingsDocument } from "../../../../model/packagings.types";
import { CustomOrder } from "../../CustomTypes";
import { SuppliersDocument } from "../../../../model/suppliers.types";
import { pricing } from "../../../../model/orders.types";
import packagingUtils from "../../../../utils/packagingUtils";
import {
  ARCHIVE,
  CONTRACT,
  CREATEINVOICE,
  FULFILLMENT,
  PRODUCTION,
  PRODUCTIONQUEUE
} from "../../../../utils/orderUtils";
import userService from "../../../../services/userService";
import { ROLES } from "../../../../utils/userdataUtils";
import ReplaceMaterialModal from "../../modals/ReplaceMaterialModal";
import RemoveMaterialModal from "../../modals/RemoveMaterialModal";
import baseUtils from "../../../../utils/baseUtils";
import accessUtils, { ACTIONS } from "../../../../utils/accessUtils";

interface OrderPackagingProps {
  order: CustomOrder;
  packaging: Array<PackagingsDocument>;
  suppliers: Array<SuppliersDocument>;
}

interface OrderPackagingState {
  packagingToReplace?: PackagingsDocument;
}

class OrderPackaging extends PureComponent<OrderPackagingProps, OrderPackagingState> {
  constructor(props: OrderPackagingProps) {
    super(props);
    this.state = {};
  }

  handleReplacePackaging = (packaging: PackagingsDocument) => this.setState({ packagingToReplace: packaging });
  handleHide = () => this.setState({ packagingToReplace: undefined });
  render() {
    const { order, packaging, suppliers } = this.props;
    const { packagingToReplace } = this.state;
    let replaceable =
      ([PRODUCTIONQUEUE, PRODUCTION].includes(order.state) && userService.hasRole(ROLES.PRODUCTION)) ||
      (order.state === PRODUCTIONQUEUE && userService.hasRole(ROLES.PACKAGINGPROCUREMENT));

    // special case order contract
    if (!replaceable && order.state === CONTRACT) {
      if (order.contract) {
        replaceable =
          order.contract.some(con => !con.finished) &&
          userService.hasOneOfRoles([ROLES.PACKAGINGPROCUREMENT, ROLES.PROCUREMENT]);
      }
    }
    const showBatches = !replaceable && [FULFILLMENT, CREATEINVOICE, ARCHIVE].includes(order.state);
    return (
      <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--subtable kt-datatable--loaded table-responsive">
        <ReplaceMaterialModal
          order={order}
          type={"packaging"}
          material={packagingToReplace}
          externalHandling={true}
          onHide={this.handleHide}
        />
        <table className="kt-datatable__table d-table ">
          <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
            <tr className="kt-datatable__row d-table-row">
              <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                <span>Units</span>
              </th>
              <th className="kt-datatable__cell d-table-cell" style={{ width: "23%" }}>
                <span>Commodity</span>
              </th>
              <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                <span>Amount</span>
              </th>
              <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                <span>Required</span>
              </th>
              <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                <span>Order Quantity</span>
              </th>
              <th className="kt-datatable__cell d-table-cell" style={{ width: "20%" }}>
                <span>Supplier</span>
              </th>
              <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                <span>Delivery</span>
              </th>
              <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                <span>Price</span>
              </th>
              <th
                className={"kt-datatable__cell d-table-cell" + (replaceable ? "" : " text-right")}
                style={{ minWidth: "6%" }}
              >
                <span>Change</span>
              </th>
              {(replaceable || showBatches) && (
                <th className="kt-datatable__cell d-table-cell text-right" style={{ width: "8%" }}>
                  <span>{replaceable ? "Action" : "Batches"}</span>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
            {order.calculations.map(calc =>
              calc.packagings.map(price => {
                const units = calc.units;
                const pack = packaging.find(p => p._id.toString() === price._id.toString());
                if (!pack) return null;
                const supplier =
                  typeof price.supplier === "string"
                    ? price.supplier
                    : suppliers.find(s => s._id.toString() === price.supplier.toString())!;
                return (
                  <OrderPackagingEntry
                    key={calc.id.toString() + price._id.toString()}
                    units={units}
                    price={price}
                    packaging={pack}
                    supplier={supplier}
                    replaceable={replaceable}
                    showBatches={showBatches}
                    order={order}
                    onReplacePackaging={this.handleReplacePackaging}
                  />
                );
              })
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

interface OrderPackagingEntryProps {
  units: number;
  price: pricing;
  packaging: PackagingsDocument;
  supplier: SuppliersDocument | "accumulatedstock" | "custom" | "customer" | "ownstock";
  replaceable: boolean;
  showBatches: boolean;
  order: CustomOrder;
  onReplacePackaging: (packaging: PackagingsDocument) => void;
}

const OrderPackagingEntry: React.FunctionComponent<OrderPackagingEntryProps> = ({
  units,
  price,
  packaging,
  supplier,
  replaceable,
  showBatches,
  order,
  onReplacePackaging
}) => {
  if (!packaging) return <></>;
  let batchPrice;
  let totalUsage = 0;
  let totalPrice = 0;
  const batches: Array<string> = [];
  // If there are used batches calculate show the average batch price and calculate the price difference to the batch price
  if (showBatches && order.usedPackagingBatches) {
    for (let i = 0; i < order.usedPackagingBatches.length; i++) {
      let batch = order.usedPackagingBatches[i];
      if (batch.packagingId.toString() !== packaging._id.toString()) continue;
      totalUsage += +batch.used;
      totalPrice += +batch.used * +batch.price;
    }
    if (totalUsage !== 0) batchPrice = totalPrice / totalUsage;
    order.usedPackagingBatches.forEach(batch => {
      if (batch.packagingId && batch.packagingId.toString() === packaging._id.toString()) batches.push(batch.lot);
    });
  }
  const priceDifference =
    (batchPrice ? batchPrice / +price.estimatedprice - 1 : +price.price / +price.estimatedprice - 1) * 100;
  const canReplace = accessUtils.canPerformAction(ACTIONS.ORDERPACKAGINGREPLACE);
  return (
    <tr className="kt-datatable__row d-table-row">
      <td className=" kt-datatable__cell d-table-cell">
        <span>{units}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <Link className="kt-user-card-v2__name kt-link" to={"/packaging/" + packaging._id.toString()}>
              {packagingUtils.getPackagingType(packaging.packaging_type)}
              {packaging.article_number && <span className="text-success"> {packaging.article_number}</span>}
            </Link>
            <span className="kt-user-card-v2__email">{packagingUtils.resolvePackagingProperties(packaging)}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>{price.amount + (price.amount === 1 ? " item" : " items")}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>{units * price.amount}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>{price.orderquantity}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className={"kt-font-bold " + (!supplier && "text-warning")}>
          {!supplier ? "Invalid supplier" : typeof supplier === "string" ? _.upperFirst(supplier) : supplier.name}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-font-bold">{price.deliverytime} days</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-font-bold">
          {batchPrice ? baseUtils.formatEuro(batchPrice) : baseUtils.formatEuro(price.price)}/
          {price.amount > 1 ? "pcs." : "unit"}
        </span>
        {price.amount > 1 && (
          <span className="kt-font-muted">
            {batchPrice
              ? baseUtils.formatEuro(batchPrice * price.amount)
              : baseUtils.formatEuro(price.price * price.amount)}
            /unit
          </span>
        )}
      </td>
      <td className=" kt-datatable__cell d-table-cell text-right">
        <span
          className={
            "kt-font-bold " + (priceDifference < 0 ? "text-success" : priceDifference > 0 ? "text-danger" : "")
          }
        >
          <p className="kt-user-card-v2__name mb-0">
            {priceDifference > 0 && <i className="fa fa-exclamation mr-1" />}
            {priceDifference < 0 && <i className="fa fa-caret-down mr-1" />}
            {priceDifference === 0 && <i className="fa fa-caret-right mr-1" />}
            {priceDifference > 0 && "+"}
            {supplier === "customer" ? "-" : priceDifference.toFixed(2) + "%"}
          </p>
        </span>
      </td>
      {replaceable && (
        <td className="kt-datatable__cell d-table-cell text-right">
          <div className="btn-group">
            <button
              className={"btn btn-sm btn-secondary px-2 py-1 " + (canReplace ? "" : " disabled")}
              onClick={canReplace ? () => onReplacePackaging(packaging) : undefined}
              disabled={!canReplace}
            >
              Replace
            </button>
            <RemoveMaterialModal material={packaging} order={order} type={"packaging"} showAsDropdown={true} />
          </div>
        </td>
      )}
      {showBatches && (
        <td className="kt-datatable__cell d-table-cell text-right" style={{ maxWidth: "8%" }}>
          <span>{batches.length === 0 ? "N/A" : batches.join(", ")}</span>
        </td>
      )}
    </tr>
  );
};

export default OrderPackaging;
