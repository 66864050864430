import _ from "lodash";
import { BSON } from "realm-web";
import { OutputData } from "../components/dashboard/CustomTypes";
import { OrdersDocument } from "../model/orders.types";
import { Period } from "../components/common/CustomTypes";
import { Stats } from "../model/customTypes.types";
import orderUtils, { DECLINED } from "./orderUtils";
import { UserdataDocument } from "../model/userdata.types";
import { T_FULFILLMENT, T_ORDERED } from "./timelineUtils";
import dateUtils from "./dateUtils";
import invoiceUtils, { I_CANCELED } from "./invoiceUtils";
import { MARGIN } from "./orderCalculationUtils";
import contractUtils from "./contractUtils";

export const PERIODS = [
  { value: "thismonth", label: "This Month" },
  { value: "lastmonth", label: "Last Month" },
  { value: "thisyear", label: "This Year" },
  { value: "lastyear", label: "Last Year" }
];

export enum PERIODTYPES {
  DELIVERY = "delivery",
  INVOICE = "invoice",
  ORDERED = "ordered"
}

/**
 * Calculate the projection of future output.
 * @param data: Output date that is used to project
 * @param type: Type of data that should be projected
 * @param year: Optional, needed for charts in the past (so that no projection is generated)
 * @returns { OutputDataPrepared } Projected data is a format apexcharts can work with
 */
function createProjection(data: Array<OutputData>, type: "units" | "turnover" | "profit", year?: number) {
  const curYear = new Date().getFullYear();
  // Can only project in the future
  if (year && year !== curYear) return [];
  const last = _.maxBy(data, o => {
    return o.t.getTime();
  })!;
  if (!last)
    return [
      { x: new Date(curYear, 0, 1, 23, 59, 59, 999).toISOString(), y: 0 },
      { x: new Date(curYear, 12, 1, 23, 59, 59, 999).toISOString(), y: 0 }
    ];
  const passedDays = (new Date().getTime() - new Date(curYear, 0, 1, 2).getTime()) / (1000 * 60 * 60 * 24);
  const output =
    data.reduce((sum, oh) => sum + (type === "units" ? oh.amount : type === "turnover" ? oh.turnover : oh.margin), 0) /
    passedDays;
  // Not using 365 - passedDays because of daylight saving time and leap years
  const remainingDays = (new Date(curYear, 12, 1, 2).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24);
  const projectionTarget =
    (type === "units" ? last.y : type === "turnover" ? last.totalTurnover : last.totalMargin) + remainingDays * output;
  return [
    {
      x: last.t.toISOString(),
      y: type === "units" ? last.y : type === "turnover" ? last.totalTurnover : last.totalMargin
    },
    { x: new Date(curYear, 12, 1, 2).toISOString(), y: projectionTarget }
  ];
}

/**
 * Filter orders by the given owner
 * @param orders list of order documents
 * @param ownerId optional owner id
 * @returns {Array<OrdersDocument>} list of orders sorted in an ascending way according to the identifier
 */
function getOrdersForOwner(orders: Array<OrdersDocument>, ownerId: BSON.ObjectId | string | undefined) {
  let ordersFiltered = [...orders];
  if (ownerId) ordersFiltered = orders.filter(order => order.createdFrom.toString() === ownerId.toString());
  return _.orderBy(ordersFiltered, order => +order.identifier, "asc");
}

/**
 * Calculate the sales statistics
 * @param orders list of orders
 * @param user userdata document of the user to calculate stats for
 * @param period start and end
 * @param periodType optional, determines of the type of the period, if none is passed ordered date is used
 * @returns {object} object with all kinds of statistics
 */
function getSalesStats(
  orders: Array<OrdersDocument>,
  user: UserdataDocument | undefined,
  period: Period,
  periodType?: PERIODTYPES
): Stats | null {
  if (!orders || orders.length === 0) return null;
  let bookSales = 0;
  let bookProfit = 0;
  let writtenSales = 0;
  let pendingOffers = 0;
  let pendingOrders = 0;
  let bookCommission = 0;
  let output = 0;
  let commission = 0;
  let offerVolume = 0;
  let badMargin = 0;
  let goodMargin = 0;
  let writtenProfit = 0;
  const begin = new Date(period.beginning).setHours(0, 0, 0, 0);
  const end = new Date(period.end).setHours(23, 59, 59, 999);
  let totalProcessingTime = 0;
  let totalProcessedOrders = 0;
  let averageProcessingTime = 0;
  let orderedDate;
  let finishDate;
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    if (contractUtils.isContract(order) || order.state === DECLINED) continue;
    const totalTurnover = getTotalTurnover(order);
    const totalMargin = getTotalMargin(order);
    const percentMargin = getPercentMargin(order);
    if (percentMargin < 0) {
      console.warn("Found negative margin for order", order._id.toString());
    }
    const relevantDate =
      periodType === "delivery"
        ? order.delivery
          ? order.delivery
          : order.targetDate
        : periodType === "invoice"
        ? order.invoices && order.invoices.length > 0
          ? order.invoices[0].invoiceDate
          : null
        : order.createdOn;
    if (!relevantDate) continue;
    if (orderUtils.isOrder(order)) {
      if (relevantDate.getTime() >= begin && relevantDate.getTime() <= end) {
        pendingOrders += 1;
        bookSales += totalTurnover;
        bookProfit += totalMargin;
        bookCommission += totalMargin;
        orderedDate = order.createdOn;
      }
      const fulfillment = order.timeline.find(t => t.type === T_FULFILLMENT);
      if (fulfillment) finishDate = fulfillment.date;
      if (order.invoices) {
        for (let j = 0; j < order.invoices.length; j++) {
          const invoice = order.invoices[j];
          if (
            invoice.state !== I_CANCELED &&
            invoice.invoiceDate.getTime() >= begin &&
            invoice.invoiceDate.getTime() <= end
          ) {
            const total = invoiceUtils.getSubtotal(invoice.positions);
            writtenSales += total;
            output +=
              order.fulfillment && order.fulfillment.totalUnits
                ? order.fulfillment.totalUnits
                : order.calculations[0].units;
            writtenProfit += invoiceUtils.calculateAbsoluteMargin(total, percentMargin);
          }
        }
      }
    } else {
      pendingOffers += 1;
      offerVolume += totalTurnover;
    }

    if (order.createdOn.getTime() >= begin && order.createdOn.getTime() <= end) {
      if (percentMargin < MARGIN.BAD) badMargin += 1;
      else if (percentMargin > MARGIN.GOOD) goodMargin += 1;
    }

    if (orderedDate && finishDate) {
      totalProcessingTime += (finishDate.getTime() - orderedDate.getTime()) / (1000 * 60 * 60 * 24);
      totalProcessedOrders += 1;
    }
    orderedDate = undefined;
    finishDate = undefined;
  }
  if (totalProcessedOrders !== 0) averageProcessingTime = totalProcessingTime / totalProcessedOrders;
  if (user && user.commission && user.commission > 0 && writtenProfit > 0)
    commission = (+user.commission * +writtenProfit) / 100;
  const finalBookMargin = bookProfit > 0 && bookSales > 0 ? (bookProfit / bookSales) * 100 : 0;
  const finalWrittenMargin = writtenProfit > 0 && writtenSales > 0 ? (writtenProfit / writtenSales) * 100 : 0;
  return {
    BookSales: bookSales,
    WrittenSales: writtenSales,
    WrittenMargin: finalWrittenMargin,
    bookProfit,
    BookMargin: finalBookMargin,
    TotalMargin: writtenProfit,
    PendingOffers: pendingOffers,
    PendingOrders: pendingOrders,
    WrittenProfit: writtenProfit,
    Output: output,
    Commission: commission,
    marginpercent: finalWrittenMargin,
    OfferVolume: offerVolume,
    BadMargin: badMargin,
    GoodMargin: goodMargin,
    TotalResults: orders.length,
    totalTime: averageProcessingTime
  } as Stats;
}

/**
 * Collect the outstanding order volume of the given orders
 * @param orders: Orders that should be used for calculation
 * @param period: Period with start and end date that is relevant
 * @returns { number } Outstanding order volume
 */
function getOutstandingOrderVolume(orders: Array<OrdersDocument>, period: Period) {
  let outstandingOrderVolume = 0;
  // Do not calculate outstanding volume for periods that start in the future
  if (period.beginning < new Date()) {
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      if (
        orderUtils.isOrder(order) &&
        !contractUtils.isContract(order) &&
        order.state !== DECLINED &&
        order.createdOn <= period.end
      ) {
        const fulfillment = order.timeline.find(t => t.type === T_FULFILLMENT);
        if (
          (fulfillment && fulfillment.date <= period.end) ||
          getAlreadyInvoicedVolume([order], { beginning: new Date(0), end: period.end }) !== 0
        )
          continue;
        outstandingOrderVolume += getTotalTurnover(order);
      }
    }
  }
  return outstandingOrderVolume;
}

/**
 * Get the outstanding relative margin for the given orders.
 * @param orders: Orders that should be used for calculation
 * @param period: Period with start and end date that is relevant
 * @returns Outstanding margin
 */
function getOutstandingMargin(orders: Array<OrdersDocument>, period: Period) {
  let outstandingMargin = 0;
  if (period.beginning < new Date()) {
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      if (
        orderUtils.isOrder(order) &&
        !contractUtils.isContract(order) &&
        order.state !== DECLINED &&
        order.createdOn <= period.end
      ) {
        const fulfillment = order.timeline.find(t => t.type === T_FULFILLMENT);
        if (
          (fulfillment && fulfillment.date <= period.end) ||
          getAlreadyInvoicedVolume([order], { beginning: new Date(0), end: period.end }) !== 0
        )
          continue;
        outstandingMargin += getTotalMargin(order);
      }
    }
  }
  return outstandingMargin;
}

/**
 * Collect the already invoiced volume of the given orders
 * @param orders: Orders that should be used for calculation
 * @param period: Period with start and end date that is relevant
 * @param user: If set only the orders of the user are checked
 * @returns { number } Already invoiced volume
 */
function getAlreadyInvoicedVolume(orders: Array<OrdersDocument>, period: Period, user?: BSON.ObjectId) {
  let alreadyInvoicedVolume = 0;
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    if (
      !orderUtils.isOrder(order) ||
      contractUtils.isContract(order) ||
      order.state === DECLINED ||
      !order.invoices ||
      (user && order.createdFrom.toString() !== user.toString())
    )
      continue;
    for (let j = 0; j < order.invoices.length; j++) {
      const invoice = order.invoices[j];
      if (
        invoice.state !== I_CANCELED &&
        invoice.invoiceDate >= period.beginning &&
        invoice.invoiceDate <= period.end
      ) {
        alreadyInvoicedVolume += invoiceUtils.getSubtotal(invoice.positions);
      }
    }
  }
  return alreadyInvoicedVolume;
}

/**
 * Collect the book margin of the given orders
 * @param orders: Orders that should be used for calculation
 * @param period: Period with start and end date that is relevant
 * @returns { number } Book margin
 */
function getBookMargin(orders: Array<OrdersDocument>, period: Period) {
  let bookMargin = 0;
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    if (![DECLINED].includes(order.state) && order.createdOn >= period.beginning && order.createdOn <= period.end) {
      bookMargin += getTotalMargin(order) / getTotalTurnover(order);
    }
  }
  return bookMargin;
}

/**
 * Collect the written profit of the given orders
 * @param orders: Orders that should be used for calculation
 * @param period: Period with start and end date that is relevant
 * @returns { number } Written profit
 */
function getWrittenProfit(orders: Array<OrdersDocument>, period: Period) {
  let writtenProfit = 0;
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    if (!orderUtils.isOrder(order) || contractUtils.isContract(order) || order.state === DECLINED || !order.invoices)
      continue;
    const percentMargin = getPercentMargin(order);
    if (percentMargin < 0) {
      console.warn("Found negative margin for order", order._id.toString());
    }
    for (let j = 0; j < order.invoices.length; j++) {
      const invoice = order.invoices[j];
      if (invoice.invoiceDate >= period.beginning && invoice.invoiceDate <= period.end) {
        for (let k = 0; k < invoice.positions.length; k++) {
          const position = invoice.positions[k];
          const total = invoiceUtils.getSubtotal([position]);
          if (invoice.state !== I_CANCELED) {
            writtenProfit += invoiceUtils.calculateAbsoluteMargin(total, percentMargin);
          }
        }
      }
    }
  }
  return writtenProfit;
}

/**
 * Collect the average total margin of the given orders
 * @param orders: Orders that should be used for calculation
 * @param period: Period with start and end date that is relevant
 * @returns { number } Average total margin
 */
function getAverageTotalMargin(orders: Array<OrdersDocument>, period: Period) {
  let totalMargin = 0;
  let amountOrders = 0;
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    if (
      !contractUtils.isContract(order) &&
      order.state !== DECLINED &&
      order.createdOn >= period.beginning &&
      order.createdOn <= period.end
    ) {
      amountOrders++;
      totalMargin += getTotalMargin(order);
    }
  }
  return amountOrders ? totalMargin / amountOrders : 0;
}

/**
 * Collect the average order volume of the given orders
 * @param orders: Orders that should be used for calculation
 * @param period: Period with start and end date that is relevant
 * @returns { number } Average order volume
 */
function getAverageOrderVolume(orders: Array<OrdersDocument>, period: Period) {
  let outstandingOrderVolume = 0;
  let amountOrders = 0;
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    if (
      !contractUtils.isContract(order) &&
      order.state !== DECLINED &&
      order.createdOn >= period.beginning &&
      order.createdOn <= period.end
    ) {
      amountOrders++;
      outstandingOrderVolume += getTotalTurnover(order);
    }
  }
  return outstandingOrderVolume / amountOrders;
}

/**
 * Collect the average processing time of the given orders
 * @param orders: Orders that should be used for calculation
 * @param period: Period with start and end date that is relevant
 * @returns { number } Average processing time
 */
function getAverageProcessingTime(orders: Array<OrdersDocument>, period: Period) {
  let processingTime = 0;
  let amountOrders = 0;
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    if (orderUtils.isOrder(order) && !contractUtils.isContract(order) && order.state !== DECLINED) {
      let orderedDate;
      let finishDate;
      for (let j = 0; j < order.timeline.length; j++) {
        const timelineEntry = order.timeline[j];
        if (timelineEntry.type === T_FULFILLMENT) {
          if (timelineEntry.date < period.beginning || timelineEntry.date > period.end) break;
          finishDate = timelineEntry.date;
        }
        if (timelineEntry.type === T_ORDERED) orderedDate = timelineEntry.date;
      }
      if (orderedDate && finishDate) {
        processingTime += (finishDate - orderedDate) / (1000 * 60 * 60 * 24);
        amountOrders += 1;
      }
    }
  }
  return processingTime / amountOrders;
}

/**
 * Collect the active customers of the given orders
 * @param orders: Orders that should be used for calculation
 * @param period: Period with start and end date that is relevant
 * @returns { number } Active customers
 */
function getActiveCustomers(orders: Array<OrdersDocument>, period: Period) {
  const customers: { [key: string]: boolean } = {};
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    if (![DECLINED].includes(order.state) && order.createdOn >= period.beginning && order.createdOn <= period.end) {
      if (!customers[order.createdFor.toString()]) customers[order.createdFor.toString()] = true;
    }
  }
  return Object.keys(customers).length;
}

/**
 * Calculate the total turnover of an order
 * @param order an order document
 * @returns {number} the total turnover of the order
 */
function getTotalTurnover(order: OrdersDocument) {
  if (orderUtils.hasFulfillmentPriceInfo(order)) {
    return order.fulfillment?.priceInfo?.totalPrice!;
  } else if (order.calculations.length > 1) {
    const turnover = order.calculations.reduce((a, b) => a + +b.info.totalprice, 0);
    return turnover / order.calculations.length;
  } else {
    return order.calculations[0].info.totalprice;
  }
}

/**
 * Calculate the total margin of an order
 * @param order an order document
 * @returns {number} the total margin of the order
 */
function getTotalMargin(order: OrdersDocument) {
  if (orderUtils.hasFulfillmentPriceInfo(order)) {
    return order.fulfillment?.priceInfo?.totalMargin!;
  } else if (order.calculations.length > 1) {
    const totalMargin = order.calculations.reduce((a, b) => a + +b.info.totalmargin, 0);
    return totalMargin / order.calculations.length;
  } else {
    return order.calculations[0].info.totalmargin;
  }
}

/**
 * Calculate the percent margin of an order
 * @param order an order document
 * @returns {number} the percent margin of the order
 */
function getPercentMargin(order: OrdersDocument) {
  if (orderUtils.hasFulfillmentPriceInfo(order)) {
    return +order.fulfillment!.priceInfo!.percentMargin;
  } else if (order.calculations.length > 1) {
    const percentMargin = order.calculations.reduce((a, b) => a + +b.info.percentmargin, 0);
    return percentMargin / order.calculations.length;
  } else {
    return +order.calculations[0].info.percentmargin;
  }
}

/**
 * Calculate the exponential moving average
 */
function getEMA(periods: Array<any>) {
  let total = 0;
  for (let i = 0; i < periods.length; i++) {
    const period = periods[i];
    if (period.invoiced > 0) total += period.invoiced * (periods.length - i);
  }
  // Gaußsche Summenformel
  if (total > 0) return total / ((periods.length * (periods.length + 1)) / 2);
  return 0;
}

/**
 * Calculate the average capacity of the given periods. Only periods that contained invoiced orders are considered.
 * @param periods: Periods whose average should be calculated
 * @returns { number } Average capacity
 */
function getAverageCapacity(periods: Array<any>) {
  let total = 0;
  let count = 0;
  for (let i = 0; i < periods.length; i++) {
    const period = periods[i];
    if (period.invoiced) {
      count++;
      total += period.invoiced;
    }
  }
  return total / count;
}

/**
 * Calculate the price difference of calculations between estimated and real price
 * @param order an order document
 * @returns {number} price difference of commodities
 */
function getCommodityPriceDifference(order: OrdersDocument) {
  let previous = 0;
  let current = 0;
  for (let i = 0; i < order.calculations.length; i++) {
    const calculation = order.calculations[i];
    for (let j = 0; j < calculation.prices.length; j++) {
      const price = calculation.prices[j];
      previous += price.estimatedprice ? +price.estimatedprice : +price.price;
      current += +price.price;
    }
  }
  return Math.round((current / previous - 1) * 100 * 100) / 100;
}

/**
 * Check if the order is inside the correct week.
 * @param order: Order that should be checked
 * @param begin: Begin of the period
 * @param end: End of the period
 * @returns { boolean } Indicates whether the order was finished inside the period or not
 */
function checkCorrectWeek(order: OrdersDocument, begin: number, end: number) {
  if (order.productionWeek && order.settings.productionMachine) {
    const [week, year] = order.productionWeek.split("-");
    const date = dateUtils.getStartOfISOWeek(+week + 1, +year);
    return date.getTime() >= begin && date.getTime() <= end;
  } else if (order.delivery) {
    return order.delivery.getTime() >= begin && order.delivery.getTime() <= end;
  }
  return false;
}

/**
 * Calculate the description for the given timeframe. If Monday til Sunday of the same week are selected the CW is shown
 * else the amount of days
 * @param startDate: Start of the timeframe
 * @param endDate: End of the timeframe
 * @returns { string } Timeframe description
 */
function calculateTimeframeDescription(startDate: Date, endDate: Date) {
  const startCW = dateUtils.getCW(startDate);
  const endCW = dateUtils.getCW(endDate);
  return startCW === endCW && endDate.getTime() - startDate.getTime() > 1000 * 60 * 60 * 24 * 6
    ? "CW" + startCW
    : "over " + Math.ceil(dateUtils.getDaysBetween(startDate, endDate)) + " days";
}

/**
 * Get the reserved capacity amount
 * @returns {number} the reserved capacity
 */
function getReservedCapacity(orders: Array<OrdersDocument>) {
  let amount = 0;
  const begin = new Date().getTime();
  const end = new Date(Date.now() + 6 * 7 * 24 * 60 * 60 * 1000).getTime();
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    if (orderUtils.isOrder(order) && order.state !== DECLINED) {
      if (checkCorrectWeek(order, begin, end)) {
        amount += +order.calculations[0].units;
      }
    }
  }
  return amount;
}

export default {
  PERIODS,
  calculateTimeframeDescription,
  checkCorrectWeek,
  createProjection,
  getActiveCustomers,
  getAlreadyInvoicedVolume,
  getAverageCapacity,
  getAverageOrderVolume,
  getAverageTotalMargin,
  getAverageProcessingTime,
  getBookMargin,
  getOrdersForOwner,
  getSalesStats,
  getTotalTurnover,
  getPercentMargin,
  getReservedCapacity,
  getTotalMargin,
  getEMA,
  getOutstandingMargin,
  getOutstandingOrderVolume,
  getCommodityPriceDifference,
  getWrittenProfit
};
