import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { BSON } from "realm-web";
import UploadMaterialFileModal from "../../common/UploadMaterialFileModal";
import RelatedOrders from "../../common/RelatedOrders";
import { DataContext } from "../../../context/dataContext";
import { CommodityBatch, CommoditiesDocument } from "../../../model/commodities.types";
import accessUtils, { CREATELOCATIONS, EDITLOCATIONS } from "../../../utils/accessUtils";
import baseUtils from "../../../utils/baseUtils";
import dateUtils from "../../../utils/dateUtils";
import fileUtils from "../../../utils/fileUtils";
import userService from "../../../services/userService";
import { ORDERORDERCOMMODITIES, PRODUCTION, PRODUCTIONQUEUE, WAITING } from "../../../utils/orderUtils";
import { OrdersDocument } from "../../../model/orders.types";
import commodityUtils, { CUSTOMER } from "../../../utils/commodityUtils";
import calculationUtils from "../../../utils/calculationUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { SuppliersDocument } from "../../../model/suppliers.types";

interface EditBatchModalProps {
  commodity: CommoditiesDocument;
  batch?: CommodityBatch;
  location: BSON.ObjectId;
  onBatchUpdate: (batch: CommodityBatch, create: boolean) => void;
  context: React.ContextType<typeof DataContext>;
  create: boolean;
}
interface EditBatchModalState {
  batchEdited: BatchInput;
  removedOrders: Array<BSON.ObjectId>;
  onSummary: boolean;
  show: boolean;
  showUploadFileModal: boolean;
}

interface BatchInput extends Omit<CommodityBatch, "amount" | "price"> {
  amount: string;
  price: string;
  totalPrice: string;
}

class EditBatchModal extends PureComponent<EditBatchModalProps, EditBatchModalState> {
  constructor(props: EditBatchModalProps) {
    super(props);
    this.state = {
      batchEdited: this.prepareBatchForInput(props.batch),
      removedOrders: [],
      onSummary: false,
      show: false,
      showUploadFileModal: false
    };
  }

  componentDidUpdate(
    prevProps: Readonly<EditBatchModalProps>,
    prevState: Readonly<EditBatchModalState>,
    snapshot?: any
  ) {
    const { batch, location } = this.props;
    if (!_.isEqual(batch, prevProps.batch) || !_.isEqual(location, prevProps.location)) {
      this.setState({ batchEdited: this.prepareBatchForInput(this.props.batch) });
    }
  }

  /**
   * Generates a new, empty, batch.
   * @returns { CommodityBatch } Empty batch
   */
  generateEmptyBatch = () => {
    const { location } = this.props;
    return {
      supplier: "ownstock",
      _id: new BSON.ObjectId().toString(),
      amount: 0,
      lot: "",
      location: location,
      note: "",
      price: 0,
      stocked: new Date(),
      expiry: new Date(),
      files: []
    } as CommodityBatch;
  };

  /**
   * Prepares the batch for input usage by using strings for the number fields.
   * @param batch Batch that should be prepared
   * @returns { BatchInput } Batch prepared for input usage
   */
  prepareBatchForInput = (batch: CommodityBatch | undefined) => {
    const b = batch ? batch : this.generateEmptyBatch();
    const batchCopy = _.omit(_.cloneDeep(b), ["amount", "price"]);
    return {
      ...batchCopy,
      amount: b.amount.toString(),
      price: b.price.toString(),
      totalPrice: (b.amount * b.price).toFixed(2)
    };
  };

  /**
   * Prepares the batch for database usage by using numbers for the number fields.
   * @param batch Batch that should be prepared
   * @returns { CommodityBatch } Batch prepared for database usage
   */
  prepareBatchForDatabase = (batch: BatchInput) => {
    const batchCopy = _.omit(_.cloneDeep(batch), ["amount", "price", "totalPrice"]);
    return { ...batchCopy, amount: +batch.amount, price: +batch.price };
  };

  handleShow = () => this.setState({ show: true });
  handleHide = () =>
    this.setState({
      onSummary: false,
      show: false,
      showUploadFileModal: false,
      batchEdited: this.prepareBatchForInput(this.props.batch),
      removedOrders: []
    });

  /**
   * Handles changing properties of the batch.
   * @param e Event that triggered the change
   */
  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const batchEdited = _.cloneDeep(this.state.batchEdited);
    const name = e.target.name;
    let val: string | Date | BSON.ObjectId = e.target.value;
    if (["stocked", "expiry"].includes(name)) val = new Date(val);
    if (["supplier", "location"].includes(name) && BSON.ObjectId.isValid(val.toString())) {
      val = new BSON.ObjectId(val.toString());
    }
    if (name === "supplier" && val === CUSTOMER) batchEdited.price = "0";
    // @ts-ignore
    batchEdited[name] = val;
    this.setState({ batchEdited });
  };

  /**
   * Handles changing a number value of the batch.
   * @param e Event that triggered the change
   */
  handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const batchEdited = _.cloneDeep(this.state.batchEdited);
    let val = e.target.value;
    val = val.replaceAll(/^0+/g, "0");
    if (!val.includes(".")) val = Number(val).toString();
    if (!val || Number(val) < 0) return;
    if (e.target.name === "totalPrice") {
      batchEdited.price = batchEdited.amount !== "0" ? Number((+val / +batchEdited.amount).toFixed(2)).toString() : "0";
    } else if (e.target.name === "price") {
      batchEdited.totalPrice = Number((+batchEdited.amount * +val).toFixed(2)).toString();
    } else if (e.target.name === "amount") {
      batchEdited.totalPrice = Number((+batchEdited.price * +val).toFixed(2)).toString();
    }
    // @ts-ignore
    batchEdited[e.target.name] = val;
    this.setState({ batchEdited });
  };

  /**
   * Handles the blur of a number field. The value is casted to number and back to string.
   * @param e Event that triggered the change
   */
  handleBlurNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = Number(e.target.value).toString();
    this.handleChangeNumber(e);
  };

  /**
   * Handles adding a new file for the batch
   * @param id Not needed, kept for comparability
   * @param path Path to the file
   * @param type Not needed, kept for comparability
   * @param title Title of the file
   */
  handleAddUpload = (id: BSON.ObjectId, path: string, type: string, title?: string) => {
    const batchEdited = _.cloneDeep(this.state.batchEdited);
    const fileType = fileUtils.getFileExtension(path);
    batchEdited.files.push({
      type: "file",
      date: new Date(),
      person: userService.getUserId(),
      path,
      title: title ? title : "",
      category: "file",
      fileType: fileType === null ? "" : fileType[1],
      fileSize: 0
    });
    this.setState({ batchEdited, showUploadFileModal: false });
  };

  handleDeleteUpload = (path: string) => {
    const batchEdited = _.cloneDeep(this.state.batchEdited);
    batchEdited.files = batchEdited.files.filter(f => f.path !== path);
    this.setState({ batchEdited });
  };

  /**
   * Handles the update of a batch
   */
  handleBatchUpdate = () => {
    const { create, onBatchUpdate } = this.props;
    const { batchEdited, removedOrders } = this.state;
    if (batchEdited.supplier !== "customer") {
      batchEdited.orders = [];
    } else {
      const rOString = removedOrders.map(rO => rO.toString());
      batchEdited.orders = batchEdited.orders!.filter(o => !rOString.includes(o.toString()));
    }
    // Since the empty batch is generated when the outer page renders it can happen that every batch generated while
    // there is getting the same id
    if (create) batchEdited._id = new BSON.ObjectId().toString();
    onBatchUpdate(this.prepareBatchForDatabase(batchEdited), create);
    this.handleHide();
  };

  /**
   * Handles adding a new order to the batchEdited orders array.
   * @param e Event that triggered the change
   */
  onOrdersChange = (e: any) => {
    const batchEdited = _.cloneDeep(this.state.batchEdited);
    if (!batchEdited.orders) batchEdited.orders = [];
    batchEdited.orders.push(new BSON.ObjectId(e.value));
    this.setState({ batchEdited });
  };

  /**
   * Handles removing an order from the batchEdited orders array
   * @param order ID of the order that should be removed
   * @param remove Flag that is needed for compatibility and should always be set
   */
  onOrdersRemove = (order: BSON.ObjectId, remove?: boolean) => {
    let removedOrders = _.cloneDeep(this.state.removedOrders);
    const isInRemoved = removedOrders.some(o => o.toString() === order.toString());
    if (remove && !isInRemoved) {
      removedOrders.push(order);
    } else if (!remove && isInRemoved) {
      // Undo removal
      removedOrders = removedOrders.filter(o => o.toString() !== order.toString());
    }
    this.setState({ removedOrders });
  };

  /**
   * Checks the batch for invalid data. The LOT, amount and price have to be filled out.
   */
  checkInvalidData = () => {
    const { create } = this.props;
    const { batchEdited, removedOrders } = this.state;
    let invalid =
      (create && +batchEdited.amount <= 0) || (!create && +batchEdited.amount < 0) || batchEdited.lot.trim() === "";
    if (batchEdited.supplier === CUSTOMER) {
      const rOString = removedOrders.map(rO => rO.toString());
      const remainingOrders = batchEdited.orders?.filter(o => !rOString.includes(o.toString()));
      return (
        invalid ||
        !batchEdited.orders ||
        batchEdited.orders.length === 0 ||
        !remainingOrders ||
        remainingOrders.length === 0
      );
    }
    return invalid || +batchEdited.price <= 0;
  };

  /**
   * Checks if the batch is from a supplier and if yes, if that supplier is disabled
   * @returns {boolean | undefined} true if the batch supplier is disabled, false if not or the batch is not from a supplier, undefined if no information regarding the disabled state was found
   */
  checkSupplierDisabled = (): boolean | undefined => {
    const { context } = this.props;
    const { supplier } = this.state.batchEdited;
    if (supplier === "accumulatedstock" || supplier === "custom" || supplier === "customer" || supplier === "ownstock")
      return false;
    const supplierDoc = baseUtils.getDocFromCollection(context.suppliers, supplier.toString()) as SuppliersDocument;
    return supplierDoc.disabled;
  };

  render() {
    const { batch, commodity, context, create } = this.props;
    const { batchEdited, removedOrders, onSummary, show, showUploadFileModal } = this.state;
    const invalid = this.checkInvalidData();
    const supplierDisabled = this.checkSupplierDisabled();
    const warnings = supplierDisabled ? ["The selected supplier is disabled"] : [];
    let priceDiffersToMuch = false;
    let averagePrice = calculationUtils.getAverageStockPrice(commodity.stock);
    const priceDifference = Math.abs(1 - +batchEdited.price / averagePrice);
    if (priceDifference > 0.3) {
      priceDiffersToMuch = true;
    }
    const canChange = create
      ? accessUtils.canCreateData(CREATELOCATIONS.COMMODITYSTOCK)
      : accessUtils.canEditData(EDITLOCATIONS.COMMODITYSTOCK);

    const isCustomerStock = batchEdited.supplier === CUSTOMER;
    let orders: Array<OrdersDocument> = [];
    if (isCustomerStock) {
      orders = context.orders.filter(
        o =>
          [ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION].includes(o.state) &&
          o.settings.manufacturer.toString() === batchEdited.location.toString() &&
          (!batchEdited.orders || !batchEdited.orders.some(bO => bO.toString() === o._id.toString())) &&
          o.calculations[0].prices.some(p => p._id.toString() === commodity._id.toString())
      );
    }
    const isApproved = commodityUtils.isCommodityApproved(commodity);

    return (
      <>
        <button
          className={
            "btn btn-sm mr-1" +
            (canChange && isApproved ? "" : " disabled") +
            (!!batch && batch.disabled ? " btn-secondary" : " btn-success")
          }
          onClick={canChange && isApproved ? this.handleShow : undefined}
          disabled={!canChange || !isApproved}
        >
          {create ? (
            <>
              <i className="fa fa-plus" /> Add new Batch
            </>
          ) : (
            <i className={"fas pr-0 " + (!!batch && batch.disabled ? "fa-info-circle" : "fa-pen")} />
          )}
        </button>
        <Modal
          show={show}
          onHide={this.handleHide}
          centered
          size="lg"
          style={showUploadFileModal ? { zIndex: "99" } : {}}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {onSummary
                ? "Data summary for Batch " + batchEdited.lot
                : create
                ? "Create new Batch"
                : "Batch " + (!!batch && batch.lot) + " Settings"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {onSummary ? (
              <>
                <h6 className="text-dark font-weight-bolder text-center">
                  Please check that the inserted data is valid!
                </h6>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Lot</label>
                  <div className="col-10">
                    <input type="text" className="form-control" disabled value={batchEdited.lot} name="lot" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Price / kg</label>
                  <div className="col-4">
                    <div className="input-group">
                      <input type="number" className="form-control" disabled value={batchEdited.price} name="price" />
                      <div className="input-group-append">
                        <span className="input-group-text">€</span>
                      </div>
                    </div>
                  </div>
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Total price</label>
                  <div className="col-4">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        disabled
                        value={batchEdited.totalPrice}
                        name="totalPrice"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">€</span>
                      </div>
                    </div>
                  </div>
                </div>
                {priceDiffersToMuch && batchEdited.supplier !== CUSTOMER && (
                  <div className="alert alert-danger mt-4" role="alert">
                    <div className="alert-icon">
                      <i className="flaticon-exclamation-1" />
                    </div>
                    <div className="alert-text">
                      Price differs in <b>{(Math.round(priceDifference * 100 * 100) / 100).toString() + "%"}</b> -
                      please check that you have entered the price per kg!
                      <br />
                      The current average price is <b>{baseUtils.formatEuro(averagePrice)}</b>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Lot</label>
                  <div className="col-10">
                    <input
                      type="text"
                      className="form-control"
                      value={batchEdited.lot}
                      name="lot"
                      onChange={(!!batch && batch.disabled) || !create ? undefined : this.handleChange}
                      placeholder="LOT"
                      disabled={(!!batch && batch.disabled) || !create}
                    />
                  </div>
                </div>
                {batch && !batch.disabled && (
                  <div className="alert alert-warning" role="alert">
                    <div className="alert-icon">
                      <i className="flaticon-warning" />
                    </div>
                    <div className="alert-text">
                      Please avoid editing prices or merging different batches, as it will change corresponding orders.
                      <br />
                      Each batch should be handled independently.
                    </div>
                  </div>
                )}
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Amount</label>
                  <div className="col-10">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        min={0}
                        value={batchEdited.amount}
                        name="amount"
                        onChange={this.handleChangeNumber}
                        onBlur={this.handleBlurNumber}
                        disabled={!!batch && batch.disabled}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">{commodity.type ? "tsd" : "kg"}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Supplier</label>
                  <div className="col-10">
                    <select
                      className="form-control"
                      value={batchEdited.supplier.toString()}
                      name="supplier"
                      onChange={(!!batch && batch.disabled) || !create ? undefined : this.handleChange}
                      disabled={(!!batch && batch.disabled) || !create}
                    >
                      <option value="customer">Customer</option>
                      <option value="ownstock">Stock</option>
                      {context.suppliers
                        .sort((s1, s2) => s1.name.localeCompare(s2.name))
                        .map(s => (
                          <option key={s._id.toString()} value={s._id.toString()}>
                            {s.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">
                    Price / {commodity.type ? "tsd" : "kg"}
                  </label>
                  <div className="col-4">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        value={batchEdited.price}
                        name="price"
                        onChange={this.handleChangeNumber}
                        onBlur={this.handleBlurNumber}
                        disabled={(!!batch && batch.disabled) || isCustomerStock}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">€</span>
                      </div>
                    </div>
                  </div>
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Total price</label>
                  <div className="col-4">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        value={batchEdited.totalPrice}
                        name="totalPrice"
                        onChange={this.handleChangeNumber}
                        onBlur={this.handleBlurNumber}
                        disabled={(!!batch && batch.disabled) || isCustomerStock}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">€</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Location</label>
                  <div className="col-10">
                    <select
                      className="form-control"
                      value={batchEdited.location.toString()}
                      name="location"
                      disabled={create || (!!batch && batch.disabled)}
                      onChange={this.handleChange}
                    >
                      {context.manufacturers.map(m => (
                        <option key={m._id.toString()} value={m._id.toString()}>
                          {m.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Since</label>
                  <div className="col-10">
                    <input
                      type="date"
                      className="form-control"
                      value={isNaN(+batchEdited.stocked) ? 0 : batchEdited.stocked.toISOString().split("T")[0]}
                      name="stocked"
                      onChange={this.handleChange}
                      disabled={!!batch && batch.disabled}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Expiry</label>
                  <div className="col-10">
                    <input
                      type="date"
                      className="form-control"
                      value={isNaN(+batchEdited.expiry) ? 0 : batchEdited.expiry.toISOString().split("T")[0]}
                      name="expiry"
                      onChange={this.handleChange}
                      disabled={!!batch && batch.disabled}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Note</label>
                  <div className="col-10">
                    <textarea
                      className="form-control"
                      value={batchEdited.note}
                      name="note"
                      placeholder="Important notes, e.g. corresponding order"
                      onChange={this.handleChange}
                      disabled={!!batch && batch.disabled}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Files</label>
                  <div className="col-10">
                    {batchEdited.files.map((f, key) => (
                      <div className="kt-widget4 mb-2" key={f.path + f.date.toString() + key}>
                        <div className="kt-widget4__item">
                          <div className="kt-widget4__pic kt-widget4__pic--icon">
                            <img src={process.env.PUBLIC_URL + "/media/icons/pdf_icon.png"} alt="pdf icon" />
                          </div>
                          <div className="kt-widget4__info">
                            <div>
                              <a
                                href={f.path}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="kt-widget4__username mr-2"
                              >
                                {(f.title ? f.title : "File #" + (key + 1)) + " - " + baseUtils.formatDate(f.date)}
                              </a>
                            </div>
                            <p className="kt-widget4__text">{dateUtils.getTimeAgo(f.date)}</p>
                          </div>
                          {(!batch || !batch.disabled) && (
                            <button
                              className="btn btn-danger btn-sm p-2"
                              onClick={() => this.handleDeleteUpload(f.path)}
                            >
                              <i className="flaticon2-cross px-1" />
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                    {(!batch || !batch.disabled) && (
                      <>
                        <button
                          className="btn btn-secondary"
                          onClick={() => this.setState({ showUploadFileModal: true })}
                        >
                          Upload
                        </button>
                        {showUploadFileModal && (
                          <UploadMaterialFileModal
                            material={commodity}
                            type=""
                            addUpload={this.handleAddUpload}
                            onClose={() => this.setState({ showUploadFileModal: false })}
                            hideSupplier={true}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
                {isCustomerStock && (
                  <div className="form-group row">
                    <label className="col-2 col-form-label text-right text-dark kt-font-bold">Related Orders</label>
                    <div className="col-10">
                      <Select
                        className="select-default"
                        options={orders.map(o => {
                          return { value: o._id.toString(), label: "AT-" + o.identifier + " " + o.title };
                        })}
                        value={{ value: "", label: "Select Orders" }}
                        onChange={(e: any) => this.onOrdersChange(e)}
                      />
                      <RelatedOrders
                        orders={batchEdited.orders || []}
                        context={context}
                        removedOrders={removedOrders}
                        onRelatedOrder={this.onOrdersRemove}
                        alwaysRemovable={true}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </Modal.Body>
          {(!batch || !batch.disabled) && (
            <Modal.Footer>
              <button
                className="btn btn-secondary"
                onClick={() => (onSummary ? this.setState({ onSummary: false }) : this.handleHide())}
              >
                {onSummary ? "Back" : "Close"}
              </button>
              <ErrorOverlayButton
                className="btn btn-success"
                buttonText={onSummary ? "Save changes" : "Next"}
                onClick={() => (onSummary ? this.handleBatchUpdate() : this.setState({ onSummary: true }))}
                disabled={invalid}
                warnings={warnings}
              />
            </Modal.Footer>
          )}
        </Modal>
      </>
    );
  }
}

export default EditBatchModal;
