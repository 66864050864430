import _ from "lodash";
import React, { Component } from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DataContext } from "../../../context/dataContext";
import { ManufacturersDocument, ProductionLine } from "../../../model/manufacturers.types";
import { OrdersDocument } from "../../../model/orders.types";
import DeleteProductionLineModal from "./DeleteProductionLineModal";
import userService from "../../../services/userService";
import StartProductionModal from "./StartProductionModal";
import manufacturerUtils from "../../../utils/manufacturerUtils";
import { ROLES } from "../../../utils/userdataUtils";
import authenticationService from "../../../services/authenticationService";
import { PRODUCTION, PRODUCTIONQUEUE } from "../../../utils/orderUtils";
import dateUtils from "../../../utils/dateUtils";
import ProductionLineModal from "./ProductionLineModal";
import ReturnToQueueModal from "../../order/modals/ReturnToQueueModal";

interface ProductionLineProps extends RouteComponentProps<{}, {}, {}> {
  productionLine: ProductionLine;
  orders: Array<OrdersDocument>;
  updateProductionLines: () => void;
}

interface ProductionLineState {
  manufacturer: ManufacturersDocument;
  searchFilter: string;
  showEditProductionLineModal: boolean;
  showDeleteProductionLineModal: boolean;
  showOrderInformationModal: boolean;
  selectedOrder?: OrdersDocument;
}

class ProductionLineWidget extends Component<ProductionLineProps, ProductionLineState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: ProductionLineProps, context: React.ContextType<typeof DataContext>) {
    super(props);
    const manufacturer = context.manufacturers.find(
      m =>
        m.productionLines &&
        m.productionLines.map(pl => pl._id.toString()).includes(props.productionLine._id.toString())
    );
    this.state = {
      manufacturer: manufacturer!,
      searchFilter: "",
      showEditProductionLineModal: false,
      showDeleteProductionLineModal: false,
      showOrderInformationModal: false
    };
  }

  componentDidUpdate(
    prevProps: Readonly<ProductionLineProps>,
    prevState: Readonly<ProductionLineState>,
    snapshot?: any
  ) {
    if (!_.isEqual(this.props.productionLine, prevProps.productionLine)) {
      this.setState({ manufacturer: this.findManufacturer() });
    }
  }

  /**
   * Find the manufacturer the production line of the widget relates to.
   * @returns { ManufacturersDocument } Manufacturer the production line belongs to
   */
  findManufacturer = () => {
    const { manufacturers } = this.context;
    return manufacturers.find(
      m =>
        m.productionLines &&
        m.productionLines.map(pl => pl._id.toString()).includes(this.props.productionLine._id.toString())
    )!;
  };

  /**
   * Finish the current order of the production line.
   */
  onClickFinishCurrentOrder = async (order: OrdersDocument) =>
    this.props.history.push("/order/" + order._id.toString() + "?productionFinished=true");

  render() {
    const { productionLine, orders, updateProductionLines } = this.props;
    const {
      manufacturer,
      showDeleteProductionLineModal,
      showEditProductionLineModal,
      showOrderInformationModal,
      selectedOrder
    } = this.state;
    const ordersForMachine = orders.filter(
      o => o.settings.productionMachine!.toString() === productionLine._id.toString()
    );
    const ordersInProduction = ordersForMachine.filter(o => o.state === PRODUCTION);
    const ordersInQueue = ordersForMachine.filter(o => o.state === PRODUCTIONQUEUE);
    const ordersPlanned =
      ordersForMachine.length > 0 ||
      this.context.orders.some(
        o => o.settings.productionMachine && o.settings.productionMachine.toString() === productionLine._id.toString()
      );
    const canMakeActions =
      userService.isAuthorizedForAction(ROLES.PRODUCTION) ||
      manufacturerUtils.isEmployeeOfManufacturer(authenticationService.getUserDataID(), manufacturer);
    return (
      <>
        <div className="col-12 col-md-6 col-lg-3">
          <div className="kt-portlet kt-portlet--height-fluid">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <div className="kt-portlet__head-title">
                  <div className="h5 mb-0 font-weight-bold">
                    {productionLine.name}
                    <span className="text-muted ml-2" style={{ fontSize: "12px" }}>
                      {manufacturer.name}
                    </span>
                  </div>
                  <span className="text-muted" style={{ fontSize: "12px" }}>
                    Capacity: {productionLine.capacity}
                  </span>
                </div>
              </div>
              {canMakeActions && (
                <div className="kt-portlet__head-toolbar">
                  <button
                    type="button"
                    className="btn bg-white"
                    onClick={() => this.setState({ showEditProductionLineModal: true })}
                  >
                    <i className="fa fa-cog pr-0" />
                  </button>
                  {(userService.isAuthorizedForAction(ROLES.ADMIN) ||
                    manufacturerUtils.isResponsibleOfManufacturer(userService.getUserData()._id, manufacturer)) &&
                    (ordersPlanned ? (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="plannedOrders">
                            <span className="text-danger">
                              <b>
                                The machine currently can't be deleted as there are still orders planned for this
                                machine
                              </b>
                            </span>
                          </Tooltip>
                        }
                      >
                        <button type="button" className={"btn bg-white disabled"} style={{ cursor: "not-allowed" }}>
                          <i className="fas fa-trash-alt pr-0" />
                        </button>
                      </OverlayTrigger>
                    ) : (
                      <button
                        type="button"
                        className="btn bg-white"
                        onClick={() => this.setState({ showDeleteProductionLineModal: true })}
                      >
                        <i className="fas fa-trash-alt pr-0" />
                      </button>
                    ))}
                  <DeleteProductionLineModal
                    onHide={() => this.setState({ showDeleteProductionLineModal: false })}
                    productionLine={productionLine}
                    show={showDeleteProductionLineModal}
                  />
                </div>
              )}
            </div>
            {productionLine.image.includes("youtube") ? (
              <iframe
                src={productionLine.image + "?autoplay=1&modestbranding=1&rel=0&showinfo=0"}
                frameBorder="0"
                allowFullScreen
                style={{ width: "100%", height: "335px" }}
              />
            ) : (
              <img
                src={productionLine.image ? productionLine.image : "media/img/machine.jpg"}
                style={{ width: "100%", height: "335px", objectFit: "cover" }}
              />
            )}
            <div className="kt-portlet__body">
              <div className="row">
                <div className="col-12">
                  <div className="text-muted">
                    Updated:{" "}
                    {productionLine.lastUpdate.toLocaleDateString("de-DE", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit"
                    })}{" "}
                    {productionLine.lastUpdate.toLocaleTimeString()}
                  </div>
                  <span className="text-dark h5">
                    In Production:
                    <br />
                    {ordersInProduction.length > 0 ? (
                      ordersInProduction.map(order => (
                        <div className="mb-1" key={order._id.toString()}>
                          <Link
                            to={"/order/" + order._id.toString()}
                            className="kt-link text-success mb-1"
                            style={{ fontSize: "1.1rem" }}
                          >
                            AT-{order.identifier}{" "}
                            {order.title.length > 38 ? order.title.substring(0, 35) + "..." : order.title}
                          </Link>
                          <br />
                          <button
                            className="btn btn-success btn-xs mr-2 "
                            onClick={() => this.onClickFinishCurrentOrder(order)}
                          >
                            <i className="fa fa-check " />
                            Finish
                          </button>
                          <ReturnToQueueModal order={order} variant={"productionDashboard"} />
                        </div>
                      ))
                    ) : (
                      <span className="text-danger">Inactive</span>
                    )}
                  </span>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <div className="text-dark h5 h-100">Next:</div>
                </div>
                <div className="col-12">
                  <div className="row">
                    {ordersInQueue.length > 0 ? (
                      ordersInQueue
                        .sort((o1, o2) => o1.createdOn.getTime() - o2.createdOn.getTime())
                        .map(order => {
                          return (
                            <div className="col-12 col-md-6 mb-4" key={order._id.toString()}>
                              <div className="text-dark">
                                <span
                                  className="kt-link text-dark pointer"
                                  style={{ fontWeight: 700 }}
                                  onClick={() =>
                                    this.setState({ showOrderInformationModal: true, selectedOrder: order })
                                  }
                                >
                                  AT-{order.identifier}{" "}
                                  {order.title.length > 15 ? order.title.slice(0, 12) + "..." : order.title}
                                </span>
                                <br />
                                {order.targetDate ? "CW" + dateUtils.getCW(order.targetDate) : "-"}
                              </div>
                            </div>
                          );
                        })
                    ) : (
                      <div className="col-12 col-xl-6 text-dark">No orders queued</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProductionLineModal
          onHide={() => this.setState({ showEditProductionLineModal: false })}
          selectedManufacturer={manufacturer._id.toString()}
          show={showEditProductionLineModal}
          updateProductionLines={updateProductionLines}
          context={this.context}
          productionLine={productionLine}
          manufacturer={manufacturer}
        />
        <StartProductionModal
          show={showOrderInformationModal}
          order={selectedOrder}
          onHide={() => this.setState({ showOrderInformationModal: false, selectedOrder: undefined })}
          productionLine={productionLine}
          canMakeActions={canMakeActions}
        />
      </>
    );
  }
}

export default withRouter(ProductionLineWidget);
