import React, { Component } from "react";
import { BSON } from "realm-web";
import CalculationDetails from "./calculationDetails/CalculationDetails";
import {
  CalculationCustomPriceDetails,
  CalculationType,
  CustomCalculationForModal,
  ExtendedCapsule,
  Preferences,
  SelectedCommoditiesDocument,
  SelectedPackagingsDocument
} from "./CustomTypes";

interface CalculationProps {
  activeType: string;
  preferences: Preferences;
  calculations: Array<CalculationType>;
  recipe: Array<SelectedCommoditiesDocument>;
  selectedPackaging: Array<SelectedPackagingsDocument>;
  selectedCapsule: ExtendedCapsule | null;
  customCalculations: Array<CalculationCustomPriceDetails>;
  onCalculationPropertyChange: (id: BSON.ObjectId, path: string, value: any, updatePrices?: boolean) => void;
  onCalculationAdd: () => void;
  onCalculationDelete: (calculation: CalculationType) => void;
  onRecalculateSpecific: (calculation: CalculationType) => void;
  onUpdateCalculation: (id: BSON.ObjectId) => void;
  onCapsuleCalculationUpdate: (calculationId: BSON.ObjectId, path: string, value: any) => void;
  onRecipeCalculationUpdate: (
    commodityId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    path: string,
    value: any,
    recalculate: boolean
  ) => void;
  onPackagingCalculationUpdate: (
    packagingId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    path: string,
    value: any
  ) => void;
  onCapsuleSupplierChange: (calculationId: BSON.ObjectId, newSupplier: BSON.ObjectId | "ownstock" | "customer") => void;
  onPackagingSupplierChange: (
    packagingId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    newSupplier: BSON.ObjectId | "ownstock" | "customer"
  ) => void;
  onRecipeSupplierChange: (
    commodityId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    newSupplier: BSON.ObjectId | "ownstock" | "customer"
  ) => void;
  onSaveCustomCalculation: (
    customCalculationInfo: Array<CustomCalculationForModal>,
    calcId: BSON.ObjectId | string,
    buffer: string | undefined,
    optionalCost: string | undefined,
    note: string | undefined
  ) => void;
  onResetCustomCalculation: (calcId: BSON.ObjectId) => void;
}

interface CalculationState {}

class Calculation extends Component<CalculationProps, CalculationState> {
  render() {
    const {
      activeType,
      preferences,
      calculations,
      recipe,
      selectedPackaging,
      selectedCapsule,
      customCalculations,
      onCalculationPropertyChange,
      onCalculationAdd,
      onCalculationDelete,
      onRecalculateSpecific,
      onUpdateCalculation,
      onCapsuleCalculationUpdate,
      onRecipeCalculationUpdate,
      onPackagingCalculationUpdate,
      onCapsuleSupplierChange,
      onPackagingSupplierChange,
      onRecipeSupplierChange,
      onSaveCustomCalculation,
      onResetCustomCalculation
    } = this.props;

    return (
      <div className="kt-portlet__body kt-portlet__body--fit">
        <div
          className={
            "kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--subtable kt-datatable--loaded"
          }
        >
          <table
            className="kt-datatable__table mt-5 table-responsive"
            style={{
              display: "block",
              background: "rgb(247, 248, 250)",
              padding: "15px",
              width: "100%",
              borderRadius: "10px"
            }}
          >
            <thead>
              <tr>
                <td>
                  <h5 className="kt-font-dark" style={{ paddingLeft: "10px" }}>
                    Calculations
                  </h5>
                </td>
              </tr>
            </thead>
            <tbody className="kt-datatable__body">
              {calculations.map(calculation => (
                <CalculationDetails
                  key={calculation.id.toString()}
                  activeType={activeType}
                  disableDelete={calculations.length === 1}
                  preferences={preferences}
                  calculation={calculation}
                  recipe={recipe}
                  selectedPackaging={selectedPackaging}
                  selectedCapsule={selectedCapsule}
                  customCalculation={customCalculations.find(
                    calc => calc.calculationId.toString() === calculation.id.toString()
                  )}
                  onCalculationDelete={onCalculationDelete}
                  onCalculationPropertyChange={onCalculationPropertyChange}
                  onRecalculateSpecific={onRecalculateSpecific}
                  onUpdateCalculation={onUpdateCalculation}
                  onCapsuleCalculationUpdate={onCapsuleCalculationUpdate}
                  onRecipeCalculationUpdate={onRecipeCalculationUpdate}
                  onPackagingCalculationUpdate={onPackagingCalculationUpdate}
                  onCapsuleSupplierChange={onCapsuleSupplierChange}
                  onPackagingSupplierChange={onPackagingSupplierChange}
                  onRecipeSupplierChange={onRecipeSupplierChange}
                  onSaveCustomCalculation={onSaveCustomCalculation}
                  onResetCustomCalculation={onResetCustomCalculation}
                />
              ))}
            </tbody>
          </table>
          <div className="row mt-5">
            <div className="col-auto">
              <button type="button" className="form-control btn btn-success" onClick={onCalculationAdd}>
                <i className="la la-plus" />
                Add Calculation
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Calculation;
