import * as utils from "../../_metronic/utils/utils";

const localStorageLastLocationKey = "AdminCentral-lastLocation";

function acceptLocation(lastLocation) {
  return !!(
    lastLocation &&
    lastLocation.pathname &&
    lastLocation.pathname !== "/" &&
    lastLocation.pathname.indexOf("auth") === -1 &&
    lastLocation.pathname !== "/logout"
  );
}

export function saveLastLocation(lastLocation) {
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  const _useLocations = localStorateLocations ? JSON.parse(localStorateLocations) : [];
  if (acceptLocation(lastLocation)) {
    _useLocations.push(lastLocation.pathname);
    utils.setStorage(localStorageLastLocationKey, JSON.stringify(_useLocations), 120);
  }
}

export function forgotLastLocation() {
  utils.removeStorage(localStorageLastLocationKey);
}

export function getLastLocation() {
  const localStorageLocations = utils.getStorage(localStorageLastLocationKey);
  if (!localStorageLocations) {
    return "/";
  }
  const _userLocations = JSON.parse(localStorageLocations);
  const last = _userLocations.pop();
  return _userLocations.length > 0 && last !== "/login" ? last : "/";
}
