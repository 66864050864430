import _ from "lodash";
import React, { PureComponent } from "react";
import { Accordion } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import { PaginationState } from "../common/CustomTypes";
import HistoryBackButton from "../listings/common/HistoryBackButton";
import {
  CustomerFilter,
  DueLevelFilter,
  InvoicesFilter,
  MissingReminderFilter,
  OwnerFilter,
  SearchBar
} from "../listings/common/Filters";
import SplashScreen from "../common/SplashScreen";
import BaseListing from "../listings/BaseListing";
import { paginate } from "../common/Pagination";
import orderUtils, { DECLINED } from "../../utils/orderUtils";
import { Invoice, OrdersDocument } from "../../model/orders.types";
import FinanceOverviewRow from "./FinanceOverviewRow";
import ExportModal from "./ExportModal";
import invoiceUtils from "../../utils/invoiceUtils";
import baseUtils, { getComponentState } from "../../utils/baseUtils";
import PayInvoiceModal from "../order/modals/PayInvoiceModal";
import { CustomOrder } from "../order/CustomTypes";
import DateInput from "../common/DateInput";
import dateUtils from "../../utils/dateUtils";

interface FinanceOverviewProps extends RouteComponentProps<{}, {}, {}> {
  context: React.ContextType<typeof DataContext>;
}

interface FinanceOverviewState extends PaginationState {
  search: string;
  owner: "" | { value: string; label: string };
  status: { value: string; label: string };
  startDate: Date;
  endDate: Date;
  customer: { value: string; label: string };
  due: { value: string; label: string };
  reminder: { value: string; label: string };
  relevantOrders: Array<OrdersDocument>;
  filteredOrders: Array<OrdersDocument>;
  totalAmount: number;
  invoiceToPay: { order: CustomOrder; invoice: Invoice } | null;
  activeKey: string;
}

const CONSTRUCTORNAME = "FinanceOverview";

class FinanceOverview extends PureComponent<FinanceOverviewProps, FinanceOverviewState> {
  constructor(props: FinanceOverviewProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const relevantOrders = this.getRelevantOrders();
    const state = getComponentState(this.props.context, CONSTRUCTORNAME);
    if (state) {
      state.relevantOrders = relevantOrders;
      this.setState({ ...state });
    } else {
      const filteredOrders = this.getFilteredOrders(relevantOrders);
      const totalAmount = this.getTotalAmount(filteredOrders);
      this.setState({
        relevantOrders,
        filteredOrders,
        totalAmount
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<FinanceOverviewProps>,
    prevState: Readonly<FinanceOverviewState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.context.orders, this.props.context.orders)) {
      const relevantOrders = this.getRelevantOrders();
      const filteredOrders = this.getFilteredOrders(relevantOrders);
      const totalAmount = this.getTotalAmount(filteredOrders);
      this.setState({
        relevantOrders,
        filteredOrders,
        totalAmount
      });
    }
    if (
      prevState.search !== this.state.search ||
      prevState.owner !== this.state.owner ||
      prevState.status !== this.state.status ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.customer !== this.state.customer ||
      prevState.due !== this.state.due ||
      prevState.reminder !== this.state.reminder
    ) {
      const filteredOrders = this.getFilteredOrders(this.state.relevantOrders);
      const totalAmount = this.getTotalAmount(filteredOrders);
      this.setState({ filteredOrders, totalAmount });
    }
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }

  handleReset = () => this.setState(this.getDefaultState(true));
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value, currentPage: 1 });
  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value, currentPage: 1 });
  handleSelectChange = (name: string, entry: { value: string; label: string } | "") => {
    // @ts-ignore
    this.setState({ [name]: entry, currentPage: 1 });
  };
  handlePayInvoice = (order: OrdersDocument, invoice: any) => {
    const { context } = this.props;
    const customOrder: any = {
      ...order,
      createdFrom: baseUtils.getDocFromCollection(context.userdata, order.createdFrom),
      createdFor: baseUtils.getDocFromCollection(context.companies, order.createdFor),
      settings: {
        ...order.settings,
        manufacturer: baseUtils.getDocFromCollection(context.manufacturers, order.settings.manufacturer)
      }
    };
    this.setState({ invoiceToPay: { order: customOrder, invoice } });
  };
  handleClose = () => this.setState({ invoiceToPay: null });

  /**
   * Get the default state
   * @param reset flag if it is a reset or not. on reset orders are kept
   * @returns {FinanceOverviewState} default state for the component
   */
  getDefaultState = (reset?: boolean) => {
    const relevantOrders = reset ? this.state.relevantOrders : [];
    const filteredOrders = reset ? this.state.filteredOrders : [];
    return {
      currentPage: 1,
      pageSize: 10,
      owner: "",
      status: { value: "all", label: "All Statuses" },
      search: "",
      relevantOrders,
      filteredOrders,
      totalAmount: 0,
      invoiceToPay: null,
      activeKey: "",
      startDate: new Date("2020"),
      endDate: new Date((new Date().getFullYear() + 1).toString()),
      customer: { value: "all", label: "All customers" },
      due: { value: "all", label: "All levels" },
      reminder: { value: "all", label: "All invoices" }
    } as FinanceOverviewState;
  };

  /**
   * Get only relevant orders that have an invoice
   * is locked only orders of that manufacturer
   * @returns {Array<OrdersDocument>} list of relevant orders
   */
  getRelevantOrders = () => {
    return this.props.context.orders.filter(
      o => orderUtils.isOrder(o) && o.state !== DECLINED && o.invoices && o.invoices.length > 0
    );
  };

  /**
   * Get filtered orders
   * @param relevantOrders list of relevant orders to filter
   * @returns {Array<OrdersDocument>} list of all orders matching the filters
   */
  getFilteredOrders = (relevantOrders: Array<OrdersDocument>) => {
    const { companies, userdata } = this.props.context;
    const { customer, due, endDate, search, owner, reminder, status, startDate } = this.state;
    let filteredOrders = relevantOrders.slice();
    filteredOrders = orderUtils.filterOrders(filteredOrders, undefined, owner ? owner.value : "");
    if (status)
      filteredOrders = filteredOrders.filter(o => {
        const invoices = invoiceUtils.sortInvoices(o);
        return invoices.some(invoice => invoiceUtils.matchesInvoiceStatus(invoice, status.value));
      });
    if (search.trim() !== "") {
      filteredOrders = orderUtils.filterBySearch(filteredOrders, companies, userdata, search, true) as Array<
        OrdersDocument
      >;
    }
    filteredOrders = filteredOrders.filter(
      o =>
        o.invoices &&
        o.invoices.some(i => {
          const dueDate = orderUtils.getInvoiceDue(i.dueIn, i.invoiceDate);
          const since = dateUtils.getDaysBetween(new Date(), dueDate);
          let check = i.invoiceDate.getTime() > startDate.getTime() && i.invoiceDate.getTime() < endDate.getTime();
          if (due.value !== "all" && check) {
            check = i.state !== "paid";
            switch (due.value) {
              case "0":
                check = check && dueDate.getTime() > new Date().getTime();
                break;
              case "1":
                check = check && due.value === "1" && since >= 0 && since < 7;
                break;
              case "2":
                check = check && due.value === "2" && since >= 7 && since < 14;
                break;
              case "3":
                check = check && due.value === "3" && since >= 14;
                break;
            }
          }
          if (reminder.value !== "all" && dueDate.getTime() < new Date().getTime() && check) {
            check = i.state !== "paid";
            switch (reminder.value) {
              case "reminder":
                check = check && reminder.value === "reminder" && i.reminder.length === 0;
                break;
              case "dunning":
                check = check && reminder.value === "dunning" && !i.reminder.some(r => !!r.position);
                break;
            }
          }
          return check;
        })
    );
    if (customer.value !== "all") {
      filteredOrders = filteredOrders.filter(o => o.createdFor.toString() === customer.value);
    }
    filteredOrders = _.orderBy(filteredOrders, item => item.createdOn, ["desc"]);
    return filteredOrders;
  };

  getTotalAmount = (orders: Array<OrdersDocument>) => {
    return orders.reduce(
      (sum, o) =>
        (o.invoices ? o.invoices.reduce((sumI, i) => (i.state !== "canceled" ? i.totalGross : 0) + sumI, 0) : 0) + sum,
      0
    );
  };

  render() {
    const { context, history } = this.props;
    const { orders, companies, userdata } = context;
    const {
      activeKey,
      pageSize,
      currentPage,
      endDate,
      customer,
      due,
      owner,
      status,
      filteredOrders,
      invoiceToPay,
      reminder,
      search,
      startDate,
      totalAmount
    } = this.state;
    const selectableUsers = userdata.filter(user => user.company_id === "internal");

    const headerDefinition = [
      { title: "", size: 5 },
      { title: "Description", size: 25 },
      { title: "Company", size: 25 },
      { title: "Amount", size: 16 },
      { title: "Progress", size: 12 },
      { title: "Owner / Invoice Issuer", size: 17 }
    ];
    return (
      <>
        {invoiceToPay && (
          <PayInvoiceModal
            order={invoiceToPay.order}
            invoice={invoiceToPay.invoice}
            onHide={this.handleClose}
            show={!!invoiceToPay}
          />
        )}
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand fa fa-file-invoice-dollar" />
              </span>
              <h3 className="kt-portlet__head-title">Invoices</h3>
              <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
                Reset
              </button>
            </div>
            <HistoryBackButton history={history} />
          </div>
          <div className="kt-portlet__body pb-0">
            <div className="kt-form kt-form--label-right kt-margin-b-10">
              <div className="row align-items-center">
                <SearchBar
                  onSearch={this.handleSearch}
                  search={search}
                  additionalSizeClasses={"col-md-4 col-xl-2 mt-2"}
                />
                <OwnerFilter owner={owner} selectableUsers={selectableUsers} onFilterChange={this.handleSelectChange} />
                <InvoicesFilter
                  status={status}
                  onFilterChange={this.handleSelectChange}
                  additionalSizeClasses={"col-md-4 col-xl-2"}
                />
                <div className="col-md-4 col-xl-2 mt-2">
                  <div className="kt-form__group kt-form__group--inline kt-margin-b-20-tablet-and-mobile">
                    <div className="kt-form__label">
                      <label>Start</label>
                    </div>
                    <div className="kt-form__control">
                      <DateInput
                        value={startDate}
                        onBlur={e => this.setState({ startDate: new Date(e.target.valueAsDate!) })}
                        name="start"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-xl-2 mt-2">
                  <div className="kt-form__group kt-form__group--inline kt-margin-b-20-tablet-and-mobile">
                    <div className="kt-form__label">
                      <label>End</label>
                    </div>
                    <div className="kt-form__control">
                      <DateInput
                        value={endDate}
                        onBlur={e => this.setState({ endDate: new Date(e.target.valueAsDate!) })}
                        name="end"
                      />
                    </div>
                  </div>
                </div>
                <CustomerFilter
                  customer={customer}
                  selectableCustomers={companies}
                  onFilterChange={this.handleSelectChange}
                  additionalSizeClasses={"col-md-4 col-xl-2 mt-2"}
                />
                <DueLevelFilter
                  due={due}
                  onFilterChange={this.handleSelectChange}
                  additionalSizeClasses={"col-md-4 col-xl-2 mt-2"}
                />
                <MissingReminderFilter
                  reminder={reminder}
                  onFilterChange={this.handleSelectChange}
                  additionalSizeClasses={"col-md-4 col-xl-2 mt-2"}
                />
                <ExportModal context={context} additionalCSSClasses="mt-2" />
              </div>
              <div className="row align-items-center">
                <div className="col-md-4 mt-4">
                  <span className="text-dark">Total displayed amount: {baseUtils.formatEuro(totalAmount)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="kt-portlet__body kt-portlet__body--fit">
            {orders.length === 0 ? (
              <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
            ) : (
              <Accordion activeKey={activeKey} onSelect={e => this.setState({ activeKey: e ? e : "" })}>
                <BaseListing
                  headerDefinition={headerDefinition}
                  documents={filteredOrders}
                  bodyContent={
                    <>
                      {paginate(filteredOrders, currentPage, pageSize).map(o => (
                        <FinanceOverviewRow
                          key={o._id.toString()}
                          order={o}
                          status={status.value}
                          onPayInvoice={this.handlePayInvoice}
                          activeKey={activeKey}
                          {...this.props}
                        />
                      ))}
                    </>
                  }
                  currentPage={currentPage}
                  pageSize={pageSize}
                  onPageChange={this.handlePageChange}
                  onPageSizeChange={this.handlePageSizeChange}
                />
              </Accordion>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default FinanceOverview;
