import React from "react";

interface AvisShipmentCodeProps {
  shipmentCode: string;
  qrCode: string;
}

const AvisShipmentCode: React.FC<AvisShipmentCodeProps> = ({ shipmentCode, qrCode }) => {
  return (
    <div>
      <h1 className="mb-10 font-weight-bold text-dark">Shipment Code</h1>
      <p className="text-dark-50 mb-10 font-size-lg" style={{ fontSize: "1.2rem" }}>
        Please attach this code to the shipment or print out the QR code and include it with the delivery note. All
        shipments must refer to a shipment code.
      </p>
      <div className="text-dark-50 mb-10 font-size-lg mt-10 text-center text-black" style={{ fontSize: "1.5rem" }}>
        Shipment Code:
        <h1 className="text-black" style={{ fontSize: "4rem" }}>
          <b>{shipmentCode}</b>
        </h1>
      </div>
      <div className="text-center">
        <div className="mb-4">
          <img src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCode)}`} alt="QRCode" height={200} />
        </div>
        <div style={{ fontSize: "1.2rem" }}>
          <u className="pointer" onClick={window.print}>
            Print QR-Code
          </u>
        </div>
      </div>
    </div>
  );
};

export default AvisShipmentCode;
