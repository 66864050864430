import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import EventApi from "@fullcalendar/core/api/EventApi";
import View from "@fullcalendar/core/View";
import { EventSourceInput } from "@fullcalendar/core/structs/event-source";
import commodityUtils from "../../../../utils/commodityUtils";
import baseUtils from "../../../../utils/baseUtils";
import { MinimumCalendarCommodity, MinimumCalendarCommodityOrder } from "../../../../model/commodities.types";
import { ManufacturersDocument } from "../../../../model/manufacturers.types";
import { MinimumCalendarPackaging } from "../../../../model/packagings.types";
import { MinimumCalendarPackagingOrder } from "../../../../model/packagingOrders.types";
import packagingUtils from "../../../../utils/packagingUtils";
import { DeliveryCalendarUserContext } from "../../../../context/deliveryCalendarUserContext";
import OrderOverviewModal from "../shared/OrderOverviewModal";
import CalendarView, { ViewType } from "../shared/CalendarView";
import CalendarViewHeader from "../shared/CalendarViewHeader";

export interface MinimumEventData {
  material: MinimumCalendarCommodity | MinimumCalendarPackaging;
  materialOrder: MinimumCalendarCommodityOrder | MinimumCalendarPackagingOrder;
  destination: ManufacturersDocument;
  type: ViewType.MINIMUM_VIEW;
}

interface MinimumDeliveryCalendarProps extends RouteComponentProps {
  context: React.ContextType<typeof DeliveryCalendarUserContext>;
}

interface MinimumDeliveryCalendarState {
  loading: boolean;
  search: string;
  status: { value: string; label: string };
  type: { value: string; label: string };
  selectedEvent: MinimumEventData | null;
  events: Array<EventSourceInput>;
}

/**
 * This component is a special form of the delivery calendar. Witch containing only the necessary information for special user.
 * See Novalabs request.
 */
class MinimumDeliveryCalendar extends PureComponent<MinimumDeliveryCalendarProps, MinimumDeliveryCalendarState> {
  constructor(props: MinimumDeliveryCalendarProps) {
    super(props);
    this.state = {
      search: "",
      status: { value: "", label: "All" },
      type: { value: "commodities", label: "Commodities" },
      loading: true,
      selectedEvent: null,
      events: []
    };
  }

  componentDidMount() {
    this.setState({ events: this.collectData(), loading: true });
  }

  componentDidUpdate(
    prevProps: Readonly<MinimumDeliveryCalendarProps>,
    prevState: Readonly<MinimumDeliveryCalendarState>,
    snapshot?: any
  ) {
    if (
      !_.isEqual(this.props.context.commodities, prevProps.context.commodities) ||
      !_.isEqual(this.props.context.packagings, prevProps.context.packagings) ||
      !_.isEqual(this.props.context.packagingOrders, prevProps.context.packagingOrders) ||
      this.state.type.value !== prevState.type.value
    ) {
      this.setState({ events: this.collectData() });
    }
  }

  /**
   * Collect events to be displayed in the calendar
   * @returns {Array<EventSourceInput>} list of calendar events populated with additional information and properties
   */
  collectData = () => {
    const { commodities, manufacturer, packagings, packagingOrders } = this.props.context;
    const { type } = this.state;
    const events: Array<EventSourceInput> = [];
    if (!type.value || type.value === "commodities") {
      for (let i = 0; i < commodities.length; i++) {
        const commodity = commodities[i];
        if (
          !commodity.orders ||
          (Array.isArray(commodity.orders) && commodity.orders.length === 0) ||
          !Array.isArray(commodity.orders)
        )
          continue;
        for (let j = 0; j < commodity.orders.length; j++) {
          const commodityOrder = commodity.orders[j];
          const deliveryDate = commodityUtils.resolveDeliveryDate(commodityOrder);

          events.push({
            title:
              commodityUtils.resolveStockUnit(commodityOrder.orderquantity, commodity.type) +
              " of " +
              commodity.title.en,
            start: deliveryDate.toISOString().split("T")[0],
            material: commodity,
            materialOrder: commodityOrder,
            destination: manufacturer,
            className: commodityOrder.delivered
              ? "fc-event-success"
              : deliveryDate < new Date()
              ? "fc-event-warning"
              : "",
            backgroundColor: "rgba(255,196,0,0.1)"
          });
        }
      }
    }
    if (!type.value || type.value === "packaging") {
      for (let i = 0; i < packagingOrders.length; i++) {
        const pOrder = packagingOrders[i];
        const packaging = packagings.find(p => p._id.toString() === pOrder.packaging.toString())!;
        const deliveryDate = pOrder.expectedDelivery;

        events.push({
          title: `${pOrder.orderQuantity} pcs. of ${packagingUtils.getShortPackagingInfo(packaging)}`,
          start: deliveryDate.toISOString().split("T")[0],
          material: packaging,
          materialOrder: pOrder,
          destination: manufacturer,
          className: pOrder.delivered ? "fc-event-success" : deliveryDate < new Date() ? "fc-event-warning" : "",
          backgroundColor: "rgba(255,196,0,0.1)"
        });
      }
    }
    return events;
  };

  /**
   * Get events filtered by manufacturer and search
   * @returns {Array<EventSourceInput>} list of filtered events to display in the calendar
   */
  getFilteredEvents = () => {
    const { events, search, status, type } = this.state;
    let keys: string[];
    if (type.value === "") {
      keys = ["title", "material.packaging_type", "material.title.de", "material.title.en"];
    } else {
      keys =
        type.value === "packaging" ? ["material.packaging_type"] : ["title", "material.title.de", "material.tile.en"];
    }

    let tmpEvents = events.slice();
    if (search.trim()) {
      return baseUtils.doFuseSearch(tmpEvents, search, keys);
    }
    if (status.value !== "") {
      tmpEvents = tmpEvents.filter((event: any) =>
        status.value === "open" ? !event.materialOrder.delivered : event.materialOrder.delivered
      );
    }
    return tmpEvents;
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value.toLowerCase() });

  handleSelectEvent = (arg: { el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: View }) =>
    // event._def.extendedProps contains commodityOrder, person, supplier, order and destination
    this.setState({ selectedEvent: arg.event._def.extendedProps as MinimumEventData });

  handleSelectChange = (name: string, entry: "" | { value: string; label: string }) =>
    // @ts-ignore
    this.setState({ [name]: entry });

  handleHideModal = () => this.setState({ selectedEvent: null });

  render() {
    const { context, history, location, match } = this.props;
    const { selectedEvent, search, status, type } = this.state;
    const events = this.getFilteredEvents();

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <OrderOverviewModal
          calendarType={ViewType.MINIMUM_VIEW}
          event={selectedEvent}
          context={context}
          onClose={this.handleHideModal}
        />
        <CalendarViewHeader
          type={type}
          calendarType={ViewType.MINIMUM_VIEW}
          search={search}
          status={status}
          onSearch={this.handleSearch}
          onSelectChange={this.handleSelectChange}
          history={history}
          location={location}
          match={match}
        />
        <CalendarView events={events} onSelectEvents={this.handleSelectEvent} />
      </div>
    );
  }
}

export default MinimumDeliveryCalendar;
