import { CustomOrder } from "../components/order/CustomTypes";
import { CONTRACT } from "./orderUtils";
import { OrdersDocument } from "../model/orders.types";

/**
 * Get the next call of an contract
 * @param order the contract document
 * @returns {ContractCall | null} next call or null
 */
const getNextCall = (order: OrdersDocument | CustomOrder) => {
  const calls = order.contract ? order.contract?.slice() : [];
  if (calls.length === 0) return null;
  calls.sort((a, b) => {
    if (!a.date || !b.date) return 1;
    return a.date.valueOf() - b.date.valueOf();
  });
  const filtered = calls.filter(c => !c.called && !c.finished);
  return filtered.length > 0 ? filtered[0] : null;
};

/**
 * Checks whether a contract is finished or not
 * @param order an order document
 * @returns {boolean} true if the contract is finished, else false
 */
function isContractFinished(order: CustomOrder | OrdersDocument) {
  if (order.state !== CONTRACT) return false;
  const calls = order.contract;
  if (!calls || calls.length === 0) return false;
  return calls.every(c => !!c.finished || !!c.output);
}

/**
 * Check if order document is actually a contract
 * @param order any order document
 * @returns {boolean} true if order doc is a contract, else false
 */
function isContract(order: CustomOrder | OrdersDocument): boolean {
  return Boolean(order.state === CONTRACT || (order.contract && order.contract.length > 0));
}

export default { isContractFinished, getNextCall, isContract };
