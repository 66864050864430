import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "../../../translations/i18n";
import { useWarehouseContext } from "../../../context/warehouseContext";
import { useDataContext } from "../../../context/dataContext";
import { BaseActionModalProps } from "../../../model/warehouse/common.types";
import { FileType } from "../../../model/configuration/warehouseConfiguration.types";
import { Batch, BatchFile } from "../../../model/warehouse/batch.types";
import baseUtils from "../../../utils/baseUtils";
import fileUtils from "../../../utils/fileUtils";
import { getBatchFileEntry } from "../../../utils/batchUtils";
import { getBatchIdFromSelectedEntries } from "../../../utils/warehouseActionUtils";
import UploadFileDragNDrop from "../common/UploadFileDragNDrop";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import Tooltip from "../../common/Tooltip";
import dbService, { BATCH } from "../../../services/dbService";
import toastUtils from "../../../utils/toastUtils";

interface ReportDamageModalState {
  batchEntry?: Batch;
  damageText: string;
  batchDamageFiles: Array<BatchFile>;
  showUpload: boolean;
}

const getDefaultState = (batchEntry?: Batch): ReportDamageModalState => {
  return {
    batchEntry: batchEntry,
    damageText: "",
    batchDamageFiles: [],
    showUpload: false
  };
};

const ReportDamageModal: React.FC<BaseActionModalProps> = ({ show, onHide }) => {
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { configuration, selectedEntries } = warehouseContext;
  const { batch, updateDocumentInContext } = dataContext;

  const [saving, setSaving] = useState<boolean>(false);
  const [state, setState] = useState<ReportDamageModalState>(getDefaultState());

  useEffect(() => {
    let batchEntry: Batch | undefined = undefined;
    if (show && selectedEntries.length > 0) {
      batchEntry = batch.find(b => b._id.toString() === getBatchIdFromSelectedEntries(selectedEntries));
      setState(getDefaultState(batchEntry));
    }
  }, [show]);

  const errors = useMemo(() => {
    const newErrors = [];
    if (state.damageText === "") newErrors.push(i18n.t("warehouse:reportDamageErrorTextEmpty"));
    return newErrors;
  }, [state.damageText]);

  const handleHide = useCallback(() => {
    if (saving) return;
    onHide();
  }, [saving]);

  const handleChangeDamageText = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const damageText = e.currentTarget.value;
    setState(prevState => {
      return { ...prevState, damageText };
    });
  }, []);

  const handleShowUpload = useCallback(() => {
    setState(prevState => {
      return { ...prevState, showUpload: !prevState.showUpload };
    });
  }, []);

  const handleUploadFile = useCallback(
    (file: File, title: string, type: FileType) => {
      const batchDamageFiles = _.cloneDeep(state.batchDamageFiles);
      const fileType = fileUtils.getFileExtension(file!.name);
      const fileName = baseUtils.encodeString(title) + "." + fileType;
      const path = fileUtils.uploadFile(file, fileName);
      if (path) {
        const batchDamageFile = getBatchFileEntry(path, file, type);
        batchDamageFiles.push(batchDamageFile);
        setState(prevState => {
          return { ...prevState, batchDamageFiles };
        });
      } else {
        toast.error(i18n.t("common:errorFileUpload"));
      }
    },
    [state.batchDamageFiles]
  );

  const handleSave = useCallback(async () => {
    const { batchEntry } = state;
    if (!batchEntry) return;
    setSaving(true);
    try {
      const res = await dbService.callFunction<boolean>(
        "reportDamage",
        [batchEntry._id, state.damageText, state.batchDamageFiles],
        true
      );
      await toastUtils.databaseOperationToast(
        res,
        i18n.t("warehouse:reportDamageSuccess"),
        i18n.t("warehouse:reportDamageFailure"),
        () => {
          updateDocumentInContext(BATCH, batchEntry._id);
          onHide();
        }
      );
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      setSaving(false);
    }
  }, [state.batchEntry, state.batchDamageFiles, state.damageText]);

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>{i18n.t("warehouse:reportDamage")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          <h4 className="mb-5 font-weight-bold text-black">{i18n.t("warehouse:reportDamage")}</h4>
          <div className="form-group fv-plugins-icon-container mb-2">
            <textarea
              className="form-control form-control-solid form-control-lg"
              name="comment"
              placeholder={i18n.t("warehouse:damage")}
              rows={2}
              value={state.damageText}
              onChange={handleChangeDamageText}
            />
            <div className="row justify-content-end mt-1">
              <div className="col text-right">
                <button className="btn btn-text text-muted p-0 mr-1" onClick={handleShowUpload}>
                  {i18n.t("warehouse:uploadFile")}
                </button>
                <Tooltip
                  tooltipText={
                    state.batchDamageFiles.length === 0 ? (
                      `0 ${i18n.t("common:files")}`
                    ) : (
                      <span>
                        {state.batchDamageFiles.map(f => {
                          return <p key={f._id.toString()}>{f.title}</p>;
                        })}
                      </span>
                    )
                  }
                >
                  <img
                    className="align-self-center"
                    src="/media/files/pdf.svg"
                    alt="pdf symbol"
                    style={state.batchDamageFiles.length ? { height: 20 } : { height: 20, opacity: "35%" }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          {configuration && state.showUpload && (
            <div className="row">
              <UploadFileDragNDrop fileTypes={configuration.values.fileTypes} onUploadFile={handleUploadFile} />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className={"btn btn-secondary " + (saving ? "disabled" : "")}
          onClick={saving ? undefined : onHide}
        >
          {i18n.t("common:close")}
        </button>
        <ErrorOverlayButton
          buttonText={i18n.t("common:save")}
          className="btn btn-success"
          errors={errors}
          saving={saving}
          onClick={handleSave}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ReportDamageModal;
