import _ from "lodash";
import React, { PureComponent } from "react";
import { ResponsiveLine } from "@nivo/line";
import { DataContext } from "../../context/dataContext";
import { CommoditiesDocument } from "../../model/commodities.types";
import baseUtils from "../../utils/baseUtils";
import commodityUtils from "../../utils/commodityUtils";

interface CommodityPriceChartProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
}

interface CommodityPriceChartState {
  data: Array<{ id: string; color: string; data: Array<any> }>;
}

class CommodityPriceChart extends PureComponent<CommodityPriceChartProps, CommodityPriceChartState> {
  constructor(props: CommodityPriceChartProps) {
    super(props);
    this.state = { data: [] };
  }

  componentDidMount() {
    this.collectData();
  }

  componentDidUpdate(
    prevProps: Readonly<CommodityPriceChartProps>,
    prevState: Readonly<CommodityPriceChartState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.commodity, this.props.commodity)) this.collectData();
  }

  /**
   * Collect all needed data to populate the graph.
   */
  collectData = () => {
    const { commodity, context } = this.props;
    if (commodity && commodity.suppliers) {
      let graphdata = [];
      // Iterate over all suppliers and their prices
      for (let i = 0; i < commodity.suppliers.length; i++) {
        const sup = commodity.suppliers[i];
        const data = [];
        const supplier = baseUtils.getDocFromCollection(context.suppliers, sup._id);
        for (let j = 0; j < sup.prices.length; j++) {
          // X-Axis is the MOQ, Y-Axis the unit price
          data.push({
            x: +sup.prices[j].moq,
            y: +sup.prices[j].price
          });
        }
        if (data.length > 0)
          graphdata.push({
            id: supplier.name,
            color: "hsl(262, 70%, 50%)",
            data
          });
      }
      this.setState({ data: graphdata });
    }
  };

  /**
   * Get the maximum and the minimum prices to resolve the borders of the graph.
   * @returns { min: number, max: number } Minimum and maximum value
   */
  getMinMax = () => {
    const { data } = this.state;
    let absMin = Number.MAX_SAFE_INTEGER;
    let absMax = 0;

    if (data) {
      for (let i = 0; i < data.length; i++) {
        let min = _.minBy(data[i].data, val => val.y);
        let max = _.maxBy(data[i].data, val => val.y);
        if (min.y < absMin) {
          absMin = min.y;
        }
        if (max.y > absMax) {
          absMax = max.y;
        }
      }
    }
    absMax = absMax * 1.1;
    absMin = absMin * 0.9;
    if (absMin < 0) {
      absMin = 0;
    }
    return { min: absMin, max: absMax };
  };

  render() {
    const { commodity } = this.props;
    const { data } = this.state;
    const { min, max } = this.getMinMax();
    return (
      <div className="kt-portlet kt-portlet--head-noborder">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Price Graph</h3>
          </div>
          <div className="kt-portlet__head-toolbar" />
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit-top">
          <div style={{ display: "block", width: "100%", height: 350 }}>
            <ResponsiveLine
              data={data}
              margin={{ top: 30, right: 50, bottom: 80, left: 50 }}
              xScale={{ type: "linear" }}
              yScale={{
                type: "linear",
                min,
                max,
                stacked: false,
                reverse: false
              }}
              tooltip={input => {
                return (
                  <div
                    style={{
                      padding: 5,
                      backgroundColor: "white",
                      borderRadius: 5
                    }}
                  >
                    <span className="kt-font-bold kt-font-dark">{input.point.serieId}</span>
                    <br />
                    <span className="kt-font-dark">
                      MOQ: {commodityUtils.resolveStockUnit(+input.point.data.x || 0, commodity.type)}
                    </span>
                    <br />
                    <span className="kt-font-dark">{baseUtils.formatEuro(+input.point.data.y || 0)}</span>
                  </div>
                );
              }}
              yFormat=" >-$,.2f"
              xFormat=" >-#,.0f"
              curve="stepAfter"
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendOffset: 36,
                legendPosition: "middle"
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendOffset: -40,
                legendPosition: "middle"
              }}
              pointSize={16}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              areaOpacity={0.05}
              useMesh={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CommodityPriceChart;
