import _ from "lodash";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import accessUtils, { CREATELOCATIONS } from "../../../utils/accessUtils";
import { PackagingsDocument } from "../../../model/packagings.types";
import packagingUtils, {
  T_BAG,
  T_BLISTER,
  T_BOTTLE,
  T_BOX,
  T_LABEL,
  T_LID,
  T_LIQUIDBOTTLE,
  T_MULTILAYERLABEL,
  T_PIPETTE,
  T_SLEEVE,
  T_SPRAYPUMP,
  T_PACKAGINGUNIT,
  T_LIQUIDPACKAGINGUNIT,
  T_CAPSULEUNIT,
  T_STICKER,
  T_SPOON,
  T_GRAMM,
  T_SILICAGELBAG
} from "../../../utils/packagingUtils";
import dbService, { PACKAGINGS } from "../../../services/dbService";
import { StickerForms } from "../PackagingHelper";

interface ClonePackagingModalProps extends RouteComponentProps<{}, {}, {}> {
  packaging: PackagingsDocument;
}

interface ClonePackagingModalState {
  show: boolean;
  saving: boolean;
  articleNumber: string;
  note: string;
}

class DuplicatePackagingModal extends PureComponent<ClonePackagingModalProps, ClonePackagingModalState> {
  constructor(props: ClonePackagingModalProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  handleArticleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ articleNumber: e.target.value });
  };

  handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ note: e.target.value });
  };

  handleSave = async () => {
    this.setState({ saving: true });
    const { packaging } = this.props;
    const { note, articleNumber } = this.state;
    const clonePackaging = _.cloneDeep(packaging);
    clonePackaging._id = new BSON.ObjectId();
    clonePackaging.article_number = articleNumber;
    clonePackaging.note = note;
    clonePackaging.suppliers = [];
    clonePackaging.specifications = [];
    clonePackaging.images = [];
    clonePackaging.timeline = [];

    try {
      const result = await dbService.insertDocument(PACKAGINGS, clonePackaging);
      if (result && result.insertedId) {
        toast.success("Packaging created successfully");
        this.setState({ show: false });
        this.props.history.push("/packaging/" + result.insertedId.toString());
      } else toast.error("Packaging could not be created");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = () => {
    return {
      show: false,
      saving: false,
      articleNumber: "",
      note: ""
    } as ClonePackagingModalState;
  };

  renderCorrespondingCloningContent = () => {
    const { packaging } = this.props;
    let correspondingContent: Array<PropertyRow> = [];

    switch (packaging.packaging_type) {
      case T_BOTTLE:
      case T_LIQUIDBOTTLE:
        if (packaging.packaging_type === T_BOTTLE) {
          correspondingContent.push({
            label: "Shape",
            content: packaging.packaging_shape
          });
        }
        correspondingContent.push({
          label: "Material",
          content: packaging.packaging_material
        });
        correspondingContent.push({
          label: "Color",
          content: packaging.packaging_color
        });
        correspondingContent.push({
          label: "Volume",
          content: packaging.packaging_volume,
          unit: T_LIQUIDPACKAGINGUNIT
        });
        correspondingContent.push({
          label: "Height",
          content: packaging.packaging_height,
          unit: T_PACKAGINGUNIT
        });
        correspondingContent.push({
          label: "Width",
          content: packaging.packaging_width,
          unit: T_PACKAGINGUNIT
        });
        correspondingContent.push({
          label: "Label height",
          content: packaging.packaging_label_height,
          unit: T_PACKAGINGUNIT
        });
        correspondingContent.push({
          label: "Neck",
          content: packaging.packaging_neck
        });
        break;
      case T_BAG:
        correspondingContent.push({
          label: "Shape",
          content: packaging.bag_shape
        });
        correspondingContent.push({
          label: "Material",
          content: packaging.bag_material
        });
        correspondingContent.push({
          label: "Zipper",
          content: packaging.bag_zipper
        });
        correspondingContent.push({
          label: "Volume",
          content: packaging.packaging_volume,
          unit: T_LIQUIDPACKAGINGUNIT
        });
        correspondingContent.push({
          label: "Height",
          content: packaging.bag_height,
          unit: T_PACKAGINGUNIT
        });
        correspondingContent.push({
          label: "Width",
          content: packaging.bag_width,
          unit: T_PACKAGINGUNIT
        });
        correspondingContent.push({
          label: "Color",
          content: packaging.bag_color
        });
        break;
      case T_BLISTER:
        correspondingContent.push({
          label: "Capsules",
          content: packaging.blister_capsules,
          unit: T_CAPSULEUNIT
        });
        correspondingContent.push({
          label: "Depth",
          content: packaging.blister_depth,
          unit: T_PACKAGINGUNIT
        });
        correspondingContent.push({
          label: "Height",
          content: packaging.blister_height,
          unit: T_PACKAGINGUNIT
        });
        correspondingContent.push({
          label: "Width",
          content: packaging.blister_width,
          unit: T_PACKAGINGUNIT
        });
        break;
      case T_BOX:
        correspondingContent.push({
          label: "Quality",
          content: packaging.box_quality
        });
        correspondingContent.push({
          label: "Depth",
          content: packaging.box_depth,
          unit: T_PACKAGINGUNIT
        });
        correspondingContent.push({
          label: "Height",
          content: packaging.box_height,
          unit: T_PACKAGINGUNIT
        });
        correspondingContent.push({
          label: "Width",
          content: packaging.box_width,
          unit: T_PACKAGINGUNIT
        });
        break;
      case T_LID:
        correspondingContent.push({
          label: "Shape",
          content: packaging.lid_shape
        });
        correspondingContent.push({
          label: "Material",
          content: packaging.lid_material
        });
        correspondingContent.push({
          label: "Size",
          content: packaging.lid_size
        });
        correspondingContent.push({
          label: "Color",
          content: packaging.lid_color
        });
        break;
      case T_PIPETTE:
      case T_SPRAYPUMP:
        correspondingContent.push({
          label: "Color",
          content: packaging.packaging_color
        });
        if (packaging.packaging_type === T_SPRAYPUMP) {
          correspondingContent.push({
            label: "Norm",
            content: "DIN " + packaging.packaging_norm
          });
        }
        correspondingContent.push({
          label: "Height",
          content: packaging.packaging_height,
          unit: T_PACKAGINGUNIT
        });
        correspondingContent.push({
          label: "Neck",
          content: packaging.packaging_neck
        });
        break;
      case T_SLEEVE:
        correspondingContent.push({
          label: "Print",
          content: packaging.sleeve_print ? "Printed" : "No print"
        });
        correspondingContent.push({
          label: "Size",
          content: packaging.sleeve_size,
          unit: T_PACKAGINGUNIT
        });
        break;
      case T_LABEL:
      case T_MULTILAYERLABEL:
        correspondingContent.push({
          label: "Quality",
          content: packaging.label_quality
        });
        correspondingContent.push({
          label: "Width",
          content: packaging.label_width,
          unit: T_PACKAGINGUNIT
        });
        correspondingContent.push({
          label: "Height",
          content: packaging.label_height,
          unit: T_PACKAGINGUNIT
        });
        break;
      case T_STICKER:
        correspondingContent = [
          {
            label: "Quality",
            content: packaging.quality
          },
          {
            label: "Form",
            content: packaging.form
          }
        ];
        if (packaging.form === StickerForms.ROUND)
          correspondingContent.push({
            label: "Diameter",
            content: packaging.diameter,
            unit: T_PACKAGINGUNIT
          });
        else
          correspondingContent = correspondingContent.concat([
            {
              label: "Width",
              content: packaging.packaging_width,
              unit: T_PACKAGINGUNIT
            },
            {
              label: "Height",
              content: packaging.packaging_height,
              unit: T_PACKAGINGUNIT
            }
          ]);
        break;
      case T_SPOON:
        correspondingContent = [
          {
            label: "Capacity",
            content: packaging.capacity,
            unit: T_GRAMM
          },
          {
            label: "Color",
            content: packaging.packaging_color
          }
        ];
        break;
      case T_SILICAGELBAG:
        correspondingContent = [
          {
            label: "Weight",
            content: packaging.weight,
            unit: T_GRAMM
          }
        ];
        break;
    }

    return <ClonePackagingPropertyRows rowContent={correspondingContent} />;
  };

  render() {
    const { packaging } = this.props;
    const { saving, show, articleNumber, note } = this.state;
    const canDuplicate = accessUtils.canCreateData(CREATELOCATIONS.PACKAGING);
    return (
      <>
        <button
          type="button"
          className={"btn btn-secondary btn-sm btn-upper" + (saving || !canDuplicate ? " disabled" : "")}
          onClick={canDuplicate ? this.handleShow : undefined}
          disabled={saving || !canDuplicate}
        >
          DUPLICATE
        </button>
        <Modal show={show} centered onHide={this.handleHide} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="kt-widget__top">
                <div className="kt-widget__content">
                  <div className="kt-widget__head">
                    <div className="kt-widget__user">
                      <span className={"kt-widget__username" + (packaging.disabled ? " text-danger" : "")}>
                        {packaging.disabled && "[DISABLED]"} Duplicate {packagingUtils.getShortPackagingInfo(packaging)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-warning" role="alert">
              <div className="alert-icon">
                <i className="flaticon-warning" />
              </div>
              <div className="alert-text">
                Do you really want to clone this packaging: {packagingUtils.resolvePackagingProperties(packaging)}?
                <br />
                Some properties are packaging specific and will not be copied. The duplicated packaging will contain the
                following information.
              </div>
            </div>
            <div className="text-dark">
              <div className="form-group row">
                <label className="col-3 my-4 col-form-label">Article number</label>
                <div className="col-9 my-4">
                  <input
                    className={"form-control"}
                    type="text"
                    placeholder="Article number"
                    name="articleNumber"
                    onChange={this.handleArticleNumberChange}
                    value={articleNumber}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 my-4 col-form-label">Note</label>
                <div className="col-9 my-4">
                  <textarea
                    className="form-control"
                    placeholder={"Note"}
                    name="note"
                    rows={3}
                    onChange={this.handleNoteChange}
                    value={note}
                  />
                </div>
              </div>
              {this.renderCorrespondingCloningContent()}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary mr-2" onClick={this.handleHide}>
              Close
            </button>
            <button
              className={"btn btn-success" + (saving ? " disabled" : "")}
              onClick={this.handleSave}
              disabled={saving}
            >
              {saving && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Duplicate
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

interface ClonePackagingPropertyRowProps {
  rowContent: Array<PropertyRow>;
}

interface PropertyRow {
  label: string;
  content?: string;
  unit?: string;
}

const ClonePackagingPropertyRows: React.FunctionComponent<ClonePackagingPropertyRowProps> = ({ rowContent }) => {
  return (
    <>
      {rowContent.map(row => {
        return (
          <div className="row mt-2" key={row.label + "-" + row.content}>
            <div className="col-3 my-4">{row.label}</div>
            <div className="col-9 my-4">
              {packagingUtils.getPackagingDescription(row.content)} {row.unit}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default withRouter(DuplicatePackagingModal);
