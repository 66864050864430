import React, { useCallback, useContext, useMemo, useState } from "react";
import i18n from "../../../../translations/i18n";
import { resolveTranslation } from "../../../../utils/translationUtils";
import {
  AvisUnit,
  DeliveryAnnouncement,
  Notification,
  NotificationState
} from "../../../../model/warehouse/deliveryAnnouncement.types";
import baseUtils, { formatNumValue } from "../../../../utils/baseUtils";
import { DataContext } from "../../../../context/dataContext";
import { OrdersDocument } from "../../../../model/orders.types";
import { useWarehouseContext, useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { SelectedDeliveryAnnouncementEntryType } from "../../../../utils/warehouseUtils";

interface DefaultDeliveryAnnouncementRowProps {
  deliveryAnnouncement: DeliveryAnnouncement;
}

const DefaultDeliveryAnnouncementRow: React.FC<DefaultDeliveryAnnouncementRowProps> = ({ deliveryAnnouncement }) => {
  const { _id, sender, notification, shipmentCode, created } = deliveryAnnouncement;

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const handleToggleDetails = useCallback(() => setShowDetails(prevState => !prevState), []);

  const headerDefinition = useMemo(
    () => [
      { title: "#", size: 1 },
      { title: i18n.t("common:article"), size: 40 },
      { title: i18n.t("warehouse:amount"), size: 10 },
      { title: i18n.t("warehouse:reference"), size: 40 },
      { title: i18n.t("warehouse:status"), size: 9 }
    ],
    []
  );

  const totalAmounts = useMemo(() => {
    let amountList = [
      { unit: AvisUnit.KG, amount: 0 },
      { unit: AvisUnit.UNITS, amount: 0 },
      { unit: AvisUnit.PCS, amount: 0 }
    ];
    for (let i = 0; i < notification.length; i++) {
      const n = notification[i];
      if (n.totalAmount.unit === AvisUnit.KG) amountList[0].amount += n.totalAmount.value;
      else if (n.totalAmount.unit === AvisUnit.UNITS) amountList[1].amount += n.totalAmount.value;
      else if (n.totalAmount.unit === AvisUnit.PCS) amountList[2].amount += n.totalAmount.value;
    }
    amountList = amountList.filter(a => a.amount > 0);
    return amountList.map(aL => `${aL.amount}${aL.unit}`).join(", ");
  }, [notification]);

  const id = useMemo(() => _id.toString(), [_id]);

  return (
    <>
      <tr className="kt-datatable__row d-table-row nopadding table-hover" onClick={handleToggleDetails}>
        <td className="kt-datatable__cell d-table-cell">
          <span className="mt-0 text-black">
            <b>{shipmentCode}</b>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name text-dark " style={{ lineHeight: "1.2rem" }}>
                <span className="kt-user-card-v2__email mt-0 text-black">
                  {`${notification.length} ${i18n.t("common:article")}`}
                </span>
              </span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name text-black kt-font-bold">{totalAmounts}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name text-dark " style={{ lineHeight: "1.2rem" }}>
                <span className="kt-user-card-v2__email mt-0 text-black">{sender.name}</span>
              </span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name text-dark " style={{ lineHeight: "1.2rem" }}>
                <span className="kt-user-card-v2__email mt-0 text-black">{baseUtils.formatDate(created)}</span>
              </span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell text-right">
          <div className="label label-light label-inline kt-font-bold text-black my-auto" style={{ width: 60 }}>
            RE
          </div>
        </td>
      </tr>
      {showDetails && (
        <tr className="kt-datatable__row d-table-row">
          <td colSpan={10} className="px-0">
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive p-2 bg-light mb-0">
              <table className="kt-datatable__table d-table p-5 bg-light">
                <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
                  <tr className="kt-datatable__row d-table-row">
                    {headerDefinition.map((def, idx) => (
                      <th key={idx} className="kt-datatable__cell d-table-cell" style={{ width: `${def.size}%` }}>
                        <span>{def.title}</span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                  {notification.map(n => (
                    <DefaultDeliveryAnnouncementDetailRow
                      key={n._id.toString()}
                      deliveryAnnouncementId={id}
                      notification={n}
                      numberOfSiblingLocations={notification.length}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

interface DefaultDeliveryAnnouncementDetailRowProps {
  deliveryAnnouncementId: string;
  notification: Notification;
  numberOfSiblingLocations: number;
}

const DefaultDeliveryAnnouncementDetailRow: React.FC<DefaultDeliveryAnnouncementDetailRowProps> = ({
  deliveryAnnouncementId,
  notification,
  numberOfSiblingLocations
}) => {
  const context = useContext(DataContext);

  const warehouseContext = useWarehouseContext();
  const dispatch = useWarehouseDispatch();
  const { selectedEntries } = warehouseContext;

  const { _id, order, content, totalAmount } = notification;

  const handleCheckbox = useCallback(
    () =>
      dispatch({
        type: WarehouseActionType.SELECT_DELIVERY_ANNOUNCEMENT_ENTRY,
        payload: {
          type: SelectedDeliveryAnnouncementEntryType.NOTIFICATION,
          deliveryAnnouncementId: deliveryAnnouncementId,
          numberOfChildren: numberOfSiblingLocations,
          childId: _id.toString()
        }
      }),
    [_id, deliveryAnnouncementId, numberOfSiblingLocations]
  );

  const checked = useMemo(
    () =>
      selectedEntries.some(
        entry =>
          entry.type === SelectedDeliveryAnnouncementEntryType.NOTIFICATION &&
          entry.deliveryAnnouncementId === deliveryAnnouncementId &&
          entry.childId === _id.toString()
      ),
    [selectedEntries]
  );

  const orderDoc: OrdersDocument | undefined = useMemo(
    () => (order ? baseUtils.getDocFromCollection(context.orders, order.reference) : undefined),
    [context.orders.length, order] // Context orders only length dependency since AT should never change and like that the loading is covered
  );

  return (
    <tr className="kt-datatable__row d-table-row nopadding">
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <input type="checkbox" className="ml-0 kt-checkbox--solid" checked={checked} onChange={handleCheckbox} />
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-dark " style={{ lineHeight: "1.2rem" }}>
              <span className="d-inline-block text-ellipsis align-bottom" style={{ maxWidth: "250px" }}>
                {resolveTranslation(content.details.title)}
              </span>
              <small className={"kt-user-card-v2__email ml-2 text-ellipsis align-bottom"} style={{ maxWidth: "250px" }}>
                {resolveTranslation(content.details.subtitle)}
              </small>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black kt-font-bold">{formatNumValue(totalAmount)}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black kt-font-bold">
              {orderDoc ? `AT-${orderDoc.identifier}` : "-"}
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span
          className={
            "kt-font-bold " + (notification.state === NotificationState.CREATED ? "text-warning" : "text-success")
          }
          style={{ paddingLeft: "1px" }}
        >
          <span>
            {notification.state === NotificationState.CREATED
              ? i18n.t("warehouse:avisTab")
              : i18n.t("warehouse:inBookedIn")}
          </span>
        </span>
      </td>
    </tr>
  );
};

export default DefaultDeliveryAnnouncementRow;
