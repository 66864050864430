import _ from "lodash";
import React, { useMemo } from "react";
import i18n from "../../../../translations/i18n";
import { OrdersDocument } from "../../../../model/orders.types";
import { CommodityReservationInformation, CommodityInformation } from "../../../../model/warehouse/customTypes.types";
import { BaseTableHeaderDefinition } from "../../../listings/BaseListing";
import calculationUtils from "../../../../utils/calculationUtils";
import { DEFAULTWEIGHTUNIT } from "../../../../utils/warehouseUtils";

interface ExtendedCommodityInformation extends CommodityInformation {
  newReservedAmount: number;
}

interface OrderReservationOverviewProps {
  selectedOrder: OrdersDocument;
  commodityInformation: Map<string, CommodityInformation>;
  orderReservations: Array<CommodityReservationInformation>;
}

const OrderReservationOverview: React.FC<OrderReservationOverviewProps> = ({
  selectedOrder,
  commodityInformation,
  orderReservations
}) => {
  const tableHeader: Array<BaseTableHeaderDefinition> = [
    { title: i18n.t("warehouse:commodity"), size: 35 },
    { title: i18n.t("warehouse:neededAmount"), size: 20 },
    { title: i18n.t("warehouse:reserved"), size: 25 },
    { title: i18n.t("warehouse:pending"), size: 20 }
  ];

  const usedCommodities = useMemo(
    () =>
      _.orderBy(
        Array.from(commodityInformation.values()).map(c => {
          const newReservations =
            orderReservations.length > 0
              ? orderReservations
                  .filter(r => r.commodityId === c.commodityId)
                  .reduce(
                    (commodityReservedAmount, reservation) => commodityReservedAmount + reservation.amount.value,
                    0
                  )
              : 0;
          const newReservedAmount = newReservations + c.reservedAmount.value;
          return { ...c, newReservedAmount };
        }) as Array<ExtendedCommodityInformation>,
        c => Boolean(c.newReservedAmount),
        "desc"
      ),
    [commodityInformation, orderReservations]
  );

  return (
    <>
      <h4 className="text-black font-weight-bold mb-3 d-block">{i18n.t("common:summary")}</h4>
      <div className="p-2 py-4 mb-4 bg-light">
        <div className="row mx-3">
          <div className="col-12">
            <h5 className="font-weight-bold text-black">{`AT-${selectedOrder.identifier} ${selectedOrder.title}`}</h5>
            <div className="text-black mb-3">{selectedOrder.subtitle}</div>
            <div className="kt-portlet__body px-0 pt-0">
              <div
                className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive overflow-auto"
                style={{ maxHeight: "40vh" }}
              >
                <table className="kt-datatable__table d-table bg-light">
                  <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
                    <tr className="kt-datatable__row d-table-row">
                      {tableHeader.map((def, index) => (
                        <th
                          key={(typeof def.title === "string" ? def.title : index.toString()) + (def.size || "")}
                          className={"kt-datatable__cell d-table-cell " + (def.additionalColumnClasses || "")}
                          style={{ width: `${def.size}%` }}
                        >
                          <span className={def.spanClasses || "text-black"}>{def.title}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                    {usedCommodities.map(cI => (
                      <ReservationOverviewRow key={cI.commodityId} commodityInformation={cI} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface ReservationOverviewRowProps {
  commodityInformation: ExtendedCommodityInformation;
}

const ReservationOverviewRow: React.FC<ReservationOverviewRowProps> = ({ commodityInformation }) => {
  const updatedReservedAmount = commodityInformation.newReservedAmount;
  const newPendingAmount = useMemo(() => {
    return updatedReservedAmount >= commodityInformation.neededAmount.value
      ? 0
      : commodityInformation.neededAmount.value - updatedReservedAmount;
  }, [commodityInformation, updatedReservedAmount]);

  return (
    <tr className="kt-datatable__row d-table-row nopadding">
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-user-card-v2__email mt-0 text-black kt-font-bold">
          {commodityInformation.commodityName}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-user-card-v2__email mt-0 text-black">
          {calculationUtils.formatAmount(commodityInformation.neededAmount.value, 2)}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-user-card-v2__email mt-0 text-black">
          {commodityInformation.reservedAmount.value === 0
            ? `0${DEFAULTWEIGHTUNIT}`
            : calculationUtils.formatAmount(commodityInformation.reservedAmount.value, 2)}{" "}
          →{" "}
          <span className={updatedReservedAmount > 0 ? "text-success kt-font-bold" : ""}>
            {updatedReservedAmount === 0
              ? `0${DEFAULTWEIGHTUNIT}`
              : calculationUtils.formatAmount(updatedReservedAmount, 2)}
          </span>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-user-card-v2__email mt-0 text-black">
          {newPendingAmount === 0 ? `0${DEFAULTWEIGHTUNIT}` : calculationUtils.formatAmount(newPendingAmount, 2)}
        </span>
      </td>
    </tr>
  );
};

export default OrderReservationOverview;
