import React, { memo, useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { BSON } from "realm-web";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Modal, Table } from "react-bootstrap";
import { CustomOrder } from "../../CustomTypes";
import Pagination, { paginate } from "../../../common/Pagination";
import { PackagingPrice, PackagingsDocument } from "../../../../model/packagings.types";
import packagingUtils from "../../../../utils/packagingUtils";
import { SuppliersDocument } from "../../../../model/suppliers.types";
import baseUtils from "../../../../utils/baseUtils";
import { DataContext } from "../../../../context/dataContext";
import orderCalculationUtils from "../../../../utils/orderCalculationUtils";
import calculationUtils from "../../../../utils/calculationUtils";
import { AdditionalProductionStep, CalculationType, PackagingCalculation } from "../../../configurator/CustomTypes";
import { calculationInfo, pricing } from "../../../../model/orders.types";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import ConfiguratorHelper from "../../../configurator/ConfiguratorHelper";
import orderUtils, { ORDERORDERCOMMODITIES, PRODUCTIONQUEUE, WAITING } from "../../../../utils/orderUtils";
import { PackagingStockDocument } from "../../../../model/packagingStock.types";
import dbService, { Action, ORDERS, UpdateAction } from "../../../../services/dbService";
import toastUtils from "../../../../utils/toastUtils";
import { T_CALCULATIONCHANGED } from "../../../../utils/timelineUtils";
import userService from "../../../../services/userService";
import { ALLTORSO, PackagingTypes } from "../../../configurator/configuratorConstants";
import { BLISTERING, BOTTLING } from "../../../configurator/calculationDetails/calculationHelper";

enum ModalSteps {
  PACKAGING_SELECTION,
  SELECTION_OVERVIEW,
  CALCULATION_OVERVIEW
}

interface PackagingToAdd {
  _id: BSON.ObjectId;
  calculationAfter: CalculationType;
  calculationBefore: CalculationType;
  relatedCalculation: PackagingCalculation;
  relatedPackaging: PackagingsDocument;
}

interface SupplierOption {
  type: "supplier";
  supplierOption: { _id: BSON.ObjectId; price: PackagingPrice };
}

interface OwnStockOption {
  type: "ownstock";
  ownStockOption: PackagingPrice;
}

interface CustomerOption {
  type: "customer";
  customerOption: PackagingPrice;
}

type PriceOption = SupplierOption | OwnStockOption | CustomerOption;

interface PackagingWithBestPrices {
  _id: BSON.ObjectId;
  availableStock: number;
  bestPrice?: PriceOption;
  relatedPackaging: PackagingsDocument;
  relatedStock: Array<PackagingStockDocument>;
  selectedPriceOption?: PriceOption;
}

interface AddAdditionalPackagingModalProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
}

interface AddAdditionalPackagingModalState {
  amountPerUnit: number;
  currentPage: number;
  errors: Array<string>;
  filter: { value: string; label: string };
  invalidOwnStockOrderUnits: boolean;
  invalidOwnStockUnitAmount: boolean;
  packagingToAdd: PackagingToAdd | undefined;
  pageSize: number;
  step: ModalSteps;
  query: string;
  selectablePackaging: Array<PackagingWithBestPrices>;
  selectedAdditionalPackaging: PackagingWithBestPrices;
  selectedSupplier: string;
  show: boolean;
  submitting: boolean;
  units: number;
  useOwnStock: boolean;
}

const AddAdditionalPackagingModal: React.FunctionComponent<AddAdditionalPackagingModalProps> = ({ order, context }) => {
  const getDefaultState = () => {
    return {
      amountPerUnit: 1,
      currentPage: 1,
      errors: [],
      filter: { value: "", label: "All Packaging" },
      invalidOwnStockOrderUnits: false,
      invalidOwnStockUnitAmount: false,
      packagingToAdd: undefined,
      pageSize: 10,
      step: ModalSteps.PACKAGING_SELECTION,
      query: "",
      selectablePackaging: [],
      selectedAdditionalPackaging: {} as PackagingWithBestPrices,
      selectedSupplier: "",
      show: false,
      submitting: false,
      units: order.calculations[0].units,
      useOwnStock: false
    } as AddAdditionalPackagingModalState;
  };

  const [modalState, setModalState] = useState<AddAdditionalPackagingModalState>(getDefaultState());

  // state destruction
  const {
    amountPerUnit,
    currentPage,
    errors,
    filter,
    invalidOwnStockOrderUnits,
    invalidOwnStockUnitAmount,
    packagingToAdd,
    pageSize,
    step,
    query,
    selectablePackaging,
    selectedAdditionalPackaging,
    selectedSupplier,
    show,
    submitting,
    units,
    useOwnStock
  } = modalState;
  const { packagings, packagingStock, packagingOrders, orders, suppliers } = context;

  // handling of possible packaging selection
  useEffect(() => {
    if (show) {
      // Only create selection when visible
      createPackagingSelection();
    }
  }, [show, order, packagingStock, packagingOrders, orders]);

  // error handling
  useEffect(() => {
    validateUnitAndAmount();
  }, [amountPerUnit, units, selectedSupplier]);

  const handleHide = useCallback(() => {
    setModalState(prevState => {
      return {
        ...prevState,
        show: false
      };
    });
  }, []);

  const handleShow = useCallback(() => {
    setModalState(prevState => {
      return {
        ...prevState,
        show: true
      };
    });
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setModalState(prevState => {
      return {
        ...prevState,
        currentPage: page
      };
    });
  }, []);

  const handlePageSizeChange = useCallback((pageSize: number) => {
    setModalState(prevState => {
      return {
        ...prevState,
        currentPage: 1,
        pageSize: pageSize
      };
    });
  }, []);

  const handleChangeQuery = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setModalState(prevState => {
      return {
        ...prevState,
        currentPage: 1,
        query: query
      };
    });
  }, []);

  const handleFilterChange = useCallback((entry: { value: string; label: string }) => {
    const filterOption = entry ? entry : { value: "", label: "All Packaging" };
    setModalState(prevState => {
      return {
        ...prevState,
        currentPage: 1,
        filter: filterOption
      };
    });
  }, []);

  const handleGoBack = useCallback(() => {
    let nextStep: ModalSteps;
    if (step === ModalSteps.CALCULATION_OVERVIEW) nextStep = ModalSteps.SELECTION_OVERVIEW;
    else nextStep = ModalSteps.PACKAGING_SELECTION;

    setModalState(prevState => {
      return {
        ...prevState,
        step: nextStep
      };
    });
  }, [step]);

  const handleClickSelect = useCallback((relatedPackaging: PackagingWithBestPrices) => {
    const packaging = relatedPackaging;
    if (!packaging.bestPrice || !packaging) return;
    const useOwnStock = packaging.bestPrice.type === "ownstock";
    setModalState(prevState => {
      return {
        ...prevState,
        selectedAdditionalPackaging: packaging,
        selectedSupplier: useOwnStock
          ? "ownstock"
          : (packaging.bestPrice as SupplierOption).supplierOption._id.toString(),
        step: ModalSteps.SELECTION_OVERVIEW,
        useOwnStock: useOwnStock
      };
    });
  }, []);

  const handleChangeNumericAmount = useCallback(
    (type: "amountPerUnit" | "unit", e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      value = value.replaceAll(/^0+/g, "0");
      if (!value.includes(".")) value = Number(value).toString();
      // error prevention, avoid negative or unlogical values
      if (+value >= 1) {
        if (type === "amountPerUnit") {
          const newUnitsValue = order.calculations[0].units * +value;
          setModalState(prevState => {
            return {
              ...prevState,
              amountPerUnit: +value,
              units: newUnitsValue
            };
          });
        } else {
          setModalState(prevState => {
            return {
              ...prevState,
              units: +value
            };
          });
        }
      }
    },
    []
  );

  const handleChangeSelectedSupplier = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newExtendedPackaging = _.cloneDeep(selectedAdditionalPackaging);
      const supplier = e.target.value;
      const useOwnStock = supplier === "ownstock";
      let packagingPrice: PriceOption;

      if (supplier === "ownstock") {
        packagingPrice = {
          type: "ownstock",
          ownStockOption: calculationUtils.getPackagingStockPriceForManufacturer(
            newExtendedPackaging.relatedStock,
            order.settings.filler || order.settings.manufacturer
          )
        };
      } else if (supplier === "customer") {
        packagingPrice = {
          type: "customer",
          customerOption: {
            _id: new BSON.ObjectId(),
            moq: 0,
            price: 0,
            deliverytime: 7,
            delivery: "",
            note: "",
            date: null
          }
        };
      } else {
        const packagingPriceMOQ = orderCalculationUtils.getPackagingPriceForMOQ(
          newExtendedPackaging.relatedPackaging,
          order.calculations[0].units,
          supplier,
          "",
          0,
          true
        );
        if (!packagingPriceMOQ) return;

        // receiving moq price from supplier
        packagingPrice = {
          type: "supplier",
          supplierOption: packagingPriceMOQ
        };
      }

      // setting the selected price object
      newExtendedPackaging.selectedPriceOption = packagingPrice;
      if (!newExtendedPackaging.selectedPriceOption) return;

      setModalState(prevState => {
        return {
          ...prevState,
          selectedAdditionalPackaging: newExtendedPackaging,
          selectedSupplier: supplier,
          useOwnStock: useOwnStock
        };
      });
    },
    [order, selectedAdditionalPackaging]
  );

  const handlePrepareCalculation = useCallback(() => {
    const orderObject = _.cloneDeep(order);

    // rebuild calculation objects
    const relatedCalculations = ConfiguratorHelper.getCalculations(orderObject);

    // receive the related calculation object
    const calculationBefore = _.cloneDeep(relatedCalculations[0]);
    if (!selectedAdditionalPackaging.selectedPriceOption) return;
    let priceToUse: PackagingPrice;
    switch (selectedAdditionalPackaging.selectedPriceOption.type) {
      case "supplier":
        priceToUse = _.cloneDeep(
          (selectedAdditionalPackaging.selectedPriceOption as SupplierOption).supplierOption.price
        );
        break;
      case "ownstock":
        priceToUse = _.cloneDeep((selectedAdditionalPackaging.selectedPriceOption as OwnStockOption).ownStockOption);
        break;
      case "customer":
        priceToUse = _.cloneDeep((selectedAdditionalPackaging.selectedPriceOption as CustomerOption).customerOption);
        break;
    }

    let priceType:
      | { type: "ownstock" }
      | { type: "customer"; name: string }
      | { type: "supplier"; id: BSON.ObjectId; name: string };
    if (selectedAdditionalPackaging.selectedPriceOption.type === "supplier") {
      const relatedSupplierId = selectedAdditionalPackaging.selectedPriceOption.supplierOption._id;
      const relatedSupplier: SuppliersDocument | undefined = baseUtils.getDocFromCollection(
        suppliers,
        relatedSupplierId
      );
      if (!relatedSupplier) return;
      priceType = { type: "supplier", id: relatedSupplierId, name: relatedSupplier.name };
    } else if (selectedAdditionalPackaging.selectedPriceOption.type === "customer") {
      priceType = { type: "customer", name: orderObject.createdFor.name };
    } else {
      priceType = { type: "ownstock" };
    }

    // build update price object
    const packagingCalculationObject = calculationUtils.getPackagingByPriceObject(
      priceToUse,
      priceType,
      calculationBefore.id,
      units,
      amountPerUnit
    );

    // checking for consideration of additional production steps
    let additionalProductionStep: AdditionalProductionStep | undefined = undefined;
    if (
      [PackagingTypes.BOTTLE, PackagingTypes.LIQUIDBOTTLE, PackagingTypes.BLISTER].includes(
        selectedAdditionalPackaging.relatedPackaging.packaging_type
      )
    ) {
      const type = calculationUtils.getTabForType(orderObject.settings.type);
      additionalProductionStep = {
        order: orderObject,
        context: context,
        manufacturer: orderObject.settings.filler || orderObject.settings.manufacturer,
        productionType: [PackagingTypes.BOTTLE, PackagingTypes.LIQUIDBOTTLE].includes(
          selectedAdditionalPackaging.relatedPackaging.packaging_type
        )
          ? BOTTLING
          : BLISTERING,
        productType: type,
        units: units,
        amountPerUnit: amountPerUnit
      };
    }

    // update calculation
    const calculationAfter = calculationUtils.updateCalculationWithPackagingPrice(
      calculationBefore,
      packagingCalculationObject,
      additionalProductionStep
    );

    // build result object for further handling
    const packagingToAdd = {
      _id: new BSON.ObjectId(),
      relatedPackaging: selectedAdditionalPackaging.relatedPackaging,
      calculationBefore: calculationBefore,
      calculationAfter: calculationAfter,
      relatedCalculation: packagingCalculationObject
    } as PackagingToAdd;

    setModalState(prevState => {
      return {
        ...prevState,
        packagingToAdd: packagingToAdd,
        step: ModalSteps.CALCULATION_OVERVIEW
      };
    });
  }, [order, context, units, amountPerUnit, selectedAdditionalPackaging]);

  const handleCalculationConfirmation = useCallback(async () => {
    setModalState(prevState => {
      return {
        ...prevState,
        submitting: true
      };
    });

    if (!packagingToAdd) return;
    // build pricing object to add
    const newPricingObject: pricing = {
      _id: packagingToAdd.relatedPackaging._id,
      amount: amountPerUnit,
      estimatedprice: packagingToAdd.relatedCalculation.price,
      supplier: packagingToAdd.relatedCalculation.supplier._id,
      orderquantity: amountPerUnit * units,
      price: packagingToAdd.relatedCalculation.price,
      totalprice: packagingToAdd.relatedCalculation.totalPrice,
      requested: null,
      updated: null,
      ordered: useOwnStock ? new Date() : null,
      userOrdered: useOwnStock ? userService.getUserId() : null,
      userDelivered: useOwnStock ? userService.getUserId() : null,
      delivered: useOwnStock ? new Date() : null,
      eta: useOwnStock ? new Date() : packagingToAdd.relatedCalculation.eta,
      auto: true,
      deliverytime: packagingToAdd.relatedCalculation.deliveryTime,
      buffer: packagingToAdd.relatedCalculation.buffer,
      delivery: packagingToAdd.relatedCalculation.delivery
    };

    const timeline = {
      id: new BSON.ObjectId(),
      type: T_CALCULATIONCHANGED,
      date: new Date(),
      person: userService.getUserId(),
      prices: {
        oldPrice: packagingToAdd.calculationBefore.unitPriceNaked,
        newPrice: packagingToAdd.calculationAfter.unitPriceNaked
      }
    };

    // saving extended calculation info model
    const standardCalculationInfo = { ...order.calculations[0].info.standardCalculation };
    if (packagingToAdd.calculationAfter.additionalGeneralPrices) {
      standardCalculationInfo[packagingToAdd.calculationAfter.additionalGeneralPrices.type] = {
        price: packagingToAdd.calculationAfter.additionalGeneralPrices.price,
        unitPrice: packagingToAdd.calculationAfter.additionalGeneralPrices.unitPrice
      };
    }

    const newInfo: calculationInfo = {
      ...order.calculations[0].info,
      unitprice: packagingToAdd.calculationAfter.unitPrice,
      unitpricenaked: packagingToAdd.calculationAfter.unitPriceNaked,
      unitmargin: packagingToAdd.calculationAfter.unitMargin,
      totalprice: packagingToAdd.calculationAfter.totalPrice,
      percentmargin: packagingToAdd.calculationAfter.percentMargin,
      marginBuffer: packagingToAdd?.calculationAfter.marginBuffer,
      totalmargin: packagingToAdd.calculationAfter.totalMargin,
      standardCalculation: standardCalculationInfo
    };

    let updateObject: { "calculations.0.info": calculationInfo; state?: string } = {
      "calculations.0.info": newInfo
    };

    if (!useOwnStock && (order.state === WAITING || order.state === PRODUCTIONQUEUE))
      updateObject.state = ORDERORDERCOMMODITIES;

    // build transactional actions for adding new packaging's
    const actions: Array<Action> = [
      {
        collection: ORDERS,
        filter: { _id: order._id },
        push: {
          "calculations.0.packagings": newPricingObject,
          timeline
        },
        update: updateObject
      } as UpdateAction
    ];

    // start db transactions
    const success = await dbService.transaction(actions);

    await toastUtils.databaseOperationToast(
      success,
      "Order successfully updated!",
      "Error order could not be updated!",
      () => setModalState(getDefaultState()),
      () =>
        setModalState(prevState => {
          return {
            ...prevState,
            submitting: false
          };
        })
    );
  }, [packagingToAdd, useOwnStock, amountPerUnit, units]);

  /**
   * Function to receive a packaging collection with entries that could be additionally added
   * @returns {Array<PackagingWithBestPrices>} the possible selectable packaging with extended information
   */
  const createPackagingSelection = useCallback(() => {
    const calculation = order.calculations[0];
    const newSelectablePackaging: Array<PackagingWithBestPrices> = [];
    const selectedPackagingIds = calculation.packagings.map(p => p._id.toString());
    const usedPackaging = selectedPackagingIds.map(id => baseUtils.getDocFromCollection(packagings, id));
    const usedPackagingTypes = usedPackaging.map(p => p?.packaging_type);
    const hasTorso = usedPackaging.some(p => ALLTORSO.includes(p?.packaging_type));
    const availablePackaging = packagings.filter(
      p =>
        p.suppliers.length > 0 &&
        p.suppliers.some(s => s.prices.length > 0) &&
        !selectedPackagingIds.includes(p._id) &&
        (hasTorso ? !ALLTORSO.includes(p.packaging_type) : ALLTORSO.includes(p.packaging_type)) &&
        !usedPackagingTypes.includes(p.packaging_type)
    );

    for (let i = 0; i < availablePackaging.length; i++) {
      const relatedPackaging = availablePackaging[i];
      const relatedPackagingStock = context.packagingStock.filter(
        pS => pS.packaging.toString() === relatedPackaging._id.toString()
      );

      // receive available stock
      const availableStock = packagingUtils.getAvailableStock(
        relatedPackaging,
        packagingStock,
        packagingOrders,
        orders,
        order.settings.filler?._id || order.settings.manufacturer._id
      );

      const supplierPriceMOQ = orderCalculationUtils.getPackagingPriceForMOQ(
        relatedPackaging,
        calculation.units,
        "",
        "",
        0,
        true
      );
      let supplierBestPrice: SupplierOption | undefined;
      if (supplierPriceMOQ !== undefined) {
        // receive supplier best price option
        supplierBestPrice = {
          type: "supplier",
          supplierOption: supplierPriceMOQ
        };
      }

      // receive possible own stock option
      const ownStockPrice: OwnStockOption = {
        type: "ownstock",
        ownStockOption: calculationUtils.getPackagingStockPriceForManufacturer(
          relatedPackagingStock,
          order.settings.filler || order.settings.manufacturer
        )
      };

      // build best price object (comparing own stock with supplier options)
      const bestPrice =
        supplierBestPrice &&
        (supplierBestPrice.supplierOption.price.price < ownStockPrice.ownStockOption.price ||
          ownStockPrice.ownStockOption.price === 0 || // case: unlogical own stock prices due to broken data :)
          availableStock <= 0)
          ? supplierBestPrice
          : ownStockPrice;

      if (
        (bestPrice && bestPrice.type === "supplier" && bestPrice.supplierOption.price.price > 0) ||
        (bestPrice.type === "ownstock" && bestPrice.ownStockOption.price > 0)
      ) {
        newSelectablePackaging.push({
          _id: new BSON.ObjectId(),
          availableStock: availableStock,
          bestPrice: bestPrice,
          relatedPackaging: relatedPackaging,
          relatedStock: relatedPackagingStock,
          selectedPriceOption: bestPrice
        });
      }
    }

    setModalState(prevState => {
      return {
        ...prevState,
        selectablePackaging: newSelectablePackaging
      };
    });
  }, [packagings, order, packagingStock, packagingOrders, orders]);

  /**
   * Function for handling filter properties
   * @param selectablePackaging the collection to be filtered
   * @returns {Array<PackagingWithBestPrices>} array of filtered packaging
   */
  const filterSelectablePackaging = useCallback(
    (selectablePackaging: Array<PackagingWithBestPrices>) => {
      let filteringResult = selectablePackaging.slice();

      // search query handling
      const workingQuery = query.trim().toLowerCase();
      if (workingQuery !== "") {
        filteringResult = filteringResult.filter(
          extendedPackaging =>
            packagingUtils
              .concatPackagingInfo(extendedPackaging.relatedPackaging)
              .toLowerCase()
              .includes(workingQuery) || extendedPackaging.relatedPackaging.note.toLowerCase().includes(workingQuery)
        );
      }

      // package type handling
      if (filter.value !== "") {
        filteringResult = filteringResult.filter(
          extendedPackaging => extendedPackaging.relatedPackaging.packaging_type.trim() === filter.value
        );
      }

      // sorting
      filteringResult = _.sortBy(filteringResult, ["relatedPackaging.packaging_type", "bestPrice.price.price"]);

      return filteringResult;
    },
    [query, filter]
  );

  /**
   * Function for error handling
   */
  const validateUnitAndAmount = useCallback(() => {
    const errors = [] as Array<string>;
    let invalidOwnStockOrderUnits = false;
    let invalidOwnStockUnitAmount = false;
    if (useOwnStock) {
      const usedStock = amountPerUnit * units;
      if (selectedAdditionalPackaging.availableStock < usedStock) {
        errors.push("The stock to be used is more than the available stock!");
        invalidOwnStockOrderUnits = true;
        if (amountPerUnit > 1) {
          // mark unit amountPerUnit as possible error source
          invalidOwnStockUnitAmount = true;
        }
      }
    }
    // set error state
    setModalState(prevState => {
      return {
        ...prevState,
        errors: errors,
        invalidOwnStockUnitAmount: invalidOwnStockUnitAmount,
        invalidOwnStockOrderUnits: invalidOwnStockOrderUnits
      };
    });
  }, [useOwnStock, amountPerUnit, units, selectedAdditionalPackaging]);

  const filteredResult = filterSelectablePackaging(selectablePackaging);
  const relatedPackagingFilterOptions = packagingUtils.getSelectableOptions(
    selectablePackaging.map(sP => sP.relatedPackaging)
  );

  return (
    <>
      <button className="btn btn-sm btn-secondary" onClick={handleShow}>
        Add Packaging
      </button>
      <Modal className="" show={show} onHide={handleHide} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Additional Packaging</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === ModalSteps.PACKAGING_SELECTION && (
            <PackagingSelection
              currentPage={currentPage}
              filter={filter}
              filterOptions={relatedPackagingFilterOptions}
              onHandleChangeQuery={handleChangeQuery}
              onHandleClickSelect={handleClickSelect}
              onHandleFilterChange={handleFilterChange}
              onHandlePageChange={handlePageChange}
              onHandlePageSizeChange={handlePageSizeChange}
              packaging={filteredResult}
              pageSize={pageSize}
              query={query}
            />
          )}
          {step === ModalSteps.SELECTION_OVERVIEW && selectedAdditionalPackaging.selectedPriceOption && (
            <SelectionOverview
              orderUnits={units}
              invalidOrderUnits={invalidOwnStockOrderUnits}
              invalidUnitAmount={invalidOwnStockUnitAmount}
              onHandleChangeNumericAmount={handleChangeNumericAmount}
              onHandleChangeSelectedSupplier={handleChangeSelectedSupplier}
              packagingPriceOption={
                selectedAdditionalPackaging.selectedPriceOption.type === "ownstock"
                  ? (selectedAdditionalPackaging.selectedPriceOption as OwnStockOption).ownStockOption
                  : selectedAdditionalPackaging.selectedPriceOption.type === "supplier"
                  ? (selectedAdditionalPackaging.selectedPriceOption as SupplierOption).supplierOption.price
                  : (selectedAdditionalPackaging.selectedPriceOption as CustomerOption).customerOption
              }
              unitAmount={amountPerUnit}
              selectedPackaging={selectedAdditionalPackaging}
              selectedSupplier={selectedSupplier}
              context={context}
            />
          )}
          {step === ModalSteps.CALCULATION_OVERVIEW && packagingToAdd && (
            <CalculationOverview
              amountPerUnit={amountPerUnit}
              packagingToAdd={packagingToAdd}
              selectedAdditionalPackaging={selectedAdditionalPackaging}
              selectedSupplier={selectedSupplier}
              units={units}
              useOwnStock={useOwnStock}
              context={context}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {(step === ModalSteps.SELECTION_OVERVIEW || step === ModalSteps.CALCULATION_OVERVIEW) && (
            <button className="btn btn-secondary" onClick={handleGoBack} disabled={submitting}>
              Back
            </button>
          )}
          {step === ModalSteps.PACKAGING_SELECTION && (
            <button className="btn btn-secondary" onClick={handleHide}>
              Close
            </button>
          )}
          {step === ModalSteps.SELECTION_OVERVIEW && (
            <ErrorOverlayButton
              errors={errors}
              className="btn btn-success"
              buttonText="Next"
              onClick={handlePrepareCalculation}
            />
          )}
          {step === ModalSteps.CALCULATION_OVERVIEW && (
            <button className="btn btn-success" onClick={handleCalculationConfirmation} disabled={submitting}>
              {submitting && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Confirm
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface PackagingSelectionProps {
  currentPage: number;
  filter: { value: string; label: string };
  filterOptions: { value: string; label: string }[];
  onHandleChangeQuery: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleClickSelect: (relatedPackaging: PackagingWithBestPrices) => void;
  onHandleFilterChange: (entry: { value: string; label: string }) => void;
  onHandlePageChange: (page: number) => void;
  onHandlePageSizeChange: (pageSize: number) => void;
  packaging: Array<PackagingWithBestPrices>;
  pageSize: number;
  query: string;
}

const PackagingSelection: React.FunctionComponent<PackagingSelectionProps> = ({
  currentPage,
  filter,
  filterOptions,
  onHandleChangeQuery,
  onHandleClickSelect,
  onHandleFilterChange,
  onHandlePageChange,
  onHandlePageSizeChange,
  packaging,
  pageSize,
  query
}) => {
  return (
    <>
      <div className="row">
        <div className="col-4">
          <div className="kt-input-icon kt-input-icon--left">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              onChange={onHandleChangeQuery}
              value={query}
              name="query"
            />
            <span className="kt-input-icon__icon kt-input-icon__icon--left">
              <span>
                <i className="la la-search" />
              </span>
            </span>
          </div>
        </div>
        <div className="col-4">
          <Select
            className="select-default"
            isClearable={true}
            options={filterOptions}
            value={{
              value: filter.value,
              label: filter.value ? filter.label : "All Packaging"
            }}
            onChange={(value: any) => onHandleFilterChange(value)}
          />
        </div>
        <div className="col-4" />
      </div>
      <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
        <Table>
          <thead>
            <tr>
              <th style={{ width: "45%" }}>Packaging</th>
              <th style={{ width: "20%" }}>Best Price</th>
              <th style={{ width: "20%" }}>Warehouse Stock</th>
              <th style={{ width: "15%" }} />
            </tr>
          </thead>
          <tbody>
            {paginate(packaging, currentPage, pageSize).map(packagingWithBestPrices => {
              const relatedPrice =
                packagingWithBestPrices.bestPrice && packagingWithBestPrices.bestPrice.type === "ownstock"
                  ? packagingWithBestPrices.bestPrice.ownStockOption.price
                  : (packagingWithBestPrices.bestPrice as SupplierOption).supplierOption.price.price;
              return (
                <tr key={packagingWithBestPrices._id.toString()} className="table-hover">
                  <td className="align-middle">
                    <span>
                      <div className="kt-user-card-v2">
                        <div className="kt-user-card-v2__details">
                          <span className="kt-user-card-v2__name">
                            <Link
                              to={"/packaging/" + packagingWithBestPrices.relatedPackaging._id.toString()}
                              className="kt-user-card-v2__name pr-2"
                              style={{ display: "inline" }}
                            >
                              {packagingUtils.getPackagingType(packagingWithBestPrices.relatedPackaging.packaging_type)}
                            </Link>
                          </span>
                          <span className="kt-user-card-v2__email">
                            {packagingUtils.resolvePackagingProperties(packagingWithBestPrices.relatedPackaging)}
                          </span>
                        </div>
                      </div>
                    </span>
                  </td>
                  <td className="align-middle">
                    {!packagingWithBestPrices.bestPrice || !relatedPrice || relatedPrice === 0
                      ? "-,-- €"
                      : baseUtils.formatEuro(relatedPrice)}
                  </td>
                  <td className="align-middle">{packagingWithBestPrices.availableStock} units</td>
                  <td className="align-middle">
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={() => onHandleClickSelect(packagingWithBestPrices)}
                    >
                      Select
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="kt-datatable__pager kt-datatable--paging-loaded justify-content-center">
          <Pagination
            itemsCount={packaging.length}
            pageSize={pageSize}
            onPageChange={onHandlePageChange}
            onPageSizeChange={onHandlePageSizeChange}
            currentPage={currentPage}
          />
        </div>
      </div>
    </>
  );
};

interface SelectionOverviewProps {
  context: React.ContextType<typeof DataContext>;
  invalidUnitAmount: boolean;
  invalidOrderUnits: boolean;
  onHandleChangeNumericAmount: (type: "amountPerUnit" | "unit", e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleChangeSelectedSupplier: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  orderUnits: number;
  packagingPriceOption: PackagingPrice;
  unitAmount: number;
  selectedPackaging: PackagingWithBestPrices;
  selectedSupplier: string;
}

const SelectionOverview: React.FunctionComponent<SelectionOverviewProps> = ({
  invalidUnitAmount,
  invalidOrderUnits,
  orderUnits,
  packagingPriceOption,
  onHandleChangeNumericAmount,
  onHandleChangeSelectedSupplier,
  unitAmount,
  selectedPackaging,
  selectedSupplier,
  context
}) => {
  return (
    <>
      <div className="row mb-2">
        <div className="col-3 text-right align-self-center">
          <span className="text-dark">Packaging</span>
        </div>
        <div className="col-9">
          <input
            type="text"
            value={
              packagingUtils.getPackagingType(selectedPackaging.relatedPackaging.packaging_type) +
              " (" +
              packagingUtils.resolvePackagingProperties(selectedPackaging.relatedPackaging) +
              ")"
            }
            disabled
            className="form-control"
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-3 text-right align-self-center">
          <span className="text-dark">Amount</span>
        </div>
        <div className="col-9">
          <div className="input-group">
            <input
              type="number"
              value={unitAmount}
              className={"form-control " + (invalidUnitAmount ? "is-invalid" : "")}
              onChange={e => onHandleChangeNumericAmount("amountPerUnit", e)}
              name="newAmount"
            />
            <div className="input-group-append">
              <span className="input-group-text">per unit</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-3 text-right align-self-center">
          <span className="text-dark">Supplier</span>
        </div>
        <div className="col-9">
          <select className="form-control" onChange={onHandleChangeSelectedSupplier} value={selectedSupplier}>
            {selectedPackaging.availableStock > 0 && (
              <option value="ownstock" key="ownstock">
                Ownstock
              </option>
            )}
            {selectedPackaging.relatedPackaging.suppliers.map(s => {
              const supplier: SuppliersDocument = baseUtils.getDocFromCollection(context.suppliers, s._id);
              return (
                <option value={supplier._id.toString()} key={supplier._id.toString()}>
                  {supplier.name}
                </option>
              );
            })}
            <option value="customer" key="customer">
              Customer
            </option>
          </select>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-3 text-right align-self-center">
          <span className="text-dark">Price</span>
        </div>
        <div className="col-3">
          <input
            type="text"
            value={baseUtils.formatEuro(packagingPriceOption.price)}
            disabled
            className="form-control"
          />
        </div>
        {"purchasePrice" in packagingPriceOption &&
          "purchaseCurrency" in packagingPriceOption &&
          !!packagingPriceOption.purchasePrice &&
          !!packagingPriceOption.purchaseCurrency && (
            <>
              <div className="col-3 text-right align-self-center">
                <span className="text-dark">Purchase price</span>
              </div>
              <div className="col-3">
                <input
                  type="text"
                  value={
                    packagingPriceOption.purchasePrice
                      ? baseUtils.formatCurrency(
                          packagingPriceOption.purchasePrice,
                          packagingPriceOption.purchaseCurrency
                        )
                      : "Missing!"
                  }
                  disabled
                  className="form-control"
                />
              </div>
            </>
          )}
      </div>
      <div className="row mb-2 mt-4">
        <div className="col-3 text-right align-self-center">
          <span className="text-dark">Order Quantity</span>
        </div>
        <div className="col-3">
          <div className="input-group">
            <input
              type="number"
              value={orderUnits}
              className={"form-control " + (invalidOrderUnits ? "is-invalid" : "")}
              onChange={e => onHandleChangeNumericAmount("unit", e)}
              name="orderUnits"
            />
            <div className="input-group-append">
              <span className="input-group-text">units</span>
            </div>
          </div>
        </div>
        <div className="col-3 text-right align-self-center">
          <span className="text-dark">Amount stocked</span>
        </div>
        <div className="col-3">
          <input type="text" value={selectedPackaging.availableStock + " units"} disabled className="form-control" />
        </div>
      </div>
    </>
  );
};

interface CalculationOverviewProps {
  amountPerUnit: number;
  packagingToAdd: PackagingToAdd;
  selectedAdditionalPackaging: PackagingWithBestPrices;
  selectedSupplier: string;
  units: number;
  useOwnStock: boolean;
  context: React.ContextType<typeof DataContext>;
}

const CalculationOverview: React.FunctionComponent<CalculationOverviewProps> = ({
  amountPerUnit,
  packagingToAdd,
  selectedAdditionalPackaging,
  selectedSupplier,
  units,
  useOwnStock,
  context
}) => {
  return (
    <>
      <div className="text-dark">Please check the following information:</div>
      <div className="text-dark mt-4">Overall:</div>
      <div className="row mt-4 text-dark">
        <div className="col-3 text-right">Packaging:</div>
        <div className="col-9">
          {packagingUtils.resolvePackagingProperties(selectedAdditionalPackaging.relatedPackaging)}
        </div>
      </div>
      <div className="row text-dark">
        <div className="col-3 text-right">Amount:</div>
        <div className="col-4">{amountPerUnit}</div>
        <div className="col-5" />
      </div>
      <div className="row text-dark">
        <div className="col-3 text-right">Units:</div>
        <div className="col-4">{units}</div>
        <div className="col-5" />
      </div>
      {useOwnStock && (
        <div className="row text-dark">
          <div className="col-3 text-right">Own Stock:</div>
          <div className="col-3">{selectedAdditionalPackaging.availableStock}</div>
          <div className="col-1">
            <i className="fa fa-arrow-right mx-2" />
          </div>
          <div className="col-5">
            {selectedAdditionalPackaging.availableStock - amountPerUnit * units} {"(-" + amountPerUnit * units + ")"}
          </div>
        </div>
      )}
      {!useOwnStock && (
        <div className="row text-dark">
          <div className="col-3 text-right">Supplier:</div>
          <div className="col-9">{orderUtils.getSupplierNameById(selectedSupplier, context.suppliers)}</div>
        </div>
      )}
      {packagingToAdd && (
        <>
          <div className="row text-dark">
            <div className="col-3 text-right">Price:</div>
            <div className="col-9">{baseUtils.formatEuro(packagingToAdd.relatedCalculation.price)}</div>
          </div>
          <div className="row text-dark">
            <div className="col-3 text-right">Total Amount:</div>
            <div className="col-9">{packagingToAdd.relatedCalculation.totalAmount}</div>
          </div>
          <div className="row text-dark">
            <div className="col-3 text-right">Total Price:</div>
            <div className="col-9">
              {baseUtils.formatEuro(
                packagingToAdd.relatedCalculation.totalPrice ? packagingToAdd.relatedCalculation.totalPrice : 0
              )}
            </div>
          </div>
          <div className="row text-dark">
            <div className="col-3 text-right">Delivery Time:</div>
            <div className="col-9">{packagingToAdd.relatedCalculation.deliveryTime + " day(s)"}</div>
          </div>
        </>
      )}
      <div className="text-dark mt-4">Calculation:</div>
      <div className="row text-dark">
        <div className="col-3 text-right">Turnover Unit:</div>
        <div className="col-9">{baseUtils.formatEuro(packagingToAdd.calculationBefore.unitPrice)}</div>
      </div>
      <div className="row text-dark">
        <div className="col-3 text-right">Turnover (Total):</div>
        <div className="col-9">{baseUtils.formatEuro(packagingToAdd.calculationBefore.totalPrice)}</div>
      </div>
      <div className="row text-dark">
        <div className="col-3 text-right">Margin (%):</div>
        <div className="col-3">{packagingToAdd.calculationBefore.percentMargin.toFixed(2) + " %"}</div>
        <div className="col-1">
          <i className="fa fa-arrow-right mx-2" />
        </div>
        <div className="col-5">
          {packagingToAdd.calculationAfter.percentMargin.toFixed(2) + " %"}{" "}
          {"( " +
            (packagingToAdd.calculationAfter.percentMargin - packagingToAdd.calculationBefore.percentMargin).toFixed(
              2
            ) +
            "% )"}
        </div>
      </div>
      <div className="row text-dark">
        <div className="col-3 text-right">Margin (Unit)</div>
        <div className="col-3">{baseUtils.formatEuro(packagingToAdd.calculationBefore.unitMargin)}</div>
        <div className="col-1">
          <i className="fa fa-arrow-right mx-2" />
        </div>
        <div className="col-5">
          {baseUtils.formatEuro(packagingToAdd.calculationAfter.unitMargin)}{" "}
          {"( " +
            baseUtils.formatEuro(
              packagingToAdd.calculationAfter.unitMargin - packagingToAdd.calculationBefore.unitMargin
            ) +
            ")"}
        </div>
      </div>
      <div className="row text-dark">
        <div className="col-3 text-right">Cost (Unit)</div>
        <div className="col-3">{baseUtils.formatEuro(packagingToAdd.calculationBefore.unitPriceNaked)}</div>
        <div className="col-1">
          <i className="fa fa-arrow-right mx-2" />
        </div>
        <div className="col-5">
          {baseUtils.formatEuro(packagingToAdd.calculationAfter.unitPriceNaked)}{" "}
          {"( +" +
            baseUtils.formatEuro(
              packagingToAdd.calculationAfter.unitPriceNaked - packagingToAdd.calculationBefore.unitPriceNaked
            ) +
            ")"}
        </div>
      </div>
      <div className="row text-dark">
        <div className="col-3 text-right">Margin (Total)</div>
        <div className="col-3">{baseUtils.formatEuro(packagingToAdd.calculationBefore.totalMargin)}</div>
        <div className="col-1">
          <i className="fa fa-arrow-right mx-2" />
        </div>
        <div className="col-5">
          {baseUtils.formatEuro(packagingToAdd.calculationAfter.totalMargin)}{" "}
          {"( " +
            baseUtils.formatEuro(
              packagingToAdd.calculationAfter.totalMargin - packagingToAdd.calculationBefore.totalMargin
            ) +
            ")"}
        </div>
      </div>
    </>
  );
};

export default memo(AddAdditionalPackagingModal, (prevProps, nextProps) => {
  return (
    _.isEqual(prevProps.order, nextProps.order) &&
    _.isEqual(prevProps.context.orders, nextProps.context.orders) &&
    _.isEqual(prevProps.context.packagingOrders, nextProps.context.packagingOrders) &&
    _.isEqual(prevProps.context.packagings, nextProps.context.packagings) &&
    _.isEqual(prevProps.context.packagingStock, nextProps.context.packagingStock)
  );
});
