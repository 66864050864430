import _ from "lodash";
import Joi from "joi";
import countryList from "i18n-iso-countries";
import React, { Component, PureComponent, useRef } from "react";
import { BSON } from "realm-web";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { DataContext } from "../../context/dataContext";
import Pagination, { paginate } from "../common/Pagination";
import SelectEmployee from "../common/SelectEmployee";
import companyUtils from "../../utils/companyUtils";
import userService from "../../services/userService";
import dbService, { COMPANIES, USERDATA } from "../../services/dbService";
import { ExtendedCustomer } from "./CustomTypes";
import { UserdataDocument } from "../../model/userdata.types";
import { CompaniesDocument } from "../../model/companies.types";
import { SearchBar } from "../listings/common/Filters";
import baseUtils from "../../utils/baseUtils";

countryList.registerLocale(require("i18n-iso-countries/langs/en.json"));

interface CustomerSelectionProps {
  selectedCustomer: ExtendedCustomer | null;
  fullscreen: boolean;
  userdata: Array<UserdataDocument>;
  companies: Array<CompaniesDocument>;
  onCustomerSelect: (customer: ExtendedCustomer) => void;
}

interface CustomerSelectionState {
  currentPage: number;
  pageSize: number;
  searchQuery: string;
  extendedCustomers: Array<ExtendedCustomer>;
  newCustomer?: BSON.ObjectId;
}

class CustomerSelection extends Component<CustomerSelectionProps, CustomerSelectionState> {
  constructor(props: CustomerSelectionProps) {
    super(props);
    this.state = {
      searchQuery: "",
      currentPage: 1,
      pageSize: 10,
      extendedCustomers: []
    };
  }

  componentDidMount() {
    const extendedCustomers = this.getExtendedCustomer();
    this.setState({ extendedCustomers });
  }

  componentDidUpdate(
    prevProps: Readonly<CustomerSelectionProps>,
    prevState: Readonly<CustomerSelectionState>,
    snapshot?: any
  ) {
    const { newCustomer } = this.state;
    if (
      prevProps.companies !== this.props.companies ||
      prevProps.companies.length !== this.props.companies.length ||
      prevProps.userdata !== this.props.userdata ||
      prevProps.userdata.length !== this.props.userdata.length
    ) {
      const extendedCustomers = this.getExtendedCustomer();
      let state: any = { extendedCustomers };
      if (newCustomer) {
        const newCustomerObject = extendedCustomers.find(cust => cust._id.toString() === newCustomer.toString());
        if (newCustomerObject) {
          this.props.onCustomerSelect(newCustomerObject);
          state.newCustomer = undefined;
        }
      }
      this.setState({ ...state });
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<CustomerSelectionProps>,
    nextState: Readonly<CustomerSelectionState>,
    nextContext: any
  ): boolean {
    return (
      nextProps.companies !== this.props.companies ||
      nextProps.companies.length !== this.props.companies.length ||
      nextProps.userdata !== this.props.userdata ||
      nextProps.userdata.length !== this.props.userdata.length ||
      nextState !== this.state ||
      nextProps !== this.props
    );
  }

  getExtendedCustomer = () => {
    const { companies, userdata } = this.props;
    let extendedCustomers = [];
    for (let i = 0; i < companies.length; i++) {
      const company = _.omit(_.cloneDeep(companies[i]), "owner") as ExtendedCustomer;
      const [contact, owner] = companyUtils.getContactAndOwner(companies[i], userdata);
      if (owner) {
        company.owner = owner;
        company.contact = contact;
        extendedCustomers.push(company);
      }
    }
    return extendedCustomers;
  };

  getDisplayedCustomers = () => {
    const { selectedCustomer } = this.props;
    const { extendedCustomers, searchQuery } = this.state;
    let displayedCustomer = _.cloneDeep(extendedCustomers);
    if (selectedCustomer) {
      displayedCustomer = displayedCustomer.filter(cust => cust._id.toString() !== selectedCustomer._id.toString());
      displayedCustomer.unshift(selectedCustomer);
    }
    let displayedCustomerFuse: Array<{
      displayedCustomer: ExtendedCustomer;
      oFullName: string | null;
      cFullName: string | null;
    }> = displayedCustomer.map(dc => {
      return {
        displayedCustomer: dc,
        oFullName: dc.owner ? dc.owner.prename + " " + dc.owner.surname : null,
        cFullName: dc.contact ? dc.contact.prename + " " + dc.contact.surname : null
      };
    });
    if (searchQuery.trim() === "") return displayedCustomer;
    return baseUtils
      .doFuseSearch(displayedCustomerFuse, searchQuery, ["displayedCustomer.name", "oFullName", "cFullName"])
      .map(dcf => dcf.displayedCustomer);
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: e.target.value.toLowerCase(), currentPage: 1 });
  };

  handlePageChange = (page: number) => {
    this.setState({ currentPage: page });
  };

  handlePageSizeChange = (pageSize: number) => {
    this.setState({ pageSize, currentPage: 1 });
  };

  handleCustomerSelect = (customer: ExtendedCustomer) => {
    this.setState({ currentPage: 1, searchQuery: "" });
    this.props.onCustomerSelect(customer);
  };

  handleCreateNewCustomer = (id: BSON.ObjectId) => {
    const { extendedCustomers } = this.state;
    if (id) {
      const newCustomerObject = extendedCustomers.find(cust => cust._id.toString() === id.toString());
      if (newCustomerObject) this.props.onCustomerSelect(newCustomerObject);
      else this.setState({ newCustomer: id });
    }
  };

  render() {
    const { fullscreen } = this.props;
    const { searchQuery, pageSize, currentPage } = this.state;
    const displayedCustomers = this.getDisplayedCustomers();
    return (
      <div>
        <div
          className={!fullscreen ? "kt-portlet kt-portlet--tabs mt-1" : "kt-portlet kt-portlet--tabs mt-1 d-none"}
          style={{ boxShadow: "none" }}
        >
          <div className="kt-portlet__body pb-1 pl-0 pr-0">
            <div className="from-group row mb-0">
              <SearchBar onSearch={this.handleSearch} search={searchQuery} additionalSizeClasses="col-md-3" />
              <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                <AddCustomerModal onCreateNewCustomer={this.handleCreateNewCustomer} />
              </div>
            </div>
          </div>
        </div>
        <>
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div
              className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded"
              id="column_rendering"
            >
              {displayedCustomers.length === 0 ? (
                <div className="row mt-3">
                  <h3 className="mx-auto text-muted">No matching customers found</h3>
                </div>
              ) : (
                <table className="kt-datatable__table table-responsive">
                  <thead className="kt-datatable__head">
                    <tr className="kt-datatable__row" style={{ minWidth: "600px" }}>
                      <th className="kt-datatable__cell" style={{ width: "35%" }}>
                        <span>Company</span>
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "30%" }}>
                        <span>Contact Person</span>
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "35%" }}>
                        <span>Owner</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="kt-datatable__body">
                    {paginate(displayedCustomers, currentPage, pageSize).map(customer => (
                      <CustomerSelectionRow
                        key={customer._id.toString()}
                        customer={customer}
                        onCustomerSelect={this.handleCustomerSelect}
                      />
                    ))}
                  </tbody>
                </table>
              )}
              <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <Pagination
                  itemsCount={displayedCustomers.length}
                  pageSize={pageSize}
                  onPageChange={this.handlePageChange}
                  currentPage={currentPage}
                  onPageSizeChange={this.handlePageSizeChange}
                  additionalNavClasses={"justify-content-center flex-grow-1"}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    );
  }
}

interface CustomerSelectionRowProps {
  customer: ExtendedCustomer;
  onCustomerSelect: (customer: ExtendedCustomer) => void;
}

const CustomerSelectionRow: React.FunctionComponent<CustomerSelectionRowProps> = ({ customer, onCustomerSelect }) => {
  const rowRef = useRef(null);
  const additionalRowClass = customer.selected ? "table-selected-hover" : "table-hover";
  const nameSplit = customer.name.split(/[ -]+/);
  return (
    <tr
      className={"kt-datatable__row " + (customer.selected ? "table-selected " : "table-default ") + additionalRowClass}
      style={{ minWidth: "600px" }}
      onClick={() => onCustomerSelect(customer)}
      ref={rowRef}
    >
      <td className="kt-datatable__cell" style={{ width: "35%" }}>
        <span>
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__pic">
              <div className="kt-badge kt-badge--xl kt-badge--primary">
                {(nameSplit.length > 1 ? nameSplit[0][0] + nameSplit[1][0] : customer.name.slice(0, 2)).toUpperCase()}
              </div>
            </div>
            <div className="kt-user-card-v2__details">
              <Link
                onMouseEnter={() => {
                  // @ts-ignore
                  if (rowRef && rowRef.current) rowRef.current.classList.remove(additionalRowClass);
                }}
                onMouseLeave={() => {
                  // @ts-ignore
                  if (rowRef && rowRef.current) rowRef.current.classList.add(additionalRowClass);
                }}
                onClick={e => e.stopPropagation()}
                to={"/company/" + customer._id.toString()}
                target="_blank"
                className="kt-user-card-v2__name kt-link"
              >
                {customer.name}
              </Link>
              <span className="kt-user-card-v2__email">{customer.address[0].city}</span>
            </div>
          </div>
        </span>
      </td>
      <td className="kt-datatable__cell" style={{ width: "30%" }}>
        <span>
          {customer.contact
            ? customer.contact.title + " " + customer.contact.prename + " " + customer.contact.surname
            : "-"}
        </span>
      </td>
      <td className="kt-datatable__cell" style={{ width: "35%" }}>
        <span>
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__pic">
              <div className="kt-badge kt-badge--xl ">
                <img src={customer.owner.img_url} />
              </div>
            </div>
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name">{customer.owner.prename + " " + customer.owner.surname}</span>
              <span className="kt-user-card-v2__name">{customer.owner.position}</span>
            </div>
          </div>
        </span>
      </td>
    </tr>
  );
};

interface AddCustomerModalProps {
  onCreateNewCustomer: (id: BSON.ObjectId) => void;
}

interface AddCustomerModalState {
  creating: boolean;
  show: boolean;
  company: {
    companyName: string;
    contactMail: string;
    street: string;
    streetnr: string;
    zip: string;
    city: string;
    country: string;
    owner?: UserdataDocument;
  };
  contactPerson: { title: string; prename: string; surname: string; email: string };
}

class AddCustomerModal extends PureComponent<AddCustomerModalProps, AddCustomerModalState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  emailSchema = Joi.string()
    .email({ tlds: { allow: false }, minDomainSegments: 1 })
    .required()
    .label("Email");

  constructor(props: AddCustomerModalProps) {
    super(props);
    this.state = {
      show: false,
      creating: false,
      company: {
        companyName: "",
        contactMail: "",
        street: "",
        streetnr: "",
        zip: "",
        city: "",
        country: "Germany",
        owner: userService.getUserData()
      },
      contactPerson: { title: "Mrs", prename: "", surname: "", email: "" }
    };
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });

  handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const company = { ...this.state.company };
    _.set(company, e.target.name, e.target.value);
    this.setState({ company });
  };

  handleCompanyOwner = (owner: UserdataDocument | undefined) => {
    const company = { ...this.state.company };
    company.owner = owner;
    this.setState({ company });
  };

  handleContactPersonChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const contactPerson = { ...this.state.contactPerson };
    _.set(contactPerson, e.target.name, e.target.value);
    this.setState({ contactPerson });
  };

  handleCreateCustomer = async () => {
    this.setState({ creating: true });
    const { company, contactPerson } = this.state;
    if (!this.isDataComplete()) {
      toast.error("Data is incomplete");
      return;
    }
    const result: any = await dbService.callFunction("saveCreateCompanyAndUser", [company, contactPerson, true]);
    if (result) {
      if (result.result) {
        await this.context.updateDocumentInContext(COMPANIES, result.insertedCompany);
        await this.context.updateDocumentInContext(USERDATA, result.insertedUser);
        toast.success("Customer '" + company.companyName + "' created successfully");
        this.props.onCreateNewCustomer(result.insertedCompany);
        this.setState({ creating: false, show: false });
      } else {
        toast.error(result.message);
        this.setState({ creating: false });
      }
    }
  };

  getFormClasses = (valid: boolean) => {
    return "form-control " + (valid ? "" : "is-invalid");
  };

  isDataComplete = () => {
    const { company, contactPerson } = this.state;
    const companyEntries = Object.entries(company);
    const contactPersonEntries = Object.entries(contactPerson);
    let isValid = true;
    for (let i = 0; i < companyEntries.length; i++) {
      const entry = companyEntries[i];
      if (entry[0] === "owner") isValid = isValid && !!entry[1];
      else if (entry[0] === "contactMail")
        isValid = isValid && (entry[1] === "" || !this.emailSchema.validate(entry[1]).error);
      else isValid = isValid && entry[1].toString().trim() !== "";
      if (!isValid) return isValid;
    }
    for (let i = 0; i < contactPersonEntries.length; i++) {
      const entry = contactPersonEntries[i];
      if (entry[0] === "email") isValid = isValid && !this.emailSchema.validate(entry[1]).error;
      else isValid = isValid && entry[1].trim() !== "";
      if (!isValid) return isValid;
    }
    return isValid;
  };

  render() {
    const { show, company, contactPerson, creating } = this.state;
    return (
      <>
        <Modal show={show} size={"lg"} centered name={"AddCustomerModal"} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand fa fa-building mr-2" />
              Add New Customer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <div className="kt-portlet__body">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="kt-section kt-section--first">
                        <div className="kt-section__body">
                          <h3 className="kt-section__title kt-section__title-lg">Company Info:</h3>
                          <div className="form-group row">
                            <label className="col-3 col-form-label">Company</label>
                            <div className="col-9">
                              <input
                                className={this.getFormClasses(company.companyName.trim() !== "")}
                                type="text"
                                value={company.companyName}
                                onChange={this.handleCompanyChange}
                                placeholder="Company"
                                name="companyName"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-3 col-form-label">Contact Email</label>
                            <div className="col-9">
                              <input
                                className={this.getFormClasses(
                                  company.contactMail === "" || !this.emailSchema.validate(company.contactMail).error
                                )}
                                type="text"
                                value={company.contactMail}
                                onChange={this.handleCompanyChange}
                                placeholder="Contact Email"
                                name="contactMail"
                              />
                            </div>
                          </div>
                          <div className="form-group row mb-2">
                            <label className="col-3 col-form-label">Address</label>
                            <div className="col-6">
                              <input
                                className={this.getFormClasses(company.street.trim() !== "")}
                                type="text"
                                name="street"
                                placeholder="Street"
                                value={company.street}
                                onChange={this.handleCompanyChange}
                              />
                            </div>
                            <div className="col-3">
                              <input
                                className={this.getFormClasses(company.streetnr.trim() !== "")}
                                type="text"
                                name="streetnr"
                                placeholder="Nr."
                                value={company.streetnr}
                                onChange={this.handleCompanyChange}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-3" />
                            <div className="col-2">
                              <input
                                className={this.getFormClasses(company.zip.trim() !== "")}
                                type="text"
                                name="zip"
                                placeholder="Zip"
                                value={company.zip}
                                onChange={this.handleCompanyChange}
                              />
                            </div>
                            <div className="col-4">
                              <input
                                className={this.getFormClasses(company.city.trim() !== "")}
                                type="text"
                                name="city"
                                placeholder="City"
                                value={company.city}
                                onChange={this.handleCompanyChange}
                              />
                            </div>
                            <div className="col-3">
                              <select
                                className="form-control"
                                name="country"
                                value={company.country}
                                onChange={this.handleCompanyChange}
                              >
                                {Object.values(countryList.getNames("en")).map((item: string) => (
                                  <option key={item}>{item}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-3 col-form-label">Owner</label>
                            <div className="col-9">
                              <SelectEmployee
                                userdata={this.context.userdata}
                                selectedUser={company.owner}
                                onSelectUser={this.handleCompanyOwner}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
                      <div className="kt-section">
                        <div className="kt-section__body">
                          <h3 className="kt-section__title kt-section__title-lg">Contact Person:</h3>
                          <div className="form-group row">
                            <label className="col-3 col-form-label">Name</label>
                            <div className="col-1">
                              <select
                                className="form-control p-0"
                                name="title"
                                value={contactPerson.title}
                                onChange={this.handleContactPersonChange}
                              >
                                <option value="Mr">Mr.</option>
                                <option value="Mrs">Mrs.</option>
                              </select>
                            </div>
                            <div className="col-4">
                              <input
                                className={this.getFormClasses(contactPerson.prename.trim() !== "")}
                                type="text"
                                name="prename"
                                placeholder="Prename"
                                value={contactPerson.prename}
                                onChange={this.handleContactPersonChange}
                              />
                            </div>
                            <div className="col-4">
                              <input
                                className={this.getFormClasses(contactPerson.surname.trim() !== "")}
                                type="text"
                                name="surname"
                                placeholder="Surname"
                                value={contactPerson.surname}
                                onChange={this.handleContactPersonChange}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-3 col-form-label">Email</label>
                            <div className="col-9">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="la la-at" />
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  className={this.getFormClasses(!this.emailSchema.validate(contactPerson.email).error)}
                                  name="email"
                                  placeholder="Email"
                                  value={contactPerson.email}
                                  onChange={this.handleContactPersonChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="p-2">
            <button key="cancel" className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <button
              key="add"
              className={"btn btn-primary " + (this.isDataComplete() && !creating ? "" : "disabled")}
              disabled={!this.isDataComplete() || creating}
              onClick={this.handleCreateCustomer}
            >
              {creating && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Add Customer
            </button>
          </Modal.Footer>
        </Modal>
        <button type="button" className="btn btn-secondary" onClick={this.handleShow}>
          Add New Customer
        </button>
      </>
    );
  }
}

export default CustomerSelection;
