import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import ProductionPlan from "./ProductionPlan";

interface ProductionPlanProps extends RouteComponentProps {}

const ProductionPlanWrapper: React.FunctionComponent<ProductionPlanProps> = props => {
  const context = useContext(DataContext);
  return <ProductionPlan context={context} {...props} />;
};

export default ProductionPlanWrapper;
