import React, { PureComponent, useEffect, useState } from "react";
import { OverlayTrigger, Popover, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import CompanyWidget from "../../common/CompanyWidget";
import ManufacturerWidget from "../../common/ManufacturerWidget";
import { DataContext } from "../../../context/dataContext";
import baseUtils from "../../../utils/baseUtils";
import { OrdersDocument } from "../../../model/orders.types";
import { PackagingsDocument } from "../../../model/packagings.types";
import { PackagingOrderDocument } from "../../../model/packagingOrders.types";
import CreatePackagingOrderModal from "../modals/CreatePackagingOrderModal";
import DeliverPackagingOrderModal from "../modals/DeliverPackagingOrderModal";
import { OrderWidget } from "../../listings/common/BaseListingComponents";

interface PackagingPackagingOrdersProps {
  packaging: PackagingsDocument;
  context: React.ContextType<typeof DataContext>;
  size: "full" | "small";
  onTabChange?: (tab: string) => void;
  order?: OrdersDocument;
}

interface PackagingPackagingOrdersState {}

class PackagingPackagingOrders extends PureComponent<PackagingPackagingOrdersProps, PackagingPackagingOrdersState> {
  /**
   * Calculate the width of the progress bar
   * @param order: Packaging order whose process should be calculated
   * @returns { string } Represents the percentage of the progress bar width
   */
  getProgressBarWidth = (order: PackagingOrderDocument) => {
    if (order.delivered) {
      return "100%";
    }
    if (!order.ordered) {
      return "0%";
    }
    if (order.ordered && !order.delivered) {
      let eta = order.expectedDelivery;
      let timeUntilEta = eta.getTime() - order.ordered.getTime();
      let timeSinceOrdered = new Date().getTime() - order.ordered.getTime();

      return (timeSinceOrdered / (timeUntilEta !== 0 ? timeUntilEta : 1)) * 100 + "%";
    }

    return "50%";
  };

  render() {
    const { packaging, context, onTabChange, order, size } = this.props;
    let orders: Array<PackagingOrderDocument> = context.packagingOrders.filter(
      o => o.packaging.toString() === packaging._id.toString()
    );
    if (orders && size === "small") orders = orders.filter(o => !o.delivered);
    return (
      <>
        {orders && orders.length > 0 ? (
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  <th style={{ width: size === "full" ? "10%" : "16%" }}>Amount</th>
                  <th style={{ width: size === "full" ? "21%" : "41%" }}>Supplier</th>
                  {size === "full" && (
                    <>
                      <th style={{ width: "10%" }}>Price</th>
                      <th style={{ width: "10%" }}>Incoterm</th>
                      <th style={{ width: "20%" }}>Manufacturer</th>
                    </>
                  )}
                  <th style={{ width: "13%" }}>Progress</th>
                  {size === "full" && <th style={{ width: "6%" }}>Ord. PDF</th>}
                  <th style={{ width: size === "full" ? "10%" : "15%" }} />
                </tr>
              </thead>
              <tbody>
                {orders.map(o => {
                  const supplier = o.supplier
                    ? baseUtils.getDocFromCollection(context.suppliers, o.supplier!.toString())
                    : null;
                  const manufacturer = o.destination
                    ? baseUtils.getDocFromCollection(context.manufacturers, o.destination!.toString())
                    : null;
                  const cOrder =
                    o.relatedOrders && o.relatedOrders.length === 1
                      ? baseUtils.getDocFromCollection(context.orders, o.relatedOrders[0].toString())
                      : null;
                  return (
                    <tr
                      key={o._id.toString()}
                      className={o.delivered ? "" : "table-hover"}
                      style={{ opacity: o.delivered ? 0.3 : 1 }}
                    >
                      <td className="align-middle">
                        <AmountCell cOrder={cOrder} o={o} context={context} />
                      </td>
                      <td className="align-middle">
                        {supplier ? <CompanyWidget company={supplier} type="supplier" /> : "Unknown"}
                      </td>
                      {size === "full" && (
                        <>
                          <td className="align-middle">
                            <span className="font-weight-bold">
                              {baseUtils.formatEuro(o.totalPrice / o.orderQuantity)} / unit
                            </span>
                            <br />
                            <span className="text-muted">{baseUtils.formatEuro(o.totalPrice)} total</span>
                          </td>
                          <td className="align-middle">{o.incoterm ? o.incoterm.toUpperCase() : "Not set"}</td>
                          <td className="align-middle">
                            {manufacturer ? <ManufacturerWidget manufacturer={manufacturer} /> : "Unknown"}
                          </td>
                        </>
                      )}
                      <td className="align-middle">
                        <div className="progress" style={{ height: 5 }}>
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{
                              width: this.getProgressBarWidth(o)
                            }}
                          />
                        </div>
                        {size === "full" && (
                          <small>
                            {o.delivered ? (
                              <div>Delivered: {baseUtils.formatDate(o.delivered)}</div>
                            ) : (
                              <>
                                <div>
                                  Ordered:{" "}
                                  {o.ordered ? baseUtils.formatDate(o.ordered) : baseUtils.formatDate(o.created)}
                                </div>
                                <div>ETA: {baseUtils.formatDate(o.expectedDelivery)}</div>
                              </>
                            )}
                          </small>
                        )}
                      </td>
                      {size === "full" && (
                        <td className="align-middle text-center">
                          <div className="kt-widget4__pic kt-widget4__pic--icon">
                            <img
                              src={process.env.PUBLIC_URL + "/media/icons/pdf_icon.png"}
                              alt="orderPDF"
                              style={{
                                width: 30,
                                opacity: 0.25
                              }}
                            />
                          </div>
                        </td>
                      )}
                      <td className="align-middle text-right">
                        {o.delivered ? (
                          <div>
                            {o.lot ? <span className="mr-2">Batch: {o.lot}</span> : <i className="fa fa-check" />}
                          </div>
                        ) : (
                          <div className="btn-group">
                            <CreatePackagingOrderModal packaging={packaging} context={context} packagingOrder={o} />
                            <DeliverPackagingOrderModal packaging={packaging} context={context} packagingOrder={o} />
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="m-4 text-center text-muted">No orders planned</div>
        )}
        <div className="kt-separator kt-separator--space-lg kt-separator--fit kt-separator--border-solid" />
        <div className="text-right">
          <CreatePackagingOrderModal packaging={packaging} context={context} order={order} />
          {size === "small" && onTabChange && (
            <button className="btn btn-success ml-2" onClick={() => onTabChange("packagingOrders")}>
              Show All
            </button>
          )}
        </div>
      </>
    );
  }
}

interface AmountCellProps {
  cOrder: OrdersDocument;
  o: PackagingOrderDocument;
  context: React.ContextType<typeof DataContext>;
}

const AmountCell: React.FunctionComponent<AmountCellProps> = ({ cOrder, o, context }) => {
  const [showPopover, setShowPopover] = useState(false);

  let setTimeoutConst: any = null;

  useEffect(() => {
    return () => {
      if (setTimeoutConst) {
        clearTimeout(setTimeoutConst);
      }
    };
  });

  const handleMouseEnter = () => {
    setTimeoutConst = setTimeout(() => {
      setShowPopover(true);
    }, 0);
  };

  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst);
    setShowPopover(false);
  };

  return (
    <>
      <span className="font-weight-bold">{o.orderQuantity} pcs.</span>
      <br />
      <span className="text-muted">
        for{" "}
        {cOrder ? (
          <Link className={"kt-link kt-font-dark"} to={"/order/" + cOrder._id.toString()}>
            AT-{cOrder.identifier}
          </Link>
        ) : o.relatedOrders && o.relatedOrders.length > 1 ? (
          <OverlayTrigger
            placement="top"
            show={showPopover}
            overlay={
              <Popover id={"relatedOrders"} onMouseEnter={() => setShowPopover(true)} onMouseLeave={handleMouseLeave}>
                <Popover.Content>
                  <>
                    {o.relatedOrders && o.relatedOrders.length > 0 && (
                      <div className="text-left mb-4">
                        <h6 className="kt-font-dark">Orders</h6>
                        {o.relatedOrders.map(ord => {
                          const ordDoc = baseUtils.getDocFromCollection(context.orders, ord);
                          if (ordDoc)
                            return <OrderWidget key={ordDoc._id.toString()} document={ordDoc} prefix={"AT-"} />;
                          return <React.Fragment key={ord.toString()} />;
                        })}
                      </div>
                    )}
                  </>
                </Popover.Content>
              </Popover>
            }
          >
            <span
              className="kt-font-dark kt-link"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >{`${o.relatedOrders.length} orders`}</span>
          </OverlayTrigger>
        ) : (
          "stock"
        )}
      </span>
    </>
  );
};

export default PackagingPackagingOrders;
