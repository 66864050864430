import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../../context/dataContext";
import DunningListing from "./DunningListing";

interface DunningWrapperProps extends RouteComponentProps {}

const DunningWrapper: React.FunctionComponent<DunningWrapperProps> = props => {
  const context = useContext(DataContext);
  return <DunningListing context={context} {...props} />;
};

export default DunningWrapper;
