import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const RemainingInfoTooltip = () => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="allocatedInfo">
          This value indicates the remaining stock amount, i.e. the current stock amount <br /> with the "Used Amount"
          value and "Other Allocated" value <b>subtracted</b>.
        </Tooltip>
      }
    >
      <i className="fa fa-info-circle ml-2 text-muted" />
    </OverlayTrigger>
  );
};

export default RemainingInfoTooltip;
