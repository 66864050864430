import React from "react";
import { toAbsoluteUrl } from "../../../_metronic";

export default class SearchResult extends React.Component {
  render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }
    if (data.length === 0) {
      return (
        <div className="kt-quick-search__result">
          <div className="kt-quick-search__message kt-hidden">No record found</div>
        </div>
      );
    }
    return (
      <div className="kt-quick-search__result">
        {data.map((item, index) => (
          <React.Fragment key={index}>
            {item.type === 1 && (
              <React.Fragment>
                {" "}
                <div className="kt-quick-search__item">
                  <img src={toAbsoluteUrl("/media/files/pdf.svg")} style={{ height: "20px" }} />
                  <div className="kt-quick-search__item-wrapper">
                    <a href="#" className="kt-quick-search__item-title">
                      {item.text}
                    </a>
                    <div className="kt-quick-search__item-desc">{item.description}</div>
                  </div>
                </div>
              </React.Fragment>
            )}
            {item.type === 0 && <div className="kt-quick-search__category">{item.text}</div>}
          </React.Fragment>
        ))}
      </div>
    );
  }
}
