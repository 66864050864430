import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { CommodityBatch, CommoditiesDocument } from "../../model/commodities.types";
import baseUtils from "../../utils/baseUtils";
import fileUtils from "../../utils/fileUtils";
import { ManufacturersDocument } from "../../model/manufacturers.types";

interface ExpiredStockProps extends RouteComponentProps {
  commodities: Array<CommoditiesDocument>;
  manufacturers: Array<ManufacturersDocument>;
}

interface ExpiredStockState {
  generating: boolean;
}

class ExpiredStock extends PureComponent<ExpiredStockProps, ExpiredStockState> {
  constructor(props: ExpiredStockProps) {
    super(props);
    this.state = { generating: false };
  }

  handleClickGenerateCSV = () => {
    const { commodities, manufacturers } = this.props;
    this.setState({ generating: true });
    const now = new Date();
    const cWExpiredBatches = commodities.filter(c => c.stock.some(b => b.expiry < now && b.amount > 0));
    const expiredBatches: Array<{ batch: CommodityBatch; commodity: string }> = [];
    try {
      for (let i = 0; i < cWExpiredBatches.length; i++) {
        const c = cWExpiredBatches[i];
        for (let j = 0; j < c.stock.length; j++) {
          const b = c.stock[j];
          if (b.expiry < now && b.amount > 0) {
            expiredBatches.push({ batch: b, commodity: c.title.en });
          }
        }
      }
      const headers = [
        "Batch ID",
        "Location",
        "Batch Lot",
        "Commodity Title",
        "Amount KG",
        "Price KG",
        "Price Total",
        "Expired At"
      ];
      const content = [];
      for (let i = 0; i < expiredBatches.length; i++) {
        const b = expiredBatches[i];
        const location = manufacturers.find(m => m._id.toString() === b.batch.location.toString());
        content.push([
          b.batch._id.toString(),
          location ? location.name : "Unknown",
          b.batch.lot,
          b.commodity,
          b.batch.amount.toString(),
          b.batch.price.toString(),
          (b.batch.price * b.batch.amount).toString(),
          baseUtils.formatDate(b.batch.expiry)
        ]);
      }
      const csv = fileUtils.exportAsCSV(headers, content);
      fileUtils.downloadFile(csv, "ExpiredStock_" + baseUtils.formatDate(new Date()) + ".csv", "text/plain");
    } catch (e) {
      toast.error("Error generating CSV");
      console.error(e);
    } finally {
      this.setState({ generating: false });
    }
  };

  render() {
    const { history } = this.props;
    const { generating } = this.state;

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Expired Stock</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <button
                onClick={() => {
                  history.goBack();
                }}
                className="btn btn-clean kt-margin-r-10"
              >
                <i className="la la-arrow-left" />
                <span className="kt-hidden-mobile">Back</span>
              </button>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col text-dark font-weight-bolder mb-5">
              Exports all batches that are expired with their value.
            </div>
          </div>
        </div>
        <div className="kt-portlet__foot">
          <div className="float-right">
            <button
              className="btn btn-success"
              disabled={generating}
              onClick={generating ? undefined : this.handleClickGenerateCSV}
            >
              {generating ? "Generating..." : "Generate CSV"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ExpiredStock);
