import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import SimpleConfirmationModal from "../common/SimpleConfirmationModal";
import { CompaniesDocument } from "../../model/companies.types";
import dbService, { COMPANIES } from "../../services/dbService";
import accessUtils, { ACTIONS } from "../../utils/accessUtils";

interface SupplyCentralFeaturesProps {
  company: CompaniesDocument;
}

interface SupplyCentralFeaturesState {
  customerNotes: boolean;
  showConfirmation: boolean;
  saving: boolean;
}

class SupplyCentralFeatures extends PureComponent<SupplyCentralFeaturesProps, SupplyCentralFeaturesState> {
  constructor(props: SupplyCentralFeaturesProps) {
    super(props);
    this.state = { customerNotes: props.company.featureAccess?.notes ?? false, showConfirmation: false, saving: false };
  }

  handleShowConfirmationModal = () => this.setState({ showConfirmation: true });
  handleHideConfirmationModal = () => this.setState({ showConfirmation: false });

  handleChangeFeatureAccess = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  };

  resetData = () => {
    this.setState({ customerNotes: this.props.company.featureAccess?.notes ?? false });
  };

  submitChanges = async () => {
    const { company } = this.props;
    const { customerNotes } = this.state;
    this.setState({ saving: true });
    try {
      const res = await dbService.updateDocument(COMPANIES, company._id, { "featureAccess.notes": customerNotes });
      if (res && res.modifiedCount > 0) {
        toast.success("Feature access saved successfully");
      } else {
        toast.error("Error saving feature access");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { customerNotes, showConfirmation, saving } = this.state;
    const canChange = accessUtils.canPerformAction(ACTIONS.SUPPLYCENTRALFEATURE);
    return (
      <>
        <div className="alert alert-light alert-elevate" style={{ backgroundColor: "white" }}>
          <div className="alert-icon">
            <i className="flaticon-warning kt-font-danger" />
          </div>
          <div className="alert-text">
            Please note that changing the access to a SupplyCentral feature has an instant impact on the customer. Do
            NOT randomly enable or disable features - it could have massive impact on the customer relationship.
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th className="w-25">Feature</th>
              <th>Description</th>
              <th style={{ width: "15%" }}>Enabled</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Customer Order Notes</td>
              <td>
                Users of the customer company can add notes to orders. Those notes are shown on the order page and the
                notes overview. In addition to this Slack notifications are sent when a customer is adding a note to an
                order.
              </td>
              <td>
                <input
                  className={"prettyCheckbox" + (canChange && !saving ? "" : " disabled")}
                  type="checkbox"
                  disabled={!canChange || saving}
                  checked={customerNotes}
                  name="customerNotes"
                  onChange={canChange ? this.handleChangeFeatureAccess : undefined}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="kt-portlet__foot text-right">
          <button
            className={"btn btn-secondary mr-2" + (canChange && !saving ? "" : " disabled")}
            disabled={!canChange || saving}
            onClick={canChange && !saving ? this.resetData : undefined}
          >
            Reset
          </button>
          <button
            className={"btn btn-success" + (canChange && !saving ? "" : " disabled")}
            onClick={canChange && !saving ? this.handleShowConfirmationModal : undefined}
            disabled={!canChange || saving}
          >
            Save
          </button>
          {canChange && (
            <SimpleConfirmationModal.SimpleConfirmationModal
              modalTitle="Confirm saving changes"
              modalDescription="Do you really want to save your changes? Any changed feature access has instant impact on the customer."
              show={showConfirmation}
              onConfirm={this.submitChanges}
              confirmButtonText="Confirm"
              cancelButtonText="Cancel"
              onClose={this.handleHideConfirmationModal}
            />
          )}
        </div>
      </>
    );
  }
}

export default SupplyCentralFeatures;
