import React, { PureComponent } from "react";
import { Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BSON } from "realm-web";
import CompanyWidget from "../../common/CompanyWidget";
import PersonWidget from "../../common/PersonWidget";
import { DataContext } from "../../../context/dataContext";
import { CommoditiesDocument, CommodityOrder } from "../../../model/commodities.types";
import { OrdersDocument } from "../../../model/orders.types";
import dbService, { UpdateAction, COMMODITIES, EMORDERS, ORDERS } from "../../../services/dbService";
import dbCommodityService from "../../../services/dbServices/dbCommodityService";
import userService from "../../../services/userService";
import baseUtils from "../../../utils/baseUtils";
import commodityUtils from "../../../utils/commodityUtils";
import { T_COMMODITYORDERCANCELED } from "../../../utils/timelineUtils";
import toastUtils from "../../../utils/toastUtils";
import { ExternalManufacturerOrdersDocument } from "../../../model/externalManufacturerOrders.types";
import { CompaniesDocument } from "../../../model/companies.types";
import { EM_OPEN, EM_ORDERCANCELED } from "../../externalManufacturers/ExternalManufacturerHelper";
import { TIMELINE_SLICE_AMOUNT } from "../../../utils/materialUtils";

interface CancelCommodityOrderModalProps {
  commodity: CommoditiesDocument;
  commodityOrder: CommodityOrder;
  context: React.ContextType<typeof DataContext>;
  show: boolean;
  onHide: (show: boolean) => void;
}

interface CancelCommodityOrderModalState {
  saving: boolean;
}

class CancelCommodityOrderModal extends PureComponent<CancelCommodityOrderModalProps, CancelCommodityOrderModalState> {
  constructor(props: CancelCommodityOrderModalProps) {
    super(props);
    this.state = { saving: false };
  }

  /**
   * Handle cancelling a commodity order.
   */
  handleCancelOrder = async () => {
    const { commodity, commodityOrder, context, onHide } = this.props;
    this.setState({ saving: true });
    const timelineUpdate = commodityUtils.prepareCommodityOrderTimeline(commodityOrder);
    let success;
    // If there are orders relating to the commodity order we need to update them too
    if (
      commodityOrder.orders.length > 0 ||
      (commodityOrder.relatedEMOrders && commodityOrder.relatedEMOrders.length > 0)
    ) {
      // Update commodity
      const actions: Array<UpdateAction> = [
        {
          collection: COMMODITIES,
          filter: { _id: commodity._id, "orders._id": commodityOrder._id },
          push: { timeline: { $each: [timelineUpdate], $slice: TIMELINE_SLICE_AMOUNT } },
          pull: { orders: commodityOrder }
        }
      ];
      const timeline = {
        id: new BSON.ObjectId(),
        type: T_COMMODITYORDERCANCELED,
        date: new Date(),
        amount: commodityOrder.orderquantity,
        commodity: commodity._id,
        supplier: commodityOrder.supplier,
        person: userService.getUserId(),
        price: commodityOrder.totalPrice
      };
      // Update orders
      for (let i = 0; i < commodityOrder.orders.length; i++) {
        const order = baseUtils.getDocFromCollection(context.orders, commodityOrder.orders[i].toString());
        if (order) {
          actions.push({
            collection: ORDERS,
            filter: { _id: order._id },
            update: {
              "calculations.0.prices.$[c].ordered": null,
              "calculations.0.prices.$[c].userOrdered": null,
              "calculations.0.prices.$[c].eta": null,
              "calculations.0.prices.$[c].viaWarehouse": false,
              "calculations.0.prices.$[c].arrivedAtWarehouse": null
            },
            push: { timeline },
            arrayFilters: [{ "c._id": commodity._id }]
          });
        }
      }
      if (commodityOrder.relatedEMOrders) {
        for (let i = 0; i < commodityOrder.relatedEMOrders.length; i++) {
          const order = commodityOrder.relatedEMOrders[i];
          if (order) {
            actions.push({
              collection: EMORDERS,
              filter: { _id: order },
              update: {
                state: EM_OPEN,
                actualPrice: null,
                actualDeliveryDate: null
              },
              push: {
                timeline: {
                  _id: new BSON.ObjectId(),
                  type: EM_ORDERCANCELED,
                  date: new Date(),
                  person: userService.getUserId()
                }
              }
            });
          }
        }
      }
      success = await dbService.updatesAsTransaction(actions);
    } else {
      const res = await dbCommodityService.cancelCommodityOrder(commodity._id, commodityOrder, timelineUpdate!);
      success = !!res && res.modifiedCount > 0;
    }
    await toastUtils.databaseOperationToast(
      success,
      "Commodity order successfully cancelled",
      "Error cancelling commodity order",
      () => {
        context.updateDocumentInContext(COMMODITIES, commodity._id);
        commodityOrder.orders.forEach(o => context.updateDocumentInContext(ORDERS, o));
        if (commodityOrder.relatedEMOrders)
          commodityOrder.relatedEMOrders.forEach(o => context.updateDocumentInContext(EMORDERS, o));
      }
    );
    this.setState({ saving: false });
    if (success) onHide(false);
  };

  render() {
    const { commodity, commodityOrder, context, show, onHide } = this.props;
    const { saving } = this.state;
    const orders: Array<OrdersDocument> = [];
    const externalOrders: Array<ExternalManufacturerOrdersDocument> = [];
    commodityOrder.orders.forEach(o => {
      const order = baseUtils.getDocFromCollection(context.orders, o);
      if (order) orders.push(order);
    });
    if (commodityOrder.relatedEMOrders)
      commodityOrder.relatedEMOrders.forEach(o => {
        const order = baseUtils.getDocFromCollection(context.externalManufacturerOrders, o);
        if (order) externalOrders.push(order);
      });
    return (
      <Modal show={show} onHide={() => onHide(true)} centered size={orders.length > 0 ? "lg" : undefined}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel order for {commodity.title.en}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-warning my-2" role="alert">
            <div className="alert-icon">
              <i className="flaticon-warning" />
            </div>
            <div className="alert-text">
              Do you really want to cancel this order of{" "}
              {commodityUtils.resolveStockUnit(commodityOrder.orderquantity, commodity.type)} for {commodity.title.en}?
              <br />
              <span className="font-weight-bolder">This can not be undone!</span>
              {(orders.length > 0 || externalOrders.length > 0) && (
                <>
                  <br />
                  <div>The following (external) orders will be updated:</div>
                </>
              )}
            </div>
          </div>
          {(orders.length > 0 || externalOrders.length > 0) && (
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr className="table-hover">
                    <th style={{ width: "30%" }}>Order</th>
                    <th style={{ width: "35%" }}>Customer</th>
                    <th style={{ width: "35%" }}>Owner</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map(o => {
                    const customer = baseUtils.getDocFromCollection(context.companies, o.createdFor);
                    const owner = baseUtils.getDocFromCollection(context.userdata, o.createdFrom);
                    return (
                      <tr key={o._id.toString()}>
                        <td className="align-middle">
                          <Link to={"/order/" + o._id.toString()} className="h6 kt-link kt-font-dark">
                            AT-{o.identifier}
                          </Link>
                          <div className="text-muted">{o.title}</div>
                        </td>
                        <td className="align-middle">
                          {customer ? <CompanyWidget company={customer} type="company" /> : "Unknown"}
                        </td>
                        <td className="align-middle">{owner ? <PersonWidget person={owner} /> : "Unknown"}</td>
                      </tr>
                    );
                  })}
                  {externalOrders.map(o => {
                    const customer = baseUtils.getDocFromCollection(context.companies, o.company) as CompaniesDocument;
                    const owner = customer ? baseUtils.getDocFromCollection(context.userdata, customer.owner) : "-";
                    return (
                      <tr key={o._id.toString()}>
                        <td className="align-middle">
                          <Link to={"/externalOrder/" + o._id.toString()} className="h6 kt-link kt-font-dark">
                            EMO-{o.identifier}
                          </Link>
                          <div className="text-muted">{o.reference}</div>
                        </td>
                        <td className="align-middle">
                          {customer ? <CompanyWidget company={customer} type="company" /> : "Unknown"}
                        </td>
                        <td className="align-middle">{owner ? <PersonWidget person={owner} /> : "Unknown"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => onHide(true)}>
            Close
          </button>
          <button
            className={"btn btn-secondary" + (saving ? " disabled" : "")}
            disabled={saving}
            onClick={this.handleCancelOrder}
          >
            Cancel Order
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CancelCommodityOrderModal;
