import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useWarehouseContext,
  useWarehouseDispatch,
  WarehouseActionType,
  WarehouseListingTabNames
} from "../../../../context/warehouseContext";
import { CommodityWithBatches } from "../../../../model/warehouse/customTypes.types";
import { getBBDColor, getPackagingUnitAmount, SelectedCommodityEntryType } from "../../../../utils/warehouseUtils";
import baseUtils, { formatNumValueLocale } from "../../../../utils/baseUtils";
import i18n from "../../../../translations/i18n";
import { resolveTranslation } from "../../../../utils/translationUtils";
import Tooltip from "../../../common/Tooltip";
import { NumValue } from "../../../../model/common.types";
import { Batch, BatchLocation } from "../../../../model/warehouse/batch.types";
import { useDataContext } from "../../../../context/dataContext";
import { getBatchLocationStatusIndicationText } from "../../../../utils/batchUtils";
import { StatusContextMenu } from "../../common/StatusContextMenu";
import { filterLocations } from "../../../../utils/warehouseFilterUtils";
import StorageSpaceInformation from "../common/StorageSpaceInformation";
import WarehouseInformation from "../common/WarehouseInformation";
import { Reservation } from "../../../../model/warehouse/reservation.types";
import { ReservationStatusContextMenu } from "../../common/ReservationStatusContextMenu";
import BBDInformation from "../common/BBDInformation";

interface DefaultCommodityWithBatchesRowProps {
  activeTab: WarehouseListingTabNames;
  commodity: CommodityWithBatches;
}

const DefaultCommodityWithBatchesRow: React.FC<DefaultCommodityWithBatchesRowProps> = ({ activeTab, commodity }) => {
  const dispatch = useWarehouseDispatch();
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { configuration, selectedEntries, selectedLocation, query } = warehouseContext;
  const { reservation } = dataContext;
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (query) setShowDetails(true);
    else setShowDetails(false);
  }, [query]);

  const uniqueWarehouses = useMemo(
    () =>
      Array.from(new Set(commodity.batches.flatMap(b => b.locations.map(l => l.location.warehouseSnapshot.shortName)))),
    [commodity.batches]
  );

  const uniqueSenders = useMemo(
    () => Array.from(new Set(commodity.batches.map(b => b.sender.name))),
    [commodity.batches]
  );

  const uniqueMHD = useMemo(
    () => Array.from(new Set(commodity.batches.map(b => b.expiry.toISOString().split("T")[0]))),
    [commodity.batches]
  );

  const totalAmounts = useMemo(() => {
    const valueUnitMap = commodity.batches.reduce((map, b) => {
      const mapCopy = { ...map };
      if (b.totalAmount.unit in map) mapCopy[b.totalAmount.unit] += b.totalAmount.value;
      else mapCopy[b.totalAmount.unit] = b.totalAmount.value;
      return mapCopy;
    }, {} as { [unit: string]: number });

    const valueList: Array<NumValue> = Object.entries(valueUnitMap).map(([unit, value]) => ({
      value,
      unit
    }));
    return valueList.reduce(
      (str, val, idx) =>
        str +
        formatNumValueLocale(val) +
        (idx === valueList.length - 1 ? "" : idx === valueList.length - 2 ? " & " : ", "),
      ""
    );
  }, [commodity.batches]);

  const totalReservedAmounts = useMemo(() => {
    const valueUnitMap = commodity.reservations.reduce((map, r) => {
      const mapCopy = { ...map };
      r.materials.forEach(m => {
        if (m.material.details._id.toString() === commodity._id.toString()) {
          if (m.reservedAmount.unit in map) mapCopy[m.reservedAmount.unit] += m.reservedAmount.value;
          else mapCopy[m.reservedAmount.unit] = m.reservedAmount.value;
        }
      });

      return mapCopy;
    }, {} as { [unit: string]: number });

    const valueList: Array<NumValue> = Object.entries(valueUnitMap).map(([unit, value]) => ({
      value,
      unit
    }));
    return valueList.reduce(
      (str, val, idx) =>
        str +
        formatNumValueLocale(val) +
        (idx === valueList.length - 1 ? "" : idx === valueList.length - 2 ? " & " : ", "),
      ""
    );
  }, [commodity.reservations]);

  const checked = useMemo(
    () =>
      selectedEntries.some(
        entry =>
          entry.type === SelectedCommodityEntryType.COMMODITY &&
          entry.commodityId === commodity._id.toString() &&
          !entry.batchId
      ),
    [commodity, selectedEntries]
  );

  const allChildren: Array<[batchId: string, locationId: string]> = useMemo(() => {
    const batchesWithFilteredLocations: Array<[bId: string, locations: Array<BatchLocation>]> = commodity.batches.map(
      b => [b._id.toString(), b.locations.filter(l => filterLocations(l, activeTab, warehouseContext))]
    );
    const allChildren: Array<[batchId: string, locationId: string]> = [];
    batchesWithFilteredLocations.forEach(b => b[1].forEach(l => allChildren.push([b[0], l._id.toString()])));
    return allChildren;
  }, [activeTab, commodity.batches, configuration, selectedLocation, reservation]);

  const handleToggleDetails = useCallback(() => setShowDetails(prevState => !prevState), []);

  const handleCheckbox = useCallback(() => {
    dispatch({
      type: WarehouseActionType.SELECT_COMMODITY_ENTRY,
      payload: {
        type: SelectedCommodityEntryType.COMMODITY,
        commodityId: commodity._id.toString(),
        numberOfChildren: allChildren.length,
        currentChildren: allChildren
      }
    });
  }, [commodity, allChildren]);

  return (
    <>
      <tr className={"kt-datatable__row d-table-row nopadding table-hover"} onClick={handleToggleDetails}>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <input
                type="checkbox"
                className="ml-0 kt-checkbox--solid"
                checked={checked}
                onClick={e => e.stopPropagation()}
                onChange={handleCheckbox}
              />
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name text-dark " style={{ lineHeight: "1.2rem" }}>
                <span className="d-inline-block text-ellipsis align-bottom" style={{ maxWidth: "180px" }}>
                  {resolveTranslation(commodity.title)}
                </span>
                <small
                  className={"kt-user-card-v2__email ml-2 text-ellipsis align-bottom"}
                  style={{ maxWidth: "180px" }}
                >
                  {resolveTranslation(commodity.subtitle)}
                </small>
              </span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className={"kt-user-card-v2__email mt-0 text-black"}>{commodity.identifier || "-"}</span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className={"kt-user-card-v2__email mt-0 text-black"}>
            {uniqueSenders.length === 1 ? uniqueSenders[0] : i18n.t("warehouse:variousSenders")}
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span
            className={
              "kt-user-card-v2__email mt-0 " +
              (uniqueMHD.length === 1 ? getBBDColor(new Date(uniqueMHD[0])) : "text-black")
            }
          >
            {uniqueMHD.length === 1 ? baseUtils.formatDate(new Date(uniqueMHD[0])) : i18n.t("warehouse:various")}
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span
                className={"kt-user-card-v2__name text-black " + (totalAmounts ? "kt-font-bold" : "font-weight-300")}
              >
                {totalAmounts || `-`}
              </span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span
                className={
                  "kt-user-card-v2__name text-black " + (totalReservedAmounts ? "kt-font-bold" : "font-weight-300")
                }
              >
                {totalReservedAmounts || `-`}
              </span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell text-right">
          <Tooltip
            tooltipText={
              uniqueWarehouses.length === 1
                ? resolveTranslation(commodity.batches[0].locations[0].location.warehouseSnapshot.warehouseName)
                : i18n.t("warehouse:variousWarehouses")
            }
          >
            <div className={"label label-light label-inline kt-font-bold text-black my-auto"} style={{ width: 60 }}>
              {uniqueWarehouses.length === 1 ? uniqueWarehouses[0] : "VAR"}
            </div>
          </Tooltip>
        </td>
      </tr>
      {showDetails && (
        <tr className={"kt-datatable__row d-table-row"}>
          <td colSpan={10} className="px-0">
            {commodity.batches.length > 0 && (
              <DefaultCommodityBatchesTable
                commodity={commodity}
                activeTab={activeTab}
                totalNumberOfLocations={allChildren.length}
              />
            )}
            {commodity.reservations.length > 0 && <DefaultCommodityReservationsTable commodity={commodity} />}
          </td>
        </tr>
      )}
    </>
  );
};

interface DefaultCommodityBatchesTableProps {
  commodity: CommodityWithBatches;
  activeTab: WarehouseListingTabNames;
  totalNumberOfLocations: number;
}

const DefaultCommodityBatchesTable: React.FC<DefaultCommodityBatchesTableProps> = ({
  activeTab,
  commodity,
  totalNumberOfLocations
}) => {
  const headerDefinition = useMemo(
    () => [
      { title: <i className="fas fa-lock text-muted" style={{ right: 0 }} />, size: 1 },
      { title: "#", size: 1 },
      { title: i18n.t("warehouse:amount"), size: 11, className: "pr-0" },
      { title: i18n.t("warehouse:supplier"), size: 12 },
      { title: i18n.t("order:lotNumber"), size: 12, className: "pr-0" },
      { title: i18n.t("warehouse:warehouseShort"), size: 15 },
      { title: i18n.t("warehouse:storageSpace"), size: 10 },
      { title: i18n.t("warehouse:packagingUnitAbbreviation"), size: 18 },
      { title: i18n.t("warehouse:bbd"), size: 10 },
      { title: i18n.t("warehouse:status"), size: 10 }
    ],
    []
  );

  return (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive p-2 bg-light mb-0">
      <table className="kt-datatable__table d-table p-5 bg-light">
        <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
          <tr className="kt-datatable__row d-table-row">
            {headerDefinition.map((def, idx) => (
              <th
                key={idx}
                className={"kt-datatable__cell d-table-cell " + (def.className || "")}
                style={{ width: `${def.size}%` }}
              >
                <span>{def.title}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
          {commodity.batches.map(b => (
            <DefaultCommodityBatchDetailRows
              key={b._id.toString()}
              activeTab={activeTab}
              commodity={commodity}
              batch={b}
              totalNumberOfLocations={totalNumberOfLocations}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface DefaultCommodityBatchDetailRowsProps {
  activeTab: WarehouseListingTabNames;
  commodity: CommodityWithBatches;
  batch: Batch;
  totalNumberOfLocations: number;
}

const DefaultCommodityBatchDetailRows: React.FC<DefaultCommodityBatchDetailRowsProps> = ({
  activeTab,
  commodity,
  batch,
  totalNumberOfLocations
}) => {
  const warehouseContext = useWarehouseContext();
  const { configuration, selectedLocation } = warehouseContext;

  const filteredLocations = useMemo(
    () => batch.locations.filter(l => filterLocations(l, activeTab, warehouseContext)),
    [activeTab, batch, configuration, selectedLocation]
  );

  return (
    <>
      {filteredLocations.map(l => (
        <DefaultCommodityBatchDetailRow
          key={l._id.toString()}
          commodity={commodity}
          batch={batch}
          location={l}
          totalNumberOfLocations={totalNumberOfLocations}
        />
      ))}
    </>
  );
};

interface DefaultCommodityBatchDetailRowProps {
  commodity: CommodityWithBatches;
  batch: Batch;
  location: BatchLocation;
  totalNumberOfLocations: number;
}

const DefaultCommodityBatchDetailRow: React.FC<DefaultCommodityBatchDetailRowProps> = ({
  commodity,
  batch,
  location,
  totalNumberOfLocations
}) => {
  const dataContext = useDataContext();
  const warehouseContext = useWarehouseContext();
  const dispatch = useWarehouseDispatch();
  const { reservation } = dataContext;
  const { selectedEntries, query } = warehouseContext;

  const checked = useMemo(
    () =>
      selectedEntries.some(
        entry =>
          entry.type === SelectedCommodityEntryType.BATCH_LOCATION &&
          entry.commodityId === commodity._id.toString() &&
          entry.batchId === batch._id.toString() &&
          entry.locationId === location._id.toString()
      ),
    [commodity, batch, location, selectedEntries]
  );

  const [status, color] = useMemo(
    () => getBatchLocationStatusIndicationText(batch, location),
    [batch, location, reservation]
  );

  const handleCheckbox = useCallback(
    () =>
      dispatch({
        type: WarehouseActionType.SELECT_COMMODITY_ENTRY,
        payload: {
          type: SelectedCommodityEntryType.BATCH_LOCATION,
          commodityId: commodity._id.toString(),
          batchId: batch._id.toString(),
          locationId: location._id.toString(),
          numberOfChildren: totalNumberOfLocations
        }
      }),
    [commodity, batch, location, totalNumberOfLocations]
  );

  const handleSelectBatch = useCallback(() => {
    dispatch({ type: WarehouseActionType.SELECT_BATCH, payload: { batch } });
  }, [batch]);

  return (
    <tr
      className={"kt-datatable__row d-table-row nopadding" + (query && query === batch.lot ? " animate-row" : "")}
      onClick={handleSelectBatch}
    >
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <i className={"flaticon-add-label-button icon-md " + color} />
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <input type="checkbox" className="ml-0 kt-checkbox--solid" checked={checked} onChange={handleCheckbox} />
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell pr-0">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black kt-font-bold">
              <span className="text-break">{formatNumValueLocale(location.amountAtLocation)}</span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2 ">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black font-weight-300">
              <span className="d-inline-block text-ellipsis align-middle" style={{ maxWidth: "110px" }}>
                {batch.sender.name}
              </span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell pr-0">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black font-weight-300">
              <span>{batch.lot}</span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <WarehouseInformation location={location} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <StorageSpaceInformation batch={batch} location={location} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black font-weight-300">
              <span className="text-break">{getPackagingUnitAmount(location.packagingUnits)}</span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <BBDInformation batch={batch} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <StatusContextMenu status={status} batchId={batch._id.toString()} locationId={location._id.toString()} />
      </td>
    </tr>
  );
};

interface DefaultCommodityReservationTableProps {
  commodity: CommodityWithBatches;
}

const DefaultCommodityReservationsTable: React.FC<DefaultCommodityReservationTableProps> = ({ commodity }) => {
  const headerDefinition = useMemo(
    () => [
      { title: <i className="fas fa-lock text-muted" style={{ right: 0 }} />, size: 1 },
      { title: "#", size: 1 },
      { title: i18n.t("warehouse:amount"), size: 11, className: "pr-0" },
      { title: i18n.t("warehouse:reservation"), size: 19 },
      { title: i18n.t("common:order"), size: 18 },
      { title: i18n.t("warehouse:location"), size: 20 },
      { title: i18n.t("warehouse:createdOn"), size: 20 },
      { title: i18n.t("warehouse:status"), size: 10 }
    ],
    []
  );

  return (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive p-2 bg-light mb-0">
      <table className="kt-datatable__table d-table p-5 bg-light">
        <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
          <tr className="kt-datatable__row d-table-row">
            {headerDefinition.map((def, idx) => (
              <th
                key={idx}
                className={"kt-datatable__cell d-table-cell " + (def.className || "")}
                style={{ width: `${def.size}%` }}
              >
                <span>{def.title}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
          {commodity.reservations.map(r => (
            <DefaultCommodityReservationRow key={r._id.toString()} commodity={commodity} reservation={r} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface DefaultCommodityReservationRowProps {
  commodity: CommodityWithBatches;
  reservation: Reservation;
}

const DefaultCommodityReservationRow: React.FC<DefaultCommodityReservationRowProps> = ({ commodity, reservation }) => {
  const reservedMaterial = useMemo(
    () => reservation.materials.find(m => m.material.details._id.toString() === commodity._id.toString()),
    [reservation, commodity]
  );
  if (!reservedMaterial) return null;

  return (
    <tr className="kt-datatable__row d-table-row nopadding">
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <i className={"flaticon-add-label-button icon-md text-warning"} />
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <input
              type="checkbox"
              className="ml-0 kt-checkbox--solid kt-checkbox--disabled not-allowed-cursor"
              disabled={true}
            />
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell pr-0">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black kt-font-bold">
              <span className="text-break">{formatNumValueLocale(reservedMaterial?.reservedAmount)}</span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2 ">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black font-weight-300">
              <span className="d-inline-block text-ellipsis align-middle" style={{ maxWidth: "110px" }}>
                RV-{reservation.identifier}
              </span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black font-weight-300">
              <span>AT-{reservation.order.identifier}</span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black font-weight-300">
              <span>{resolveTranslation(reservedMaterial.warehouseSnapshot.warehouseName)}</span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className={"kt-user-card-v2__name font-weight-300 "}>
              <span className="text-break">{baseUtils.formatDate(reservation.created)}</span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <ReservationStatusContextMenu
          reservationId={reservation._id.toString()}
          materialId={commodity._id.toString()}
          locationId={reservedMaterial.warehouseSnapshot._id.toString()}
        />
      </td>
    </tr>
  );
};

export default DefaultCommodityWithBatchesRow;
