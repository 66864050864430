import React from "react";
import { BSON } from "realm-web";
import { InvoicePosition } from "../../CustomTypes";

interface PositionRowProps {
  position: InvoicePosition;
  index: number;
  disable: boolean;
  reverseCharge: boolean;
  onChange: (id: BSON.ObjectId) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onRemove: (id: BSON.ObjectId) => void;
  disableInput?: boolean;
}

const PositionRow: React.FunctionComponent<PositionRowProps> = React.memo(
  ({ position, index, disable, disableInput, reverseCharge, onChange, onRemove }) => {
    return (
      <tr className="kt-datatable__row" style={{ backgroundColor: "#fafafa" }}>
        <td className=" kt-datatable__cell align-middle">{index + 1}</td>
        <td className="kt-datatable__cell">
          <input
            type="text"
            className="form-control"
            value={position.description}
            disabled={disableInput}
            name="description"
            placeholder="Description"
            onChange={onChange(position.id)}
          />
        </td>
        <td className="kt-datatable__cell">
          <input
            type="number"
            className="form-control"
            value={position.amount}
            disabled={disableInput}
            name="amount"
            min={0}
            onChange={onChange(position.id)}
          />
        </td>
        <td className="kt-datatable__cell">
          <select
            className="form-control bootstrap-select"
            value={position.unit}
            disabled={disableInput}
            name={"unit"}
            onChange={onChange(position.id)}
          >
            <option value="bottle">Bottles</option>
            <option value="bag">Bags</option>
            <option value="blister">Blister</option>
            <option value="capsule">Capsules</option>
            <option value="item">Items</option> <option value="kg">KG</option>
            <option value="hour">Hours</option>
            <option value="flatrate">Flatrate</option>
          </select>
        </td>
        <td className="kt-datatable__cell">
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              value={position.price}
              disabled={position.type === "position" || disableInput}
              name={"price"}
              onChange={onChange(position.id)}
            />
          </div>
        </td>
        <td className="kt-datatable__cell">
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              value={position.vat}
              disabled={disableInput || reverseCharge}
              name="vat"
              min={0}
              onChange={disableInput || reverseCharge ? undefined : onChange(position.id)}
            />
          </div>
        </td>
        <td className="kt-datatable__cell">
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              value={position.discount}
              disabled={disableInput}
              name="discount"
              min={0}
              onChange={onChange(position.id)}
            />
          </div>
        </td>
        <td className="kt-datatable__cell">
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              style={{ backgroundColor: "#fafafa" }}
              value={Math.round((+position.total - (+position.discount / 100) * +position.total) * 100) / 100}
              disabled
            />
          </div>
        </td>
        <td className="kt-datatable__cell">
          <button
            onClick={() => onRemove(position.id)}
            className={"btn btn-sm btn-clean btn-icon btn-icon-sm " + (disable ? "disabled" : "")}
            title="Delete"
            disabled={disable}
          >
            <i className="flaticon2-delete" />
          </button>
        </td>
      </tr>
    );
  }
);

export default PositionRow;
