import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { ShippingGroupType } from "../../CustomTypes";

interface ShippingGroupProps {
  shippingGroup?: ShippingGroupType;
  shippingGroups: Array<ShippingGroupType>;
  onAddShippingGroup: (sg: ShippingGroupType) => void;
  onRemoveShippingGroup: () => void;
  onChangeShippingGroup: (e: any) => void;
  disabled?: boolean;
}

interface ShippingGroupState {}

class ShippingGroup extends PureComponent<ShippingGroupProps, ShippingGroupState> {
  render() {
    const {
      shippingGroup,
      shippingGroups,
      onAddShippingGroup,
      onRemoveShippingGroup,
      onChangeShippingGroup,
      disabled
    } = this.props;
    let placeholder = false;
    let sg = shippingGroup;
    if (!shippingGroup) {
      sg = {
        id: new BSON.ObjectId(),
        number: shippingGroups.length + 1,
        boxes: "0",
        items: "0",
        weight: "0"
      };
      placeholder = true;
    }
    sg = sg as ShippingGroupType;
    return (
      <div
        className={"form-group mb-1 row " + (placeholder ? "pointer pointer-all" : "")}
        style={placeholder ? { opacity: "0.25" } : {}}
        onClick={placeholder ? () => onAddShippingGroup(sg!) : undefined}
      >
        <label className="col-3 col-form-label kt-font-dark kt-font-bold">
          Ship. group #{sg.number}
          <br />
          {!placeholder && !disabled && sg.number !== 1 && (
            <u className="pointer text-hover-dark" style={{ opacity: "0.3" }} onClick={() => onRemoveShippingGroup()}>
              remove
            </u>
          )}
        </label>
        <div className="col-3">
          <input
            className="form-control"
            type="number"
            name="boxes"
            value={placeholder ? "" : sg.boxes}
            onChange={disabled ? undefined : onChangeShippingGroup}
            tabIndex={placeholder ? -1 : undefined}
            readOnly={placeholder}
            disabled={disabled}
            onFocus={placeholder ? e => e.target.blur() : undefined}
          />
          <span className="form-text text-muted">Amount of boxes</span>
        </div>
        <div className="col-3">
          <input
            className="form-control"
            type="number"
            name="items"
            value={placeholder ? "" : sg.items}
            onChange={disabled ? undefined : onChangeShippingGroup}
            tabIndex={placeholder ? -1 : undefined}
            readOnly={placeholder}
            disabled={disabled}
            onFocus={placeholder ? e => e.target.blur() : undefined}
          />
          <span className="form-text text-muted">Items per box</span>
        </div>
        <div className="col-3">
          <div className="input-group">
            <input
              className="form-control"
              type="number"
              name="weight"
              value={placeholder ? "" : sg.weight}
              onChange={disabled ? undefined : onChangeShippingGroup}
              tabIndex={placeholder ? -1 : undefined}
              readOnly={placeholder}
              disabled={disabled}
              onFocus={placeholder ? e => e.target.blur() : undefined}
            />
            <div className="input-group-append">
              <span className="input-group-text">kg</span>
            </div>
          </div>
          <span className="form-text text-muted">Weight per box</span>
        </div>
      </div>
    );
  }
}

export default ShippingGroup;
