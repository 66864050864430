import { RouteComponentProps } from "react-router-dom";
import React, { useContext } from "react";
import MinimumDeliveryCalendar from "./MinimumDeliveryCalendar";
import { DeliveryCalendarUserContext } from "../../../../context/deliveryCalendarUserContext";

interface MinimumDeliveryCalendarWrapperProps extends RouteComponentProps {}

const MinimumDeliveryCalendarWrapper: React.FunctionComponent<MinimumDeliveryCalendarWrapperProps> = props => {
  const context = useContext(DeliveryCalendarUserContext);
  return <MinimumDeliveryCalendar {...props} context={context} />;
};

export default MinimumDeliveryCalendarWrapper;
