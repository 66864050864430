import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { CommoditiesDocument } from "../../model/commodities.types";
import { RawbidsCommodityComparison } from "./RawbidsCommodityComparison";
import { DataContext } from "../../context/dataContext";
import dbService, { COMMODITIES, UpdateAction } from "../../services/dbService";
import toastUtils from "../../utils/toastUtils";
import SimpleConfirmationModal from "../common/SimpleConfirmationModal";
import { fetchRawbidsCommodity } from "../../utils/rawbidsUtils";

interface RawbidsSettingsProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
}

const RawbidsSettings: React.FunctionComponent<RawbidsSettingsProps> = ({ commodity, context }) => {
  const [saving, setSaving] = useState<false | "update" | "disconnect">(false);

  const handleDisconnect = useCallback(async () => {
    if (!commodity.rawbidsData?.commoditySnapshot) {
      toast.success("Already disconnected");
      return;
    }
    setSaving("disconnect");
    try {
      const action: UpdateAction = {
        collection: COMMODITIES,
        filter: { _id: commodity._id },
        update: { rawbidsData: null }
      };
      const res = await dbService.transaction([action]);
      toastUtils.databaseOperationToast(res, "Successfully updated commodity", "Commodity could not be updated", () => {
        context.updateDocumentInContext(COMMODITIES, commodity._id);
      });
    } finally {
      setSaving(false);
    }
  }, [commodity]);

  const handleUpdateSnapshot = useCallback(async () => {
    if (!commodity.rawbidsData?.commoditySnapshot) return;
    setSaving("update");
    try {
      const result = await fetchRawbidsCommodity(commodity.rawbidsData.rawbidsId, context, true);
      if (!result) {
        toast.error("Error fetching commodity information. Please try again later");
        return;
      }
      const action: UpdateAction = {
        collection: COMMODITIES,
        filter: { _id: commodity._id },
        update: { "rawbidsData.commoditySnapshot": result, "rawbidsData.lastUpdate": new Date() }
      };
      const res = await dbService.transaction([action]);
      toastUtils.databaseOperationToast(res, "Successfully updated commodity", "Commodity could not be updated", () => {
        context.updateDocumentInContext(COMMODITIES, commodity._id);
      });
    } finally {
      setSaving(false);
    }
  }, [commodity]);

  return (
    <>
      <div className="kt-form__body">
        <div className="kt-section kt-section--first">
          <div className="kt-section__body">
            {commodity.rawbidsData?.commoditySnapshot && (
              <RawbidsCommodityComparison
                commodity={commodity}
                rawbidsCommodity={commodity.rawbidsData?.commoditySnapshot}
                context={context}
              />
            )}
          </div>
        </div>
      </div>
      <div className="kt-separator kt-separator--space-lg kt-separator--fit kt-separator--border-solid" />
      <div className="float-right">
        <SimpleConfirmationModal.SimpleConfirmationModalButton
          buttonClasses={"btn btn-upper btn-dark bg-rawbids mr-2 " + (saving && "disabled")}
          buttonText={
            <>
              <img
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: 0,
                  objectFit: "cover"
                }}
                alt="R"
                src={"https://app.rawbids.com/rawbidsFavicon.ico"}
                className="country-icon mr-1"
              />
              Disconnect
            </>
          }
          modalTitle={"Disconnect Rawbids Commodity"}
          modalDescription={
            <div className="py-4 text-dark">
              Are you sure you want to disconnect the rawbids commodity{" "}
              <em>{commodity.rawbidsData?.commoditySnapshot.title.en}</em> -{" "}
              <em>{commodity.rawbidsData?.commoditySnapshot.subtitle.en}</em>?
            </div>
          }
          confirmButtonText={"Disconnect"}
          cancelButtonText={"Close"}
          onConfirm={saving ? undefined : handleDisconnect}
          saving={saving === "disconnect"}
          buttonId="disconnectRawbids"
        />
        <button
          type="button"
          className={"btn btn-upper btn-dark bg-rawbids " + (saving && "disabled")}
          onClick={saving ? undefined : handleUpdateSnapshot}
        >
          {saving === "update" && (
            <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
              <svg className="button-splash-spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
              </svg>
            </div>
          )}
          <img
            style={{
              width: "12px",
              height: "12px",
              borderRadius: 0,
              objectFit: "cover"
            }}
            alt="R"
            src={"https://app.rawbids.com/rawbidsFavicon.ico"}
            className="country-icon mr-1"
          />
          Update Information
        </button>
      </div>
    </>
  );
};

export default RawbidsSettings;
