import Select from "react-select";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Content, ContentType } from "../../../../model/warehouse/common.types";
import { Batch } from "../../../../model/warehouse/batch.types";
import { useDataContext } from "../../../../context/dataContext";
import i18n from "../../../../translations/i18n";
import { resolveTranslation } from "../../../../utils/translationUtils";
import { formatNumValue, getNumericValue } from "../../../../utils/baseUtils";
import {
  SelectedBatchEntry,
  SelectedCommodityEntry,
  SelectedDeliveryAnnouncementEntry,
  useWarehouseContext
} from "../../../../context/warehouseContext";
import { SelectOption } from "../../../../model/common.types";
import { getBatchIdFromSelectedEntries } from "../../../../utils/warehouseActionUtils";

interface ProductTypeOption {
  value: ContentType;
  label: string;
}

export interface BookOutSettings {
  batch: Batch;
  amount: number;
}

interface OnlyBookOutViewProps {
  bookOutSettings: BookOutSettings | null;
  onChangeBookOutSettings: (batch?: Batch, amount?: number) => void;
}

/**
 * Get default state values
 * @param batch list of batches
 * @param bookOutSettings book out settings for this component
 * @param selectedEntries list of selected entries from warehouse context
 * @returns {[productType: ProductTypeOption, product: SelectOption<Content> | null, selectedBatch: SelectOption<Batch> | null]} triple with state default values
 */
const getDefaultStateValues = (
  batch: Array<Batch>,
  bookOutSettings: BookOutSettings | null,
  selectedEntries: Array<SelectedBatchEntry | SelectedCommodityEntry | SelectedDeliveryAnnouncementEntry>
): [
  productType: ProductTypeOption,
  product: SelectOption<Content> | null,
  selectedBatch: SelectOption<Batch> | null,
  preselected: boolean
] => {
  let preselectedBatch: Batch | undefined;
  if (selectedEntries.length > 0)
    preselectedBatch = batch.find(
      b => b._id.toString() === getBatchIdFromSelectedEntries(selectedEntries) && b.totalAmount.value > 0
    );

  let productType = {
    value: ContentType.COMMODITY,
    label: i18n.t("warehouse:commodity")
  };
  if (bookOutSettings)
    productType = {
      value: bookOutSettings.batch.content.type,
      label: i18n.t(`warehouse:${bookOutSettings.batch.content.type}`)
    };
  else if (preselectedBatch)
    productType = { value: preselectedBatch.content.type, label: i18n.t(`warehouse:${preselectedBatch.content.type}`) };

  let product = null;
  if (bookOutSettings)
    product = {
      value: bookOutSettings.batch.content.details._id.toString(),
      label: resolveTranslation(bookOutSettings.batch.content.details.title),
      data: bookOutSettings.batch.content
    };
  else if (preselectedBatch)
    product = {
      value: preselectedBatch.content.details._id.toString(),
      label: resolveTranslation(preselectedBatch.content.details.title),
      data: preselectedBatch.content
    };

  let selectedBatch = null;
  if (bookOutSettings)
    selectedBatch = {
      value: bookOutSettings.batch._id.toString(),
      label: `${bookOutSettings.batch.lot} - ${formatNumValue(bookOutSettings.batch.totalAmount, 2)}`,
      data: bookOutSettings.batch
    };
  else if (preselectedBatch)
    selectedBatch = {
      value: preselectedBatch._id.toString(),
      label: `${preselectedBatch.lot} - ${formatNumValue(preselectedBatch.totalAmount, 2)}`,
      data: preselectedBatch
    };

  return [productType, product, selectedBatch, !!preselectedBatch];
};

const OnlyBookOutView: React.FC<OnlyBookOutViewProps> = ({ bookOutSettings, onChangeBookOutSettings }) => {
  const dataContext = useDataContext();
  const warehouseContext = useWarehouseContext();
  const { batch } = dataContext;
  const { selectedEntries } = warehouseContext;

  const [defaultProductType, defaultProduct, defaultSelectedBatch, preselected] = useMemo(
    () => getDefaultStateValues(batch, bookOutSettings, selectedEntries),
    []
  );

  const [productType, setProductType] = useState<ProductTypeOption>(defaultProductType);
  const [product, setProduct] = useState<SelectOption<Content> | null>(defaultProduct);
  const [selectedBatch, setSelectedBatch] = useState<SelectOption<Batch> | null>(defaultSelectedBatch);
  const [amount, setAmount] = useState<string>(bookOutSettings?.amount.toString() || "0");

  // update parent state
  useEffect(() => {
    if (selectedBatch && isFinite(+amount)) {
      onChangeBookOutSettings(selectedBatch.data, +amount);
    }
  }, [selectedBatch, amount]);

  const productTypeOptions = useMemo(
    () => Object.values(ContentType).map(c => ({ value: c, label: i18n.t(`warehouse:${c}`) })),
    []
  );

  const productOptions = useMemo(() => {
    const options: Array<SelectOption<Content>> = [];
    batch.forEach(b => {
      if (
        !b.blocked &&
        b.content.type === productType.value &&
        !options.some(o => o.value === b.content.details._id.toString()) &&
        b.totalAmount.value > 0
      ) {
        options.push({
          value: b.content.details._id.toString(),
          label: resolveTranslation(b.content.details.title),
          data: b.content
        });
      }
    });
    return options;
  }, [batch, productType]);

  const batchOptions = useMemo(() => {
    const options: Array<SelectOption<Batch>> = [];
    if (!product) return options;
    batch.forEach(b => {
      if (!b.blocked && b.content.details._id.toString() === product.value) {
        options.push({
          value: b._id.toString(),
          label: `${b.lot} - ${formatNumValue(b.totalAmount, 2)}`,
          data: b
        });
      }
    });
    return options;
  }, [batch, product]);

  const handleChangeProductType = useCallback((e: any) => {
    setProductType(e);
    setProduct(null);
    setSelectedBatch(null);
  }, []);
  const handleChangeProduct = useCallback((e: any) => {
    setProduct(e);
    setSelectedBatch(null);
  }, []);
  const handleChangeBatch = useCallback((e: any) => setSelectedBatch(e), []);
  const handleChangeAmount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setAmount(getNumericValue(e) || "0"),
    []
  );

  return (
    <>
      <span className=" font-size-lg text-black d-block mt-3 mb-2" style={{ fontWeight: 500 }}>
        {i18n.t("warehouse:bookOutSelection")}
      </span>
      <hr className="w-100" />
      <div className="row mb-2">
        <div className="col-4">
          <span className="  text-black d-block mt-2" style={{ fontWeight: 500 }}>
            {i18n.t("warehouse:productType")}
          </span>
        </div>
        <div className="col-8">
          <div className={preselected ? "not-allowed-cursor" : ""}>
            <Select
              className="select-warehouse"
              classNamePrefix="select-warehouse"
              onChange={handleChangeProductType}
              value={productType}
              options={productTypeOptions}
              isDisabled={preselected}
            />
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          <span className="  text-black d-block mt-2" style={{ fontWeight: 500 }}>
            {i18n.t(`warehouse:${productType.value}`)}
          </span>
        </div>
        <div className="col-8">
          <div className={preselected ? "not-allowed-cursor" : ""}>
            <Select
              className="select-warehouse"
              classNamePrefix="select-warehouse"
              onChange={handleChangeProduct}
              value={product}
              options={productOptions}
              isDisabled={preselected}
            />
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          <span className="  text-black d-block mt-2" style={{ fontWeight: 500 }}>
            {i18n.t("warehouse:batch")}
          </span>
        </div>
        <div className="col-8">
          <div className={!product || preselected ? "not-allowed-cursor" : ""}>
            <Select
              className="select-warehouse"
              classNamePrefix="select-warehouse"
              isDisabled={!product || preselected}
              onChange={handleChangeBatch}
              value={selectedBatch}
              options={batchOptions}
            />
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          <span className="text-black d-block mt-2" style={{ fontWeight: 500 }}>
            {i18n.t("warehouse:amount")}
          </span>
        </div>
        <div className="col-8">
          <div className="input-group">
            <input
              type={"number"}
              min={0}
              className={
                "form-control form-control-solid hide-arrows " +
                ((+amount > (selectedBatch?.data.totalAmount.value || Infinity) || amount === "0") && "is-invalid")
              }
              value={amount}
              onChange={handleChangeAmount}
            />
            <div className="input-group-append">
              <span className="input-group-text input-group-solid">
                {selectedBatch?.data.totalAmount.unit || i18n.t("warehouse:units")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlyBookOutView;
