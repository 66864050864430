import dbService, { CONFIGURATION } from "../services/dbService";
import { BaseConfiguration, ConfigurationKeys } from "../model/configuration/configuration.types";

/**
 * Load a configuration document
 * @param key the configuration key
 * @returns {Promise <T | undefined>} the configuration document or undefined if not found
 */
export const getConfiguration = async <T extends BaseConfiguration>(key: ConfigurationKeys): Promise<T | undefined> => {
  return dbService.getDb()?.collection(CONFIGURATION).findOne({ key });
};

/**
 * Load configurations relevant for the context
 * @returns {Promise<Array<BaseConfiguration>>}
 */
export const loadContextConfigurations = async (): Promise<Array<BaseConfiguration>> => {
  return dbService.getFilteredCollection(CONFIGURATION, { key: { $in: [ConfigurationKeys.NOTIFICATION] } });
};

/**
 * Get a configuration from a collection
 * @param key the configuration key
 * @param collection configuration collection
 * @returns {Promise <T | undefined>} the configuration document or undefined if not found
 */
export const getConfigurationFromCollection = <T extends BaseConfiguration>(
  key: ConfigurationKeys,
  collection: Array<BaseConfiguration>
): T | undefined => {
  return collection.find(c => c.key === key) as T | undefined;
};
