import React from "react";
import { BSON } from "realm-web";
import { OrdersDocument } from "../../model/orders.types";
import { ProductionWeek, ProductionWeekDocument } from "../../model/productionPlan.types";
import { T_CAPSULE, T_CUSTOM, T_LIQUID, T_POWDER, T_SERVICE, T_SOFTGEL, T_TABLET } from "../order/OrderHelper";
import baseUtils from "../../utils/baseUtils";
import { DataContext } from "../../context/dataContext";
import orderUtils, { ARCHIVE, CREATEINVOICE, DECLINED, FULFILLMENT } from "../../utils/orderUtils";
import { T_FULFILLMENT } from "../../utils/timelineUtils";
import dashboardUtils from "../../utils/dashboardUtils";
import { I_CANCELED } from "../../utils/invoiceUtils";

export interface ExtendedProductionWeek extends ProductionWeek {
  _id?: BSON.ObjectId;
  reserved: number;
  typeString: string;
}

/**
 * Get information about all planned orders of a week
 * @param orderIds list of order ids
 * @param orders orders for the current location
 * @param context the data context
 * @param machine optional parameter to filter additionally for machine
 * @param manufacturer optional manufacturer to filter for
 * @returns {[reservedCapacity: number, typeString: string]} tuple with reserved capacity and string including all planned types
 */
const getPlannedOrdersInformation = (
  orderIds: Array<BSON.ObjectId>,
  orders: Array<OrdersDocument>,
  context: React.ContextType<typeof DataContext>,
  machine?: BSON.ObjectId,
  manufacturer?: BSON.ObjectId | string
): [number, string] => {
  let reservedCapacity = 0;
  let types: Array<string> = [];
  let capsuleString = "";
  let tabletString = "";
  for (let i = 0; i < orderIds.length; i++) {
    const order: OrdersDocument | null = baseUtils.getDocFromCollection(orders, orderIds[i]);
    // filter for relevant manufacturer
    if (
      order &&
      (!machine ||
        (machine &&
          order.settings.productionMachine &&
          order.settings.productionMachine.toString() === machine.toString())) &&
      (!manufacturer || order.settings.manufacturer.toString() === manufacturer.toString())
    ) {
      reservedCapacity += +order.calculations[0].units;
      const type = getOrderType(order, context);
      if (type && !types.includes(type)) {
        if (type.includes("Capsule")) {
          if (capsuleString.length === 0) capsuleString += type;
          else {
            const subString = type.slice(13);
            const split = capsuleString.replaceAll(",", "").split(" ");
            if (!split.some(s => s === subString)) capsuleString += ", " + type.slice(13);
          }
        } else if (type.includes("Tablet")) {
          if (tabletString.length === 0) tabletString += type;
          else {
            const subString = type.slice(7);
            const split = tabletString.replaceAll(",", "").split(" ");
            if (!split.some(s => s === subString)) tabletString += ", " + type.slice(7);
          }
        } else types.push(type);
      }
    }
  }
  let typeString = capsuleString;
  if (tabletString) typeString += (!!typeString.trim() ? " & " : "") + tabletString;
  if (types.length > 0) typeString += (!!typeString.trim() ? " & " : "") + types.join(" & ");
  return [reservedCapacity, typeString ? typeString : "Empty"];
};

/**
 * Get the order type
 * @param order an order document
 * @param context the data context
 * @returns {string} description of the order type including capsule size
 */
const getOrderType = (order: OrdersDocument, context: React.ContextType<typeof DataContext>) => {
  const { capsules, tablets } = context;
  switch (order.settings.type) {
    case T_POWDER:
      return "Powder";
    case T_LIQUID:
      return "Liquid";
    case T_CAPSULE:
      const capsule = baseUtils.getDocFromCollection(capsules, order.settings.id)!;
      return `Capsule Size ${capsule.capsule_size}`;
    case T_TABLET:
      const tablet = baseUtils.getDocFromCollection(tablets, order.settings.id)!;
      return `Tablet ${tablet.volume}ml, ${tablet.shape}`;
    case T_CUSTOM:
      return "Custom";
    case T_SOFTGEL:
      return "Softgel";
    default:
      return "Unknown";
  }
};

/**
 * Get the calculated capacity of the last 6 weeks
 * @param orders list of order documents
 * @param manufacturer id of manufacturer
 * @returns {number} calculated capacity of last 6 weeks
 */
const getCalculatedCapacity = (orders: Array<OrdersDocument>, manufacturer?: BSON.ObjectId | string) => {
  const periods = []; // last 6 weeks
  for (let i = 0; i < 6; i++) {
    const d = new Date(Date.now() - (i + 1) * 7 * 24 * 60 * 60 * 1000);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    const monday = new Date(d.setDate(diff));
    monday.setHours(0, 0, 0, 0);
    const sunday = new Date(monday.getTime() + 7 * 24 * 60 * 60 * 1000 - 1);
    periods.push({
      monday: monday,
      sunday: sunday,
      invoiced: 0
    });
  }
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    const hasFfInfo = orderUtils.hasFulfillmentPriceInfo(order);
    if (!manufacturer || order.settings.manufacturer.toString() === manufacturer.toString()) {
      if (
        orderUtils.isOrder(order) &&
        order.settings.type !== T_SERVICE &&
        order.state !== DECLINED &&
        order.invoices
      ) {
        for (let j = 0; j < periods.length; j++) {
          const period = periods[j];
          const sundayTime = period.sunday.getTime();
          const mondayTime = period.monday.getTime();
          const fulfillmentEntry = order.timeline.find(t => t.type === T_FULFILLMENT);
          if (
            hasFfInfo &&
            fulfillmentEntry.date.getTime() >= mondayTime &&
            fulfillmentEntry.date.getTime() <= sundayTime
          )
            period.invoiced += order.fulfillment?.totalUnits!;
          for (let k = 0; k < order.invoices.length; k++) {
            const invoice = order.invoices[k];
            if (invoice.invoiceDate.getTime() <= sundayTime && invoice.invoiceDate.getTime() >= mondayTime) {
              if (invoice.state !== I_CANCELED)
                period.invoiced += invoice.positions.reduce(
                  (a: number, b: any) => a + (b.type === "position" ? +b.amount : 0),
                  0
                );
            }
          }
        }
      }
    }
  }
  return Math.round(dashboardUtils.getEMA(periods));
};

/**
 * Get produced units for a week and manufacturer if given
 * @param orders list of all orders
 * @param week the week to get the produced units for
 * @param manufacturer optional manufacturer to filter for
 * @returns {number} the amount of produced units in this week and for the given manufacturer
 */
function getProducedUnitsForWeek(
  orders: Array<OrdersDocument>,
  week: ProductionWeekDocument,
  manufacturer?: BSON.ObjectId | string
) {
  let producedUnits = 0;
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    if (
      !order.productionWeek ||
      ![CREATEINVOICE, FULFILLMENT, ARCHIVE].includes(order.state) ||
      (manufacturer && order.settings.manufacturer.toString() !== manufacturer.toString())
    )
      continue;
    if (order.productionWeek === week.week) {
      const hasFfInfo = orderUtils.hasFulfillmentPriceInfo(order);
      producedUnits += hasFfInfo ? +order.fulfillment!.totalUnits! : +order.calculations[0].units;
    }
  }
  return producedUnits;
}

export default { getOrderType, getPlannedOrdersInformation, getCalculatedCapacity, getProducedUnitsForWeek };
