import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import accessUtils, { CREATELOCATIONS, EDITLOCATIONS } from "../../../utils/accessUtils";
import dbService, { COMMODITYCATEGORIES } from "../../../services/dbService";
import { ID_OPTIONS, INTERNALCODE_OPTIONS } from "../../../utils/commodityUtils";
import toastUtils from "../../../utils/toastUtils";
import { CommoditycategoriesDocument } from "../../../model/commoditycategories.types";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import baseUtils from "../../../utils/baseUtils";

interface CreateNewCommodityCategoryModalProps {
  commodityCategory?: CommoditycategoriesDocument;
}

interface CreateNewCommodityCategoryModalState {
  de: string;
  en: string;
  show: boolean;
  saving: boolean;
  codeReference?: { value: string; label: string };
  identifierReference?: { value: string; label: string };
}

class CreateNewCommodityCategoryModal extends PureComponent<
  CreateNewCommodityCategoryModalProps,
  CreateNewCommodityCategoryModalState
> {
  constructor(props: CreateNewCommodityCategoryModalProps) {
    super(props);
    this.state = {
      de: props.commodityCategory?.name.de || "",
      en: props.commodityCategory?.name.en || "",
      codeReference: props.commodityCategory
        ? INTERNALCODE_OPTIONS.find(o => o.value === props.commodityCategory?.internal_code_reference)
        : INTERNALCODE_OPTIONS[0],
      identifierReference: props.commodityCategory
        ? ID_OPTIONS.find(o => o.value === props.commodityCategory?.identifierReference)
        : ID_OPTIONS[0],
      show: false,
      saving: false
    };
  }

  componentDidUpdate(prevProps: Readonly<CreateNewCommodityCategoryModalProps>) {
    const { commodityCategory } = this.props;
    if (!_.isEqual(prevProps.commodityCategory, commodityCategory)) {
      this.setState({
        de: commodityCategory?.name.de || "",
        en: commodityCategory?.name.en || "",
        codeReference: commodityCategory
          ? INTERNALCODE_OPTIONS.find(o => o.value === commodityCategory?.internal_code_reference)
          : INTERNALCODE_OPTIONS[0],
        identifierReference: commodityCategory
          ? ID_OPTIONS.find(o => o.value === commodityCategory?.identifierReference)
          : ID_OPTIONS[0],
        saving: false
      });
    }
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });

  /**
   * Handles creating a new commodity category.
   */
  handleCreateCommodityCategory = async () => {
    const { updateDocumentInContext } = this.context;
    const { de, en, codeReference, identifierReference } = this.state;
    if (!codeReference || !identifierReference) return;
    this.setState({ saving: true });
    const deUpdated = baseUtils.capitalizeAllWords(de.trim());
    const enUpdated = baseUtils.capitalizeAllWords(en.trim());

    const category = {
      _id: new BSON.ObjectId(),
      name: { de: deUpdated, en: enUpdated },
      internal_code_reference: codeReference.value,
      identifierReference: identifierReference.value
    };
    try {
      const res = await dbService.insertDocument(COMMODITYCATEGORIES, category);
      await toastUtils.databaseOperationToast(
        !!res && res.insertedId,
        "Commodity category successfully added",
        "Adding commodity category failed",
        () => updateDocumentInContext(COMMODITYCATEGORIES, res?.insertedId)
      );
    } finally {
      this.setState({ saving: false });
    }
  };

  /**
   * Handles update a commodity category.
   */
  handleUpdateCommodityCategory = async () => {
    const { commodityCategory } = this.props;
    if (!commodityCategory) return;
    const { updateDocumentInContext } = this.context;
    const { de, en } = this.state;
    this.setState({ saving: true });
    const deUpdated = baseUtils.capitalizeAllWords(de.trim());
    const enUpdated = baseUtils.capitalizeAllWords(en.trim());
    const update = {
      name: { de: deUpdated, en: enUpdated }
    };
    try {
      const res = await dbService.updateDocument(COMMODITYCATEGORIES, commodityCategory._id, update);
      await toastUtils.databaseOperationToast(
        !!res && res.modifiedCount > 0,
        "Commodity category successfully added",
        "Adding commodity category failed",
        () => updateDocumentInContext(COMMODITYCATEGORIES, commodityCategory._id)
      );
    } finally {
      this.setState({ saving: false });
    }
  };

  /**
   * Check the input fields of the names for missing data
   * @returns { Array<string> } Errors found
   */
  checkForErrors = (): Array<string> => {
    const { de, en } = this.state;
    const errors = [];
    if (!de) errors.push("German name required");
    if (!en) errors.push("English name required");
    return errors;
  };

  render() {
    const { commodityCategory } = this.props;
    const { de, en, show, saving, codeReference, identifierReference } = this.state;
    const errors = this.checkForErrors();
    const edit = !!commodityCategory;
    const canClickButton = edit
      ? accessUtils.canEditData(EDITLOCATIONS.GENERALSETTINGS)
      : accessUtils.canCreateData(CREATELOCATIONS.GENERALSETTINGS);

    return (
      <>
        <button
          className={"btn " + (edit ? "btn-secondary" : "btn-primary") + (canClickButton ? "" : " disabled")}
          onClick={canClickButton ? this.handleShow : undefined}
          disabled={!canClickButton}
        >
          <i className={edit ? "fa fa-pen" : " fas fa-plus-circle"} />
          {edit ? "Edit" : "Create"}
        </button>
        <Modal show={show} centered onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{edit ? "Edit" : "Create new"} Commodity Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row my-2">
              <div className="col-4 text-dark align-self-center">German</div>
              <div className="col-8">
                <input className="form-control" value={de} onChange={e => this.setState({ de: e.target.value })} />
              </div>
            </div>
            <div className="row my-2">
              <div className="col-4 text-dark align-self-center">English</div>
              <div className="col-8">
                <input className="form-control" value={en} onChange={e => this.setState({ en: e.target.value })} />
              </div>
            </div>
            <div className="row my-2">
              <div className="col-4 text-dark align-self-center">Internal Code Range</div>
              <div className="col-8">
                <Select
                  className="select-default"
                  options={INTERNALCODE_OPTIONS}
                  value={codeReference}
                  isDisabled={edit}
                  onChange={(value: any) => this.setState({ codeReference: value.value })}
                />
              </div>
            </div>
            <div className="row my-2">
              <div className="col-4 text-dark align-self-center">Identifier Range</div>
              <div className="col-8">
                <Select
                  className="select-default"
                  options={ID_OPTIONS}
                  value={identifierReference}
                  isDisabled={edit}
                  onChange={(value: any) => this.setState({ identifierReference: value.value })}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <ErrorOverlayButton
              buttonText={"Save"}
              className={"btn btn-success"}
              saving={saving}
              errors={errors}
              onClick={edit ? this.handleUpdateCommodityCategory : this.handleCreateCommodityCategory}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateNewCommodityCategoryModal;
