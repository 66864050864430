import _ from "lodash";
import React, { PureComponent } from "react";
import { ActiveSubstance } from "./CustomTypes";
import { ActivesubstancesDocument } from "../../model/activesubstances.types";

interface SelectActiveSubstanceProps {
  selected: Array<ActiveSubstance>;
  activeSubstances: Array<ActivesubstancesDocument>;
  onAdd: () => void;
  onChange: (idx: number, _id: string, nrv: string) => void;
  onDelete: (idx: number) => void;
  disabled?: boolean;
}

interface SelectActiveSubstanceState {
  nrvMap: { [key: string]: string };
}

class SelectActiveSubstance extends PureComponent<SelectActiveSubstanceProps, SelectActiveSubstanceState> {
  constructor(props: SelectActiveSubstanceProps) {
    super(props);
    this.state = { nrvMap: {} };
  }

  componentDidMount() {
    this.buildNRVMap();
  }

  componentDidUpdate(
    prevProps: Readonly<SelectActiveSubstanceProps>,
    prevState: Readonly<SelectActiveSubstanceState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.selected, this.props.selected)) this.buildNRVMap();
  }

  /**
   * Builds the map of all added active substances.
   */
  buildNRVMap = () => {
    const { selected } = this.props;
    let nrvMap: { [key: string]: string } = {};
    for (let as of selected) {
      nrvMap[as._id] = as.nrv;
    }
    this.setState({ nrvMap });
  };

  /**
   * Filters the active substances so that only not already used (and the one that is selected inside the select)
   * are shown.
   * @param _id: ID of the selected active substance
   * @returns { Array<ActivesubstancesDocument> } List of active substances that should be shown inside the select
   */
  filterActiveSubstances = (_id: string) => {
    const { activeSubstances, selected } = this.props;
    const asSel = selected.map(s => s._id.toString());
    return _.sortBy(
      activeSubstances.filter(as => as._id.toString() === _id || !asSel.includes(as._id.toString())),
      item => item.name.en.trim().toLowerCase()
    );
  };

  /**
   * Handles the change of an active substance.
   * @param idx: Index of the active substance
   * @param _id: ID of the active substance
   * @param nrv: NRV for the active substance
   */
  handleNRVChange = (idx: number, _id: string, nrv: string) => {
    const { onChange } = this.props;
    let val = nrv.replaceAll(/^0+/g, "0");
    if (!val.includes(".")) val = Number(val).toString();
    if (!val || Number(val) < 0) return;
    onChange(idx, _id, val);
  };

  /**
   * Handles the blur of an active substance
   * @param idx: Index of the active substance
   * @param _id: ID of the active substance
   * @param nrv: NRV for the active substance
   */
  handleNRVBlur = (idx: number, _id: string, nrv: string) => {
    const { onChange } = this.props;
    const nNrv = Number(nrv);
    if ((!nNrv && nNrv !== 0) || nNrv < 0) return;
    let nrvMap = _.cloneDeep(this.state.nrvMap);
    onChange(idx, _id, nrv);
    nrvMap[_id] = Number(nrv).toString();
    this.setState({ nrvMap });
  };

  render() {
    const { disabled, selected, onAdd, onChange, onDelete } = this.props;
    const { nrvMap } = this.state;
    const addDisabled = selected.some(s => s._id === "") || disabled;

    return (
      <div className="form-group row">
        <div className="col-12">
          {selected.map((s, idx) => (
            <div key={s._id} className="row mb-4">
              <div className={disabled ? "col-12" : "col-9 col-sm-10"}>
                <div className="row">
                  <div className="col-6 col-sm-7">
                    <div className="input-group">
                      <div className="input-group-prepend d-none d-sm-flex">
                        <span className="input-group-text">
                          <i className="la la-edit" />
                        </span>
                      </div>
                      <select
                        className="form-control"
                        value={s._id}
                        onChange={e => onChange(idx, e.target.value, "0")}
                        disabled={disabled}
                      >
                        <option value="" disabled>
                          Please select
                        </option>
                        {this.filterActiveSubstances(s._id).map(fas => (
                          <option key={fas._id.toString()} value={fas._id.toString()}>
                            {fas.name.en}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-6 col-sm-5">
                    <div className="input-group">
                      <div className="input-group-prepend d-none d-sm-flex">
                        <span className="input-group-text">
                          <i className=" la-percent" />
                        </span>
                      </div>
                      <input
                        className={"form-control" + (s._id === "" || disabled ? " disabled" : "")}
                        disabled={s._id === "" || disabled}
                        value={nrvMap[s._id] ? nrvMap[s._id] : s._id === "" ? "" : "0"}
                        type="number"
                        onChange={e => this.handleNRVChange(idx, s._id, e.target.value)}
                        onBlur={e => this.handleNRVBlur(idx, s._id, e.target.value)}
                        placeholder={s._id === "" ? "Please select a substance" : ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {!disabled && (
                <div className="col-3 col-sm-2 text-right">
                  <button onClick={() => onDelete(idx)} className="btn btn-danger">
                    <i className="fa fa-trash pr-0" />
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="col-9 col-sm-10" />
        <div className="col-3 col-sm-2 text-right">
          {!disabled && (
            <button
              onClick={onAdd}
              className={"btn btn-success" + (addDisabled ? " disabled" : "")}
              disabled={addDisabled}
            >
              <i className="fa fa-plus pr-0" />
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default SelectActiveSubstance;
