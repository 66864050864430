import React from "react";
import CommodityStockWidget from "./CommodityStockWidget";
import commodityUtils from "../../../utils/commodityUtils";

interface CommoditiesStockStatisticsProps {
  stock: number;
  ordered: number;
  reserved: number;
  available: number;
  commodityType: string | null;
  outerClassName?: string;
  innerClassName?: string;
}

const CommoditiesStockStatistics: React.FunctionComponent<CommoditiesStockStatisticsProps> = ({
  stock,
  ordered,
  reserved,
  available,
  commodityType,
  outerClassName,
  innerClassName
}: CommoditiesStockStatisticsProps) => {
  return (
    <div className={outerClassName}>
      <div className={innerClassName}>
        <CommodityStockWidget
          header="Current Stock"
          content={commodityUtils.resolveStockUnit(stock, commodityType)}
          colorClass={stock > 0 ? "btn-label-success" : ""}
        />
      </div>
      <div className={innerClassName}>
        <CommodityStockWidget
          header="Ordered"
          content={commodityUtils.resolveStockUnit(ordered, commodityType)}
          colorClass={ordered > 0 ? "btn-label-success" : ""}
        />
      </div>
      <div className={innerClassName}>
        <CommodityStockWidget
          header="Reserved"
          content={commodityUtils.resolveStockUnit(reserved, commodityType)}
          colorClass={reserved > 0 ? "btn-label-warning" : ""}
        />
      </div>
      <div className={innerClassName}>
        <CommodityStockWidget
          header="Available"
          content={commodityUtils.resolveStockUnit(available, commodityType)}
          colorClass={available < 0 ? "btn-label-danger" : "btn-label-success"}
        />
      </div>
    </div>
  );
};
export default CommoditiesStockStatistics;
