import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import i18n from "../../../translations/i18n";
import { CustomerData, CustomOrder, DatasheetData } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import OrderHelper, { T_CAPSULE, T_CUSTOM, T_LIQUID, T_POWDER, T_SERVICE, T_SOFTGEL, T_TABLET } from "../OrderHelper";
import datasheetGeneration from "../../../utils/pdf/datasheetGeneration";
import baseUtils from "../../../utils/baseUtils";
import dateUtils from "../../../utils/dateUtils";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import orderUtils from "../../../utils/orderUtils";
import userService from "../../../services/userService";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import CustomerDataComponent from "./common/CustomerData";
import PDFPreview from "../../common/PDFPreview";
import config from "../../../config/config.json";
import { T_DATASHEET } from "../../../utils/timelineUtils";
import notificationService, { R_ORDERFILEUPLOAD } from "../../../services/notificationService";
import BioNumberSelection from "./common/BioNumberSelection";
import LanguageSelectionDropdown from "../../common/LanguageSelectionDropdown";

interface CreateDatasheetModalProps {
  order: CustomOrder;
  disabled?: boolean;
  context: React.ContextType<typeof DataContext>;
}

interface CreateDatasheetModalState {
  show: boolean;
  uploading: boolean;
  customerData: CustomerData;
  pdfData: DatasheetData;
}

class CreateDatasheetModal extends PureComponent<CreateDatasheetModalProps, CreateDatasheetModalState> {
  constructor(props: CreateDatasheetModalProps) {
    super(props);
    const { customerData, pdfData } = this.getDefaultStateValues(props);
    this.state = {
      show: false,
      uploading: false,
      customerData,
      pdfData
    };
  }

  componentDidUpdate(prevProps: Readonly<CreateDatasheetModalProps>, prevState: Readonly<CreateDatasheetModalState>) {
    // Update on order changes and on show
    if ((this.state.show && !_.isEqual(prevProps.order, this.props.order)) || (!prevState.show && this.state.show)) {
      const { customerData, pdfData } = this.getDefaultStateValues(this.props);
      this.setState({ pdfData, customerData });
    }
  }

  /**
   * Get the translated consumption text based on the order type
   * @param order the order including the order type on which the text depends
   * @returns the translated consumption text based on the order type
   */
  getConsumptionText = (order: CustomOrder): string => {
    switch (order.settings.type) {
      case T_CAPSULE:
        return i18n.t("datasheet:datasheetConsumptionCapsule");
      case T_TABLET:
        return i18n.t("datasheet:datasheetConsumptionTablet");
      case T_POWDER:
        return i18n.t("datasheet:datasheetConsumptionPowder");
      case T_LIQUID:
        return i18n.t("datasheet:datasheetConsumptionLiquid");
      default:
        return i18n.t("datasheet:datasheetConsumption");
    }
  };

  /**
   * Get the default state values
   * @param props the modal properties
   * @returns { {pdfData: DatasheetData, customerData: CustomerData} } default state values for pdf data and customer data
   */
  getDefaultStateValues = (
    props: CreateDatasheetModalProps
  ): { pdfData: DatasheetData; customerData: CustomerData } => {
    const { order, context } = props;
    const pdfData = {
      title: order.title,
      consumption: this.getConsumptionText(order),
      warning: i18n.t("datasheet:datasheetWarning"),
      description:
        i18n.t("datasheet:datasheetDescription") +
        " " +
        OrderHelper.getCommodityList(order, context, undefined, i18n.language),
      ingredients: OrderHelper.getCommodityList(order, context, true, i18n.language),
      coloringAdditives: OrderHelper.getColorAdditiveList(order, context), // only E-numbers are returned
      notes: "",
      dataSheetText: "",
      bioNumber: ""
    };
    const customerData = OrderHelper.getCustomerData(order);
    return { pdfData, customerData };
  };

  /**
   * Use props and state to create data sheet pdf html
   * @returns {string} html representation for data sheet pdf
   */
  doCreatePDF = (): string => {
    const { order, context } = this.props;
    const { customerData, pdfData } = this.state;
    return datasheetGeneration.createDataSheet(order, customerData, pdfData, context);
  };

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });

  handleUpload = async () => {
    const { order } = this.props;
    this.setState({ uploading: true });
    const pdfResult = await this.createDatasheetPDF();
    if (!pdfResult.result || !pdfResult.path) {
      toast.error("Datasheet creation failed: " + pdfResult.message);
      this.setState({ uploading: false });
      return;
    }
    window.open(config.mediahubBase + pdfResult.path, "_blank");
    const timelineEntry = {
      id: new BSON.ObjectId(),
      type: T_DATASHEET,
      date: new Date(),
      path: pdfResult.path,
      person: new BSON.ObjectId(userService.getUserData()._id),
      identifier: order.identifier
    };
    try {
      const result = await dbOrderService.pushToTimeline(order._id, timelineEntry);
      if (result && result.modifiedCount) {
        toast.success("Datasheet created successfully");
        notificationService.notify(R_ORDERFILEUPLOAD, order._id, {
          de: i18n.t("datasheet:datasheet", { lng: "de" }),
          en: i18n.t("datasheet:datasheet", { lng: "en" })
        });
        this.setState({ uploading: false, show: false });
      } else toast.error("Adding of timeline entry failed. " + pdfResult.path);
    } catch (e) {
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ uploading: false });
    }
  };

  createDatasheetPDF = async () => {
    const { order } = this.props;
    const { pdfData } = this.state;
    const data = JSON.stringify({
      html: this.doCreatePDF(),
      fileName:
        i18n.t("datasheet:datasheet") +
        "_" +
        (orderUtils.isOrderState(order.state) ? "AT-" : "AN-") +
        order.identifier +
        (pdfData.title ? "_" + baseUtils.encodeString(pdfData.title) : "") +
        "_V" +
        orderUtils.getPDFVersion(order, T_DATASHEET) +
        "_" +
        dateUtils.timeStampDate() +
        ".pdf"
    });
    let path;
    try {
      path = await pdfUtils.uploadAndReturnPath(data);
    } catch (e) {
      return { result: false, message: e.message };
    }
    return { result: true, path: path };
  };

  handleCustomerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const customerData: CustomerData = { ...this.state.customerData };
    const key = e.target.name as keyof CustomerData;
    customerData[key] = e.target.value;
    this.setState({ customerData });
  };

  handlePDFDataChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const pdfData: DatasheetData = { ...this.state.pdfData };
    const key = e.target.name as keyof DatasheetData;
    pdfData[key] = e.target.value;
    this.setState({ pdfData });
  };

  /**
   * Function for changing to selected language.
   */
  handleLanguageChange = () => {
    const { pdfData } = this.state;
    const { order, context } = this.props;

    const translationObject = {
      title: pdfData.title,
      consumption: this.getConsumptionText(order),
      warning: i18n.t("datasheet:datasheetWarning"),
      description:
        i18n.t("datasheet:datasheetDescription") +
        " " +
        OrderHelper.getCommodityList(order, context, undefined, i18n.language),
      ingredients: OrderHelper.getCommodityList(order, context, true, i18n.language),
      coloringAdditives: pdfData.coloringAdditives,
      notes: pdfData.notes,
      dataSheetText: pdfData.dataSheetText,
      bioNumber: pdfData.bioNumber
    };

    this.setState({ pdfData: translationObject });
  };

  /**
   * Render the pdf preview
   * @returns {JSX.Element} the pdf preview with required data
   */
  renderPreview = (): JSX.Element => {
    const { order } = this.props;
    const { pdfData } = this.state;
    const fileName =
      i18n.t("common:draft") +
      "_" +
      i18n.t("datasheet:datasheet") +
      "_AT-" +
      order.identifier +
      (pdfData.title ? "_" + baseUtils.encodeString(pdfData.title) : "") +
      "_" +
      dateUtils.timeStampDate() +
      ".pdf";
    return <PDFPreview createPDF={this.doCreatePDF} fileName={fileName} />;
  };

  render() {
    const { order, disabled } = this.props;
    const { show, uploading, customerData, pdfData } = this.state;
    return (
      <>
        <button
          className={"btn btn-label-brand btn-sm btn-bold mr-3 my-1 " + (disabled ? "btn-disabled" : "")}
          onClick={this.handleShow}
          disabled={disabled}
        >
          <i className="fa fa-file-alt" />
          Datasheet
        </button>
        <Modal show={show} onHide={this.handleClose} size={"xl"} centered name={"createDatasheetModal"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand fa fa-file-alt mr-2" />
              Create Datasheet
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <CustomerDataComponent customerData={customerData} onCustomerChange={this.handleCustomerChange} />
              <div className="col-6">
                <div className="input-group row mb-2">
                  <label className="col-lg-3 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                    Order:
                  </label>
                  <div className="input-group-prepend">
                    <span className="input-group-text">AT-</span>
                  </div>
                  <input type="text" className="form-control" disabled value={order.identifier} />
                </div>
                <LanguageSelectionDropdown
                  labelPosition="front"
                  wrapperClasses={"input-group row mb-2"}
                  labelColumnClasses={"col-lg-3 col-form-label ml-3"}
                  labelClasses={"kt-widget24__stats kt-font-dark kt-font-bold mb-0"}
                  selectClasses={"select-default border-radius-left-0 col px-0"}
                  label={"Language:"}
                  languageChangeCallback={this.handleLanguageChange}
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <div>
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Title:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      value={pdfData.title}
                      name={"title"}
                      onChange={this.handlePDFDataChange}
                    />
                  </div>
                  <BioNumberSelection
                    bioNumber={pdfData.bioNumber}
                    onChange={this.handlePDFDataChange}
                    wrapperClasses={"input-group row mb-2"}
                    labelColumnClasses={"col-lg-2 col-form-label ml-3"}
                    labelClasses={"kt-widget24__stats kt-font-dark kt-font-bold mb-0"}
                  />
                  {[T_CUSTOM, T_SOFTGEL, T_SERVICE].includes(order.state) && (
                    <div className="input-group row mb-2">
                      <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                        Datasheet text:
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Datasheet text"
                        rows={5}
                        value={pdfData.dataSheetText}
                        name={"dataSheetText"}
                        onChange={this.handlePDFDataChange}
                      />
                    </div>
                  )}
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Consumption recommendation:
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Consumption Recommendation"
                      rows={3}
                      value={pdfData.consumption}
                      name={"consumption"}
                      onChange={this.handlePDFDataChange}
                    />
                  </div>
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Warning note:
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Warning note"
                      rows={4}
                      value={pdfData.warning}
                      name={"warning"}
                      onChange={this.handlePDFDataChange}
                    />
                  </div>
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Description:
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Description"
                      rows={2}
                      value={pdfData.description}
                      name={"description"}
                      onChange={this.handlePDFDataChange}
                    />
                  </div>
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Ingredients:
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Warning note"
                      rows={2}
                      value={pdfData.ingredients}
                      name={"ingredients"}
                      onChange={this.handlePDFDataChange}
                    />
                  </div>
                  <div className="input-group row mb-2">
                    <label className="col-lg-2 col-form-label kt-widget24__stats kt-font-dark ml-3 kt-font-bold">
                      Notes:
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Notes"
                      rows={2}
                      value={pdfData.notes}
                      name={"notes"}
                      onChange={this.handlePDFDataChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            {this.renderPreview()}
            <button
              className={"btn btn-success " + (uploading ? "btn-disabled" : "")}
              disabled={uploading}
              onClick={this.handleUpload}
            >
              {uploading && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Create Datasheet
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateDatasheetModal;
