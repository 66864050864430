import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { BSON } from "realm-web";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";
import { ExtendedEMCommodity } from "../../../model/customTypes.types";
import baseUtils from "../../../utils/baseUtils";
import dbService from "../../../services/dbService";
import ExternalManufacturerHelper from "../ExternalManufacturerHelper";
import EMCommodityHeader from "./EMCommodityHeader";
import SplashScreen from "../../common/SplashScreen";
import EMCommodityOverview from "./EMCommodityOverview";
import EmCommodityRelatedOrders from "./EMCommodityRelatedOrders";
import EmCommodityPriceGraph from "./EMCommodityPriceGraph";
import EMCommodityDocuments from "./EMCommodityDocuments";

interface EMCommodityParams {
  id: string;
}

interface ExternalManufacturerCommodityProps extends RouteComponentProps<EMCommodityParams, {}, {}> {
  context: React.ContextType<typeof ExternalManufacturerContext>;
}

interface ExternalManufacturerCommodityState {
  commodity?: ExtendedEMCommodity;
}

class ExternalManufacturerCommodity extends PureComponent<
  ExternalManufacturerCommodityProps,
  ExternalManufacturerCommodityState
> {
  _isMounted = false;
  _id: string | undefined;
  constructor(props: ExternalManufacturerCommodityProps) {
    super(props);
    this._id = props.match.params.id;
    this.state = {};
  }

  componentDidMount = async () => {
    await this.initializeData();
  };

  componentDidUpdate = async (
    prevProps: Readonly<ExternalManufacturerCommodityProps>,
    prevState: Readonly<ExternalManufacturerCommodityState>,
    snapshot?: any
  ) => {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this._id = this.props.match.params.id;
      await this.initializeData();
    }

    if (this._id && prevProps !== this.props) {
      const { commodities } = this.props.context;
      const commodity = baseUtils.getDocFromCollection(commodities, this._id);
      if (commodity) {
        const newCommodity = ExternalManufacturerHelper.getExtendedCommodity(commodity, this.props.context);
        if (!_.isEqual(newCommodity, prevState.commodity)) {
          this.setState({ commodity: newCommodity });
        }
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  initializeData = async () => {
    const { history, context } = this.props;
    const { commodities } = context;
    if (!this._id || !BSON.ObjectId.isValid(this._id)) {
      history.push("/commodities");
      return;
    }
    this._isMounted = true;
    let commodity = baseUtils.getDocFromCollection(commodities, this._id);
    if (!commodity) {
      const commoditiesCol = await dbService.callFunction("getEMCommodities", []);
      commodity = baseUtils.getDocFromCollection(commoditiesCol, this._id);
      if (!commodity) {
        console.error("No commodity could be loaded for id", this._id);
        history.push("/commodities");
        return;
      }
    }
    if (this._isMounted)
      this.setState({ commodity: ExternalManufacturerHelper.getExtendedCommodity(commodity, context) });
  };

  render() {
    const { context } = this.props;
    const { commodity } = this.state;
    if (!commodity)
      return (
        <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid ">
          <div className="kt-portlet ">
            <div className="kt-portlet__body" style={{ minHeight: "80vh" }}>
              <SplashScreen
                additionalSVGStyle={{
                  height: "80px",
                  width: "80px"
                }}
              />
            </div>
          </div>
        </div>
      );
    return (
      <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <EMCommodityHeader commodity={commodity} />
        <div className="row">
          <div className="col-xl-4">
            <EMCommodityOverview commodity={commodity} />
            {commodity.specifications && commodity.specifications.length > 0 && (
              <EMCommodityDocuments commodity={commodity} />
            )}
            <EmCommodityPriceGraph commodity={commodity} />
          </div>
          <div className="col-xl-8">
            <EmCommodityRelatedOrders commodity={commodity} context={context} />
          </div>
        </div>
      </div>
    );
  }
}

export default ExternalManufacturerCommodity;
