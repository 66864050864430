import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import validator from "validator";
import { ManufacturerNotificationSettings, ManufacturersDocument } from "../../model/manufacturers.types";
import dbService, { MANUFACTURERS, UpdateAction } from "../../services/dbService";
import toastUtils from "../../utils/toastUtils";
import userService from "../../services/userService";
import ErrorOverlayButton from "../common/ErrorOverlayButton";

interface ManufacturerNotificationSettingsPanelProps {
  manufacturer: ManufacturersDocument;
}

const ManufacturerNotificationSettingsPanel: React.FunctionComponent<ManufacturerNotificationSettingsPanelProps> = ({
  manufacturer
}) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [mailAddress, setMailAddress] = useState<string>("");
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (manufacturer.notificationSettings?.targetWeekChanges) {
      const { mailAddress, isEnabled } = manufacturer.notificationSettings?.targetWeekChanges;
      setMailAddress(mailAddress);
      setIsEnabled(isEnabled);
    } else {
      setMailAddress("");
      setIsEnabled(false);
    }
  }, [manufacturer.notificationSettings?.targetWeekChanges]);

  const handleChangeNotificationMail = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setMailAddress(e.target.value);
  }, []);

  const handleChangeNotificationsEnabled = useCallback(() => {
    setIsEnabled(!isEnabled);
  }, [isEnabled]);

  const handleUpdateManufacturer = async () => {
    setSaving(true);
    try {
      const actions: Array<UpdateAction> = [];
      const updateObject: ManufacturerNotificationSettings = {
        targetWeekChanges: {
          mailAddress: mailAddress,
          isEnabled: isEnabled
        }
      };
      actions.push({
        collection: MANUFACTURERS,
        filter: { _id: manufacturer._id },
        update: {
          notificationSettings: updateObject
        }
      });
      const success = await dbService.updatesAsTransaction(actions);
      await toastUtils.databaseOperationToast(
        success,
        "Manufacturer settings successfully updated!",
        "Manufacturer settings could not be updated!"
      );
    } catch (e) {
      toast.error("An error occurred: " + e);
    } finally {
      setSaving(false);
    }
  };

  const errors = useMemo(() => {
    const newErrors: Array<string> = [];
    if (!validator.isEmail(mailAddress)) {
      newErrors.push("Please enter a valid email address!");
    }
    return newErrors;
  }, [mailAddress]);

  if (!userService.hasOneOfRoles(["Internal", "Admin", "Sales"])) return null;
  return (
    <div className="kt-portlet kt-portlet--mobile" style={{ minHeight: "150px" }}>
      <div className="kt-portlet__head kt-portlet__head--lg">
        <div className="kt-portlet__head-label">
          <span className="kt-portlet__head-icon">
            <i className="kt-font-brand flaticon2-settings" />
          </span>
          <h3 className="kt-portlet__head-title">Notification Settings</h3>
        </div>
      </div>
      <div className="kt-portlet__body kt-portlet__body--fit">
        <div className="row m-4">
          <p>
            Enter an email address to receive notifications when the target week changes for orders related to this
            manufacturer.
          </p>
          <div className="form-group row w-100">
            <label className="col-3 col-form-label">Notification Email</label>
            <div className="col-9 col-xl-6">
              <input
                className={"form-control" + (saving ? "" : " disabled")}
                disabled={saving}
                type="text"
                onChange={handleChangeNotificationMail}
                value={mailAddress}
              />
            </div>
          </div>
          <div className="form-group row w-100">
            <label className="col-3 col-form-check-label">Notification Enabled</label>
            <div className="col-9 col-xl-6">
              <input
                className={"form-check-input ml-1" + (saving ? "" : " disabled")}
                disabled={saving}
                type="checkbox"
                onChange={handleChangeNotificationsEnabled}
                checked={isEnabled}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="kt-portlet__foot">
        <div className="float-right">
          <ErrorOverlayButton
            errors={errors}
            buttonText={"Save"}
            className="btn btn-success"
            onClick={handleUpdateManufacturer}
            saving={saving}
          />
        </div>
      </div>
    </div>
  );
};

export default ManufacturerNotificationSettingsPanel;
