import React from "react";
import { BSON } from "realm-web";
import { ExtendedEMOrder } from "../../../model/customTypes.types";
import ExternalManufacturerHelper, {
  EM_ACCEPTED,
  EM_ARRIVED,
  EM_CANCELED,
  EM_CHECKED,
  EM_DELIVERED,
  EM_EXPIRED,
  EM_FINISHED,
  EM_OPEN,
  EM_ORDERED
} from "../ExternalManufacturerHelper";
import baseUtils from "../../../utils/baseUtils";
import CancelEMOrderModal from "../modals/CancelEMOrderModal";
import { EmOrderTimelineEntry } from "../../../model/externalManufacturerOrders.types";
import userService from "../../../services/userService";
import dbService, { UpdateAction, EMORDERS } from "../../../services/dbService";
import toastUtils from "../../../utils/toastUtils";
import AcceptRequestModal from "../modals/AcceptRequestModal";
import UpdatePriceModal from "../internal/UpdatePriceModal";

interface EmOrderHeaderProps {
  order: ExtendedEMOrder;
  internal?: boolean;
}

const EmOrderHeader: React.FunctionComponent<EmOrderHeaderProps> = ({ order, internal }) => {
  const handleOrderArrived = async () => {
    const timeline: EmOrderTimelineEntry = {
      _id: new BSON.ObjectId(),
      type: EM_ARRIVED,
      date: new Date(),
      person: userService.getUserId()
    };
    const actions: Array<UpdateAction> = [
      {
        collection: EMORDERS,
        filter: { _id: order._id },
        update: { state: EM_ARRIVED },
        push: { timeline }
      }
    ];
    const success = await dbService.updatesAsTransaction(actions);
    await toastUtils.databaseOperationToast(
      success,
      "Commodity order cancelled successfully",
      "Error cancelling order"
    );
  };

  const handleOrderFinished = async () => {
    const timeline: EmOrderTimelineEntry = {
      _id: new BSON.ObjectId(),
      type: EM_FINISHED,
      date: new Date(),
      person: userService.getUserId()
    };
    const actions: Array<UpdateAction> = [
      {
        collection: EMORDERS,
        filter: { _id: order._id },
        update: { state: EM_FINISHED },
        push: { timeline }
      }
    ];
    const success = await dbService.updatesAsTransaction(actions);
    await toastUtils.databaseOperationToast(success, "External order finished", "Error finishing external order");
  };

  const getButtons = () => {
    const getButton = (button: any, disabled?: boolean) => ({
      key: button.text,
      component: (
        <button
          className={"btn btn-sm btn-upper " + button.classes + (disabled ? " disabled" : "")}
          onClick={button.onClick}
          disabled={disabled}
          title={disabled ? "This action is not allowed" : ""}
        >
          {button.text}
        </button>
      )
    });

    if (internal) {
      const cancelModal = { key: "cancelOrder", component: <CancelEMOrderModal order={order} /> };
      switch (order.state) {
        case EM_ARRIVED:
          const finishedButton = {
            text: "Order finished",
            classes: "btn-success",
            onClick: handleOrderFinished
          };
          return [getButton(finishedButton)];
        case EM_OPEN:
          const updateModal = { key: "updatePrices", component: <UpdatePriceModal order={order} /> };
          return [cancelModal, updateModal];
        case EM_CHECKED:
          return [cancelModal];
        case EM_DELIVERED:
          const arrivedButton = {
            text: "Order arrived",
            classes: "btn-success",
            onClick: handleOrderArrived
          };
          return [getButton(arrivedButton)];
        case EM_ORDERED:
        case EM_FINISHED:
        case EM_EXPIRED:
        case EM_CANCELED:
        default:
          return [];
      }
    } else {
      const cancelModal = { key: "cancelOrder", component: <CancelEMOrderModal order={order} /> };
      switch (order.state) {
        case EM_OPEN:
        case EM_ACCEPTED:
          return [cancelModal];
        case EM_DELIVERED:
          const arrivedButton = {
            text: "Order arrived",
            classes: "btn-success",
            onClick: handleOrderArrived
          };
          return [getButton(arrivedButton)];
        case EM_CHECKED:
          const acceptModal = { key: "acceptOrder", component: <AcceptRequestModal order={order} /> };
          return [cancelModal, acceptModal];
        case EM_ORDERED:
        case EM_ARRIVED:
        case EM_EXPIRED:
        case EM_FINISHED:
        case EM_CANCELED:
        default:
          return [];
      }
    }
  };

  const src = order.commodity ? ExternalManufacturerHelper.getCommodityImage(order.commodity) : "files/others.png";
  const ordered = ExternalManufacturerHelper.getLatestTimelineEntry(order, EM_ORDERED);
  const delivered = ExternalManufacturerHelper.getLatestTimelineEntry(order, EM_DELIVERED);
  const finished = ExternalManufacturerHelper.getLatestTimelineEntry(order, EM_FINISHED);
  const canceled = ExternalManufacturerHelper.getLatestTimelineEntry(order, EM_CANCELED);
  const progress = ExternalManufacturerHelper.getEMOrderProgress(order.state);
  const buttonDefinition = getButtons();

  return (
    <div className="kt-portlet">
      <div className="kt-portlet__body">
        <div className="kt-widget kt-widget--user-profile-3">
          <div className="kt-widget__top">
            <div className="kt-widget__media">
              <img src={src} alt="color" />
            </div>
            <div className="kt-widget__content">
              <div className="kt-widget__head">
                <div className="kt-widget__user">
                  <span className={"kt-widget__title kt-font-bold kt-font-dark"}>
                    <span className={order.state === EM_EXPIRED ? "text-danger" : "kt-font-dark"}>{`${
                      order.state === EM_EXPIRED ? "[EXPIRED] " : order.state === EM_CANCELED ? "[CANCELED] " : ""
                    } ${
                      [EM_OPEN, EM_CHECKED].includes(order.state) ? "Request REQ" : "Order EMO"
                    }-${order.identifier.toString()}`}</span>
                    <br />
                    <span style={{ fontWeight: 200 }}>{order.reference}</span>
                    <br />
                    <span style={{ fontWeight: 200, fontSize: "14px" }}>
                      {order.commodity ? order.commodity.title.en : ""}
                    </span>
                  </span>
                </div>
                <div className="kt-widget__action">
                  {buttonDefinition.map(button => (
                    <React.Fragment key={button.key}>{button.component}</React.Fragment>
                  ))}
                </div>
              </div>
              <div className="kt-widget__info">
                <div className="kt-widget__desc" />
                <div className="kt-widget__stats d-flex align-items-center">
                  <div className="kt-widget__item" style={{ margin: "10px", textAlign: "center" }}>
                    <span className="kt-widget__date" style={{ width: "120px" }}>
                      Created
                    </span>
                    <div className="kt-widget__label" style={{ width: "100%" }}>
                      <span className="btn btn-label-brand btn-sm btn-bold btn-upper" style={{ width: "100%" }}>
                        {baseUtils.formatDate(order.createdOn)}
                      </span>
                    </div>
                  </div>
                  {ordered && (
                    <div className="kt-widget__item" style={{ margin: "10px", textAlign: "center" }}>
                      <span className="kt-widget__date" style={{ width: "120px" }}>
                        Ordered
                      </span>
                      <div className="kt-widget__label" style={{ width: "100%" }}>
                        <span className="btn btn-label-brand btn-sm btn-bold btn-upper" style={{ width: "100%" }}>
                          {baseUtils.formatDate(ordered.date)}
                        </span>
                      </div>
                    </div>
                  )}
                  {delivered && (
                    <div className="kt-widget__item" style={{ margin: "10px", textAlign: "center" }}>
                      <span className="kt-widget__date" style={{ width: "120px" }}>
                        Delivered
                      </span>
                      <div className="kt-widget__label" style={{ width: "100%" }}>
                        <span className="btn btn-label-brand btn-sm btn-bold btn-upper" style={{ width: "100%" }}>
                          {baseUtils.formatDate(delivered.date)}
                        </span>
                      </div>
                    </div>
                  )}
                  {finished && (
                    <div className="kt-widget__item" style={{ margin: "10px", textAlign: "center" }}>
                      <span className="kt-widget__date" style={{ width: "120px" }}>
                        Finished
                      </span>
                      <div className="kt-widget__label" style={{ width: "100%" }}>
                        <span className="btn btn-label-Success btn-sm btn-bold btn-upper" style={{ width: "100%" }}>
                          {baseUtils.formatDate(finished.date)}
                        </span>
                      </div>
                    </div>
                  )}
                  {canceled && (
                    <div className="kt-widget__item" style={{ margin: "10px", textAlign: "center" }}>
                      <span className="kt-widget__date" style={{ width: "120px" }}>
                        Canceled
                      </span>
                      <div className="kt-widget__label" style={{ width: "100%" }}>
                        <span className="btn btn-label-danger btn-sm btn-bold btn-upper" style={{ width: "100%" }}>
                          {baseUtils.formatDate(canceled.date)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="kt-widget__item flex-fill" style={{ width: "200px", textAlign: "center" }}>
                    <span className="kt-widget__subtitel">Progress</span>
                    <div className="kt-widget__progress d-flex  align-items-center">
                      <div className="progress" style={{ height: "5px", width: "200px" }}>
                        <div
                          className={
                            "progress-bar " +
                            ([EM_EXPIRED, EM_CANCELED].includes(order.state) ? "kt-bg-danger" : "kt-bg-success")
                          }
                          style={{ width: progress }}
                        />
                      </div>
                      <span className="kt-widget__stat">{progress}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="kt-widget__bottom">
            <EmOrderHeaderInfoWidget icon={"flaticon-cart"} description={"Amount"} value={order.amount + "kg"} />
            <EmOrderHeaderInfoWidget
              icon={"flaticon-piggy-bank"}
              description={"Price Limit"}
              value={order.priceLimit ? baseUtils.formatEuro(order.priceLimit) : "-"}
            />
            <EmOrderHeaderInfoWidget
              icon={"flaticon-piggy-bank"}
              description={"Actual Price"}
              value={order.actualPrice !== null ? baseUtils.formatEuro(order.actualPrice) : "-"}
              valueClasses={
                order.priceLimit && order.actualPrice && order.priceLimit < order.actualPrice
                  ? "text-danger"
                  : order.priceLimit && order.actualPrice && order.priceLimit >= order.actualPrice
                  ? "text-success"
                  : ""
              }
            />
            <EmOrderHeaderInfoWidget
              icon={"flaticon-mail"}
              description={"Latest Delivery Date"}
              value={order.deliveryDateLimit ? baseUtils.formatDate(order.deliveryDateLimit) : "-"}
              valueClasses={
                order.deliveryDateLimit &&
                [EM_OPEN, EM_ORDERED].includes(order.state) &&
                order.deliveryDateLimit < new Date()
                  ? "text-danger"
                  : ""
              }
            />
            <EmOrderHeaderInfoWidget
              icon={"flaticon-mail"}
              description={"Expected Delivery Date"}
              value={order.actualDeliveryDate ? baseUtils.formatDate(order.actualDeliveryDate) : "-"}
              valueClasses={
                order.deliveryDateLimit &&
                order.actualDeliveryDate &&
                order.actualDeliveryDate > order.deliveryDateLimit
                  ? "text-danger"
                  : ""
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface EmOrderHeaderInfoWidgetProps {
  icon: string;
  description: string;
  value: string;
  valueClasses?: string;
}

const EmOrderHeaderInfoWidget: React.FunctionComponent<EmOrderHeaderInfoWidgetProps> = ({
  icon,
  description,
  value,
  valueClasses
}) => {
  return (
    <div className="kt-widget__item">
      <div className="kt-widget__icon">
        <i className={icon} />
      </div>
      <div className="kt-widget__details">
        <span className="kt-widget__title">{description}</span>
        <span className={"kt-widget__value " + (valueClasses ? valueClasses : "")}>{value}</span>
      </div>
    </div>
  );
};

export default EmOrderHeader;
