import React, { useCallback, useState } from "react";
import i18n from "../../../translations/i18n";
import { BatchFile } from "../../../model/warehouse/batch.types";
import { useWarehouseContext } from "../../../context/warehouseContext";
import Tooltip from "../../common/Tooltip";
import { FileType } from "../../../model/configuration/warehouseConfiguration.types";
import UploadFileDragNDrop from "./UploadFileDragNDrop";

interface ChangeBBDViewProps {
  bbd: Date;
  comment: string;
  files: Array<BatchFile>;
  onChangeBBD: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeComment: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onUploadFile: (file: File, title: string, type: FileType) => void;
  onRemoveFile: (fileId: string) => void;
}

const ChangeBBDView: React.FunctionComponent<ChangeBBDViewProps> = ({
  bbd,
  comment,
  files,
  onChangeBBD,
  onChangeComment,
  onUploadFile
}) => {
  const warehouseContext = useWarehouseContext();

  const [showUpload, setShowUpload] = useState<boolean>(false);

  const handleShowUpload = useCallback(() => setShowUpload(!showUpload), [showUpload]);

  return (
    <>
      <div className="row m-3">
        <input
          type="date"
          className="form-control form-control-solid"
          name="bbd"
          value={bbd.toISOString().split("T")[0]}
          onChange={onChangeBBD}
        />
      </div>
      <div className="row m-3">
        <textarea
          className="form-control form-control-solid"
          placeholder={i18n.t("warehouse:comments")}
          rows={3}
          value={comment}
          onChange={onChangeComment}
        />
      </div>
      <Tooltip
        tooltipText={
          files.length === 0 ? (
            `0 ${i18n.t("common:files")}`
          ) : (
            <span>
              {files.map(f => {
                return <p key={f._id.toString()}>{f.title}</p>;
              })}
            </span>
          )
        }
      >
        <img
          className="ml-3 align-self-center"
          src="/media/files/pdf.svg"
          alt="pdf symbol"
          style={files.length ? { height: 20 } : { height: 20, opacity: "35%" }}
        />
      </Tooltip>
      <button className="btn btn-text text-muted mr-2" onClick={handleShowUpload}>
        {i18n.t("warehouse:uploadFile")}
      </button>
      {showUpload && warehouseContext.configuration && (
        <div className="row m-3">
          <div className="row">
            <UploadFileDragNDrop
              fileTypes={warehouseContext.configuration.values.fileTypes}
              onUploadFile={onUploadFile}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChangeBBDView;
