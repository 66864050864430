import _ from "lodash";
import React from "react";
import { ExtendedEMOrder } from "../../../model/customTypes.types";
import baseUtils from "../../../utils/baseUtils";
import EmCommodityWidget from "../EMCommodityWidget";

interface EmOrderOverviewProps {
  order: ExtendedEMOrder;
}

const EmOrderOverview: React.FunctionComponent<EmOrderOverviewProps> = ({ order }) => {
  return (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Overview</h3>
        </div>
        <div className="kt-portlet__head-toolbar" />
      </div>
      <div className="kt-form kt-form--label-right">
        <div className="kt-portlet__body">
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">Created</label>
            <div className="col-8 col-form-label kt-font-bolder kt-font-dark">
              {baseUtils.formatDate(order.createdOn)}
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">State</label>
            <div className="col-8 col-form-label kt-font-bolder kt-font-dark">{_.upperFirst(order.state)}</div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label my-auto">Commodity</label>
            <div className="col-8 col-form-label kt-font-bolder kt-font-dark">
              {order.commodity ? <EmCommodityWidget commodity={order.commodity} small={true} /> : "Unknown"}
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">Amount</label>
            <div className="col-8 col-form-label kt-font-bolder kt-font-dark">{order.amount}kg</div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">Price Limit</label>
            <div className={"col-8 col-form-label " + (order.priceLimit ? "kt-font-bolder kt-font-dark" : "")}>
              {order.priceLimit ? baseUtils.formatEuro(order.priceLimit) : "-"}
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">Latest Delivery</label>
            <div className={"col-8 col-form-label " + (order.deliveryDateLimit ? "kt-font-bolder kt-font-dark" : "")}>
              {order.deliveryDateLimit ? baseUtils.formatDate(order.deliveryDateLimit) : "-"}
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">Actual Price</label>
            <div className={"col-8 col-form-label " + (order.actualPrice ? "kt-font-bolder kt-font-dark" : "")}>
              {order.actualPrice !== null ? baseUtils.formatEuro(order.actualPrice) : "-"}
            </div>
          </div>
          {order.actualPrice !== null && (
            <div className="form-group form-group-xs row">
              <label className="col-4 col-form-label">Total price</label>
              <div className="col-8 col-form-label kt-font-bolder kt-font-dark">
                {baseUtils.formatEuro(order.actualPrice * order.amount)}
              </div>
            </div>
          )}
          <div className="form-group form-group-xs row">
            <label className="col-4 col-form-label">Expected Delivery</label>
            <div className={"col-8 col-form-label " + (order.actualDeliveryDate ? "kt-font-bolder kt-font-dark" : "")}>
              {order.actualDeliveryDate ? baseUtils.formatDate(order.actualDeliveryDate) : "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmOrderOverview;
