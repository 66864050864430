import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ExtendedEMCommodity } from "../../../model/customTypes.types";
import DateInput from "../../common/DateInput";
import calculationUtils from "../../../utils/calculationUtils";
import dbService from "../../../services/dbService";
import userService from "../../../services/userService";
import baseUtils from "../../../utils/baseUtils";
import slackService from "../../../services/slackService";

interface EmCommodityOrderModalProps {
  commodity: ExtendedEMCommodity | null;
  onClose: () => void;
}

interface EmCommodityOrderModalState {
  loading: boolean;
  reference: string;
  amount: string;
  priceLimit: string;
  enablePriceLimit: boolean;
  deliveryLimit: Date;
  enableDeliveryLimit: boolean;
  note: string;
}

class EMCommodityRequestModal extends PureComponent<EmCommodityOrderModalProps, EmCommodityOrderModalState> {
  constructor(props: EmCommodityOrderModalProps) {
    super(props);
    this.state = {
      loading: false,
      reference: "",
      amount: "0",
      priceLimit: "0",
      enablePriceLimit: false,
      deliveryLimit: new Date(),
      enableDeliveryLimit: false,
      note: ""
    };
  }

  componentDidMount() {
    this.initializeData();
  }

  componentDidUpdate(
    prevProps: Readonly<EmCommodityOrderModalProps>,
    prevState: Readonly<EmCommodityOrderModalState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.commodity, this.props.commodity && this.props.commodity)) {
      this.initializeData();
    }
  }

  initializeData = () => {
    const { commodity } = this.props;
    this.setState({
      reference: "",
      amount: "1",
      priceLimit: commodity && commodity.price ? Math.round(commodity.price.min).toString() : "0",
      deliveryLimit: commodity
        ? new Date(new Date().setDate(new Date().getDate() + commodity.deliveryTime))
        : new Date(),
      note: "",
      enableDeliveryLimit: false,
      enablePriceLimit: false
    });
  };

  handleClose = () => {
    if (this.state.loading) return;
    this.props.onClose();
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let val: any = e.target.value;
    if (e.target.type === "number") {
      val = val.replaceAll(/^0+/g, "0");
      if (!val.includes(".")) val = Number(val).toString();
      if (!val || Number(val) < 0) return;
    } else if (e.target.type === "date") {
      val = new Date(e.target.value);
    }
    // @ts-ignore
    this.setState({ [e.target.name]: val });
  };

  handleOrderCommodity = async () => {
    const { commodity } = this.props;
    const {
      loading,
      reference,
      amount,
      priceLimit,
      deliveryLimit,
      note,
      enablePriceLimit,
      enableDeliveryLimit
    } = this.state;
    if (!commodity || loading) return;
    this.setState({ loading: true });
    try {
      const emOrderData = {
        reference,
        amount: +amount,
        note,
        priceLimit: enablePriceLimit ? +priceLimit : null,
        deliveryLimit: enableDeliveryLimit ? deliveryLimit : null,
        user: userService.getUserId(),
        company: userService.getCompanyId()
      };
      const result = await dbService.callFunction("createExternalManufacturerOrder", [commodity._id, emOrderData]);
      if (result) {
        toast.success("Request successfully created");
        const message = `<https://www.admincentral.private-label-factory.com/externalOrder/${result._id.toString()}|REQ-${
          result.identifier
        }>: ${userService.getUserData().prename} ${userService.getUserData().surname} requested ${amount}kg of ${
          commodity.title.en
        }`;
        await slackService.sendMessage("#notifications-external", message);
        this.props.onClose();
        window.location.href = "/order/" + result._id.toString();
      } else toast.error("Request could not be created");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { commodity } = this.props;
    const {
      reference,
      amount,
      priceLimit,
      deliveryLimit,
      note,
      loading,
      enableDeliveryLimit,
      enablePriceLimit
    } = this.state;
    if (!commodity) return null;
    return (
      <Modal show={!!commodity} onHide={this.handleClose} size={"lg"} centered name="emCommodityReqeust">
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="kt-font-brand flaticon-shopping-basket mr-2" />
            Request Commodity <em>{commodity.title.en}</em>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Currently Required</label>
            <div className="col-9">
              <label
                className={
                  "col-3 col-form-label kt-font-dark kt-font-bold pl-0 " +
                  (commodity.amount ? "text-success" : "text-muted")
                }
              >
                {commodity.amount ? calculationUtils.formatAmount(commodity.amount, 2) : "-"}
              </label>
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Expected Price Range</label>
            <div className="col-9">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold pl-0 ">
                {commodity.price
                  ? commodity.price.min === commodity.price.max
                    ? baseUtils.formatEuro(commodity.price.min)
                    : `${baseUtils.formatEuro(commodity.price.min)} - ${baseUtils.formatEuro(commodity.price.max)}`
                  : "-,-- €"}
              </label>
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Title/Reference</label>
            <div className="col-9">
              <input
                className={"form-control " + (reference.trim() === "" ? "is-invalid" : "")}
                type="text"
                value={reference}
                name="reference"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Quantity</label>
            <div className="col-9">
              <div className="row p-0 m-0">
                <div className="col-7 pl-0 my-auto">
                  <input
                    type="range"
                    className="custom-range align-middle"
                    min={1}
                    max={1000}
                    name="amount"
                    value={amount}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-5 pr-0">
                  <div className="input-group">
                    <input
                      className={"form-control " + (+amount <= 0 ? "is-invalid" : "")}
                      type="number"
                      value={amount}
                      name="amount"
                      min={1}
                      onChange={this.handleChange}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">kg</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row mb-2 d-none">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">
              <input
                type="checkbox"
                className="align-middle mr-1"
                checked={enablePriceLimit}
                onChange={() => this.setState({ enablePriceLimit: !enablePriceLimit })}
              />
              Price Limit
            </label>
            <div className="col-9">
              {enablePriceLimit ? (
                <div className="row p-0 m-0">
                  <div className="col-7 pl-0 my-auto">
                    <input
                      type="range"
                      className="custom-range align-middle"
                      min={commodity.price ? commodity.price.min : 0}
                      max={commodity.price ? commodity.price.max * 1.5 : 0}
                      step={0.1}
                      name="priceLimit"
                      value={priceLimit}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-5 pr-0">
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="number"
                        value={priceLimit}
                        name="priceLimit"
                        min={0}
                        onChange={this.handleChange}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">€</span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <label className="col-form-label text-muted">No Price Limit</label>
              )}
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">
              <input
                type="checkbox"
                checked={enableDeliveryLimit}
                className="align-middle mr-1"
                onChange={() => this.setState({ enableDeliveryLimit: !enableDeliveryLimit })}
              />
              Latest Delivery Date
            </label>
            <div className="col-9">
              {enableDeliveryLimit ? (
                <DateInput value={deliveryLimit} onBlur={this.handleChange} name="deliveryLimit" />
              ) : (
                <label className="col-form-label text-muted ">No Delivery Limit</label>
              )}
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Note</label>
            <div className="col-9">
              <textarea className="form-control" name="note" rows={2} onChange={this.handleChange} value={note} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={"btn btn-secondary " + (loading && "disabled")}
            disabled={loading}
            onClick={this.handleClose}
          >
            Close
          </button>
          <button
            className={"btn btn-success " + (loading && "disabled")}
            disabled={loading}
            onClick={this.handleOrderCommodity}
          >
            {loading && (
              <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                <svg className="button-splash-spinner" viewBox="0 0 50 50">
                  <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                </svg>
              </div>
            )}
            Request
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EMCommodityRequestModal;
