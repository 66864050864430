import React, { PureComponent } from "react";

interface PriorityWidgetProps {
  priority: string;
}
interface PriorityWidgetState {}

class PriorityWidget extends PureComponent<PriorityWidgetProps, PriorityWidgetState> {
  render() {
    const { priority } = this.props;
    let badge = "";
    if (priority === "high") badge = "kt-badge--danger";
    if (priority === "medium") badge = "kt-badge--warning";
    if (priority === "low") badge = "kt-badge--success";
    return (
      <span style={{ width: 80 }}>
        <span className={"kt-badge kt-badge--inline kt-badge--pill " + badge}>{priority}</span>
      </span>
    );
  }
}

export default PriorityWidget;
