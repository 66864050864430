import React from "react";
import { Accordion } from "react-bootstrap";
import { DataContext } from "../../../context/dataContext";
import PackagingStockContent from "./PackagingStockContent";
import Pagination, { paginate } from "../../common/Pagination";
import { PackagingExtended } from "../StockListing";

interface PackagingStockAccordionListingProps {
  filteredPackagings: Array<PackagingExtended>;
  showDisabled: boolean;
  pageSize: number;
  currentPage: number;
  activeLevels: Array<boolean>;
  onContentToggle: (e: number) => void;
  onPageChange: (e: number) => void;
  onPageSizeChange: (e: number) => void;
  context: React.ContextType<typeof DataContext>;
}

const PackagingStockListing: React.FunctionComponent<PackagingStockAccordionListingProps> = ({
  showDisabled,
  pageSize,
  currentPage,
  activeLevels,
  onContentToggle,
  onPageChange,
  onPageSizeChange,
  filteredPackagings,
  context
}: PackagingStockAccordionListingProps) => {
  return (
    <>
      <Accordion activeKey={"1"}>
        {paginate(filteredPackagings, currentPage, pageSize).map((packagingExtended, index) => {
          return (
            <PackagingStockContent
              key={packagingExtended.packaging._id.toString()}
              isActive={activeLevels[index]}
              level={index}
              onToggleClick={onContentToggle}
              packagingExtended={packagingExtended}
              showDisabled={showDisabled}
              context={context}
            />
          );
        })}
      </Accordion>
      {filteredPackagings.length > 0 && (
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive px-4">
              <div className="kt-datatable__pager kt-datatable--paging-loaded justify-content-center">
                <Pagination
                  itemsCount={filteredPackagings.length}
                  pageSize={pageSize}
                  onPageChange={currentPage => onPageChange(currentPage)}
                  currentPage={currentPage}
                  onPageSizeChange={pageSize => onPageSizeChange(pageSize)}
                  additionalPageSizeClasses={"ml-2"}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PackagingStockListing;
