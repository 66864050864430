import React from "react";
import Select from "react-select";

interface DelayReasonsProps {
  reason: { value: string; label: string } | "";
  onChange: (value: { value: string; label: string } | "") => void;
}

export const DELAY_REASON = [
  { value: "delayedDelivery", label: "Delay in delivery" },
  { value: "missingPf", label: "Missing printing file" },
  { value: "productionDelay", label: "Delay in production" },
  { value: "commodityNotAvailable", label: "Commodity currently not available" }
];

const DelayReasons: React.FunctionComponent<DelayReasonsProps> = ({ reason, onChange }) => {
  return (
    <Select
      className="select-default"
      options={DELAY_REASON}
      isClearable={true}
      value={reason || { value: "", label: "No Notification" }}
      onChange={(e: any) => onChange(e || "")}
    />
  );
};

export default DelayReasons;
