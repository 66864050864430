import React, { PureComponent, useContext } from "react";
import { BSON } from "realm-web";
import { CalculationType, Preferences, SelectedCommoditiesDocument } from "../CustomTypes";
import calculationUtils from "../../../utils/calculationUtils";
import { DataContext } from "../../../context/dataContext";
import commodityUtils from "../../../utils/commodityUtils";
import { ProductTypes } from "../configuratorConstants";

interface CommoditiesCalculationDetailsProps {
  type: string;
  recipe: Array<SelectedCommoditiesDocument>;
  calculation: CalculationType;
  preferences: Preferences;
  onRecipeCalculationUpdate: (
    commodityId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    path: string,
    value: any,
    recalculate: boolean
  ) => void;
  onRecipeSupplierChange: (
    commodityId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    newSupplier: BSON.ObjectId | "ownstock" | "customer"
  ) => void;
}

interface CommoditiesCalculationDetailsState {}

class CommoditiesCalculationDetails extends PureComponent<
  CommoditiesCalculationDetailsProps,
  CommoditiesCalculationDetailsState
> {
  getPricePerUnit = () => {
    const { type } = this.props;
    if (type === ProductTypes.SERVICE) return "€ / Unit";
    else if ([ProductTypes.CUSTOM, ProductTypes.SOFTGEL].includes(type)) return "€ / k";
    else return "€ / kg";
  };

  render() {
    const { type, recipe, calculation, preferences, onRecipeCalculationUpdate, onRecipeSupplierChange } = this.props;

    return (
      <table className={"kt-datatable__table"}>
        <thead className="kt-datatable__head text-align-left">
          <tr className="kt-datatable__row" style={{ backgroundColor: "#fcfcfc" }}>
            <th className=" kt-datatable__cell">
              <span>
                <b>Commodities</b>
              </span>
            </th>
          </tr>
          <tr className="kt-datatable__row">
            <th className=" kt-datatable__cell">
              <span style={{ width: 150 }}>Title</span>
            </th>
            <th className=" kt-datatable__cell">
              <span style={{ width: 70 }}>Auto</span>
            </th>
            <th className=" kt-datatable__cell">
              <span style={{ width: 60 }}>Amount</span>
            </th>
            <th className=" kt-datatable__cell">
              <span style={{ width: 75 }}>Buffer</span>
            </th>

            <th className="kt-datatable__cell">
              <span style={{ width: 150 }}>Supplier</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>Total</span>
            </th>

            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>MOQ</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>{this.getPricePerUnit()}</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>€ / Unit</span>
            </th>
            <th className="kt-datatable__cell">
              <span style={{ width: 70 }}>Total €</span>
            </th>
          </tr>
        </thead>
        <React.Fragment>
          <tbody className="kt-datatable__body">
            {recipe.map(commodity => (
              <CommoditiesCalculationDetailsRow
                key={commodity._id.toString()}
                type={type}
                commodity={commodity}
                preferences={preferences}
                calculation={calculation}
                onRecipeCalculationUpdate={onRecipeCalculationUpdate}
                onRecipeSupplierChange={onRecipeSupplierChange}
              />
            ))}
          </tbody>
        </React.Fragment>
      </table>
    );
  }
}

interface CommoditiesCalculationDetailsRowProps {
  type: string;
  commodity: SelectedCommoditiesDocument;
  preferences: Preferences;
  calculation: CalculationType;
  onRecipeCalculationUpdate: (
    commodityId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    path: string,
    value: any,
    recalculate: boolean
  ) => void;
  onRecipeSupplierChange: (
    commodityId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    newSupplier: BSON.ObjectId | "ownstock" | "customer"
  ) => void;
}

const CommoditiesCalculationDetailsRow: React.FunctionComponent<CommoditiesCalculationDetailsRowProps> = React.memo(
  ({ type, commodity, preferences, calculation, onRecipeCalculationUpdate, onRecipeSupplierChange }) => {
    const context = useContext(DataContext);
    if (!calculation.priceDetails) return <></>;
    const matchingCalculation = commodity.calculations.find(calc => calc.id.toString() === calculation.id.toString())!;
    const priceDetails = calculation.priceDetails!.recipePrices.find(
      com => com._id.toString() === commodity._id.toString()
    )!;

    if (!priceDetails || !matchingCalculation) return <></>;

    const moq = matchingCalculation.supplier.price.moq;
    const totalAmount = matchingCalculation.totalAmount;
    const totalFormattedAmount =
      type === ProductTypes.SERVICE
        ? totalAmount
        : [ProductTypes.SOFTGEL, ProductTypes.CUSTOM].includes(type)
        ? totalAmount / 1000
        : totalAmount / (1000 * 1000);
    const matchingMOQ = totalFormattedAmount >= moq;

    const renderAmount = () => {
      let content;
      if (type === ProductTypes.SERVICE) {
        content = "1";
      } else if ([ProductTypes.SOFTGEL, ProductTypes.CUSTOM].includes(type)) {
        content = preferences.amountPerUnit;
      } else {
        content = calculationUtils.formatAmount(commodity.amount!.toString(), 2);
      }
      return (
        <td className="kt-datatable__cell">
          <span style={{ width: 60 }}>{content}</span>
        </td>
      );
    };

    const renderTotalAmount = () => {
      let content;
      if ([ProductTypes.SERVICE].includes(type)) {
        content = totalAmount;
      } else if ([ProductTypes.SOFTGEL, ProductTypes.CUSTOM].includes(type)) {
        content = totalAmount / 1000 + "k";
      } else {
        content = calculationUtils.formatAmount(totalAmount.toString(), 2);
      }
      return (
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }}>{content}</span>
        </td>
      );
    };

    const getMOQUnit = () => {
      if ([ProductTypes.SERVICE].includes(type)) {
        return " Unit(s)";
      } else if ([ProductTypes.CUSTOM, ProductTypes.SOFTGEL].includes(type)) {
        return "k";
      } else {
        return " kg";
      }
    };

    const getSupplierList = () => {
      const suppliers = context.suppliers;
      const commoditySuppliers = commodity.suppliers;
      let supplierList: Array<{ key: string | BSON.ObjectId; value: string }> = commoditySuppliers.map(supp => {
        const supplierName = suppliers.find(s => s._id.toString() === supp._id.toString())!.name;
        return { key: supp._id, value: supplierName };
      });
      supplierList.unshift({ key: "customer", value: "Customer (0€)" });
      const totalStock = commodityUtils.getAllStock(commodity, preferences.selectedManufacturer!._id);
      if (totalStock >= totalFormattedAmount) supplierList.unshift({ key: "ownstock", value: "Stock" });
      return supplierList;
    };

    const handleRecipeSupplierChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.currentTarget.value;
      const id = ["customer", "ownstock"].includes(value) ? value : new BSON.ObjectId(value);
      onRecipeSupplierChange(commodity._id, calculation.id, id as "ownstock" | "customer" | BSON.ObjectId);
    };

    return (
      <tr className="kt-datatable__row">
        <td className="kt-datatable__cell">
          <span style={{ width: 150 }}>
            <b>{commodity.title.en}</b>
          </span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }}>
            <div className="custom-control custom-switch ml-2" style={{ display: "inline" }}>
              <input
                type="checkbox"
                className="custom-control-input"
                id={"autoselect_" + commodity._id.toString() + calculation.id.toString()}
                checked={matchingCalculation.auto}
                onChange={() => {
                  onRecipeCalculationUpdate(commodity._id, calculation.id, "auto", !matchingCalculation.auto, false);
                }}
              />
              <label
                className="custom-control-label"
                htmlFor={"autoselect_" + commodity._id.toString() + calculation.id.toString()}
              />
            </div>
          </span>
        </td>
        {renderAmount()}
        <td className="kt-datatable__cell">
          <span style={{ width: 75 }}>
            <input
              type="number"
              className="form-control"
              value={matchingCalculation.buffer.toString()}
              disabled={commodity.type === "service"}
              min="0"
              onChange={e =>
                onRecipeCalculationUpdate(
                  commodity._id,
                  calculation.id,
                  "buffer",
                  Number(parseInt(e.target.value) || 0),
                  false
                )
              }
              onBlur={e =>
                onRecipeCalculationUpdate(
                  commodity._id,
                  calculation.id,
                  "buffer",
                  Number(parseInt(e.target.value) || 0),
                  true
                )
              }
              name="buffer"
            />
          </span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 150 }}>
            <select
              className="form-control"
              disabled={matchingCalculation.auto}
              value={matchingCalculation.supplier._id.toString()}
              onChange={handleRecipeSupplierChange}
            >
              {getSupplierList().map(supp => (
                <option key={supp.key.toString()} value={supp.key.toString()}>
                  {supp.value}
                </option>
              ))}
            </select>
          </span>
        </td>
        {renderTotalAmount()}
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }} className={"text-bold " + (matchingMOQ ? "" : "text-danger")}>
            {moq + getMOQUnit()}
          </span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }}>
            {matchingCalculation.price.toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR"
            }) + (matchingCalculation.incoterm ? `(${matchingCalculation.incoterm})` : "")}
          </span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }}>
            <b>
              {priceDetails.unitPrice.toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR"
              })}
            </b>
          </span>
        </td>
        <td className="kt-datatable__cell">
          <span style={{ width: 70 }}>
            {(matchingCalculation.totalPrice || 0).toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR"
            })}
          </span>
        </td>
      </tr>
    );
  }
);

export default CommoditiesCalculationDetails;
