import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { CustomOrder } from "../CustomTypes";
import { CustomerNote, OrdersDocument } from "../../../model/orders.types";
import userService from "../../../services/userService";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import notificationService, { R_INITIALNOTEONORDER } from "../../../services/notificationService";

const getDefaultState = () => {
  return {
    disabled: true,
    show: false,
    submitting: false,
    message: ""
  } as StartConversationModalState;
};

interface StartConversationModalProps {
  order: CustomOrder | OrdersDocument;
}

interface StartConversationModalState {
  disabled: boolean;
  show: boolean;
  submitting: boolean;
  message: string;
}

const StartConversationModal: React.FunctionComponent<StartConversationModalProps> = ({ order }) => {
  const [conversationModalState, setConversationModalState] = useState<StartConversationModalState>(getDefaultState());
  const { disabled, show, submitting, message } = conversationModalState; // state deconstructing

  const handleHide = () => {
    setConversationModalState(prevState => {
      return {
        ...prevState,
        show: false
      };
    });
  };

  const handleShow = () => {
    setConversationModalState(prevState => {
      return {
        ...prevState,
        show: true
      };
    });
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const disabled = value.trim().length === 0;
    setConversationModalState(prevState => {
      return {
        ...prevState,
        disabled: disabled,
        show: true,
        message: value
      };
    });
  };

  const handleSubmit = async () => {
    setConversationModalState(prevState => {
      return {
        ...prevState,
        submitting: true
      };
    });
    await performNoteSubmission();
  };

  const performNoteSubmission = useCallback(async () => {
    try {
      // building update object
      const note = {
        _id: new BSON.ObjectId(),
        date: new Date(),
        person: userService.getUserId(),
        note: message
      } as CustomerNote;

      // performing update
      const res = await dbOrderService.addCustomerNote(order._id, note);
      if (res && res.modifiedCount > 0) {
        toast.success("Note successfully added");
        notificationService.notify(R_INITIALNOTEONORDER, order._id);
      } else {
        toast.error("Error adding note");
      }
    } finally {
      setConversationModalState(getDefaultState());
    }
  }, [message]);

  return (
    <>
      <button className={"btn btn-label-brand btn-sm btn-bold"} onClick={handleShow}>
        <i className="flaticon2-chat" style={{ fontSize: "1.1rem" }} />
        Start Conversation
      </button>
      <Modal show={show} centered onHide={!submitting ? handleHide : undefined} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="kt-font-brand flaticon2-chat mr-2" />
            Initialize order notes conversation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-portlet__body">
            <div className="from-group row col-12 mb-1 p-0">
              <div className="alert alert-light" role="alert">
                <div className="alert-icon">
                  <i className="flaticon-information" />
                </div>
                <div className="alert-text">
                  Initialize a order related conversation note. The customer will be informed via notification at
                  SupplyCentral.
                </div>
              </div>
            </div>
            <div className="form-group row pr-3">
              <label className="col-2 col-form-label kt-font-dark kt-font-bold text-right">Message</label>
              <div className="col-10">
                <textarea
                  className="form-control"
                  rows={3}
                  placeholder={"Message"}
                  value={message}
                  name="message"
                  onChange={handleMessageChange}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" disabled={submitting} onClick={!submitting ? handleHide : undefined}>
            Close
          </button>
          <button
            className="btn btn-success"
            disabled={disabled || submitting}
            onClick={!disabled && !submitting ? handleSubmit : undefined}
          >
            {submitting ? (
              <>
                <div className="button-splash-spinner d-inline">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>{" "}
                Submitting
              </>
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StartConversationModal;
