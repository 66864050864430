import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import RelatedOrders from "../../common/RelatedOrders";
import { DataContext } from "../../../context/dataContext";
import accessUtils, { CREATELOCATIONS, EDITLOCATIONS } from "../../../utils/accessUtils";
import baseUtils from "../../../utils/baseUtils";
import dateUtils from "../../../utils/dateUtils";
import fileUtils from "../../../utils/fileUtils";
import userService from "../../../services/userService";
import { ORDERORDERCOMMODITIES, PRODUCTION, PRODUCTIONQUEUE, WAITING } from "../../../utils/orderUtils";
import { OrdersDocument } from "../../../model/orders.types";
import { CUSTOMER } from "../../../utils/commodityUtils";
import { PackagingsDocument } from "../../../model/packagings.types";
import { PackagingStock, PackagingStockDocument } from "../../../model/packagingStock.types";
import DateInput from "../../common/DateInput";
import UploadMaterialFileModal from "../../common/UploadMaterialFileModal";
import dbService, { PACKAGINGSTOCK } from "../../../services/dbService";
import toastUtils from "../../../utils/toastUtils";
import calculationUtils from "../../../utils/calculationUtils";

interface EditPackagingBatchModalProps {
  packaging: PackagingsDocument;
  stock?: PackagingStockDocument;
  location: BSON.ObjectId;
  context: React.ContextType<typeof DataContext>;
  create: boolean;
}

interface EditPackagingBatchModalState {
  batch: BatchInput;
  removedOrders: Array<BSON.ObjectId>;
  onSummary: boolean;
  show: boolean;
  showUploadFileModal: boolean;
}

interface BatchInput extends Omit<PackagingStock, "amount" | "price"> {
  amount: string;
  price: string;
  totalPrice: string;
}

class EditPackagingBatchModal extends PureComponent<EditPackagingBatchModalProps, EditPackagingBatchModalState> {
  constructor(props: EditPackagingBatchModalProps) {
    super(props);
    this.state = {
      batch: this.prepareBatchForInput(props.stock),
      removedOrders: [],
      onSummary: false,
      show: false,
      showUploadFileModal: false
    };
  }

  componentDidUpdate(
    prevProps: Readonly<EditPackagingBatchModalProps>,
    prevState: Readonly<EditPackagingBatchModalState>,
    snapshot?: any
  ) {
    const { stock, location } = this.props;
    if (!_.isEqual(stock, prevProps.stock) || !_.isEqual(location, prevProps.location)) {
      this.setState({ batch: this.prepareBatchForInput(this.props.stock) });
    }
  }

  /**
   * Generates a new, empty, batch.
   * @returns { PackagingStock } Empty batch
   */
  getDefaultBatch = (): PackagingStock => {
    return {
      packaging: this.props.packaging._id,
      supplier: "customer",
      amount: 0,
      lot: "",
      location: this.props.location,
      note: "",
      price: 0,
      stocked: new Date(),
      expiry: null,
      person: userService.getUserId(),
      files: [],
      timeline: []
    };
  };

  /**
   * Prepares the batch for input usage by using strings for the number fields.
   * @param batch: Batch that should be prepared
   * @returns { BatchInput } Batch prepared for input usage
   */
  prepareBatchForInput = (batch: PackagingStockDocument | undefined) => {
    const b = batch ? batch : this.getDefaultBatch();
    const batchCopy = _.omit(_.cloneDeep(b), ["amount", "price"]);
    return {
      ...batchCopy,
      amount: b.amount.toString(),
      price: b.price.toString(),
      totalPrice: (b.amount * b.price).toFixed(2)
    };
  };

  /**
   * Prepares the batch for database usage by using numbers for the number fields.
   * @param batch: Batch that should be prepared
   * @returns { Batch } Batch prepared for database usage
   */
  prepareBatchForDatabase = (batch: BatchInput) => {
    const batchCopy = _.omit(_.cloneDeep(batch), ["amount", "price", "expiry", "totalPrice"]);
    return { ...batchCopy, amount: +batch.amount, price: +batch.price, expiry: batch.expiry ? batch.expiry : null };
  };

  handleShow = () => this.setState({ show: true });
  handleHide = () =>
    this.setState({
      onSummary: false,
      show: false,
      showUploadFileModal: false,
      batch: this.prepareBatchForInput(this.props.stock),
      removedOrders: []
    });

  /**
   * Handles changing properties of the batch.
   * @param e: Event that triggered the change
   */
  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const batchEdited = _.cloneDeep(this.state.batch);
    const name = e.target.name;
    let val: string | Date | BSON.ObjectId = e.target.value;
    if (name === "stocked") {
      val = new Date(val);
      if (isNaN(val.getTime())) return;
    } else if (name === "expiry" && val) {
      val = new Date(val);
    }
    if (["supplier", "location"].includes(name) && BSON.ObjectId.isValid(val.toString())) {
      val = new BSON.ObjectId(val.toString());
    }
    if (name === "supplier" && val === CUSTOMER) batchEdited.price = "0";
    // @ts-ignore
    batchEdited[name] = val;
    this.setState({ batch: batchEdited });
  };

  /**
   * Handles changing a number value of the batch.
   * @param e: Event that triggered the change
   */
  handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const batchEdited = _.cloneDeep(this.state.batch);
    let val = e.target.value;
    val = val.replaceAll(/^0+/g, "0");
    if (!val.includes(".")) val = Number(val).toString();
    if (!val || Number(val) < 0) return;
    if (e.target.name === "totalPrice") {
      batchEdited.price = batchEdited.amount !== "0" ? Number((+val / +batchEdited.amount).toFixed(2)).toString() : "0";
    } else if (e.target.name === "price") {
      batchEdited.totalPrice = Number((+batchEdited.amount * +val).toFixed(2)).toString();
    } else if (e.target.name === "amount") {
      batchEdited.totalPrice = Number((+batchEdited.price * +val).toFixed(2)).toString();
    }
    // @ts-ignore
    batchEdited[e.target.name] = val;
    this.setState({ batch: batchEdited });
  };

  /**
   * Handles the blur of a number field. The value is casted to number and back to string.
   * @param e: Event that triggered the change
   */
  handleBlurNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = Number(e.target.value).toString();
    this.handleChangeNumber(e);
  };

  /**
   * Handles adding a new file for the batch
   * @param id: Not needed, kept for comparability
   * @param path: Path to the file
   * @param type: Not needed, kept for comparability
   * @param title: Title of the file
   */
  handleAddUpload = (id: BSON.ObjectId, path: string, type: string, title?: string) => {
    const batchEdited = _.cloneDeep(this.state.batch);
    const fileType = fileUtils.getFileExtension(path);
    batchEdited.files.push({
      _id: new BSON.ObjectId(),
      type: "file",
      date: new Date(),
      person: userService.getUserId(),
      path,
      title: title ? title : "",
      fileType: fileType === null ? "" : fileType[1],
      fileSize: 0
    });
    this.setState({ batch: batchEdited, showUploadFileModal: false });
  };

  handleDeleteUpload = (path: string) => {
    const batchEdited = _.cloneDeep(this.state.batch);
    batchEdited.files = batchEdited.files.filter(f => f.path !== path);
    this.setState({ batch: batchEdited });
  };

  /**
   * Handles the update of a batch
   */
  handleSaveBatch = async () => {
    const { stock, create, context } = this.props;
    const { batch, removedOrders } = this.state;
    const { updateDocumentInContext } = context;
    if (batch.supplier !== "customer") {
      batch.relatedOrders = [];
    } else {
      const rOString = removedOrders.map(rO => rO.toString());
      batch.relatedOrders = batch.relatedOrders!.filter(o => !rOString.includes(o.toString()));
    }
    const preparedBatch = this.prepareBatchForDatabase(batch);
    let success;
    let id: any;
    if (create) {
      const res = await dbService.insertDocument(PACKAGINGSTOCK, preparedBatch);
      success = !!res && !!res.insertedId;
      if (res) id = res.insertedId;
    } else if (stock && "_id" in stock && !create) {
      const res = await dbService.replaceDocument(PACKAGINGSTOCK, stock._id, preparedBatch);
      success = !!res && res.modifiedCount > 0;
      id = stock._id;
    } else {
      toast.error("Unknown stock. Could not update or create");
      console.error(JSON.stringify(stock));
      return;
    }
    await toastUtils.databaseOperationToast(success, "Stock updated successfully", "Error updating stock", () => {
      updateDocumentInContext(PACKAGINGSTOCK, id);
      this.handleHide();
    });
  };

  /**
   * Handles adding a new order to the batchEdited orders array.
   * @param e: Event that triggered the change
   */
  onOrdersChange = (e: any) => {
    const batchEdited = _.cloneDeep(this.state.batch);
    if (!batchEdited.relatedOrders) batchEdited.relatedOrders = [];
    batchEdited.relatedOrders.push(new BSON.ObjectId(e.value));
    this.setState({ batch: batchEdited });
  };

  /**
   * Handles removing an order from the batchEdited orders array
   * @param order: ID of the order that should be removed
   * @param remove: Flag that is needed for compatibility and should always be set
   */
  onOrdersRemove = (order: BSON.ObjectId, remove?: boolean) => {
    let removedOrders = _.cloneDeep(this.state.removedOrders);
    const isInRemoved = removedOrders.some(o => o.toString() === order.toString());
    if (remove && !isInRemoved) {
      removedOrders.push(order);
    } else if (!remove && isInRemoved) {
      // Undo removal
      removedOrders = removedOrders.filter(o => o.toString() !== order.toString());
    }
    this.setState({ removedOrders });
  };

  /**
   * Checks the batch for invalid data. The LOT, amount and price have to be filled out.
   */
  checkInvalidData = () => {
    const { create } = this.props;
    const { batch, removedOrders } = this.state;
    let invalid = (create && +batch.amount <= 0) || (!create && +batch.amount < 0) || batch.lot.trim() === "";
    if (batch.supplier === CUSTOMER) {
      const rOString = removedOrders.map(rO => rO.toString());
      const remainingOrders = batch.relatedOrders?.filter(o => !rOString.includes(o.toString()));
      return (
        invalid ||
        !batch.relatedOrders ||
        batch.relatedOrders.length === 0 ||
        !remainingOrders ||
        remainingOrders.length === 0
      );
    }
    return invalid || +batch.price <= 0;
  };

  render() {
    const { stock, packaging, context, create } = this.props;
    const { batch, removedOrders, onSummary, show, showUploadFileModal } = this.state;
    const packagingStock = context.packagingStock.filter(pS => pS.packaging.toString() === packaging._id.toString());
    const invalid = this.checkInvalidData();
    let averagePrice = calculationUtils.getAverageStockPrice(packagingStock);
    let priceDiffersToMuch = false;
    const priceDifference = Math.abs(1 - +batch.price / averagePrice);
    if (priceDifference > 0.3) {
      priceDiffersToMuch = true;
    }
    const canChange = create
      ? accessUtils.canCreateData(CREATELOCATIONS.PACKAGINGSTOCK)
      : accessUtils.canEditData(EDITLOCATIONS.PACKAGINGSTOCK);

    const isCustomerStock = batch.supplier === CUSTOMER;
    let orders: Array<OrdersDocument> = [];
    if (isCustomerStock) {
      orders = context.orders.filter(
        o =>
          [ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION].includes(o.state) &&
          o.settings.manufacturer.toString() === batch.location.toString() &&
          (!batch.relatedOrders || !batch.relatedOrders.some(bO => bO.toString() === o._id.toString())) &&
          o.calculations[0].packagings.some(p => p._id.toString() === packaging._id.toString())
      );
    }

    return (
      <>
        <button
          className={
            "btn btn-sm mr-1" +
            (canChange ? "" : " disabled") +
            (stock && stock.disabled ? " btn-secondary" : " btn-success")
          }
          onClick={canChange ? this.handleShow : undefined}
          disabled={!canChange}
        >
          {create ? (
            <>
              <i className="fa fa-plus" /> Add new Batch
            </>
          ) : (
            <i className={"fas pr-0 " + (stock && stock.disabled ? "fa-info-circle" : "fa-pen")} />
          )}
        </button>
        <Modal
          show={show}
          onHide={this.handleHide}
          centered
          size="lg"
          style={showUploadFileModal ? { zIndex: "99" } : {}}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {onSummary ? (
                <>
                  Data summary for Batch <em>{batch.lot}</em>
                </>
              ) : create ? (
                "Create new Batch"
              ) : (
                <>
                  Batch <em>{stock && stock.lot}</em> Settings
                </>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {onSummary ? (
              <>
                <h6 className="text-dark font-weight-bolder text-center">
                  Please check that the inserted data is valid!
                </h6>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Lot</label>
                  <div className="col-10">
                    <input type="text" className="form-control" disabled value={batch.lot} name="lot" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Price / kg</label>
                  <div className="col-4">
                    <div className="input-group">
                      <input type="number" className="form-control" disabled value={batch.price} name="price" />
                      <div className="input-group-append">
                        <span className="input-group-text">€</span>
                      </div>
                    </div>
                  </div>
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Total price</label>
                  <div className="col-4">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        disabled
                        value={batch.totalPrice}
                        name="totalPrice"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">€</span>
                      </div>
                    </div>
                  </div>
                </div>
                {packagingStock.length !== 0 &&
                  averagePrice !== 0 &&
                  priceDiffersToMuch &&
                  batch.supplier !== CUSTOMER && (
                    <div className="alert alert-danger mt-4" role="alert">
                      <div className="alert-icon">
                        <i className="flaticon-exclamation-1" />
                      </div>
                      <div className="alert-text">
                        Price differs in <b>{(Math.round(priceDifference * 100 * 100) / 100).toString() + "%"}</b> -
                        please check that you have entered the price per kg!
                        <br />
                        The current average price is <b>{baseUtils.formatEuro(averagePrice)}</b>
                      </div>
                    </div>
                  )}
              </>
            ) : (
              <>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Lot</label>
                  <div className="col-10">
                    <input
                      type="text"
                      className="form-control"
                      value={batch.lot}
                      name="lot"
                      onChange={(!!stock && stock.disabled) || !create ? undefined : this.handleChange}
                      placeholder="LOT"
                      disabled={(!!stock && stock.disabled) || !create}
                    />
                  </div>
                </div>
                {stock && !stock.disabled && (
                  <div className="alert alert-warning" role="alert">
                    <div className="alert-icon">
                      <i className="flaticon-warning" />
                    </div>
                    <div className="alert-text">
                      Please avoid editing prices or merging different batches, as it will change corresponding orders.
                      <br />
                      Each batch should be handled independently.
                    </div>
                  </div>
                )}
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Amount</label>
                  <div className="col-10">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        min={0}
                        value={batch.amount}
                        name="amount"
                        onChange={this.handleChangeNumber}
                        onBlur={this.handleBlurNumber}
                        disabled={!!stock && stock.disabled}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">pcs.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Supplier</label>
                  <div className="col-10">
                    <select
                      className="form-control"
                      value={batch.supplier.toString()}
                      name="supplier"
                      onChange={(!!stock && stock.disabled) || !create ? undefined : this.handleChange}
                      disabled={(!!stock && stock.disabled) || !create}
                    >
                      <option value="customer">Customer</option>
                      <option value="ownstock">Own Stock</option>
                      {context.suppliers
                        .sort((s1, s2) => s1.name.localeCompare(s2.name))
                        .map(s => (
                          <option key={s._id.toString()} value={s._id.toString()}>
                            {s.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Price / item</label>
                  <div className="col-4">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        value={batch.price}
                        name="price"
                        onChange={this.handleChangeNumber}
                        onBlur={this.handleBlurNumber}
                        disabled={(!!stock && stock.disabled) || isCustomerStock}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">€</span>
                      </div>
                    </div>
                  </div>
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Total price</label>
                  <div className="col-4">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        value={batch.totalPrice}
                        name="totalPrice"
                        onChange={this.handleChangeNumber}
                        onBlur={this.handleBlurNumber}
                        disabled={(!!stock && stock.disabled) || isCustomerStock}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">€</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Location</label>
                  <div className="col-10">
                    <select
                      className="form-control"
                      value={batch.location.toString()}
                      name="location"
                      disabled={create || (!!stock && stock.disabled)}
                      onChange={this.handleChange}
                    >
                      {context.manufacturers.map(m => (
                        <option key={m._id.toString()} value={m._id.toString()}>
                          {m.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Since</label>
                  <div className="col-10">
                    <DateInput
                      value={batch.stocked}
                      onBlur={this.handleChange}
                      name={"stocked"}
                      disabled={!!stock && stock.disabled}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Expiry (optional)</label>
                  <div className="col-10">
                    <DateInput
                      value={batch.expiry}
                      onBlur={this.handleChange}
                      name={"expiry"}
                      disabled={!!stock && stock.disabled}
                      allowClear={true}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Note (optional)</label>
                  <div className="col-10">
                    <textarea
                      className="form-control"
                      value={batch.note}
                      name="note"
                      placeholder="Important notes, e.g. corresponding order"
                      onChange={this.handleChange}
                      disabled={!!stock && stock.disabled}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right text-dark kt-font-bold">Files (optional)</label>
                  <div className="col-10">
                    {batch.files.map((f, key) => (
                      <div className="kt-widget4 mb-2" key={f.path + f.date.toString() + key}>
                        <div className="kt-widget4__item">
                          <div className="kt-widget4__pic kt-widget4__pic--icon">
                            <img src={process.env.PUBLIC_URL + "/media/icons/pdf_icon.png"} alt="pdf icon" />
                          </div>
                          <div className="kt-widget4__info">
                            <div>
                              <a
                                href={f.path}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="kt-widget4__username mr-2"
                              >
                                {(f.title ? f.title : "File #" + (key + 1)) + " - " + baseUtils.formatDate(f.date)}
                              </a>
                            </div>
                            <p className="kt-widget4__text">{dateUtils.getTimeAgo(f.date)}</p>
                          </div>
                          {(!stock || !stock.disabled) && (
                            <button
                              className="btn btn-danger btn-sm p-2"
                              onClick={() => this.handleDeleteUpload(f.path)}
                            >
                              <i className="flaticon2-cross px-1" />
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                    {(!stock || !stock.disabled) && (
                      <>
                        <button
                          className="btn btn-secondary"
                          onClick={() => this.setState({ showUploadFileModal: true })}
                        >
                          Upload
                        </button>
                        {showUploadFileModal && (
                          <UploadMaterialFileModal
                            material={packaging}
                            type=""
                            addUpload={this.handleAddUpload}
                            onClose={() => this.setState({ showUploadFileModal: false })}
                            hideSupplier={true}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
                {isCustomerStock && (
                  <div className="form-group row">
                    <label className="col-2 col-form-label text-right text-dark kt-font-bold">Related Orders</label>
                    <div className="col-10">
                      <Select
                        className="select-default"
                        options={orders.map(o => {
                          return { value: o._id.toString(), label: "AT-" + o.identifier + " " + o.title };
                        })}
                        value={{ value: "", label: "Select Orders" }}
                        onChange={(e: any) => this.onOrdersChange(e)}
                      />
                      <RelatedOrders
                        orders={batch.relatedOrders || []}
                        context={context}
                        removedOrders={removedOrders}
                        onRelatedOrder={this.onOrdersRemove}
                        alwaysRemovable={true}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </Modal.Body>
          {(!stock || !stock.disabled) && (
            <Modal.Footer>
              <button
                className="btn btn-secondary"
                onClick={() => (onSummary ? this.setState({ onSummary: false }) : this.handleHide())}
              >
                {onSummary ? "Back" : "Close"}
              </button>
              <button
                className={"btn btn-success" + (invalid ? " disabled" : "")}
                onClick={() => (onSummary ? this.handleSaveBatch() : this.setState({ onSummary: true }))}
                disabled={invalid}
              >
                {onSummary ? "Save changes" : "Next"}
              </button>
            </Modal.Footer>
          )}
        </Modal>
      </>
    );
  }
}

export default EditPackagingBatchModal;
