import _ from "lodash";
import { BSON } from "realm-web";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import i18n from "../../../../translations/i18n";
import {
  DEFAULTDIMENSIONS,
  DEFAULTMAXWEIGHT,
  DEFAULTWEIGHTUNIT,
  getWarehouseLabel
} from "../../../../utils/warehouseUtils";
import { useWarehouseContext, WarehouseContext } from "../../../../context/warehouseContext";
import {
  Dimensions,
  PackagingUnitShape,
  PhysicalWarehouse,
  StorageSpace,
  WarehouseDefinition,
  WarehouseTypes
} from "../../../../model/configuration/warehouseConfiguration.types";
import dbService, { CONFIGURATION, UpdateAction } from "../../../../services/dbService";
import { getConfigTimelineEntry } from "../../../../utils/warehouseConfigUtils";
import toastUtils from "../../../../utils/toastUtils";
import { LengthUnit, ModalMode, NumValue } from "../../../../model/common.types";
import { getNumericValue } from "../../../../utils/baseUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";

interface CreatePackagingUnitModalProps {
  mode: ModalMode;
  storageSpaceEdit?: StorageSpace;
  warehouse?: WarehouseDefinition;
  warehouseArea?: PhysicalWarehouse;
  isInUse?: boolean;
}

interface CreateStorageSpacesModalState {
  storageSpaceNo: string;
  warehouse: {
    value: string;
    warehouse: WarehouseDefinition | undefined;
  };
  warehouseArea: {
    value: string;
    warehouse: PhysicalWarehouse | undefined;
  };
  dimensions: Dimensions;
  maxWeight: NumValue;
}

const getDefaultStorageSpace = (
  warehouseContext: WarehouseContext,
  mode: ModalMode,
  storageSpaceEdit?: StorageSpace,
  warehouse?: WarehouseDefinition,
  warehouseArea?: PhysicalWarehouse
): CreateStorageSpacesModalState => {
  if ((mode === ModalMode.EDIT || mode === ModalMode.DELETE) && storageSpaceEdit && warehouse && warehouseArea) {
    return {
      storageSpaceNo: storageSpaceEdit.storageSpaceNo,
      warehouse: {
        value: warehouse._id.toString(),
        warehouse: warehouse
      },
      warehouseArea: {
        value: warehouseArea._id.toString(),
        warehouse: warehouseArea
      },
      dimensions: storageSpaceEdit.dimensions || DEFAULTDIMENSIONS,
      maxWeight: storageSpaceEdit.maxWeight || DEFAULTMAXWEIGHT
    };
  } else {
    const firstWarehouse = warehouseContext.configuration?.values.warehouseStructure[0];
    const firstPhysicalWarehouse = firstWarehouse?.physicalWarehouses[0];
    return {
      storageSpaceNo: "",
      warehouse: {
        value: firstWarehouse?._id.toString() ?? "",
        warehouse: firstWarehouse
      },
      warehouseArea: {
        value: firstPhysicalWarehouse?._id.toString() ?? "",
        warehouse: firstPhysicalWarehouse
      },
      dimensions: DEFAULTDIMENSIONS,
      maxWeight: DEFAULTMAXWEIGHT
    };
  }
};

const CreateStorageSpacesModal: React.FunctionComponent<CreatePackagingUnitModalProps> = ({
  mode,
  storageSpaceEdit,
  warehouse,
  warehouseArea,
  isInUse
}) => {
  const warehouseContext = useWarehouseContext();

  const [show, setShow] = useState<boolean>(false);
  const [state, setState] = useState<CreateStorageSpacesModalState>(
    getDefaultStorageSpace(warehouseContext, mode, storageSpaceEdit, warehouse, warehouseArea)
  );
  const [saving, setSaving] = useState<boolean>(false);

  const warehouses = useMemo(() => {
    return warehouseContext.configuration?.values.warehouseStructure.map(w => {
      if (w.physicalWarehouses.some(pw => pw.type === WarehouseTypes.DIRECTLYMANAGED))
        return { value: w._id.toString(), label: getWarehouseLabel(w), warehouse: w };
    });
  }, [warehouseContext.configuration]);

  const physicalWarehouses = useMemo(() => {
    return state.warehouse.warehouse?.physicalWarehouses.map(pw => {
      if (pw.type === WarehouseTypes.DIRECTLYMANAGED)
        return { value: pw._id.toString(), label: getWarehouseLabel(pw), warehouse: pw };
    });
  }, [state.warehouse]);

  const storageSpaceShapeOptions = useMemo(() => {
    return [
      { value: PackagingUnitShape.ROUND, label: i18n.t("warehouse:round") },
      {
        value: PackagingUnitShape.ANGULAR,
        label: i18n.t("warehouse:angular")
      }
    ];
  }, []);

  const [errors, warnings] = useMemo(() => {
    const { storageSpaceNo, warehouse, warehouseArea, dimensions } = state;
    const newErrors: Array<string> = [];
    const warnings: Array<string> = [];

    if (dimensions.length === 0 || dimensions.width === 0 || dimensions.height === 0) {
      warnings.push(i18n.t("warehouse:storageSpaceDimensionWarning"));
    }
    if (storageSpaceNo === "") newErrors.push(i18n.t("warehouse:storageSpaceNumberMissing"));
    if (
      mode === ModalMode.CREATE &&
      warehouseContext.configuration?.values.warehouseStructure.some(wh =>
        wh.physicalWarehouses.some(pw =>
          pw.storageSpaces?.some(
            stSp =>
              stSp.storageSpaceNo === storageSpaceNo &&
              wh._id.toString() === warehouse.value &&
              pw._id.toString() === warehouseArea.value
          )
        )
      )
    )
      newErrors.push(i18n.t("warehouse:storageSpaceAlreadyExists"));
    if (
      !_.isEqual(
        { length: dimensions.length, width: dimensions.width, height: dimensions.height },
        { length: DEFAULTDIMENSIONS.length, width: DEFAULTDIMENSIONS.width, height: DEFAULTDIMENSIONS.height }
      )
    ) {
      if (dimensions.length === 0 || dimensions.width === 0 || dimensions.height === 0) {
        newErrors.push(i18n.t("warehouse:storageSpaceDimensionError"));
      }
    }
    return [newErrors, warnings];
  }, [state, mode, warehouseContext.configuration]);

  const handleShow = () => {
    setShow(true);
    setState(getDefaultStorageSpace(warehouseContext, mode, storageSpaceEdit, warehouse, warehouseArea));
  };
  const handleClose = () => setShow(false);

  const handleChangeWarehouse = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newWarehouse = e.currentTarget.value;
      const warehouse = warehouseContext.configuration?.values.warehouseStructure.find(
        w => w._id.toString() === newWarehouse
      );
      if (warehouse)
        setState(prevState => {
          return {
            ...prevState,
            warehouse: {
              value: warehouse._id.toString(),
              warehouse
            }
          };
        });
    },
    [warehouseContext.configuration?.values.warehouseStructure]
  );

  const handleChangeWarehouseArea = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newWarehouseArea = e.currentTarget.value;
      const warehouseArea = state.warehouse.warehouse?.physicalWarehouses.find(
        w => w._id.toString() === newWarehouseArea
      );
      if (warehouseArea)
        setState(prevState => {
          return {
            ...prevState,
            warehouseArea: {
              value: warehouseArea._id.toString(),
              warehouse: warehouseArea
            }
          };
        });
    },
    [state.warehouse]
  );

  const handleChangeStorageSpaceNo = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const storageSpaceNo = e.currentTarget.value;
    setState(prevState => {
      return { ...prevState, storageSpaceNo };
    });
  }, []);

  const handleChangeDimensions = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { dimensions } = state;
      const newDimensions = _.cloneDeep(dimensions);
      const newDimension = getNumericValue(e, false);
      _.set(newDimensions, e.currentTarget.name, Number(newDimension));
      setState(prevState => {
        return { ...prevState, dimensions: newDimensions };
      });
    },
    [state.dimensions]
  );

  const handleChangeSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const dimensions = _.cloneDeep(state.dimensions);
      const { name, value } = e.currentTarget;
      switch (name) {
        case "unit":
          dimensions.unit = value;
          break;
        case "shape":
          const newShape = value as PackagingUnitShape; // PackagingUnitShape is used for storage space and packaging dimensions
          dimensions.shape = newShape;
          break;
      }
      setState(prevState => {
        return { ...prevState, dimensions };
      });
    },
    [state.dimensions]
  );

  const handleChangeMaxWeight = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const maxWeight = _.cloneDeep(state.maxWeight);
      const updatedMaxWeight = getNumericValue(e, false);
      maxWeight.value = Number(updatedMaxWeight);
      setState(prevState => {
        return { ...prevState, maxWeight };
      });
    },
    [state.maxWeight]
  );

  const handleSave = useCallback(async () => {
    setSaving(true);
    const newStorageSpace: StorageSpace = getStorageSpace();
    try {
      if (warehouseContext.configuration) {
        const newConfig = _.cloneDeep(warehouseContext.configuration.values);
        const indexWarehouse = newConfig.warehouseStructure.findIndex(w => w._id.toString() === state.warehouse.value);
        const indexWarehouseArea = newConfig.warehouseStructure[indexWarehouse].physicalWarehouses.findIndex(
          pw => pw._id.toString() === state.warehouseArea.value
        );
        const existingStorageSpaces =
          newConfig.warehouseStructure[indexWarehouse].physicalWarehouses[indexWarehouseArea].storageSpaces;

        // Edit
        if (mode === ModalMode.EDIT && storageSpaceEdit && existingStorageSpaces) {
          const existingIndex = existingStorageSpaces.findIndex(
            eStSp => eStSp._id.toString() === storageSpaceEdit._id.toString()
          );
          const updatedStorageSpaces =
            newConfig.warehouseStructure[indexWarehouse].physicalWarehouses[indexWarehouseArea].storageSpaces;
          if (!updatedStorageSpaces) {
            toast.error(i18n.t("warehouse:storageSpaceEditError"));
            return;
          }
          updatedStorageSpaces[existingIndex] = newStorageSpace;

          const actions: Array<UpdateAction> = [
            {
              collection: CONFIGURATION,
              filter: { _id: warehouseContext.configuration?._id },
              update: {
                "values.warehouseStructure.$[wFilter].physicalWarehouses.$[pwFilter].storageSpaces.$[stSpFilter]":
                  newStorageSpace,
                lastUpdate: new Date()
              },
              arrayFilters: [
                {
                  "wFilter._id": state.warehouse.warehouse?._id
                },
                {
                  "pwFilter._id": state.warehouseArea.warehouse?._id
                },
                { "stSpFilter._id": storageSpaceEdit._id }
              ],
              push: {
                timeline: getConfigTimelineEntry(warehouseContext.configuration.values, newConfig)
              }
            }
          ];
          const res = await dbService.transaction(actions);
          await toastUtils.databaseOperationToast(
            res,
            i18n.t("warehouse:storageSpaceEditSuccess"),
            i18n.t("warehouse:storageSpaceEditError"),
            () => {
              setShow(false);
            }
          );

          // Delete
        } else if (!isInUse && mode === ModalMode.DELETE && storageSpaceEdit && existingStorageSpaces) {
          const existingIndex = existingStorageSpaces.findIndex(
            eStSp => eStSp._id.toString() === storageSpaceEdit._id.toString()
          );
          newConfig.warehouseStructure[indexWarehouse].physicalWarehouses[indexWarehouseArea].storageSpaces?.splice(
            existingIndex,
            1
          );
          const action: UpdateAction = {
            collection: CONFIGURATION,
            filter: { _id: warehouseContext.configuration._id },
            update: { lastUpdate: new Date() },
            pull: {
              "values.warehouseStructure.$[wFilter].physicalWarehouses.$[pwFilter].storageSpaces": {
                _id: storageSpaceEdit._id
              }
            },
            push: {
              timeline: getConfigTimelineEntry(warehouseContext.configuration.values, newConfig)
            },
            arrayFilters: [
              {
                "wFilter._id": state.warehouse.warehouse?._id
              },
              {
                "pwFilter._id": state.warehouseArea.warehouse?._id
              }
            ]
          };
          const res = await dbService.transaction([action]);
          await toastUtils.databaseOperationToast(
            res,
            i18n.t("warehouse:storageSpaceDeleteSuccess"),
            i18n.t("common:storageSpaceDeleteError"),
            () => {
              setShow(false);
            }
          );

          // create
        } else {
          newConfig.warehouseStructure[indexWarehouse].physicalWarehouses[indexWarehouseArea].storageSpaces =
            existingStorageSpaces ? existingStorageSpaces.concat([newStorageSpace]) : [newStorageSpace];
          const action: UpdateAction = {
            collection: CONFIGURATION,
            filter: { _id: warehouseContext.configuration?._id },
            update: { lastUpdate: new Date() },
            push: {
              "values.warehouseStructure.$[wFilter].physicalWarehouses.$[pwFilter].storageSpaces": newStorageSpace,
              timeline: getConfigTimelineEntry(warehouseContext.configuration.values, newConfig)
            },
            arrayFilters: [
              {
                "wFilter._id": state.warehouse.warehouse?._id
              },
              {
                "pwFilter._id": state.warehouseArea.warehouse?._id
              }
            ]
          };
          const res = await dbService.transaction([action]);
          await toastUtils.databaseOperationToast(
            res,
            i18n.t("warehouse:storageSpaceCreateSuccess"),
            i18n.t("warehouse:storageSpaceCreateError"),
            () => {
              setShow(false);
            }
          );
        }
      }
    } catch (e) {
      toast.error(
        `${
          mode === ModalMode.DELETE
            ? i18n.t("warehouse:storageSpaceDeleteError")
            : mode === ModalMode.EDIT
            ? i18n.t("warehouse:storageSpaceEditError")
            : i18n.t("warehouse:storageSpaceCreateError")
        }: ` + e
      );
    } finally {
      setState(getDefaultStorageSpace(warehouseContext, mode, storageSpaceEdit, warehouse, warehouseArea));
      setSaving(false);
    }
  }, [state, warehouseContext.configuration, mode, isInUse, storageSpaceEdit]);

  const getStorageSpace = useCallback(() => {
    return {
      _id: storageSpaceEdit ? storageSpaceEdit._id : new BSON.ObjectId(),
      storageSpaceNo: state.storageSpaceNo.trim(),
      ...(state.dimensions.height !== 0 &&
        state.dimensions.width !== 0 &&
        state.dimensions.length !== 0 && { dimensions: state.dimensions }),
      ...(state.maxWeight.value !== 0 && { maxWeight: state.maxWeight })
    };
  }, [state, storageSpaceEdit]);

  return (
    <>
      {mode === ModalMode.DELETE ? (
        <ErrorOverlayButton
          className="btn btn-secondary"
          buttonText={
            <>
              <i className="fa fa-trash" />
              {i18n.t("warehouse:delete")}
            </>
          }
          errors={isInUse ? [i18n.t("warehouse:storageSpaceUsed")] : []}
          onClick={handleShow}
        />
      ) : (
        <button className={"btn " + (mode === ModalMode.EDIT ? "btn-secondary" : "btn-primary")} onClick={handleShow}>
          <i className={mode === ModalMode.EDIT ? "fa fa-pen" : " fas fa-plus-circle"} />
          {mode === ModalMode.EDIT ? i18n.t("common:edit") : i18n.t("common:create")}
        </button>
      )}
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {mode === ModalMode.EDIT
              ? i18n.t("warehouse:storageSpaceEdit")
              : mode === ModalMode.DELETE
              ? i18n.t("warehouse:storageSpaceDeletion")
              : i18n.t("warehouse:storageSpaceCreation")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">{i18n.t("warehouse:warehouse")}</div>
            <div className="col-8">
              <select
                value={state.warehouse.value}
                className="form-control"
                disabled={mode !== ModalMode.CREATE}
                placeholder={i18n.t("warehouse:warehouse")}
                onChange={handleChangeWarehouse}
              >
                {warehouses ? (
                  warehouses.map(ws => {
                    if (ws) {
                      return (
                        <option key={ws.value} value={ws.value}>
                          {ws.label}
                        </option>
                      );
                    }
                  })
                ) : (
                  <option disabled></option>
                )}
              </select>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">{i18n.t("warehouse:warehouseArea")}</div>
            <div className="col-8">
              <select
                value={state.warehouseArea.value}
                className="form-control"
                disabled={mode !== ModalMode.CREATE}
                placeholder={i18n.t("warehouse:warehouseArea")}
                onChange={handleChangeWarehouseArea}
              >
                {physicalWarehouses ? (
                  physicalWarehouses.map(pw => {
                    if (pw) {
                      return (
                        <option key={pw.value} value={pw.value}>
                          {pw.label}
                        </option>
                      );
                    }
                  })
                ) : (
                  <option disabled></option>
                )}
              </select>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">{i18n.t("warehouse:storageSpaceNumber")}</div>
            <div className="col-8">
              <input
                className="form-control"
                value={state.storageSpaceNo}
                disabled={mode === ModalMode.DELETE}
                onChange={handleChangeStorageSpaceNo}
              />
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">
              <span>{i18n.t("warehouse:length")}</span>
              <small className="text-muted"> ({i18n.t("warehouse:optional")})</small>
            </div>
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  value={state.dimensions.length}
                  name="length"
                  type="number"
                  disabled={mode === ModalMode.DELETE}
                  onChange={handleChangeDimensions}
                />
                <div className="input-group-append">
                  <LengthUnitSelect
                    selectedUnit={state.dimensions.unit}
                    disabled={mode === ModalMode.DELETE}
                    onUnitChange={handleChangeSelect}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">
              <span>{i18n.t("packaging:width")}</span>
              <small className="text-muted"> ({i18n.t("warehouse:optional")})</small>
            </div>
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  value={state.dimensions.width}
                  name="width"
                  type="number"
                  disabled={mode === ModalMode.DELETE}
                  onChange={handleChangeDimensions}
                />
                <div className="input-group-append">
                  <LengthUnitSelect
                    selectedUnit={state.dimensions.unit}
                    disabled={mode === ModalMode.DELETE}
                    onUnitChange={handleChangeSelect}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">
              <span>{i18n.t("packaging:height")}</span>
              <small className="text-muted"> ({i18n.t("warehouse:optional")})</small>
            </div>
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  value={state.dimensions.height}
                  name="height"
                  type="number"
                  disabled={mode === ModalMode.DELETE}
                  onChange={handleChangeDimensions}
                />
                <div className="input-group-append">
                  <LengthUnitSelect
                    selectedUnit={state.dimensions.unit}
                    disabled={mode === ModalMode.DELETE}
                    onUnitChange={handleChangeSelect}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">
              <span>{i18n.t("packaging:shape")}</span>
              <small className="text-muted"> ({i18n.t("warehouse:optional")})</small>
            </div>
            <div className="col-8">
              <select
                className="form-control"
                value={state.dimensions.shape}
                disabled={mode === ModalMode.DELETE}
                name="shape"
                onChange={handleChangeSelect}
              >
                {storageSpaceShapeOptions.map(s => (
                  <option key={s.value} value={s.value}>
                    {s.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">
              <span>{i18n.t("warehouse:maxWeight")}</span>
              <small className="text-muted"> ({i18n.t("warehouse:optional")})</small>
            </div>
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  value={state.maxWeight.value}
                  name="maxWeight"
                  type="number"
                  disabled={mode === ModalMode.DELETE}
                  onChange={handleChangeMaxWeight}
                />
                <div className="input-group-append">
                  <span className="input-group-text">{DEFAULTWEIGHTUNIT}</span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            {i18n.t("common:close")}
          </button>
          {mode === ModalMode.DELETE ? (
            <ErrorOverlayButton
              errors={isInUse ? [i18n.t("warehouse:storageSpaceUsed")] : []}
              className="btn btn-danger"
              saving={saving}
              buttonText={i18n.t("warehouse:delete")}
              onClick={handleSave}
            />
          ) : (
            <ErrorOverlayButton
              buttonText={i18n.t("common:save")}
              className="btn btn-success"
              onClick={handleSave}
              errors={errors}
              warnings={warnings}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface LengthUnitSelectProps {
  selectedUnit: string;
  disabled: boolean;
  onUnitChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const LengthUnitSelect: React.FunctionComponent<LengthUnitSelectProps> = ({ selectedUnit, disabled, onUnitChange }) => {
  return (
    <select className="form-control" value={selectedUnit} onChange={onUnitChange} name="unit" disabled={disabled}>
      {Object.entries(LengthUnit).map(([key, value]) => (
        <option key={key} value={value}>
          {value}
        </option>
      ))}
    </select>
  );
};

export default CreateStorageSpacesModal;
