import React, { PureComponent } from "react";
import { pricing, pricingCommodities } from "../../../../model/orders.types";
import dateUtils from "../../../../utils/dateUtils";

interface OrderProgressbarProps {
  price: pricing | pricingCommodities;
}

interface OrderProgressbarState {}

class OrderProgressbar extends PureComponent<OrderProgressbarProps, OrderProgressbarState> {
  render() {
    const { price } = this.props;
    let width = "100%";
    let color = "bg-success";
    const daysUntilArrival = dateUtils.getDaysUntil(price.eta ? price.eta : new Date());
    if (!price.ordered && !price.delivered) return <></>;
    if (!price.eta && price.ordered && !price.delivered) {
      color = "bg-warning";
    } else if (price.ordered && !price.delivered) {
      const commonWidth =
        (dateUtils.getDaysBetween(new Date(), price.ordered) / dateUtils.getDaysBetween(price.ordered, price.eta!)) *
        100;
      width = commonWidth + "%";
      if (daysUntilArrival < 0 && daysUntilArrival > -7) color = "bg-warning";
      else if (daysUntilArrival <= -7) color = "bg-danger";
    }
    return (
      <div className="progress" style={{ height: "7px", maxWidth: "200px" }}>
        <div className={"progress-bar " + color} role="progressbar" style={{ width: width }} />
      </div>
    );
  }
}

export default OrderProgressbar;
