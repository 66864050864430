import _ from "lodash";
import React, { PureComponent, useState } from "react";
import { BSON } from "realm-web";
import { DataContext } from "../../context/dataContext";
import dateUtils from "../../utils/dateUtils";
import { OrdersDocument } from "../../model/orders.types";
import ProductionPlanHelper, { ExtendedProductionWeek } from "./ProductionPlanHelper";

interface ProductionOrderPlanningProps {
  order: OrdersDocument;
  ordersAtLocation: Array<OrdersDocument>;
  context: React.ContextType<typeof DataContext>;
  reschedule?: boolean;
  onSelectWeek: (week: ExtendedProductionWeek) => void;
}

interface ProductionOrderPlanningState {
  weeks: Array<ExtendedProductionWeek>;
}

class ProductionOrderPlanning extends PureComponent<ProductionOrderPlanningProps, ProductionOrderPlanningState> {
  capacity: number;
  constructor(props: ProductionOrderPlanningProps) {
    super(props);
    this.state = {
      weeks: []
    };
    this.capacity = this.getCalculatedCapacity(props);
  }

  async componentDidMount() {
    this.setState({ weeks: this.getPreparedProductionPlan() });
  }

  componentDidUpdate(
    prevProps: Readonly<ProductionOrderPlanningProps>,
    prevState: Readonly<ProductionOrderPlanningState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.context.productionPlan, this.props.context.productionPlan)) {
      this.setState({ weeks: this.getPreparedProductionPlan() });
    }
  }

  /**
   * Get a prepared production plan
   * @returns {Array<ExtendedProductionWeek>} list of extended production weeks
   */
  getPreparedProductionPlan = () => {
    const { productionPlan } = this.props.context;
    const weeks = this.getWeeks();
    // Prepare weeks
    for (let i = 0; i < productionPlan.length; i++) {
      const productionWeek = productionPlan[i];
      const relatedWeek = weeks.find(week => week.week === productionWeek.week);
      if (relatedWeek) {
        const [reserved, typeString] = this.getPlannedOrdersInformation(productionWeek.plannedOrders);
        relatedWeek.plannedOrders = relatedWeek.plannedOrders.concat(productionWeek.plannedOrders);
        relatedWeek._id = productionWeek._id;
        relatedWeek.reserved = reserved;
        relatedWeek.typeString = typeString;
        if (productionWeek.producedUnits) relatedWeek.producedUnits = productionWeek.producedUnits;
      }
    }
    return weeks;
  };

  /**
   * Get information about all planned orders of a week
   * @param orderIds list of order ids
   * @returns {[reservedCapacity: number, typeString: string]} tuple with reserved capacity and string including all planned types
   */
  getPlannedOrdersInformation = (orderIds: Array<BSON.ObjectId>): [number, string] => {
    const { ordersAtLocation: orders, context } = this.props;
    return ProductionPlanHelper.getPlannedOrdersInformation(orderIds, orders, context);
  };

  /**
   * Get weeks from the current date on up to max 2 years ahead
   * @returns {Array<ExtendedProductionWeek>} list of extended production weeks
   */
  getWeeks = () => {
    const weeks: Array<ExtendedProductionWeek> = [];
    const today = new Date();
    const thisYear = today.getUTCFullYear();
    // create weeks for max next 2 years
    for (let i = thisYear; i < thisYear + 2; i++) {
      for (let j = 1; j <= 53; j++) {
        const startDay = dateUtils.getStartOfISOWeek(j, i);
        // Skip weeks before CW
        if (thisYear === startDay.getUTCFullYear() && dateUtils.getCW(today) > j) continue;
        // check if week 53 starts in the following year. False if year is leap year.
        if (startDay.getUTCFullYear() > i) break;
        const newWeek: ExtendedProductionWeek = {
          week: `${j}-${i}`,
          startDate: startDay,
          endDate: new Date(startDay.getTime() + (7 * 24 * 60 * 60 * 1000 - 1)),
          plannedOrders: [] as Array<BSON.ObjectId>,
          reserved: 0,
          typeString: "Empty"
        };
        weeks.push(newWeek);
      }
    }
    return weeks;
  };

  /**
   * Get the calculated capacity of the last 6 weeks
   * @param props the properties of the component
   * @returns {number} calculated capacity of last 6 weeks
   */
  getCalculatedCapacity = (props: ProductionOrderPlanningProps) => {
    const { order, context } = props;
    const { orders } = context;
    const manufacturer = order.settings.manufacturer;
    return ProductionPlanHelper.getCalculatedCapacity(orders, manufacturer);
  };

  /**
   * Check if a date is within a week
   * @param week the week with start and end date
   * @param date date to check
   * @returns {boolean} true if date is in week else false
   */
  isInWeek = (week: ExtendedProductionWeek, date?: Date | null) =>
    !!date && week.startDate.getTime() <= date.getTime() && date.getTime() <= week.endDate.getTime();

  render() {
    const { order, onSelectWeek, reschedule } = this.props;
    const { weeks } = this.state;
    return (
      <div className="container">
        <h5 className="kt-font-bolder kt-font-dark">
          Select the week the order will be produced. Expected capacity per week: {this.capacity} units
        </h5>
        <hr />
        <div className="row">
          {weeks.map(week => (
            <ProductionOrderPlanningWeek
              key={week.week}
              order={order}
              week={week}
              currentOrderUnits={order.calculations[0].units}
              isTargetWeek={this.isInWeek(week, order.targetDate)}
              isCurrentWeek={this.isInWeek(week, new Date())}
              isCurrentOrderWeek={reschedule && week.week === order.productionWeek}
              capacity={this.capacity}
              onSelectWeek={onSelectWeek}
            />
          ))}
        </div>
      </div>
    );
  }
}

interface ProductionOrderPlanningWeekProps {
  order: OrdersDocument;
  week: ExtendedProductionWeek;
  currentOrderUnits: number;
  isTargetWeek: boolean;
  isCurrentWeek: boolean;
  capacity: number;
  isCurrentOrderWeek?: boolean;
  onSelectWeek: (week: ExtendedProductionWeek) => void;
}

const ProductionOrderPlanningWeek: React.FunctionComponent<ProductionOrderPlanningWeekProps> = ({
  order,
  week,
  currentOrderUnits,
  isTargetWeek,
  isCurrentWeek,
  isCurrentOrderWeek,
  capacity,
  onSelectWeek
}) => {
  const [hover, setHover] = useState(false);
  let totalReserved = week.reserved + (week.producedUnits ? week.producedUnits : 0);
  let units =
    hover &&
    (order.productionWeek !== week.week ||
      !order.settings.productionMachine ||
      !week.plannedOrders.some(o => o.toString() === order._id.toString()))
      ? totalReserved + currentOrderUnits
      : totalReserved;
  let percentage = (units / capacity) * 100;
  let color = "success";
  if (percentage >= 100) color = "danger";
  else if (percentage >= 80) color = "warning";

  const startDate = new Date(week.startDate.valueOf() + week.startDate.getTimezoneOffset() * 60 * 1000);
  const endDate = new Date(week.endDate.valueOf() + week.endDate.getTimezoneOffset() * 60 * 1000);

  return (
    <div
      className="col-12 col-md-6 col-xl-4 mt-4"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => onSelectWeek(week)}
    >
      <div className={"p-3 week pointer"} style={{ minHeight: "50px", borderRadius: "10px" }}>
        <h5 className=" kt-font-bold">
          <span className="kt-font-dark mr-2">CW {week.week.split("-")[0]}</span>
          {isCurrentOrderWeek ? (
            <span className="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill p-1 px-2 float-right">
              <b>Planned Week</b>
            </span>
          ) : isCurrentWeek ? (
            <span className="kt-badge kt-badge--primary kt-badge--inline kt-badge--pill p-1 px-2 float-right">
              <b>Current Week</b>
            </span>
          ) : isTargetWeek ? (
            <span className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill p-1 px-2 float-right">
              <b>Target Week</b>
            </span>
          ) : null}
          <br />
          <small style={{ color: "#74788d" }}>
            {startDate.toLocaleDateString("de-DE", {
              month: "numeric",
              day: "numeric",
              year: "numeric"
            })}{" "}
            -{" "}
            {endDate.toLocaleDateString("de-DE", {
              month: "numeric",
              day: "numeric",
              year: "numeric"
            })}
          </small>
        </h5>
        <hr />
        <div style={{ width: "100%", textAlign: "center" }}>
          <h6 className="kt-font-dark kt-font-bold pb-3 pt-1">{week.typeString}</h6>
          <div className="progress mb-3" style={{ height: "5px" }}>
            <div className={"progress-bar bg-" + color} role="progressbar" style={{ width: percentage + "%" }} />
          </div>
          <span className="kt-font-dark">{`${units} of ${capacity} units`}</span>
        </div>
      </div>
    </div>
  );
};

export default ProductionOrderPlanning;
