import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ProductionLine } from "../../../model/manufacturers.types";
import dbManufacturerService from "../../../services/dbServices/dbManufacturerService";

interface DeleteProductionLineModalProps {
  onHide: () => void;
  productionLine: ProductionLine;
  show: boolean;
}

class DeleteProductionLineModal extends Component<DeleteProductionLineModalProps, {}> {
  /**
   * Delete the production line the widget relates to.
   */
  deleteProductionLine = async () => {
    const { onHide, productionLine } = this.props;
    const res = await dbManufacturerService.deleteProductionLine(productionLine._id);
    if (res) {
      toast.success(
        <b>
          <i className="fa fa-check mx-2" />
          Machine deleted successfully
        </b>
      );
      // Hide modal only on success
      onHide();
    } else {
      toast.error(
        <b>
          <i className="fa fa-exclamation mx-2" />
          Error deleting machine
        </b>
      );
    }
  };

  render() {
    const { onHide, productionLine, show } = this.props;
    return (
      <Modal show={show} centered onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Delete machine {productionLine.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-danger" role="alert">
            <div className="alert-icon">
              <i className="flaticon-exclamation-1" />
            </div>
            <div className="alert-text">Machine {productionLine.name} will be deleted. This cannot be undone.</div>
          </div>
          <h5 className="font-weight-bold">Do you really want to delete the machine?</h5>
        </Modal.Body>
        <Modal.Footer>
          <div className="float-right">
            <button className="btn btn-secondary mr-2" onClick={onHide}>
              Close
            </button>
            <button className="btn btn-danger font-weight-bold" onClick={this.deleteProductionLine}>
              Yes, i'm sure!
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DeleteProductionLineModal;
