import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic";
import CompanyWidget from "../common/CompanyWidget";
import { CustomerNotesExtended, PaginationState } from "../common/CustomTypes";
import Pagination, { paginate } from "../common/Pagination";
import PersonWidget from "../common/PersonWidget";
import { OrderWidget } from "../listings/common/BaseListingComponents";
import { CustomerFilter, OwnerFilter, SearchBar } from "../listings/common/Filters";
import { DataContext } from "../../context/dataContext";
import { UserdataDocument } from "../../model/userdata.types";
import baseUtils from "../../utils/baseUtils";
import ContinueOrderConversationNotesModal from "./ContinueOrderConversationNotesModal";
import ReadCustomerNoteModal from "./ReadCustomerNoteModal";
import userService from "../../services/userService";
import { CompaniesDocument } from "../../model/companies.types";
import { OrdersDocument } from "../../model/orders.types";
import { CustomOrder } from "../order/CustomTypes";
import orderUtils from "../../utils/orderUtils";

interface OrderNoteOverview {
  customer: CompaniesDocument;
  owner: UserdataDocument;
  order: OrdersDocument | CustomOrder;
  relatedNotes: Array<CustomerNotesExtended>;
}

interface OrderConversationNotesListingProps extends RouteComponentProps {
  context: React.ContextType<typeof DataContext>;
}

interface OrderConversationNotesListingState extends PaginationState {
  notes: Array<OrderNoteOverview>;
  owner: "" | { value: string; label: string };
  read: "" | { value: string; label: string };
  answered: "" | { value: string; label: string };
  customer: { value: string; label: string };
  query: string;
}

class OrderConversationNotesListing extends PureComponent<
  OrderConversationNotesListingProps,
  OrderConversationNotesListingState
> {
  constructor(props: OrderConversationNotesListingProps) {
    super(props);
    this.state = {
      notes: this.getNotes(),
      query: "",
      currentPage: 1,
      pageSize: 10,
      owner: !userService.isAdmin() ? { value: userService.getUserId().toString(), label: "You" } : "",
      read: "",
      answered: "",
      customer: { value: "all", label: "All customers" }
    };
  }

  componentDidUpdate(
    prevProps: Readonly<OrderConversationNotesListingProps>,
    prevState: Readonly<OrderConversationNotesListingState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.context.orders, this.props.context.orders)) {
      this.setState({ notes: this.getNotes() });
    }
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ query: e.target.value, currentPage: 1 });

  handleFilterChange = (name: string, filter: { value: string; label: string } | "") => {
    // @ts-ignore
    this.setState({ [name]: filter });
  };

  handlePageChange = (page: number) => {
    this.setState({ currentPage: page });
  };

  handlePageSizeChange = (pageSize: number) => {
    this.setState({ pageSize, currentPage: 1 });
  };

  getNotes = () => {
    const { context } = this.props;
    const notes: Array<OrderNoteOverview> = [];
    for (let i = 0; i < context.orders.length; i++) {
      const o = context.orders[i];
      if (o.customerNotes && o.customerNotes.length > 0) {
        const relevantOrder = _.cloneDeep(o);
        const relatedOwner: UserdataDocument | undefined = baseUtils.getDocFromCollection(
          context.userdata,
          o.createdFrom
        );
        const relatedCustomer: CompaniesDocument | undefined = baseUtils.getDocFromCollection(
          context.companies,
          o.createdFor
        );
        const relatedNotes = [] as Array<CustomerNotesExtended>;

        if (relatedOwner && relatedCustomer) {
          for (let j = 0; j < o.customerNotes.length; j++) {
            const n = o.customerNotes[j];
            const person: UserdataDocument | undefined = baseUtils.getDocFromCollection(context.userdata, n.person);
            const company: CompaniesDocument | "internal" | undefined =
              person?.company_id === "internal" ? person.company_id : relatedCustomer;

            if (person) {
              if (o.customerNotes.length - 1 === j) {
                relatedNotes.push({ order: o, note: n, person, company, lastMessage: true });
              } else {
                relatedNotes.push({ order: o, note: n, person, company });
              }
            }
          }
          notes.push({
            customer: relatedCustomer,
            owner: relatedOwner,
            order: relevantOrder,
            relatedNotes: relatedNotes
          });
        }
      }
    }
    return notes;
  };

  /**
   * Filters and sorts customer notes.
   */
  getFilterAndSortNotes = () => {
    const { query, notes, owner, read, answered, customer } = this.state;
    let notesFilteredAndSorted = _.cloneDeep(notes);
    if (owner && owner.value !== "") {
      notesFilteredAndSorted = notesFilteredAndSorted.filter(n => n.owner._id.toString() === owner.value.toString());
    }
    if (customer.value !== "all") {
      notesFilteredAndSorted = notesFilteredAndSorted.filter(n => n.customer._id.toString() === customer.value);
    }
    if (query) {
      notesFilteredAndSorted = baseUtils.doFuseSearch(notesFilteredAndSorted, query, [
        "order.identifier",
        "company.name",
        "order.title",
        "order.subtitle",
        "relatedNotes.person.surname",
        "relatedNotes.person.prename"
      ]);
    }
    return notesFilteredAndSorted.sort((n1, n2) => n1.customer.name.localeCompare(n2.customer.name));
  };

  getOwnersWithOrdersWithNotes = () => {
    const { context } = this.props;
    const { notes } = this.state;
    let userIds: Array<string> = [userService.getUserId().toString()];
    if (userService.isAdmin()) {
      userIds = Array.from(new Set(notes.map(n => n.order.createdFrom.toString())));
    }
    const result = userIds
      .map(u => context.userdata.find(uD => uD._id.toString() === u))
      .filter(elem => elem !== undefined);
    return result as Array<UserdataDocument>;
  };

  getCustomersWithOrdersWithNotes = () => {
    const { notes } = this.state;
    const companies = notes.map(n => n.customer) as Array<CompaniesDocument>;
    return companies.reduce(
      (coms: Array<CompaniesDocument>, c) =>
        !coms.some(com => com._id.toString() === c._id.toString()) ? coms.concat(c) : coms,
      []
    );
  };

  render() {
    const { history } = this.props;
    const { query, currentPage, pageSize, owner, customer } = this.state;

    const notesFiltered = this.getFilterAndSortNotes();
    const notesPaginated = paginate(notesFiltered, currentPage, pageSize);
    const selectableUsers = this.getOwnersWithOrdersWithNotes();
    const customers = this.getCustomersWithOrdersWithNotes();

    return (
      <>
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand fa fa-building" />
              </span>
              <h3 className="kt-portlet__head-title">Order Conversation Notes</h3>
            </div>
            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-wrapper">
                <button onClick={history.goBack} className="btn btn-clean kt-margin-r-10">
                  <i className="la la-arrow-left" />
                  <span className="kt-hidden-mobile">Back</span>
                </button>
              </div>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="kt-form kt-form--label-right kt-margin-b-10">
              <div className="row align-items-center">
                <div className="col-12 order-2 order-xl-1">
                  <div className="row align-items-center">
                    <SearchBar
                      onSearch={this.handleSearch}
                      search={query}
                      additionalSizeClasses="col-xl-2 col-md-4 col-12"
                    />
                    <OwnerFilter
                      owner={owner}
                      selectableUsers={selectableUsers}
                      onFilterChange={this.handleFilterChange}
                      additionalSizeClasses="col-xl-2 col-md-4 col-6"
                      disabled={!userService.isAdmin()}
                    />
                    <CustomerFilter
                      customer={customer}
                      selectableCustomers={customers}
                      onFilterChange={this.handleFilterChange}
                      additionalSizeClasses="col-xl-2 col-md-4 col-6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {notesFiltered.length > 0 ? (
          notesPaginated.map(n => {
            return (
              <div className="kt-portlet kt-portlet--mobile">
                <div
                  className="kt-portlet__head kt-portlet__head--lg"
                  style={{ backgroundColor: "#fafafa", borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
                >
                  <div className="row w-100 p-3">
                    <div className="col-2">
                      <OrderWidget document={n.order} prefix={orderUtils.isOrder(n.order) ? "AT-" : "Offer AN-"} />
                    </div>
                    <div className="col-2">
                      <CompanyWidget company={n.customer} />
                    </div>
                    <div className="col-8">
                      <PersonWidget person={n.owner} />
                    </div>
                  </div>
                </div>
                <div className="kt-portlet__body kt-portlet__body--fit p-3">
                  <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive px-4">
                    <table className="table">
                      <thead>
                        <tr className="kt-datatable__row">
                          <th style={{ width: "5%" }}>#</th>
                          <th style={{ width: "15%" }}>Person</th>
                          <th style={{ width: "7.5%" }}>Date</th>
                          <th style={{ width: "7.5%" }}>Time</th>
                          <th style={{ width: "25%" }}>Received</th>
                          <th style={{ width: "25%" }}>Sent</th>
                          <th className="text-right" style={{ width: "15%" }}>
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {n.relatedNotes.map((noteObject, idx) => (
                          <tr key={idx} style={idx % 2 === 0 ? { backgroundColor: "#fcfcfc" } : {}}>
                            <td className="align-middle">{idx + 1}</td>
                            <td className="align-middle">
                              <PersonWidget person={noteObject.person} />
                            </td>
                            <td className="align-middle">{baseUtils.formatDate(noteObject.note.date)}</td>
                            <td className="align-middle">
                              {noteObject.note.date.toLocaleTimeString("de-DE", {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit"
                              })}
                            </td>
                            <td className="align-middle">
                              {noteObject.person.company_id !== "internal" ? noteObject.note.note : ""}
                            </td>
                            <td className="align-middle">
                              {noteObject.person.company_id === "internal" ? noteObject.note.note : ""}
                            </td>
                            <td className="align-middle text-right">
                              {noteObject.person.company_id !== "internal" && (
                                <>
                                  {noteObject.note.readDate ? (
                                    <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill mr-2">
                                      <i className="fa fa-check mr-2" /> Read
                                    </span>
                                  ) : (
                                    <ReadCustomerNoteModal note={noteObject} />
                                  )}
                                </>
                              )}
                              {noteObject.lastMessage && <ContinueOrderConversationNotesModal note={noteObject} />}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <img
            src={toAbsoluteUrl("/media/img/no_results.jpg")}
            className="d-block my-0 mx-auto "
            style={{ height: "500px" }}
            alt="No results"
          />
        )}
        {notesFiltered.length > 0 && (
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__body kt-portlet__body--fit">
              <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive px-4">
                <div className="kt-datatable__pager kt-datatable--paging-loaded justify-content-center">
                  <Pagination
                    itemsCount={notesFiltered.length}
                    pageSize={pageSize}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                    onPageSizeChange={this.handlePageSizeChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default OrderConversationNotesListing;
