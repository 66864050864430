import _ from "lodash";
import { BSON } from "realm-web";
import { CustomOrder, InvoicePosition } from "../components/order/CustomTypes";
import orderUtils from "./orderUtils";
import { Invoice, OrdersDocument, Position } from "../model/orders.types";
import { DataContextType } from "../context/dataContext";

export const I_INVOICE = "invoice";
export const I_REMINDER = "reminder";
export const I_CANCELATION = "cancelation";
export const I_OPEN = "open";
export const I_PAID = "paid";
export const I_PARTLYPAID = "partlyPaid";
export const I_CANCELED = "canceled";
export const I_FINALINVOICE = "final invoice";
export const I_PAYMENTINADVANCE = "payment in advance";
export const I_PARTIALINVOICE = "partial invoice";
export const I_POSITION = "position";
export const I_FREEPOSITION = "freeposition";
export const I_SERVICE = "service";

export const I_DUNNING_PERIOD = 1; // weeks, One-week period has been requested by finance

export const I_PAYMENTTARGETS = [7, 10, 14, 20, 28, 30, 45, 60, 90];

/**
 * Get the total amount of all positions with discount included
 * @param positions list of positions
 * @returns {number} total amount of all positions with discount included without VAT
 */
function getSubtotal(positions: Array<Position | InvoicePosition>) {
  return positions.reduce((a, b) => a + (+b.total - (+b.discount / 100) * +b.total), 0);
}

/**
 * Calculate the amount and total amount for all existing vat values
 * @param positions list of positions
 * @returns { Array<{vat: string, value: number, total: number}>} Objects with vat values and the respective (total) amount
 */
function getVatAmount(
  positions: Array<Position | InvoicePosition>
): Array<{ vat: string; value: number; total: number }> {
  const vat: Array<{ vat: string; value: number; total: number }> = [];
  for (let i = 0; i < positions.length; i++) {
    const position = positions[i];
    const total = getTotalWithDiscount(position);
    if (vat.some(v => +v.vat === +position.vat)) {
      const current = vat.find(v => +v.vat === +position.vat)!;
      current.value = current.value + total * (+position.vat / 100);
      current.total += total;
    } else {
      vat.push({
        vat: position.vat.toString(),
        value: +total * (+position.vat / 100),
        total: total
      });
    }
  }
  return vat;
}

/**
 * Get the default position for an order
 * @param order an order document
 * @param context data context
 * @returns {InvoicePosition} the default position for an order
 */
function getDefaultPosition(order: OrdersDocument | CustomOrder, context: DataContextType) {
  const calculation = order.calculations[0];
  const oF = order.fulfillment;
  const useOF = oF && oF.priceInfo && oF.totalUnits;
  return {
    id: new BSON.ObjectId(),
    type: "position",
    description: orderUtils.getProductDescription(order, context),
    amount: useOF ? oF!.totalUnits : calculation.units.toString(),
    unit: "item",
    price: (Math.round(+(useOF ? oF!.priceInfo!.unitPrice : calculation.info.unitprice) * 100) / 100).toString(),
    total: (Math.round(+(useOF ? oF!.priceInfo!.totalPrice : calculation.info.totalprice) * 100) / 100).toString(),
    vat: "7",
    discount: "0"
  } as InvoicePosition;
}

/**
 * Calculate the total amount with discount for a positoin
 * @param position a order position
 * @returns {number} the total amount including the discount
 */
function getTotalWithDiscount(position: Position | InvoicePosition): number {
  if (position.discount) {
    return Math.round((+position.total - (+position.discount / 100) * +position.total) * 100) / 100;
  }
  return +position.total;
}

/**
 * Calculate the total amount with vat and discount for a position
 * @param position a order position
 * @returns {number} the total amount including vat and discount
 */
function getTotalWithVatAndDiscount(position: InvoicePosition | Position): number {
  return (
    Math.round((+position.total - (+position.discount / 100) * +position.total) * (1 + +position.vat / 100) * 100) / 100
  );
}

/**
 * Calculate the total amount with vat and discount for a position and including reminder
 * @param invoice an invoice document
 * @returns {number} the total amount including vat and discount
 */
function getGrossTotalWithReminders(invoice: Invoice): number {
  const total = invoice.positions.reduce((sum, i) => sum + getTotalWithVatAndDiscount(i), 0);
  const totalReminder = invoice.reminder.reduce((sum, r) => (r.position ? r.position.total + sum : sum), 0);
  return total + totalReminder;
}

/**
 * Sort all invoices that the order has
 * @param order an order document
 * @returns { Array<Invoice> } list of invoices
 */
function sortInvoices(order: OrdersDocument) {
  let invoices: Array<Invoice> = [];
  if (order.invoices) {
    invoices = _.cloneDeep(order.invoices);
    invoices.sort(
      (a, b) =>
        orderUtils.getInvoiceDue(b.dueIn, b.invoiceDate).getTime() -
        orderUtils.getInvoiceDue(a.dueIn, a.invoiceDate).getTime()
    );
  }
  return invoices;
}

/**
 * Calculate the pending amount of an invoice
 * @param invoice the invoice data
 * @param state state of the invoice
 * @param totalGross optional, already calculated gross amount
 * @returns {number} the remaining pending amount
 */
function getGrossPending(invoice: Invoice, state: string, totalGross?: number): number {
  const total = totalGross ?? getGrossTotalWithReminders(invoice);
  let paid = 0;
  if (state === I_PAID) paid = total;
  else if (invoice.payments) paid = invoice.payments.reduce((a: number, b: { amount: number }) => a + +b.amount, 0);
  return total - paid;
}

/**
 * Calculate and return dunning stats
 * @param filteredInvoices The list of invoices to be analysed.
 * @returns {noReminder: number, firstReminder: number, firstDunning: number, multipleDunning:number, total: number} object witch contains the corresponding dunning stats.
 */
function getDunningStats(filteredInvoices: { isSelected: boolean; order: OrdersDocument; invoice: Invoice }[]) {
  let noReminder = 0;
  let firstReminder = 0;
  let firstDunning = 0;
  let multipleDunning = 0;
  for (let i = 0; i < filteredInvoices.length; i++) {
    const invoice = filteredInvoices[i];
    const rCount = invoice.invoice.reminder.length;
    const pendingAmount = getGrossPending(invoice.invoice, invoice.invoice.state);
    if (rCount === 0) noReminder += pendingAmount;
    else if (rCount === 1) firstReminder += pendingAmount;
    else if (rCount === 2) firstDunning += pendingAmount;
    else multipleDunning += pendingAmount;
  }
  const total = noReminder + firstReminder + firstDunning + multipleDunning;
  return { noReminder, firstReminder, firstDunning, multipleDunning, total };
}

/**
 * Check if an invoice for an order matches the desired state
 * @param invoice an extended invoice entry with dueDate
 * @param status the desired status to check for
 * @returns {boolean} check if invoice is of the correct status
 */
function matchesInvoiceStatus(invoice: Invoice, status: string): boolean {
  const currentTime = new Date().getTime();
  return (
    status === "all" ||
    invoice.state === status ||
    (status === "due" &&
      orderUtils.getInvoiceDue(invoice.dueIn, invoice.invoiceDate).getTime() < currentTime &&
      invoice.state !== I_PAID) ||
    (status === "dunning" && invoice.reminder.length > 0 && invoice.state !== I_PAID)
  );
}

/**
 * Resolve the type of invoice
 * @param invoice Invoice that should be checked for its type.
 * @returns { string } Type of invoice
 */
function resolveInvoiceType(invoice: Invoice) {
  if (invoice.dueIn === -1) return I_PAYMENTINADVANCE;
  if (invoice.partial) return I_PARTIALINVOICE;
  return I_FINALINVOICE;
}

/**
 * Calculate the absolute margin from a percent margin and a given amount
 * @param amount an amount to calculate the margin for
 * @param percentMargin a percent margin
 * @returns {number} the absolute margin
 */
function calculateAbsoluteMargin(amount: number, percentMargin: number): number {
  return percentMargin < 0
    ? (amount - amount / (Math.abs(percentMargin) / 100 + 1)) * -1
    : amount - amount / (percentMargin / 100 + 1);
}

export default {
  calculateAbsoluteMargin,
  getSubtotal,
  getVatAmount,
  getDefaultPosition,
  getDunningStats,
  getTotalWithDiscount,
  getTotalWithVatAndDiscount,
  sortInvoices,
  getGrossPending,
  getGrossTotalWithReminders,
  matchesInvoiceStatus,
  resolveInvoiceType
};
