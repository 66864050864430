import _ from "lodash";
import React, { PureComponent, useState } from "react";
import { BSON } from "realm-web";
import Select from "react-select";
import { toast } from "react-toastify";
import { toAbsoluteUrl } from "../../../_metronic";
import { BatchSlim, CustomOrder, PackagingBatchSlim } from "../CustomTypes";
import userService from "../../../services/userService";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import baseUtils from "../../../utils/baseUtils";
import { DataContext } from "../../../context/dataContext";
import dateUtils from "../../../utils/dateUtils";
import config from "../../../config/config.json";
import orderUtils from "../../../utils/orderUtils";
import packagingUtils from "../../../utils/packagingUtils";
import OrderHelper from "../OrderHelper";
import {
  T_CALLCREATED,
  T_CALLED,
  T_COMMODITYDELIVERED,
  T_COMMODITYFROMCUSTOMER,
  T_COMMODITYFROMSTOCK,
  T_COMMODITYORDERCANCELED,
  T_COMMODITYORDERED,
  T_COMMODITYORDEREDITED,
  T_COMMODITYORDERUNLINKED,
  T_COMMODITYREMOVED,
  T_COMMODITYREPLACED,
  T_DATASHEET,
  T_DECLINED,
  T_DELIVERYINFORMATION,
  T_FEEDBACK,
  T_FILE,
  T_FULFILLMENT,
  T_INVOICECANCELED,
  T_INVOICECREATED,
  T_INVOICEPAID,
  T_INVOICEPARTLYPAID,
  T_INVOICEREMINDER,
  T_MANUFACTURINGPDF,
  T_NOTE,
  T_OFFER,
  T_PRODUCTSPECIFICATION,
  T_OFFERCONFIRMATION,
  T_OFFERPDF,
  T_NONBINDINGOFFERPDF,
  T_ORDERED,
  T_PACKAGINGDELIVERED,
  T_PACKAGINGFROMCUSTOMER,
  T_PACKAGINGFROMSTOCK,
  T_PACKAGINGORDERCANCELED,
  T_PACKAGINGORDERED,
  T_PACKAGINGORDERED2,
  T_PACKAGINGORDEREDITED,
  T_PACKAGINGORDERUNLINKED,
  T_PACKAGINGREMOVED,
  T_PACKAGINGREPLACED,
  T_PRODUCTION,
  T_REMINDER,
  T_REORDER,
  T_REPORTPDF,
  T_REQUESTAPPROVED,
  T_REQUESTED,
  T_REQUESTPENDING,
  T_REQUESTREVOKED,
  T_REQUESTUPDATED,
  T_RESCHEDULE,
  T_RESERVECOMMODITY,
  T_RESERVEPACKAGING,
  T_RETURNPRODUCTIONQUEUE,
  T_SHIPPINGLABEL,
  T_TARGETDATEUPDATED,
  T_UPDATE,
  T_WAITING,
  T_FULFILLMENTREVOKED,
  T_COMMODITYATWAREHOUSE,
  T_TITLEUPDATED,
  T_CONVERTEDTOCONTRACT,
  T_CALCULATIONCHANGED,
  T_MANUFACTURERCHANGED,
  T_PRODUCTIONREPORTAPPROVED,
  T_ORDERSHIPPED,
  T_ORDERARCHIVED,
  T_PACKAGINGFROMCUSTOMERDELIVERED,
  T_COMMODITYFROMCUSTOMERDELIVERED,
  T_OWNERCHANGED,
  T_SHELFLIFESET,
  T_FILLERCHANGED,
  T_QRCODECREATED,
  T_REQUESTAPPROVEDWITHRAWBIDS
} from "../../../utils/timelineUtils";
import { OrdersDocument } from "../../../model/orders.types";
import { CommoditiesDocument } from "../../../model/commodities.types";
import { PackagingsDocument } from "../../../model/packagings.types";
import accessUtils from "../../../utils/accessUtils";
import { UserdataDocument } from "../../../model/userdata.types";
import slackService from "../../../services/slackService";

interface OrderTimelineProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
  readOnly?: boolean;
}

interface OrderTimelineState {}

class OrderTimeline extends PureComponent<OrderTimelineProps, OrderTimelineState> {
  render() {
    const { order, context, readOnly } = this.props;
    const timeline = order.timeline;
    return (
      <>
        {!readOnly && <OrderTimelineNote order={order} context={context} />}
        {!readOnly && <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed" />}
        <div className="kt-notes kt-scroll kt-scroll--pull ps ps--active-y">
          <div className="kt-notes__items">
            {timeline
              .slice()
              .reverse()
              .map(entry => (
                <OrderTimelineEntry
                  {...this.props}
                  timelineEntry={entry}
                  key={entry._id?.toString() || entry.id?.toString() || entry.type + entry.date}
                />
              ))}
          </div>
        </div>
      </>
    );
  }
}

export default OrderTimeline;

interface OrderTimelineEntryProps {
  order: CustomOrder;
  timelineEntry: any;
  context: React.ContextType<typeof DataContext>;
}

interface OrderTimelineEntryState {}

class OrderTimelineEntry extends PureComponent<OrderTimelineEntryProps, OrderTimelineEntryState> {
  /**
   * Get image with file icon for the given file type
   * @param fileType the file type
   * @returns {JSX.Element} image component
   */
  getFileTypeImage(fileType: string) {
    const { timelineEntry } = this.props;
    return OrderHelper.getFileTypeImage(timelineEntry, fileType);
  }

  /**
   * Checks the type of the timeline entry and returns information on what to display
   * @param userName the name of the person who created the timeline entry
   * @return {{image: JSX.Element, info.JSX.Element}} Object with image/icon and info description for the timeline entry
   */
  getTimelineDefinition = (userName: string) => {
    const { timelineEntry, order, context } = this.props;
    let com: CommoditiesDocument;
    let pack: PackagingsDocument;
    let users: Array<UserdataDocument>;
    const canSeeFinanceData = accessUtils.canSeeFinanceData();
    const unknown = {
      image: <i className="fas fa-question" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
      info: (
        <>
          <span className="kt-widget4__username">Unknown Entry</span>
        </>
      )
    };
    try {
      switch (timelineEntry.type) {
        case T_NOTE:
          users = [];
          if (timelineEntry.slackTargets) {
            for (let i = 0; i < timelineEntry.slackTargets.length; i++) {
              const st = timelineEntry.slackTargets[i];
              const u = baseUtils.getDocFromCollection(context.userdata, st);
              if (u) users.push(u);
            }
          }
          return {
            image: <i className="flaticon2-talk" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <>
                <span className="kt-widget4__username">
                  Note
                  {users.length > 0 && (
                    <>
                      <img
                        className="ml-2 mr-1"
                        src={toAbsoluteUrl("/media/svg/slack.svg")}
                        style={{ height: "18px" }}
                        alt="slack"
                      />
                      <span className="font-weight-normal text-muted">
                        {users.map(u => u.prename + " " + u.surname).join(", ")}
                      </span>
                    </>
                  )}
                </span>
                <span style={{ color: "#595d6e" }}>{timelineEntry.text}</span>
              </>
            )
          };
        case T_REPORTPDF:
          return canSeeFinanceData
            ? {
                image: <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />,
                info: (
                  <a
                    href={config.mediahubBase + timelineEntry.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="kt-widget4__username"
                  >
                    Calculation Report for {"AN-" + timelineEntry.offernumber}
                    <span style={{ fontWeight: 100 }}>{` (Version ${timelineEntry.version})`}</span>
                  </a>
                )
              }
            : null;
        case T_OFFERPDF:
          return canSeeFinanceData
            ? {
                image: <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />,
                info: (
                  <a
                    href={config.mediahubBase + timelineEntry.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="kt-widget4__username"
                  >
                    Offer PDF for {"AN-" + timelineEntry.offernumber}
                    <span style={{ fontWeight: 100 }}>{` (Version ${timelineEntry.version})`}</span>
                  </a>
                )
              }
            : null;
        case T_PRODUCTSPECIFICATION:
          return {
            image: <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />,
            info: (
              <a
                href={config.mediahubBase + timelineEntry.path}
                target="_blank"
                rel="noopener noreferrer"
                className="kt-widget4__username"
              >
                Product Specification PDF for {"AN-" + timelineEntry.offernumber}
                <span style={{ fontWeight: 100 }}>{` (Version ${timelineEntry.version})`}</span>
              </a>
            )
          };
        case T_NONBINDINGOFFERPDF:
          return {
            image: <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />,
            info: (
              <a
                href={config.mediahubBase + timelineEntry.path}
                target="_blank"
                rel="noopener noreferrer"
                className="kt-widget4__username"
              >
                Non-binding offer PDF for {"AN-" + timelineEntry.offernumber}
                <span style={{ fontWeight: 100 }}>{` (Version ${timelineEntry.version})`}</span>
              </a>
            )
          };
        case T_MANUFACTURINGPDF:
          return {
            image: <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />,
            info: (
              <a
                href={config.mediahubBase + timelineEntry.path}
                target="_blank"
                rel="noopener noreferrer"
                className="kt-widget4__username"
              >
                Manufacturing Sheet for {"AT-" + timelineEntry.identifier}
              </a>
            )
          };
        case T_DATASHEET:
          return {
            image: <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />,
            info: (
              <a
                href={config.mediahubBase + timelineEntry.path}
                target="_blank"
                rel="noopener noreferrer"
                className="kt-widget4__username"
              >
                Datasheet for {"AT-" + timelineEntry.identifier}
              </a>
            )
          };
        case T_OFFERCONFIRMATION:
          return canSeeFinanceData
            ? {
                image: <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />,
                info: (
                  <a
                    href={config.mediahubBase + timelineEntry.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="kt-widget4__username"
                  >
                    Order Confirmation for {"AT-" + timelineEntry.identifier}
                  </a>
                )
              }
            : null;
        case T_DELIVERYINFORMATION:
          return {
            image: <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />,
            info: (
              <a
                href={config.mediahubBase + timelineEntry.path}
                target="_blank"
                rel="noopener noreferrer"
                className="kt-widget4__username"
              >
                Delivery Information {"AT-" + timelineEntry.identifier}
              </a>
            )
          };
        case T_INVOICECREATED:
          if (!canSeeFinanceData) return null;
          const invoice = order.invoices!.find(i => i._id.toString() === timelineEntry.invoiceId.toString());
          if (!invoice) return unknown;
          return {
            image: <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />,
            info: (
              <a
                href={config.mediahubBase + invoice.path}
                target="_blank"
                rel="noopener noreferrer"
                className="kt-widget4__username"
              >
                {`Invoice #${invoice.invoiceNumber}-${invoice.pdfData.content.title} (${
                  invoice.dueIn === -1 ? "Payment in advance" : invoice.partial ? "Partial Invoice" : "Final Invoice"
                })`}
                <br />
                <span style={{ fontWeight: 100 }}>
                  {baseUtils.formatEuro(invoice.total) +
                    (invoice.dueIn === -1
                      ? " to be paid in advance"
                      : " due on " +
                        baseUtils.formatDate(orderUtils.getInvoiceDue(invoice.dueIn, invoice.invoiceDate)))}
                </span>
              </a>
            )
          };
        case T_INVOICEPAID:
        case T_INVOICEPARTLYPAID:
          if (!canSeeFinanceData) return null;
          const invoicePP = order.invoices!.find(i => i._id.toString() === timelineEntry.invoiceId.toString())!;
          const partPaymentId = timelineEntry.paymentId;
          const partPayment = invoicePP.payments.find(p => p._id.toString() === partPaymentId.toString())!;
          return {
            image: <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />,
            info: (
              <a
                href={config.mediahubBase + (partPayment.path ? partPayment.path : invoicePP.path)}
                target="_blank"
                rel="noopener noreferrer"
                className="kt-widget4__username"
              >
                {`Invoice #${invoicePP.invoiceNumber}-${invoicePP.pdfData.content.title} ${
                  timelineEntry.type === T_INVOICEPARTLYPAID ? "partially" : "fully"
                } paid`}
                <br />
                <span style={{ fontWeight: 100 }}>
                  {baseUtils.formatEuro(partPayment.amount) + " paid on " + baseUtils.formatDate(timelineEntry.date)}
                  {timelineEntry.note && " (" + timelineEntry.note + ")"}
                </span>
              </a>
            )
          };
        case T_INVOICECANCELED:
          if (!canSeeFinanceData) return null;
          const invoiceCC = order.invoices!.find(i => i._id.toString() === timelineEntry.invoiceId.toString());
          if (!invoiceCC) return unknown;
          return {
            image: <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />,
            info: (
              <a
                href={config.mediahubBase + invoiceCC.cancelation?.path}
                target="_blank"
                rel="noopener noreferrer"
                className="kt-widget4__username"
              >
                {`Cancelation #${invoiceCC.cancelation?.cancelationNumber}-${invoiceCC.cancelation?.content.title}`}
                <br />
                <span style={{ fontWeight: 100 }}>{"Invoice #" + invoiceCC.invoiceNumber + " canceled"}</span>
              </a>
            )
          };
        case T_INVOICEREMINDER:
          if (!canSeeFinanceData) return null;
          const invoiceR = order.invoices!.find(i => i._id.toString() === timelineEntry.invoiceId.toString())!;
          const reminder = invoiceR.reminder.find(r => r._id.toString() === timelineEntry.reminderId.toString());
          return {
            image: <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />,
            info: (
              <a
                href={config.mediahubBase + reminder?.path}
                target="_blank"
                rel="noopener noreferrer"
                className="kt-widget4__username"
              >
                {`Reminder for #${invoiceR.invoiceNumber}-${invoiceR.pdfData.content.title}`}
                <br />
                <span style={{ fontWeight: 100 }}>
                  {"Reminder " +
                    (reminder?.position
                      ? "with dunning fees and interest (" + baseUtils.formatEuro(reminder.position.total) + ")"
                      : " without interest") +
                    " created"}
                </span>
              </a>
            )
          };
        case T_RESERVECOMMODITY:
          return {
            image: <i className="fas fa-warehouse" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <>
                <span className="kt-widget4__username">Commodity reservations were adjusted</span>
                {timelineEntry.reservations.added.map((ad: BatchSlim, key: number) => {
                  const com = context.commodities.find(c => ad.commodityId.toString() === c._id.toString())!;
                  return (
                    <span key={key} className="kt-widget4__email">
                      New: {ad.used} kg of {com.title.en} from batch {ad.lot}
                    </span>
                  );
                })}
                {timelineEntry.reservations.altered.map((al: { old: BatchSlim; new: BatchSlim }, key: number) => {
                  const com = context.commodities.find(c => al.old.commodityId.toString() === c._id.toString())!;
                  if (al.new.used === al.old.used) return null;
                  return (
                    <span key={key} className="kt-widget4__email">
                      Altered: {al.new.used} kg instead of {al.old.used} kg of {com.title.en} from batch {al.old.lot}
                    </span>
                  );
                })}
                {timelineEntry.reservations.removed.map((re: BatchSlim, key: number) => {
                  const com = context.commodities.find(c => re.commodityId.toString() === c._id.toString())!;
                  return (
                    <span key={key} className="kt-widget4__email">
                      Removed: {com.title.en} from batch {re.lot}
                    </span>
                  );
                })}
              </>
            )
          };
        case T_RESERVEPACKAGING:
          return {
            image: <i className="fas fa-warehouse" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <>
                <span className="kt-widget4__username">Packaging reservations were adjusted</span>
                {timelineEntry.reservations.added.map((ad: PackagingBatchSlim, key: number) => {
                  const pack = context.packagings.find(c => ad.packagingId.toString() === c._id.toString())!;
                  return (
                    <span key={key} className="kt-widget4__email">
                      New: {ad.used} pcs. of {packagingUtils.getShortPackagingInfo(pack)} from batch {ad.lot}
                    </span>
                  );
                })}
                {timelineEntry.reservations.altered.map(
                  (al: { old: PackagingBatchSlim; new: PackagingBatchSlim }, key: number) => {
                    const pack = context.packagings.find(c => al.old.packagingId.toString() === c._id.toString())!;
                    if (al.new.used === al.old.used) return null;
                    return (
                      <span key={key} className="kt-widget4__email">
                        Altered: {al.new.used} pcs. instead of {al.old.used} pcs. of{" "}
                        {packagingUtils.getShortPackagingInfo(pack)} from batch {al.old.lot}
                      </span>
                    );
                  }
                )}
                {timelineEntry.reservations.removed.map((re: PackagingBatchSlim, key: number) => {
                  const pack = context.packagings.find(c => re.packagingId.toString() === c._id.toString())!;
                  return (
                    <span key={key} className="kt-widget4__email">
                      Removed: {packagingUtils.getShortPackagingInfo(pack)} from batch {re.lot}
                    </span>
                  );
                })}
              </>
            )
          };
        case T_REMINDER:
          return {
            image: <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />,
            info: (
              <a
                href={config.mediahubBase + timelineEntry.path}
                target="_blank"
                rel="noopener noreferrer"
                className="kt-widget4__username"
              >
                Reminder for Invoice {timelineEntry.invoiceNumber ? "RE-" + timelineEntry.invoiceNumber : ""}
                <br />
              </a>
            )
          };
        case T_CALLED:
          return {
            image: <i className="flaticon2-pie-chart" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">Called from contract AT-{timelineEntry.contractIdentifier}</span>
            )
          };
        case T_TARGETDATEUPDATED:
          return {
            image: <i className="flaticon2-calendar-2" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <>
                <span className="kt-widget4__username">{`Target date was changed from ${
                  timelineEntry.oldDate
                    ? `${baseUtils.formatDate(timelineEntry.oldDate)} (CW ${dateUtils.getCW(timelineEntry.oldDate)})`
                    : "-"
                } to ${baseUtils.formatDate(timelineEntry.newDate)} (CW ${dateUtils.getCW(
                  timelineEntry.newDate
                )})`}</span>
                {timelineEntry.note && <span>Note: {timelineEntry.note}</span>}
                {timelineEntry.notification && (
                  <span className="kt-widget4__email">Reason: {timelineEntry.notification}</span>
                )}
              </>
            )
          };
        case T_CALLCREATED:
          return {
            image: <i className="flaticon2-pie-chart" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Contract call created ({timelineEntry.units} units)</span>
          };
        case T_REQUESTPENDING:
          return {
            image: <i className="flaticon2-download" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Requested commodity approval</span>
          };
        case T_REQUESTAPPROVED:
          return {
            image: <i className="flaticon2-download" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Commodities approved</span>
          };
        case T_REQUESTAPPROVEDWITHRAWBIDS:
          return {
            image: <i className="flaticon2-download" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Commodities approved with Rawbids</span>
          };
        case T_REQUESTREVOKED:
          return {
            image: <i className="flaticon2-cancel" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Commodity approval revoked</span>
          };
        case T_CALCULATIONCHANGED:
          return {
            image: <i className="fa fa-check-square" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">{`Calculation updated (Old cost per unit: ${baseUtils.formatEuro(
                timelineEntry.prices.oldPrice
              )} New cost per unit: ${baseUtils.formatEuro(timelineEntry.prices.newPrice)})`}</span>
            )
          };
        case T_MANUFACTURERCHANGED:
          return {
            image: <i className="fa fa-check-square" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">{`Manufacturer updated from ${
                timelineEntry.manufacturer.old.name
              } to ${timelineEntry.manufacturer.new.name} (Old cost per unit: ${baseUtils.formatEuro(
                timelineEntry.prices.oldPrice
              )}, New cost per unit: ${baseUtils.formatEuro(timelineEntry.prices.newPrice)})`}</span>
            )
          };
        case T_FILLERCHANGED:
          return {
            image: <i className="fa fa-check-square" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">{`Filler updated from ${timelineEntry.manufacturer.old.name} to ${
                timelineEntry.manufacturer.new.name
              } (Old cost per unit: ${baseUtils.formatEuro(
                timelineEntry.prices.oldPrice
              )}, New cost per unit: ${baseUtils.formatEuro(timelineEntry.prices.newPrice)})`}</span>
            )
          };
        case T_OWNERCHANGED:
          return {
            image: <i className="fas fa-handshake" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">{`The order was handed over from ${timelineEntry.owner.old.name} to ${timelineEntry.owner.new.name}`}</span>
            )
          };
        case T_UPDATE:
          return {
            image: <i className="flaticon2-download" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Updated Order</span>
          };
        case T_OFFER:
          return {
            image: <i className="flaticon2-plus-1" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Initial offer created</span>
          };
        case T_WAITING:
          return {
            image: <i className="flaticon2-plus-1" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">All commodities ordered</span>
          };
        case T_DECLINED:
          return {
            image: <i className="flaticon2-delete" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Declined and closed</span>
          };
        case T_REORDER:
          return {
            image: <i className="flaticon2-refresh-arrow" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Reorder</span>
          };
        case T_PRODUCTION:
          return {
            image: <i className="flaticon2-download" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">In Production</span>
          };
        case T_FULFILLMENT:
          return {
            image: <i className="flaticon2-download" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">In Fulfillment</span>
          };
        case T_FULFILLMENTREVOKED:
          return {
            image: <i className="flaticon2-delete" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Fulfillment Revoked</span>
          };
        case T_REQUESTED:
          return {
            image: <i className="flaticon2-download" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Request created</span>
          };
        case T_REQUESTUPDATED:
          return {
            image: <i className="flaticon2-download" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Updated request</span>
          };
        case T_FEEDBACK:
          const feedBack = context.orderFeedback.find(fb => fb.order.toString() === order._id.toString());
          return {
            image: <i className="flaticon2-download" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <>
                <span className="kt-widget4__username">
                  Feedback from {userName}:{" "}
                  {timelineEntry.skip ? "Feedback skipped" : feedBack?.comment || "No comment"}
                </span>
                <span>
                  {feedBack?.rating &&
                    feedBack?.rating !== -1 &&
                    [1, 2, 3, 4, 5].map(val => (
                      <i
                        key={val}
                        className={"fas fa-star mr-1"}
                        style={val <= feedBack?.rating ? { color: "gold" } : {}}
                      />
                    ))}
                </span>
              </>
            )
          };
        case T_RETURNPRODUCTIONQUEUE:
          return {
            image: <i className="flaticon2-download text-danger" style={{ fontSize: "28px" }} />,
            info: (
              <>
                <span className="kt-widget4__username">Returned to queue</span>
                <span className="kt-widget4__email">
                  Reason: {timelineEntry.reason ? timelineEntry.reason : "No reason given"}
                </span>
              </>
            )
          };
        case T_RESCHEDULE:
          return {
            image: (
              <i className="flaticon-calendar-with-a-clock-time-tools text-warning" style={{ fontSize: "28px" }} />
            ),
            info: (
              <>
                <span className="kt-widget4__username">Rescheduled</span>
                <span className="kt-widget4__email">
                  From CW {timelineEntry.week.before} to CW {timelineEntry.week.after} with reason:{" "}
                  {timelineEntry.reason}
                </span>
              </>
            )
          };
        case T_ORDERED:
          return {
            image: <i className="fa fa-check-square" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">{`Order placed (${order.calculations[0].units} units)`}</span>
          };
        case T_CONVERTEDTOCONTRACT:
          return {
            image: <i className="fas fa-exchange-alt" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">{`Order converted to contract`}</span>
          };
        case T_COMMODITYORDERED:
          if (BSON.ObjectId.isValid(timelineEntry.commodity))
            com = baseUtils.getDocFromCollection(context.commodities, timelineEntry.commodity);
          else if (timelineEntry.commodity.id && BSON.ObjectId.isValid(timelineEntry.commodity.id))
            com = baseUtils.getDocFromCollection(context.commodities, timelineEntry.commodity.id);
          else
            return {
              image: <i className="flaticon2-box-1" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
              info: (
                <>
                  <span className="kt-widget4__username">Unknown Commodity</span>
                </>
              )
            };
          return {
            image: <i className="flaticon2-box-1" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Ordered ${
                  (timelineEntry.commodity.orderquantity
                    ? timelineEntry.commodity.orderquantity
                    : timelineEntry.amount
                    ? timelineEntry.amount
                    : "Unknown ") + (com.type ? "tsd." : "kg")
                } of ${com.title.en} for ${
                  timelineEntry.price
                    ? baseUtils.formatEuro(timelineEntry.price)
                    : timelineEntry.commodity.totalprice
                    ? baseUtils.formatEuro(timelineEntry.commodity.totalprice)
                    : "-"
                }`}
              </span>
            )
          };
        case T_COMMODITYORDEREDITED:
          return {
            image: <i className="flaticon2-edit" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Order of ${
                  baseUtils.getDocFromCollection(context.commodities, timelineEntry.commodity).title.en
                }  was edited`}
              </span>
            )
          };
        case T_COMMODITYORDERCANCELED:
          com = baseUtils.getDocFromCollection(context.commodities, timelineEntry.commodity);
          return {
            image: <i className="flaticon2-delete" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Order of ${timelineEntry.amount + (com.type ? "tsd." : "kg")} ${
                  com.title.en
                } for ${baseUtils.formatEuro(timelineEntry.price)} canceled`}
              </span>
            )
          };
        case T_COMMODITYORDERUNLINKED:
          com = baseUtils.getDocFromCollection(context.commodities, timelineEntry.commodity);
          return {
            image: <i className="flaticon2-delete" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Order was unlinked from a commodity order of ${com ? com.title.en : "Unknown commodity"}`}
              </span>
            )
          };
        case T_COMMODITYDELIVERED:
          com = baseUtils.getDocFromCollection(context.commodities, timelineEntry.commodity);
          return {
            image: <i className="flaticon2-delivery-truck" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Order of ${timelineEntry.amount + (com.type ? "tsd." : "kg")} ${
                  com.title.en
                } for ${baseUtils.formatEuro(timelineEntry.price)} delivered`}
              </span>
            )
          };
        case T_COMMODITYATWAREHOUSE:
          com = baseUtils.getDocFromCollection(context.commodities, timelineEntry.commodity);
          return {
            image: <i className="flaticon2-delivery-truck" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">{`${com.title.en} arrived at warehouse`}</span>
          };
        case T_COMMODITYREPLACED:
          const comPre = baseUtils.getDocFromCollection(context.commodities, timelineEntry.commodityPre).title.en;
          const comPost = baseUtils.getDocFromCollection(context.commodities, timelineEntry.commodityPost).title.en;
          return {
            image: <i className="flaticon2-reload" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {comPre === comPost
                  ? `Source of ${comPre} was changed`
                  : `Commodity ${comPre} was replaced with ${comPost}`}
              </span>
            )
          };
        case T_COMMODITYREMOVED:
          com = baseUtils.getDocFromCollection(context.commodities, timelineEntry.commodity);
          return {
            image: <i className="flaticon2-delete" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Commodity ${com ? com.title.en : "Unknown commodity"} was removed`}
              </span>
            )
          };
        case T_COMMODITYFROMSTOCK:
          com = baseUtils.getDocFromCollection(context.commodities, timelineEntry.commodity);
          return {
            image: <i className="flaticon2-reload" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Commodity ${com ? com.title.en : "Unknown commodity"} will now be taken from stock`}
              </span>
            )
          };
        case T_COMMODITYFROMCUSTOMER:
          com = baseUtils.getDocFromCollection(context.commodities, timelineEntry.commodity);
          return {
            image: <i className="flaticon2-reload" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Commodity ${com ? com.title.en : "Unknown commodity"} will now be provided by customer`}
              </span>
            )
          };
        case T_COMMODITYFROMCUSTOMERDELIVERED:
          com = baseUtils.getDocFromCollection(context.commodities, timelineEntry.commodity);
          return {
            image: <i className="flaticon2-delivery-truck" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Commodity ${com ? com.title.en : "Unknown commodity"} was delivered by customer`}
              </span>
            )
          };
        case T_PACKAGINGORDERED:
          const packaging = baseUtils.getDocFromCollection(context.packagings, timelineEntry.packaging);
          return {
            image: <i className="flaticon2-box-1" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <>
                <span className="kt-widget4__username">
                  {`Ordered ${timelineEntry.orderquantity} x ${packagingUtils.getPackagingType(
                    packaging.packaging_type
                  )} for ${baseUtils.formatEuro(timelineEntry.price)}`}
                </span>
                <p className="kt-widget4__text">{packagingUtils.getShortPackagingInfo(packaging)}</p>
              </>
            )
          };
        case T_PACKAGINGORDERED2:
          pack = baseUtils.getDocFromCollection(context.packagings, timelineEntry.packaging);
          return {
            image: <i className="flaticon2-box-1" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Ordered ${timelineEntry.amount} pcs. of ${packagingUtils.getShortPackagingInfo(pack)} for ${
                  timelineEntry.price
                    ? baseUtils.formatEuro(timelineEntry.price)
                    : timelineEntry.commodity.totalprice
                    ? baseUtils.formatEuro(timelineEntry.commodity.totalprice)
                    : "-"
                }`}
              </span>
            )
          };
        case T_PACKAGINGORDEREDITED:
          return {
            image: <i className="flaticon2-edit" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Order of ${packagingUtils.getShortPackagingInfo(
                  baseUtils.getDocFromCollection(context.packagings, timelineEntry.packaging)
                )}  was edited`}
              </span>
            )
          };
        case T_PACKAGINGORDERCANCELED:
          pack = baseUtils.getDocFromCollection(context.packagings, timelineEntry.packaging);
          return {
            image: <i className="flaticon2-delete" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Order of ${timelineEntry.amount} pcs. ${packagingUtils.getShortPackagingInfo(
                  pack
                )} for ${baseUtils.formatEuro(timelineEntry.price)} canceled`}
              </span>
            )
          };
        case T_PACKAGINGORDERUNLINKED:
          pack = baseUtils.getDocFromCollection(context.packagings, timelineEntry.packaging);
          return {
            image: <i className="flaticon2-delete" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Order was unlinked from a packaging order of ${
                  pack ? packagingUtils.getShortPackagingInfo(pack) : "Unknown packaging"
                }`}
              </span>
            )
          };
        case T_PACKAGINGDELIVERED:
          return {
            image: <i className="flaticon2-delivery-truck" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Packaging ${packagingUtils.getShortPackagingInfo(
                  baseUtils.getDocFromCollection(context.packagings, timelineEntry.packaging)
                )} was delivered`}
              </span>
            )
          };
        case T_PACKAGINGREPLACED:
          const packPre = packagingUtils.resolvePackagingProperties(
            baseUtils.getDocFromCollection(context.packagings, timelineEntry.packagingPre)
          );
          const packPost = packagingUtils.resolvePackagingProperties(
            baseUtils.getDocFromCollection(context.packagings, timelineEntry.packagingPost)
          );
          return {
            image: <i className="flaticon2-reload" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {packPre === packPost
                  ? `Source of packaging ${packPre} was changed`
                  : `Packaging ${packPre} was replaced with ${packPost}`}
              </span>
            )
          };
        case T_PACKAGINGREMOVED:
          pack = baseUtils.getDocFromCollection(context.packagings, timelineEntry.packaging);
          return {
            image: <i className="flaticon2-delete" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Packaging ${
                  pack ? packagingUtils.resolvePackagingProperties(pack) : "Unknown packaging"
                } was removed`}
              </span>
            )
          };
        case T_PACKAGINGFROMSTOCK:
          pack = baseUtils.getDocFromCollection(context.packagings, timelineEntry.packaging);
          return {
            image: <i className="flaticon2-reload" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Packaging ${
                  pack ? packagingUtils.resolvePackagingProperties(pack) : "Unknown packaging"
                } will now be taken from stock`}
              </span>
            )
          };
        case T_PACKAGINGFROMCUSTOMER:
          pack = baseUtils.getDocFromCollection(context.packagings, timelineEntry.packaging);
          return {
            image: <i className="flaticon2-reload" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Packaging ${
                  pack ? packagingUtils.resolvePackagingProperties(pack) : "Unknown packaging"
                } will now be provided by customer`}
              </span>
            )
          };
        case T_PACKAGINGFROMCUSTOMERDELIVERED:
          pack = baseUtils.getDocFromCollection(context.packagings, timelineEntry.packaging);
          return {
            image: <i className="flaticon2-delivery-truck" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <span className="kt-widget4__username">
                {`Packaging ${
                  pack ? packagingUtils.resolvePackagingProperties(pack) : "Unknown packaging"
                } was delivered by customer`}
              </span>
            )
          };
        case T_FILE:
          if (!["printing_file", "label"].includes(timelineEntry.category))
            return {
              image: this.getFileTypeImage(timelineEntry.fileType),
              info: (
                <a href={timelineEntry.path} target="_blank" rel="noopener noreferrer" className="kt-widget4__username">
                  {(timelineEntry.title || timelineEntry.category || "File") +
                    " - " +
                    (timelineEntry.fileType || "undefined type") +
                    " - " +
                    (!timelineEntry.fileSize
                      ? "undefined size"
                      : timelineEntry.fileSize && timelineEntry.fileSize < 1048576
                      ? (timelineEntry.fileSize / 1024).toFixed(2) + " KB"
                      : timelineEntry.fileSize && timelineEntry.fileSize >= 1048576
                      ? (timelineEntry.fileSize / 1024 / 1024).toFixed(2) + " MB"
                      : "undefined size")}
                </a>
              )
            };
          else
            return {
              image: <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />,
              info: (
                <a href={timelineEntry.path} target="_blank" rel="noopener noreferrer" className="kt-widget4__username">
                  Printing file uploaded
                </a>
              )
            };
        case T_SHIPPINGLABEL:
          return {
            image: <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="" />,
            info: (
              <a href={timelineEntry.path} target="_blank" rel="noopener noreferrer" className="kt-widget4__username">
                Shipping Label
              </a>
            )
          };
        case T_TITLEUPDATED:
          return {
            image: <i className="flaticon2-edit" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: (
              <>
                <span className="kt-widget4__username">Title was updated</span>
                {timelineEntry.titlePre !== timelineEntry.titlePost && (
                  <span className="font-weight-normal text-muted">
                    Title was updated from: "{timelineEntry.titlePre}" to "{timelineEntry.titlePost}"
                  </span>
                )}
                {timelineEntry.subtitlePre !== timelineEntry.subtitlePost && (
                  <span className="font-weight-normal text-muted">
                    Subtitle was updated from: "{timelineEntry.subtitlePre}" to "{timelineEntry.subtitlePost}"
                  </span>
                )}
              </>
            )
          };
        case T_PRODUCTIONREPORTAPPROVED:
          return {
            image: <i className="fa fa-check" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Production report approved</span>
          };
        case T_ORDERSHIPPED:
          return {
            image: <i className="fa fa-truck-moving" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Order shipped</span>
          };
        case T_ORDERARCHIVED:
          return {
            image: <i className="fa fa-archive" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Order archived</span>
          };
        case T_SHELFLIFESET:
          return {
            image: <i className="flaticon2-calendar-5" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">Shelf life was set to {timelineEntry.shelfLife} months</span>
          };
        case T_QRCODECREATED:
          return {
            image: <i className="fas fa-qrcode" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
            info: <span className="kt-widget4__username">QR Code was generated</span>
          };
        default:
          return unknown;
      }
    } catch (error) {
      slackService.sendMessage(
        "#interne-fehlermeldungen",
        `Wrong timeline entry found: ${error ? error : "unknown"}. ${JSON.stringify(timelineEntry)}`
      );
      return unknown;
    }
  };

  /**
   * Renders the content of the timeline entry
   * @param userName the name of the person who created the timeline entry
   * @returns { JSX.Element } Content to be displayed for the timeline
   */
  renderTimelineContent = (userName: string) => {
    const { timelineEntry } = this.props;
    const definition = this.getTimelineDefinition(userName);
    if (!definition) return null;
    return (
      <div className="kt-notes__content">
        <div className="kt-notes__section">
          <div className="kt-notes__info">
            <span className="kt-notes__title">{userName}</span>
            <span className="kt-notes__desc">
              {timelineEntry.date.toLocaleDateString("de-DE", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
              })}
            </span>
          </div>
        </div>
        <span className="kt-notes__body">
          <div className="kt-widget4">
            <div className="kt-widget4__item">
              <div className="kt-widget4__pic kt-widget4__pic--icon">{definition.image}</div>
              <div className="kt-widget4__info">
                {definition.info} <p className="kt-widget4__text">{dateUtils.getTimeAgo(timelineEntry.date)}</p>
              </div>
            </div>
          </div>
        </span>
      </div>
    );
  };

  render() {
    const { timelineEntry, context } = this.props;
    const user = baseUtils.getDocFromCollection(context.userdata, timelineEntry.person);
    const userName = user ? user.prename + " " + user.surname : "-";
    const timelineContent = this.renderTimelineContent(userName);

    return timelineContent ? (
      <div className="kt-notes__item pb-5">
        <div className="kt-notes__media">
          <img
            src={
              user && user.img_url ? user.img_url : "https://private-label-factory.de/img/mail/placeholder_female.jpg"
            }
            alt="image"
          />
        </div>
        {timelineContent}
      </div>
    ) : (
      <></>
    );
  }
}

interface OrderTimelineNoteProps {
  order: CustomOrder | OrdersDocument;
  context: React.ContextType<typeof DataContext>;
  small?: boolean;
}

export const OrderTimelineNote: React.FunctionComponent<OrderTimelineNoteProps> = React.memo(
  ({ order, context, small }) => {
    const [note, setNote] = useState("");
    const defaultTargets: Array<{ value: string; label: string }> = [];
    const [targets, setTarget] = useState(defaultTargets);

    const handleNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => setNote(e.target.value);
    const handleTarget = (e?: Array<{ value: string; label: string }>) => {
      setTarget(e ?? []);
    };

    const handleAddNote = async () => {
      const user = userService.getUserData();
      const timelineEntry = {
        type: T_NOTE,
        text: note,
        date: new Date(),
        person: new BSON.ObjectId(user._id),
        slackTargets: targets.map(t => t.value)
      };
      try {
        const result = await dbOrderService.pushToTimeline(order._id, timelineEntry);
        if (result && result.modifiedCount) {
          setNote("");
          toast.success("Note added successfully");
          if (targets.length > 0) {
            const orderDesc = orderUtils.resolveOrderDescription(order);
            const notifiedUsers = targets.map(t => t.value);
            const message = `${user.prename} ${user.surname} has added a note for you on ${orderDesc[0]} <${
              process.env.REACT_APP_BASE_URL
            }/order/${order._id.toString()}|${orderDesc[1]}-${order.identifier}>: ${note}`;
            for (let i = 0; i < notifiedUsers.length; i++) {
              slackService.sendMessage(notifiedUsers[i], message);
            }
            setTarget([]);
          }
        } else toast.error("Note could not be added");
      } catch (e) {
        toast.error("An unexpected error occurred: " + e.message);
      }
    };

    const getRelevantUsers = () => {
      const usersOrder: Set<string> = new Set<string>();
      for (let i = 0; i < order.timeline.length; i++) {
        usersOrder.add(order.timeline[i].person.toString());
      }
      const usersWithSlack = _.orderBy(
        context.userdata.filter(u => u.slackId),
        [u => u.prename, u => u.surname]
      );
      const relevantUsers: Array<UserdataDocument> = [];
      const usedSlackIds: Array<string> = [];
      usersOrder.forEach(u => {
        const user = baseUtils.getDocFromCollection(usersWithSlack, u);
        if (user && user.slackId) {
          relevantUsers.push(user);
          usedSlackIds.push(user.slackId);
        }
      });
      for (let i = 0; i < usersWithSlack.length; i++) {
        const u = usersWithSlack[i];
        if (!usersOrder.has(u._id.toString()) && !usedSlackIds.includes(u.slackId!)) {
          relevantUsers.push(u);
          usedSlackIds.push(u.slackId!);
        }
      }
      return relevantUsers.map(u => {
        return { value: u._id.toString(), label: u.prename + " " + u.surname };
      });
    };

    const disabled = note.trim() === "";
    return (
      <div>
        <div className={"form-group " + (small && "mb-2")}>
          <textarea
            className="form-control"
            id="exampleTextarea"
            rows={3}
            placeholder="Type notes"
            onChange={handleNote}
            value={note}
          />
        </div>
        <div className="row">
          <div className="col d-flex mb-2">
            <div className="mr-2 align-self-center">Slack Notification:</div>
            <div className="w-25">
              <Select
                isClearable={true}
                className="select-default"
                options={getRelevantUsers()}
                value={targets}
                onChange={(e: any) => handleTarget(e)}
                isMulti={true}
              />
            </div>
            <div className="ml-auto">
              <button className={"btn btn-clean btn-bold ml-2 " + (small && "btn-sm")} onClick={() => setNote("")}>
                Reset
              </button>
              <button
                className={"btn btn-label-brand btn-bold " + (small && "btn-sm") + (disabled && " btn-disabled")}
                disabled={disabled}
                onClick={handleAddNote}
              >
                Add Note
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
