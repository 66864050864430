import React from "react";
import { ExtendedEMOrder } from "../../../model/customTypes.types";
import { EmOrderTimelineEntry } from "../../../model/externalManufacturerOrders.types";
import {
  EM_ACCEPTED,
  EM_ARRIVED,
  EM_CANCELED,
  EM_CHECKED,
  EM_DELIVERED,
  EM_EXPIRED,
  EM_FINISHED,
  EM_ORDERCANCELED,
  EM_ORDERCREATED,
  EM_ORDERED,
  EM_ORDEREDITED,
  EM_ORDERUNLINKED
} from "../ExternalManufacturerHelper";
import dateUtils from "../../../utils/dateUtils";
import baseUtils from "../../../utils/baseUtils";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";
import { DataContext } from "../../../context/dataContext";

interface EmOrderTimelineProps {
  order: ExtendedEMOrder;
  context: React.ContextType<typeof ExternalManufacturerContext | typeof DataContext>;
}

const EmOrderTimeline: React.FunctionComponent<EmOrderTimelineProps> = ({ order, context }) => {
  return (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            <i className="flaticon2-time mr-2" style={{ color: "#a2a5b9" }} />
            Timeline
          </h3>
        </div>
        <div className="kt-portlet__head-toolbar" />
      </div>
      <div className="kt-form kt-form--label-right">
        <div className="kt-portlet__body" style={{ minHeight: "50vh" }}>
          <div className="kt-notes kt-scroll kt-scroll--pull ps ps--active-y">
            <div className="kt-notes__items">
              {order.timeline
                .slice()
                .reverse()
                .map(entry => (
                  <EMOTimelineEntry key={entry._id.toString()} entry={entry} context={context} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface EMOTimelineEntryProps {
  entry: EmOrderTimelineEntry;
  context: React.ContextType<typeof ExternalManufacturerContext | typeof DataContext>;
}

const EMOTimelineEntry: React.FunctionComponent<EMOTimelineEntryProps> = ({ entry, context }) => {
  const user = baseUtils.getDocFromCollection(context.userdata, entry.person);
  const userName = user ? user.prename + " " + user.surname : "-";
  /**
   * Checks the type of the timeline entry and returns information on what to display
   * @return {{image: JSX.Element, info.JSX.Element}} Object with image/icon and info description for the timeline entry
   */
  const getTimelineDefinition = () => {
    switch (entry.type) {
      case EM_ORDERCREATED:
        return {
          image: <i className="flaticon2-plus-1" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
          info: (
            <>
              <span className="kt-widget4__username">Request created.</span>
            </>
          )
        };
      case EM_CHECKED:
        return {
          image: <i className="flaticon2-plus-1" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
          info: (
            <>
              <span className="kt-widget4__username">The request was checked and updated with a price.</span>
            </>
          )
        };
      case EM_ACCEPTED:
        return {
          image: <i className="flaticon2-plus-1" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
          info: (
            <>
              <span className="kt-widget4__username">
                The suggested price was accepted. Commodity will be ordered soon.
              </span>
            </>
          )
        };
      case EM_ARRIVED:
        return {
          image: <i className="flaticon2-delivery-package" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
          info: (
            <>
              <span className="kt-widget4__username">The order arrived at its final destination.</span>
            </>
          )
        };
      case EM_CANCELED:
        return {
          image: <i className="flaticon2-delete" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
          info: (
            <>
              <span className="kt-widget4__username">The order was canceled.</span>
            </>
          )
        };
      case EM_ORDERCANCELED:
        return {
          image: <i className="flaticon2-delete" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
          info: (
            <>
              <span className="kt-widget4__username">
                The order was reset as the commodity order was canceled by procurement.
              </span>
            </>
          )
        };
      case EM_ORDERED:
        return {
          image: <i className="flaticon2-box-1" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
          info: (
            <>
              <span className="kt-widget4__username">
                The ordered amount was included in a commodity order and will soon be delivered.
              </span>
            </>
          )
        };
      case EM_DELIVERED:
        return {
          image: <i className="flaticon2-delivery-truck" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
          info: (
            <>
              <span className="kt-widget4__username">
                The order arrived at the airport and is now on the way to its final destination.
              </span>
            </>
          )
        };
      case EM_EXPIRED:
        return {
          image: <i className="flaticon2-hourglass-1" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
          info: (
            <>
              <span className="kt-widget4__username">Order expired due to exceeding the delivery date limit.</span>
            </>
          )
        };
      case EM_FINISHED:
        return {
          image: <i className="flaticon2-check-mark" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
          info: (
            <>
              <span className="kt-widget4__username">The order is completed.</span>
            </>
          )
        };
      case EM_ORDERUNLINKED:
        return {
          image: <i className="flaticon2-delete" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
          info: (
            <>
              <span className="kt-widget4__username">
                The order couldn't be handled with the previously selected commodity order and was reset.
              </span>
            </>
          )
        };
      case EM_ORDEREDITED:
        return {
          image: <i className="flaticon2-edit" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
          info: (
            <>
              <span className="kt-widget4__username">
                The order was edited
                {entry.changes
                  ? ": " +
                    (entry.changes.actualDeliveryDate
                      ? `Old delivery date ${
                          entry.changes.actualDeliveryDate.old
                            ? baseUtils.formatDate(entry.changes.actualDeliveryDate.old)
                            : "-"
                        } - New delivery date ${baseUtils.formatDate(entry.changes.actualDeliveryDate.new)}. `
                      : "")
                  : ""}
              </span>
            </>
          )
        };
      default:
        return {
          image: <i className="fas fa-question" style={{ fontSize: "28px", color: "#bfc5d4" }} />,
          info: (
            <>
              <span className="kt-widget4__username">Unknown Entry</span>
            </>
          )
        };
    }
  };

  /**
   * Renders the content of the timeline entry
   * @returns { JSX.Element } Content to be displayed for the timeline
   */
  const renderTimelineContent = () => {
    const definition = getTimelineDefinition();
    return (
      <div className="kt-notes__content">
        <div className="kt-notes__section">
          <div className="kt-notes__info">
            <span className="kt-notes__title">{userName}</span>
            <span className="kt-notes__desc">{baseUtils.formatDate(entry.date)}</span>
          </div>
        </div>
        <span className="kt-notes__body">
          <div className="kt-widget4">
            <div className="kt-widget4__item">
              <div className="kt-widget4__pic kt-widget4__pic--icon">{definition.image}</div>
              <div className="kt-widget4__info">
                {definition.info} <p className="kt-widget4__text">{dateUtils.getTimeAgo(entry.date)}</p>
              </div>
            </div>
          </div>
        </span>
      </div>
    );
  };
  return (
    <div className="kt-notes__item pb-5">
      <div className="kt-notes__media">
        <img
          src={user && user.img_url ? user.img_url : "https://private-label-factory.de/img/mail/placeholder_female.jpg"}
          alt="image"
        />
      </div>
      {renderTimelineContent()}
    </div>
  );
};

export default EmOrderTimeline;
