import _, { isArray } from "lodash";
import { CustomPackagingsDocument, SelectedPackagingsDocument } from "./CustomTypes";
import packagingUtils from "../../utils/packagingUtils";
import { PackagingTypes, ProductTypes } from "./configuratorConstants";

/**
 * Advanced packaging filter class
 */
export class AdvancedPackagingFilter {
  private _packaging: Array<CustomPackagingsDocument>;
  private _packagingByType: any;

  constructor(packaging: Array<CustomPackagingsDocument>, selectedPackaging: Array<SelectedPackagingsDocument>) {
    const filteredPackaging = packaging.filter(
      pack => !selectedPackaging.some(sPack => pack._id.toString() === sPack._id.toString())
    );
    this._packaging = this.packagingCopy(filteredPackaging);
    this._packagingByType = this.getPackagingByTypes();
  }

  /**
   * Set packaging
   * @param packaging list of packaging to update the filter with
   * @param selectedPackaging list of selected packaging to filter out
   */
  public setPackaging(
    packaging: Array<CustomPackagingsDocument>,
    selectedPackaging: Array<SelectedPackagingsDocument>
  ) {
    const filteredPackaging = packaging.filter(
      pack => !selectedPackaging.some(sPack => pack._id.toString() === sPack._id.toString())
    );
    this._packaging = this.packagingCopy(filteredPackaging);
    this._packagingByType = this.getPackagingByTypes();
  }

  /**
   * Creates a map with packaging divided by their type
   * @private
   * @returns a map with packaging type as key mapped to all packaging for that key
   */
  private getPackagingByTypes() {
    let packagingMap: any = {};
    for (let i = 0; i < this._packaging.length; i++) {
      const packaging = this._packaging[i];
      if (packaging.packaging_type in packagingMap) {
        packagingMap[packaging.packaging_type].push(packaging);
      } else {
        packagingMap[packaging.packaging_type] = [packaging];
      }
    }
    return packagingMap;
  }

  /**
   * Gets all packaging of a given type
   * @param type the packaging type
   * @private
   * @returns array with  all packaging of the given type
   */
  private getPackagingByType(type: string): Array<CustomPackagingsDocument> {
    return this._packagingByType[type] || [];
  }

  /**
   * Copies the objects of an array
   * @param packaging packaging array to copy
   * @private
   * @returns array with packaging copies
   */
  private packagingCopy(packaging: Array<CustomPackagingsDocument>) {
    return _.cloneDeep(packaging);
  }

  /**
   * Get filtered torso packaging based on active tab and preferences
   * @param type the currently selected product type
   * @param torsoSelection currently possible torso selection
   * @param preferences preferences object
   * @param recipeVolume (optional) object with volume info for the recipe selection
   * @returns filtered torso packaging
   */
  public getFilteredTorsoPackaging(
    type: string,
    torsoSelection: Array<string>,
    preferences: any,
    recipeVolume?: { value: number; noDefault: boolean }
  ) {
    const { selectedCapsule, selectedTablet, amountPerUnit } = preferences;
    let torsoPackaging: Array<CustomPackagingsDocument> = [];
    for (let i = 0; i < torsoSelection.length; i++) {
      torsoPackaging = torsoPackaging.concat(this.getPackagingByType(torsoSelection[i]));
    }
    if (recipeVolume && [ProductTypes.POWDER, ProductTypes.LIQUID].includes(type))
      return this.getTorsoPackagingPowderLiquid(torsoPackaging, recipeVolume);
    if (type === ProductTypes.CAPSULES)
      return this.getTorsoPackaging(torsoPackaging, selectedCapsule.capsule_volume, amountPerUnit);
    else if (type === ProductTypes.TABLETS)
      return this.getTorsoPackaging(torsoPackaging, +selectedTablet.volume, amountPerUnit);
    return torsoPackaging;
  }

  /**
   * Filter torso based on the recipe volume of a liquid or powder product
   * @param torsoPackaging all possible torso packaging
   * @param recipeVolume object with volume and flag if density was known while computing the volume
   * @private
   * @returns filtered torso packaging
   */
  private getTorsoPackagingPowderLiquid(
    torsoPackaging: Array<CustomPackagingsDocument>,
    recipeVolume: { value: number; noDefault: boolean }
  ) {
    let filteredPackaging: Array<CustomPackagingsDocument> = [];
    // For bottle, liquidbottle and bag recommend packaging with smallest volume
    let recommendedMap: any = {
      bottle: Number.POSITIVE_INFINITY,
      liquidbottle: Number.POSITIVE_INFINITY,
      bag: Number.POSITIVE_INFINITY
    };
    for (let i = 0; i < torsoPackaging.length; i++) {
      const torso = torsoPackaging[i];
      // tuple of boolean and number, 5% margin if every density was known otherwise 50% margin of error
      const comparison = packagingUtils.compareTorsoAndVolumeWithMargin(
        torso,
        recipeVolume.value,
        recipeVolume.noDefault ? 0.05 : 0.5
      );
      if (comparison[0]) {
        const type = torso.packaging_type;
        filteredPackaging.push(torso);
        // Make sure that packaging that are within the lower limit but not higher than the actual value are shown, but not recommended
        if (recipeVolume.noDefault && recommendedMap[type] > comparison[1] && comparison[1] >= recipeVolume.value)
          recommendedMap[type] = comparison[1];
      }
    }
    // No recommendation if volume is not exact
    if (!recipeVolume.noDefault) return filteredPackaging;
    return this.setRecommended(filteredPackaging, torso => this.isTorsoRecommended(recommendedMap, torso)).sort(
      (a, b) => +!!b.recommended - +!!a.recommended
    );
  }

  /**
   * Filter torso packaging based on volume of selected capsule or tablet
   * @param torsoPackaging all possible torso packaging
   * @param volume volume of the capsule or tablet
   * @param amountPerUnit the amount of capsules
   * @private
   * @returns filtered torso packaging
   */
  private getTorsoPackaging(torsoPackaging: Array<CustomPackagingsDocument>, volume: number, amountPerUnit: string) {
    let filteredPackaging: Array<CustomPackagingsDocument> = [];
    // For bottle, liquidbottle and bag recommend packaging with smallest volume, for blister with highest capsule count
    let recommendedMap: any = {
      bottle: Number.POSITIVE_INFINITY,
      liquidbottle: Number.POSITIVE_INFINITY,
      bag: Number.POSITIVE_INFINITY,
      blister: 0
    };
    for (let i = 0; i < torsoPackaging.length; i++) {
      const torso = torsoPackaging[i];
      // tuple of boolean and number
      const comparison = packagingUtils.compareTorsoAndVolume(torso, volume, +amountPerUnit);
      if (comparison[0]) {
        const type = torso.packaging_type;
        filteredPackaging.push(torso);
        if (type !== PackagingTypes.BLISTER && recommendedMap[type] > comparison[1])
          recommendedMap[type] = comparison[1];
        else if (type === PackagingTypes.BLISTER && recommendedMap[type] < comparison[1])
          recommendedMap[type] = comparison[1];
      }
    }
    return this.setRecommended(filteredPackaging, torso => this.isTorsoRecommended(recommendedMap, torso)).sort(
      (a, b) => +!!b.recommended - +!!a.recommended
    );
  }

  /**
   * Get all suitable packaging for the next step including recommended ones
   * @param currentSelection the already selected packaging
   * @param nextSelection the packaging type to be selected next
   * @returns Array with all suitable packaging
   */
  public getFilteredPackaging(currentSelection: Array<SelectedPackagingsDocument>, nextSelection: Array<string>) {
    if (!nextSelection) return [];
    // nextSelection may also be an array I guess
    let packaging: Array<CustomPackagingsDocument> = [];
    for (let i = 0; i < nextSelection.length; i++) {
      const nextType = nextSelection[i];
      if (isArray(nextType)) {
        for (let j = 0; j < nextType.length; j++) {
          const type = nextType[j];
          packaging = packaging.concat(this.getPackagingForType(currentSelection, type));
        }
      } else {
        packaging = packaging.concat(this.getPackagingForType(currentSelection, nextType));
      }
    }
    // Sort recommended packagings to the beginning
    return packaging.sort((a, b) => +!!b.recommended - +!!a.recommended);
  }

  /**
   * Get all matching packaging of a type for the current selection
   * @param currentSelection the currently selected packaging
   * @param type the type of the packaging to search for
   * @private
   * @returns Array with matching packaging
   */
  private getPackagingForType(
    currentSelection: Array<SelectedPackagingsDocument>,
    type: string
  ): Array<CustomPackagingsDocument> {
    switch (type) {
      case PackagingTypes.LID:
        const lBottle = this.getSelectedByType(currentSelection, PackagingTypes.LIQUIDBOTTLE);
        if (lBottle) return this.getClosureForLiquidbottle(lBottle);
        else return this.getLids(currentSelection);
      case PackagingTypes.LABEL:
        return this.getLabels(currentSelection);
      case PackagingTypes.MULTILAYER_LABEL:
        return this.getMultilayerLabels(currentSelection);
      case PackagingTypes.BOX:
        return this.getBoxes(currentSelection);
      case PackagingTypes.SLEEVE:
        return this.getSleeves(currentSelection);
      case PackagingTypes.PACKAGEINSERT:
      case PackagingTypes.STICKER:
      case PackagingTypes.SPOON:
      case PackagingTypes.SILICAGELBAG:
        return this.getOptionalAccessories(type);
      default:
        return [];
    }
  }

  /**
   * Gets all suitable lids
   * @param currentSelection already selected packaging
   * @private
   * @return Array of lids
   */
  private getLids(currentSelection: Array<SelectedPackagingsDocument>) {
    const bottle = this.getSelectedByType(currentSelection, PackagingTypes.BOTTLE);
    if (bottle) return this.getLidForBottle(bottle);
    return [];
  }

  /**
   * Gets all suitable labels
   * @param currentSelection already selected packaging
   * @private
   * @return Array of labels
   */
  private getLabels(currentSelection: Array<SelectedPackagingsDocument>) {
    // Types are exclusive
    const bottle = this.getSelectedByType(currentSelection, PackagingTypes.BOTTLE);
    if (bottle) return this.getLabelForBottle(bottle, PackagingTypes.LABEL);
    const liquidbottle = this.getSelectedByType(currentSelection, PackagingTypes.LIQUIDBOTTLE);
    if (liquidbottle) return this.getLabelForBottle(liquidbottle, PackagingTypes.LABEL);
    const bag = this.getSelectedByType(currentSelection, PackagingTypes.BAG);
    if (bag) return this.getLabelForBag(bag, PackagingTypes.LABEL);
    return [];
  }

  /**
   * Gets all suitable multilayer labels
   * @param currentSelection already selected packaging
   * @private
   * @return Array of labels
   */
  private getMultilayerLabels(currentSelection: Array<SelectedPackagingsDocument>) {
    // Types are exclusive
    const bottle = this.getSelectedByType(currentSelection, PackagingTypes.BOTTLE);
    if (bottle) return this.getLabelForBottle(bottle, PackagingTypes.MULTILAYER_LABEL);
    const liquidbottle = this.getSelectedByType(currentSelection, PackagingTypes.LIQUIDBOTTLE);
    if (liquidbottle) return this.getLabelForBottle(liquidbottle, PackagingTypes.MULTILAYER_LABEL);
    const bag = this.getSelectedByType(currentSelection, PackagingTypes.BAG);
    if (bag) return this.getLabelForBag(bag, PackagingTypes.MULTILAYER_LABEL);
    return [];
  }

  /**
   * Gets all suitable boxes
   * @param currentSelection already selected packaging
   * @private
   * @return Array of boxes
   */
  private getBoxes(currentSelection: Array<SelectedPackagingsDocument>) {
    // Types are exclusive
    const bottle = this.getSelectedByType(currentSelection, PackagingTypes.BOTTLE);
    if (bottle) return this.getBoxForBottle(bottle);
    const liquidbottle = this.getSelectedByType(currentSelection, PackagingTypes.LIQUIDBOTTLE);
    if (liquidbottle) return this.getBoxForBottle(liquidbottle);
    const blister = this.getSelectedByType(currentSelection, PackagingTypes.BLISTER);
    if (blister) return this.getBoxForBlister(blister);
    return [];
  }

  /**
   * Gets all suitable sleeves
   * @param currentSelection already selected packaging
   * @private
   * @return Array of sleeves
   */
  private getSleeves(currentSelection: Array<SelectedPackagingsDocument>) {
    const bottle = this.getSelectedByType(currentSelection, PackagingTypes.BOTTLE);
    if (bottle) return this.getSleeveForBottle(bottle);
    const liquidbottle = this.getSelectedByType(currentSelection, PackagingTypes.LIQUIDBOTTLE);
    if (liquidbottle) return this.getSleeveForBottle(liquidbottle);
    return [];
  }

  /**
   * Get a packaging object of the given type from selected packaging
   * @param selectedPackaging already selected packaging
   * @param type the desired packaging type
   * @private
   * @returns found packaging object
   */
  private getSelectedByType(selectedPackaging: Array<SelectedPackagingsDocument>, type: string) {
    return selectedPackaging.find(pack => pack.packaging_type === type);
  }

  /**
   * Get all lids that fit for the given bottle
   * @param bottle the bottle packaging to find matching lids for
   * @private
   * @returns Array of lids with recommended flag set for best suitable lids
   */
  private getLidForBottle(bottle: SelectedPackagingsDocument) {
    const lidPackaging = this.getPackagingByType(PackagingTypes.LID);
    const filteredLids = lidPackaging.filter(lid =>
      packagingUtils.compareNeckSize(lid.lid_size, bottle.packaging_neck)
    );
    return this.setRecommended(filteredLids, lid => this.isLidRecommended(bottle, lid));
  }

  /**
   * Get all sleeves that fit for the given lid
   * @param bottle the bottle packaging to find matching sleeves for
   * @private
   * @returns Array of sleeves with recommended flag set for best suitable sleeves
   */
  private getSleeveForBottle(bottle: SelectedPackagingsDocument) {
    const sleevePackaging = this.getPackagingByType(PackagingTypes.SLEEVE);
    const filteredSleeves = sleevePackaging.filter(sleeve =>
      packagingUtils.compareSleeveAndBottle(sleeve.sleeve_size, bottle.packaging_neck)
    );
    return this.setRecommended(filteredSleeves, () => true);
  }

  /**
   * Get all labels that fit for the given bottle
   * @param bottle the bottle packaging to find matching labels for
   * @param type label or multilayer label
   * @private
   * @returns Array of labels with recommended flag set for best suitable labels
   */
  private getLabelForBottle(bottle: SelectedPackagingsDocument, type: string) {
    let labelPackaging = this.getPackagingByType(type);
    const maxLabelHeight = bottle.packaging_label_height;
    const diameter = bottle.packaging_width;
    let filteredLabels: Array<CustomPackagingsDocument> = [];
    let biggestArea = 0;
    for (let i = 0; i < labelPackaging.length; i++) {
      const label = labelPackaging[i];
      if (packagingUtils.compareLabelAndBottle(label.label_width, label.label_height, maxLabelHeight, diameter)) {
        filteredLabels.push(label);
        // compute area
        const area = packagingUtils.getLabelArea(label);
        if (area > biggestArea) biggestArea = area;
      }
    }
    // Set recommended according to biggest area
    return this.setRecommended(filteredLabels, label => packagingUtils.getLabelArea(label) === biggestArea);
  }

  /**
   * Get all boxes that fit the given bottle
   * @param bottle the bottle packaging to find matching boxes for
   * @private
   * @returns Array of boxes with recommended flag set for best suitable boxes
   */
  private getBoxForBottle(bottle: SelectedPackagingsDocument) {
    const boxPackaging = this.getPackagingByType(PackagingTypes.BOX);
    const bottleDimension = [bottle.packaging_width, bottle.packaging_height];
    let filteredBoxes = [];
    let smallestVolume = Number.POSITIVE_INFINITY;
    for (let i = 0; i < boxPackaging.length; i++) {
      const box = boxPackaging[i];
      const boxDimension = [box.box_width, box.box_height, box.box_depth];
      if (packagingUtils.compareBottleAndBox(boxDimension, bottleDimension)) {
        filteredBoxes.push(box);
        // compute volume
        const volume = packagingUtils.getBoxVolume(box);
        if (volume < smallestVolume) smallestVolume = volume;
      }
    }
    // Recommend smallest volume box
    return this.setRecommended(filteredBoxes, box => packagingUtils.getBoxVolume(box) === smallestVolume);
  }

  /**
   * Get all lids, spray pumps and pipettes that fit the given liquidbottle
   * @param liquidbottle the liquid bottle packaging to find matching lids and pipettes for
   * @private
   * @returns Array of lids/pipettes/sprayPumps with recommended flag set for best suitable lids, spray pumps, pipettes
   */
  private getClosureForLiquidbottle(liquidbottle: SelectedPackagingsDocument) {
    let packaging: Array<CustomPackagingsDocument> = this.getLidForBottle(
      liquidbottle
    ) as Array<CustomPackagingsDocument>;
    const sprayPumpPackaging = this.getPackagingByType(PackagingTypes.SPRAYPUMP);
    const pipettePackaging = this.getPackagingByType(PackagingTypes.PIPETTE);
    let filteredPipettes = [];
    let filteredSprayPumps = [];
    let longestHeight = "0";
    for (let i = 0; i < pipettePackaging.length; i++) {
      const pipette = pipettePackaging[i];
      if (
        packagingUtils.comparePipetteAndBottle(
          pipette.packaging_neck,
          pipette.packaging_height,
          liquidbottle.packaging_neck,
          liquidbottle.packaging_height
        )
      ) {
        filteredPipettes.push(pipette);
        if (pipette.packaging_height && +pipette.packaging_height > +longestHeight)
          longestHeight = pipette.packaging_height;
      }
    }
    packaging = packaging.concat(
      this.setRecommended(filteredPipettes, pipette => pipette.packaging_height === longestHeight)
    );
    for (let i = 0; i < sprayPumpPackaging.length; i++) {
      const sprayPump = sprayPumpPackaging[i];
      if (
        packagingUtils.compareSprayPumpAndBottle(
          sprayPump.packaging_neck,
          sprayPump.packaging_height,
          liquidbottle.packaging_neck,
          liquidbottle.packaging_height
        )
      ) {
        filteredSprayPumps.push(sprayPump);
        if (sprayPump.packaging_height && +sprayPump.packaging_height > +longestHeight)
          longestHeight = sprayPump.packaging_height;
      }
    }
    packaging = packaging.concat(
      this.setRecommended(filteredSprayPumps, sprayPump => sprayPump.packaging_height === longestHeight)
    );
    return packaging;
  }

  /**
   * Get all box that fit for the given blister
   * @param blister the blister packaging to find matching boxes for
   * @private
   * @returns Array of boxes with recommended flag set for best suitable boxes
   */
  private getBoxForBlister(blister: SelectedPackagingsDocument) {
    const boxPackaging = this.getPackagingByType(PackagingTypes.BOX);
    const amount = blister.amount ? Number(blister.amount) : 1;
    const blisterDimension = [blister.blister_width, blister.blister_height, blister.blister_depth];
    let filteredBoxes = [];
    let smallestVolume = Number.POSITIVE_INFINITY;
    for (let i = 0; i < boxPackaging.length; i++) {
      const box = boxPackaging[i];
      const boxDimension = [box.box_width, box.box_height, box.box_depth];
      if (packagingUtils.compareBlisterAndBox(amount, boxDimension, blisterDimension)) {
        filteredBoxes.push(box);
        // compute volume
        const volume = packagingUtils.getBoxVolume(box);
        if (volume < smallestVolume) smallestVolume = volume;
      }
    }
    // Recommend smallest volume box
    return this.setRecommended(filteredBoxes, box => packagingUtils.getBoxVolume(box) === smallestVolume);
  }

  /**
   * Get all labels that fit for the given bag
   * @param bag the bag packaging to find matching labels for
   * @param type label or multilayer label
   * @private
   * @returns Array of labels with recommended flag set for best suitable labels
   */
  private getLabelForBag(bag: SelectedPackagingsDocument, type: string) {
    let labelPackaging = this.getPackagingByType(type);
    let filteredLabels: Array<CustomPackagingsDocument> = [];
    let biggestArea = 0;
    for (let i = 0; i < labelPackaging.length; i++) {
      const label = labelPackaging[i];
      if (packagingUtils.compareLabelAndBag(label.label_width, label.label_height, bag.bag_width, bag.bag_height)) {
        filteredLabels.push(label);
        // compute area
        const area = packagingUtils.getLabelArea(label);
        if (area > biggestArea) biggestArea = area;
      }
    }
    // Set recommended according to biggest area
    return this.setRecommended(filteredLabels, label => packagingUtils.getLabelArea(label) === biggestArea);
  }

  /**
   * Get optional accessories which don't have specific requirements or recommendations
   * @param type accessory type
   * @private
   * @returns Array of the given accessory type
   */
  private getOptionalAccessories(type: string) {
    return this.getPackagingByType(type);
  }

  /**
   * Set the recommended flag if suitable for packaging objects
   * @param filteredPackaging the filtered packaging to check for recommendations
   * @param conditionFunction a function to determine whether a packaging is recommended or not
   * @private
   * @returns Array with same packaging as filteredPackaging but with recommended flag set
   */
  private setRecommended(
    filteredPackaging: Array<CustomPackagingsDocument>,
    conditionFunction: (packaging: CustomPackagingsDocument) => boolean
  ) {
    let packaging = [];
    for (let i = 0; i < filteredPackaging.length; i++) {
      const copy = { ...filteredPackaging[i] };
      if (conditionFunction(copy as CustomPackagingsDocument)) {
        copy.recommended = true;
        // Add recommended up front
        packaging.unshift(copy);
      } else packaging.push(copy);
    }
    return packaging as Array<CustomPackagingsDocument>;
  }

  /**
   * Checks if torso is recommend
   * @param recommendedMap map containing the best value for each torso packaging type
   * @param torso the torso packaging object to check
   * @private
   * @returns True if torso is recommended, else False
   */
  private isTorsoRecommended(recommendedMap: any, torso: CustomPackagingsDocument) {
    const type = torso.packaging_type;
    const recommendedValue = recommendedMap[type];
    let comparisonValue;
    if ([PackagingTypes.BOTTLE, PackagingTypes.LIQUIDBOTTLE].includes(type)) comparisonValue = torso.packaging_volume;
    else if (type === PackagingTypes.BAG) comparisonValue = torso.bag_volume;
    else if (type === PackagingTypes.BLISTER) comparisonValue = torso.blister_capsules;
    if (!comparisonValue) return false;
    return comparisonValue.toString() === recommendedValue.toString();
  }

  /**
   * Checks if lid is recommended for the bottle
   * @param bottle the bottle packaging object
   * @param lid the lid packaging object
   * @private
   * @returns True if lid is recommended, else False
   */
  private isLidRecommended(bottle: SelectedPackagingsDocument, lid: CustomPackagingsDocument) {
    // Add additional cases if required
    return bottle.packaging_color === lid.lid_color;
  }
}
