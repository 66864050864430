import React from "react";

interface DunningFloatingButtonProps {
  numberOfSelected: number;
  isDisabled: boolean;
  onClick: () => void;
}

const DunningFloatingButton: React.FunctionComponent<DunningFloatingButtonProps> = ({
  numberOfSelected,
  isDisabled,
  onClick
}) => {
  return (
    <div
      className="kt-scrolltop"
      style={{ marginRight: "2.5%", opacity: "1", width: "fit-content", background: "none" }}
    >
      <button className="btn btn-success" disabled={isDisabled} onClick={onClick}>
        Remind Selected ({numberOfSelected})
      </button>
    </div>
  );
};

export default DunningFloatingButton;
