import { BSON } from "realm-web";
import dbService from "./dbService";

export const R_ORDERCREATED = "orderCreated";
export const R_OFFERAPPROVED = "offerApproved";
export const R_OFFERUPDATED = "offerUpdated";
export const R_ORDERUPDATED = "orderUpdated";
export const R_ORDERCONFIRMED = "orderConfirmed";
export const R_ORDERPRODUCTIONSTARTED = "orderProductionStarted";
export const R_ORDERFULFILLMENTSTARTED = "orderFulfillmentStarted";
export const R_ORDERFILEUPLOAD = "orderFileUpload";
export const R_ORDERSHIPPED = "orderShipped";
export const R_ORDERDECLINED = "orderDeclined";
export const R_ORDERARCHIVED = "orderArchived";
export const R_INITIALNOTEONORDER = "initialNoteOnOrder";
export const R_INVOICEREMINDER = "invoiceReminder";
export const R_INVOICECANCELLATION = "invoiceCancellation";
export const R_INVOICECREATED = "invoiceCreated";
export const R_NEWSADDED = "newsAdded";
export const R_PRODUCTLOWSTOCK = "productLowStock";
export const R_ANSWERONORDER = "answerOnOrder";

export const NOTIFICATION_REASONS = [
  R_ORDERCREATED,
  R_OFFERAPPROVED,
  R_OFFERUPDATED,
  R_ORDERUPDATED,
  R_ORDERCONFIRMED,
  R_ORDERPRODUCTIONSTARTED,
  R_ORDERFULFILLMENTSTARTED,
  R_ORDERFILEUPLOAD,
  R_ORDERSHIPPED,
  R_ORDERDECLINED,
  R_ORDERARCHIVED,
  R_INITIALNOTEONORDER,
  R_INVOICEREMINDER,
  R_INVOICECANCELLATION,
  R_INVOICECREATED,
  R_NEWSADDED,
  R_PRODUCTLOWSTOCK,
  R_ANSWERONORDER
];

/**
 * Send a notification message
 * @param reason notification reason
 * @param reasonId reference id to a related document that will be linked in notifications
 * @param suffix suffix in multiple languages that will be appended to the notification reason message
 * @returns {Promise<boolean>} boolean result of sendNotification function, True if execution was completed, False if execution was aborted early
 */
function notify(reason: string, reasonId: BSON.ObjectId, suffix?: { de: string; en: string }) {
  if (!NOTIFICATION_REASONS.includes(reason)) {
    console.error("Reason", reason, "is not valid.");
    return;
  }
  return dbService.callFunction("sendNotification", [reason, reasonId, suffix]);
}

export default { notify };
