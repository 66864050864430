import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import {
  CalculationCustomPriceDetails,
  CalculationType,
  CustomCalculationForModal,
  ExtendedCapsule,
  Preferences,
  SelectedCommoditiesDocument,
  SelectedPackagingsDocument
} from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import CommoditiesCalculationDetails from "./CommoditiesCalculationDetails";
import PackagingCalculationDetails from "./PackagingCalculationDetails";
import GeneralCalculationDetails from "./GeneralCalculationDetails";
import CapsuleCalculationDetails from "./CapsuleCalculationDetails";
import { ProductTypes } from "../configuratorConstants";
import { MARGIN } from "../../../utils/orderCalculationUtils";
import CustomCalculationModal from "../modals/CustomCalculationModal";

interface CalculationDetailsProps {
  activeType: string;
  disableDelete: boolean;
  preferences: Preferences;
  calculation: CalculationType;
  recipe: Array<SelectedCommoditiesDocument>;
  selectedPackaging: Array<SelectedPackagingsDocument>;
  selectedCapsule: ExtendedCapsule | null;
  customCalculation?: CalculationCustomPriceDetails;
  onCalculationPropertyChange: (id: BSON.ObjectId, path: string, value: any, updatePrices?: boolean) => void;
  onCalculationDelete: (calculation: CalculationType) => void;
  onRecalculateSpecific: (calculation: CalculationType) => void;
  onUpdateCalculation: (id: BSON.ObjectId) => void;
  onCapsuleCalculationUpdate: (calculationId: BSON.ObjectId, path: string, value: any) => void;
  onRecipeCalculationUpdate: (
    commodityId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    path: string,
    value: any,
    recalculate: boolean
  ) => void;
  onPackagingCalculationUpdate: (
    packagingId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    path: string,
    value: any
  ) => void;
  onCapsuleSupplierChange: (calculationId: BSON.ObjectId, newSupplier: BSON.ObjectId | "ownstock" | "customer") => void;
  onPackagingSupplierChange: (
    packagingId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    newSupplier: BSON.ObjectId | "ownstock" | "customer"
  ) => void;
  onRecipeSupplierChange: (
    commodityId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    newSupplier: BSON.ObjectId | "ownstock" | "customer"
  ) => void;
  onSaveCustomCalculation: (
    customCalculationInfo: Array<CustomCalculationForModal>,
    calcId: BSON.ObjectId | string,
    buffer: string | undefined,
    optionalCost: string | undefined,
    note: string | undefined
  ) => void;
  onResetCustomCalculation: (calcId: BSON.ObjectId) => void;
}

interface CalculationDetailsState {
  showDetails: boolean;
}

class CalculationDetails extends PureComponent<CalculationDetailsProps, CalculationDetailsState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  rowRef: any;

  constructor(props: CalculationDetailsProps) {
    super(props);
    this.rowRef = React.createRef();
    this.state = { showDetails: false };
  }

  componentDidMount() {
    this.props.onUpdateCalculation(this.props.calculation.id);
  }

  toggleDetails = () => this.setState({ showDetails: !this.state.showDetails });

  parseValue = (val: string) => {
    let value = val.replaceAll(/^0+/g, "0");
    if (!value.includes(".")) value = Number(value).toString();
    return value;
  };

  getMarginBadgeProperties = () => {
    const percentMargin = this.props.calculation.percentMargin;
    if (percentMargin < MARGIN.CRITICAL) {
      return ["kt-badge--danger", "NOT ALLOWED!"];
    } else if (percentMargin < MARGIN.BAD && percentMargin >= MARGIN.CRITICAL) {
      return ["kt-badge--danger", "CRITICAL MARGIN"];
    } else if (percentMargin < MARGIN.GOOD && percentMargin >= MARGIN.BAD) {
      return ["kt-badge--warning", "BAD MARGIN"];
    } else {
      return ["kt-badge--success", "GOOD MARGIN"];
    }
  };

  render() {
    const {
      calculation,
      activeType,
      disableDelete,
      preferences,
      recipe,
      selectedPackaging,
      selectedCapsule,
      customCalculation,
      onCalculationPropertyChange,
      onCalculationDelete,
      onRecalculateSpecific,
      onCapsuleCalculationUpdate,
      onRecipeCalculationUpdate,
      onPackagingCalculationUpdate,
      onCapsuleSupplierChange,
      onPackagingSupplierChange,
      onRecipeSupplierChange,
      onSaveCustomCalculation,
      onResetCustomCalculation
    } = this.props;
    const { showDetails } = this.state;
    const [badgeClasses, badgeText] = this.getMarginBadgeProperties();
    return (
      <>
        <tr
          className="kt-datatable__row kt-datatable__row-subtable--even calculation-details-header calculation-details-header-hover mt-3"
          ref={this.rowRef}
          onClick={this.toggleDetails}
        >
          <td className="kt-datatable__cell" style={{ padding: "20px" }}>
            <div className="kt-widget kt-widget--user-profile-2">
              <div className="kt-widget__body">
                <div className="kt-widget__content" style={{ paddingBottom: "0.3rem" }}>
                  <div className="kt-widget__stats flex-grow-0 mr-3">
                    <div>
                      <div
                        className="input-group"
                        onClick={e => e.stopPropagation()}
                        onMouseEnter={() => {
                          if (this.rowRef && this.rowRef.current)
                            this.rowRef.current.classList.remove("calculation-details-header-hover");
                        }}
                        onMouseLeave={() => {
                          if (this.rowRef && this.rowRef.current)
                            this.rowRef.current.classList.add("calculation-details-header-hover");
                        }}
                      >
                        <input
                          type="number"
                          className="form-control"
                          value={calculation.units}
                          name="units"
                          min="0"
                          onChange={e =>
                            onCalculationPropertyChange(
                              calculation.id,
                              "units",
                              Number(parseInt(e.target.value) || "0").toString(),
                              false
                            )
                          }
                          onBlur={() => onRecalculateSpecific(calculation)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">Units</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="kt-widget__stats flex-grow-0 mr-3">
                    <div
                      className="input-group mr-2"
                      onClick={e => e.stopPropagation()}
                      onMouseEnter={() => {
                        if (this.rowRef && this.rowRef.current)
                          this.rowRef.current.classList.remove("calculation-details-header-hover");
                      }}
                      onMouseLeave={() => {
                        if (this.rowRef && this.rowRef.current)
                          this.rowRef.current.classList.add("calculation-details-header-hover");
                      }}
                    >
                      <input
                        className="form-control"
                        placeholder="Margin"
                        name="margin"
                        type="number"
                        min="0"
                        onChange={e =>
                          onCalculationPropertyChange(calculation.id, "margin", this.parseValue(e.target.value), true)
                        }
                        value={calculation.margin}
                      />
                      <div className="input-group-append">
                        <select
                          className="form-control pr-1 pl-2"
                          style={{ borderRadius: "0px 5px 5px 0px" }}
                          onChange={e =>
                            onCalculationPropertyChange(calculation.id, "marginType", e.currentTarget.value, true)
                          }
                          value={calculation.marginType}
                        >
                          <option key="percent" value="percent">
                            %
                          </option>
                          <option key="euro" value="euro">
                            €
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="kt-widget__stats flex-grow-0 mr-3">
                    <span className={"kt-badge kt-badge--inline pt-2 pb-2 " + badgeClasses}>
                      <b>{badgeText}</b>
                    </span>
                  </div>
                  <div className="kt-widget__stats mr-3">
                    {customCalculation && (
                      <span className="kt-badge kt-badge--inline pt-2 pb-2 kt-badge--warning">
                        <b>CUSTOM</b>
                      </span>
                    )}
                  </div>
                  <CustomCalculationModal
                    type={activeType}
                    preferences={preferences}
                    calculation={calculation}
                    selectedPackaging={selectedPackaging}
                    customCalculation={customCalculation}
                    rowRef={this.rowRef}
                    onSaveCustomCalculation={onSaveCustomCalculation}
                    onResetCustomCalculation={onResetCustomCalculation}
                  />
                  <div className="kt-widget-stats ml-2">
                    <button
                      type="button"
                      disabled={disableDelete}
                      className={"btn btn-danger " + (disableDelete ? "disabled" : "")}
                      onClick={e => {
                        e.stopPropagation();
                        onCalculationDelete(calculation);
                      }}
                      onMouseEnter={() => {
                        if (this.rowRef && this.rowRef.current)
                          this.rowRef.current.classList.remove("calculation-details-header-hover");
                      }}
                      onMouseLeave={() => {
                        if (this.rowRef && this.rowRef.current)
                          this.rowRef.current.classList.add("calculation-details-header-hover");
                      }}
                    >
                      <i className="fa fa-trash p-0" style={{ fontSize: "100%" }} />
                    </button>
                  </div>
                </div>
                <div className="kt-widget__content">
                  <div className="kt-widget__stats " style={{ paddingBottom: "0.3rem" }}>
                    <div className="kt-widget__icon">
                      <i className="flaticon-piggy-bank" />
                    </div>
                    <div className="kt-widget__details">
                      <span className="kt-widget__title">Unit Price Naked</span>
                      <span className="kt-widget__value">
                        {calculation.unitPriceNaked.toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR"
                        })}
                      </span>
                    </div>
                  </div>
                  <div className="kt-widget__stats " style={{ paddingBottom: "0.3rem" }}>
                    <div className="kt-widget__icon">
                      <i className="flaticon-piggy-bank" />
                    </div>
                    <div className="kt-widget__details">
                      <span className="kt-widget__title">Unit Price</span>
                      <span className="kt-widget__value">
                        {calculation.unitPrice.toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR"
                        })}
                      </span>
                    </div>
                  </div>
                  <div className="kt-widget__stats " style={{ paddingBottom: "0.3rem" }}>
                    <div className="kt-widget__icon">
                      <i className="flaticon-piggy-bank" />
                    </div>
                    <div className="kt-widget__details">
                      <span className="kt-widget__title">Total Price</span>
                      <span className="kt-widget__value">
                        {calculation.totalPrice.toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR"
                        })}
                      </span>
                    </div>
                  </div>
                  <div className="kt-widget__stats " style={{ paddingBottom: "0.3rem" }}>
                    <div className="kt-widget__icon">
                      <i className="flaticon-pie-chart" />
                    </div>
                    <div className="kt-widget__details">
                      <span className="kt-widget__title">Total Margin</span>
                      <span className="kt-widget__value">{calculation.percentMargin.toFixed(2)}%</span>
                    </div>
                  </div>
                  <div className="kt-widget__stats " style={{ paddingBottom: "0.3rem" }}>
                    <div className="kt-widget__icon">
                      <i className="flaticon-pie-chart" />
                    </div>
                    <div className="kt-widget__details">
                      <span className="kt-widget__title">Unit Margin</span>
                      <span className="kt-widget__value">
                        {calculation.unitMargin.toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR"
                        })}
                      </span>
                    </div>
                  </div>
                  <div className="kt-widget__stats" style={{ paddingBottom: "0.3rem" }}>
                    <div className="kt-widget__icon">
                      <i className="flaticon-pie-chart" />
                    </div>
                    <div className="kt-widget__details">
                      <span className="kt-widget__title">Total Margin</span>
                      <span className="kt-widget__value">
                        {calculation.totalMargin.toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR"
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr
          className={
            "kt-datatable__row-subtable kt-datatable__row-subtable--even cd-collapse " + (showDetails ? "cd-show" : "")
          }
        >
          <td className="kt-datatable__subtable" colSpan={8}>
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded shadow-none">
              {activeType === ProductTypes.CAPSULES && (
                <CapsuleCalculationDetails
                  capsule={selectedCapsule}
                  preferences={preferences}
                  calculation={calculation}
                  onCapsuleCalculationUpdate={onCapsuleCalculationUpdate}
                  onCapsuleSupplierChange={onCapsuleSupplierChange}
                />
              )}
              <CommoditiesCalculationDetails
                type={activeType}
                recipe={recipe}
                preferences={preferences}
                calculation={calculation}
                onRecipeCalculationUpdate={onRecipeCalculationUpdate}
                onRecipeSupplierChange={onRecipeSupplierChange}
              />
              <PackagingCalculationDetails
                type={activeType}
                selectedPackaging={selectedPackaging}
                preferences={preferences}
                calculation={calculation}
                onPackagingCalculationUpdate={onPackagingCalculationUpdate}
                onPackagingSupplierChange={onPackagingSupplierChange}
              />
              <GeneralCalculationDetails calculation={calculation} />
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export default CalculationDetails;
