import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { SupplierSelected } from "../../common/supplier/CustomTypes";
import { CommoditiesDocument, CommodityPrice, CommoditySupplier } from "../../../model/commodities.types";
import SupplierPricesSelection from "../../common/supplier/SupplierPricesSelection";
import { PackagingPrice, PackagingsDocument, PackagingSupplier } from "../../../model/packagings.types";
import dbCommodityService from "../../../services/dbServices/dbCommodityService";
import dbPackagingService from "../../../services/dbServices/dbPackagingService";

interface UpdatePricesModalProps {
  type: "commodity" | "packaging";
  document: CommoditiesDocument | PackagingsDocument;
  onClose: () => void;
}

interface UpdatePricesModalState {
  document: CommoditiesDocument | PackagingsDocument;
}

class UpdatePricesModal extends PureComponent<UpdatePricesModalProps, UpdatePricesModalState> {
  constructor(props: UpdatePricesModalProps) {
    super(props);
    this.state = { document: props.document };
  }

  handleSupplierChange = (suppliersSelected: Array<SupplierSelected>) => {
    const { type } = this.props;
    const document = _.cloneDeep(this.state.document);
    if (document) {
      const suppliers = [];
      for (let i = 0; i < suppliersSelected.length; i++) {
        const supplier = suppliersSelected[i];
        let sup: CommoditySupplier | PackagingSupplier = { _id: supplier._id, prices: [] };
        if (type === "commodity") {
          sup = sup as CommoditySupplier;
        } else {
          sup = sup as PackagingSupplier;
        }
        for (let j = 0; j < supplier.prices.length; j++) {
          const price = supplier.prices[j];
          const newPrice: CommodityPrice | PackagingPrice = {
            _id: price._id,
            moq: +price.moq,
            price: +price.totalPrice,
            deliverytime: +price.deliveryTime,
            delivery: type === "packaging" ? "parcel" : "",
            note: price.note,
            date: price.age
          };
          let commPrice: CommodityPrice;
          if (type === "commodity") {
            commPrice = newPrice as CommodityPrice;
            commPrice.incoterm = price.incoterm ? price.incoterm : "";
            commPrice.purchasePrice = +price.purchasePrice;
            commPrice.purchaseCurrency = price.purchasePriceCurrency;
          }
          // @ts-ignore
          sup.prices.push(type === "commodity" ? commPrice : newPrice);
        }
        suppliers.push(sup);
      }
      // @ts-ignore
      document.suppliers = suppliers;
      this.setState({ document });
    }
  };

  handleSave = async () => {
    const { type, onClose } = this.props;
    const { document } = this.state;
    const updateDocument =
      type === "commodity" ? dbCommodityService.updateCommodity : dbPackagingService.updatePackaging;
    try {
      const result = await updateDocument(document._id, { suppliers: document.suppliers });
      if (result && result.modifiedCount) {
        toast.success("Prices successfully updated");
        onClose();
      } else toast.error("Prices could not be updated");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    }
  };

  render() {
    const { type, onClose, document } = this.props;
    let pricesSelectionType: "Commodity" | "Packaging" = type === "commodity" ? "Commodity" : "Packaging";
    return (
      <Modal
        show={true}
        onHide={onClose}
        size={"xl"}
        centered
        name={"updatePricesModal"}
        backdropClassName={"customBackdrop"}
        style={{ zIndex: 950 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update {_.upperFirst(type)} prices</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SupplierPricesSelection
            document={document}
            type={pricesSelectionType}
            onSuppliersSelectedChange={this.handleSupplierChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary btn-sm btn-upper" onClick={onClose}>
            Close
          </button>
          <button className="btn btn-success btn-sm btn-upper" onClick={this.handleSave}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default UpdatePricesModal;
