import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import StockListing from "./StockListing";

interface StockWrapperProps extends RouteComponentProps {}

const StockListingWrapper: React.FunctionComponent<StockWrapperProps> = props => {
  const context = useContext(DataContext);
  return <StockListing context={context} {...props} />;
};

export default StockListingWrapper;
