import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import baseUtils from "../../../utils/baseUtils";
import {
  EM_ACCEPTED,
  EM_ARRIVED,
  EM_CHECKED,
  EM_DELIVERED,
  EM_FINISHED,
  EM_OPEN,
  EM_ORDERED
} from "../ExternalManufacturerHelper";
import { ExternalManufacturerOrdersDocument } from "../../../model/externalManufacturerOrders.types";

interface EmCommodityRelatedOrderProps {
  order: ExternalManufacturerOrdersDocument;
  internal?: boolean;
}

const EmCommodityRelatedOrder: React.FunctionComponent<EmCommodityRelatedOrderProps> = ({ order, internal }) => {
  return (
    <tr>
      <td className="align-middle">
        <Link
          to={(internal ? "/externalOrder/" : "/order/") + order._id.toString()}
          className="h6 kt-link kt-font-dark"
        >
          {[EM_OPEN, EM_CHECKED].includes(order.state) ? "REQ" : "EMO"}-{order.identifier}
        </Link>
        <div className="text-muted">{order.reference}</div>
      </td>
      <td className="align-middle">{baseUtils.formatDate(order.createdOn)}</td>
      <td className="align-middle">
        <span
          className={
            "kt-badge kt-badge--inline kt-badge--pill kt-font-bold " +
            ([EM_OPEN, EM_ACCEPTED].includes(order.state)
              ? "kt-badge--light"
              : order.state === EM_ORDERED
              ? "kt-badge--info"
              : [EM_DELIVERED, EM_ARRIVED, EM_FINISHED].includes(order.state)
              ? "kt-badge--success"
              : "kt-badge--danger")
          }
        >
          {_.upperFirst(order.state)}
        </span>
      </td>
      <td className="align-middle kt-font-bold">{order.amount}kg</td>
      <td className={"align-middle " + (order.actualPrice && "kt-font-bold text-success")}>
        {order.actualPrice ? baseUtils.formatEuro(order.actualPrice) : "-"}
      </td>
      <td className="align-middle">
        {order.actualDeliveryDate ? baseUtils.formatDate(order.actualDeliveryDate) : "-"}
      </td>
      <td className={"align-middle " + (order.priceLimit && "kt-font-bold")}>
        {order.priceLimit ? baseUtils.formatEuro(order.priceLimit) : "-"}
      </td>
      <td className="align-middle">{order.deliveryDateLimit ? baseUtils.formatDate(order.deliveryDateLimit) : "-"}</td>
    </tr>
  );
};

export default EmCommodityRelatedOrder;
