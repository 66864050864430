import { BSON } from "realm-web";
import dbService, { NEWS } from "../dbService";
import { NewsTypes } from "../../model/news.types";

/**
 * Update a news object
 * @param id the id of the document to update
 * @param data the data to update with
 * @returns result of update
 */
async function updateNewsObject(id: BSON.ObjectId, data: NewsTypes) {
  const db = dbService.getDb();
  return db?.collection(NEWS).updateOne({ _id: id }, { $set: data });
}

/**
 * Insert a new news object
 * @param news the news object
 * @returns result of insert
 */
async function createNewsObject(news: NewsTypes) {
  const db = dbService.getDb();
  return db?.collection(NEWS).insertOne(news);
}

/**
 * Deletes a news object
 * @param id the id of the news document to delete
 * @returns result of delete
 */
async function deleteNewsObject(id: BSON.ObjectId) {
  const db = dbService.getDb();
  return db?.collection(NEWS).deleteOne({ _id: id });
}

export default { updateNewsObject, createNewsObject, deleteNewsObject };
