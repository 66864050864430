import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { DataContext } from "../../context/dataContext";
import { SampleRecipesDocument } from "../../model/sampleRecipes.types";
import packagingUtils from "../../utils/packagingUtils";
import calculationUtils from "../../utils/calculationUtils";

interface SampleRecipeModalProps {
  sampleRecipe: SampleRecipesDocument;
  show: boolean;
  onClose: () => void;
}
interface SampleRecipeModalState {}

class SampleRecipeModal extends PureComponent<SampleRecipeModalProps, SampleRecipeModalState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  render() {
    const { onClose, sampleRecipe, show } = this.props;
    const { commodities, packagings } = this.context;
    return (
      <Modal show={show} centered onHide={onClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Sample Recipe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sampleRecipe.image_url && (
            <div className="text-center mb-4">
              <img src={sampleRecipe.image_url} className="w-75" alt="Sample recipe example image" />
            </div>
          )}
          <div className="row mb-2">
            <label className="col-3 kt-font-dark kt-font-bold">Type</label>
            <div className="col-9">
              <span className="kt-font-dark">{_.upperFirst(sampleRecipe.settings.type)}</span>
            </div>
          </div>
          <div className="row mb-2">
            <label className="col-3 kt-font-dark kt-font-bold">Title</label>
            <div className="col-9">
              <span className="kt-font-dark">{sampleRecipe.title.en + " / " + sampleRecipe.title.de}</span>
            </div>
          </div>
          <div className="row mb-2">
            <label className="col-3 kt-font-dark kt-font-bold">Subtitle</label>
            <div className="col-9">
              <span className="kt-font-dark">{sampleRecipe.subtitle.en + " / " + sampleRecipe.subtitle.de}</span>
            </div>
          </div>
          {(sampleRecipe.note.en || sampleRecipe.note.de) && (
            <div className="row mb-2">
              <label className="col-3 kt-font-dark kt-font-bold">Description</label>
              <div className="col-9">
                <span className="kt-font-dark">{sampleRecipe.note.en + " / " + sampleRecipe.note.de}</span>
              </div>
            </div>
          )}
          <div className="row mb-2">
            <label className="col-3 kt-font-dark kt-font-bold">Usage</label>
            <div className="col-9">
              <span className="kt-font-dark">{sampleRecipe.used + " times"}</span>
            </div>
          </div>
          <div className="row mb-2">
            <label className="col-3 kt-font-dark kt-font-bold">Amount per unit</label>
            <div className="col-9">
              <span className="kt-font-dark">{sampleRecipe.settings.perUnit}</span>
            </div>
          </div>
          <div className="row mb-2">
            <label className="col-3 kt-font-dark kt-font-bold">Recipe</label>
            <div className="col-9">
              <ul className="recipeList">
                {sampleRecipe.recipe.map(c => (
                  <li className="kt-font-dark nth" key={c._id.toString()}>
                    {calculationUtils.formatAmount(c.amount, 2)}{" "}
                    {commodities.find(com => com._id.toString() === c._id.toString())?.title.en}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="row mb-2">
            <label className="col-3 kt-font-dark kt-font-bold">Packaging</label>
            <div className="col-9">
              <ul className="recipeList">
                {sampleRecipe.packaging.length > 0 ? (
                  sampleRecipe.packaging.map(p => (
                    <li className="kt-font-dark nth" key={p._id.toString()}>
                      {p.amount}{" "}
                      {packagingUtils.getShortPackagingInfo(
                        packagings.find(pack => pack._id.toString() === p._id.toString())!
                      )}
                    </li>
                  ))
                ) : (
                  <span className="kt-font-dark">no packaging</span>
                )}
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-light" onClick={onClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SampleRecipeModal;
