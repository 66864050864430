import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CreatePackagingBag,
  CreatePackagingBlister,
  CreatePackagingBottle,
  CreatePackagingBox,
  CreatePackagingLabel,
  CreatePackagingLid,
  CreatePackagingLiquidBottle,
  CreatePackagingPipette,
  CreatePackagingSilicaGelBag,
  CreatePackagingSleeve,
  CreatePackagingSpoon,
  CreatePackagingSprayPump,
  CreatePackagingSticker
} from "./CreatePackagingContent";
import {
  BagData,
  BlisterData,
  BottleData,
  BoxData,
  GeneralPackagingData,
  LabelData,
  LidData,
  PipetteData,
  SprayPumpData,
  SleeveData,
  StickerData,
  SpoonData,
  SilicaGelBagData
} from "./CustomTypes";
import {
  BAG_MATERIALS,
  BAG_SHAPES,
  BAG_ZIPPERS,
  BOTTLE_MATERIALS,
  BOTTLE_NECKS,
  BOTTLE_SHAPES,
  BOTTLELID_NECKS,
  BOX_QUALITIES,
  LABEL_QUALITIES,
  LID_MATERIALS,
  LID_SHAPES,
  LIQUIDBOTTLE_NECKS,
  SLEEVE_SIZES,
  SPRAYPUMP_LENGTH,
  SPRAYPUMP_NORMS,
  COLORS,
  STICKER_FORMS,
  StickerForms
} from "./PackagingHelper";
import CanOnlyBeDoneByProcurement from "../common/CanOnlyBeDoneByProcurement";
import { SupplierSelected } from "../common/supplier/CustomTypes";
import SupplierPricesSelection from "../common/supplier/SupplierPricesSelection";
import { PackagingPrice, Packagings, PackagingSupplier } from "../../model/packagings.types";
import {
  PACKAGING_TYPES,
  T_BAG,
  T_BLISTER,
  T_BOTTLE,
  T_BOX,
  T_LABEL,
  T_LID,
  T_LIQUIDBOTTLE,
  T_MULTILAYERLABEL,
  T_PIPETTE,
  T_SPRAYPUMP,
  T_SLEEVE,
  T_STICKER,
  T_PACKAGEINSERT,
  T_SILICAGELBAG,
  T_SPOON
} from "../../utils/packagingUtils";
import dbService, { PACKAGINGS } from "../../services/dbService";
import accessUtils, { CREATELOCATIONS } from "../../utils/accessUtils";

interface CreatePackagingProps extends RouteComponentProps<{}, {}, {}> {}

interface CreatePackagingState {
  type: string;
  generalData: GeneralPackagingData;
  packagingData: {
    bottle: BottleData;
    bag: BagData;
    blister: BlisterData;
    box: BoxData;
    lid: LidData;
    pipette: PipetteData;
    sprayPump: SprayPumpData;
    sleeve: SleeveData;
    label: LabelData;
    sticker: StickerData;
    spoon: SpoonData;
    silicaGelBag: SilicaGelBagData;
  };
  creating: boolean;
}

class CreatePackaging extends PureComponent<CreatePackagingProps, CreatePackagingState> {
  constructor(props: CreatePackagingProps) {
    super(props);
    this.state = {
      creating: false,
      packagingData: {
        bag: {
          shape: BAG_SHAPES[0].value,
          material: BAG_MATERIALS[0].value,
          color: COLORS[0].value,
          zipper: BAG_ZIPPERS[0].value,
          volume: "",
          height: "",
          width: ""
        },
        blister: {
          capsules: "",
          depth: "",
          height: "",
          width: ""
        },
        bottle: {
          shape: BOTTLE_SHAPES[0].value,
          material: BOTTLE_MATERIALS[0].value,
          color: COLORS[0].value,
          neck: BOTTLE_NECKS[0].value,
          labelHeight: "",
          volume: "",
          height: "",
          width: ""
        },
        box: {
          quality: BOX_QUALITIES[0].value,
          depth: "",
          height: "",
          width: ""
        },
        label: {
          quality: LABEL_QUALITIES[0].value,
          height: "",
          width: ""
        },
        lid: {
          shape: LID_SHAPES[0].value,
          material: LID_MATERIALS[0].value,
          color: COLORS[0].value,
          size: BOTTLELID_NECKS[0].value
        },
        pipette: {
          color: COLORS[0].value,
          height: "",
          neck: LIQUIDBOTTLE_NECKS[0].value
        },
        sprayPump: {
          color: COLORS[0].value,
          norm: SPRAYPUMP_NORMS[0].value,
          height: SPRAYPUMP_LENGTH[0].value,
          neck: LIQUIDBOTTLE_NECKS[0].value
        },
        sleeve: {
          size: SLEEVE_SIZES[0].value,
          print: false
        },
        sticker: {
          quality: LABEL_QUALITIES[0].value,
          form: STICKER_FORMS[0].value,
          diameter: "",
          width: "",
          height: ""
        },
        spoon: {
          capacity: "",
          color: COLORS[0].value
        },
        silicaGelBag: {
          weight: ""
        }
      },
      generalData: {
        images: [],
        suppliers: [],
        articleNumber: "",
        note: ""
      },
      type: "bottle"
    };
  }

  /**
   * Resolve the current selected packaging type to the type to access the data with
   * @returns {string} resolved type to access packaging data with
   */
  getDataType = () => {
    const { type } = this.state;
    switch (type) {
      case T_BOTTLE:
      case T_LIQUIDBOTTLE:
        return T_BOTTLE;
      case T_LABEL:
      case T_MULTILAYERLABEL:
        return T_LABEL;
      default:
        return type;
    }
  };

  /**
   * Check if packaging data is complete
   * @returns {boolean} true if data is complete, else false
   */
  isDataComplete = () => {
    const { packagingData } = this.state;
    const type = this.getDataType();
    // No additional data for package insert
    if (type === T_PACKAGEINSERT) return true;
    if (type === T_STICKER)
      return (
        (packagingData.sticker.form === StickerForms.ROUND && packagingData.sticker.diameter.trim() !== "") ||
        (packagingData.sticker.form === StickerForms.ANGULAR &&
          packagingData.sticker.width.trim() !== "" &&
          packagingData.sticker.height.trim() !== "")
      );
    const data = _.get(packagingData, type);
    let values: Array<string | boolean> = Object.values(data);
    for (let value of values) {
      if (typeof value === "string" && value.trim() === "") {
        return false;
      }
    }
    return true;
  };

  handleGeneralChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const generalData = _.cloneDeep(this.state.generalData);
    const key = e.target.name as keyof GeneralPackagingData;
    _.set(generalData, key, e.target.value);
    this.setState({ generalData });
  };

  handlePackagingDataChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const type = this.getDataType();
    const packagingData = _.cloneDeep(this.state.packagingData);
    const data = _.get(packagingData, type);
    const key = e.target.name;
    let val = e.target.value;
    if (e.target.type === "number") {
      val = val.replaceAll(/^0+/g, "0");
      if (!val.includes(".")) val = Number(val).toString();
      if (!val || +val < 0) return;
    }
    if (key === "print") _.set(data, key, !_.get(data, key));
    else _.set(data, key, val);
    this.setState({ packagingData });
  };

  handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => this.setState({ type: e.currentTarget.value });

  handleSupplierChange = (suppliersSelected: Array<SupplierSelected>) => {
    const generalData = _.cloneDeep(this.state.generalData);
    const suppliers = [];
    for (let i = 0; i < suppliersSelected.length; i++) {
      const supplier = suppliersSelected[i];
      let sup: PackagingSupplier = { _id: supplier._id, prices: [] };
      for (let j = 0; j < supplier.prices.length; j++) {
        const price = supplier.prices[j];
        let newPrice: PackagingPrice = {
          _id: price._id,
          moq: +price.moq,
          price: +price.totalPrice,
          deliverytime: +price.deliveryTime,
          delivery: "parcel",
          note: price.note,
          date: price.age
        };
        sup.prices.push(newPrice);
      }
      suppliers.push(sup);
    }
    generalData.suppliers = suppliers;
    this.setState({ generalData });
  };

  handleSave = async () => {
    this.setState({ creating: true });
    const { type, generalData, packagingData } = this.state;
    const { note, articleNumber, suppliers } = generalData;
    const { bottle, box, bag, blister, lid, pipette, sprayPump, sleeve, label, sticker, spoon, silicaGelBag } =
      packagingData;
    const packaging: Packagings = {
      packaging_type: type,
      note: note,
      article_number: articleNumber,
      images: [],
      specifications: [],
      suppliers: suppliers
    };
    switch (type) {
      case T_BOTTLE:
      case T_LIQUIDBOTTLE:
        if (type === T_BOTTLE) packaging.packaging_shape = bottle.shape;
        packaging.packaging_material = bottle.material;
        packaging.packaging_color = bottle.color;
        packaging.packaging_volume = bottle.volume;
        packaging.packaging_height = bottle.height;
        packaging.packaging_width = bottle.width;
        packaging.packaging_label_height = bottle.labelHeight;
        packaging.packaging_neck = bottle.neck;
        break;
      case T_BAG:
        packaging.bag_shape = bag.shape;
        packaging.bag_material = bag.material;
        packaging.bag_zipper = bag.zipper;
        packaging.bag_volume = bag.volume;
        packaging.bag_height = bag.height;
        packaging.bag_width = bag.width;
        packaging.bag_color = bag.color;
        break;
      case T_BLISTER:
        packaging.blister_capsules = blister.capsules;
        packaging.blister_depth = blister.depth;
        packaging.blister_height = blister.height;
        packaging.blister_width = blister.width;
        break;
      case T_BOX:
        packaging.box_quality = box.quality;
        packaging.box_depth = box.depth;
        packaging.box_height = box.height;
        packaging.box_width = box.width;
        break;
      case T_LID:
        packaging.lid_shape = lid.shape;
        packaging.lid_material = lid.material;
        packaging.lid_size = lid.size;
        packaging.lid_color = lid.color;
        break;
      case T_PIPETTE:
        packaging.packaging_color = pipette.color;
        packaging.packaging_height = pipette.height;
        packaging.packaging_neck = pipette.neck;
        break;
      case T_SPRAYPUMP:
        packaging.packaging_color = sprayPump.color;
        packaging.packaging_norm = sprayPump.norm;
        packaging.packaging_height = sprayPump.height;
        packaging.packaging_neck = sprayPump.neck;
        break;
      case T_SLEEVE:
        packaging.sleeve_print = sleeve.print;
        packaging.sleeve_size = sleeve.size;
        break;
      case T_LABEL:
      case T_MULTILAYERLABEL:
        packaging.label_quality = label.quality;
        packaging.label_width = label.width;
        packaging.label_height = label.height;
        break;
      case T_STICKER:
        packaging.form = sticker.form;
        packaging.quality = sticker.quality;
        if (sticker.form === StickerForms.ROUND) {
          packaging.diameter = sticker.diameter;
        } else if (sticker.form === StickerForms.ANGULAR) {
          packaging.packaging_height = sticker.height;
          packaging.packaging_width = sticker.width;
        }
        break;
      case T_SPOON:
        packaging.capacity = spoon.capacity;
        packaging.packaging_color = spoon.color;
        break;
      case T_SILICAGELBAG:
        packaging.weight = silicaGelBag.weight;
        break;
    }
    try {
      const result = await dbService.insertDocument(PACKAGINGS, packaging);
      if (result && result.insertedId) {
        toast.success("Packaging created successfully");
        this.props.history.push("/packaging/" + result.insertedId.toString());
      } else toast.error("Packaging could not be created");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ creating: false });
    }
  };

  render() {
    const { type, generalData, packagingData, creating } = this.state;
    const { note, articleNumber } = generalData;
    const { bottle, box, bag, blister, lid, pipette, sprayPump, sleeve, label, sticker, spoon, silicaGelBag } =
      packagingData;
    const complete = this.isDataComplete();
    const canCreate = accessUtils.canCreateData(CREATELOCATIONS.PACKAGING);

    return (
      <div>
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand fa fa-building" />
              </span>
              <h3 className="kt-portlet__head-title">Create a New Packaging</h3>
            </div>
            <div className="kt-portlet__head-toolbar">
              <button onClick={this.props.history.goBack} className="btn btn-clean">
                <i className="la la-arrow-left" />
                <span className="kt-hidden-mobile">Back</span>
              </button>
            </div>
          </div>
          <div className="kt-portlet__body">
            {canCreate ? (
              <div className="kt-form">
                <div className="row">
                  <div className="col-xl-2 col" />
                  <div className="col-xl-8 col-12">
                    <div className="kt-section">
                      <div className="kt-section__body">
                        <h3 className="kt-section__title kt-section__title-lg">Packaging Info:</h3>
                        <div>
                          <div className="form-group row">
                            <label className="col-3 col-form-label">Packaging Type</label>
                            <div className="col-9">
                              <select className="form-control" onChange={this.handleTypeChange}>
                                {PACKAGING_TYPES.slice(1).map(filter => (
                                  <option key={filter.value} value={filter.value}>
                                    {filter.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
                          {type === T_BOTTLE && (
                            <CreatePackagingBottle
                              data={bottle}
                              onPackagingDataChange={this.handlePackagingDataChange}
                            />
                          )}
                          {type === T_LIQUIDBOTTLE && (
                            <CreatePackagingLiquidBottle
                              data={bottle}
                              onPackagingDataChange={this.handlePackagingDataChange}
                            />
                          )}
                          {type === T_PIPETTE && (
                            <CreatePackagingPipette
                              data={pipette}
                              onPackagingDataChange={this.handlePackagingDataChange}
                            />
                          )}
                          {type === T_SPRAYPUMP && (
                            <CreatePackagingSprayPump
                              data={sprayPump}
                              onPackagingDataChange={this.handlePackagingDataChange}
                            />
                          )}
                          {type === T_LID && (
                            <CreatePackagingLid data={lid} onPackagingDataChange={this.handlePackagingDataChange} />
                          )}
                          {type === T_BAG && (
                            <CreatePackagingBag data={bag} onPackagingDataChange={this.handlePackagingDataChange} />
                          )}
                          {type === T_BLISTER && (
                            <CreatePackagingBlister
                              data={blister}
                              onPackagingDataChange={this.handlePackagingDataChange}
                            />
                          )}
                          {type === T_BOX && (
                            <CreatePackagingBox data={box} onPackagingDataChange={this.handlePackagingDataChange} />
                          )}
                          {(type === T_LABEL || type === T_MULTILAYERLABEL) && (
                            <CreatePackagingLabel data={label} onPackagingDataChange={this.handlePackagingDataChange} />
                          )}
                          {type === T_SLEEVE && (
                            <CreatePackagingSleeve
                              data={sleeve}
                              onPackagingDataChange={this.handlePackagingDataChange}
                            />
                          )}
                          {type === T_STICKER && (
                            <CreatePackagingSticker
                              data={sticker}
                              onPackagingDataChange={this.handlePackagingDataChange}
                            />
                          )}
                          {type === T_SPOON && (
                            <CreatePackagingSpoon data={spoon} onPackagingDataChange={this.handlePackagingDataChange} />
                          )}
                          {type === T_SILICAGELBAG && (
                            <CreatePackagingSilicaGelBag
                              data={silicaGelBag}
                              onPackagingDataChange={this.handlePackagingDataChange}
                            />
                          )}
                          <div className="form-group row">
                            <label className="col-3 col-form-label">Note </label>
                            <div className="col-3">
                              <textarea
                                className="form-control"
                                placeholder={"Note"}
                                name="note"
                                rows={3}
                                onChange={this.handleGeneralChange}
                                value={note}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-3 col-form-label">Article Number </label>
                            <div className="col-3">
                              <input
                                className={"form-control"}
                                type="text"
                                placeholder="Article number"
                                name="articleNumber"
                                onChange={this.handleGeneralChange}
                                value={articleNumber}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
                        <div className="form-group row">
                          <label className="col-3 col-form-label">Suppliers / Pricing </label>
                          <div className="col-9">
                            <SupplierPricesSelection
                              type={"Packaging"}
                              onSuppliersSelectedChange={this.handleSupplierChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <CanOnlyBeDoneByProcurement location="packaging" />
            )}
          </div>
          <div className="kt-portlet__foot text-right">
            <button
              className={"btn btn-success " + ((!complete || creating) && "disabled")}
              disabled={!complete || creating}
              onClick={this.handleSave}
            >
              {creating && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Create Packaging
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatePackaging;
