import React, { useEffect, useMemo, useState } from "react";
import { BSON } from "realm-web";
import { resolveTranslation } from "../../../../utils/translationUtils";
import {
  Batch,
  LocationWarehouseAreaSnapshot,
  LocationWarehouseSnapshot,
  StorageSpaceSnapshot
} from "../../../../model/warehouse/batch.types";
import {
  AdjustedPackagingUnitValues,
  BookOutPackagingUnit,
  BookOutReservation,
  ExtendedBatchLocation,
  ExtendedPackagingUnit
} from "../../../../model/warehouse/customTypes.types";
import {
  PackagingUnitDefinition,
  PhysicalWarehouse,
  StorageSpace
} from "../../../../model/configuration/warehouseConfiguration.types";
import { NumValue, SelectOption } from "../../../../model/common.types";
import { compareBatchLocations } from "../../../../utils/batchUtils";
import {
  getPackagingUnitSelectOption,
  getStorageSpaceSelectOption,
  getWarehouseAreaSelectOption
} from "../../../../utils/warehouseUtils";
import { BookOutOverviewNewPackagingUnits } from "./bookOutOverview/BookOutOverviewNewPackagingUnits";
import { BookOutOverviewReservations } from "./bookOutOverview/BookOutOverviewReservations";
import { BookOutOverviewSpecificLocation } from "./bookOutOverview/BookOutOverviewSpecificLocation";
import { BookOutOverviewRemoteWarehouseData } from "./bookOutOverview/BookOutOverviewRemoteWarehouseData";
import i18n from "../../../../translations/i18n";

interface BookOutOverviewProps {
  batch: Batch;
  batchLocationSettings: Array<ExtendedBatchLocation>;
  onChangeBookOutData: (data: BookOutData) => void;
}

export interface BookOutLocation {
  warehouseSnapshot: LocationWarehouseSnapshot;
  warehouseArea: LocationWarehouseAreaSnapshot;
  specificLocations: Array<BookOutSpecificLocation> | null;
  remoteWarehouseData: RemoteWarehouseData | null;
}

export interface RemoteWarehouseData {
  locationId: string;
  amountToBookOut: string;
  amountAtLocation: NumValue;
  packagingUnits: Array<ExtendedPackagingUnit>;
}

export interface BookOutSpecificLocation {
  locationId: string; // id of the specific location since we group by logical + physical warehouse here
  storageSpace?: StorageSpaceSnapshot;
  packagingUnits: Array<BookOutPackagingUnit>;
}

export interface NewPackagingUnitValues extends AdjustedPackagingUnitValues {
  reference: { location: string; packagingUnit: string }; // to which location/pu the new values originally belong
  _id: string;
  amount: string;
  unit: string;
  warehouse: LocationWarehouseSnapshot;
  selectedPU: SelectOption<PackagingUnitDefinition>;
  selectedWarehouseArea: SelectOption<LocationWarehouseAreaSnapshot | PhysicalWarehouse>;
  selectedStorageSpace: SelectOption<null | StorageSpace | StorageSpaceSnapshot> | null;
}

interface BookOutOverviewState {
  locations: Array<BookOutLocation>;
  newPackagingUnits: Array<NewPackagingUnitValues>;
}

export interface BookOutData {
  locations: Array<BookOutLocation>;
  newPackagingUnits: Array<NewPackagingUnitValues>;
  reservations: Array<BookOutReservation>;
}

/**
 * Get default state for book out overview
 * @param batchLocationSettings list of extended batch locations
 * @returns { BookOutOverviewState } object with default state values
 */
const getDefaultState = (batchLocationSettings: Array<ExtendedBatchLocation>): BookOutOverviewState => {
  const locations: Array<BookOutLocation> = [];
  const newPackagingUnits: Array<NewPackagingUnitValues> = [];

  batchLocationSettings.forEach(l => {
    const { _id, packagingUnits, remoteWarehouseData, location } = l;
    const specificLocations: Array<BookOutSpecificLocation> | null = remoteWarehouseData
      ? null
      : [
          {
            locationId: _id.toString(),
            storageSpace: location.storageSpace,
            packagingUnits: packagingUnits
              .map(pU => {
                if (+pU.amountToBookOut <= 0) return null;
                const amountLeft = pU.amountPerPu.value * (pU.quantity || 0) - +pU.amountToBookOut;
                const quantity = Math.floor(amountLeft / pU.amountPerPu.value);
                const leftOver = amountLeft - quantity * pU.amountPerPu.value;
                if (leftOver > 0) {
                  newPackagingUnits.push({
                    _id: new BSON.ObjectId().toString(),
                    reference: { location: _id.toString(), packagingUnit: pU._id.toString() },
                    quantity: "1",
                    amount: leftOver.toString(),
                    unit: pU.amountPerPu.unit,
                    warehouse: location.warehouseSnapshot,
                    selectedPU: getPackagingUnitSelectOption(pU.puSnapshot),
                    selectedStorageSpace: getStorageSpaceSelectOption(location.storageSpace),
                    selectedWarehouseArea: getWarehouseAreaSelectOption(
                      location.warehouseArea,
                      location.warehouseSnapshot
                    )
                  });
                }
                const newPu: BookOutPackagingUnit = {
                  ...pU,
                  adjustedValues: {
                    quantity: quantity.toString()
                  }
                };
                return newPu;
              })
              .filter(e => !!e) as Array<BookOutPackagingUnit>
          }
        ];

    if (specificLocations && specificLocations[0].packagingUnits.length === 0) return;

    const existingLocation = locations.find(l2 =>
      compareBatchLocations(
        { warehouseArea: l2.warehouseArea, warehouseSnapshot: l2.warehouseSnapshot },
        location,
        true
      )
    );

    if (existingLocation) {
      if (existingLocation.specificLocations && specificLocations)
        existingLocation.specificLocations = existingLocation.specificLocations.concat(specificLocations);
    } else {
      if (specificLocations || remoteWarehouseData) {
        const newLocation: BookOutLocation = {
          warehouseSnapshot: location.warehouseSnapshot,
          warehouseArea: location.warehouseArea,
          specificLocations: specificLocations,
          remoteWarehouseData:
            remoteWarehouseData && +remoteWarehouseData.amountToBookOut > 0
              ? {
                  locationId: _id.toString(),
                  amountToBookOut: remoteWarehouseData?.amountToBookOut || "0",
                  packagingUnits: l.packagingUnits,
                  amountAtLocation: l.amountAtLocation
                }
              : null
        };
        if (newLocation.specificLocations || newLocation.remoteWarehouseData) locations.push(newLocation);
      }
    }
  });

  return { locations, newPackagingUnits };
};

const BookOutOverview: React.FC<BookOutOverviewProps> = ({ batch, batchLocationSettings, onChangeBookOutData }) => {
  const [{ locations, newPackagingUnits }] = useState<BookOutOverviewState>(getDefaultState(batchLocationSettings));

  // No updates for reservations via handlers necessary
  const reservations = useMemo(() => {
    const bookOutReservations: Array<BookOutReservation> = [];
    batchLocationSettings.forEach(l => {
      if (l.reservations && l.reservations.some(r => +r.amountToBookOut > 0)) {
        l.reservations.forEach(r => {
          const materials = r.materials.filter(
            m => m.material.details._id.toString() === batch.content.details._id.toString()
          );
          bookOutReservations.push({
            _id: new BSON.ObjectId().toString(),
            reservation: { ...r, materials },
            location: l.location
          });
        });
      }
    });
    return bookOutReservations;
  }, [batchLocationSettings]);

  useEffect(() => {
    onChangeBookOutData({ locations, newPackagingUnits, reservations });
  }, [locations, newPackagingUnits, reservations]);

  return (
    <>
      <span className=" font-size-lg text-black d-block mt-3 mb-3" style={{ fontWeight: 500 }}>
        {i18n.t("warehouse:batch")} {batch.lot}
      </span>
      {locations.map(l => (
        <BookOutOverviewLocation
          key={l.warehouseSnapshot._id.toString() + l.warehouseArea._id.toString()}
          location={l}
        />
      ))}
      <BookOutOverviewNewPackagingUnits newPackagingUnits={newPackagingUnits} />
      <BookOutOverviewReservations reservations={reservations} />
    </>
  );
};

interface BookOutOverviewLocationProps {
  location: BookOutLocation;
}

const BookOutOverviewLocation: React.FC<BookOutOverviewLocationProps> = ({ location }) => {
  const { remoteWarehouseData, specificLocations, warehouseSnapshot, warehouseArea } = location;
  return (
    <div
      className="card card-custom card-fit card-border my-3"
      style={{
        boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.05)"
      }}
    >
      <div className="card-body">
        <div className="font-size-lg text-black d-block my-2 font-weight-normal">
          {resolveTranslation(warehouseSnapshot.warehouseName)} {resolveTranslation(warehouseArea.warehouseName)}
          <span className={"badge  float-right " + (remoteWarehouseData ? "badge-info" : "badge-primary")}>
            {remoteWarehouseData ? i18n.t("warehouse:remote") : i18n.t("warehouse:managed")}
          </span>
        </div>
        <hr />
        {remoteWarehouseData ? (
          <BookOutOverviewRemoteWarehouseData location={location} />
        ) : (
          specificLocations &&
          specificLocations.map(specLoc => (
            <BookOutOverviewSpecificLocation
              key={specLoc.storageSpace?._id.toString() || "entrance"}
              specificLocation={specLoc}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default BookOutOverview;
