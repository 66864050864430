import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BSON } from "realm-web";
import SettingsTable from "./SettingsTable";
import { DataContext } from "../../context/dataContext";
import dbService, { COMMODITYPROPERTIES } from "../../services/dbService";
import toastUtils from "../../utils/toastUtils";

interface CommodityPropertiesProps extends RouteComponentProps<{}, {}, {}> {}

interface CommodityPropertiesState {
  query: string;
}

class CommodityProperties extends PureComponent<CommodityPropertiesProps, CommodityPropertiesState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: CommodityPropertiesProps) {
    super(props);
    this.state = { query: "" };
  }

  /**
   * Handles creating a new commodity property.
   * @param _id: ID of the commodity property that is created
   * @param de: Name of the commodity property in german
   * @param en: Name of the commodity property in english
   */
  handleCreateCommodityProperties = async (_id: BSON.ObjectId, de: string, en: string) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.insertDocument(COMMODITYPROPERTIES, { _id, name: { de, en } });
    await toastUtils.databaseOperationToast(
      !!res && res.insertedId,
      "Commodity property successfully added",
      "Adding commodity property failed",
      () => updateDocumentInContext(COMMODITYPROPERTIES, _id)
    );
  };

  /**
   * Handles editing an commodity property.
   * @param _id: ID of the commodity property that is edited
   * @param de: Name of the commodity property in german
   * @param en: Name of the commodity property in english
   */
  handleEditCommodityProperties = async (_id: BSON.ObjectId, de: string, en: string) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.updateDocument(COMMODITYPROPERTIES, _id, { name: { de, en } });
    await toastUtils.databaseOperationToast(
      !!res && res.modifiedCount > 0,
      "Commodity property successfully edited",
      "Editing commodity property failed",
      () => updateDocumentInContext(COMMODITYPROPERTIES, _id)
    );
  };

  /**
   * Handles deleting an commodity property.
   * @param _id: ID of the commodity property that should be deleted
   */
  handleDeleteCommodityProperties = async (_id: BSON.ObjectId) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.deleteDocument(COMMODITYPROPERTIES, _id);
    await toastUtils.databaseOperationToast(
      !!res && res.deletedCount > 0,
      "Commodity property successfully deleted",
      "Deleting commodity property failed",
      () => updateDocumentInContext(COMMODITYPROPERTIES, _id)
    );
  };

  render() {
    const { query } = this.state;
    const { commodities, commodityproperties } = this.context;

    const ql = query.toLowerCase();
    const c = _.cloneDeep(
      commodityproperties.filter(c => c.name.de.toLowerCase().includes(ql) || c.name.en.toLowerCase().includes(ql))
    );

    return (
      <SettingsTable
        setting="CommodityProperties"
        settings={c.sort((c1, c2) => c1.name.de.localeCompare(c2.name.de))}
        title={{ table: "Commodity Properties", modal: "Commodity Property" }}
        usageCheck={_id => commodities.some(c => c.properties.map(p => p.toString()).includes(_id.toString()))}
        onDelete={this.handleDeleteCommodityProperties}
        onSave={this.handleCreateCommodityProperties}
        onEdit={this.handleEditCommodityProperties}
        onSearch={query => this.setState({ query })}
      />
    );
  }
}

export default withRouter(CommodityProperties);
