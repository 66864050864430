import React, { PureComponent } from "react";
import OutputGoals from "./roadmap/OutputGoals";

interface RoadmapDashboardProps {}

interface RoadmapDashboardState {}

class RoadmapDashboard extends PureComponent<RoadmapDashboardProps, RoadmapDashboardState> {
  render() {
    return (
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-4">
          <OutputGoals type="bottles" />
        </div>
        <div className="col-12 col-lg-6 col-xl-4">
          <OutputGoals type="capsules" />
        </div>
        <div className="col-12 col-lg-6 col-xl-4">
          <OutputGoals type="turnover" />
        </div>
        <div className="col-12 col-lg-6 col-xl-4">
          <OutputGoals type="profit" />
        </div>
      </div>
    );
  }
}

export default RoadmapDashboard;
