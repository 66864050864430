import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";
import ExternalManufacturerOrder from "./ExternalManufacturerOrder";

interface EMOrderParams {
  id: string;
}

interface EMOrderWrapperProps extends RouteComponentProps<EMOrderParams, {}, {}> {}

const EmOrderWrapper: React.FunctionComponent<EMOrderWrapperProps> = props => {
  const context = useContext(ExternalManufacturerContext);
  return <ExternalManufacturerOrder context={context} {...props} />;
};

export default EmOrderWrapper;
