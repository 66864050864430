import React from "react";
import i18n from "../../translations/i18n";
import { OrdersDocument, Setting } from "../../model/orders.types";
import { CompaniesDocument } from "../../model/companies.types";
import pdfUtils, { ExtendedPackaging } from "./pdfUtils";
import calculationUtils from "../calculationUtils";
import baseUtils from "../baseUtils";
import userService from "../../services/userService";
import { CustomOrder } from "../../components/order/CustomTypes";
import { DataContext } from "../../context/dataContext";
import { T_LIQUID, T_POWDER } from "../../components/order/OrderHelper";
import { UserdataDocument } from "../../model/userdata.types";

function createOffer(
  order: OrdersDocument | CustomOrder,
  settings: Setting,
  selectedCustomer: CompaniesDocument,
  bulk: boolean,
  draft: boolean,
  context: React.ContextType<typeof DataContext>,
  productSpecification?: boolean,
  nonBinding?: boolean,
  responsible?: UserdataDocument
) {
  const { packagings } = context;
  const todayString = baseUtils.formatDate(new Date());
  let validUntil = new Date();
  validUntil.setDate(validUntil.getDate() + 10);
  const validUntilString = baseUtils.formatDate(validUntil);
  const title = order.title;
  const calculations = order.calculations;
  const currentUser = userService.getUserData();

  let offernumber = "Unknown";
  if (draft) {
    offernumber = "TBD";
  } else if (order.identifier) {
    offernumber = order.identifier.toString();
  }
  const fullPackaging = calculations[0].packagings.map(pack => {
    return {
      supplier: pack.supplier.toString(),
      amount: pack.amount,
      ...packagings.find(p => p._id.toString() === pack._id.toString())
    };
  }) as Array<ExtendedPackaging>;

  let html =
    '<head><link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"><style>tr:nth-child(even) {background-color: white;}</style><meta http-equiv="content-type" content="text/html; charset=utf-8">';
  html += "</head>";
  html += '<body style="font-family: Helvetica ">';
  html +=
    '</img><hr><img src="https://private-label-factory.de/config/assets/img/PLF_logo_bw.png" width="200"/><br><table cellpadding="5" cellspacing="0" style="width: 100%; "<tr><td><br><br><span style="font-size:12px; float:left;"><u>Private Label Factory Deutschland GmbH, Philipp-Reis-Str. 7, 45659 Recklinghausen</u></span><br>';
  html += '<span style="font-size:16px; float:left;">';
  html += pdfUtils.getCompanyHTML(selectedCustomer);
  html +=
    `</span></td><td style="text-align: right; font-size:16px">${i18n.t("offer:offerNumber")} ` +
    offernumber +
    `<br>${i18n.t("offer:offerDate")}: ` +
    todayString;
  if (productSpecification || nonBinding) {
    html += "<br><br><br>";
  } else {
    html += `<br>${i18n.t("offer:validUntil")}: ` + validUntilString + "<br><br>";
  }
  html += pdfUtils.getResponsibleHTML(responsible ?? currentUser);
  if (productSpecification) {
    html +=
      `</td></tr><tr><td style="font-size:25px; font-weight: bold;"><br>${i18n.t("offer:productSpecificationNo")} ` +
      offernumber;
  } else if (nonBinding) {
    html +=
      `</td></tr><tr><td style="font-size:25px; font-weight: bold;"><br>${i18n.t("offer:nonBindingOfferNo")} ` +
      offernumber;
  } else {
    html +=
      `</td></tr><tr><td style="font-size:25px; font-weight: bold;"><br>${i18n.t("offer:offerNo")} ` + offernumber;
  }

  if (title) {
    html += '<br><span style="font-size:18px;">' + title + "</span>";
  }

  html += pdfUtils.getSettingsDescription(order, settings, context, bulk);
  html += "</td></tr></table></body>";

  if (order.subtitle) {
    html += `<br>`;
    html += '<table style="width:100%"><tr><td>';
    html += '<span style="font-size:14px; position: relative; top:0; left:0"><b>' + order.subtitle + "</b><br/></span>";
    html += "</td></tr></table>";
  }

  html += pdfUtils.getRecipeDescription(order, settings, context, undefined, bulk);

  if (settings.type !== "service" && !bulk) {
    html += `<br>`;
    html += '<table style="width:100%"><tr><td>';
    html += `<h4 style="margin-bottom:5px">${i18n.t("offer:packagingData")}</h4>`;
    html += pdfUtils.getOfferPackagingDescription(fullPackaging, i18n.language);
    html += "</td></tr></table>";
  }

  html += `<table style="width:100%"><tr><td style="width:50%; padding-right:20px;"><span style="font-size:15px;"><br><br><br><br>${i18n.t(
    "offer:acknowledgement"
  )}<br><br>${i18n.t(
    "offer:team"
  )}</span><br><img src="https://private-label-factory.com/images/certifications.jpg" style="width:180px;"></td><td>`;
  if (!productSpecification || nonBinding) {
    if (nonBinding) {
      html += `<br><h4 style="margin-bottom:5px">${i18n.t("offer:nonBindingOfferPrices")}</h4>`;
    } else {
      html += `<br><h4 style="margin-bottom:5px">${i18n.t("offer:offerPrices")}</h4>`;
    }

    html += `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa" ><tr style="background-color:#cccccc; width:100%"><td><b>${i18n.t(
      "offer:amount"
    )}</b></td><td><b style="white-space:nowrap;">`;
    if (bulk && ![T_POWDER, T_LIQUID].includes(settings.type)) {
      html += i18n.t("offer:pricePerK");
    } else if (bulk && [T_POWDER, T_LIQUID].includes(settings.type)) {
      html += i18n.t("offer:pricePerKg");
    } else {
      html += i18n.t("offer:pricePerUnit");
    }

    html += `</b></td><td><b>${i18n.t("offer:totalPrice")}</b></td></tr>`;
    html += "<tbody>";
    if (bulk && [T_POWDER, T_LIQUID].includes(settings.type)) {
      calculations.map(
        selectedCalculation =>
          (html +=
            "<tr><td>" +
            calculationUtils.formatAmount(
              pdfUtils.getCommodityWeight(selectedCalculation) * selectedCalculation.units,
              2
            ) +
            "</td><td><b>" +
            baseUtils.formatEuro(
              +selectedCalculation.info.unitprice /
                +calculationUtils.convertAmount(pdfUtils.getCommodityWeight(selectedCalculation).toString(), "mg", "kg")
            ) +
            "</b></td><td><b><u>" +
            baseUtils.formatEuro(+selectedCalculation.info.totalprice) +
            "</u></b></td></tr>")
      );
    } else {
      calculations.map(
        selectedCalculation =>
          (html +=
            "<tr><td>" +
            (selectedCalculation.units > 100000
              ? selectedCalculation.units / 1000 + i18n.t("offer:thousand")
              : selectedCalculation.units.toLocaleString("de-DE")) +
            "</td><td><b>" +
            baseUtils.formatEuro(+selectedCalculation.info.unitprice) +
            "</b></td><td><b><u>" +
            baseUtils.formatEuro(+selectedCalculation.info.totalprice) +
            "</u></b></td></tr>")
      );
    }
    html += `</table><p style="font-size: 15px;">${i18n.t("offer:netPrice7Vat")}</p>`;
    html += "</td></tr></table>";
  } else {
    html += "</td></tr></table>";
  }
  html += i18n.t("template:additionalText1");

  return html;
}

export default { createOffer };
