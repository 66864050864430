import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

const RestrictedInformation: React.FunctionComponent<RouteComponentProps> = ({ history }) => {
  return (
    <div className="kt-portlet kt-portlet--mobile" style={{ minHeight: "100%" }}>
      <div className="kt-portlet__body " style={{ minHeight: "100%", marginTop: "10rem" }}>
        <div className="align-self-center text-center">
          <div className="h5 text-muted">
            <i className="fa fa-info-circle mr-2 text-muted" />
            You cannot access this information.
          </div>
          <button onClick={history.goBack} className="btn btn-secondary mt-2">
            <span className="">Go Back</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(RestrictedInformation);
