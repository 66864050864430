import { toSVG } from "bwip-js/dist/bwip-js";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import dbService from "../services/dbService";
import i18n from "../translations/i18n";

export const MAX_PARTITION = 100; // the maximum of individual barcodes that will be generated at the same time

/**
 * Get a barcode as a string encoded svg
 * @param encodeString The string to encode as a barcode
 * @param altText The text below the barcode
 * @returns {string} The barcode as svg string
 */
export function getBarcodeSVG(encodeString: string, altText?: string): string {
  return toSVG({
    bcid: "code128",
    text: encodeString,
    height: 12,
    includetext: true, // Show human-readable text
    textxalign: "center",
    textsize: 12,
    alttext: altText ?? encodeString
  });
}

/**
 * Handling individual barcode creation in partitions
 * @param batchId The id of the batch
 * @param barcodesArray An array of information to generate the barcodes with
 * @param maxPartition The max partition that should be given to the backend
 * @param commodityTitle The commodity title for the barcode text
 * @param results The array of already given results
 * @returns {Promise<void>} The indication if the database upload worked
 */
export async function barcodePartitionalCalls(
  batchId: BSON.ObjectId,
  barcodesArray: Array<{ barcodeId: BSON.ObjectId; svg: string; packageNo: number }>,
  maxPartition: number,
  commodityTitle: string,
  results?: Array<boolean>
): Promise<void> {
  let remaining = barcodesArray;
  let resultsArray = results ? results : [];
  if (remaining.length > maxPartition) {
    resultsArray = await uploadBarcodePartition(barcodesArray, maxPartition, commodityTitle, batchId, resultsArray);
    remaining = remaining.slice(maxPartition);
    await barcodePartitionalCalls(batchId, remaining, maxPartition, commodityTitle, resultsArray);
  } else {
    resultsArray = await uploadBarcodePartition(barcodesArray, remaining.length, commodityTitle, batchId, resultsArray);
    if (resultsArray.every(r => r === true)) toast.success(i18n.t("warehouse:pUBarcodeGenerationSuccess"));
    else toast.error(i18n.t("warehouse:pUBarcodeGenerationFailure"));
  }
}

/**
 * Upload the individual barcodes and return the array of given results
 * @param barcodesArray An array of information to generate the barcodes with
 * @param maxPartition The max partition that should be given to the backend
 * @param commodityTitle The commodity title for the barcode text
 * @param batchId The id of the batch
 * @param resultsArray The array of already given results
 * @returns {Array<boolean>} The given results if uploads were successful
 */
async function uploadBarcodePartition(
  barcodesArray: Array<{ barcodeId: BSON.ObjectId; svg: string; packageNo: number }>,
  maxPartition: number,
  commodityTitle: string,
  batchId: BSON.ObjectId,
  resultsArray: Array<boolean>
) {
  for (let i = 0; i < maxPartition; i++) {
    const svg = getBarcodeSVG(
      barcodesArray[i].svg,
      `${commodityTitle.replaceAll(/\s/g, "").substring(0, 10)} - ${barcodesArray[i].svg}`
    );
    // exchange string to be encoded with actual svg
    barcodesArray[i].svg = svg;
  }
  const res = await dbService.callFunction<boolean>(
    "generateBarcode",
    [batchId, undefined, undefined, barcodesArray],
    true
  );
  resultsArray.push(res);
  return resultsArray;
}
