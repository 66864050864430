import React, { PureComponent } from "react";
import { Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BSON } from "realm-web";
import CompanyWidget from "../../common/CompanyWidget";
import PersonWidget from "../../common/PersonWidget";
import { DataContext } from "../../../context/dataContext";
import { OrdersDocument } from "../../../model/orders.types";
import dbService, { Action, ORDERS, PACKAGINGORDERS, PACKAGINGS } from "../../../services/dbService";
import baseUtils from "../../../utils/baseUtils";
import toastUtils from "../../../utils/toastUtils";
import { PackagingsDocument } from "../../../model/packagings.types";
import { PackagingOrderDocument } from "../../../model/packagingOrders.types";
import packagingUtils from "../../../utils/packagingUtils";
import { T_PACKAGINGORDERCANCELED } from "../../../utils/timelineUtils";
import userService from "../../../services/userService";

interface CancelPackagingOrderModalProps {
  packaging: PackagingsDocument;
  packagingOrder: PackagingOrderDocument;
  context: React.ContextType<typeof DataContext>;
  show: boolean;
  onHide: (show: boolean) => void;
}

interface CancelPackagingOrderModalState {
  saving: boolean;
}

class CancelPackagingOrderModal extends PureComponent<CancelPackagingOrderModalProps, CancelPackagingOrderModalState> {
  constructor(props: CancelPackagingOrderModalProps) {
    super(props);
    this.state = { saving: false };
  }

  /**
   * Handle canceling a packaging order.
   */
  handleCancelOrder = async () => {
    const { packaging, packagingOrder, context, onHide } = this.props;
    this.setState({ saving: true });
    let success;
    // If there are orders relating to the packaging order we need to update them too
    if (packagingOrder.relatedOrders.length > 0) {
      let actions: Array<Action> = [];
      const timeline = {
        id: new BSON.ObjectId(),
        type: T_PACKAGINGORDERCANCELED,
        date: new Date(),
        amount: packagingOrder.orderQuantity,
        packaging: packaging._id,
        supplier: packagingOrder.supplier,
        person: userService.getUserId(),
        price: packagingOrder.totalPrice
      };
      // Update orders
      for (let i = 0; i < packagingOrder.relatedOrders.length; i++) {
        const order = baseUtils.getDocFromCollection(context.orders, packagingOrder.relatedOrders[i].toString());
        if (order) {
          actions.push({
            collection: ORDERS,
            filter: { _id: order._id },
            update: {
              "calculations.0.packagings.$[c].ordered": null,
              "calculations.0.packagings.$[c].userOrdered": null,
              "calculations.0.packagings.$[c].eta": null
            },
            push: { timeline },
            arrayFilters: [{ "c._id": packaging._id }]
          });
        }
      }
      success = await dbService.callFunction("deletePackagingOrder", [packagingOrder, actions]);
    } else {
      const res = await dbService.deleteDocument(PACKAGINGORDERS, packagingOrder._id);
      success = !!res && res.deletedCount > 0;
    }
    await toastUtils.databaseOperationToast(
      success,
      "Packaging order successfully cancelled",
      "Error canceling packaging order",
      () => {
        context.updateDocumentInContext(PACKAGINGS, packaging._id);
        context.updateDocumentInContext(PACKAGINGORDERS, packagingOrder._id);
        packagingOrder.relatedOrders.forEach(o => context.updateDocumentInContext(ORDERS, o));
      }
    );
    this.setState({ saving: false });
    if (success) onHide(false);
  };

  render() {
    const { packaging, packagingOrder, context, show, onHide } = this.props;
    const { saving } = this.state;
    const orders: Array<OrdersDocument> = [];
    packagingOrder.relatedOrders.forEach(o => {
      const order = baseUtils.getDocFromCollection(context.orders, o);
      if (order) orders.push(order);
    });
    return (
      <Modal show={show} onHide={() => onHide(true)} centered size={orders.length > 0 ? "lg" : undefined}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel order for {packagingUtils.getShortPackagingInfo(packaging)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-warning my-2" role="alert">
            <div className="alert-icon">
              <i className="flaticon-warning" />
            </div>
            <div className="alert-text">
              Do you really want to cancel this order of {packagingOrder.orderQuantity} pcs. for{" "}
              {packagingUtils.resolvePackagingProperties(packaging)}?
              <br />
              <span className="font-weight-bolder">This can not be undone!</span>
              {orders.length > 0 && (
                <>
                  <br />
                  <div>The following orders will be updated:</div>
                </>
              )}
            </div>
          </div>
          {orders.length > 0 && (
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr className="table-hover">
                    <th style={{ width: "30%" }}>Order</th>
                    <th style={{ width: "35%" }}>Customer</th>
                    <th style={{ width: "35%" }}>Owner</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map(o => {
                    const customer = baseUtils.getDocFromCollection(context.companies, o.createdFor);
                    const owner = baseUtils.getDocFromCollection(context.userdata, o.createdFrom);
                    return (
                      <tr key={o._id.toString()}>
                        <td className="align-middle">
                          <Link to={"/order/" + o._id.toString()} className="h6 kt-link kt-font-dark">
                            AT-{o.identifier}
                          </Link>
                          <div className="text-muted">{o.title}</div>
                        </td>
                        <td className="align-middle">
                          {customer ? <CompanyWidget company={customer} type="company" /> : "Unknown"}
                        </td>
                        <td className="align-middle">{owner ? <PersonWidget person={owner} /> : "Unknown"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => onHide(true)}>
            Close
          </button>
          <button
            className={"btn btn-secondary" + (saving ? " disabled" : "")}
            disabled={saving}
            onClick={this.handleCancelOrder}
          >
            Cancel Order
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CancelPackagingOrderModal;
