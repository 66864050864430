import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import i18n from "../../translations/i18n";
import { WarehouseProvider } from "../../context/warehouseContext";
import PackagingUnitsSettings from "./warehouseSettings/PackagingUnitsSettings";
import StorageSpacesSettings from "./warehouseSettings/StorageSpacesSettings";
import FileTypeSettings from "./warehouseSettings/FileTypeSettings";

interface WarehouseSettingsProps extends RouteComponentProps {}

const WarehouseSettings: React.FunctionComponent<WarehouseSettingsProps> = ({ history, match, location }) => {
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    i18n.changeLanguage("de").then(() => setReady(true));
  }, []);

  const tabDef = [
    {
      name: "PackagingUnitsConfig",
      tabHeader: i18n.t("warehouse:packagingUnitConfiguration"),
      tableContent: <PackagingUnitsSettings history={history} match={match} location={location} />,
      size: 33
    },
    {
      name: "StorageSpaces",
      tabHeader: i18n.t("warehouse:storageSpaces"),
      tableContent: <StorageSpacesSettings history={history} match={match} location={location} />,
      size: 33
    },
    {
      name: "FileTypes",
      tabHeader: i18n.t("warehouse:fileTypes"),
      tableContent: <FileTypeSettings history={history} match={match} location={location} />,
      size: 33
    }
  ];
  const [currentTab, setCurrentTab] = useState(tabDef[0]);

  const handleChangeCurrentTab = (e: string) => {
    const newTab = tabDef.find(td => td.name === e);
    if (newTab) setCurrentTab(newTab);
  };

  if (!ready) return null;
  return (
    <WarehouseProvider>
      <div className="container-fluid">
        <div className="mb-2 bg-light" style={{ borderBottom: "1px solid #ebedf2" }}>
          <div className="btn-group btn-group-md w-100">
            {tabDef.map(tab => {
              return (
                <button
                  key={tab.name}
                  className={"btn btn-outline-secondary " + (currentTab.name === tab.name && "active")}
                  style={{ width: `${tab.size}%` }}
                  onClick={() => handleChangeCurrentTab(tab.name)}
                >
                  {tab.tabHeader}
                </button>
              );
            })}
          </div>
        </div>
        <div>{currentTab.tableContent}</div>
      </div>
    </WarehouseProvider>
  );
};

export default WarehouseSettings;
