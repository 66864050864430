import _ from "lodash";
import React, { useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CommoditiesDocument, CommodityOrder, StockTransferOrder } from "../../../model/commodities.types";
import dbService, { UpdateAction, COMMODITIES } from "../../../services/dbService";
import commodityUtils from "../../../utils/commodityUtils";
import { DataContext } from "../../../context/dataContext";
import stoUtils from "../../../utils/stoUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import userService from "../../../services/userService";

interface TransferCommodityModalProps {
  commodity: CommoditiesDocument;
  commodityOrder: CommodityOrder;
  stockTransferOrders: Array<StockTransferOrder>;
  context: React.ContextType<typeof DataContext>;
}

interface TransferCommodityModalState {
  transferAmounts: Array<number>;
  transferSelections: Array<boolean>;
  transferSelectionsEnabled: Array<boolean>;
  availableTransferAmount: number;
  predictedRemainingStock: number;
  errors: Array<string>;
  show: boolean;
  saving: boolean;
}

enum TransferCommodityActionType {
  SHOW,
  RELOAD,
  CLOSE,
  SAVING,
  FINISH_SAVING,
  SELECTION_CHANGED,
  TRANSFER_AMOUNT_CHANGED
}

type TransferCommodityAction =
  | {
      type: TransferCommodityActionType.SHOW;
    }
  | {
      type: TransferCommodityActionType.RELOAD;
      payload: {
        stockTransferOrders: Array<StockTransferOrder>;
        commodityOrder: CommodityOrder;
      };
    }
  | {
      type: TransferCommodityActionType.CLOSE;
    }
  | {
      type: TransferCommodityActionType.SAVING;
    }
  | {
      type: TransferCommodityActionType.FINISH_SAVING;
    }
  | {
      type: TransferCommodityActionType.SELECTION_CHANGED;
      payload: {
        transferSelections: Array<boolean>;
      };
    }
  | {
      type: TransferCommodityActionType.TRANSFER_AMOUNT_CHANGED;
      payload: {
        transferAmounts: Array<number>;
        predictedRemainingStock: number;
        errors: Array<string>;
      };
    };

/**
 * Function to calculate the overall transfer amount typed in by the user
 * @param onTransfer the actual input of the 1 - N input fields
 * @returns {number} the amount that is reserved for transfer
 */
const calculateTransferAmount = (onTransfer: Array<number>) => {
  return onTransfer.reduce((overallTransferAmount, transferAmount) => {
    return overallTransferAmount + transferAmount;
  }, 0);
};

/**
 * Method to receive the initial/default state of the following component
 * @param stockTransferOrders the corresponding stock transfer orders
 * @returns {TransferCommodityModalState} the initial/default state of the component
 */
const getDefaultState = (
  stockTransferOrders: Array<StockTransferOrder>,
  commodityOrder: CommodityOrder
): TransferCommodityModalState => {
  // collecting the corresponding transfer amounts
  const transferAmounts = stockTransferOrders.map(sto => {
    return sto.amount;
  });

  // checking if there are already corresponding transfer processes, if true -> disable transfer for this specific transfer
  const transferSelection = Array<boolean>(stockTransferOrders.length).fill(false);
  const transferSelectionsEnabled = stockTransferOrders.map(sto => {
    return sto.created === undefined;
  });

  const reservedForTransport = transferAmounts.reduce((accTrAmount, trAmount) => {
    return accTrAmount + trAmount;
  }, 0);

  return {
    availableTransferAmount: commodityOrder.orderquantity,
    transferSelections: transferSelection,
    transferSelectionsEnabled: transferSelectionsEnabled,
    predictedRemainingStock: commodityOrder.orderquantity - reservedForTransport,
    transferAmounts: transferAmounts,
    errors: [],
    show: false,
    saving: false
  };
};

const reducer = (state: TransferCommodityModalState, action: TransferCommodityAction) => {
  switch (action.type) {
    case TransferCommodityActionType.SHOW:
      return {
        ...state,
        show: true
      };
    case TransferCommodityActionType.RELOAD:
      const defaultState = getDefaultState(action.payload.stockTransferOrders, action.payload.commodityOrder);
      return {
        ...defaultState
      };
    case TransferCommodityActionType.CLOSE:
      return {
        ...state,
        show: false
      };
    case TransferCommodityActionType.SAVING:
      return {
        ...state,
        saving: true
      };
    case TransferCommodityActionType.FINISH_SAVING:
      return {
        ...state,
        saving: false
      };
    case TransferCommodityActionType.SELECTION_CHANGED:
      return {
        ...state,
        transferSelections: action.payload.transferSelections
      };
    case TransferCommodityActionType.TRANSFER_AMOUNT_CHANGED:
      return {
        ...state,
        transferAmounts: action.payload.transferAmounts,
        predictedRemainingStock: action.payload.predictedRemainingStock,
        errors: action.payload.errors
      };
    default:
      return state;
  }
};

const headerDefinition = [
  { title: "Select", size: 10 },
  { title: "Order(s)", size: 15 },
  { title: "Destination", size: 25 },
  { title: "Transfer amount", size: 30 },
  { title: "Transfer state", size: 20, additionalClass: "text-center" }
];

const TransferCommodityModal: React.FunctionComponent<TransferCommodityModalProps> = ({
  commodity,
  commodityOrder,
  stockTransferOrders,
  context
}) => {
  // hook definitions
  const [modalState, dispatch] = useReducer(reducer, getDefaultState(stockTransferOrders, commodityOrder));
  const [firstRender, setFirstRender] = useState<boolean>(true);

  const {
    availableTransferAmount,
    show,
    predictedRemainingStock,
    transferSelectionsEnabled,
    transferSelections,
    transferAmounts,
    errors,
    saving
  } = modalState;

  useEffect(() => {
    if (!firstRender) {
      dispatch({
        type: TransferCommodityActionType.RELOAD,
        payload: { stockTransferOrders: stockTransferOrders, commodityOrder: commodityOrder }
      });
    } else {
      setFirstRender(false);
    }
  }, [stockTransferOrders]);

  const handleShow = () => {
    dispatch({ type: TransferCommodityActionType.SHOW });
  };

  const handleClose = () => {
    dispatch({ type: TransferCommodityActionType.CLOSE });
  };

  const handleSelectionChange = (index: number) => {
    const transferSelectionsCopy = _.cloneDeep(transferSelections);
    transferSelectionsCopy[index!] = !transferSelectionsCopy[index!];

    dispatch({
      type: TransferCommodityActionType.SELECTION_CHANGED,
      payload: { transferSelections: transferSelectionsCopy }
    });
  };

  /**
   * Handle input changes
   * @param e change event
   * @param index {number} index of the corresponding input field
   */
  const handleTransformAmountChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (e.target.value) {
      const transferAmountsCopy = _.cloneDeep(transferAmounts);

      // updating corresponding input an information values
      transferAmountsCopy[index] = Number(e.target.value);

      // calculate the predicted remaining stock
      const predictedRemainingStock = availableTransferAmount - calculateTransferAmount(transferAmountsCopy);

      // checking for errors
      const errors = [];
      if (predictedRemainingStock < 0) {
        errors.push("The transfer amount is greater than the available warehouse amount");
      }

      if (transferAmountsCopy.some(transferAmount => transferAmount <= 0)) {
        errors.push("Production transfer cannot be less or equals 0");
      }

      dispatch({
        type: TransferCommodityActionType.TRANSFER_AMOUNT_CHANGED,
        payload: {
          transferAmounts: transferAmountsCopy,
          predictedRemainingStock: predictedRemainingStock,
          errors: errors
        }
      });
    }
  };

  /**
   * Adjust sto to represent the production transport
   */
  const handleTransferCommodity = async () => {
    // activate update indicator
    dispatch({ type: TransferCommodityActionType.SAVING });

    // collect corresponding actions for updating stock transfer orders
    const actions: Array<UpdateAction> = [];
    stockTransferOrders.forEach((stockTransferOrder, index) => {
      // check if row is enabled and selected
      if (transferSelectionsEnabled[index] && transferSelections[index]) {
        // create update object
        const stockTransferCopy = _.cloneDeep(stockTransferOrder);
        stockTransferCopy.amount = transferAmounts[index];
        stockTransferCopy.created = new Date(); // start date of the production transfer
        stockTransferCopy.personCreated = userService.getUserId();

        const commodityOrderEdited = _.cloneDeep(commodityOrder);
        const correspondingSTOIndex = commodityOrder.stockTransferOrders!.findIndex(
          sto => sto._id.toString() === stockTransferOrder._id.toString()
        );
        commodityOrderEdited.stockTransferOrders![correspondingSTOIndex] = stockTransferCopy;

        // creating timeline Entry
        const timelineEntry = commodityUtils.prepareCommodityOrderTimeline(commodityOrder, commodityOrderEdited);

        // creating actions to update the commodity object
        actions.push({
          collection: COMMODITIES,
          filter: { _id: commodity._id },
          update: { "orders.$[c].stockTransferOrders.$[r]": stockTransferCopy },
          push: {
            timeline: timelineEntry
          },
          arrayFilters: [{ "c._id": commodityOrder._id }, { "r._id": stockTransferOrder._id }]
        });
      }
    });

    // updating database entry
    let res;
    try {
      res = await dbService.updatesAsTransaction(actions);
      if (res) {
        toast.success("Order state successfully updated");
      } else {
        toast.error("Order state could not be updated");
      }
    } catch (e) {
      toast.error("An error occurred\nReason: " + e);
    } finally {
      dispatch({ type: TransferCommodityActionType.FINISH_SAVING });
      if (res) {
        dispatch({ type: TransferCommodityActionType.CLOSE });
      }
    }
  };

  return (
    <>
      <button className="btn btn-success" onClick={handleShow}>
        Transfer
      </button>
      {show && (
        <Modal show={show} onHide={handleClose} size={"lg"} centered name={"transferCommodityModal"}>
          <Modal.Header>
            <Modal.Title>
              <i className="fa fa-truck" /> Transfer to production (further available for Transport:{" "}
              {commodityUtils.resolveStockUnit(predictedRemainingStock, commodity.type)})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
              <table className="kt-datatable__table d-table">
                <thead className="kt-datatable__head text-left" style={{ display: "table-header-group" }}>
                  <tr className="kt-datatable__row d-table-row">
                    {headerDefinition.map(def => (
                      <th
                        key={def.title + def.size}
                        className={"kt-datatable__cell d-table-cell " + def.additionalClass}
                        style={{ width: `${def.size}%` }}
                      >
                        <span>{def.title}</span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="kt-datatable__body" style={{ display: "table-row-group", color: "black" }}>
                  {stockTransferOrders.map((sto, index) => {
                    const { destination, extendedOrders } = stoUtils.resolveDestinationAndOrders(
                      sto,
                      context.manufacturers,
                      context.orders
                    );
                    return (
                      <tr
                        key={sto._id.toString()}
                        className="kt-datatable__row d-table-row nopadding"
                        style={!transferSelectionsEnabled[index] ? { opacity: 0.4 } : {}}
                      >
                        <td className="kt-datatable__cell d-table-cell">
                          <input
                            type="checkbox"
                            className="prettyCheckbox"
                            disabled={!transferSelectionsEnabled[index]}
                            checked={transferSelections[index]}
                            onChange={() => handleSelectionChange(index)}
                          />
                        </td>
                        <td className="kt-datatable__cell d-table-cell">
                          {extendedOrders.map((order, index) => {
                            return (
                              "AT-" + (index === extendedOrders.length - 1 ? order.identifier : order.identifier + ", ")
                            );
                          })}
                        </td>
                        <td className="kt-datatable__cell d-table-cell">{destination.name}</td>
                        <td className="kt-datatable__cell d-table-cell">
                          <div className="input-group">
                            <input
                              type="number"
                              disabled={!transferSelectionsEnabled[index]}
                              className={
                                "form-control " +
                                (predictedRemainingStock < 0 || transferAmounts[index] <= 0 ? "is-invalid" : "")
                              }
                              value={transferAmounts[index]}
                              onChange={e => handleTransformAmountChange(e, index)}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">{!commodity.type ? "kg" : "tsd"}</span>
                            </div>
                          </div>
                        </td>
                        <td className="kt-datatable__cell d-table-cell text-center">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={"commodity-state"}>
                                <span>
                                  <b>
                                    {sto.delivered
                                      ? "Commodity has been delivered"
                                      : sto.created
                                      ? "Commodity is on the way to production"
                                      : "Commodity is at warehouse"}
                                  </b>
                                </span>
                                <br />
                              </Tooltip>
                            }
                            placement={"top"}
                          >
                            <i
                              className={
                                sto.delivered ? "fa fa-check" : sto.created ? "fa fa-truck" : "fa fa-warehouse"
                              }
                            />
                          </OverlayTrigger>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="btn-group">
              <button className="btn btn-secondary mr-2" onClick={handleClose}>
                Cancel
              </button>
              <ErrorOverlayButton
                errors={errors}
                disabled={transferSelections.every(selected => !selected)}
                className={"btn btn-success"}
                buttonText={"Confirm"}
                saving={saving}
                onClick={handleTransferCommodity}
              >
                {saving && (
                  <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                    <svg className="button-splash-spinner" viewBox="0 0 50 50">
                      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                    </svg>
                  </div>
                )}
              </ErrorOverlayButton>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default TransferCommodityModal;
