import React from "react";
import WarehouseHierarchy from "./selectionPanel/WarehouseHierarchy";
import ProductFilter from "./selectionPanel/ProductFilter";
import i18n from "../../../translations/i18n";

const WarehouseSelectionPanel: React.FC = () => {
  return (
    <div className="kt-portlet">
      <div className="kt-portlet__body p-4 ">
        <WarehouseHierarchy allowSelection={true} />
        <ProductFilter />
        <div className="navi navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon pt-7 not-allowed">
          <h5 className=" m-2 font-weight-bold text-black">{i18n.t("warehouse:status")}</h5>
          <hr className="w-100" />
          <div className="navi-item my-0 ">
            <span className="navi-link">
              <span className="navi-icon mr-4">
                <i className="fa fa-genderless text-light-grey" />
              </span>
              <span className="navi-text font-weight-bolder">{i18n.t("warehouse:inInflow")}</span>
              <span className="navi-label">
                <span className="label label-box label-rounded label-light-grey font-weight-bolder">-</span>
              </span>
            </span>
          </div>
          <div className="navi-item my-0">
            <span className="navi-link">
              <span className="navi-icon mr-4">
                <i className="fa fa-genderless text-danger" />
              </span>
              <span className="navi-text font-weight-bolder">{i18n.t("warehouse:inIncoming")}</span>
              <span className="navi-label">
                <span className="label label-box label-rounded label-light-grey font-weight-bolder">-</span>
              </span>
            </span>
          </div>
          <div className="navi-item my-0">
            <span className="navi-link">
              <span className="navi-icon mr-4">
                <i className="fa fa-genderless text-success" />
              </span>
              <span className="navi-text font-weight-bolder">{i18n.t("warehouse:inBookedIn")}</span>
              <span className="navi-label">
                <span className="label label-box label-rounded label-light-grey font-weight-bolder">-</span>
              </span>
            </span>
          </div>
          <div className="navi-item my-0">
            <span className="navi-link">
              <span className="navi-icon mr-4">
                <i className="fa fa-genderless text-warning" />
              </span>
              <span className="navi-text font-weight-bolder">{i18n.t("warehouse:inOutgoing")}</span>
              <span className="navi-label">
                <span className="label label-box label-rounded label-light-grey font-weight-bolder">-</span>
              </span>
            </span>
          </div>
          <div className="navi-item my-0">
            <span className="navi-link">
              <span className="navi-icon mr-4">
                <i className="fa fa-genderless text-info" />
              </span>
              <span className="navi-text font-weight-bolder">{i18n.t("warehouse:inRelocation")}</span>
              <span className="navi-label">
                <span className="label label-box label-rounded label-light-grey font-weight-bolder">-</span>
              </span>
            </span>
          </div>
        </div>
        <div className="navi navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon pt-7 not-allowed">
          <h5 className=" m-2 font-weight-bold text-black">{i18n.t("warehouse:kiAdvisor")}</h5>
          <hr className="w-100" />
          <div className="navi-item my-0">
            <span className="navi-link">
              <span className="navi-icon mr-4">
                <i className="fa fa-magic text-light-grey font-size-xs" />
              </span>
              <span className="navi-text font-weight-bolder">{i18n.t("warehouse:provisioningPossible")}</span>
            </span>
          </div>
          <div className="navi-item my-0">
            <span className="navi-link">
              <span className="navi-icon mr-4">
                <i className="fa fa-magic text-light-grey font-size-xs" />
              </span>
              <span className="navi-text font-weight-bolder">{i18n.t("warehouse:swapAllocation")}</span>
            </span>
          </div>
          <div className="navi-item my-0">
            <span className="navi-link">
              <span className="navi-icon mr-4">
                <i className="fa fa-magic text-light-grey font-size-xs" />
              </span>
              <span className="navi-text font-weight-bolder">{i18n.t("warehouse:outsourceToExternal")}</span>
            </span>
          </div>
          <div className="navi-item my-0">
            <span className="navi-link">
              <span className="navi-icon mr-4">
                <i className="fa fa-magic text-light-grey font-size-xs" />
              </span>
              <span className="navi-text font-weight-bolder">{i18n.t("warehouse:internalRelocation")}</span>
            </span>
          </div>
          <div className="navi-item my-0">
            <span className="navi-link">
              <span className="navi-icon mr-4">
                <i className="fa fa-magic text-light-grey font-size-xs" />
              </span>
              <span className="navi-text font-weight-bolder">{i18n.t("warehouse:retrofittingStorage")}</span>
            </span>
          </div>
          <div className="navi-item my-0">
            <span className="navi-link">
              <span className="navi-icon mr-4">
                <i className="fa fa-magic text-light-grey font-size-xs" />
              </span>
              <span className="navi-text font-weight-bolder">{i18n.t("warehouse:shortShelfLife")}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarehouseSelectionPanel;
