import _ from "lodash";
import React, { PureComponent } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { BSON } from "realm-web";
import { LocalStock } from "./CustomTypes";
import EditBatchModal from "./modals/EditBatchModal";
import { DataContext } from "../../context/dataContext";
import { CommodityBatch, CommoditiesDocument } from "../../model/commodities.types";
import commodityUtils from "../../utils/commodityUtils";
import CommodityLocalStockRow from "./CommodityLocalStockRow";
import CommoditiesStockStatistics from "../stock/Commodities/CommoditiesStockStatistics";

interface CommodityLocalStockProps {
  localStock: LocalStock;
  commodity: CommoditiesDocument;
  onStockUpdate?: (stock: Array<CommodityBatch>, saveChanges: boolean) => void;
  context: React.ContextType<typeof DataContext>;
  readOnly?: true;
  showDisabledStock: boolean;
  localDisabledStock: LocalStock | undefined;
  disableStatistics?: true;
}

interface CommodityLocalStockState {
  stock: number;
  ordered: number;
  reserved: number;
  available: number;
}

class CommodityLocalStock extends PureComponent<CommodityLocalStockProps, CommodityLocalStockState> {
  constructor(props: CommodityLocalStockProps) {
    super(props);
    this.state = { stock: 0, ordered: 0, reserved: 0, available: 0 };
  }

  componentDidMount() {
    this.calculateStockValues();
  }

  componentDidUpdate(
    prevProps: Readonly<CommodityLocalStockProps>,
    prevState: Readonly<CommodityLocalStockState>,
    snapshot?: any
  ) {
    if (prevProps !== this.props) this.calculateStockValues();
  }

  /**
   * Handles updating a batch.
   * @param batch: Batch after the update
   * @param create: Indicates whether the batch is created or updated
   */
  handleUpdateBatch = (batch: CommodityBatch, create: boolean) => {
    const { onStockUpdate } = this.props;
    if (onStockUpdate) {
      const commodity = _.cloneDeep(this.props.commodity);
      if (create) {
        commodity.stock.push(batch);
      } else {
        for (let i = 0; i < commodity.stock.length; i++) {
          const stock = commodity.stock[i];
          if (stock._id.toString() === batch._id.toString()) {
            commodity.stock[i] = batch;
            break;
          }
        }
      }
      onStockUpdate(commodity.stock, true);
    }
  };

  /**
   * Handles deleting a batch.
   * @param _id: ID of the batch
   */
  handleDisableBatch = (_id: BSON.ObjectId | string) => {
    const { onStockUpdate } = this.props;
    if (onStockUpdate) {
      const commodity = _.cloneDeep(this.props.commodity);
      for (let i = 0; i < commodity.stock.length; i++) {
        const stock = commodity.stock[i];
        if (stock._id.toString() === _id.toString()) {
          stock.amount = 0;
          stock.disabled = !stock.disabled;
          break;
        }
      }
      onStockUpdate(commodity.stock, false);
    }
  };

  /**
   * Calculates the currently stocked amounts of the commodity.
   */
  calculateStockValues = () => {
    const { commodity, context, localStock } = this.props;
    this.setState({ ...commodityUtils.calculateStockValues(commodity, context, localStock) });
  };

  /**
   * Resolves the supplier referenced by the given ID or key.
   * @param supplier: ID of the supplier or key for other things like own stock
   * @returns { SuppliersDocument | string } The supplier object if available or display name
   */
  resolveSupplier = (supplier: BSON.ObjectId | "accumulatedstock" | "custom" | "customer" | "ownstock") => {
    const { context } = this.props;
    if (supplier === "accumulatedstock") return "Accumulated Stock";
    if (supplier === "custom") return "Custom";
    if (supplier === "customer") return "Customer";
    if (supplier === "ownstock") return "Stock";
    const sup = context.suppliers.find(s => s._id.toString() === supplier.toString());
    if (sup) return sup;
    return "Unknown";
  };

  render() {
    const { commodity, context, localStock, showDisabledStock, localDisabledStock, readOnly, disableStatistics } =
      this.props;
    const { stock, ordered, reserved, available } = this.state;
    const stockList = _.cloneDeep(localStock);
    return (
      <>
        {!disableStatistics && (
          <CommoditiesStockStatistics
            stock={stock}
            ordered={ordered}
            reserved={reserved}
            available={available}
            commodityType={commodity.type}
            outerClassName={"row mb-5"}
            innerClassName={"col-12 col-sm-6 col-md-3"}
          />
        )}
        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
          <Table>
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Amount</th>
                <th style={{ width: "20%" }}>Supplier</th>
                <th style={{ width: "10%" }}>Batch</th>
                <th style={{ width: "8%" }}>Price</th>
                <th style={{ width: "10%" }}>Since</th>
                <th style={{ width: "10%" }}>Expiry</th>
                <th style={{ width: "18%" }}>
                  Allocated
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="allocatedInfo">
                        This value indicates the amount that was already allocated for <br />
                        production via the "Reserve Commodities" dialog on the order <br />
                        page, i.e. the amount is not yet <b>booked out</b> but also may not be available.
                      </Tooltip>
                    }
                  >
                    <i className="fa fa-info-circle ml-2 text-muted" />
                  </OverlayTrigger>
                </th>
                <th style={{ width: "7%" }}>Files</th>
                {!readOnly && <th style={{ width: "7%" }} />}
              </tr>
            </thead>
            <tbody>
              {showDisabledStock &&
                localDisabledStock &&
                localDisabledStock.localStock.length > 0 &&
                localDisabledStock.localStock.map(b => {
                  return (
                    <CommodityLocalStockRow
                      key={b._id.toString()}
                      context={context}
                      batch={b}
                      localStock={localDisabledStock}
                      commodity={commodity}
                      supplier={this.resolveSupplier(b.supplier)}
                      onBatchUpdate={this.handleUpdateBatch}
                      onBatchDisable={this.handleDisableBatch}
                      readOnly={readOnly}
                    />
                  );
                })}
              {stockList.localStock.length > 0
                ? stockList.localStock.map(b => {
                    return (
                      <CommodityLocalStockRow
                        key={b._id.toString()}
                        context={context}
                        batch={b}
                        localStock={localStock}
                        commodity={commodity}
                        supplier={this.resolveSupplier(b.supplier)}
                        onBatchUpdate={this.handleUpdateBatch}
                        onBatchDisable={this.handleDisableBatch}
                        readOnly={readOnly}
                      />
                    );
                  })
                : !readOnly && (
                    <tr>
                      <td colSpan={9}>
                        <img
                          src={process.env.PUBLIC_URL + "/media/img/no_results.jpg"}
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: "500px"
                          }}
                          alt="No results"
                        />
                      </td>
                    </tr>
                  )}
            </tbody>
          </Table>
        </div>
        {!readOnly && (
          <div className="text-right mb-3 mr-2">
            <EditBatchModal
              commodity={commodity}
              location={localStock.manufacturer._id}
              onBatchUpdate={this.handleUpdateBatch}
              context={context}
              create={true}
            />
          </div>
        )}
      </>
    );
  }
}

export default CommodityLocalStock;
