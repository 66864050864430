import _ from "lodash";
import React, { PureComponent } from "react";
import Select from "react-select";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../../context/dataContext";
import { PaginationState } from "../../common/CustomTypes";
import { Invoice, OrdersDocument } from "../../../model/orders.types";
import orderUtils, { DECLINED } from "../../../utils/orderUtils";
import HistoryBackButton from "../../listings/common/HistoryBackButton";
import { DunningLevelFilter, OwnerFilter, SearchBar } from "../../listings/common/Filters";
import SplashScreen from "../../common/SplashScreen";
import BaseListing from "../../listings/BaseListing";
import { paginate } from "../../common/Pagination";
import invoiceUtils from "../../../utils/invoiceUtils";
import baseUtils, { getComponentState } from "../../../utils/baseUtils";
import DunningDetailsRow from "./DunningDetailsRow";
import Stat from "../../common/Stat";
import { CompaniesDocument } from "../../../model/companies.types";
import { UserdataDocument } from "../../../model/userdata.types";
import DunningConfirmationModal from "./DunningConfirmationModal";
import { I_INTEREST } from "../../../services/dbServices/dbOrderService";

interface DunningListingProps extends RouteComponentProps {
  context: React.ContextType<typeof DataContext>;
}

export interface DunningObject {
  isSelected: boolean;
  correspondingAccountingMail: string | undefined;
  hasAccountingMailChanged: boolean;
  pdfLanguage: { value: string; label: string };
  reminderOption: { value: number; label: string };
  owner: string;
  company: { id: string; name: string } | undefined;
  order: OrdersDocument;
  invoice: Invoice;
}

/**
 * Possible dunning row changes
 */
export enum dunningChangeType {
  LANGUAGE,
  DUNNING_LEVEL,
  ACCOUNTING_MAIL
}

interface DunningListingState extends PaginationState {
  search: string;
  owner: "" | { value: string; label: string };
  sort: { value: string; label: string };
  reminder: { value: string; label: string };
  sortOrder: { value: "asc"; label: "Ascending" } | { value: "desc"; label: "Descending" };
  hideCancellation: boolean;
  relevantOrders: Array<OrdersDocument>;
  filteredInvoices: Array<DunningObject>;
  selectedInvoices: Array<DunningObject>;
  stats?: { noReminder: number; firstReminder: number; firstDunning: number; multipleDunning: number; total: number };
  previewPDFLanguage: { value: string; label: string };
  isScrollButtonActive: boolean;
}

const CONSTRUCTORNAME = "DunningListing";

class DunningListing extends PureComponent<DunningListingProps, DunningListingState> {
  constructor(props: DunningListingProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const relevantOrders = this.getRelevantOrders();
    const state = getComponentState(this.props.context, CONSTRUCTORNAME);
    if (state) {
      state.relevantOrders = relevantOrders;
      this.setState({ ...state });
    } else {
      this.setState({
        relevantOrders,
        filteredInvoices: this.getFilteredInvoices(relevantOrders)
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<DunningListingProps>, prevState: Readonly<DunningListingState>) {
    if (!_.isEqual(prevProps.context.orders, this.props.context.orders)) {
      const relevantOrders = this.getRelevantOrders();
      this.setState({
        relevantOrders,
        filteredInvoices: this.getFilteredInvoices(relevantOrders)
      });
    }
    if (
      prevState.reminder !== this.state.reminder ||
      prevState.search !== this.state.search ||
      prevState.owner !== this.state.owner ||
      prevState.sort !== this.state.sort ||
      prevState.sortOrder !== this.state.sortOrder ||
      prevState.hideCancellation !== this.state.hideCancellation
    ) {
      this.setState({ filteredInvoices: this.getFilteredInvoices() });
    }
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }
  handleReset = () => this.setState(this.getDefaultState(true));
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value, currentPage: 1 });
  handlePageChange = async (page: number) => {
    this.setState({ currentPage: page });
  };
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleSelectChange = (name: string, entry: { value: string; label: string } | "") => {
    // @ts-ignore
    this.setState({ [name]: entry, currentPage: 1 });
  };
  handleFinish = () => this.setState({ selectedInvoices: [] });

  /**
   * Method to handle the change of row selections.
   * @param correspondingInvoiceId id of the corresponding row entry.
   */
  handleSelected = (correspondingInvoiceId: string) => {
    const { filteredInvoices, selectedInvoices } = this.state;
    const filteredInvoicesWorkingCopy = [...filteredInvoices];
    const selectedInvoicesWorkingCopy = [...selectedInvoices];

    const filteredIndex = filteredInvoicesWorkingCopy.findIndex(
      dunningObject => dunningObject.invoice._id.toString() === correspondingInvoiceId
    );

    const dunningObjectCopy = _.cloneDeep(filteredInvoicesWorkingCopy[filteredIndex]);

    dunningObjectCopy.isSelected = !dunningObjectCopy.isSelected;

    if (dunningObjectCopy.isSelected) {
      // push object to selected invoices
      selectedInvoicesWorkingCopy.push(dunningObjectCopy);
    } else {
      // remove entity from selected invoices
      const selectedIndex = selectedInvoicesWorkingCopy.findIndex(
        selectedDunningObject => selectedDunningObject.invoice._id.toString() === correspondingInvoiceId
      );
      selectedInvoicesWorkingCopy.splice(selectedIndex, 1);
    }

    filteredInvoicesWorkingCopy.splice(filteredIndex, 1, dunningObjectCopy);
    this.setState({
      filteredInvoices: filteredInvoicesWorkingCopy,
      selectedInvoices: selectedInvoicesWorkingCopy
    });
  };

  /**
   * Function to handle the update of a dunning row
   * @param changeTyp the type of the change.
   * @param correspondingInvoiceId the id of the corresponding dunning object.
   * @param selectedLanguage the language to change.
   * @param selectedDunningLevel the dunning level to change.
   * @param newMail the new mail to change.
   */
  handleRowUpdate = (
    changeTyp: dunningChangeType,
    correspondingInvoiceId: string,
    selectedLanguage?: { label: string; value: string },
    selectedDunningLevel?: { label: string; value: number },
    newMail?: string
  ) => {
    const { filteredInvoices, selectedInvoices } = this.state;
    const filteredInvoicesWorkingCopy = [...filteredInvoices];
    const selectedInvoicesWorkingCopy = [...selectedInvoices];

    // receive corresponding index of the dunning row
    const filteredIndex = filteredInvoicesWorkingCopy.findIndex(
      dunningObject => dunningObject.invoice._id.toString() === correspondingInvoiceId
    );

    // receive corresponding index if dunning row is also selected
    const selectedIndex = selectedInvoicesWorkingCopy.findIndex(
      dunningObject => dunningObject.invoice._id.toString() === correspondingInvoiceId
    );
    // receive corresponding dunning row objects
    const dunningObjectCopy = _.cloneDeep(filteredInvoicesWorkingCopy[filteredIndex]);

    // update the dunning object corresponding to the change type
    switch (changeTyp) {
      case dunningChangeType.LANGUAGE:
        if (selectedLanguage) {
          dunningObjectCopy.pdfLanguage = selectedLanguage;
        }
        break;
      case dunningChangeType.DUNNING_LEVEL:
        if (selectedDunningLevel) {
          dunningObjectCopy.reminderOption = selectedDunningLevel;
        }
        break;
      case dunningChangeType.ACCOUNTING_MAIL:
        if (newMail) {
          // Check if new changes differs from the old value, if true save new accounting mail
          if (
            (dunningObjectCopy.correspondingAccountingMail &&
              dunningObjectCopy.correspondingAccountingMail.trim() !== newMail.trim()) ||
            !dunningObjectCopy.correspondingAccountingMail
          ) {
            dunningObjectCopy.correspondingAccountingMail = newMail.trim();
            dunningObjectCopy.hasAccountingMailChanged = true;
          }
        }
        break;
    }
    // saving changes
    filteredInvoicesWorkingCopy.splice(filteredIndex, 1, dunningObjectCopy);

    // check if entity is also already selected
    if (selectedIndex !== -1) {
      selectedInvoicesWorkingCopy.splice(selectedIndex, 1, dunningObjectCopy);
      this.setState({ filteredInvoices: filteredInvoicesWorkingCopy, selectedInvoices: selectedInvoicesWorkingCopy });
    } else {
      this.setState({ filteredInvoices: filteredInvoicesWorkingCopy });
    }
  };

  /**
   * Return a supported language object.
   * @param language to receive.
   * @returns {value: string, label: string}
   */
  getPDFLanguageObject = (language: string) => {
    if (language === "de") return { value: "de", label: "German" };
    else return { value: "en", label: "English" }; // default english
  };

  /**
   * Get the default state
   * @param reset optional, flag if it is a reset or not. on reset orders are kept
   * @returns {DunningListingState} default state for the component
   */
  getDefaultState = (reset?: boolean): DunningListingState => {
    const relevantOrders = this.state?.relevantOrders || [];
    const filteredInvoices = this.state?.filteredInvoices || [];
    const selectedInvoices = reset ? [] : this.state?.selectedInvoices || [];

    return {
      currentPage: 1,
      pageSize: 20,
      owner: "",
      reminder: { value: "", label: "All Reminder Levels" },
      search: "",
      sort: { value: "date", label: "Creation Date" },
      sortOrder: { value: "desc", label: "Descending" },
      hideCancellation: true,
      isScrollButtonActive: false,
      relevantOrders,
      filteredInvoices,
      selectedInvoices
    } as DunningListingState;
  };

  /**
   * Get only relevant orders that have an invoice
   * is locked only orders of that manufacturer
   * @returns {Array<OrdersDocument>} list of relevant orders
   */
  getRelevantOrders = () => {
    return this.props.context.orders.filter(
      o => orderUtils.isOrder(o) && o.state !== DECLINED && o.invoices && o.invoices.length > 0
    );
  };

  /**
   * Returning selectable dunning levels
   * @returns {Array<{label: string, value: number}>}
   */
  getDunningLevels = () => {
    return [
      { value: 0, label: "Level 0 (without fees)" },
      { value: 1, label: "Level 1 (Interest (" + I_INTEREST + "% dunning fees))" },
      { value: 2, label: "Level 2 (Interest (40€ base + " + I_INTEREST + "% dunning fees))" }
    ];
  };

  /**
   * Get filtered orders
   * @param orders optional, if set they are used instead of the relevant orders from state
   * @returns {Array<DunningObject>} list of all orders matching the filters
   */
  getFilteredInvoices = (orders?: Array<OrdersDocument>): Array<DunningObject> => {
    const { companies, userdata } = this.props.context;
    const { search, owner, relevantOrders, sort, sortOrder, hideCancellation, reminder } = this.state;
    const ordersToProcess = orders ? orders : relevantOrders;

    // filter by trustworthiness
    const filteredCompanies = companies.filter(company => !company.isTrustworthy);
    const filteredCompaniesIds: Array<String> = filteredCompanies.map(company => company._id.toString());
    const dunningOrders = ordersToProcess.filter(order => {
      return filteredCompaniesIds.includes(order.createdFor.toString()); // Try to avoid extend operation
    });

    let filteredInvoices: Array<DunningObject> = [];
    let filteredOrders = orderUtils.filterOrders(dunningOrders, undefined, owner ? owner.value : "");
    if (search.trim() !== "")
      filteredOrders = orderUtils.filterBySearch(
        filteredOrders,
        filteredCompanies,
        userdata,
        search
      ) as Array<OrdersDocument>;
    for (let i = 0; i < filteredOrders.length; i++) {
      const order = filteredOrders[i];
      const invoices = order.invoices;
      if (!invoices) continue;
      for (let j = 0; j < invoices.length; j++) {
        const invoice = invoices[j];
        if (
          (invoiceUtils.matchesInvoiceStatus(invoice, "due") ||
            invoiceUtils.matchesInvoiceStatus(invoice, "dunning")) &&
          (!hideCancellation || !invoice.cancelation)
        ) {
          const lastReminderLevel =
            invoice.reminder.length > 0 ? invoice.reminder[invoice.reminder.length - 1].level : undefined;
          const company: CompaniesDocument = baseUtils.getDocFromCollection(companies, order.createdFor);
          const owner: UserdataDocument = baseUtils.getDocFromCollection(userdata, order.createdFrom);
          filteredInvoices.push({
            correspondingAccountingMail: company.accountingEmail,
            hasAccountingMailChanged: false,
            owner: owner ? owner.prename + " " + owner.surname : "Unknown owner",
            company: company
              ? {
                  id: company._id.toString(),
                  name: company.name
                }
              : undefined,
            isSelected: false,
            pdfLanguage: this.getPDFLanguageObject(invoice.pdfData.content.language || "de")!,
            reminderOption:
              lastReminderLevel !== undefined
                ? lastReminderLevel === 2
                  ? this.getDunningLevels()[2]
                  : this.getDunningLevels()[lastReminderLevel + 1]
                : this.getDunningLevels()[0],
            order,
            invoice
          });
        }
      }
    }
    switch (reminder.value) {
      case "":
        break;
      case "noReminder":
        filteredInvoices = filteredInvoices.filter(fI => fI.invoice.reminder.length === 0);
        break;
      case "firstReminder":
        filteredInvoices = filteredInvoices.filter(fI => fI.invoice.reminder.length === 1);
        break;
      case "firstDunning":
        filteredInvoices = filteredInvoices.filter(fI => fI.invoice.reminder.length === 2);
        break;
      case "multipleDunning":
        filteredInvoices = filteredInvoices.filter(fI => fI.invoice.reminder.length >= 3);
        break;
    }
    switch (sort.value) {
      case "date":
        filteredInvoices = _.orderBy(filteredInvoices, item => item.invoice.invoiceDate, sortOrder.value);
        break;
      case "duedate":
        filteredInvoices = _.orderBy(
          filteredInvoices,
          item => {
            const dueDate = new Date(item.invoice.invoiceDate);
            dueDate.setDate(dueDate.getDate() + item.invoice.dueIn);
            return dueDate;
          },
          sortOrder.value
        );
        break;
      case "invoicenumber":
        filteredInvoices = _.orderBy(filteredInvoices, item => item.invoice.invoiceNumber, sortOrder.value);
        break;
      case "customer":
        filteredInvoices = _.orderBy(filteredInvoices, item => item.invoice.pdfData.customer, sortOrder.value);
        break;
      case "state":
        filteredInvoices = _.orderBy(filteredInvoices, item => item.invoice.state, sortOrder.value);
    }

    const stats = invoiceUtils.getDunningStats(filteredInvoices);
    this.setState({ stats });
    return filteredInvoices;
  };

  render() {
    const { context, history } = this.props;
    const { orders, userdata } = context;
    const {
      pageSize,
      currentPage,
      owner,
      reminder,
      filteredInvoices,
      selectedInvoices,
      sort,
      sortOrder,
      stats,
      search
    } = this.state;
    const selectableUsers = userdata.filter(user => user.company_id === "internal");
    const headerDefinition = [
      { title: "Select", size: 5 },
      { title: "Invoice #", size: 5 },
      { title: "Order", size: 5 },
      { title: "Company", size: 10 },
      { title: "Due", size: 5 },
      { title: "Last Reminder", size: 5 },
      { title: "Reminders", size: 5 },
      { title: "Invoiced", size: 5 },
      { title: "Pending", size: 5 },
      { title: "Owner", size: 10 },
      { title: "Language", size: 10 },
      { title: "Next Dunning Level", size: 15 },
      { title: "E-Mail", size: 15 }
    ];
    return (
      <React.Fragment>
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand fas fa-hand-holding-usd" />
              </span>
              <h3 className="kt-portlet__head-title">Dunning</h3>
              <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
                Reset
              </button>
            </div>
            <HistoryBackButton history={history} />
          </div>
          <div className="kt-portlet__body pb-0">
            <div className="kt-form kt-form--label-right  kt-margin-b-10">
              <div className="row align-items-center">
                <SearchBar onSearch={this.handleSearch} search={search} additionalSizeClasses={"col-md-2"} />
                <OwnerFilter
                  owner={owner}
                  selectableUsers={selectableUsers}
                  onFilterChange={this.handleSelectChange}
                  additionalSizeClasses={"col-md-3 col-xl-2"}
                  noLabel={true}
                />
                <DunningLevelFilter
                  remind={reminder}
                  onFilterChange={this.handleSelectChange}
                  additionalSizeClasses={"col-md-3 col-xl-2"}
                  noLabel={true}
                />
                <div className="col-md-3 col-xl-2 kt-margin-b-20-tablet-and-mobile">
                  <Select
                    className="select-default"
                    options={[
                      { value: "date", label: "Creation Date" },
                      { value: "duedate", label: "Due Date" },
                      { value: "invoicenumber", label: "Invoice Number" },
                      { value: "customer", label: "Customer" },
                      { value: "state", label: "State" }
                    ]}
                    value={sort}
                    onChange={(value: any) => this.handleSelectChange("sort", value)}
                  />
                </div>
                <div className="col-md-3 col-xl-2 kt-margin-b-20-tablet-and-mobile">
                  <Select
                    className="select-default"
                    options={[
                      { value: "asc", label: "Ascending" },
                      { value: "desc", label: "Descending" }
                    ]}
                    value={sortOrder ? sortOrder : { value: "desc", label: "Descending" }}
                    onChange={(value: any) =>
                      this.handleSelectChange("sortOrder", value || { value: "desc", label: "Descending" })
                    }
                  />
                </div>
                <DunningConfirmationModal
                  selectedDunningObjects={selectedInvoices}
                  onFinish={this.handleFinish}
                  context={context}
                />
                <div className="row mx-auto mt-4 py-3 bg-light" style={{ borderRadius: "15px", width: "100%" }}>
                  <Stat
                    title={"No Reminder"}
                    value={stats ? stats.noReminder : 0}
                    unit={"euro"}
                    alternativeSizeClass={"col"}
                  />
                  <Stat
                    title={"First Reminder"}
                    value={stats ? stats.firstReminder : 0}
                    unit={"euro"}
                    alternativeSizeClass={"col"}
                  />
                  <Stat
                    title={"First Dunning"}
                    value={stats ? stats.firstDunning : 0}
                    unit={"euro"}
                    alternativeSizeClass={"col"}
                  />
                  <Stat
                    title={"Multiple Dunning"}
                    value={stats ? stats.multipleDunning : 0}
                    unit={"euro"}
                    alternativeSizeClass={"col"}
                  />
                  <Stat title={"Total"} value={stats ? stats.total : 0} unit={"euro"} alternativeSizeClass={"col "} />
                </div>
              </div>
            </div>
            <div className="kt-portlet__body kt-portlet__body--fit">
              {orders.length === 0 ? (
                <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
              ) : (
                <BaseListing
                  tableStyle={{ overflow: "visible" }}
                  headerDefinition={headerDefinition}
                  documents={filteredInvoices}
                  bodyContent={
                    <>
                      {paginate(filteredInvoices, currentPage, pageSize).map(correspondingDunningObject => (
                        <DunningDetailsRow
                          key={correspondingDunningObject.invoice._id.toString()}
                          dunningObject={correspondingDunningObject}
                          onSelected={this.handleSelected}
                          onUpdate={this.handleRowUpdate}
                        />
                      ))}
                    </>
                  }
                  currentPage={currentPage}
                  pageSize={pageSize}
                  onPageChange={this.handlePageChange}
                  onPageSizeChange={this.handlePageSizeChange}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DunningListing;
