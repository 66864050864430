import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../../../context/dataContext";
import DeliveryCalendar from "./DeliveryCalendar";

interface DeliveryCalendarWrapperProps extends RouteComponentProps {}

const DeliveryCalendarWrapper: React.FunctionComponent<DeliveryCalendarWrapperProps> = props => {
  const context = useContext(DataContext);
  return <DeliveryCalendar {...props} context={context} />;
};

export default DeliveryCalendarWrapper;
