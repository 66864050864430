import React, { Component } from "react";
import { BSON } from "realm-web";
import ProductionLineWidget from "./production/ProductionLineWidget";
import ProductionLineAddWidget from "./production/ProductionLineAddWidget";
import { ManufacturersDocument, ProductionLine } from "../../model/manufacturers.types";
import manufacturerUtils from "../../utils/manufacturerUtils";
import userService from "../../services/userService";
import { ROLES } from "../../utils/userdataUtils";
import authenticationService from "../../services/authenticationService";
import { OrdersDocument } from "../../model/orders.types";
import { ProductionWeekDocument } from "../../model/productionPlan.types";

interface ProductionDashboardProps {
  orders: Array<OrdersDocument>;
  productionPlan: Array<ProductionWeekDocument>;
  manufacturers: Array<ManufacturersDocument>;
  manufacturer: { name: string; id: BSON.ObjectId | string | null };
}

class ProductionDashboard extends Component<ProductionDashboardProps, {}> {
  /**
   * Collects all production lines for the given manufacturer.
   * @returns { Array<ProductionLine> } Contains all production lines matching the manufacturer filter
   */
  collectProductionLines = () => {
    const { manufacturer, manufacturers } = this.props;
    let productionLines: Array<ProductionLine> = [];
    if (manufacturer.id) {
      const manufacturerObject = manufacturers.find(m => m._id.toString() === manufacturer.id!.toString());
      if (manufacturerObject && manufacturerObject.productionLines) {
        productionLines = productionLines.concat(manufacturerObject.productionLines);
      }
    } else {
      manufacturers.forEach(m => {
        if (
          m.productionLines &&
          (userService.isAuthorizedForAction(ROLES.ADMIN) ||
            userService.isAuthorizedForAction(ROLES.SALES) ||
            userService.isAuthorizedForAction(ROLES.PROCUREMENT) ||
            manufacturerUtils.isEmployeeOfManufacturer(authenticationService.getUserDataID(), m))
        )
          productionLines = productionLines.concat(m.productionLines);
      });
    }
    return productionLines;
  };

  /**
   * Get order for the current week
   * @returns {Array<OrdersDocument>} list of orders planned for current week
   */
  getCurrentOrders = () => {
    const { orders, productionPlan } = this.props;
    const now = new Date();
    const currentWeek = productionPlan.find(
      week => week.startDate.getTime() <= now.getTime() && now.getTime() <= week.endDate.getTime()
    );
    if (!currentWeek) return [];
    let currentOrders = currentWeek.plannedOrders.map(pO => orders.find(o => o._id.toString() === pO.toString()));
    return currentOrders.filter(o => !!o && !!o.productionWeek && !!o.settings.productionMachine) as Array<
      OrdersDocument
    >;
  };

  render() {
    const { manufacturer, manufacturers } = this.props;
    const productionLinesShown = this.collectProductionLines();
    const currentOrders: Array<OrdersDocument> = this.getCurrentOrders();
    return (
      <div className="row">
        {productionLinesShown.length > 0 &&
          productionLinesShown.map(p => {
            return (
              <ProductionLineWidget
                key={p._id.toString()}
                productionLine={p}
                orders={currentOrders}
                updateProductionLines={this.collectProductionLines}
              />
            );
          })}
        {(userService.isAuthorizedForAction(ROLES.ADMIN) ||
          (userService.isAuthorizedForAction(ROLES.PRODUCTION) &&
            manufacturerUtils.isEmployeeOfAnyManufacturer(authenticationService.getUserDataID(), manufacturers))) && (
          <ProductionLineAddWidget
            updateProductionLines={this.collectProductionLines}
            selectedManufacturer={manufacturer.id}
          />
        )}
        {userService.isAuthorizedForAction(ROLES.PRODUCTION, true) &&
          !manufacturerUtils.isEmployeeOfAnyManufacturer(authenticationService.getUserDataID(), manufacturers) && (
            <div className="col-12 text-center h3 text-dark mt-4">
              You got the role "Production", but are not assigned to a manufacturer, therefore no production lines are
              shown.
            </div>
          )}
      </div>
    );
  }
}

export default ProductionDashboard;
