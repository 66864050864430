import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { OrdersCommodityUsage } from "../CustomTypes";
import OrderAmountChart from "../OrderAmountChart";
import CompanyWidget from "../../common/CompanyWidget";
import { PaginationState } from "../../common/CustomTypes";
import Pagination, { paginate } from "../../common/Pagination";
import PriorityWidget from "../../common/PriorityWidget";
import { DataContext } from "../../../context/dataContext";
import { CommoditiesDocument } from "../../../model/commodities.types";
import commodityUtils from "../../../utils/commodityUtils";
import orderUtils, { ARCHIVE, DECLINED } from "../../../utils/orderUtils";
import contractUtils from "../../../utils/contractUtils";

interface CommodityOrdersProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
}

interface CommodityOrdersState extends PaginationState {
  orders: Array<OrdersCommodityUsage>;
  manufacturer: string;
}

class CommodityOrders extends PureComponent<CommodityOrdersProps, CommodityOrdersState> {
  constructor(props: CommodityOrdersProps) {
    super(props);
    this.state = { manufacturer: "all", orders: [], currentPage: 1, pageSize: 5 };
  }

  componentDidMount() {
    this.setState({ orders: this.collectOrders() });
  }

  componentDidUpdate(
    prevProps: Readonly<CommodityOrdersProps>,
    prevState: Readonly<CommodityOrdersState>,
    snapshot?: any
  ) {
    if (prevProps !== this.props || prevState.manufacturer !== this.state.manufacturer) {
      this.setState({ orders: this.collectOrders() });
    }
  }

  handleChangeManufacturer = (manufacturer: string) => {
    this.setState({ manufacturer });
  };

  /**
   * Collect all orders where the current commodity is used and prepares the date for further usage.
   * @returns { OrdersCommodityUsage } Data about the usage of the current commodity
   */
  collectOrders = () => {
    const { commodity, context } = this.props;
    const { manufacturer } = this.state;
    const orders = context.orders
      .filter(
        o =>
          orderUtils.isOrder(o) &&
          !contractUtils.isContract(o) &&
          o.state !== DECLINED &&
          o.calculations[0].prices.some(p => p._id.toString() === commodity._id.toString()) &&
          (manufacturer === "all" || o.settings.manufacturer.toString() === manufacturer)
      )
      .sort((o1, o2) => o2.createdOn.getTime() - o1.createdOn.getTime());
    const ordersPrepared = [];
    for (let i = 0; i < orders.length; i++) {
      const o = orders[i];
      const commodityPrice = o.calculations[0].prices.find(p => p._id.toString() === commodity._id.toString())!;
      const customer = context.companies.find(c => o.createdFor.toString() === c._id.toString())!;
      const usage = commodityUtils.calculateUsage(o, commodityPrice, commodity.type);
      ordersPrepared.push({ order: o, customer, commodityPrice, amountRaw: usage.raw, amountUnit: usage.unit });
    }
    return ordersPrepared;
  };

  render() {
    const { commodity, context } = this.props;
    const { currentPage, manufacturer, orders, pageSize } = this.state;
    const ordersPaginated: Array<OrdersCommodityUsage> = paginate(orders, currentPage, pageSize);
    return (
      <>
        <>
          <OrderAmountChart
            orders={orders}
            context={context}
            manufacturer={manufacturer}
            onChangeManufacturer={this.handleChangeManufacturer}
          />
          {orders && orders.length > 0 ? (
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
              <Table>
                <thead>
                  <tr>
                    <th style={{ width: "35%" }}>Order</th>
                    <th style={{ width: "25%" }}>Company</th>
                    <th style={{ width: "10%" }}>Priority</th>
                    <th style={{ width: "10%" }}>Required</th>
                    <th style={{ width: "10%" }}>Ordered</th>
                    <th style={{ width: "10%" }}>Order Created</th>
                  </tr>
                </thead>
                <tbody>
                  {ordersPaginated.map(o => {
                    const com = o.commodityPrice;
                    return (
                      <tr
                        key={o.order._id.toString()}
                        style={{ backgroundColor: "#fafafa", opacity: o.order.state === ARCHIVE ? 0.5 : 1 }}
                      >
                        <td className="align-middle">
                          <Link to={"/order/" + o.order._id.toString()} className="h6 kt-link kt-font-dark">
                            AT-{o.order.identifier}
                          </Link>
                          <div className="text-muted">{o.order.title}</div>
                        </td>
                        <td className="align-middle">
                          <CompanyWidget company={o.customer} />
                        </td>
                        <td className="align-middle">
                          <PriorityWidget priority={o.order.priority} />
                        </td>
                        <td className="align-middle text-success kt-font-bold">{o.amountUnit}</td>
                        <td className="align-middle kt-font-bold">
                          {com.ordered
                            ? com.ordered.toLocaleDateString("de-DE", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric"
                              })
                            : "-"}
                        </td>
                        <td className="align-middle">
                          {o.order.createdOn.toLocaleDateString("de-DE", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric"
                          })}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <Pagination
                  itemsCount={orders.length}
                  pageSize={pageSize}
                  onPageChange={currentPage => this.setState({ currentPage })}
                  currentPage={currentPage}
                  additionalNavClasses="justify-content-center flex-grow-1"
                />
              </div>
            </div>
          ) : (
            <div className="text-center text-muted my-5">No orders with {commodity.title.en}</div>
          )}
        </>
      </>
    );
  }
}
export default CommodityOrders;
