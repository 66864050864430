import React, { PureComponent } from "react";
import { CommoditiesDocument } from "../../model/commodities.types";

interface CommodityNoteProps {
  commodity: CommoditiesDocument;
  onTabChange: (tab: string) => void;
}

interface CommodityNoteState {}

class CommodityNote extends PureComponent<CommodityNoteProps, CommodityNoteState> {
  render() {
    const { commodity, onTabChange } = this.props;
    return (
      <div className="kt-portlet kt-portlet--head-noborder">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Note</h3>
          </div>
          <div className="kt-portlet__head-toolbar" />
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit-top">
          <div className="kt-section kt-section--space-sm mt-2">
            {commodity.note
              ? commodity.note
              : "Important additional information about the commodity that cannot be found anywhere else can be stored here."}
          </div>
          <div className="kt-section kt-section--last">
            <button className="btn btn-label-brand btn-sm btn-bold" onClick={() => onTabChange("settings")}>
              Update Note
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CommodityNote;
