import { BSON } from "realm-web";
import {
  Batch,
  BatchComment,
  BatchFile,
  BatchLocation,
  BatchLocationInformation,
  BatchTimelineEntry,
  BatchTimelineEntryPayload,
  BatchTimelineSubType,
  BatchTimelineType,
  PackagingUnit
} from "../model/warehouse/batch.types";
import userService from "../services/userService";
import {
  FileType,
  PhysicalWarehouse,
  StorageSpace,
  WarehouseDefinition,
  WarehouseTypes
} from "../model/configuration/warehouseConfiguration.types";
import { DEFAULTWEIGHTUNIT } from "./warehouseUtils";
import { NumValue } from "../model/common.types";
import { CommoditiesDocument } from "../model/commodities.types";
import { ContentType } from "../model/warehouse/common.types";

export enum BatchStatus {
  AVAILABLE,
  PARTIALLY_BLOCKED,
  BLOCKED
}

/**
 * Returns a new BatchLocationInformation object based on the given information
 * @param logicalWarehouse the WarehouseDefinition from which the warehouseSnapshot should be taken from
 * @param physicalWarehouse the PhysicalWarehouse from which the warehouseArea should be taken from
 * @param storageSpace optional, the StorageSpace from which the storageSpace should be taken from
 * @returns {BatchLocationInformation} the BatchLocationInformation including snapshots from the given information
 */
export const getBatchLocationInformationObject = (
  logicalWarehouse: WarehouseDefinition,
  physicalWarehouse: PhysicalWarehouse,
  storageSpace?: StorageSpace
): BatchLocationInformation => {
  const batchLocationInformationObject: BatchLocationInformation = {
    warehouseSnapshot: {
      _id: logicalWarehouse._id,
      shortName: logicalWarehouse.shortName,
      warehouseName: logicalWarehouse.warehouseName
    },
    warehouseArea: {
      _id: physicalWarehouse._id,
      type: physicalWarehouse.type,
      shortName: physicalWarehouse.shortName,
      warehouseName: physicalWarehouse.warehouseName
    }
  };
  if (storageSpace) {
    batchLocationInformationObject.storageSpace = {
      _id: storageSpace._id,
      storageSpaceNo: storageSpace.storageSpaceNo
    };
  }
  return batchLocationInformationObject;
};

/**
 * Returns a new BatchLocation object based on the given information
 * @param batchLocationInformation the location information which should be used for the new batch location
 * @param packagingUnitAssignments optional, if given, the new location will include the given quantity of those packagingUnits
 * @returns {BatchLocation} the BatchLocation object with prefilled information
 */
export const getBatchLocationObject = (
  batchLocationInformation: BatchLocationInformation,
  packagingUnitAssignments?: Array<{ packagingUnit: PackagingUnit; quantity: number | undefined }>
): BatchLocation => {
  const totalAmount = packagingUnitAssignments
    ? packagingUnitAssignments.reduce(
        (totalAmount, pUAssignment) =>
          totalAmount +
          (pUAssignment.quantity ?? pUAssignment.packagingUnit.quantity ?? 0) *
            pUAssignment.packagingUnit.amountPerPu.value,
        0
      )
    : 0;
  const newPackagingUnits = packagingUnitAssignments
    ? packagingUnitAssignments.map(pUAssignment => {
        const adjustedPU: PackagingUnit = {
          _id: new BSON.ObjectId(),
          amountPerPu: pUAssignment.packagingUnit.amountPerPu,
          puSnapshot: pUAssignment.packagingUnit.puSnapshot,
          quantity: pUAssignment.quantity ?? pUAssignment.packagingUnit.quantity,
          weight: pUAssignment.packagingUnit.weight
        };
        return adjustedPU;
      })
    : [];

  return {
    _id: new BSON.ObjectId(),
    location: batchLocationInformation,
    amountAtLocation: {
      value: totalAmount,
      unit: DEFAULTWEIGHTUNIT // might change in later versions, if we use something else than kg in PUs
    },
    packagingUnits: newPackagingUnits
  };
};

/**
 * Get the batch status
 * @param batch a batch
 * @returns {BatchStatus} status if batch is available or blocked
 */
export const getBatchStatus = (batch: Batch): BatchStatus => {
  if (batch.blocked || batch.locations.every(l => l.packagingUnits.every(p => p.blocked))) return BatchStatus.BLOCKED;
  if (batch.locations.some(l => l.packagingUnits.some(p => p.blocked))) return BatchStatus.PARTIALLY_BLOCKED;
  return BatchStatus.AVAILABLE;
};

/**
 * Get a batch status indication
 * @param batch a batch
 * @returns {[color: string, statusKey: string]} tuple with text color and translation key for status
 */
export const getBatchStatusIndicationText = (batch: Batch): [color: string, statusKey: string] => {
  const status = getBatchStatus(batch);
  if (status === BatchStatus.BLOCKED) return ["text-danger", "blocked"];
  return ["text-success", "available"];
};

/**
 * Get the status for a specific location of batch
 * @param batch the batch related to the location
 * @param location a specific batch location
 * @returns {BatchStatus} status if amount at batch location is available, reserved or partially reserved
 */
export const getBatchLocationStatus = (batch: Batch, location: BatchLocation): BatchStatus => {
  if (batch.blocked) return BatchStatus.BLOCKED;
  if (location.packagingUnits.every(pU => pU.blocked)) return BatchStatus.BLOCKED;
  if (location.packagingUnits.some(pU => pU.blocked)) return BatchStatus.PARTIALLY_BLOCKED;
  return BatchStatus.AVAILABLE;
};

/**
 * Get a status indication for a specific location of batch
 * @param batch the batch related to the location
 * @param location a specific batch location
 * @returns {[status: BatchStatus, color: string, statusKey: string]} triple with status, text color and translation key for status
 */
export const getBatchLocationStatusIndicationText = (
  batch: Batch,
  location: BatchLocation
): [status: BatchStatus, color: string, statusKey: string] => {
  const status = getBatchLocationStatus(batch, location);
  if (status === BatchStatus.BLOCKED) return [BatchStatus.BLOCKED, "text-danger", "blocked"];
  if (status === BatchStatus.PARTIALLY_BLOCKED)
    return [BatchStatus.PARTIALLY_BLOCKED, "text-warning", "partiallyBlocked"];
  return [BatchStatus.AVAILABLE, "text-success", "available"];
};

/**
 * Get how much of the amount at a location is still available (not blocked)
 * @param location a specific batch location
 * @returns {NumValue} the available amount
 */
export const getBatchLocationAvailableAmount = (location: BatchLocation): NumValue => {
  const blockedAmount = location.packagingUnits.reduce((sumBlocked, p) => {
    if (p.blocked) return sumBlocked + p.amountPerPu.value;
    return sumBlocked;
  }, 0);
  const availableAmount = location.amountAtLocation.value - blockedAmount;

  return { value: availableAmount, unit: DEFAULTWEIGHTUNIT };
};

/**
 * Get a complete batch comment document
 * @param comment the comments content
 * @returns {BatchComment} a batch comment document
 */
export const getBatchCommentDocument = (comment: string): BatchComment => {
  return {
    date: new Date(),
    person: userService.getUserSnapshot(),
    text: comment.trim(),
    _id: new BSON.ObjectId()
  };
};

/**
 * Compare two batch locations with each other
 * @param location1 information about the location
 * @param location2 information about the location to compare with
 * @param ignoreStorageSpace optional, flag to not check storage space
 * @returns {boolean} true if information matches, else false
 */
export const compareBatchLocations = (
  location1: BatchLocationInformation,
  location2: BatchLocationInformation,
  ignoreStorageSpace?: boolean
): boolean => {
  return (
    location1.warehouseSnapshot._id.toString() === location2.warehouseSnapshot._id.toString() &&
    location1.warehouseArea._id.toString() === location2.warehouseArea._id.toString() &&
    (!!ignoreStorageSpace || location1.storageSpace?._id.toString() === location2.storageSpace?._id.toString())
  );
};

/**
 * Get a batch file object
 * @param filePath the path of the file
 * @param file the file itself
 * @param type the type of file
 * @returns {BatchFile} the batch file object
 */
export const getBatchFileEntry = (filePath: string, file: File, type: FileType): BatchFile => {
  const user = userService.getUserSnapshot();
  return {
    _id: new BSON.ObjectId(),
    type,
    date: new Date(),
    person: { _id: user._id, prename: user.prename, surname: user.surname },
    path: filePath,
    title: file.name,
    fileExtension: file.type,
    fileSize: file.size
  };
};

/**
 * Get a timeline entry for a batch
 * @param type The type of batch timeline entry
 * @param subtype The subtype of the batch timeline entry
 * @param payload The payload of the timeline entry
 * @param diff optional, diff object
 * @returns {BatchTimelineEntry} The batch timeline entry
 */
export const getBatchTimelineEntry = (
  type: BatchTimelineType,
  subtype: BatchTimelineSubType,
  payload: BatchTimelineEntryPayload,
  diff?: { pre: Partial<Batch>; post: Partial<Batch> }
): BatchTimelineEntry => {
  const user = userService.getUserSnapshot();
  return {
    _id: new BSON.ObjectId(),
    type,
    subtype,
    date: new Date(),
    person: { _id: user._id, prename: user.prename, surname: user.surname },
    payload: payload ?? {},
    diff: diff ?? { pre: {}, post: {} }
  };
};

/**
 * Get the total batch amount for a material
 * @param batch list of all batches
 * @param material material document or already a specific id
 * @return {NumValue | null} total amount
 */
export const getTotalAmountForMaterial = (
  batch: Array<Batch>,
  material: CommoditiesDocument | string | BSON.ObjectId
): NumValue | null => {
  const materialId = typeof material === "object" && "_id" in material ? material._id.toString() : material.toString();
  const batches = batch.filter(
    b => b.content.type === ContentType.COMMODITY && b.content.details._id.toString() === materialId
  );

  let unit: string | null = null;
  let amount = 0;
  batches.forEach(b => {
    if (!b.blocked)
      b.locations.forEach(l => {
        if (l.location.warehouseArea.type === WarehouseTypes.REMOTE) {
          if (unit && unit !== l.amountAtLocation.unit) throw Error("Mismatch in units. Cannot calculate");
          amount += l.amountAtLocation.value;
          if (!unit) unit = l.amountAtLocation.unit;
        } else {
          l.packagingUnits.forEach(pU => {
            if (!pU.blocked) {
              if (unit && unit !== pU.amountPerPu.unit) throw Error("Mismatch in units. Cannot calculate");
              amount += (pU.quantity || 0) * pU.amountPerPu.value;
              if (!unit) unit = pU.amountPerPu.unit;
            }
          });
        }
      });
  });
  if (!unit) return null;
  return { value: amount, unit };
};
