import React, { useEffect, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import Select from "react-select";
import { ManufacturersDocument } from "../../../model/manufacturers.types";
import { UserdataDocument } from "../../../model/userdata.types";
import { CompaniesDocument } from "../../../model/companies.types";
import { KeyboardEventKeyValues } from "../../../utils/thingsTypeScriptShouldProvideUtils";

interface BaseProps {
  additionalSizeClasses?: string;
  setZIndex?: boolean;
  noLabel?: boolean;
  disabled?: boolean;
}

interface ManufacturerFilterProps extends BaseProps {
  manufacturer: "" | { value: string; label: string };
  manufacturers: Array<ManufacturersDocument>;
  manufacturerLocked: boolean;
  onFilterSelect: (name: string, manufacturer: { value: string; label: string } | "") => void;
  customLabel?: string;
  notClearable?: true;
}

export const ManufacturerFilter: React.FunctionComponent<ManufacturerFilterProps> = ({
  customLabel,
  manufacturer,
  manufacturers,
  manufacturerLocked,
  onFilterSelect,
  additionalSizeClasses,
  setZIndex,
  noLabel,
  notClearable
}) => {
  const handleSelectManufacturer = (value: any) => {
    // Avoid selection if manufacturer is locked
    if (manufacturerLocked) return;
    onFilterSelect("manufacturer", value || "");
  };

  const manufacturerSelect = (
    <Select
      isClearable={!notClearable}
      className="select-default"
      isDisabled={manufacturerLocked}
      options={manufacturers.map(item => {
        return { value: item._id.toString(), label: item.name };
      })}
      value={manufacturer ? manufacturer : { value: "", label: "All Manufacturers" }}
      onChange={handleSelectManufacturer}
    />
  );
  return (
    <div
      style={setZIndex ? { zIndex: 5 } : undefined} //is needed because otherwise the select is behind the deliveryCalendar, values >5 will put the select in front of the menubar
      className={"kt-margin-b-20-tablet-and-mobile " + (additionalSizeClasses ? additionalSizeClasses : "col-md-2")}
    >
      {noLabel ? (
        manufacturerSelect
      ) : (
        <div className="kt-form__group kt-form__group--inline">
          <div className="kt-form__label">
            <label>{customLabel ? customLabel : "Manufacturer"}</label>
          </div>
          <div className="kt-form__control">{manufacturerSelect}</div>
        </div>
      )}
    </div>
  );
};

interface SearchBarProps extends BaseProps {
  name?: string;
  search?: string;
  tooltip?: React.ReactElement;
  buttonClasses?: string;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBar: React.FunctionComponent<SearchBarProps> = ({
  search,
  tooltip,
  onSearch,
  name,
  buttonClasses,
  additionalSizeClasses
}) => {
  const [value, setValue] = useState(!!search ? search : "");

  useEffect(() => {
    setValue(!!search ? search : "");
  }, [search]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 || e.key === KeyboardEventKeyValues.ENTER) {
      e.currentTarget.blur();
      e.currentTarget.focus();
    }
  };
  const input = (
    <input
      type="text"
      name={name ? name : "searchBar"}
      className="form-control"
      placeholder="Search..."
      value={value}
      onKeyDown={handleKeyDown}
      onChange={e => setValue(e.target.value)}
      onBlur={onSearch}
    />
  );
  const goButton = (
    <span className="kt-input-icon__icon kt-input-icon__icon--right">
      <button className={buttonClasses ? buttonClasses : "btn btn-primary btn-sm float-right"}>Go</button>
    </span>
  );
  return (
    <div className={"kt-margin-b-20-tablet-and-mobile " + (additionalSizeClasses ? additionalSizeClasses : "col-md-2")}>
      <div className="kt-input-icon kt-input-icon--left d-none d-lg-block">
        {input}
        {tooltip ? (
          <OverlayTrigger overlay={tooltip}>
            <span className="kt-input-icon__icon kt-input-icon__icon--left">
              <span>
                <i className="la la-search" />
              </span>
            </span>
          </OverlayTrigger>
        ) : (
          <span className="kt-input-icon__icon kt-input-icon__icon--left">
            <span>
              <i className="la la-search" />
            </span>
          </span>
        )}
        {goButton}
      </div>
      <div className="kt-input-icon d-block d-lg-none">
        {input}
        {goButton}
      </div>
    </div>
  );
};

interface MarginFilterProps extends BaseProps {
  margin: "" | { value: string; label: string };
  onFilterSelect: (name: string, margin: { value: string; label: string } | "") => void;
  isDisabled?: boolean;
}

export const MarginFilter: React.FunctionComponent<MarginFilterProps> = ({
  margin,
  onFilterSelect,
  additionalSizeClasses,
  noLabel,
  isDisabled
}) => {
  const marginSelect = (
    <Select
      className="select-default"
      isClearable={true}
      options={[
        { value: "high", label: "High" },
        { value: "medium", label: "Medium" },
        { value: "low", label: "Low" },
        { value: "forbidden", label: "Forbidden" }
      ]}
      value={margin ? { value: margin.value, label: margin.label } : { value: "", label: "All Margins" }}
      onChange={isDisabled ? undefined : (value: any) => onFilterSelect("margin", value || "")}
      isDisabled={isDisabled}
    />
  );
  return (
    <div className={"kt-margin-b-20-tablet-and-mobile " + (additionalSizeClasses ? additionalSizeClasses : "col-md-2")}>
      {noLabel ? (
        marginSelect
      ) : (
        <div className="kt-form__group kt-form__group--inline">
          <div className="kt-form__label">
            <label>Quality</label>
          </div>
          <div className="kt-form__control">{marginSelect}</div>
        </div>
      )}
    </div>
  );
};

interface PriorityFilterProps extends BaseProps {
  priority: "" | { value: string; label: string };
  onFilterSelect: (name: string, priority: { value: string; label: string } | "") => void;
}

export const PriorityFilter: React.FunctionComponent<PriorityFilterProps> = ({
  priority,
  onFilterSelect,
  additionalSizeClasses,
  noLabel
}) => {
  const prioritySelect = (
    <Select
      className="select-default"
      isClearable={true}
      options={[
        { value: "high", label: "High" },
        { value: "medium", label: "Medium" },
        { value: "low", label: "Low" }
      ]}
      value={priority ? { value: priority.value, label: priority.label } : { value: "", label: "All Priorities" }}
      onChange={(value: any) => onFilterSelect("priority", value || "")}
    />
  );
  return (
    <div className={"kt-margin-b-20-tablet-and-mobile " + (additionalSizeClasses ? additionalSizeClasses : "col-md-2")}>
      {noLabel ? (
        prioritySelect
      ) : (
        <div className="kt-form__group kt-form__group--inline">
          <div className="kt-form__label">
            <label>Priority</label>
          </div>
          <div className="kt-form__control">{prioritySelect}</div>
        </div>
      )}
    </div>
  );
};

interface OwnerFilterProps extends BaseProps {
  owner: "" | { value: string; label: string };
  selectableUsers: Array<UserdataDocument>;
  onFilterChange: (name: string, owner: { value: string; label: string } | "") => void;
}

export const OwnerFilter: React.FunctionComponent<OwnerFilterProps> = ({
  owner,
  selectableUsers,
  onFilterChange,
  additionalSizeClasses,
  noLabel,
  disabled
}) => {
  const options = [{ value: "", label: "All Owners" }];
  selectableUsers.forEach(u => options.push({ value: u._id.toString(), label: u.prename + " " + u.surname }));
  const select = (
    <Select
      className={"select-default" + (disabled ? " disabled" : "")}
      options={options}
      value={owner ? { value: owner.value, label: owner.label } : { value: "", label: "All Owners" }}
      isClearable={true}
      onChange={(e: any) => onFilterChange("owner", e || "")}
      isDisabled={disabled}
    />
  );
  return (
    <div className={"kt-margin-b-20-tablet-and-mobile " + (additionalSizeClasses ? additionalSizeClasses : "col-md-2")}>
      {noLabel ? (
        select
      ) : (
        <div className="kt-form__group kt-form__group--inline">
          <div className="kt-form__label">
            <label>Owner</label>
          </div>
          <div className="kt-form__control">{select}</div>
        </div>
      )}
    </div>
  );
};

interface InvoicesFilterProps extends BaseProps {
  status: { value: string; label: string };
  onFilterChange: (name: string, status: { value: string; label: string }) => void;
}

export const InvoicesFilter: React.FunctionComponent<InvoicesFilterProps> = ({
  status,
  onFilterChange,
  additionalSizeClasses,
  noLabel
}) => {
  const select = (
    <Select
      className="select-default"
      options={[
        { value: "all", label: "All Statuses" },
        { value: "open", label: "Open" },
        { value: "due", label: "Due" },
        { value: "paid", label: "Paid" },
        { value: "partlyPaid", label: "Partially paid" },
        { value: "canceled", label: "Canceled" }
      ]}
      value={{ value: status.value, label: status.label }}
      isClearable={true}
      onChange={(e: any) => onFilterChange("status", e || { value: "all", label: "All Statuses" })}
    />
  );
  return (
    <div className={"kt-margin-b-20-tablet-and-mobile " + (additionalSizeClasses ? additionalSizeClasses : "col-md-2")}>
      {noLabel ? (
        select
      ) : (
        <div className="kt-form__group kt-form__group--inline">
          <div className="kt-form__label">
            <label>Status</label>
          </div>
          <div className="kt-form__control">{select}</div>
        </div>
      )}
    </div>
  );
};

interface CustomerFilterProps extends BaseProps {
  customer: { value: string; label: string };
  selectableCustomers: Array<CompaniesDocument>;
  onFilterChange: (name: string, customer: { value: string; label: string }) => void;
}

export const CustomerFilter: React.FunctionComponent<CustomerFilterProps> = ({
  customer,
  selectableCustomers,
  onFilterChange,
  additionalSizeClasses,
  noLabel
}) => {
  const select = (
    <Select
      className="select-default"
      options={[{ value: "all", label: "All customers" }].concat(
        selectableCustomers.map(c => {
          return { value: c._id.toString(), label: c.name };
        })
      )}
      isClearable={true}
      value={{ value: customer.value, label: customer.label }}
      onChange={(e: any) => onFilterChange("customer", e || { value: "all", label: "All customers" })}
    />
  );
  return (
    <div className={"kt-margin-b-20-tablet-and-mobile " + (additionalSizeClasses ? additionalSizeClasses : "col-md-2")}>
      {noLabel ? (
        select
      ) : (
        <div className="kt-form__group kt-form__group--inline">
          <div className="kt-form__label">
            <label>Customer</label>
          </div>
          <div className="kt-form__control">{select}</div>
        </div>
      )}
    </div>
  );
};

interface DueLevelFilterProps extends BaseProps {
  due: { value: string; label: string };
  onFilterChange: (name: string, due: { value: string; label: string }) => void;
}

export const DueLevelFilter: React.FunctionComponent<DueLevelFilterProps> = ({
  due,
  onFilterChange,
  additionalSizeClasses,
  noLabel
}) => {
  const select = (
    <Select
      className="select-default"
      options={[
        { value: "all", label: "All levels" },
        { value: "0", label: "Only not overdue" },
        { value: "1", label: "under 7 days" },
        { value: "2", label: "between 7 and 14 days" },
        { value: "3", label: "over 14 days" }
      ]}
      isClearable={true}
      value={{ value: due.value, label: due.label }}
      onChange={(e: any) => onFilterChange("due", e || { value: "all", label: "All levels" })}
    />
  );
  return (
    <div className={"kt-margin-b-20-tablet-and-mobile " + (additionalSizeClasses ? additionalSizeClasses : "col-md-2")}>
      {noLabel ? (
        select
      ) : (
        <div className="kt-form__group kt-form__group--inline">
          <div className="kt-form__label">
            <label>Overdue</label>
          </div>
          <div className="kt-form__control">{select}</div>
        </div>
      )}
    </div>
  );
};

interface MissingReminderFilterProps extends BaseProps {
  reminder: { value: string; label: string };
  onFilterChange: (name: string, entry: { value: string; label: string }) => void;
}

export const MissingReminderFilter: React.FunctionComponent<MissingReminderFilterProps> = ({
  reminder,
  onFilterChange,
  additionalSizeClasses,
  noLabel
}) => {
  const select = (
    <Select
      className="select-default"
      options={[
        { value: "all", label: "All invoices" },
        { value: "reminder", label: "Missing reminder" },
        { value: "dunning", label: "Missing dunning" }
      ]}
      isClearable={true}
      value={{ value: reminder.value, label: reminder.label }}
      onChange={(e: any) => onFilterChange("reminder", e || { value: "all", label: "All invoices" })}
    />
  );
  return (
    <div className={"kt-margin-b-20-tablet-and-mobile " + (additionalSizeClasses ? additionalSizeClasses : "col-md-2")}>
      {noLabel ? (
        select
      ) : (
        <div className="kt-form__group kt-form__group--inline">
          <div className="kt-form__label">
            <label>Reminder</label>
          </div>
          <div className="kt-form__control">{select}</div>
        </div>
      )}
    </div>
  );
};

interface ReadNoteFilterProps extends BaseProps {
  read: "" | { value: string; label: string };
  onFilterChange: (name: string, entry: { value: string; label: string }) => void;
}

export const ReadNoteFilter: React.FunctionComponent<ReadNoteFilterProps> = ({
  read,
  onFilterChange,
  additionalSizeClasses,
  noLabel
}) => {
  const select = (
    <Select
      className="select-default"
      options={[
        { value: "", label: "All" },
        { value: "read", label: "Read" },
        { value: "unread", label: "Unread" }
      ]}
      isClearable={true}
      value={read ? { value: read.value, label: read.label } : { value: "", label: "All" }}
      onChange={(e: any) => onFilterChange("read", e || { value: "", label: "All" })}
    />
  );
  return (
    <div className={"kt-margin-b-20-tablet-and-mobile " + (additionalSizeClasses ? additionalSizeClasses : "col-md-2")}>
      {noLabel ? (
        select
      ) : (
        <div className="kt-form__group kt-form__group--inline">
          <div className="kt-form__label">
            <label>Read</label>
          </div>
          <div className="kt-form__control">{select}</div>
        </div>
      )}
    </div>
  );
};

interface DunningLevelFilterProps extends BaseProps {
  remind: "" | { value: string; label: string };
  onFilterChange: (name: string, entry: { value: string; label: string }) => void;
}

export const DunningLevelFilter: React.FunctionComponent<DunningLevelFilterProps> = ({
  remind,
  onFilterChange,
  additionalSizeClasses,
  noLabel
}) => {
  const select = (
    <Select
      className="select-default"
      options={[
        { value: "", label: "All Reminder Levels" },
        { value: "noReminder", label: "No Reminder" },
        { value: "firstReminder", label: "First Reminder" },
        { value: "firstDunning", label: "First Dunning" },
        { value: "multipleDunning", label: "Multiple Dunning" }
      ]}
      isClearable={true}
      value={remind ? { value: remind.value, label: remind.label } : { value: "", label: "All Reminder Levels" }}
      onChange={(e: any) => onFilterChange("reminder", e ? e : { value: "", label: "All Reminder Levels" })}
    />
  );
  return (
    <div className={"kt-margin-b-20-tablet-and-mobile " + (additionalSizeClasses ? additionalSizeClasses : "col-md-2")}>
      {noLabel ? (
        select
      ) : (
        <div className="kt-form__group kt-form__group--inline">
          <div className="kt-form__label">
            <label>Remind</label>
          </div>
          <div className="kt-form__control">{select}</div>
        </div>
      )}
    </div>
  );
};

interface AnsweredNoteFilterProps extends BaseProps {
  answered: "" | { value: string; label: string };
  onFilterChange: (name: string, entry: { value: string; label: string }) => void;
}

export const AnsweredNoteFilter: React.FunctionComponent<AnsweredNoteFilterProps> = ({
  answered,
  onFilterChange,
  additionalSizeClasses,
  noLabel
}) => {
  const select = (
    <Select
      className="select-default"
      options={[
        { value: "", label: "All" },
        { value: "answered", label: "Answered" },
        { value: "unanswered", label: "Unanswered" }
      ]}
      isClearable={true}
      value={answered ? { value: answered.value, label: answered.label } : { value: "", label: "All" }}
      onChange={(e: any) => onFilterChange("answered", e || { value: "", label: "All" })}
    />
  );
  return (
    <div className={"kt-margin-b-20-tablet-and-mobile " + (additionalSizeClasses ? additionalSizeClasses : "col-md-2")}>
      {noLabel ? (
        select
      ) : (
        <div className="kt-form__group kt-form__group--inline">
          <div className="kt-form__label">
            <label>Answered</label>
          </div>
          <div className="kt-form__control">{select}</div>
        </div>
      )}
    </div>
  );
};
