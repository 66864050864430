import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { BSON } from "realm-web";
import { Link } from "react-router-dom";
import { LocalPackagingStock } from "./CustomTypes";
import CompanyWidget from "../common/CompanyWidget";
import { DataContext } from "../../context/dataContext";
import baseUtils from "../../utils/baseUtils";
import dateUtils from "../../utils/dateUtils";
import { PackagingsDocument } from "../../model/packagings.types";
import { PackagingStockDocument } from "../../model/packagingStock.types";
import accessUtils, { DELETELOCATIONS } from "../../utils/accessUtils";
import toastUtils from "../../utils/toastUtils";
import dbService, { PACKAGINGSTOCK } from "../../services/dbService";
import BatchFilesModal from "../common/BatchFilesModal";
import EditPackagingBatchModal from "./modals/EditPackagingBatchModal";
import packagingUtils from "../../utils/packagingUtils";
import PackagingStockStatistics from "../stock/Packaging/PackagingsStockStatisitcs";

interface PackagingLocalStockProps {
  localStock: LocalPackagingStock;
  packaging: PackagingsDocument;
  context: React.ContextType<typeof DataContext>;
  showDisabledStock: boolean;
  disableStockStatistics?: true;
}

interface PackagingLocalStockState {
  stock: number;
  ordered: number;
  reserved: number;
  available: number;
}

class PackagingLocalStock extends PureComponent<PackagingLocalStockProps, PackagingLocalStockState> {
  constructor(props: PackagingLocalStockProps) {
    super(props);
    this.state = { stock: 0, ordered: 0, reserved: 0, available: 0 };
  }

  componentDidMount() {
    this.setState({
      ...packagingUtils.calculateStockValues(this.props.packaging, this.props.context, this.props.localStock)
    });
  }

  componentDidUpdate(prevProps: Readonly<PackagingLocalStockProps>) {
    if (prevProps !== this.props)
      this.setState({
        ...packagingUtils.calculateStockValues(this.props.packaging, this.props.context, this.props.localStock)
      });
  }

  /**
   * Resolves the supplier referenced by the given ID or key.
   * @param supplier: ID of the supplier or key for other things like own stock
   * @returns { SuppliersDocument | string } The supplier object if available or display name
   */
  resolveSupplier = (supplier: BSON.ObjectId | "accumulatedstock" | "custom" | "customer" | "ownstock") => {
    const { context } = this.props;
    if (supplier === "accumulatedstock") return "Accumulated Stock";
    if (supplier === "custom") return "Custom";
    if (supplier === "customer") return "Customer";
    if (supplier === "ownstock") return "Stock";
    const sup = context.suppliers.find(s => s._id.toString() === supplier.toString());
    if (sup) return sup;
    return "Unknown";
  };

  render() {
    const { packaging, context, localStock, showDisabledStock, disableStockStatistics } = this.props;
    const { stock, ordered, reserved, available } = this.state;
    let stockList = _.cloneDeep(localStock);
    if (!showDisabledStock) stockList.stock = stockList.stock.filter(ls => !ls.disabled);
    return (
      <>
        {!disableStockStatistics && (
          <PackagingStockStatistics
            stock={stock}
            ordered={ordered}
            reserved={reserved}
            available={available}
            outerClassName={"row mb-5"}
            innerClassName={"col-12 col-sm-6 col-md-3"}
          />
        )}
        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
          <Table>
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Amount</th>
                <th style={{ width: "20%" }}>Supplier</th>
                <th style={{ width: "10%" }}>Batch</th>
                <th style={{ width: "10%" }}>Price</th>
                <th style={{ width: "10%" }}>Since</th>
                <th style={{ width: "10%" }}>Expiry</th>
                <th style={{ width: "18%" }}>
                  Allocated
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="allocatedInfo">
                        This value indicates the amount that was already allocated for <br />
                        production via the "Reserve Packaging" dialog on the order <br />
                        page, i.e. the amount is not yet <b>booked out</b> but also may not be available.
                      </Tooltip>
                    }
                  >
                    <i className="fa fa-info-circle ml-2 text-muted" />
                  </OverlayTrigger>
                </th>
                <th style={{ width: "5%" }}>Files</th>
                <th style={{ width: "7%" }} />
              </tr>
            </thead>
            <tbody>
              {stockList.stock.length > 0 ? (
                stockList.stock.map(b => {
                  const supplier = this.resolveSupplier(b.supplier);
                  const allocatedStock = packagingUtils.calculateAllocatedStock(packaging._id, b._id, context.orders);
                  const totalAllocated = allocatedStock.reduce((sum, s) => sum + s.allocated, 0);
                  return (
                    <tr key={b._id.toString()}>
                      <td className="align-middle font-weight-bold" style={{ opacity: b.disabled ? "0.4" : "1" }}>
                        <div className={b.amount < 0 ? "text-danger" : "text-success"}>{b.amount} pcs.</div>
                        {totalAllocated > 0 && (
                          <div className="text-muted font-weight-normal">
                            {b.amount - totalAllocated} pcs.
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="freeInfo">
                                  Total available amount (1st column) minus the already allocated amount (7th column)
                                </Tooltip>
                              }
                            >
                              <i className="fa fa-info-circle ml-2 text-muted" />
                            </OverlayTrigger>
                          </div>
                        )}
                      </td>
                      <td className="align-middle" style={{ opacity: b.disabled ? "0.4" : "1" }}>
                        {typeof supplier === "string" ? supplier : <CompanyWidget company={supplier} type="supplier" />}
                      </td>
                      <td className="align-middle" style={{ opacity: b.disabled ? "0.4" : "1" }}>
                        {b.lot.trim()}
                        {b.note && (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="note">
                                <b>Note:</b> {b.note}
                              </Tooltip>
                            }
                          >
                            <i className="fa fa-info-circle ml-2 text-info" style={{ fontSize: "1.1rem" }} />
                          </OverlayTrigger>
                        )}
                      </td>
                      <td className="align-middle" style={{ opacity: b.disabled ? "0.4" : "1" }}>
                        {baseUtils.formatEuro(b.price)}
                      </td>
                      <td className="align-middle" style={{ opacity: b.disabled ? "0.4" : "1" }}>
                        {baseUtils.formatDate(b.stocked)}
                        <br />
                        <span className="text-muted" style={{ fontSize: "0.9rem" }}>
                          {dateUtils.generateTimeDiffString(b.stocked)}
                        </span>
                      </td>
                      <td className="align-middle" style={{ opacity: b.disabled ? "0.4" : "1" }}>
                        {b.expiry ? baseUtils.formatDate(b.expiry) : "no expiration"}
                        <br />
                        <span className="text-muted" style={{ fontSize: "0.9rem" }}>
                          {b.expiry ? dateUtils.generateTimeDiffString(b.expiry) : "-"}
                        </span>
                      </td>
                      <td className="align-middle" style={{ opacity: b.disabled ? "0.4" : "1" }}>
                        {allocatedStock.length > 0
                          ? allocatedStock.map(aStock => (
                              <div>
                                <div key={aStock.order._id.toString()} className="kt-user-card-v2 kt-font-sm">
                                  <div className="kt-user-card-v2__details">
                                    <Link
                                      to={"/order/" + aStock.order._id.toString()}
                                      className="kt-user-card-v2__name kt-link mr-2 d-inline font-weight-light"
                                    >
                                      {"AT-" + aStock.order.identifier}:
                                    </Link>
                                    <span className="kt-user-card-v2__name d-inline font-weight-bold">
                                      {aStock.allocated} pcs.
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))
                          : "-"}
                      </td>
                      <td className="align-middle" style={{ opacity: b.disabled ? "0.4" : "1" }}>
                        <BatchFilesModal lot={b.lot} files={b.files} />
                      </td>
                      <td className="align-middle text-right">
                        <div className="btn-group">
                          <EditPackagingBatchModal
                            packaging={packaging}
                            stock={b}
                            context={context}
                            location={localStock.manufacturer._id}
                            create={false}
                          />
                          <DisablePackagingBatchModal batch={b} context={context} />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={9}>
                    <img
                      src={process.env.PUBLIC_URL + "/media/img/no_results.jpg"}
                      style={{
                        display: "block",
                        margin: "0 auto",
                        height: "500px"
                      }}
                      alt="No results"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <div className="text-right mb-3 mr-2">
          <EditPackagingBatchModal
            packaging={packaging}
            location={localStock.manufacturer._id}
            context={context}
            create={true}
          />
        </div>
      </>
    );
  }
}

interface DisableBatchModalProps {
  batch: PackagingStockDocument;
  context: React.ContextType<typeof DataContext>;
}

interface DisableBatchModalState {
  saving: boolean;
  show: boolean;
}

class DisablePackagingBatchModal extends PureComponent<DisableBatchModalProps, DisableBatchModalState> {
  constructor(props: DisableBatchModalProps) {
    super(props);
    this.state = { show: false, saving: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => !this.state.saving && this.setState({ show: false });

  /**
   * Handles the deletion of a batch.
   */
  handleDisableBatch = async () => {
    const { batch, context } = this.props;
    // set disabled flag
    const { updateDocumentInContext } = context;
    this.setState({ saving: true });
    const res = await dbService.updateDocument(PACKAGINGSTOCK, batch._id, { disabled: !batch.disabled, amount: 0 });
    await toastUtils.databaseOperationToast(
      !!res && res.modifiedCount > 0,
      `Batch ${batch.lot} successfully disabled`,
      `Disabling batch ${batch.lot} failed`,
      () => {
        updateDocumentInContext(PACKAGINGSTOCK, batch._id);
        this.setState({ saving: false, show: false });
      }
    );
    this.setState({ saving: false });
  };

  render() {
    const { batch } = this.props;
    const { show, saving } = this.state;
    const canDelete = accessUtils.canDeleteData(DELETELOCATIONS.PACKAGINGSTOCK);

    return (
      <>
        <button
          className={"btn btn-secondary btn-sm" + (canDelete ? "" : " disabled")}
          onClick={canDelete ? this.handleShow : undefined}
          disabled={!canDelete}
        >
          <i className={"fas pr-0 " + (batch.disabled ? "fa-undo" : "fa-times")} />
        </button>
        <Modal show={show} onHide={this.handleHide} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {batch.disabled ? "Enable Batch" : "Disable batch"} <em>{batch.lot}</em>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-warning" role="alert">
              <div className="alert-icon">
                <i className="flaticon-warning" />
              </div>
              <div className="alert-text">
                Do you really want to {batch.disabled ? "enable" : "disable"} batch{" "}
                <em>
                  <b>{batch.lot}</b>
                </em>
                ?
                <br />
                <br />
                {!batch.disabled && (
                  <>
                    This action will clear the stock and mark it as disabled.
                    <br />
                    <br />
                    This can be reverted as long as you have not clicked "Save changes" yet.
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={"btn btn-secondary " + (saving && "disabled")}
              disabled={saving}
              onClick={this.handleHide}
            >
              Close
            </button>
            <button
              className={"btn btn-secondary " + (saving && "disabled")}
              disabled={saving}
              onClick={this.handleDisableBatch}
            >
              {saving && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              {batch.disabled ? "Enable" : "Disable"} batch
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default PackagingLocalStock;
