import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { toAbsoluteUrl } from "../_metronic";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: "de",
    preload: ["de", "en"],
    supportedLngs: ["de", "en"],
    ns: [
      "common",
      "datasheet",
      "deliveryNote",
      "offer",
      "order",
      "opos",
      "invoice",
      "template",
      "packaging",
      "pdf",
      "warehouse"
    ],
    backend: {
      loadPath: toAbsoluteUrl("/locales/{{lng}}/{{ns}}.json")
    },
    detection: {
      lookupLocalStorage: "i18nextLng"
    },
    debug: process.env.NODE_ENV !== "production"
  });

export default i18n;
