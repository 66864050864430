import { BSON } from "realm-web";
import dbService, { MANUFACTURERS } from "../dbService";
import { Capabilities, ProductionLine } from "../../model/manufacturers.types";

/**
 * Add the given production line to the referenced manufacturer.
 * @param _id: ID of the manufacturer
 * @param productionLine: Production line that should be added
 * @returns { Realm.Services.MongoDB.UpdateResult<any> } Result of the query
 */
async function addProductionLine(_id: BSON.ObjectId, productionLine: ProductionLine) {
  return dbService
    .getDb()
    ?.collection(MANUFACTURERS)
    .updateOne({ _id }, { $push: { productionLines: productionLine } });
}

/**
 * Deletes the referenced production line. The manufacturer that contains the line is found without needing to be referenced.
 * @param _id: ID of the production line
 * @returns { Realm.Services.MongoDB.UpdateResult<any> } Result of the query
 */
async function deleteProductionLine(_id: BSON.ObjectId) {
  return dbService
    .getDb()
    ?.collection(MANUFACTURERS)
    .updateOne({ "productionLines._id": _id }, { $pull: { productionLines: { _id } } });
}

/**
 * Updates the name and the image of the referenced production line.
 * @param _id: ID of the production line
 * @param name: Name of the production line
 * @param image: Image of the production line
 * @param type: Type of the production line
 * @param capacity: The capacity of the production line
 * @returns { Realm.Services.MongoDB.UpdateResult<any> } Result of the query
 */
async function editProductionLine(_id: BSON.ObjectId, name: string, image: string, type: string, capacity: number) {
  return dbService
    .getDb()
    ?.collection(MANUFACTURERS)
    .updateOne(
      { "productionLines._id": _id },
      {
        $set: {
          "productionLines.$.name": name,
          "productionLines.$.image": image,
          "productionLines.$.type": type,
          "productionLines.$.capacity": capacity,
          "productionLines.$.lastUpdate": new Date()
        }
      }
    );
}

/**
 * Updates the capabilities of a manufacturer.
 * @param _id: ID of the manufacturer
 * @param capabilities: Capabilities that should be set
 * @returns { Realm.Services.MongoDB.UpdateResult<any> } Result of the query
 */
async function updateCapabilities(_id: BSON.ObjectId, capabilities: Capabilities) {
  return dbService
    .getDb()
    ?.collection(MANUFACTURERS)
    .updateOne({ _id }, { $set: capabilities });
}

/**
 * Updates the employees of a manufacturer.
 * @param _id: ID of the manufacturer
 * @param employees: List of employees
 * @returns { Realm.Services.MongoDB.UpdateResult<any> } Result of the query
 */
async function updateEmployees(_id: BSON.ObjectId, employees: Array<BSON.ObjectId>) {
  return dbService
    .getDb()
    ?.collection(MANUFACTURERS)
    .updateOne({ _id }, { $set: { employees } });
}

export default {
  addProductionLine,
  deleteProductionLine,
  editProductionLine,
  updateCapabilities,
  updateEmployees
};
