import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Nav, Tab } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import CompanyWidget from "../common/CompanyWidget";
import { DataContext } from "../../context/dataContext";
import { UserdataDocument } from "../../model/userdata.types";
import dbService, { USERDATA } from "../../services/dbService";
import userService from "../../services/userService";
import { ROLES } from "../../utils/userdataUtils";
import UserNotifications from "./UserNotifications";
import baseUtils from "../../utils/baseUtils";

interface UserParams {
  id: string;
}

interface UserProps extends RouteComponentProps<UserParams, {}, {}> {
  context: React.ContextType<typeof DataContext>;
}

interface UserState {
  user?: UserdataDocument;
  saving: boolean;
  activeTab: string;
}

class User extends PureComponent<UserProps, UserState> {
  _userId: string | undefined;

  constructor(props: UserProps) {
    super(props);
    this._userId = props.match.params.id;
    this.state = {
      user: _.cloneDeep(baseUtils.getDocFromCollection(props.context.userdata, this._userId)),
      saving: false,
      activeTab: "settings"
    };
  }

  componentDidUpdate = async (prevProps: Readonly<UserProps>, prevState: Readonly<UserState>, snapshot?: any) => {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this._userId = this.props.match.params.id;
      this.setState({
        user: _.cloneDeep(baseUtils.getDocFromCollection(this.props.context.userdata, this._userId)),
        activeTab: "settings"
      });
    }

    if (this._userId && prevProps !== this.props) {
      const { userdata } = this.props.context;
      const user = baseUtils.getDocFromCollection(userdata, this._userId);
      if (user && !_.isEqual(user, prevState.user)) {
        this.setState({
          user: _.cloneDeep(baseUtils.getDocFromCollection(userdata, this._userId))
        });
      }
    }
  };

  /**
   * Handles the change of a flat property for the userdata document.
   * @param property: Indicates the property that is changed
   * @param value: New value for the property
   */
  handleChange = (property: "title" | "prename" | "surname" | "position", value: string) => {
    const user = _.cloneDeep(this.state.user);
    if (!user) return;
    user[property] = value;
    this.setState({ user });
  };

  /**
   * Handles the change of roles for the userdata document.
   * @param roles: New role list
   */
  handleRoleChange = (roles: Array<string>) => {
    const user = _.cloneDeep(this.state.user);
    if (!user) return;
    user.role = roles;
    this.setState({ user });
  };

  /**
   * Handles the change of an email address or a telephone number of the userdata document.
   * @param list: Indicates whether an email or a telephone number was changed
   * @param index: Determines which element in the list was changed
   * @param value: New value for the entry
   */
  handleListChange = (list: "mail" | "phone", index: number, value: string) => {
    const user = _.cloneDeep(this.state.user);
    if (!user) return;
    if (list === "mail") {
      user.email[index] = value;
    } else if (list === "phone") {
      user.telephone[index] = value;
    }
    this.setState({ user });
  };

  /**
   * Handles adding a new, empty, entry to the email or telephone numbers list.
   * @param list: Indicates which list should be extended
   */
  handleListAdd = (list: "mail" | "phone") => {
    const user = _.cloneDeep(this.state.user);
    if (!user) return;
    if (list === "mail") {
      user.email.push("");
    } else if (list === "phone") {
      user.telephone.push("");
    }
    this.setState({ user });
  };

  /**
   * Handles deleting and item from the email of telephone numbers list of the userdata document.
   * @param list: Indicates which list should be altered
   * @param index: Index that should be deleted
   */
  handleListDelete = (list: "mail" | "phone", index: number) => {
    const user = _.cloneDeep(this.state.user);
    if (!user) return;
    if (list === "mail") {
      user.email.splice(index, 1);
    } else if (list === "phone") {
      user.telephone.splice(index, 1);
    }
    this.setState({ user });
  };

  /**
   * Reset the userdata document to the version that is currently inside context.
   */
  handleReset = () => {
    const { userdata } = this.props.context;
    this.setState({ user: _.cloneDeep(userdata.find(u => u._id.toString() === this._userId)) });
  };

  /**
   * Handles saving of the userdata document and updates the context the reflect the changes.
   */
  handleSaveSettings = async () => {
    const { user } = this.state;
    const { updateDocumentInContext } = this.props.context;
    if (!user) return;
    this.setState({ saving: true });
    const res = await dbService.replaceDocument(USERDATA, user._id, user);
    if (res && res.modifiedCount) {
      toast.success(
        <b>
          <i className="fa fa-check mx-2" />
          User updated successfully
        </b>
      );
      await updateDocumentInContext(USERDATA, user._id);
    } else {
      toast.error(
        <b>
          <i className="fa fa-exclamation mx-2" />
          Error updating user
        </b>
      );
    }
    this.setState({ saving: false });
  };

  render() {
    const { history, context } = this.props;
    const { user, saving, activeTab } = this.state;
    const { companies } = context;
    if (!user || user.role.some(r => [ROLES.SYSTEM, ROLES.API].includes(r))) {
      history.replace("/userdata");
    } else {
      const company = user.company_id
        ? user.company_id === "internal"
          ? "internal"
          : companies.find(c => c._id.toString() === user.company_id.toString())
        : null;
      // Only admins can alter internal userdata documents and people can edit their own data
      const canEdit =
        user._id.toString() === userService.getUserId()?.toString() ||
        user.company_id !== "internal" ||
        userService.isAuthorizedForAction(ROLES.ADMIN);
      return (
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <div className="kt-widget kt-widget--user-profile-3">
                <div className="kt-widget__top">
                  <div className="kt-widget__media">
                    {user.img_url ? (
                      <div className="kt-badge kt-badge--xl ">
                        <img src={user.img_url} style={{ height: "60px", width: "60px", borderRadius: "50%" }} />
                      </div>
                    ) : (
                      <div className="kt-badge kt-badge--xl kt-badge--primary">
                        {user.prename ? user.prename[0].toUpperCase() : ""}
                      </div>
                    )}
                  </div>
                  <div className="kt-widget__content">
                    <div className="kt-widget__head">
                      <div className="kt-widget__user">
                        <a href="#" className="kt-widget__username">
                          {user.prename + " " + user.surname}
                        </a>
                        <span className="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-success">
                          {user.company_id === "internal" ? "Employee" : "Customer"}
                        </span>
                      </div>
                    </div>
                    <div className="kt-widget__subhead">
                      <a href={"mailto:" + user.email[0]}>
                        <i className="flaticon2-mail" />
                        {user.email[0]}
                      </a>
                      {user.telephone[0] && (
                        <a href={"tel:" + user.telephone[0]}>
                          <i className="flaticon2-phone pr-2" />
                          {user.telephone[0]}
                        </a>
                      )}
                    </div>
                    <div className="kt-user-card-v2 inline-flex mr-3 mt-1">
                      <div className="kt-user-card-v2__pic">
                        {company ? <CompanyWidget company={company} /> : "No company linked"}
                      </div>
                    </div>
                    <div className="kt-user-card-v2 inline-flex mt-1">
                      <div className="kt-user-card-v2__details" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">
                      {user.company_id === "internal" ? "Employee" : "Customer"}
                    </h3>
                  </div>
                </div>
                <div className="kt-form kt-form--label-right">
                  <div className="kt-portlet__body">
                    <div className="form-group form-group-xs row">
                      <label className="col-4 col-form-label">Name:</label>
                      <div className="col-8">
                        <span className="form-control-plaintext kt-font-bolder">
                          {user.prename + " " + user.surname}
                        </span>
                      </div>
                    </div>
                    <div className="form-group form-group-xs row">
                      <label className="col-4 col-form-label">Email:</label>
                      <div className="col-8">
                        <span className="form-control-plaintext kt-font-bolder">{user.email[0]}</span>
                      </div>
                    </div>
                    <div className="form-group form-group-xs row">
                      <label className="col-4 col-form-label">Telephone:</label>
                      <div className="col-8">
                        <span className="form-control-plaintext kt-font-bolder">{user.telephone[0]}</span>
                      </div>
                    </div>
                    <div className="form-group form-group-xs row">
                      <label className="col-4 col-form-label">Company:</label>
                      <div className="col-8">
                        <span className="form-control-plaintext kt-font-bolder">
                          {company ? <CompanyWidget company={company} /> : "No company linked"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <Tab.Container id="userTabs" activeKey={activeTab} onSelect={e => e && this.setState({ activeTab: e })}>
                <div className="kt-portlet kt-portlet--tabs">
                  <div className="kt-portlet__head">
                    <div className="kt-portlet__head-toolbar">
                      <ul
                        className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <Nav.Link role="tab" id="settings" eventKey="settings" active={activeTab === "settings"}>
                            <i className="flaticon2-gear" /> Settings
                          </Nav.Link>
                        </li>
                        <li className="nav-item">
                          <Nav.Link
                            role="tab"
                            id="notifications"
                            eventKey="notifications"
                            active={activeTab === "notifications"}
                          >
                            <i className="flaticon2-notification" /> Notifications
                          </Nav.Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="kt-portlet__body">
                    <Tab.Content>
                      <Tab.Pane key="settings" eventKey="settings" transition={false}>
                        <div className="kt-form__body">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="row">
                                <div className="col-3">
                                  <h3 className="kt-section__title kt-section__title-md">Mandatory data</h3>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-3 col-form-label">Title</label>
                                <div className="col-9 col-xl-6">
                                  <select
                                    className={"form-control" + (canEdit ? "" : " disabled")}
                                    disabled={!canEdit}
                                    onChange={e => this.handleChange("title", e.target.value)}
                                    value={user.title}
                                  >
                                    <option value="">No title</option>
                                    <option value="Mr">Mr</option>
                                    <option value="Mrs">Mrs</option>
                                  </select>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-3 col-form-label">Prename</label>
                                <div className="col-9 col-xl-6">
                                  <input
                                    className={"form-control" + (canEdit ? "" : " disabled")}
                                    disabled={!canEdit}
                                    type="text"
                                    onChange={e => this.handleChange("prename", e.target.value)}
                                    value={user.prename}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-3 col-form-label">Surname</label>
                                <div className="col-9 col-xl-6">
                                  <input
                                    className={"form-control" + (canEdit ? "" : " disabled")}
                                    disabled={!canEdit}
                                    type="text"
                                    onChange={e => this.handleChange("surname", e.target.value)}
                                    value={user.surname}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-3 col-form-label">Roles</label>
                                <div className="col-9 col-xl-6">
                                  <Dropdown
                                    placeholder="Roles"
                                    fluid
                                    multiple
                                    selection
                                    disabled={!userService.isAuthorizedForAction(ROLES.ADMIN)}
                                    options={Object.values(ROLES)
                                      .filter(role => ![ROLES.SYSTEM, ROLES.API].includes(role))
                                      .map(r => {
                                        return { key: r, text: r, value: r };
                                      })}
                                    value={user.role}
                                    onChange={(e, { value }) => {
                                      if (value && Array.isArray(value))
                                        this.handleRoleChange(value.map(v => v.toString()));
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-3 col-form-label">Email</label>
                                <div className="col-9 col-xl-6">
                                  {user.email.map((m, key) => {
                                    return (
                                      <div className="row" key={key}>
                                        <div className="col-10">
                                          <input
                                            className={"form-control mb-2" + (canEdit ? "" : " disabled")}
                                            disabled={!canEdit}
                                            type="text"
                                            onChange={e => this.handleListChange("mail", key, e.target.value)}
                                            value={m}
                                          />
                                        </div>
                                        <div className="col-2">
                                          <button
                                            disabled={key === 0 || !canEdit}
                                            className={
                                              "btn btn-danger float-right" + (key === 0 || !canEdit ? " disabled" : "")
                                            }
                                            onClick={() => this.handleListDelete("mail", key)}
                                          >
                                            <i className="fa fa-trash pr-0" />
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                  {canEdit && (
                                    <div className="row">
                                      <div className="col-10" />
                                      <div className="col-2">
                                        <button
                                          className="btn btn-success float-right"
                                          onClick={() => this.handleListAdd("mail")}
                                        >
                                          <i className="fa fa-plus pr-0" />
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="kt-section__body">
                              <div className="row">
                                <div className="col-3">
                                  <h3 className="kt-section__title kt-section__title-md">Optional data</h3>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-3 col-form-label">Position</label>
                                <div className="col-9 col-xl-6">
                                  <input
                                    className={"form-control" + (canEdit ? "" : " disabled")}
                                    disabled={!canEdit}
                                    type="text"
                                    onChange={e => this.handleChange("position", e.target.value)}
                                    value={user.position}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-3 col-form-label">Telephone</label>
                                <div className="col-9 col-xl-6">
                                  {user.telephone.map((t, key) => {
                                    return (
                                      <div className="row" key={key}>
                                        <div className="col-10">
                                          <input
                                            className={"form-control mb-2" + (canEdit ? "" : " disabled")}
                                            disabled={!canEdit}
                                            type="text"
                                            onChange={e => this.handleListChange("phone", key, e.target.value)}
                                            value={t}
                                          />
                                        </div>
                                        <div className="col-2">
                                          <button
                                            className={"btn btn-danger float-right" + (canEdit ? "" : " disabled")}
                                            disabled={!canEdit}
                                            onClick={() => this.handleListDelete("phone", key)}
                                          >
                                            <i className="fa fa-trash pr-0" />
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                  {canEdit && (
                                    <div className="row">
                                      <div className="col-10" />
                                      <div className="col-2">
                                        <button
                                          className="btn btn-success float-right"
                                          onClick={() => this.handleListAdd("phone")}
                                        >
                                          <i className="fa fa-plus pr-0" />
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {canEdit && (
                          <div className="kt-portlet__foot">
                            <div className="float-right">
                              <button
                                disabled={saving}
                                className={"btn btn-secondary mr-2" + (saving ? " disabled" : "")}
                                onClick={this.handleReset}
                              >
                                Reset
                              </button>
                              <button
                                disabled={saving}
                                className={"btn btn-success" + (saving ? " disabled" : "")}
                                onClick={this.handleSaveSettings}
                              >
                                {saving ? "Saving..." : "Save"}
                              </button>
                            </div>
                          </div>
                        )}
                      </Tab.Pane>
                      <Tab.Pane key="notifications" eventKey="notifications" transition={false}>
                        <UserNotifications user={user} />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(User);
