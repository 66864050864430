import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import dbService, { UpdateAction, EMORDERS } from "../../../services/dbService";
import userService from "../../../services/userService";
import toastUtils from "../../../utils/toastUtils";
import { ExtendedEMOrder } from "../../../model/customTypes.types";
import { EM_ACCEPTED, EM_CANCELED, EM_CHECKED, EM_OPEN } from "../ExternalManufacturerHelper";
import {
  EmOrderTimelineEntry,
  ExternalManufacturerOrdersDocument
} from "../../../model/externalManufacturerOrders.types";

interface CancelEMOrderModalProps {
  order: ExtendedEMOrder | ExternalManufacturerOrdersDocument;
  iconBtn?: boolean;
}

interface CancelEMOrderModalState {
  show: boolean;
}

class CancelEMOrderModal extends PureComponent<CancelEMOrderModalProps, CancelEMOrderModalState> {
  constructor(props: CancelEMOrderModalProps) {
    super(props);
    this.state = { show: false };
  }

  handleShow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    this.setState({ show: true });
  };
  handleHide = () => this.setState({ show: false });

  /**
   * Handles cancellation of an order.
   */
  handleCancelOrder = async () => {
    const { order } = this.props;
    // Build timeline entries
    const timeline: EmOrderTimelineEntry = {
      _id: new BSON.ObjectId(),
      type: EM_CANCELED,
      date: new Date(),
      person: userService.getUserId()
    };
    const actions: Array<UpdateAction> = [
      {
        collection: EMORDERS,
        filter: { _id: order._id },
        update: { state: EM_CANCELED },
        push: { timeline }
      }
    ];
    const success = await dbService.updatesAsTransaction(actions);
    await toastUtils.databaseOperationToast(
      success,
      `Commodity ${[EM_OPEN, EM_CHECKED].includes(order.state) ? "request" : "order"} cancelled successfully`,
      `Error cancelling ${[EM_OPEN, EM_CHECKED].includes(order.state) ? "request" : "order"}`,
      this.handleHide
    );
  };

  render() {
    const { order, iconBtn } = this.props;
    const { show } = this.state;
    const disabled = ![EM_OPEN, EM_CHECKED, EM_ACCEPTED].includes(order.state);
    return (
      <>
        {iconBtn ? (
          <button className="btn btn-secondary px-2 py-1" onClick={this.handleShow}>
            <i className="flaticon2-cancel-music p-0" style={{ opacity: 0.3 }} />
          </button>
        ) : (
          <button
            disabled={disabled}
            className={
              "btn btn-sm btn-upper " +
              (order.deliveryDateLimit && order.deliveryDateLimit < new Date() ? "btn-danger" : "btn-secondary")
            }
            onClick={this.handleShow}
          >
            {order.state === EM_CHECKED ? "Decline" : "Cancel"}
          </button>
        )}
        <Modal show={show} onHide={this.handleHide} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {order.state === EM_CHECKED ? "Decline" : "Cancel"} Commodity{" "}
              {[EM_OPEN, EM_CHECKED].includes(order.state) ? "Request REQ" : "Order EMO"}-{order.identifier}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-warning" role="alert">
              <div className="alert-icon">
                <i className="flaticon-warning" />
              </div>
              <div className="alert-text">
                Do you really want to {order.state === EM_CHECKED ? "decline" : "cancel"} commodity{" "}
                {[EM_OPEN, EM_CHECKED].includes(order.state) ? "request REQ" : "order EMO"}-{order.identifier}{" "}
                {order.reference}?
                <br />
                This can not be undone.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            <button className="btn btn-secondary" onClick={this.handleCancelOrder}>
              {order.state === EM_CHECKED ? "Decline" : "Cancel"}{" "}
              {[EM_OPEN, EM_CHECKED].includes(order.state) ? "Request" : "Order"}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CancelEMOrderModal;
