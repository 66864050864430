import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { DataContext } from "../../../context/dataContext";
import { ProductionLine } from "../../../model/manufacturers.types";
import { OrdersDocument } from "../../../model/orders.types";
import CompanyWidget from "../../common/CompanyWidget";
import { OrderWidget } from "../../listings/common/BaseListingComponents";
import dbService, { ORDERS } from "../../../services/dbService";
import dateUtils from "../../../utils/dateUtils";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import { PRODUCTION } from "../../../utils/orderUtils";
import { T_PRODUCTION } from "../../../utils/timelineUtils";
import authenticationService from "../../../services/authenticationService";
import notificationService, { R_ORDERPRODUCTIONSTARTED } from "../../../services/notificationService";

interface StartProductionModalProps {
  show: boolean;
  canMakeActions: boolean;
  order?: OrdersDocument;
  onHide: () => void;
  productionLine: ProductionLine;
}

class StartProductionModal extends Component<StartProductionModalProps, {}> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  /**
   * Removes the given order from the queue of the given production line
   */
  removeOrderFromQueue = async () => {
    const { order, onHide } = this.props;
    if (!(order && order.productionWeek && order.settings.productionMachine)) return;
    let result: any;
    try {
      result = await dbService.callFunction("removePlannedOrder", [order]);
      if (result) {
        toast.success(`Order successfully removed from schedule`);
        this.context.updateDocumentInContext(ORDERS, order._id);
        onHide();
      } else toast.error("Order could not be unscheduled");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    }
  };

  /**
   * Start the given order on the given production line
   */
  startOrderProduction = async () => {
    const { order, onHide } = this.props;
    if (!order) return;
    if (order.state !== "production") {
      // Update order only when the update of the production line was successful
      const resOrd = await dbOrderService.switchState(order._id, PRODUCTION, {
        type: T_PRODUCTION,
        date: new Date(),
        person: authenticationService.getUserDataID().toString()
      });
      if (resOrd) {
        toast.success(
          <b>
            <i className="fa fa-check mx-2" />
            Order started successfully
          </b>
        );
        notificationService.notify(R_ORDERPRODUCTIONSTARTED, order._id);
        this.context.updateDocumentInContext(ORDERS, order._id);
        onHide();
      } else {
        // Inform user when the order update failed so that they can update if themself (click "Start Production")
        toast.error(
          <b>
            <i className="fa fa-exclamation mx-2" />
            Order could not be updated
          </b>
        );
      }
    }
  };

  render() {
    const { canMakeActions, show, order, onHide, productionLine } = this.props;
    return order ? (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Start production of AT-{order.identifier}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-4 text-dark align-self-center h6">Order:</div>
            <div className="col-8 text-dark h5">
              <OrderWidget document={order} prefix={"AT-"} />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-4 text-dark align-self-center h6">Customer:</div>
            <div className="col-8 text-dark">
              <CompanyWidget
                company={this.context.companies.find(c => c._id.toString() === order.createdFor.toString())!}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-4 text-dark align-self-center h6">Target week:</div>
            <div className="col-8 text-dark h5">
              {order.targetDate ? "CW:" + dateUtils.getCW(order.targetDate) : "-"}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-4 text-dark align-self-center h6">Machine:</div>
            <div className="col-8">
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__pic d-none d-xl-block ">
                  <div className="kt-badge kt-badge--xl kt-badge--primary">
                    <i className="far fas fa-pallet" style={{ fontSize: "1.6rem" }} />
                  </div>
                </div>
                <div className="kt-user-card-v2__details">
                  <span className={"kt-user-card-v2__name d-inline-block "}>
                    Machine: <span className="kt-font-bolder">{productionLine.name}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary mr-2" onClick={onHide}>
            Close
          </button>
          <button
            className={"btn btn-secondary mr-2" + (!canMakeActions || !order ? " disabled" : "")}
            onClick={this.removeOrderFromQueue}
            disabled={!canMakeActions || !order}
          >
            Dequeue
          </button>
          <button
            className={"btn btn-success" + (!canMakeActions || !order ? " disabled" : "")}
            onClick={order ? () => this.startOrderProduction() : undefined}
            disabled={!canMakeActions || !order}
          >
            Start production
          </button>
        </Modal.Footer>
      </Modal>
    ) : null;
  }
}

export default StartProductionModal;
