import React, { useCallback, useMemo } from "react";
import { roundToDigits } from "../../utils/baseUtils";
import i18n from "../../translations/i18n";

interface DragAndDropProps {
  file: File | null;
  onSelectFile: (e: React.ChangeEvent<HTMLInputElement> | { target: HTMLInputElement }) => void;
}

const DragAndDrop: React.FC<DragAndDropProps> = ({ file, onSelectFile }) => {
  const uploadRef: React.RefObject<HTMLInputElement> = useMemo(() => React.createRef(), []);
  const dragAndDropRef: React.RefObject<HTMLDivElement> = useMemo(() => React.createRef(), []);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      const files = e.dataTransfer.files;
      if (files && files.length > 0 && uploadRef.current) {
        uploadRef.current.files = files;
        onSelectFile({ target: uploadRef.current });
      }
      e.dataTransfer.clearData();
    },
    [uploadRef]
  );

  const handleHighlight = useCallback(() => {
    if (dragAndDropRef && dragAndDropRef.current) dragAndDropRef.current.classList.add("highlightDragAndDrop");
  }, [dragAndDropRef]);

  const handleDisableHighlight = useCallback(() => {
    if (dragAndDropRef && dragAndDropRef.current) dragAndDropRef.current.classList.remove("highlightDragAndDrop");
  }, [dragAndDropRef]);

  return (
    <div
      className="warehouseFileDiv"
      ref={dragAndDropRef}
      onDrop={handleDrop}
      onClick={uploadRef.current?.click}
      onMouseEnter={handleHighlight}
      onMouseLeave={handleDisableHighlight}
      onDragOver={handleHighlight}
      onDragLeave={handleDisableHighlight}
    >
      <i
        className="fa fa-images"
        style={{
          marginBottom: "10px",
          fontSize: 72,
          opacity: 0.2
        }}
      />
      {file ? (
        <div>
          <p className="text-black m-0">{file.name}</p>
          <small className="">{roundToDigits(file.size / 1000, 1)} KB</small>
        </div>
      ) : (
        <p style={{ fontSize: 14 }} className="font-weight-bolder m-0">
          {i18n.t("warehouse:dragNDropOrBrowse")}
        </p>
      )}
      <input
        type="file"
        ref={uploadRef}
        className="warehouseFileInput"
        accept="*"
        name="file"
        onClick={e => e.stopPropagation()}
        onChange={onSelectFile}
      />
    </div>
  );
};

export default DragAndDrop;
