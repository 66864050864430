import React, { useContext, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import Select from "react-select";
import { CustomOrder } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import userService from "../../../services/userService";
import { T_OWNERCHANGED } from "../../../utils/timelineUtils";
import dbService, { UpdateAction, ORDERS } from "../../../services/dbService";
import Tooltip from "../../common/Tooltip";
import { UserdataDocument } from "../../../model/userdata.types";
import { ROLES } from "../../../utils/userdataUtils";

interface ChangeOwnerModalProps {
  order: CustomOrder;
}

const ChangeOwnerModal: React.FunctionComponent<ChangeOwnerModalProps> = ({ order }) => {
  const context = useContext(DataContext);
  const { userdata } = context;
  const { createdFrom } = order;
  const internalUser = useMemo(
    () =>
      userdata.filter(
        p =>
          p.company_id === "internal" &&
          p._id.toString() !== createdFrom._id.toString() &&
          p.role.some(r =>
            [
              ROLES.SALES,
              ROLES.ADMIN,
              ROLES.PROCUREMENT,
              ROLES.PACKAGINGPROCUREMENT,
              ROLES.FINANCE,
              ROLES.CONTROLLING
            ].includes(r)
          )
      ),
    [userdata]
  );

  const internalUserOptions: Array<{ value: string; label: string; object?: UserdataDocument }> = useMemo(
    () =>
      internalUser.map(p => {
        return {
          value: p._id.toString(),
          label: `${p.prename} ${p.surname} ${p.position.trim() ? `- ${p.position.trim()}` : ""}`,
          object: p
        };
      }),
    [internalUser]
  );
  const canReplace = userService.isAdmin() || userService.getUserId().toString() === createdFrom._id.toString();

  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [owner, setOwner] = useState<UserdataDocument | null>(null);

  const handleShow = () => {
    setOwner(null);
    setShow(true);
  };
  const handleHide = () => setShow(false);

  const handleChangeOwner = (e: any) => {
    setOwner(e.object);
  };

  const handleSave = async () => {
    if (!owner) return;
    setSaving(true);
    try {
      const userId = new BSON.ObjectId(userService.getUserData()._id);
      const timelineEntry = {
        id: new BSON.ObjectId(),
        type: T_OWNERCHANGED,
        date: new Date(),
        person: userId,
        owner: {
          old: { _id: createdFrom._id, name: `${createdFrom.prename} ${createdFrom.surname}` },
          new: { _id: owner._id, name: `${owner.prename} ${owner.surname}` }
        }
      };
      const actions: Array<UpdateAction> = [
        {
          collection: ORDERS,
          filter: { _id: order._id },
          update: { createdFrom: owner._id },
          push: { timeline: { $each: [timelineEntry] } }
        }
      ];
      const res = await dbService.updatesAsTransaction(actions);
      if (res) {
        toast.success(
          <b>
            <i className="fa fa-check mr-2" />
            Owner changed successfully
          </b>
        );
        context.updateDocumentInContext(ORDERS, order._id);
        handleHide();
      } else {
        toast.error(
          <b>
            <i className="fa fa-exclamation mr-2" />
            Changing owner failed
          </b>
        );
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Tooltip
        show={canReplace ? false : undefined}
        tooltipText="Only the owner of the order itself or an admin can change the ownership"
      >
        <i
          className={"fa fa-cog ml-2 " + (canReplace && "pointer")}
          style={{ height: "13px", width: "13px", opacity: canReplace ? 1 : 0.7 }}
          onClick={canReplace ? handleShow : undefined}
        />
      </Tooltip>
      <Modal show={show} onHide={handleHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Change Owner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-xl-12">
                <div className="kt-section kt-section--first mb-3">
                  <div className="kt-section__body">
                    <br />
                    <div className="mx-auto" style={{ maxWidth: "300px" }}>
                      <label className="kt-font-bold kt-font-dark mx-auto">New Owner</label>
                      <Select
                        className="select-default"
                        onChange={(e: any) => handleChangeOwner(e)}
                        value={
                          owner
                            ? {
                                value: owner._id.toString(),
                                label: `${owner.prename} ${owner.surname} - ${owner.position}`
                              }
                            : { value: "", label: "-" }
                        }
                        options={internalUserOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleHide} disabled={saving}>
            Close
          </button>
          <button
            className={"btn btn-success " + (!owner ? "disabled" : "")}
            onClick={!owner ? undefined : handleSave}
            disabled={!owner || saving}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangeOwnerModal;
