import _ from "lodash";
import React, { PureComponent } from "react";
import Chart from "react-apexcharts";
import { DataContext } from "../../../context/dataContext";
import orderUtils, { DECLINED } from "../../../utils/orderUtils";
import { ROLES } from "../../../utils/userdataUtils";
import { T_FULFILLMENT } from "../../../utils/timelineUtils";
import { UserdataDocument } from "../../../model/userdata.types";
import baseUtils from "../../../utils/baseUtils";
import contractUtils from "../../../utils/contractUtils";

interface ACMarginsVsCalculatedMarginsGraphProps {
  context: React.ContextType<typeof DataContext>;
}

interface ACMarginsVsCalculatedMarginsGraphState {
  series: Array<{ name: string; data: Array<number> | Array<{ x: string; y: number; fillColor: string }> }>;
  options: any;
}

class ACMarginsVsCalculatedMarginsGraph extends PureComponent<
  ACMarginsVsCalculatedMarginsGraphProps,
  ACMarginsVsCalculatedMarginsGraphState
> {
  sales: Array<UserdataDocument>;
  // Real margin data calculated by Burak
  data: Array<any> = [
    [20646],
    14676.02,
    [20354],
    10866.96,
    [20775],
    13300.91,
    [20654],
    120.45,
    [20846],
    3715.88,
    [20721],
    1207.66,
    [20774],
    1070.72,
    [20728],
    4733.0,
    [20797],
    4483.27,
    [20744],
    -1344.65,
    [20796],
    3247.42,
    [20658],
    9806.08,
    [20569],
    1958.18,
    [20410],
    4387.2,
    [20647],
    11576.52,
    [20847],
    1543.24,
    [20802],
    871.35,
    [21016],
    4552.42,
    [20823],
    2901.53,
    [20576],
    1972.22,
    [20669],
    8869.75,
    [20612],
    2980.23,
    [20794],
    2612.55,
    [21080],
    12644.35,
    [20875],
    2031.81,
    [20872],
    1281.8,
    [20801],
    5296.67,
    [20802],
    1238.73,
    [20827],
    5079.11,
    [20697],
    2100.0,
    [20791],
    971.65,
    [20792],
    1021.9,
    [20821],
    4442.4,
    [20729],
    12932.24,
    [20757],
    563.54,
    [20675],
    3184.59,
    [20832],
    1558.46,
    [20829],
    1159.12,
    [20947],
    2725.93,
    [2094],
    1621.12,
    [20610],
    3252.3,
    [20864],
    4232.25,
    [20809],
    1897.6,
    [20778],
    1737.54,
    [20744],
    1194.1,
    [20891],
    2129.76,
    [20769],
    2344.45,
    [20696],
    2782.07,
    [20871],
    1998.4,
    [20641],
    2399.76,
    [20753],
    2665.81,
    [20857],
    3015.49,
    [20908],
    3732.32,
    [20746],
    2501.82,
    [20800],
    2537.78,
    [20693],
    1612.36,
    [20694],
    1454.25,
    [20695],
    1210.63,
    [20826],
    7317.21,
    [20580],
    3257.3,
    [20828],
    776.5,
    [20831],
    639.6,
    [20830],
    972.66,
    [20744],
    5938.11,
    [20643],
    1727.4,
    [20901],
    10020.5,
    [20985],
    333.7,
    [21047],
    2577.08,
    [20854],
    331.02,
    [20867],
    2703.92,
    [20720],
    3041.84,
    [20822],
    755.38,
    [20895],
    3980.62,
    [20894],
    1855.8,
    [20869],
    9475.7,
    [20546],
    1675.62,
    [20983],
    3743.84,
    [20854],
    2774.0,
    [20878],
    2814.15,
    [20991],
    1606.5,
    [20834],
    638.75,
    [20834],
    561.05,
    [20790],
    3198.67,
    [21193],
    20467.0,
    [20979],
    1796.43,
    [21037],
    795.44,
    [21220],
    1730.89,
    [20267],
    5585.91,
    [20985],
    1650.07,
    [20816],
    13921.9,
    [21138],
    12188.51,
    [20938],
    2019.65,
    [21060],
    2803.5,
    [21082],
    2612.7,
    [20944],
    1728.54,
    [20858],
    3651.72,
    [20958],
    1147.32,
    [20518],
    5323.23,
    [20246],
    1683.92,
    [20247],
    1874.68,
    [20248],
    1541.65,
    [21017],
    7479.7,
    [20968],
    20345.99,
    [20953],
    20831.58,
    [20642],
    8003.0,
    [20422],
    5938.04,
    [20771],
    3550.05,
    [20980],
    1231.86,
    [20977],
    1112.15,
    [20952],
    6536.22,
    [20845],
    5685.03,
    [20905],
    125.14,
    [20815],
    9586.5,
    [20669],
    2723.1,
    [20936],
    8343.92,
    [20734],
    8723.04,
    [20987],
    2365.16,
    [21020],
    2150.25,
    [21207],
    4891.04,
    [21150],
    3453.25,
    [20986],
    3177.37,
    [20727],
    5142.93,
    [21079],
    3129.94,
    [20649],
    10920.72,
    [20990],
    2792.05,
    [20902],
    2222.53,
    [21043],
    3620.91,
    [20772],
    2253.65,
    [20845],
    18906.9,
    [21110],
    396.44,
    [20866],
    852.45,
    [20881],
    5444.25,
    [21044],
    1306.49,
    [20989],
    -2524.8,
    [20764],
    2550.68,
    [20949],
    1271.75,
    [21036],
    3412.71,
    [20898],
    1650.72,
    [21119],
    471.68,
    [20884],
    2312.1,
    [20890],
    8338.89,
    [20574],
    5459.95,
    [20948],
    1395.53,
    [20951],
    -12789.18,
    [21040],
    1608.3,
    [20910],
    2004.24,
    [20973],
    1520.32,
    [20900],
    1060.2,
    [21076],
    3558.44,
    [21099],
    1577.54,
    [20972],
    1702.48,
    [20899],
    2014.74,
    [20982],
    1192.15,
    [21048],
    54787.0,
    [20941],
    1441.96,
    [20649],
    -11669.3,
    [20966],
    4462.96,
    [21041],
    2177.34,
    [21108],
    6878.22,
    [20923],
    2739.83,
    [21112],
    3340.9,
    [20299],
    9113.66,
    [21221],
    2234.01,
    [21206],
    1886.05,
    [21175],
    1329.28,
    [21126],
    2490.14,
    [21116],
    3569.6,
    [21106],
    1850.69,
    [21077],
    1124.48,
    [21070],
    603.2,
    [21035],
    3190.76,
    [21007],
    2815.9,
    [20804],
    19258.61,
    [21223],
    2196.4,
    [21013],
    4647.68,
    [20614],
    -6787.37,
    [20401],
    -20157.01,
    [21141],
    7908.78,
    [21043],
    935.94,
    [21009],
    2300.31,
    [20734],
    8331.56,
    [20551],
    3374.44,
    [21085],
    4029.75,
    [21084],
    2820.07,
    [21220],
    1355.0,
    [21154, 21155],
    2889.08,
    [21240],
    1430.61,
    [21129],
    2172.79,
    [20996],
    1798.78,
    [20816],
    7227.57,
    [20792],
    294.5,
    [20912],
    4463.52,
    [21030],
    2130.14,
    [21159],
    16320.48,
    [21011],
    4875.24,
    [21132],
    2149.25,
    [21087],
    3805.65,
    [21088],
    3305.65,
    [21089],
    2383.0,
    [20085],
    6669.2,
    [20881],
    3420.17,
    [21234],
    20150.0,
    [21144],
    12326.95,
    [21146],
    4565.32,
    [20503],
    6514.1,
    [21065],
    1839.06,
    [21158],
    6799.16,
    [21172],
    1991.88,
    [21161],
    7596.88,
    [21090],
    2231.45,
    [20851],
    11062.51,
    [20579],
    11092.2,
    [21028],
    11102.46,
    [20935],
    8304.2,
    [21103],
    2080.8,
    [21170],
    2356.34,
    [21142],
    2134.52,
    [20850],
    8149.66,
    [20852],
    6218.8,
    [20876],
    3029.6,
    [21214],
    2715.0,
    [20935],
    9392.0,
    [21205],
    3200.0,
    [20984],
    1520.0,
    [21121, 21134],
    18787.48,
    [21083],
    2010.87,
    [21176],
    2336.92,
    [21123],
    1995.76,
    [21118],
    1675.66,
    [21225],
    1548.83,
    [20956],
    -5230.0,
    [20879],
    23388.0,
    [20589],
    8698.92,
    [21098],
    1709.39,
    [20575],
    7112.86,
    [20526],
    9179.51,
    [20959],
    35780.88,
    [21177],
    2125.92,
    [21287],
    3200.22,
    [21296],
    4576.64,
    [21258],
    1559.7,
    [21093],
    2502.48,
    [21294],
    2174.06,
    [21243],
    1178.03,
    [21222],
    1957.43,
    [21202],
    2994.22,
    [21178],
    2355.78,
    [21135],
    2195.17,
    [20882],
    -2581.17,
    [21184],
    10807.26,
    [20972],
    628.18,
    [21244],
    7429.6,
    [21133],
    3003.65,
    [21124],
    27364.0,
    [20910],
    1082.88
  ];

  constructor(props: ACMarginsVsCalculatedMarginsGraphProps) {
    super(props);
    this.sales = props.context.userdata.filter(
      u =>
        (u.role.includes(ROLES.SALES) || u.role.includes(ROLES.ADMIN)) &&
        ["Christoph Stachelek", "Jana Papke", "Tayfun Bati", "Pierre Sönnichsen", "Fabian Geisler"].includes(
          u.prename + " " + u.surname
        )
    );
    this.state = {
      series: [],
      options: {
        chart: {
          type: "scatter",
          id: "basic-bar-marginACReal",
          toolbar: {
            show: true
          },
          zoom: { enabled: false }
        },
        xaxis: {
          type: "datetime"
        },
        yaxis: {
          min: -20000,
          max: 20000,
          labels: {
            formatter: (v: string) => baseUtils.formatEuro(+v)
          }
        },
        dataLabels: { enabled: false },
        colors: ["transparent"]
      }
    };
  }

  componentDidMount() {
    this.setState({ series: this.calculateData() });
  }

  componentDidUpdate(
    prevProps: Readonly<ACMarginsVsCalculatedMarginsGraphProps>,
    prevState: Readonly<ACMarginsVsCalculatedMarginsGraphState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.context.orders, this.props.context.orders)) {
      this.setState({ series: this.calculateData() });
    }
  }

  calculateData = () => {
    const { context } = this.props;
    const orders = context.orders.filter(
      o => orderUtils.isOrder(o) && !contractUtils.isContract(o) && DECLINED !== o.state
    );
    const data = [];
    const good = "#3dff36";
    const bad = "#ff0000";
    for (let i = 0; i < this.sales.length; i++) {
      const s = this.sales[i];
      let sData: Array<{ x: string; y: number; fillColor: string }> = [];
      const sOrds = orders.filter(o => o.createdFrom.toString() === s._id.toString());
      for (let j = 0; j < this.data.length; j = j + 2) {
        let x = new Date(0);
        let y = 0;
        for (let k = 0; k < this.data[j].length; k++) {
          const ord = sOrds.find(o => o.identifier === this.data[j][k]);
          if (ord) {
            const fulfillment = ord.timeline.find(t => t.type === T_FULFILLMENT);
            y += ord.fulfillment?.priceInfo?.totalMargin ?? ord.calculations[0].info.totalmargin;
            x = fulfillment ? fulfillment.date : x;
          }
        }
        if (x > new Date("2022-01-01")) {
          const val = this.data[j + 1] - y;
          sData.push({
            x: x.toISOString(),
            y: val,
            fillColor: val >= 0 ? good : bad
          });
        }
      }
      data.push({ name: s.prename + " " + s.surname, data: sData });
    }
    return data;
  };

  render() {
    const { series, options } = this.state;
    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">AC Margin vs Real Margin (2022) by Sales Employee</h3>
          </div>
        </div>
        <div className="kt-portlet__body p-3">
          <Chart series={series} type="scatter" options={options} heigth="700" width="100%" />
        </div>
      </div>
    );
  }
}
export default ACMarginsVsCalculatedMarginsGraph;
