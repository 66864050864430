import React, { PureComponent } from "react";
import { Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import CompanyWidget from "../../common/CompanyWidget";
import { DataContext } from "../../../context/dataContext";
import { CommoditiesDocument } from "../../../model/commodities.types";
import { OrdersDocument } from "../../../model/orders.types";
import dbService, { COMMODITIES } from "../../../services/dbService";
import orderUtils, { ARCHIVE, CREATEINVOICE, DECLINED } from "../../../utils/orderUtils";
import toastUtils from "../../../utils/toastUtils";
import commodityUtils from "../../../utils/commodityUtils";
import accessUtils, { DELETELOCATIONS } from "../../../utils/accessUtils";

interface DisableCommodityModalProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
}

interface DisableCommodityModalState {
  show: boolean;
  saving: boolean;
}

class DisableCommodityModal extends PureComponent<DisableCommodityModalProps, DisableCommodityModalState> {
  constructor(props: DisableCommodityModalProps) {
    super(props);
    this.state = { show: false, saving: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false, saving: false });

  /**
   * Handles disabling a commodity. If the commodity is already disabled it is enabled.
   */
  handleDisableCommodity = async () => {
    const { commodity, context } = this.props;
    this.setState({ saving: true });
    const disabled = !commodity.disabled;
    const res = await dbService.updateDocument(COMMODITIES, commodity._id, { disabled });
    await toastUtils.databaseOperationToast(
      !!res && res.modifiedCount > 0,
      "Commodity " + (disabled ? "disabled" : "enabled") + " successfully",
      "Error " + (disabled ? "disabling" : "enabling") + " commodity",
      () => context.updateDocumentInContext(COMMODITIES, commodity._id)
    );
    this.setState({ show: false, saving: false });
  };

  /**
   * Calculate the used amount of the commodity inside the given order. Usage of services is not needed and thus there
   * is no case for services.
   * @param o: Order whose usage should be calculated.
   * @returns { string } Used amount with correct unit
   */
  calculateUsedAmount = (o: OrdersDocument) => {
    const { commodity } = this.props;
    const price = o.calculations[0].prices.find(p => p._id.toString() === commodity._id.toString());
    if (!price) return "-";
    return commodityUtils.calculateUsage(o, price, commodity.type).unit;
  };

  /**
   * Collect all orders that use the given commodity.
   * @returns { Array<OrdersDocument> } All, active, orders that use the given commodity
   */
  getOrdersWithCommodity = () => {
    const { commodity, context } = this.props;
    const { orders } = context;
    return orders.filter(
      o =>
        ![ARCHIVE, DECLINED, CREATEINVOICE].includes(o.state) &&
        o.calculations[0].prices.some(p => p._id.toString() === commodity._id.toString())
    );
  };

  render() {
    const { commodity, context } = this.props;
    const { saving, show } = this.state;
    const { companies } = context;
    const ordersWithCommodity = this.getOrdersWithCommodity();
    const canDisable = accessUtils.canDeleteData(DELETELOCATIONS.COMMODITY);
    return (
      <>
        <button
          type="button"
          className={"btn btn-secondary btn-sm btn-upper" + (saving || !canDisable ? " disabled" : "")}
          onClick={canDisable ? (commodity.disabled ? this.handleDisableCommodity : this.handleShow) : undefined}
          disabled={saving || !canDisable}
        >
          <i className={commodity.disabled ? "fa fa-plus" : "fas fa-times"} />
          {commodity.disabled ? "Enable" : "Disable"}
        </button>
        <Modal show={show} centered onHide={this.handleHide} size={ordersWithCommodity.length > 0 ? "xl" : "lg"}>
          <Modal.Header closeButton>
            <Modal.Title>Disable {commodity.title.en}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {ordersWithCommodity.length === 0 ? (
              <>
                <div
                  className="alert alert-secondary mt-4 font-weight-bolder"
                  role="alert"
                  style={{ background: "white", border: 0 }}
                >
                  <div className="alert-icon">
                    <i className="flaticon-exclamation-1" style={{ color: "red" }} />
                  </div>
                  <div className="text-dark h5 my-auto">
                    Do you really want to disable {commodity.title.en}?
                    <br />
                    Disabled commodities can no longer be used for new calculations.
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="alert alert-warning" role="alert">
                  <div className="alert-icon">
                    <i className="flaticon-warning" />
                  </div>
                  <div className="alert-text">
                    Commodity {commodity.title.en} can not be disabled since it is used for {ordersWithCommodity.length}{" "}
                    orders.
                    <br />
                    Please decline / finish the offers / orders below. Then the commodity can be disabled.
                  </div>
                </div>
                <Table className="overflow-auto table-responsive" style={{ maxHeight: "60vh" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>AN/AT</th>
                      <th style={{ minWidth: "35%" }}>Title</th>
                      <th style={{ width: "40%" }}>Customer</th>
                      {commodity.type !== "service" && <th style={{ width: "15%" }}>Amount</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {ordersWithCommodity.map(o => {
                      return (
                        <tr key={o._id.toString()}>
                          <td className="align-middle">
                            <Link to={"/order/" + o._id.toString()} className="kt-link kt-font-dark kt-font-bolder">
                              {orderUtils.resolveOrderDescription(o)[1]}-{o.identifier}
                            </Link>
                          </td>
                          <td className="align-middle">{o.title ? o.title : "-"}</td>
                          <td className="align-middle">
                            <CompanyWidget
                              company={companies.find(c => c._id.toString() === o.createdFor.toString())!}
                            />
                          </td>
                          {commodity.type !== "service" && (
                            <td className="align-middle">{this.calculateUsedAmount(o)}</td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            <button
              className={"btn btn-secondary" + (saving || ordersWithCommodity.length > 0 ? " disabled" : "")}
              onClick={this.handleDisableCommodity}
              disabled={saving || ordersWithCommodity.length > 0}
            >
              Disable
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default DisableCommodityModal;
