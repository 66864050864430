import React, { PureComponent } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CustomOrder } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import dbService, { UpdateAction, BATCH, ORDERS } from "../../../services/dbService";
import userService from "../../../services/userService";
import { T_DECLINED, T_NOTE } from "../../../utils/timelineUtils";
import toastUtils from "../../../utils/toastUtils";
import notificationService, { R_ORDERDECLINED } from "../../../services/notificationService";
import { I_OPEN } from "../../../utils/invoiceUtils";
import { getBatchTimelineEntry } from "../../../utils/batchUtils";
import { BatchTimelineSubType, BatchTimelineType } from "../../../model/warehouse/batch.types";
import { ARCHIVE, CREATEINVOICE, DECLINED } from "../../../utils/orderUtils";

interface CancelOrderModalProps {
  order: CustomOrder;
}

interface CancelOrderModalState {
  reason: string;
  show: boolean;
}

class CancelOrderModal extends PureComponent<CancelOrderModalProps, CancelOrderModalState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: CancelOrderModalProps) {
    super(props);
    this.state = { reason: "", show: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  handleChangeReason = (e: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({ reason: e.target.value });

  /**
   * Handles cancellation of an order.
   */
  handleCancelOrder = async () => {
    const { order } = this.props;
    const { reason } = this.state;
    // Build timeline entries
    const timelineEntries = [
      { type: T_NOTE, date: new Date(), person: userService.getUserId(), text: reason },
      {
        type: T_DECLINED,
        date: new Date(),
        person: userService.getUserId()
      }
    ];
    const batchTimelineEntry = getBatchTimelineEntry(
      BatchTimelineType.BATCHCUSTOMERORDERREMOVED,
      BatchTimelineSubType.BATCH,
      { customerOrder: order._id }
    );
    const actions: Array<UpdateAction> = [
      {
        collection: ORDERS,
        filter: { _id: order._id },
        update: { state: "declined", contractInformation: null },
        push: { timeline: { $each: timelineEntries } }
      },
      // Remove order from list of customer orders of a batch
      {
        collection: BATCH,
        filter: { "sender.customerOrders._id": order._id },
        pull: { "sender.customerOrders": { _id: order._id } },
        push: { timeline: batchTimelineEntry }
      }
    ];
    // Calls need to be handled differently since the contract itself has to be updated
    if (order.contractInformation) {
      // Update the contract
      actions.push({
        collection: ORDERS,
        filter: { _id: order.contractInformation.contractId },
        update: { "contract.$[c].called": null },
        arrayFilters: [{ "c._id": order.contractInformation.callId }]
      });
    }
    const success = await dbService.updatesAsTransaction(actions);
    await toastUtils.databaseOperationToast(!!success, "Order cancelled successfully", "Error cancelling order", () => {
      notificationService.notify(R_ORDERDECLINED, order._id);
      order.contractInformation && this.context.updateDocumentInContext(ORDERS, order.contractInformation.contractId);
      this.context.updateDocumentInContext(ORDERS, order._id);
    });
    if (!!success) this.handleHide();
  };

  render() {
    const { reservation, orders } = this.context;
    const { order } = this.props;
    const { reason, show } = this.state;

    const openOrders =
      order.calculations[0].prices.some(p => p.ordered && !p.delivered) ||
      order.calculations[0].packagings.some(p => p.ordered && !p.delivered);
    const openInvoices = order.invoices && order.invoices.some(i => i.state === I_OPEN);
    const hasActiveCalls =
      order.contract &&
      order.contract.some(
        c =>
          !!c.called &&
          orders.some(
            o =>
              o.contractInformation &&
              o.contractInformation.callId.toString() === c._id.toString() &&
              ![ARCHIVE, CREATEINVOICE, DECLINED].includes(o.state)
          )
      );
    const hasReservation = reservation.some(r => r.order._id.toString() === order._id.toString());

    const disabled = hasActiveCalls || openInvoices || openOrders || hasReservation;
    return (
      <>
        {disabled ? (
          <OverlayTrigger
            overlay={
              <Tooltip id={"cantCancel"}>
                {hasActiveCalls && (
                  <div className="text-danger">
                    <b>Contracts with created open calls can not be canceled</b>
                  </div>
                )}
                {openInvoices && (
                  <div className="text-danger">
                    <b>Orders with open invoices can not be canceled</b>
                  </div>
                )}
                {openOrders && (
                  <div className="text-danger">
                    <b>Orders with open material orders can not be canceled</b>
                  </div>
                )}
                {hasReservation && (
                  <div className="text-danger">
                    <b>There are open reservations for this order. Please contact the warehouse team</b>
                  </div>
                )}
              </Tooltip>
            }
            placement={"left"}
          >
            <button className="btn btn-sm btn-upper btn-danger disabled">Cancel</button>
          </OverlayTrigger>
        ) : (
          <button className="btn btn-sm btn-upper btn-danger" onClick={this.handleShow}>
            Cancel
          </button>
        )}
        <Modal show={show} onHide={this.handleHide} centered>
          <Modal.Header closeButton>
            <Modal.Title>Cancel Order AT-{order.identifier}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-warning" role="alert">
              <div className="alert-icon">
                <i className="flaticon-warning" />
              </div>
              <div className="alert-text">
                Do you really want to cancel order AT-{order.identifier} {order.title}?
                <br />
                This can not be reverted.
              </div>
            </div>
            <div className="row form-group">
              <label className="col-3 col-form-label text-dark kt-font-bold">Reason:</label>
              <div className="col-9">
                <textarea className="form-control" rows={3} value={reason} onChange={this.handleChangeReason} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            {reason.length > 5 ? (
              <button className="btn btn-danger" onClick={this.handleCancelOrder}>
                Cancel Order
              </button>
            ) : (
              <OverlayTrigger
                overlay={
                  <Tooltip id={"cantCancel"}>
                    <span className="text-danger">
                      <b>Please provide a reason for canceling the order</b>
                    </span>
                  </Tooltip>
                }
                placement={"left"}
              >
                <button className="btn btn-danger disabled">Cancel Order</button>
              </OverlayTrigger>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CancelOrderModal;
