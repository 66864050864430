import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { SupplierSelected } from "../../common/supplier/CustomTypes";
import SupplierPricesSelection from "../../common/supplier/SupplierPricesSelection";
import { DataContext } from "../../../context/dataContext";
import { PackagingPrice, PackagingsDocument, PackagingSupplier } from "../../../model/packagings.types";
import { PACKAGINGS } from "../../../services/dbService";
import accessUtils, { EDITLOCATIONS } from "../../../utils/accessUtils";
import packagingUtils from "../../../utils/packagingUtils";

interface PackagingPricingProps {
  packaging: PackagingsDocument;
  context: React.ContextType<typeof DataContext>;
}

interface PackagingPricingState {
  packaging: PackagingsDocument;
}

class PackagingPricing extends PureComponent<PackagingPricingProps, PackagingPricingState> {
  constructor(props: PackagingPricingProps) {
    super(props);
    this.state = {
      packaging: _.cloneDeep(props.packaging)
    };
  }

  componentDidUpdate(
    prevProps: Readonly<PackagingPricingProps>,
    prevState: Readonly<PackagingPricingState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.packaging.suppliers, this.props.packaging.suppliers)) {
      toast.info("Packaging suppliers were updated");
      this.setState({ packaging: _.cloneDeep(this.props.packaging) });
    }
  }

  handleSupplierChange = (suppliersSelected: Array<SupplierSelected>) => {
    const packaging = _.cloneDeep(this.state.packaging);
    if (packaging) {
      const suppliers = [];
      for (let i = 0; i < suppliersSelected.length; i++) {
        const supplier = suppliersSelected[i];
        let sup: PackagingSupplier = { _id: supplier._id, prices: [] };
        for (let j = 0; j < supplier.prices.length; j++) {
          const price = supplier.prices[j];
          let newPrice: PackagingPrice = {
            _id: price._id,
            moq: +price.moq,
            price: +price.totalPrice,
            deliverytime: +price.deliveryTime,
            delivery: "parcel",
            note: price.note,
            date: price.age
          };
          sup.prices.push(newPrice);
        }
        suppliers.push(sup);
      }
      packaging.suppliers = suppliers;
      this.setState({ packaging });
    }
  };

  handleSave = async () => {
    const { context } = this.props;
    const { packaging } = this.state;
    try {
      const result = await packagingUtils.updatePackagingWithTimeline(this.props.packaging, packaging);
      if (!!result) {
        await context.updateDocumentInContext(PACKAGINGS, packaging._id);
        toast.success("Packaging prices successfully updated");
      } else toast.error("Prices could not be updated");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    }
  };

  handleReset = () => this.setState({ packaging: _.cloneDeep(this.props.packaging) });

  render() {
    const { packaging } = this.state;
    const canEdit = accessUtils.canEditData(EDITLOCATIONS.PACKAGINGPRICES);
    return (
      <div>
        <SupplierPricesSelection
          document={packaging}
          type={"Packaging"}
          onSuppliersSelectedChange={this.handleSupplierChange}
          disabled={!canEdit}
        />
        <div className="kt-separator kt-separator--space-lg kt-separator--fit kt-separator--border-solid" />
        <div className="float-right">
          <button
            className={"btn btn-secondary mr-2 " + (!canEdit && "disabled")}
            disabled={!canEdit}
            onClick={canEdit ? this.handleReset : undefined}
          >
            Reset
          </button>
          <button
            className={"btn btn-success " + (!canEdit && "disabled")}
            disabled={!canEdit}
            onClick={canEdit ? this.handleSave : undefined}
          >
            Save changes
          </button>
        </div>
      </div>
    );
  }
}

export default PackagingPricing;
