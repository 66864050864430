import _ from "lodash";
import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { ExtendedEMOrder } from "../../../model/customTypes.types";
import EMCommodityWidget from "../EMCommodityWidget";
import {
  EM_ACCEPTED,
  EM_ARRIVED,
  EM_CHECKED,
  EM_DELIVERED,
  EM_FINISHED,
  EM_OPEN,
  EM_ORDERED
} from "../ExternalManufacturerHelper";
import baseUtils from "../../../utils/baseUtils";
import { ExternalManufacturerOrdersDocument } from "../../../model/externalManufacturerOrders.types";
import UpdatePriceModal from "../internal/UpdatePriceModal";

interface EMOrdersRowProps extends RouteComponentProps {
  order: ExtendedEMOrder | ExternalManufacturerOrdersDocument;
  internal?: boolean;
}

const EMOrdersRow: React.FunctionComponent<EMOrdersRowProps> = ({ internal, order, history }) => {
  const forwardOrder = (e: any) => {
    e.stopPropagation();
    history.push((internal ? "/externalOrder/" : "/order/") + order._id.toString());
  };
  return (
    <tr className="kt-datatable__row table-hover d-table-row" onClick={forwardOrder}>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2 ">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">
              <Link
                to={(internal ? "/externalOrder/" : "/order/") + order._id.toString()}
                onClick={e => e.stopPropagation()}
                className="h6 kt-link kt-font-dark"
              >
                {[EM_OPEN, EM_CHECKED].includes(order.state) ? "REQ" : "EMO"}-{order.identifier}
              </Link>
            </span>
            <span className="kt-user-card-v2__email">{order.reference}</span>
          </div>
        </div>
      </td>
      {!internal && (
        <td>{"commodity" in order && order.commodity ? <EMCommodityWidget commodity={order.commodity} /> : "-"}</td>
      )}
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <span
            className={
              "kt-badge kt-badge--inline kt-badge--pill kt-font-bold " +
              ([EM_OPEN, EM_ACCEPTED].includes(order.state)
                ? internal
                  ? "kt-badge--warning"
                  : "kt-badge--light"
                : order.state === EM_ORDERED
                ? "kt-badge--info"
                : [EM_CHECKED, EM_DELIVERED, EM_ARRIVED, EM_FINISHED].includes(order.state)
                ? internal
                  ? "kt-badge--light"
                  : "kt-badge--success"
                : "kt-badge--danger")
            }
          >
            {_.upperFirst(order.state)}
          </span>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell kt-font-bold">
        <span>{order.amount}kg</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>{baseUtils.formatDate(order.createdOn)}</span>
      </td>
      <td className={"align-kt-datatable__cell d-table-cell"}>
        <span className={order.actualPrice ? "kt-font-bold text-success" : ""}>
          {order.actualPrice ? baseUtils.formatEuro(order.actualPrice) : "-"}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>{order.actualDeliveryDate ? baseUtils.formatDate(order.actualDeliveryDate) : "-"}</span>
      </td>
      <td className={"kt-datatable__cell d-table-cell"}>
        <span className={order.priceLimit ? "kt-font-bold" : ""}>
          {order.priceLimit ? baseUtils.formatEuro(order.priceLimit) : "-"}
        </span>
      </td>
      <td className={"kt-datatable__cell d-table-cell "}>
        <span className={order.deliveryDateLimit && order.deliveryDateLimit < new Date() ? "text-danger" : ""}>
          {order.deliveryDateLimit ? baseUtils.formatDate(order.deliveryDateLimit) : "-"}
        </span>
      </td>
      {internal && (
        <td className="kt-datatable__cell d-table-cell" onClick={e => e.stopPropagation()}>
          {order.state === EM_OPEN && <UpdatePriceModal order={order} small={true} />}
          {order.state === EM_ACCEPTED && (
            <Link to={"/commodity/" + order.commodityId.toString()} className="btn btn-success btn-sm py-1 px-2">
              Order Commodity
            </Link>
          )}
        </td>
      )}
    </tr>
  );
};

export default EMOrdersRow;
