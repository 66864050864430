import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import authenticationService from "../../services/authenticationService";
import userService from "../../services/userService";
import dbService from "../../services/dbService";

interface ChangePasswordProps {}

interface ChangePasswordState {
  show: boolean;
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
  saving: boolean;
  errors: { oldPassword?: string; newPassword?: string; newPasswordRepeat?: string };
}

class ChangePassword extends PureComponent<ChangePasswordProps, ChangePasswordState> {
  constructor(props: ChangePasswordProps) {
    super(props);
    this.state = { show: false, oldPassword: "", newPassword: "", newPasswordRepeat: "", errors: {}, saving: false };
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => {
    if (!this.state.saving) this.setState({ show: false });
  };
  // @ts-ignore
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ [e.target.name]: e.target.value });
  handleKeyDown = (e: any) => {
    if (e.keyCode === 13) this.handleChangePassword();
  };
  handleChangePassword = async () => {
    const { oldPassword, newPassword, newPasswordRepeat, saving } = this.state;
    if (saving) return;
    const userMail = userService.getUserMail()!;
    const errors: { oldPassword?: string; newPassword?: string; newPasswordRepeat?: string } = {};
    let error = false;
    this.setState({ saving: true });
    try {
      await authenticationService.checkCredentials(userMail, oldPassword);
    } catch (e) {
      errors.oldPassword = "The entered password is invalid";
      error = true;
    }

    if (newPassword.length < 10 && !error) {
      errors.newPassword = "Password must have at least 10 characters";
      error = true;
    } else if (newPassword.includes(" ") && !error) {
      errors.newPassword = "Password cannot include whitespaces";
      error = true;
    } else if (newPassword !== newPasswordRepeat && !error) {
      errors.newPasswordRepeat = "Password does not match";
      error = true;
    }

    if (!error) {
      try {
        await dbService.callFunction("setPasswordChangeDate", []);
        await authenticationService.changePassword(userMail, newPassword);
      } catch (e) {
        error = true;
        toast.error("An unexpected error occurred: " + e.message);
        console.error(e);
      }
    }

    this.setState({ errors, oldPassword: "", newPassword: "", newPasswordRepeat: "", saving: false }, () => {
      if (!error) {
        toast.success("Password successfully changed");
        this.handleClose();
      }
    });
  };

  render() {
    const { show, oldPassword, newPassword, newPasswordRepeat, errors, saving } = this.state;
    return (
      <>
        <button className="btn btn-label-success btn-sm btn-bold ml-2" onClick={this.handleShow}>
          Change Password
        </button>
        <Modal show={show} centered onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-warning" role="alert">
              <div className="alert-icon">
                <i className="flaticon-exclamation-1" />
              </div>
              <div className="alert-text">
                After you changed your password you will be unable to login with your current password. Create a
                reasonably safe password and to prevent losing your password consider using a password manager to store
                it safely.
              </div>
            </div>
            <form>
              <div className="row mb-3">
                <div className="col-4 text-dark align-self-center">Current password</div>
                <div className="col-8">
                  <input
                    className="form-control"
                    type="password"
                    value={oldPassword}
                    name="oldPassword"
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                  />
                  {errors.oldPassword && <span className="text-danger ml-1">{errors.oldPassword}</span>}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4 text-dark align-self-center">New password</div>
                <div className="col-8">
                  <input
                    className="form-control"
                    type="password"
                    value={newPassword}
                    name="newPassword"
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                  />
                  {errors.newPassword && <span className="text-danger ml-1">{errors.newPassword}</span>}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4 text-dark align-self-center">Repeat new password</div>
                <div className="col-8">
                  <input
                    className="form-control"
                    type="password"
                    value={newPasswordRepeat}
                    name="newPasswordRepeat"
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                  />
                  {errors.newPasswordRepeat && <span className="text-danger ml-1">{errors.newPasswordRepeat}</span>}
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary btn-sm" onClick={this.handleClose}>
              Close
            </button>
            <button
              className={
                "btn btn-success btn-sm btn-bold " +
                ((oldPassword.trim() === "" ||
                  newPassword.trim() === "" ||
                  newPasswordRepeat.trim() === "" ||
                  saving) &&
                  "disabled")
              }
              disabled={
                oldPassword.trim() === "" || newPassword.trim() === "" || newPasswordRepeat.trim() === "" || saving
              }
              onClick={this.handleChangePassword}
            >
              {saving && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Change Password
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ChangePassword;
