import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import EventApi from "@fullcalendar/core/api/EventApi";
import View from "@fullcalendar/core/View";
import { EventSourceInput } from "@fullcalendar/core/structs/event-source";

export enum ViewType {
  FULL_VIEW,
  MINIMUM_VIEW
}
interface CalendarViewProps {
  events: Array<EventSourceInput>;
  onSelectEvents: (arg: { el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: View }) => void;
}

const CalendarView: React.FunctionComponent<CalendarViewProps> = ({ events, onSelectEvents }) => {
  return (
    <div className="kt-portlet__body ">
      <FullCalendar
        scrollTime={0}
        defaultView={"dayGridMonth"}
        plugins={[dayGridPlugin, interactionPlugin]}
        eventClick={onSelectEvents}
        eventLimit={6}
        firstDay={1} // 1 for monday, default 0 is sunday
        events={events}
        weekNumbers={true}
        weekNumberCalculation={"ISO"}
        weekLabel={"CW"}
        contentHeight={"auto"}
      />
    </div>
  );
};

export default CalendarView;
