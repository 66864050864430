import _ from "lodash";
import React from "react";
import { Tooltip } from "@material-ui/core";
import { CalculationType, CustomPackagingsDocument, Preferences, SelectedPackagingsDocument } from "./CustomTypes";
import packagingUtils from "../../utils/packagingUtils";

interface PackagingItemProps {
  packaging: CustomPackagingsDocument | SelectedPackagingsDocument;
  preferences: Preferences;
  calculations: Array<CalculationType>;
  onPackagingSelect: (packaging: CustomPackagingsDocument) => void;
  onPackagingAmount: (packaging: SelectedPackagingsDocument, add: boolean) => void;
}

export default class PackagingItem extends React.PureComponent<PackagingItemProps> {
  /**
   * Resolve packaging properties and description
   * @returns {object} object with packaging properties
   */
  resolvePackaging() {
    const { packaging } = this.props;
    const { packaging_type: type } = packaging;
    let packagingProperties: any = packagingUtils.resolveExtendedPackagingProperties(packaging) || {};
    packagingProperties.type = packagingUtils.getPackagingDescription(type);
    return packagingProperties;
  }

  /**
   * Render packaging properties
   * @param packagingProperties the packaging properties
   * @returns {JSX.element} rendered packaging properties
   */
  renderProperties(packagingProperties: any) {
    return (
      <>
        {Object.entries(packagingProperties).map(([key, value]) => {
          // Render properties, exclude type, price, url and color as they are handled differently
          if (key === "type" || key === "price" || key === "url" || key === "color") return;
          return (
            <div key={key} className="kt-widget13__item">
              <span className="kt-widget13__desc">{packagingUtils.getPackagingDescription(key)}</span>
              <span className="kt-widget13__text kt-widget13__text--bold">{value as any}</span>
            </div>
          );
        })}
      </>
    );
  }

  /**
   * Render the color bdage
   * @param color the color
   * @returns {JSX.Element} color badge
   */
  renderColorBadge(color: string | undefined) {
    if (!color) return;
    let originColor = _.lowerFirst(color);
    const style = packagingUtils.getColorBadgeStyle(originColor);
    return (
      <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded" style={style}>
        <b>{originColor === "color" ? "Special Color" : _.upperFirst(originColor)}</b>
      </span>
    );
  }

  render() {
    const { packaging, onPackagingSelect, onPackagingAmount, calculations } = this.props;
    const lowestPrice = packagingUtils.getLowestPrice(packaging);
    const lowestDeliveryTime = packagingUtils.getLowestDeliveryTime(packaging);
    const packagingProperties: any = this.resolvePackaging();
    let matchingMOQ;
    if ("amount" in packaging && packaging.amount !== undefined && packaging.calculations !== undefined) {
      matchingMOQ = packaging.calculations[0].supplier.price.moq > +calculations[0].units * +packaging.amount;
    }
    const disabled = !!packaging.disabled;
    const noSupplier =
      packaging.suppliers.length === 0 || packaging.suppliers.reduce((sum, s) => sum + s.prices.length, 0) === 0;

    return (
      <div className="col-12 col-md-6 col-lg-4 col-xl-3" id={packaging._id.toString()}>
        <div className="kt-portlet kt-portlet--height-fluid kt-widget19">
          <div className="kt-portlet__body kt-portlet__body--fit kt-portlet__body--unfill">
            <div
              className="kt-widget19__pic kt-portlet-fit--top kt-portlet-fit--sides previewImg"
              style={{ height: "200px" }}
            >
              <img className="packaging_img" src={packagingProperties.url} alt="packaging" />
              {packaging.recommended && !disabled && (
                <span
                  className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded"
                  style={{ position: "absolute", top: 5, right: 5 }}
                >
                  <b>Recommended</b>
                </span>
              )}
              {disabled && (
                <span
                  className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded"
                  style={{ position: "absolute", top: 5, right: 5 }}
                >
                  <b>DISABLED</b>
                </span>
              )}
              {"calculations" in packaging ? (
                <span
                  className="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded"
                  style={{ position: "absolute", bottom: 5, left: 5, backgroundColor: "rgb(199,199,199)" }}
                >
                  <i className="flaticon-truck kt-icon-xl mr-2" />
                  <span className="font-weight-bold kt-font-md" style={{ color: "#50566a" }}>
                    {packaging.calculations![0].deliveryTime + " Days"}
                  </span>
                </span>
              ) : (
                lowestDeliveryTime !== undefined && (
                  <span
                    className="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded"
                    style={{ position: "absolute", bottom: 5, left: 5, backgroundColor: "rgb(199,199,199)" }}
                  >
                    <i className="flaticon-truck kt-icon-xl mr-2" />
                    <span className="font-weight-bold kt-font-md" style={{ color: "#50566a" }}>
                      {"Min. " + lowestDeliveryTime}
                    </span>
                  </span>
                )
              )}
              {this.renderColorBadge(packagingProperties.color)}
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="kt-widget13" style={{ height: "100%" }}>
              <div className="kt-widget13__item">
                <span className="kt-widget13__desc">Type</span>
                <span className="kt-widget13__text kt-widget13__text--bold">{packagingProperties.type}</span>
              </div>
              {this.renderProperties(packagingProperties)}
              <div className="kt-widget13__item">
                <span className="kt-widget13__desc">Price</span>
                <span className="kt-widget13__text kt-widget13__text--bold">
                  {"calculations" in packaging && "amount" in packaging ? (
                    matchingMOQ ? (
                      <>
                        <span className="text-danger">
                          {packaging.calculations![0].price
                            ? packaging.calculations![0].price.toLocaleString("de-DE", {
                                style: "currency",
                                currency: "EUR"
                              })
                            : "-,-- €"}
                        </span>
                        <Tooltip
                          title={`Total quantity is below MOQ: ${+calculations[0].units * packaging.amount!}/${
                            packaging.calculations![0].supplier.price.moq
                          } units`}
                          placement="top"
                        >
                          <i className="la la-info-circle ml-1 text-danger" />
                        </Tooltip>
                      </>
                    ) : (
                      <span className="text-green">
                        {"calculations" in packaging && packaging.calculations![0].price
                          ? packaging.calculations![0].price.toLocaleString("de-DE", {
                              style: "currency",
                              currency: "EUR"
                            })
                          : "-,-- €"}
                      </span>
                    )
                  ) : (
                    <span className="text-green">
                      from{" "}
                      {lowestPrice
                        ? lowestPrice.toLocaleString("de-DE", {
                            style: "currency",
                            currency: "EUR"
                          })
                        : "-,-- €"}
                    </span>
                  )}
                </span>
              </div>
              <div className="kt-widget13__item">
                <span className="kt-widget13__desc">Note</span>
                <span className="kt-widget13__text kt-widget13__text--bold">
                  {packaging.note ? packaging.note : "-"}
                </span>
              </div>
            </div>
            {"amount" in packaging ? (
              <>
                <div className="text-center mt-2">
                  <span style={{ fontSize: 18 }}>
                    <b>{packaging.amount}</b>
                  </span>
                </div>
                <div style={{ margin: "0 auto" }}>
                  <div className="btn-group btn-group mt-1" role="group">
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={() => onPackagingAmount(packaging, false)}
                    >
                      <i className="la la-minus" />
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-success"
                      onClick={() => onPackagingAmount(packaging, true)}
                    >
                      <i className="la la-plus" />
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <button
                type="button"
                className={"btn btn-secondary btn-sm mt-3 " + (noSupplier && "disabled")}
                style={{ width: "100%", bottom: 0, position: "relative" }}
                disabled={noSupplier}
                onClick={noSupplier ? undefined : () => onPackagingSelect(packaging)}
              >
                {noSupplier ? "No Supplier" : "Select"}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
