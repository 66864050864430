import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import ProductionOrderMachinePlanning from "./ProductionOrderMachinePlanning";
import ProductionOrderPlanning from "./ProductionOrderPlanning";
import { ExtendedProductionWeek } from "./ProductionPlanHelper";
import ProductionPlanOrderDetails from "./ProductionPlanOrderDetails";
import { OrderWidget } from "../listings/common/BaseListingComponents";
import { DataContext } from "../../context/dataContext";
import { ManufacturersDocument, ProductionLine } from "../../model/manufacturers.types";
import { OrdersDocument } from "../../model/orders.types";
import dbService, { ORDERS } from "../../services/dbService";
import accessUtils, { ACTIONS } from "../../utils/accessUtils";
import baseUtils from "../../utils/baseUtils";
import orderUtils, { PRODUCTION, PRODUCTIONQUEUE } from "../../utils/orderUtils";
import DelayReasons from "../common/DelayReasons";
import slackService from "../../services/slackService";
import dateUtils from "../../utils/dateUtils";
import dbOrderService from "../../services/dbServices/dbOrderService";
import userService from "../../services/userService";
import { T_RESCHEDULE, T_RETURNPRODUCTIONQUEUE } from "../../utils/timelineUtils";

interface PlanOrderModalProps {
  order: OrdersDocument | null;
  context: React.ContextType<typeof DataContext>;
  onClose: () => void;
  reschedule?: boolean;
}

interface PlanOrderModalState {
  step: number;
  selectedWeek: ExtendedProductionWeek | null;
  selectedMachine: ProductionLine | null;
  ordersAtLocation: Array<OrdersDocument>;
  submitting: boolean;
  reasonNotification: { value: string; label: string } | "";
  reasonReschedule: string;
}

class PlanOrderModal extends PureComponent<PlanOrderModalProps, PlanOrderModalState> {
  constructor(props: PlanOrderModalProps) {
    super(props);
    this.state = {
      step: 0,
      selectedWeek: null,
      selectedMachine: null,
      ordersAtLocation: [],
      submitting: false,
      reasonNotification: "",
      reasonReschedule: ""
    };
  }

  componentDidMount() {
    const { order, context } = this.props;
    if (order)
      this.setState({
        ordersAtLocation: context.orders.filter(
          o => o.settings.manufacturer && o.settings.manufacturer.toString() === order.settings.manufacturer.toString()
        )
      });
  }

  componentDidUpdate(
    prevProps: Readonly<PlanOrderModalProps>,
    prevState: Readonly<PlanOrderModalState>,
    snapshot?: any
  ) {
    const { order, context } = this.props;
    if (order && (!_.isEqual(order, prevProps.order) || !_.isEqual(context.orders, prevProps.context.orders)))
      this.setState({
        ordersAtLocation: context.orders.filter(
          o => o.settings.manufacturer && o.settings.manufacturer.toString() === order.settings.manufacturer.toString()
        )
      });
  }

  handleNext = () => {
    const { step } = this.state;
    if (step < 1) this.setState({ step: step + 1 });
  };
  handleBack = () => {
    const { step } = this.state;
    if (step === 1) this.setState({ step: step - 1 });
    else if (step === 2) this.setState({ step: step - 1, selectedWeek: null });
    else if (step === 3) this.setState({ step: step - 1, selectedMachine: null });
    else if (step === 4) this.setState({ step: 0 });
  };
  handleClose = () => {
    if (this.state.submitting) return;
    this.setState({ step: 0, selectedWeek: null, selectedMachine: null, reasonNotification: "" });
    this.props.onClose();
  };
  handleSelectWeek = (week: ExtendedProductionWeek) => {
    const { step } = this.state;
    this.setState({ step: step + 1, selectedWeek: week });
  };
  handleSelectMachine = (machine: ProductionLine) => {
    const { step } = this.state;
    this.setState({ step: step + 1, selectedMachine: machine });
  };
  handleCancelSchedule = () => {
    const { step } = this.state;
    if (step !== 0) return;
    this.setState({ step: 4 });
  };
  handleReasonNotification = (reasonNotification: { value: string; label: string } | "") =>
    this.props.reschedule && this.setState({ reasonNotification });

  handleRemoveFromSchedule = async () => {
    const { order, reschedule } = this.props;
    if (!(order && reschedule && order.productionWeek && order.settings.productionMachine)) return;
    this.setState({ submitting: true });
    let result: any;
    try {
      result = await dbService.callFunction("removePlannedOrder", [order]);
      if (result) toast.success(`Order successfully removed from schedule`);
      else toast.error("Order could not be unscheduled");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ submitting: false }, () => result && this.handleClose());
    }
  };

  handleSubmit = async () => {
    const { order, reschedule, context } = this.props;
    const { selectedMachine, selectedWeek, reasonNotification, reasonReschedule } = this.state;
    if (!selectedWeek || !selectedMachine || !order) {
      toast.error("Selection not complete yet");
      return;
    }
    const customer = baseUtils.getDocFromCollection(this.props.context.companies, order.createdFor);
    this.setState({ submitting: true });
    const planned = order.productionWeek && order.settings.productionMachine;
    const plannedOrders = selectedWeek.plannedOrders.slice();
    const alreadyInWeek = plannedOrders.some(o => o.toString() === order._id.toString());
    if (
      alreadyInWeek &&
      planned &&
      order.productionWeek === selectedWeek.week &&
      order.settings.productionMachine!.toString() === selectedMachine._id.toString()
    ) {
      toast.success(`Order already planned for CW ${order.productionWeek.split("-")[0]} on ${selectedMachine.name}`);
      this.setState({ submitting: false }, () => this.handleClose());
      return;
    }
    if (!alreadyInWeek) plannedOrders.push(order._id);
    const productionWeek = {
      week: selectedWeek.week,
      startDate: selectedWeek.startDate,
      endDate: selectedWeek.endDate,
      plannedOrders: plannedOrders
    };
    let result: any;
    try {
      result = await dbService.callFunction("planOrderProduction", [
        productionWeek,
        selectedMachine._id,
        order._id,
        planned ? order.productionWeek : undefined
      ]);
      const cwSelected = selectedWeek.week.split("-")[0];
      if (result) {
        toast.success(
          `Order successfully ${reschedule ? "rescheduled" : "scheduled"} for CW ${cwSelected} on ${
            selectedMachine.name
          } `
        );
        if (order.state === PRODUCTION) {
          const productionCW = +(order.productionWeek?.split("-")[0] || "0");
          const now = new Date().getTime();
          // This complicated due to issues that would arise in first/last CW of the year.
          const lastCW = dateUtils.getCW(new Date(now - 1000 * 60 * 60 * 24 * 7));
          const thisCW = dateUtils.getCW(new Date(now));
          const nextCW = dateUtils.getCW(new Date(now + 1000 * 60 * 60 * 24 * 7));
          if (
            (productionCW === lastCW && +cwSelected === thisCW) ||
            (productionCW === thisCW && +cwSelected === nextCW)
          ) {
            await dbOrderService.pushToTimeline(order._id, {
              _id: BSON.ObjectId,
              type: T_RESCHEDULE,
              date: new Date(),
              person: userService.getUserId(),
              reason: reasonReschedule,
              week: { before: order.productionWeek, after: selectedWeek.week }
            });
          } else {
            await dbOrderService.switchState(order._id, PRODUCTIONQUEUE, {
              _id: BSON.ObjectId,
              type: T_RETURNPRODUCTIONQUEUE,
              date: new Date(),
              person: userService.getUserId(),
              reason:
                "Production week changed from " +
                order.productionWeek +
                " to " +
                selectedWeek.week +
                " with reason: " +
                (reasonReschedule ? reasonReschedule : "No reason provided")
            });
          }
        }
        context.updateDocumentInContext(ORDERS, order._id);
        if (reasonNotification && reschedule && order.productionWeek !== selectedWeek.week) {
          const message = `Production of order <https://www.admincentral.private-label-factory.com/order/${order._id.toString()}|AT-${
            order.identifier
          }> for _${customer ? customer.name : "Unknown Customer"}_ was rescheduled. Before: CW${
            order.productionWeek
          } Now: CW${selectedWeek.week}. Reason: ${
            reasonNotification.label
          }`;
          slackService.sendMessage(order.createdFrom, message);
        }
      } else toast.error("Order could not be planned");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ submitting: false }, () => result && this.handleClose());
    }
  };

  /**
   * Get the name of the currently selected machine of the order
   * @param manufacturer the related manufacturer document
   * @returns {string} name of the machine
   */
  getMachineNameForOrder = (manufacturer: ManufacturersDocument) => {
    const { order } = this.props;
    if (!order || !order.settings.productionMachine) return "Not set";
    const machine = manufacturer.productionLines.find(
      p => p._id.toString() === order.settings.productionMachine!.toString()
    );
    return machine ? machine.name : "Unknown machine";
  };

  render() {
    const { order, context, reschedule } = this.props;
    const {
      step,
      selectedWeek,
      selectedMachine,
      ordersAtLocation,
      submitting,
      reasonNotification,
      reasonReschedule
    } = this.state;
    if (!order) return null;
    const manufacturer = baseUtils.getDocFromCollection(context.manufacturers, order.settings.manufacturer);
    if (!manufacturer) return null;
    const size = step === 0 ? "lg" : step === 3 || step === 4 ? undefined : "xl";
    const ready = orderUtils.isReadyForProduction(order, context.packagings) || order.state === PRODUCTION;
    const canSubmit = accessUtils.canPerformAction(
      reschedule ? ACTIONS.PRODUCTIONPLANRESCHEDULE : ACTIONS.PRODUCTIONPLANPLAN
    );

    return (
      <Modal
        show={!!order}
        size={size}
        onHide={this.handleClose}
        centered
        name={"planOrderModal"}
        backdropClassName={"customBackdrop"}
        dialogClassName={"extendedModalDialog"}
        style={{ zIndex: 950 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="flaticon2-calendar-1 kt-font-brand mr-2" />
            {step === 3
              ? "Summary"
              : step === 4
              ? `Remove Order AT-${order.identifier} from schedule`
              : `Plan ${order.calculations[0].units} units of Order AT-${order.identifier} at ${manufacturer.name}`}
          </Modal.Title>
        </Modal.Header>
        {/* In summary (step 3) overflow auto would lead to the Select not being displayed properly */}
        <Modal.Body className={step === 3 ? "" : "overflow-auto"} style={{ maxHeight: "80vh" }}>
          {step === 0 ? (
            <ProductionPlanOrderDetails
              order={order}
              context={context}
              reschedule={reschedule}
              onCancelSchedule={this.handleCancelSchedule}
            />
          ) : step === 1 && !selectedWeek ? (
            <ProductionOrderPlanning
              order={order}
              ordersAtLocation={ordersAtLocation}
              context={context}
              onSelectWeek={this.handleSelectWeek}
              reschedule={reschedule}
            />
          ) : step === 2 && selectedWeek ? (
            <ProductionOrderMachinePlanning
              order={order}
              context={context}
              ordersAtLocation={ordersAtLocation}
              manufacturer={manufacturer}
              week={selectedWeek}
              onSelectMachine={this.handleSelectMachine}
              reschedule={reschedule}
            />
          ) : step === 3 && selectedWeek && selectedMachine ? (
            <div>
              <div className="alert alert-info" role="alert">
                <div className="alert-icon">
                  <i className="flaticon-exclamation-1" />
                </div>
                <div className="alert-text">The order can be rescheduled or assigned to another machine anytime</div>
              </div>
              <OrderWidget document={order} prefix={"Order AT-"} noLink={true} titleClasses={"kt-font-lg"} />
              <div className="mt-2">
                <div className="kt-user-card-v2">
                  <div className="kt-user-card-v2__pic d-none d-xl-block py-1">
                    <div className="kt-badge kt-badge--xl kt-badge--primary">
                      <i className="fas fa-calendar-week" style={{ fontSize: "1.6rem" }} />
                    </div>
                  </div>
                  <div className="kt-user-card-v2__details">
                    <span className={"kt-user-card-v2__name d-inline-block kt-font-lg"}>
                      Production week:{" "}
                      <span className={"kt-font-dark kt-font-bolder"}>{selectedWeek.week.split("-")[0]}</span>
                    </span>
                    <br />
                    <span className="kt-user-card-v2__email">{`${new Date(
                      selectedWeek.startDate.valueOf() + selectedWeek.startDate.getTimezoneOffset() * 60 * 1000
                    ).toLocaleDateString("de-DE", {
                      month: "numeric",
                      day: "numeric",
                      year: "numeric"
                    })} - ${new Date(
                      selectedWeek.endDate.valueOf() + selectedWeek.endDate.getTimezoneOffset() * 60 * 1000
                    ).toLocaleDateString("de-DE", {
                      month: "numeric",
                      day: "numeric",
                      year: "numeric"
                    })}`}</span>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <div className="kt-user-card-v2">
                  <div className="kt-user-card-v2__pic d-none d-xl-block py-1">
                    <div className="kt-badge kt-badge--xl kt-badge--primary">
                      <i className="far fas fa-pallet" style={{ fontSize: "1.6rem" }} />
                    </div>
                  </div>
                  <div className="kt-user-card-v2__details">
                    <span className={"kt-user-card-v2__name d-inline-block kt-font-lg"}>
                      Machine: <span className={"kt-font-dark kt-font-bolder"}>{selectedMachine.name}</span>
                    </span>
                    <br />
                    <span className="kt-user-card-v2__email">{manufacturer.name}</span>
                  </div>
                </div>
              </div>
              {reschedule && (
                <div className="mt-4 row form-group">
                  <div className="col-4 col-form-label kt-font-bold kt-font-lg" style={{ color: "#595d6e" }}>
                    Notification:
                  </div>
                  <div className="col-8">
                    <DelayReasons reason={reasonNotification} onChange={this.handleReasonNotification} />
                  </div>
                </div>
              )}
              {order.state === PRODUCTION && (
                <div className="mt-4 row form-group">
                  <div className="col-4 col-form-label kt-font-bold kt-font-lg" style={{ color: "#595d6e" }}>
                    Reason:
                  </div>
                  <div className="col-8">
                    <textarea
                      onChange={e => this.setState({ reasonReschedule: e.target.value })}
                      className="form-control"
                      value={reasonReschedule}
                      rows={3}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : step === 4 && order.productionWeek && order.settings.productionMachine ? (
            <div>
              <div className="alert alert-warning" role="alert">
                <div className="alert-icon">
                  <i className="flaticon-exclamation-1" />
                </div>
                <div className="alert-text">
                  The order will be removed from the schedule but can be rescheduled anytime. Are you sure the
                  production week and machine should be unset?
                </div>
              </div>
              <OrderWidget document={order} prefix={"Order AT-"} noLink={true} titleClasses={"kt-font-lg"} />
              <div className="mt-2">
                <div className="kt-user-card-v2">
                  <div className="kt-user-card-v2__pic d-none d-xl-block py-1">
                    <div className="kt-badge kt-badge--xl kt-badge--primary">
                      <i className="fas fa-calendar-week" style={{ fontSize: "1.6rem" }} />
                    </div>
                  </div>
                  <div className="kt-user-card-v2__details">
                    <span className={"kt-user-card-v2__name d-inline-block kt-font-lg"}>
                      Production week:{" "}
                      <span className={"kt-font-bolder text-warning"}>{order.productionWeek.split("-")[0]}</span>
                    </span>
                    <br />
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <div className="kt-user-card-v2">
                  <div className="kt-user-card-v2__pic d-none d-xl-block py-1">
                    <div className="kt-badge kt-badge--xl kt-badge--primary">
                      <i className="far fas fa-pallet" style={{ fontSize: "1.6rem" }} />
                    </div>
                  </div>
                  <div className="kt-user-card-v2__details">
                    <span className={"kt-user-card-v2__name d-inline-block kt-font-lg"}>
                      Machine:{" "}
                      <span className={"kt-font-bolder text-warning"}>{this.getMachineNameForOrder(manufacturer)}</span>
                    </span>
                    <br />
                    <span className="kt-user-card-v2__email">{manufacturer.name}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <button
            className={"btn btn-secondary " + (submitting && "disabled")}
            disabled={submitting}
            onClick={step > 0 ? this.handleBack : this.handleClose}
          >
            {step > 0 ? "Back" : "Close"}
          </button>
          {step < 1 && ready && (
            <button
              className={"btn btn-primary " + ((submitting || !canSubmit) && "disabled")}
              disabled={submitting || !canSubmit}
              style={{ minWidth: "80px" }}
              onClick={canSubmit ? this.handleNext : undefined}
            >
              {reschedule ? "Reschedule" : "Plan"}
            </button>
          )}
          {step === 3 && selectedMachine && selectedWeek && (
            <button
              className={"btn btn-success " + (submitting && "disabled")}
              disabled={submitting}
              onClick={this.handleSubmit}
            >
              {submitting && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner button-splash-spinner-dark" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              {reschedule ? "Reschedule Order" : "Plan Order"}
            </button>
          )}
          {step === 4 && order.productionWeek && order.settings.productionMachine && (
            <button className={"btn btn-warning " + (submitting && "disabled")} onClick={this.handleRemoveFromSchedule}>
              {submitting && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner button-splash-spinner-dark" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Remove
            </button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PlanOrderModal;
