import _ from "lodash";
import React from "react";
import { toAbsoluteUrl } from "../../_metronic";
import {
  ExtendedEMCommodity,
  ExtendedEMOrder,
  ExternalManufacturerCommodityDocument
} from "../../model/customTypes.types";
import { ExternalManufacturerContext } from "../../context/externalManufacturerContext";
import baseUtils from "../../utils/baseUtils";
import commodityUtils from "../../utils/commodityUtils";
import config from "../../config/config.json";
import { ExternalManufacturerOrdersDocument } from "../../model/externalManufacturerOrders.types";
import { DataContext } from "../../context/dataContext";

export const EMOrderNumber = "externalManufacturerOrderNr";
// EMO States/Timeline entries
export const EM_OPEN = "open";
export const EM_CHECKED = "checked";
export const EM_ACCEPTED = "accepted";
export const EM_DELIVERED = "delivered";
export const EM_ORDERED = "ordered";
export const EM_CANCELED = "canceled";
export const EM_EXPIRED = "expired";
export const EM_ARRIVED = "arrived";
export const EM_FINISHED = "finished";
// EMO Additional timeline entries
export const EM_ORDERCREATED = "orderCreated";
export const EM_ORDERUNLINKED = "orderUnlinked";
export const EM_ORDEREDITED = "orderEdited";
export const EM_ORDERCANCELED = "orderCanceled";

/**
 * Get a commodity with extended data
 * @param commodity the external manufacturer commodity
 * @param context the external manufacturer context
 * @returns {ExtendedEMCommodity} extended commodity
 */
function getExtendedCommodity(
  commodity: ExternalManufacturerCommodityDocument,
  context: React.ContextType<typeof ExternalManufacturerContext | typeof DataContext>
): ExtendedEMCommodity {
  const {
    compositions,
    commodityproperties,
    commoditycategories,
    allergens,
    solvents,
    activesubstances,
    colors
  } = context;
  let tempCommodity = _.cloneDeep(
    _.omit(commodity, ["form", "category", "properties", "activesubstance", "allergens", "color", "solvent"])
  );
  const tempForm = baseUtils.getDocFromCollection(compositions, commodity.form);
  if (tempForm) _.set(tempCommodity, "form", tempForm);
  const tempCat = baseUtils.getDocFromCollection(commoditycategories, commodity.category);
  if (tempCat) _.set(tempCommodity, "category", tempCat);
  if (commodity.solvent) {
    const tempSolvent = baseUtils.getDocFromCollection(solvents, commodity.solvent);
    if (tempSolvent) _.set(tempCommodity, "solvent", tempSolvent);
  }
  if (commodity.color) {
    const tempColor = baseUtils.getDocFromCollection(colors, commodity.color);
    if (tempColor) _.set(tempCommodity, "color", tempColor);
  }

  const tempProps = [];
  for (let j = 0; j < commodity.properties.length; j++) {
    const comProp = commodity.properties[j];
    const tempProp = baseUtils.getDocFromCollection(commodityproperties, comProp);
    if (tempProp) tempProps.push(tempProp);
  }
  _.set(tempCommodity, "properties", tempProps);

  const tempSubstances = [];
  for (let j = 0; j < commodity.activesubstance.length; j++) {
    const activeSub = commodity.activesubstance[j];
    const activeSubDoc = baseUtils.getDocFromCollection(activesubstances, activeSub._id);
    const tmpActiveSub = {
      ...activeSubDoc,
      value: activeSub.value
    };
    if (tmpActiveSub) tempSubstances.push(tmpActiveSub);
  }
  _.set(tempCommodity, "activesubstance", tempSubstances);

  const tempAllergens = [];
  for (let j = 0; j < commodity.allergens.length; j++) {
    const allergen = commodity.allergens[j];
    const tmpAllergen = baseUtils.getDocFromCollection(allergens, allergen);
    if (tmpAllergen) tempAllergens.push(tmpAllergen);
  }
  _.set(tempCommodity, "allergens", tempAllergens);

  return tempCommodity as ExtendedEMCommodity;
}

/**
 * Get the image for a commodity
 * @param commodity an extended EM commodity
 * @returns {string} path to image
 */
function getCommodityImage(commodity: ExtendedEMCommodity) {
  const type = commodityUtils.getCommodityType(commodity);
  let src = config.mediahubBase;
  if (type === "softgel") src = toAbsoluteUrl("/media/img/Softgels.jpg");
  else if (
    commodity.color &&
    commodity.color.image &&
    commodity.form &&
    commodity.form._id.toString() === "5ffdc73a95ac35e327854aa0"
  ) {
    src += commodity.color.image;
  } else if (commodity.form && commodity.form.toString() === "5ffdc73a95ac35e327854aa0") {
    src += "files/unknown.png";
  } else {
    src += "files/others.png";
  }
  return src;
}

/**
 * Get the latest timeline entry of an order
 * @param order the EM order
 * @param entryType the timeline entry to get
 * @returns {EmOrderTimelineEntry} the latest timeline entry or undefined if none was found
 */
function getLatestTimelineEntry(order: ExtendedEMOrder | ExternalManufacturerOrdersDocument, entryType: string) {
  return order.timeline
    .slice()
    .reverse()
    .find(t => t.type === entryType);
}

/**
 * Matches the state of the given EM order to the correct percentage.
 * @param state the state of an EM order
 * @returns {string} string with percentage indicating the progress
 */
function getEMOrderProgress(state: string): string {
  switch (state) {
    case EM_OPEN:
      return "0%";
    case EM_ORDERED:
      return "25%";
    case EM_DELIVERED:
      return "50%";
    case EM_ARRIVED:
      return "75%";
    case EM_FINISHED:
    case EM_CANCELED:
      return "100%";
    default:
      return "0%";
  }
}

export default { getExtendedCommodity, getCommodityImage, getLatestTimelineEntry, getEMOrderProgress };
