import { BSON } from "realm-web";
import { CapsulesDocument } from "../model/capsules.types";

/**
 * Resolve the best price for the given capsule and requested amount.
 * @param capsule Capsules document
 * @param amount Amount of capsules that are ordered
 * @returns { { price: number, supplier: BSON.ObjectId | null, type: string | null } } Lowest price and matching supplier
 */
function getBestPrice(
  capsule: CapsulesDocument,
  amount: number
): { price: number; supplier: BSON.ObjectId | null; type: string | null } {
  const bestPrice: { price: number; supplier: BSON.ObjectId | null; type: string | null } = {
    price: Number.POSITIVE_INFINITY,
    supplier: null,
    type: null
  };
  for (let supplier of capsule.suppliers) {
    for (let price of supplier.prices) {
      if (amount >= price.moq && price.price < bestPrice.price) {
        bestPrice.price = price.price;
        bestPrice.supplier = supplier._id;
        bestPrice.type = supplier.type || null;
      }
    }
  }
  return bestPrice;
}

/**
 * Resolve the fastest delivery for the given capsule and requested amount.
 * @param capsule Capsules document
 * @param amount Amount of capsules that are ordered
 * @returns { { days: number, supplier: BSON.ObjectId | null, type: string | null } } Fastest delivery and matching supplier
 */
function getFastestDelivery(
  capsule: CapsulesDocument,
  amount: number
): { days: number; supplier: BSON.ObjectId | null; type: string | null } {
  const fastestDelivery: { days: number; supplier: BSON.ObjectId | null; type: string | null } = {
    days: Number.POSITIVE_INFINITY,
    supplier: null,
    type: null
  };
  for (let supplier of capsule.suppliers) {
    for (let price of supplier.prices) {
      if (amount >= price.moq && price.deliverytime < fastestDelivery.days) {
        fastestDelivery.days = price.deliverytime;
        fastestDelivery.supplier = supplier._id;
        fastestDelivery.type = supplier.type || null;
      }
    }
  }
  return fastestDelivery;
}

export default { getBestPrice, getFastestDelivery };
