import { BSON } from "realm-web";
import { BaseConfiguration } from "./configuration.types";

export interface NotificationConfiguration extends BaseConfiguration {
  values: NotificationConfigurationValues;
}

export interface NotificationConfigurationValues {
  settings: Array<NotificationSetting>;
}

export interface NotificationSetting {
  _id: BSON.ObjectId;
  type: NotificationTypes;
  users: Array<NotificationUser>;
}

export interface NotificationUser {
  userId: string; // reference to userdata._id
  email: boolean; // mail from userdata unless mail is specifically given
  mailAddress?: string;
  slack: boolean; // slackId from userdata
}

export enum NotificationTypes {
  ORDER_TW_CONFLICT = "orderTWConflict"
}
