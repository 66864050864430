import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { Stats } from "../../../model/customTypes.types";
import baseUtils from "../../../utils/baseUtils";
import { OrdersDocument } from "../../../model/orders.types";
import dashboardUtils from "../../../utils/dashboardUtils";
import { MARGIN } from "../../../utils/orderCalculationUtils";

interface SalesStatsProps {
  stats: Stats | null;
  dashboard: "sales" | "investor";
  orders: Array<OrdersDocument>;
  year?: number;
  selectedUser?: BSON.ObjectId;
}

interface SalesStatsState {}

class SalesStats extends PureComponent<SalesStatsProps, SalesStatsState> {
  render() {
    const { dashboard, orders, selectedUser, stats, year } = this.props;
    const badMarginPercentage =
      stats && stats.GoodMargin && stats.BadMargin ? (stats.BadMargin / (stats.BadMargin + stats.GoodMargin)) * 100 : 0;
    const openPeriod = { beginning: new Date(0), end: new Date() };
    const curYear = year ? year : new Date().getFullYear();
    const beginning = new Date(curYear, 0, 1, 0, 0, 0, 0);
    const end = new Date(beginning);
    end.setFullYear(beginning.getFullYear() + 1);
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="row row-no-padding row-col-separator-xl h-100">
            <div className="col-md-12 col-lg-12 col-xl-4 ">
              <div className="kt-widget1 h-100">
                <div className="kt-widget1__item h-25">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">
                      {dashboard === "sales" ? "Offenes Angebotsvolumen" : "Open offer volume"}
                    </h3>
                  </div>
                  <span className="kt-widget1__number kt-font-success">
                    {stats && stats.OfferVolume ? baseUtils.formatEuro(stats.OfferVolume) : "0.00€"}
                  </span>
                </div>
                <div className="kt-widget1__item h-25">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">
                      {dashboard === "sales"
                        ? "Buchumsatz"
                        : "Book turnover" + (year ? " (Orders from " + year + ")" : "")}
                    </h3>
                  </div>
                  <span className="kt-widget1__number kt-font-success">
                    {baseUtils.formatEuro(dashboardUtils.getOutstandingOrderVolume(orders, openPeriod))}
                  </span>
                </div>
                <div className="kt-widget1__item h-25">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">
                      {(dashboard === "sales"
                        ? "Buchmarge"
                        : "Book margin" + (year ? " (Orders from " + year + ")" : "")) + " (€)"}
                    </h3>
                  </div>
                  <span className="kt-widget1__number kt-font-success">
                    {baseUtils.formatEuro(dashboardUtils.getOutstandingMargin(orders, openPeriod))}
                  </span>
                </div>
                <div className="kt-widget1__item h-25">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">
                      {(dashboard === "sales"
                        ? "Buchmarge"
                        : "Book margin" + (year ? " (Orders from " + year + ")" : "")) + " (%)"}
                    </h3>
                  </div>
                  <span className="kt-widget1__number kt-font-success">
                    {stats && stats.BookMargin ? stats.BookMargin.toFixed(2) + "%" : "0.00%"}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-xl-4 ">
              <div className="kt-widget1 h-100">
                <div className="kt-widget1__item h-25">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">
                      {dashboard === "sales" ? "Geschriebener Umsatz" : "Written turnover"}
                    </h3>
                  </div>
                  <span className="kt-widget1__number kt-font-success">
                    {baseUtils.formatEuro(
                      dashboardUtils.getAlreadyInvoicedVolume(orders, { beginning, end }, selectedUser)
                    )}
                  </span>
                </div>
                <div className="kt-widget1__item h-25">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">
                      {(dashboard === "sales" ? "Geschriebene Marge" : "Written margin") + " (€)"}
                    </h3>
                  </div>
                  <span className="kt-widget1__number kt-font-success">
                    {stats && stats.TotalMargin ? baseUtils.formatEuro(stats.TotalMargin) : "0.00€"}
                  </span>
                </div>
                <div className="kt-widget1__item h-25">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">
                      {(dashboard === "sales" ? "Geschriebene Marge" : "Written margin") + " (%)"}
                    </h3>
                  </div>
                  <span className="kt-widget1__number kt-font-success">
                    {stats && stats.WrittenMargin ? stats.WrittenMargin.toFixed(2) + "%" : "0.00%"}
                  </span>
                </div>
                <div className="kt-widget1__item h-25">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">
                      {dashboard === "sales" ? "Geschriebene Provision" : "Production time"}
                    </h3>
                  </div>
                  <span className="kt-widget1__number kt-font-success">
                    {dashboard === "sales"
                      ? stats && stats.Commission
                        ? baseUtils.formatEuro(stats.Commission)
                        : "0.00€"
                      : stats && stats.totalTime
                      ? stats.totalTime.toFixed(2) + " Days"
                      : "0 Days"}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-xl-4 ">
              <div className="kt-widget1 h-100">
                <div className="kt-widget1__item h-25">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">
                      {dashboard === "sales" ? "Ausgewertete Angebote & Aufträge" : "Evaluated offers & orders"}
                    </h3>
                  </div>
                  <span className="kt-widget1__number kt-font-success">
                    {stats && stats.TotalResults ? stats.TotalResults : "0"}
                  </span>
                </div>
                <div className="kt-widget1__item h-25">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">
                      {dashboard === "sales" ? "Offene Angebote" : "Open offers"}
                    </h3>
                  </div>
                  <span className="kt-widget1__number kt-font-success">
                    {stats && stats.PendingOffers ? stats.PendingOffers : "0"}
                  </span>
                </div>
                <div className="kt-widget1__item h-25">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">
                      {dashboard === "sales" ? "Offene Aufträge" : "Open orders"}
                    </h3>
                  </div>
                  <span className="kt-widget1__number kt-font-success">
                    {stats && stats.PendingOrders ? stats.PendingOrders : "0"}
                  </span>
                </div>
                <div className="kt-widget1__item h-25">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title kt-font-bolder">
                      {dashboard === "sales" ? "Anteil schlechter Aufträge" : `Orders with >${MARGIN.BAD}% margin`}
                    </h3>
                  </div>
                  <span className="kt-widget1__number kt-font-success">
                    {(dashboard === "sales" ? badMarginPercentage : 100 - badMarginPercentage).toFixed(2) + "%"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SalesStats;
