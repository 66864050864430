import React, { useCallback, useEffect, useState } from "react";
import { useWarehouseContext, useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { KeyboardEventKeyValues } from "../../../../utils/thingsTypeScriptShouldProvideUtils";
import i18n from "../../../../translations/i18n";

const WarehouseSearch: React.FC = () => {
  const warehouseContext = useWarehouseContext();
  const dispatch = useWarehouseDispatch();
  const { query } = warehouseContext;
  const [value, setValue] = useState(!!query ? query : "");

  useEffect(() => {
    if (query !== value) setValue(!!query ? query : "");
  }, [query]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value), []);

  const handleBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      dispatch({
        type: WarehouseActionType.SEARCH,
        payload: { query: e.target.value }
      }),
    []
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Check both since keyCode is deprecated but key may not work in older browsers
    if (e.keyCode === 13 || e.key === KeyboardEventKeyValues.ENTER) {
      e.currentTarget.blur();
      e.currentTarget.focus();
    }
  };

  return (
    <div className="form-group my-0 mb-2 " style={{ width: "400px" }}>
      <div className="input-icon">
        <input
          type="text"
          className="form-control"
          placeholder={`${i18n.t("common:search")}...`}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <span>
          <i className="flaticon2-search-1 icon-md" />
        </span>
      </div>
    </div>
  );
};

export default WarehouseSearch;
