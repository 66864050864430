import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BSON } from "realm-web";
import SettingsTable from "./SettingsTable";
import { DataContext } from "../../context/dataContext";
import dbService, { ALLERGENS } from "../../services/dbService";
import toastUtils from "../../utils/toastUtils";

interface AllergensProps extends RouteComponentProps<{}, {}, {}> {}

interface AllergensState {
  query: string;
}

class Allergens extends PureComponent<AllergensProps, AllergensState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: AllergensProps) {
    super(props);
    this.state = { query: "" };
  }

  /**
   * Handles creating a new allergen.
   * @param _id: ID of the allergen that is created
   * @param de: Name of the allergen in german
   * @param en: Name of the allergen in english
   */
  handleCreateAllergens = async (_id: BSON.ObjectId, de: string, en: string) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.insertDocument(ALLERGENS, { _id, name: { de, en } });
    await toastUtils.databaseOperationToast(
      !!res && res.insertedId,
      "Allergen successfully added",
      "Adding allergen failed",
      () => updateDocumentInContext(ALLERGENS, _id)
    );
  };

  /**
   * Handles editing an allergen.
   * @param _id: ID of the allergen that is edited
   * @param de: Name of the allergen in german
   * @param en: Name of the allergen in english
   */
  handleEditAllergens = async (_id: BSON.ObjectId, de: string, en: string) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.updateDocument(ALLERGENS, _id, { name: { de, en } });
    await toastUtils.databaseOperationToast(
      !!res && res.modifiedCount > 0,
      "Allergen successfully edited",
      "Editing allergen failed",
      () => updateDocumentInContext(ALLERGENS, _id)
    );
  };

  /**
   * Handles deleting an allergen.
   * @param _id: ID of the allergen that should be deleted
   */
  handleDeleteAllergens = async (_id: BSON.ObjectId) => {
    const { updateDocumentInContext } = this.context;
    const res = await dbService.deleteDocument(ALLERGENS, _id);
    await toastUtils.databaseOperationToast(
      !!res && res.deletedCount > 0,
      "Allergen successfully deleted",
      "Deleting allergen failed",
      () => updateDocumentInContext(ALLERGENS, _id)
    );
  };

  render() {
    const { query } = this.state;
    const { commodities, allergens } = this.context;

    const ql = query.toLowerCase();
    const a = _.cloneDeep(
      allergens.filter(a => a.name.de.toLowerCase().includes(ql) || a.name.en.toLowerCase().includes(ql))
    );

    return (
      <SettingsTable
        setting="Allergens"
        settings={a.sort((a1, a2) => a1.name.de.localeCompare(a2.name.de))}
        title={{ table: "Allergens", modal: "Allergen" }}
        usageCheck={_id => commodities.some(c => c.allergens.map(a => a.toString()).includes(_id.toString()))}
        onDelete={this.handleDeleteAllergens}
        onSave={this.handleCreateAllergens}
        onEdit={this.handleEditAllergens}
        onSearch={query => this.setState({ query })}
      />
    );
  }
}

export default withRouter(Allergens);
