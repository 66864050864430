import i18n from "i18next";
import { LanguageObject } from "../model/common.types";

/**
 * Function to retrieve a translation based on the current language and given key and translation file
 * @param key the key of the datasheet locals file
 * @param content the content to use if the key is an empty string
 * @param translationFile the file including the translations
 * @returns {string} the translation or the content if no key was given
 */
function findAndReplaceTranslation(key: string, content: string, translationFile: string): string {
  const keyValue = translationFile + ":" + key;
  return key !== "" ? i18n.t(keyValue, { lng: i18n.language }) : content;
}

export const language = () =>
  (i18n.language.slice(0, 2) || window.localStorage.i18nextLng.slice(0, 2)) as keyof LanguageObject;

export const fullLanguage = () => i18n.language || window.localStorage.i18nextLng;

/**
 * Resolve the translation of a database object.
 * @param translations Object containing the different translations (like DE, EN etc.)
 * @returns {string} translation
 */
export function resolveTranslation(translations: LanguageObject): string {
  const translation = translations[language()];
  return translation ? translation : translations.de;
}

export default findAndReplaceTranslation;
