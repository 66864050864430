import { BSON } from "realm-web";
import * as Realm from "realm-web";
import { toast } from "react-toastify";
import { PackagingPrice, PackagingsDocument } from "../model/packagings.types";
import { CapsulePrice } from "../model/capsules.types";
import { CommoditiesDocument, CommodityPrice } from "../model/commodities.types";
import { ManufacturersDocument } from "../model/manufacturers.types";
import calculationUtils from "./calculationUtils";
import { CustomOrder } from "../components/order/CustomTypes";
import { calculation, calculationInfo, OrdersDocument, pricing, pricingCommodities } from "../model/orders.types";
import {
  T_CAPSULE,
  T_CUSTOM,
  T_LIQUID,
  T_POWDER,
  T_SERVICE,
  T_SOFTGEL,
  T_TABLET
} from "../components/order/OrderHelper";
import orderUtils from "./orderUtils";
import { DataContext } from "../context/dataContext";

export const MARGIN = {
  GOOD: 40,
  BAD: 30,
  CRITICAL: 20
};

/**
 * Calculate the unit price over all commodities of an existing order
 * @param order an order document
 * @returns {number} the unit price
 */
function calculateCommoditiesUnitPrice(order: OrdersDocument | CustomOrder) {
  const type = order.settings.type;
  const calculation = order.calculations[0];
  const commodities = calculation.prices;
  const amountPerUnit = +order.settings.perUnit;
  let totalUnitPrice = 0;
  for (let i = 0; i < commodities.length; i++) {
    const commodity = commodities[i];
    totalUnitPrice += calculateCommodityUnitPrice(type, amountPerUnit, commodity);
  }
  return totalUnitPrice;
}

/**
 * Calculate the unit price over all commodities of an existing order
 * @param order an order document
 * @returns {number} the unit price
 */
function calculatePackagingUnitPrice(order: OrdersDocument | CustomOrder) {
  const packagings = order.calculations[0].packagings;
  return packagings.reduce((a, b) => a + +b.price * +b.amount * (1 + +b.buffer / 100), 0);
}

/**
 * Calculation new calculation info object after changes to a single commodity price
 * @param order an order document
 * @param oldPrice the old commodity price
 * @param newPrice the new commodity price
 * @returns {calculationInfo} the calculation info object with adjusted prices
 */
function getCalculationInfoAfterPriceChange(
  order: OrdersDocument | CustomOrder,
  oldPrice: pricingCommodities,
  newPrice: pricingCommodities
) {
  const type = order.settings.type;
  const oldCommodityUnitPrice = calculateCommodityUnitPrice(type, +order.settings.perUnit, oldPrice);
  const newCommodityUnitPrice = calculateCommodityUnitPrice(type, +order.settings.perUnit, newPrice);
  const calculationInfo = order.calculations[0].info;
  const units = +order.calculations[0].units;
  const unitPriceNaked = calculationInfo.unitpricenaked + (newCommodityUnitPrice - oldCommodityUnitPrice);
  const unitMargin = calculationInfo.unitprice - unitPriceNaked;
  const totalMargin = unitMargin * units;
  const percentMargin = (calculationInfo.unitprice / unitPriceNaked - 1) * 100;
  const calc: calculationInfo = {
    unitprice: calculationInfo.unitprice,
    unitpricenaked: unitPriceNaked,
    unitmargin: unitMargin,
    totalprice: calculationInfo.totalprice,
    totalmargin: totalMargin,
    percentmargin: percentMargin,
    marginBuffer: calculationInfo.marginBuffer ? calculationInfo.marginBuffer : 0
  };
  if (calculationInfo.customCalculation) calc.customCalculation = calculationInfo.customCalculation;
  if (calculationInfo.standardCalculation) calc.standardCalculation = calculationInfo.standardCalculation;
  return calc;
}

/**
 * Calculate the unit price for one commodity
 * @param type the order type
 * @param perUnit the amount per unit
 * @param commodity the commodity price object
 * @returns {number} the commodity's unit price
 */
function calculateCommodityUnitPrice(type: string, perUnit: number, commodity: pricingCommodities) {
  let amount;
  switch (type) {
    case T_CAPSULE:
    case T_TABLET:
      amount = perUnit * +commodity.amount;
      return ((amount * (1 + +commodity.buffer / 100)) / (1000 * 1000)) * commodity.price;
    case T_POWDER:
    case T_LIQUID:
      amount = +commodity.amount;
      return ((amount * (1 + +commodity.buffer / 100)) / (1000 * 1000)) * commodity.price;
    case T_CUSTOM:
    case T_SOFTGEL:
      amount = perUnit * +commodity.amount;
      return ((amount * (1 + +commodity.buffer / 100)) / 1000) * commodity.price;
    case T_SERVICE:
      return commodity.price;
    default:
      return 0;
  }
}

/**
 * Find a matching price for a commodity and the given parameters
 * @param commodity a commodities document
 * @param manufacturer the manufacturer, used for stock calculation
 * @param orderQuantity the total order quantity of the commodity
 * @param supplierId optional id of a supplier to only search priches for
 * @param targetIncoterm optional id of an incoterm to only search prices for, requires supplierId
 * @param deliveryTime optional delivery time to only search prices for, requires incoterm + supplier
 * @param fallbackLocation optional if provided the stock price of this location is checked if the manufacturer got no stock
 * @returns {_id: BSON.ObjectId | "customer", price: CommodityPrice} object with id of the supplier and a commodity price object
 */
function getMatchingCommodityPrice(
  commodity: CommoditiesDocument,
  manufacturer: ManufacturersDocument,
  orderQuantity: number,
  supplierId?: BSON.ObjectId | string,
  targetIncoterm?: string,
  deliveryTime?: number,
  fallbackLocation?: ManufacturersDocument
): { _id: BSON.ObjectId | "ownstock" | "customer"; price: CommodityPrice } {
  let price: { _id: BSON.ObjectId | "ownstock" | "customer"; price: CommodityPrice } | undefined;
  if (typeof supplierId === "string" && !BSON.ObjectId.isValid(supplierId)) {
    if (["custom", "customer"].includes(supplierId)) {
      return { _id: "customer", price: calculationUtils.createCustomerCommodityPrice() };
      // get default for customer
    } else if (["accumulatedstock", "ownstock"].includes(supplierId)) {
      // get default for manufacturer
      price = {
        _id: "ownstock",
        price: calculationUtils.getCommodityStockPriceForManufacturer(commodity.stock, manufacturer)
      };
      if (price.price.price === 0 && fallbackLocation) {
        price.price = calculationUtils.getCommodityStockPriceForManufacturer(commodity.stock, fallbackLocation);
      }
    }
  }
  if (price && !isNaN(price.price.price)) return price;
  // order quantity already in correct format to compare with moq
  price = getPriceForMOQ(commodity, orderQuantity, supplierId, targetIncoterm, deliveryTime);
  // if no price was found for delivery time, try without. To be clear this should not actually happen, this is just to
  // have some kind of fallback
  if (!price && deliveryTime) price = getPriceForMOQ(commodity, orderQuantity, supplierId, targetIncoterm);
  // if not price was found and targetIncoterm was given, try without
  if (!price && targetIncoterm) price = getPriceForMOQ(commodity, orderQuantity, supplierId);
  // if not price was found, dont search for a specific supplier if one was given
  if (!price && supplierId) price = getPriceForMOQ(commodity, orderQuantity);
  if (!price) return { _id: "customer", price: calculationUtils.createCustomerCommodityPrice() };
  return price;
}

/**
 * Get a matching price for a commodity and the given parameters
 * @param commodity a commodities document
 * @param quantity the total order quantity of the commodity
 * @param supplierId optional id of a supplier to only search priches for
 * @param targetIncoterm optional id of an incoterm to only search prices for, requires supplierId
 * @param deliveryTime optional delivery time to only search prices for, requires incoterm + supplier
 * @param silent optional, do not show toasts or errors
 * @returns {_id: BSON.ObjectId | "customer", price: CommodityPrice} object with id of the supplier and a commodity price object
 */
function getPriceForMOQ(
  commodity: CommoditiesDocument,
  quantity: number,
  supplierId?: BSON.ObjectId | string,
  targetIncoterm?: string,
  deliveryTime?: number,
  silent?: boolean
) {
  const commoditySuppliers = commodity.suppliers;
  // Collect all prices
  let allPrices: Array<{ id: Realm.BSON.ObjectId; price: CommodityPrice }> = [];
  let lowestMOQ: number;
  commoditySuppliers.forEach(cSupplier => {
    if (!supplierId || cSupplier._id.toString() === supplierId.toString()) {
      cSupplier.prices.forEach(sPrice => {
        if (
          !targetIncoterm ||
          (sPrice.incoterm === targetIncoterm && (!deliveryTime || sPrice.deliverytime === deliveryTime))
        ) {
          const moq = sPrice.moq;
          if ((!lowestMOQ && lowestMOQ !== 0) || moq < lowestMOQ) lowestMOQ = moq;
          allPrices.push({ id: cSupplier._id as Realm.BSON.ObjectId, price: sPrice });
        }
      });
    }
  });
  if (allPrices.length === 0) {
    if (!supplierId && !targetIncoterm && !silent) toast.error("No supplier and prices found.");
    if (!silent) console.error("No suppliers found for commodity", commodity._id.toString());
    return;
  }
  const foundMatchingMOQ = quantity >= lowestMOQ!;
  if (foundMatchingMOQ) {
    // If we found a matching moq remove all non-matching moqs
    allPrices = allPrices.filter(price => price.price.moq <= quantity);
  } else {
    return;
  }
  return getLowestPriceFromSupplier(commoditySuppliers, allPrices) as { _id: BSON.ObjectId; price: CommodityPrice };
}

/**
 * Find a matching price for a packaging and the given parameters
 * @param packaging a packaging document
 * @param manufacturer the manufacturer, used for stock calculation
 * @param orderQuantity the total order quantity of the commodity
 * @param context the data context
 * @param supplierId optional id of a supplier to only search priches for
 * @param targetDelivery optional delivery option to only search prices for, requires supplierId
 * @param deliveryTime optional delivery time to only search prices for, requires delivery + supplier
 * @returns {_id: BSON.ObjectId | "customer", price: PackagingPrice} object with id of the supplier and a commodity price object
 */
function getMatchingPackagingPrice(
  packaging: PackagingsDocument,
  manufacturer: ManufacturersDocument,
  orderQuantity: number,
  context: React.ContextType<typeof DataContext>,
  supplierId?: BSON.ObjectId | string,
  targetDelivery?: string,
  deliveryTime?: number
): { _id: BSON.ObjectId | "ownstock" | "customer"; price: PackagingPrice } {
  const packagingStock = context.packagingStock.filter(pS => pS.packaging.toString() === packaging._id.toString());
  let price: { _id: BSON.ObjectId | "ownstock" | "customer"; price: PackagingPrice } | undefined;
  if (typeof supplierId === "string" && !BSON.ObjectId.isValid(supplierId)) {
    if (["custom", "customer"].includes(supplierId)) {
      return { _id: "customer", price: calculationUtils.createCustomerPackagingPrice() };
      // get default for customer
    } else if (["accumulatedstock", "ownstock"].includes(supplierId)) {
      // get default for manufacturer
      price = {
        _id: "ownstock",
        price: calculationUtils.getPackagingStockPriceForManufacturer(packagingStock, manufacturer)
      };
    }
  }
  if (price && !isNaN(price.price.price)) return price;
  // order quantity already in correct format to compare with moq
  price = getPackagingPriceForMOQ(packaging, orderQuantity, supplierId, targetDelivery, deliveryTime);
  // if no price was found for delivery time, try without. To be clear this should not actually happen, this is just to
  // have some kind of fallback
  if (!price && deliveryTime) price = getPackagingPriceForMOQ(packaging, orderQuantity, supplierId, targetDelivery);
  // if not price was found and targetIncoterm was given, try without
  if (!price && targetDelivery) price = getPackagingPriceForMOQ(packaging, orderQuantity, supplierId);
  // if not price was found, dont search for a specific supplier if one was given
  if (!price && supplierId) price = getPackagingPriceForMOQ(packaging, orderQuantity);
  if (!price) return { _id: "customer", price: calculationUtils.createCustomerPackagingPrice() };
  return price;
}

/**
 * Get a matching price for a packaging and the given parameters
 * @param packaging a packaging document
 * @param quantity the total order quantity of the commodity
 * @param supplierId optional id of a supplier to only search priches for
 * @param targetDelivery optional delivery option to only search prices for, requires supplierId
 * @param deliveryTime optional delivery time to only search prices for, requires delivery + supplier
 * @param silent optional, do not show toasts or errors
 * @returns {_id: BSON.ObjectId | "customer", price: PackagingPrice} object with id of the supplier and a commodity price object
 */
function getPackagingPriceForMOQ(
  packaging: PackagingsDocument,
  quantity: number,
  supplierId?: BSON.ObjectId | string,
  targetDelivery?: string,
  deliveryTime?: number,
  silent?: boolean
) {
  const packagingSuppliers = packaging.suppliers;
  // Collect all prices
  let allPrices: Array<{ id: Realm.BSON.ObjectId; price: PackagingPrice }> = [];
  let lowestMOQ: number;
  packagingSuppliers.forEach(cSupplier => {
    if (!supplierId || cSupplier._id.toString() === supplierId.toString()) {
      cSupplier.prices.forEach(sPrice => {
        if (
          !targetDelivery ||
          (sPrice.delivery === targetDelivery && (!deliveryTime || sPrice.deliverytime === deliveryTime))
        ) {
          const moq = sPrice.moq;
          if (!lowestMOQ || moq < lowestMOQ) lowestMOQ = moq;
          allPrices.push({ id: cSupplier._id as Realm.BSON.ObjectId, price: sPrice });
        }
      });
    }
  });
  if (allPrices.length === 0) {
    if (!supplierId && !targetDelivery && !silent) toast.error("No supplier and prices found.");
    if (!silent) console.error("No suppliers found for packaging", packaging._id.toString());
    return;
  }
  const foundMatchingMOQ = quantity >= lowestMOQ!;
  if (foundMatchingMOQ) {
    // If we found a matching moq remove all non-matching moqs
    allPrices = allPrices.filter(price => price.price.moq <= quantity);
  } else {
    return;
  }
  return getLowestPriceFromSupplier(packagingSuppliers, allPrices) as { _id: BSON.ObjectId; price: PackagingPrice };
}

/**
 * Get lowest price from extracted suppliers for packaging or commodity
 * @param objectSuppliers collected suppliers for a packaging or commodity document
 * @param allPrices all collected prices for a packaging or commodity document
 * @returns object with id, name and price of the best matching supplier and price
 */
function getLowestPriceFromSupplier(
  objectSuppliers: Array<{ _id: BSON.ObjectId; prices: Array<PackagingPrice | CommodityPrice | CapsulePrice> }>,
  allPrices: Array<{ id: Realm.BSON.ObjectId; price: PackagingPrice | CommodityPrice | CapsulePrice }>
) {
  allPrices.sort((a, b) => a.price.price - b.price.price);
  const lowestPrice = allPrices[0];
  return { _id: lowestPrice.id, price: lowestPrice.price };
}

/**
 * Recalculate the calculation info by subtracting the old commodity unit price and adding the new commodity unit price
 * @param order an order document
 * @param newCalculation the updated calculation
 * @returns {calculationInfo} the calculation info with updated prices
 */
function recalculateInfoOnCommodityChanges(order: CustomOrder | OrdersDocument, newCalculation: calculation) {
  const oldCalculation = order.calculations.find(c => c.id.toString() === newCalculation.id.toString());
  if (!oldCalculation) return;
  const type = order.settings.type;
  const amountPerUnit = +order.settings.perUnit;
  const oldCommodityUnitPrice = calculateCommoditiesUnitPriceForCalculation(oldCalculation, type, amountPerUnit);
  const newCommodityUnitPrice = calculateCommoditiesUnitPriceForCalculation(newCalculation, type, amountPerUnit);
  return getUpdatedCalculationInfo(oldCalculation, oldCommodityUnitPrice, newCommodityUnitPrice);
}

/**
 * Calculate the unit price over all commodities of an existing order
 * @param calculation a calculation
 * @param type the type of the order
 * @param amountPerUnit the amount per unit
 * @returns {number} the unit price
 */
function calculateCommoditiesUnitPriceForCalculation(calculation: calculation, type: string, amountPerUnit: number) {
  const commodities = calculation.prices;
  let totalUnitPrice = 0;
  for (let i = 0; i < commodities.length; i++) {
    const commodity = commodities[i];
    totalUnitPrice += calculateCommodityUnitPrice(type, amountPerUnit, commodity);
  }
  return totalUnitPrice;
}
/**
 * Recalculate the calculation info by subtracting the old packaging unit price and adding the new packaging unit price
 * @param order an order document
 * @param newCalculation the updated calculation
 * @returns {calculationInfo} the calculation info with updated prices
 */
function recalculateInfoOnPackagingChanges(order: CustomOrder | OrdersDocument, newCalculation: calculation) {
  const oldCalculation = order.calculations.find(c => c.id.toString() === newCalculation.id.toString());
  if (!oldCalculation) return;
  const oldPackagingUnitPrice = oldCalculation.packagings.reduce((a, b) => a + +b.price * +b.amount, 0);
  const newPackagingUnitPrice = newCalculation.packagings.reduce((a, b) => a + +b.price * +b.amount, 0);
  return getUpdatedCalculationInfo(oldCalculation, oldPackagingUnitPrice, newPackagingUnitPrice);
}

/**
 * Get an updated calculation info
 * @param calculation the calculation to update the calculation info for
 * @param oldUnitPrice the old unit price to subtract
 * @param newUnitPrice the new unit price to add
 * @returns {calculationInfo} the updated calculation info object
 */
function getUpdatedCalculationInfo(calculation: calculation, oldUnitPrice: number, newUnitPrice: number) {
  const calculationInfo = calculation.info;
  const units = +calculation.units;
  const unitPriceNaked = calculationInfo.unitpricenaked + (newUnitPrice - oldUnitPrice);
  const unitMargin = calculationInfo.unitprice - unitPriceNaked;
  const totalMargin = unitMargin * units;
  const percentMargin = (calculationInfo.unitprice / unitPriceNaked - 1) * 100;
  const calc: calculationInfo = {
    unitprice: calculationInfo.unitprice,
    unitpricenaked: unitPriceNaked,
    unitmargin: unitMargin,
    totalprice: calculationInfo.totalprice,
    totalmargin: totalMargin,
    percentmargin: percentMargin,
    marginBuffer: calculationInfo.marginBuffer ? calculationInfo.marginBuffer : 0
  };
  if (calculationInfo.customCalculation) calc.customCalculation = calculationInfo.customCalculation;
  return calc;
}

/**
 * Find a valid matching or the minimum moq for a commodity or packaging
 * @param document a commodity or packaging document
 * @param supplierId the supplier id
 * @param quantity the order quantity to find matching moq for
 * @returns {number} the lowest valid moq or the minimum moq if no matching moq was found
 */
function getMatchingMOQ(
  document: CommoditiesDocument | PackagingsDocument,
  supplierId: BSON.ObjectId | string,
  quantity: number
) {
  let minMoq = Number.POSITIVE_INFINITY;
  let validMoq = -1;
  for (let i = 0; i < document.suppliers.length; i++) {
    const supplier = document.suppliers[i];
    if (supplier._id.toString() === supplierId.toString()) {
      for (let j = 0; j < supplier.prices.length; j++) {
        const moq = +supplier.prices[j].moq;
        if (moq <= minMoq) {
          minMoq = moq;
        }
        if (moq <= quantity && validMoq < moq) {
          validMoq = moq;
        }
      }
    }
  }
  // return min moq if no valid moq was found
  return validMoq <= quantity && validMoq >= 0 ? validMoq : minMoq;
}

/**
 * Calculate the bound capital of an order
 * @param order an order document
 * @returns {number} the bound capital
 */
function getBoundCapital(order: OrdersDocument | CustomOrder) {
  let boundCapital = 0;
  const calculation = order.calculations[0];
  // @ts-ignore
  const allPrices: Array<pricingCommodities | pricing> = calculation.prices.concat(calculation.packagings);
  for (let i = 0; i < allPrices.length; i++) {
    const price = allPrices[i];
    if (price.orderquantity) {
      boundCapital += price.orderquantity * price.price;
    } else {
      const requiredAmount = orderUtils.getTotalAmountWithBuffer(
        +order.settings.perUnit,
        +calculation.units,
        +price.amount,
        +price.buffer,
        order.settings.type
      );
      boundCapital += requiredAmount * price.price;
    }
  }
  return boundCapital;
}

/**
 * Get the cost per produced unit for a commodity price entry
 * @param type the order type
 * @param pricePerUnit price per kg/tsd./pcs.
 * @param buffer included buffer
 * @param commodityAmount the amount for the commodity without buffer
 * @param amountPerUnit the amount per unit for capsules, tables, softgels,..., e.g., 120 capsules per unit.
 * @returns {number} commodity cost per produced unit
 */
const getCommodityPricePerProducedUnit = (
  type: string,
  pricePerUnit: number,
  buffer: number,
  commodityAmount: number,
  amountPerUnit: number
): number => {
  let pricePerProducedUnit = 0;
  if (type === T_CAPSULE || type === T_TABLET)
    pricePerProducedUnit = (pricePerUnit * (1 + buffer / 100) * commodityAmount * amountPerUnit) / (1000 * 1000);
  else if (type === T_POWDER || type === T_LIQUID)
    pricePerProducedUnit = (pricePerUnit * (1 + buffer / 100) * commodityAmount) / (1000 * 1000);
  else if (type === T_CUSTOM || type === T_SOFTGEL)
    pricePerProducedUnit = (pricePerUnit * (1 + buffer / 100) * commodityAmount * amountPerUnit) / 1000;
  else if (type === T_SERVICE) return pricePerUnit;
  return pricePerProducedUnit;
};

export default {
  getMatchingCommodityPrice,
  getMatchingPackagingPrice,
  recalculateInfoOnCommodityChanges,
  recalculateInfoOnPackagingChanges,
  getCalculationInfoAfterPriceChange,
  calculateCommoditiesUnitPrice,
  calculatePackagingUnitPrice,
  getMatchingMOQ,
  getPriceForMOQ,
  getPackagingPriceForMOQ,
  getBoundCapital,
  getCommodityPricePerProducedUnit
};
