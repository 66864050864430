import React, { useMemo } from "react";
import { getBatchPackagingUnitDescription } from "../../../../../utils/warehouseUtils";
import Tooltip from "../../../../common/Tooltip";
import { ExtendedBatchLocation } from "../../../../../model/warehouse/customTypes.types";
import i18n from "../../../../../translations/i18n";

interface RemoteWarehouseStockProps {
  location: ExtendedBatchLocation;
  onChangeRemoteWarehouseAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoteWarehouseTakeAll: () => void;
}

export const RemoteWarehouseStock: React.FC<RemoteWarehouseStockProps> = ({
  location,
  onChangeRemoteWarehouseAmount,
  onRemoteWarehouseTakeAll
}) => {
  const amountLeft = useMemo(
    () =>
      location.amountAtLocation.value -
      (location.remoteWarehouseData ? +location.remoteWarehouseData.amountToBookOut : 0),
    [location.amountAtLocation, location.remoteWarehouseData]
  );

  const nothingLeft = useMemo(() => amountLeft <= 0, [amountLeft]);

  return (
    <>
      <tr className={"kt-datatable__row d-table-row "}>
        <td colSpan={7} className="px-0">
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive py-2 px-5 bg-light mb-0">
            <table className="kt-datatable__table d-table bg-light">
              <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
                <tr className="kt-datatable__row d-table-row border-0">
                  <th className="kt-datatable__cell d-table-cell pb-0 ">
                    <span className="font-weight-300">{i18n.t("warehouse:stock")}</span>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </td>
      </tr>
      <tr className={"kt-datatable__row d-table-row"}>
        <td colSpan={7} className="px-0">
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive py-2 px-5 mb-0">
            <table className="kt-datatable__table d-table p-5">
              <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
                <tr className="kt-datatable__row d-table-row">
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "24%" }}>
                    <span className="font-weight-300">{i18n.t("warehouse:packagingUnits")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "23%" }}>
                    <span className="font-weight-300">{i18n.t("warehouse:amount")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "23%" }}>
                    <span className="font-weight-300">{i18n.t("warehouse:usedAmount")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "20%" }}>
                    <span className="font-weight-300">{i18n.t("warehouse:restAmount")}</span>
                  </th>
                  <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "10%" }} />
                </tr>
              </thead>
              <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                <tr className="kt-datatable__row d-table-row border-0">
                  <td className="kt-datatable__cell d-table-cell pb-0">
                    <div className="kt-user-card-v2">
                      <div className="kt-user-card-v2__details ">
                        <span className="kt-user-card-v2__name text-black ">
                          {getBatchPackagingUnitDescription(location.packagingUnits)}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="kt-datatable__cell d-table-cell pb-0">
                    <div className="input-group input-group-sm">
                      <input
                        type={"text"}
                        disabled
                        className="form-control form-control-sm form-control-solid "
                        value={location.amountAtLocation.value}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text input-group-solid">{location.amountAtLocation.unit}</span>
                      </div>
                      <div className="input-group-append input-group-solid">
                        <Tooltip tooltipText={i18n.t(`warehouse:${nothingLeft ? "nothingLeft" : "useTotalAmount"}`)}>
                          <button
                            className={"btn btn-light " + (nothingLeft ? "disabled" : "")}
                            onClick={nothingLeft ? undefined : onRemoteWarehouseTakeAll}
                          >
                            <i className="fas fa-angle-double-right pr-0" />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                  </td>
                  <td className="kt-datatable__cell d-table-cell pb-0">
                    <div className="input-group input-group-sm">
                      <input
                        type={"number"}
                        min={0}
                        className={
                          "form-control form-control-sm form-control-solid hide-arrows " +
                          (amountLeft < 0 ? "is-invalid" : "")
                        }
                        value={location.remoteWarehouseData?.amountToBookOut}
                        onChange={onChangeRemoteWarehouseAmount}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text input-group-solid">{location.amountAtLocation.unit}</span>
                      </div>
                    </div>
                  </td>
                  <td className="kt-datatable__cell d-table-cell pb-0">
                    <div className="input-group input-group-sm">
                      <input
                        type={"text"}
                        disabled
                        className="form-control form-control-sm form-control-solid "
                        value={amountLeft}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text input-group-solid">{location.amountAtLocation.unit}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </>
  );
};
