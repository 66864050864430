import React, { useMemo } from "react";
import i18n from "../../../../translations/i18n";
import { OrdersDocument } from "../../../../model/orders.types";
import { CommodityInformation, CommodityReservationInformation } from "../../../../model/warehouse/customTypes.types";
import baseUtils from "../../../../utils/baseUtils";
import calculationUtils from "../../../../utils/calculationUtils";
import { useDataContext } from "../../../../context/dataContext";
import { CommoditiesDocument } from "../../../../model/commodities.types";

interface OrderReservationInformationProps {
  selectedOrder: OrdersDocument;
  selectedCommodityId: string | undefined;
  commodityInformation: Map<string, CommodityInformation>;
  orderReservations: Array<CommodityReservationInformation>;
  onSelectCommodity: (commodityId: string) => void;
}

const OrderReservationInformation: React.FC<OrderReservationInformationProps> = ({
  selectedOrder,
  selectedCommodityId,
  commodityInformation,
  orderReservations,
  onSelectCommodity
}) => {
  return (
    <>
      <h4 className="font-weight-bold text-black">{`AT-${selectedOrder.identifier} ${selectedOrder.title}`}</h4>
      <div className="text-black mb-3">{selectedOrder.subtitle}</div>
      <div className="p-2 py-4 mb-4 bg-light">
        <div className="row mx-3 justify-content-end">
          <OrderCommodityInfo
            selectedOrder={selectedOrder}
            selectedCommodity={selectedCommodityId ? commodityInformation.get(selectedCommodityId) : undefined}
            commodityInformation={commodityInformation}
          />
          <OrderRecipeInfo
            orderReservations={orderReservations}
            commodityInformation={commodityInformation}
            selectedCommodityId={selectedCommodityId}
            onSelectCommodity={onSelectCommodity}
          />
        </div>
      </div>
    </>
  );
};

interface OrderCommodityInfoProps {
  selectedOrder: OrdersDocument;
  selectedCommodity: CommodityInformation | undefined;
  commodityInformation: Map<string, CommodityInformation>;
}

const OrderCommodityInfo: React.FC<OrderCommodityInfoProps> = ({
  selectedOrder,
  selectedCommodity,
  commodityInformation
}) => {
  const dataContext = useDataContext();
  const { manufacturers, commodities } = dataContext;

  const manufacturer = useMemo(
    () => manufacturers.find(m => m._id.toString() === selectedOrder.settings.manufacturer.toString()),
    [selectedOrder, manufacturers]
  );

  const readyForProduction = useMemo(() => {
    const allCommodities = Array.from(commodityInformation.values());
    const reservedCommodities = allCommodities.filter(
      commodity => commodity.reservedAmount.value >= commodity.neededAmount.value
    );
    return allCommodities.length === reservedCommodities.length;
  }, [commodityInformation]);

  const hasCommodityOrders = useMemo(() => {
    const commodity: CommoditiesDocument = selectedCommodity
      ? baseUtils.getDocFromCollection(commodities, selectedCommodity.commodityId)
      : undefined;
    if (!commodity) return null;
    return commodity.orders && commodity.orders.some(cO => !cO.delivered);
  }, [commodities, selectedCommodity]);

  return (
    <div className="col-6">
      <h5 className="text-black font-weight-bold mb-3 d-block">{i18n.t("common:informationPlural")}</h5>
      <div className="form-group form-group-xs d-flex row w-100">
        <div className="col-3 text-black kt-font-bold">{i18n.t("warehouse:commodity")}</div>
        <div className="col-9 text-black text-right">
          {selectedCommodity?.commodityName ?? i18n.t("warehouse:reservationNoCommoditySelectedError")}
        </div>
      </div>
      <div className="form-group form-group-xs d-flex row w-100">
        <div className="col-6 text-black kt-font-bold">{i18n.t("warehouse:neededAmount")}</div>
        <div className="col-6 text-black text-right">
          {selectedCommodity ? `ca. ${calculationUtils.formatAmount(selectedCommodity.neededAmount.value, 2)}` : ""}
        </div>
      </div>
      <div className="form-group form-group-xs d-flex row w-100">
        <div className="col-3 text-black kt-font-bold">{i18n.t("warehouse:production")}</div>
        <div className="col-9 text-black text-right">{manufacturer ? manufacturer.name : "-"}</div>
      </div>
      <div className="form-group form-group-xs d-flex row w-100">
        <div className="col-6 text-black kt-font-bold">{i18n.t("warehouse:targetDate")}</div>
        <div className={"col-6 text-right " + (selectedOrder.targetDate ? "text-warning" : "text-black")}>
          {selectedOrder.targetDate ? baseUtils.formatDate(selectedOrder.targetDate) : "-"}
        </div>
      </div>
      <div className="form-group form-group-xs d-flex row w-100">
        <div className="col-6 text-black kt-font-bold">{i18n.t("warehouse:orders")}</div>
        <div className={"col-6 text-right " + (hasCommodityOrders ? "text-success" : "text-danger")}>
          {hasCommodityOrders ? i18n.t("packaging:yes") : i18n.t("packaging:no")}
        </div>
      </div>
      <div className="form-group form-group-xs d-flex row w-100">
        <div className="col-6 text-black kt-font-bold">{i18n.t("warehouse:readyForProduction")}</div>
        <div className={"col-6 text-right " + (readyForProduction ? "text-success" : "text-danger")}>
          {readyForProduction ? i18n.t("packaging:yes") : i18n.t("packaging:no")}
        </div>
      </div>
    </div>
  );
};

interface OrderRecipeInfoProps {
  orderReservations: Array<CommodityReservationInformation>;
  commodityInformation: Map<string, CommodityInformation>;
  selectedCommodityId: string | undefined;
  onSelectCommodity: (commodityId: string) => void;
}

const OrderRecipeInfo: React.FC<OrderRecipeInfoProps> = ({
  orderReservations,
  commodityInformation,
  selectedCommodityId,
  onSelectCommodity
}) => {
  const usedCommodities = useMemo(() => Array.from(commodityInformation.values()), [commodityInformation]);

  const totalReady = useMemo(() => {
    let count = 0;
    for (let i = 0; i < usedCommodities.length; i++) {
      const currentCommodity = usedCommodities[i];
      const { reservedAmount, neededAmount } = currentCommodity;
      const pending = neededAmount.value - reservedAmount.value;
      if (pending <= 0) {
        count++;
      } else {
        const newReservedAmount = orderReservations.reduce((totalAmount, reservation) => {
          if (reservation.commodityId === currentCommodity.commodityId) {
            return totalAmount + reservation.amount.value;
          } else {
            return totalAmount;
          }
        }, 0);
        if (newReservedAmount > pending) count++;
      }
    }
    return count;
  }, [usedCommodities, orderReservations]);

  return (
    <div className="col-6 kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive px-3 mb-0">
      <h5 className="text-black font-weight-bold mb-3 d-block">
        {i18n.t("pdf:recipe")} ({totalReady} / {usedCommodities.length} {i18n.t("warehouse:ready")})
      </h5>
      <div className="overflow-auto row" style={{ maxHeight: "150px" }}>
        <div className="col-12">
          <table className="kt-datatable__table d-table">
            <tbody className="bg-light">
              {usedCommodities.map(cI => (
                <OrderRecipeInfoRow
                  key={cI.commodityId}
                  commodityInformation={cI}
                  orderReservations={orderReservations.filter(r => r.commodityId === cI.commodityId)}
                  selectedCommodityId={selectedCommodityId}
                  onSelectCommodity={onSelectCommodity}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

interface OrderRecipeInfoRowProps {
  commodityInformation: CommodityInformation;
  orderReservations: Array<CommodityReservationInformation>;
  selectedCommodityId: string | undefined;
  onSelectCommodity: (commodityId: string) => void;
}

const OrderRecipeInfoRow: React.FC<OrderRecipeInfoRowProps> = ({
  commodityInformation,
  orderReservations,
  selectedCommodityId,
  onSelectCommodity
}) => {
  const isReserved = commodityInformation.reservedAmount.value >= commodityInformation.neededAmount.value;
  const pendingAmount = isReserved
    ? 0
    : commodityInformation.neededAmount.value - commodityInformation.reservedAmount.value;
  const selectionReservedAmount = useMemo(() => {
    return orderReservations.reduce(
      (commodityReservedAmount, reservation) => commodityReservedAmount + reservation.amount.value,
      0
    );
  }, [orderReservations]);

  return (
    <tr
      key={commodityInformation.commodityId.toString()}
      className={
        "kt-datatable__row d-table-row table-hover " +
        (selectedCommodityId === commodityInformation.commodityId.toString() ? "table-active-select" : "")
      }
      onClick={() => onSelectCommodity(commodityInformation.commodityId.toString())}
    >
      <td className="kt-datatable__cell d-table-cell" style={{ width: "75%" }}>
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black kt-font-bold text-ellipsis" style={{ maxWidth: "200px" }}>
              {commodityInformation.commodityName}
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell text-right" style={{ width: "25%" }}>
        <div
          className={
            `kt-font-bold text-right ` +
            (isReserved || selectionReservedAmount >= pendingAmount ? "text-success" : "text-warning")
          }
        >
          {isReserved
            ? i18n.t("warehouse:reserved")
            : selectionReservedAmount >= pendingAmount
            ? i18n.t("warehouse:ready")
            : i18n.t("warehouse:pending")}
        </div>
      </td>
    </tr>
  );
};

export default OrderReservationInformation;
