import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AllocatedInfoTooltip = () => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="allocatedInfo">
          This value indicates the amount that was already allocated for <br />
          <b>other</b> orders via the "Reserve Packaging" dialog on the order <br />
          page, i.e. the amount is not yet <b>booked out</b> but also may not be available. <br />
          This value is already subtracted from the displayed "Remaining" value
        </Tooltip>
      }
    >
      <i className="fa fa-info-circle ml-2 text-muted" />
    </OverlayTrigger>
  );
};

export default AllocatedInfoTooltip;
