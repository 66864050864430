import * as Realm from "realm-web";
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Tooltip } from "@material-ui/core";
import commodityUtils from "../../utils/commodityUtils";
import ConfiguratorHelper from "./ConfiguratorHelper";
import calculationUtils from "../../utils/calculationUtils";
import { SubstanceCalculationModal } from "./SubstanceCalculationModal";
import { CalculationType, CustomCommoditiesDocument, Preferences, SelectedCommoditiesDocument } from "./CustomTypes";
import { ProductTypes } from "./configuratorConstants";
import OpenCommodityIcon from "../commodities/common/OpenCommodityIcon";

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  padding: 8,
  paddingLeft: 18,
  paddingRight: 18,
  marginBottom: 8,
  background: isDragging ? "#eff0f5" : "white",
  ...draggableStyle
});

interface CommoditySelectionItemProps {
  commodity: CustomCommoditiesDocument | SelectedCommoditiesDocument;
  index: number;
  availableStock: number;
}

export const CommoditySelectionItem: React.FunctionComponent<CommoditySelectionItemProps> = ({
  commodity,
  index,
  availableStock
}) => {
  const lowestPrice = commodityUtils.getLowestPrice(commodity);
  const lowestDeliveryTime = commodityUtils.getLowestDeliveryTime(commodity);
  const dateLimit = new Date(); // Set half year as limit
  dateLimit.setMonth(dateLimit.getMonth() + 6);

  return (
    <Draggable draggableId={commodity._id.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          className="draggable"
        >
          <div className={availableStock > 0 ? "rawmaterial_title text-success" : "rawmaterial_title"}>
            {commodity.title.en} <OpenCommodityIcon commodity={commodity} />
            <span className="float-right text-muted" style={{ fontSize: "0.9rem", color: "#959cb6" }}>
              ID: {commodity.identifier}
            </span>
          </div>
          {ConfiguratorHelper.renderCommodityProperties(commodity)}
          <div className="rawmaterial_subtitle mb-3">{commodity.title.de}</div>
          <div className="rawmaterial_subtitle mb-3">{commodity.subtitle.en ? <i>{commodity.subtitle.en}</i> : ""}</div>
          <div
            className="kt-widget kt-widget--user-profile-3"
            style={{
              paddingTop: "0px",
              backgroundColor: "rgb(247, 248, 250)",
              borderRadius: "10px",
              padding: "5px"
            }}
          >
            <div className="kt-widget__bottom justify-content-between" style={{ borderTop: "none", marginTop: "0px" }}>
              <div className="kt-widget__item p-0 flex-grow-0">
                <div className="kt-widget__icon">
                  <i className="flaticon-coins" style={{ fontSize: "1.2rem" }} />
                </div>
                <div className="kt-widget__details  pl-2">
                  <span className="kt-widget__value" style={{ fontSize: "0.9rem", color: "#50566a" }}>
                    {lowestPrice.price
                      ? "from " +
                        lowestPrice.price.toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR"
                        }) +
                        "/kg"
                      : "price not set"}
                  </span>
                </div>
              </div>
              <div className="kt-widget__item p-0 flex-grow-0">
                <div className="kt-widget__icon">
                  <i className="flaticon-truck" style={{ fontSize: "1.2rem" }} />
                </div>
                <div className="kt-widget__details pl-2">
                  <span className="kt-widget__value" style={{ fontSize: "0.9rem", color: "#50566a" }}>
                    {lowestDeliveryTime ? "Min. " + lowestDeliveryTime : "delivery time not set"}
                  </span>
                </div>
              </div>
              <div className="kt-widget__item p-0 flex-grow-0">
                <div className="kt-widget__icon">
                  <i className="flaticon-tool" style={{ fontSize: "1.2rem" }} />
                </div>
                <div className="kt-widget__details  pl-2">
                  <span
                    className={
                      "kt-widget__value " +
                      (availableStock > 0 ? "text-success" : availableStock < 0 ? "text-danger" : "")
                    }
                    style={{ fontSize: "0.9rem", color: "#50566a" }}
                  >
                    {commodityUtils.resolveStockUnit(availableStock, commodity.type) + " Stock"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

interface RecipeItemProps {
  activeType: string;
  commodity: SelectedCommoditiesDocument;
  index: number;
  preferences: Preferences;
  calculations: Array<CalculationType>;
  onRecipeSave: (id: Realm.BSON.ObjectId, amount: number) => void;
  availableStock: number;
}

export const RecipeItem: React.FunctionComponent<RecipeItemProps> = ({
  commodity,
  index,
  preferences,
  activeType,
  onRecipeSave,
  calculations,
  availableStock
}) => {
  const totalAmount = calculationUtils.getTotalAmountWithBuffer(
    +preferences.amountPerUnit,
    +calculations[0].units,
    commodity.amount!,
    activeType,
    commodity.calculations[0].buffer
  );
  const matchingMOQ = commodity.calculations[0].supplier.price.moq * 1000 * 1000 > totalAmount;
  const [edit, setEdit] = useState(commodity.amount === 0);
  const [amount, setAmount] = useState(commodity.amount!.toString());
  const [unit, setUnit] = useState("mg");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove leading zeros
    let value = e.target.value.replaceAll(/^0+/g, "0");
    if (!value.includes(".")) value = Number(value).toString();
    setAmount(value);
  };
  const handleUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUnit(e.currentTarget.value);
  };
  const handleSave = () => {
    onRecipeSave(commodity._id, +calculationUtils.convertAmount(amount, unit, "mg"));
    setEdit(false);
  };
  const handleModalSave = (id: Realm.BSON.ObjectId, amount: number) => {
    onRecipeSave(id, amount);
    setEdit(false);
  };
  return (
    <Draggable draggableId={commodity._id.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
            opacity: commodity.disabled ? "0.4" : "1"
          }}
          className="draggable"
        >
          <div style={{ display: "inline" }}>
            <div className="rawmaterial_title" style={{ display: "inline" }}>
              <b>
                <span className="kt-widget__value">
                  {calculationUtils.formatAmount(commodity.amount!.toString(), 2)}
                </span>
              </b>{" "}
              {commodity.title.en}
              {commodity.density && commodity.density > 0 ? (
                ""
              ) : (
                <Tooltip
                  title={<span style={{ fontSize: "14px" }}>Density not set. Calculating with 0,5g/ml</span>}
                  placement="top"
                >
                  <i className="la la-info-circle ml-1 text-warning" style={{ fontSize: "18px", fontWeight: 900 }} />
                </Tooltip>
              )}
            </div>
            <button
              type="button"
              className={"btn btn-secondary ml-1 p-0"}
              style={{
                marginBottom: 5,
                border: "none"
              }}
              onClick={() => setEdit(true)}
            >
              <i className="la la-pencil" />
            </button>
            {commodity.activesubstance.length > 0 && (
              <SubstanceCalculationModal onRecipeSave={handleModalSave} commodity={commodity} />
            )}
            {commodity.disabled && (
              <div className="float-right">
                <span className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded">
                  <b>DISABLED</b>
                </span>
              </div>
            )}
            {ConfiguratorHelper.renderCommodityProperties(commodity)}
            {!edit && (
              <>
                <div className="rawmaterial_subtitle mb-3">{commodity.title.de}</div>
                <div className="rawmaterial_subtitle mb-3">
                  {commodity.subtitle.en ? <i>{commodity.subtitle.en}</i> : ""}
                </div>
              </>
            )}
          </div>
          <div
            className={
              edit ? "kt-widget kt-widget--user-profile-3 mt-3" : "kt-widget kt-widget--user-profile-3 mt-3 d-none"
            }
            style={{
              paddingTop: "0px",
              backgroundColor: "rgb(247, 248, 250)",
              borderRadius: "10px",
              padding: "5px"
            }}
          >
            <div className="row">
              <div className="col-12">
                <div className="input-group p-0">
                  <div className="input-group-prepend">
                    <span className="form-control " style={{ borderRadius: "10px 0px 0px 10px" }}>
                      <span className="text-muted">
                        {activeType === ProductTypes.CAPSULES
                          ? "Per Capsule"
                          : activeType === ProductTypes.TABLETS
                          ? "Per Tablet"
                          : activeType === ProductTypes.LIQUID || activeType === ProductTypes.POWDER
                          ? "Per Unit"
                          : ""}
                      </span>
                    </span>
                  </div>
                  <input
                    className="form-control"
                    style={{ borderRadius: "0px" }}
                    name="amount"
                    type="number"
                    min="0"
                    value={amount}
                    onChange={handleChange}
                    onKeyPress={e => e.key === "Enter" && handleSave()}
                  />
                  <div className="input-group-append">
                    <select
                      className="form-control pr-1"
                      style={{ borderRadius: "0px" }}
                      name="units"
                      onChange={handleUnitChange}
                      value={unit}
                    >
                      <option value="ug">&#181;g</option>
                      <option value="mg">mg</option>
                      <option value="g">g/ml</option>
                      <option value="kg">kg</option>
                      {/*<option value="milliliter">ml</option>*/}
                    </select>
                  </div>
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-success"
                      style={{ borderRadius: "0px 10px 10px 0px" }}
                      onClick={handleSave}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="row mt-0">
                  <div className="col-12 mt-0" style={{ textAlign: "center" }} />
                </div>
              </div>
              <div className="col-6" />
            </div>
          </div>
          <div
            className={edit ? "kt-widget kt-widget--user-profile-3 d-none" : "kt-widget kt-widget--user-profile-3"}
            style={{
              paddingTop: "0px",
              backgroundColor: "rgb(247, 248, 250)",
              borderRadius: "10px",
              padding: "5px",
              paddingLeft: "10px"
            }}
          >
            <div className="kt-widget__bottom justify-content-between" style={{ borderTop: "none", marginTop: "0px" }}>
              <div className="kt-widget__item p-0 flex-grow-0">
                <div className="kt-widget__icon">
                  <i className="flaticon-coins" style={{ fontSize: "1.2rem" }} />
                </div>
                <div className="kt-widget__details">
                  {commodity.calculations[0] ? (
                    matchingMOQ ? (
                      <React.Fragment>
                        <span
                          className="kt-widget__value text-danger"
                          style={{
                            fontSize: "0.9rem",
                            color: "#50566a",
                            display: "inline"
                          }}
                        >
                          {commodity.calculations[0].price.toLocaleString("de-DE", {
                            style: "currency",
                            currency: "EUR"
                          }) + "/kg"}
                        </span>
                        <Tooltip
                          title={`Total quantity is below MOQ: ${calculationUtils.formatAmount(
                            totalAmount.toString(),
                            2
                          )}/${commodity.calculations[0].supplier.price.moq}kg`}
                          placement="top"
                        >
                          <i className="la la-info-circle ml-1 text-danger" />
                        </Tooltip>
                      </React.Fragment>
                    ) : (
                      <span
                        className="kt-widget__value"
                        style={{
                          fontSize: "0.9rem",
                          color: "#50566a"
                        }}
                      >
                        {commodity.calculations[0].price.toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR"
                        }) + "/kg"}
                      </span>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="kt-widget__item p-0 flex-grow-0">
                <div className="kt-widget__icon">
                  <i className="flaticon-truck" style={{ fontSize: "1.2rem" }} />
                </div>
                <div className="kt-widget__details">
                  <span className="kt-widget__value" style={{ fontSize: "0.9rem", color: "#50566a" }}>
                    {commodity.calculations[0].deliveryTime ? commodity.calculations[0].deliveryTime + " Days" : ""}
                  </span>
                </div>
              </div>
              <div className="kt-widget__item p-0 flex-grow-0">
                <div className="kt-widget__icon">
                  <i className="flaticon-tool" style={{ fontSize: "1.2rem" }} />
                </div>

                <div className="kt-widget__details">
                  <span className="kt-widget__value" style={{ fontSize: "0.9rem", color: "#50566a" }}>
                    {commodity.amount ? calculationUtils.formatAmount(totalAmount.toString(), 2) + " Total Amount" : ""}
                  </span>
                </div>
              </div>
              <div className="kt-widget__item p-0 flex-grow-0">
                <div className="kt-widget__icon">
                  <i className="flaticon-tool" style={{ fontSize: "1.2rem" }} />
                </div>

                <div className="kt-widget__details">
                  <span
                    className={availableStock > 0 ? "kt-widget__value text-success" : "kt-widget__value text-danger"}
                    style={{ fontSize: "0.9rem" }}
                  >
                    {commodityUtils.resolveStockUnit(availableStock, commodity.type) + " Stock"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};
