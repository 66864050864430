import React, { PureComponent } from "react";
import _ from "lodash";
import { BSON } from "realm-web";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { DataContext } from "../../../context/dataContext";
import calculationUtils, { MARGIN_BUFFER } from "../../../utils/calculationUtils";
import {
  ALLTYPES,
  BLENDING,
  BLISTERING,
  BOTTLING,
  ENCAPSULATION,
  PRODUCTION_TYPES,
  TABLETING
} from "../../configurator/calculationDetails/calculationHelper";
import { round } from "../../../utils/baseUtils";
import { CalculationCustomPriceObject, CustomCalculationForModal } from "../../configurator/CustomTypes";
import { PackagingTypes, ProductTypes } from "../../configurator/configuratorConstants";
import { CustomOrder } from "../CustomTypes";
import { CalculationManufacturerPrice, CustomCalculationInfo } from "../../../model/orders.types";
import orderUtils, {
  CONTRACT,
  ORDERORDERCOMMODITIES,
  PRODUCTION,
  PRODUCTIONQUEUE,
  WAITING
} from "../../../utils/orderUtils";
import { T_CALCULATIONCHANGED, T_REPORTPDF } from "../../../utils/timelineUtils";
import dateUtils from "../../../utils/dateUtils";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import dbService, { UpdateAction, ORDERS } from "../../../services/dbService";
import calculationReportGeneration from "../../../utils/pdf/calculationReportGeneration";
import { CustomCalculationPreviewStats } from "../../configurator/modals/CustomCalculationModal";
import CustomCalculationView, { CustomCalculationSelectOption } from "./CustomCalculationView";
import userService from "../../../services/userService";
import { ManufacturersDocument } from "../../../model/manufacturers.types";
import { T_CAPSULE } from "../OrderHelper";

interface EditCustomCalculationModalProps {
  context: React.ContextType<typeof DataContext>;
  order: CustomOrder;
}

interface EditCustomCalculationModalState {
  buffer: string;
  optionalCost?: string;
  note?: string;
  customCalculationInfo: Array<CustomCalculationForModal>;
  show: boolean;
  stats: CustomCalculationPreviewStats;
  recalculatedUnitPrice: number;
  recalculatedUnitPriceNaked: number;
  saving: boolean;
  changed: boolean;
}

class EditCustomCalculationModal extends PureComponent<
  EditCustomCalculationModalProps,
  EditCustomCalculationModalState
> {
  constructor(props: EditCustomCalculationModalProps) {
    super(props);
    // initialise with dummy data
    this.state = {
      buffer: "",
      customCalculationInfo: [],
      show: false,
      stats: { unitPriceNaked: 0, totalMarginPercent: 0, unitMargin: 0, totalMargin: 0 },
      recalculatedUnitPrice: 0,
      recalculatedUnitPriceNaked: 0,
      saving: false,
      changed: false
    };
  }

  handleShow = () => {
    this.setState(this.getDefaultState(true));
  };

  handleHide = () => {
    this.setState({ show: false });
  };

  handleOptionalCost = (e: React.ChangeEvent<HTMLInputElement>) => {
    const optionalCost = this.getNumericValue(e, true);
    const { stats } = this.calculateCalculationInfo(
      +this.state.buffer,
      +optionalCost,
      this.state.customCalculationInfo
    );
    this.setState({ optionalCost, stats, changed: true });
  };

  handleBuffer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newBuffer = this.getNumericValue(e);
    const { stats } = this.calculateCalculationInfo(
      +newBuffer,
      +(this.state.optionalCost || "0"),
      this.state.customCalculationInfo
    );
    this.setState({ buffer: newBuffer, stats, changed: true });
  };

  handleNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const note = e.currentTarget.value;
    this.setState({ note, changed: true });
  };

  handleChangeManufacturer = (e: CustomCalculationSelectOption, index: number) => {
    const cci = [...this.state.customCalculationInfo];
    cci[index].manufacturerId = e.value.manufacturerId;
    cci[index].cost =
      (
        +this.getPriceFromSelectedManufacturer(e.document, e.value.type) * this.calculateTotalAmount(e.value.type)
      ).toString() || cci[index].cost;
    const { stats } = this.calculateCalculationInfo(+this.state.buffer, +(this.state.optionalCost || "0"), cci);
    this.setState({ customCalculationInfo: cci, stats, changed: true });
  };

  handleChangeCost = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const cci = [...this.state.customCalculationInfo];
    const newValue = this.getNumericValue(e);
    if (newValue) {
      cci[index].cost = newValue;
    }
    const { stats } = this.calculateCalculationInfo(+this.state.buffer, +(this.state.optionalCost || "0"), cci);
    this.setState({ customCalculationInfo: cci, stats, changed: true });
  };

  handleReset = () => {
    this.setState(this.getDefaultState(true));
  };

  handleSave = async () => {
    const { context, order } = this.props;
    const oldUnitPriceNaked = order.calculations[0].info.unitpricenaked;
    const { changed, buffer, customCalculationInfo, optionalCost } = this.state;
    const calculationForOrder = this.calculateCalculationInfo(
      +buffer,
      optionalCost ? +optionalCost : 0,
      customCalculationInfo
    ).calculationForOrder;
    if (changed) {
      const newOrder = _.cloneDeep(order);
      if (calculationForOrder) {
        newOrder.calculations[0].info = calculationForOrder;
        newOrder.calculations[0].margin = calculationForOrder?.percentmargin;
      }

      // Disable buttons
      this.setState({ saving: true });
      try {
        const userId = new BSON.ObjectId(userService.getUserData()._id);
        const settings = {
          ...order.settings,
          manufacturer: order.settings.manufacturer._id,
          filler: order.settings.filler?._id.toString()
        };
        const editAllowed = [WAITING, PRODUCTIONQUEUE, PRODUCTION, ORDERORDERCOMMODITIES, CONTRACT].includes(
          order.state
        );

        if (editAllowed) {
          // Generates the HTML for the calculation report
          const calculationReport = JSON.stringify({
            html: calculationReportGeneration.createCalculationProtocol(newOrder.calculations, settings, context),
            fileName: "Report_AN-" + order.identifier + "_V1_" + dateUtils.timeStampDate() + ".pdf"
          });
          let path;
          path = await pdfUtils.uploadAndReturnPath(calculationReport);
          if (!path) {
            toast.error("An error occurred during calculation report generation");
            return;
          } else {
            toast.success("Calculation report successfully created");
          }
          const timelineEntryOne = {
            _id: new BSON.ObjectId(),
            type: T_CALCULATIONCHANGED,
            date: new Date(),
            person: userId,
            prices: { oldPrice: oldUnitPriceNaked, newPrice: calculationForOrder?.unitpricenaked }
          };

          const timelineEntryTwo = {
            _id: new BSON.ObjectId(),
            type: T_REPORTPDF,
            offernumber: order.identifier,
            version: orderUtils.countTimelineEntries(newOrder, T_REPORTPDF),
            date: new Date(),
            person: userId,
            path
          };

          const actions: Array<UpdateAction> = [
            {
              collection: ORDERS,
              filter: { _id: order._id },
              update: {
                "calculations.0.info": calculationForOrder,
                "calculations.0.margin": calculationForOrder?.percentmargin
              },
              push: { timeline: { $each: [timelineEntryOne, timelineEntryTwo] } }
            }
          ];
          const res = await dbService.updatesAsTransaction(actions);

          if (res && path) {
            toast.success(
              <b>
                <i className="fa fa-check mr-2" />
                Calculation updated successfully
              </b>
            );
            this.handleHide();
          } else {
            toast.error(
              <b>
                <i className="fa fa-exclamation mr-2" />
                Calculation update failed
              </b>
            );
          }
        }
      } catch (e) {
        toast.error("An error occurred during the calculation update: ", e.message);
      } finally {
        this.setState({ saving: false });
      }
    } else {
      toast.info(
        <b>
          <i className="fa fa-exclamation mr-2" />
          Nothing in calculation changed
        </b>
      );
      this.setState({ saving: false });
    }
  };

  /**
   * Recalculate unitPriceNaked with the custom calculation if given, otherwise try to reconstruct from current prices
   * ! Reconstructed price might differ from the unitPriceNaked in the order due to changed prices
   * @param unitPrice summed up unitPrice of recipe, capsule and packaging
   * @returns {number} recalculated unitPriceNaked from custom calculation or reconstructed
   */
  recalculateUnitPriceNaked = (unitPrice: number) => {
    const { info } = this.props.order.calculations[0];
    const { customCalculation } = info;
    let recalculatedUnitPriceNaked = 0;
    if (customCalculation) {
      recalculatedUnitPriceNaked = unitPrice + calculationUtils.calculateCustomUnitPriceFromOrder(this.props.order);
    } else if (!customCalculation) {
      const generalUnitPrice = this.recalculateGeneralUnitPrice();
      // we cannot use oldUnitPriceNaked to subtract only the generalUnitPrice since we have no information then if the pricing changed
      // since we look up current prices which can differ from the one in the order
      recalculatedUnitPriceNaked = unitPrice + generalUnitPrice + (info.marginBuffer || 0);
    }
    return recalculatedUnitPriceNaked;
  };

  /**
   * Recalculate unitPrice without custom- or generalUnitPrice, either with the custom calculation or by reconstructing
   * with manufacturer prices (might differ from order unitPrice)
   * @returns {number} unitPrice without custom- or generalUnitPrice
   */
  recalculateUnitPrice = () => {
    const { order, context } = this.props;
    return calculationUtils.recalculateUnitPrice(order, context);
  };

  /**
   * Reconstruct the generalUnitPrice with current prices, so might not be the same as in order
   * @returns {number} reconstructed generalUnitPrice
   */
  recalculateGeneralUnitPrice = () => {
    const { order, context } = this.props;
    return calculationUtils.recalculateGeneralUnitPrice(order, context).generalUnitPrice;
  };

  /**
   * Calculate custom calculation for order and for stats
   * @param buffer the margin buffer for the custom calculation
   * @param optionalCosts optional costs for the custom calculation
   * @param customCalculationInfo custom calculation info of the custom calculation
   * @returns {calculationInfo, CustomCalculationPreviewStats} information for order and preview stats
   */
  calculateCalculationInfo = (
    buffer: number,
    optionalCosts: number,
    customCalculationInfo: Array<CustomCalculationForModal>
  ) => {
    const { recalculatedUnitPrice, note } = this.state;
    const { units, info } = this.props.order.calculations[0];
    const customCalculationDetails: CalculationCustomPriceObject = {};
    // fill customCalculationDetails
    for (const t of ALLTYPES) {
      const infoCustom = customCalculationInfo.find(cci => cci.type === t);
      if (infoCustom) {
        customCalculationDetails[t] = {
          price: Number(infoCustom.cost) / this.calculateTotalAmount(t),
          unitPrice: Number(infoCustom.cost) / Number(units),
          manufacturerId: infoCustom.manufacturerId
        };
      }
    }
    // calculate unitPrice of given custom prices
    const totalCustomUnitPrice = (
      Object.values(customCalculationDetails) as Array<CalculationManufacturerPrice>
    ).reduce((a: number, b: CalculationManufacturerPrice) => a + b.unitPrice, 0);
    const customCalculation: CustomCalculationInfo = {
      note: note || "",
      ...customCalculationDetails,
      optionalCosts
    };
    const optionalCostsPerUnit = optionalCosts / units;
    const unitPriceNaked = recalculatedUnitPrice + totalCustomUnitPrice + buffer + optionalCostsPerUnit;
    const unitPrice = info.unitprice;
    const percentMargin = (unitPrice / unitPriceNaked - 1) * 100;
    const unitMargin = unitPrice - unitPriceNaked;
    const totalPrice = unitPrice * units;
    const totalMargin = totalPrice - unitPriceNaked * units;
    const calculationForOrder = {
      unitprice: unitPrice,
      unitpricenaked: unitPriceNaked,
      unitmargin: unitMargin,
      totalprice: totalPrice,
      percentmargin: percentMargin,
      marginBuffer: buffer,
      totalmargin: totalMargin,
      customCalculation: customCalculation
    };
    const stats = {
      unitPriceNaked: unitPriceNaked,
      totalMarginPercent: percentMargin,
      unitMargin: unitMargin,
      totalMargin: totalMargin
    };
    return {
      calculationForOrder,
      stats
    };
  };

  getDefaultState = (show: boolean): EditCustomCalculationModalState => {
    const calcInfos = this.props.order.calculations[0].info;
    const customCalc = calcInfos.customCalculation;
    const customCalculationInfo = this.getCustomCalculationInfo();
    const recalculatedUnitPrice = this.recalculateUnitPrice();
    const recalcUnitPriceNaked = this.recalculateUnitPriceNaked(recalculatedUnitPrice);
    if (customCalc) {
      return {
        show: show,
        buffer:
          calcInfos.marginBuffer !== undefined && calcInfos.marginBuffer !== null
            ? calcInfos.marginBuffer.toString()
            : MARGIN_BUFFER.toString(),
        optionalCost: customCalc.optionalCosts?.toString() || "",
        note: customCalc.note || "",
        customCalculationInfo: customCalculationInfo,
        stats: {
          unitPriceNaked: calcInfos.unitpricenaked,
          totalMarginPercent: calcInfos.percentmargin,
          unitMargin: calcInfos.unitmargin,
          totalMargin: calcInfos.totalmargin
        },
        recalculatedUnitPrice: recalculatedUnitPrice,
        recalculatedUnitPriceNaked: recalcUnitPriceNaked,
        saving: false,
        changed: false
      };
    } else {
      const units = this.props.order.calculations[0].units;
      const unitPrice = this.props.order.calculations[0].info.unitprice;
      const percentMargin = (unitPrice / recalcUnitPriceNaked - 1) * 100;
      const unitMargin = unitPrice - recalcUnitPriceNaked;
      const totalPrice = unitPrice * units;
      const totalMargin = totalPrice - recalcUnitPriceNaked * units;
      return {
        show: show,
        buffer: MARGIN_BUFFER.toString(),
        optionalCost: "",
        note: "",
        customCalculationInfo: customCalculationInfo,
        stats: {
          unitPriceNaked: recalcUnitPriceNaked,
          totalMarginPercent: percentMargin,
          unitMargin: unitMargin,
          totalMargin: totalMargin
        },
        recalculatedUnitPrice: recalculatedUnitPrice,
        recalculatedUnitPriceNaked: recalcUnitPriceNaked,
        saving: false,
        changed: false
      };
    }
  };

  /**
   * Get customCalculationInfo on show of modal for prefilling inputs either from existing custom calculation or
   * reconstructed with prices from manufacturers
   * @returns {Array<CustomCalculationForModal>} customCalculationInfo for state
   */
  getCustomCalculationInfo = () => {
    const { order, context } = this.props;
    const { packagings } = order.calculations[0];
    const customPrices = order.calculations[0].info.customCalculation;
    const standardCalculationInfo = order.calculations[0].info.standardCalculation;
    const selectedManufacturer = order.settings.manufacturer;
    const selectedFiller = order.settings.filler;
    const info: Array<CustomCalculationForModal> = [];
    if (customPrices) {
      ALLTYPES.map(t => {
        const customPrice = customPrices[t];
        if (customPrice) {
          info.push({
            manufacturerId: customPrice.manufacturerId,
            type: t,
            cost: `${round(customPrice.price * this.calculateTotalAmount(t))}`
          });
        }
      });
    } else if (!customPrices) {
      const productType = calculationUtils.getTabForType(order.settings.type);
      // Nothing to do for custom or services
      if (productType === ProductTypes.CUSTOM || productType === ProductTypes.SERVICE) return [];
      const packagingDocs = context.packagings.filter(p =>
        packagings.some(orderP => p._id.toString() === orderP._id.toString())
      );
      const packageTypes = packagingDocs.map(p => {
        return p.packaging_type;
      });
      const typesFiltered: Array<PRODUCTION_TYPES> = [];
      if (productType === ProductTypes.CAPSULES) {
        typesFiltered.push(ENCAPSULATION);
        if (packageTypes.includes(PackagingTypes.BOTTLE)) typesFiltered.push(BOTTLING);
        else if (packageTypes.includes(PackagingTypes.BLISTER)) typesFiltered.push(BLISTERING);
      } else if (productType === ProductTypes.TABLETS) {
        typesFiltered.push(TABLETING);
        if (packageTypes.includes(PackagingTypes.BOTTLE)) typesFiltered.push(BOTTLING);
      } else if (productType === ProductTypes.POWDER || productType === ProductTypes.LIQUID) {
        typesFiltered.push(BLENDING);
        if (
          (packageTypes.includes(PackagingTypes.BOTTLE) && productType === ProductTypes.POWDER) ||
          (packageTypes.includes(PackagingTypes.LIQUIDBOTTLE) && productType === ProductTypes.LIQUID)
        )
          typesFiltered.push(BOTTLING);
      } else if (productType === ProductTypes.SOFTGEL) {
        if (packageTypes.includes(PackagingTypes.BOTTLE)) typesFiltered.push(BOTTLING);
      }

      typesFiltered.map(t => {
        const man =
          !selectedFiller || [TABLETING, ENCAPSULATION, BLENDING].includes(t) ? selectedManufacturer : selectedFiller;
        let price;
        // if present, get standard calc price info else reconstructed prices
        if (standardCalculationInfo && standardCalculationInfo[t]) {
          price = standardCalculationInfo[t]!.price;
        } else {
          price = this.getPriceFromSelectedManufacturer(man, t);
        }
        if (price) {
          info.push({
            manufacturerId: man._id,
            type: t,
            cost: `${round(+price * this.calculateTotalAmount(t))}`
          });
        }
      });
    }
    return info;
  };

  /**
   * Recalculate totalAmount for calculating total costs
   * @param type type of production the price calculation depends on
   * @returns {number} calculated total amount
   */
  calculateTotalAmount = (type: PRODUCTION_TYPES) => {
    const { context, order } = this.props;
    return calculationUtils.calculateTotalAmount(order, context, type, true);
  };

  /**
   * Get the price for the given type provided by the given manufacturer
   * @param manufacturer id of the selected manufacturer or manufacturer document
   * @param type given type
   * @returns {string} price for the type of selected manufacturer
   */
  getPriceFromSelectedManufacturer = (manufacturer: ManufacturersDocument, type: PRODUCTION_TYPES): string => {
    const { order, context } = this.props;
    return calculationUtils.getPriceFromSelectedManufacturer(order, context, manufacturer, type);
  };

  getNumericValue = (e: React.ChangeEvent<HTMLInputElement>, allowEmpty?: boolean) => {
    if (allowEmpty && e.target.value.trim() === "") return "";
    let value = e.target.value.replaceAll(/^0+/g, "0");
    value = value.replaceAll(/^-0+/g, "-0");
    if (!value.includes(".") && (!value.includes("-") || value.length > 2)) value = Number(value).toString();
    return value;
  };

  render() {
    const { order, context } = this.props;
    const { capsules } = context;
    const { buffer, optionalCost, note, customCalculationInfo, show, stats, saving, recalculatedUnitPriceNaked } =
      this.state;
    const type = order.settings.type;
    const oldUnitPriceNaked = order.calculations[0].info.unitpricenaked;
    const productType = calculationUtils.getTabForType(order.settings.type);
    const selectedCapsule =
      type === T_CAPSULE ? capsules.find(c => c._id.toString() === order.settings.id.toString()) : undefined;

    return (
      <>
        <button className="btn btn-sm btn-upper btn-secondary" onClick={this.handleShow}>
          Edit Calculation
        </button>
        <Modal show={show} onHide={this.handleHide} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Custom Calculation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomCalculationView
              type={productType}
              edit={true}
              buffer={buffer}
              optionalCost={optionalCost}
              note={note}
              selectedCapsule={selectedCapsule}
              customCalculationInfo={customCalculationInfo}
              stats={stats}
              oldUnitPriceNaked={oldUnitPriceNaked}
              reconstructedUnitPriceNaked={recalculatedUnitPriceNaked}
              onChangeManufacturer={this.handleChangeManufacturer}
              onChangeCost={this.handleChangeCost}
              onChangeOptionalCost={this.handleOptionalCost}
              onChangeBuffer={this.handleBuffer}
              onChangeNote={this.handleNote}
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide} disabled={saving}>
              Close
            </button>
            <button className="btn btn-secondary" onClick={this.handleReset} disabled={saving}>
              Reset
            </button>
            <button className="btn btn-success" onClick={this.handleSave} disabled={saving}>
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default EditCustomCalculationModal;
