import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import authenticationService from "../../services/authenticationService";

class Logout extends Component {
  async componentDidMount() {
    await authenticationService.logout();
  }
  render() {
    return <Redirect to="/login?logout=true" />;
  }
}

export default Logout;
