import React, { Component } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_metronic";
import baseUtils from "../../utils/baseUtils";
import dateUtils from "../../utils/dateUtils";

interface MaterialTimelineProps {
  content: Array<MaterialTimelineContent>;
}

interface MaterialTimelineState {}

export interface MaterialTimelineContent {
  image: string;
  icon: string;
  user: string;
  date: Date;
  change: string;
  additionalInformation?: string;
  details: { key: string; pre?: Array<string>; post?: Array<string> };
}

class MaterialTimeline extends Component<MaterialTimelineProps, MaterialTimelineState> {
  /**
   * Renders the popover for the material timeline that contains detailed information.
   * @param c: Content of the timeline
   * @param key: Key that should be used since every popover has to have a unique id
   * @returns { JSX.Element } Popover with content
   */
  renderPopover = (c: MaterialTimelineContent, key: number) => {
    return (
      <Popover id={"changeInfo" + key} style={{ minWidth: "60em", maxWidth: "75%" }} className="d-none d-md-inline">
        <Popover.Title>Change</Popover.Title>
        <Popover.Content>{this.renderDetailedChange(c.details)}</Popover.Content>
      </Popover>
    );
  };

  /**
   * Render a change in detail for the popover
   * @param change: Contains a singe timeline entry
   * @returns { JSX.Element } Row containing the change
   */
  renderDetailedChange = (change: { key: string; pre?: Array<string>; post?: Array<string> }) => {
    const { key, pre, post } = change;
    return (
      <div className="row">
        <span className="col-2 text-dark">{key}:</span>
        {pre && post && (
          <>
            <span className="col-4">
              {pre.map((p, k) => (
                <span className="text-dark" key={k}>
                  {p}
                  <br />
                </span>
              ))}
            </span>
            <span className="col-2 my-auto text-center">
              <i className="fa fa-arrow-right mx-2" />
            </span>
            <span className="col-4">
              {post.map((p, k) => (
                <span className="text-dark" key={k}>
                  {p}
                  <br />
                </span>
              ))}
            </span>
          </>
        )}
        {pre && !post && (
          <>
            <span className="col-4">
              {pre.map((p, k) => (
                <span className="text-danger" key={k}>
                  {p}
                  <br />
                </span>
              ))}
            </span>
            <span className="col-2 my-auto text-center">
              <i className="fa fa-minus mx-2" />
            </span>
            <span className="col-4">-</span>
          </>
        )}
        {!pre && post && (
          <>
            <span className="col-4">-</span>
            <span className="col-2 my-auto text-center">
              <i className="fa fa-plus mx-2" />
            </span>
            <span className="col-4">
              {post.map((p, k) => (
                <span className="text-success" key={k}>
                  {p}
                  <br />
                </span>
              ))}
            </span>
          </>
        )}
      </div>
    );
  };

  render() {
    const { content } = this.props;
    return content.length > 0 ? (
      <div className="kt-notes kt-scroll kt-scroll--pull ps ps--active-y">
        <div className="kt-notes__items">
          {content.map((c, key) => (
            <div className="kt-notes__item pb-4" key={key}>
              <div className="kt-notes__media">
                <img src={c.image} alt="user" />
              </div>
              <div className="kt-notes__content pb-2">
                <div className="kt-notes__section">
                  <div className="kt-notes__info">
                    <span className="kt-notes__title">{c.user}</span>
                    <span className="kt-notes__desc">{baseUtils.formatDate(c.date)}</span>
                  </div>
                </div>
                <span className="kt-notes__body">
                  <div className="kt-widget4">
                    <div className="kt-widget4__item">
                      <div className="kt-widget4__pic kt-widget4__pic--icon">
                        <i className={c.icon} style={{ fontSize: "19px", color: "#bfc5d4" }} />
                      </div>
                      <div className="kt-widget4__info">
                        <span className="kt-widget4__username">
                          <OverlayTrigger overlay={this.renderPopover(c, key)}>
                            <span tabIndex={0}>
                              {c.change}
                              <span className="d-none d-md-inline">
                                <span className="ml-2 text-muted">
                                  <i className="fa fa-info-circle btn-icon" />
                                </span>
                              </span>
                            </span>
                          </OverlayTrigger>
                        </span>
                        {c.additionalInformation && <p className="kt-widget4__text">{c.additionalInformation}</p>}
                        <p className="kt-widget4__text">{dateUtils.getTimeAgo(c.date)}</p>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    ) : (
      <img
        src={toAbsoluteUrl("/media/img/no_results.jpg")}
        className="d-block my-0 mx-auto "
        style={{ height: "500px" }}
      />
    );
  }
}

export default MaterialTimeline;
