import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface ErrorOverlayButtonProps {
  buttonText: string | JSX.Element;
  className: string;
  errors?: Array<string>;
  iconClass?: string;
  warnings?: Array<string>;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  saving?: boolean;
  showLoadingIndicatorOnSaving?: true;
}

const ErrorOverlayButton: React.FunctionComponent<ErrorOverlayButtonProps> = ({
  buttonText,
  className,
  errors,
  iconClass,
  warnings,
  onClick,
  disabled,
  saving,
  showLoadingIndicatorOnSaving
}) => {
  const hasErrors = errors && errors.length > 0;
  return (
    <OverlayTrigger
      show={(!hasErrors && (!warnings || warnings.length === 0)) || saving || disabled ? false : undefined}
      overlay={
        <Tooltip id="errorDescription">
          <ul className="m-0 p-0">
            {errors && errors.length > 0
              ? errors.map((e, key) => {
                  return (
                    <ul className="text-left pl-0" key={key}>
                      <span className="text-danger">
                        <b>{e}</b>
                      </span>
                      <br />
                    </ul>
                  );
                })
              : warnings && warnings.length > 0
              ? warnings.map((e, key) => {
                  return (
                    <ul className="text-left pl-0" key={key}>
                      <span className="text-warning">
                        <b>{e}</b>
                      </span>
                      <br />
                    </ul>
                  );
                })
              : ""}
          </ul>
        </Tooltip>
      }
    >
      <button
        type="button"
        className={className + (hasErrors || saving || disabled ? " disabled" : "")}
        onClick={hasErrors || saving || disabled ? undefined : onClick}
      >
        {showLoadingIndicatorOnSaving && saving && (
          <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
            <svg className="button-splash-spinner" viewBox="0 0 50 50">
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
            </svg>
          </div>
        )}
        {iconClass && <i className={iconClass} />}
        {buttonText}
        {!hasErrors && warnings && warnings.length > 0 && (
          <i className="fa fa-info-circle icon-sm ml-1 text-secondary" />
        )}
      </button>
    </OverlayTrigger>
  );
};

export default ErrorOverlayButton;
