import countryList from "i18n-iso-countries";
import _ from "lodash";
import React, { PureComponent } from "react";
import { Badge, Modal } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DataContext } from "../../../context/dataContext";
import { Commodities, CommoditiesDocument } from "../../../model/commodities.types";
import { COMMODITIES } from "../../../services/dbService";
import dbGeneralService from "../../../services/dbServices/dbGeneralService";
import accessUtils, { CREATELOCATIONS } from "../../../utils/accessUtils";
import toastUtils from "../../../utils/toastUtils";
import dbCommodityService from "../../../services/dbServices/dbCommodityService";

interface DuplicateCommodityModalProps extends RouteComponentProps<{}, {}, {}> {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
}

interface DuplicateCommodityModalState {
  show: boolean;
  saving: boolean;
}

class DuplicateCommodityModal extends PureComponent<DuplicateCommodityModalProps, DuplicateCommodityModalState> {
  constructor(props: DuplicateCommodityModalProps) {
    super(props);
    this.state = { show: false, saving: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false, saving: false });

  /**
   * Handles duplicating the current commodity. All data that is commodity specific is reset.
   */
  handleDuplicateCommodity = async () => {
    const { context, history } = this.props;
    const { commoditycategories, updateDocumentInContext } = context;
    const commodity = _.omit(_.cloneDeep(this.props.commodity), ["_id"]) as Commodities;
    this.setState({ saving: true });
    commodity.stock = [];
    commodity.orders = [];
    commodity.suppliers = [];
    if (commodity.type || commodity.category === "custom") {
      commodity.internal_code = "";
    } else {
      const categoryReference = commoditycategories.find(
        c => c._id.toString() === commodity.category.toString()
      )!.internal_code_reference;
      commodity.internal_code = (await dbGeneralService.getAndIncreaseGeneralNr(categoryReference)).toString();
    }
    commodity.hs_code = "";
    commodity.identifier = "";
    commodity.article_number = "";
    commodity.cas_number = null;
    commodity.organic_code = commodity.organic ? (await dbGeneralService.getNextOrganicCode()).toString() : "";
    commodity.forecast = [];
    commodity.timeline = [];
    commodity.specifications = [];
    commodity.disabled = false;
    const res = await dbCommodityService.createCommodity(commodity);
    await toastUtils.databaseOperationToast(
      !!res && !!res.insertedId,
      "Commodity duplicated successfully",
      "Error duplicating commodity",
      () => (res && "insertedId" in res ? updateDocumentInContext(COMMODITIES, res.insertedId) : undefined)
    );
    if (!!res && res.insertedId) history.push("/commodity/" + res.insertedId.toString());
    this.setState({ show: false, saving: false });
  };

  render() {
    const { commodity, context } = this.props;
    const { saving, show } = this.state;
    const { allergens, activesubstances, colors, commodityproperties, commoditycategories, compositions } = context;
    const cAllergensIds = commodity.allergens.map(a => a.toString());
    const cAllergens = allergens.filter(a => cAllergensIds.includes(a._id.toString()));
    const cActiveSubsIds = commodity.activesubstance.map(as => as._id.toString());
    const cActiveSubs = activesubstances.filter(as => cActiveSubsIds.includes(as._id.toString()));
    const cPropertiesIds = commodity.properties.map(p => p.toString());
    const cProperties = commodityproperties.filter(p => cPropertiesIds.includes(p._id.toString()));
    const cCategory = commoditycategories.find(cc => cc._id.toString() === commodity.category.toString());
    const cColor = commodity.color ? colors.find(c => c._id.toString() === commodity.color!.toString()) : null;
    const cForm = compositions.find(c => c._id.toString() === commodity.form.toString());
    const canDuplicate = accessUtils.canCreateData(CREATELOCATIONS.COMMODITY);
    return (
      <>
        <button
          type="button"
          className={"btn btn-secondary btn-sm btn-upper" + (saving || !canDuplicate ? " disabled" : "")}
          onClick={canDuplicate ? this.handleShow : undefined}
          disabled={saving || !canDuplicate}
        >
          Duplicate
        </button>
        <Modal show={show} centered onHide={this.handleHide} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Duplicate {commodity.title.en}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-warning" role="alert">
              <div className="alert-icon">
                <i className="flaticon-warning" />
              </div>
              <div className="alert-text">
                Do you really want to duplicate {commodity.title.en}?
                <br />
                Some properties are commodity specific and will not be copied. The duplicated commodity will contain the
                following information.
              </div>
            </div>
            <div className="m-2 text-dark">
              <div className="row mt-2">
                <div className="col-3 my-4">Title</div>
                <div className="col-9 my-4">
                  {commodity.title.en}
                  <br />
                  {commodity.title.de}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3 my-4">Subtitle</div>
                <div className="col-9 my-4">
                  {commodity.subtitle.en}
                  <br />
                  {commodity.subtitle.de}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3 my-4">Note</div>
                <div className="col-9 my-4">{commodity.note ? commodity.note : "-"}</div>
              </div>
              <div className="row mt-2">
                <div className="col-3 my-4">Category</div>
                <div className="col-9 my-4">{cCategory ? cCategory.name.en : "Custom"}</div>
              </div>
              <div className="row mt-2">
                <div className="col-3 my-4">Composition</div>
                <div className="col-9 my-4">{cForm ? cForm.name.en : "Custom"}</div>
              </div>
              <div className="row mt-2">
                <div className="col-3 my-4">Properties</div>
                <div className="col-9 my-4">
                  {cProperties.length > 0
                    ? cProperties.map(p => (
                        <Badge className="mr-1" key={p._id.toString()} pill variant="secondary">
                          {p.name.en}
                        </Badge>
                      ))
                    : "No properties specified"}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3 my-4">Allergens</div>
                <div className="col-9 my-4">
                  {cAllergens.length > 0
                    ? cAllergens.map(a => (
                        <Badge className="mr-1" key={a._id.toString()} pill variant="secondary">
                          {a.name.en}
                        </Badge>
                      ))
                    : "No allergens specified"}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3 my-4">Active substances</div>
                <div className="col-9 my-4">
                  {cActiveSubs.length > 0
                    ? cActiveSubs.map(a => (
                        <Badge className="mr-1" key={a._id.toString()} pill variant="secondary">
                          {a.name.en}
                        </Badge>
                      ))
                    : "No active substances specified"}
                </div>
              </div>
              {cColor && (
                <div className="row mt-2">
                  <div className="col-3 my-4">Color</div>
                  <div className="col-9 my-4">{cColor.name.en}</div>
                </div>
              )}
              {commodity.toxic_amount && (
                <div className="row mt-2">
                  <div className="col-3 my-4">Toxic amount</div>
                  <div className="col-9 my-4">{commodity.toxic_amount.toString()} mg / day</div>
                </div>
              )}
              {commodity.country && (
                <div className="row mt-2">
                  <div className="col-3 my-4">Country</div>
                  <div className="col-9 my-4">{countryList.getName(commodity.country, "en")}</div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary mr-2" onClick={this.handleHide}>
              Close
            </button>
            <button
              className={"btn btn-success" + (saving ? " disabled" : "")}
              onClick={this.handleDuplicateCommodity}
              disabled={saving}
            >
              Duplicate
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withRouter(DuplicateCommodityModal);
