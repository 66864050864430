import React, { ChangeEvent, PureComponent } from "react";
import { BSON } from "realm-web";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import baseUtils from "../../utils/baseUtils";
import { NewsDocument, NewsTypes } from "../../model/news.types";
import dbService, { NEWS } from "../../services/dbService";
import userService from "../../services/userService";
import dbNewsService from "../../services/dbServices/dbNewsService";
import { DataContext } from "../../context/dataContext";
import UploadImageWithPreview from "./UploadImageWithPreview";
import notificationService, { R_NEWSADDED } from "../../services/notificationService";

interface CreateNewsProps extends RouteComponentProps<{}, {}, {}> {
  news: Array<NewsDocument>;
}

interface CreateNewsState {
  header: string;
  content: string;
  picture: string;
  tags: Array<string>;
  originNews: NewsDocument | null;
  submitting: boolean;
}

class CreateNews extends PureComponent<CreateNewsProps, CreateNewsState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  _isMounted = false;

  constructor(props: CreateNewsProps) {
    super(props);
    this.state = {
      header: "",
      content: "",
      picture: "",
      tags: [],
      originNews: null,
      submitting: false
    };
  }

  handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAddUpload = (path: string) => {
    this.setState({ picture: path });
  };

  handleDeleteUpload = () => {
    this.setState({ picture: "" });
  };

  handleRemoveTags = (index: number) => {
    let tags = this.state.tags.filter((_, index2) => index2 !== index);
    this.setState({ tags });
  };

  handleAddTags = (event: any) => {
    if (event.target.value !== "") {
      this.setState({ tags: [event.target.value, ...this.state.tags] });
      event.target.value = "";
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.initialize();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initialize = () => {
    const { location, news } = this.props;
    const newsId = new URLSearchParams(location.search).get("id");
    location.search.toString().replace("?id=", "");
    let newsObject;
    if (newsId) {
      newsObject = baseUtils.getDocFromCollection(news, newsId);
      if (!newsObject) {
        newsObject = dbService.getDocumentFromCollection(NEWS, newsId);
      }
    }
    if (newsObject && this._isMounted) {
      this.setState({
        header: newsObject.header,
        content: newsObject.content,
        picture: newsObject.picture,
        tags: newsObject.tags,
        originNews: newsObject
      });
    }
  };

  submitChanges = async () => {
    this.setState({ submitting: true });
    const { history } = this.props;
    const { header, content, picture, tags, originNews } = this.state;
    const newsObject: NewsTypes = {
      header: header,
      content: content,
      picture: picture,
      tags: tags,
      date: new Date(),
      author: new BSON.ObjectId(userService.getUserData()._id)
    };

    try {
      let result;
      if (originNews) {
        result = await dbNewsService.updateNewsObject(originNews._id, newsObject);
        if (result && result.modifiedCount) {
          history.push("/news");
          toast.success("News updated successfully");
          await this.context.updateDocumentInContext(NEWS, originNews._id);
        } else toast.error("News update failed");
      } else {
        result = await dbNewsService.createNewsObject(newsObject);
        if (result && result.insertedId) {
          history.push("/news");
          toast.success("News created successfully");
          notificationService.notify(R_NEWSADDED, result.insertedId);
        } else toast.error("News creation failed");
      }
    } catch (e) {
      toast.error("An unexpected error occurred: " + e.message);
      console.error(e);
    } finally {
      if (this._isMounted) this.setState({ submitting: false });
    }
  };

  render() {
    const { history } = this.props;
    const { header, content, tags, picture, submitting } = this.state;

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand flaticon2-checking" />
            </span>
            <h3 className="kt-portlet__head-title">Article</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <button onClick={() => history.goBack()} className="btn btn-clean kt-margin-r-10">
              <i className="la la-arrow-left" />
              <span className="kt-hidden-mobile">Back</span>
            </button>
            <button
              type="button"
              className={
                !submitting
                  ? "btn btn-brand"
                  : "btn btn-brand btn btn-primary kt-spinner kt-spinner--left kt-spinner--md kt-spinner--light"
              }
              onClick={this.submitChanges}
            >
              <i className={!submitting ? "la la-check" : "d-none"} />
              <span className="kt-hidden-mobile">Save Article</span>
            </button>
          </div>
        </div>
        <div className="kt-portlet__body">
          <form className="kt-form" id="kt_form">
            <div className="row">
              <div className="col-xl-2" />
              <div className="col-xl-8">
                <div className="kt-section">
                  <div className="kt-section__body">
                    <h3 className="kt-section__title kt-section__title-lg">Contact Person:</h3>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Title</label>
                      <div className="col-9">
                        <input
                          className={header.length < 2 ? "form-control is-invalid" : "form-control"}
                          type="text"
                          name="header"
                          placeholder="Title"
                          value={header}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Text</label>
                      <div className="col-9">
                        <textarea
                          className={content.length < 5 ? "form-control is-invalid" : "form-control"}
                          name="content"
                          placeholder="Text"
                          value={content}
                          onChange={this.handleChange}
                          rows={25}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Tags</label>
                      <div className="col-9">
                        <div className="tags-input">
                          <ul id="tags">
                            {tags.map((tag, index) => (
                              <li key={tag} className="tag">
                                <span className="tag-title">{tag}</span>
                                <span className="tag-close-icon" onClick={() => this.handleRemoveTags(index)}>
                                  x
                                </span>
                              </li>
                            ))}
                          </ul>
                          <input
                            type="text"
                            onKeyUp={event => (event.key === "Enter" ? this.handleAddTags(event) : null)}
                            placeholder="Press enter to add tags"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Picture</label>
                      <div className="col-9">
                        <UploadImageWithPreview
                          onAddUpload={this.handleAddUpload}
                          onDeleteUpload={this.handleDeleteUpload}
                          preview={picture}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2" />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CreateNews;
