import React from "react";
import Tooltip from "../../common/Tooltip";
import WarehouseSearch from "../listingPanels/common/WarehouseSearch";
import i18n from "../../../translations/i18n";
import WarehouseListingTabPanel from "../listingPanels/WarehouseListingTabPanel";

const WarehouseListing: React.FC = () => {
  return (
    <div className="kt-portlet">
      <div className="kt-portlet__head bg-light">
        <div className="kt-portlet__head-label">
          <h5 className=" m-2 font-weight-bold text-black">{i18n.t("warehouse:rawMaterials")}</h5>
        </div>
        <div className="kt-portlet__head-toolbar align-items-end">
          <WarehouseOpenActions />
          <WarehouseSearch />
        </div>
      </div>
      <WarehouseListingTabPanel />
    </div>
  );
};

const WarehouseOpenActions: React.FC = () => {
  return (
    <div className="d-none" style={{ marginRight: 30, marginBottom: "0.8rem" }}>
      <Tooltip tooltipId={"openMovements"} tooltipText={i18n.t("warehouse:openMovements")}>
        <button className="btn btn-white mr-3" style={{ background: "transparent", padding: 4, border: "none" }}>
          <i className="flaticon2-writing mr-1 line-height-sm" />
          <span
            className="position-absolute translate-middle badge badge-circle badge-warning"
            style={{ top: 18, padding: 3, marginLeft: -7 }}
          >
            0
          </span>
        </button>
      </Tooltip>
      <Tooltip tooltipId={"openDeliveries"} tooltipText={i18n.t("warehouse:openDeliveries")}>
        <button className="btn btn-white" style={{ background: "transparent", padding: 4, border: "none" }}>
          <i className="flaticon2-delivery-truck mr-1 line-height-sm" />
          <span
            className="position-absolute translate-middle badge badge-circle badge-danger"
            style={{ top: 18, padding: 3, marginLeft: -7 }}
          >
            0
          </span>
        </button>
      </Tooltip>
    </div>
  );
};

export default WarehouseListing;
