import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { CommoditiesDocument } from "../../../../model/commodities.types";
import { SupplierSelectedPrices } from "../../../common/supplier/CustomTypes";
import { getPriceWithFastestDelivery, getLowestPrice, RawbidsRequestResult } from "../../../../utils/rawbidsUtils";
import baseUtils from "../../../../utils/baseUtils";

interface RawbidsCommodityPricesProps {
  requestResults: Array<RawbidsRequestResult>;
  commodities: Array<CommoditiesDocument>;
}

const RawbidsCommodityPrices: React.FunctionComponent<RawbidsCommodityPricesProps> = ({
  requestResults,
  commodities
}) => {
  return (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th style={{ width: "25%" }}>Commodity</th>
            <th style={{ width: "15%" }}>Needed Amount</th>
            <th style={{ width: "20%" }}>Requested Amount</th>
            <th style={{ width: "20%" }}>Cheapest Price</th>
            <th style={{ width: "20%" }}>Fastest Delivery</th>
          </tr>
        </thead>
        <tbody>
          {commodities.map(com => {
            const matchingRequestResult = requestResults.find(
              result => result.requestedCommodityId === com._id.toString()
            );
            return <RawbidsCommodityPricesRow key={com._id} commodity={com} requestResult={matchingRequestResult} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

interface RawbidsCommodityPricesRowProps {
  commodity: CommoditiesDocument;
  requestResult?: RawbidsRequestResult;
}

const RawbidsCommodityPricesRow: React.FunctionComponent<RawbidsCommodityPricesRowProps> = ({
  commodity,
  requestResult
}) => {
  const [showDetails, setShowDetails] = useState(true);
  const [lowestPrice, setLowestPrice] = useState<SupplierSelectedPrices | null>(null);
  const [fastestDelivery, setFastestDelivery] = useState<SupplierSelectedPrices | null>(null);

  useEffect(() => {
    if (requestResult) {
      setLowestPrice(getLowestPrice(requestResult.priceResult));
      setFastestDelivery(getPriceWithFastestDelivery(requestResult.priceResult));
    }
  }, [requestResult]);

  const handleToggleDetails = useCallback(() => setShowDetails(prevState => !prevState), []);

  return (
    <>
      <tr
        className={
          "kt-datatable__row d-table-row material-row" +
          (!requestResult || requestResult.priceResult.length === 0 ? " kt-font-danger" : "")
        }
      >
        <td className="kt-datatable__cell d-table-cell align-middle" onClick={handleToggleDetails}>
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <Link className="kt-user-card-v2__name kt-link" to={"/commodity/" + commodity._id.toString()}>
                {commodity.title.en}
                <span className="text-success"> (ID: {commodity.identifier})</span>
              </Link>
              <span className="kt-user-card-v2__email">{commodity.subtitle.en}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell align-middle" onClick={handleToggleDetails}>
          {requestResult && <span>{requestResult.neededAmounts.map(amount => `${amount}kg`).join(", ")}</span>}
        </td>
        <td className="kt-datatable__cell d-table-cell align-middle" onClick={handleToggleDetails}>
          {requestResult && <span>{requestResult.requestedAmounts.map(amount => `${amount}kg`).join(", ")}</span>}
        </td>
        <td className="kt-datatable__cell d-table-cell align-middle" onClick={handleToggleDetails}>
          {lowestPrice ? (
            <div>{`${lowestPrice.purchasePrice} ${lowestPrice.purchasePriceCurrency}/kg`}</div>
          ) : (
            <span>-</span>
          )}
        </td>
        <td className="kt-datatable__cell d-table-cell align-middle" onClick={handleToggleDetails}>
          {fastestDelivery ? <div>{fastestDelivery.deliveryTime + " days"}</div> : <span>-</span>}
        </td>
      </tr>
      <tr className={showDetails ? "kt-datatable__row d-table-row " : "d-none"}>
        <td colSpan={5}>
          <div
            className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded"
            style={{ boxShadow: "none" }}
          >
            {requestResult && requestResult.priceResult.length > 0 ? (
              <table className="kt-datatable__table d-table">
                <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
                  <tr>
                    <th style={{ width: "15%" }}>MOQ</th>
                    <th style={{ width: "15%" }}>Purchase Price</th>
                    <th style={{ width: "10%" }}>Incoterm</th>
                    <th style={{ width: "15%" }}>Delivery time</th>
                    <th style={{ width: "30%" }}>Source</th>
                    <th style={{ width: "15%" }}>Last update</th>
                  </tr>
                </thead>
                <tbody
                  className="kt-datatable__body "
                  style={{ backgroundColor: "#fafafa", display: "table-row-group" }}
                >
                  {requestResult.priceResult.map((pR, index) => (
                    <RawbidsCommodityPricesRowDetails key={index} priceResult={pR} />
                  ))}
                </tbody>
              </table>
            ) : (
              <div>No prices found</div>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

interface RawbidsCommodityPricesRowDetailsProps {
  priceResult: SupplierSelectedPrices;
}

const RawbidsCommodityPricesRowDetails: React.FunctionComponent<RawbidsCommodityPricesRowDetailsProps> = ({
  priceResult
}) => {
  const shortenedNote = useMemo(() => priceResult.note.split(".", 1), [priceResult]);

  return (
    <tr className="kt-datatable__row d-table-row">
      <td className="kt-datatable__cell d-table-cell py-2">
        <span>{`${priceResult.moq} kg`}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell py-2">
        <span>{`${priceResult.purchasePrice} ${priceResult.purchasePriceCurrency} / kg`}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell py-2">
        <span>{priceResult.incoterm}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell py-2">
        <span>{priceResult.deliveryTime + " days"}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell py-2">
        <span>{shortenedNote}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell py-2">
        <span>{priceResult.age ? baseUtils.formatDate(priceResult.age) : "-"}</span>
      </td>
    </tr>
  );
};

export default RawbidsCommodityPrices;
