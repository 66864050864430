import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import FinanceOverview from "./FinanceOverview";

interface FinanceOverviewWrapperProps extends RouteComponentProps<{}, {}, {}> {}

const FinanceOverviewWrapper: React.FunctionComponent<FinanceOverviewWrapperProps> = props => {
  const context = useContext(DataContext);
  return <FinanceOverview context={context} {...props} />;
};

export default FinanceOverviewWrapper;
