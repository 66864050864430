import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import accessUtils, { DELETELOCATIONS } from "../../utils/accessUtils";

interface DeleteSettingValueModalProps {
  _id: BSON.ObjectId;
  name: { de: string; en: string };
  title: string;
  onDelete: (_id: BSON.ObjectId) => Promise<void>;
  disabled: boolean;
}

interface DeleteSettingValueModalState {
  show: boolean;
  deletingData: boolean;
}

class DeleteSettingValueModal extends PureComponent<DeleteSettingValueModalProps, DeleteSettingValueModalState> {
  constructor(props: DeleteSettingValueModalProps) {
    super(props);
    this.state = { show: false, deletingData: false };
  }

  /**
   * Handle the deletion of the given settings value. Closes the modal afterwards.
   */
  handleDelete = async () => {
    const { _id, onDelete } = this.props;
    this.setState({ deletingData: true });
    await onDelete(_id);
    this.setState({ deletingData: false, show: false });
  };

  render() {
    const { name, title, disabled } = this.props;
    const { show, deletingData } = this.state;
    const canDelete = accessUtils.canDeleteData(DELETELOCATIONS.GENERALSETTINGS);

    return (
      <>
        <button
          className={"btn btn-danger" + (disabled || !canDelete ? " disabled" : "")}
          onClick={!disabled && canDelete ? () => this.setState({ show: true }) : undefined}
          disabled={disabled || !canDelete}
        >
          <i className="fa fa-trash" />
          Delete
        </button>
        <Modal centered show={show && !disabled} onHide={() => this.setState({ show: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Delete {title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row my-2">
              <div className="col-4 text-dark align-self-center">German</div>
              <div className="col-8">
                <input className="form-control" defaultValue={name.de} disabled />
              </div>
            </div>
            <div className="row my-2">
              <div className="col-4 text-dark align-self-center">English</div>
              <div className="col-8">
                <input className="form-control" defaultValue={name.en} disabled />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={() => this.setState({ show: false })}>
              Close
            </button>
            <button className={"btn btn-danger" + (deletingData ? " disabled" : "")} onClick={this.handleDelete}>
              {deletingData ? "Deleting..." : "Delete"}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default DeleteSettingValueModal;
