import React from "react";

const NotAvailable = () => {
  return (
    <div className="mb-3 text-center">
      <div className="my-5 pb-5">
        <i className="fa fa-exclamation-circle mr-2" style={{ color: "rgb(191, 197, 212)" }} />
        Not available in this state.
      </div>
    </div>
  );
};

export default NotAvailable;
