import _ from "lodash";
import React, { PureComponent } from "react";
import Select from "react-select";
import { BSON } from "realm-web";
import { DataContext } from "../../context/dataContext";
import userService from "../../services/userService";
import { ROLES } from "../../utils/userdataUtils";
import manufacturerUtils from "../../utils/manufacturerUtils";
import authenticationService from "../../services/authenticationService";
import { PERIODS } from "../../utils/dashboardUtils";

interface DashboardSettingsProps {
  context: React.ContextType<typeof DataContext>;
  dashboard:
    | "sales"
    | "production"
    | "finance"
    | "generaldashboard"
    | "controlling"
    | "investor"
    | "roadmap"
    | "presentation";
  selectedManufacturer: { name: string; id: string };
  user: BSON.ObjectId | string;
  period: "thismonth" | "lastmonth" | "thisyear" | "lastyear";
  onDashboardChange: (entry: any) => void;
  onManufacturerChange: (value: any) => void;
  onUserChange: (value: any) => void;
  onPeriodChange: (value: any) => void;
}

interface DashboardSettingsState {}

class DashboardSettings extends PureComponent<DashboardSettingsProps, DashboardSettingsState> {
  render() {
    const {
      context,
      dashboard,
      selectedManufacturer,
      user,
      period,
      onDashboardChange,
      onManufacturerChange,
      onUserChange,
      onPeriodChange
    } = this.props;
    const { manufacturers, userdata } = context;
    const users = userdata.filter(u => u.company_id === "internal");
    const selectedUser = user ? users.find(u => u._id.toString() === user) : "";
    const selectedPeriod = period ? PERIODS.find(p => p.value.toString() === period) : "";
    let options = [
      { value: "generaldashboard", label: "General Dashboard" },
      { value: "sales", label: "Sales" },
      { value: "production", label: "Production" }
    ];
    if (userService.isAuthorizedForAction(ROLES.CONTROLLING)) {
      options.push({
        value: "controlling",
        label: "Controlling"
      });
    }
    if (userService.isAuthorizedForAction(ROLES.CONTROLLING))
      options.push({
        value: "finance",
        label: "Finance"
      });
    if (userService.isAuthorizedForAction(ROLES.CONTROLLING)) options.push({ value: "roadmap", label: "Roadmap" });
    if (userService.isAuthorizedForAction(ROLES.CONTROLLING)) options.push({ value: "investor", label: "Investor" });
    if (userService.isAdmin()) options.push({ value: "presentation", label: "Presentation" });

    return (
      <div className="kt-portlet">
        <div className="kt-portlet__body  kt-portlet__body--fit pb-3 pb-md-0">
          <div className="row row-no-padding">
            {!userService.isAuthorizedForAction(ROLES.INVESTOR, true) && (
              <div className="col-12 col-sm-6 col-md-3 px-3 pt-3 pb-0 pb-md-3">
                <Select
                  className="select-default"
                  value={
                    dashboard !== "generaldashboard"
                      ? { value: dashboard, label: _.upperFirst(dashboard) }
                      : { value: dashboard, label: "General Dashboard" }
                  }
                  onChange={(value: any) => onDashboardChange(value ? value.value : "generaldashboard")}
                  options={options}
                />
              </div>
            )}
            {!["sales", "generaldashboard", "finance", "investor", "roadmap", "presentation"].includes(dashboard) && (
              <div className="col-12 col-sm-6 col-md-3 px-3 pt-3 pb-0 pb-md-3">
                <Select
                  className="select-default"
                  isClearable={true}
                  value={{ value: selectedManufacturer.id.toString(), label: selectedManufacturer.name }}
                  onChange={(value: any) => onManufacturerChange(value)}
                  options={[{ value: "allmanufacturers", label: "All Manufacturers" }].concat(
                    manufacturers.map(m => {
                      return {
                        value: m._id.toString(),
                        label: m.name,
                        isDisabled: !(
                          userService.isAuthorizedForAction(ROLES.ADMIN) ||
                          userService.isAuthorizedForAction(ROLES.SALES) ||
                          manufacturerUtils.isEmployeeOfManufacturer(authenticationService.getUserDataID(), m)
                        )
                      };
                    })
                  )}
                />
              </div>
            )}
            {![
              "generaldashboard",
              "controlling",
              "finance",
              "production",
              "investor",
              "roadmap",
              "presentation"
            ].includes(dashboard) && (
              <div className="col-12 col-sm-6 col-md-3 px-3 pt-3 pb-0 pb-md-3">
                <Select
                  className="select-default"
                  value={
                    user && selectedUser
                      ? { value: user.toString(), label: selectedUser.prename + " " + selectedUser.surname }
                      : { value: "", label: "All Persons" }
                  }
                  onChange={onUserChange}
                  options={[{ value: "", label: "All Persons", isDisabled: true } as any].concat(
                    _.sortBy(users, person => person.prename).map(user => {
                      return {
                        value: user._id.toString(),
                        label: user.prename + " " + user.surname
                      };
                    })
                  )}
                />
              </div>
            )}
            {!["production", "controlling", "finance", "roadmap", "presentation"].includes(dashboard) && (
              <div className="col-12 col-sm-6 col-md-3 px-3 pt-3 pb-0 pb-md-3">
                <Select
                  className="select-default"
                  value={
                    selectedPeriod
                      ? { value: period, label: selectedPeriod.label }
                      : { value: "thisyear", label: "This Year" }
                  }
                  onChange={onPeriodChange}
                  options={PERIODS}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardSettings;
