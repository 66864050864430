import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { ExtendedBatchLocation } from "../../../../../model/warehouse/customTypes.types";
import { WarehouseTypes } from "../../../../../model/configuration/warehouseConfiguration.types";
import Tooltip from "../../../../common/Tooltip";
import { resolveTranslation } from "../../../../../utils/translationUtils";
import { getBatchPackagingUnitDescription, getBatchReservationDescription } from "../../../../../utils/warehouseUtils";
import { formatNumValue } from "../../../../../utils/baseUtils";
import { DirectlyManagedWarehousePackagingUnits } from "./DirectlyManagedWarehousePackagingUnits";
import { RemoteWarehouseStock } from "./RemoteWarehouseStock";
import { ExtendedReservationRow } from "./ExtendedReservationRow";
import i18n from "../../../../../translations/i18n";

interface ExtendedLocationRowProps {
  location: ExtendedBatchLocation;
  onChangeReservationAmount: (e: React.ChangeEvent<HTMLInputElement>, locationId: string) => void;
  onChangePUAmount: (e: React.ChangeEvent<HTMLInputElement>, locationId: string) => void;
  onChangeRemoteWarehouseAmount: (e: React.ChangeEvent<HTMLInputElement>, locationId: string) => void;
  onReservationTakeAll: (locationId: string, reservationId: string) => void;
  onPUTakeAll: (locationId: string, packagingUnitId: string) => void;
  onRemoteWarehouseTakeAll: (locationId: string) => void;
  onToggleReservationCheckbox: (e: React.ChangeEvent<HTMLInputElement>, locationId: string) => void;
}

export const ExtendedLocationRow: React.FC<ExtendedLocationRowProps> = React.memo(
  ({
    location,
    onChangeReservationAmount,
    onChangePUAmount,
    onChangeRemoteWarehouseAmount,
    onReservationTakeAll,
    onPUTakeAll,
    onRemoteWarehouseTakeAll,
    onToggleReservationCheckbox
  }) => {
    const [show, setShow] = useState<boolean>(true);

    const handleToggleShow = useCallback(() => setShow(prevState => !prevState), []);
    const handleChangeReservationAmount = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => onChangeReservationAmount(e, location._id.toString()),
      []
    );
    const handleChangePUAmount = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => onChangePUAmount(e, location._id.toString()),
      []
    );
    const handleChangeRemoteWarehouseAmount = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => onChangeRemoteWarehouseAmount(e, location._id.toString()),
      []
    );
    const handleReservationTakeAll = useCallback(
      (reservationId: string) => onReservationTakeAll(location._id.toString(), reservationId),
      []
    );
    const handlePUTakeAll = useCallback(
      (packagingUnitId: string) => onPUTakeAll(location._id.toString(), packagingUnitId),
      []
    );
    const handleRemoteWarehouseTakeAll = useCallback(() => onRemoteWarehouseTakeAll(location._id.toString()), []);
    const handleToggleReservationCheckbox = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => onToggleReservationCheckbox(e, location._id.toString()),
      []
    );

    const isDirectlyManaged = useMemo(
      () => location.location.warehouseArea.type === WarehouseTypes.DIRECTLYMANAGED,
      [location.location.warehouseArea]
    );

    return (
      <>
        <tr className="kt-datatable__row d-table-row bg-light table-hover" onClick={handleToggleShow}>
          <td className="kt-datatable__cell d-table-cell">
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details ">
                <i
                  className={
                    "flaticon-add-label-button icon-md " + (location.reservations ? "text-danger" : "text-success")
                  }
                />
              </div>
            </div>
          </td>
          <td className="kt-datatable__cell d-table-cell">
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details ">
                <Tooltip
                  tooltipText={`${resolveTranslation(
                    location.location.warehouseSnapshot.warehouseName
                  )} - ${resolveTranslation(location.location.warehouseArea.warehouseName)}`}
                >
                  <span className="kt-user-card-v2__name text-black ">
                    {location.location.warehouseSnapshot.shortName} - {location.location.warehouseArea.shortName}
                  </span>
                </Tooltip>
              </div>
            </div>
          </td>
          <td className="kt-datatable__cell d-table-cell">
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details ">
                <span
                  className={
                    "kt-user-card-v2__name text-black " +
                    (!!location.location.storageSpace?.storageSpaceNo ? "kt-font-bold" : "font-weight-300")
                  }
                >
                  {location.location.storageSpace?.storageSpaceNo || "-"}
                </span>
              </div>
            </div>
          </td>
          <td className="kt-datatable__cell d-table-cell">
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details ">
                <span className="kt-user-card-v2__name text-black font-weight-300">
                  {getBatchPackagingUnitDescription(location.packagingUnits)}
                </span>
              </div>
            </div>
          </td>
          <td className="kt-datatable__cell d-table-cell">
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details ">
                <span className="kt-user-card-v2__name text-black font-weight-300">
                  {location.reservations ? getBatchReservationDescription(location.reservations) : "-"}
                </span>
              </div>
            </div>
          </td>
          <td className="kt-datatable__cell d-table-cell">
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details ">
                <span className="kt-user-card-v2__name text-black font-weight-300">
                  {formatNumValue(location.amountAtLocation)}
                </span>
              </div>
            </div>
          </td>
        </tr>
        {show && (
          <>
            {isDirectlyManaged ? (
              <DirectlyManagedWarehousePackagingUnits
                location={location}
                onChangePUAmount={handleChangePUAmount}
                onPUTakeAll={handlePUTakeAll}
              />
            ) : (
              <RemoteWarehouseStock
                location={location}
                onChangeRemoteWarehouseAmount={handleChangeRemoteWarehouseAmount}
                onRemoteWarehouseTakeAll={handleRemoteWarehouseTakeAll}
              />
            )}
            {location.reservations && (
              <>
                <tr className={"kt-datatable__row d-table-row"}>
                  <td colSpan={7} className="px-0 bg-light">
                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive py-2 px-5 mb-0">
                      <table className="kt-datatable__table d-table bg-light">
                        <thead
                          className="kt-datatable__head header-no-padding"
                          style={{ display: "table-header-group" }}
                        >
                          <tr className="kt-datatable__row d-table-row border-0">
                            <th className="kt-datatable__cell d-table-cell pb-0">
                              <span className="font-weight-300">{i18n.t("warehouse:reservations")}</span>
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr className={"kt-datatable__row d-table-row"}>
                  <td colSpan={7} className="px-0">
                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive py-2 px-5 mb-0">
                      <table className="kt-datatable__table d-table p-5">
                        <thead
                          className="kt-datatable__head header-no-padding"
                          style={{ display: "table-header-group" }}
                        >
                          <tr className="kt-datatable__row d-table-row">
                            <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "12%" }}>
                              <span className="font-weight-300">{i18n.t("common:id")}</span>
                            </th>
                            <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "12%" }}>
                              <span className="font-weight-300">{i18n.t("common:order")}</span>
                            </th>
                            <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "23%" }}>
                              <span className="font-weight-300">{i18n.t("warehouse:reservedAmount")}</span>
                            </th>
                            <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "23%" }}>
                              <span className="font-weight-300">{i18n.t("warehouse:usedAmount")}</span>
                            </th>
                            <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "20%" }}>
                              <span className="font-weight-300">{i18n.t("warehouse:restAmount")}</span>
                            </th>
                            <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "10%" }}>
                              <span className="font-weight-300">{i18n.t("warehouse:removeReservation")}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                          {location.reservations.map(r => (
                            <ExtendedReservationRow
                              key={r._id.toString()}
                              reservation={r}
                              onChangeReservationAmount={handleChangeReservationAmount}
                              onReservationTakeAll={handleReservationTakeAll}
                              onToggleReservationCheckbox={handleToggleReservationCheckbox}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </>
            )}
          </>
        )}
      </>
    );
  },
  _.isEqual
);
