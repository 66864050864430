import React from "react";
import { ExternalManufacturerCommodityDocument } from "../model/customTypes.types";
import { ActivesubstancesDocument } from "../model/activesubstances.types";
import { AllergensDocument } from "../model/allergens.types";
import { ColorsDocument } from "../model/colors.types";
import { CommoditycategoriesDocument } from "../model/commoditycategories.types";
import { CommoditypropertiesDocument } from "../model/commodityproperties.types";
import { CompositionsDocument } from "../model/compositions.types";
import { SolventsDocument } from "../model/solvents.types";
import { ExternalManufacturerOrdersDocument } from "../model/externalManufacturerOrders.types";
import { UserdataDocument } from "../model/userdata.types";

export const ExternalManufacturerContext = React.createContext({
  commodities: [] as Array<ExternalManufacturerCommodityDocument>,
  externalManufacturerOrders: [] as Array<ExternalManufacturerOrdersDocument>,
  activesubstances: [] as Array<ActivesubstancesDocument>,
  allergens: [] as Array<AllergensDocument>,
  colors: [] as Array<ColorsDocument>,
  commoditycategories: [] as Array<CommoditycategoriesDocument>,
  commodityproperties: [] as Array<CommoditypropertiesDocument>,
  compositions: [] as Array<CompositionsDocument>,
  solvents: [] as Array<SolventsDocument>,
  userdata: [] as Array<UserdataDocument>,
  savedState: {} as any,
  saveComponentState: (key: string, state: any) => undefined
});
