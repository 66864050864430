import _ from "lodash";
import React, { PureComponent } from "react";
import Select from "react-select";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import { PaginationState } from "../common/CustomTypes";
import { Invoice, OrdersDocument } from "../../model/orders.types";
import orderUtils, { DECLINED } from "../../utils/orderUtils";
import HistoryBackButton from "../listings/common/HistoryBackButton";
import { InvoicesFilter, OwnerFilter, SearchBar } from "../listings/common/Filters";
import ExportModal from "./ExportModal";
import SplashScreen from "../common/SplashScreen";
import BaseListing from "../listings/BaseListing";
import { paginate } from "../common/Pagination";
import FinanceDetailsRow from "./FinanceDetailsRow";
import invoiceUtils from "../../utils/invoiceUtils";
import baseUtils, { getComponentState } from "../../utils/baseUtils";
import PayInvoiceModal from "../order/modals/PayInvoiceModal";
import { CustomOrder } from "../order/CustomTypes";
import ExportOutgoingInvoiceBook from "./outgoingInvoiceBook/ExportOutgoingInvoiceBook";

interface FinanceDetailsProps extends RouteComponentProps {
  context: React.ContextType<typeof DataContext>;
}

interface FinanceDetailsState extends PaginationState {
  search: string;
  owner: "" | { value: string; label: string };
  status: { value: string; label: string };
  sort: { value: string; label: string };
  sortOrder: { value: "asc"; label: "Ascending" } | { value: "desc"; label: "Descending" };
  hideCancellation: boolean;
  relevantOrders: Array<OrdersDocument>;
  filteredInvoices: Array<{ order: OrdersDocument; invoice: Invoice }>;
  invoiceToPay: { order: CustomOrder; invoice: Invoice } | null;
}

const CONSTRUCTORNAME = "FinanceDetails";

class FinanceDetails extends PureComponent<FinanceDetailsProps, FinanceDetailsState> {
  constructor(props: FinanceDetailsProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const relevantOrders = this.getRelevantOrders();
    const state = getComponentState(this.props.context, CONSTRUCTORNAME);
    if (state) {
      state.relevantOrders = relevantOrders;
      this.setState({ ...state });
    } else {
      this.setState({
        relevantOrders,
        filteredInvoices: this.getFilteredInvoices(relevantOrders)
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<FinanceDetailsProps>,
    prevState: Readonly<FinanceDetailsState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.context.orders, this.props.context.orders)) {
      const relevantOrders = this.getRelevantOrders();
      this.setState({
        relevantOrders,
        filteredInvoices: this.getFilteredInvoices(relevantOrders)
      });
    }
    if (
      prevState.search !== this.state.search ||
      prevState.owner !== this.state.owner ||
      prevState.status !== this.state.status ||
      prevState.sort !== this.state.sort ||
      prevState.sortOrder !== this.state.sortOrder ||
      prevState.hideCancellation !== this.state.hideCancellation
    ) {
      this.setState({ filteredInvoices: this.getFilteredInvoices() });
    }
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }

  handleReset = () => this.setState(this.getDefaultState(true));
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value, currentPage: 1 });
  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value, currentPage: 1 });
  handleSelectChange = (name: string, entry: { value: string; label: string } | "") => {
    // @ts-ignore
    this.setState({ [name]: entry, currentPage: 1 });
  };
  handlePayInvoice = (order: OrdersDocument, invoice: any) => {
    const { context } = this.props;
    const customOrder: any = {
      ...order,
      createdFrom: baseUtils.getDocFromCollection(context.userdata, order.createdFrom),
      createdFor: baseUtils.getDocFromCollection(context.companies, order.createdFor),
      settings: {
        ...order.settings,
        manufacturer: baseUtils.getDocFromCollection(context.manufacturers, order.settings.manufacturer)
      }
    };
    this.setState({ invoiceToPay: { order: customOrder, invoice } });
  };
  handleClose = () => this.setState({ invoiceToPay: null });

  /**
   * Get the default state
   * @param reset flag if it is a reset or not. on reset orders are kept
   * @returns {FinanceDetailsState} default state for the component
   */
  getDefaultState = (reset?: boolean) => {
    const relevantOrders = reset ? this.state.relevantOrders : [];
    const filteredInvoices = reset ? this.state.filteredInvoices : [];
    return {
      currentPage: 1,
      pageSize: 30,
      owner: "",
      status: { value: "all", label: "All Statuses" },
      search: "",
      sort: { value: "date", label: "Creation Date" },
      sortOrder: { value: "desc", label: "Descending" },
      hideCancellation: true,
      invoiceToPay: null,
      relevantOrders,
      filteredInvoices
    } as FinanceDetailsState;
  };

  /**
   * Get only relevant orders that have an invoice
   * is locked only orders of that manufacturer
   * @returns {Array<OrdersDocument>} list of relevant orders
   */
  getRelevantOrders = () => {
    return this.props.context.orders.filter(
      o => orderUtils.isOrder(o) && o.state !== DECLINED && o.invoices && o.invoices.length > 0
    );
  };

  /**
   * Get filtered orders
   * @param orders: Optional, if set they are used instead of the relevant orders from state
   * @returns {Array<OrdersDocument>} list of all orders matching the filters
   */
  getFilteredInvoices = (orders?: Array<OrdersDocument>) => {
    const { companies, userdata } = this.props.context;
    const { search, owner, relevantOrders, status, sort, sortOrder, hideCancellation } = this.state;
    let filteredInvoices: Array<{ order: OrdersDocument; invoice: Invoice }> = [];
    let filteredOrders = orderUtils.filterOrders(orders ? orders : relevantOrders, undefined, owner ? owner.value : "");
    if (search.trim() !== "")
      filteredOrders = orderUtils.filterBySearch(filteredOrders, companies, userdata, search) as Array<OrdersDocument>;
    for (let i = 0; i < filteredOrders.length; i++) {
      const order = filteredOrders[i];
      const invoices = order.invoices;
      if (!invoices) continue;
      for (let j = 0; j < invoices.length; j++) {
        const invoice = invoices[j];
        if (invoiceUtils.matchesInvoiceStatus(invoice, status.value) && (!hideCancellation || !invoice.cancelation))
          filteredInvoices.push({ order, invoice });
      }
    }
    switch (sort.value) {
      case "date":
        filteredInvoices = _.orderBy(filteredInvoices, item => item.invoice.invoiceDate, sortOrder.value);
        break;
      case "duedate":
        filteredInvoices = _.orderBy(
          filteredInvoices,
          item => {
            const dueDate = new Date(item.invoice.invoiceDate);
            dueDate.setDate(dueDate.getDate() + item.invoice.dueIn);
            return dueDate;
          },
          sortOrder.value
        );
        break;
      case "invoicenumber":
        filteredInvoices = _.orderBy(filteredInvoices, item => item.invoice.invoiceNumber, sortOrder.value);
        break;
      case "customer":
        filteredInvoices = _.orderBy(filteredInvoices, item => item.order.createdFor.toString(), sortOrder.value);
        break;
      case "state":
        filteredInvoices = _.orderBy(filteredInvoices, item => item.invoice.state, sortOrder.value);
    }

    return filteredInvoices;
  };

  render() {
    const { context, history } = this.props;
    const { orders, userdata } = context;
    const {
      pageSize,
      currentPage,
      owner,
      status,
      filteredInvoices,
      sort,
      sortOrder,
      hideCancellation,
      invoiceToPay,
      search
    } = this.state;
    const selectableUsers = userdata.filter(user => user.company_id === "internal");

    const headerDefinition = [
      { title: "Invoice #", size: 8 },
      { title: "Order", size: 8 },
      { title: "Title", size: 12 },
      { title: "Company", size: 12 },
      { title: "Created", size: 7 },
      { title: "Due", size: 7 },
      { title: "State", size: 5 },
      { title: "Reminders", size: 5 },
      { title: "Last Reminder", size: 5 },
      { title: "Invoiced", size: 8 },
      { title: "Pending", size: 8 },
      { title: "Owner", size: 10 },
      { title: "Action", size: 5 }
    ];
    return (
      <>
        {invoiceToPay && (
          <PayInvoiceModal
            order={invoiceToPay.order}
            invoice={invoiceToPay.invoice}
            onHide={this.handleClose}
            show={!!invoiceToPay}
          />
        )}
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand fa fa-file-invoice-dollar" />
              </span>
              <h3 className="kt-portlet__head-title">Invoices</h3>
              <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
                Reset
              </button>
            </div>
            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-wrapper">
                <ExportModal context={context} />
                <ExportOutgoingInvoiceBook context={context} />
                <HistoryBackButton history={history} />
              </div>
            </div>
          </div>
          <div className="kt-portlet__body pb-0">
            <div className="kt-form kt-form--label-right  kt-margin-b-10">
              <div className="row align-items-center">
                <SearchBar onSearch={this.handleSearch} search={search} additionalSizeClasses={"col-md-2"} />
                <OwnerFilter
                  owner={owner}
                  selectableUsers={selectableUsers}
                  onFilterChange={this.handleSelectChange}
                  additionalSizeClasses={"col-md-3 col-xl-2"}
                />
                <InvoicesFilter
                  status={status}
                  onFilterChange={this.handleSelectChange}
                  additionalSizeClasses={"col-md-3 col-xl-2"}
                />
                <div className="col-md-3 col-xl-2 kt-margin-b-20-tablet-and-mobile">
                  <div className="kt-form__group kt-form__group--inline">
                    <div className="kt-form__label">
                      <label>Sort:</label>
                    </div>
                    <div className="kt-form__control">
                      <Select
                        className="select-default"
                        options={[
                          { value: "date", label: "Creation Date" },
                          { value: "duedate", label: "Due Date" },
                          { value: "invoicenumber", label: "Invoice Number" },
                          { value: "customer", label: "Customer" },
                          { value: "state", label: "State" }
                        ]}
                        value={sort}
                        onChange={(value: any) => this.handleSelectChange("sort", value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-xl-2 kt-margin-b-20-tablet-and-mobile">
                  <Select
                    className="select-default"
                    options={[
                      { value: "asc", label: "Ascending" },
                      { value: "desc", label: "Descending" }
                    ]}
                    value={sortOrder ? sortOrder : { value: "desc", label: "Descending" }}
                    onChange={(value: any) =>
                      this.handleSelectChange("sortOrder", value || { value: "desc", label: "Descending" })
                    }
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-11" />
                <div className="col-auto kt-margin-b-20-tablet-and-mobile mt-2">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={hideCancellation}
                      onChange={() => this.setState({ hideCancellation: !hideCancellation })}
                    />
                    <label className="form-check-label kt-font-dark kt-font-bold">Hide cancelled</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="kt-portlet__body kt-portlet__body--fit">
            {orders.length === 0 ? (
              <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
            ) : (
              <BaseListing
                headerDefinition={headerDefinition}
                documents={filteredInvoices}
                bodyContent={
                  <>
                    {paginate(filteredInvoices, currentPage, pageSize).map(i => (
                      <FinanceDetailsRow
                        key={i.order._id.toString() + i.invoice._id.toString()}
                        invoice={i.invoice}
                        order={i.order}
                        onPayInvoice={this.handlePayInvoice}
                        context={context}
                      />
                    ))}
                  </>
                }
                currentPage={currentPage}
                pageSize={pageSize}
                onPageChange={this.handlePageChange}
                onPageSizeChange={this.handlePageSizeChange}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default FinanceDetails;
