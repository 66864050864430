import React, { PureComponent } from "react";
import OutputTargetProjection from "./OutputTargetProjection";
import { OutputData } from "../CustomTypes";

interface OutputHistoryProps {
  targetOutput: number;
  targetOutputTurnover: number;
  targetOutputProfit: number;
  outputHistory: Array<OutputData>;
  year: number;
}

interface OutputHistoryState {
  dataType: "profit" | "turnover" | "units";
}

class OutputHistory extends PureComponent<OutputHistoryProps, OutputHistoryState> {
  constructor(props: OutputHistoryProps) {
    super(props);
    this.state = { dataType: "profit" };
  }

  render() {
    const { outputHistory, targetOutput, targetOutputProfit, targetOutputTurnover, year } = this.props;
    const { dataType } = this.state;
    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">
              {year === new Date().getFullYear() ? "Output Target & Projection" : "Output History (" + year + ")"}
            </h3>
          </div>
          <div className="kt-portlet__head--right align-self-center">
            <div className="btn-group">
              <button
                className={"btn " + (dataType === "profit" ? "btn-success" : "btn-secondary")}
                onClick={() => this.setState({ dataType: "profit" })}
              >
                Profit
              </button>
              <button
                className={"btn " + (dataType === "turnover" ? "btn-success" : "btn-secondary")}
                onClick={() => this.setState({ dataType: "turnover" })}
              >
                Turnover
              </button>
              <button
                className={"btn " + (dataType === "units" ? "btn-success" : "btn-secondary")}
                onClick={() => this.setState({ dataType: "units" })}
              >
                Units
              </button>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body p-3">
          <OutputTargetProjection
            outputHistory={outputHistory}
            targetOutput={targetOutput}
            targetOutputProfit={targetOutputProfit}
            targetOutputTurnover={targetOutputTurnover}
            dataType={dataType}
            year={year}
          />
        </div>
      </div>
    );
  }
}
export default OutputHistory;
