import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { CommoditiesDocument } from "../../../../model/commodities.types";
import { useDataContext } from "../../../../context/dataContext";
import commodityUtils from "../../../../utils/commodityUtils";
import CommodityCountryWidget from "../../../commodities/common/CommodityCountryWidget";
import RawbidsConnectionModal from "../../../commodities/modals/RawbidsConnectionModal";

interface DisconnectedCommoditiesProps {
  commodities: Array<CommoditiesDocument>;
}

const DisconnectedCommodities: React.FunctionComponent<DisconnectedCommoditiesProps> = ({ commodities }) => {
  return (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th style={{ width: "35%" }}>Title</th>
            <th style={{ width: "15%" }}>Category</th>
            <th style={{ width: "15%" }}>Origin</th>
            <th style={{ width: "20%" }}>Additional Information</th>
            <th style={{ width: "15%" }}>Connect</th>
          </tr>
        </thead>
        <tbody>
          {commodities.map(com => (
            <DisconnectedCommoditiesRow key={com._id} commodity={com} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface DisconnectedCommoditiesRowProps {
  commodity: CommoditiesDocument;
}

const DisconnectedCommoditiesRow: React.FunctionComponent<DisconnectedCommoditiesRowProps> = ({ commodity }) => {
  const dataContext = useDataContext();
  const { commoditycategories } = dataContext;

  const categoryName = useMemo(
    () => commoditycategories.find(category => category._id.toString() === commodity.category.toString())?.name.en,
    [commoditycategories, commodity]
  );

  return (
    <tr>
      <td className="align-middle">
        <span>
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <Link className="kt-user-card-v2__name kt-link" to={"/commodity/" + commodity._id.toString()}>
                <span className="kt-user-card-v2__name">
                  {commodity.title.en}
                  {commodity.organic && <i className="fas fa-leaf text-success ml-1" />}
                </span>
                <span className="kt-user-card-v2__email">{commodity.subtitle.en}</span>
              </Link>
            </div>
          </div>
        </span>
      </td>
      <td className="align-middle">
        <span
          className="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded"
          style={{
            backgroundColor: commodityUtils.getCategoryColor(categoryName ? categoryName : ""),
            color: categoryName && categoryName === "probiotics" ? "black" : "white",
            display: "inline-block"
          }}
        >
          {categoryName ? categoryName : "unknown"}
        </span>
      </td>
      <td className="align-middle">
        <CommodityCountryWidget countryCode={commodity.country} />
      </td>
      <td className="align-middle">
        <div>HS Code: {commodity.hs_code}</div>
        <div>CAS Number: {commodity.cas_number ?? "-"}</div>
      </td>
      <td>
        <RawbidsConnectionModal commodity={commodity} context={dataContext} />
      </td>
    </tr>
  );
};

export default DisconnectedCommodities;
