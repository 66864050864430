import _ from "lodash";
import React, { PureComponent, useRef } from "react";
import { Link } from "react-router-dom";
import { BSON } from "realm-web";
import { Tooltip } from "@material-ui/core";
import SearchBox from "../common/SearchBox";
import Pagination, { paginate } from "../common/Pagination";
import { CalculationType, CustomCommoditiesDocument, Preferences, SelectedCommoditiesDocument } from "./CustomTypes";
import commodityUtils from "../../utils/commodityUtils";
import calculationUtils from "../../utils/calculationUtils";
import { ProductTypes } from "./configuratorConstants";

interface PurchasedSelectionProps {
  activeType: string;
  preferences: Preferences;
  commodities: Array<CustomCommoditiesDocument>;
  recipe: Array<SelectedCommoditiesDocument>;
  calculations: Array<CalculationType>;
  onRecipeAdd: (id: BSON.ObjectId, index?: number, amount?: number) => void;
  onRecipeDelete: (id: BSON.ObjectId) => void;
}

interface PurchasedSelectionState {
  filteredCommodities: Array<CustomCommoditiesDocument>;
  searchQuery: string;
  currentPage: number;
  pageSize: number;
}

class PurchasedSelection extends PureComponent<PurchasedSelectionProps, PurchasedSelectionState> {
  constructor(props: PurchasedSelectionProps) {
    super(props);
    const { commodities, recipe, activeType } = props;
    this.state = {
      filteredCommodities: this.getFilteredCommodities(commodities, recipe, activeType),
      searchQuery: "",
      currentPage: 1,
      pageSize: 10
    };
  }

  componentDidUpdate(
    prevProps: Readonly<PurchasedSelectionProps>,
    prevState: Readonly<PurchasedSelectionState>,
    snapshot?: any
  ) {
    const { commodities, recipe, activeType } = this.props;
    if (prevProps.activeType !== activeType) {
      this.setState({
        searchQuery: "",
        currentPage: 1,
        pageSize: 10,
        filteredCommodities: this.getFilteredCommodities(commodities, recipe, activeType)
      });
    }

    // Update filtered commodities when recipe changed
    if (!_.isEqual(recipe, prevProps.recipe) || commodities !== prevProps.commodities) {
      this.setState({ filteredCommodities: this.getFilteredCommodities(commodities, recipe, activeType) });
    }
  }

  /**
   * Filter commodities by product type
   * @param commodities array of all commodities
   * @param recipe array of selected commodities
   * @param type current type
   * @returns array of filtered commodities
   */
  getFilteredCommodities = (
    commodities: Array<CustomCommoditiesDocument>,
    recipe: Array<SelectedCommoditiesDocument>,
    type: string
  ) => {
    let filter = "";
    switch (type) {
      case ProductTypes.SOFTGEL:
        filter = "softgel";
        break;
      case ProductTypes.CUSTOM:
        filter = "purchased";
        break;
      case ProductTypes.SERVICE:
        filter = "service";
        break;
    }
    return commodities.filter(
      comm =>
        !(comm.disabled || !comm.type) &&
        comm.type === filter &&
        !recipe.some(rec => rec._id.toString() === comm._id.toString())
    );
  };

  /**
   * Search commodities and return the commodities to be displayed
   */
  getDisplayedCommodities = () => {
    const { searchQuery, filteredCommodities } = this.state;
    if (searchQuery.trim() === "") return filteredCommodities;
    return commodityUtils.doCommoditySearch(filteredCommodities, searchQuery.trim());
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: e.target.value.toLowerCase() });
  };

  handlePageChange = (page: number) => {
    this.setState({ currentPage: page });
  };

  handlePageSizeChange = (pageSize: number) => {
    this.setState({ pageSize, currentPage: 1 });
  };

  handleRecipeAdd = (id: BSON.ObjectId) => {
    this.props.onRecipeAdd(id, undefined, 1);
    this.setState({ currentPage: 1 });
  };

  handleRecipeDelete = (id: BSON.ObjectId) => {
    this.props.onRecipeDelete(id);
    this.setState({ currentPage: 1 });
  };

  render() {
    const { activeType, recipe, preferences, calculations } = this.props;
    const { searchQuery, currentPage, pageSize } = this.state;
    const displayedCommodities = this.getDisplayedCommodities();

    return (
      <div
        className="mt-3"
        style={{
          backgroundColor: "rgb(247,248,250)",
          padding: 8
        }}
      >
        <div
          className="kt-portlet kt-portlet--tabs "
          style={{ boxShadow: "None", backgroundColor: "rgb(247,248,250)" }}
        >
          <div className="kt-portlet__body pt-0 pb-1 pl-0 pr-0">
            <div className="from-group row mb-0">
              <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                <SearchBox
                  title={"Search customer"}
                  onSearch={this.handleSearch}
                  value={searchQuery}
                  placeholder={"Search..."}
                  idSuffix="Customer"
                />
              </div>
            </div>
          </div>
        </div>
        <>
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div
              className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded"
              id="column_rendering"
            >
              {displayedCommodities.length === 0 ? (
                <div className="row mt-3">
                  <h3 className="mx-auto text-muted">No matching products found</h3>
                </div>
              ) : (
                <table className="kt-datatable__table table-responsive">
                  <thead className="kt-datatable__head">
                    <tr className="kt-datatable__row" style={{ minWidth: "600px" }}>
                      <th className="kt-datatable__cell" style={{ width: "35%" }}>
                        <span>Product</span>
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "20%" }}>
                        <span>Offers</span>
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "15%" }}>
                        <span>Best Price</span>
                      </th>
                      <th className="kt-datatable__cell" style={{ width: "15%" }}>
                        <span>Delivery Time</span>
                      </th>
                      {activeType !== ProductTypes.SERVICE && (
                        <th className="kt-datatable__cell" style={{ width: "15%" }}>
                          <span>Stock</span>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="kt-datatable__body">
                    {recipe.map(r => (
                      <PurchasedSelectionRow
                        key={r._id.toString()}
                        activeType={activeType}
                        preferences={preferences}
                        calculations={calculations}
                        product={r}
                        onRecipeItem={this.handleRecipeDelete}
                      />
                    ))}
                    {paginate(displayedCommodities, currentPage, pageSize).map(commodity => (
                      <PurchasedSelectionRow
                        key={commodity._id.toString()}
                        activeType={activeType}
                        preferences={preferences}
                        calculations={calculations}
                        product={commodity}
                        onRecipeItem={this.handleRecipeAdd}
                      />
                    ))}
                  </tbody>
                </table>
              )}
              <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <Pagination
                  itemsCount={displayedCommodities.length}
                  pageSize={pageSize}
                  onPageChange={this.handlePageChange}
                  currentPage={currentPage}
                  onPageSizeChange={this.handlePageSizeChange}
                  additionalNavClasses={"justify-content-center flex-grow-1"}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    );
  }
}

interface PurchasedSelectionRowProps {
  activeType: string;
  product: CustomCommoditiesDocument | SelectedCommoditiesDocument;
  preferences: Preferences;
  calculations: Array<CalculationType>;
  onRecipeItem: (id: BSON.ObjectId) => void;
}

const PurchasedSelectionRow: React.FunctionComponent<PurchasedSelectionRowProps> = React.memo(
  ({ product, onRecipeItem, preferences, activeType, calculations }) => {
    const rowRef = useRef(null);
    const additionalRowClass = "amount" in product ? "table-selected-hover" : "table-hover";
    const nameSplit = product.title.en.split(/[ -]+/);
    const suppliers = product.suppliers.length;
    const allPrices = product.suppliers.reduce((count, current) => count + current.prices.length, 0);
    const lowestPrice = commodityUtils.getLowestPrice(product);
    const lowestDeliveryTime = commodityUtils.getLowestDeliveryTime(product);
    const highestStock = commodityUtils.getAllStock(product, preferences.selectedManufacturer!._id);
    let matchingMOQ;
    let totalAmount: number;
    if ("amount" in product) {
      totalAmount = calculationUtils.getTotalAmountWithBuffer(
        +preferences.amountPerUnit,
        +calculations[0].units,
        product.amount!,
        activeType,
        product.calculations[0].buffer
      );
      matchingMOQ = product.calculations[0].supplier.price.moq > totalAmount;
    }

    return (
      <tr
        id={product._id.toString()}
        className={
          "kt-datatable__row " + ("amount" in product ? "table-selected " : "table-default ") + additionalRowClass
        }
        style={{ minWidth: "600px", minHeight: "75px" }}
        onClick={() => onRecipeItem(product._id)}
        ref={rowRef}
      >
        <td className="kt-datatable__cell" style={{ width: "35%" }}>
          <span>
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__pic">
                <div className="kt-badge kt-badge--xl kt-badge--primary">
                  {(nameSplit.length > 1
                    ? nameSplit[0][0] + nameSplit[1][0]
                    : product.title.en.slice(0, 2)
                  ).toUpperCase()}
                </div>
              </div>
              <div className="kt-user-card-v2__details">
                <Link
                  onMouseEnter={() => {
                    // @ts-ignore
                    if (rowRef && rowRef.current) rowRef.current.classList.remove(additionalRowClass);
                  }}
                  onMouseLeave={() => {
                    // @ts-ignore
                    if (rowRef && rowRef.current) rowRef.current.classList.add(additionalRowClass);
                  }}
                  onClick={e => e.stopPropagation()}
                  to={"/commodity/" + product._id.toString()}
                  target="_blank"
                  className="kt-user-card-v2__name kt-link d-inline"
                >
                  {product.title.en}
                </Link>
                <br />
                <span className="kt-user-card-v2__email">{product.subtitle.en}</span>
              </div>
            </div>
          </span>
        </td>
        <td className="kt-datatable__cell" style={{ width: "20%" }}>
          <span>
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <span className="kt-user-card-v2__name">{allPrices} Offers</span>
                <span className="kt-user-card-v2__email">from {suppliers} Suppliers</span>
              </div>
            </div>
          </span>
        </td>
        <td className="kt-datatable__cell" style={{ width: "15%" }}>
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name">
                {"calculations" in product ? (
                  product.calculations[0] ? (
                    matchingMOQ ? (
                      <>
                        <span className="text-danger">
                          {product.calculations[0].price.toLocaleString("de-DE", {
                            style: "currency",
                            currency: "EUR"
                          })}
                        </span>
                        <Tooltip
                          title={`Total quantity is below MOQ: ${totalAmount!.toString()}/${
                            product.calculations[0].supplier.price.moq
                          }`}
                          placement="top"
                        >
                          <i className="la la-info-circle ml-1 text-danger" />
                        </Tooltip>
                      </>
                    ) : (
                      product.calculations[0].price.toLocaleString("de-DE", {
                        style: "currency",
                        currency: "EUR"
                      })
                    )
                  ) : (
                    ""
                  )
                ) : lowestPrice.price ? (
                  "from " +
                  lowestPrice.price.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR"
                  })
                ) : (
                  "price not set"
                )}
              </span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell" style={{ width: "15%" }}>
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name">
                {"calculations" in product
                  ? product.calculations[0].deliveryTime
                    ? product.calculations[0].deliveryTime + " Days"
                    : ""
                  : lowestDeliveryTime
                  ? "Min. " + lowestDeliveryTime + " Days"
                  : "delivery time not set"}
              </span>
            </div>
          </div>
        </td>
        {activeType !== ProductTypes.SERVICE && (
          <td className="kt-datatable__cell" style={{ width: "15%" }}>
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <span
                  className={highestStock > 0 ? "kt-user-card-v2__name text-success" : "kt-user-card-v2__name"}
                  style={{ fontSize: "0.9rem", color: "#50566a" }}
                >
                  {+highestStock.toFixed(2) + (highestStock !== 0 ? "k" : "")}
                </span>
              </div>
            </div>
          </td>
        )}
      </tr>
    );
  }
);

{
}

export default PurchasedSelection;
