import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { BSON } from "realm-web";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CompanyWidget from "../common/CompanyWidget";
import baseUtils from "../../utils/baseUtils";
import dateUtils from "../../utils/dateUtils";
import BatchFilesModal from "../common/BatchFilesModal";
import EditBatchModal from "./modals/EditBatchModal";
import DisableBatchModal from "./modals/DisableBatchModal";
import commodityUtils, { CUSTOMER } from "../../utils/commodityUtils";
import { CommodityBatch, CommoditiesDocument } from "../../model/commodities.types";
import { DataContext } from "../../context/dataContext";
import { SuppliersDocument } from "../../model/suppliers.types";
import { LocalStock } from "./CustomTypes";

interface CommodityLocalStockRowsProps {
  context: React.ContextType<typeof DataContext>;
  batch: CommodityBatch;
  localStock: LocalStock;
  commodity: CommoditiesDocument;
  supplier: SuppliersDocument | "Accumulated Stock" | "Custom" | "Customer" | "Stock" | "Unknown";
  readOnly?: true;
  onBatchUpdate: (batch: CommodityBatch, create: boolean) => void;
  onBatchDisable: (_id: BSON.ObjectId | string) => void;
}

class CommodityLocalStockRow extends PureComponent<CommodityLocalStockRowsProps> {
  constructor(props: CommodityLocalStockRowsProps) {
    super(props);
  }

  render() {
    const { localStock, batch, readOnly, commodity, supplier, context, onBatchUpdate, onBatchDisable } = this.props;
    const allocatedStock = commodityUtils.calculateAllocatedStock(commodity._id, batch._id, context.orders);
    const totalAllocated = allocatedStock.reduce((sum, s) => sum + s.allocated, 0);
    return (
      <tr>
        <td className="align-middle font-weight-bold" style={{ opacity: batch.disabled ? "0.4" : "1" }}>
          <div className={batch.amount < 0 ? "text-danger" : "text-success"}>
            {commodityUtils.resolveStockUnit(batch.amount, commodity.type)}
            {batch.supplier === CUSTOMER && (
              <span className="text-muted font-weight-normal">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="freeInfo">
                      Commodities that are provided by customer are not regarded in stock and reservation calculation
                    </Tooltip>
                  }
                >
                  <i className="fa fa-info-circle ml-2 text-muted" />
                </OverlayTrigger>
              </span>
            )}
          </div>
          {totalAllocated > 0 && (
            <div className="text-muted font-weight-normal">
              {commodityUtils.resolveStockUnit(batch.amount - totalAllocated, commodity.type)}
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="freeInfo">
                    Total available amount (1st column) minus the already allocated amount (7th column)
                  </Tooltip>
                }
              >
                <i className="fa fa-info-circle ml-2 text-muted" />
              </OverlayTrigger>
            </div>
          )}
        </td>
        <td className="align-middle" style={{ opacity: batch.disabled ? "0.4" : "1" }}>
          {typeof supplier === "string" ? supplier : <CompanyWidget company={supplier} type="supplier" />}
        </td>
        <td className="align-middle" style={{ opacity: batch.disabled ? "0.4" : "1" }}>
          {batch.lot.trim()}
          {batch.note && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="note">
                  <b>Note:</b> {batch.note}
                </Tooltip>
              }
            >
              <i className="fa fa-info-circle ml-2 text-info" style={{ fontSize: "1.1rem" }} />
            </OverlayTrigger>
          )}
        </td>
        <td className="align-middle" style={{ opacity: batch.disabled ? "0.4" : "1" }}>
          {baseUtils.formatEuro(batch.price)}
        </td>
        <td className="align-middle" style={{ opacity: batch.disabled ? "0.4" : "1" }}>
          {baseUtils.formatDate(batch.stocked)}
          <br />
          <span className="text-muted" style={{ fontSize: "0.9rem" }}>
            {dateUtils.generateTimeDiffString(batch.stocked)}
          </span>
        </td>
        <td className="align-middle" style={{ opacity: batch.disabled ? "0.4" : "1" }}>
          {baseUtils.formatDate(batch.expiry)}
          <br />
          <span className="text-muted" style={{ fontSize: "0.9rem" }}>
            {dateUtils.generateTimeDiffString(batch.expiry)}
          </span>
        </td>
        <td className="align-middle" style={{ opacity: batch.disabled ? "0.4" : "1" }}>
          {allocatedStock.length > 0
            ? allocatedStock.map(aStock => (
                <div key={aStock.order._id.toString()}>
                  <div className="kt-user-card-v2 kt-font-sm">
                    <div className="kt-user-card-v2__details">
                      <Link
                        to={"/order/" + aStock.order._id.toString()}
                        className="kt-user-card-v2__name kt-link mr-2 d-inline font-weight-light"
                      >
                        {"AT-" + aStock.order.identifier}:
                      </Link>
                      <span className="kt-user-card-v2__name d-inline font-weight-bold">
                        {commodityUtils.resolveStockUnit(aStock.allocated, commodity.type)}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            : "-"}
        </td>
        <td className="align-middle" style={{ opacity: batch.disabled ? "0.4" : "1" }}>
          <BatchFilesModal lot={batch.lot} files={batch.files} />
        </td>
        {!readOnly && (
          <td className="align-middle text-right">
            <div className="btn-group">
              <EditBatchModal
                commodity={commodity}
                batch={batch}
                location={localStock.manufacturer._id}
                onBatchUpdate={onBatchUpdate}
                context={context}
                create={false}
              />
              <DisableBatchModal batch={batch} onBatchDisable={onBatchDisable} />
            </div>
          </td>
        )}
      </tr>
    );
  }
}

export default CommodityLocalStockRow;
