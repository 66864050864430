import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { Modal } from "react-bootstrap";
import { CustomOrder } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import dbService, { ORDERS, UpdateAction } from "../../../services/dbService";
import orderUtils, { CREATEINVOICE } from "../../../utils/orderUtils";
import toastUtils from "../../../utils/toastUtils";
import dateUtils from "../../../utils/dateUtils";
import accessUtils, { ACTIONS } from "../../../utils/accessUtils";
import notificationService, { R_ORDERSHIPPED } from "../../../services/notificationService";
import DateInput from "../../common/DateInput";
import userService from "../../../services/userService";
import { ROLES } from "../../../utils/userdataUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { T_ORDERSHIPPED } from "../../../utils/timelineUtils";

interface SetDeliveryDateModalProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
  type: "edit" | "ship";
}

interface SetDeliveryDateModalState {
  show: boolean;
  deliveryDate: Date | null;
  loading: boolean;
}

class SetDeliveryDateModal extends PureComponent<SetDeliveryDateModalProps, SetDeliveryDateModalState> {
  constructor(props: SetDeliveryDateModalProps) {
    super(props);
    this.state = {
      show: false,
      deliveryDate: props.order.delivery ? props.order.delivery : new Date(),
      loading: false
    };
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () =>
    this.setState({
      show: false,
      deliveryDate: this.props.order.delivery ? this.props.order.delivery : new Date(),
      loading: false
    });

  handleChangeDeliveryDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val: string | number | Date = e.target.value;
    if (val) {
      this.setState({ deliveryDate: new Date(val) });
      return;
    }
    this.setState({ deliveryDate: null });
  };

  handleClickSubmit = async () => {
    const { context, order, type } = this.props;
    const { deliveryDate } = this.state;
    this.setState({ loading: true });
    try {
      const action: UpdateAction = {
        collection: ORDERS,
        filter: { _id: order._id },
        update: type === "edit" ? { delivery: deliveryDate } : { state: CREATEINVOICE, delivery: deliveryDate }
      };
      if (type === "ship")
        action["push"] = {
          timeline: {
            _id: new BSON.ObjectId(),
            type: T_ORDERSHIPPED,
            date: new Date(),
            person: userService.getUserId()
          }
        };
      const res = await dbService.transaction([action]);
      await toastUtils.databaseOperationToast(
        res,
        "Delivery date set successfully",
        "Error setting delivery date",
        () => {
          if (type === "ship") notificationService.notify(R_ORDERSHIPPED, order._id);
          context.updateDocumentInContext(ORDERS, order._id);
        }
      );
      if (res) this.handleClose();
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { type, order } = this.props;
    const { deliveryDate, loading, show } = this.state;
    const canShip = accessUtils.canPerformAction(ACTIONS.ORDERSHIPPED);
    const errors = orderUtils.checkOrderShippingPreconditions(order);
    return (
      <>
        {type === "edit" ? (
          <i className="fa fa-cog pointer ml-2" onClick={this.handleShow} />
        ) : (
          <ErrorOverlayButton
            errors={userService.isAdmin() ? [] : errors}
            className={"btn btn-sm btn-upper btn-success"}
            disabled={!canShip}
            buttonText={"Order Shipped"}
            onClick={this.handleShow}
          />
        )}
        <Modal show={show} onHide={this.handleClose} centered name="deliveryDate">
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand fa fa-calendar mr-2" />
              {type === "edit" ? "Edit delivery date" : "Order Shipped"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group form-group-last">
              <div className="input-group mx-auto" style={{ maxWidth: "300px" }}>
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    {deliveryDate ? "Delivery on CW " + dateUtils.getCW(deliveryDate) : "-"}
                  </span>
                </div>
                <DateInput
                  classes={"form-control "}
                  value={deliveryDate}
                  onBlur={this.handleChangeDeliveryDate}
                  name={"delivery"}
                  allowClear={userService.hasOneOfRoles([ROLES.ADMIN, ROLES.DATAMAINTENANCE])}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className={"btn btn-secondary" + (loading ? " disabled" : "")} onClick={this.handleClose}>
              Close
            </button>
            <button className={"btn btn-success" + (loading ? " disabled" : "")} onClick={this.handleClickSubmit}>
              <b>{type === "edit" ? "Set delivery date" : "Order Shipped"}</b>
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default SetDeliveryDateModal;
