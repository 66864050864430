import _ from "lodash";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import countryList from "i18n-iso-countries";
import { CommoditiesDocument } from "../../../../model/commodities.types";
import { DataContext } from "../../../../context/dataContext";
import CommodityBaseImage from "../../../commodities/CommodityBaseImage";
import CommodityLocalStock from "../../../commodities/CommodityLocalStock";
import { LocalStock } from "../../../commodities/CustomTypes";

interface CommodityToDisableOverview {
  pohodaFilterdCommodity: CommoditiesDocument;
  localStock: LocalStock;
  localDisabledStock: LocalStock;
  context: React.ContextType<typeof DataContext>;
}

const CommodityToDisableOverview: React.FunctionComponent<CommodityToDisableOverview> = ({
  pohodaFilterdCommodity,
  localStock,
  localDisabledStock,
  context
}) => {
  return (
    <div className="kt-portlet p-4">
      <div className="kt-user-card-v2">
        <div className="kt-user-card-v2__pic">
          <div className="kt-widget__media">
            <CommodityBaseImage commodity={pohodaFilterdCommodity} context={context} />
          </div>
        </div>
        <div className="kt-user-card-v2__details m-3">
          <span className="kt-user-card-v2__name">
            <Link
              to={"/commodity/" + pohodaFilterdCommodity._id}
              className="kt-user-card-v2__name pr-2"
              style={{ display: "inline" }}
            >
              {pohodaFilterdCommodity.title.en}
            </Link>
            {pohodaFilterdCommodity.country && (
              <OverlayTrigger
                placement="right"
                delay={{ show: 100, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip product-popover">
                    {countryList.getName(pohodaFilterdCommodity.country, "en")}
                  </Tooltip>
                }
              >
                <img
                  style={{
                    width: 14,
                    height: 14,
                    borderRadius: 14,
                    objectFit: "cover"
                  }}
                  alt={pohodaFilterdCommodity.country}
                  src={"media/icons/countries/" + pohodaFilterdCommodity.country.toLowerCase() + ".png"}
                  className="country-icon"
                />
              </OverlayTrigger>
            )}
          </span>
          <span className="kt-user-card-v2__email">{pohodaFilterdCommodity.subtitle.en}</span>
        </div>
      </div>
      <CommodityLocalStock
        disableStatistics
        localStock={localStock}
        commodity={pohodaFilterdCommodity}
        context={context}
        showDisabledStock={true}
        localDisabledStock={localDisabledStock}
        readOnly
      />
    </div>
  );
};

export default memo(CommodityToDisableOverview, (prevProps, nextProps) => {
  return (
    _.isEqual(prevProps.localDisabledStock, nextProps.localDisabledStock) &&
    _.isEqual(prevProps.localDisabledStock, nextProps.localDisabledStock) &&
    _.isEqual(prevProps.pohodaFilterdCommodity, nextProps.pohodaFilterdCommodity)
  );
});
