import React, { PureComponent } from "react";
import { UserdataDocument } from "../../../model/userdata.types";
import { Stats } from "../../../model/customTypes.types";
import authenticationService from "../../../services/authenticationService";

interface SalesWelcomeProps {
  selectedUser: UserdataDocument;
  stats: Stats | null;
}

class SalesWelcome extends PureComponent<SalesWelcomeProps, {}> {
  render() {
    const { selectedUser, stats } = this.props;
    const userId = authenticationService.getUserDataID();
    return (
      <div className="col-12 kt-portlet">
        <div className="kt-portlet__body">
          <div className="kt-widget kt-widget--user-profile-3">
            <div className="kt-widget__top overflow-auto">
              <div
                className="kt-widget__media "
                style={
                  selectedUser && selectedUser._id.toString() === userId.toString() ? { opacity: 1 } : { opacity: 0.2 }
                }
              >
                <img src={selectedUser ? selectedUser.img_url : ""} alt="image" />
              </div>
              <div className="kt-widget__content" style={{ display: "block", margin: "auto" }}>
                <div className="kt-widget__head">
                  <a href="#" className="kt-widget__username">
                    {selectedUser && selectedUser._id.toString() === userId.toString()
                      ? "Guten Tag,"
                      : "You are now viewing as"}
                    <br />
                    <span className="kt-font-bolder" style={{ fontSize: "1.5rem" }}>
                      {(selectedUser ? selectedUser.prename : "") + " " + (selectedUser ? selectedUser.surname : "")}
                    </span>
                  </a>
                </div>
              </div>
              <div className="kt-widget__content" style={{ display: "block", margin: "auto" }}>
                <div className="kt-widget__head">
                  <span className="kt-widget__username" style={{ textAlign: "center" }}>
                    <span className="kt-font-bolder text-success" style={{ fontSize: "2rem" }}>
                      {stats && stats.BookSales
                        ? stats.BookSales.toLocaleString("de-DE", {
                            style: "currency",
                            currency: "EUR"
                          })
                        : "0.00€"}
                    </span>
                    <br />
                    <span style={{ fontSize: "1.5rem" }}>Buchumsatz</span>
                  </span>
                </div>
              </div>
              <div className="kt-widget__content" style={{ display: "block", margin: "auto" }}>
                <div className="kt-widget__head">
                  <span className="kt-widget__username" style={{ textAlign: "center" }}>
                    <span className="kt-font-bolder text-success" style={{ fontSize: "2rem" }}>
                      {stats && stats.BookMargin ? stats.BookMargin.toFixed(2) + "%" : "0.00%"}
                    </span>
                    <br />
                    <span style={{ fontSize: "1.5rem" }}>Buchmarge</span>
                  </span>
                </div>
              </div>
              <div className="kt-widget__content" style={{ display: "block", margin: "auto" }}>
                <div className="kt-widget__head">
                  <span className="kt-widget__username" style={{ textAlign: "center" }}>
                    <span className="kt-font-bolder text-success" style={{ fontSize: "2rem" }}>
                      {stats && stats.WrittenSales
                        ? stats.WrittenSales.toLocaleString("de-DE", {
                            style: "currency",
                            currency: "EUR"
                          })
                        : "0.00€"}
                    </span>
                    <br />
                    <span style={{ fontSize: "1.5rem" }}>Geschriebener Umsatz</span>
                  </span>
                </div>
              </div>
              <div className="kt-widget__content" style={{ display: "block", margin: "auto" }}>
                <div className="kt-widget__head">
                  <span className="kt-widget__username" style={{ textAlign: "center" }}>
                    <span className="kt-font-bolder text-success" style={{ fontSize: "2rem" }}>
                      {stats && stats.Commission
                        ? stats.Commission.toLocaleString("de-DE", {
                            style: "currency",
                            currency: "EUR"
                          })
                        : "0.00€"}
                    </span>
                    <br />
                    <span style={{ fontSize: "1.5rem" }}>Geschriebene Provision</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SalesWelcome;
