import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";
import AddEmployeeModal from "./AddEmployeeModal";
import { DataContext } from "../../context/dataContext";
import { ManufacturersDocument } from "../../model/manufacturers.types";

interface ManufacturerEmployeesProps {
  canChange: boolean;
  manufacturer: ManufacturersDocument;
  onReset: () => void;
  onSave: (typeData: "capabilities" | "employees") => void;
  onAddEmployee: (_id: BSON.ObjectId) => void;
  onRemoveEmployee: (_id: BSON.ObjectId) => void;
}

interface ManufacturerEmployeesState {
  showAddEmployeeModal: boolean;
}

class ManufacturerEmployees extends PureComponent<ManufacturerEmployeesProps, ManufacturerEmployeesState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: ManufacturerEmployeesProps) {
    super(props);
    this.state = { showAddEmployeeModal: false };
  }

  render() {
    const { onAddEmployee, onRemoveEmployee, canChange, manufacturer, onReset, onSave } = this.props;
    const { showAddEmployeeModal } = this.state;
    const { manufacturers, userdata } = this.context;
    return (
      <div className="kt-portlet kt-portlet--mobile" style={{ minHeight: "150px" }}>
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand flaticon2-avatar" />
            </span>
            <h3 className="kt-portlet__head-title">Employees</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="row m-4">
            {manufacturer.employees && manufacturer.employees.length > 0 ? (
              <Table>
                <thead>
                  <tr>
                    <th />
                    <th>Name</th>
                    <th>Position</th>
                    <th>Mail</th>
                    <th>Phone</th>
                    {canChange && <th />}
                  </tr>
                </thead>
                <tbody>
                  {manufacturer.employees.map(e => {
                    const employee = userdata.find(u => u._id.toString() === e.toString())!;
                    return (
                      <tr key={employee._id.toString()}>
                        <td className="align-middle">
                          <img
                            src={employee.img_url ? employee.img_url : "/media/users/default.jpg"}
                            style={{ height: "40px" }}
                          />
                        </td>
                        <td className="align-middle">
                          {employee.prename} {employee.surname}
                        </td>
                        <td className="align-middle">{employee.position}</td>
                        <td className="align-middle">{employee.email[0]}</td>
                        <td className="align-middle">{employee.telephone[0]}</td>
                        {canChange && (
                          <td className="align-middle">
                            <div className="btn btn-sm btn-secondary" onClick={() => onRemoveEmployee(employee._id)}>
                              Remove
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div className="col-12 text-dark text-center h4">No employees registered</div>
            )}
          </div>
          {canChange && (
            <div className="row m-4">
              <div className="col-12">
                <div
                  className="btn btn-success float-right"
                  onClick={() => this.setState({ showAddEmployeeModal: true })}
                >
                  Add Employee
                </div>
              </div>
              <AddEmployeeModal
                show={showAddEmployeeModal}
                onHide={() => this.setState({ showAddEmployeeModal: false })}
                manufacturer={manufacturers}
                userdata={userdata}
                addEmployee={onAddEmployee}
              />
            </div>
          )}
        </div>
        {canChange && (
          <div className="kt-portlet__foot">
            <div className="float-right">
              <div className="btn btn-secondary mr-2" onClick={onReset}>
                Reset
              </div>
              <div className="btn btn-success" onClick={() => onSave("employees")}>
                Save
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ManufacturerEmployees;
