import _ from "lodash";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BSON } from "realm-web";
import Select from "react-select";
import DateInput from "../../../common/DateInput";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { parseCSV } from "../../../../utils/fileUtils";
import { CommoditiesDocument } from "../../../../model/commodities.types";
import { DataContext } from "../../../../context/dataContext";
import CommodityToDisableOverview from "./CommodityToDisableOverview";
import MissingCommodityOrBatchOverview from "./MissingCommodityOrBatchOverview";
import dbService, {
  Action,
  COMMODITIES,
  INSERT,
  InsertAction,
  POHODAHISTORY,
  UPDATE,
  UpdateAction
} from "../../../../services/dbService";
import { DisabledBatches, PohodaHistoryDocument } from "../../../../model/pohodaHistory.types";
import toastUtils from "../../../../utils/toastUtils";
import baseUtils from "../../../../utils/baseUtils";
import pohodaUtils, { PohodaEntry } from "../../../../utils/pohoda/pohodaUtils";
import { ManufacturersDocument } from "../../../../model/manufacturers.types";

export const pohodaRelatedManufacturer = "5ef4fd72a4cf4fbc3202f609"; // CZ

enum PohodaMode {
  IMPORT,
  REVERT
}

enum ToggleAwarenessObject {
  DISABLE,
  MISSING
}

const pohodaModeOptions = [
  { value: PohodaMode.IMPORT, label: "pohoda import" },
  { value: PohodaMode.REVERT, label: "revert pohoda import" }
];

interface PohodaImportProps {
  context: React.ContextType<typeof DataContext>;
}

interface PohodaImportState {
  activeProcess: boolean;
  activeStep: number;
  disablePohodaBatches: boolean;
  disablingAwarenessConfirmed: boolean;
  errors: Array<string>;
  excludingUpperBoundary: Date | null;
  hideContent: boolean;
  invalidDocument: boolean;
  missingEntries: Array<PohodaEntry>;
  missingEntriesAwarenessConfirmed: boolean;
  parsedPohodaEntries: Array<PohodaEntry>;
  pohodaMode: { value: number; label: string };
  pohodaReversionObject: { value: string; label: string } | null;
  relatedCommoditiesAndBatchesToBeDisabled: Array<CommoditiesDocument>;
  revertingPohodaProcess: boolean;
  showBatchesToDisable: boolean;
  transformingData: boolean;
}

const PohodaImport: React.FunctionComponent<PohodaImportProps> = ({ context }) => {
  const getDefaultState = (): PohodaImportState => {
    return {
      activeProcess: false,
      activeStep: 0,
      disablePohodaBatches: false,
      disablingAwarenessConfirmed: false,
      errors: [] as Array<string>,
      excludingUpperBoundary: null,
      hideContent: false,
      invalidDocument: false,
      missingEntries: [],
      missingEntriesAwarenessConfirmed: false,
      parsedPohodaEntries: [],
      pohodaMode: { value: PohodaMode.IMPORT, label: "pohoda import" },
      pohodaReversionObject: null,
      relatedCommoditiesAndBatchesToBeDisabled: [] as Array<CommoditiesDocument>,
      revertAwarenessConfirmed: false,
      revertingPohodaProcess: false,
      showBatchesToDisable: true,
      transformingData: false
    } as PohodaImportState;
  };

  // state hook
  const [pohodaImportState, setPohodaImportState] = useState<PohodaImportState>(getDefaultState);
  // ref hook
  const pohodaFileReference = useRef<HTMLInputElement>(null);

  // state deconstructing
  const {
    activeProcess,
    activeStep,
    disablePohodaBatches,
    disablingAwarenessConfirmed,
    errors,
    excludingUpperBoundary,
    hideContent,
    invalidDocument,
    missingEntries,
    missingEntriesAwarenessConfirmed,
    parsedPohodaEntries,
    pohodaMode,
    pohodaReversionObject,
    relatedCommoditiesAndBatchesToBeDisabled,
    revertingPohodaProcess,
    showBatchesToDisable,
    transformingData
  } = pohodaImportState;

  // props deconstructing
  const { commodities, pohodaHistory, manufacturers } = context;
  const pohodaManufacturer = useMemo(
    () => baseUtils.getDocFromCollection(manufacturers, pohodaRelatedManufacturer),
    []
  ) as ManufacturersDocument; // CZ manufacturer document

  // error handling
  useEffect(() => {
    getErrors();
  }, [
    activeProcess,
    activeStep,
    disablingAwarenessConfirmed,
    excludingUpperBoundary,
    missingEntriesAwarenessConfirmed,
    parsedPohodaEntries,
    pohodaReversionObject
  ]);

  // operational pohoda handling
  useEffect(() => {
    if (transformingData) {
      transformData();
    } else if (disablePohodaBatches) {
      disableBatches();
    } else if (revertingPohodaProcess) {
      revertPohodaProcess();
    }
  }, [transformingData, disablePohodaBatches, revertingPohodaProcess]);

  const handleCancelation = () => {
    setPohodaImportState(getDefaultState);
  };

  const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value ? new Date(e.target.value) : null;
    setPohodaImportState(prevState => {
      return {
        ...prevState,
        excludingUpperBoundary: date
      };
    });
  };

  const handleStartProcess = () => {
    setPohodaImportState(prevState => {
      return {
        ...prevState,
        activeProcess: true
      };
    });
  };

  const handleOnHide = () => {
    setPohodaImportState(prevState => {
      return {
        ...prevState,
        hideContent: true
      };
    });
  };

  const handleOnShow = () => {
    setPohodaImportState(prevState => {
      return {
        ...prevState,
        hideContent: false
      };
    });
  };

  const handleShowBatchesToDisable = () => {
    setPohodaImportState(prevState => {
      return {
        ...prevState,
        showBatchesToDisable: true
      };
    });
  };

  const handleShowMissingEntries = () => {
    setPohodaImportState(prevState => {
      return {
        ...prevState,
        showBatchesToDisable: false
      };
    });
  };

  const handleToggleAwareness = (type: ToggleAwarenessObject) => {
    if (type === ToggleAwarenessObject.DISABLE) {
      setPohodaImportState(prevState => {
        return {
          ...prevState,
          disablingAwarenessConfirmed: !prevState.disablingAwarenessConfirmed
        };
      });
    } else {
      setPohodaImportState(prevState => {
        return {
          ...prevState,
          missingEntriesAwarenessConfirmed: !prevState.missingEntriesAwarenessConfirmed
        };
      });
    }
  };

  const handlePohodaModeChange = (e: { value: number; label: string }) => {
    setPohodaImportState(prevState => {
      return {
        ...prevState,
        pohodaMode: e
      };
    });
  };

  const handlePohodaReversionDateChange = (e: { value: string; label: string } | null) => {
    setPohodaImportState(prevState => {
      return {
        ...prevState,
        pohodaReversionObject: e
      };
    });
  };

  /**
   * Parsing a pohoda csv file into equivalent json objects
   * @param e change event
   */
  const handleParsePohodaCSV = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      setPohodaImportState(prevState => {
        return {
          ...prevState,
          invalidDocument: true
        };
      });
      return; // exit
    }

    // parsing csv
    const file = await e.target.files[0].text();
    const content: Array<string> = file.split(/[\r\n]+/);
    const parsedCSV = parseCSV(content, ",");
    const parsedPohoda = pohodaUtils.parsePohodaCSV(parsedCSV);

    if (parsedPohoda) {
      setPohodaImportState(prevState => {
        return {
          ...prevState,
          invalidDocument: false,
          parsedPohodaEntries: parsedPohoda
        };
      });
    } else {
      setPohodaImportState(prevState => {
        return {
          ...prevState,
          invalidDocument: true
        };
      });
    }
  };

  const handleStartDataTransforming = () => {
    setPohodaImportState(prevState => {
      return {
        ...prevState,
        transformingData: true
      };
    });
  };

  const handleStartBatchDisabling = async () => {
    setPohodaImportState(prevState => {
      return {
        ...prevState,
        disablePohodaBatches: true
      };
    });
  };

  const handleRevertPohodaProcess = async () => {
    setPohodaImportState(prevState => {
      return {
        ...prevState,
        revertingPohodaProcess: true
      };
    });
  };

  const getErrors = () => {
    const errors: Array<string> = [];

    if (pohodaMode.value === PohodaMode.IMPORT) {
      if (activeProcess && activeStep === 0) {
        if (!excludingUpperBoundary) {
          errors.push("Please select a beginning date");
        }

        if (parsedPohodaEntries.length === 0) {
          errors.push("Please upload a valid pohoda list");
        }
      }

      if (activeProcess && activeStep === 1) {
        if (!disablingAwarenessConfirmed || !missingEntriesAwarenessConfirmed) {
          errors.push("Confirmations are missing");
        }
      }
    } else {
      // case reverting
      if (pohodaReversionObject === null) {
        errors.push("Please select an process date");
      }
    }
    setPohodaImportState(prevState => {
      return {
        ...prevState,
        errors: errors
      };
    });
  };

  /**
   * Function to transform the parsed pohoda entries into manufacturer scoped commodities and batches
   */
  const transformData = useCallback(() => {
    let missingEntries = _.cloneDeep(parsedPohodaEntries);
    const relatedCommoditiesAndBatchesToBeDisabled: Array<CommoditiesDocument> = [];

    // going through commodities. Building pohoda scoped commodity object for further processing.
    commodities.forEach(commodity => {
      const possibleRelevantCommodity = _.cloneDeep(commodity);

      // building a pohoda related initial stock
      possibleRelevantCommodity.stock = [];

      // going through the commodity related batches and filtering the pohoda relevant
      commodity.stock.forEach(batch => {
        if (
          batch.location.toString() === pohodaRelatedManufacturer &&
          parsedPohodaEntries.find(parsedEntry => batch.lot.trim() === parsedEntry.lot) === undefined
        ) {
          // CASE: batch is stored in the system but not included in pohoda list.
          if (batch.amount > 0 && batch.stocked.getTime() < excludingUpperBoundary!.getTime()) {
            // ignore batches with amount equals 0 or been stocked after the specified date
            possibleRelevantCommodity.stock.push(batch);
          }
        } else if (
          batch.location.toString() === pohodaRelatedManufacturer &&
          parsedPohodaEntries.find(parsedEntry => batch.lot.trim() === parsedEntry.lot) !== undefined
        ) {
          // CASE: System includes pohoda entry
          // -> delete entry from the possible missing system batches/commodities
          missingEntries = missingEntries.filter(entry => batch.lot.trim() !== entry.lot);
        }
      });

      // Adding possible scoped commodity if cz scoped batches are greater than 0
      if (possibleRelevantCommodity.stock.length > 0) {
        relatedCommoditiesAndBatchesToBeDisabled.push(possibleRelevantCommodity);
      }
    });

    setPohodaImportState(prevState => {
      return {
        ...prevState,
        activeStep: 1,
        missingEntries: missingEntries,
        relatedCommoditiesAndBatchesToBeDisabled: relatedCommoditiesAndBatchesToBeDisabled,
        transformingData: false
      };
    });
  }, [parsedPohodaEntries, excludingUpperBoundary]);

  /**
   * Function to revert the selected pohoda process
   */
  const revertPohodaProcess = useCallback(async () => {
    if (pohodaReversionObject?.value) {
      const actions: Array<UpdateAction> = [];

      // receive the related pohoda entry
      const pohodaHistoryEntry = baseUtils.getDocFromCollection(
        pohodaHistory,
        pohodaReversionObject?.value
      ) as PohodaHistoryDocument;

      // collection revert transactions
      pohodaHistoryEntry.disabledBatches.forEach(entry => {
        entry.relatedBatches.forEach(disabledBatch => {
          actions.push({
            action: UPDATE,
            collection: COMMODITIES,
            filter: { _id: new BSON.ObjectId(entry.relatedCommodity) },
            update: {
              "stock.$[s].disabled": false
            },
            arrayFilters: [{ "s._id": disabledBatch }]
          } as UpdateAction);
        });
      });

      // starting db transactions
      const success = await dbService.updatesAsTransaction(actions);
      await toastUtils.databaseOperationToast(
        success,
        "pohoda Process successfully reverted!",
        "Error pohoda process could not be reverted!",
        () => setPohodaImportState(getDefaultState()),
        () =>
          setPohodaImportState(prevState => {
            return {
              ...prevState,
              revertingPohodaProcess: false
            };
          })
      );
    }
  }, [pohodaReversionObject, pohodaHistory]);

  /**
   * Function to disable the relevant pohoda batches
   */
  const disableBatches = useCallback(async () => {
    const actions: Array<Action> = [];

    // building history entries
    const disabledBatches: Array<DisabledBatches> = [];
    relatedCommoditiesAndBatchesToBeDisabled.forEach(commodity => {
      const relatedBatches = commodity.stock.map(batch => batch._id.toString());

      // overall history entry
      disabledBatches.push({
        _id: new BSON.ObjectId(),
        relatedCommodity: commodity._id.toString(),
        relatedBatches: relatedBatches
      });

      commodity.stock.forEach(batch => {
        // adding disabling transaction
        actions.push({
          action: UPDATE,
          collection: COMMODITIES,
          filter: { _id: commodity._id },
          update: {
            "stock.$[s].disabled": true
          },
          arrayFilters: [{ "s._id": batch._id }]
        } as UpdateAction);
      });
    });

    const pohodaHistoryEntry = {
      _id: new BSON.ObjectId(),
      date: new Date(),
      disabledBatches: disabledBatches
    } as PohodaHistoryDocument;

    // adding action for history entry
    actions.push({
      action: INSERT,
      collection: POHODAHISTORY,
      object: pohodaHistoryEntry
    } as InsertAction);

    // starting db transactions
    const success = await dbService.transaction(actions);
    await toastUtils.databaseOperationToast(
      success,
      "Batches successfully disabled!",
      "Error batches could not be disabled!",
      () => setPohodaImportState(getDefaultState()),
      () =>
        setPohodaImportState(prevState => {
          return {
            ...prevState,
            disablePohodaBatches: false
          };
        })
    );
  }, [relatedCommoditiesAndBatchesToBeDisabled]);

  const commoditiesToDisableOverview = useMemo(
    () =>
      relatedCommoditiesAndBatchesToBeDisabled
        .sort((a, b) => a.title.en.localeCompare(b.title.en))
        .map(commodity => (
          <CommodityToDisableOverview
            key={commodity._id.toString()}
            pohodaFilterdCommodity={commodity}
            context={context}
            localStock={{
              manufacturer: pohodaManufacturer,
              localStock: commodity.stock.filter(batch => !batch.disabled)
            }}
            localDisabledStock={{
              manufacturer: pohodaManufacturer,
              localStock: commodity.stock.filter(batch => batch.disabled)
            }}
          />
        )),
    [relatedCommoditiesAndBatchesToBeDisabled]
  );
  return (
    <div className="kt-portlet kt-portlet--mobile">
      <div className="kt-portlet__head kt-portlet__head--lg">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Pohoda Stock Transfer</h3>
        </div>
      </div>
      <div className="kt-portlet__body">
        {!activeProcess && activeStep === 0 && (
          <>
            <div className="row mt-2">
              <div className="col text-dark font-weight-bolder mb-3">
                Importing Pohoda csv from PLF Production CZ. Disable all batches that are not contained in the list.
              </div>
            </div>
            <div className="row">
              <div className="col col-6 col-md-4 col-lg-3 align-self-center">Mode</div>
              <div className="col col-6 col-md-8 col-lg-3">
                <Select
                  className="select-default"
                  options={pohodaModeOptions}
                  onChange={(value: any) => handlePohodaModeChange(value)}
                  value={pohodaMode}
                />
              </div>
            </div>
          </>
        )}
        {pohodaMode.value === PohodaMode.REVERT && activeProcess && activeStep === 0 && (
          <>
            <div className="row mt-2">
              <div className="col text-dark font-weight-bolder mb-3">
                Please select the date of the performed Pohoda import process.
              </div>
            </div>
            <div className="row">
              <div className="col col-6 col-md-4 col-lg-3 align-self-center">Process Date</div>
              <div className="col col-6 col-md-8 col-lg-3">
                <Select
                  className="select-default"
                  options={pohodaHistory.map(pohodaHistoryEntry => {
                    return {
                      value: pohodaHistoryEntry._id.toString(),
                      label: baseUtils.formatDate(pohodaHistoryEntry.date, true)
                    };
                  })}
                  onChange={(value: any) => handlePohodaReversionDateChange(value)}
                  value={pohodaReversionObject}
                />
              </div>
            </div>
          </>
        )}
        {pohodaMode.value === PohodaMode.IMPORT && activeProcess && activeStep === 0 && (
          <>
            <div className="row mt-2">
              <div className="col col-6 col-md-4 col-lg-3 align-self-center">Beginning Date</div>
              <div className="col col-6 col-md-8 col-lg-3">
                <DateInput
                  value={excludingUpperBoundary}
                  onBlur={handleChangeDate}
                  name="target"
                  max={new Date(new Date().setDate(new Date().getDate() - 1))}
                  allowClear={true}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col col-6 col-md-4 col-lg-3 align-self-center">Upload Pohoda List</div>
              <div className="col col-4 col-md-8 col-lg-3">
                <div className="row">
                  <div className="col-12">
                    <button
                      className="btn btn-success"
                      type="button"
                      onClick={() => pohodaFileReference.current?.click()}
                    >
                      <i className="fa fa-upload" />
                      <span className="kt-hidden-mobile">Upload Pohoda List</span>
                    </button>
                    {invalidDocument ? (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 100, hide: 400 }}
                        overlay={
                          <Tooltip id="infoMessage-popover-error">
                            <span>
                              The uploaded file could not be parsed! Please make sure the file is a valid .csv and
                              containing following structure:
                              <br />
                              Column1: X (Containing Spacer) <br />
                              Column 2: Evidenční č (Containing Lots)
                              <br />
                              Column 3: Kód zásoby (Containing internal codes) <br />
                              Column 4: Název (Containing the name of the commodity) <br />
                            </span>
                          </Tooltip>
                        }
                      >
                        <i className="fa fa-exclamation-triangle fa-2x text-warning ml-3 align-middle" />
                      </OverlayTrigger>
                    ) : (
                      parsedPohodaEntries?.length > 0 && (
                        <i className="fa fa-check fa-2x text-success ml-3 align-middle" />
                      )
                    )}
                  </div>
                </div>
                <input
                  type="file"
                  ref={pohodaFileReference}
                  accept="text/csv"
                  style={{ display: "none" }}
                  onChange={handleParsePohodaCSV}
                />
              </div>
            </div>
          </>
        )}
        {pohodaMode.value === PohodaMode.IMPORT && activeProcess && activeStep === 1 && missingEntries.length > 0 && (
          <div className="alert alert-warning" role="alert">
            <div className="alert-icon">
              <i className="flaticon-warning" />
            </div>
            <div className="alert-text">
              There were <span className="font-weight-bold">{missingEntries.length}</span> pohoda entries, that not
              could parse with the existing data in the system! <br /> This could be due to the fact that a lot or
              commodity is missing.
            </div>
          </div>
        )}
        {pohodaMode.value === PohodaMode.IMPORT && activeProcess && activeStep === 1 && (
          <>
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-9">
                    <ul className="nav card-header-pills nav-pills mb-0">
                      <li className="nav-item">
                        <a
                          className={"nav-link " + (showBatchesToDisable ? "active" : "")}
                          onClick={handleShowBatchesToDisable}
                        >
                          Batches to disable (
                          {relatedCommoditiesAndBatchesToBeDisabled?.reduce((acc, commodity) => {
                            return acc + commodity.stock.length;
                          }, 0)}
                          )
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={"nav-link " + (!showBatchesToDisable ? "active" : "")}
                          onClick={handleShowMissingEntries}
                        >
                          Missing Commodities/Batch Entries ({missingEntries?.length})
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-3 text-right">
                    <button className="btn btn-info" onClick={!hideContent ? handleOnHide : handleOnShow}>
                      <i className={!hideContent ? "fa fa-chevron-up" : "fa fa-chevron-down"} />
                      <span>{!hideContent ? "Hide" : "Show"}</span>
                    </button>
                  </div>
                </div>
              </div>
              {!hideContent && (
                <div className="card-body">
                  {showBatchesToDisable ? (
                    commoditiesToDisableOverview
                  ) : (
                    <MissingCommodityOrBatchOverview missingPohodaEntries={missingEntries} />
                  )}
                </div>
              )}
            </div>
            <div className="row ml-1 mt-3">
              <label className="kt-checkbox kt-font-dark m-0 pl-4">
                <input
                  type="checkbox"
                  onChange={() => handleToggleAwareness(ToggleAwarenessObject.DISABLE)}
                  checked={disablingAwarenessConfirmed}
                />
                <span />
                <div className="pl-1 kt-font-dark">
                  I am aware that all batches listed under (Batches to disable) will be disabled with the next step.
                </div>
              </label>
            </div>
            <div className="row ml-1 mt-3">
              <label className="kt-checkbox kt-font-dark m-0 pl-4">
                <input
                  type="checkbox"
                  onChange={() => handleToggleAwareness(ToggleAwarenessObject.MISSING)}
                  checked={missingEntriesAwarenessConfirmed}
                />
                <span />
                <div className="pl-1 kt-font-dark">
                  I am aware that the entries under Missing Commodities/Batch Entries are not considered.
                </div>
              </label>
            </div>
          </>
        )}
      </div>
      <div className="kt-portlet__foot">
        <div className="float-right">
          {activeProcess && (
            <button className="btn btn-secondary mr-3" onClick={handleCancelation}>
              Cancel
            </button>
          )}
          {pohodaMode.value === PohodaMode.IMPORT && (
            <ErrorOverlayButton
              errors={errors}
              saving={transformingData || disablePohodaBatches}
              showLoadingIndicatorOnSaving
              buttonText={!activeProcess ? "Start" : activeStep === 0 ? "Continue (Step 1/2)" : "Confirm (Step 2/2)"}
              className="btn btn-success mr-3"
              onClick={
                !activeProcess
                  ? handleStartProcess
                  : activeStep === 0
                  ? handleStartDataTransforming
                  : handleStartBatchDisabling
              }
            />
          )}
          {pohodaMode.value === PohodaMode.REVERT && (
            <ErrorOverlayButton
              errors={errors}
              saving={revertingPohodaProcess}
              showLoadingIndicatorOnSaving
              buttonText={!activeProcess ? "Start" : "Confirm"}
              className="btn btn-success mr-3"
              onClick={!activeProcess ? handleStartProcess : handleRevertPohodaProcess}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(PohodaImport, (prevProps, nextProps) => {
  return (
    _.isEqual(prevProps.context.commodities, nextProps.context.commodities) &&
    _.isEqual(prevProps.context.pohodaHistory, nextProps.context.pohodaHistory)
  );
});
