import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { toAbsoluteUrl } from "../../../_metronic";
import { DataContext } from "../../../context/dataContext";
import dbService, { ADDITIVES } from "../../../services/dbService";
import toastUtils from "../../../utils/toastUtils";

interface CreateAdditiveProps {
  context: React.ContextType<typeof DataContext>;
}
interface CreateAdditiveState {
  eNumber: string;
  nameEN: string;
  nameDE: string;
  show: boolean;
  saving: boolean;
}

class CreateAdditiveModal extends PureComponent<CreateAdditiveProps, CreateAdditiveState> {
  constructor(props: CreateAdditiveProps) {
    super(props);
    this.state = this.getDefaultState();
  }
  getDefaultState = () => {
    return { eNumber: "", nameEN: "", nameDE: "", show: false, saving: false };
  };
  handleShow = () => this.setState({ show: true });
  handleClose = () => {
    this.setState(this.getDefaultState());
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCreate = async () => {
    const { eNumber, nameDE, nameEN } = this.state;
    this.setState({ saving: true });
    if (!this.checkForMissingData()) {
      toast.error(
        <b>
          <i className="fa fa-exclamation mx-2" />
          Please complete the input
        </b>
      );
      this.setState({ saving: false });
      return;
    }

    const additive = { eNumber: "E" + eNumber.replace(/^E|e/, ""), name: { en: nameEN, de: nameDE } };
    const result = await dbService.insertDocument(ADDITIVES, additive);
    await toastUtils.databaseOperationToast(
      !!result && !!result.insertedId,
      ` Additive inserted successfully`,
      ` Error inserting additive`,
      () => this.setState(this.getDefaultState())
    );
    this.setState({ saving: false });
  };

  /**
   * Check the capsule creation for missing data.
   * @returns { boolean } whether data is complete or not
   */
  checkForMissingData = () => {
    const { eNumber, nameEN, nameDE } = this.state;
    if (!nameDE) return false;
    if (!nameEN) return false;
    if (!eNumber) return false;
    return true;
  };

  render() {
    const { show, nameDE, nameEN, eNumber, saving } = this.state;
    return (
      <>
        <div className=" col-align-end text-right">
          <button onClick={this.handleShow} className={"btn btn-secondary"} style={{ borderRadius: "4px" }}>
            Create Additive
          </button>
        </div>
        <Modal show={show} centered onHide={this.handleClose} name={"CreateAdditiveModal"} closeButton>
          <Modal.Header>
            <Modal.Title>Create Additive</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12">
              <div className={"input-group mb-2"}>
                <div className="input-group-prepend">
                  <span className="input-group-text">E -</span>
                </div>
                <input
                  className="form-control"
                  type="text"
                  onChange={this.handleChange}
                  placeholder="E-Number"
                  value={eNumber}
                  name="eNumber"
                />
              </div>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img src={toAbsoluteUrl("/media/icons/icon-de.png")} className="country-icon" />
                  </span>
                </div>
                <input
                  className="form-control"
                  type="text"
                  onChange={this.handleChange}
                  value={nameDE}
                  placeholder="German Name"
                  name="nameDE"
                />
              </div>
              <div className="input-group mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img src={toAbsoluteUrl("/media/icons/icon-en.png")} className="country-icon" />
                  </span>
                </div>
                <input
                  className="form-control"
                  type="text"
                  onChange={this.handleChange}
                  placeholder="English Name"
                  value={nameEN}
                  name="nameEN"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <button
              className={"btn btn-success " + (saving && "disabled")}
              disabled={saving}
              onClick={saving ? undefined : this.handleCreate}
            >
              {saving && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Create Additive
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateAdditiveModal;
