import React from "react";
import { ExtendedCustomer, Preferences, SelectedCommoditiesDocument, SelectedPackagingsDocument } from "./CustomTypes";

interface ConfiguratorTabsProps {
  productType: string;
  fullscreenMode: boolean;
  requestComplete: boolean;
  currentStep: number;
  changeStep: (step: number) => void;
  recipe: Array<SelectedCommoditiesDocument>;
  selectedPackaging: Array<SelectedPackagingsDocument>;
  preferences: Preferences;
  customer: ExtendedCustomer | null;
}

const ConfiguratorTabs: React.FunctionComponent<ConfiguratorTabsProps> = React.memo(
  ({
    currentStep,
    fullscreenMode,
    changeStep,
    productType,
    recipe,
    selectedPackaging,
    preferences,
    customer,
    requestComplete
  }) => {
    return (
      <div
        className={!fullscreenMode ? "kt-grid__item kt-wizard-v2__aside" : "kt-grid__item kt-wizard-v2__aside d-none"}
      >
        <div className="kt-wizard-v2__nav">
          <div className="kt-wizard-v2__nav-items kt-wizard-v2__nav-items--clickable">
            <div
              className="kt-wizard-v2__nav-item"
              data-ktwizard-type="step"
              data-ktwizard-state={currentStep === 1 ? "current" : "pending"}
              onClick={() => changeStep(1)}
            >
              <div className="kt-wizard-v2__nav-body">
                <div className="kt-wizard-v2__nav-icon text-center" style={{ width: "20px" }}>
                  1
                </div>
                <div className="kt-wizard-v2__nav-label">
                  <div className="kt-wizard-v2__nav-label-title">
                    {productType === "custom" || productType === "softgel"
                      ? "Product"
                      : productType === "service"
                      ? "Service"
                      : "Recipe"}
                    {recipe.length > 0 && (
                      <React.Fragment>
                        &nbsp;&nbsp;
                        <i
                          className={
                            recipe.some(r => !!r.disabled)
                              ? "text-danger fas fa-times-circle"
                              : "text-success fa fa-check-circle"
                          }
                        />
                      </React.Fragment>
                    )}
                  </div>
                  <div className="kt-wizard-v2__nav-label-desc">Create the recipe to calculate</div>
                </div>
              </div>
            </div>
            <div
              className={
                preferences.bulk || productType === "service"
                  ? "kt-wizard-v2__nav-item d-none"
                  : "kt-wizard-v2__nav-item"
              }
              data-ktwizard-type="step"
              data-ktwizard-state={currentStep === 2 ? "current" : "pending"}
              onClick={() => changeStep(2)}
            >
              <div className="kt-wizard-v2__nav-body">
                <div className="kt-wizard-v2__nav-icon text-center" style={{ width: "20px" }}>
                  2
                </div>
                <div className="kt-wizard-v2__nav-label">
                  <div className="kt-wizard-v2__nav-label-title">
                    Packaging
                    {selectedPackaging.length > 0 && (
                      <React.Fragment>
                        &nbsp;&nbsp;
                        <i
                          className={
                            selectedPackaging.some(sp => !!sp.disabled)
                              ? "text-danger fas fa-times-circle"
                              : "text-success fa fa-check-circle"
                          }
                        />
                      </React.Fragment>
                    )}
                  </div>
                  <div className="kt-wizard-v2__nav-label-desc">Add Bottle, Label, Box and more</div>
                </div>
              </div>
            </div>
            <div
              className="kt-wizard-v2__nav-item"
              data-ktwizard-type="step"
              data-ktwizard-state={currentStep === 3 ? "current" : "pending"}
              onClick={() => changeStep(3)}
            >
              <div className="kt-wizard-v2__nav-body">
                <div className="kt-wizard-v2__nav-icon text-center" style={{ width: "20px" }}>
                  3
                </div>
                <div className="kt-wizard-v2__nav-label">
                  <div className="kt-wizard-v2__nav-label-title">
                    Select Customer
                    {customer && (
                      <React.Fragment>
                        &nbsp;&nbsp;
                        <i className="text-success fa fa-check-circle" />
                      </React.Fragment>
                    )}
                  </div>
                  <div className="kt-wizard-v2__nav-label-desc">Select customer to address</div>
                </div>
              </div>
            </div>
            <div
              className="kt-wizard-v2__nav-item"
              data-ktwizard-type="step"
              data-ktwizard-state={currentStep === 4 ? "current" : "pending"}
              onClick={() => changeStep(4)}
            >
              <div className="kt-wizard-v2__nav-body">
                <div className="kt-wizard-v2__nav-icon text-center" style={{ width: "20px" }}>
                  4
                </div>
                <div className="kt-wizard-v2__nav-label">
                  <div className="kt-wizard-v2__nav-label-title">
                    Calculate{" "}
                    {requestComplete && (
                      <React.Fragment>
                        &nbsp;&nbsp;
                        <i className="text-success fa fa-check-circle" />
                      </React.Fragment>
                    )}
                  </div>
                  <div className="kt-wizard-v2__nav-label-desc">Calculate offer</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default ConfiguratorTabs;
