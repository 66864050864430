import _ from "lodash";
import React, { Component } from "react";
import { Modal, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import LanguageSelectionDropdown from "../../common/LanguageSelectionDropdown";
import { DataContext } from "../../../context/dataContext";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import offerPDFGeneration from "../../../utils/pdf/offerPDFGeneration";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import { CustomOrder } from "../CustomTypes";
import userService from "../../../services/userService";
import orderUtils, { OFFER, REQUESTPENDING } from "../../../utils/orderUtils";
import dateUtils from "../../../utils/dateUtils";
import { T_OFFERPDF } from "../../../utils/timelineUtils";
import { MARGIN } from "../../../utils/orderCalculationUtils";
import dbService, { UpdateAction, ORDERS } from "../../../services/dbService";
import baseUtils from "../../../utils/baseUtils";

interface EditMarginModalProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
}

interface EditMarginModalState {
  order: CustomOrder;
  calculationInputs: Array<{
    id: string;
    units: string;
    unitPrice: string;
    percentMargin: string;
  }>;
  processingData: boolean;
  show: boolean;
}
class EditMarginModal extends Component<EditMarginModalProps, EditMarginModalState> {
  constructor(props: EditMarginModalProps) {
    super(props);
    const order = _.cloneDeep(props.order);
    const calculationInputs = (order ? order : props.order).calculations.map(calculation => {
      return {
        id: calculation.id,
        units: calculation.units.toString(),
        unitPrice: calculation.info.unitprice.toString(),
        percentMargin: calculation.info.percentmargin.toString()
      };
    });
    this.state = {
      calculationInputs,
      order,
      processingData: false,
      show: false
    };
  }

  componentDidUpdate(
    prevProps: Readonly<EditMarginModalProps>,
    prevState: Readonly<EditMarginModalState>,
    snapshot?: any
  ) {
    if ((this.state.show && !_.isEqual(prevProps.order, this.props.order)) || (!prevState.show && this.state.show)) {
      const order = _.cloneDeep(this.props.order);
      const calculationInputs = order.calculations.map(calculation => {
        return {
          id: calculation.id,
          units: calculation.units.toString(),
          unitPrice: calculation.info.unitprice.toString(),
          percentMargin: calculation.info.percentmargin.toString()
        };
      });
      this.setState({ calculationInputs, order });
    }
  }

  /**
   * Update the calculation when the unit amount is updated
   * @param e: ChangeEvent that was triggered
   * @param id: ID of the calculation
   */
  handleChangeUnits = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { calculationInputs, order } = this.state;
    const calculation = order.calculations.find(c => c.id === id);
    const value = Number(e.target.value);
    if (!calculation || (!value && value !== 0)) return;
    const calculationInput = calculationInputs.find(ci => ci.id === id);
    calculationInput!.units = e.target.value;
    calculation.units = value;
    calculation.info.totalprice = calculation.info.unitprice * calculation.units;
    calculation.info.totalmargin = calculation.info.totalprice - calculation.info.unitpricenaked * calculation.units;
    this.setState({ calculationInputs, order });
  };

  /**
   * Update the calculation when the unit price is updated.
   * @param e: ChangeEvent that was triggered
   * @param id: ID of the calculation
   */
  handleChangeUnitPrice = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { calculationInputs, order } = this.state;
    const calculation = order.calculations.find(c => c.id === id);
    const value = Number(e.target.value);
    if (!calculation || (!value && value !== 0)) return;
    const calculationInput = calculationInputs.find(ci => ci.id === id);
    calculationInput!.unitPrice = e.target.value;
    calculation.info.unitprice = Math.ceil(value * 100) / 100;
    calculation.margin = value - calculation.info.unitpricenaked;
    calculation.info.unitmargin = value - calculation.info.unitpricenaked;
    calculation.info.totalprice = calculation.info.unitprice * calculation.units;
    calculation.info.totalmargin = calculation.info.totalprice - calculation.info.unitpricenaked * calculation.units;
    calculation.info.percentmargin = (calculation.info.unitprice / calculation.info.unitpricenaked - 1) * 100;
    calculationInput!.percentMargin = calculation.info.percentmargin.toString();
    this.setState({ calculationInputs, order });
  };

  /**
   * Update the calculation when the percent margin is updated.
   * @param e: ChangeEvent that was triggered
   * @param id: ID of the calculation
   */
  handleChangePercentMargin = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { calculationInputs, order } = this.state;
    const calculation = order.calculations.find(c => c.id === id);
    const value = Number(e.target.value);
    if (!calculation || (!value && value !== 0)) return;
    const calculationInput = calculationInputs.find(ci => ci.id === id);
    calculationInput!.percentMargin = e.target.value;
    calculation.info.percentmargin = value;
    calculation.info.unitprice = Math.ceil(calculation.info.unitpricenaked * 100 * (1 + value / 100)) / 100;
    calculationInput!.unitPrice = calculation.info.unitprice.toString();
    calculation.info.unitmargin = calculation.info.unitprice - calculation.info.unitpricenaked;
    calculation.margin = calculation.info.unitprice - calculation.info.unitpricenaked;
    calculation.info.totalprice = calculation.info.unitprice * calculation.units;
    calculation.info.totalmargin = calculation.info.totalprice - calculation.info.unitpricenaked * calculation.units;
    this.setState({ calculationInputs, order });
  };

  /**
   * Saves the updated calculation and notifies the user about the result of the update.
   */
  handleClickSaveButton = async () => {
    const { context, order: customOrder } = this.props;
    const { order } = this.state;
    // Disabled the button
    this.setState({ processingData: true });
    const userId = new BSON.ObjectId(userService.getUserData()._id);
    const settings = {
      ...order.settings,
      manufacturer: order.settings.manufacturer._id,
      filler: order.settings.filler?._id.toString()
    };
    const productSpecification = [OFFER, REQUESTPENDING].includes(order.state);
    let path = "";
    if (!productSpecification) {
      // Generates the HTML for the offer confirmation
      const offerPDF = JSON.stringify({
        html: offerPDFGeneration.createOffer(
          order,
          settings,
          customOrder.createdFor,
          order.calculations[0].packagings.length === 0,
          false,
          context,
          false,
          false
        ),
        fileName:
          "AN-" +
          order.identifier +
          "_V" +
          orderUtils.countTimelineEntries(order, T_OFFERPDF) +
          "_" +
          dateUtils.timeStampDate() +
          ".pdf"
      });
      path = await pdfUtils.uploadAndReturnPath(offerPDF);
      if (path) {
        const timelineEntry = {
          id: new BSON.ObjectId(),
          type: T_OFFERPDF,
          offernumber: order.identifier,
          version: orderUtils.countTimelineEntries(order, T_OFFERPDF),
          date: new Date(),
          person: userId,
          path
        };
        await dbOrderService.pushToTimeline(order._id, timelineEntry);
        // fire and forget
        await dbOrderService.incrementVersion(order._id);
      }
    }
    let res;
    if (order.contractInformation) {
      const actions: Array<UpdateAction> = [];
      actions.push({
        collection: ORDERS,
        filter: { _id: order._id },
        update: {
          "calculations.0.units": order.calculations[0].units,
          "calculations.0.info": order.calculations[0].info,
          "calculations.0.margin": order.calculations[0].margin
        }
      });
      actions.push({
        collection: ORDERS,
        filter: { _id: order.contractInformation.contractId },
        update: { "contract.$[c].value": order.calculations[0].units }, // Calls are always orders
        arrayFilters: [{ "c._id": order.contractInformation.callId }]
      });
      res = await dbService.updatesAsTransaction(actions);
    } else {
      res = !!(await dbOrderService.updateCalculations(order._id, order.calculations));
    }
    if (res && (productSpecification || path)) {
      toast.success(
        <b>
          <i className="fa fa-check mr-2" />
          Margin updated successfully
        </b>
      );
    } else {
      toast.error(
        <b>
          <i className="fa fa-exclamation mr-2" />
          Margin update failed
        </b>
      );
    }
    this.handleClose();
  };

  handleShow = () => this.setState({ show: true, processingData: false });
  handleClose = () => this.setState({ show: false });

  /**
   * Deletes the referenced calculation. Also prevents the last calculation from being deleted.
   * @param id: ID of the calculation that should be deleted
   */
  handleDeleteCalculation = (id: string) => {
    const { order } = this.state;
    const calculationsNew = order.calculations.filter(c => c.id.toString() !== id.toString());
    if (calculationsNew.length > 0) {
      // @ts-ignore
      order.calculations = calculationsNew;
      this.setState({ order });
    }
  };

  render() {
    const { calculationInputs, order, processingData, show } = this.state;
    const productSpecification = [OFFER, REQUESTPENDING].includes(order.state);
    const isAdmin = userService.isAdmin();

    return (
      <>
        <button className="btn btn-sm btn-upper btn-secondary" onClick={this.handleShow}>
          Edit Margin
        </button>
        {show && (
          <Modal show={show} onHide={this.handleClose} size={"xl"} centered name={"editMarginModal"}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Margin for AN-{order.identifier}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table>
                <tbody>
                  {order.calculations.map((c, key) => {
                    const ci = calculationInputs.find(ci => ci.id === c.id);
                    return (
                      <tr
                        key={key}
                        className="m-4"
                        style={{
                          borderRadius: "10px",
                          padding: "20px",
                          background: "white"
                        }}
                      >
                        <td className={key === 0 ? "border-0" : ""}>
                          <div className={"row " + (key === 0 ? "" : "mt-4")}>
                            <div className="col-3">
                              <div className="input-group">
                                <input
                                  type="number"
                                  className={"form-control" + (isAdmin ? "" : " disabled")}
                                  value={c.units}
                                  name="units"
                                  disabled={!isAdmin}
                                  onChange={isAdmin ? e => this.handleChangeUnits(e, c.id) : undefined}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">Units</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="input-group">
                                <input
                                  className="form-control"
                                  value={ci!.unitPrice}
                                  placeholder="Margin"
                                  type="number"
                                  onChange={e => this.handleChangeUnitPrice(e, c.id)}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">€</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="input-group">
                                <input
                                  className="form-control"
                                  value={ci!.percentMargin}
                                  placeholder="Margin"
                                  type="number"
                                  onChange={e => this.handleChangePercentMargin(e, c.id)}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">%</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-2">
                              <span>
                                {c.info.percentmargin < MARGIN.CRITICAL && (
                                  <span className="kt-badge kt-badge--danger kt-badge--inline pt-2 pb-2">
                                    <b>
                                      <u>NOT ALLOWED!</u>
                                    </b>
                                  </span>
                                )}
                                {c.info.percentmargin < MARGIN.BAD && c.info.percentmargin >= MARGIN.CRITICAL && (
                                  <span className="kt-badge kt-badge--danger kt-badge--inline pt-2 pb-2">
                                    <b>CRITICAL MARGIN</b>
                                  </span>
                                )}
                                {c.info.percentmargin < MARGIN.GOOD && c.info.percentmargin >= MARGIN.BAD && (
                                  <span className="kt-badge kt-badge--warning kt-badge--inline pt-2 pb-2">
                                    <b>BAD MARGIN</b>
                                  </span>
                                )}
                                {c.info.percentmargin >= MARGIN.GOOD && (
                                  <span className="kt-badge kt-badge--success kt-badge--inline pt-2 pb-2">
                                    <b>GOOD MARGIN</b>
                                  </span>
                                )}
                              </span>
                            </div>
                            <div className="col-1 text-right">
                              <button
                                type="button"
                                disabled={order.calculations.length === 1}
                                onClick={() => this.handleDeleteCalculation(c.id)}
                                className={"btn btn-danger" + (order.calculations.length === 1 ? " disabled" : "")}
                              >
                                <i className="fa fa-trash p-0" />
                              </button>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-2 d-flex">
                              <i className="flaticon-piggy-bank custom-icon" />
                              <div className="d-block ml-2 align-self-center">
                                <div className="calculation-info-text">Unit Price Naked</div>
                                <span className="calculation-margin-text">
                                  {baseUtils.formatEuro(c.info.unitpricenaked)}
                                </span>
                              </div>
                            </div>
                            <div className="col-2 d-flex">
                              <i className="flaticon-piggy-bank custom-icon" />
                              <div className="d-block ml-2 align-self-center">
                                <div className="calculation-info-text">Unit</div>
                                <span className="calculation-margin-text">
                                  {baseUtils.formatEuro(c.info.unitprice)}
                                </span>
                              </div>
                            </div>
                            <div className="col-2 d-flex">
                              <i className="flaticon-piggy-bank custom-icon" />
                              <div className="d-block ml-2 align-self-center">
                                <div className="calculation-info-text">Total Price</div>
                                <span className="calculation-margin-text">
                                  {baseUtils.formatEuro(c.info.totalprice)}
                                </span>
                              </div>
                            </div>
                            <div className="col-2 d-flex">
                              <i className="flaticon-pie-chart custom-icon" />
                              <div className="d-block ml-2 align-self-center">
                                <div className="calculation-info-text">Total Margin</div>
                                <span className="calculation-margin-text">{c.info.percentmargin.toFixed(2)} %</span>
                              </div>
                            </div>
                            <div className="col-2 d-flex">
                              <i className="flaticon-pie-chart custom-icon" />
                              <div className="d-block ml-2 align-self-center">
                                <div className="calculation-info-text">Unit Margin</div>
                                <span className="calculation-margin-text">
                                  {baseUtils.formatEuro(c.info.unitmargin)}
                                </span>
                              </div>
                            </div>
                            <div className="col-2 d-flex">
                              <i className="flaticon-pie-chart custom-icon" />
                              <div className="d-block ml-2 align-self-center">
                                <div className="calculation-info-text">Total Margin</div>
                                <span className="calculation-margin-text">
                                  {baseUtils.formatEuro(c.info.totalmargin)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {!productSpecification && (
                <LanguageSelectionDropdown
                  labelPosition="front"
                  additionalWrapperStyles={{ width: "350px" }}
                  labelColumnClasses={"col-form-label"}
                  labelClasses={"kt-font-dark"}
                  wrapperClasses={"form-group row float-right mb-2"}
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary btn-sm btn-upper"
                style={{ width: "100px" }}
                onClick={this.handleClose}
              >
                Close
              </button>
              <button
                className={"btn btn-success btn-sm btn-upper " + (processingData ? "disabled" : "")}
                disabled={processingData}
                style={{ minWidth: "100px" }}
                onClick={this.handleClickSaveButton}
              >
                {processingData && (
                  <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                    <svg className="button-splash-spinner" viewBox="0 0 50 50">
                      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                    </svg>
                  </div>
                )}
                {processingData ? "Processing" : "Save"}
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

export default EditMarginModal;
