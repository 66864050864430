import React from "react";
import { Link } from "react-router-dom";
import orderUtils, { ARCHIVE, CREATEINVOICE, FULFILLMENT } from "../../../../utils/orderUtils";
import dateUtils from "../../../../utils/dateUtils";
import baseUtils from "../../../../utils/baseUtils";
import { ContractCall, OrdersDocument } from "../../../../model/orders.types";

interface OrderContractItemProps {
  call: ContractCall;
  order?: OrdersDocument;
}

const OrderContractItem: React.FunctionComponent<OrderContractItemProps> = ({ call, order }) => {
  const output = call.output
    ? call.output
    : order && [FULFILLMENT, CREATEINVOICE, ARCHIVE].includes(order.state)
    ? order.calculations[0].units
    : "-";

  return (
    <tr className="kt-datatable__row d-table-row">
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">{call.value}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">{call.date ? baseUtils.formatDate(call.date) : "-"}</span>
            <span className="kt-user-card-v2__email">
              {call.date ? dateUtils.generateTimeDiffString(call.date) : ""}
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">
              {call.called ? (
                order ? (
                  <>
                    {baseUtils.formatDate(call.called)}
                    <Link className="d-inline kt-user-card-v2__name kt-link ml-1" to={"/order/" + order._id.toString()}>
                      ({orderUtils.isOrder(order) ? "AT" : "AN"}-{order.identifier.toString()})
                    </Link>
                  </>
                ) : (
                  baseUtils.formatDate(call.called)
                )
              ) : (
                "-"
              )}
            </span>
            <span className="kt-user-card-v2__email">
              {call.called ? dateUtils.generateTimeDiffString(call.called) : ""}
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">{call.finished ? baseUtils.formatDate(call.finished) : "-"}</span>
            <span className="kt-user-card-v2__email">
              {call.finished ? dateUtils.generateTimeDiffString(call.finished) : ""}
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span
              className={"kt-user-card-v2__name " + (output !== "-" && "font-weight-bold ")}
              style={output !== "-" ? { fontSize: "1.3rem" } : {}}
            >
              {output}
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default OrderContractItem;
