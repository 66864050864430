import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { CustomOrder } from "../CustomTypes";
import baseUtils from "../../../utils/baseUtils";
import config from "../../../config/config.json";
import userService from "../../../services/userService";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import fileUtils from "../../../utils/fileUtils";

interface UploadFileModalProps {
  order: CustomOrder;
  disabled?: boolean;
}

interface UploadFileModalState {
  show: boolean;
  title: string;
  type: string;
  file: File | undefined;
  uploading: boolean;
}

class UploadFileModal extends PureComponent<UploadFileModalProps, UploadFileModalState> {
  uploadRef: React.RefObject<any>;
  constructor(props: UploadFileModalProps) {
    super(props);
    this.state = {
      show: false,
      title: "",
      type: "miscellaneous",
      file: undefined,
      uploading: false
    };
    this.uploadRef = React.createRef();
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false, file: undefined });

  handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) toast.error("No file selected");
    this.setState({ file: e.target.files![0] });
  };

  handleUpload = () => {
    this.setState({ uploading: true });
    const { order } = this.props;
    const { file, title, type } = this.state;
    const customer = order.createdFor.name;
    if (!file) toast.error("No file selected");
    const xhr = new XMLHttpRequest();
    let isoDate = new Date().toISOString();
    isoDate = isoDate.split(":").join("-");
    isoDate = isoDate.split(".").join("-");
    const fileType = fileUtils.getFileExtension(file!.name);

    let fileName = title ? baseUtils.encodeString(title) + "_" : "";
    fileName +=
      "AT-" +
      baseUtils.encodeString(order.identifier.toString()) +
      "_" +
      baseUtils.encodeString(customer) +
      "_" +
      isoDate +
      "." +
      fileType;

    xhr.open("POST", "https://mediahub.private-label-factory.com/upload/" + fileName, true);

    xhr.setRequestHeader("Content-Type", "application/octate-stream");
    xhr.onreadystatechange = async () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          try {
            const result = await this.addTimelineEntry(
              config.mediahubFileBase + xhr.responseText,
              title,
              type,
              file!.type,
              file!.size
            );
            if (result && result.modifiedCount) {
              toast.success("File uploaded successfully");
              this.setState({ show: false });
            } else toast.error("Error while updating the order");
          } catch (e) {
            toast.error("Uploaded succeeded but timeline entry could not be added");
          } finally {
            this.setState({ uploading: false });
          }
        } else {
          toast.error("Upload of file failed");
          this.setState({ uploading: false });
        }
      }
    };
    xhr.send(file);
  };

  /**
   * Push a timeline entry to an order
   * @param path mediahub path
   * @param title title of the file
   * @param type internal type of the file
   * @param fileType actual file type
   * @param fileSize size of the file
   * @returns { Realm.Services.MongoDB.UpdateResult<any> } result from update function
   */
  addTimelineEntry = (path: string, title: string, type: string, fileType: string, fileSize: number) => {
    const { order } = this.props;
    const mime = require("mime-types");
    const timelineEntry = {
      id: new BSON.ObjectId(),
      type: "file",
      date: new Date(),
      person: new BSON.ObjectId(userService.getUserData()._id),
      path: path,
      title: title,
      category: type,
      fileType: mime.extension(fileType).toUpperCase(),
      fileSize: fileSize
    };
    return dbOrderService.pushToTimeline(order._id, timelineEntry);
  };

  getFileDescription = () => {
    const { file } = this.state;
    return fileUtils.getFileDescription(file);
  };

  render() {
    const { disabled } = this.props;
    const { show, file, type, uploading } = this.state;
    return (
      <>
        <button
          className={"btn btn-label-brand btn-sm btn-bold mr-3 my-1 " + (disabled ? "btn-disabled" : "")}
          onClick={this.handleShow}
          disabled={disabled}
        >
          <i className="fa fa-arrow-circle-up" />
          Upload File
        </button>
        <Modal show={show} onHide={this.handleClose} centered name={"uploadFileModal"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="kt-font-brand fa fa-arrow-circle-up mr-2" />
              Upload File
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ paddingRight: "0rem" }}>
              <div className="row">
                <div className="col-12">
                  <h5 className="text-dark">File title</h5>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Title"
                    // Dont allow special characters but _
                    onChange={e => this.setState({ title: e.target.value.replaceAll(/[\W\s]/g, "") })}
                  />
                  <h5 className="mt-3 text-dark">File type</h5>
                  <select className="form-control" onChange={e => this.setState({ type: e.target.value })} value={type}>
                    <option value={"miscellaneous"}>Miscellaneous</option>
                    <option value={"printing_file"}>Printing file</option>
                    <option value={"picture"}>Picture</option>
                  </select>
                  <div className=" row my-3">
                    <div className="col-8">
                      <span className="text-center" style={{ color: "#595d6e", fontSize: "1rem", fontWeight: 500 }}>
                        {this.getFileDescription()}
                      </span>
                    </div>
                    <div className="col-4">
                      <button className="btn btn-success float-right" onClick={() => this.uploadRef.current.click()}>
                        Select File
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <input
              type="file"
              ref={this.uploadRef}
              accept="*"
              style={{ display: "none" }}
              onChange={this.handleSelectFile}
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            <button
              className={file || !uploading ? "btn btn-success" : "btn btn-success disabled"}
              disabled={!file || uploading}
              onClick={this.handleUpload}
            >
              {uploading && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Upload File
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default UploadFileModal;
