import { CustomOrder } from "../../components/order/CustomTypes";

/**
 * Create a qr code for an order
 * @param order an order document
 * @returns {string} html representation of pdf with qr code
 */
function createOrderQRCode(order: CustomOrder) {
  return `
<head>
  <link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet">
  <meta http-equiv="content-type" content="text/html; charset=utf-8">
</head>
<body style="font-family: Helvetica ">
  <div style="position: absolute; top: 25px; left: 40px; ">
    <div style="font-size: 36px; font-weight: 900">AT-${order.identifier}</div>
  </div>
  <div style="position: absolute; top: 90px; left: 15px;">
    <div style="text-align: center; background-color: #333333; border-radius: 10px; padding: 5px; margin-bottom: 5px">
       <img alt="QR" style=" border-radius: 8px" src="https://v2018.api2pdf.com/zebra?apikey={{API_KEY}}&format=QR_CODE&value=${
         process.env.REACT_APP_BASE_URL || ""
       }order/${order._id.toString()}" width="200"/>
    </div>
  </div>
</body>`;
}

export default { createOrderQRCode };
