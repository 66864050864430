import React, { PureComponent } from "react";
import { PackagingsDocument } from "../../model/packagings.types";
import { DataContext } from "../../context/dataContext";
import { Nav, Tab } from "react-bootstrap";
import { TabDefinition } from "../common/CustomTypes";
import PackagingPricing from "./tabPanels/PackagingPricing";
import PackagingSettings from "./tabPanels/PackagingSettings";
import PackagingDocuments from "./tabPanels/PackagingDocuments";
import PackagingTimeline from "./tabPanels/PackagingTimeline";
import PackagingPackagingOrders from "./tabPanels/PackagingPackagingOrders";
import PackagingOrders from "./tabPanels/PackagingOrders";
import PackagingStock from "./tabPanels/PackagingStock";

interface PackagingTabPanelProps {
  packaging: PackagingsDocument;
  context: React.ContextType<typeof DataContext>;
}

interface PackagingTabPanelState {
  currentTab: string;
}

class PackagingTabPanel extends PureComponent<PackagingTabPanelProps, PackagingTabPanelState> {
  constructor(props: PackagingTabPanelProps) {
    super(props);
    this.state = {
      currentTab: this.getTabDefinitions()[0].name
    };
  }

  getTabDefinitions = (): Array<TabDefinition> => {
    const { packaging, context } = this.props;
    return [
      {
        name: "timeline",
        tabContent: (
          <>
            <i className="flaticon2-time" /> Timeline
          </>
        ),
        component: <PackagingTimeline packaging={packaging} context={context} />
      },
      {
        name: "pricing",
        tabContent: (
          <>
            <i className="flaticon2-shopping-cart" /> Pricing
          </>
        ),
        component: <PackagingPricing packaging={packaging} context={context} />
      },
      {
        name: "stock",
        tabContent: (
          <>
            <i className="flaticon2-box-1" /> Stock
          </>
        ),
        component: <PackagingStock packaging={packaging} context={context} />
      },
      {
        name: "packagingOrders",
        tabContent: (
          <>
            <i className="flaticon-shopping-basket" /> Packaging Orders
          </>
        ),
        component: <PackagingPackagingOrders packaging={packaging} context={context} size="full" />
      },
      {
        name: "orders",
        tabContent: (
          <>
            <i className="flaticon-notepad" /> Related Orders
          </>
        ),
        component: <PackagingOrders packaging={packaging} context={context} />
      },
      {
        name: "documents",
        tabContent: (
          <>
            <i className="flaticon2-contract" /> Documents
          </>
        ),
        component: <PackagingDocuments packaging={packaging} context={context} />
      },
      {
        name: "settings",
        tabContent: (
          <>
            <i className="flaticon2-gear" /> Settings
          </>
        ),
        component: <PackagingSettings packaging={packaging} context={context} />
      }
    ];
  };

  /**
   * Handle tab changes
   * @param eventKey new tab
   */
  handleTab = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
  };

  /**
   * Render tabs
   * @param tabDefinition list with tab definitions
   * @returns {JSX.Element} rendered tabs
   */
  renderTabs = (tabDefinition: Array<TabDefinition>) => {
    return (
      <ul
        id={"packagingTabPanelTabs"}
        className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand"
        role="tablist"
      >
        {tabDefinition.map(tab => {
          if (!tab.condition || tab.condition(this.props.packaging))
            return (
              <li className="nav-item" key={tab.name}>
                {this.renderTab(tab.name, tab.tabContent)}
              </li>
            );
        })}
      </ul>
    );
  };

  /**
   * Render a tab
   * @param name name of the tab
   * @param content content to render
   * @returns {JSX.Element} rendered tab
   */
  renderTab = (name: string, content: JSX.Element) => {
    return (
      <Nav.Link role="tab" id={name} eventKey={name} active={name === this.state.currentTab}>
        {content}
      </Nav.Link>
    );
  };

  render() {
    const { packaging } = this.props;
    const { currentTab } = this.state;
    const tabDefinition = this.getTabDefinitions();
    return (
      <Tab.Container id="tabContainer" activeKey={currentTab} onSelect={this.handleTab}>
        <div className="kt-portlet kt-portlet--tabs mb-0">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-toolbar">{this.renderTabs(tabDefinition)}</div>
          </div>
          <div className="kt-portlet__body">
            <Tab.Content>
              {tabDefinition.map(tab => {
                if (!tab.condition || tab.condition(packaging))
                  return (
                    <Tab.Pane key={tab.name} eventKey={tab.name} transition={false}>
                      {tab.component}
                    </Tab.Pane>
                  );
              })}
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    );
  }
}

export default PackagingTabPanel;
