import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ProductionPlanHelper from "./ProductionPlanHelper";
import CompanyWidget from "../common/CompanyWidget";
import PersonWidget from "../common/PersonWidget";
import { ALLTORSO } from "../configurator/configuratorConstants";
import { CalendarWeekBadge, OrderWidget, PriorityBadge } from "../listings/common/BaseListingComponents";
import { DataContext } from "../../context/dataContext";
import { CompaniesDocument } from "../../model/companies.types";
import { OrdersDocument } from "../../model/orders.types";
import { PackagingsDocument } from "../../model/packagings.types";
import { UserdataDocument } from "../../model/userdata.types";
import accessUtils, { ACTIONS } from "../../utils/accessUtils";
import baseUtils from "../../utils/baseUtils";
import orderUtils, { ARCHIVE, CREATEINVOICE, FULFILLMENT, PRODUCTION, PRODUCTIONQUEUE } from "../../utils/orderUtils";
import { T_PRODUCTION } from "../../utils/timelineUtils";
import packagingUtils from "../../utils/packagingUtils";
import userService from "../../services/userService";
import notificationService, { R_ORDERPRODUCTIONSTARTED } from "../../services/notificationService";
import dbOrderService from "../../services/dbServices/dbOrderService";
import { ORDERS } from "../../services/dbService";

interface ProductionPlanWeekRowProps extends RouteComponentProps {
  order: OrdersDocument;
  context: React.ContextType<typeof DataContext>;
  onRescheduleOrder: () => void;
}

const ProductionPlanWeekRow: React.FunctionComponent<ProductionPlanWeekRowProps> = ({
  order,
  context,
  history,
  onRescheduleOrder
}) => {
  const company: CompaniesDocument = baseUtils.getDocFromCollection(context.companies, order.createdFor)!;
  const owner: UserdataDocument = baseUtils.getDocFromCollection(context.userdata, order.createdFrom)!;
  const orderType = ProductionPlanHelper.getOrderType(order, context);
  const typeSplit = orderType.split(" ");
  const isOpen = ![PRODUCTION, FULFILLMENT, CREATEINVOICE, ARCHIVE].includes(order.state);
  const isDone = [FULFILLMENT, CREATEINVOICE, ARCHIVE].includes(order.state);

  /**
   * Render packaging information cells
   * @returns {JSX.Element} td elements with packaging info
   */
  const renderPackagingInfo = () => {
    const { packagings } = context;
    const units = order.calculations[0].units;
    const packaging = order.calculations[0].packagings;
    if (packaging.length === 0) return <span className="kt-font-dark kt-font-bolder">Bulk</span>;
    const fullPackaging: Array<PackagingsDocument> = packaging.map(p =>
      baseUtils.getDocFromCollection(packagings, p._id.toString())
    );
    const torso = fullPackaging.find(p => ALLTORSO.includes(p.packaging_type));
    const torsoPrice = torso ? packaging.find(p => p._id.toString() === torso._id.toString()) : null;
    return (
      <span className="kt-font-bold kt-font-dark">
        {torso && torsoPrice
          ? `${torsoPrice.amount * units} x ${packagingUtils.getShortPackagingInfo(torso)}`
          : "No torso found"}
      </span>
    );
  };

  const handleReschedule = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRescheduleOrder();
  };

  const handleStartProduction = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const { updateDocumentInContext } = context;
    const timelineEntry = {
      type: T_PRODUCTION,
      date: new Date(),
      person: userService.getUserId()
    };
    try {
      const result = await dbOrderService.switchState(order._id, PRODUCTION, timelineEntry);
      if (result && result.modifiedCount) {
        toast.success("Order " + "AT-" + order.identifier + " updated successfully");
        notificationService.notify(R_ORDERPRODUCTIONSTARTED, order._id);
        await updateDocumentInContext(ORDERS, order._id);
      } else toast.error("Order could not be updated.");
    } catch (e) {
      console.error(e);
      toast.error("Unexpected Error occured: " + e.message);
    }
  };

  const canReschedule = accessUtils.canPerformAction(ACTIONS.PRODUCTIONPLANRESCHEDULE);
  const startProductionErrors = orderUtils.validateOrderDataForProduction(order, context.packagings);

  return (
    <tr
      className={"kt-datatable__row d-table-row table-hover "}
      style={isOpen ? {} : { background: "#fbfbfb" }}
      onClick={() => history.push("/order/" + order._id.toString())}
    >
      <td className="kt-datatable__cell d-table-cell" style={{ minWidth: "120px" }}>
        <OrderWidget document={order} prefix={"Order AT-"} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <CompanyWidget company={company} type="company" />
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <PersonWidget person={owner} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-font-bold kt-font-dark text-center">{typeSplit[0]}</span>
        {typeSplit.length > 1 && <span className="kt-font-dark text-center">{typeSplit.slice(1).join(" ")}</span>}
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <PriorityBadge document={order} />
      </td>
      <td className="kt-datatable__cell d-table-cell" style={{ minWidth: "120px" }}>
        <CalendarWeekBadge order={order} />
      </td>
      <td className="kt-datatable__cell d-table-cell text-center">
        <span className="kt-font-bold kt-font-dark">{orderUtils.getTotalProductAmount(order)}</span>
        <span className="kt-font-dark">in {order.calculations[0].units} units</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">{renderPackagingInfo()}</td>
      <td className="kt-datatable__cell d-table-cell">
        {isDone ? (
          <span>
            <span className="kt-badge kt-badge--inline kt-font-md kt-badge--pill kt-align-center kt-badge--success">
              Finished
            </span>
          </span>
        ) : !isOpen ? (
          <span>
            <span className={"kt-badge kt-badge--inline kt-font-md kt-badge--pill kt-align-center kt-badge--info"}>
              Production
            </span>
          </span>
        ) : (
          <span>
            <span className={"kt-badge kt-badge--inline kt-font-md kt-badge--pill kt-align-center kt-badge--warning"}>
              Ready
            </span>
          </span>
        )}
      </td>
      <td className="kt-datatable__cell d-table-cell ">
        {!isDone && (
          <div className="btn-group show text-center">
            <button
              type="button"
              className={`btn btn-secondary btn-group-xs btn-sm dropdown-toggle-split${
                order.state !== PRODUCTIONQUEUE && startProductionErrors.length !== 0 ? " btn-disabled" : ""
              }`}
              disabled={order.state !== PRODUCTIONQUEUE && startProductionErrors.length !== 0}
              onClick={handleStartProduction}
            >
              Start Production
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary btn-font-md dropdown-toggle"
              data-toggle="dropdown"
              onClick={e => e.stopPropagation()}
            ></button>
            <div className="dropdown-menu text-center">
              <span onClick={canReschedule ? handleReschedule : undefined}>Reschedule</span>
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export default withRouter(ProductionPlanWeekRow);
