import _ from "lodash";
import React, { useCallback, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import { useStateWithCallback } from "../../utils/useStateCallback";
import dateUtils from "../../utils/dateUtils";
import DateInput from "../common/DateInput";
import orderUtils, { ARCHIVE, CREATEINVOICE, DECLINED } from "../../utils/orderUtils";
import baseUtils from "../../utils/baseUtils";
import fileUtils, { DEFAULT_SEPARATOR, purgeStringForCSV } from "../../utils/fileUtils";
import { CompaniesDocument } from "../../model/companies.types";
import { T_DELIVERYINFORMATION, T_SHIPPINGLABEL } from "../../utils/timelineUtils";
import HistoryBackButton from "../listings/common/HistoryBackButton";

interface OrderStatisticsProps extends RouteComponentProps {}

const OrderStatistics: React.FunctionComponent<OrderStatisticsProps> = props => {
  const context = React.useContext(DataContext);
  return <OrderListing context={context} {...props} />;
};

interface OrderListingProps extends RouteComponentProps {
  context: React.ContextType<typeof DataContext>;
}

const OrderListing: React.FunctionComponent<OrderListingProps> = ({ context, history }) => {
  const [period, setPeriod] = useState<{ start: Date; end: Date }>(dateUtils.getDefaultPeriod());
  const [generating, setGenerating] = useStateWithCallback<boolean>(false);

  const handlePeriodChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as "start" | "end";
    let value = e.target.valueAsDate;
    setPeriod(prevPeriod => {
      const newPeriod = _.cloneDeep(prevPeriod);
      if (!value) return prevPeriod;
      if (name === "start") value.setHours(0, -value.getTimezoneOffset(), 0, 0);
      else value.setHours(23, 59 + -value.getTimezoneOffset(), 59, 999);
      _.set(newPeriod, name, value);
      return newPeriod;
    });
  }, []);

  const handleGenerateCSV = useCallback(async () => {
    const { orders, companies, commodities } = context;
    const { start, end } = period;
    setGenerating(true, () => {
      try {
        const orderListing: Array<Array<string>> = [];
        for (let i = 0; i < orders.length; i++) {
          const order = orders[i];
          // Skip offers and declined orders and orders outside the period
          if (
            !orderUtils.isOrder(order) ||
            order.state === DECLINED ||
            !(start < order.createdOn && order.createdOn < end)
          )
            continue;

          const customer: CompaniesDocument | undefined = baseUtils.getDocFromCollection(companies, order.createdFor);
          let shippingDate: Date | undefined | null = order.timeline.find(
            t => t.type === T_DELIVERYINFORMATION || t.type === T_SHIPPINGLABEL
          )?.date;
          // Fallback for completed orders
          if (!shippingDate && [ARCHIVE, CREATEINVOICE].includes(order.state)) shippingDate = order.delivery;

          orderListing.push([
            `AT-${order.identifier}`,
            purgeStringForCSV(customer?.name.trim() || "unknown", DEFAULT_SEPARATOR),
            orderUtils.getOrderUnitsString(order, context),
            orderUtils.getRecipeString(order, context),
            baseUtils.formatDate(order.createdOn),
            shippingDate ? baseUtils.formatDate(shippingDate) : "N/A"
          ]);
        }

        const headers = ["Auftragsnummer", "Kunde", "Menge", "Rezeptur", "Auftragseingang", "Versanddatum"];
        const fileName = `OrderListing_${baseUtils.formatDate(start)}-${baseUtils.formatDate(end, undefined, {
          timeZone: "UTC"
        })}_${baseUtils.formatDate(new Date())}.csv`;

        const csv = fileUtils.exportAsCSV(headers, orderListing, DEFAULT_SEPARATOR);
        fileUtils.downloadFile(csv, fileName, "text/plain");
      } finally {
        setGenerating(false);
      }
    });
  }, [context, period]);

  return (
    <div className="kt-portlet kt-portlet--mobile">
      <div className="kt-portlet__head kt-portlet__head--lg">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Order Listing</h3>
        </div>
        <HistoryBackButton history={history} />
      </div>
      <div className="kt-portlet__body">
        <div className="row mt-2">
          <div className="col text-dark font-weight-bolder mb-5">
            Exports the orders created within the given period with information about the units and recipes as well as
            the creation and shipping date.
          </div>
        </div>
        <div className="row">
          <div className="col col-6 col-md-4 col-lg-3 align-self-center">From</div>
          <div className="col col-6 col-md-8 col-lg-3">
            <DateInput
              value={period.start}
              min={dateUtils.getDefaultPeriod().start}
              onBlur={handlePeriodChange}
              name={"start"}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col col-6 col-md-4 col-lg-3 align-self-center">To</div>
          <div className="col col-6 col-md-8 col-lg-3">
            <DateInput value={period.end} max={new Date()} onBlur={handlePeriodChange} name={"end"} />
          </div>
        </div>
      </div>
      <div className="kt-portlet__foot">
        <div className="float-right">
          <button
            className="btn btn-success"
            disabled={generating}
            onClick={generating ? undefined : handleGenerateCSV}
          >
            {generating ? "Generating..." : "Generate CSV"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderStatistics;
