import { BSON } from "realm-web";
import { ManufacturerFeature, ManufacturersDocument } from "../model/manufacturers.types";
import userService from "../services/userService";
import { ROLES } from "./userdataUtils";
import { SuppliersDocument } from "../model/suppliers.types";
import { CompaniesDocument } from "../model/companies.types";

/**
 * Check if the given provider is a manufacturer or not
 * @param manufacturer a manufacturer, company or supplier
 * @returns { boolean } true if document os a ManufacturersDocument, false if not
 */
function isManufacturer(
  manufacturer: SuppliersDocument | ManufacturersDocument | CompaniesDocument
): manufacturer is ManufacturersDocument {
  return "productionLines" in manufacturer;
}

/**
 * Checks if the given user is an employee of the referenced manufacturer.
 * @param user ID of the user
 * @param manufacturer Manufacturer that should be checked
 * @returns { boolean } Indicating whether the user is employee of the manufacturer or not
 */
function isEmployeeOfManufacturer(user: BSON.ObjectId | string, manufacturer: ManufacturersDocument): boolean {
  return (
    isResponsibleOfManufacturer(user, manufacturer) ||
    (manufacturer.employees && manufacturer.employees.some(e => e.toString() === user.toString()))
  );
}

/**
 * Checks if the given user is the responsible of the given manufacturer.
 * @param user ID of the user
 * @param manufacturer Manufacturer that should be checked
 * @returns { boolean } Indicating if the user is responsible for the manufacturer or not
 */
function isResponsibleOfManufacturer(user: BSON.ObjectId | string, manufacturer: ManufacturersDocument): boolean {
  return user.toString() === manufacturer.person.toString();
}

/**
 * Checks if the given user is an employee of any manufacturer.
 * @param user ID of the user
 * @param manufacturers Manufacturers that should be checked
 * @returns { boolean } Indicating whether the user is employee any manufacturer or not
 */
function isEmployeeOfAnyManufacturer(
  user: BSON.ObjectId | string,
  manufacturers: Array<ManufacturersDocument>
): boolean {
  for (let manufacturer of manufacturers) {
    if (isEmployeeOfManufacturer(user, manufacturer)) return true;
  }
  return false;
}

/**
 * Check if the current user is assigned to a manufacturer
 * @param manufacturers list of manufacturers
 * @returns {string} id of manufacturer or empty string
 */
function checkCurrentUserManufacturer(manufacturers: Array<ManufacturersDocument>): string {
  const isProduction = userService.hasRole(ROLES.PRODUCTION, true);
  if (!isProduction) return "";
  const userId = userService.getUserId();
  for (let i = 0; i < manufacturers.length; i++) {
    const manufacturer = manufacturers[i];
    if (isEmployeeOfManufacturer(userId, manufacturer)) return manufacturer._id.toString();
  }
  return "";
}

/**
 * Check if the current user is assigned to a manufacturer and returns the manufacturer as Object
 * @param manufacturers list of manufacturers
 * @returns {"" | {value: string, label: string}} id and name of manufacturer or empty string
 */
function checkCurrentUserManufacturerObject(
  manufacturers: Array<ManufacturersDocument>
): "" | { value: string; label: string } {
  const isProduction = userService.hasRole(ROLES.PRODUCTION, true);
  if (!isProduction) return "";
  const userId = userService.getUserId();
  for (let i = 0; i < manufacturers.length; i++) {
    const manufacturer = manufacturers[i];
    if (isEmployeeOfManufacturer(userId, manufacturer))
      return { value: manufacturer._id.toString(), label: manufacturer.name.toString() };
  }
  return "";
}

/**
 * Check if a feature is supported by a manufacturer
 * @param manufacturer manufacturer to check
 * @param feature the feature to check for
 * @returns {boolean} true if feature is supported, else false
 */
function checkManufacturerFeature(manufacturer: ManufacturersDocument, feature: ManufacturerFeature): boolean {
  return !!manufacturer.features && manufacturer.features.includes(feature);
}

export default {
  isManufacturer,
  isEmployeeOfAnyManufacturer,
  isEmployeeOfManufacturer,
  isResponsibleOfManufacturer,
  checkCurrentUserManufacturer,
  checkCurrentUserManufacturerObject,
  checkManufacturerFeature
};
