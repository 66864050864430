import React, { useContext, useState } from "react";
import { BSON } from "realm-web";
import SimpleConfirmationModal from "../../common/SimpleConfirmationModal";
import { ExtendedEMOrder } from "../../../model/customTypes.types";
import baseUtils from "../../../utils/baseUtils";
import ExternalManufacturerHelper, { EM_ACCEPTED, EM_CHECKED } from "../ExternalManufacturerHelper";
import { ExternalManufacturerOrdersDocument } from "../../../model/externalManufacturerOrders.types";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";
import dbService, { EMORDERS } from "../../../services/dbService";
import userService from "../../../services/userService";
import toastUtils from "../../../utils/toastUtils";
import EMCommodityWidget from "../EMCommodityWidget";

interface AcceptRequestModalProps {
  order: ExtendedEMOrder | ExternalManufacturerOrdersDocument;
  iconBtn?: boolean;
}

const AcceptRequestModal: React.FunctionComponent<AcceptRequestModalProps> = ({ order, iconBtn }) => {
  const context = useContext(ExternalManufacturerContext);
  const commodity =
    "commodity" in order ? order.commodity : baseUtils.getDocFromCollection(context.commodities, order.commodityId);
  const extendedCommodity =
    "commodity" in order
      ? commodity
      : commodity
      ? ExternalManufacturerHelper.getExtendedCommodity(commodity, context)
      : null;
  const [show, setShow] = useState(false);

  const handleShow = (e: any) => {
    e.stopPropagation();
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const handleConfirm = async () => {
    const actions = [
      {
        collection: EMORDERS,
        filter: { _id: order._id },
        update: {
          state: EM_ACCEPTED
        },
        push: {
          timeline: {
            _id: new BSON.ObjectId(),
            type: EM_ACCEPTED,
            date: new Date(),
            person: userService.getUserId()
          }
        }
      }
    ];
    const result = await dbService.updatesAsTransaction(actions);
    await toastUtils.databaseOperationToast(
      !!result,
      "Request successfully accepted",
      "Request could not be accepted",
      () => handleClose()
    );
  };

  return (
    <>
      {iconBtn ? (
        <button
          type="button"
          className={"btn btn-success px-2 py-1 " + (order.state !== EM_CHECKED ? "disabled" : "")}
          disabled={order.state !== EM_CHECKED}
          onClick={handleShow}
        >
          <i className="flaticon2-check-mark p-0" />
        </button>
      ) : (
        <button
          className={"btn btn-success btn-sm btn-upper" + (order.state !== EM_CHECKED ? "disabled" : "")}
          disabled={order.state !== EM_CHECKED}
          onClick={handleShow}
        >
          Accept
        </button>
      )}
      <SimpleConfirmationModal.SimpleConfirmationModal
        show={show}
        size={"md"}
        modalTitle={
          <>
            Accept Request REQ-{order.identifier}
            <em>{order.reference}</em>
          </>
        }
        modalDescription={
          <>
            <div className="alert alert-info" role="alert">
              <div className="alert-icon">
                <i className="flaticon-warning" />
              </div>
              <div className="alert-text">
                Accept request REQ-{order.identifier} <em>{order.reference}</em> of {order.amount} kg of{" "}
                <em>{extendedCommodity ? extendedCommodity.title.en : "unknown commodity"}</em> for a total price of{" "}
                <b>{order.actualPrice ? baseUtils.formatEuro(order.actualPrice * order.amount) : "-,-- €"}</b>?
              </div>
            </div>
            <div className="form-group row mb-1">
              <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Commodity</label>
              <div className="col-8 align-self-center">
                <EMCommodityWidget commodity={extendedCommodity} />
              </div>
            </div>
            <div className="form-group row mb-1">
              <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Amount</label>
              <div className="col-8 align-self-center">
                <span className={"font-weight-bold text-dark"}>{order.amount} kg</span>
              </div>
            </div>
            <div className="form-group row mb-1">
              <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Price</label>
              <div className="col-8 align-self-center">
                <span
                  className={
                    "font-weight-bold " +
                    (order.actualPrice && order.priceLimit
                      ? order.actualPrice > order.priceLimit
                        ? "text-danger"
                        : "text-success"
                      : "text-success")
                  }
                >
                  {order.actualPrice ? baseUtils.formatEuro(order.actualPrice) : "-,-- €"}/kg
                </span>
              </div>
            </div>
            {order.priceLimit && (
              <div className="form-group row mb-1">
                <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Price Limit</label>
                <div className="col-8 align-self-center">
                  <span className="text-dark font-weight-bold">{baseUtils.formatEuro(order.priceLimit)}/kg</span>
                </div>
              </div>
            )}
            {order.actualDeliveryDate && (
              <>
                <div className="form-group row mb-1">
                  <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Delivery Date</label>
                  <div className="col-8 align-self-center">
                    <span
                      className={
                        "font-weight-bold " +
                        (order.deliveryDateLimit && order.actualDeliveryDate > order.deliveryDateLimit
                          ? "text-danger"
                          : "text-success")
                      }
                    >
                      {baseUtils.formatDate(order.actualDeliveryDate)}
                    </span>
                  </div>
                </div>
                {order.deliveryDateLimit && (
                  <div className="form-group row mb-1">
                    <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">
                      Delivery Date Limit
                    </label>
                    <div className="col-8 align-self-center">
                      <span className="text-dark font-weight-bold">
                        {baseUtils.formatDate(order.deliveryDateLimit)}
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        }
        confirmButtonText={"Confirm Price"}
        cancelButtonText={"Close"}
        onConfirm={handleConfirm}
        onClose={handleClose}
      />
    </>
  );
};

export default AcceptRequestModal;
