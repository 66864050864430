import React, { useMemo } from "react";
import i18n from "../../../../translations/i18n";
import baseUtils, { formatNumValueLocale } from "../../../../utils/baseUtils";
import Tooltip from "../../../common/Tooltip";
import { resolveTranslation } from "../../../../utils/translationUtils";
import { Batch, BatchLocation } from "../../../../model/warehouse/batch.types";
import { getBatchLocationAvailableAmount } from "../../../../utils/batchUtils";
import { getPackagingUnitAmount } from "../../../../utils/warehouseUtils";
import { BaseTableHeaderDefinition } from "../../../listings/BaseListing";

interface ReservationRowProps {
  batch: Batch;
}

const ReservationRow: React.FC<ReservationRowProps> = ({ batch }) => {
  const uniqueWarehouses = useMemo(
    () => Array.from(new Set(batch.locations.map(l => l.location.warehouseSnapshot.shortName))),
    [batch]
  );

  const filteredLocations = useMemo(() => {
    return batch.locations;
  }, [batch]);

  const reservationRowHeader: Array<BaseTableHeaderDefinition> = [
    {
      title: <i className="fas fa-lock text-muted pb-1" style={{ right: 0 }} />,
      size: 5,
      additionalColumnClasses: "pb-1"
    },
    { title: i18n.t("warehouse:available"), size: 19, additionalColumnClasses: "pb-1" },
    { title: i18n.t("warehouse:warehouseShort"), size: 20, additionalColumnClasses: "pb-1" },
    { title: i18n.t("warehouse:warehouseAreaShort"), size: 20, additionalColumnClasses: "pb-1" },
    { title: i18n.t("warehouse:storageSpaceShort"), size: 15, additionalColumnClasses: "pb-1" },
    { title: i18n.t("warehouse:packagingUnitAbbreviation"), size: 20, additionalColumnClasses: "pb-1" }
  ];

  return (
    <>
      <tr className="kt-datatable__row d-table-row nopadding">
        <td className="kt-datatable__cell d-table-cell">
          <span className="kt-user-card-v2__email mt-0 text-black kt-font-bold">{batch.sender.name}</span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className="kt-user-card-v2__email mt-0 text-black">{batch.lot}</span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className={"kt-user-card-v2__email " + (new Date() < batch.expiry ? "text-black" : "text-danger")}>
            {baseUtils.formatDate(batch.expiry)}
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name text-black kt-font-bold">
                {formatNumValueLocale(batch.totalAmount)}
              </span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell text-right">
          <Tooltip
            tooltipText={
              uniqueWarehouses.length === 1
                ? resolveTranslation(batch.locations[0].location.warehouseSnapshot.warehouseName)
                : i18n.t("warehouse:variousWarehouses")
            }
          >
            <div className={"label label-light label-inline kt-font-bold text-black my-auto"} style={{ width: 60 }}>
              {uniqueWarehouses.length === 1 ? uniqueWarehouses[0] : "VAR"}
            </div>
          </Tooltip>
        </td>
      </tr>
      <tr className="kt-datatable__row d-table-row">
        <td colSpan={7} className="px-0">
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive p-2 bg-light mb-0">
            <table className="kt-datatable__table d-table p-5 bg-light">
              <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
                <tr className="kt-datatable__row d-table-row">
                  {reservationRowHeader.map((def, idx) => (
                    <th
                      key={idx}
                      className={"kt-datatable__cell d-table-cell " + (def.additionalColumnClasses || "")}
                      style={{ width: `${def.size}%` }}
                    >
                      <span>{def.title}</span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                {filteredLocations.map(fL => (
                  <ReservationRowDetailRow key={fL._id.toString()} location={fL} />
                ))}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </>
  );
};

interface ReservationRowDetailRowProps {
  location: BatchLocation;
}

const ReservationRowDetailRow: React.FC<ReservationRowDetailRowProps> = ({ location }) => {
  const availableAmount = useMemo(() => getBatchLocationAvailableAmount(location), [location]);

  return (
    <tr className="kt-datatable__row d-table-row nopadding">
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <i className={"flaticon-add-label-button icon-md text-success"} />
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black kt-font-bold">
              <span className="text-break">{formatNumValueLocale(availableAmount)}</span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black font-weight-300">
              <span className="d-inline-block text-ellipsis align-middle" style={{ maxWidth: "110px" }}>
                {resolveTranslation(location.location.warehouseSnapshot.warehouseName)}
              </span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black font-weight-300">
              <span className="d-inline-block text-ellipsis align-middle" style={{ maxWidth: "110px" }}>
                {resolveTranslation(location.location.warehouseArea.warehouseName)}
              </span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span
              className={
                "kt-user-card-v2__name text-black " +
                (!!location.location.storageSpace?.storageSpaceNo ? "kt-font-bold" : "font-weight-300")
              }
            >
              <span>{location.location.storageSpace?.storageSpaceNo || "-"}</span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black font-weight-300">
              <span className="d-inline-block text-ellipsis align-middle" style={{ maxWidth: "90px" }}>
                {getPackagingUnitAmount(location.packagingUnits)}
              </span>
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default ReservationRow;
