import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDataContext } from "../../../../context/dataContext";
import i18n from "../../../../translations/i18n";
import { BaseActionModalProps } from "../../../../model/warehouse/common.types";
import { Batch } from "../../../../model/warehouse/batch.types";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import OnlyBookOutView, { BookOutSettings } from "./OnlyBookOutView";
import BookOutSpecificSelection from "./BookOutSpecificSelection";
import BookOutOverview, { BookOutData } from "./BookOutOverview";
import { ExtendedBatchLocation } from "../../../../model/warehouse/customTypes.types";
import dbService, { BATCH, RESERVATION } from "../../../../services/dbService";
import toastUtils from "../../../../utils/toastUtils";

enum BookOutModalViews {
  ORDER,
  RAW_MATERIAL,
  ONLY_BOOK_OUT
}

enum BookOutModalSteps {
  GENERAL_SELECTION,
  SPECIFIC_SELECTION,
  OVERVIEW
}

const BookOutModal: React.FC<BaseActionModalProps> = ({ show, onHide }) => {
  const dataContext = useDataContext();

  const [saving, setSaving] = useState<boolean>(false);
  const [step, setStep] = useState<BookOutModalSteps>(BookOutModalSteps.GENERAL_SELECTION);
  const [view, setView] = useState<BookOutModalViews>(BookOutModalViews.ONLY_BOOK_OUT);
  const [bookOutSettings, setBookOutSettings] = useState<BookOutSettings | null>(null);
  const [batchLocationSettings, setBatchLocationSettings] = useState<Array<ExtendedBatchLocation> | null>(null);
  const [bookOutData, setBookOutData] = useState<BookOutData | null>(null);

  useEffect(() => {
    if (!show) {
      // 300ms is close transition of the modal. Without this delay here the modal would visibly reset while closing
      setTimeout(() => {
        setStep(BookOutModalSteps.GENERAL_SELECTION);
        setView(BookOutModalViews.ONLY_BOOK_OUT);
        setBookOutSettings(null);
        setBatchLocationSettings(null);
        setBookOutData(null);
      }, 300);
    }
  }, [show]);

  const [errors, warnings] = useMemo(() => {
    const errors: Array<string> = [];
    const warnings: Array<string> = [];
    if (step === BookOutModalSteps.GENERAL_SELECTION) {
      if (view === BookOutModalViews.ONLY_BOOK_OUT) {
        if (!bookOutSettings) errors.push(i18n.t("warehouse:errorMissingBatchOrAmount"));
        if (bookOutSettings) {
          if (bookOutSettings.amount === 0) errors.push(i18n.t("warehouse:errorAmountZero"));
          if (bookOutSettings.amount > bookOutSettings.batch.totalAmount.value)
            errors.push(i18n.t("warehouse:errorExceedingAmount"));
        }
      }
    } else if (step === BookOutModalSteps.SPECIFIC_SELECTION) {
      if (!batchLocationSettings) errors.push(i18n.t("warehouse:errorMissingLocationSettings"));
      if (batchLocationSettings && bookOutSettings) {
        batchLocationSettings.forEach(s => {
          const usedAmount = s.remoteWarehouseData
            ? +s.remoteWarehouseData.amountToBookOut
            : s.packagingUnits.reduce((sum, p) => sum + +p.amountToBookOut, 0);
          const restAmount = s.amountAtLocation.value - usedAmount;
          const usedReservedAmount = s.reservations
            ? s.reservations.reduce((sum, r) => sum + +r.amountToBookOut, 0)
            : 0;
          const reservedAmount = s.reservations
            ? s.reservations.reduce((sum, r) => sum + r.reservedAmount.value, 0)
            : 0;
          if (restAmount < 0) {
            errors.push(i18n.t("warehouse:errorExceedingAmount"));
            return errors;
          }
          if (usedReservedAmount > reservedAmount)
            warnings.push(i18n.t("warehouse:warningUsedAmountExceedingReserved"));
          if (reservedAmount > 0 && reservedAmount - usedReservedAmount > restAmount)
            errors.push(i18n.t("warehouse:errorNotEnoughLeftForReservations"));
          s.reservations?.forEach(r => {
            if (+r.amountToBookOut > r.reservedAmount.value * 0.9 && !r.removeReservation)
              warnings.push(i18n.t("warehouse:warningReservationNoCanceled", { reservation: r.identifier }));
          });
        });
        const totalUsedAmount = batchLocationSettings.reduce(
          (sum, a) =>
            sum +
            +(a.remoteWarehouseData?.amountToBookOut || "0") +
            a.packagingUnits.reduce((sum2, p) => sum2 + +p.amountToBookOut, 0),
          0
        );
        const totalUsedReservedAmount = batchLocationSettings.reduce(
          (sum, a) => sum + (a.reservations ? a.reservations.reduce((sum2, r) => sum2 + +r.amountToBookOut, 0) : 0),
          0
        );
        if (totalUsedReservedAmount > totalUsedAmount)
          errors.push(i18n.t("warehouse:errorUsedReservedExceedsTotalUsed"));
        if (totalUsedAmount !== bookOutSettings.amount)
          warnings.push(
            i18n.t("warehouse:errorNotMatchingSelectedAmount", {
              usedAmount: `${totalUsedAmount}${bookOutSettings.batch.totalAmount.unit}`,
              selectedAmount: `${bookOutSettings.amount}${bookOutSettings.batch.totalAmount.unit}`
            })
          );
      }
    } else if (step === BookOutModalSteps.OVERVIEW) {
      if (!bookOutData) errors.push(i18n.t("warehouse:errorMissingBookOutData"));
    }
    return [errors, warnings];
  }, [bookOutSettings, batchLocationSettings, bookOutData, view, step]);

  const handleChangeBookOutSettings = useCallback(
    (batch?: Batch, amount?: number) => setBookOutSettings(!(batch && amount) ? null : { batch, amount }),
    []
  );

  const handleChangeBatchLocationSettings = useCallback(
    (locations: Array<ExtendedBatchLocation>) => setBatchLocationSettings(locations),
    []
  );

  const handleChangeBookOutData = useCallback((data: BookOutData) => setBookOutData(data), []);

  const handleNext = useCallback(() => setStep(prevState => prevState + 1), []);
  const handleBack = useCallback(() => setStep(prevState => prevState - 1), []);

  const handleBookOut = useCallback(async () => {
    if (!bookOutData || !bookOutSettings) return;
    const { batch } = bookOutSettings;
    const { reservations, newPackagingUnits, locations } = bookOutData;
    try {
      setSaving(true);
      const res = await dbService.callFunction<boolean>(
        "bookOutBatch",
        [batch._id, locations, newPackagingUnits, reservations],
        true
      );
      await toastUtils.databaseOperationToast(
        res,
        i18n.t("warehouse:successBookOut"),
        i18n.t("warehouse:errorBookOut"),
        () => {
          onHide();
          dataContext.updateDocumentInContext(BATCH, batch._id);
          reservations.forEach(r => dataContext.updateDocumentInContext(RESERVATION, r.reservation._id));
        }
      );
    } catch (e) {
      console.error(e);
      toast.error(`${i18n.t("common:unexpectedError")}: ${e.message}`);
    } finally {
      setSaving(false);
    }
  }, [bookOutData]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size={step === BookOutModalSteps.GENERAL_SELECTION ? undefined : "xl"}
      centered
      dialogClassName={"extendedModalDialog"}
      name={"bookOutModal"}
    >
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>{i18n.t("warehouse:bookOut")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {show && (
          <div className="px-2">
            {step === BookOutModalSteps.GENERAL_SELECTION && (
              <>
                <div className="btn-group w-100" role="group">
                  <button
                    type="button"
                    className={
                      "btn btn-light text-black disabled " + (view === BookOutModalViews.ORDER ? "active" : "")
                    }
                  >
                    {i18n.t("warehouse:orderTab")}
                  </button>
                  <button
                    type="button"
                    className={
                      "btn btn-light text-black disabled " + (view === BookOutModalViews.RAW_MATERIAL ? "active" : "")
                    }
                  >
                    {i18n.t("warehouse:rawMaterial")}
                  </button>
                  <button
                    type="button"
                    className={"btn btn-light text-black " + (view === BookOutModalViews.ONLY_BOOK_OUT ? "active" : "")}
                    onClick={() => setView(BookOutModalViews.ONLY_BOOK_OUT)}
                  >
                    {i18n.t("warehouse:onlyBookOutTab")}
                  </button>
                </div>
                {view === BookOutModalViews.ONLY_BOOK_OUT && (
                  <OnlyBookOutView
                    bookOutSettings={bookOutSettings}
                    onChangeBookOutSettings={handleChangeBookOutSettings}
                  />
                )}
              </>
            )}
            {step === BookOutModalSteps.SPECIFIC_SELECTION && bookOutSettings && (
              <BookOutSpecificSelection
                batch={bookOutSettings.batch}
                amount={bookOutSettings.amount}
                onChangeBatchLocationSettings={handleChangeBatchLocationSettings}
              />
            )}
            {step === BookOutModalSteps.OVERVIEW && bookOutSettings && batchLocationSettings && (
              <BookOutOverview
                batch={bookOutSettings.batch}
                batchLocationSettings={batchLocationSettings}
                onChangeBookOutData={handleChangeBookOutData}
              />
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ErrorOverlayButton
          saving={saving}
          buttonText={i18n.t("common:close")}
          className={"btn btn-secondary"}
          onClick={onHide}
        />
        {[BookOutModalSteps.SPECIFIC_SELECTION, BookOutModalSteps.OVERVIEW].includes(step) && (
          <ErrorOverlayButton
            saving={saving}
            buttonText={i18n.t("common:back")}
            className={"btn btn-secondary"}
            onClick={handleBack}
          />
        )}
        {step === BookOutModalSteps.OVERVIEW && (
          <ErrorOverlayButton
            errors={errors}
            warnings={warnings}
            saving={saving}
            className={"btn btn-success"}
            buttonText={i18n.t("warehouse:bookOut")}
            onClick={handleBookOut}
          />
        )}
        {[BookOutModalSteps.SPECIFIC_SELECTION, BookOutModalSteps.GENERAL_SELECTION].includes(step) && (
          <ErrorOverlayButton
            errors={errors}
            warnings={warnings}
            saving={saving}
            className={"btn btn-success"}
            buttonText={i18n.t("common:next")}
            onClick={handleNext}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default BookOutModal;
