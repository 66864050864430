import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import countryList from "i18n-iso-countries";
import { toAbsoluteUrl } from "../../../_metronic";
import { ExtendedEMCommodity } from "../../../model/customTypes.types";
import EMCommodityRequestModal from "../modals/EMCommodityRequestModal";
import ExternalManufacturerHelper from "../ExternalManufacturerHelper";

interface EmCommodityHeaderProps {
  commodity: ExtendedEMCommodity;
}

const EmCommodityHeader: React.FunctionComponent<EmCommodityHeaderProps> = ({ commodity }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const src = ExternalManufacturerHelper.getCommodityImage(commodity);
  return (
    <>
      {show && <EMCommodityRequestModal commodity={commodity} onClose={handleClose} />}
      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <div className="kt-widget kt-widget--user-profile-3">
            <div className="kt-widget__top">
              <div className="kt-widget__media">
                <img src={src} alt="color" />
              </div>
              <div className="kt-widget__content">
                <div className="kt-widget__head">
                  <div className="kt-widget__user">
                    <span
                      className={
                        "kt-widget__username kt-font-bold " + (commodity.disabled ? "text-danger" : "kt-font-dark")
                      }
                    >
                      {commodity.title.en ? commodity.title.en : "Unknown"}
                      <span style={{ fontSize: "1.1rem" }}>
                        {commodity.subtitle.en && (
                          <>
                            <br />
                            {commodity.subtitle.en}
                          </>
                        )}
                      </span>
                    </span>
                  </div>
                  <div className="kt-widget__action">
                    <button className={"btn btn-primary btn-sm"} id={"headerOrderCommodityButton"} onClick={handleShow}>
                      Request Commodity
                    </button>
                  </div>
                </div>
                <div className="kt-widget__info mt-1">
                  {commodity.country && (
                    <div className="kt-font-dark kt-font-bold">
                      <img
                        style={{
                          width: 20,
                          height: 20,
                          borderRadius: 5,
                          objectFit: "cover"
                        }}
                        alt={commodity.country}
                        src={toAbsoluteUrl("/media/icons/countries/" + commodity.country.toLowerCase() + ".png")}
                        className="country-icon mr-1"
                      />
                      <span className="align-middle">{countryList.getName(commodity.country, "en")}</span>
                    </div>
                  )}
                </div>
                <div className="kt-widget__info mt-1">
                  <span style={{ color: "#8a8a8a" }}>Article Number: </span>
                  <span className="kt-font-dark kt-font-bold ml-1 mr-3">{commodity.identifier || "-"}</span>
                  <span style={{ color: "#8a8a8a" }}>HS Code: </span>
                  <span className="kt-font-dark kt-font-bold ml-1 mr-3">{commodity.hs_code || "-"}</span>
                  <span style={{ color: "#8a8a8a" }}>CAS Number: </span>
                  <span className="kt-font-dark kt-font-bold ml-1 mr-3">{commodity.cas_number || "-"}</span>
                  <span style={{ color: "#8a8a8a" }}>
                    Internal Code
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="allocatedInfo">This is a legacy value and shouldn't be used anymore.</Tooltip>
                      }
                    >
                      <i className="fa fa-info-circle ml-1 text-info" />
                    </OverlayTrigger>
                    :{" "}
                  </span>
                  <span className="kt-font-dark ml-1 mr-3">{commodity.internal_code || "-"}</span>
                  {commodity.organic && (
                    <>
                      <span style={{ color: "#8a8a8a" }}>
                        Organic Code
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="allocatedInfo">This is a legacy value and shouldn't be used anymore.</Tooltip>
                          }
                        >
                          <i className="fa fa-info-circle ml-1 text-info" />
                        </OverlayTrigger>
                        :{" "}
                      </span>
                      <span className="kt-font-dark ml-1 mr-3">{commodity.organic_code || " -"}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmCommodityHeader;
