import React, { PureComponent } from "react";
import Select from "react-select";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import CompaniesOverviewTable from "./CompaniesOverviewTable";
import { getComponentState } from "../../utils/baseUtils";
import { SearchBar } from "../listings/common/Filters";

interface CompaniesProps extends RouteComponentProps<{}, {}, {}> {}

interface CompaniesState {
  query: string;
  rating: "" | { value: string; label: string };
  orderStatus: "" | { value: string; label: string };
}

const CONSTRUCTORNAME = "Companies";

class Companies extends PureComponent<CompaniesProps, CompaniesState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: CompaniesProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const state = getComponentState(this.context, CONSTRUCTORNAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ query: e.target.value });
  handleReset = () => this.setState(this.getDefaultState());
  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value.replace(/,/g, ".") });
  };
  handleSelect = (name: string, entry: "" | { value: string; label: string }) => {
    // @ts-ignore
    this.setState({ [name]: entry });
  };

  /**
   * Get the default state
   * @returns {CompaniesState} state for the component
   */
  getDefaultState = () => {
    return { query: "", rating: "", orderStatus: "" } as CompaniesState;
  };

  render() {
    const { history } = this.props;
    const { rating, orderStatus, query } = this.state;
    const { companies, orders, userdata } = this.context;

    return (
      <div>
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand fa fa-building" />
              </span>
              <h3 className="kt-portlet__head-title">Companies</h3>
              <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
                Reset
              </button>
            </div>
            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-wrapper">
                <button onClick={() => history.goBack()} className="btn btn-clean kt-margin-r-10">
                  <i className="la la-arrow-left" />
                  <span className="kt-hidden-mobile">Back</span>
                </button>
                <button type="button" className="btn btn-brand btn-icon-sm">
                  <Link to="/create-company">
                    <i className="fa fa-plus text-white" />
                    <span style={{ color: "white" }}>Create</span>
                  </Link>
                </button>
              </div>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="kt-form kt-form--label-right  kt-margin-b-10">
              <div className="row align-items-center">
                <div className="col-xl-8 order-2 order-xl-1">
                  <div className="row align-items-center">
                    <SearchBar onSearch={this.handleSearch} search={query} additionalSizeClasses="col-md-3" />
                    <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                      <div className="kt-form__group kt-form__group--inline">
                        <div className="kt-form__label">
                          <label>Status:</label>
                        </div>
                        <div className="kt-form__control">
                          <div className="dropdown">
                            <Select
                              className="select-default"
                              isClearable={true}
                              value={orderStatus ? orderStatus : { value: "all", label: "All" }}
                              onChange={(value: any) => this.handleSelect("orderStatus", value || "")}
                              options={[
                                { value: "inTime", label: "In Time" },
                                { value: "delayed", label: "Delayed" },
                                { value: "noOrder", label: "No Activ Orders" }
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                      <div className="kt-form__group kt-form__group--inline">
                        <div className="kt-form__label">
                          <label>Rating:</label>
                        </div>
                        <div className="kt-form__control">
                          <div className="dropdown">
                            <Select
                              className="select-default"
                              isClearable={true}
                              options={[
                                { value: "A", label: "A" },
                                { value: "B", label: "B" },
                                { value: "C", label: "C" }
                              ]}
                              value={rating ? rating : { value: "", label: "All Ratings" }}
                              onChange={(value: any) => this.handleSelect("rating", value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="kt-portlet__body kt-portlet__body--fit">
            <CompaniesOverviewTable
              companies={companies}
              orders={orders}
              userdata={userdata}
              orderStatus={orderStatus ? orderStatus.value : "all"}
              query={query}
              rating={rating ? rating.value : "all"}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Companies);
