import React, { PureComponent } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Select from "react-select";
import { DataContext } from "../../context/dataContext";
import { PaginationState } from "../common/CustomTypes";
import baseUtils, { getComponentState } from "../../utils/baseUtils";
import SplashScreen from "../common/SplashScreen";
import Pagination, { paginate } from "../common/Pagination";
import SupplierOverviewItem from "./SupplierOverviewItem";
import { SearchBar } from "../listings/common/Filters";
import { SuppliersDocument } from "../../model/suppliers.types";
import accessUtils, { CREATELOCATIONS } from "../../utils/accessUtils";

interface SupplierOverviewProps extends RouteComponentProps<{}, {}, {}> {}

interface SupplierOverviewState extends PaginationState {
  searchQuery: string;
  ratingFilter: "" | { value: string; label: string };
}
const CONSTRUCTORNAME = "SupplierOverview";

class SupplierOverview extends PureComponent<SupplierOverviewProps, SupplierOverviewState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  constructor(props: SupplierOverviewProps, context: React.ContextType<typeof DataContext>) {
    super(props, context);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const state = getComponentState(this.context, CONSTRUCTORNAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }

  handleReset = () => this.setState(this.getDefaultState());
  handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ searchQuery: e.target.value, currentPage: 1 });
  handleSelectChange = (entry: "" | { value: string; label: string }) =>
    this.setState({ ratingFilter: entry, currentPage: 1 });
  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });

  /**
   * Get the default state
   * @returns {SupplierOverviewState} state for the component
   */
  getDefaultState = () => {
    return {
      currentPage: 1,
      ratingFilter: "",
      pageSize: 10,
      searchQuery: ""
    } as SupplierOverviewState;
  };

  getFilteredSupplier = () => {
    const { suppliers, userdata } = this.context;
    const { ratingFilter, searchQuery } = this.state;
    const query = searchQuery.trim();
    let filteredSuppliers = [...suppliers];
    filteredSuppliers = filteredSuppliers.filter(s => !s.disabled);
    if (ratingFilter && ratingFilter.value !== "")
      filteredSuppliers = filteredSuppliers.filter(s => s.rating === ratingFilter.value);
    if (query !== "") {
      let suppliersFuse: Array<{
        supplier: SuppliersDocument;
        owner: string | null;
        personNames: string[] | null;
      }> = filteredSuppliers.map(s => {
        const owner = s.owner ? baseUtils.getDocFromCollection(userdata, s.owner) : null;
        const persons = s.person ? s.person.map(p => baseUtils.getDocFromCollection(userdata, p)) : null;
        const personNames = persons ? persons.map(p => p.prename + " " + p.surname) : null;
        return {
          supplier: s,
          owner: owner ? owner.prename + " " + owner.surname : "",
          personNames: personNames
        };
      });
      let filteredSuppliersFuse = baseUtils.doFuseSearch(suppliersFuse, query, [
        "supplier.name",
        "supplier.rating",
        "supplier.address.street",
        "supplier.address.streetnr",
        "supplier.address.zip",
        "supplier.address.city",
        "supplier.address.country",
        "owner",
        "personNames"
      ]);

      return filteredSuppliersFuse.map(s => s.supplier);
    }
    return filteredSuppliers;
  };

  render() {
    const { history } = this.props;
    const { suppliers, userdata } = this.context;
    const { currentPage, pageSize, searchQuery, ratingFilter } = this.state;
    const filteredSuppliers = this.getFilteredSupplier();
    return (
      <>
        {suppliers.length === 0 ? (
          <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
        ) : (
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              <div className="kt-portlet__head-label">
                <span className="kt-portlet__head-icon">
                  <i className="kt-font-brand fa fa-building" />
                </span>
                <h3 className="kt-portlet__head-title">Suppliers</h3>
                <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
                  Reset
                </button>
              </div>
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">
                  <button onClick={history.goBack} className="btn btn-clean kt-margin-r-10">
                    <i className="la la-arrow-left" />
                    <span className="kt-hidden-mobile">Back</span>
                  </button>
                  {accessUtils.canCreateData(CREATELOCATIONS.SUPPLIER) && (
                    <button type="button" className="btn btn-brand btn-icon-sm">
                      <i className="flaticon2-plus" />
                      <Link to="/create-supplier">
                        <span style={{ color: "white" }}>Add New</span>
                      </Link>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="kt-form kt-form--label-right  kt-margin-b-10">
                <div className="row align-items-center">
                  <div className="col-xl-8 order-2 order-xl-1">
                    <div className="row align-items-center">
                      <SearchBar
                        onSearch={this.handleSearchChange}
                        search={searchQuery}
                        additionalSizeClasses="col-md-3"
                      />
                      <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                        <div className="kt-form__group kt-form__group--inline">
                          <div className="kt-form__label">
                            <label>Rating:</label>
                          </div>
                          <div className="kt-form__control">
                            <div className="dropdown">
                              <Select
                                className="select-default"
                                isClearable={true}
                                options={[
                                  { value: "A", label: "A" },
                                  { value: "B", label: "B" },
                                  { value: "C", label: "C" }
                                ]}
                                value={ratingFilter ? ratingFilter : { value: "", label: "All" }}
                                onChange={(value: any) => this.handleSelectChange(value || "")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="kt-portlet__body kt-portlet__body--fit">
              <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
                <table className="kt-datatable__table d-table">
                  <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
                    <tr className="kt-datatable__row d-table-row">
                      <th className="kt-datatable__cell d-table-cell">
                        <span>Company</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell">
                        <span>Contact Person</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell" style={{ minWidth: "80px" }}>
                        <span>Rating</span>
                      </th>
                      <th className="kt-datatable__cell d-table-cell ">
                        <span>Owner</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="kt-datatable__body " style={{ display: "table-row-group" }}>
                    {paginate(filteredSuppliers, currentPage, pageSize).map(s => {
                      const owner = baseUtils.getDocFromCollection(userdata, s.owner);
                      const contact =
                        s.person.length > 0 ? baseUtils.getDocFromCollection(userdata, s.person[0]) : undefined;
                      return (
                        <SupplierOverviewItem
                          key={s._id.toString()}
                          supplier={s}
                          owner={owner}
                          contactPerson={contact}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <div className="kt-datatable__pager kt-datatable--paging-loaded">
                  <Pagination
                    itemsCount={filteredSuppliers.length}
                    pageSize={pageSize}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                    onPageSizeChange={this.handlePageSizeChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default SupplierOverview;
