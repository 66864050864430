import { BSON } from "realm-web";
import { calculation, pricing, Setting } from "../../model/orders.types";
import { DataContextType } from "../../context/dataContext";
import calculationUtils from "../calculationUtils";
import { PackagingsDocument } from "../../model/packagings.types";
import { CapsulesDocument, CapsuleSupplierType } from "../../model/capsules.types";
import { ManufacturersDocument } from "../../model/manufacturers.types";
import { PackagingTypes } from "../../components/configurator/configuratorConstants";
import calculationHelper from "../../components/configurator/calculationDetails/calculationHelper";
import pdfUtils from "./pdfUtils";
import { MARGIN } from "../orderCalculationUtils";
import baseUtils from "../baseUtils";
import { StickerForms } from "../../components/packaging/PackagingHelper";

function getMarginDescription(margin: number) {
  if (margin < MARGIN.CRITICAL) {
    return "<b>NOT ALLOWED</b>";
  } else if (margin < MARGIN.BAD && margin >= MARGIN.CRITICAL) {
    return "<b>CRITICAL</b>";
  } else if (margin < MARGIN.GOOD && margin >= MARGIN.BAD) {
    return "<b>BAD</b>";
  } else if (margin >= MARGIN.GOOD) {
    return "<b>GOOD</b>";
  } else {
    return "<b>ERROR</b>";
  }
}

function getExtendedPackaging(prices: Array<pricing>, packaging: Array<PackagingsDocument>) {
  let extendedPackaging: Array<any> = [];
  for (let i = 0; i < packaging.length; i++) {
    const pack = packaging[i];
    const price = prices.find(pr => pr._id.toString() === pack._id.toString());
    if (price) {
      extendedPackaging.push({ ...price, ...pack });
    }
  }
  return extendedPackaging;
}

function containsBottle(prices: Array<pricing>, packaging: Array<PackagingsDocument>) {
  const extendedPackaging = getExtendedPackaging(prices, packaging);
  return extendedPackaging.some(x => x?.packaging_type === PackagingTypes.BOTTLE);
}

function containsLiquidBottle(prices: Array<pricing>, packaging: Array<PackagingsDocument>) {
  const extendedPackaging = getExtendedPackaging(prices, packaging);
  return extendedPackaging.some(x => x?.packaging_type === PackagingTypes.LIQUIDBOTTLE);
}

function containsBlister(prices: Array<pricing>, packaging: Array<PackagingsDocument>) {
  const extendedPackaging = getExtendedPackaging(prices, packaging);
  return extendedPackaging.some(x => x?.packaging_type === PackagingTypes.BLISTER);
}

function findBlister(prices: Array<pricing>, packaging: Array<PackagingsDocument>) {
  const extendedPackaging = getExtendedPackaging(prices, packaging);
  return extendedPackaging.find(x => x?.packaging_type === PackagingTypes.BLISTER);
}

function createCalculationProtocol(
  calculations: Array<calculation>,
  calculationSettings: Setting,
  context: DataContextType
) {
  const { packagings, suppliers, capsules, manufacturers } = context;
  const selectedCapsule =
    calculationSettings.type === "capsule"
      ? capsules.find(cap => cap._id.toString() === calculationSettings.id.toString())
      : null;
  const perUnit = +calculationSettings.perUnit;
  const manufacturer = manufacturers.find(man => man._id.toString() === calculationSettings.manufacturer.toString())!;

  const creationTime = new Date().toLocaleDateString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  });
  let html =
    '<head><link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"><meta http-equiv="content-type" content="text/html; charset=utf-8"></head>';
  html +=
    '<body style="font-family: Roboto,serif;"><hr><br><img src="https://private-label-factory.de/config/assets/img/PLF_logo_bw.png" width="200"><br><br><h3 style="margin-bottom:0">Calculation Report</h3>';
  html += "<h3>" + manufacturer.name + "</h3>";
  html += '<span style="margin-top:0; float:right">Created on: ' + creationTime + "</span></body>";

  for (let i = 0; i < calculations.length; i++) {
    const calculation = calculations[i];
    const units = +calculation.units;
    html +=
      '<h5 style="margin-bottom:5px">Calculation #' +
      (i + 1) +
      " - Total: " +
      baseUtils.formatEuro(calculation.info.totalprice) +
      " - Units: " +
      calculation.units +
      " - Margin: " +
      calculation.info.percentmargin +
      "% (" +
      baseUtils.formatEuro(calculation.info.totalmargin) +
      ') - <span font-size: 12;"><b><span style="font-size:15">Calculation <u>';

    html += getMarginDescription(calculation.info.percentmargin);
    html +=
      '</u></span></b></span></h5><table cellpadding="5" cellspacing="0" style="font-size:12px;"><tr style="background-color:#cccccc"><td style="width:300px"><b>Description</b></td><td style="width:250px"><b>Amount (per Unit)</b></td><td style="width:280px"><b>Amount (total)</b></td><td style="width:500px"><b>Supplier</b></td><td style="width:200px"><b>Price (per Unit)</b></td><td style="width:200px"><b>Price (Total)</b></td></tr>';
    html += "<tbody>";

    // Handle recipe
    html += createRecipeProtocol(calculation, calculationSettings, context);

    // Handle capsule
    const capsulePrice = calculation.info.standardCalculation?.capsule;
    if (calculationSettings.type === "capsule" && selectedCapsule && capsulePrice) {
      const capsuleSupplierIsManufacturer =
        capsulePrice.supplierType && capsulePrice.supplierType === CapsuleSupplierType.MANUFACTURER;
      const capsuleSupplier = BSON.ObjectId.isValid(capsulePrice.supplier)
        ? capsuleSupplierIsManufacturer
          ? manufacturers.find(m => m._id.toString() === capsulePrice.supplier.toString())
          : suppliers.find(s => s._id.toString() === capsulePrice.supplier.toString())
        : capsulePrice.supplier;
      const capsuleSupplierName =
        typeof capsuleSupplier === "string" ? capsuleSupplier : capsuleSupplier?.name || "Unknown Supplier";

      html +=
        "<tr><td><b>Capsules</b> (" +
        selectedCapsule.capsule_size +
        ", " +
        selectedCapsule.capsule_material.en +
        ")<br>" +
        selectedCapsule.capsule_color.en +
        "</td><td><b>" +
        perUnit +
        "</b></td><td><b>" +
        (perUnit * units) / 1000 +
        "tsd." +
        "</b></td><td><b>" +
        capsuleSupplierName +
        "</b><br>" +
        "autoselect => <b>" +
        (!!capsulePrice.auto).toString() +
        "</b></td><td><b>" +
        baseUtils.formatEuro(capsulePrice.price) +
        "/tsd.</b></td><td><b><u>" +
        baseUtils.formatEuro((capsulePrice.price * perUnit * units) / 1000) +
        "</u></b></td>";
    }

    // Handle packaging
    html += createPackagingProtocol(calculation, calculationSettings, context);

    html += "</tbody>";
    html += "</table>";
    if (calculation.info.customCalculation) {
      html +=
        '<h5 style="margin-bottom:5px">Custom</h5><table cellpadding="5" cellspacing="0" style="font-size:12px;"><tr style="background-color:#cccccc"><td style="width:200px"><b>Description</b></td><td style="width:200px"><b>Amount (per Unit)</b></td><td style="width:200px"><b>Amount (total)</b></td><td style="width:200px"><b>Price (per Unit)</b></td><td style="width:200px"><b>Price (Total)</b></td></tr>';
      html += "<tbody>";
      html += createCapsuleTabletProtocol(
        calculation,
        calculationSettings,
        selectedCapsule,
        capsulePrice,
        manufacturer
      );

      if (
        containsBottle(calculation.packagings, packagings) &&
        ["capsule", "tablet", "softgel"].includes(calculationSettings.type) &&
        calculation.info.customCalculation.bottling
      ) {
        html +=
          "<tr><td><b>Bottling</b></td><td>1</td><td>" +
          units +
          "</td><td>" +
          baseUtils.formatEuro(+calculation.info.customCalculation.bottling.price) +
          "</td><td>" +
          baseUtils.formatEuro(units * +calculation.info.customCalculation.bottling.price) +
          "</td>";
      }

      if (calculationSettings.type === "powder" || calculationSettings.type === "liquid") {
        let weight = 0;
        for (let y = 0; y < calculation.prices.length; y++) {
          weight += +calculation.prices[y].amount;
        }
        const kiloWeight = (weight * +units) / (1000 * 1000);
        if (calculation.info.customCalculation.blending) {
          html +=
            "<tr><td><b>Mixing/Blending</b></td><td>1</td><td>" +
            calculationUtils.formatAmount(weight, 2) +
            "</td><td>" +
            baseUtils.formatEuro((calculation.info.customCalculation.blending.price * weight) / (1000 * 1000)) +
            "</td><td>" +
            baseUtils.formatEuro(calculation.info.customCalculation.blending.price * kiloWeight) +
            "</td></tr>";
          if (calculation.info.customCalculation.bottling) {
            html += "<tr><td><b>Bottling</b></td><td>1</td><td>" + units + "</td><td>";
            html +=
              baseUtils.formatEuro((calculation.info.customCalculation.bottling.price * weight) / (1000 * 1000)) +
              "</td><td>";
            html += baseUtils.formatEuro(calculation.info.customCalculation.bottling.price * kiloWeight) + "</td></tr>";
          }
        }
      }

      if (
        containsLiquidBottle(calculations[i].packagings, packagings) &&
        calculationSettings.type === "liquid" &&
        calculation.info.customCalculation.bottling
      ) {
        html +=
          "<tr><td><b>Bottling</b></td><td>1</td><td>" +
          units +
          "</td><td>" +
          baseUtils.formatEuro(+calculation.info.customCalculation.bottling.price) +
          "</td><td>" +
          baseUtils.formatEuro(units * +calculation.info.customCalculation.bottling.price) +
          "</td>";
      }

      if (
        containsBlister(calculation.packagings, packagings) &&
        (calculationSettings.type === "capsule" || calculationSettings.type === "softgel") &&
        calculation.info.customCalculation.blistering
      ) {
        const blister = findBlister(calculation.packagings, packagings);
        html += "<tr><td><b>Blistering</b></td><td>" + blister.amount + "</td><td>" + +blister.amount * units;
        html +=
          "</td><td>" +
          baseUtils.formatEuro(calculation.info.customCalculation.blistering.price) +
          "</td><td>" +
          baseUtils.formatEuro(calculation.info.customCalculation.blistering.price * blister.amount * +units) +
          "</td></tr>";
      }
      if (calculation.info.customCalculation.optionalCosts) {
        html += "<tr><td><b>Optional Costs</b></td><td></td><td>";
        html +=
          "</td><td></td><td>" + baseUtils.formatEuro(calculation.info.customCalculation.optionalCosts) + "</td></tr>";
      }
    } else {
      html +=
        '<h5 style="margin-bottom:5px">General</h5><table cellpadding="5" cellspacing="0" style="font-size:12px;"><tr style="background-color:#cccccc"><td style="width:200px"><b>Description</b></td><td style="width:200px"><b>Amount (per Unit)</b></td><td style="width:200px"><b>Amount (total)</b></td><td style="width:200px"><b>Price (per Unit)</b></td><td style="width:200px"><b>Price (Total)</b></td></tr>';
      html += "<tbody>";
      html += createCapsuleTabletProtocol(
        calculation,
        calculationSettings,
        selectedCapsule,
        capsulePrice,
        manufacturer
      );

      if (
        containsBottle(calculation.packagings, packagings) &&
        (calculationSettings.type === "capsule" || calculationSettings.type === "softgel")
      ) {
        const bottlingCost = manufacturer.capsules!.bottling[0].price!;
        html +=
          "<tr><td><b>Bottling</b></td><td>1</td><td>" +
          units +
          "</td><td>" +
          baseUtils.formatEuro(+bottlingCost) +
          "</td><td>" +
          baseUtils.formatEuro(units * +bottlingCost) +
          "</td>";
      }

      if (containsBottle(calculation.packagings, packagings) && calculationSettings.type === "tablet") {
        const bottlingCost = manufacturer.tablets!.bottling[0].price!;
        html +=
          "<tr><td><b>Bottling</b></td><td>1</td><td>" +
          units +
          "</td><td>" +
          baseUtils.formatEuro(+bottlingCost) +
          "</td><td>" +
          baseUtils.formatEuro(units * +bottlingCost) +
          "</td>";
      }

      if (calculationSettings.type === "powder" || calculationSettings.type === "liquid") {
        let weight = 0;
        for (let y = 0; y < calculation.prices.length; y++) {
          weight += +calculation.prices[y].amount;
        }
        const kiloWeight = (weight * +units) / (1000 * 1000);
        const blendingPrice = +calculationHelper.getPriceFromManufacturer(
          manufacturer,
          "powder",
          "blending",
          "amount",
          kiloWeight.toString()
        );

        html +=
          "<tr><td><b>Mixing/Blending</b></td><td>1</td><td>" +
          calculationUtils.formatAmount(weight, 2) +
          "</td><td>" +
          baseUtils.formatEuro((blendingPrice * weight) / (1000 * 1000)) +
          "</td><td>" +
          baseUtils.formatEuro(blendingPrice * kiloWeight) +
          "</td></tr>";
        if (containsBottle(calculations[i].packagings, packagings)) {
          const bottlingPrice = +calculationHelper.getPriceFromManufacturer(
            manufacturer,
            "powder",
            "bottling",
            "amount",
            kiloWeight.toString()
          );
          html += "<tr><td><b>Bottling</b></td><td>1</td><td>" + units + "</td><td>";
          html += baseUtils.formatEuro((bottlingPrice * weight) / (1000 * 1000)) + "</td><td>";
          html += baseUtils.formatEuro(bottlingPrice * kiloWeight) + "</td></tr>";
        }
      }

      if (containsLiquidBottle(calculations[i].packagings, packagings) && calculationSettings.type === "liquid") {
        const bottlingCost = manufacturer.liquids?.bottling[0].price!;
        html +=
          "<tr><td><b>Bottling</b></td><td>1</td><td>" +
          units +
          "</td><td>" +
          baseUtils.formatEuro(+bottlingCost) +
          "</td><td>" +
          baseUtils.formatEuro(units * +bottlingCost) +
          "</td>";
      }

      if (
        containsBlister(calculation.packagings, packagings) &&
        (calculationSettings.type === "capsule" || calculationSettings.type === "softgel")
      ) {
        const blister = findBlister(calculation.packagings, packagings);
        const blisterAmount = blister.amount * units;
        html += "<tr><td><b>Blistering</b></td><td>" + blister.amount + "</td><td>" + +blister.amount * units;
        const blisteringPrice = +calculationHelper.getPriceFromManufacturer(
          manufacturer,
          "capsules",
          "blistering",
          "amount",
          blisterAmount.toString()
        );
        html +=
          "</td><td>" +
          baseUtils.formatEuro(blisteringPrice) +
          "</td><td>" +
          baseUtils.formatEuro(blisteringPrice * blister.amount * +units) +
          "</td></tr>";
      }
    }
    html += "</tbody></table>";
    if (calculation.info.customCalculation?.note) {
      html +=
        '<table cellpadding="5" cellspacing="0" style="font-size:12px"><tr style="background-color:#cccccc"><td style="width:1000px"><b>Note</b></td></tr>';
      html += "<tbody>";
      html += "<tr><td>" + calculation.info.customCalculation.note + "</td><td>";
      html += "</tbody></table>";
    }
    html +=
      '<h5 style="margin-bottom:5px; margin-top: 5px">Margin</h5><table cellpadding="5" cellspacing="0" style="font-size:12px; background-color:#efefef"><tr style="background-color:#cccccc"><td style="width:200px"><b>Costs per Unit</b></td><td style="width:200px"><b>Total Costs</b></td><td style="width:200px"><b>Margin Percentage</b></td><td style="width:200px"><b>Margin per Unit</b></td><td style="width:200px"><b>Price per Unit</b></td><td style="width:200px"><b>Total Margin</b></td><td style="width:200px"><b>Total Price</b></td><td style="width:200px"><b>Rating</b></td></tr>';
    html += "<tbody>";
    html +=
      "<tr><td>" +
      baseUtils.formatEuro(calculation.info.unitpricenaked) +
      "</td><td>" +
      baseUtils.formatEuro(calculation.info.unitpricenaked * units) +
      "</td><td>" +
      calculation.info.percentmargin.toFixed(2) +
      "%" +
      "</td><td>" +
      baseUtils.formatEuro(calculation.info.unitmargin) +
      "</td><td>" +
      baseUtils.formatEuro(calculation.info.unitprice) +
      "</td><td>" +
      baseUtils.formatEuro(calculation.info.totalmargin) +
      "</td><td>" +
      baseUtils.formatEuro(calculation.info.totalprice) +
      "</td><td>";
    html += getMarginDescription(calculation.info.percentmargin);
    html += "</td></tr>";
    html += "</tbody></table>";
  }
  html += createAllPricesForProtocol(calculations, context);

  return html;
}

/**
 * Create recipe protocol info
 * @param calculation calculation object
 * @param calculationsettings calculation settings
 * @param context context with all collections
 */
function createRecipeProtocol(calculation: calculation, calculationsettings: Setting, context: DataContextType) {
  let html = "";
  const { commodities, suppliers } = context;
  const units = +calculation.units;
  const perUnit = +calculationsettings.perUnit;
  for (let j = 0; j < calculation.prices.length; j++) {
    const price = calculation.prices[j];
    const id = price._id;
    const amount = price.amount;
    const commodity = commodities.find(comm => comm._id.toString() === id.toString())!;
    const supplier: any =
      typeof price.supplier === "string"
        ? price.supplier
        : suppliers.find(supp => supp._id.toString() === price.supplier.toString());
    const supplierName = typeof supplier === "string" ? supplier : supplier?.name || "Unknown Supplier";

    html +=
      "<tr><td><b>" +
      commodity.title.en +
      "</b><br>" +
      commodity.subtitle.en +
      "<br/>" +
      calculationUtils.formatAmount(amount, 2) +
      "</td><td>" +
      "<b>";
    if (calculationsettings.type === "capsule" || calculationsettings.type === "tablet") {
      html += calculationUtils.formatAmount(amount * perUnit, 2);
    } else if (calculationsettings.type === "powder" || calculationsettings.type === "liquid") {
      html += calculationUtils.formatAmount(amount, 2);
    }
    html += "</b><br>";
    if (calculationsettings.type === "capsule" || calculationsettings.type === "tablet") {
      html += calculationUtils.formatAmount(amount, 2) + " x " + perUnit + " pcs";
    } else if (calculationsettings.type === "powder" || calculationsettings.type === "liquid") {
      html += calculationUtils.formatAmount(amount, 2) + " per item";
    } else if (
      calculationsettings.type === "custom" ||
      calculationsettings.type === "softgel" ||
      calculationsettings.type === "service"
    ) {
      html += perUnit + " pcs";
    }
    html += "<br></td><td><b>";

    if (calculationsettings.type === "capsule" || calculationsettings.type === "tablet") {
      html += calculationUtils.formatAmount(amount * perUnit * units * (1 + price.buffer / 100), 2);
    } else if (calculationsettings.type === "custom" || calculationsettings.type === "softgel") {
      let val = (amount * perUnit * units * (1 + price.buffer / 100)) / 1000;
      html += val.toString() + "tsd.";
    } else if (calculationsettings.type === "service") {
      html += (amount * perUnit * units).toString();
    } else if (calculationsettings.type === "powder" || calculationsettings.type === "liquid") {
      html += calculationUtils.formatAmount(amount * units * (1 + price.buffer / 100), 2);
    }

    html += "</b><br>";
    if (calculationsettings.type === "capsule" || calculationsettings.type === "tablet") {
      html += calculationUtils.formatAmount(amount * perUnit, 2);
    } else if (calculationsettings.type === "powder" || calculationsettings.type === "liquid") {
      html += calculationUtils.formatAmount(amount, 2);
    } else if (
      calculationsettings.type === "custom" ||
      calculationsettings.type === "softgel" ||
      calculationsettings.type === "service"
    ) {
      html += amount * perUnit;
    }
    html += " x " + units + " units + " + price.buffer + "% (";

    if (calculationsettings.type === "capsule" || calculationsettings.type === "tablet") {
      html += calculationUtils.formatAmount((price.buffer / 100) * amount * perUnit, 2);
    } else if (calculationsettings.type === "powder" || calculationsettings.type === "liquid") {
      html += calculationUtils.formatAmount((price.buffer / 100) * amount, 2);
    } else if (calculationsettings.type === "custom" || calculationsettings.type === "softgel") {
      html += (price.buffer / 100) * amount * perUnit;
    } else if (calculationsettings.type === "service") {
      html += 0;
    }
    html +=
      ")" +
      "</td><td><b>" +
      supplierName +
      "</b><br>" +
      "autoselect => <b>" +
      price.auto +
      "</b><br>Delivery in " +
      price.deliverytime +
      " Days</td><td><b>";

    if (calculationsettings.type === "capsule" || calculationsettings.type === "tablet") {
      html += baseUtils.formatEuro((price.price * amount * perUnit) / (1000 * 1000));
    } else if (calculationsettings.type === "powder" || calculationsettings.type === "liquid") {
      html += baseUtils.formatEuro((price.price * amount) / (1000 * 1000));
    } else if (calculationsettings.type === "custom" || calculationsettings.type === "softgel") {
      html += baseUtils.formatEuro((price.price * amount * perUnit) / 1000);
    } else if (calculationsettings.type === "service") {
      html += baseUtils.formatEuro((price.price * amount) / (1000 * 1000));
    }

    if (calculationsettings.type === "custom" || calculationsettings.type === "softgel") {
      html += "/item</b><br>" + baseUtils.formatEuro(price.price) + "/tsd @ ";
    } else if (calculationsettings.type === "service") {
      html += "/item</b><br>" + baseUtils.formatEuro(price.price) + "/unit";
    } else {
      html += "/unit</b><br>" + baseUtils.formatEuro(price.price) + "/kg @ ";
    }

    if (calculationsettings.type === "capsule" || calculationsettings.type === "tablet") {
      html += calculationUtils.formatAmount(amount * perUnit, 2);
    } else if (calculationsettings.type === "powder" || calculationsettings.type === "liquid") {
      html += calculationUtils.formatAmount(amount, 2);
    } else if (
      calculationsettings.type === "custom" ||
      calculationsettings.type === "softgel" ||
      calculationsettings.type === "service"
    ) {
      html += amount * perUnit + "items";
    }

    html +=
      "/unit" +
      "</td><td><b><u>" +
      price.totalprice?.toLocaleString("de-DE", {
        style: "currency",
        currency: "EUR"
      }) +
      "</u></b>" +
      "</td></tr>";
  }
  return html;
}

/**
 * Create packaging protocol data
 * @param calculation calculation object
 * @param calculationsettings setting object
 * @param context data context with all collections
 * @returns html string with packaging data
 */
function createPackagingProtocol(calculation: calculation, calculationsettings: Setting, context: DataContextType) {
  let html = "";
  const { packagings, suppliers } = context;
  const units = +calculation.units;
  for (let j = 0; j < calculation.packagings.length; j++) {
    const pack = calculation.packagings[j];
    const id = pack._id;
    const amount = pack.amount;
    const packaging = packagings.find(pack => pack._id.toString() === id.toString())!;
    const supplier: any =
      typeof pack.supplier === "string"
        ? pack.supplier
        : suppliers.find(supp => supp._id.toString() === pack.supplier.toString());
    const supplierName = typeof supplier === "string" ? supplier : supplier?.name || "Unknown Supplier";
    html += "<tr><td>";
    html += getPackagingDescription(packaging);
    html +=
      "</td><td><b>" +
      amount +
      "pcs</b></td><td><b>" +
      amount * units +
      "</b></td><td><b>" +
      supplierName +
      "</b><br>" +
      "autoselect => <b>" +
      pack.auto.toString() +
      "</b><br>Delivery in " +
      pack.deliverytime +
      " Days</td><td><b>" +
      baseUtils.formatEuro(pack.price) +
      "/unit</b></td><td><b><u>" +
      baseUtils.formatEuro(pack.price * units * amount) +
      "</u></b></td></tr>";
  }
  return html;
}

/**
 * Create protocol info for capsule and tablet
 * @param calculation calculation object
 * @param calculationsettings setting object
 * @param selectedCapsule selected capsule document
 * @param capsuleCalculation the calculation of the capsule
 * @param manufacturer manufacturers document
 * @returns html string with capsule and tablet data
 */
function createCapsuleTabletProtocol(
  calculation: calculation,
  calculationsettings: Setting,
  selectedCapsule: CapsulesDocument | null | undefined,
  capsuleCalculation: any,
  manufacturer: ManufacturersDocument
) {
  let html = "";
  const units = +calculation.units;
  const perUnit = +calculationsettings.perUnit;
  if (calculation.info.customCalculation) {
    if (
      calculationsettings.type === "capsule" &&
      selectedCapsule &&
      capsuleCalculation &&
      calculation.info.customCalculation.encapsulation
    ) {
      const price = calculation.info.customCalculation.encapsulation.price;
      html +=
        "<tr><td><b>Encapsulation</b></td><td>" +
        calculationsettings.perUnit +
        "</td><td>" +
        ((perUnit * units) / 1000).toFixed(0) +
        "tsd.</td><td>";

      html +=
        baseUtils.formatEuro(+price) +
        "/tsd.</td><td>" +
        baseUtils.formatEuro((+price / 1000) * perUnit * units) +
        "</td>";
    } else if (calculationsettings.type === "tablet" && calculation.info.customCalculation.tableting) {
      const tabletingPrice = calculation.info.customCalculation.tableting.price;
      html +=
        "<tr><td><b>Tableting</b></td><td>" +
        perUnit +
        "</td><td>" +
        ((perUnit * units) / 1000).toFixed(0) +
        "tsd.</td><td>";
      html += baseUtils.formatEuro(+tabletingPrice);

      html += "/tsd.</td><td>";

      html += baseUtils.formatEuro((+tabletingPrice / 1000) * perUnit * units) + "</td>";
    }
  } else {
    if (calculationsettings.type === "capsule" && selectedCapsule && capsuleCalculation) {
      const price = calculationHelper.getPriceFromManufacturer(
        manufacturer,
        "capsules",
        "encapsulation",
        "size",
        selectedCapsule.capsule_size
      );
      html +=
        "<tr><td><b>Encapsulation</b></td><td>" +
        calculationsettings.perUnit +
        "</td><td>" +
        ((perUnit * units) / 1000).toFixed(0) +
        "tsd.</td><td>";

      html +=
        baseUtils.formatEuro(+price) +
        "/tsd.</td><td>" +
        baseUtils.formatEuro((+price / 1000) * perUnit * units) +
        "</td>";
    } else if (calculationsettings.type === "tablet") {
      const tabletingPrice = calculationHelper.getPriceFromManufacturer(
        manufacturer,
        "tablets",
        "tableting",
        "amount",
        ((perUnit * units) / 1000).toString()
      );
      html +=
        "<tr><td><b>Tableting</b></td><td>" +
        perUnit +
        "</td><td>" +
        ((perUnit * units) / 1000).toFixed(0) +
        "tsd.</td><td>";
      html += baseUtils.formatEuro(+tabletingPrice);

      html += "/tsd.</td><td>";

      html += baseUtils.formatEuro((+tabletingPrice / 1000) * perUnit * units) + "</td>";
    }
  }

  return html;
}

/**
 * Create info about all prices
 * @param calculations list of calculations
 * @param context data context with all collections
 * @returns html string with information about all prices
 */
function createAllPricesForProtocol(calculations: Array<calculation>, context: DataContextType) {
  const { commodities, packagings, suppliers } = context;
  let html = "";
  html +=
    '<hr><h5 style="margin-bottom:5px">Used Commodities - All Prices</h5><table cellpadding="5" cellspacing="0" style="font-size:12px;"><tr style="background-color:#cccccc"><td style="width:200px"><b>Description</b></td><td style="width:200px"><b>Supplier</b></td><td style="width:200px"><b>MOQ</b></td><td style="width:200px"><b>Price/kg</b></td><td style="width:200px"><b>Deliverytime</b></td><td style="width:200px"><b>Delivery</b></td><td style="width:200px"><b>Selected</b></td></tr>';
  html += "<tbody>";
  const recipe = calculations[0].prices;
  for (let i = 0; i < recipe.length; i++) {
    const comm = recipe[i];
    const fullCommodity = commodities.find(com => com._id.toString() === comm._id.toString());
    if (!fullCommodity) continue;
    for (let j = 0; j < fullCommodity.suppliers.length; j++) {
      const supp = fullCommodity.suppliers[j];
      const fullSupplier = suppliers.find(sup => sup._id.toString() === supp._id.toString());
      if (!fullSupplier) continue;
      for (let k = 0; k < supp.prices.length; k++) {
        const currPrice = supp.prices[k];
        html +=
          "<tr><td>" +
          fullCommodity.title.en +
          "</td><td>" +
          fullSupplier.name +
          "</td><td>" +
          supp.prices[k].moq +
          "</td><td>" +
          baseUtils.formatEuro(supp.prices[k].price) +
          "</td><td>" +
          supp.prices[k].deliverytime +
          " Days" +
          "</td><td>" +
          supp.prices[k].delivery +
          "</td><td>" +
          (comm.supplier.toString() === fullSupplier._id.toString() &&
          +comm.price === +currPrice.price &&
          +comm.deliverytime === +currPrice.deliverytime &&
          comm.delivery === currPrice.delivery &&
          comm.incoterm === currPrice.incoterm
            ? "true"
            : "") +
          "</td></tr>";
      }
    }
  }
  const selectedPackaging = calculations[0].packagings;
  for (let i = 0; i < selectedPackaging.length; i++) {
    const pack = selectedPackaging[i];
    const fullPackaging = packagings.find(pac => pac._id.toString() === pack._id.toString());
    if (!fullPackaging) continue;
    for (let j = 0; j < fullPackaging.suppliers.length; j++) {
      const supp = fullPackaging.suppliers[j];
      const fullSupplier = suppliers.find(sup => sup._id.toString() === supp._id.toString());
      if (!fullSupplier) continue;
      for (let k = 0; k < supp.prices.length; k++) {
        const currPrice = supp.prices[k];
        html += "<tr><td>";
        html += getPackagingDescription(fullPackaging);

        html +=
          "</td><td>" +
          fullSupplier.name +
          "</td><td>" +
          currPrice.moq +
          "</td><td>" +
          baseUtils.formatEuro(currPrice.price) +
          "</td><td>" +
          currPrice.deliverytime +
          " Days" +
          "</td><td>" +
          currPrice.delivery +
          "</td><td>" +
          (pack.supplier.toString() === fullSupplier._id.toString() &&
          +pack.price === +currPrice.price &&
          +pack.deliverytime === +currPrice.deliverytime &&
          pack.delivery === currPrice.delivery
            ? "true"
            : "") +
          "</td></tr>";
      }
    }
  }

  html += "</tbody>";
  html += "</table>";
  return html;
}

/**
 * Get packaging description
 * @param packaging a packaging document
 * @returns {string} html string
 */
function getPackagingDescription(packaging: PackagingsDocument): string {
  let html = "";
  switch (packaging.packaging_type) {
    case PackagingTypes.BOTTLE:
    case PackagingTypes.LIQUIDBOTTLE:
      html += getBottleDescription(packaging, packaging.packaging_type === PackagingTypes.LIQUIDBOTTLE);
      break;
    case PackagingTypes.BLISTER:
      html += getBlisterDescription(packaging);
      break;
    case PackagingTypes.BAG:
      html += getBagDescription(packaging);
      break;
    case PackagingTypes.SLEEVE:
      html += getSleeveDescription(packaging);
      break;
    case PackagingTypes.LID:
      html += getLidDescription(packaging);
      break;
    case PackagingTypes.LABEL:
    case PackagingTypes.MULTILAYER_LABEL:
      html += getLabelDescription(packaging, packaging.packaging_type === PackagingTypes.MULTILAYER_LABEL);
      break;
    case PackagingTypes.PIPETTE:
      html += getPipetteDescription(packaging);
      break;
    case PackagingTypes.SPRAYPUMP:
      html += getSprayPumpDescription(packaging);
      break;
    case PackagingTypes.BOX:
      html += getBoxDescription(packaging);
      break;
    case PackagingTypes.STICKER:
      html += getStickerDescription(packaging);
      break;
    case PackagingTypes.SPOON:
      html += getSpoonDescription(packaging);
      break;
    case PackagingTypes.SILICAGELBAG:
      html += getSilicaGelBagDescription(packaging);
      break;
    case PackagingTypes.PACKAGEINSERT:
      html += getPackageInsertDescription(packaging);
      break;
  }
  if (packaging.article_number) {
    html += ", Art. Nr.: " + packaging.article_number;
  }
  return html;
}

/**
 * Get bottle description
 * @param packaging a packaging document
 * @param liquid flag if it is a liquid bottle
 * @returns {string} html string
 */
function getBottleDescription(packaging: PackagingsDocument, liquid?: boolean): string {
  return (
    "<b>" +
    (liquid ? "Liquidbottle" : "Bottle") +
    " </b><br>" +
    packaging.packaging_volume +
    "ml, " +
    getTranslation(packaging.packaging_color) +
    ", " +
    getTranslation(packaging.packaging_material) +
    ", " +
    packaging.packaging_neck
  );
}

/**
 * Get lid description
 * @param packaging a packaging document
 * @returns {string} html string
 */
function getLidDescription(packaging: PackagingsDocument): string {
  return (
    "<b>Lid</b><br/>" +
    packaging.lid_size +
    ", " +
    getTranslation(packaging.lid_material) +
    ", " +
    getTranslation(packaging.lid_color) +
    ", " +
    getTranslation(packaging.lid_shape)
  );
}

/**
 * Get label description
 * @param packaging a packaging document
 * @param multilayer flag if it is a multilayer label
 * @returns {string} html string
 */
function getLabelDescription(packaging: PackagingsDocument, multilayer?: boolean): string {
  return (
    "<b>" +
    (multilayer ? "Multilayer Label" : "Label") +
    "</b><br>" +
    packaging.label_height +
    "mm" +
    ", " +
    packaging.label_width +
    "mm, " +
    getTranslation(packaging.label_quality)
  );
}

/**
 * Get sleeve description
 * @param packaging a packaging document
 * @returns {string} html string
 */
function getSleeveDescription(packaging: PackagingsDocument): string {
  return (
    "<b>Sleeve</b><br>for " +
    packaging.sleeve_size +
    "mm" +
    ", " +
    (packaging.sleeve_print ? "with print" : "without print")
  );
}

/**
 * Get box description
 * @param packaging a packaging document
 * @returns {string} html string
 */
function getBoxDescription(packaging: PackagingsDocument): string {
  return (
    "<b>Box</b><br>" +
    packaging.box_height +
    "mm, " +
    packaging.box_width +
    "mm, " +
    packaging.box_depth +
    "mm, " +
    getTranslation(packaging.box_quality)
  );
}

/**
 * Get bag description
 * @param packaging a packaging document
 * @returns {string} html string
 */
function getBagDescription(packaging: PackagingsDocument): string {
  return (
    "<b>Bag</b><br>" +
    packaging.bag_volume +
    "ml" +
    ", " +
    packaging.bag_height +
    "mm, " +
    packaging.bag_width +
    "mm, " +
    getTranslation(packaging.bag_color) +
    ", " +
    getTranslation(packaging.bag_material) +
    ", " +
    getTranslation(packaging.bag_shape)
  );
}

/**
 * Get blister description
 * @param packaging a packaging document
 * @returns {string} html string
 */
function getBlisterDescription(packaging: PackagingsDocument): string {
  return (
    "<b>Blister</b><br>" +
    packaging.blister_capsules +
    " capsules, " +
    packaging.blister_height +
    "mm, " +
    packaging.blister_width +
    "mm, " +
    packaging.blister_depth +
    "mm"
  );
}

/**
 * Get pipette description
 * @param packaging a packaging document
 * @returns {string} html string
 */
function getPipetteDescription(packaging: PackagingsDocument): string {
  return (
    "<b>Pipette</b><br>" +
    packaging.packaging_neck +
    ", " +
    packaging.packaging_height +
    "mm, " +
    getTranslation(packaging.packaging_color)
  );
}

/**
 * Get sprayPump description
 * @param packaging a packaging document
 * @returns {string} html string
 */
function getSprayPumpDescription(packaging: PackagingsDocument): string {
  return (
    "<b>Spray Pump</b><br>" +
    "DIN " +
    packaging.packaging_norm +
    ", " +
    packaging.packaging_height +
    "mm, " +
    packaging.packaging_neck +
    ", " +
    getTranslation(packaging.packaging_color)
  );
}

/**
 * Get sticker description
 * @param packaging a packaging document
 * @returns {string} html string
 */
function getStickerDescription(packaging: PackagingsDocument): string {
  return (
    "<b>Sticker</b><br>" +
    packaging.form +
    ", " +
    (packaging.form === StickerForms.ROUND
      ? `⌀ ${packaging.diameter} mm`
      : packaging.packaging_height + "mm" + ", " + packaging.packaging_width + "mm, ") +
    getTranslation(packaging.quality)
  );
}

/**
 * Get spoon description
 * @param packaging a packaging document
 * @returns {string} html string
 */
function getSpoonDescription(packaging: PackagingsDocument): string {
  return "<b>Spoon</b><br>" + packaging.capacity + "g, " + getTranslation(packaging.packaging_color);
}

/**
 * Get silica gel bag description
 * @param packaging a packaging document
 * @returns {string} html string
 */
function getSilicaGelBagDescription(packaging: PackagingsDocument): string {
  return "<b>Silica Gel Bag</b><br>" + packaging.weight + "g";
}

/**
 * Get package insert description
 * @param packaging a packaging document
 * @returns {string} html string
 */
function getPackageInsertDescription(packaging: PackagingsDocument): string {
  return "<b>Package Insert</b><br>";
}

/**
 * Get translation for packaging properties
 * @param key the value to get a translation for
 * @returns {string} translation
 */
function getTranslation(key: string | undefined | null): string {
  return pdfUtils.getTranslation(key, "en");
}
export default { createCalculationProtocol };
