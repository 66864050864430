import React, { useState } from "react";
import { Modal } from "react-bootstrap";

interface SimpleConfirmationModalButtonProps {
  buttonClasses?: any;
  buttonText: string | JSX.Element;
  modalTitle?: string;
  modalDescription: JSX.Element | string;
  confirmButtonText: string;
  cancelButtonText: string;
  onConfirm: any;
  buttonId?: string;
  disabled?: boolean;
  size?: "lg" | "sm" | "xl" | "md";
  swapButtons?: boolean;
  hideConfirm?: boolean;
  saving?: boolean;
  iconClass?: string;
}

const SimpleConfirmationModalButton: React.FunctionComponent<SimpleConfirmationModalButtonProps> = props => {
  const { buttonClasses, buttonText, buttonId, disabled, saving, iconClass } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e: any) => {
    e.preventDefault();
    setShow(true);
  };
  return (
    <>
      <button
        className={buttonClasses ? buttonClasses : ""}
        id={buttonId ? buttonId : ""}
        onClick={handleShow}
        type="button"
        disabled={disabled}
      >
        {saving && (
          <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
            <svg className="button-splash-spinner" viewBox="0 0 50 50">
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
            </svg>
          </div>
        )}
        {iconClass && <i className={iconClass} />}
        {buttonText}
      </button>
      <SimpleConfirmationModal show={show} onClose={handleClose} {...props} />
    </>
  );
};

interface SimpleConfirmationModalProps {
  show: boolean;
  showModal?: boolean;
  modalTitle?: JSX.Element | string;
  modalDescription: JSX.Element | string;
  confirmButtonText: string;
  cancelButtonText: string;
  checkboxText?: string;
  onClose: any;
  onConfirm: any;
  onShowModal?: any;
  size?: "lg" | "sm" | "xl" | "md";
  swapButtons?: boolean;
  hideConfirm?: boolean;
}

const SimpleConfirmationModal: React.FunctionComponent<SimpleConfirmationModalProps> = props => {
  const {
    show,
    modalTitle,
    modalDescription,
    confirmButtonText,
    cancelButtonText,
    onClose,
    onConfirm,
    showModal,
    onShowModal,
    checkboxText,
    size,
    swapButtons,
    hideConfirm
  } = props;
  const [checkboxSelect, setCheckboxSelect] = useState(showModal);
  const handleConfirm = () => {
    if (onShowModal) onShowModal(checkboxSelect);
    onConfirm();
    onClose();
  };
  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        size={size === "md" ? undefined : size ? size : "sm"}
        name={"SimpleConfirmationModal"}
      >
        {modalTitle && (
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          {modalDescription}
          {/* Checkbox so customer can configure that he doesnt want to see the popup anymore */}
          {onShowModal && (
            <>
              <br />
              <br />
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={!checkboxSelect}
                  onChange={() => setCheckboxSelect(!checkboxSelect)}
                  id="showModalCheckbox"
                />
                <label className="form-check-label pl-1" htmlFor="showModalCheckbox">
                  {checkboxText}
                </label>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="p-2">
          {swapButtons ? (
            <>
              {!hideConfirm && (
                <button key="add" className="btn btn-secondary" onClick={handleConfirm}>
                  {confirmButtonText}
                </button>
              )}
              <button key="cancel" className="btn btn-success" onClick={onClose}>
                {cancelButtonText}
              </button>
            </>
          ) : (
            <>
              <button key="cancel" className="btn btn-secondary" onClick={onClose}>
                {cancelButtonText}
              </button>
              {!hideConfirm && (
                <button key="add" className="btn btn-success" onClick={handleConfirm}>
                  {confirmButtonText}
                </button>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

// eslint-disable-next-line
export default { SimpleConfirmationModal, SimpleConfirmationModalButton };
