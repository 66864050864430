import React from "react";

interface BioNumberSelectionProps {
  bioNumber: string;
  wrapperClasses?: string;
  labelClasses?: string;
  labelColumnClasses?: string;
  label?: string;
  selectClasses?: string;
  selectColumnClasses?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const B_NR_GE = "DE-ÖKO-006";
export const B_NR_CZ = "CZ-BIO-003";
export const B_NR_PS = "DE-ÖKO-007";

const BioNumberSelection: React.FunctionComponent<BioNumberSelectionProps> = ({
  bioNumber,
  wrapperClasses,
  label,
  labelClasses,
  labelColumnClasses,
  selectClasses,
  selectColumnClasses,
  onChange
}) => {
  const select = (
    <select
      className={selectClasses ? selectClasses : "form-control"}
      value={bioNumber}
      name={"bioNumber"}
      onChange={onChange}
    >
      <option value={""}>-</option>
      <option value={B_NR_GE}>{B_NR_GE}</option>
      <option value={B_NR_CZ}>{B_NR_CZ}</option>
      <option value={B_NR_PS}>{B_NR_PS}</option>
    </select>
  );
  return (
    <div className={wrapperClasses}>
      <div className={labelColumnClasses}>
        <label className={labelClasses}>{label ? label : "Bio Number"}</label>
      </div>
      {selectColumnClasses ? <div className={selectColumnClasses}>{select}</div> : select}
    </div>
  );
};

export default BioNumberSelection;
