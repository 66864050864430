import _ from "lodash";
import React, { PureComponent } from "react";
import Chart from "react-apexcharts";
import { DataContext } from "../../../context/dataContext";
import { Period } from "../../common/CustomTypes";
import orderUtils, { DECLINED } from "../../../utils/orderUtils";
import { ROLES } from "../../../utils/userdataUtils";
import { T_FULFILLMENT } from "../../../utils/timelineUtils";
import contractUtils from "../../../utils/contractUtils";

interface SalesMarginMissGraphProps {
  context: React.ContextType<typeof DataContext>;
  period: Period;
}

interface SalesMarginMissGraphState {
  series: Array<{ name: string; data: Array<number> | Array<{ x: string; y: number; fillColor: string }> }>;
  options: any;
}

class SalesMarginMissGraph extends PureComponent<SalesMarginMissGraphProps, SalesMarginMissGraphState> {
  constructor(props: SalesMarginMissGraphProps) {
    super(props);
    this.state = {
      series: [],
      options: {
        chart: {
          type: "scatter",
          id: "basic-bar-marginMiss",
          toolbar: {
            show: true
          },
          zoom: { enabled: false }
        },
        xaxis: {
          type: "datetime"
        },
        yaxis: {
          min: -2,
          max: 2,
          labels: {
            formatter: (v: string) => (+v * 100).toFixed(2) + "%"
          }
        },
        dataLabels: { enabled: false },
        colors: ["transparent"]
      }
    };
  }

  componentDidMount() {
    this.setState({ series: this.calculateData() });
  }

  componentDidUpdate(
    prevProps: Readonly<SalesMarginMissGraphProps>,
    prevState: Readonly<SalesMarginMissGraphState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.context.orders, this.props.context.orders)) {
      this.setState({ series: this.calculateData() });
    }
  }

  calculateData = () => {
    const { context, period } = this.props;
    const sales = context.userdata.filter(
      u =>
        (u.role.includes(ROLES.SALES) || u.role.includes(ROLES.ADMIN)) &&
        ["Christoph Stachelek", "Jana Papke", "Tayfun Bati", "Pierre Sönnichsen", "Fabian Geisler"].includes(
          u.prename + " " + u.surname
        )
    );
    const orders = context.orders.filter(
      o =>
        orderUtils.isOrder(o) &&
        !contractUtils.isContract(o) &&
        o.state !== DECLINED &&
        o.createdOn.getTime() > period.beginning.getTime() &&
        o.createdOn.getTime() < period.end.getTime()
    );
    const data = [];
    const good = "#3dff36";
    const bad = "#ff0000";
    for (let i = 0; i < sales.length; i++) {
      const s = sales[i];
      const sOrders = orders.filter(o => o.createdFrom.toString() === s._id.toString());
      let sData: Array<{ x: string; y: number; fillColor: string }> = [];
      for (let j = 0; j < sOrders.length; j++) {
        const sO = sOrders[j];
        const fulfillment = sO.timeline.find(t => t.type === T_FULFILLMENT);
        if (!fulfillment || !sO.fulfillment?.priceInfo) continue;
        const marginPre = sO.calculations[0].info.totalmargin;
        const marginPost = sO.fulfillment.priceInfo.totalMargin;
        const val = marginPre === 0 ? 0 : (marginPost ?? marginPre) / marginPre - 1;
        sData.push({
          x: fulfillment.date.toISOString(),
          y: val,
          fillColor: val >= 0 ? good : bad
        });
      }
      sData = sData.sort((d1, d2) => new Date(d1.x).getTime() - new Date(d2.x).getTime());
      sData.push({
        x: period.end.toISOString(),
        y: sData.length > 0 ? sData[sData.length - 1].y : 0,
        fillColor: good
      });
      data.push({ name: s.prename + " " + s.surname, data: sData });
    }
    return data;
  };

  render() {
    const { series, options } = this.state;
    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">Margin Miss by Sales Employee</h3>
          </div>
        </div>
        <div className="kt-portlet__body p-3">
          <Chart series={series} type="scatter" options={options} heigth="700" width="100%" />
        </div>
      </div>
    );
  }
}
export default SalesMarginMissGraph;
