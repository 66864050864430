import _ from "lodash";
import React, { Component } from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { BSON } from "realm-web";
import { Dropdown } from "semantic-ui-react";
import SupplierPricesSelection from "../common/supplier/SupplierPricesSelection";
import { SupplierSelected } from "../common/supplier/CustomTypes";
import { DataContext } from "../../context/dataContext";
import { CapsulesDocument, CapsuleSupplier } from "../../model/capsules.types";
import { AdditiveDocument } from "../../model/additives.types";
import dbService, { CAPSULES } from "../../services/dbService";
import accessUtils, { EDITLOCATIONS } from "../../utils/accessUtils";
import capsuleUtils from "../../utils/capsuleUtils";
import toastUtils from "../../utils/toastUtils";
import SelectAdditives from "./SelectAdditives";
import baseUtils from "../../utils/baseUtils";

interface CapsuleParams {
  id: string;
}

interface CapsuleProps extends RouteComponentProps<CapsuleParams, {}, {}> {
  currencies: Array<any>;
}

interface CapsuleState {
  selectedAdditives: Array<AdditiveDocument | "">;
  capsule?: CapsulesDocument;
  selectedProperties: Array<BSON.ObjectId>;
  submit: boolean;
  submitted: boolean;
  bestPriceAmount: number;
  activeTab: "Suppliers" | "Settings";
}

class Capsule extends Component<CapsuleProps, CapsuleState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  _id: string;

  constructor(props: CapsuleProps, context: React.ContextType<typeof DataContext>) {
    super(props);
    this._id = props.match.params.id;
    const capsule = _.cloneDeep(context.capsules.find(c => c._id.toString() === this._id));
    const selectedAdditives =
      capsule && capsule.additives
        ? context.additives.filter(a => capsule.additives?.some(s => s.toString() === a._id.toString()))
        : ([] as Array<AdditiveDocument>);
    this.state = {
      selectedAdditives,
      capsule,
      selectedProperties: capsule ? capsule.properties : [],
      submit: false,
      submitted: false,
      bestPriceAmount: 500,
      activeTab: "Suppliers"
    };
  }

  /**
   * Convert the commodity properties into the format needed for the dropdown
   * @returns Properties in triple format
   */
  convertProperties = () => {
    const properties = this.context.commodityproperties;
    const convertedProperties: Array<{ key: string; text: string; value: string }> = [];
    if (properties.length > 0) {
      properties.map(property =>
        convertedProperties.push({
          key: property._id.toString(),
          text: property.name.en,
          value: property._id.toString()
        })
      );
    }
    return convertedProperties;
  };

  /**
   * Writes the changes that were made to the capsule to the database.
   */
  submitChanges = async () => {
    const { capsule, selectedProperties, selectedAdditives } = this.state;
    const { updateDocumentInContext } = this.context;
    const additives = (selectedAdditives.filter(s => s !== "") as Array<AdditiveDocument>).map(s => s && s._id);
    if (capsule) {
      capsule.properties = selectedProperties;
      capsule.additives = additives;
      capsule.capsule_volume = Number(capsule.capsule_volume);
      capsule.capsule_weight = Number(capsule.capsule_weight);
      capsule.capsule_bottlingcosts = Number(capsule.capsule_bottlingcosts);
      const res = await dbService.replaceDocument(CAPSULES, capsule._id, capsule);
      await toastUtils.databaseOperationToast(
        !!res && res.modifiedCount > 0,
        "Capsule updated successfully",
        "Error updating capsule",
        () => updateDocumentInContext(CAPSULES, capsule._id)
      );
    }
  };

  handleAddAdditive = () => {
    const selectedAdditives = _.cloneDeep(this.state.selectedAdditives)!;
    selectedAdditives.push("");
    this.setState({ selectedAdditives });
  };

  handleAdditiveChange = (idx: number, id: BSON.ObjectId) => {
    const selectedAdditives = _.cloneDeep(this.state.selectedAdditives)!;
    const additive = baseUtils.getDocFromCollection(this.context.additives, id);
    selectedAdditives[idx] = additive!;
    this.setState({ selectedAdditives });
  };

  handleDeleteAdditive = (idx: number) => {
    const selectedAdditives = _.cloneDeep(this.state.selectedAdditives)!;
    selectedAdditives.splice(idx, 1);
    this.setState({ selectedAdditives });
  };

  handleSuppliersSelectedChange = (suppliersSelected: Array<SupplierSelected>) => {
    const { capsule } = this.state;
    if (capsule) {
      const suppliers = [];
      for (let supplier of suppliersSelected) {
        const sup: CapsuleSupplier = {
          _id: supplier._id,
          prices: [],
          ...(supplier.type ? { type: supplier.type } : {})
        };
        for (let price of supplier.prices) {
          sup.prices.push({
            _id: price._id.toString(),
            moq: +price.moq,
            price: +price.totalPrice,
            deliverytime: +price.deliveryTime,
            delivery: "",
            note: price.note,
            date: price.age
          });
        }
        suppliers.push(sup);
      }
      capsule.suppliers = suppliers;
      this.setState({ capsule });
    }
  };

  handleChangeStringProperty = (key: string, value: string) => {
    const { capsule } = this.state;
    if (!capsule) return;
    switch (key) {
      case "size":
        capsule.capsule_size = value;
        break;
      case "material_de":
        capsule.capsule_material.de = value;
        break;
      case "material_en":
        capsule.capsule_material.en = value;
        break;
      case "color_de":
        capsule.capsule_color.de = value;
        break;
      case "color_en":
        capsule.capsule_color.en = value;
        break;
    }
    this.setState({ capsule });
  };

  handleChangeNumberProperty = (key: string, value: string) => {
    const { capsule } = this.state;
    const val = Number(value);
    if (!capsule || (!val && val !== 0)) return;
    switch (key) {
      case "volume":
        capsule.capsule_volume = val;
        break;
      case "weight":
        capsule.capsule_weight = val;
        break;
      case "bottlingCost":
        capsule.capsule_bottlingcosts = val;
        break;
    }
    this.setState({ capsule });
  };

  render() {
    const { activeTab, bestPriceAmount, capsule, selectedAdditives } = this.state;
    const { capsules, suppliers, additives, manufacturers } = this.context;
    if (!capsule) {
      return <Redirect to="/capsules" />;
    }
    const bestPrice = capsuleUtils.getBestPrice(capsule, bestPriceAmount);
    const bestPriceSupplier =
      bestPrice.supplier &&
      (bestPrice.type === "manufacturer"
        ? manufacturers.find(m => m._id.toString() === bestPrice.supplier!.toString())
        : suppliers.find(s => s._id.toString() === bestPrice.supplier!.toString()))!.name;
    const fastestDelivery = capsuleUtils.getFastestDelivery(capsule, bestPriceAmount);
    const fastestDeliverySupplier =
      fastestDelivery.supplier &&
      (fastestDelivery.type === "manufacturer"
        ? manufacturers.find(s => s._id.toString() === fastestDelivery.supplier!.toString())
        : suppliers.find(s => s._id.toString() === fastestDelivery.supplier!.toString()))!.name;
    const canEdit = accessUtils.canEditData(EDITLOCATIONS.CAPSULESETTINGS);

    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-widget kt-widget--user-profile-3">
              <div className="kt-widget__top">
                <div className="kt-widget__media">
                  <div className="kt-badge kt-badge--xl kt-badge--primary">
                    {capsule.capsule_size.toString().toUpperCase()}
                  </div>
                </div>
                <div className="kt-widget__content">
                  <div className="kt-widget__head">
                    <div className="kt-widget__user">
                      <div className="kt-widget__username">
                        {capsule.capsule_size}
                        {capsule.capsule_material.en ? " (" + capsule.capsule_material.en + ")" : ""}
                      </div>
                      <span className="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-success">
                        Capsule
                      </span>
                    </div>
                  </div>
                  <div className="kt-widget__info">
                    <div className="kt-widget__desc">{capsule.capsule_color.en ? capsule.capsule_color.en : ""}</div>
                  </div>
                </div>
              </div>
              <div className="kt-widget__bottom">
                <div className="kt-widget__item">
                  <div className="kt-widget__icon">
                    <i className="flaticon-coins" />
                  </div>
                  <div className="kt-widget__details">
                    <span className="kt-widget__title">Best Price</span>
                    <span className="kt-widget__value">
                      {bestPrice.price.toLocaleString("de-DE", {
                        style: "currency",
                        currency: "EUR"
                      })}
                    </span>
                    {bestPriceSupplier}
                  </div>
                </div>
                <div className="kt-widget__item">
                  <div className="kt-widget__icon">
                    <i className="flaticon-truck" />
                  </div>
                  <div className="kt-widget__details">
                    <span className="kt-widget__title">Fastest Delivery</span>
                    <span className="kt-widget__value">{fastestDelivery.days}</span>
                    {fastestDeliverySupplier}
                  </div>
                </div>
                <div className="kt-widget__item">
                  <div className="kt-widget__icon">
                    <i className="flaticon-users" />
                  </div>
                  <div className="kt-widget__details">
                    <span className="kt-widget__title">
                      {capsule.suppliers.length} {capsule.suppliers.length > 1 ? "Suppliers" : "Supplier"}
                    </span>
                    <span
                      onClick={() => this.setState({ activeTab: "Suppliers" })}
                      className="kt-widget__value kt-font-brand pointer"
                    >
                      View
                    </span>
                  </div>
                </div>
                <div className="kt-widget__item">
                  <div className="kt-widget__icon">
                    <i className="flaticon-cogwheel" />
                  </div>
                  <div className="kt-widget__details">
                    <span className="kt-widget__title">Amount for Preview</span>
                    <select
                      className="form-control"
                      onChange={e => this.setState({ bestPriceAmount: parseFloat(e.target.value) })}
                      value={bestPriceAmount}
                    >
                      <option value="1">1 tsd</option>
                      <option value="2">2 tsd</option>
                      <option value="5">5 tsd</option>
                      <option value="10">10 tsd</option>
                      <option value="25">25 tsd</option>
                      <option value="50">50 tsd</option>
                      <option value="100">100 tsd</option>
                      <option value="250">250 tsd</option>
                      <option value="500">500 tsd</option>
                      <option value="1000">1.000 tsd</option>
                      <option value="2500">2.500 tsd</option>
                      <option value="5000">5.000 tsd</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4">
            <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">Capsule</h3>
                </div>
                {canEdit && (
                  <div className="kt-portlet__head-toolbar">
                    <button
                      onClick={() => this.setState({ activeTab: "Settings" })}
                      className="btn btn-label-brand btn-sm btn-bold"
                    >
                      Update Information
                    </button>
                  </div>
                )}
              </div>
              <div className="kt-form kt-form--label-right">
                <div className="kt-portlet__body">
                  <div className="form-group form-group-xs row">
                    <label className="col-4 col-form-label">Capsule Size:</label>
                    <div className="col-8">
                      <span className="form-control-plaintext kt-font-bolder">{capsule.capsule_size}</span>
                    </div>
                  </div>
                  <div className="form-group form-group-xs row">
                    <label className="col-4 col-form-label">Capsule Material:</label>
                    <div className="col-8">
                      <span className="form-control-plaintext kt-font-bolder">
                        {capsule.capsule_material.en ? capsule.capsule_material.en : "undefined"}
                      </span>
                    </div>
                  </div>
                  <div className="form-group form-group-xs row">
                    <label className="col-4 col-form-label">Color:</label>
                    <div className="col-8">
                      <span className="form-control-plaintext kt-font-bolder">
                        {capsule.capsule_color.en ? capsule.capsule_color.en : "undefined"}
                      </span>
                    </div>
                  </div>
                  <div className="form-group form-group-xs row">
                    <label className="col-4 col-form-label">Volume</label>
                    <div className="col-8">
                      <span className="form-control-plaintext kt-font-bolder">{capsule.capsule_volume}</span>
                    </div>
                  </div>
                  <div className="form-group form-group-xs row">
                    <label className="col-4 col-form-label">Weight (mg)</label>
                    <div className="col-8">
                      <span className="form-control-plaintext kt-font-bolder">{capsule.capsule_weight}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="kt-portlet kt-portlet--tabs">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-toolbar">
                  <ul className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand">
                    <li className="nav-item">
                      <a
                        className={"nav-link" + (activeTab === "Suppliers" ? " active" : "")}
                        onClick={() => this.setState({ activeTab: "Suppliers" })}
                      >
                        <i className="flaticon2-shopping-cart" /> Pricing
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={"nav-link" + (activeTab === "Settings" ? " active" : "")}
                        onClick={() => this.setState({ activeTab: "Settings" })}
                      >
                        <i className="flaticon2-gear" /> Settings
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="kt-portlet__body">
                <div className={activeTab === "Suppliers" ? "" : "d-none"}>
                  <SupplierPricesSelection
                    document={capsule}
                    type={"Capsule"}
                    onSuppliersSelectedChange={this.handleSuppliersSelectedChange}
                    disabled={!canEdit}
                    allowManufacturer={true}
                  />
                </div>
                <div className={activeTab === "Settings" ? "" : "d-none"}>
                  <div className="kt-section kt-section--first">
                    <div className="kt-section__body">
                      <div className="form-group row">
                        <div className="col-3 text-right align-self-center">Size</div>
                        <div className="col-lg-9 col-xl-6">
                          <input
                            className="form-control"
                            type="text"
                            onChange={
                              canEdit ? e => this.handleChangeStringProperty("size", e.target.value) : undefined
                            }
                            disabled={!canEdit}
                            value={capsule.capsule_size}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-3 text-right align-self-center">Material (de)</div>
                        <div className="col-lg-9 col-xl-6">
                          <input
                            className="form-control"
                            type="text"
                            onChange={
                              canEdit ? e => this.handleChangeStringProperty("material_de", e.target.value) : undefined
                            }
                            disabled={!canEdit}
                            value={capsule.capsule_material.de}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-3 text-right align-self-center">Material (en)</div>
                        <div className="col-lg-9 col-xl-6">
                          <input
                            className="form-control"
                            type="text"
                            onChange={
                              canEdit ? e => this.handleChangeStringProperty("material_en", e.target.value) : undefined
                            }
                            disabled={!canEdit}
                            value={capsule.capsule_material.en}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-3 text-right align-self-center">Color (de)</div>
                        <div className="col-lg-9 col-xl-6">
                          <input
                            className="form-control"
                            type="text"
                            onChange={
                              canEdit ? e => this.handleChangeStringProperty("color_de", e.target.value) : undefined
                            }
                            disabled={!canEdit}
                            value={capsule.capsule_color.de}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-3 text-right align-self-center">Color (en)</div>
                        <div className="col-lg-9 col-xl-6">
                          <input
                            className="form-control"
                            type="text"
                            onChange={
                              canEdit ? e => this.handleChangeStringProperty("color_en", e.target.value) : undefined
                            }
                            disabled={!canEdit}
                            value={capsule.capsule_color.en}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-3 text-right align-self-top mt-2">Additives</div>
                        <div className="col-lg-10 col-xl-6">
                          <SelectAdditives
                            selectedAdditives={selectedAdditives}
                            additives={additives}
                            onAdd={this.handleAddAdditive}
                            onChange={this.handleAdditiveChange}
                            onDelete={this.handleDeleteAdditive}
                            context={this.context}
                            compact={true}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-3 text-right align-self-center">Volume</div>
                        <div className="col-lg-9 col-xl-6">
                          <div className="input-group">
                            <input
                              className="form-control"
                              type="number"
                              onChange={
                                canEdit ? e => this.handleChangeNumberProperty("volume", e.target.value) : undefined
                              }
                              disabled={!canEdit}
                              value={capsule.capsule_volume.toString()}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-3 text-right align-self-center">Weight (mg)</div>
                        <div className="col-lg-9 col-xl-6">
                          <div className="input-group">
                            <input
                              className="form-control"
                              type="number"
                              onChange={
                                canEdit ? e => this.handleChangeNumberProperty("weight", e.target.value) : undefined
                              }
                              disabled={!canEdit}
                              value={capsule.capsule_weight.toString()}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-3 text-right align-self-center">Bottling Costs</div>
                        <div className="col-lg-9 col-xl-6">
                          <div className="input-group">
                            <input
                              className="form-control"
                              type="number"
                              onChange={
                                canEdit
                                  ? e => this.handleChangeNumberProperty("bottlingCost", e.target.value)
                                  : undefined
                              }
                              disabled={!canEdit}
                              value={capsule.capsule_bottlingcosts.toString()}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-3 text-right align-self-center">Properties</div>
                        <div className="col-lg-9 col-xl-6">
                          <Dropdown
                            placeholder="Properties"
                            fluid
                            multiple
                            selection
                            disabled={!canEdit}
                            options={this.convertProperties().map(cp => {
                              return cp;
                            })}
                            value={this.state.selectedProperties.map(p => p.toString())}
                            onChange={
                              canEdit
                                ? (e, { value }) =>
                                    this.setState({
                                      // @ts-ignore
                                      selectedProperties: value.map(v => BSON.ObjectId(v))
                                    })
                                : undefined
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="kt-portlet__foot">
                <div className="float-right">
                  <button
                    className="btn btn-secondary mr-2"
                    onClick={() =>
                      this.setState({
                        capsule: _.cloneDeep(capsules.find(c => c._id.toString() === capsule?._id.toString()))
                      })
                    }
                  >
                    Reset
                  </button>
                  <button className="btn btn-success" onClick={this.submitChanges}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Capsule);
