import React, { PureComponent } from "react";
import _ from "lodash";
import { BSON } from "realm-web";
import { Modal } from "react-bootstrap";
import { PackagingTypes, ProductTypes } from "../configuratorConstants";
import {
  CalculationCustomPriceDetails,
  CalculationCustomPriceObject,
  CalculationType,
  CustomCalculationForModal,
  Preferences,
  SelectedPackagingsDocument
} from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import { MARGIN_BUFFER } from "../../../utils/calculationUtils";
import calculationHelper, {
  ALLTYPES,
  BLENDING,
  BLISTERING,
  BOTTLING,
  ENCAPSULATION,
  PRODUCTION_TYPES,
  TABLETING
} from "../../../components/configurator/calculationDetails/calculationHelper";
import { round } from "../../../utils/baseUtils";
import CustomCalculationView, { CustomCalculationSelectOption } from "../../order/modals/CustomCalculationView";
import { ManufacturersDocument } from "../../../model/manufacturers.types";

export interface CustomCalculationPreviewStats {
  unitPriceNaked: number;
  totalMarginPercent: number;
  unitMargin: number;
  totalMargin: number;
}

interface CustomCalculationModalProps {
  type: string;
  preferences: Preferences;
  calculation: CalculationType;
  customCalculation?: CalculationCustomPriceDetails;
  selectedPackaging: Array<SelectedPackagingsDocument>;
  rowRef: any;
  onSaveCustomCalculation: (
    customCalculationInfo: Array<CustomCalculationForModal>,
    calcId: BSON.ObjectId | string,
    buffer: string | undefined,
    optionalCost: string | undefined,
    note: string | undefined
  ) => void;
  onResetCustomCalculation: (calcId: BSON.ObjectId) => void;
}

interface CustomCalculationModalState {
  buffer: string;
  optionalCost?: string;
  note?: string;
  customCalculationInfo: Array<CustomCalculationForModal>;
  show: boolean;
  stats: CustomCalculationPreviewStats;
}

class CustomCalculationModal extends PureComponent<CustomCalculationModalProps, CustomCalculationModalState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: CustomCalculationModalProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  componentDidUpdate(prevProps: Readonly<CustomCalculationModalProps>) {
    if (
      !_.isEqual(prevProps.calculation, this.props.calculation) ||
      !_.isEqual(prevProps.customCalculation, this.props.customCalculation)
    ) {
      this.setState(this.getDefaultState(false));
    }
  }

  handleShow = () => {
    this.setState({ show: true });
  };

  handleHide = () => {
    this.setState({ show: false });
  };

  handleOptionalCost = (e: React.ChangeEvent<HTMLInputElement>) => {
    const optionalCost = this.getNumericValue(e, true);
    const stats: CustomCalculationPreviewStats = this.calculatePreviewStats(
      +this.state.buffer,
      +optionalCost,
      this.state.customCalculationInfo
    );
    this.setState({ optionalCost, stats });
  };

  handleBuffer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newBuffer = this.getNumericValue(e);
    const stats: CustomCalculationPreviewStats = this.calculatePreviewStats(
      +newBuffer,
      +(this.state.optionalCost || "0"),
      this.state.customCalculationInfo
    );
    this.setState({ buffer: newBuffer, stats });
  };

  handleNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const note = e.currentTarget.value;
    this.setState({ note });
  };

  handleChangeManufacturer = (e: CustomCalculationSelectOption, index: number) => {
    const cci = [...this.state.customCalculationInfo];
    cci[index].manufacturerId = e.value.manufacturerId;
    cci[index].cost = this.getPriceFromSelectedManufacturer(e.document, e.value.type) || cci[index].cost;
    const stats: CustomCalculationPreviewStats = this.calculatePreviewStats(
      +this.state.buffer,
      +(this.state.optionalCost || "0"),
      cci
    );
    this.setState({ customCalculationInfo: cci, stats });
  };

  handleChangeCost = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const cci = [...this.state.customCalculationInfo];
    const newValue = this.getNumericValue(e);
    if (newValue) {
      cci[index].cost = newValue;
    }
    const stats: CustomCalculationPreviewStats = this.calculatePreviewStats(
      +this.state.buffer,
      +(this.state.optionalCost || "0"),
      cci
    );
    this.setState({ customCalculationInfo: cci, stats });
  };

  handleReset = (calcId: BSON.ObjectId) => {
    this.setState(this.getDefaultState(true));
    this.props.onResetCustomCalculation(calcId);
  };

  /**
   * Calculate custom calculation as preview in modal
   * @param buffer the margin buffer for the custom calculation
   * @param optionalCosts optional costs for the custom calculation
   * @param customCalculationInfo custom calculation info of the custom calculation
   * @returns {CustomCalculationPreviewStats} information for custom calculation preview
   */
  calculatePreviewStats = (
    buffer: number,
    optionalCosts: number,
    customCalculationInfo: Array<CustomCalculationForModal>
  ) => {
    const calculation = this.props.calculation;
    if (calculation.priceDetails) {
      const totalRecipeUnitPrice = calculation.priceDetails.recipeUnitPrice;
      const totalPackagingUnitPrice = calculation.priceDetails.packagingUnitPrice;
      const totalCapsuleUnitPrice = calculation.priceDetails.capsuleUnitPrice;
      const totalGeneralUnitPrice = calculation.priceDetails.generalUnitPrice;
      const generalPrices = calculation.priceDetails.generalPrices;
      const customCalculationDetails: CalculationCustomPriceObject = {};

      // fill customCalculationDetails
      for (const t of ALLTYPES) {
        const infoCustom = customCalculationInfo.find(cci => cci.type === t);
        const infoGeneral = generalPrices[t];
        if (infoGeneral && infoCustom) {
          customCalculationDetails[t] = {
            price: Number(infoCustom.cost) / infoGeneral.totalAmount,
            unitPrice: Number(infoCustom.cost) / Number(calculation.units),
            manufacturerId: infoCustom.manufacturerId
          };
        }
      }
      // calculate unitPrice of given custom prices
      let customUnitPrice;
      let totalCustomUnitPrice;
      if (customCalculationDetails) {
        totalCustomUnitPrice = Object.values(customCalculationDetails).reduce(
          (a: any, b: any) => a + b.unitPrice,
          0
        ) as number;
        customUnitPrice = totalRecipeUnitPrice + totalPackagingUnitPrice + totalCapsuleUnitPrice + totalCustomUnitPrice;
      } else {
        customUnitPrice =
          totalRecipeUnitPrice + totalPackagingUnitPrice + totalCapsuleUnitPrice + totalGeneralUnitPrice;
      }
      // calculate final values for preview stats
      const units = +calculation.units;
      const optionalCostsPerUnit = optionalCosts ? optionalCosts / units : 0;
      const unitPriceNaked = customUnitPrice + optionalCostsPerUnit + buffer;

      let unitPrice: number;
      let unitMargin: number;
      let totalPrice: number;
      let totalMargin: number;
      let percentMargin: number;
      if (calculation.marginType === "euro") {
        unitPrice = +calculation.margin;
        percentMargin = (unitPrice / unitPriceNaked - 1) * 100;
      } else {
        percentMargin = +calculation.margin;
        unitPrice = unitPriceNaked * (1 + percentMargin / 100);
      }
      unitMargin = unitPrice - unitPriceNaked;
      totalPrice = unitPrice * units;
      totalMargin = totalPrice - unitPriceNaked * units;
      return {
        unitPriceNaked: unitPriceNaked,
        totalMarginPercent: percentMargin,
        unitMargin: unitMargin,
        totalMargin: totalMargin
      };
    } else {
      return {
        unitPriceNaked: calculation.unitPriceNaked,
        totalMarginPercent: calculation.percentMargin,
        unitMargin: calculation.unitMargin,
        totalMargin: calculation.totalMargin
      };
    }
  };

  getDefaultState = (show: boolean) => {
    const customCalc = this.props.customCalculation;
    const calculation = this.props.calculation;
    if (customCalc) {
      return {
        show: show,
        buffer:
          customCalc.marginBuffer !== undefined && customCalc.marginBuffer !== null
            ? customCalc.marginBuffer.toString()
            : MARGIN_BUFFER.toString(),
        optionalCost: customCalc.optionalCosts?.toString() || "",
        note: customCalc.note || "",
        customCalculationInfo: this.getCustomCalculationInfo(),
        stats: {
          unitPriceNaked: calculation.unitPriceNaked,
          totalMarginPercent: calculation.percentMargin,
          unitMargin: calculation.unitMargin,
          totalMargin: calculation.totalMargin
        }
      };
    } else {
      return {
        show: show,
        buffer: MARGIN_BUFFER.toString(),
        optionalCost: "",
        note: "",
        customCalculationInfo: this.getCustomCalculationInfo(),
        stats: {
          unitPriceNaked: calculation.unitPriceNaked,
          totalMarginPercent: calculation.percentMargin,
          unitMargin: calculation.unitMargin,
          totalMargin: calculation.totalMargin
        }
      };
    }
  };

  getCustomCalculationInfo = () => {
    const prices = this.props.calculation.priceDetails?.generalPrices;
    const customPrices = this.props.customCalculation;
    const selectedManufacturer = this.props.preferences.selectedManufacturer?._id;
    const selectedFiller = this.props.preferences.selectedFiller?._id;
    const info: Array<CustomCalculationForModal> = [];
    const types = ALLTYPES;
    if (customPrices && prices) {
      types.map(t => {
        const customPrice = customPrices[t];
        const price = prices[t];
        if (customPrice && price && price.totalAmount !== 0) {
          info.push({
            manufacturerId: customPrice.manufacturerId,
            type: t,
            cost: `${round(customPrice.price * price.totalAmount)}`
          });
        }
      });
      return info;
    } else if (!customPrices && prices) {
      types.map(t => {
        const man =
          !selectedFiller || [TABLETING, ENCAPSULATION, BLENDING].includes(t) ? selectedManufacturer : selectedFiller;
        const price = prices[t];
        if (price && price.totalAmount !== 0) {
          info.push({
            manufacturerId: man,
            type: t,
            cost: `${round(price.price * price.totalAmount)}`
          });
        }
      });
    }
    return info;
  };

  /**
   * Get the total costs for the given type provided by the given manufacturer
   * @param manufacturer a manufacturer document
   * @param type given type
   * @returns {string} cost for the type of selected manufacturer
   */
  getPriceFromSelectedManufacturer = (manufacturer: ManufacturersDocument, type: PRODUCTION_TYPES): string => {
    const { preferences, calculation, selectedPackaging } = this.props;
    const prices = calculation.priceDetails?.generalPrices;
    const activeType = this.props.type;
    let costOfManufacturer: string = "";
    let newCost: string = "";
    if (manufacturer && prices) {
      if (type === ENCAPSULATION && preferences.selectedCapsule && prices.encapsulation) {
        costOfManufacturer = calculationHelper.getPriceFromManufacturer(
          manufacturer,
          ProductTypes.CAPSULES,
          type,
          "size",
          preferences.selectedCapsule.capsule_size
        );
        newCost = (+costOfManufacturer * +prices.encapsulation.totalAmount).toString();
      } else if (type === TABLETING && prices.tableting) {
        const totalAmount = (+calculation.units * +preferences.amountPerUnit) / 1000;
        costOfManufacturer = calculationHelper.getPriceFromManufacturer(
          manufacturer,
          ProductTypes.TABLETS,
          type,
          "amount",
          totalAmount.toString()
        );
        newCost = (+costOfManufacturer * +prices.tableting.totalAmount).toString();
      } else if (type === BOTTLING && prices.bottling) {
        const bottleAmount = selectedPackaging.find(pack => pack.packaging_type === PackagingTypes.BOTTLE)?.amount;
        if (
          bottleAmount &&
          [
            ProductTypes.CAPSULES,
            ProductTypes.LIQUID,
            ProductTypes.TABLETS,
            ProductTypes.POWDER,
            ProductTypes.SOFTGEL
          ].includes(activeType)
        ) {
          let totalAmount = +calculation.units * bottleAmount;
          if ([ProductTypes.POWDER, ProductTypes.LIQUID].includes(activeType))
            totalAmount = (+preferences.amountPerUnit * +calculation.units) / (1000 * 1000);
          costOfManufacturer = calculationHelper.getPriceFromManufacturer(
            manufacturer,
            activeType === ProductTypes.SOFTGEL ? ProductTypes.CAPSULES : activeType,
            type,
            "amount",
            totalAmount.toString()
          );
          newCost = (+costOfManufacturer * +prices.bottling.totalAmount).toString();
        }
      } else if (type === BLISTERING && prices.blistering) {
        const blisterAmount = selectedPackaging.find(pack => pack.packaging_type === PackagingTypes.BLISTER)?.amount;
        if (blisterAmount) {
          const totalAmount = +calculation.units * blisterAmount;
          costOfManufacturer = calculationHelper.getPriceFromManufacturer(
            manufacturer,
            ProductTypes.CAPSULES,
            type,
            "amount",
            totalAmount.toString()
          );
          newCost = (+costOfManufacturer * +prices.blistering.totalAmount).toString();
        }
      } else if (type === BLENDING && prices.blending) {
        const totalAmount = (+preferences.amountPerUnit * +calculation.units) / (1000 * 1000);
        if (activeType === ProductTypes.POWDER || activeType === ProductTypes.LIQUID) {
          costOfManufacturer = calculationHelper.getPriceFromManufacturer(
            manufacturer,
            activeType,
            type,
            "amount",
            totalAmount.toString()
          );
          newCost = (+costOfManufacturer * +prices.blending.totalAmount).toString();
        }
      }
    }
    return newCost;
  };

  getNumericValue = (e: React.ChangeEvent<HTMLInputElement>, allowEmpty?: boolean) => {
    if (allowEmpty && e.target.value.trim() === "") return "";
    let value = e.target.value.replaceAll(/^0+/g, "0");
    value = value.replaceAll(/^-0+/g, "-0");
    if (!value.includes(".") && (!value.includes("-") || value.length > 2)) value = Number(value).toString();
    return value;
  };

  render() {
    const { type, calculation, rowRef, preferences, onSaveCustomCalculation } = this.props;
    const { buffer, optionalCost, note, customCalculationInfo, show, stats } = this.state;

    return (
      <div className="kt-widget-stats ml-2" onClick={e => e.stopPropagation()}>
        <button
          type="button"
          className="btn btn-secondary"
          style={{ borderColor: "transparent" }}
          onClick={() => this.handleShow()}
          onMouseEnter={() => {
            if (rowRef && rowRef.current) rowRef.current.classList.remove("calculation-details-header-hover");
          }}
          onMouseLeave={() => {
            if (rowRef && rowRef.current) rowRef.current.classList.add("calculation-details-header-hover");
          }}
        >
          <i className="fas fa-cog p-0" style={{ fontSize: "100%" }} />
        </button>
        <Modal show={show} onHide={() => this.handleHide()} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Custom Calculation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomCalculationView
              type={type}
              edit={false}
              buffer={buffer}
              optionalCost={optionalCost}
              note={note}
              selectedCapsule={preferences.selectedCapsule}
              customCalculationInfo={customCalculationInfo}
              stats={stats}
              onChangeManufacturer={this.handleChangeManufacturer}
              onChangeCost={this.handleChangeCost}
              onChangeOptionalCost={this.handleOptionalCost}
              onChangeBuffer={this.handleBuffer}
              onChangeNote={this.handleNote}
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={() => this.handleHide()}>
              Close
            </button>
            <button className="btn btn-secondary" onClick={() => this.handleReset(calculation.id)}>
              Reset
            </button>
            <button
              className="btn btn-success"
              onClick={() => onSaveCustomCalculation(customCalculationInfo, calculation.id, buffer, optionalCost, note)}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CustomCalculationModal;
