import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_metronic";
import { CommoditiesDocument, MinimumCalendarCommodity } from "../../model/commodities.types";
import config from "../../config/config.json";
import { DataContext } from "../../context/dataContext";
import commodityUtils from "../../utils/commodityUtils";
import baseUtils from "../../utils/baseUtils";
import { DeliveryCalendarUserContext } from "../../context/deliveryCalendarUserContext";

interface CommodityWidgetProps {
  commodity: CommoditiesDocument | MinimumCalendarCommodity;
  context: React.ContextType<typeof DataContext> | React.ContextType<typeof DeliveryCalendarUserContext>;
  additionalClasses?: string;
}

class CommodityWidget extends PureComponent<CommodityWidgetProps, {}> {
  /**
   * Renders the correct image for the given commodity.
   * @returns { JSX.Element } Image tag with correct link
   */
  renderImage = () => {
    const { commodity, context } = this.props;
    const { colors } = context;
    const type = commodityUtils.getCommodityType(commodity);
    const color = commodity.color ? baseUtils.getDocFromCollection(colors, commodity.color) : null;
    let src = config.mediahubBase;
    if (type === "softgel") src = toAbsoluteUrl("/media/img/Softgels.jpg");
    else if (color && color.image && commodity.form.toString() === "5ffdc73a95ac35e327854aa0") {
      src += color.image;
    } else if (commodity.form.toString() === "5ffdc73a95ac35e327854aa0") {
      src += "files/unknown.png";
    } else {
      src += "files/others.png";
    }
    return <img src={src} alt="color" />;
  };

  render() {
    const { commodity, additionalClasses } = this.props;
    return (
      <div className={"kt-user-card-v2 " + (additionalClasses ? additionalClasses : "")}>
        <div className="kt-user-card-v2__pic d-none d-xl-block">{this.renderImage()}</div>
        <div className="kt-user-card-v2__details">
          <Link
            to={"/commodity/" + commodity._id.toString()}
            className="kt-user-card-v2__name kt-link"
            onClick={e => e.stopPropagation()}
          >
            {commodity.title.en.trim() !== ""
              ? commodity.title.en.length > 33
                ? commodity.title.en.slice(0, 30) + "..."
                : commodity.title.en
              : "<Empty Name - Please fill in>"}
          </Link>
          <span className="kt-user-card-v2__email">
            {commodity.subtitle.en.length > 33 ? (
              <OverlayTrigger placement="auto" overlay={<Tooltip id="tooltip">{commodity.subtitle.en}</Tooltip>}>
                <span>{commodity.subtitle.en.slice(0, 30) + "..."}</span>
              </OverlayTrigger>
            ) : (
              commodity.subtitle.en
            )}
          </span>
        </div>
      </div>
    );
  }
}

export default CommodityWidget;
