import React, { PureComponent } from "react";
import CompanyWidget from "../common/CompanyWidget";
import PersonWidget from "../common/PersonWidget";
import { CustomOrder } from "../order/CustomTypes";
import CreateManufacturingSheetModal from "../order/modals/CreateManufacturingSheetModal";
import { T_CUSTOM, T_SERVICE, T_SOFTGEL } from "../order/OrderHelper";
import { DataContext } from "../../context/dataContext";
import accessUtils, { ACTIONS } from "../../utils/accessUtils";
import dateUtils from "../../utils/dateUtils";
import orderUtils from "../../utils/orderUtils";

interface ProductionOrderHeaderProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
  reschedule?: boolean;
  onCancelSchedule: () => void;
}

interface ProductionOrderHeaderState {}

class ProductionOrderHeader extends PureComponent<ProductionOrderHeaderProps, ProductionOrderHeaderState> {
  render() {
    const { order, context, reschedule, onCancelSchedule } = this.props;
    const orderDescription = orderUtils.resolveOrderDescription(order);
    const canCancel = accessUtils.canPerformAction(ACTIONS.PRODUCTIONPLANCANCEL);

    return (
      <div className="kt-widget kt-widget--user-profile-3">
        <div className="kt-widget__top">
          <div className="kt-widget__content">
            <div className="kt-widget__head">
              <span className={"kt-widget__title"}>
                <div className="align-middle">
                  {`${orderDescription[0]} ${orderDescription[1]}-${order!.identifier.toString()}`}
                  <span className="ml-2">
                    <i
                      className={
                        "kt-nav__link-icon flaticon-add-label-button " +
                        (order.priority === "low"
                          ? "text-success"
                          : order.priority === "medium"
                          ? "text-warning"
                          : order.priority === "high"
                          ? "text-danger"
                          : "")
                      }
                    />
                    <span className="kt-nav__link-text ml-1 kt-font-bold">{order.priority} priority</span>
                  </span>
                </div>
                <span style={{ fontWeight: 200 }}>{order.title}</span>
                <span className="ml-2" style={{ fontWeight: 200, fontSize: "14px" }}>
                  {order.subtitle}
                </span>
              </span>
              <div className="kt-widget__action mb-2">
                <CreateManufacturingSheetModal
                  order={order}
                  context={context}
                  disabled={[T_CUSTOM, T_SOFTGEL, T_SERVICE].includes(order.settings.type)}
                />
                {!!reschedule && (
                  <button
                    className={"btn btn-secondary btn-sm" + (canCancel ? "" : " disabled")}
                    onClick={canCancel ? onCancelSchedule : undefined}
                    disabled={!canCancel}
                  >
                    Cancel schedule
                  </button>
                )}
              </div>
            </div>
            <div className="kt-widget__info">
              <CompanyWidget company={order.createdFor} additionalClasses={"mr-3"} />
              <PersonWidget person={order.createdFrom} additionalClasses={"mr-3 mt-1"} />
              <div className="kt-widget__stats d-flex  ml-auto">
                <div className="kt-widget__item" style={{ margin: "10px", textAlign: "center" }}>
                  <span className="kt-widget__date" style={{ width: "120px" }}>
                    Start Date
                  </span>
                  <div className="kt-widget__label" style={{ width: "100%" }}>
                    <span className="btn btn-label-brand btn-sm btn-bold btn-upper" style={{ width: "100%" }}>
                      {order.createdOn.toLocaleDateString("de-DE", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric"
                      })}
                    </span>
                  </div>
                </div>
                <div className="kt-widget__item" style={{ margin: "10px", textAlign: "center" }}>
                  <span className="kt-widget__date" style={{ width: "120px" }}>
                    Target Week
                  </span>
                  <div className="kt-widget__label" style={{ width: "100%" }}>
                    <span className="btn btn-label-brand btn-sm btn-bold btn-upper" style={{ width: "100%" }}>
                      {order.targetDate ? "CW " + dateUtils.getCW(order.targetDate) : "not set"}
                    </span>
                  </div>
                </div>
                {!!reschedule && (
                  <div className="kt-widget__item" style={{ margin: "10px 0", textAlign: "center" }}>
                    <span className="kt-widget__date" style={{ width: "120px" }}>
                      Production Week
                    </span>
                    <div className="kt-widget__label" style={{ width: "100%" }}>
                      <span className="btn btn-label-brand btn-sm btn-bold btn-upper" style={{ width: "100%" }}>
                        {order.productionWeek ? "CW " + order.productionWeek : "not set"}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductionOrderHeader;
