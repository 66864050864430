import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import dbService from "../../../services/dbService";
import toastUtils from "../../../utils/toastUtils";
import accessUtils, { DELETELOCATIONS } from "../../../utils/accessUtils";
import orderUtils, { ARCHIVE, CREATEINVOICE, DECLINED } from "../../../utils/orderUtils";
import baseUtils from "../../../utils/baseUtils";
import { resolveTranslation } from "../../../utils/translationUtils";
import commodityUtils from "../../../utils/commodityUtils";
import { OrdersDocument } from "../../../model/orders.types";
import { SuppliersDocument } from "../../../model/suppliers.types";
import { CommoditiesDocument, CommodityOrder } from "../../../model/commodities.types";
import { ExternalManufacturerOrdersDocument } from "../../../model/externalManufacturerOrders.types";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { useDataContext } from "../../../context/dataContext";
import BaseListing from "../../listings/BaseListing";
import { paginate } from "../../common/Pagination";
import { EM_CHECKED, EM_OPEN } from "../../externalManufacturers/ExternalManufacturerHelper";
import packagingUtils from "../../../utils/packagingUtils";
import { BasicSelectOption } from "../../../model/common.types";

enum OrderType {
  ORDERS = "orders",
  COMMODITY_ORDERS = "commodityOrders"
}

interface CommodityOrderInfo {
  commodity: CommoditiesDocument;
  commodityOrder: CommodityOrder;
}

interface DisableSupplierModalProps {
  supplier: SuppliersDocument;
}

const DisableSupplierModal: React.FC<DisableSupplierModalProps> = ({ supplier }) => {
  const dataContext = useDataContext();
  const { commodities, orders } = dataContext;

  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [displayedOrders, setDisplayedOrders] = useState<BasicSelectOption>({
    value: OrderType.ORDERS,
    label: "Orders"
  });

  const handleShow = useCallback(() => setShow(true), []);
  const handleHide = useCallback(() => setShow(false), []);
  const handleChangePage = useCallback(currentPage => setCurrentPage(currentPage), []);

  const handleChangePageSize = useCallback(pageSize => {
    setPageSize(pageSize);
    setCurrentPage(1);
  }, []);

  const handleTypeChange = useCallback((value: any) => {
    setDisplayedOrders(value);
    setCurrentPage(1);
  }, []);

  const handleToggleDisabledState = useCallback(async () => {
    const disabled = !supplier.disabled;
    try {
      setSaving(true);
      const result = await dbService.callFunction<boolean>("disableSupplier", [supplier._id, disabled], true);
      await toastUtils.databaseOperationToast(
        result,
        `Supplier ${disabled ? "disabled" : "enabled"} successfully`,
        `Error ${disabled ? "disabling" : "enabling"} supplier. Please try again later.`,
        () => {
          setShow(false);
        }
      );
    } catch (e) {
      console.error(e);
      toast.error(`Error ${disabled ? "disabling" : "enabling"} supplier. Please try again later: ${e.message}`);
    } finally {
      setSaving(false);
    }
  }, [supplier]);

  const canDisable = useMemo(() => accessUtils.canDeleteData(DELETELOCATIONS.SUPPLIER), []);

  const openOrders = useMemo(() => {
    return orders.filter(
      o =>
        ![ARCHIVE, DECLINED, CREATEINVOICE].includes(o.state) &&
        (o.calculations[0].prices.some(p => p.supplier.toString() === supplier._id.toString()) ||
          o.calculations[0].packagings.some(p => p.supplier.toString() === supplier._id.toString()))
    );
  }, [orders, supplier]);

  const paginatedOpenOrders = useMemo(() => {
    return paginate(openOrders, currentPage, pageSize);
  }, [openOrders, currentPage, pageSize]);

  const openCommodityOrders = useMemo(() => {
    const commodityOrders: Array<CommodityOrderInfo> = [];
    return commodities.reduce((openOrdersBySupplier, c) => {
      c.orders &&
        c.orders
          .filter(
            o =>
              (!o.delivered || (o.stockTransferOrders && o.stockTransferOrders.some(sto => !sto.delivered))) &&
              o.supplier &&
              o.supplier.toString() === supplier._id.toString()
          )
          .forEach(o => openOrdersBySupplier.push({ commodity: c, commodityOrder: o }));
      return openOrdersBySupplier;
    }, commodityOrders);
  }, [commodities, supplier]);

  const paginatedOpenCommodityOrders = useMemo(() => {
    return paginate(openCommodityOrders, currentPage, pageSize);
  }, [openCommodityOrders, currentPage, pageSize]);

  const headerDefinitionCommodityOrders = [
    { title: "Amount", size: 20 },
    { title: "Commodity", size: 35 },
    { title: "Destination", size: 25 },
    { title: "ETA", size: 20 }
  ];

  const headerDefinitionsOrders = [
    { title: "AN/AT", size: 15 },
    { title: "Title", size: 25 },
    { title: "Commodities", size: 30 },
    { title: "Packaging", size: 30 }
  ];

  return (
    <>
      <button
        className="btn btn-secondary btn-sm btn-upper"
        onClick={canDisable ? (supplier.disabled ? handleToggleDisabledState : handleShow) : undefined}
        disabled={saving || !canDisable}
      >
        <i className={supplier.disabled ? "fa fa-plus" : "fas fa-times"} />
        {supplier.disabled ? "Enable" : "Disable"}
      </button>
      <Modal show={show} centered onHide={handleHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Disable {supplier.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {openCommodityOrders.length === 0 && openOrders.length === 0 ? (
            <div
              className="alert alert-secondary mt-4 font-weight-bolder"
              role="alert"
              style={{ background: "white", border: 0 }}
            >
              <div className="alert-icon">
                <i className="flaticon-exclamation-1" style={{ color: "red" }} />
              </div>
              <div className="text-dark h5 my-auto">
                Do you really want to disable {supplier.name}?
                <br />
                <span className="kt-font-bold">
                  If you proceed, all saved prices from the supplier will be purged and cannot be recovered afterwards.{" "}
                </span>
                No new prices can be added to the supplier, and it will no longer be selectable except in batches.
              </div>
            </div>
          ) : (
            <>
              <div className="alert alert-warning" role="alert">
                <div className="alert-icon">
                  <i className="flaticon-warning" />
                </div>
                <div className="alert-text">
                  <div>
                    Do you really want to disable {supplier.name}?{" "}
                    <span className="kt-font-bold">
                      {`It is still used in ${openOrders.length} orders and ${openCommodityOrders.length} commodity orders.`}
                    </span>
                  </div>
                  <div className="mt-2">
                    Please review the orders listed below carefully.{" "}
                    <span className="kt-font-bold">
                      If you proceed, all saved prices from the supplier will be purged and cannot be recovered
                      afterwards.{" "}
                    </span>
                    No new prices can be added to the supplier, and it will no longer be selectable except in batches.
                  </div>
                </div>
              </div>
              <div className="kt-portlet">
                <div className="kt-portlet__body">
                  <div className="row justify-content-start mb-2">
                    <div className="col-4">
                      <Select
                        className="select-default"
                        options={[
                          { value: OrderType.ORDERS, label: "Orders" },
                          { value: OrderType.COMMODITY_ORDERS, label: "Commodity Orders" }
                        ]}
                        value={displayedOrders}
                        onChange={handleTypeChange}
                      />
                    </div>
                  </div>
                  {displayedOrders.value === OrderType.ORDERS ? (
                    <BaseListing
                      headerDefinition={headerDefinitionsOrders}
                      documents={openOrders}
                      bodyContent={
                        <>
                          {paginatedOpenOrders.map(openOrder => (
                            <OpenOrderRow key={openOrder._id.toString()} openOrder={openOrder} supplier={supplier} />
                          ))}
                        </>
                      }
                      currentPage={currentPage}
                      pageSize={pageSize}
                      onPageChange={handleChangePage}
                      onPageSizeChange={handleChangePageSize}
                    />
                  ) : (
                    <BaseListing
                      headerDefinition={headerDefinitionCommodityOrders}
                      documents={openCommodityOrders}
                      bodyContent={
                        <>
                          {paginatedOpenCommodityOrders.map(openCommodityOrder => (
                            <OpenCommodityOrderRow
                              key={openCommodityOrder.commodityOrder._id.toString()}
                              openCommodityOrder={openCommodityOrder}
                            />
                          ))}
                        </>
                      }
                      currentPage={currentPage}
                      pageSize={pageSize}
                      onPageChange={handleChangePage}
                      onPageSizeChange={handleChangePageSize}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleHide}>
            Close
          </button>
          <ErrorOverlayButton
            buttonText="Disable"
            className={"btn btn-secondary"}
            onClick={handleToggleDisabledState}
            saving={saving}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface OpenOrderRowProps {
  openOrder: OrdersDocument;
  supplier: SuppliersDocument;
}

const OpenOrderRow: React.FC<OpenOrderRowProps> = ({ openOrder, supplier }) => {
  const dataContext = useDataContext();
  const { commodities, packagings } = dataContext;

  const commodityPrices = useMemo(() => {
    return openOrder.calculations[0].prices.filter(p => p.supplier.toString() === supplier._id.toString());
  }, [openOrder, supplier]);

  const packagingPrices = useMemo(() => {
    return openOrder.calculations[0].packagings.filter(p => p.supplier.toString() === supplier._id.toString());
  }, [openOrder, supplier]);

  return (
    <tr className="kt-datatable__row d-table-row">
      <td className="kt-datatable__cell d-table-cell kt-font-dark">
        <Link to={"/order/" + openOrder._id.toString()} className="kt-link kt-font-dark">
          {orderUtils.resolveOrderDescription(openOrder)[1]}-{openOrder.identifier}
        </Link>
      </td>
      <td className="kt-datatable__cell d-table-cell kt-font-dark">{openOrder.title ? openOrder.title : "-"}</td>
      <td className="kt-datatable__cell d-table-cell kt-font-dark">
        <>
          {commodityPrices.length > 0 ? (
            commodityPrices.map(cP => {
              const commodity = commodities.find(c => c._id.toString() === cP._id.toString());
              return commodity ? <div key={commodity._id.toString()}>{commodity.title.en}</div> : null;
            })
          ) : (
            <div>-</div>
          )}
        </>
      </td>
      <td className="kt-datatable__cell d-table-cell kt-font-dark">
        <>
          {packagingPrices.length > 0 ? (
            packagingPrices.map(pP => {
              const packaging = packagings.find(p => p._id.toString() === pP._id.toString());
              return packaging ? (
                <div key={packaging._id.toString()}>{packagingUtils.getPackagingType(packaging.packaging_type)}</div>
              ) : null;
            })
          ) : (
            <div>-</div>
          )}
        </>
      </td>
    </tr>
  );
};

interface OpenCommodityOrderRowProps {
  openCommodityOrder: CommodityOrderInfo;
}

const OpenCommodityOrderRow: React.FC<OpenCommodityOrderRowProps> = ({ openCommodityOrder }) => {
  const dataContext = useDataContext();
  const { orders, externalManufacturerOrders } = dataContext;
  const { commodityOrder, commodity } = openCommodityOrder;

  const eta = useMemo(() => {
    if (commodityOrder.ordered) {
      let eta = new Date(commodityOrder.ordered.getTime());
      eta.setDate(eta.getDate() + commodityOrder.deliverytime);
      return eta;
    }
  }, [commodityOrder]);

  const amount = useMemo(() => {
    return commodityUtils.resolveStockUnit(commodityOrder.orderquantity, commodity.type);
  }, [commodityOrder, commodity]);

  const relatedOrders = useMemo(() => {
    if (commodityOrder.orders && commodityOrder.orders.length > 0) {
      return commodityOrder.orders.map(o => baseUtils.getDocFromCollection(orders, o.toString()) as OrdersDocument);
    }
  }, [commodityOrder, orders]);

  const relatedEMOrders = useMemo(() => {
    if (commodityOrder.relatedEMOrders && commodityOrder.relatedEMOrders.length > 0) {
      return commodityOrder.relatedEMOrders.map(
        rEMO =>
          baseUtils.getDocFromCollection(
            externalManufacturerOrders,
            rEMO.toString()
          ) as ExternalManufacturerOrdersDocument
      );
    }
  }, [commodityOrder, externalManufacturerOrders]);

  return (
    <tr className="kt-datatable__row d-table-row">
      <td className="kt-datatable__cell d-table-cell kt-font-dark">{amount}</td>
      <td className="kt-datatable__cell d-table-cell kt-font-dark">
        <Link className="kt-link kt-font-dark" to={"/commodity/" + commodity._id.toString()}>
          {resolveTranslation(commodity.title)}
        </Link>
      </td>
      <td className="kt-datatable__cell d-table-cell kt-font-dark">
        {relatedOrders &&
          relatedOrders.map(rO => (
            <Link key={rO._id.toString()} className="kt-link kt-font-dark d-block" to={"/order/" + rO._id.toString()}>
              AT-{rO.identifier}
            </Link>
          ))}
        {relatedEMOrders &&
          relatedEMOrders.map(rEMO => (
            <Link
              key={rEMO._id.toString()}
              className="kt-link kt-font-dark d-block"
              to={"/externalOrder/" + rEMO._id.toString()}
            >
              {[EM_OPEN, EM_CHECKED].includes(rEMO.state) ? "REQ" : "EMO"}-{rEMO.identifier}
            </Link>
          ))}
        {(!relatedOrders || relatedOrders.length === 0) && (!relatedEMOrders || relatedEMOrders.length === 0) && (
          <span className="kt-font-dark">Stock</span>
        )}
      </td>
      <td className="kt-datatable__cell d-table-cell kt-font-dark">{eta ? baseUtils.formatDate(eta) : "-"}</td>
    </tr>
  );
};

export default DisableSupplierModal;
