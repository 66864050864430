import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { CustomOrder } from "../CustomTypes";
import { OrderUsedBatches } from "../tabPanles/OrderFulfillment";
import { DataContext } from "../../../context/dataContext";
import dbService, { UpdateAction, COMMODITIES, ORDERS, PACKAGINGSTOCK } from "../../../services/dbService";
import { T_FULFILLMENTREVOKED } from "../../../utils/timelineUtils";
import userService from "../../../services/userService";
import toastUtils from "../../../utils/toastUtils";
import { PRODUCTION } from "../../../utils/orderUtils";
import { OrderFileTypes } from "../../../model/orders.types";

interface RevokeFulfillmentModalProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
}

interface RevokeFulfillmentModalState {
  show: boolean;
  saving: boolean;
}

class RevokeFulfillmentModal extends PureComponent<RevokeFulfillmentModalProps, RevokeFulfillmentModalState> {
  constructor(props: RevokeFulfillmentModalProps) {
    super(props);
    this.state = { show: false, saving: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  handleRevokeFulfillment = async () => {
    const { order, context } = this.props;
    this.setState({ saving: true });
    try {
      const cBat = order.usedBatches || [];
      const pBat = order.usedPackagingBatches || [];
      const actions: Array<UpdateAction> = [];
      for (let i = 0; i < cBat.length; i++) {
        const cB = cBat[i];
        actions.push({
          collection: COMMODITIES,
          filter: { _id: cB.commodityId },
          inc: { "stock.$[batch].amount": cB.used },
          update: { disabled: false },
          arrayFilters: [{ "batch._id": cB.id }]
        });
      }
      for (let i = 0; i < pBat.length; i++) {
        const pB = pBat[i];
        actions.push({
          collection: PACKAGINGSTOCK,
          filter: { _id: pB.id },
          inc: { amount: pB.used },
          update: { disabled: false }
        });
      }
      const timelineEntry = {
        type: T_FULFILLMENTREVOKED,
        date: new Date(),
        person: userService.getUserId()
      };
      actions.push({
        collection: ORDERS,
        filter: { _id: order._id },
        update: { state: PRODUCTION, "fulfillment.productionReportApproval": { required: false } },
        push: { timeline: timelineEntry },
        pull: { files: { type: OrderFileTypes.PRODUCTION_REPORT } }
      });
      const res = await dbService.updatesAsTransaction(actions);
      await toastUtils.databaseOperationToast(
        res,
        "Fulfillment successfully revoked",
        "Error revoking fulfillment",
        () => {
          context.updateDocumentInContext(ORDERS, order._id);
          for (let i = 0; i < cBat.length; i++) {
            context.updateDocumentInContext(COMMODITIES, cBat[i].commodityId);
          }
          for (let i = 0; i < pBat.length; i++) {
            context.updateDocumentInContext(PACKAGINGSTOCK, pBat[i].id);
          }
        }
      );
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { order, context } = this.props;
    const { show, saving } = this.state;
    return (
      <>
        <button
          className={"btn btn-sm btn-upper btn-secondary " + (!order.fulfillment?.priceInfo && "disabled")}
          disabled={!order.fulfillment?.priceInfo}
          onClick={order.fulfillment?.priceInfo ? this.handleShow : undefined}
        >
          Revoke
        </button>
        <Modal show={show} onHide={this.handleHide} centered name="RevokeFulfillmentModal" size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Revoke Fulfillment of Order AT-{order.identifier}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="overflow-auto" style={{ maxHeight: "80vh" }}>
            <div className="text-dark">
              Are you sure you want to revoke the fulfillment of Order AT-{order.identifier}? The following batches will
              be adjusted
            </div>
            {order.usedBatches && order.usedBatches.length > 0 && (
              <OrderUsedBatches type={"commodity"} usedBatches={order.usedBatches} context={context} />
            )}
            {order.usedPackagingBatches && order.usedPackagingBatches.length > 0 && (
              <OrderUsedBatches type={"packaging"} usedBatches={order.usedPackagingBatches} context={context} />
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            <button
              className={"btn btn-secondary" + (saving && " disabled")}
              disabled={saving}
              onClick={saving ? undefined : this.handleRevokeFulfillment}
            >
              {saving && (
                <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                  <svg className="button-splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                </div>
              )}
              Revoke
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default RevokeFulfillmentModal;
