import React, { PureComponent } from "react";
import { OrdersDocument } from "../../../model/orders.types";
import orderUtils, { DECLINED } from "../../../utils/orderUtils";
import ReactSpeedometer from "react-d3-speedometer";
import dashboardUtils from "../../../utils/dashboardUtils";
import dateUtils from "../../../utils/dateUtils";
import { T_FULFILLMENT } from "../../../utils/timelineUtils";
import contractUtils from "../../../utils/contractUtils";

interface Period {
  monday: Date;
  sunday: Date;
  planned: number;
  invoiced: number;
}

interface CapacityBarometerProps {
  orders: Array<OrdersDocument>;
  dashboard: "general" | "investor";
}

interface CapacityBarometerState {}

class CapacityBarometer extends PureComponent<CapacityBarometerProps, CapacityBarometerState> {
  /**
   * Calculate the average invoiced amount over all periods
   * @param periods periods
   * @returns {number} average invoiced amount over periods
   */
  getAverage = (periods: Array<Period>) => {
    let total = periods.reduce((a, b) => a + +b.invoiced, 0);
    if (total > 0) return total / periods.length;
    else return 0;
  };

  /**
   * Get initial periods
   * @returns {Array<Period>} list of periods
   */
  getInitialPeriods = () => {
    let periods: Array<Period> = [];
    for (let i = 0; i < 6; i++) {
      const d = new Date(Date.now() + i * 7 * 24 * 60 * 60 * 1000);
      const [monday, sunday] = dateUtils.getMondaySunday(d);
      periods.push({
        monday: monday,
        sunday: sunday,
        planned: 0,
        invoiced: 0
      });
    }
    return periods;
  };

  /**
   * Get periods populated with data
   * @returns {Array<Period>} periods filled with data
   */
  getData = () => {
    const { orders } = this.props;
    let periods = this.getInitialPeriods();

    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      if (orderUtils.isOrder(order) && order.state !== DECLINED && !contractUtils.isContract(order)) {
        for (let k = 0; k < periods.length; k++) {
          const period = periods[k];
          const mondayTime = period.monday.getTime();
          const sundayTime = period.sunday.getTime();
          if (order.delivery && order.delivery.getTime() >= mondayTime && order.delivery.getTime() <= sundayTime) {
            period.planned += orderUtils.hasFulfillmentPriceInfo(order)
              ? order.fulfillment!.totalUnits!
              : order.calculations[0].units;
          }
          for (let j = 0; j < order.timeline.length; j++) {
            const timelineEntry = order.timeline[j];
            if (timelineEntry.type === T_FULFILLMENT) {
              if (
                timelineEntry.date.getTime() <= sundayTime - 6 * 7 * 24 * 60 * 60 * 1000 &&
                timelineEntry.date.getTime() >= mondayTime - 6 * 7 * 24 * 60 * 60 * 1000
              ) {
                period.invoiced += orderUtils.hasFulfillmentPriceInfo(order)
                  ? order.fulfillment!.totalUnits!
                  : order.calculations[0].units;
              }
            }
          }
        }
      }
    }
    return periods;
  };

  /**
   * Calculate the workload
   * @param periods list of periods
   * @param mode a mode
   * @returns {number} the calculated workload
   */
  getWorkload = (periods: Array<Period>, mode?: any) => {
    const { orders } = this.props;
    if (periods.length === 0) return 0;
    let workload = (dashboardUtils.getReservedCapacity(orders) / dashboardUtils.getAverageCapacity(periods)) * 100;
    if (isNaN(workload)) return 0;
    if (workload > 100 && mode === "gauge") {
      return 103;
    } else {
      return Math.round(workload * 100) / 100;
    }
  };

  render() {
    const { dashboard, orders } = this.props;
    const periods = this.getData();
    const workload = this.getWorkload(periods);
    const gaugeWorkload = this.getWorkload(periods, "gauge");

    const exponentialMovingAverage = dashboardUtils.getEMA(periods);
    return (
      <div className="kt-portlet ">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">Capacity Barometer</h3>
          </div>
        </div>
        <div className="kt-portlet__body overflow-auto pt-3">
          <div className="kt-widget16 p-0 d-block mx-auto my-0">
            <div className="kt-widget16__stats p-0">
              <div className="kt-widget16__visual">
                <div id="kt_chart_support_tickets">
                  <ReactSpeedometer
                    segmentColors={[
                      "#FFB600",
                      "#FFAA00",
                      "#FF9E00",
                      "#FF9100",
                      "#FF8500",
                      "#FF7900",
                      "#FF6D00",
                      "#FF6000",
                      "#FF5400",
                      "#FF4800"
                    ]}
                    width={300}
                    height={180}
                    minValue={0}
                    maxValue={100}
                    value={gaugeWorkload}
                    maxSegmentLabels={5}
                    segments={10}
                    needleColor={"#74788d"}
                    needleTransitionDuration={5000}
                    needleTransition="easeElastic"
                    labelFontSize={"13px"}
                    textColor={"#74788d"}
                    currentValueText={workload + "%"}
                  />
                </div>
              </div>
            </div>
            {dashboard === "general" && (
              <div className="kt-widget16__items w-100">
                <div className="kt-widget16__item px-0 py-1">
                  <span className="kt-widget16__date">Estimated Capacity 6W</span>
                  <span className="kt-widget16__price">{Math.round(exponentialMovingAverage * 6)}</span>
                </div>
                <div className="kt-widget16__item px-0 py-1">
                  <span className="kt-widget16__date">Reserved Capacity 6W</span>
                  <span className="kt-widget16__price">{dashboardUtils.getReservedCapacity(orders)}</span>
                </div>
                <div className="kt-widget16__item px-0 py-1">
                  <span className="kt-widget16__date">Output This Week</span>
                  <span className="kt-widget16__price">{periods[0].invoiced}</span>
                </div>
                <div className="kt-widget16__item px-0 py-1">
                  <span className="kt-widget16__date">Output Last Week</span>
                  <span className="kt-widget16__price ">{periods[1].invoiced}</span>
                </div>
                <div className="kt-widget16__item px-0 py-1">
                  <span className="kt-widget16__date">Average 6W</span>
                  <span className="kt-widget16__price ">{Math.round(this.getAverage(periods))}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CapacityBarometer;
