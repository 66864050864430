import _ from "lodash";
import React, { PureComponent } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";
import EMCommodityWidget from "../EMCommodityWidget";
import ExternalManufacturerHelper, {
  EM_ACCEPTED,
  EM_ARRIVED,
  EM_CANCELED,
  EM_CHECKED,
  EM_DELIVERED,
  EM_EXPIRED,
  EM_FINISHED,
  EM_OPEN,
  EM_ORDERED
} from "../ExternalManufacturerHelper";
import baseUtils from "../../../utils/baseUtils";
import CancelEMOrderModal from "../modals/CancelEMOrderModal";
import AcceptRequestModal from "../modals/AcceptRequestModal";

interface EmDashboardOrdersProps extends RouteComponentProps<{}, {}, {}> {
  context: React.ContextType<typeof ExternalManufacturerContext>;
  request?: boolean;
}

interface EmDashboardOrdersState {}

class EmDashboardOrders extends PureComponent<EmDashboardOrdersProps, EmDashboardOrdersState> {
  render() {
    const { context, history, request } = this.props;
    const { externalManufacturerOrders: orders, commodities } = context;
    const filteredOrders = orders.filter(
      o =>
        (request && [EM_OPEN, EM_CHECKED].includes(o.state)) ||
        (!request && ![EM_OPEN, EM_CHECKED, EM_CANCELED, EM_EXPIRED].includes(o.state))
    );
    return (
      <div className="kt-portlet kt-portlet--height-fluid" style={{ minHeight: "40vh" }}>
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">Current {request ? "Requests" : "Orders"}</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          {filteredOrders.length === 0 ? (
            <div className="text-center text-muted my-5">
              No {request ? "requests" : "orders"} were created yet
              <br />
              <br />
              <br />
              <Link className="btn btn-primary btn-sm" to={"/commodities"}>
                View commodities
              </Link>
            </div>
          ) : (
            <div
              className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive"
              style={{ maxHeight: "50vh" }}
            >
              <table className="kt-datatable__table d-table">
                <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
                  <tr className="kt-datatable__row d-table-row">
                    <th className="kt-datatable__cell d-table-cell" style={{ width: "20%" }}>
                      <span>Order</span>
                    </th>
                    <th className="kt-datatable__cell d-table-cell" style={{ width: request ? "25%" : "35%" }}>
                      <span>Commodity</span>
                    </th>
                    <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }}>
                      <span>Status</span>
                    </th>
                    <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }}>
                      <span>Amount</span>
                    </th>
                    <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }}>
                      <span>Price</span>
                    </th>
                    <th className="kt-datatable__cell d-table-cell" style={{ width: "15%" }}>
                      <span>Last update</span>
                    </th>
                    {request && (
                      <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }}>
                        <span>Action</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                  {filteredOrders
                    .slice()
                    .sort(
                      (o1, o2) =>
                        o2.timeline[o2.timeline.length - 1].date.getTime() -
                        o1.timeline[o1.timeline.length - 1].date.getTime()
                    )
                    .map(order => {
                      const commodity = baseUtils.getDocFromCollection(commodities, order.commodityId);
                      const extendedCommodity = commodity
                        ? ExternalManufacturerHelper.getExtendedCommodity(commodity, context)
                        : null;
                      const lastUpdate = order.timeline[order.timeline.length - 1].date;
                      const forwardOrder = (e: React.MouseEvent<any>) => {
                        e.stopPropagation();
                        history.push("/order/" + order._id.toString());
                      };
                      return (
                        <tr
                          key={order._id.toString()}
                          className="kt-datatable__row table-hover d-table-row"
                          onClick={forwardOrder}
                        >
                          <td className="kt-datatable__cell d-table-cell">
                            <div className="kt-user-card-v2 ">
                              <div className="kt-user-card-v2__details">
                                <span className="kt-user-card-v2__name">
                                  <Link
                                    to={"/order/" + order._id.toString()}
                                    onClick={e => e.stopPropagation()}
                                    className="h6 kt-link kt-font-dark"
                                  >
                                    {request ? "REQ" : "EMO"}-{order.identifier}
                                  </Link>
                                </span>
                                <span className="kt-user-card-v2__email">{order.reference}</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            {extendedCommodity ? <EMCommodityWidget commodity={extendedCommodity} small={true} /> : "-"}
                          </td>
                          <td className="kt-datatable__cell d-table-cell">
                            <span>
                              <span
                                className={
                                  "kt-badge kt-badge--inline kt-badge--pill kt-font-bold " +
                                  ([EM_OPEN, EM_ACCEPTED].includes(order.state)
                                    ? "kt-badge--light"
                                    : order.state === EM_ORDERED
                                    ? "kt-badge--info"
                                    : [EM_CHECKED, EM_DELIVERED, EM_ARRIVED, EM_FINISHED].includes(order.state)
                                    ? "kt-badge--success"
                                    : "kt-badge--danger")
                                }
                              >
                                {_.upperFirst(order.state)}
                              </span>
                            </span>
                          </td>
                          <td className="kt-datatable__cell d-table-cell kt-font-bold">
                            <span>{order.amount}kg</span>
                          </td>
                          <td className="kt-datatable__cell d-table-cell">
                            <span
                              className={
                                order.actualPrice && order.state !== EM_OPEN
                                  ? "text-success kt-font-bold"
                                  : "text-muted"
                              }
                            >
                              {order.actualPrice && order.state !== EM_OPEN
                                ? baseUtils.formatEuro(order.actualPrice)
                                : "pending"}
                            </span>
                          </td>
                          <td className="kt-datatable__cell d-table-cell kt-font-bold">
                            <span>{baseUtils.formatDate(lastUpdate)}</span>
                            <span className={"kt-font-sm"}>{lastUpdate.toLocaleTimeString()}</span>
                          </td>
                          {request && (
                            <td className="kt-datatable__cell d-table-cell" onClick={e => e.stopPropagation()}>
                              <div className="btn-group">
                                <AcceptRequestModal order={order} iconBtn={true} />
                                <CancelEMOrderModal order={order} iconBtn={true} />
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default EmDashboardOrders;
