import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { differenceInCalendarWeeks } from "date-fns";
import CancelCommodityOrderModal from "./CancelCommodityOrderModal";
import { DataContext } from "../../../context/dataContext";
import {
  CommoditiesDocument,
  CommodityOrder,
  CommodityPrice,
  StockTransferOrder
} from "../../../model/commodities.types";
import { OrdersDocument } from "../../../model/orders.types";
import dbService, { COMMODITIES, EMORDERS, ORDERS, UpdateAction } from "../../../services/dbService";
import dbCommodityService from "../../../services/dbServices/dbCommodityService";
import userService from "../../../services/userService";
import accessUtils, { CREATELOCATIONS, EDITLOCATIONS } from "../../../utils/accessUtils";
import baseUtils from "../../../utils/baseUtils";
import commodityUtils, { checkOrdersForDifferentManufacturers } from "../../../utils/commodityUtils";
import dateUtils from "../../../utils/dateUtils";
import { I_CIFA, I_CIFS, I_FAS, I_FCA, I_FOBA, I_FOBS, INCOTERMS } from "../../../utils/suppliersUtils";
import { T_COMMODITYORDERED, T_COMMODITYORDEREDITED, T_COMMODITYORDERUNLINKED } from "../../../utils/timelineUtils";
import toastUtils from "../../../utils/toastUtils";
import orderUtils, {
  ETAChangeNotificationInformation,
  ORDERORDERCOMMODITIES,
  REQUESTAPPROVED,
  WAITING
} from "../../../utils/orderUtils";
import orderCalculationUtils from "../../../utils/orderCalculationUtils";
import { ExternalManufacturerOrdersDocument } from "../../../model/externalManufacturerOrders.types";
import {
  EM_ACCEPTED,
  EM_ORDERED,
  EM_ORDEREDITED,
  EM_ORDERUNLINKED
} from "../../externalManufacturers/ExternalManufacturerHelper";
import slackService from "../../../services/slackService";
import CommodityOrderSelect from "./commodityOrderCreationTabs/CommodityOrderSelect";
import CommodityOrderSettings from "./commodityOrderCreationTabs/CommodityOrderSettings";
import CommodityOrderDistribution from "./commodityOrderCreationTabs/CommodityOrderDistribution";
import { fetchRawbidsOrder, getDefaultRawbidsOrderSnapshot } from "../../../utils/rawbidsUtils";
import { getNotificationTargetsContext, NotificationTypeFilter } from "../../../utils/notificationUtils";
import { NotificationTypes } from "../../../model/configuration/notificationConfiguration.types";

interface CreateCommodityOrderModalProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
  commodityOrder?: CommodityOrder;
  order?: OrdersDocument;
}

interface CreateCommodityOrderModalState {
  ordersWithCommodity: Array<{ order: OrdersDocument; selected: boolean }>;
  relatedExternalOrders: Array<{ order: ExternalManufacturerOrdersDocument; selected: boolean }>;
  selectedManufacturer: string;
  availableIncoterms: { [key: string]: Array<string> };
  commodityOrder: CommodityOrder;
  show: boolean;
  showOffers: boolean;
  stage: "select" | "settings" | "distribution";
  saving: boolean;
  showCancel: boolean;
  orderQuantity: string;
  purchasePrice: string;
  totalPrice: string;
  removedOrders: Array<BSON.ObjectId>;
  removedExternalOrders: Array<BSON.ObjectId>;
  usageMap: { [key: string]: number };
  loadingRawbidsData: boolean;
}

class CreateCommodityOrderModal extends PureComponent<CreateCommodityOrderModalProps, CreateCommodityOrderModalState> {
  _isMounted = false;

  constructor(props: CreateCommodityOrderModalProps) {
    super(props);
    const edit = !!props.commodityOrder;
    this.state = {
      ordersWithCommodity: [],
      relatedExternalOrders: [],
      selectedManufacturer: "",
      availableIncoterms: {},
      commodityOrder: edit ? _.cloneDeep(props.commodityOrder!) : this.getDefaultCommodityOrder(props.commodity),
      show: !!props.order,
      showOffers: false,
      stage: edit ? "settings" : "select",
      saving: false,
      showCancel: false,
      orderQuantity: edit ? props.commodityOrder!.orderquantity.toString() : "0",
      purchasePrice: edit && props.commodityOrder!.purchasePrice ? props.commodityOrder!.purchasePrice.toString() : "0",
      totalPrice: edit ? props.commodityOrder!.totalPrice.toString() : "0",
      removedOrders: [],
      removedExternalOrders: [],
      usageMap: {},
      loadingRawbidsData: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.collectData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: Readonly<CreateCommodityOrderModalProps>) {
    if (this._isMounted) {
      if (
        !_.isEqual(prevProps.commodity, this.props.commodity) ||
        !_.isEqual(prevProps.commodityOrder, this.props.commodityOrder) ||
        !_.isEqual(prevProps.context.orders, this.props.context.orders) ||
        !_.isEqual(prevProps.context.externalManufacturerOrders, this.props.context.externalManufacturerOrders)
      ) {
        this.collectData();
      }
      // If an order was loaded after the constructor was passed (case when orders are still loading) we need to set
      // the show flag as soon as an order appears
      if (!prevProps.order && this.props.order) this.setState({ show: true });
    }
  }

  handleShow = () => {
    const edit = !!this.props.commodityOrder;
    this.setState({
      show: true,
      commodityOrder: edit
        ? _.cloneDeep(this.props.commodityOrder!)
        : this.getDefaultCommodityOrder(this.props.commodity),
      orderQuantity: edit ? this.props.commodityOrder!.orderquantity.toString() : "0",
      purchasePrice:
        edit && this.props.commodityOrder!.purchasePrice ? this.props.commodityOrder!.purchasePrice.toString() : "0",
      totalPrice: edit ? this.props.commodityOrder!.totalPrice.toString() : "0",
      loadingRawbidsData: false
    });
  };

  handleHide = () => {
    const { commodity, commodityOrder } = this.props;
    this.setState({
      show: false,
      showOffers: false,
      stage: commodityOrder ? "settings" : "select",
      commodityOrder: commodityOrder ? _.cloneDeep(commodityOrder) : this.getDefaultCommodityOrder(commodity),
      ordersWithCommodity: this.getResetOrders(),
      relatedExternalOrders: this.getResetExternalOrders(),
      saving: false,
      showCancel: false,
      selectedManufacturer: "",
      removedOrders: [],
      removedExternalOrders: []
    });
  };

  /**
   * Builds the default commodity order object
   * @param commodity
   */
  getDefaultCommodityOrder = (commodity: CommoditiesDocument) => {
    const firstSupplier = commodity.suppliers.length > 0 ? commodity.suppliers[0] : null;
    const firstPrice = firstSupplier && firstSupplier.prices.length > 0 ? firstSupplier.prices[0] : null;
    return {
      _id: new BSON.ObjectId(),
      created: new Date(),
      orderquantity: 0,
      supplier: firstSupplier ? firstSupplier._id : null,
      shelflife: 24,
      expiry: null,
      incoterm: firstPrice ? firstPrice.incoterm : INCOTERMS[0],
      destination: null,
      port_shipment: "",
      port_destination: "",
      notify: "asap logistics GmbH",
      deliverytime: firstPrice ? firstPrice.deliverytime : 7,
      carrier: "",
      packages: "",
      totalPrice: 0,
      purchasePrice: 0,
      currency: firstPrice ? firstPrice.purchaseCurrency : "EUR",
      person: userService.getUserId(),
      orderPDF: null,
      orders: [],
      delivered: null,
      ordered: null,
      notes: "",
      asap: false,
      warehouseDestination: this.props.context.general.find(g => g.data === "defaultWarehouse")?.value.toString()
    };
  };

  /**
   * Reset the selected flags on all orders that contain an open order for the commodity.
   * @returns { Array<{ order: OrdersDocument; selected: boolean }> } Reset orders
   */
  getResetOrders = (): Array<{ order: OrdersDocument; selected: boolean }> => {
    const ordersWithCommodity = _.cloneDeep(this.state.ordersWithCommodity);
    // Don't reset when editing
    if (!!this.props.commodityOrder) return ordersWithCommodity;
    ordersWithCommodity.forEach(o => (o.selected = false));
    return ordersWithCommodity;
  };

  /**
   * Reset the selected flags on all external orders that contain an open order for the commodity.
   * @returns { Array<{ order: ExternalManufacturerOrdersDocument; selected: boolean }> } Reset external orders
   */
  getResetExternalOrders = (): Array<{ order: ExternalManufacturerOrdersDocument; selected: boolean }> => {
    const relatedExternalOrders = _.cloneDeep(this.state.relatedExternalOrders);
    // Don't reset when editing
    if (!!this.props.commodityOrder) return relatedExternalOrders;
    relatedExternalOrders.forEach(o => (o.selected = false));
    return relatedExternalOrders;
  };

  /**
   * Collects all data that is needed for the modal. These are the incoterm <-> supplier map and the orders that
   * contain open commodity orders for the commodity.
   */
  collectData = () => {
    const { commodity, context, order, commodityOrder } = this.props;
    const { ordersWithCommodity: ordersWithCommodityState } = this.state;
    const { externalManufacturerOrders } = context;
    const availableIncoterms: { [key: string]: Array<string> } = {};
    commodity.suppliers.forEach(s =>
      s.prices.forEach(p => {
        const key = s._id.toString();
        if (!availableIncoterms[key]) availableIncoterms[key] = [p.incoterm];
        else !availableIncoterms[key].includes(p.incoterm) && availableIncoterms[key].push(p.incoterm);
      })
    );
    const ordersWithCommodity = [];
    for (let i = 0; i < context.orders.length; i++) {
      const orderDoc = context.orders[i];
      if (![REQUESTAPPROVED, ORDERORDERCOMMODITIES, WAITING].includes(orderDoc.state)) continue;
      if (orderDoc.calculations[0].prices.some(p => p._id.toString() === commodity._id.toString() && !p.ordered)) {
        ordersWithCommodity.push({
          order: orderDoc,
          selected:
            (!!order && order._id.toString() === orderDoc._id.toString()) ||
            ordersWithCommodityState.some(o => o.selected && o.order._id.toString() === orderDoc._id.toString()) // Check if order was already selected
        });
      }
    }
    if (!!commodityOrder) {
      // If a commodity order is being edited load list of related orders
      for (let i = 0; i < commodityOrder.orders.length; i++) {
        const cO = commodityOrder.orders[i];
        const orderDoc: OrdersDocument = baseUtils.getDocFromCollection(context.orders, cO);
        if (orderDoc) {
          const orderWithCommodity = ordersWithCommodity.find(
            owc => owc.order._id.toString() === orderDoc._id.toString()
          );
          // Set selected to true if it was already added
          if (orderWithCommodity) {
            orderWithCommodity.selected = true;
          } else if (orderDoc.calculations[0].prices.some(p => p._id.toString() === commodity._id.toString())) {
            // Otherwise, add the related order
            ordersWithCommodity.push({
              order: orderDoc,
              selected: true
            });
          }
        }
      }
    }
    const relatedExternalOrders = [];
    for (let i = 0; i < externalManufacturerOrders.length; i++) {
      const emo = externalManufacturerOrders[i];
      if (emo.commodityId.toString() === commodity._id.toString()) {
        // Edit
        if (!!commodityOrder && emo.state === EM_ORDERED) {
          relatedExternalOrders.push({
            order: emo,
            selected:
              !!commodityOrder &&
              !!commodityOrder.relatedEMOrders &&
              commodityOrder.relatedEMOrders.some(o => o.toString() === emo._id.toString())
          });
        } else if (!commodityOrder && emo.state === EM_ACCEPTED) {
          // Create
          relatedExternalOrders.push({
            order: emo,
            selected: false
          });
        }
      }
    }
    if (this._isMounted)
      this.setState({
        availableIncoterms,
        ordersWithCommodity,
        removedOrders: [],
        relatedExternalOrders
      });
  };

  /**
   * Handles changes of the commodity order.
   * @param e Event that triggered the change
   */
  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { availableIncoterms } = this.state;
    let orderQuantity = this.state.orderQuantity;
    let totalPrice = this.state.totalPrice;
    let purchasePrice = this.state.purchasePrice;
    const commodityOrder = _.cloneDeep(this.state.commodityOrder);
    const name = e.target.name;
    let value: string | number = e.target.value;
    // Handle numbers
    if (e.target.type === "number") {
      value = value.replaceAll(/^0+/g, "0");
      if (!value.includes(".")) value = Number(value).toString();
      if (+value >= 0) {
        if (name === "orderquantity") {
          orderQuantity = value;
          commodityOrder.orderquantity = +value;
        } else if (name === "purchasePrice") {
          purchasePrice = value;
          commodityOrder.purchasePrice = +value;
        } else if (name === "totalPrice") {
          totalPrice = value;
          commodityOrder.totalPrice = +value;
        }
      }
    } else {
      if (name.includes("rawbidsOrder") && !commodityOrder.rawbidsOrder) {
        commodityOrder.rawbidsOrder = { snapshot: getDefaultRawbidsOrderSnapshot(), lastUpdate: new Date(0) };
      }
      // Handle delivery time since user selects a date by "days until" are saved
      if (name === "deliverytime") {
        const date = value ? new Date(value) : new Date();
        commodityOrder.deliverytime = Math.ceil(dateUtils.getDaysBetween(date, commodityOrder.created));
      } else if (name === "expiry") {
        // @ts-ignore
        commodityOrder[name] = value ? new Date(value) : null;
      } else {
        _.set(commodityOrder, name, value);
      }
    }
    // If supplier is changed update the incoterm
    if (name === "supplier") commodityOrder.incoterm = availableIncoterms[value][0];
    // If supplier or incoterm are updated update the prices
    if (["supplier", "incoterm"].includes(name)) {
      const updatedPrices = this.updatePrices(commodityOrder);
      purchasePrice = updatedPrices.purchasePrice.toString();
      totalPrice = updatedPrices.totalPrice.toString();
    }
    this.setState({ commodityOrder, orderQuantity, purchasePrice, totalPrice }, () =>
      this.setState({ usageMap: this.getUsageMap(true) })
    );
  };

  /**
   * Handles blur of the order quantity. In this case the prices are automatically filled in.
   */
  handleBlurQuantity = () => {
    const commodityOrder = _.cloneDeep(this.state.commodityOrder);
    const updatedPrices = this.updatePrices(commodityOrder);
    this.setState({
      commodityOrder: updatedPrices,
      purchasePrice: updatedPrices.purchasePrice.toString(),
      totalPrice: updatedPrices.totalPrice.toString(),
      usageMap: this.getUsageMap(true)
    });
  };

  /**
   * Handles the removal of an order in order to disconnect the order and commodity order
   * @param order the order id
   * @param remove flag if order should be removed or readded
   */
  handleRelatedOrder = (order: BSON.ObjectId, remove?: boolean) => {
    const commodityOrder = _.cloneDeep(this.state.commodityOrder);
    let removedOrders = _.cloneDeep(this.state.removedOrders);
    const isInRemoved = removedOrders.some(o => o.toString() === order.toString());
    if (remove && !isInRemoved) {
      removedOrders.push(order);
    } else if (!remove && isInRemoved) {
      // Undo removal
      removedOrders = removedOrders.filter(o => o.toString() !== order.toString());
    }
    const { destination, warehouseDestination } = this.resolveDestinations(removedOrders);
    commodityOrder.destination = destination;
    commodityOrder.warehouseDestination = warehouseDestination;
    this.setState({ removedOrders, commodityOrder }, () => this.setState({ usageMap: this.getUsageMap(false) }));
  };

  /**
   * Handles the removal of an external order in order to disconnect the order and commodity order
   * @param order the external order id
   * @param remove flag if order should be removed or readded
   */
  handleRelatedExternalOrder = (order: BSON.ObjectId, remove?: boolean) => {
    let removedExternalOrders = _.cloneDeep(this.state.removedExternalOrders);
    const isInRemoved = removedExternalOrders.some(o => o.toString() === order.toString());
    if (remove && !isInRemoved) {
      removedExternalOrders.push(order);
    } else if (!remove && isInRemoved) {
      // Undo removal
      removedExternalOrders = removedExternalOrders.filter(o => o.toString() !== order.toString());
    }
    this.setState({ removedExternalOrders }, () => this.setState({ usageMap: this.getUsageMap(false) }));
  };

  handleClickLoadRawbidsOrderData = async () => {
    const { context, commodity } = this.props;
    const commodityOrder = _.cloneDeep(this.state.commodityOrder);
    this.setState({ loadingRawbidsData: true });
    try {
      if (
        commodity.orders.some(
          o =>
            o._id.toString() !== commodityOrder._id.toString() &&
            o.rawbidsOrder?.snapshot.orderNo === commodityOrder.rawbidsOrder?.snapshot.orderNo
        )
      ) {
        toast.error("Rawbids order is already connected to a commodity order.");
        return;
      }
      if (commodityOrder.rawbidsOrder?.snapshot.orderNo) {
        const res = await fetchRawbidsOrder(commodityOrder.rawbidsOrder.snapshot.orderNo);
        if (res.success) {
          if (res.data.commodity._id !== commodity.rawbidsData?.rawbidsId) {
            toast.error("Entered order contains a different commodity. Please check order and Rawbids commodity link.");
            return;
          }
          const { terms, destination, changedETA, targetDate, noteCustomer, totalPrice, amount, currency } = res.data;
          commodityOrder.rawbidsOrder = { snapshot: res.data, lastUpdate: new Date() };
          commodityOrder.incoterm = terms.deliveryTerms;
          commodityOrder.deliverytime = dateUtils.getDaysBetween(
            new Date(changedETA || targetDate),
            commodityOrder.created
          );
          commodityOrder.notes = noteCustomer;
          commodityOrder.orderquantity = amount;
          commodityOrder.purchasePrice = totalPrice;
          commodityOrder.currency = currency;
          // Assume that PLF orders are always in €
          commodityOrder.totalPrice = totalPrice;
          // Try to resolve the manufacturer
          const manufacturer = context.manufacturers.find(m => m.name.includes(destination.split("\n")[0]));
          if (manufacturer) {
            commodityOrder.destination = manufacturer._id;
          }
          this.setState({
            commodityOrder,
            orderQuantity: amount.toString(),
            purchasePrice: totalPrice.toString(),
            totalPrice: totalPrice.toString()
          });
        } else {
          toast.error(
            "Error requesting Rawbids order information. Please check that you entered the correct order number."
          );
        }
      }
    } catch (e) {
      console.error("ERROR:", e);
      toast.error("Error requesting Rawbids order information. Please try again.");
    } finally {
      this.setState({ loadingRawbidsData: false });
    }
  };

  handleUpdateUsageMap = (m: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const { relatedExternalOrders } = this.state;
    const usageMap = _.clone(this.state.usageMap);
    const commodityOrder = _.cloneDeep(this.state.commodityOrder);
    let value = e.target.value;
    value = value.replaceAll(/^0+/g, "0");
    if (!value.includes(".")) value = Number(value).toString();
    usageMap[m] = Number(value);
    let amountAllocated = 0;
    for (let key in usageMap) {
      if (key !== commodityOrder.warehouseDestination!.toString()) amountAllocated += usageMap[key];
    }
    usageMap[commodityOrder.warehouseDestination!.toString()] =
      commodityOrder.orderquantity -
      amountAllocated -
      relatedExternalOrders.reduce((sum, emo) => sum + (emo.selected ? emo.order.amount : 0), 0);
    this.setState({ usageMap, commodityOrder });
  };

  /**
   * Handles ordering a commodity.
   */
  handleOrder = async () => {
    const { commodity, context, commodityOrder: commodityOrderEdited } = this.props;
    const { ordersWithCommodity, removedOrders, relatedExternalOrders, removedExternalOrders, usageMap } = this.state;
    const commodityOrder = _.cloneDeep(this.state.commodityOrder);
    const etaChangeNotificationTargets = getNotificationTargetsContext(
      NotificationTypes.ORDER_TW_CONFLICT,
      context,
      NotificationTypeFilter.SLACK
    );
    this.setState({ saving: true });
    const withWarehouse = !!commodityOrder.warehouseDestination;
    let success;
    const edit = !!commodityOrderEdited;
    commodityOrder.destination = new BSON.ObjectId(commodityOrder.destination!.toString());
    if (removedOrders && removedOrders.length > 0) {
      // Filter out removed orders
      commodityOrder.orders = commodityOrder.orders.filter(
        o => !removedOrders.some(rO => rO.toString() === o.toString())
      );
    }
    if (removedExternalOrders && removedExternalOrders.length > 0 && commodityOrder.relatedEMOrders) {
      // Filter out removed external orders
      commodityOrder.relatedEMOrders = commodityOrder.relatedEMOrders.filter(
        o => !removedExternalOrders.some(rO => rO.toString() === o.toString())
      );
    }
    const hasOrders = commodity.orders && commodity.orders.length > 0;
    const relevantOrders = ordersWithCommodity.filter(
      o => o.selected && !removedOrders.some(order => order.toString() === o.order._id.toString())
    );
    if (!edit) {
      commodityOrder.created = new Date();
      if (
        relevantOrders.length > 0 ||
        relatedExternalOrders.some(
          o => o.selected && !removedExternalOrders.some(order => order.toString() === o.order._id.toString())
        )
      ) {
        const stockTransferOrders: Array<StockTransferOrder> = [];
        if (commodityOrder.warehouseDestination) {
          for (let i = 0; i < relevantOrders.length; i++) {
            const o = relevantOrders[i];
            const man = o.order.settings.manufacturer.toString();
            const idx = stockTransferOrders.findIndex(sTO => sTO.destination.toString() === man);
            if (idx === -1) {
              stockTransferOrders.push({
                _id: new BSON.ObjectId(),
                orderIds: [o.order._id],
                amount: usageMap[man]!,
                destination: man,
                personDelivered: null,
                delivered: null
              });
            } else {
              stockTransferOrders[idx].orderIds = stockTransferOrders[idx].orderIds.concat(o.order._id);
            }
          }
        }
        commodityOrder.stockTransferOrders = stockTransferOrders;
        const timelineUpdate = commodityUtils.prepareCommodityOrderTimeline(commodityOrderEdited, commodityOrder);
        let actions: Array<UpdateAction> = [
          {
            collection: COMMODITIES,
            filter: { _id: commodity._id },
            update: hasOrders ? {} : { orders: [commodityOrder] },
            push: hasOrders
              ? {
                  orders: commodityOrder,
                  timeline: timelineUpdate
                }
              : { timeline: timelineUpdate }
          }
        ];
        const { actions: orderUpdateActions, notificationInformation } = this.getOrderUpdateActions(
          commodity,
          commodityOrder,
          ordersWithCommodity,
          withWarehouse,
          removedOrders
        );
        actions = actions.concat(orderUpdateActions);
        actions = actions.concat(
          this.getExternalOrderUpdateActions(commodityOrder, relatedExternalOrders, removedExternalOrders)
        );
        if (commodityOrder.orders.length !== orderUpdateActions.length) {
          await slackService.sendMessage(
            "#interne-fehlermeldungen",
            `Orders will not be updated for commodity order. \n Commodity Order: ${JSON.stringify(
              commodityOrder
            )}; \n Removed Orders: ${JSON.stringify(removedOrders)}; \n Actions: ${JSON.stringify(
              orderUpdateActions
            )}; \n Orders with Commodity: ${JSON.stringify(
              ordersWithCommodity.map(owc => {
                return { identifier: owc.order.identifier, selected: owc.selected };
              })
            )} `
          );
          toast.error("An unexpected error occurred. Please try again later.");
          return;
        }
        success = await dbService.updatesAsTransaction(actions);
        if (success) orderUtils.sendETAChangeMessages(notificationInformation, true, etaChangeNotificationTargets);
      } else {
        const timelineUpdate = commodityUtils.prepareCommodityOrderTimeline(commodityOrderEdited, commodityOrder);
        const res = await dbCommodityService.addCommodityOrder(
          commodity._id,
          commodityOrder,
          timelineUpdate!,
          !!commodity.orders
        );
        success = !!res && res.modifiedCount > 0;
      }
    } else {
      if (!withWarehouse) {
        commodityOrder.stockTransferOrders = [];
      } else if (!commodityOrderEdited?.warehouseDestination && commodityOrder.warehouseDestination) {
        if (
          relevantOrders.length > 0 ||
          relatedExternalOrders.some(
            o => o.selected && !removedExternalOrders.some(order => order.toString() === o.order._id.toString())
          )
        ) {
          const stockTransferOrders: Array<StockTransferOrder> = [];
          if (commodityOrder.warehouseDestination) {
            for (let i = 0; i < relevantOrders.length; i++) {
              const o = relevantOrders[i];
              const man = o.order.settings.manufacturer.toString();
              const idx = stockTransferOrders.findIndex(sTO => sTO.destination.toString() === man);
              if (idx === -1) {
                stockTransferOrders.push({
                  _id: new BSON.ObjectId(),
                  orderIds: [o.order._id],
                  amount: usageMap[man]!,
                  destination: man,
                  personDelivered: null,
                  delivered: null
                });
              } else {
                stockTransferOrders[idx].orderIds = stockTransferOrders[idx].orderIds.concat(o.order._id);
              }
            }
          }
          commodityOrder.stockTransferOrders = stockTransferOrders;
        }
      } else if (commodityOrder.stockTransferOrders) {
        const rOIDs = removedOrders.map(rO => rO.toString());
        for (let i = 0; i < commodityOrder.stockTransferOrders.length; i++) {
          const sto = commodityOrder.stockTransferOrders[i];
          sto.orderIds = sto.orderIds.filter(o => !rOIDs.includes(o.toString()));
          sto.amount = usageMap[sto.destination.toString()];
        }
        commodityOrder.stockTransferOrders = commodityOrder.stockTransferOrders.filter(sto => sto.orderIds.length);
      }
      const timelineUpdate = commodityUtils.prepareCommodityOrderTimeline(commodityOrderEdited, commodityOrder);
      let actions: Array<UpdateAction> = [
        {
          collection: COMMODITIES,
          filter: { _id: commodity._id, "orders._id": commodityOrder._id },
          update: { "orders.$": commodityOrder },
          push: timelineUpdate ? { timeline: timelineUpdate } : {}
        }
      ];
      const { actions: orderUpdateActions, notificationInformation } = this.getOrderUpdateActions(
        commodity,
        commodityOrder,
        ordersWithCommodity,
        withWarehouse,
        removedOrders
      );
      actions = actions.concat(orderUpdateActions);
      actions = actions.concat(
        this.getExternalOrderUpdateActions(commodityOrder, relatedExternalOrders, removedExternalOrders)
      );
      success = await dbService.updatesAsTransaction(actions);
      if (success) orderUtils.sendETAChangeMessages(notificationInformation, true, etaChangeNotificationTargets);
    }
    await toastUtils.databaseOperationToast(
      success,
      "Commodity successfully ordered",
      "Error ordering commodity",
      () => {
        context.updateDocumentInContext(COMMODITIES, commodity._id);
        ordersWithCommodity.forEach(o => {
          if (o.selected && !removedOrders.some(o2 => o2.toString() === o.order._id.toString()))
            context.updateDocumentInContext(ORDERS, o.order._id);
        });
        relatedExternalOrders.forEach(o => {
          if (o.selected && !removedExternalOrders.some(o2 => o2.toString() === o.order._id.toString()))
            context.updateDocumentInContext(EMORDERS, o.order._id);
        });
      }
    );
    this.handleHide();
  };

  /**
   * Handles changing the selected manufacturer for which the open orders are filtered. Also resets the selected flags.
   * @param e Change that triggered the event
   */
  handleChangeSelectedManufacturer = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedManufacturer: e.target.value, ordersWithCommodity: this.getResetOrders() });
  };

  /**
   * Handles clicking the checkbox to check all orders. Only checks the orders that are visible to the user.
   */
  handleCheckAllOrders = () => {
    const { selectedManufacturer } = this.state;
    const ordersWithCommodity = _.cloneDeep(this.state.ordersWithCommodity);
    // Check if all are checked or not
    const all = ordersWithCommodity.some(
      o =>
        orderUtils.isOrder(o.order) &&
        (!selectedManufacturer || o.order.settings.manufacturer.toString() === selectedManufacturer) &&
        !o.selected
    );
    // Set the flags
    ordersWithCommodity.forEach(o => {
      if (
        orderUtils.isOrder(o.order) &&
        (!selectedManufacturer || o.order.settings.manufacturer.toString() === selectedManufacturer)
      )
        o.selected = all;
    });
    this.setState({ ordersWithCommodity });
  };

  /**
   * Handles clicking the checkbox to check all orders. Only checks the orders that are visible to the user.
   */
  handleCheckAllExternalOrders = () => {
    const relatedExternalOrders = _.cloneDeep(this.state.relatedExternalOrders);
    // Check if all are checked or not
    const all = relatedExternalOrders.some(o => !o.selected);
    // Set the flags
    relatedExternalOrders.forEach(o => {
      o.selected = all;
    });
    this.setState({ relatedExternalOrders });
  };

  /**
   * Handles checking a single order.
   * @param _id ID of the order
   */
  handleCheckOrder = (_id: BSON.ObjectId) => {
    const ordersWithCommodity = _.cloneDeep(this.state.ordersWithCommodity);
    for (let i = 0; i < ordersWithCommodity.length; i++) {
      const o = ordersWithCommodity[i];
      if (o.order._id.toString() === _id.toString()) {
        o.selected = !o.selected;
        // We will not find another match
        break;
      }
    }
    this.setState({ ordersWithCommodity });
  };

  /**
   * Handles checking a single external order.
   * @param _id ID of the external order
   */
  handleCheckExternalOrder = (_id: BSON.ObjectId) => {
    const relatedExternalOrders = _.cloneDeep(this.state.relatedExternalOrders);
    for (let i = 0; i < relatedExternalOrders.length; i++) {
      const o = relatedExternalOrders[i];
      if (o.order._id.toString() === _id.toString()) {
        o.selected = !o.selected;
        // We will not find another match
        break;
      }
    }
    this.setState({ relatedExternalOrders });
  };

  /**
   * Handle clicking the next button. Calculates the required commodity amount in relation of the selected orders.
   */
  handleClickNext = () => {
    const { ordersWithCommodity, relatedExternalOrders } = this.state;
    const commodityOrder = _.cloneDeep(this.state.commodityOrder);
    const selectedOrders = ordersWithCommodity.filter(o => o.selected);
    const selectedExternalOrders = relatedExternalOrders.filter(o => o.selected);
    const { destination, warehouseDestination } = this.resolveDestinations();
    if (selectedOrders.length > 0) {
      commodityOrder.orders = selectedOrders.map(so => so.order._id);
    } else {
      commodityOrder.orders = [];
    }
    commodityOrder.destination = destination;
    commodityOrder.warehouseDestination = warehouseDestination;
    if (selectedExternalOrders.length > 0) {
      commodityOrder.relatedEMOrders = selectedExternalOrders.map(seo => seo.order._id);
    }
    this.setState({ stage: "settings", commodityOrder, usageMap: this.getUsageMap(true) });
  };

  handlePrepareDistribution = () => {
    this.setState({ stage: "distribution", usageMap: this.getUsageMap(true) });
  };

  getUsageMap = (preserveExisting: boolean) => {
    const { commodity, commodityOrder: pCO } = this.props;
    const { commodityOrder, relatedExternalOrders, removedOrders, removedExternalOrders } = this.state;
    const roStr = removedOrders.map(rO => rO.toString());
    // Only check orders that are relevant
    const orders = this.state.ordersWithCommodity.filter(o => o.selected && !roStr.includes(o.order._id.toString()));
    const usageMap: { [key: string]: number } = {};
    if (!commodityOrder.warehouseDestination) return usageMap;
    let amountAllocated = 0;
    if (pCO && preserveExisting && pCO.stockTransferOrders) {
      // Builds the usage map from the existing STOs while filtering out STOs with no orders
      const rOIDs = removedOrders.map(rO => rO.toString());
      for (let i = 0; i < pCO.stockTransferOrders.length; i++) {
        const sto = pCO.stockTransferOrders[i];
        sto.orderIds = sto.orderIds.filter(oid => !rOIDs.includes(oid.toString()));
        if (sto.orderIds.length === 0) continue;
        usageMap[sto.destination.toString()] = sto.amount;
        amountAllocated += sto.amount;
      }
      // Ensure that every manufacturer that is related is also set in usage map
      const orderManufacturers = orders.map(o => o.order.settings.manufacturer.toString());
      for (let i = 0; i < orderManufacturers.length; i++) {
        const m = orderManufacturers[i];
        if (!(m in usageMap)) usageMap[m] = 0;
      }
    } else {
      for (let i = 0; i < orders.length; i++) {
        const o = orders[i].order;
        const man = o.settings.manufacturer.toString();
        // Retrieve how much of the commodity is needed
        const usage = commodityUtils.calculateUsage(
          o,
          o.calculations[0].prices.find(p => p._id.toString() === commodity._id.toString())!,
          commodity.type,
          false
        ).raw;
        // If the location is not inside the usageMap create it - else add it
        if (man in usageMap) {
          usageMap[man] += usage;
        } else {
          usageMap[man] = usage;
        }
        // Also save the amount we allocated
        amountAllocated += usage;
      }
    }
    // Calculate the remaining amount after EMOs and STOs are handled
    const emoStr = removedExternalOrders.map(rEMO => rEMO.toString());
    const remainingAmount =
      commodityOrder.orderquantity -
      amountAllocated -
      relatedExternalOrders.reduce(
        (sum, emo) => sum + (emo.selected && !emoStr.includes(emo.order._id.toString()) ? emo.order.amount : 0),
        0
      );
    // The remaining amount has to be "used" at warehouse
    const wD = commodityOrder.warehouseDestination.toString();
    if (wD in usageMap) {
      usageMap[wD] += remainingAmount;
    } else {
      usageMap[wD] = remainingAmount;
    }
    return usageMap;
  };

  /**
   * Resolves the destinations (normal and warehouse) of the commodity order
   * @param removedOrders Orders that are removed from the commodity order
   * @returns { { destination: string | BSON.ObjectId | null, warehouseDestination: undefined | null | string | BSON.ObjectId } } The destination and the warehouse destination
   */
  resolveDestinations = (
    removedOrders?: Array<BSON.ObjectId>
  ): {
    destination: string | BSON.ObjectId | null;
    warehouseDestination: undefined | null | string | BSON.ObjectId;
  } => {
    const { general } = this.props.context;
    const { ordersWithCommodity, commodityOrder } = this.state;
    const rOString = (removedOrders ?? this.state.removedOrders).map(rO => rO.toString());
    const selectedOrders = ordersWithCommodity.filter(o => o.selected && !rOString.includes(o.order._id.toString()));
    // Check if the relevant orders contain different manufacturers
    const differentManufacturers = checkOrdersForDifferentManufacturers(selectedOrders.map(o => o.order));
    const defaultWarehouse = general.find(g => g.data === "defaultWarehouse")?.value.toString();
    // Start with the destinations that are currently set in order
    let destination = commodityOrder.destination;
    let warehouseDestination = commodityOrder.warehouseDestination;
    if (selectedOrders.length > 0) {
      // If multiple orders are selected we need to check if they are at the same location or not
      destination =
        differentManufacturers && defaultWarehouse
          ? new BSON.ObjectId(defaultWarehouse)
          : selectedOrders[0].order.settings.manufacturer;
      // If destination and warehouse destination are the same and the default warehouse without having multiple manufacturers we don't need the warehouse destination which would imply STOs
      if (
        !differentManufacturers &&
        warehouseDestination &&
        warehouseDestination.toString() === destination?.toString() &&
        warehouseDestination.toString() === defaultWarehouse
      )
        warehouseDestination = null;
      // If manufacturers differ we need a warehouse in between
      else if (differentManufacturers) warehouseDestination = new BSON.ObjectId(defaultWarehouse);
    } else {
      // If no orders are selected we don't need the warehouse
      warehouseDestination = this.props.commodityOrder ? this.props.commodityOrder.warehouseDestination : undefined;
    }
    return { destination, warehouseDestination };
  };

  /**
   * Get actions to update orders
   * @param commodityOrder the commodity order
   * @param relatedExternalOrders list of external orders related to the commodity order
   * @param removedExternalOrders list of external orders that should not be added
   * @returns {Array<UpdateAction>} list of actions to update orders
   */
  getExternalOrderUpdateActions = (
    commodityOrder: CommodityOrder,
    relatedExternalOrders: Array<{ order: ExternalManufacturerOrdersDocument; selected: boolean }>,
    removedExternalOrders?: Array<BSON.ObjectId>
  ): Array<UpdateAction> => {
    const { commodityOrder: commodityOrderEdited } = this.props;
    const edit = !!commodityOrderEdited;
    let actions: Array<UpdateAction> = [];
    relatedExternalOrders
      .filter(o => o.selected)
      .forEach(o => {
        if (
          !edit &&
          removedExternalOrders &&
          removedExternalOrders.some(o2 => o2.toString() === o.order._id.toString())
        )
          return;
        if (
          edit &&
          removedExternalOrders &&
          removedExternalOrders.some(o2 => o2.toString() === o.order._id.toString())
        ) {
          // Handle edited commodity order with removed related order, Reset order related fields
          actions.push({
            collection: EMORDERS,
            filter: { _id: o.order._id },
            update: {
              state: EM_ACCEPTED,
              actualDeliveryDate: null
            },
            push: {
              timeline: {
                _id: new BSON.ObjectId(),
                type: EM_ORDERUNLINKED,
                date: new Date(),
                person: userService.getUserId()
              }
            }
          });
        } else {
          let changes: any = {};
          const deliveryDate = commodityUtils.resolveDeliveryDate(commodityOrder);
          if (commodityOrderEdited) {
            if (!_.isEqual(deliveryDate, o.order.actualDeliveryDate)) {
              changes["actualDeliveryDate"] = { old: o.order.actualDeliveryDate, new: deliveryDate };
            }
          }
          // No update needed if no relevant changes are made
          if (_.isEmpty(changes) && commodityOrderEdited) return;
          actions.push({
            collection: EMORDERS,
            filter: { _id: o.order._id },
            update: {
              state: EM_ORDERED,
              actualDeliveryDate: deliveryDate
            },
            push: {
              timeline: commodityOrderEdited
                ? {
                    _id: new BSON.ObjectId(),
                    type: EM_ORDEREDITED,
                    date: new Date(),
                    person: userService.getUserId(),
                    changes
                  }
                : {
                    _id: new BSON.ObjectId(),
                    type: EM_ORDERED,
                    date: new Date(),
                    person: userService.getUserId()
                  }
            }
          });
        }
      });
    return actions;
  };

  /**
   * Get actions to update orders
   * @param commodity the commodities document
   * @param commodityOrder the commodity order
   * @param ordersWithCommodity list of orders related to the commodity order
   * @param viaWarehouse Flag if the order passes the warehouse or not
   * @param removedOrders list of orders that should not be added
   * @returns {Array<UpdateAction>} list of actions to update orders
   */
  getOrderUpdateActions = (
    commodity: CommoditiesDocument,
    commodityOrder: CommodityOrder,
    ordersWithCommodity: Array<{ order: OrdersDocument; selected: boolean }>,
    viaWarehouse?: boolean,
    removedOrders?: Array<BSON.ObjectId>
  ): {
    actions: Array<UpdateAction>;
    notificationInformation: Array<ETAChangeNotificationInformation>;
  } => {
    const { commodityOrder: commodityOrderEdited } = this.props;
    const notificationInformation: Array<ETAChangeNotificationInformation> = [];
    const edit = !!commodityOrderEdited;
    let actions: Array<UpdateAction> = [];
    ordersWithCommodity
      .filter(o => o.selected)
      .forEach(o => {
        // When creating skip if order was removed later on
        if (!edit && removedOrders && removedOrders.some(o2 => o2.toString() === o.order._id.toString())) return;
        if (edit && removedOrders && removedOrders.some(o2 => o2.toString() === o.order._id.toString())) {
          // Handle edited commodity order with removed related order, Reset order related fields
          actions.push({
            collection: ORDERS,
            filter: { _id: o.order._id },
            update: {
              "calculations.0.prices.$[c].ordered": null,
              "calculations.0.prices.$[c].userOrdered": null,
              "calculations.0.prices.$[c].delivered": null,
              "calculations.0.prices.$[c].userDelivered": null,
              "calculations.0.prices.$[c].eta": null,
              "calculations.0.prices.$[c].viaWarehouse": false,
              "calculations.0.prices.$[c].arrivedAtWarehouse": null
            },
            push: {
              timeline: {
                _id: new BSON.ObjectId(),
                type: T_COMMODITYORDERUNLINKED,
                date: new Date(),
                commodity: commodity._id,
                person: userService.getUserId()
              }
            },
            arrayFilters: [{ "c._id": commodity._id }]
          });
        } else {
          const pricePre = o.order.calculations[0].prices.find(p => p._id.toString() === commodity._id.toString())!;
          const pricePost = _.cloneDeep(pricePre);
          pricePost.supplier = new BSON.ObjectId(commodityOrder.supplier!.toString());
          pricePost.price = commodityOrder.totalPrice / commodityOrder.orderquantity;
          pricePost.totalprice = pricePost.price * pricePre.orderquantity!;
          pricePost.purchasePrice = commodityOrder.purchasePrice / commodityOrder.orderquantity;
          pricePost.purchaseCurrency = commodityOrder.currency;
          // Recalculate price information
          const newInfo = orderCalculationUtils.getCalculationInfoAfterPriceChange(o.order, pricePre, pricePost);
          const newEta = commodityUtils.resolveDeliveryDate(commodityOrder);
          actions.push({
            collection: ORDERS,
            filter: { _id: o.order._id },
            update: {
              "calculations.0.prices.$[c].supplier": new BSON.ObjectId(commodityOrder.supplier!.toString()),
              "calculations.0.prices.$[c].orderquantity": commodityOrder.orderquantity,
              "calculations.0.prices.$[c].price": commodityOrder.totalPrice / commodityOrder.orderquantity,
              "calculations.0.prices.$[c].totalprice": commodityOrder.totalPrice,
              "calculations.0.prices.$[c].purchasePrice": commodityOrder.purchasePrice / commodityOrder.orderquantity,
              "calculations.0.prices.$[c].purchaseCurrency": commodityOrder.currency,
              "calculations.0.prices.$[c].incoterm": commodityOrder.incoterm,
              "calculations.0.prices.$[c].ordered": commodityOrderEdited
                ? commodityOrderEdited.created
                : commodityOrder.created,
              "calculations.0.prices.$[c].userOrdered": userService.getUserId(),
              "calculations.0.prices.$[c].deliverytime": commodityOrderEdited
                ? commodityOrderEdited.deliverytime
                : commodityOrder.deliverytime,
              "calculations.0.prices.$[c].viaWarehouse": viaWarehouse,
              "calculations.0.prices.$[c].delivered": null,
              "calculations.0.prices.$[c].userDelivered": null,
              "calculations.0.prices.$[c].eta": newEta,
              "calculations.0.info": newInfo
            },
            push: {
              timeline: {
                _id: new BSON.ObjectId(),
                type: commodityOrderEdited ? T_COMMODITYORDEREDITED : T_COMMODITYORDERED,
                date: new Date(),
                amount: commodityOrder.orderquantity,
                commodity: commodity._id,
                supplier: commodityOrder.supplier,
                person: userService.getUserId(),
                price: commodityOrder.totalPrice
              }
            },
            arrayFilters: [{ "c._id": commodity._id }]
          });

          // Check for target date conflict if target date of order is set
          if (o.order.targetDate) {
            // weekStartsOn 1 indicates that first day of week is monday instead of sunday
            const targetWeekDiff = differenceInCalendarWeeks(newEta, o.order.targetDate, { weekStartsOn: 1 });
            // If diff >= -1 then newEta target week is a week before, the same or a later week
            if (targetWeekDiff >= -1) {
              notificationInformation.push({
                materialName: commodity.title.en,
                materialId: commodity._id.toString(),
                materialAmountDescription: commodityUtils.resolveStockUnit(
                  commodityOrder.orderquantity,
                  commodity.type
                ),
                orderIdentifier: o.order.identifier.toString(),
                orderId: o.order._id.toString(),
                orderTargetDate: o.order.targetDate,
                materialOrderTargetDate: newEta
              });
            }
          }
        }
      });
    return { actions, notificationInformation };
  };

  /**
   * Resolves the best price for the commodity in relation to the specified options. The first price is returned in
   * case of no matches.
   * @param commodityOrder Commodity order that contains options like incoterm and order quantity
   * @returns { CommodityPrice } Price that matches the options or first available if none matched
   */
  resolveMatchingPrice = (commodityOrder: CommodityOrder) => {
    const { commodity } = this.props;
    if (!commodityOrder.supplier) return;
    const sup = commodity.suppliers.find(s => commodityOrder.supplier!.toString() === s._id.toString());
    if (!sup || sup.prices.length === 0) return;
    let price: CommodityPrice | undefined;
    for (let i = 0; i < sup.prices.length; i++) {
      const p = sup.prices[i];
      if (p.incoterm === commodityOrder.incoterm && p.moq <= commodityOrder.orderquantity) {
        if (!price || price.price > p.price) price = p;
      }
    }
    if (!price) return sup.prices[0];
    return price;
  };

  /**
   * Updates the total price, purchase price and currency if there was an update to order quantity, supplier
   * or incoterm.
   * @param commodityOrder Commodity order
   * @returns { CommodityOrder } Updated commodity order
   */
  updatePrices = (commodityOrder: CommodityOrder) => {
    const { orderQuantity } = this.state;
    const price = this.resolveMatchingPrice(commodityOrder);
    if (!price) return commodityOrder;
    commodityOrder.totalPrice = price.price * +orderQuantity;
    commodityOrder.purchasePrice = price.purchasePrice ? price.purchasePrice * +orderQuantity : 0;
    commodityOrder.currency = price.purchaseCurrency;
    return commodityOrder;
  };

  /**
   * Check the input values for errors.
   * @returns { Array<string> } All errors that were found
   */
  checkForErrors = () => {
    const { commodityOrder, usageMap, stage } = this.state;
    const errors = [];
    if (commodityOrder.orderquantity <= 0) errors.push("Order quantity has to be above 0");
    if (!commodityOrder.supplier) errors.push("Supplier has to be set");
    if (commodityOrder.expiry && commodityOrder.expiry.getTime() <= new Date().getTime())
      errors.push("Expiry has to be in the future");
    if (!commodityOrder.incoterm) errors.push("Incoterm has to be set");
    if (!commodityOrder.destination) errors.push("Destination has to be set");
    if ([I_FCA, I_FAS, I_FOBA, I_FOBS, I_CIFA, I_CIFS].includes(commodityOrder.incoterm) && !commodityOrder.notify)
      errors.push("Notify has to be set");
    if (commodityOrder.totalPrice <= 0) errors.push("Total price has to be above 0");
    if (commodityOrder.purchasePrice <= 0) errors.push("Purchase price has to be above 0");
    if (!commodityOrder.currency) errors.push("Currency for purchase price has to be set");
    if (commodityOrder.stockTransferOrders && commodityOrder.stockTransferOrders.some(sto => sto.amount < 0))
      errors.push("Can't distribute a negative amount");
    if (
      stage === "distribution" &&
      commodityOrder.warehouseDestination &&
      usageMap[commodityOrder.warehouseDestination.toString()] < 0
    )
      errors.push("Warehouse can't contain a negative amount");
    return errors;
  };

  render() {
    const { commodity, context } = this.props;
    const {
      commodityOrder,
      saving,
      show,
      showCancel,
      stage,
      ordersWithCommodity,
      selectedManufacturer,
      relatedExternalOrders,
      availableIncoterms,
      removedOrders,
      removedExternalOrders,
      orderQuantity,
      purchasePrice,
      totalPrice,
      usageMap,
      loadingRawbidsData
    } = this.state;
    const errors = this.checkForErrors();
    const edit = !!this.props.commodityOrder;
    const canCreate = edit
      ? accessUtils.canEditData(EDITLOCATIONS.COMMODITYORDER)
      : accessUtils.canCreateData(CREATELOCATIONS.COMMODITYORDER);
    const warehouseRequired =
      checkOrdersForDifferentManufacturers(
        ordersWithCommodity.filter(o => o.selected).map(o => o.order),
        removedOrders.map(rO => rO.toString())
      ) || commodityOrder.warehouseDestination;

    const isApproved = commodityUtils.isCommodityApproved(commodity);
    return (
      <>
        <button
          className={"btn btn-secondary" + (edit ? " mr-1" : "") + (canCreate && isApproved ? "" : " disabled")}
          onClick={canCreate && isApproved ? this.handleShow : undefined}
          disabled={!canCreate || !isApproved}
        >
          {edit ? "Edit" : "New Order"}
        </button>
        <Modal show={show && !showCancel} onHide={this.handleHide} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {edit ? "Edit Order of" : "Order"} {commodity.title.en}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="overflow-auto" style={{ maxHeight: "80vh" }}>
            {stage === "select" && (
              <CommodityOrderSelect
                commodity={commodity}
                context={context}
                orders={ordersWithCommodity}
                relatedExternalOrders={relatedExternalOrders}
                selectedManufacturer={selectedManufacturer}
                onChangeSelectedManufacturer={this.handleChangeSelectedManufacturer}
                onCheckAllExternalOrders={this.handleCheckAllExternalOrders}
                onCheckExternalOrder={this.handleCheckExternalOrder}
                onCheckAllOrder={this.handleCheckAllOrders}
                onCheckOrder={this.handleCheckOrder}
              />
            )}
            {stage === "settings" && (
              <CommodityOrderSettings
                commodity={commodity}
                context={context}
                commodityOrder={commodityOrder}
                availableIncoterms={availableIncoterms}
                edit={!!this.props.commodityOrder}
                orders={ordersWithCommodity}
                relatedExternalOrders={relatedExternalOrders}
                removedOrders={removedOrders}
                removedExternalOrders={removedExternalOrders}
                orderQuantity={orderQuantity}
                purchasePrice={purchasePrice}
                totalPrice={totalPrice}
                loadingRawbidsData={loadingRawbidsData}
                resolveMatchingPrice={this.resolveMatchingPrice}
                onChange={this.handleChange}
                onBlurQuantity={this.handleBlurQuantity}
                onRelatedOrder={this.handleRelatedOrder}
                onRelatedExternalOrder={this.handleRelatedExternalOrder}
                onShowCancel={() => this.setState({ showCancel: true })}
                onClickLoadRawbidsOrderData={this.handleClickLoadRawbidsOrderData}
              />
            )}
            {stage === "distribution" && (
              <CommodityOrderDistribution
                commodity={commodity}
                context={context}
                commodityOrder={commodityOrder}
                relatedOrders={ordersWithCommodity.filter(o => o.selected).map(o => o.order)}
                relatedExternalOrders={relatedExternalOrders.filter(o => o.selected).map(o => o.order)}
                removedOrders={removedOrders}
                removedExternalOrders={removedExternalOrders}
                onUpdateUsageMap={this.handleUpdateUsageMap}
                usageMap={usageMap}
                warehouseDestination={commodityOrder.warehouseDestination!.toString()}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            {((["settings", "distribution"].includes(stage) && !edit) ||
              (edit && warehouseRequired && stage === "distribution")) && (
              <button
                className="btn btn-secondary"
                onClick={() => this.setState({ stage: stage === "distribution" ? "settings" : "select" })}
              >
                Back
              </button>
            )}
            {stage === "select" ? (
              <button className="btn btn-success" onClick={this.handleClickNext}>
                Next
              </button>
            ) : errors.length > 0 ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id={"commodity-invalid"}>
                    {errors.map((e, key) => {
                      return (
                        <React.Fragment key={key}>
                          <span className="text-danger">
                            <b>{e}</b>
                          </span>
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </Tooltip>
                }
                placement={"left"}
              >
                <button className="btn btn-success disabled">
                  {edit && !warehouseRequired ? (
                    "Save changes"
                  ) : warehouseRequired && stage !== "distribution" ? (
                    "Next"
                  ) : (
                    <>
                      <i className="fa fa-check" />
                      Order
                    </>
                  )}
                </button>
              </OverlayTrigger>
            ) : warehouseRequired && stage !== "distribution" ? (
              <button className="btn btn-success" onClick={this.handlePrepareDistribution}>
                Next
              </button>
            ) : (
              <button
                className={"btn btn-success" + (saving ? " disabled" : "")}
                onClick={this.handleOrder}
                disabled={saving}
              >
                {edit ? (
                  "Save changes"
                ) : (
                  <>
                    <i className="fa fa-check" />
                    Order
                  </>
                )}
              </button>
            )}
          </Modal.Footer>
        </Modal>
        <CancelCommodityOrderModal
          commodity={commodity}
          commodityOrder={commodityOrder}
          context={context}
          show={showCancel}
          onHide={(show: boolean) => this.setState({ showCancel: false, show })}
        />
      </>
    );
  }
}

export default CreateCommodityOrderModal;
