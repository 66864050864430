import _ from "lodash";
import {
  calculation,
  CapsuleCalculationPrice,
  OrdersDocument,
  pricing,
  pricingCommodities,
  Setting
} from "../../model/orders.types";
import commodityUtils from "../../utils/commodityUtils";

export interface MaterialChangeStatistics {
  commodities: [number, number, number];
  packaging: [number, number, number];
  capsule: [number, number, number];
}

/**
 * Compare two orders
 * @param originOrder the old order
 * @param newOrder the new order
 * @returns Object with list of found issues and tuples for statistics
 */
function compareOrders(originOrder: OrdersDocument, newOrder: OrdersDocument) {
  let changes: Array<{ type: string; message: string }> = [];
  const oldCalculations = originOrder.calculations;
  const newCalculations = newOrder.calculations;
  changes = changes.concat(compareCalculations(oldCalculations, newCalculations));
  const oldSettings = originOrder.settings;
  const newSettings = newOrder.settings;
  changes = changes.concat(compareSettings(oldSettings, newSettings));
  const stats = getMaterialChanges(oldCalculations[0], newCalculations[0]);
  changes = changes.concat(checkForOrderedCommodities(oldCalculations, newCalculations));
  return { issues: changes, statistic: stats };
}

/**
 * Check calculations if already ordered commodities or packaging changed
 * @param oldCalculations the old calculations
 * @param newCalculations the new calculations
 * @returns list of errors
 */
function checkForOrderedCommodities(oldCalculations: Array<calculation>, newCalculations: Array<calculation>) {
  let errors: Array<{ type: string; message: string }> = [];
  const oldCalculation = oldCalculations[0];
  const newCalculation = newCalculations[0];
  const foundChangedOrdered =
    oldCalculation.prices.some(price =>
      newCalculation.prices.some(
        nPrice =>
          price._id.toString() === nPrice._id.toString() &&
          !commodityUtils.isFiller(price._id) &&
          !comparePrice(price, nPrice) &&
          price.ordered
      )
    ) ||
    oldCalculation.packagings.some(price =>
      newCalculation.packagings.some(
        nPrice => price._id.toString() === nPrice._id.toString() && !comparePrice(price, nPrice) && price.ordered
      )
    );
  if (foundChangedOrdered) {
    errors.push({ type: "fatal", message: "An already ordered commodity or packaging was changed" });
  }
  return errors;
}

/**
 * Compare commodity and packaging and get changed, added and removed count
 * @param oldCalculation the old calculation
 * @param newCalculation the new calculation
 * @returns Object with triples for commodities, and packaging each
 */
function getMaterialChanges(oldCalculation: calculation, newCalculation: calculation): MaterialChangeStatistics {
  const added = newCalculation.prices.reduce(
    (a, price) => a + (oldCalculation.prices.every(oPrice => oPrice._id.toString() !== price._id.toString()) ? 1 : 0),
    0
  );
  const removed = oldCalculation.prices.reduce(
    (a, price) => a + (newCalculation.prices.every(nPrice => nPrice._id.toString() !== price._id.toString()) ? 1 : 0),
    0
  );
  const changed = oldCalculation.prices.reduce(
    (a, price) =>
      a +
      (newCalculation.prices.some(
        nPrice => nPrice._id.toString() === price._id.toString() && !comparePrice(price, nPrice)
      )
        ? 1
        : 0),
    0
  );
  const pAdded = newCalculation.packagings.reduce(
    (a, price) =>
      a + (oldCalculation.packagings.every(oPrice => oPrice._id.toString() !== price._id.toString()) ? 1 : 0),
    0
  );
  const pRemoved = oldCalculation.packagings.reduce(
    (a, price) =>
      a + (newCalculation.packagings.every(nPrice => nPrice._id.toString() !== price._id.toString()) ? 1 : 0),
    0
  );
  const pChanged = oldCalculation.packagings.reduce(
    (a, price) =>
      a +
      (newCalculation.packagings.some(
        nPrice => nPrice._id.toString() === price._id.toString() && !comparePrice(price, nPrice)
      )
        ? 1
        : 0),
    0
  );
  const cAdded =
    newCalculation.info.standardCalculation?.capsule &&
    oldCalculation.info.standardCalculation &&
    !oldCalculation.info.standardCalculation?.capsule
      ? 1
      : 0;
  const cRemoved =
    !newCalculation.info.standardCalculation?.capsule && oldCalculation.info.standardCalculation?.capsule ? 1 : 0;
  const cChanged =
    newCalculation.info.standardCalculation?.capsule &&
    oldCalculation.info.standardCalculation?.capsule &&
    !comparePrice(newCalculation.info.standardCalculation?.capsule, oldCalculation.info.standardCalculation?.capsule)
      ? 1
      : 0;
  return {
    commodities: [added, changed, removed],
    packaging: [pAdded, pChanged, pRemoved],
    capsule: [cAdded, cChanged, cRemoved]
  };
}

/**
 * Compare two price objects
 * @param oldPrice the old price
 * @param newPrice the new price
 * @returns true if prices were considered equal, else false
 */
function comparePrice(
  oldPrice: pricing | pricingCommodities | CapsuleCalculationPrice,
  newPrice: pricing | pricingCommodities | CapsuleCalculationPrice
) {
  let equal = true;
  for (const key in oldPrice) {
    if (!equal) return false;
    const oldValue = _.get(oldPrice, key);
    const newValue = _.get(newPrice, key);
    if (typeof oldValue === "number" && typeof newValue === "number") {
      equal = equal && Math.abs(oldValue - newValue) < 1e-9;
    } else {
      equal = equal && _.isEqual(oldValue, newValue);
    }
  }
  return equal;
}

/**
 * Compare overall calculations
 * @param oldCalculations array with old calculations
 * @param newCalculations array with new calculations
 * @returns list with found issues
 */
function compareCalculations(oldCalculations: Array<calculation>, newCalculations: Array<calculation>) {
  let changes: Array<{ type: string; message: string }> = [];
  if (!_.isEqual(oldCalculations, newCalculations)) {
    if (oldCalculations.length !== newCalculations.length) {
      if (oldCalculations.length > newCalculations.length) {
        const deletedButEqual = newCalculations.every(nCalc => {
          const matchingOldCalc = oldCalculations.find(oCalc => oCalc.units === nCalc.units);
          return matchingOldCalc && _.isEqual(nCalc, matchingOldCalc);
        });
        if (!deletedButEqual) {
          changes.push({ type: "error", message: "Calculations were deleted and changed" });
        }
      } else if (oldCalculations.length < newCalculations.length) {
        changes.push({ type: "error", message: "New calculation was added" });
      }
    } else {
      const matching = oldCalculations.every(oCalc => {
        const matchingCalc = newCalculations.find(nCalc => nCalc.units === oCalc.units);
        if (!matchingCalc) return false;
        const stats = getMaterialChanges(oCalc, matchingCalc);
        return (
          matchingCalc &&
          stats.commodities.reduce((a, b) => a + b, 0) === 0 &&
          stats.packaging.reduce((a, b) => a + b, 0) === 0 &&
          stats.capsule.reduce((a, b) => a + b, 0) === 0
        );
      });
      if (!matching) {
        changes.push({ type: "error", message: "Calculations changed" });
      } else {
        const matchingInfo = oldCalculations.every(oCalc => {
          const matchingCalc = newCalculations.find(nCalc => nCalc.units === oCalc.units)!;
          return compareCalculationInfo(oCalc, matchingCalc);
        });
        if (!matchingInfo) {
          changes.push({ type: "error", message: "Calculation margin and/or prices changed" });
        }
      }
    }
  }
  return changes;
}

/**
 * Compare the info objects of calculations
 * @param oldCalculation the old calculation
 * @param newCalculation the new calculation
 * @returns true if calculations were considered equal, else false
 */
function compareCalculationInfo(oldCalculation: calculation, newCalculation: calculation) {
  const oldInfo = oldCalculation.info;
  const newInfo = newCalculation.info;
  for (const key in oldInfo) {
    if (["customCalculation", "standardCalculation"].includes(key)) continue;
    const oldValue = _.get(oldInfo, key);
    const newValue = _.get(newInfo, key);
    // Check if differ by a lot. Tolerance of 1e-9
    if (Math.abs(oldValue - newValue) > 1e-9) {
      return false;
    }
  }
  return true;
}

/**
 * Compare the settings object of two orders
 * @param oldSettings settings of the old order
 * @param newSettings settings of the new order
 * @returns list with issues
 */
function compareSettings(oldSettings: Setting, newSettings: Setting) {
  let changes: Array<{ type: string; message: string }> = [];
  if (!_.isEqual(oldSettings, newSettings)) {
    if (!_.isEqual(oldSettings.type, newSettings.type)) {
      changes.push({ type: "fatal", message: "Product type changed! Create new order instead" });
    }
    if (!_.isEqual(oldSettings.perUnit, newSettings.perUnit)) {
      changes.push({ type: "error", message: "Amount per unit and therefore calculation changed" });
    }
    if (!_.isEqual(oldSettings.id, newSettings.id)) {
      changes.push({
        type: "warn",
        message: "Selected capsule or tablet changed. Make sure the recipe and packaging still fit"
      });
    }
    if (!_.isEqual(oldSettings.manufacturer, newSettings.manufacturer)) {
      changes.push({ type: "warn", message: "Manufacturer changed" });
    }
  }
  return changes;
}

export default { compareOrders };
