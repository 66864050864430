import _ from "lodash";
import React, { PureComponent } from "react";
import Select from "react-select";
import { Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BSON } from "realm-web";
import { CommodityOrderExtended } from "./CustomTypes";
import { DataContext } from "../../context/dataContext";
import { CommoditiesDocument, CommodityOrder } from "../../model/commodities.types";
import dbGeneralService from "../../services/dbServices/dbGeneralService";
import dbService, { COMMODITIES } from "../../services/dbService";
import userService from "../../services/userService";
import commodityOrderPDFGeneration from "../../utils/pdf/commodityOrderPDFGeneration";
import pdfUtils from "../../utils/pdf/pdfUtils";
import SplashScreen from "../common/SplashScreen";

interface CustomSupplier {
  _id: BSON.ObjectId | string;
  incoterm: Array<{
    incoterm: string;
    destination: Array<{
      destination: string | BSON.ObjectId | null;
      orders: Array<CommodityOrderExtended>;
      pdfCreated: boolean;
      paymentCondition: "" | { value: string; label: string };
    }>;
  }>;
}

interface PlannedCommodityOrdersModalProps {
  commodities: Array<CommoditiesDocument>;
  orders: Array<{ _id: BSON.ObjectId; orders: Array<CommodityOrder> }>;
  plannedOrders: number;
}
interface PlannedCommodityOrdersModalState {
  orders: Array<CommodityOrderExtended>;
  suppliers: Array<CustomSupplier>;
  view: number;
  show: boolean;
  loading: boolean;
  alreadyOpened: boolean;
}

class PlannedCommodityOrdersModal extends PureComponent<
  PlannedCommodityOrdersModalProps,
  PlannedCommodityOrdersModalState
> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: PlannedCommodityOrdersModalProps) {
    super(props);
    this.state = { view: 1, show: false, orders: [], suppliers: [], loading: false, alreadyOpened: false };
  }

  componentDidUpdate(prevProps: Readonly<PlannedCommodityOrdersModalProps>) {
    // Only execute if modal was already opened
    if (this.state.alreadyOpened && !_.isEqual(prevProps.orders, this.props.orders)) {
      this.init();
    }
  }

  handleShow = () => {
    this.setState(
      { view: 1, show: true, loading: this.state.orders.length === 0, alreadyOpened: true },
      // Load data on first show, add small timeout to be able to show loading screen
      this.state.orders.length === 0 ? () => setTimeout(this.init, 500) : undefined
    );
  };

  /**
   * Handler for paymentCondition Select. Sets the order.paymentCondition for every order connected to the supplier.
   * @param supplierID Id of the supplier
   * @param value selected paymentCondition to set in the
   */
  handlePaymentChange = (supplierID: BSON.ObjectId | string | null, value: { value: string; label: string }) => {
    const orders = _.cloneDeep(this.state.orders);
    const order = orders.filter(
      o => supplierID && o.order.supplier && supplierID.toString() === o.order.supplier.toString()
    )!;
    for (let i = 0; i < order.length; i++) {
      order[i].paymentCondition = value;
    }
    this.setState({ orders });
  };

  /**
   * Creates all order PDFs that are needed (one per supplier) and attaches the PDFs to the commodity orders
   */
  createOrders = async () => {
    const { commodities } = this.props;
    const { orders } = this.state;
    const { commodityproperties, manufacturers, updateDocumentInContext } = this.context;
    const suppliers: Array<CustomSupplier> = [];
    // Collect all commodity orders that should be processed
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      if (!order.checked) continue;
      const os = order.order.supplier ? order.order.supplier.toString() : "";
      if (os && suppliers.some(s => os === s._id.toString())) {
        for (let j = 0; j < suppliers.length; j++) {
          const supplier = suppliers[j];
          if (supplier._id === order.order.supplier) {
            let inc = supplier.incoterm.find(oi => oi.incoterm.toString() === order.order.incoterm.toString());
            if (inc) {
              const des = inc.destination.find(
                oD =>
                  oD.destination &&
                  order.order.destination &&
                  oD.destination.toString() === order.order.destination!.toString()
              );
              if (des) {
                des.orders.push(order);
              } else {
                inc.destination.push({
                  destination: order.order.destination ? order.order.destination : "",
                  orders: [order],
                  pdfCreated: false,
                  paymentCondition: order.paymentCondition!
                });
              }
            } else {
              supplier.incoterm.push({
                incoterm: order.order.incoterm,
                destination: [
                  {
                    destination: order.order.destination ? order.order.destination : "",
                    orders: [order],
                    pdfCreated: false,
                    paymentCondition: order.paymentCondition!
                  }
                ]
              });
            }
          }
        }
      } else if (os) {
        suppliers.push({
          _id: order.order.supplier!,
          incoterm: [
            {
              incoterm: order.order.incoterm,
              destination: [
                {
                  destination: order.order.destination,
                  orders: [order],
                  pdfCreated: false,
                  paymentCondition: order.paymentCondition!
                }
              ]
            }
          ]
        });
      }
    }
    this.setState({ suppliers });

    // Generate one PDF per supplier
    for (let i = 0; i < suppliers.length; i++) {
      const supplierPDF = suppliers[i];
      for (let j = 0; j < supplierPDF.incoterm.length; j++) {
        const incotermPDF = supplierPDF.incoterm[j];
        for (let k = 0; k < suppliers[i].incoterm[j].destination.length; k++) {
          const destinationPDF = incotermPDF.destination[k];
          const incoterm = incotermPDF.incoterm.toUpperCase();
          const commodityOrderNr = await dbGeneralService.getNextCommodityOrderNr();
          const destination = destinationPDF.destination;
          const destinationName = manufacturers.find(m => destination && destination.toString() === m._id.toString());
          const orderPDF = JSON.stringify({
            html: commodityOrderPDFGeneration.createCommodityOrder(
              this.context.suppliers.find(s => s._id.toString() === suppliers[i]._id.toString())!,
              destinationPDF.orders,
              userService.getUserData(),
              commodities,
              commodityOrderNr,
              commodityproperties,
              manufacturers,
              destinationName,
              this.context.orders
            ),
            fileName:
              "Order-" +
              commodityOrderNr +
              "_" +
              incoterm +
              "_" +
              (destinationName ? destinationName.name.toString().toUpperCase() : "") +
              ".pdf"
          });
          let pdf = await pdfUtils.uploadAndReturnPath(orderPDF);
          pdf = pdf.replace("pdf-files/", "");
          destinationPDF.pdfCreated = true;
          for (let l = 0; l < destinationPDF.orders.length; l++) {
            const co = orders.find(
              o => o.checked && o.order._id.toString() === destinationPDF.orders[l].order._id.toString()
            );
            co!.order.orderPDF = pdf;
          }
          // Needed so that we can indicate which supplier is already done and not only when all are done
          this.setState({ suppliers });
        }
      }
    }

    this.setState({ orders, suppliers, view: 4 });

    // Update the commodities
    for (let i = 0; i < orders.length; i++) {
      if (orders[i].checked) {
        let commodity = commodities.find(c => c._id.toString() === orders[i]._id.toString())!;
        for (let j = 0; j < commodity.orders.length; j++) {
          if (commodity.orders[j]._id.toString() === orders[i].order._id.toString()) {
            commodity.orders[j].ordered = new Date();
            await dbService.updateDocument(COMMODITIES, commodity._id, { orders: commodity.orders });
            await updateDocumentInContext(COMMODITIES, commodity._id);
            break;
          }
        }
      }
    }
  };

  /**
   * Initialize all data needed.
   */
  init = () => {
    const { orders } = this.props;
    const ordersCollected = [];
    for (let cOrder of orders) {
      for (let order of cOrder.orders) {
        if (!order.ordered && !order.delivered) {
          ordersCollected.push({ order, _id: cOrder._id, checked: true, id: new BSON.ObjectId() });
        }
      }
    }
    ordersCollected.sort((c1, c2) => this.sortOrdersBySupplier(c1, c2));
    this.setState({ orders: ordersCollected, loading: false });
  };

  /**
   * Sort the given suppliers by name a-z
   * @param c1 CommodityOrderExtended
   * @param c2 CommodityOrderExtended
   * @returns { number } Represents the result of the sorting
   */
  sortOrdersBySupplier = (
    c1: { order: CommodityOrder; _id: BSON.ObjectID; checked: boolean; id: BSON.ObjectID },
    c2: { order: CommodityOrder; _id: BSON.ObjectID; checked: boolean; id: BSON.ObjectID }
  ): number => {
    const { suppliers } = this.context;
    const c1Supplier = c1.order.supplier && suppliers.find(s => s._id.toString() === c1.order.supplier!.toString());
    const c2Supplier = c2.order.supplier && suppliers.find(s => s._id.toString() === c2.order.supplier!.toString());
    return c1Supplier && c2Supplier ? c1Supplier.name.toString().localeCompare(c2Supplier.name.toString()) : 0;
  };

  /**
   * Select or deselect all orders.
   */
  handleClickAllCheckbox = () => {
    const orders = _.cloneDeep(this.state.orders);
    const allSelected = !orders.some(o => !o.checked);
    if (allSelected) {
      for (let i = 0; i < orders.length; i++) {
        orders[i].checked = false;
      }
    } else {
      for (let i = 0; i < orders.length; i++) {
        orders[i].checked = true;
      }
    }
    this.setState({ orders });
  };

  /**
   * Toggle the given checkbox.
   * @param idx Index that should be toggled
   */
  handleClickCheckbox = (idx: number) => {
    const orders = _.cloneDeep(this.state.orders);
    orders[idx].checked = !orders[idx].checked;
    this.setState({ orders });
  };

  render() {
    const { commodities, plannedOrders } = this.props;
    const { orders, show, suppliers, view, loading } = this.state;
    const suppliersContext = this.context.suppliers;
    const allSelected = !orders.some(o => !o.checked);
    let supplierList: Array<string> = [];
    let options = [{ value: "0", label: "Payment in advance" }];
    options = options.concat(
      [7, 10, 14, 28, 30, 60].map(x => {
        return { value: x.toString(), label: x.toString() + " days after invoice date" };
      })
    );

    // Disabled for now: AC-573
    return (
      <>
        <OverlayTrigger
          overlay={
            <Tooltip id="disabledInfo">
              This feature is currently disabled as it is not working as intended and cannot be fixed easily. <br />
              It will be reimplemented after the warehouse and related commodity order rework.
            </Tooltip>
          }
        >
          <button className={"btn disabled " + (plannedOrders ? "btn-success" : "btn-secondary")}>
            {loading ? (
              <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                <svg className="button-splash-spinner" viewBox="0 0 50 50">
                  <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                </svg>
              </div>
            ) : (
              <i className="flaticon2-supermarket" />
            )}
            <span className="align-middle kt-font-bold">Planned Orders ({plannedOrders})</span>
          </button>
        </OverlayTrigger>
        <Modal show={show} centered onHide={() => this.setState({ show: false, view: 1 })} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Export Orders</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {view === 1 && (
              <div
                className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive"
                style={{ maxHeight: "70vh", overflowY: "auto" }}
              >
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}>
                        <span>
                          <input
                            type="checkbox"
                            className="prettyCheckbox"
                            checked={allSelected}
                            onChange={this.handleClickAllCheckbox}
                          />
                        </span>
                      </th>
                      <th style={{ width: "20%" }}>Supplier</th>
                      <th style={{ width: "15%" }}>AT-number</th>
                      <th style={{ width: "20%" }}>Commodity</th>
                      <th style={{ width: "15%" }}>Amount</th>
                      <th style={{ width: "10%" }}>Incoterm</th>
                      <th style={{ width: "15%" }}>Price</th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: "#fafafa" }}>
                    {loading ? (
                      <tr>
                        <td colSpan={10}>
                          <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
                        </td>
                      </tr>
                    ) : (
                      orders.map((order, key) => {
                        const commodity = commodities.find(c => c._id.toString() === order._id.toString());
                        const references: { reference: string; id: string }[] = [{ reference: "", id: "" }];
                        for (let x = 0; x < order.order.orders.length; x++) {
                          const orderDocument = this.context.orders.find(
                            o => o._id.toString() === order.order.orders[x].toString()
                          );
                          const at = orderDocument ? "AT - " + orderDocument.identifier : "TBD";
                          const id = orderDocument ? orderDocument._id : undefined;
                          references.push({ reference: at.toString(), id: id });
                        }
                        return (
                          <tr key={order.id.toString()}>
                            <td>
                              <input
                                type="checkbox"
                                className="prettyCheckbox"
                                checked={order.checked}
                                onChange={() => this.handleClickCheckbox(key)}
                              />
                            </td>
                            <td>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <Link
                                    to={"/supplier/" + (order.order.supplier ? order.order.supplier!.toString() : "")}
                                    className="kt-user-card-v2__name kt-link"
                                  >
                                    {
                                      suppliersContext.find(s => s._id.toString() === order.order.supplier?.toString())
                                        ?.name
                                    }
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  {references.map(ref => (
                                    <Link
                                      key={ref.id}
                                      to={"/order/" + ref.id}
                                      className="kt-user-card-v2__name kt-link"
                                    >
                                      {ref.reference}
                                    </Link>
                                  ))}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <Link
                                    to={"/commodity/" + commodity?._id.toString()}
                                    className="kt-user-card-v2__name kt-link"
                                  >
                                    {commodity?.title.en}
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <span className="kt-user-card-v2__name kt-link">
                                    {order.order.orderquantity.toFixed(2) +
                                      (commodity && commodity.type !== "" ? " tsd" : " kg")}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <span className="kt-user-card-v2__name kt-link">
                                    {order.order.incoterm.toUpperCase()}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <span className="kt-user-card-v2__name kt-link">
                                    {order.order.totalPrice.toFixed(2) + " " + order.order.currency.toUpperCase()}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              </div>
            )}
            {view === 2 && (
              <div style={{ maxHeight: "70vh", overflowY: "auto" }} id="scrollContainer">
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: "30%" }}>Supplier</th>
                      <th style={{ width: "30%" }}>Price</th>
                      <th style={{ width: "400%" }}>Payment Condition</th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: "#fafafa" }}>
                    {orders.map(ord => {
                      if (
                        ord.checked &&
                        !supplierList.some(s => (ord.order.supplier ? s === ord.order.supplier.toString() : false))
                      ) {
                        supplierList.push(ord.order.supplier ? ord.order.supplier.toString() : "");
                        return (
                          <tr key={ord.id.toString()}>
                            <td className={"align-middle"}>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <Link
                                    to={"/supplier/" + ord.order.supplier ? ord.order.supplier!.toString() : ""}
                                    className="kt-user-card-v2__name kt-link"
                                  >
                                    {
                                      suppliersContext.find(s => s._id.toString() === ord.order.supplier?.toString())
                                        ?.name
                                    }
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td className={"align-middle"}>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <span className="kt-user-card-v2__name kt-link">
                                    {ord.order.totalPrice.toFixed(2) + " " + ord.order.currency.toUpperCase()}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className={"align-middle"}>
                              <div className="cold-md-4">
                                {/* Scrolling issue https://github.com/JedWatson/react-select/issues/4088 */}
                                <Select
                                  options={options}
                                  menuShouldBlockScroll={true}
                                  menuPosition={"fixed"}
                                  // alternate solution for scroll issue but the function is called way to often and therefore slows down performance
                                  // menuPortalTarget={document.getElementById("scrollContainer")}
                                  // closeMenuOnScroll={event => {
                                  //   // @ts-ignore
                                  //   return event && event.target && event.target.id === "scrollContainer";
                                  // }}
                                  value={ord.paymentCondition ? ord.paymentCondition : { value: "", label: "Not Set" }}
                                  className="select-default"
                                  onChange={(value: any) => {
                                    this.handlePaymentChange(ord.order.supplier ? ord.order.supplier : null, value);
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </Table>
              </div>
            )}
            {view === 3 && (
              <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
                {suppliers.map(supplier =>
                  supplier.incoterm.map(incoterm =>
                    incoterm.destination.map(destination => (
                      <div key={supplier._id.toString()} className="row m-4">
                        <div className="col">
                          <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__details">
                              <span className="kt-user-card-v2__name kt-link">
                                {suppliersContext.find(s => s._id.toString() === supplier._id.toString())?.name}
                                {destination.pdfCreated ? (
                                  <i className="text-success fas fa-check-circle ml-1" />
                                ) : (
                                  <div className="kt-spinner kt-spinner--sm kt-spinner--brand ml-2 d-inline" />
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )
                )}
              </div>
            )}
            {view === 4 && (
              <div style={{ padding: "0!important" }}>
                <div
                  style={{
                    maxHeight: "70vh",
                    overflowY: "auto",
                    marginTop: "1rem",
                    marginBottom: "1rem"
                  }}
                >
                  <div className="kt-notification-v2">
                    {suppliers.map(supplier =>
                      supplier.incoterm.map(incoterm =>
                        incoterm.destination.map(destination => {
                          const pdfs = Array.from(new Set(destination.orders.map(o => o.order.orderPDF)));
                          return pdfs
                            ? pdfs.map(pdf => (
                                <a
                                  key={pdf}
                                  href={"https://mediahub.private-label-factory.com/pdf-files/" + pdf}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="kt-notification-v2__item"
                                  style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}
                                >
                                  <div className="kt-notification__item-icon">
                                    <img
                                      src={process.env.PUBLIC_URL + "/media/icons/pdf_icon.png"}
                                      style={{ width: 20 }}
                                      alt=""
                                    />
                                  </div>

                                  <div className="kt-font-bold kt-font-dark ml-2">{pdf}</div>
                                </a>
                              ))
                            : "error1";
                        })
                      )
                    )}
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {view > 1 && view !== 3 && (
              <button type="button" className="btn btn-secondary" onClick={() => this.setState({ view: 1 })}>
                Back
              </button>
            )}
            {view === 1 && (
              <button type="button" className="btn btn-success" onClick={() => this.setState({ view: 2 })}>
                <i className="fas fa-clipboard-list" />
                Set Payment Condition
              </button>
            )}
            {view === 2 && (
              <button
                type="button"
                className="btn btn-success"
                onClick={() => this.setState({ view: 3 }, () => this.createOrders())}
              >
                <i className="fas fa-clipboard-list" />
                Create Orders
              </button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default PlannedCommodityOrdersModal;
