import _ from "lodash";
import Select from "react-select";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import validator from "validator";
import config from "../../../config/config.json";
import baseUtils from "../../../utils/baseUtils";
import invoiceUtils from "../../../utils/invoiceUtils";
import orderUtils from "../../../utils/orderUtils";
import dateUtils from "../../../utils/dateUtils";
import { dunningChangeType, DunningObject } from "./DunningListing";
import { languageOptions } from "../../common/LanguageSelectionDropdown";
import { I_INTEREST } from "../../../services/dbServices/dbOrderService";

interface DunningDetailsRowProps {
  dunningObject: DunningObject;
  onSelected: (correspondingInvoiceId: string) => void;
  onUpdate: (
    changeTyp: dunningChangeType,
    correspondingInvoiceId: string,
    selectedLanguage?: { label: string; value: string },
    selectedDunningLevel?: { label: string; value: number },
    newMail?: string
  ) => void;
}

const propsAreEqual = (prevProps: DunningDetailsRowProps, nextProps: DunningDetailsRowProps) => {
  return _.isEqual(prevProps.dunningObject, nextProps.dunningObject);
};

/**
 * Component to represent dunning rows.
 */
const DunningDetailsRow: React.FunctionComponent<DunningDetailsRowProps> = React.memo(
  ({ dunningObject, onSelected, onUpdate }) => {
    const [accountingMail, setAccountingMail] = useState<string | undefined>(dunningObject.correspondingAccountingMail);
    const [isMailIndicatorActive, setIsMailIndicatorActive] = useState<boolean>(false);

    const total = invoiceUtils.getGrossTotalWithReminders(dunningObject.invoice);
    const reminder = _.orderBy(dunningObject.invoice.reminder, r => r.date, "desc");
    const reminderAge = reminder.length > 0 ? dateUtils.getDaysBetween(reminder[0].date, new Date()) : 0;
    const pending = invoiceUtils.getGrossPending(dunningObject.invoice, dunningObject.invoice.state, total);

    const correspondingInvoiceId = dunningObject.invoice._id.toString();

    /**
     * Function to handle the changes of accounting mails.
     * @param mailChange the new accounting mail.
     */
    const handleMailChange = (mailChange: string) => {
      setIsMailIndicatorActive(!validator.isEmail(mailChange));
      setAccountingMail(mailChange);
    };

    /**
     * Return the possible dunning level selections.
     * @returns {Array<{label: string, value: number}>} collection of selectable dunning levels.
     */
    const getDunningLevelProposals = (): Array<{ label: string; value: number }> => {
      return [
        { label: "Level 0 (without fees)", value: 0 },
        { label: "Level 1 (+ " + I_INTEREST + "%)", value: 1 },
        { label: "Level 2 (40€ + " + I_INTEREST + "%)", value: 2 }
      ];
    };

    return (
      <tr className="kt-datatable__row d-table-row nopadding">
        <td className="text-center">
          <input
            type="checkbox"
            className="prettyCheckbox"
            checked={dunningObject.isSelected}
            onChange={() => onSelected(correspondingInvoiceId)}
          />
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <a
                href={config.mediahubBase + dunningObject.invoice.path}
                rel="noopener noreferrer"
                target="_blank"
                className="kt-user-card-v2__name kt-link"
              >
                {"RE-" + dunningObject.invoice.invoiceNumber}
              </a>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <Link to={"/order/" + dunningObject.order._id.toString()} className="kt-user-card-v2__name kt-link">
                {"AT-" + dunningObject.order.identifier}
              </Link>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              {dunningObject.company ? (
                <Link to={"/company/" + dunningObject.company.id} className="kt-user-card-v2__name kt-link">
                  {dunningObject.company.name.length <= 45
                    ? dunningObject.company.name
                    : dunningObject.company.name.slice(0, 45)}
                </Link>
              ) : (
                "Unknown company"
              )}
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name">
                {baseUtils.formatDate(
                  orderUtils.getInvoiceDue(dunningObject.invoice.dueIn, dunningObject.invoice.invoiceDate)
                )}
              </span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span
            className={
              " mr-5  kt-font-bold kt-badge kt-badge--inline kt-badge--pill " +
              (reminderAge < 7 ? "kt-badge--success" : "kt-badge--danger text-white")
            }
            style={{ width: "90px" }}
          >
            <p className={"text-center"} style={{ fontSize: "13px" }}>
              {reminder.length > 0 ? baseUtils.formatDate(reminder[0].date) : "-"}
            </p>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span
            className={
              " p-auto kt-font-bold  kt-badge kt-badge--inline kt-badge--pill " +
              (reminder.length === 0
                ? "kt-badge--success"
                : reminder.length === 1
                ? "kt-badge--warning text-white"
                : "kt-badge--danger text-white")
            }
            style={{ width: "40px" }}
          >
            <p className={"text-center  "} style={{ fontSize: "13px" }}>
              {reminder.length > 0 ? reminder.length : "-"}
            </p>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name kt-font-boldest">{baseUtils.formatEuro(total)}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name">{baseUtils.formatEuro(pending)}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name">{dunningObject.owner}</span>
            </div>
          </div>
        </td>
        <td className={"pr-4"}>
          <Select
            className={"select-default"}
            options={languageOptions}
            value={dunningObject.pdfLanguage}
            onChange={(value: any) => onUpdate(dunningChangeType.LANGUAGE, correspondingInvoiceId, value)}
          />
        </td>
        <td className={"pr-4"}>
          <Select
            className={"select-default"}
            options={getDunningLevelProposals()}
            value={
              dunningObject.reminderOption
                ? getDunningLevelProposals()[dunningObject.reminderOption.value]
                : getDunningLevelProposals()[0]
            }
            onChange={(value: any) =>
              onUpdate(dunningChangeType.DUNNING_LEVEL, correspondingInvoiceId, undefined, value)
            }
          />
        </td>
        <td className={"pr-4"}>
          <input
            style={isMailIndicatorActive ? { border: "1px solid red" } : {}}
            type="text"
            placeholder="E-Mail"
            value={accountingMail}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMailChange(e.target.value)}
            onBlur={() =>
              onUpdate(dunningChangeType.ACCOUNTING_MAIL, correspondingInvoiceId, undefined, undefined, accountingMail)
            }
            className="form-control "
          />
        </td>
      </tr>
    );
  },
  propsAreEqual
);

export default DunningDetailsRow;
