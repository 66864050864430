import React, { PureComponent } from "react";
import { PackagingsDocument } from "../../model/packagings.types";
import { DataContext } from "../../context/dataContext";
import packagingUtils from "../../utils/packagingUtils";
import orderCalculationUtils from "../../utils/orderCalculationUtils";
import baseUtils from "../../utils/baseUtils";
import DisablePackagingModal from "./modals/DisablePackagingModal";
import DuplicatePackagingModal from "./modals/DuplicatePackagingModal";

interface PackagingHeaderProps {
  packaging: PackagingsDocument;
  context: React.ContextType<typeof DataContext>;
  onTabChange: (tab: string) => void;
}

interface PackagingHeaderState {
  amount: number;
}

class PackagingHeader extends PureComponent<PackagingHeaderProps, PackagingHeaderState> {
  constructor(props: PackagingHeaderProps) {
    super(props);
    this.state = {
      amount: 1000
    };
  }

  handleAmount = (e: React.ChangeEvent<HTMLSelectElement>) => this.setState({ amount: +e.target.value });

  render() {
    const { packaging, context, onTabChange } = this.props;
    const { amount } = this.state;
    const { suppliers } = context;
    const bestPrice = orderCalculationUtils.getPackagingPriceForMOQ(
      packaging,
      amount,
      undefined,
      undefined,
      undefined,
      true
    );
    const bestPriceSupplier = bestPrice ? suppliers.find(s => s._id.toString() === bestPrice._id.toString()) : null;
    const fastestDelivery = packagingUtils.getFastestDeliverySupplier(packaging, amount);
    const fastestDeliverySupplier = fastestDelivery
      ? suppliers.find(s => s._id.toString() === fastestDelivery._id.toString())
      : null;

    return (
      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <div className="kt-widget kt-widget--user-profile-3">
            <div className="kt-widget__top">
              <div className="kt-widget__media">
                <div className="kt-badge kt-badge--xl kt-badge--primary">
                  {packaging.packaging_type[0].toUpperCase()}
                </div>
              </div>
              <div className="kt-widget__content">
                <div className="kt-widget__head">
                  <div className="kt-widget__user">
                    <span className={"kt-widget__username" + (packaging.disabled ? " text-danger" : "")}>
                      {packaging.disabled && "[DISABLED] "}
                      {packagingUtils.getShortPackagingInfo(packaging)}
                    </span>
                    <span className="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-success">
                      Packaging
                    </span>
                  </div>
                  <div className="kt-widget__action">
                    <DuplicatePackagingModal packaging={packaging} />
                    <DisablePackagingModal packaging={packaging} context={context} />
                  </div>
                </div>
                <div className="kt-widget__info">
                  <div className="kt-widget__desc">
                    {packagingUtils.resolvePackagingProperties(packaging)}
                    {packaging.article_number && (
                      <span style={{ fontSize: "1.2rem", fontWeight: 600 }} className="kt-text-bolder">
                        <br />
                        Art. Nr: {packaging.article_number}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="kt-widget__bottom">
              <div className="kt-widget__item">
                <div className="kt-widget__icon">
                  <i className="flaticon-coins" />
                </div>
                <div className="kt-widget__details">
                  <span className="kt-widget__title">Best Price</span>
                  <span className="kt-widget__value">
                    {bestPrice ? baseUtils.formatEuro(bestPrice.price.price) : "-,-- €"}
                  </span>
                  {bestPriceSupplier ? bestPriceSupplier.name : "Unknown"}
                </div>
              </div>
              <div className="kt-widget__item">
                <div className="kt-widget__icon">
                  <i className="flaticon-truck" />
                </div>
                <div className="kt-widget__details">
                  <span className="kt-widget__title">Fastest Delivery</span>
                  <span className="kt-widget__value">
                    {fastestDelivery ? fastestDelivery.price.deliverytime : "Unknown"} Days
                  </span>
                  {fastestDeliverySupplier ? fastestDeliverySupplier.name : "Unknown"}
                </div>
              </div>
              <div className="kt-widget__item">
                <div className="kt-widget__icon">
                  <i className="flaticon-users" />
                </div>
                <div className="kt-widget__details">
                  <span className="kt-widget__title">{`${packaging.suppliers.length} ${
                    packaging.suppliers.length === 1 ? "Supplier" : "Suppliers"
                  }`}</span>
                  <span
                    onClick={() => onTabChange("pricing")}
                    className="kt-widget__value kt-font-brand pointer"
                    style={{ fontSize: "0.95rem" }}
                  >
                    View
                  </span>
                </div>
              </div>
              <div className="kt-widget__item">
                <div className="kt-widget__icon">
                  <i className="flaticon-cogwheel" />
                </div>
                <div className="kt-widget__details">
                  <span className="kt-widget__title">Amount for Preview</span>
                  <select className="form-control" onChange={this.handleAmount} value={amount}>
                    {[1, 10, 100, 250, 500, 1000, 2500, 5000, 10000, 25000].map(o => (
                      <option key={o.toString()} value={o}>
                        {o}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PackagingHeader;
