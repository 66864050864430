import { BSON } from "realm-web";
import { CommoditiesDocument, Forecast } from "../../model/commodities.types";

/**
 * Generate the commodity report HTML content.
 * @param results: Forecasts that are used for the report
 * @param commodities: List of commodities, used for resolving names
 * @param summary: Summary text
 * @param image: Link to the background image for the first page
 * @returns { string } HTML content for the commodity report
 */
function createCommodityReport(
  results: Array<{ _id: BSON.ObjectId; forecast: Forecast }>,
  commodities: Array<CommoditiesDocument>,
  summary: string,
  image: string
) {
  let html =
    '<head><link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"><meta http-equiv="content-type" content="text/html; charset=utf-8"></head>';
  html += '<body style="font-family: Helvetica; padding: 0; margin: 0">';

  html += '<div style="width: 100%; height: 100%; background-size: cover; background-image: url(';
  html += image;
  html +=
    ')"><span style="font-size: 12px; position: absolute; color: rgba(255,255,255,0.7); top: 1330px; left: 80px"><b>Private Label Factory Deutschland GmbH</b> | Philipp-Reis-Str. 7, 45659 Recklinghausen<br>USt-IdNr: DE317701381 | Amtsgericht Recklinghausen | HRB 9376 | Geschäftsführer: Christoph Stachelek<br>info@private-label-factory.com | +49 0209 51956620</span><img style="opacity: 1; position: absolute; top: 50px; right: 50px; width: 64px" src="https://mediahub.private-label-factory.com/files/logo_light.png"><div style="position: absolute; top: 1000px; left: 80px"><span style="font-size: 30px; color: white"><b>PRIVATE LABEL FACTORY</b></span><br><span style="font-size: 80px; font-weight: 1000; color: white"><b>ROHSTOFFREPORT</b></span><br><br><br><span style="font-size: 30px; color: white">Preisprognose und Marktentwicklung</span></div><span style="font-size: 24px; position: absolute; color: white; top: 1330px; right: 80px"><b>';
  html += new Date().toLocaleString("de-DE", { month: "long", year: "numeric" });
  html += "</b></span></div>";

  html += '<div style="page-break-before:always;">';
  html +=
    '<div style="position: relative; margin: 80px"><span style="font-size: 40px"><br><br><br><b>Liebe Kunden,</b></span><br><br><span style="font-size: 19px">mit unserem Rohstoffreport möchten wir Sie regelmäßig zu aktuellen Entwicklungen auf dem Rohstoffmarkt informieren. Hierzu sammeln wir Informationen zu Nachfrage, Frachtvolumen, ausbleibenden oder schlechten Ernten, Währungsschwankungen und vielem mehr. Wir hoffen, Ihnen mit diesen Informationen einen wertvollen Einblick in den Rohstoffmarkt geben zu können.<br><br>';
  html += summary;
  html += "<br></span></div>";

  for (let i = 0; i < results.length; i++) {
    html +=
      '<div style="page-break-inside: avoid; margin-left: 80px; margin-right: 80px;  padding-top: 50px; padding-bottom: 20px; display: grid"><span style="font-size: 24px"><b>';
    html += commodities.find(c => c._id.toString() === results[i]._id.toString())!.title.de;

    html +=
      '&nbsp;<img src="https://mediahub.private-label-factory.com/files/' +
      results[i].forecast.forecast +
      '.png" style="height: 20">';

    html += '</b></span><br><span style="font-size: 19px">';
    html += results[i].forecast.reason.de;
    html += "</span></div>";
  }

  html +=
    '<br><br><br><br><span style="margin-left: 80px; margin-top: 40px; font-size: 19px; padding-top: 40px"><i>Mit freundlichen Grüßen,</i></span><br><br><span style="margin-left: 80px; font-size: 19px"><b>Ihr Team der Private Label Factory</b></span><br><br><br><img src="https://mediahub.private-label-factory.com/files/plf_logo_dark.png" style="width: 220px; margin-left: 80px">';
  html += "</div></body>";
  return html;
}

export default { createCommodityReport };
