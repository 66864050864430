import _ from "lodash";
import { BSON } from "realm-web";
import dbService, { UpdateAction, ORDERS } from "../dbService";
import { calculation, Cancelation, CustomerNote, Invoice, Reminder } from "../../model/orders.types";
import { I_CANCELED, I_PAID, I_PARTLYPAID } from "../../utils/invoiceUtils";
import baseUtils from "../../utils/baseUtils";
import { CustomOrder } from "../../components/order/CustomTypes";
import { T_TITLEUPDATED } from "../../utils/timelineUtils";
import userService from "../userService";

// 9% + EZB base interest rate
export const I_INTEREST = 9 + -0.88;

/**
 * Updates the calculations of an order.
 * @param id ID of the order
 * @param calculations Updated calculations
 * @returns {Realm.Services.MongoDB.UpdateResult<any>}: Result of the update
 */
async function updateCalculations(id: BSON.ObjectId, calculations: Array<calculation>) {
  const db = dbService.getDb();
  return db?.collection(ORDERS).updateOne({ _id: id }, { $set: { calculations } });
}

/**
 * Pushes the given timeline entry onto the timeline of the referenced order.
 * @param id ID of the order
 * @param timelineEntry Entry that should be pushed
 * @returns {Realm.Services.MongoDB.UpdateResult<any>}: Result of the update
 */
async function pushToTimeline(id: BSON.ObjectId, timelineEntry: any) {
  const db = dbService.getDb();
  return db?.collection(ORDERS).updateOne({ _id: id }, { $push: { timeline: timelineEntry } });
}

/**
 * Add an invoice to the referenced order
 * @param id ID of the order
 * @param timelineEntry Timeline entry that should be pushed to the order
 * @param invoice Invoice that should ab pushed to the order
 * @returns {Realm.Services.MongoDB.UpdateResult<any>}: Result of the update
 */
async function addInvoice(id: BSON.ObjectId, timelineEntry: any, invoice: Invoice) {
  const db = dbService.getDb();
  return db?.collection(ORDERS).updateOne(
    { _id: id },
    {
      $push: { timeline: timelineEntry, invoices: invoice }
    }
  );
}

/**
 * Add an invoice reminder to the referenced order and invoice
 * @param orderId ID of the order
 * @param invoiceId ID of the invoice
 * @param timelineEntry Timeline entry that should be created
 * @param reminder Reminder that should be pushed to the invoice
 * @returns { boolean } Indicates the success of the operation
 */
async function addInvoiceReminder(
  orderId: BSON.ObjectId,
  invoiceId: BSON.ObjectId | string,
  timelineEntry: any,
  reminder: Reminder
) {
  const actions: Array<UpdateAction> = [
    {
      collection: ORDERS,
      filter: { _id: orderId },
      push: { timeline: timelineEntry, "invoices.$[i].reminder": reminder },
      arrayFilters: [{ "i._id": invoiceId }]
    }
  ];
  return await dbService.updatesAsTransaction(actions);
}

/**
 * Add an invoice cancellation to the referenced order and invoice
 * @param orderId ID of the order
 * @param invoiceId ID of the invoice
 * @param timelineEntry Timeline entry that should be created
 * @param cancelation Cancelation that should be pushed to the invoice
 * @returns { boolean } Indicates the success of the operation
 */
async function addInvoiceCancelation(
  orderId: BSON.ObjectId,
  invoiceId: BSON.ObjectId | string,
  timelineEntry: any,
  cancelation: Cancelation
) {
  const actions: Array<UpdateAction> = [
    {
      collection: ORDERS,
      filter: { _id: orderId },
      update: { "invoices.$[i].cancelation": cancelation, "invoices.$[i].state": I_CANCELED },
      push: { timeline: timelineEntry },
      arrayFilters: [{ "i._id": invoiceId }]
    }
  ];
  return await dbService.updatesAsTransaction(actions);
}

/**
 * Deletes a timeline entry
 * @param id ID of the order
 * @param timelineEntry entry to be deleted
 * @returns {Realm.Services.MongoDB.UpdateResult<any>}: Result of the update
 */
async function deleteFromTimeline(id: BSON.ObjectId, timelineEntry: any) {
  const db = dbService.getDb();
  return db?.collection(ORDERS).updateOne({ _id: id }, { $pull: { timeline: timelineEntry } });
}

/**
 * Updates a timeline entry
 * @param id ID of the order
 * @param timelineEntry entry to be update
 * @param newEntry the entry with updated content
 * @returns {Realm.Services.MongoDB.UpdateResult<any>}: Result of the update
 */
async function updateTimelineEntry(id: BSON.ObjectId, timelineEntry: any, newEntry: any) {
  return dbService.callFunction("updateTimelineEntry", [id, timelineEntry, newEntry]);
}

/**
 * Switches the state of the given order in the given state and pushes a timeline entry related to the state switch.
 * @param _id ID of the order
 * @param state New state of the order
 * @param timelineEntry Timeline entry that is related to the switch
 * @param additionalUpdate an additional update that will be merged with the default update query
 * @returns { Realm.Services.MongoDB.UpdateResult<any> } Result of the update
 */
async function switchState(_id: BSON.ObjectId, state: string, timelineEntry: any, additionalUpdate?: any) {
  const db = dbService.getDb();
  let update: any;
  if (Array.isArray(timelineEntry)) {
    update = { $set: { state }, $push: { timeline: { $each: timelineEntry } } };
  } else {
    update = { $set: { state }, $push: { timeline: timelineEntry } };
  }
  if (additionalUpdate) _.merge(update, additionalUpdate);
  return db?.collection(ORDERS).updateOne({ _id }, update);
}

/**
 * Update the note field of an order
 * @param id ID of the order
 * @param note new note
 * @returns {Realm.Services.MongoDB.UpdateResult<any>}: Result of the update
 */
async function updateNote(id: BSON.ObjectId, note: string) {
  const db = dbService.getDb();
  return db?.collection(ORDERS).updateOne({ _id: id }, { $set: { note: note } });
}

/**
 * Update the target date of an order
 * @param id ID of the order
 * @param date the new target date
 * @param timelineEntry a timeline entry to add
 * @returns {Realm.Services.MongoDB.UpdateResult<any>}: Result of the update
 */
async function updateTargetDate(id: BSON.ObjectId, date: Date, timelineEntry: any) {
  const db = dbService.getDb();
  return db
    ?.collection(ORDERS)
    .updateOne({ _id: id }, { $set: { targetDate: date }, $push: { timeline: timelineEntry } });
}

/**
 * Increment the version number of an offer
 * @param id the ID of the order document
 * @returns {Realm.Services.MongoDB.UpdateResult<any>}: Result of the increment update
 */
async function incrementVersion(id: BSON.ObjectId) {
  const db = dbService.getDb();
  return db?.collection(ORDERS).updateOne({ _id: id }, { $inc: { version: 1 } });
}

/**
 * Pay the referenced invoice of the referenced order.
 * @param orderId ID of the order
 * @param invoiceId ID of the invoice
 * @param paymentId ID of the payment
 * @param amountPaid Amount that was paid
 * @param fullyPaid Indicates whether the whole invoice was paid with this payment
 * @param timelineEntry Timeline entry that should be pushed
 * @param note Optional note
 * @param path Path to new invoice PDF, used for partial payments
 * @returns { boolean } Indicating the success of the operation
 */
async function payInvoice(
  orderId: BSON.ObjectId,
  invoiceId: BSON.ObjectId | string,
  paymentId: BSON.ObjectId | string,
  amountPaid: number,
  fullyPaid: boolean,
  timelineEntry: any,
  note?: string,
  path?: string
) {
  const actions: Array<UpdateAction> = [
    {
      collection: ORDERS,
      filter: { _id: orderId },
      update: { "invoices.$[i].state": fullyPaid ? I_PAID : I_PARTLYPAID },
      push: {
        "invoices.$[i].payments": {
          _id: paymentId,
          amount: amountPaid,
          date: new Date(),
          note: note ? note : "Zahlung " + baseUtils.formatDate(new Date()),
          path: path ? path : ""
        },
        timeline: timelineEntry
      },
      arrayFilters: [{ "i._id": invoiceId }]
    }
  ];
  return await dbService.updatesAsTransaction(actions);
}

/**
 * Add the note to the referenced order.
 * @param orderId ID of the order
 * @param note Note that should be added
 * @returns { Promise<UpdateResult<BSON.ObjectId>> } Result of the update
 */
async function addCustomerNote(orderId: BSON.ObjectId, note: CustomerNote) {
  return dbService
    .getDb()
    ?.collection(ORDERS)
    .updateOne({ _id: orderId }, { $push: { customerNotes: note } });
}

/**
 * Marks the referenced note of the referenced order as read.
 * @param orderId ID of the order
 * @param noteId ID of the note
 * @returns { Promise<UpdateResult<BSON.ObjectId>> } Result of the update
 */
async function markCustomerNoteAsRead(orderId: BSON.ObjectId, noteId: BSON.ObjectId) {
  return dbService
    .getDb()
    ?.collection(ORDERS)
    .updateOne({ _id: orderId, "customerNotes._id": noteId }, { $set: { "customerNotes.$.readDate": new Date() } });
}

/**
 * Updates the title and the subtitle of an order.
 * @param order Order that should be updated
 * @param title New title of the order
 * @param subtitle New subtitle of the order
 * @returns { Promise<UpdateResult<BSON.ObjectId>> } Result of the update
 */
async function updateOrderTitle(order: CustomOrder, title: string, subtitle: string) {
  const timelineEntry: any = {
    _id: new BSON.ObjectId(),
    date: new Date(),
    person: userService.getUserId(),
    type: T_TITLEUPDATED
  };
  if (order.title.trim() !== title.trim()) {
    timelineEntry.titlePre = order.title;
    timelineEntry.titlePost = title;
  }
  if (order.subtitle.trim() !== subtitle.trim()) {
    timelineEntry.subtitlePre = order.subtitle;
    timelineEntry.subtitlePost = subtitle;
  }
  return dbService
    .getDb()
    ?.collection(ORDERS)
    .updateOne({ _id: order._id }, { $set: { title, subtitle }, $push: { timeline: timelineEntry } });
}

export default {
  addInvoice,
  addInvoiceCancelation,
  addInvoiceReminder,
  pushToTimeline,
  deleteFromTimeline,
  switchState,
  updateCalculations,
  updateNote,
  updateTargetDate,
  updateTimelineEntry,
  incrementVersion,
  payInvoice,
  addCustomerNote,
  markCustomerNoteAsRead,
  updateOrderTitle
};
