import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { PaginationState } from "../common/CustomTypes";
import { DataContext } from "../../context/dataContext";
import orderUtils, { ARCHIVE, CONTRACT, CREATEINVOICE, FULFILLMENT } from "../../utils/orderUtils";
import { paginate } from "../common/Pagination";
import SplashScreen from "../common/SplashScreen";
import { OrdersDocument } from "../../model/orders.types";
import baseUtils, { getComponentState } from "../../utils/baseUtils";
import PersonWidget from "../common/PersonWidget";
import CompanyWidget from "../common/CompanyWidget";
import contractUtils from "../../utils/contractUtils";
import manufacturerUtils from "../../utils/manufacturerUtils";
import { ManufacturerFilter, MarginFilter, OwnerFilter, PriorityFilter, SearchBar } from "./common/Filters";
import HistoryBackButton from "./common/HistoryBackButton";
import BaseListing from "./BaseListing";
import { OrderWidget } from "./common/BaseListingComponents";
import accessUtils from "../../utils/accessUtils";

interface ContractsProps extends RouteComponentProps {}

interface ContractsState extends PaginationState {
  search: string;
  owner: "" | { value: string; label: string };
  manufacturer: "" | { value: string; label: string };
  priority: "" | { value: string; label: string };
  margin: "" | { value: string; label: string };
  manufacturerLocked: boolean;
}

const CONSTRUCTORNAME = "Contracts";

class Contracts extends PureComponent<ContractsProps, ContractsState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: ContractsProps, context: React.ContextType<typeof DataContext>) {
    super(props, context);
    this.state = this.getDefaultState(context);
  }

  componentDidMount() {
    const state = getComponentState(this.context, CONSTRUCTORNAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.context.saveComponentState(CONSTRUCTORNAME, this.state);
  }

  handleReset = () => this.setState(this.getDefaultState(this.context));
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value, currentPage: 1 });
  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value, currentPage: 1 });
  handleSelectChange = (name: string, entry: "" | { value: string; label: string }) => {
    // @ts-ignore
    this.setState({ [name]: entry ? { value: entry.value, label: entry.label } : "", currentPage: 1 });
  };

  /**
   * Get the default state
   * @param context data context
   * @returns {ContractsState} state for the component
   */
  getDefaultState = (context: React.ContextType<typeof DataContext>) => {
    const manufacturer = manufacturerUtils.checkCurrentUserManufacturerObject(context.manufacturers);
    return {
      currentPage: 1,
      pageSize: 10,
      search: "",
      owner: "",
      manufacturer: manufacturer,
      priority: "",
      margin: "",
      manufacturerLocked: manufacturer !== ""
    } as ContractsState;
  };

  /**
   * Get filtered contracts
   * @returns {Array<OrdersDocument>} list of contracts matching the filter or search
   */
  getFilteredContracts = () => {
    const { orders, companies, userdata } = this.context;
    const { search, owner, priority, manufacturer, margin } = this.state;
    let contracts = orderUtils.filterOrders(
      orders,
      CONTRACT,
      owner ? owner.value : "",
      priority ? priority.value : "",
      margin ? margin.value : "",
      manufacturer ? manufacturer.value : ""
    );
    if (search) {
      contracts = orderUtils.filterBySearch(contracts, companies, userdata, search) as Array<OrdersDocument>;
    }
    return contracts.sort((c1, c2) => c2.createdOn.getTime() - c1.createdOn.getTime());
  };

  render() {
    const { orders, userdata, manufacturers } = this.context;
    const { history } = this.props;
    const { pageSize, currentPage, owner, priority, margin, manufacturer, manufacturerLocked, search } = this.state;
    const contracts = this.getFilteredContracts();
    const selectableUsers = userdata.filter(user => user.company_id === "internal");
    const canSeeFinanceData = accessUtils.canSeeFinanceData();
    let headerDefinition = [
      { title: "Description", size: canSeeFinanceData ? 20 : 25 },
      { title: "Company", size: canSeeFinanceData ? 20 : 25 },
      { title: "Created", size: canSeeFinanceData ? 8 : 10 },
      { title: "Call", size: canSeeFinanceData ? 8 : 10 },
      { title: "Next Call", size: canSeeFinanceData ? 7 : 10 }
    ];
    if (!canSeeFinanceData) {
      headerDefinition = headerDefinition.concat([{ title: "Owner", size: 20 }]);
    } else {
      headerDefinition = headerDefinition.concat([
        { title: "Turnover", size: 8 },
        { title: "Margin %", size: 8 },
        { title: "Margin €", size: 8 },
        { title: "Owner", size: 15 }
      ]);
    }

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand flaticon2-pie-chart" />
            </span>
            <h3 className="kt-portlet__head-title">Open Contracts</h3>
            <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
              Reset
            </button>
          </div>
          <HistoryBackButton history={history} />
        </div>
        <div className="kt-portlet__body pb-0">
          <div className="kt-form kt-form--label-right  kt-margin-b-10">
            <div className="row align-items-center">
              <SearchBar onSearch={this.handleSearch} search={search} />
              <OwnerFilter owner={owner} selectableUsers={selectableUsers} onFilterChange={this.handleSelectChange} />
              <PriorityFilter priority={priority} onFilterSelect={this.handleSelectChange} />
              <MarginFilter margin={margin} onFilterSelect={this.handleSelectChange} isDisabled={!canSeeFinanceData} />
              <ManufacturerFilter
                manufacturer={manufacturer}
                manufacturerLocked={manufacturerLocked}
                manufacturers={manufacturers}
                onFilterSelect={this.handleSelectChange}
              />
            </div>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          {orders.length === 0 ? (
            <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
          ) : (
            <BaseListing
              headerDefinition={headerDefinition}
              documents={contracts}
              bodyContent={
                <>
                  {paginate(contracts, currentPage, pageSize).map(c => (
                    <ContractsRow key={c._id.toString()} contract={c} context={this.context} {...this.props} />
                  ))}
                </>
              }
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
            />
          )}
        </div>
      </div>
    );
  }
}

interface ContractsRowProps extends RouteComponentProps {
  contract: OrdersDocument;
  context: React.ContextType<typeof DataContext>;
}

const ContractsRow: React.FunctionComponent<ContractsRowProps> = ({ contract, context, history }) => {
  const { userdata, companies, orders } = context;
  const company = baseUtils.getDocFromCollection(companies, contract.createdFor);
  const owner = baseUtils.getDocFromCollection(userdata, contract.createdFrom);
  const averageTurnover = orderUtils.getAverageValue(contract, "totalprice");
  const averagePercentMargin = orderUtils.getAverageValue(contract, "percentmargin");
  const averageMargin = orderUtils.getAverageValue(contract, "totalmargin");
  const totalCalls = contract.contract ? contract.contract.length : 0;
  const calls = contract.contract
    ? contract.contract.map(c => {
        return {
          _id: c._id.toString(),
          order: orders.find(o => o.contractInformation && o.contractInformation.callId.toString() === c._id.toString())
        };
      })
    : [];
  const finishedCalls = contract.contract
    ? contract.contract.reduce((a, b) => {
        const call = calls.find(c => c._id.toString() === b._id.toString());
        if (!!b.finished || !!b.output) return a + 1;
        else if (call && call.order && [FULFILLMENT, CREATEINVOICE, ARCHIVE].includes(call.order.state)) return a + 1;
        else return a;
      }, 0)
    : 0;
  const nextCall = contractUtils.getNextCall(contract);
  const canSeeFinanceData = accessUtils.canSeeFinanceData();
  const forwardContract = () => history.push("/order/" + contract._id.toString());

  return (
    <tr className="kt-datatable__row table-hover d-table-row">
      <td className="kt-datatable__cell d-table-cell" onClick={forwardContract}>
        <OrderWidget document={contract} prefix={"Contract AT-"} />
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <CompanyWidget company={company} type={"company"} />
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardContract}>
        <span>
          <span className="kt-font-dark kt-font-bold">
            {contract.createdOn &&
              contract.createdOn.toLocaleDateString("de-DE", {
                month: "numeric",
                day: "numeric",
                year: "numeric"
              })}
          </span>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardContract}>
        <span>
          <span className="kt-font-bold text-danger">
            {contract.contract ? totalCalls - finishedCalls + " pending" : <span className="kt-font-dark">-</span>}
          </span>
          <br />
          {contract.contract && <span className="kt-font-bold text-success">{finishedCalls + " finished"}</span>}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell" onClick={forwardContract}>
        <span className="kt-font-bold kt-font-dark">
          {nextCall && nextCall.date
            ? nextCall.date.toLocaleDateString("de-DE", {
                month: "numeric",
                day: "numeric",
                year: "numeric"
              })
            : "not set"}
        </span>
      </td>
      {canSeeFinanceData && (
        <>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardContract}>
            <span>
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details">
                  <p className="kt-user-card-v2__name mb-0">
                    {averageTurnover[0] + baseUtils.formatEuro(averageTurnover[1])}
                  </p>
                </div>
              </div>
            </span>
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardContract}>
            <span>
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details">
                  <p className="kt-user-card-v2__name mb-0">
                    {averagePercentMargin[0] + averagePercentMargin[1].toFixed(2) + "%"}
                  </p>
                </div>
              </div>
            </span>
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={forwardContract}>
            <span>
              <div className="kt-user-card-v2">
                <div className="kt-user-card-v2__details">
                  <p className="kt-user-card-v2__name mb-0">
                    {averageMargin[0] + baseUtils.formatEuro(averageMargin[1])}
                  </p>
                </div>
              </div>
            </span>
          </td>
        </>
      )}
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <PersonWidget person={owner} />
        </span>
      </td>
    </tr>
  );
};

export default Contracts;
