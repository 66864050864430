import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import { CustomOrder } from "../CustomTypes";
import { OrdersDocument } from "../../../model/orders.types";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { Input } from "../../common/Input";
import dbService, { ORDERS, UpdateAction } from "../../../services/dbService";
import dbGeneralService from "../../../services/dbServices/dbGeneralService";
import { T_SHELFLIFESET } from "../../../utils/timelineUtils";
import userService from "../../../services/userService";
import toastUtils from "../../../utils/toastUtils";

interface SetShelfLifeModalProps {
  order: CustomOrder | OrdersDocument;
}

const SetShelfLifeModal: React.FC<SetShelfLifeModalProps> = ({ order }) => {
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [shelfLife, setShelfLife] = useState(order.fulfillment?.shelfLife || 24);

  useEffect(() => setShelfLife(order.fulfillment?.shelfLife || 24), [order.fulfillment?.shelfLife]);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const handleChangeShelfLife = (e: React.ChangeEvent<HTMLInputElement>) => setShelfLife(+e.target.value);

  const handleSave = useCallback(async () => {
    setSaving(true);
    try {
      const update = order.fulfillment
        ? { "fulfillment.shelfLife": shelfLife }
        : {
            fulfillment: {
              lot: await dbGeneralService.callGenerateLotNumberFunction(),
              exp: new Date(0), // set an obviously invalid date
              shelfLife,
              shippingNote: "",
              shippingGroups: []
            }
          };

      const action: UpdateAction = {
        collection: ORDERS,
        filter: { _id: order._id },
        update: update,
        push: {
          timeline: {
            _id: new BSON.ObjectId(),
            type: T_SHELFLIFESET,
            date: new Date(),
            person: userService.getUserId(),
            shelfLife
          }
        }
      };
      const result = await dbService.updatesAsTransaction([action]);
      await toastUtils.databaseOperationToast(
        result,
        "Shelf life set successfully",
        "Shelf life couldn't be set. Please try again later",
        handleHide
      );
    } finally {
      setSaving(false);
    }
  }, [shelfLife, order]);

  const errors = useMemo(() => (shelfLife <= 0 ? ["Please enter a valid shelf life"] : []), [shelfLife]);

  return (
    <>
      <button className={"btn btn-sm btn-secondary py-0 px-2 "} onClick={handleShow}>
        {!order.fulfillment?.shelfLife ? "Set" : "Edit"}
      </button>
      <Modal show={show} onHide={handleHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Set Product Shelf Life</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col-xl-12">
                <div className="kt-section kt-section--first mb-3">
                  <div className="kt-section__body">
                    <br />
                    <div className="mx-auto" style={{ maxWidth: "300px" }}>
                      <label className="kt-font-bold kt-font-dark mx-auto">Shelf Life</label>
                      <div className="input-group">
                        <Input
                          type="number"
                          min={0}
                          className="form-control"
                          name="shelfLife"
                          integerOnly={true}
                          value={shelfLife}
                          onChange={handleChangeShelfLife}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">months</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleHide} disabled={saving}>
            Close
          </button>
          <ErrorOverlayButton
            buttonText={"Save"}
            errors={errors}
            className={"btn btn-success"}
            saving={saving}
            onClick={handleSave}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SetShelfLifeModal;
