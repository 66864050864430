import React, { PureComponent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface DataTableInfoOverlayProps {
  unit: string;
}

class DataTableInfoOverlay extends PureComponent<DataTableInfoOverlayProps, {}> {
  render() {
    const { unit } = this.props;
    return (
      <OverlayTrigger
        overlay={
          <Tooltip id={"dataTableInfoTooltip-" + unit}>
            <span>
              Shows the output in {unit} for the selected period (most right bar), and two periods before.
              <br />
              All periods have the same length.
            </span>
          </Tooltip>
        }
      >
        <i className="fas fa-info-circle" style={{ color: "#dadada" }} />
      </OverlayTrigger>
    );
  }
}

export default DataTableInfoOverlay;
