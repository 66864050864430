import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { UserdataDocument } from "../../model/userdata.types";
import { CustomOrder } from "../order/CustomTypes";
import ChangeOwnerModal from "../order/modals/ChangeOwnerModal";

interface PersonWidgetProps {
  person: UserdataDocument | undefined;
  orderForChangeModal?: CustomOrder;
  additionalClasses?: string;
}

class PersonWidget extends PureComponent<PersonWidgetProps, {}> {
  render() {
    const { person, additionalClasses, orderForChangeModal } = this.props;
    const name = person ? `${person.prename} ${person.surname}` : "N/A";
    return (
      <div className={"kt-user-card-v2 " + (additionalClasses ? additionalClasses : "")}>
        <div className="kt-user-card-v2__pic d-none d-xl-block">
          <div className="kt-badge kt-badge--xl ">
            <img src={person?.img_url ? person.img_url : "/media/users/default.jpg"} />
          </div>
        </div>
        <div className="kt-user-card-v2__details">
          <Link
            to={person ? "/user/" + person._id.toString() : "/userdata"}
            className="kt-user-card-v2__name kt-link"
            onClick={e => e.stopPropagation()}
          >
            {name.trim() !== "" ? name : "<Empty Name - Please fill in>"}
          </Link>
          <span className="kt-user-card-v2__email">{person?.position || ""}</span>
        </div>
        {orderForChangeModal && <ChangeOwnerModal order={orderForChangeModal} />}
      </div>
    );
  }
}

export default PersonWidget;
