import _ from "lodash";
import React, { PureComponent } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { NotificationSettings, UserdataDocument } from "../../model/userdata.types";
import SimpleConfirmationModal from "../common/SimpleConfirmationModal";
import { DataContext } from "../../context/dataContext";
import dbService, { USERDATA } from "../../services/dbService";
import toastUtils from "../../utils/toastUtils";

interface UserNotificationsProps {
  user: UserdataDocument;
}

interface UserNotificationsState {
  saving: boolean;
  notifications: NotificationSettings;
}

const R_ORDERCREATED = { value: "orderCreated", label: "Preparing offer" };
const R_OFFERAPPROVED = { value: "offerApproved", label: "Offer approved" };
const R_OFFERUPDATED = { value: "offerUpdated", label: "Offer updated" };
const R_ORDERUPDATED = { value: "orderUpdated", label: "Order updated" };
const R_ORDERCONFIRMED = { value: "orderConfirmed", label: "Order confirmation" };
const R_ORDERPRODUCTIONSTARTED = { value: "orderProductionStarted", label: "Production started" };
const R_ORDERFULFILLMENTSTARTED = { value: "orderFulfillmentStarted", label: "Fulfillment started" };
const R_ORDERFILEUPLOAD = { value: "orderFileUpload", label: "File uploaded" };
const R_ORDERSHIPPED = { value: "orderShipped", label: "Product shipped" };
const R_ORDERDECLINED = { value: "orderDeclined", label: "Order canceled" };
const R_ORDERARCHIVED = { value: "orderArchived", label: "Order completed" };
const R_INVOICEREMINDER = { value: "invoiceReminder", label: "Invoice reminder" };
const R_INVOICECANCELLATION = { value: "invoiceCancellation", label: "Invoice canceled" };
const R_INVOICECREATED = { value: "invoiceCreated", label: "Invoice creation" };
const R_NEWSADDED = { value: "newsAdded", label: "News creation" };
const R_PRODUCTLOWSTOCK = { value: "productLowStock", label: "Stock alarm (only with Amazon connection)" };
const R_ANSWERONORDER = { value: "answerOnOrder", label: "Answer on order Note" };

const NOTIFICATION_REASONS = [
  R_ORDERCREATED,
  R_OFFERAPPROVED,
  R_OFFERUPDATED,
  R_ORDERUPDATED,
  R_ORDERCONFIRMED,
  R_ORDERPRODUCTIONSTARTED,
  R_ORDERFULFILLMENTSTARTED,
  R_ORDERFILEUPLOAD,
  R_ORDERSHIPPED,
  R_ORDERDECLINED,
  R_ORDERARCHIVED,
  R_INVOICEREMINDER,
  R_INVOICECANCELLATION,
  R_INVOICECREATED,
  R_NEWSADDED,
  R_PRODUCTLOWSTOCK,
  R_ANSWERONORDER
];

const NOTIFICATION_TYPES = ["email", "sms", "whatsapp"];

class UserNotifications extends PureComponent<UserNotificationsProps, UserNotificationsState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  constructor(props: UserNotificationsProps) {
    super(props);
    this.state = {
      saving: false,
      notifications: _.cloneDeep(props.user.notifications)
    };
  }

  componentDidUpdate(
    prevProps: Readonly<UserNotificationsProps>,
    prevState: Readonly<UserNotificationsState>,
    snapshot?: any
  ) {
    if (!_.isEqual(this.props.user, prevProps.user)) {
      this.setState({ notifications: _.cloneDeep(this.props.user.notifications) });
    }
  }

  handleReset = () => this.setState({ notifications: _.cloneDeep(this.props.user.notifications) });

  /**
   * Handles the click on a dropdown entry and sets the new contact way value.
   * @param e The new value for the contact way
   */
  handleChangeContactDetail = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const notifications = _.cloneDeep(this.state.notifications);
    const key = e.target.name as keyof NotificationSettings;
    // @ts-ignore
    notifications[key] = e.target.value;
    this.setState({ notifications });
  };

  /**
   * Handles clicking a checkbox. Checkboxes are handled as a matrix of reason x type.
   * @param reason Reason for the notification
   * @param type Type of the notification
   */
  handleCheckboxClick = (reason: string, type: string) => {
    const notifications = _.cloneDeep(this.state.notifications);
    const allTypes = type === "all";
    // Handle case where all reasons for a type (or even all types) are clicked
    if (reason === "all") {
      // If everything was checked clear everything
      if (this.isAllCheckedByType(type)) {
        for (let notificationType of allTypes
          ? NOTIFICATION_TYPES.filter(n => !["whatsapp", "sms"].includes(n))
          : [type]) {
          // @ts-ignore
          notifications[notificationType] = [];
        }
        // If only some were checked select the others
      } else {
        for (let notificationType of allTypes
          ? NOTIFICATION_TYPES.filter(n => !["whatsapp", "sms"].includes(n))
          : [type]) {
          for (let { value: notificationReason } of NOTIFICATION_REASONS) {
            // @ts-ignore
            if (!notifications[notificationType].includes(notificationReason)) {
              // @ts-ignore
              notifications[notificationType].push(notificationReason);
            }
          }
        }
      }
      // Handle case where all types are selected
    } else if (allTypes) {
      if (this.isAllChecked(reason)) {
        for (let notificationType of NOTIFICATION_TYPES.filter(n => !["whatsapp", "sms"].includes(n))) {
          // @ts-ignore
          notifications[notificationType] = notifications[notificationType].filter(r => r !== reason);
        }
      } else {
        for (let notificationType of NOTIFICATION_TYPES.filter(n => !["whatsapp", "sms"].includes(n))) {
          // @ts-ignore
          notifications[notificationType].push(reason);
        }
      }
      // Handle single checkboxes
    } else {
      // Remove reason if checkbox was checked, else push reason.
      // @ts-ignore
      if (notifications[type].includes(reason)) {
        // @ts-ignore
        notifications[type] = notifications[type].filter(r => r !== reason);
      } else {
        // @ts-ignore
        notifications[type].push(reason);
      }
    }
    this.setState({ notifications });
  };

  handleSave = async () => {
    const { user } = this.props;
    const { notifications } = this.state;
    const { updateDocumentInContext } = this.context;
    if (!user || !notifications || _.isEqual(user.notifications, notifications)) return;
    this.setState({ saving: true });
    const res = await dbService.updateDocument(USERDATA, user._id, { notifications });
    await toastUtils.databaseOperationToast(
      !!res && res.modifiedCount > 0,
      "User notification settings updated successfully",
      "Error updating user notification settings",
      () => updateDocumentInContext(USERDATA, user._id)
    );
    this.setState({ saving: false });
  };

  /**
   * Check whether the given checkbox should be checked or not.
   * @param reason Reason for the notification
   * @param type Type of the notification
   * @returns Boolean indicating checked or not
   */
  isChecked = (reason: string, type: string) => {
    const { notifications } = this.state;
    if (type === "all") {
      return this.isAllChecked(reason);
    }
    // @ts-ignore
    return notifications[type].includes(reason);
  };

  /**
   * Helper function to check if all checkboxes for a given reason are checked.
   * @param reason Reason that should be checked
   * @returns True if ALL notification types are checked for the given reason. False if not.
   */
  isAllChecked = (reason: string) => {
    const { notifications } = this.state;
    let allChecked = true;
    for (let notificationType of NOTIFICATION_TYPES.filter(n => !["whatsapp", "sms"].includes(n))) {
      // @ts-ignore
      if (!notifications[notificationType].includes(reason)) {
        allChecked = false;
        break;
      }
    }
    return allChecked;
  };

  /**
   * Helder function to check if all checkboxes for a given type (or all types) are checked.
   * @param type Type that should be checked
   * @returns True if ALL reasons are checked for the given type (or all types). False if not
   */
  isAllCheckedByType = (type: string) => {
    const { notifications } = this.state;
    let typesToCheck = type === "all" ? NOTIFICATION_TYPES.filter(n => !["whatsapp", "sms"].includes(n)) : [type];
    // @ts-ignore
    return NOTIFICATION_REASONS.every(({ value: r }) => typesToCheck.every(t => notifications[t].includes(r)));
  };

  render() {
    const { user } = this.props;
    const { notifications, saving } = this.state;
    const { contactMail, contactPhone, language } = notifications;
    if (!user) return <div />;
    const isEqual = _.isEqual(notifications, user.notifications);
    return (
      <>
        <div className="kt-form__body">
          <div className="kt-section mb-0">
            <div className="kt-section__body">
              <div className="row justify-content-center">
                <div className="col-xl-9 my-5">
                  <div className="row">
                    <div id="contactMail" className="col-12 col-md mb-5">
                      <h6 className="text-dark font-weight-bold">E-Mail</h6>
                      <select
                        className="form-control"
                        name="contactMail"
                        value={contactMail}
                        onChange={this.handleChangeContactDetail}
                      >
                        <option disabled hidden={user.email.length > 0} value={""}>
                          Not set
                        </option>
                        {user.email.map((e, key) => {
                          return (
                            <option id={e} key={key} value={e}>
                              {e}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div id="contactPhone" className="col-12 col-md mb-5">
                      <h6 className="text-dark font-weight-bold">Telephone</h6>
                      <select
                        className="form-control"
                        name="contactPhone"
                        value={contactPhone}
                        onChange={this.handleChangeContactDetail}
                      >
                        <option disabled hidden={user.telephone.length > 0} value={""}>
                          Not set
                        </option>
                        {user.telephone.map((p, key) => {
                          return (
                            <option id={p} key={key} value={p}>
                              {p}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div id="contactLanguage" className="col-12 col-md mb-5">
                      <h6 className="text-dark font-weight-bold">Notification language</h6>
                      <select
                        className="form-control"
                        name="language"
                        value={language}
                        onChange={this.handleChangeContactDetail}
                      >
                        <option disabled hidden value={""}>
                          Not set
                        </option>
                        <option value="de">German</option>
                        <option value="en">English</option>
                      </select>
                    </div>
                  </div>
                  <div id="notificationSettings" className="overflow-auto">
                    <Table>
                      <thead>
                        <tr>
                          <th style={{ width: "41%" }}>REASON</th>
                          <th className="text-center" style={{ width: "5%" }}>
                            ALL
                          </th>
                          <th style={{ width: "15%" }} />
                          {NOTIFICATION_TYPES.map((type, key) => {
                            return (
                              <th style={{ width: "13%" }} key={key} className="text-center">
                                {["whatsapp", "sms"].includes(type) ? (
                                  <>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip id="waDisabled">Currently not available</Tooltip>}
                                    >
                                      <span className="text-muted">{type.toUpperCase()}</span>
                                    </OverlayTrigger>
                                  </>
                                ) : (
                                  type.toUpperCase()
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="bg-secondary">
                          <td>All</td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              id="not_cb_all_all"
                              checked={this.isAllCheckedByType("all")}
                              onChange={() => this.handleCheckboxClick("all", "all")}
                            />
                          </td>
                          <td />
                          <td className="text-center">
                            <input
                              type="checkbox"
                              id="not_cb_all_email"
                              checked={this.isAllCheckedByType("email")}
                              onChange={() => this.handleCheckboxClick("all", "email")}
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              id="not_cb_all_sms"
                              disabled={true}
                              checked={this.isAllCheckedByType("sms")}
                              onChange={() => this.handleCheckboxClick("all", "sms")}
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              id="not_cb_all_wa"
                              disabled={true}
                              checked={this.isAllCheckedByType("whatsapp")}
                              onChange={() => this.handleCheckboxClick("all", "whatsapp")}
                            />
                          </td>
                        </tr>
                        {NOTIFICATION_REASONS.map(r => {
                          return (
                            <tr key={r.value}>
                              <td>{r.label}</td>
                              <td className="bg-secondary text-center">
                                <input
                                  type="checkbox"
                                  checked={this.isChecked(r.value, "all")}
                                  onChange={() => this.handleCheckboxClick(r.value, "all")}
                                />
                              </td>
                              <td />
                              {NOTIFICATION_TYPES.map((t, key) => {
                                return (
                                  <td key={key} className="text-center">
                                    <input
                                      type="checkbox"
                                      disabled={["whatsapp", "sms"].includes(t)}
                                      checked={this.isChecked(r.value, t)}
                                      onChange={() => this.handleCheckboxClick(r.value, t)}
                                    />
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet__foot">
          <div className="float-right">
            <button
              disabled={saving}
              className={"btn btn-secondary mr-2" + (saving ? " disabled" : "")}
              onClick={this.handleReset}
            >
              Reset
            </button>
            <SimpleConfirmationModal.SimpleConfirmationModalButton
              disabled={saving || isEqual}
              saving={saving}
              size={"md"}
              buttonClasses={"btn btn-success" + (saving || isEqual ? " disabled" : "")}
              buttonText={saving ? "Saving..." : "Save"}
              confirmButtonText={"Confirm"}
              cancelButtonText={"Cancel"}
              modalTitle={`Confirm notification settings for ${user.prename} ${user.surname}`}
              modalDescription={
                <div className="alert alert-warning" role="alert">
                  <div className="alert-icon">
                    <i className="flaticon-warning" />
                  </div>
                  <div className="alert-text">
                    You are changing the notification settings of{" "}
                    <em>
                      {user.prename} {user.surname}
                    </em>
                    <br />
                    Although the customer has the option to unsubscribe at any time, notifications should nevertheless
                    only be activated with the <b>customer's consent</b>
                  </div>
                </div>
              }
              onConfirm={this.handleSave}
            />
          </div>
        </div>
      </>
    );
  }
}

export default UserNotifications;
