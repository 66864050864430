import _ from "lodash";
import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { RouteComponentProps } from "react-router-dom";
import authenticationService from "../../services/authenticationService";
import RoadmapDashboard from "./RoadmapDashboard";
import SalesDashboard from "./SalesDashboard";
import InvestorDashboard from "./InvestorDashboard";
import FinanceDashboard from "./FinanceDashboard";
import ProductionDashboard from "./ProductionDashboard";
import ControllingDashboard from "./ControllingDashboard";
import { DataContext } from "../../context/dataContext";
import { Period } from "../common/CustomTypes";
import DashboardSettings from "./DashboardSettings";
import baseUtils from "../../utils/baseUtils";
import GeneralDashboard from "./GeneralDashboard";
import userService from "../../services/userService";
import { ROLES } from "../../utils/userdataUtils";
import PresentationDashboard from "./PresentationDashboard";

interface DashboardProps extends RouteComponentProps<{}, {}, {}> {}

interface DashboardState {
  dashboard:
    | "sales"
    | "production"
    | "finance"
    | "generaldashboard"
    | "controlling"
    | "investor"
    | "roadmap"
    | "presentation";
  period: "thismonth" | "lastmonth" | "thisyear" | "lastyear";
  user: BSON.ObjectId | string;
  selectedManufacturer: { name: string; id: string };
}

class Dashboard extends PureComponent<DashboardProps, DashboardState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  constructor(props: DashboardProps) {
    super(props);
    this.state = {
      dashboard: this.resolveDefaultDashboard(),
      selectedManufacturer: { name: "All Manufacturers", id: "" },
      user: authenticationService.getUserDataID(),
      period: "thisyear"
    };
  }

  componentDidMount() {
    if (userService.hasRole(ROLES.PRODUCTION, true)) return;
    const query = new URLSearchParams(this.props.location.search);
    let dashboard: "sales" | "production" | "finance" | "generaldashboard" | "controlling" | "investor" = "sales";
    const view = query.get("view");
    if (
      view &&
      ["sales", "production", "finance", "generaldashboard", "controlling", "investor", "presentation"].includes(view)
    ) {
      dashboard = view as typeof dashboard;
    } else {
      dashboard = this.resolveDefaultDashboard();
    }
    this.setState({ dashboard });
  }

  componentDidUpdate(prevProps: Readonly<DashboardProps>, prevState: Readonly<DashboardState>, snapshot?: any) {
    const query = new URLSearchParams(this.props.location.search).get("view");
    const oldQuery = new URLSearchParams(prevProps.location.search).get("view");
    if (!_.isEqual(query, oldQuery)) {
      let dashboard: "sales" | "production" | "finance" | "generaldashboard" | "controlling" | "investor" = "sales";
      if (query && ["sales", "production", "finance", "generaldashboard", "controlling", "investor"].includes(query)) {
        dashboard = query as typeof dashboard;
      } else {
        dashboard = this.resolveDefaultDashboard();
      }
      this.setState({ dashboard });
    }
  }

  /**
   * Resolves the default dashboard depending on the users role.
   * @returns { string } Name of the default dashboard
   */
  resolveDefaultDashboard = () => {
    if (userService.hasRole(ROLES.INVESTOR, true)) return "investor";
    if (userService.hasRole(ROLES.SALES, true)) return "sales";
    if (userService.hasRole(ROLES.FINANCE, true)) return "finance";
    if (userService.hasRole(ROLES.CONTROLLING, true)) return "controlling";
    if (userService.hasRole(ROLES.PRODUCTION, true)) return "production";
    return "generaldashboard";
  };

  /**
   * Get start and end date matching the period string
   * @returns {object} object with beginning and end dates
   */
  getPeriod = () => {
    const { period } = this.state;
    const date = new Date();
    if (period === "thismonth") {
      let beginning = new Date(date.getFullYear(), date.getMonth(), 1);
      let end = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);
      return { beginning, end };
    } else if (period === "lastmonth") {
      let beginning = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      let end = new Date(date.getFullYear(), date.getMonth(), 0, 23, 59, 59, 999);
      return { beginning, end };
    } else if (period === "thisyear") {
      let beginning = new Date(date.getFullYear(), 0, 1);
      let end = new Date(date.getFullYear() + 1, 0, 1, 0, 0, 0, -1);
      return { beginning, end };
    } else if (period === "lastyear") {
      let beginning = new Date(date.getFullYear() - 1, 0, 1);
      let end = new Date(date.getFullYear(), 0, 1, 0, 0, 0, -1);
      return { beginning, end };
    }
  };

  handleManufacturerChange = (entry: { value: string; label: string } | "") => {
    const { manufacturers } = this.context;
    const manufacturer = entry ? baseUtils.getDocFromCollection(manufacturers, entry.value) : undefined;
    if (manufacturer && entry) {
      this.setState({ selectedManufacturer: { name: entry.label, id: entry.value.toString() } });
    } else {
      this.setState({ selectedManufacturer: { name: "All Manufacturers", id: "" } });
    }
  };

  handleUserChange = (entry: { value: string; label: string }) => {
    if (BSON.ObjectId.isValid(entry.value)) {
      this.setState({ user: entry.value });
    }
  };

  handlePeriodChange = (entry: { value: string; label: string }) => {
    const period = this.state.period;
    if (["thismonth", "lastmonth", "thisyear", "lastyear"].includes(entry.value))
      this.setState({ period: entry.value as typeof period });
  };

  handleDashboardChange = (entry: string) => {
    const dashboard = this.state.dashboard;
    if (
      [
        "sales",
        "production",
        "finance",
        "generaldashboard",
        "controlling",
        "investor",
        "roadmap",
        "presentation"
      ].includes(entry)
    )
      this.setState({ dashboard: entry as typeof dashboard });
  };

  render() {
    const { dashboard, selectedManufacturer, period: periodString, user } = this.state;
    const { manufacturers, orders, productionPlan } = this.context;
    const period: Period = this.getPeriod()!;
    return (
      <>
        {!userService.hasRole(ROLES.PRODUCTION, true) && (
          <div className="row">
            <div className="col-12">
              <DashboardSettings
                context={this.context}
                dashboard={dashboard}
                period={periodString}
                selectedManufacturer={selectedManufacturer}
                user={user}
                onDashboardChange={this.handleDashboardChange}
                onManufacturerChange={this.handleManufacturerChange}
                onPeriodChange={this.handlePeriodChange}
                onUserChange={this.handleUserChange}
              />
            </div>
          </div>
        )}
        {dashboard === "sales" && <SalesDashboard context={this.context} selectedUser={user} period={period} />}
        {dashboard === "investor" && (
          <InvestorDashboard context={this.context} period={period} manufacturer={selectedManufacturer.id.toString()} />
        )}
        {dashboard === "generaldashboard" && (
          <GeneralDashboard context={this.context} periodString={periodString} period={period} />
        )}
        {dashboard === "finance" && <FinanceDashboard />}
        {dashboard === "production" && (
          <ProductionDashboard
            manufacturer={selectedManufacturer}
            manufacturers={manufacturers}
            productionPlan={productionPlan}
            orders={orders}
          />
        )}
        {dashboard === "controlling" && (
          <ControllingDashboard manufacturer={selectedManufacturer.id.toString()} context={this.context} />
        )}
        {dashboard === "roadmap" && <RoadmapDashboard />}
        {dashboard === "presentation" && <PresentationDashboard context={this.context} />}
      </>
    );
  }
}

export default Dashboard;
