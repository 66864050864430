import React, { PureComponent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import countryList from "i18n-iso-countries";
import { toAbsoluteUrl } from "../../_metronic";
import DisableCommodityModal from "./modals/DisableCommodityModal";
import DuplicateCommodityModal from "./modals/DuplicateCommodityModal";
import config from "../../config/config.json";
import { DataContext } from "../../context/dataContext";
import { CommoditiesDocument } from "../../model/commodities.types";
import commodityUtils from "../../utils/commodityUtils";
import RawbidsConnectionModal from "./modals/RawbidsConnectionModal";
import ApproveCommodityModal from "./modals/ApproveCommodityModal";
import DeleteCommodityModal from "./modals/DeleteCommodityModal";

interface CommodityHeaderProps {
  commodity: CommoditiesDocument;
  readOnly?: true;
  context: React.ContextType<typeof DataContext>;
}

interface CommodityHeaderState {}

class CommodityHeader extends PureComponent<CommodityHeaderProps, CommodityHeaderState> {
  render() {
    const { commodity, readOnly, context } = this.props;
    const { colors, commoditycategories, commodityproperties } = context;
    const colorImage = commodity.color ? colors.find(c => c._id.toString() === commodity.color!.toString()) : null;
    const category = commoditycategories.find(c => c._id.toString() === commodity.category.toString());
    const isApproved = commodityUtils.isCommodityApproved(commodity);

    return (
      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <div className="kt-widget kt-widget--user-profile-3">
            <div className="kt-widget__top">
              <div className="kt-widget__media">
                <img
                  src={config.mediahubBase + (colorImage && colorImage.image ? colorImage.image : "files/unknown.png")}
                  alt="color"
                />
              </div>
              <div className="kt-widget__content">
                <div className="kt-widget__head">
                  <div className="kt-widget__user">
                    <span
                      className={
                        "kt-widget__username kt-font-bold " +
                        (commodity.disabled ? "text-danger" : !isApproved ? "text-warning" : "kt-font-dark")
                      }
                    >
                      {commodity.disabled ? "[DISABLED] " : !isApproved ? "[PENDING] " : ""}
                      {commodity.title.en ? commodity.title.en : "undefined"}
                      {commodity.subtitle.en ? " (" + commodity.subtitle.en + ")" : ""}
                    </span>
                    {category && (
                      <span
                        className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded kt-font-bold"
                        style={{
                          backgroundColor: commodityUtils.getCategoryColor(category.name.en)
                        }}
                      >
                        {commoditycategories.find(c => c._id.toString() === commodity.category.toString())?.name.en}
                      </span>
                    )}
                  </div>
                  {!readOnly && (
                    <div className="kt-widget__action">
                      {isApproved ? (
                        <>
                          <RawbidsConnectionModal commodity={commodity} context={context} />
                          <DuplicateCommodityModal commodity={commodity} context={context} />
                          <DisableCommodityModal commodity={commodity} context={context} />
                        </>
                      ) : (
                        <>
                          <ApproveCommodityModal commodity={commodity} context={context} />
                          <DeleteCommodityModal commodity={commodity} />
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div className="kt-widget__info mb-2">
                  <div style={{ color: "#8a8a8a" }}>
                    {commodity.title.de ? commodity.title.de : "German title undefined"}
                    {commodity.subtitle.de && " (" + commodity.subtitle.de + ")"}
                    {commodity.country && (
                      <span className="kt-font-dark mr-3">
                        <br />
                        <span className="kt-font-bold">
                          <img
                            style={{
                              width: 12,
                              height: 12,
                              borderRadius: 12,
                              objectFit: "cover"
                            }}
                            alt={commodity.country}
                            src={toAbsoluteUrl("/media/icons/countries/" + commodity.country.toLowerCase() + ".png")}
                            className="country-icon mr-1"
                          />
                          {countryList.getName(commodity.country, "en")}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
                <div className="mb-2">
                  {commodity.properties.length > 0 ? (
                    commodity.properties.map(p => (
                      <span
                        key={p.toString()}
                        className="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-primary mr-2"
                      >
                        {commodityproperties.find(cp => cp._id.toString() === p.toString())?.name.en}
                      </span>
                    ))
                  ) : (
                    <span className="text-muted">
                      <i>no properties set</i>
                    </span>
                  )}
                </div>
                <div className="kt-widget__info">
                  <span style={{ color: "#8a8a8a" }}>Identifier: </span>
                  <span className="kt-font-dark kt-font-bold ml-1 mr-3">{commodity.identifier || "-"}</span>
                  <span style={{ color: "#8a8a8a" }}>HS Code: </span>
                  <span className="kt-font-dark kt-font-bold ml-1 mr-3">{commodity.hs_code || "-"}</span>
                  <span style={{ color: "#8a8a8a" }}>CAS Number: </span>
                  <span className="kt-font-dark kt-font-bold ml-1 mr-3">{commodity.cas_number || "-"}</span>
                  <span style={{ color: "#8a8a8a" }}>
                    Internal Code
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="allocatedInfo">This is a legacy value and shouldn't be used anymore.</Tooltip>
                      }
                    >
                      <i className="fa fa-info-circle ml-1 text-info" />
                    </OverlayTrigger>
                    :{" "}
                  </span>
                  <span className="kt-font-dark ml-1 mr-3">{commodity.internal_code || "-"}</span>
                  {commodity.organic && (
                    <>
                      <span style={{ color: "#8a8a8a" }}>
                        Organic Code
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="allocatedInfo">This is a legacy value and shouldn't be used anymore.</Tooltip>
                          }
                        >
                          <i className="fa fa-info-circle ml-1 text-info" />
                        </OverlayTrigger>
                        :{" "}
                      </span>
                      <span className="kt-font-dark ml-1 mr-3">{commodity.organic_code || " -"}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommodityHeader;
