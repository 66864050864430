import userService from "../../services/userService";
import { ROLES } from "../../utils/userdataUtils";
import authenticationService from "../../services/authenticationService";
import accessUtils, { ACCESS_AREAS } from "../../utils/accessUtils";

const headerOrders = {
  title: "Orders",
  root: false,
  bullet: "dot",
  icon: "flaticon-layer",
  page: "allOrders"
};

const headerCommodities = {
  title: "Commodities",
  root: false,
  bullet: "dot",
  icon: "flaticon2-supermarket",
  page: "commodities"
};

const headerPackaging = {
  title: "Packagings",
  root: false,
  bullet: "dot",
  icon: "flaticon2-open-box",
  page: "pStockOverview"
};

const headerCalculation = {
  title: "Calculation",
  root: true,
  alignment: "left",
  icon: "flaticon2-plus-1",
  page: "calculation"
};

const headerProductionPlan = {
  title: "Production Plan",
  root: false,
  bullet: "dot",
  icon: "flaticon2-calendar-1",
  page: "productionPlan"
};

const headerDeliveryCalendar = {
  title: "Delivery Calendar",
  root: false,
  bullet: "dot",
  icon: "flaticon-calendar-with-a-clock-time-tools",
  page: "deliveryCalendar"
};

const asideDashboard = {
  title: "Dashboard",
  root: true,
  icon: "flaticon2-architecture-and-city",
  page: "dashboard",
  translate: "MENU.DASHBOARD",
  bullet: "dot"
};

const asideSaleCalculator = {
  title: "Calculation",
  root: false,
  bullet: "dot",
  icon: "flaticon2-plus-1",
  page: "calculation"
};

const asideSaleRequests = {
  title: "Requests",
  root: false,
  bullet: "dot",
  icon: "flaticon-download-1",
  page: "requests"
};

const asideSaleOffers = {
  title: "Offers",
  root: false,
  bullet: "dot",
  icon: "flaticon2-list-1",
  page: "offers"
};

const asideSaleCheckCommodities = {
  title: "Check Commodities",
  root: false,
  bullet: "dot",
  icon: "flaticon2-analytics",
  page: "checkcommodities"
};

const asideSaleContracts = {
  title: "Contracts",
  root: false,
  bullet: "dot",
  icon: "flaticon2-pie-chart",
  page: "contracts"
};

const asideSaleCustomerNotes = {
  title: "Order Chat",
  root: false,
  bullet: "dot",
  icon: "flaticon2-chat",
  page: "customerNotes"
};

const asideGeneralOrders = {
  title: "Orders",
  root: false,
  bullet: "dot",
  icon: "flaticon-layer",
  page: "allOrders"
};

const asideGeneralInProgress = {
  title: "In Progress",
  root: false,
  bullet: "dot",
  icon: "flaticon-notepad",
  page: "orders",
  submenu: [
    {
      title: "Order Commodities",
      bullet: "none",
      page: "inProgress/orderCommodities"
    },
    {
      title: "Order Packagings",
      bullet: "none",
      page: "inProgress/orderPackaging"
    },
    {
      title: "Waiting",
      bullet: "none",
      page: "inProgress/waiting"
    },
    {
      title: "Ready For Production",
      bullet: "none",
      page: "inProgress/ready"
    },
    {
      title: "In Production",
      bullet: "none",
      page: "inProgress/production"
    },
    {
      title: "Fulfillment",
      bullet: "none",
      page: "inProgress/fulfillment"
    }
  ]
};

const asideGeneralInProgressInvoicing = {
  title: "Invoicing",
  bullet: "none",
  page: "inProgress/invoicing"
};

const asideGeneralDeliveryCalendar = {
  title: "Delivery Calendar",
  root: false,
  bullet: "dot",
  icon: "flaticon-calendar-with-a-clock-time-tools",
  page: "deliveryCalendar"
};

const asideProductionOrderPlanning = {
  title: "Order Planning",
  root: false,
  bullet: "dot",
  icon: "flaticon-notepad",
  page: "productionOrders"
};

const asideProductionPlan = {
  title: "Production Plan",
  root: false,
  bullet: "dot",
  icon: "flaticon2-calendar-1",
  page: "productionPlan"
};

const asideProductionMachines = {
  title: "Machines",
  root: false,
  bullet: "dot",
  icon: "far fas fa-pallet",
  page: "dashboard?view=production"
};

const asideProductionManufacturerSettings = {
  title: "Manufacturer Settings",
  bullet: "dot",
  icon: "flaticon2-box",
  page: "manufacturers"
};

const asideFinanceOverview = {
  title: "Overview",
  root: false,
  bullet: "dot",
  icon: "flaticon-doc",
  page: "financeOverview"
};

const asideFinanceInvoiceDetails = {
  title: "Invoice Details",
  root: false,
  bullet: "dot",
  icon: "flaticon2-checking",
  page: "financeDetails"
};

const asideFinanceDunning = {
  title: "Dunning",
  root: false,
  bullet: "dot",
  icon: "flaticon-envelope",
  page: "dunning"
};

const asideCommoditiesExternalOrders = {
  title: "External Orders",
  root: false,
  bullet: "dot",
  icon: "flaticon-business",
  page: "externalOrders"
};

const asideCommoditiesCommodities = {
  title: "Commodities",
  root: false,
  bullet: "dot",
  icon: "flaticon2-supermarket",
  submenu: [
    {
      title: "Commodities",
      bullet: "dot",
      page: "commodities"
    },
    {
      title: "Create Commodity",
      bullet: "dot",
      page: "create-commodity"
    }
  ]
};

const asideCommoditiesSoftgels = {
  title: "Softgels",
  root: false,
  bullet: "dot",
  icon: "fas fa-capsules",
  submenu: [
    {
      title: "Softgels",
      bullet: "dot",
      page: "softgels"
    },
    {
      title: "Create Softgel",
      bullet: "dot",
      page: "create-softgel"
    }
  ]
};

const asideCommoditiesCustomProducts = {
  title: "Custom Products",
  root: false,
  bullet: "dot",
  icon: "fas fa-boxes",
  submenu: [
    {
      title: "Custom Products",
      bullet: "dot",
      page: "customproducts"
    },
    {
      title: "Create Custom Product",
      bullet: "dot",
      page: "create-customproduct"
    }
  ]
};

const asideCommoditiesServices = {
  title: "Services",
  root: false,
  bullet: "dot",
  icon: "fas fa-hands",
  submenu: [
    {
      title: "Services",
      bullet: "dot",
      page: "services"
    },
    {
      title: "Create Service",
      bullet: "dot",
      page: "create-service"
    }
  ]
};

const asideCommoditiesCapsules = {
  title: "Capsules",
  root: false,
  bullet: "dot",
  icon: "fas fa-capsules",
  submenu: [
    {
      title: "All Capsules",
      bullet: "dot",
      page: "capsules"
    },
    {
      title: "Create Capsule",
      bullet: "dot",
      page: "create-capsule"
    }
  ]
};

const asideCommoditiesPackaging = {
  title: "Packaging",
  root: false,
  bullet: "dot",
  icon: "flaticon2-supermarket",
  submenu: [
    {
      title: "Packaging Stock Overview ",
      Bullet: "dot",
      page: "pStockOverview"
    },
    {
      title: "All Packagings",
      bullet: "dot",
      page: "packagings"
    },
    {
      title: "Create Packaging",
      bullet: "dot",
      page: "create-packaging"
    }
  ]
};

const asideStockOverview = {
  title: "Stock Overview",
  root: false,
  bullet: "dot",
  icon: "fa fa-warehouse",
  page: "stock-overview"
};

const asideWarehouse = {
  title: "Warehouse",
  root: false,
  bullet: "dot",
  icon: "fa fa-warehouse",
  page: "warehouse"
};

const asideWarehouseSettings = {
  title: "Warehouse Settings",
  root: false,
  bullet: "dot",
  icon: "flaticon2-gear",
  page: "warehouseSettings"
};

const asideContactsCompanies = {
  title: "Companies",
  root: false,
  bullet: "dot",
  icon: "flaticon2-tools-and-utensils",
  submenu: [
    {
      title: "All Companies",
      bullet: "dot",
      page: "companies"
    },
    {
      title: "Create Company",
      bullet: "dot",
      page: "create-company"
    }
  ]
};

const asideContactsUsers = {
  title: "Users",
  root: false,
  bullet: "dot",
  icon: "flaticon2-avatar",
  submenu: [
    { title: "All Users", bullet: "dot", page: "userdata" },
    {
      title: "Customers",
      bullet: "dot",
      page: "customers"
    },
    { title: "Employees", bullet: "dot", page: "employees" },
    {
      title: "Create User",
      bullet: "dot",
      page: "create-user"
    }
  ]
};

const asideContactsSuppliers = {
  title: "Suppliers",
  root: false,
  bullet: "dot",
  icon: "flaticon2-lorry",
  submenu: [
    {
      title: "All Suppliers",
      bullet: "dot",
      page: "suppliers"
    },
    {
      title: "Create Supplier",
      bullet: "dot",
      page: "create-supplier"
    }
  ]
};

const asideAdministrationNews = {
  title: "News",
  bullet: "dot",
  icon: "flaticon2-checking",
  page: "news"
};

const asideAdministrationSampleRecipes = {
  title: "Sample Recipes",
  bullet: "dot",
  icon: "fas fa-archive",
  page: "sample-recipes"
};

const asideAdministrationManufacturers = {
  title: "Manufacturers",
  bullet: "dot",
  icon: "flaticon2-box",
  page: "manufacturers"
};

const asideSCAdministrationAccountCreation = {
  title: "Account Creation",
  bullet: "dot",
  icon: "flaticon2-user-1",
  page: "accountCreation"
};

const asideSCAdministrationOpenInvitations = {
  title: "Open Invitations",
  bullet: "dot",
  icon: "flaticon2-mail-1",
  page: "openInvitations"
};

const asideMoreArchive = {
  title: "Archived Orders",
  bullet: "dot",
  icon: "far fa-file-archive",
  page: "archive"
};

const asideMoreDeclined = {
  title: "Declined Orders",
  bullet: "dot",
  icon: "flaticon2-delete",
  page: "declined"
};

const asideMoreGeneralSettings = {
  title: "General Settings",
  root: false,
  bullet: "dot",
  icon: "flaticon2-gear",
  submenu: [
    {
      title: "Active Substances",
      bullet: "dot",
      page: "active-substances"
    },
    {
      title: "Allergens",
      bullet: "dot",
      page: "allergens"
    },
    {
      title: "Commodity Categories",
      bullet: "dot",
      page: "commodity-categories"
    },
    {
      title: "Commodity Properties",
      bullet: "dot",
      page: "commodity-properties"
    },

    {
      title: "Compositions",
      bullet: "dot",
      page: "compositions"
    },
    {
      title: "Colors",
      bullet: "dot",
      page: "colors"
    },
    {
      title: "Solvents",
      bullet: "dot",
      page: "solvents"
    }
  ]
};

const asideMoreStockCheck = {
  title: "Stock check",
  bullet: "dot",
  icon: "fas fa-dolly",
  page: "stockcheck"
};

const asideToolsStockData = {
  title: "Stock Data",
  bullet: "dot",
  icon: "fas fa-dolly",
  page: "stockData"
};

const asideToolsCommoditiesToOrder = {
  title: "Commodities to Order",
  bullet: "dot",
  icon: "flaticon2-supermarket",
  page: "commoditiesToOrder"
};

const asideToolsCommodityConsumption = {
  title: "Commodity Consumption",
  bullet: "dot",
  icon: "flaticon2-supermarket",
  page: "commodityConsumption"
};

const asideToolsStockExpiredStock = {
  title: "Expired Stock",
  bullet: "dot",
  icon: "fas fa-dolly",
  page: "expiredStock"
};

const asideToolsTopPerformers = {
  title: "Top Performers",
  bullet: "dot",
  icon: "flaticon2-list-1",
  page: "topPerformers"
};

const asideToolsCommodityImportExport = {
  title: "Commodity Import/Export",
  bullet: "dot",
  icon: "fas fa-dolly",
  page: "commodityImportExport"
};

const asideToolsCommodityOrderStatistics = {
  title: "Commodity Order Statistics",
  bullet: "dot",
  icon: "flaticon-doc",
  page: "commodityOrderStatistics"
};

const asideToolsOrderStats = {
  title: "Order Statistics",
  bullet: "dot",
  icon: "flaticon-doc",
  page: "orderStatistics"
};

const asideCommodityWaste = {
  title: "Commodity Waste",
  bullet: "dot",
  icon: "fa fa-trash",
  page: "commodityWaste"
};

const externalManufacturerMenuConfig = {
  header: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        alignment: "left",
        icon: "flaticon2-architecture-and-city",
        page: "dashboard"
      },
      {
        title: "Requests & Orders",
        root: false,
        alignment: "left",
        icon: "flaticon-layer",
        page: "orders"
      },
      { title: "Commodities", root: true, icon: "flaticon2-supermarket", page: "commodities", alignment: "left" }
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        bullet: "dot"
      },
      {
        title: "Requests & Orders",
        root: false,
        bullet: "dot",
        icon: "flaticon-layer",
        page: "orders"
      },
      { title: "Commodities", root: true, icon: "flaticon2-supermarket", page: "commodities", bullet: "dot" }
    ]
  }
};

const menuConfig =
  authenticationService.isLoggedIn() && userService.hasExclusiveRole(ROLES.EXTERNALMANUFACTURER)
    ? externalManufacturerMenuConfig
    : buildMenuConfigInternal();

/**
 * Build the menu config. The menu entries are limited according to the roles of the user.
 * @returns { object } Menu config
 */
function buildMenuConfigInternal() {
  if (userService.hasRole(ROLES.MIXMASTERS, true)) {
    return {
      header: {
        self: {},
        items: [headerCalculation, headerOrders, headerCommodities, headerPackaging, headerDeliveryCalendar]
      },
      aside: {
        self: {},
        items: [
          asideDashboard,
          { section: "General" },
          asideSaleCalculator,
          asideGeneralOrders,
          asideGeneralInProgress,
          asideGeneralDeliveryCalendar,
          { section: "Commodities" },
          asideCommoditiesCommodities,
          asideCommoditiesSoftgels,
          asideCommoditiesCustomProducts,
          asideCommoditiesServices,
          asideCommoditiesCapsules,
          asideCommoditiesPackaging,
          { section: "Production" },
          asideProductionOrderPlanning,
          asideProductionManufacturerSettings,
          { section: "More" },
          asideMoreArchive,
          asideMoreDeclined
        ]
      }
    };
  } else if (userService.hasRole(ROLES.DELIVERYCALENDERONLY, true)) {
    return {
      header: {
        self: {},
        items: [headerDeliveryCalendar]
      },
      aside: {
        self: {},
        items: [{ section: "General" }, asideGeneralDeliveryCalendar]
      }
    };
  } else {
    const menuConfig = { header: { self: {}, items: [] }, aside: { self: {}, items: [asideDashboard] } };
    const canAccessFinance = accessUtils.canAccessArea(ACCESS_AREAS.FINANCE);
    const canAccessProcurement = accessUtils.canAccessArea(ACCESS_AREAS.PROCUREMENT);
    const canAccessProcurementNotProduction = accessUtils.canAccessArea(ACCESS_AREAS.PROCUREMENT_NOT_PRODUCTION);
    const canAccessSales = accessUtils.canAccessArea(ACCESS_AREAS.SALES);
    const canAccessProduction = accessUtils.canAccessArea(ACCESS_AREAS.PRODUCTION);
    const canAccessWarehouse = accessUtils.canAccessArea(ACCESS_AREAS.WAREHOUSE);
    const isProd = userService.hasRole(ROLES.PRODUCTION, true);
    const isAdmin = userService.isAdmin();
    const h = menuConfig.header.items;
    if (canAccessProcurementNotProduction || canAccessSales) {
      h.push(headerCalculation);
    }
    h.push(headerOrders);
    if (canAccessProcurement || canAccessSales || canAccessProduction) {
      h.push(headerProductionPlan);
    }
    h.push(headerCommodities);
    h.push(headerPackaging);
    if (canAccessProcurement || canAccessSales || canAccessProduction) {
      h.push(headerDeliveryCalendar);
    }

    const a = menuConfig.aside.items;
    // Sales
    a.push({ section: "Sales" });
    // Since there are production members that are also procurement (for packaging) we need to adjust the normal check
    if (canAccessSales || canAccessProcurementNotProduction) {
      a.push(asideSaleCalculator);
      a.push(asideSaleRequests);
    }
    a.push(asideSaleOffers);
    if (canAccessSales || canAccessProcurementNotProduction) {
      a.push(asideSaleCheckCommodities);
    }
    a.push(asideSaleContracts);
    if (canAccessSales || canAccessFinance || canAccessProcurementNotProduction) {
      a.push(asideSaleCustomerNotes);
    }
    // General
    a.push({ section: "General" });
    a.push(asideGeneralOrders);
    a.push(asideGeneralInProgress);
    if (canAccessFinance) {
      a[a.length - 1].submenu.push(asideGeneralInProgressInvoicing);
    }
    if (canAccessProcurement || canAccessSales || canAccessProduction) {
      a.push(asideGeneralDeliveryCalendar);
    }

    // Warehouse
    if (canAccessWarehouse) {
      a.push({ section: "Warehouse" });
      a.push(asideWarehouse);
      a.push(asideWarehouseSettings);
    }

    // Production
    if (canAccessProduction || canAccessProcurement || canAccessSales || isProd) a.push({ section: "Production" });
    if (canAccessProduction || canAccessProcurement || canAccessSales) {
      a.push(asideProductionOrderPlanning);
      a.push(asideProductionPlan);
      a.push(asideProductionMachines);
    }
    // Exception from the normal check since non-prod should not see this point
    if (isProd) {
      a.push(asideProductionManufacturerSettings);
    }
    if (canAccessFinance) {
      // Finance
      a.push({ section: "Finance" });
      a.push(asideFinanceOverview);
      a.push(asideFinanceInvoiceDetails);
      a.push(asideFinanceDunning);
    }
    // Commodities
    a.push({ section: "Commodities" });
    if (canAccessProcurementNotProduction || canAccessFinance) {
      a.push(asideCommoditiesExternalOrders);
    }
    a.push(asideCommoditiesCommodities);
    a.push(asideCommoditiesSoftgels);
    a.push(asideCommoditiesCustomProducts);
    a.push(asideCommoditiesServices);
    a.push(asideCommoditiesCapsules);
    a.push(asideCommoditiesPackaging);

    // Stock
    a.push({ section: "Stock" });
    a.push(asideStockOverview);

    // Contacts
    a.push({ section: "Contacts" });
    if (canAccessSales || canAccessProcurementNotProduction || canAccessFinance) {
      a.push(asideContactsCompanies);
    }
    if (canAccessSales || canAccessProcurementNotProduction || canAccessFinance) {
      a.push(asideContactsUsers);
    }
    a.push(asideContactsSuppliers);
    if (!isProd) {
      // Administration
      a.push({ section: "Administration" });
      a.push(asideAdministrationNews);
      if (canAccessSales) {
        a.push(asideAdministrationSampleRecipes);
      }
      a.push(asideAdministrationManufacturers);
    }
    if (canAccessSales) {
      // SC Administration
      a.push({ section: "SupplyCentral Administration" });
      a.push(asideSCAdministrationAccountCreation);
      a.push(asideSCAdministrationOpenInvitations);
    }
    // More
    a.push({ section: "More" });
    a.push(asideMoreArchive);
    a.push(asideMoreDeclined);
    a.push(asideMoreGeneralSettings);
    if (canAccessProcurementNotProduction) {
      a.push(asideMoreStockCheck);
      a.push(asideToolsStockExpiredStock);
    }
    if (isAdmin) {
      a.push({ section: "Tools" });
      a.push(asideToolsStockData);
      a.push(asideToolsCommoditiesToOrder);
      a.push(asideToolsCommodityConsumption);
      a.push(asideToolsTopPerformers);
      a.push(asideToolsCommodityImportExport);
      a.push(asideToolsCommodityOrderStatistics);
      a.push(asideToolsOrderStats);
      a.push(asideCommodityWaste);
    }
    return menuConfig;
  }
}

export default menuConfig;
