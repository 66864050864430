import _ from "lodash";
import React, { PureComponent, useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_metronic";
import SplashScreen from "../common/SplashScreen";
import SearchBox from "../common/SearchBox";
import Pagination, { paginate } from "../common/Pagination";
import { DataContext, DataContextType } from "../../context/dataContext";
import baseUtils from "../../utils/baseUtils";
import { CustomCommoditiesDocument, ExtendedOrder } from "./CustomTypes";
import ConfiguratorHelper from "./ConfiguratorHelper";
import calculationUtils from "../../utils/calculationUtils";
import { OFFER, REQUESTAPPROVED, REQUESTPENDING } from "../../utils/orderUtils";
import { ProductTypes } from "./configuratorConstants";

const PAGESIZE = 10;

interface LoadRecipeModalProps {
  onLoadRecipe: (order: ExtendedOrder) => void;
}

interface LoadRecipeModalState {
  show: boolean;
  loading: boolean;
  currentPage: number;
  searchQuery: string;
  extendedOrders: Array<ExtendedOrder>;
  selectedOrder: ExtendedOrder | null;
}

class LoadRecipeModal extends PureComponent<LoadRecipeModalProps, LoadRecipeModalState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  _isMounted = false;
  constructor(props: LoadRecipeModalProps, context: React.ContextType<typeof DataContext>) {
    super(props, context);
    this.state = {
      show: false,
      loading: true,
      currentPage: 1,
      searchQuery: "",
      extendedOrders: [],
      selectedOrder: null
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    const extendedOrders = await this.getExtendedOrders(this.context);
    if (this._isMounted) this.setState({ extendedOrders, loading: false });
  };

  async componentDidUpdate(
    prevProps: Readonly<LoadRecipeModalProps>,
    prevState: Readonly<LoadRecipeModalState>,
    snapshot?: any
  ) {
    const { extendedOrders } = this.state;
    const { orders } = this.context;
    if (extendedOrders.length !== orders.length) {
      console.info("Found context update, updating extended orders", extendedOrders.length, orders.length);
      const newExtendedOrders = await this.getExtendedOrders(this.context);
      if (this._isMounted) this.setState({ extendedOrders: newExtendedOrders, loading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleShow = () => this.setState({ show: true, selectedOrder: null, searchQuery: "", currentPage: 1 });
  handleClose = () => this.setState({ show: false });

  handlePageChange = (page: number) => this.setState({ currentPage: page });

  handleSelectOrder = (order: ExtendedOrder) => this.setState({ selectedOrder: _.cloneDeep(order) });
  handleBack = () => this.setState({ selectedOrder: null });

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ searchQuery: e.target.value.toLowerCase() });

  /**
   * Load and extend the orders
   * @param context the data context
   */
  getExtendedOrders = async (context: DataContextType) => {
    const { orders, userdata, companies } = context;
    let extendedOrders: Array<any> = [];
    for (let i = 0; i < orders.length; i++) {
      setTimeout(() => {
        const extendedOrder = _.cloneDeep(orders[i]);
        extendedOrder.createdFor = baseUtils.getDocFromCollection(companies, extendedOrder.createdFor);
        extendedOrder.createdFrom = baseUtils.getDocFromCollection(userdata, extendedOrder.createdFrom);
        extendedOrders.push(extendedOrder);
      }, 0);
    }
    while (extendedOrders.length < orders.length) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    return extendedOrders;
  };

  getFilteredOrders = () => {
    const { extendedOrders, searchQuery } = this.state;
    return extendedOrders.filter(order => {
      return (
        order.identifier.toString().includes(searchQuery) ||
        order.title.toLowerCase().includes(searchQuery) ||
        order.subtitle.toLowerCase().includes(searchQuery) ||
        order.note.toLowerCase().includes(searchQuery) ||
        order.state.toLowerCase().includes(searchQuery) ||
        order.settings.type.toLowerCase().includes(searchQuery) ||
        order.createdFor.name.toLowerCase().includes(searchQuery) ||
        order.createdFrom.prename.toLowerCase().includes(searchQuery) ||
        order.createdFrom.surname.toLowerCase().includes(searchQuery)
      );
    });
  };

  render() {
    const { onLoadRecipe } = this.props;
    const { show, currentPage, selectedOrder, searchQuery, loading } = this.state;
    const filteredOrders = this.getFilteredOrders();
    return (
      <>
        <button type="button" className={"btn btn-secondary btn-sm ml-3 pt-1 pb-1 pl-2 pr-2"} onClick={this.handleShow}>
          Load existing recipe
        </button>
        <Modal show={show} onHide={this.handleClose} centered size={"xl"} name={"loadRecipeModal"}>
          <Modal.Header closeButton>
            <Modal.Title as={"h5"}>
              <i className="kt-font-brand fa fa-plus mr-2" />
              Load Recipe
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ minHeight: "500px" }}>
            {!!selectedOrder ? (
              <OrderCommodities order={selectedOrder} />
            ) : loading ? (
              <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
            ) : (
              <div>
                <div className={"kt-portlet kt-portlet--tabs my-1"} style={{ boxShadow: "none" }}>
                  <div className="kt-portlet__body px-0 py-1">
                    <div className="from-group row mb-0">
                      <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                        <SearchBox
                          title={"Search Orders"}
                          onSearch={this.handleSearch}
                          value={searchQuery}
                          placeholder={"Search..."}
                          idSuffix="OrderRecipe"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="overflow-auto kt-datatable kt-datatable--default kt-datatable--loaded "
                  style={{ maxHeight: "70vh" }}
                >
                  <table className="kt-datatable__table table-responsive d-table">
                    <thead className="kt-datatable__head " style={{ display: "table-header-group" }}>
                      <tr className="text-left kt-datatable__row d-table-row">
                        <th className="kt-datatable__cell d-table-cell" style={{ minWidth: "200px" }}>
                          Description
                        </th>
                        <th className="kt-datatable__cell d-table-cell" style={{ minWidth: "150px" }}>
                          Type
                        </th>
                        <th className="kt-datatable__cell d-table-cell" style={{ minWidth: "150px" }}>
                          Commodities
                        </th>
                        <th className="kt-datatable__cell d-table-cell" style={{ minWidth: "200px" }}>
                          Company
                        </th>
                        <th className="kt-datatable__cell d-table-cell" style={{ minWidth: "200px" }}>
                          Owner
                        </th>
                      </tr>
                    </thead>
                    <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
                      {filteredOrders.length > 0 ? (
                        paginate(filteredOrders, currentPage, PAGESIZE).map(order => (
                          <LoadRecipeModalRow
                            order={order}
                            key={order._id.toString()}
                            handleSelectOrder={this.handleSelectOrder}
                          />
                        ))
                      ) : (
                        <tr className="w-100 text-center">
                          <td colSpan={4}>
                            <img
                              className="mx-auto my-auto text-center"
                              src={toAbsoluteUrl("/media/img/no_results.jpg")}
                              style={{
                                display: "block",
                                height: "500px"
                              }}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="kt-datatable__pager kt-datatable--paging-loaded mx-auto justify-content-center">
                    <Pagination
                      itemsCount={this.context.orders.length}
                      pageSize={PAGESIZE}
                      onPageChange={this.handlePageChange}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            {selectedOrder && (
              <>
                <button type="button" className="btn btn-secondary" onClick={this.handleBack}>
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    onLoadRecipe(selectedOrder);
                    this.handleClose();
                  }}
                >
                  Load Recipe
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

interface LoadRecipeModalRowProps {
  order: ExtendedOrder;
  handleSelectOrder: (order: ExtendedOrder) => void;
}

const LoadRecipeModalRow: React.FunctionComponent<LoadRecipeModalRowProps> = ({ order, handleSelectOrder }) => {
  const nameSplit = order.createdFor.name.split(/[ -]+/);
  const context = useContext(DataContext);
  const { capsules, tablets } = context;

  const getTypeDescription = () => {
    const type = calculationUtils.getTabForType(order.settings.type);
    switch (type) {
      case ProductTypes.CAPSULES:
        const capsule = capsules.find(cap => cap._id.toString() === order.settings.id.toString());
        return (
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">Capsules</span>
            <span className="kt-user-card-v2__email">
              {order.settings.perUnit + " x " + (capsule ? baseUtils.buildCapsuleString(capsule!) : "")}
            </span>
          </div>
        );
      case ProductTypes.TABLETS:
        const tablet = tablets.find(cap => cap._id.toString() === order.settings.id.toString());
        return (
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">Tablets</span>
            <span className="kt-user-card-v2__email">
              {order.settings.perUnit + " x " + (tablet ? baseUtils.buildTabletString(tablet!) : "")}
            </span>
          </div>
        );
      case ProductTypes.POWDER:
        return (
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">Powder</span>
            <span className="kt-user-card-v2__email">{calculationUtils.formatAmount(order.settings.perUnit, 2)}</span>
          </div>
        );
      case ProductTypes.LIQUID:
        return (
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">Liquid</span>
            <span className="kt-user-card-v2__email">{calculationUtils.formatAmount(order.settings.perUnit, 2)}</span>
          </div>
        );
      case ProductTypes.SOFTGEL:
        return (
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">Softgel</span>
            <span className="kt-user-card-v2__email">{order.settings.perUnit + " per Unit"}</span>
          </div>
        );
      case ProductTypes.CUSTOM:
        return (
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">Custom</span>
            <span className="kt-user-card-v2__email">{order.settings.perUnit + " per Unit"}</span>
          </div>
        );
      case ProductTypes.SERVICE:
        return (
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name">Service</span>
          </div>
        );
    }
  };

  return (
    <tr className="text-left kt-datatable__row table-hover d-table-row" onClick={() => handleSelectOrder(order)}>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__pic">
            <div className="kt-badge kt-badge--xl kt-badge--primary">
              {order.settings && order.settings.type && (
                <img src={process.env.PUBLIC_URL + "/media/icons/" + order.settings.type + ".png"} className="p-1" />
              )}
            </div>
          </div>
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name kt-link">
              {order.state === OFFER || order.state === REQUESTPENDING || order.state === REQUESTAPPROVED
                ? "Offer AN-" + order.identifier
                : "Order AT-" + order.identifier}
            </span>
            <span className="kt-user-card-v2__email">{order.title}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">{getTypeDescription()}</div>
      </td>
      <td className="kt-datatable__cell d-table-cell">{order.recipe.length} Commodities</td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__pic">
              <div className="kt-badge kt-badge--xl kt-badge--primary">
                {(nameSplit.length > 1
                  ? nameSplit[0][0] + nameSplit[1][0]
                  : order.createdFor.name.slice(0, 2)
                ).toUpperCase()}
              </div>
            </div>
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name kt-link"> {order.createdFor.name}</span>
              <span className="kt-user-card-v2__email">{order.createdFor.address[0].city}</span>
            </div>
          </div>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span>
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__pic">
              <div className="kt-badge kt-badge--xl ">
                <img src={order.createdFrom.img_url} />
              </div>
            </div>
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name">
                {order.createdFrom.prename + " " + order.createdFrom.surname}
              </span>
              <span className="kt-user-card-v2__name">{order.createdFrom.position}</span>
            </div>
          </div>
        </span>
      </td>
    </tr>
  );
};

interface OrderCommoditiesProps {
  order: ExtendedOrder;
}

const OrderCommodities: React.FunctionComponent<OrderCommoditiesProps> = ({ order }) => {
  const context = useContext(DataContext);
  const [extendedCommodities, setExtendedCommodities] = useState<Array<CustomCommoditiesDocument>>([]);
  const recipe = order.recipe;

  useEffect(() => {
    const {
      commodities,
      commoditycategories,
      commodityproperties,
      compositions,
      activesubstances,
      allergens
    } = context;
    const recipeCommodities = commodities.filter(comm => recipe.some(rec => rec.id.toString() === comm._id.toString()));
    setExtendedCommodities(
      ConfiguratorHelper.getExtendedCommodities(
        recipeCommodities,
        commoditycategories,
        commodityproperties,
        compositions,
        activesubstances,
        allergens
      )
    );
  }, []);

  return (
    <>
      {extendedCommodities.length === 0 ? (
        <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
      ) : (
        <div
          className="overflow-auto kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded"
          style={{ maxHeight: "75vh" }}
        >
          <table className="kt-datatable__table d-table">
            <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
              {extendedCommodities.map(commodity => (
                <tr className="kt-datatable__row d-table-row" key={commodity._id.toString()}>
                  <td className="kt-datatable__cell">
                    <div className="kt-user-card-v2">
                      <div className="kt-user-card-v2__details">
                        <span style={{ minWidth: 80 }} className="kt-user-card-v2__name">
                          {calculationUtils.formatAmount(
                            recipe.find(rec => rec.id.toString() === commodity._id.toString())!.amount,
                            2
                          )}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="kt-datatable__cell">
                    <div className="kt-user-card-v2">
                      <div className="kt-user-card-v2__details">
                        <span style={{ minWidth: 200 }} className="kt-user-card-v2__name">
                          {commodity.title.en}
                          <br />
                          <span className="kt-user-card-v2__email">{commodity.title.de}</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="kt-datatable__cell">
                    <div className="kt-user-card-v2">
                      <div className="kt-user-card-v2__details">
                        <span style={{ minWidth: 150 }} className="kt-user-card-v2__name">
                          {commodity.subtitle.en ? commodity.subtitle.en : "-"}
                          <br />
                          <span className="kt-user-card-v2__email">
                            {commodity.subtitle.de ? commodity.subtitle.de : "-"}
                          </span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="kt-datatable__cell" style={{ minWidth: 350 }}>
                    {ConfiguratorHelper.renderCommodityProperties(commodity)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default LoadRecipeModal;
