import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import packagingUtils from "../../utils/packagingUtils";
import { PackagingsDocument } from "../../model/packagings.types";
import baseUtils from "../../utils/baseUtils";

interface PackagingOverviewItemProps {
  packaging: PackagingsDocument;
}

class PackagingOverviewItem extends React.PureComponent<PackagingOverviewItemProps> {
  /**
   * Resolve packaging properties and description
   * @returns {object} object with packaging properties
   */
  resolvePackaging() {
    const { packaging } = this.props;
    const { packaging_type: type } = packaging;
    let packagingProperties: any = packagingUtils.resolveExtendedPackagingProperties(packaging) || {};
    packagingProperties.type = packagingUtils.getPackagingDescription(type);
    return packagingProperties;
  }

  /**
   * Render packaging properties
   * @param packagingProperties the packagig properties
   * @returns {JSX.element} rendered packagig properties
   */
  renderProperties(packagingProperties: any) {
    return (
      <>
        {Object.entries(packagingProperties).map(([key, value]) => {
          // Render properties, exclude type, price, url and color as they are handlded differently
          if (key === "type" || key === "price" || key === "url" || key === "color") return;
          return (
            <div key={key} className="kt-widget13__item">
              <span className="kt-widget13__desc">{packagingUtils.getPackagingDescription(key)}</span>
              <span className="kt-widget13__text kt-widget13__text--bold">{value as any}</span>
            </div>
          );
        })}
      </>
    );
  }

  /**
   * Render the color bdage
   * @param color the color
   * @returns {JSX.Element} color badge
   */
  renderColorBadge(color: string | undefined) {
    if (!color) return;
    let originColor = _.lowerFirst(color);
    const style = packagingUtils.getColorBadgeStyle(originColor);
    return (
      <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded" style={style}>
        <b>{originColor === "color" ? "Special Color" : _.upperFirst(originColor)}</b>
      </span>
    );
  }

  render() {
    const { packaging } = this.props;
    const lowestPrice = packagingUtils.getLowestPrice(packaging);
    const lowestDeliveryTime = packagingUtils.getLowestDeliveryTime(packaging);
    const packagingProperties: any = this.resolvePackaging();

    return (
      <div className="col-12 col-md-6 col-lg-4 col-xl-3" id={packaging._id.toString()}>
        <Link to={"/packaging/" + packaging._id.toString()}>
          <div className="kt-portlet kt-portlet--height-fluid kt-widget19 hov">
            <div className="kt-portlet__body kt-portlet__body--fit kt-portlet__body--unfill">
              <div
                className="kt-widget19__pic kt-portlet-fit--top kt-portlet-fit--sides previewImg"
                style={{ height: "200px" }}
              >
                <img className="packaging_img" src={packagingProperties.url} />
                {lowestDeliveryTime !== undefined && (
                  <span
                    className="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded"
                    style={{ position: "absolute", bottom: 5, left: 5, backgroundColor: "rgb(199,199,199)" }}
                  >
                    <i className="flaticon-truck kt-icon-xl mr-2 text-dark" />
                    <span className="font-weight-bold kt-font-md" style={{ color: "#50566a" }}>
                      {"Min. " + lowestDeliveryTime}
                    </span>
                  </span>
                )}
                {this.renderColorBadge(packagingProperties.color)}
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="kt-widget13" style={{ height: "100%" }}>
                <div className="kt-widget13__item">
                  <span className="kt-widget13__desc">Type</span>
                  <span className="kt-widget13__text kt-widget13__text--bold">{packagingProperties.type}</span>
                </div>
                {this.renderProperties(packagingProperties)}
                <div className="kt-widget13__item">
                  <span className="kt-widget13__desc">Price</span>
                  <span className="kt-widget13__text kt-widget13__text--bold">
                    {
                      <span className="text-green">
                        from {lowestPrice ? baseUtils.formatEuro(lowestPrice) : "-,-- €"}
                      </span>
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default PackagingOverviewItem;
