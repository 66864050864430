import React, { Component } from "react";

interface SearchBoxProps {
  title: string;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  additionalClasses?: string;
  idSuffix?: string;
  value?: string;
}

class SearchBox extends Component<SearchBoxProps, {}> {
  render() {
    const { additionalClasses, title, onSearch, placeholder, idSuffix, ...rest } = this.props;
    return (
      <div className="kt-input-icon kt-input-icon--left">
        <input
          type="text"
          className={"form-control " + additionalClasses}
          placeholder={placeholder}
          id={"kt_datatable_search_query" + (idSuffix ? idSuffix : "")}
          name={"searchbox" + (idSuffix ? idSuffix : "")}
          onChange={onSearch}
          {...rest}
        />
        <span className="kt-input-icon__icon kt-input-icon__icon--left">
          <i className="la la-search my-auto" title={title} />
        </span>
      </div>
    );
  }
}

export default SearchBox;
