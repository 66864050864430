import _ from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import ReplaceMaterialModal from "./ReplaceMaterialModal";
import { CustomOrder, PackagingOrderOptionInformation } from "../CustomTypes";
import userService from "../../../services/userService";
import accessUtils, { ACTIONS } from "../../../utils/accessUtils";
import orderUtils from "../../../utils/orderUtils";
import { ROLES } from "../../../utils/userdataUtils";
import RemoveMaterialModal from "./RemoveMaterialModal";
import { DataContext } from "../../../context/dataContext";
import calculationUtils from "../../../utils/calculationUtils";
import { CUSTOMER } from "../../../utils/commodityUtils";
import dbService, { UpdateAction, ORDERS } from "../../../services/dbService";
import orderCalculationUtils from "../../../utils/orderCalculationUtils";
import { T_PACKAGINGFROMCUSTOMER, T_PACKAGINGFROMSTOCK } from "../../../utils/timelineUtils";

interface PackagingOrderOptionsModalProps {
  order: CustomOrder;
  selectedPackaging: PackagingOrderOptionInformation;
  context: React.ContextType<typeof DataContext>;
  onClose: () => void;
}

interface PackagingOrderOptionsModalState {
  step: null | "customer" | "stock";
  stockPrice: string;
  customerAmount: string;
}

class PackagingOrderOptionsModal extends PureComponent<
  PackagingOrderOptionsModalProps,
  PackagingOrderOptionsModalState
> {
  totalAmount: number | null = null;
  constructor(props: PackagingOrderOptionsModalProps) {
    super(props);
    this.state = {
      step: null,
      stockPrice: "0",
      customerAmount: "0"
    };
  }

  componentDidMount() {
    this.initializeData();
  }

  componentDidUpdate(
    prevProps: Readonly<PackagingOrderOptionsModalProps>,
    prevState: Readonly<PackagingOrderOptionsModalState>,
    snapshot?: any
  ) {
    if (
      !_.isEqual(prevProps.order, this.props.order) ||
      !_.isEqual(prevProps.selectedPackaging, this.props.selectedPackaging)
    )
      this.initializeData();
  }

  /**
   * Initialize data
   */
  initializeData = () => {
    const { selectedPackaging, order, context } = this.props;
    const { packaging, price } = selectedPackaging;
    const manufacturer = order.settings.filler || order.settings.manufacturer;
    const pStock = context.packagingStock.filter(pS => pS.packaging.toString() === packaging._id.toString());
    const averagePrice = calculationUtils.getAverageStockPrice(pStock, manufacturer);
    let totalAmount = price ? price.amount * (1 + price.buffer / 100) * order.calculations[0].units : 0;
    this.totalAmount = totalAmount;
    this.setState({
      stockPrice: (Math.round(averagePrice * 100) / 100).toString(),
      customerAmount: totalAmount.toString()
    });
  };

  handleStep = (step: "customer" | "stock") => this.setState({ step });

  handleBack = () => this.setState({ step: null });

  handleStockValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove leading zeros
    let value = e.target.value.replaceAll(/^0+/g, "0");
    if (!value.includes(".")) value = Number(value).toString();
    this.setState({ stockPrice: value });
  };
  handleCustomerAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove leading zeros
    let value = e.target.value.replaceAll(/^0+/g, "0");
    if (!value.includes(".")) value = Number(value).toString();
    this.setState({ customerAmount: value });
  };

  handleUpdateOrder = async () => {
    if (!this.totalAmount) return;
    const { order, selectedPackaging, onClose } = this.props;
    const { step, stockPrice, customerAmount } = this.state;
    const calcPost = _.cloneDeep(order.calculations[0]);
    const pricePost = calcPost.packagings.find(p => p._id.toString() === selectedPackaging.packaging._id.toString())!;
    const commodityPrice = step === "stock" ? +stockPrice : 0;
    const orderQuantity = step === "customer" ? +customerAmount : this.totalAmount;
    const supplierType = step === "stock" ? "ownstock" : "customer";
    const userId = userService.getUserId();
    const currentDate = new Date();
    pricePost.ordered = step === "stock" ? currentDate : null;
    pricePost.userOrdered = step === "stock" ? userId : null;
    pricePost.delivered = step === "stock" ? currentDate : null;
    pricePost.userDelivered = step === "stock" ? userId : null;
    pricePost.eta = step === "stock" ? currentDate : pricePost.eta;
    pricePost.orderquantity = orderQuantity;
    pricePost.price = commodityPrice;
    pricePost.totalprice = commodityPrice * orderQuantity;
    pricePost.supplier = supplierType;
    const newCalculationInfo = orderCalculationUtils.recalculateInfoOnPackagingChanges(order, calcPost);
    const newState = orderUtils.checkOrderStateAfterPriceUpdate(order, pricePost);
    try {
      const timelineEntry = {
        id: new BSON.ObjectId(),
        type: step === "stock" ? T_PACKAGINGFROMSTOCK : T_PACKAGINGFROMCUSTOMER,
        date: new Date(),
        person: userService.getUserId(),
        packaging: selectedPackaging.packaging._id
      };
      const actions: Array<UpdateAction> = [
        {
          collection: ORDERS,
          filter: { _id: order._id },
          update: {
            "calculations.0.packagings.$[p].ordered": pricePost.ordered,
            "calculations.0.packagings.$[p].userOrdered": pricePost.userOrdered,
            "calculations.0.packagings.$[p].delivered": pricePost.delivered,
            "calculations.0.packagings.$[p].userDelivered": pricePost.userDelivered,
            "calculations.0.packagings.$[p].eta": pricePost.eta,
            "calculations.0.packagings.$[p].orderquantity": pricePost.orderquantity,
            "calculations.0.packagings.$[p].price": pricePost.price,
            "calculations.0.packagings.$[p].totalprice": pricePost.totalprice,
            "calculations.0.packagings.$[p].supplier": pricePost.supplier,
            "calculations.0.info": newCalculationInfo,
            state: newState ? newState : order.state
          },
          push: {
            timeline: timelineEntry
          },
          arrayFilters: [{ "p._id": pricePost._id }]
        }
      ];
      const result = await dbService.updatesAsTransaction(actions);
      if (result) {
        toast.success("Packaging successfully updated");
        onClose();
      } else toast.error("Packaging update failed");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    }
  };

  hasSupplierType = (type?: string) => {
    const { supplier } = this.props.selectedPackaging;
    if (type === "stock")
      return typeof supplier === "string" ? ["ownstock", "accumulatedstock"].includes(supplier) : false;
    else if (type === "customer") return supplier === "customer";
    else return typeof supplier !== "string";
  };

  render() {
    const { selectedPackaging, onClose, order, context } = this.props;
    const { step, stockPrice, customerAmount } = this.state;
    const { price, packaging } = selectedPackaging;
    const pOrders = context.packagingOrders.filter(pO => pO.packaging.toString() === packaging._id.toString());
    const manufacturerId = order.settings.filler?._id.toString() || order.settings.manufacturer._id.toString();
    const pLocalStock = context.packagingStock.filter(
      pS =>
        // Filter out stock of other packaging, stock in other locations and customer stock
        // Specifically do not include customer stock since then the user should click on "delivered by customer"
        pS.packaging.toString() === packaging._id.toString() &&
        pS.location.toString() === manufacturerId &&
        pS.supplier !== CUSTOMER
    );
    // check if stock at manufacturer is available and larger than the required amount of the packaging
    const stockAvailable =
      pLocalStock.reduce((a, b) => a + (b.amount > 0 && !b.disabled ? b.amount : 0), 0) > this.totalAmount!;
    // flag if packaging is ordered and an packaging order exists for the order
    const packagingOrderExists =
      !!price.ordered &&
      !!pOrders &&
      pOrders.some(o => o.relatedOrders.some((o2: any) => o2.toString() === order._id.toString()));
    const canOrder = accessUtils.canPerformAction(ACTIONS.ORDERPACKAGINGORDER);
    return (
      <Modal
        show={!!selectedPackaging}
        onHide={onClose}
        size={"sm"}
        centered
        name={"PackagingOrderOptionsModal"}
        backdropClassName={"customBackdrop"}
        style={{ zIndex: 950 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Packaging order options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {packagingOrderExists && !price.delivered ? (
            <span className="text-danger kt-font-bold mb-5 mt-2">
              <u>Packaging already ordered!</u>
              <br />
              <br />
              You are not able to change the corresponding order options. Please mind that already existing packaging
              orders cannot be changed by changing order options for this order.
              <br />
              <br />
            </span>
          ) : (
            <>
              {!packagingOrderExists && price.ordered && (
                <span className="text-warning kt-font-bold mb-5 mt-2">
                  <u>Packaging already ordered!</u>
                  <br />
                  <br />
                  Since no packaging order exists you are still able to change the corresponding order options. Please
                  keep in mind that corresponding order data may be overwritten.
                  <br />
                  This feature should be used with caution.
                  <br />
                  <br />
                </span>
              )}
              {step === null && (
                <>
                  <button
                    className={
                      "btn mb-2 d-block " +
                      (this.hasSupplierType("stock") ? "btn-success" : "btn-secondary") +
                      (stockAvailable ? "" : " disabled")
                    }
                    onClick={() => this.handleStep("stock")}
                    style={{ width: "100%" }}
                    disabled={!stockAvailable}
                    title={!stockAvailable ? "Not enough stock available" : ""}
                  >
                    Use Stock
                  </button>
                  <button
                    className={
                      "btn mb-2 d-block " + (this.hasSupplierType("customer") ? "btn-success" : "btn-secondary")
                    }
                    onClick={() => this.handleStep("customer")}
                    style={{ width: "100%" }}
                  >
                    Delivered by Customer
                  </button>
                  {canOrder ? (
                    <Link
                      to={"/packaging/" + packaging._id.toString() + "/" + order._id.toString()}
                      className={"btn mb-2 d-block " + (this.hasSupplierType() ? "btn-success" : "btn-secondary")}
                      style={{ width: "100%" }}
                    >
                      Regular Order
                    </Link>
                  ) : (
                    <button
                      className={
                        "btn mb-2 d-block disabled w-100 " +
                        (this.hasSupplierType("customer") ? "btn-success" : "btn-secondary")
                      }
                    >
                      Regular Order
                    </button>
                  )}
                  {userService.hasOneOfRoles([ROLES.PACKAGINGPROCUREMENT, ROLES.PROCUREMENT]) && (
                    <>
                      <ReplaceMaterialModal
                        material={packaging}
                        order={order}
                        type="packaging"
                        buttonCSSClasses="btn mb-2 d-block btn-secondary w-100"
                        callback={onClose}
                      />
                      <RemoveMaterialModal
                        material={packaging}
                        order={order}
                        type="packaging"
                        buttonCSSClasses="btn mb-2 d-block btn-secondary w-100"
                        successCallback={onClose}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
          {step === "stock" && (
            <>
              <br />
              <span className="kt-font-dark mt-2">If stock is used, a hypothetical purchase price is required.</span>
              <br />
              <br />
              <span className="text-success kt-font-bold mb-2">
                Since there is a stock for this packaging, the average price of the batches is used.
              </span>
              <br />
              <br />
              <div className="input-group mb-2 mt-2" style={{ width: "100%" }}>
                <input
                  type="number"
                  className="form-control d-block"
                  value={stockPrice}
                  min={0}
                  onChange={this.handleStockValue}
                />
                <div className="input-group-append">
                  <span className="input-group-text">€/ item</span>
                </div>
              </div>
            </>
          )}
          {step === "customer" && (
            <>
              <br />
              <span className="kt-font-dark mt-2">
                At least <b>{this.totalAmount!} pcs.</b> are required for this order. <br />
                What quantity will be delivered?
              </span>
              <br />
              <br />
              <div className="input-group mb-2 mt-2" style={{ width: "100%" }}>
                <input
                  type="number"
                  className="form-control"
                  value={customerAmount}
                  onChange={this.handleCustomerAmount}
                />
                <div className="input-group-append">
                  <span className="input-group-text">pcs.</span>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {step === null && (
            <button className="btn btn-secondary btn-sm btn-upper" onClick={onClose}>
              Close
            </button>
          )}
          {step !== null && (
            <button className="btn btn-secondary btn-sm btn-upper" onClick={this.handleBack}>
              Back
            </button>
          )}
          {(step === "stock" || step === "customer") && (
            <button className="btn btn-primary btn-sm" onClick={this.handleUpdateOrder}>
              Update Order
            </button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PackagingOrderOptionsModal;
