import React, { PureComponent } from "react";
import { DataContext } from "../../context/dataContext";
import CapacityHistory from "./general/CapacityHistory";
import GeneralVisualization from "./sales/GeneralVisualization";
import CapacityBarometer from "./general/CapacityBarometer";
import Workload from "./general/Workload";
import RecentActivities from "./general/RecentActivities";
import OverviewStats from "./general/OverviewStats";
import { Period } from "../common/CustomTypes";

interface GeneralDashboardProps {
  context: React.ContextType<typeof DataContext>;
  periodString: string;
  period: Period;
}

interface GeneralDashboardState {}

class GeneralDashboard extends PureComponent<GeneralDashboardProps, GeneralDashboardState> {
  render() {
    const { context, periodString, period } = this.props;
    const { orders, general } = context;
    return (
      <div className="row">
        <div className="col-12">
          <OverviewStats orders={orders} general={general} periodString={periodString} period={period} />
        </div>
        <div className="col-lg-6 col-xl-4">
          <CapacityHistory orders={orders} periodCount={6} />
          <GeneralVisualization orders={orders} />
        </div>
        <div className="col-lg-6 col-xl-4">
          <CapacityBarometer orders={orders} dashboard="general" />
          <Workload orders={orders} />
        </div>
        <div className="col-lg-6 col-xl-4">
          <RecentActivities context={context} />
        </div>
      </div>
    );
  }
}

export default GeneralDashboard;
