import _ from "lodash";
import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { ExtendedEMCommodity } from "../../../model/customTypes.types";
import commodityUtils from "../../../utils/commodityUtils";
import baseUtils from "../../../utils/baseUtils";

interface EmCommodityPriceGraphProps {
  commodity: ExtendedEMCommodity;
}

const EmCommodityPriceGraph: React.FunctionComponent<EmCommodityPriceGraphProps> = ({ commodity }) => {
  const data = commodity.priceGraphData;
  let absMin: number = Number.MAX_SAFE_INTEGER;
  let absMax: number = 0;
  if (data) {
    for (let i = 0; i < data.length; i++) {
      let min = _.minBy(data[i].data, val => val.y);
      let max = _.maxBy(data[i].data, val => val.y);
      if (min.y < absMin) {
        absMin = min.y;
      }
      if (max.y > absMax) {
        absMax = max.y;
      }
    }
  }
  absMax = absMax * 1.1;
  absMin = absMin * 0.9;
  if (absMin < 0) {
    absMin = 0;
  }

  return (
    <div className="kt-portlet ">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Price Graph</h3>
        </div>
        <div className="kt-portlet__head-toolbar" />
      </div>
      <div className="kt-portlet__body ">
        <div style={{ display: "block", width: "100%", height: 350 }}>
          <ResponsiveLine
            data={data}
            margin={{ top: 30, right: 50, bottom: 80, left: 50 }}
            xScale={{ type: "linear" }}
            yScale={{
              type: "linear",
              min: absMin,
              max: absMax,
              stacked: false,
              reverse: false
            }}
            tooltip={input => {
              return (
                <div
                  style={{
                    padding: 5,
                    backgroundColor: "white",
                    borderRadius: 5
                  }}
                >
                  <span className="kt-font-bold kt-font-dark">{input.point.serieId}</span>
                  <br />
                  <span className="kt-font-dark">
                    MOQ: {commodityUtils.resolveStockUnit(+input.point.data.x || 0, commodity.type)}
                  </span>
                  <br />
                  <span className="kt-font-dark">{baseUtils.formatEuro(+input.point.data.y || 0)}</span>
                </div>
              );
            }}
            yFormat=" >-$,.2f"
            xFormat=" >-#,.0f"
            curve="stepAfter"
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: 36,
              legendPosition: "middle",
              legend: "Quantity in kg"
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: -40,
              legendPosition: "middle",
              legend: "Price in €"
            }}
            pointSize={16}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            areaOpacity={0.05}
            useMesh={true}
          />
        </div>
      </div>
    </div>
  );
};

export default EmCommodityPriceGraph;
