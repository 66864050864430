import React, { useMemo } from "react";
import {
  getRawbidsCommodityProperties,
  getRawbidsCommodityPropertiesExcluded,
  getRawbidsCommodityProperty,
  RawbidsProperties
} from "../../utils/rawbidsUtils";
import { ColorsDocument } from "../../model/colors.types";
import baseUtils from "../../utils/baseUtils";
import { CompositionsDocument } from "../../model/compositions.types";
import { CommoditycategoriesDocument } from "../../model/commoditycategories.types";
import { SolventsDocument } from "../../model/solvents.types";
import CommodityCountryWidget from "./common/CommodityCountryWidget";
import { ActivesubstancesDocument } from "../../model/activesubstances.types";
import { AllergensDocument } from "../../model/allergens.types";
import { CommoditypropertiesDocument } from "../../model/commodityproperties.types";
import { CommoditiesDocument, RawbidsCommodity } from "../../model/commodities.types";
import { DataContext } from "../../context/dataContext";

interface RawbidsCommodityComparisonProps {
  commodity: CommoditiesDocument;
  rawbidsCommodity: RawbidsCommodity;
  context: React.ContextType<typeof DataContext>;
}

export const RawbidsCommodityComparison: React.FunctionComponent<RawbidsCommodityComparisonProps> = ({
  commodity,
  rawbidsCommodity,
  context
}) => {
  const { activesubstances, allergens, colors, commoditycategories, commodityproperties, compositions, solvents } =
    context;

  // Rawbids properties
  const rbCategory = useMemo(
    () => getRawbidsCommodityProperty(rawbidsCommodity, RawbidsProperties.CATEGORY),
    [rawbidsCommodity]
  );
  const rbComposition = useMemo(
    () => getRawbidsCommodityProperty(rawbidsCommodity, RawbidsProperties.COMPOSITION),
    [rawbidsCommodity]
  );
  const rbSolvent = useMemo(
    () => getRawbidsCommodityProperty(rawbidsCommodity, RawbidsProperties.SOLVENT),
    [rawbidsCommodity]
  );
  const rbAllergens = useMemo(
    () => getRawbidsCommodityProperties(rawbidsCommodity, RawbidsProperties.ALLERGEN),
    [rawbidsCommodity]
  );
  const rbOtherProps = useMemo(
    () =>
      getRawbidsCommodityPropertiesExcluded(rawbidsCommodity, [
        RawbidsProperties.CATEGORY,
        RawbidsProperties.COMPOSITION,
        RawbidsProperties.SOLVENT,
        RawbidsProperties.ALLERGEN,
        RawbidsProperties.ODOR
      ]),
    [rawbidsCommodity]
  );

  // AC properties
  const color: ColorsDocument | null = useMemo(
    () => (commodity.color ? baseUtils.getDocFromCollection(colors, commodity.color) : null),
    [commodity]
  );
  const composition: CompositionsDocument = useMemo(
    () => (commodity.form ? baseUtils.getDocFromCollection(compositions, commodity.form) : null),
    [commodity]
  );
  const category: CommoditycategoriesDocument = useMemo(
    () => (commodity.category ? baseUtils.getDocFromCollection(commoditycategories, commodity.category) : null),
    [commodity]
  );
  const sovlent: SolventsDocument = useMemo(
    () => (commodity.solvent ? baseUtils.getDocFromCollection(solvents, commodity.solvent) : null),
    [commodity]
  );

  return (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th style={{ width: "20%" }}>Value</th>
            <th style={{ width: "40%" }}>This Commodity</th>
            <th style={{ width: "40%" }}>Rawbids Commodity</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="align-middle">Title</td>
            <td className="align-middle">{commodity.title.en}</td>
            <td className="align-middle">{rawbidsCommodity.title.en}</td>
          </tr>
          <tr>
            <td className="align-middle">Subtitle</td>
            <td className="align-middle">{commodity.subtitle.en}</td>
            <td className="align-middle">{rawbidsCommodity.subtitle.en}</td>
          </tr>
          <tr>
            <td className="align-middle">Organic</td>
            <td className="align-middle">{commodity.organic ? "Yes" : "No"}</td>
            <td className="align-middle">
              {rawbidsCommodity.organic ? <span className="text-success">Yes</span> : "No"}
            </td>
          </tr>
          <tr>
            <td className="align-middle">Color</td>
            <td className="align-middle">{color?.name.en || "-"}</td>
            <td className="align-middle">{rawbidsCommodity.appearance.en || "-"}</td>
          </tr>
          <tr>
            <td className="align-middle">Category</td>
            <td className="align-middle">{category?.name.en || "-"} </td>
            <td className="align-middle">{rbCategory || "-"}</td>
          </tr>
          <tr>
            <td className="align-middle">Form</td>
            <td className="align-middle">{composition?.name.en || "-"} </td>
            <td className="align-middle">{rbComposition || "-"}</td>
          </tr>
          <tr>
            <td className="align-middle">Origin</td>
            <td className="align-middle">
              <CommodityCountryWidget countryCode={commodity.country} />
            </td>
            <td className="align-middle">
              <CommodityCountryWidget countryCode={rawbidsCommodity.country.code} />
            </td>
          </tr>
          <tr>
            <td className="align-middle">HS Code</td>
            <td className="align-middle">{commodity.hs_code || "-"}</td>
            <td className="align-middle">{rawbidsCommodity.hsCode || "-"}</td>
          </tr>
          <tr>
            <td className="align-middle">Cas Number</td>
            <td className="align-middle">{commodity.cas_number || "-"}</td>
            <td className="align-middle">{rawbidsCommodity.casNumber.join(", ") || "-"}</td>
          </tr>
          {(commodity.activesubstance.length > 0 || rawbidsCommodity.activeSubstances.length > 0) && (
            <tr>
              <td className="align-middle">Active Substances</td>
              <td className="align-middle">
                {commodity.activesubstance.length > 0
                  ? commodity.activesubstance.map(aS => {
                      const activeSubstance: ActivesubstancesDocument | undefined = baseUtils.getDocFromCollection(
                        activesubstances,
                        aS._id
                      );
                      return (
                        <div key={aS._id.toString()}>
                          {aS.value}% {activeSubstance?.name.en || "Unknown Substance"}
                        </div>
                      );
                    })
                  : "-"}
              </td>
              <td className="align-middle">
                {rawbidsCommodity.activeSubstances.length > 0
                  ? rawbidsCommodity.activeSubstances.map(aS => (
                      <div key={aS.substance._id}>
                        {aS.percentage}% {aS.substance.name.en}
                      </div>
                    ))
                  : "-"}
              </td>
            </tr>
          )}
          <tr>
            <td className="align-middle">Solvent</td>
            <td className="align-middle">{sovlent?.name.en || "-"}</td>
            <td className="align-middle">{rbSolvent || "-"}</td>
          </tr>
          {rawbidsCommodity.part.en.trim() ? (
            <tr>
              <td className="align-middle">Used Part</td>
              <td className="align-middle">-</td>
              <td className="align-middle">{rawbidsCommodity.part.en || "-"}</td>
            </tr>
          ) : null}
          {((rbAllergens && rbAllergens.length > 0) || commodity.allergens.length > 0) && (
            <tr>
              <td className="align-middle">Allergens</td>
              <td className="align-middle">
                {commodity.allergens.length > 0
                  ? commodity.allergens.map(id => {
                      const allergen: AllergensDocument | undefined = baseUtils.getDocFromCollection(allergens, id);
                      return <div key={id.toString()}>{allergen?.name.en || "Unknown Allergen"}</div>;
                    })
                  : "-"}
              </td>
              <td className="align-middle">
                {rbAllergens && rbAllergens.length > 0 ? rbAllergens.map(all => <div key={all}>{all}</div>) : "-"}
              </td>
            </tr>
          )}
          <tr>
            <td className="align-middle">Additional Properties</td>
            <td className="align-middle">
              {commodity.properties.length > 0
                ? commodity.properties.map(id => {
                    const property: CommoditypropertiesDocument | undefined = baseUtils.getDocFromCollection(
                      commodityproperties,
                      id
                    );
                    if (!property) return null;
                    return <div key={id.toString()}>{property.name.en || ""}</div>;
                  })
                : "-"}
            </td>
            <td className="align-middle">
              {rawbidsCommodity.vegetarian ? <div>vegetarian</div> : ""}
              {rawbidsCommodity.vegan ? <div>vegan</div> : ""}
              {rawbidsCommodity.kosher ? <div>kosher</div> : ""}
              {rawbidsCommodity.halal ? <div>halal</div> : ""}
              {rbOtherProps && rbOtherProps.length > 0 ? rbOtherProps.map(prop => <div key={prop}>{prop}</div>) : "-"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
