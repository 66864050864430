import { BSON } from "realm-web";
import authenticationService from "./authenticationService";
import { UserdataDocument } from "../model/userdata.types";
import { ROLES } from "../utils/userdataUtils";
import { PersonSnapshot } from "../model/warehouse/common.types";

function getUser() {
  return authenticationService.getUser();
}
function getUserMail() {
  return authenticationService.getUser()?.profile.email;
}

function getUserData(): UserdataDocument {
  return authenticationService.getUser()?.customData as any;
}

function getUserId(): BSON.ObjectId {
  return new BSON.ObjectId(authenticationService.getUser()?.customData._id as any);
}

function getUserSnapshot(): PersonSnapshot {
  const data = authenticationService.getUser()?.customData;
  return {
    _id: new BSON.ObjectId(data?._id as any),
    prename: (data?.prename as string) ?? "Unknown",
    surname: (data?.surname as string) ?? "User"
  };
}

/**
 * Check if the current user has the needed role.
 * @param roleNeeded: Role that the user has to have
 * @param ignoreAdminRole: Optional, indicates whether the admin rights should overwrite other checks or not
 * @returns { boolean } Indicating whether the user is authorized for the role (or admin)
 */
function isAuthorizedForAction(roleNeeded: string, ignoreAdminRole?: boolean) {
  const role = getUserData()?.role as Array<string>;
  return (!ignoreAdminRole && role.includes(ROLES.ADMIN)) || role.includes(roleNeeded);
}

/**
 * Check if a user has a specific role
 * @param role the role to check for
 * @param excludeAdmin exclude admin from check, means it has only the specific role
 * @returns { boolean } Indicating whether it has the role or not
 */
function hasRole(role: string, excludeAdmin?: boolean) {
  const userRole = getUserData()?.role as Array<string>;
  if (excludeAdmin) return userRole && userRole.includes(role) && !userRole.includes(ROLES.ADMIN);
  return userRole && (userRole.includes(role) || userRole.includes(ROLES.ADMIN));
}

/**
 * Check if a user exclusively has a specific role
 * @param role the role to check for
 * @returns { boolean } Indicating whether the user has only the given role
 */
function hasExclusiveRole(role: string) {
  const userRole = getUserData()?.role as Array<string>;
  return userRole && userRole.length === 1 && userRole.includes(role);
}

/**
 * Check if a user has at least one of the specified roles
 * @param roles: Roles that should be checked
 * @param excludeAdmin: Optional, if set only the mentioned roles return true
 */
function hasOneOfRoles(roles: Array<string>, excludeAdmin?: boolean) {
  const userRole = getUserData()?.role as Array<string>;
  return roles.some(r => userRole && (userRole.includes(r) || (!excludeAdmin && userRole.includes(ROLES.ADMIN))));
}

/**
 * Check if a user is an Admin
 * @returns {boolean} true if user is an admin, else false
 */
function isAdmin() {
  const userRole = getUserData()?.role as Array<string>;
  if (!userRole) return false;
  return userRole.includes(ROLES.ADMIN);
}

function getCompanyId() {
  return getUserData().company_id;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUser,
  getUserId,
  getUserSnapshot,
  getUserMail,
  getUserData,
  getCompanyId,
  isAuthorizedForAction,
  hasExclusiveRole,
  hasRole,
  hasOneOfRoles,
  isAdmin
};
