import { BSON } from "realm-web";
import { BatchFile, SenderType } from "./batch.types";
import { CompaniesAddress } from "../companies.types";
import { LanguageObject } from "../common.types";

export enum NotificationOrderType {
  CUSTOMER_ORDER = "customerOrder",
  PURCHASE_ORDER = "purchaseOrder"
}

export enum AvisUnit {
  KG = "kg",
  UNITS = "units",
  PCS = "pcs"
}

export enum AvisType {
  COMMODITY = "commodity",
  PACKAGING = "packaging",
  PRODUCT = "product"
}

export enum NotificationState {
  CREATED = "created",
  ARRIVED = "arrived"
}

export interface NotificationOrder {
  reference: string; // ID of the related order
  type: NotificationOrderType;
}

export interface NotificationContentSnapshot {
  _id: BSON.ObjectId;
  title: LanguageObject;
  subtitle: LanguageObject;
}

export interface Notification {
  _id: BSON.ObjectId;
  order?: NotificationOrder;
  content: {
    type: AvisType;
    details: NotificationContentSnapshot;
  };
  totalAmount: {
    value: number;
    unit: AvisUnit;
  };
  state: NotificationState;
  estimatedDeliveryDate: Date; // when the order will probably arrive
}

export interface Sender {
  _id: BSON.ObjectId; // the id of the customer or supplier
  type: SenderType;
  referenceNumber: string; // the number the supplier/customer/manufacturer authenticated with
  name: string;
  address: CompaniesAddress; // addressObject with street, streetnr, zip, city and country (only city and country for manufacturer)
}

export interface DeliveryAnnouncement {
  _id: BSON.ObjectId;
  sender: Sender;
  notification: Array<Notification>;
  files: Array<BatchFile>;
  shipmentCode: string; // unique shipment code for reference in deliveries
  created: Date; // timestamp when notification was created
  lastUpdate: Date;
}
