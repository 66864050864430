import React from "react";
import _ from "lodash";
import * as Realm from "realm-web";
import { BSON } from "realm-web";
import { toAbsoluteUrl } from "../_metronic";
import {
  MinimumCalendarPackaging,
  PackagingPrice,
  PackagingsDocument,
  PackagingTimeline
} from "../model/packagings.types";
import dbPackagingService from "../services/dbServices/dbPackagingService";
import diffUtils from "./diffUtils";
import { CustomPackagingsDocument, SelectedPackagingsDocument } from "../components/configurator/CustomTypes";
import PackagingDescriptions from "../components/configurator/packagingDescriptions.json";
import { TORSOSELECTION } from "../components/configurator/configuratorConstants";
import authenticationService from "../services/authenticationService";
import { PackagingStockDocument } from "../model/packagingStock.types";
import { CUSTOMER } from "./commodityUtils";
import { PackagingOrderDocument } from "../model/packagingOrders.types";
import { OrdersDocument } from "../model/orders.types";
import { ORDERORDERCOMMODITIES, PRODUCTION, PRODUCTIONQUEUE, WAITING } from "./orderUtils";
import { DataContext } from "../context/dataContext";
import { LocalPackagingStock } from "../components/packaging/CustomTypes";
import { PACKAGINGORDERS, UpdateAction } from "../services/dbService";
import { CustomOrder } from "../components/order/CustomTypes";
import { StickerForms } from "../components/packaging/PackagingHelper";
import { CommoditiesDocument } from "../model/commodities.types";

export const T_BOTTLE = "bottle";
export const T_LIQUIDBOTTLE = "liquidbottle";
export const T_BAG = "bag";
export const T_BLISTER = "blister";
export const T_BOX = "box";
export const T_LID = "lid";
export const T_PIPETTE = "pipette";
export const T_SPRAYPUMP = "sprayPump";
export const T_SLEEVE = "sleeve";
export const T_LABEL = "label";
export const T_MULTILAYERLABEL = "multilayer_label";
export const T_STICKER = "sticker";
export const T_PACKAGEINSERT = "packageInsert";
export const T_SPOON = "spoon";
export const T_SILICAGELBAG = "silicaGelBag";

export const T_PACKAGINGUNIT = "mm";
export const T_LIQUIDPACKAGINGUNIT = "ml";
export const T_CAPSULEUNIT = "pcs";
export const T_GRAMM = "g";

export const ALL_PACKAGING_TYPES = [
  T_BOTTLE,
  T_LIQUIDBOTTLE,
  T_BAG,
  T_BLISTER,
  T_BOX,
  T_LID,
  T_PIPETTE,
  T_SPRAYPUMP,
  T_SLEEVE,
  T_LABEL,
  T_MULTILAYERLABEL,
  T_STICKER,
  T_PACKAGEINSERT,
  T_SPOON,
  T_SILICAGELBAG
];

export const TRANSLATED_PACKAGING_TYPES: { [type: string]: string } = {
  bottle: "Bottle",
  lid: "Lid",
  bag: "Bag",
  blister: "Blister",
  box: "Box",
  label: "Label",
  multilayer_label: "Multilayer Label",
  sleeve: "Sleeve",
  liquidbottle: "Bottle (liquid)",
  sprayPump: "Spray Pump",
  pipette: "Pipette",
  sticker: "Sticker",
  packageInsert: "Package Insert",
  spoon: "Spoon",
  silicaGelBag: "Silica Gel Bag"
};

export const PACKAGING_TYPES = [
  { value: "", label: "All Packaging" },
  { value: T_BOTTLE, label: TRANSLATED_PACKAGING_TYPES.bottle },
  { value: T_LID, label: TRANSLATED_PACKAGING_TYPES.lid },
  { value: T_BAG, label: TRANSLATED_PACKAGING_TYPES.bag },
  { value: T_BLISTER, label: TRANSLATED_PACKAGING_TYPES.blister },
  { value: T_BOX, label: TRANSLATED_PACKAGING_TYPES.box },
  { value: T_LABEL, label: TRANSLATED_PACKAGING_TYPES.label },
  { value: T_MULTILAYERLABEL, label: TRANSLATED_PACKAGING_TYPES.multilayer_label },
  { value: T_SLEEVE, label: TRANSLATED_PACKAGING_TYPES.sleeve },
  { value: T_LIQUIDBOTTLE, label: TRANSLATED_PACKAGING_TYPES.liquidbottle },
  { value: T_PIPETTE, label: TRANSLATED_PACKAGING_TYPES.pipette },
  { value: T_SPRAYPUMP, label: TRANSLATED_PACKAGING_TYPES.sprayPump },
  { value: T_STICKER, label: TRANSLATED_PACKAGING_TYPES.sticker },
  { value: T_PACKAGEINSERT, label: TRANSLATED_PACKAGING_TYPES.packageInsert },
  { value: T_SPOON, label: TRANSLATED_PACKAGING_TYPES.spoon },
  { value: T_SILICAGELBAG, label: TRANSLATED_PACKAGING_TYPES.silicaGelBag }
];

// safety distance 2 mm
const LABELSAFETYDISTANCE = 2;

export const ROOTKEYS: Array<keyof PackagingsDocument> = [
  "packaging_type",
  "packaging_norm",
  "packaging_shape",
  "packaging_material",
  "packaging_color",
  "packaging_volume",
  "packaging_height",
  "packaging_width",
  "packaging_label_height",
  "packaging_neck",
  "label_width",
  "label_height",
  "label_quality",
  "lid_shape",
  "lid_material",
  "lid_color",
  "lid_size",
  "bag_shape",
  "bag_material",
  "bag_zipper",
  "bag_volume",
  "bag_width",
  "bag_height",
  "bag_color",
  "blister_capsules",
  "blister_width",
  "blister_height",
  "blister_depth",
  "box_quality",
  "box_width",
  "box_height",
  "box_depth",
  "sleeve_size",
  "sleeve_print",
  "note",
  "article_number",
  "diameter",
  "weight",
  "capacity",
  "form",
  "quality"
];

const PRICEKEYS: Array<
  keyof {
    moq: number;
    price: number;
    note: string;
    deliverytime: number;
  }
> = ["moq", "price", "note", "deliverytime"];

/**
 * Updates a packaging and saves a timeline object with all made changes to it.
 * @param packagingPre Packaging before update
 * @param packagingPost Packaging after update
 * @returns Result of the query
 */
async function updatePackagingWithTimeline(packagingPre: PackagingsDocument, packagingPost: PackagingsDocument) {
  const timeline: PackagingTimeline = {
    person: new BSON.ObjectId(authenticationService.getUserDataID().toString()),
    date: new Date(),
    pre: {},
    post: {}
  };
  const update: any = {};
  const unset: any = {};

  diffForRootValues(packagingPre, packagingPost, timeline, update, unset);
  diffForSuppliers(packagingPre, packagingPost, timeline, update);
  diffUtils.diffForSpecifications(packagingPre, packagingPost, timeline, update);

  if (_.isEmpty(update) && _.isEmpty(unset)) return true;

  return await dbPackagingService.updatePackaging(packagingPre._id, update, timeline, unset);
}

/**
 * Check root values for differences.
 * @param packagingPre Packaging before update
 * @param packagingPost Packaging after update
 * @param timeline Timeline entry that contains the diff
 * @param update Update object for MongoDB
 * @param unset Unset object for MongoDB
 */
function diffForRootValues(
  packagingPre: PackagingsDocument,
  packagingPost: PackagingsDocument,
  timeline: PackagingTimeline,
  update: any,
  unset: any
) {
  for (let key of ROOTKEYS) {
    if (
      (!packagingPre[key] && packagingPost[key]) ||
      (packagingPre[key] && !packagingPost[key]) ||
      (packagingPre[key] && packagingPost[key] && packagingPre[key] !== packagingPost[key])
    ) {
      // @ts-ignore
      timeline.pre[key] = packagingPre[key];
      // @ts-ignore
      timeline.post[key] = packagingPost[key];
      // Unset values when new value is undefined and it was previously set
      if (packagingPre[key] && packagingPost[key] === undefined) unset[key] = "";
      else update[key] = packagingPost[key];
    }
  }
  let imagesChanged = false;
  for (let image of packagingPost.images) {
    if (!packagingPre.images.includes(image)) {
      if (!timeline.post.images) {
        timeline.post.images = [];
      }
      timeline.post.images.push(image);
      imagesChanged = true;
    }
  }
  for (let image of packagingPre.images) {
    if (!packagingPost.images.includes(image)) {
      if (!timeline.pre.images) {
        timeline.pre.images = [];
      }
      timeline.pre.images.push(image);
      imagesChanged = true;
    }
  }
  if (imagesChanged) {
    update.images = packagingPost.images;
  }
}

/**
 * Check suppliers for differences.
 * @param packagingPre Packaging before update
 * @param packagingPost Packaging after update
 * @param timeline Timeline entry that contains the diff
 * @param update Update object for MongoDB
 */
function diffForSuppliers(
  packagingPre: PackagingsDocument,
  packagingPost: PackagingsDocument,
  timeline: PackagingTimeline,
  update: any
) {
  let supplierUpdated = false;
  const suppliersOld = packagingPre.suppliers.map(s => s._id.toString());
  for (let supplier of packagingPost.suppliers) {
    if (!suppliersOld.includes(supplier._id.toString())) {
      if (!timeline.post.prices) {
        timeline.post.prices = [];
      }
      for (let price of supplier.prices) {
        timeline.post.prices.push({ supplier: supplier._id, id: price._id, price: price.price, moq: price.moq });
        supplierUpdated = true;
      }
    } else {
      const supplierPre = packagingPre.suppliers.find(s => s._id.toString() === supplier._id.toString())!;
      for (let price of supplier.prices) {
        const pricePre = supplierPre.prices.find(p => p._id.toString() === price._id.toString());
        let changePre: any = { supplier: supplier._id, id: price._id, price: price.price, moq: price.moq };
        const changePost: any = { supplier: supplier._id, id: price._id, price: price.price, moq: price.moq };
        let changed = false;
        if (pricePre) {
          for (let value of PRICEKEYS) {
            if (price[value] !== pricePre[value]) {
              changed = true;
              changePost[value] = price[value];
              changePre[value] = pricePre[value];
            }
          }
        } else {
          changePre = null;
          changed = true;
        }
        if (changed) {
          if (changePre && !timeline.pre.prices) {
            timeline.pre.prices = [];
          }
          if (!timeline.post.prices) {
            timeline.post.prices = [];
          }
          if (changePre) timeline.pre.prices!.push(changePre);
          timeline.post.prices.push(changePost);
          supplierUpdated = true;
        }
      }
    }
  }
  const suppliersNew = packagingPost.suppliers.map(s => s._id.toString());
  for (let supplier of packagingPre.suppliers) {
    if (!suppliersNew.includes(supplier._id.toString())) {
      if (!timeline.pre.prices) {
        timeline.pre.prices = [];
      }
      for (let price of supplier.prices) {
        timeline.pre.prices.push({ supplier: supplier._id, id: price._id, price: price.price, moq: price.moq });
        supplierUpdated = true;
      }
    } else {
      const pricesNew = packagingPost.suppliers
        .find(s => s._id.toString() === supplier._id.toString())!
        .prices.map(p => p._id.toString());
      for (let price of supplier.prices) {
        if (!pricesNew.includes(price._id.toString())) {
          if (!timeline.pre.prices) {
            timeline.pre.prices = [];
          }
          timeline.pre.prices.push({ supplier: supplier._id, id: price._id, price: price.price, moq: price.moq });
          supplierUpdated = true;
        }
      }
    }
  }
  if (supplierUpdated) {
    update.suppliers = packagingPost.suppliers;
  }
}

/**
 * Resolves the packaging type of the referenced packaging.
 * @param _id: ID of the packaging
 * @param packaging: Collection of packaging
 * @returns { string } Packaging type
 */
function resolvePackagingType(_id: BSON.ObjectId, packaging: Array<PackagingsDocument>) {
  const packagingObject = packaging.find(p => p._id.toString() === _id.toString());
  if (packagingObject) {
    return packagingObject.packaging_type;
  }
  return "unknown";
}

function getColors(packaging: Array<CustomPackagingsDocument> | Array<PackagingsDocument>): Array<string> {
  let colors: Array<string> = packaging.map(pack => {
    const keys = Object.keys(pack);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (key.includes("color") && _.get(pack, key) && _.get(pack, key).trim() !== "") {
        return _.get(pack, key);
      }
    }
  });
  return Array.from(new Set(colors))
    .filter(x => x)
    .sort();
}

/**
 * Concat all useful information about the given packaging
 * @param packaging the packaging object
 * @param separator for join function
 * @returns string with all information concatenated as one string
 */
function concatPackagingInfo(
  packaging: CustomPackagingsDocument | SelectedPackagingsDocument,
  separator = " "
): string {
  let info: Array<string | undefined> = [];
  switch (packaging.packaging_type) {
    case T_BOTTLE:
      info = [
        packaging.packaging_shape!,
        packaging.packaging_volume + " ml",
        packaging.packaging_neck,
        packaging.packaging_material!,
        packaging.packaging_color!,
        packaging.packaging_label_height!,
        packaging.packaging_height!,
        packaging.packaging_width!
      ];
      break;
    case T_LIQUIDBOTTLE:
      info = [
        `${packaging.packaging_volume} ml`,
        packaging.packaging_material!,
        packaging.packaging_color!,
        packaging.packaging_label_height!,
        packaging.packaging_height!,
        packaging.packaging_width!,
        packaging.packaging_neck
      ];
      break;
    case T_BOX:
      info = [
        `${packaging.box_width} x ${packaging.box_height} x ${packaging.box_depth} mm (W x H x D)`,
        packaging.box_quality!
      ];
      break;
    case T_LID:
      info = [
        packaging.lid_shape!,
        Array.from(new Set([packaging.lid_material!, packaging.lid_color!])).join(separator),
        packaging.lid_size
      ];
      break;
    case T_BAG:
      info = [
        packaging.bag_shape!,
        packaging.bag_material!,
        packaging.bag_color!,
        `${packaging.bag_width} x ${packaging.bag_height} mm (W x H), ${packaging.bag_volume} ml`,
        packaging.bag_zipper!
      ];
      break;
    case T_BLISTER:
      info = [
        `${packaging.blister_width} x ${packaging.blister_height} x ${packaging.blister_depth} mm (W x H x D)`,
        packaging.blister_capsules
      ];
      break;
    case T_LABEL:
    case T_MULTILAYERLABEL:
      info = [packaging.label_quality!, `${packaging.label_width} x ${packaging.label_height} mm (W x H)`];
      break;
    case T_SLEEVE:
      info = [`${packaging.sleeve_size} mm`];
      break;
    case T_PIPETTE:
      info = [packaging.packaging_height + " mm", packaging.packaging_neck, packaging.packaging_color!];
      break;
    case T_SPRAYPUMP:
      info = [
        packaging.packaging_color!,
        "DIN " + packaging.packaging_norm,
        packaging.packaging_height + " mm",
        packaging.packaging_neck
      ];
      break;
    case T_STICKER:
      info = [
        packaging.form!,
        packaging.quality!,
        packaging.form === StickerForms.ROUND
          ? `⌀ ${packaging.diameter} mm`
          : `${packaging.packaging_width} x ${packaging.packaging_height} mm (W x H)`
      ];
      break;
    case T_SPOON:
      info = [packaging.packaging_color!, packaging.capacity! + " g"];
      break;
    case T_SILICAGELBAG:
      info = [packaging.weight! + " g"];
      break;
  }
  info.push(packaging.note);
  // filter undefined entries
  info = info.filter(entry => {
    return entry !== undefined;
  });
  info.unshift(packaging.packaging_type);
  // return concatenated string
  return info.join(separator);
}

function getShortPackagingInfo(
  packaging: CustomPackagingsDocument | SelectedPackagingsDocument | MinimumCalendarPackaging
): string {
  let info: Array<string | undefined> = [];
  switch (packaging.packaging_type) {
    case T_BOTTLE:
      info = [packaging.packaging_volume + " ml"];
      break;
    case T_LIQUIDBOTTLE:
      info = [`${packaging.packaging_volume} ml`];
      break;
    case T_BOX:
      info = [packaging.box_quality!];
      break;
    case T_LID:
      info = [packaging.lid_size!];
      break;
    case T_BAG:
      info = [packaging.bag_volume + " ml"];
      break;
    case T_BLISTER:
      info = [packaging.blister_capsules + " Caps."];
      break;
    case T_LABEL:
    case T_MULTILAYERLABEL:
      info = [packaging.label_quality!];
      break;
    case T_SLEEVE:
      info = [`${packaging.sleeve_size} mm`];
      break;
    case T_PIPETTE:
      info = [packaging.packaging_height + " mm"];
      break;
    case T_SPRAYPUMP:
      info = ["DIN " + packaging.packaging_norm];
      break;
    case T_STICKER:
      info = [packaging.form, packaging.quality];
      break;
    case T_SPOON:
      info = [packaging.capacity + " g"];
      break;
    case T_SILICAGELBAG:
      info = [packaging.weight + " g"];
      break;
  }
  // filter undefined entries
  info = info.filter(entry => {
    return entry !== undefined;
  });
  // return concatenated string
  return TRANSLATED_PACKAGING_TYPES[packaging.packaging_type] + (info.length > 0 ? ` (${info.join(", ")})` : "");
}

/**
 * Return the translation for a given packaging type
 * @param packaging a packaging type
 * @returns {string} translation for the given type
 */
function getPackagingType(packaging: string) {
  return packaging in TRANSLATED_PACKAGING_TYPES ? TRANSLATED_PACKAGING_TYPES[packaging] : "Unknown packaging";
}

/**
 * Determine if a packaging matches the filter or not
 * @param packaging the packaging object
 * @param key key of the value to check
 * @param filter value of the filter
 * @returns true if value exists and matches the filter, else false
 */
function filterPackaging(packaging: Array<CustomPackagingsDocument>, key: string, filter: string) {
  return packaging.filter(pack => {
    const value = getPackagingValue(pack, key);
    return value && value.trim().toLowerCase() === filter.trim().toLowerCase();
  });
}

/**
 * Get the value for a given key from a packaging object
 * @param packaging the packaging object
 * @param key key to get the value for or substring of a key
 * @returns value or null if it does not exist
 */
function getPackagingValue(packaging: any, key: string) {
  if (packaging.hasOwnProperty(key)) return packaging[key];
  else {
    const existingKey = Object.keys(packaging).find(packKey => packKey.includes(key));
    if (existingKey) return packaging[existingKey];
  }
  return null;
}

/**
 * Find the lowest price in suppliers of a packaging document
 * @param packaging a packaging document
 * @returns the lowest price of all suppliers or undefined if no prices exist
 */
function getLowestPrice(packaging: CustomPackagingsDocument | SelectedPackagingsDocument) {
  let price;
  for (let supplier of packaging.suppliers) {
    for (let sPrice of supplier.prices) {
      let sPriceNum = Math.round(sPrice.price * 100) / 100;
      if (!price || sPriceNum < price) {
        price = sPriceNum;
      }
    }
  }
  return price;
}

/**
 * Get the Date of the most recent updated price for last updated display
 * @param suppliers Arrays of suppliers and there prices
 * @returns {Date|null} the date of the most recent updated price
 */
function findLatestPriceUpdate(
  suppliers: Array<{
    _id: BSON.ObjectId;
    prices: Array<PackagingPrice>;
  }>
) {
  const prices = suppliers.map(s => s.prices.map(p => p.date)).reduce((prices, sp) => prices.concat(sp), []);
  const latestPrice = prices.reduce((date, up) => (up && up.getTime() > date!.getTime() ? up : date), new Date(0));
  return latestPrice && latestPrice.getTime() > 0 ? latestPrice : null;
}

/**
 * Find the lowest price in suppliers of a packaging document
 * @param packaging a packaging document
 * @returns {supplier:PackagingSupplier|undefined,price:number|undefined,moq:number|undefined} the lowest price of all suppliers or undefined if no prices exist
 */
function getLowestPriceMOQ(packaging: CustomPackagingsDocument | SelectedPackagingsDocument) {
  let price;
  let lpSupplier;
  let lpMOQ;
  for (let supplier of packaging.suppliers) {
    for (let sPrice of supplier.prices) {
      let sPriceNum = Math.round(sPrice.price * 100) / 100;
      if (!price || sPriceNum < price) {
        price = sPriceNum;
        lpSupplier = supplier;
        lpMOQ = sPrice.moq;
      }
    }
  }

  return { supplier: lpSupplier, price: price, moq: lpMOQ };
}
/**
 * Get the lowest delivery times from all suppliers
 * @param packaging a packaging document
 * @returns the lowest delivery time of all suppliers or undefined if no suppliers exist
 */
function getLowestDeliveryTime(packaging: CustomPackagingsDocument | SelectedPackagingsDocument) {
  let deliveryTime;
  for (let supplier of packaging.suppliers) {
    for (let sPrice of supplier.prices) {
      if (!deliveryTime || sPrice.deliverytime < deliveryTime) deliveryTime = sPrice.deliverytime;
    }
  }
  return deliveryTime;
}

/**
 * Checks if a torso packaging fits for current volume or amount per unit
 * @param packaging the torso packaging object to check
 * @param volume volume of capsule or tablet
 * @param amountPerUnit number of capsules or tablets
 * @returns tuple with True/False if packaging fits and the capacity of the packaging
 */
function compareTorsoAndVolume(
  packaging: CustomPackagingsDocument,
  volume: number,
  amountPerUnit: number
): [boolean, number] {
  const requiredVolume = volume * +amountPerUnit * 2;
  if (["bottle", "liquidbottle"].includes(packaging.packaging_type))
    return [+packaging.packaging_volume! >= requiredVolume, +packaging.packaging_volume!];
  else if (packaging.packaging_type === "bag")
    return [+packaging.bag_volume! >= requiredVolume, +packaging.bag_volume!];
  else if (packaging.packaging_type === "blister")
    return [amountPerUnit % +packaging.blister_capsules! === 0, +packaging.blister_capsules!];
  else return [false, -1];
}

/**
 * Get all stock for a location
 * @param packaging packaging document
 * @param packagingStock stock for packaging
 * @param manufacturer manufacturer id
 * @returns highest stock amount
 */
function getAllStock(
  packaging: CustomPackagingsDocument | SelectedPackagingsDocument,
  packagingStock: Array<PackagingStockDocument>,
  manufacturer: BSON.ObjectId
) {
  const pLocalStock = packagingStock.filter(
    pS =>
      // Filter out stock of other packaging, stock in other locations and customer stock
      // Specifically do not include customer stock since then the user should click on "delivered by customer"
      pS.packaging.toString() === packaging._id.toString() &&
      pS.location.toString() === manufacturer.toString() &&
      pS.supplier !== CUSTOMER &&
      pS.amount > 0 &&
      !pS.disabled
  );
  return pLocalStock.reduce((a, b) => a + b.amount, 0);
}

/**
 * Calculates the available stock of a packaging at given manufacturer
 * @param packaging the packaging for which the stock needs to be calculated
 * @param packagingStock all Packaging Stocks
 * @param packagingOrders all Packaging orders
 * @param orders all Orders
 * @param manufacturer the given manufacturer
 * @returns available stock amount for the given packaging and manufacturer
 */
function getAvailableStock(
  packaging: CustomPackagingsDocument | SelectedPackagingsDocument,
  packagingStock: Array<PackagingStockDocument>,
  packagingOrders: Array<PackagingOrderDocument>,
  orders: Array<OrdersDocument>,
  manufacturer: BSON.ObjectId | string
) {
  const usageMap: {
    requiredAmount: number;
    orderedAmount: number;
  } = {
    requiredAmount: 0,
    orderedAmount: 0
  };
  const filteredPackagingOrders = packagingOrders.filter(
    pO => pO.packaging.toString() === packaging._id.toString() && pO.destination.toString() === manufacturer.toString()
  );
  for (let k = 0; k < filteredPackagingOrders.length; k++) {
    const o = filteredPackagingOrders[k];
    if (!o.delivered) {
      usageMap.orderedAmount += o.orderQuantity;
    }
  }
  const filteredOrders = orders.filter(
    o =>
      (o.settings.filler || o.settings.manufacturer.toString()) === manufacturer.toString() &&
      [ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION].includes(o.state)
  );
  for (let i = 0; i < filteredOrders.length; i++) {
    const order = filteredOrders[i];
    const price = order.calculations[0].packagings.find(p => p._id.toString() === packaging._id.toString());
    if (price && price.supplier !== CUSTOMER) {
      usageMap.requiredAmount += price.amount * (1 + price.buffer / 100) * order.calculations[0].units;
    }
  }
  const pLocalStock = packagingStock.filter(
    pS =>
      // Filter out stock of other packaging, stock in other locations and customer stock
      // Specifically do not include customer stock since then the user should click on "delivered by customer"
      pS.packaging.toString() === packaging._id.toString() &&
      pS.location.toString() === manufacturer.toString() &&
      pS.supplier !== CUSTOMER &&
      pS.amount > 0 &&
      !pS.disabled
  );
  return pLocalStock.reduce((a, b) => a + b.amount, 0) + usageMap.orderedAmount - usageMap.requiredAmount;
}

/**
 * Checks if a torso packaging fits for the given volume with respect to a given margin
 * @param packaging the torso packaging object to check
 * @param volume volume of the recipe
 * @param margin margin of error to use for a lower limit
 * @returns tuple with True/False if packaging fits and the capacity of the packaging
 */
function compareTorsoAndVolumeWithMargin(
  packaging: CustomPackagingsDocument,
  volume: number,
  margin: number
): [boolean, number] {
  const lowerLimit = volume * (1 - margin);
  if (["bottle", "liquidbottle"].includes(packaging.packaging_type))
    return [+packaging.packaging_volume! >= lowerLimit, +packaging.packaging_volume!];
  else if (packaging.packaging_type === "bag") return [+packaging.bag_volume! >= lowerLimit, +packaging.bag_volume!];
  else return [false, -1];
}

/**
 * Checks if a bottle fits inside a box
 * @param boxDimension Triple of Width, Height, Depth
 * @param bottleDimension Tuple of Width, Height
 * @returns True if bottle fits, else False
 */
function compareBottleAndBox(boxDimension: Array<string | undefined>, bottleDimension: Array<string | undefined>) {
  if (boxDimension.some(entry => entry === undefined) || bottleDimension.some(entry => entry === undefined))
    return false;
  const [boxWidth, boxHeight, boxDepth] = boxDimension.map(x => Number(x));
  const [bottleWidth, bottleHeight] = bottleDimension.map(x => Number(x));
  // No safety distance for now
  return boxWidth >= bottleWidth && boxHeight >= bottleHeight && boxDepth >= bottleWidth;
}

/**
 * Checks if selected blister fits inside box
 * @param amount the amount of the blister
 * @param boxDimension Triple of Width, Height, Depth
 * @param blisterDimension Triple of Width, Height, Depth
 * @function
 * @returns True if blisters fit inside the box
 */
function compareBlisterAndBox(
  amount: number,
  boxDimension: Array<string | undefined>,
  blisterDimension: Array<string | undefined>
) {
  if (boxDimension.some(entry => entry === undefined) || blisterDimension.some(entry => entry === undefined))
    return false;
  const [boxWidth, boxHeight, boxDepth] = boxDimension.map(x => Number(x));
  const [blisterWidth, blisterHeight, blisterDepth] = blisterDimension.map(x => Number(x));
  // Use amount to multiply with depth, e.g. blister with amount 3 and 10 depth obv. needs at least box of 30 depth
  return boxWidth >= blisterWidth && boxHeight >= blisterHeight && boxDepth >= blisterDepth * amount;
}

/**
 * Checks if pipette fits on top of the bottle
 * @param pipetteNeck pipette neck size
 * @param pipetteHeight pipette length
 * @param bottleNeck bottle neck size
 * @param bottleHeight bottle height
 * @returns True if pipette fits as a closure for the bottle
 */
function comparePipetteAndBottle(
  pipetteNeck?: string,
  pipetteHeight?: string,
  bottleNeck?: string,
  bottleHeight?: string
) {
  if (!pipetteNeck || !pipetteHeight || !bottleNeck || !bottleHeight) return false;
  const pHeight = Number(pipetteHeight);
  const bHeight = Number(bottleHeight);
  return compareNeckSize(pipetteNeck, bottleNeck) && pHeight <= bHeight;
}

/**
 * Checks if sprayPump fits on top of the bottle
 * @param sprayPumpNeck sprayPump neck size
 * @param sprayPumpHeight sprayPump length
 * @param bottleNeck bottle neck size
 * @param bottleHeight bottle height
 * @returns True if sprayPump fits as a closure for the bottle
 */
function compareSprayPumpAndBottle(
  sprayPumpNeck?: string,
  sprayPumpHeight?: string,
  bottleNeck?: string,
  bottleHeight?: string
) {
  if (!sprayPumpNeck || !sprayPumpHeight || !bottleNeck || !bottleHeight) return false;
  const sHeight = Number(sprayPumpHeight);
  const bHeight = Number(bottleHeight);
  return compareNeckSize(sprayPumpNeck, bottleNeck) && sHeight <= bHeight;
}

/**
 * Checks if label fits onto the bag
 * @param labelWidth label width
 * @param labelHeight label height
 * @param bagWidth bag width
 * @param bagHeight bag height
 * @returns True if label fits, else False
 */
function compareLabelAndBag(labelWidth?: string, labelHeight?: string, bagWidth?: string, bagHeight?: string) {
  if (!labelWidth || !labelHeight || !bagHeight || !bagWidth) return false;
  const lWidth = Number(labelWidth);
  const lHeight = Number(labelHeight);
  const bWidth = Number(bagWidth);
  const bHeight = Number(bagHeight);
  // check if it fits either horizontally or vertically
  return (bWidth > lWidth && bHeight > lHeight) || (bWidth > lHeight && bHeight > lWidth);
}

/**
 * Checks if label fits onto bottle
 * @param labelWidth label width
 * @param labelHeight label height
 * @param maxLabelHeight max allowed label height of bottle
 * @param diameter diameter of bottle
 * @returns True if label fits onto bottle, else False
 */
function compareLabelAndBottle(labelWidth?: string, labelHeight?: string, maxLabelHeight?: string, diameter?: string) {
  if (!labelWidth || !labelHeight || !maxLabelHeight || !diameter) return false;
  // parse values
  const lWidth = Number(labelWidth);
  const lHeight = Number(labelHeight);
  const maxLHeight = Number(maxLabelHeight);
  const dia = Number(diameter);
  return dia * Math.PI - LABELSAFETYDISTANCE > lWidth && lHeight <= maxLHeight;
}

/**
 * Checks if sleeve fits for lit
 * @param sleeveSize sleeve size
 * @param bottleNeck bottle neck size
 * @returns True if sleeve fits for label, else False
 */
function compareSleeveAndBottle(sleeveSize?: string, bottleNeck?: string) {
  if (!sleeveSize || !bottleNeck) return false;
  // check if diameter matches
  return bottleNeck.split("-")[0].trim() === sleeveSize.trim();
}

/**
 * Checks if two neck sizes fit together
 * @param sizeA first neck size
 * @param sizeB second neck size
 * @returns True if necks fit together, else False
 */
function compareNeckSize(sizeA?: string, sizeB?: string) {
  if (!sizeA || !sizeB) return false;
  const splitA = sizeA.split("-");
  const splitB = sizeB.split("-");
  // compare diameter and thread
  return splitA[0].trim() === splitB[0].trim() && splitA[1].trim() === splitB[1].trim();
}

/**
 * Computes the area of a label
 * @param label the label packaging object
 * @returns computed area of the label
 */
function getLabelArea(label: CustomPackagingsDocument) {
  return Number(label.label_width) * Number(label.label_height);
}

/**
 * Compute the volume of a box
 * @param box the box packaging object
 * @returns computed volume of the box
 */
function getBoxVolume(box: CustomPackagingsDocument) {
  return Number(box.box_height) * Number(box.box_width) * Number(box.box_depth);
}

/**
 * Gets possible torso types for current product selection (tab + preferences)
 * @param tab the current tab
 * @param preferences preferences object
 * @returns Array with packaging type that are valid for the given product
 */
function getTorsoTypeForProduct(tab: string, preferences: any) {
  const torsoFilter = _.get(TORSOSELECTION, tab);
  let types = [...torsoFilter.baseTypes];
  // Resolve additional types
  if (torsoFilter.additionalTypes) {
    for (let i = 0; i < torsoFilter.additionalTypes.length; i++) {
      const additionalType = torsoFilter.additionalTypes[i];
      if (additionalType.preference && additionalType.preference.length === 2) {
        let fulfilled = additionalType.preference[1].includes(_.get(preferences, additionalType.preference[0])!);
        if (fulfilled) types = types.concat(additionalType.types);
      }
    }
  }
  return types;
}

/**
 * Get packaging translation
 * @param key key to get the translation for
 * @returns {string} translation for packaging
 */
function getPackagingDescription(key: string | undefined): string {
  return key ? _.get(PackagingDescriptions, key, _.upperFirst(key)) : "";
}

/**
 * Receive filtered packaging options in relation to an available packaging collection
 * @param currentSelectableOptions the available packages
 * @returns {Array<{value: string, label: string}>} the related option set
 */
function getSelectableOptions(currentSelectableOptions: Array<CustomPackagingsDocument>) {
  const selectableOptions = [] as Array<{ value: string; label: string }>;

  ALL_PACKAGING_TYPES.forEach(type => {
    if (currentSelectableOptions.some(packagingOption => packagingOption.packaging_type === type)) {
      const option = PACKAGING_TYPES.find(packagingType => packagingType.value === type);
      if (option) {
        selectableOptions.push(option);
      }
    }
  });
  return selectableOptions;
}

/**
 * Resolves packaging information and builds common objects with information to be displayed
 * @param packaging the packaging document
 * @returns {string} packaging information
 */
function resolvePackagingProperties(packaging: PackagingsDocument | MinimumCalendarPackaging) {
  const { packaging_type: type } = packaging;
  // Wrapper function for translation with namespace packaging
  let properties: Array<any> = [];
  switch (type) {
    case T_BOTTLE:
      properties = [
        packaging.packaging_volume + " ml",
        packaging.packaging_neck,
        `${getPackagingDescription(packaging.packaging_material!)}, ${_.upperFirst(packaging.packaging_color!)}`
      ];
      break;
    case T_LIQUIDBOTTLE:
      properties = [
        packaging.packaging_volume + " ml",
        `${getPackagingDescription(packaging.packaging_material!)}, ${_.upperFirst(packaging.packaging_color!)}`
      ];
      break;
    case T_BOX:
      properties = [
        `${packaging.box_width} x ${packaging.box_height} x ${packaging.box_depth} mm (W x H x D)`,
        getPackagingDescription(packaging.box_quality!)
      ];
      break;
    case T_LID:
      properties = [
        packaging.lid_size,
        Array.from(
          new Set([getPackagingDescription(packaging.lid_material!), _.upperFirst(packaging.lid_color!)])
        ).join(", "),
        getPackagingDescription(packaging.lid_shape!)
      ];
      break;
    case T_BAG:
      properties = [
        `${packaging.bag_width} x ${packaging.bag_height} mm (W x H), ${packaging.bag_volume} ml`,
        `${getPackagingDescription(packaging.bag_material!)}, ${_.upperFirst(packaging.bag_color!)}`,
        getPackagingDescription(packaging.bag_shape!),
        getPackagingDescription(packaging.bag_zipper!)
      ];
      break;
    case T_BLISTER:
      properties = [
        `${packaging.blister_width} x ${packaging.blister_height} x ${packaging.blister_depth} mm (W x H x D)`,
        `${packaging.blister_capsules} Caps.`
      ];
      break;
    case T_LABEL:
    case T_MULTILAYERLABEL:
      properties = [
        `${packaging.label_width} x ${packaging.label_height} mm (W x H)`,
        getPackagingDescription(packaging.label_quality!)
      ];
      break;
    case T_SLEEVE:
      properties = [`${packaging.sleeve_size} mm`, packaging.sleeve_print ? "Printed" : "No print"];
      break;
    case T_PIPETTE:
      properties = [
        packaging.packaging_height + " mm",
        packaging.packaging_neck,
        _.upperFirst(packaging.packaging_color!)
      ];
      break;
    case T_SPRAYPUMP:
      properties = [
        "DIN " + packaging.packaging_norm,
        packaging.packaging_height + "mm",
        _.upperFirst(packaging.packaging_color!)
      ];
      break;
    case T_STICKER:
      properties = [
        packaging.form === StickerForms.ROUND
          ? `⌀ ${packaging.diameter} mm`
          : `${packaging.packaging_width} x ${packaging.packaging_height} mm (W x H)`,
        getPackagingDescription(packaging.form!),
        getPackagingDescription(packaging.quality!)
      ];
      break;
    case T_SPOON:
      properties = [packaging.capacity + " g", _.upperFirst(packaging.packaging_color!)];
      break;
    case T_SILICAGELBAG:
      properties = [packaging.weight + " g"];
  }
  properties.unshift(getPackagingDescription(packaging.packaging_type));
  properties = properties.filter(prop => prop !== undefined && prop !== null);
  return properties.join(", ");
}

/**
 * Resolves packaging information and builds common objects with information to be displayed
 * @param packaging a packaging document
 * @returns {object} returns an object containing all important properties of the packaging
 */
function resolveExtendedPackagingProperties(
  packaging: PackagingsDocument | CustomPackagingsDocument | SelectedPackagingsDocument
) {
  const { packaging_type: type } = packaging;
  // Wrapper function for translation with namespace packaging
  switch (type) {
    case T_BOTTLE:
      return {
        url: resolveUrl(packaging.images, "no_bottle.jpg"),
        size: packaging.packaging_volume + " ml",
        neck: packaging.packaging_neck,
        labelHeight: packaging.packaging_label_height + " mm",
        material: `${getPackagingDescription(packaging.packaging_material!)}, ${_.upperFirst(
          packaging.packaging_color!
        )}`,
        shape: getPackagingDescription(packaging.packaging_shape!),
        color: packaging.packaging_color
      };
    case T_LIQUIDBOTTLE:
      return {
        url: resolveUrl(packaging.images, "no_liquidbottle.jpg"),
        size: packaging.packaging_volume + " ml",
        neck: packaging.packaging_neck,
        material: `${getPackagingDescription(packaging.packaging_material!)}, ${_.upperFirst(
          packaging.packaging_color!
        )}`,
        color: packaging.packaging_color
      };
    case T_BOX:
      return {
        url: resolveUrl(packaging.images, "no_box.jpg"),
        size: `${packaging.box_width} x ${packaging.box_height} x ${packaging.box_depth} mm (W x H x D)`,
        quality: getPackagingDescription(packaging.box_quality!)
      };
    case T_LID:
      return {
        url: resolveUrl(packaging.images, "no_lid.jpg"),
        size: packaging.lid_size,
        material: Array.from(
          new Set([getPackagingDescription(packaging.lid_material!), _.upperFirst(packaging.lid_color!)])
        ).join(", "),
        shape: getPackagingDescription(packaging.lid_shape!),
        color: packaging.lid_color
      };
    case T_BAG:
      return {
        url: resolveUrl(packaging.images, "no_bag.jpg"),
        size: `${packaging.bag_width} x ${packaging.bag_height} mm (W x H), ${packaging.bag_volume} ml`,
        material: `${getPackagingDescription(packaging.bag_material!)}, ${_.upperFirst(packaging.bag_color!)}`,
        shape: getPackagingDescription(packaging.bag_shape!),
        zipper: getPackagingDescription(packaging.bag_zipper!),
        color: packaging.bag_color
      };
    case T_BLISTER:
      return {
        url: resolveUrl(packaging.images, "no_blister.jpg"),
        size: `${packaging.blister_width} x ${packaging.blister_height} x ${packaging.blister_depth} mm (W x H x D)`,
        capsules: packaging.blister_capsules
      };
    case T_LABEL:
    case T_MULTILAYERLABEL:
      return {
        url: resolveUrl(packaging.images, "no_label.jpg"),
        size: `${packaging.label_width} x ${packaging.label_height} mm (W x H)`,
        quality: getPackagingDescription(packaging.label_quality!)
      };
    case T_SLEEVE:
      return {
        url: resolveUrl(packaging.images, "sleeve.jpg"),
        size: `${packaging.sleeve_size} mm`,
        print: packaging.sleeve_print ? "Yes" : "No"
      };
    case T_PIPETTE:
      return {
        url: resolveUrl(packaging.images, "no_pipette.jpg"),
        length: packaging.packaging_height + " mm",
        neck: packaging.packaging_neck,
        color: _.upperFirst(packaging.packaging_color!)
      };
    case T_SPRAYPUMP:
      return {
        url: resolveUrl(packaging.images, "placeholder.png"),
        color: _.upperFirst(packaging.packaging_color!),
        norm: "DIN " + packaging.packaging_norm,
        length: packaging.packaging_height + " mm",
        neck: packaging.packaging_neck
      };
    case T_STICKER:
      const stickerObj: any = {
        url: resolveUrl(packaging.images, "placeholder.png"),
        form: getPackagingDescription(packaging.form),
        quality: getPackagingDescription(packaging.quality)
      };
      if (packaging.form === StickerForms.ROUND) stickerObj["diameter"] = `${packaging.diameter} mm`;
      else stickerObj["size"] = `${packaging.packaging_width} x ${packaging.packaging_height} mm (W x H)`;
      return stickerObj;
    case T_SPOON:
      return {
        url: resolveUrl(packaging.images, "placeholder.png"),
        color: _.upperFirst(packaging.packaging_color!),
        capacity: packaging.capacity + " g"
      };
    case T_SILICAGELBAG:
      return {
        url: resolveUrl(packaging.images, "placeholder.png"),
        weight: packaging.weight + " g"
      };
    case T_PACKAGEINSERT:
      return {
        url: resolveUrl(packaging.images, "placeholder.png"),
        size: "Depending on box"
      };
  }
}

/**
 * Resolve url for packaging image
 * @param images available image for the packaging
 * @param defaultImage default image for the packaging
 * @returns {string} url to image
 */
function resolveUrl(images: Array<string>, defaultImage: string) {
  return images.length > 0 ? images[0] : toAbsoluteUrl("/media/img/" + defaultImage);
}

/**
 * Get style for the color badge
 * @param color the color
 * @param type optional, type do differentiate between different styles, e.g. with box shadow
 * @returns {object} object with style information
 */
function getColorBadgeStyle(color?: string, type?: "shadow") {
  if (!color) return;
  let style: any =
    type === "shadow"
      ? {
          backgroundColor: color,
          width: 28,
          height: 28,
          borderRadius: 30,
          padding: 10,
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.8)"
        }
      : {
          position: "absolute",
          bottom: 5,
          right: 5,
          backgroundColor: color,
          padding: 10,
          paddingLeft: 30,
          paddingRight: 30
        };
  switch (color) {
    case "blue":
      style.backgroundColor = "#000980";
      break;
    case "transparent":
      if (type === "shadow") {
        style.backgroundColor = null;
        style.backgroundImage = "linear-gradient(to right, white, lightblue)";
        style.color = "black";
      } else {
        style.backgroundColor = "rgba(0,0,0,0.01)";
        style.color = "black";
      }
      break;
    case "white":
      style.backgroundColor = "white";
      style.color = "black";
      break;
    case "color":
      style.backgroundColor = null;
      style.backgroundImage = "linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)";
      break;
    case "gold":
      style.backgroundColor = "#ebd182";
      break;
    case "brown":
      style.backgroundColor = "#755e48";
      break;
    case "aluminium":
      style.backgroundColor = "#b4b4be";
      break;
    case "silver":
      style.backgroundColor = "#c0c0c0";
      break;
    case "purple":
      style.backgroundColor = "#360658";
      break;
  }
  return style;
}

/**
 * Get a matching price for a packaging and the given parameters
 * @param packaging a packaging document
 * @param quantity the total order quantity of the commodity
 * @returns {_id: BSON.ObjectId | "customer", price: PackagingPrice} object with id of the supplier and a commodity price object
 */
function getFastestDeliverySupplier(packaging: PackagingsDocument, quantity: number) {
  const packagingSuppliers = packaging.suppliers;
  // Collect all prices
  let allPrices: Array<{ id: Realm.BSON.ObjectId; price: PackagingPrice }> = [];
  let lowestMOQ: number;
  packagingSuppliers.forEach(cSupplier => {
    cSupplier.prices.forEach(sPrice => {
      const moq = sPrice.moq;
      if (!lowestMOQ || moq < lowestMOQ) lowestMOQ = moq;
      allPrices.push({ id: cSupplier._id as Realm.BSON.ObjectId, price: sPrice });
    });
  });
  if (allPrices.length === 0) {
    return;
  }
  const foundMatchingMOQ = quantity >= lowestMOQ!;
  if (foundMatchingMOQ) {
    // If we found a matching moq remove all non-matching moqs
    allPrices = allPrices.filter(price => price.price.moq <= quantity);
  } else {
    return;
  }
  allPrices.sort((a, b) => a.price.deliverytime - b.price.deliverytime);
  const lowestPrice = allPrices[0];
  return { _id: lowestPrice.id, price: lowestPrice.price };
}

/**
 * Get image for packaging
 * @param packaging packaging document
 * @returns {string} path to packaging image
 */
function getPackagingImage(packaging: PackagingsDocument | MinimumCalendarPackaging) {
  switch (packaging.packaging_type) {
    case T_BOTTLE:
      return resolveUrl(packaging.images, "no_bottle.jpg");
    case T_LIQUIDBOTTLE:
      return resolveUrl(packaging.images, "no_liquidbottle.jpg");
    case T_BOX:
      return resolveUrl(packaging.images, "no_box.jpg");
    case T_LID:
      return resolveUrl(packaging.images, "no_lid.jpg");
    case T_BAG:
      return resolveUrl(packaging.images, "no_bag.jpg");
    case T_LABEL:
    case T_MULTILAYERLABEL:
      return resolveUrl(packaging.images, "no_label.jpg");
    case T_SLEEVE:
      return resolveUrl(packaging.images, "sleeve.jpg");
    case T_PIPETTE:
      return resolveUrl(packaging.images, "no_pipette.jpg");
    case T_BLISTER:
      return resolveUrl(packaging.images, "no_blister.jpg");
    default:
      return resolveUrl(packaging.images, "placeholder.png");
  }
}

/**
 * Calculate the allocated stock (reserved via reserve material modal) for a packaging
 * @param packagingId id of the packaging to get allocated stock for
 * @param batchId id of the specific batch to get allocated stock for
 * @param orders list of all orders
 * @returns {Array<{order: OrdersDocument; allocated: number}>} List of orders with allocated amount
 */
function calculateAllocatedStock(
  packagingId: BSON.ObjectId | string,
  batchId: BSON.ObjectId | string,
  orders: Array<OrdersDocument>
) {
  const relevantOrders = orders.filter(
    o => [ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION].includes(o.state) && o.usedPackagingBatches
  );
  let allocatedStock = [];
  for (let i = 0; i < relevantOrders.length; i++) {
    const order = relevantOrders[i];
    const allocated = order.usedPackagingBatches!.reduce(
      (sum, uB) =>
        sum +
        (uB.packagingId.toString() === packagingId.toString() && uB.id.toString() === batchId.toString() ? uB.used : 0),
      0
    );
    if (allocated > 0) allocatedStock.push({ order, allocated });
  }
  return allocatedStock;
}

/**
 * Calculate the allocated stock (reserved via reserve material modal) for a packaging
 * @param packagingId id of the packaging to get allocated stock for
 * @param batchId id of the specific batch to get allocated stock for
 * @param orders list of all orders
 * @param excludedOrderId optional, optional order id to exclude from calculation
 * @returns {number} Total allocated amount
 */
function calculateTotalAllocatedStock(
  packagingId: BSON.ObjectId | string,
  batchId: BSON.ObjectId | string,
  orders: Array<OrdersDocument>,
  excludedOrderId?: BSON.ObjectId | string
) {
  const relevantOrders = orders.filter(
    o =>
      [ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION].includes(o.state) &&
      o.usedPackagingBatches &&
      (!excludedOrderId || o._id.toString() !== excludedOrderId.toString())
  );
  return relevantOrders.reduce(
    (sum, o) =>
      sum +
      o.usedPackagingBatches!.reduce(
        (sum2, uB) =>
          sum2 +
          (uB.packagingId &&
          uB.packagingId.toString() === packagingId.toString() &&
          uB.id.toString() === batchId.toString()
            ? uB.used
            : 0),
        0
      ),
    0
  );
}

/**
 * Get stock statics for a packaging
 * @param packaging the related packaging
 * @param context react context
 * @param localStock local stock of the packaging
 * @returns {stock: number, ordered: number, reserved: number, available: number} the stock statistics of the packaging
 */
function calculateStockValues(
  packaging: PackagingsDocument,
  context: React.ContextType<typeof DataContext>,
  localStock: LocalPackagingStock
) {
  const orders = context.packagingOrders.filter(po => po.packaging.toString() === packaging._id.toString());
  const manufacturer = localStock.manufacturer._id.toString();
  // Collect the stock of the currently selected manufacturer
  let stock = localStock.stock.reduce((sum, b) => sum + (b.amount > 0 && !b.disabled ? b.amount : 0), 0);
  // Collect the amount of already ordered commodities
  let ordered = 0;
  if (orders) {
    for (let i = 0; i < orders.length; i++) {
      const o = orders[i];
      if (o.destination && o.destination.toString() === manufacturer && !o.delivered) {
        ordered += o.orderQuantity;
      }
    }
  }
  // Collect the amount of commodities that are needed for current orders
  let reserved = 0;
  const ordersMan = context.orders.filter(
    o =>
      [ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION].includes(o.state) &&
      (o.settings.filler || o.settings.manufacturer.toString()) === manufacturer
  );
  for (let i = 0; i < ordersMan.length; i++) {
    const o = ordersMan[i];
    const price = o.calculations[0].packagings.find(p => p._id.toString() === packaging._id.toString());
    if (price) reserved += price.amount * (1 + price.buffer / 100) * o.calculations[0].units;
  }
  return { stock, ordered, reserved, available: stock + ordered - reserved };
}

/**
 * Get an update object for packaging orders if a packaging is removed or replaced
 * @param packagingId id of the removed/replaced packaging
 * @param order custom order document
 * @param packagingOrders list of packaging orders
 * @returns { UpdateAction | null } update action or null if no update necessary
 */
function getPackagingOrderUpdate(
  packagingId: string,
  order: CustomOrder,
  packagingOrders: Array<PackagingOrderDocument>
): UpdateAction | null {
  const packagingOrder: PackagingOrderDocument | undefined = packagingOrders.find(
    pOrder =>
      pOrder.relatedOrders.some(o => o.toString() === order._id.toString()) &&
      pOrder.packaging.toString() === packagingId
  );
  if (!packagingOrder) return null;
  // Update required
  const update: UpdateAction = {
    collection: PACKAGINGORDERS,
    filter: { _id: packagingOrder._id },
    pull: { relatedOrders: order._id }
  };
  return update;
}

/**
 * Gets a style definition for a badge according to the packaging color
 * @param packaging the current packaging object
 * @returns {object} style for a colored badge
 */
const getBadgeColor = (packaging: PackagingsDocument) => {
  const type = packaging.packaging_type;
  switch (type) {
    case T_LID:
      if (packaging.lid_color) return getColorBadgeStyle(packaging.lid_color, "shadow");
      break;
    case T_BAG:
      if (packaging.bag_color) return getColorBadgeStyle(packaging.bag_color, "shadow");
      break;
    default:
      return getColorBadgeStyle(packaging.packaging_color, "shadow");
  }
};

/**
 * Determines if the given material is packaging
 * @param material Commodity or Packaging document
 * @returns {boolean} Indicating whether the document is packaging or not
 */
export function isPackaging(material: CommoditiesDocument | PackagingsDocument): material is PackagingsDocument {
  return "packaging_type" in material;
}

// eslint-disable-next-line
export default {
  resolvePackagingType,
  updatePackagingWithTimeline,
  calculateAllocatedStock,
  calculateTotalAllocatedStock,
  calculateStockValues,
  compareBlisterAndBox,
  compareBottleAndBox,
  compareLabelAndBag,
  compareLabelAndBottle,
  compareNeckSize,
  comparePipetteAndBottle,
  compareSprayPumpAndBottle,
  compareSleeveAndBottle,
  compareTorsoAndVolume,
  compareTorsoAndVolumeWithMargin,
  getColors,
  filterPackaging,
  concatPackagingInfo,
  getLowestPrice,
  findLatestPriceUpdate,
  getLowestPriceMOQ,
  getLowestDeliveryTime,
  getBoxVolume,
  getLabelArea,
  getTorsoTypeForProduct,
  getShortPackagingInfo,
  getAllStock,
  getAvailableStock,
  getPackagingType,
  getPackagingDescription,
  resolvePackagingProperties,
  resolveExtendedPackagingProperties,
  getColorBadgeStyle,
  getFastestDeliverySupplier,
  resolveUrl,
  getPackagingImage,
  getSelectableOptions,
  getPackagingOrderUpdate,
  getBadgeColor
};
