import { BSON } from "realm-web";
import dbService from "./dbService";

/**
 * Call 'sendSlackMessage' to send a message via slack
 * @param target ObjectId of a user to notify or slack channelId
 * @param message the message to send
 * @returns {Promise<boolean>} Result of backend function
 */
function sendMessage(target: BSON.ObjectId | string, message: string) {
  return dbService.callFunction("sendSlackMessage", [target, message]);
}

export default { sendMessage };
