import _ from "lodash";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Select from "react-select";
import { DataContext } from "../../../../context/dataContext";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import DateInput from "../../../common/DateInput";
import fileUtils from "../../../../utils/fileUtils";
import dateUtils from "../../../../utils/dateUtils";
import HistoryBackButton from "../../../listings/common/HistoryBackButton";
import baseUtils from "../../../../utils/baseUtils";

enum PeriodValues {
  ALL,
  CURRENT_YEAR,
  PREVIOUS_YEAR,
  CUSTOM
}

const PERIODOPTIONS = [
  { value: PeriodValues.ALL, label: "All" },
  { value: PeriodValues.CURRENT_YEAR, label: "Current Year" },
  { value: PeriodValues.PREVIOUS_YEAR, label: "Previous Year" },
  { value: PeriodValues.CUSTOM, label: "Custom" }
];

enum TransportType {
  SEA_FREIGHT,
  AIR_FREIGHT
}

interface StatisticsOverview {
  absoluteAmounts: {
    air: number;
    sea: number;
  };
  relativeAmounts: {
    air: number;
    sea: number;
  };
  averageDaysBetweenOrderingAndBooking: {
    air: number;
    sea: number;
  };
  timespan: {
    start: Date;
    end: Date;
  };
}
interface TransportTypeExporterProps extends RouteComponentProps {
  context: React.ContextType<typeof DataContext>;
}

interface TransportTypeExporterState {
  period: { value: PeriodValues; label: string };
  airFreightTimespan: number;
  customTimePeriod: {
    start: Date;
    end: Date;
  };
}

const TransportTypeExporter: React.FunctionComponent<TransportTypeExporterProps> = ({ history, context }) => {
  const getDefaultState = (): TransportTypeExporterState => {
    const defaultPeriod = dateUtils.getDefaultPeriod();
    return {
      period: PERIODOPTIONS[0], // initial all
      airFreightTimespan: 6 * 7, // 6 weeks as initial
      customTimePeriod: defaultPeriod
    };
  };

  const [transportTypeExporterState, setTransportTypeExporterState] =
    useState<TransportTypeExporterState>(getDefaultState);

  const { period, customTimePeriod, airFreightTimespan } = transportTypeExporterState;

  const handleChangeNumericAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replaceAll(/^0+/g, "0");
    if (!value.includes(".")) value = Number(value).toString();
    if (+value >= 0) {
      const newValue = Math.round(+value);
      setTransportTypeExporterState(prevState => {
        return {
          ...prevState,
          airFreightTimespan: newValue
        };
      });
    }
  };

  const handlePeriodChange = (e: any) => {
    setTransportTypeExporterState(prevState => {
      return {
        ...prevState,
        period: e
      };
    });
  };

  const handleCustomTimePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPeriod = _.cloneDeep(customTimePeriod);
    const name = e.target.name as "start" | "end";
    let value = e.target.valueAsDate;
    if (!value) return;
    if (name === "start") value.setHours(0, -value.getTimezoneOffset(), 0, 0);
    else value.setHours(23, 59 + -value.getTimezoneOffset(), 59, 999);
    _.set(newPeriod, name, value);
    setTransportTypeExporterState(prevState => {
      return {
        ...prevState,
        customTimePeriod: newPeriod
      };
    });
  };

  const handleExportCSV = () => {
    const relatedStatistic = buildStatistics();

    const headers = [
      "Transport Type",
      "Absolute Amount",
      "Relative Amount (in percent)",
      "Average Time Between Ordered and Booked (in days)"
    ];
    const csvContent: Array<Array<string>> = [];

    csvContent.push(
      [
        "Air Freight",
        relatedStatistic.absoluteAmounts.air.toString(),
        relatedStatistic.relativeAmounts.air.toString(),
        relatedStatistic.averageDaysBetweenOrderingAndBooking.air.toString()
      ],
      [
        "Sea Freight",
        relatedStatistic.absoluteAmounts.sea.toString(),
        relatedStatistic.relativeAmounts.sea.toString(),
        relatedStatistic.averageDaysBetweenOrderingAndBooking.sea.toString()
      ]
    );

    const csv = fileUtils.exportAsCSV(headers, csvContent);
    const fileName =
      "Transport_Statistics_from_" +
      baseUtils.formatDate(relatedStatistic.timespan.start) +
      "_to_" +
      baseUtils.formatDate(relatedStatistic.timespan.end) +
      ".csv".replaceAll(" ", "_");
    fileUtils.downloadFile(csv, fileName, "text/plain");
  };

  const validateData = () => {
    const errors: Array<string> = [];

    if (airFreightTimespan <= 0) {
      errors.push("Please enter a air freight timespan greater than 0");
    }

    if (period.value === PeriodValues.CUSTOM) {
      if (customTimePeriod.end.getTime() < customTimePeriod.start.getTime()) {
        errors.push("Please enter a valid custom time period");
      }
    }
    return errors;
  };

  /**
   * Function to build the relevant statistics based on timeframe and sea freight timespan
   * @returns {Array<StatisticsOverview>} the relevant statistics
   */
  const buildStatistics = (): StatisticsOverview => {
    const { commodities } = context;

    // calculate the relevant timeframe commodity orders must fulfill (CASE: Period Selection nor ALL)
    let startDate: Date;
    let endDate: Date;
    const actualDate = new Date();
    const defaultTimespan = dateUtils.getDefaultPeriod();

    switch (period.value) {
      case PeriodValues.CURRENT_YEAR:
        startDate = new Date(actualDate.getFullYear(), 0, 1);
        endDate = actualDate;
        break;
      case PeriodValues.PREVIOUS_YEAR:
        startDate = new Date(actualDate.getFullYear() - 1, 0, 1);
        endDate = new Date(actualDate.getFullYear(), 0, 1);
        break;
      case PeriodValues.CUSTOM:
        startDate = customTimePeriod.start;
        endDate = customTimePeriod.end;
        break;
      case PeriodValues.ALL:
        startDate = defaultTimespan.start;
        endDate = defaultTimespan.end;
        break;
    }

    // initialization of statistic values
    let amountOfDaysBetweenForAirTransports = 0;
    let amountOfDaysBetweenForSeaTransports = 0;
    let airTransportAmount = 0;
    let seaTransportAmount = 0;

    // filtering the relevant commodity orders and categorize them to a specific transport type
    // accumulating statistic values
    commodities.forEach(commodity => {
      commodity.orders?.forEach(order => {
        let isOrderRelevantForStatistic = order.delivered !== null && order.created !== null; // only fulfilled orders should be considered
        if (period.value !== PeriodValues.ALL && isOrderRelevantForStatistic) {
          // checking for time constraint
          const orderIsCreatedWithinTimeframe =
            order.created.getTime() >= startDate.getTime() && order.created.getTime() <= endDate.getTime();
          const orderIsFulfilledWithinTimeframe =
            order.delivered !== null &&
            order.delivered.getTime() >= startDate.getTime() &&
            order.delivered.getTime() <= endDate.getTime();

          // evaluating time constraint
          isOrderRelevantForStatistic = orderIsCreatedWithinTimeframe && orderIsFulfilledWithinTimeframe;
        }

        // accumulating relevant statistic values
        if (isOrderRelevantForStatistic) {
          // evaluating type and days between
          const relatedCategorisation =
            order.deliverytime > airFreightTimespan ? TransportType.SEA_FREIGHT : TransportType.AIR_FREIGHT;
          const daysBetweenOrderingAndBooking = order.delivered
            ? (order.delivered.getTime() - order.created.getTime()) / (1000 * 3600 * 24)
            : 0;

          // checking for type and accumulating values
          if (relatedCategorisation === TransportType.AIR_FREIGHT) {
            airTransportAmount++;
            amountOfDaysBetweenForAirTransports += daysBetweenOrderingAndBooking;
          } else {
            seaTransportAmount++;
            amountOfDaysBetweenForSeaTransports += daysBetweenOrderingAndBooking;
          }
        }
      });
    });

    let relativeAirAmount = 0;
    let relativeSeaAmount = 0;

    if (airTransportAmount + seaTransportAmount !== 0) {
      relativeAirAmount = (airTransportAmount / (airTransportAmount + seaTransportAmount)) * 100;
      relativeAirAmount = Math.round(relativeAirAmount * 100) / 100;
      relativeSeaAmount = Math.round((100 - relativeAirAmount) * 100) / 100; // necessary due to floating point representational issues
    }

    return {
      absoluteAmounts: {
        air: airTransportAmount,
        sea: seaTransportAmount
      },
      relativeAmounts: {
        air: relativeAirAmount,
        sea: relativeSeaAmount
      },
      averageDaysBetweenOrderingAndBooking: {
        air: airTransportAmount !== 0 ? Math.round(amountOfDaysBetweenForAirTransports / airTransportAmount) : 0,
        sea: seaTransportAmount !== 0 ? Math.round(amountOfDaysBetweenForSeaTransports / seaTransportAmount) : 0
      },
      timespan: {
        start: startDate,
        end: endDate
      }
    };
  };

  const errors = validateData();
  return (
    <div className="kt-portlet kt-portlet--mobile">
      <div className="kt-portlet__head kt-portlet__head--lg">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Transport Type Statistics</h3>
        </div>
        <HistoryBackButton history={history} />
      </div>
      <div className="kt-portlet__body">
        <div className="row mt-2">
          <div className="col text-dark font-weight-bolder mb-3">
            Making assumptions of existing commodity orders and categorizing them as sea freight or air freight.
            Exporting an .csv containing the total amount, relative amount and the average days between ordered and
            booked.
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-4 col-md-3 col-lg-2 align-self-center">Timespan For Air Freight</div>
          <div className="col col-4 col-md-3 col-lg-2">
            <div className="input-group">
              <input
                type="number"
                value={airFreightTimespan}
                className="form-control"
                onChange={handleChangeNumericAmount}
                name="newAmount"
              />
              <div className="input-group-append">
                <span className="input-group-text">day(s)</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col col-4 col-md-3 col-lg-2 align-self-center">Period</div>
          <div className="col col-4 col-md-3 col-lg-2">
            <Select className="select-default" options={PERIODOPTIONS} value={period} onChange={handlePeriodChange} />
          </div>
        </div>
        {period.value === PeriodValues.CUSTOM && (
          <div className="row mt-2">
            <label className="col col-4 col-md-3 col-lg-2 align-self-center">Custom Period</label>
            <div className="col col-3 col-md-3 col-lg-2">
              <DateInput value={customTimePeriod.start} onBlur={handleCustomTimePeriodChange} name={"start"} />
            </div>
            <div className="col col-2 col-md-1 col-lg-1 align-self-center text-center">
              <span className="kt-font-dark kt-font-bolder">-</span>
            </div>
            <div className="col col-3 col-md-3 col-lg-2">
              <DateInput value={customTimePeriod.end} onBlur={handleCustomTimePeriodChange} name={"end"} />
            </div>
          </div>
        )}
        <div className="alert alert-light mt-4" role="alert">
          <div className="alert-icon">
            <i className="flaticon-information" />
          </div>
          <div className="alert-text">
            The exported results are assumed based on the delivery time of an order and the selected timespan for air
            freights. For example, for a selected timeframe of 45 days, all orders with a delivery time less or equal
            than 45 days will be categorized as air freight, rest will be categorized as sea freight.
          </div>
        </div>
      </div>
      <div className="kt-portlet__foot">
        <div className="float-right">
          <ErrorOverlayButton
            errors={errors}
            buttonText={"Export"}
            className="btn btn-success mr-3"
            onClick={handleExportCSV}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(TransportTypeExporter);
