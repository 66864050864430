import config from "../config/config.json";
import http from "../services/httpService";

/**
 * Handle image Upload
 * @param files: the selected file(s)
 * @param productionLineName: Name of the production line
 * @param callback: Callback that is used to update the state etc.
 */
async function handleImageUpload(files: File[], productionLineName: string, callback: (image: string) => void) {
  // Only one file is allowed
  if (files.length === 1) {
    const file = files.pop()!;
    // file name consisting of the userdata id, current timestamp and the given local file name
    const fileName = encodeURIComponent(`${productionLineName}_${Date.now()}_${file.name}`);
    const header = {
      headers: { "Content-Type": "application/octet-stream" }
    };
    try {
      const { data: name } = await http.post(config.uploadEndpoint.concat(fileName), file, header);
      const img_url = config.mediahubFileBase.concat(encodeURIComponent(name));
      callback(img_url);
    } catch (ex) {
      console.error(ex.message);
    }
  }
}

export default { handleImageUpload };
