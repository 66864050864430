import React from "react";
import countryList from "i18n-iso-countries";
import { toAbsoluteUrl } from "../../../_metronic";

interface CommodityCountryWidgetProps {
  countryCode: string | undefined;
}

const CommodityCountryWidget: React.FunctionComponent<CommodityCountryWidgetProps> = ({ countryCode }) => {
  return (
    <div>
      <img
        style={{
          width: "14px",
          height: "14px",
          borderRadius: "7px",
          objectFit: "cover"
        }}
        alt={countryCode || "various"}
        src={toAbsoluteUrl("/media/icons/countries/") + (countryCode || "various").toLowerCase() + ".png"}
        className="country-icon"
      />{" "}
      <span className="align-middle">{countryCode ? countryList.getName(countryCode, "en") : "Various"}</span>
    </div>
  );
};

export default CommodityCountryWidget;
